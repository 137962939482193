import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import InputMask from 'react-input-mask';

export const CreateOrganizationDetailsComponent = ({
                                                       formik,
                                                       setFiledValue,
                                                   }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Organization Details</div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Name of Organization*
                    </div>
                    <input
                        autoComplete={"organization"}
                        id="permitHolder.orgName"
                        name="permitHolder.orgName"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.permitHolder.orgName}
                    />
                    {(formik.touched.permitHolder?.orgName && formik.errors.orgName) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.orgName}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Org. Telephone*</div>
                    <InputMask
                        autoComplete={"tel-national"}
                        id="permitHolder.phone"
                        name="permitHolder.phone"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.permitHolder.phone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.permitHolder?.phone && formik.errors.phone) ?
                        <ErrorMessageFieldCommonComponent
                            phone={formik.errors.phone}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Street Address*</div>
                    <input
                        autoComplete={"street-address"}
                        id="permitHolder.address.streetAddress"
                        name="permitHolder.address.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.permitHolder.address.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.permitHolder?.address?.streetAddress && formik.errors.streetAddress) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.streetAddress}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email Address*</div>
                    <input
                        autoComplete={"email"}
                        id="permitHolder.email"
                        name="permitHolder.email"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.permitHolder.email}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.permitHolder?.email && formik.errors.email) ?
                        <ErrorMessageFieldCommonComponent
                            email={formik.errors.email}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                    <input
                        autoComplete={"address-level2"}
                        id="permitHolder.address.city"
                        name="permitHolder.address.city"
                        value={formik.values.permitHolder.address.city}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["page-container-body-left-input-box"]}`}
                        type="text"
                    />
                    {(formik.touched.permitHolder?.address?.city && formik.errors.city) ?
                        <ErrorMessageFieldCommonComponent
                            city={formik.errors.city}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City Code*</div>
                    <InputMask
                        id="permitHolder.cityCode"
                        name="permitHolder.cityCode"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.permitHolder.cityCode}
                        onBlur={formik.handleBlur}
                        mask="999"
                        maskChar=" "
                        placeholder="Ex. 123"
                    />
                    {(formik.touched.permitHolder?.cityCode && formik.errors.cityCode) ?
                        <ErrorMessageFieldCommonComponent
                            cityCode={formik.errors.cityCode}/> : null}
                </div>
                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            autoComplete={"postal-code"}
                            id="permitHolder.address.zip"
                            name="permitHolder.address.zip"
                            value={formik.values.permitHolder.address.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            mask="99999"
                            maskChar=" "
                        />
                        {(formik.touched.permitHolder?.address?.zip && formik.errors.zip) ?
                            <ErrorMessageFieldCommonComponent
                                zip1={formik.errors.zip}/> : null}
                    </div>
                </div>
                <div className={styles['page-container-body-left-label-and-input-box-container']}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Org. Type*</div>
                    <InputMask
                        id="permitHolder.orgType"
                        name="permitHolder.orgType"
                        value={formik.values.permitHolder.orgType}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["page-container-body-left-input-box"]}`}
                        type="text"
                        placeholder="Ex. 123"
                        mask="999"
                        maskChar=" "
                    />
                    {(formik.touched.permitHolder?.orgType && formik.errors.zip) ?
                        <ErrorMessageFieldCommonComponent
                            orgType={formik.errors.orgType}/> : null}
                </div>
            </form>
        </>
    );
}