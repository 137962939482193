import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { Agent1099AddToSweepRequest } from './api-types'

export const getFetchAgent1099AddToSweep = () => {
  const { isLoading, error, fetchUrl } = useFetch()
  const fetchAgent1099AddToSweep = (body: Agent1099AddToSweepRequest) => {
    fetchUrl(
      `${config.SERVER_BASE_URL}/api/v1/agent1099Queue/addToSweep`,
      'POST',
      {},
      JSON.stringify(body)
    )
  }

  return {
    isLoading,
    error,
    fetchAgent1099AddToSweep
  }
}
