import { useHistory } from "react-router-dom"
import { TaxAdjustmentStatus, TaxAdjustmentSummary } from "../../../../api/check-run-payments-fetcher/api-types"
import { formatCentsToDollars } from "../../../../util"
import { SearchResultsTableColumnMap, SearchResultsTableUI } from "../../../CommonComponents/SearchResultsTableComponent/search-results-table-ui"
import { paymentsRoutePath } from '../../../../config.js'
import React, { useState } from "react"
import '../styles.scss'
import { FutureTaxAdjustmentStatusCtaUI } from "../future-tax-adjustment-status-cta/future-tax-adjustment-status-cta-ui"
import { UpdateTaxAdjustmentStatusUI } from "../update-tax-adjustment-status/update-tax-adjustment-status-ui"

export type FutureTaxAdjustmentsUIProps = {
  taxAdjustments: TaxAdjustmentSummary[]
}

type FutureTaxAdjustmentDisplayRow = {
  name: string
  statusWithCta: React.ReactNode
  grossAdjustment: string
  irsAdjustment: string
  dorAdjustment: string
  netAdjustment: string
  checkNumber: string
  paymentId: string
}

const FUTURE_TAX_ADJUSTMENT_COLUMN_MAP: SearchResultsTableColumnMap<FutureTaxAdjustmentDisplayRow> = [
    { dataKey: 'name', label: 'Payee Name', width: 30 },
    { dataKey: 'statusWithCta', label: 'Status', width: 10},
    { dataKey: 'grossAdjustment', label: 'Gross Adjustment', width: 10},
    { dataKey: 'irsAdjustment', label: 'IRS Adjustment', width: 10},
    { dataKey: 'dorAdjustment', label: 'DOR Adjustment', width: 10},
    { dataKey: 'netAdjustment', label: 'Net Adjustment', width: 10},
    { dataKey: 'checkNumber', label: 'Check #', width: 10}
]

const ROOT_CLASS = `future-tax-adjustments`

export const FutureTaxAdjustmentsUI = (
  props: FutureTaxAdjustmentsUIProps
) => {
  const history = useHistory()
  const [taxAdjustmentUpdateId, setTaxAdjustmentUpdateId] = useState<string>()
  const [taxAdjustmentUpdateStatus, setTaxAdjustmentUpdateStatus] = useState<TaxAdjustmentStatus>()
  const [showTaxAdjustmentUpdate, setShowTaxAdjustmentUpdate] = useState(false)

  const generateOriginalPaymentCta = (row: FutureTaxAdjustmentDisplayRow) => {
    return <button
      className={`dark-btn`}
      onClick={() => {
        history.push(
          `${paymentsRoutePath}/details/${row.paymentId}`
        )
      }}>
        Original
      </button>   
  }

  const rowData : FutureTaxAdjustmentDisplayRow[] = props.taxAdjustments.map((row) => {
    return {
      name: row.payeeName,
      statusWithCta: <FutureTaxAdjustmentStatusCtaUI
        taxAdjustmentId={row.taxAdjustmentId}
        currentStatus={row.status}
        showUpdateDialog={() => {
          setTaxAdjustmentUpdateId(row.taxAdjustmentId)
          setTaxAdjustmentUpdateStatus(row.status)
          setShowTaxAdjustmentUpdate(true)
         }
        }
       />,
      grossAdjustment: formatCentsToDollars(row.grossAdjustmentAmountInCents),
      irsAdjustment: formatCentsToDollars(row.irsAdjustmentAmountInCents),
      dorAdjustment: formatCentsToDollars(row.dorAdjustmentAmountInCents),
      netAdjustment: formatCentsToDollars(row.netAdjustmentAmountInCents),
      checkNumber: row.documentNumber,
      paymentId: row.originalPaymentId
    }
  })

  return (
    <div className={ROOT_CLASS}>
      <div className='header'>
        <h2 className={`outer-container-title`}>Payment & Tax Adjustments</h2>
      </div>
      <SearchResultsTableUI
        columnMap={FUTURE_TAX_ADJUSTMENT_COLUMN_MAP}
        rowData={rowData}
        additionalResults={false}
        generateRowCTA={generateOriginalPaymentCta}
      />
      {
        showTaxAdjustmentUpdate && <UpdateTaxAdjustmentStatusUI 
        status = { taxAdjustmentUpdateStatus }
        taxAdjustmentId= { taxAdjustmentUpdateId }
        setStatus = { setTaxAdjustmentUpdateStatus }
        closeDialog={ () => setShowTaxAdjustmentUpdate(false) }
      />
      }
      
    </div>
  )

}