// Interfaces related to Annuity Payees

export type Payee = IndividualPayee | EntityPayee;

type IndividualPayee = {
  id: string;
  createdOn: string;
  validFrom: string;
  validTo: string | null;
  firstName: string;
  middleInitial: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  dateOfBirth: string;
  dateOfDeath: string;
  tin: string;
  tinType?: string;
  email: string;
  phone: string;
  payeeType: "Individual";
  notes: string | null;
  country: string;
  irsName: string | null;
};

type EntityPayee = {
  id: string;
  createdOn: string;
  validFrom: string;
  validTo: string | null;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  tin: string;
  tinType?: string;
  freeformName: string;
  payeeType: "Entity";
  notes: string | null;
  country: string;
  irsName: string | null;
};

// combined payee type
export type FormPayee = {
  id: string;
  createdOn: string;
  validFrom: string;
  validTo: string | null;
  address1: string;
  address2: string;
  city: string;
  country: string;
  dateOfBirth: string;
  dateOfDeath: string | null;
  firstName: string | null;
  lastName: string | null;
  middleInitial: string | null;
  notes: string;
  payeeType: "Individual" | "Entity";
  email: string;
  phone: string;
  tin: string;
  tinType?: string;
  state: string;
  zip: string;
  freeformName: string | null;
  irsName: string | null;
};

// Payee specific to Time Payment Account with additional fields pertaining to TPA
export interface TimePaymentAccountPayee {
  id: string;
  createdOn: string;
  validFrom: string;
  validTo: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  dateOfBirth: string;
  dateOfDeath: string | null;
  tin: string | null;
  phone: string;
  freeformName: string | null;
  payeeType: "Individual" | "Entity";
  notes: string;
  country: string;
  timePaymentAccountPayeeId: string;
  dateCreated: string;
  acctStatus: string;
  isPrimaryPayee: boolean;
}

export interface PayeeFilter {
  firstName: string,
  lastName: string,
  tin: string 
}

export const dummyFilter: PayeeFilter = {
   firstName: "",
   lastName: "",
   tin: ""
}