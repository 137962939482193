import React, {useState} from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../services/Commons";
import {
    ErrorMessageFieldCommonComponent
} from "../../../CommonComponents/ErrorMessageFieldCommonComponent";
import filterExpandIcon from "../../../../icons/Icon_arrows_carrot_teal-down.svg";
import filterCollapseIcon from "../../../../icons/Icon_arrows_carrot_teal-up.svg";
import moment from "moment";
import {CheckboxComponent} from "../../../CommonComponents/CheckboxComponent";

import {inject, observer} from "mobx-react";
import {applyOccasionDateSortingOrder} from "../../../../pages/CharitableGamingPages/TaxForm/TaxFormManagementPage/functions";

export const TaxFormManagementSearchBarComponent = inject("charitableGamingStore")(observer(({
                                                                                                 formik,
                                                                                                 currentTab,
                                                                                                 setFieldValue,
                                                                                                 clearSearch,
                                                                                                 handleCheckboxChange,
                                                                                                 applyOccasionDateSortingOrder,
                                                                                                 charitableGamingStore
                                                                                             }: any) => {

        const [showFilters, setShowFilters] = useState<boolean>(true);

        const activateSearchButton = (formik.isValid && formik.dirty);

        const avgIndicatorFilterTabs = ['weeklyBeano'];
        const showSubmittingOrgsFilterTabs = ['weeklyBeano'];
        const licenseIdSearchTabs = ['weeklyBeano', 'annualBeano', 'delinquencyReports'];
        const yearSearchTabs = ['annualBeano'];

        const OccasionDateSortingOrders = [
            'Newest First',
            'Oldest First'
        ];

        return (
            <>

                <div className={`${styles['pulltab-stock-inventory-page-search-bar-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-stock-inventory-page-search-bar-title']} ${styles['']}`}>Search
                    </div>
                    <div className={`${styles['pulltab-stock-inventory-page-search-bar-filter-container']} ${styles['']}`}>

                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title-bar']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title']} ${styles['']}`}>Filter
                                & Sort
                            </div>
                            <div tabIndex={0}
                                 className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}
                                 onKeyDown={(e: any) => {
                                     if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                         setShowFilters(!showFilters);
                                     }
                                 }}
                                 onClick={() => {
                                     setShowFilters(!showFilters);
                                 }}
                            >
                                <img
                                    src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                    className={styles[""]}
                                    alt="filter expander icon"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {showFilters ?
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-expanded-container']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-inner-container-1']}`}>
                                <div
                                    className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                    <div className={`${styles['mslc-label']} ${styles['']}`}>Sort by Occasion Date</div>
                                    <div className={`${styles.custom_select_div}`}>
                                        <select
                                            id="sortByOccasionDate"
                                            name="sortByOccasionDate"
                                            onChange={async (e: any) => {
                                                await setFieldValue(e);
                                                // formik.submitForm();
                                                applyOccasionDateSortingOrder();
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder="Select"
                                            className={styles["custom_select"]}>
                                            {
                                                OccasionDateSortingOrders.map((occasionDateSortingOrder: string) =>
                                                    <option
                                                        selected={formik.values.sortByOccasionDate.toLowerCase() === occasionDateSortingOrder.toLowerCase()}
                                                        value={occasionDateSortingOrder}>{occasionDateSortingOrder}
                                                    </option>)
                                            }
                                        </select>
                                        <span className={`${styles.custom_arrow}`}></span>
                                    </div>
                                </div>
                                <div className={`${styles['pulltab-stock-inventory-page-datetime-picker-container']}`}>
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                        <div className={`${styles['mslc-label']} ${styles['']}`}>Starting Occasion Date
                                        </div>
                                        <input
                                            className={`${styles['mslc-datetime-picker']}`}
                                            type={'date'}
                                            id="startOccasionDate"
                                            name="startOccasionDate"
                                            value={formik.values.startOccasionDate}
                                            onBlur={formik.handleBlur}
                                            onChange={(e: any) => {
                                                setFieldValue(e);
                                            }}
                                            max={moment().format('YYYY-MM-DD')}
                                            placeholder={'mm/dd/yyyy'}
                                        />
                                        {formik.touched.startOccasionDate && formik.errors.startOccasionDate ?
                                            <ErrorMessageFieldCommonComponent
                                                errorMessages={formik.errors.startOccasionDate}/> : null}
                                    </div>
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                        <div className={`${styles['mslc-label']} ${styles['']}`}>Ending Occasion Date</div>
                                        <input
                                            className={`${styles['mslc-datetime-picker']}`}
                                            type={'date'}
                                            id="endOccasionDate"
                                            name="endOccasionDate"
                                            value={formik.values.endOccasionDate}
                                            onBlur={formik.handleBlur}
                                            onChange={(e: any) => {
                                                setFieldValue(e);
                                            }}
                                            min={formik.values.startOccasionDate}
                                            max={moment().format('YYYY-MM-DD')}
                                            placeholder={'mm/dd/yyyy'}
                                            disabled={!formik.values.startOccasionDate}
                                        />
                                        {formik.errors.endOccasionDate ?
                                            <ErrorMessageFieldCommonComponent
                                                errorMessages={formik.errors.endOccasionDate}/> : null}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-inner-container-2']}`}>
                                {avgIndicatorFilterTabs.includes(currentTab) ?
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-checkbox-container']} ${styles['']}`}>
                                        <div tabIndex={0}
                                             className={`${styles['pulltab-stock-inventory-page-checkbox']} ${styles['']}`}
                                             style={{
                                                 cursor: "pointer"
                                             }}
                                             onKeyDown={(e: any) => {
                                                 if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                                     handleCheckboxChange('averageIndicatorOnly', formik);
                                                     formik.submitForm();
                                                 }
                                             }}
                                             onClick={(e: any) => {
                                                 handleCheckboxChange('averageIndicatorOnly', formik);
                                                 formik.submitForm();
                                             }}
                                        >
                                            <CheckboxComponent isChecked={formik.values.averageIndicatorOnly}/>
                                        </div>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-active']} ${styles['']}`}>Average
                                            Indicator Only
                                        </div>
                                    </div> : null}
                                {showSubmittingOrgsFilterTabs.includes(currentTab) ?
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-checkbox-container']} ${styles['']}`}>
                                        <div tabIndex={0}
                                             className={`${styles['pulltab-stock-inventory-page-checkbox']} ${styles['']}`}
                                             style={{
                                                 cursor: "pointer"
                                             }}
                                             onKeyDown={(e: any) => {
                                                 if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                                     handleCheckboxChange('showSubmittingOrgsOnly', formik);
                                                     formik.submitForm();
                                                 }
                                             }}
                                             onClick={(e: any) => {
                                                 handleCheckboxChange('showSubmittingOrgsOnly', formik);
                                                 formik.submitForm();
                                             }}
                                        >
                                            <CheckboxComponent isChecked={formik.values.showSubmittingOrgsOnly}/>
                                        </div>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-active']} ${styles['']}`}>Show
                                            Submitting Orgs Only
                                        </div>
                                    </div> : null}
                            </div>
                        </div> : null}

                    <div
                        className={`${styles['pulltab-stock-inventory-page-search-bar-input-button-container']} ${styles['']}`}>
                        {licenseIdSearchTabs.includes(currentTab) ?
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>License ID</div>
                                <input className={`${styles['mslc-textbox']}`}
                                       type="text"
                                       id="licenseNumber"
                                       name="licenseNumber"
                                       value={formik.values.licenseNumber}
                                       onBlur={formik.handleBlur}
                                       maxLength={5}
                                       onChange={(e: any) => {
                                           setFieldValue(e);
                                       }}/>
                                {formik.errors.licenseNumber ?
                                    <ErrorMessageFieldCommonComponent errorMessages={formik.errors.licenseNumber}/> : null}
                            </div> : <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>Raffle ID</div>
                                <input className={`${styles['mslc-textbox']}`}
                                       type="text"
                                       id="raffleId"
                                       name="raffleId"
                                       value={formik.values.raffleId}
                                       onBlur={formik.handleBlur}
                                       maxLength={6}
                                       onChange={(e: any) => {
                                           setFieldValue(e);
                                       }}/>
                                {formik.errors.raffleId ?
                                    <ErrorMessageFieldCommonComponent errorMessages={formik.errors.raffleId}/> : null}
                            </div>}
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Year (this search will override
                                selected date range)
                            </div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="taxYear"
                                   name="taxYear"
                                   value={formik.values.taxYear}
                                   onBlur={formik.handleBlur}
                                   maxLength={4}
                                   disabled={!yearSearchTabs.includes(currentTab)}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}/>
                            {formik.touched.taxYear && formik.errors.taxYear ?
                                <ErrorMessageFieldCommonComponent taxYear={formik.errors.taxYear}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>
                </div>

            </>
        );
    })
);