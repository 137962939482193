import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const ReactivateLicenseConfirmationComponent = ({
                                                           reactivateLicense,
                                                           setShowReactivateLicenseConfirmation
                                                       }: any) => {

    return (<>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["save-confirmation-dialog-main-container"]}>
                    <div
                        id="save-confirmation-dialog-container"
                        className={stylesMessageBox["save-confirmation-dialog-container"]}
                    >
                        <div
                            className={stylesMessageBox["save-confirmation-dialog-message"]}
                        >
                            Are you sure you would like to reactivate this license?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    reactivateLicense();
                                    setShowReactivateLicenseConfirmation(false);
                                }
                            }}
                            onClick={() => {
                                reactivateLicense();
                                setShowReactivateLicenseConfirmation(false);
                            }}
                            className={stylesMessageBox["save-confirmation-dialog-yes-button"]}
                        >
                            <div
                                className={stylesMessageBox["save-confirmation-dialog-yes-button-text"]}
                            >
                                Yes, Reactivate
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    setShowReactivateLicenseConfirmation(false);
                                }
                            }}
                            role="button"
                            onClick={() => {
                                setShowReactivateLicenseConfirmation(false);
                            }}
                            className={stylesMessageBox["save-confirmation-dialog-no-button"]}
                        >
                            Go Back
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>);
};