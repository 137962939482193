import React from "react";
import moment from "moment-timezone";
import {timeOfDayFormat} from "../../../constants";
import styles from './css/styles.module.css';
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";

export const ACHTaxBatchDetailHeaderComponent = ({batchDetailSearchResponse}: any) => {
    // const batchHeaderData = JSON.parse(batchDetailSearchResponse)
    // console.log('batchHeaderData', batchDetailSearchResponse[0]);
    let filecreationdatetime = "";
    let filesubmissiondatetime = "";
    let effectiveentrydate = "";
    {
        if (batchDetailSearchResponse.length > 0) {
            filecreationdatetime = batchDetailSearchResponse[0]?.filecreationdatetime
            filesubmissiondatetime = batchDetailSearchResponse[0]?.filesubmissiondatetime
            effectiveentrydate = batchDetailSearchResponse[0]?.effectiveentrydate
            // console.log('batchHeaderData1', batchDetailSearchResponse[0]);
        }
    }

    return (
        <>
            <div
                className={`${styles["page-container-body-left-label-and-input-box-container"]}`}>
                <div className={`${styles["page-container-body-header"]}`}>
                    <div>File Creation Date</div>
                    <div>File Creation Time</div>
                    <div>Date Transmitted</div>
                    <div>Time Transmitted</div>
                    <div>Effective Entry Date</div>
                    <div>Status</div>
                    <div>Amount</div>
                    <div>{filecreationdatetime === undefined ? "" : moment.tz(filecreationdatetime, "America/New_York").format('MM-DD-YYYY')}</div>
                    <div>{filecreationdatetime === undefined ? "" : moment.tz(filecreationdatetime, "America/New_York").format(timeOfDayFormat)}</div>
                    <div>{filesubmissiondatetime === undefined ? "" : moment.tz(filesubmissiondatetime, "America/New_York").format('MM-DD-YYYY')}</div>
                    <div>{filesubmissiondatetime === undefined ? "" : moment.tz(filesubmissiondatetime, "America/New_York").format(timeOfDayFormat)}</div>
                    <div>{effectiveentrydate === undefined ? "" : moment(effectiveentrydate).format('MM-DD-YYYY')}</div>
                    <div>{batchDetailSearchResponse[0]?.status === undefined ? "" : batchDetailSearchResponse[0]?.status}</div>
                    <div>{batchDetailSearchResponse[0]?.amount === undefined ? "" : normilizeAmount(batchDetailSearchResponse[0].amount)}</div>
                </div>
            </div>
        </>
    );
}