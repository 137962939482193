import { PlayersPagesInterfaces } from "../../application.interfaces/application.pages.interfaces";
import { ManualKYCPageComponetsInterface } from "../../pages/PlayersPages/ManualKYCPage/interfaces";

import { PLAYERS_COMPONENTS } from './../componets.data-structure';
import { PlayersSupportHubPageComponentInterface, TabsPlayersSupportHubPageInterface } from "../../pages/PlayersPages/PlayersSupportHubPage/interfaces";
import { PlayerDetailsPageComponentInterface } from "../../pages/PlayersPages/TabsPlayersSupportHubPage/PlayerDetailsPage/interfaces";
import { UpdateProfilePageComponentsInterface } from "../../pages/PlayersPages/UpdateProfilePage/interfaces";



/**
 * @Page
 * @path v1\Code\src\pages\PlayersPages\UpdateProfilePage
 * 
 * @Components
 * @path v1\Code\src\components\PlayersComponents\UpdateProfileComponent
 * @path v1\Code\src\components\PlayersComponents\DisplayDocumentNewComponent
 */
export const UPDATE_PROFILE_PAGE:UpdateProfilePageComponentsInterface ={
          UpadteProfileComponent:PLAYERS_COMPONENTS.UPDATE_PROFILE_COMPONENT,
          DisplayDocumentNewComponent:PLAYERS_COMPONENTS.DISPLAY_DOCUMENT_NEW_COMPONENT,         
    
}



export const PLAYER_DETAILS_PAGE :PlayerDetailsPageComponentInterface ={
     PlayerEmailAndPhoneComponent:PLAYERS_COMPONENTS.PLAYERS_EMAIL_AND_PHONE_COMPONENT,
     PlayerStatusComponent:PLAYERS_COMPONENTS.PLAYER_STATUS_COMPONENT,
     PlayerProfileComponent:PLAYERS_COMPONENTS.PLAYER_PROFILE_COMPONENT,
}

export const  TABS_PLAYERS_SUPPORT_HUB_PAGE:TabsPlayersSupportHubPageInterface = {
     PlayerDetailsPage:PLAYER_DETAILS_PAGE
}

/**
 * @Page 
 * @path v1\Code\src\pages\PlayersPages\PlayersSupportHubPage
 * 
 * @Components
 * @path v1\Code\src\components\PlayersComponents\PlayerIDAndNameComponent
 * @path v1\Code\src\components\PlayersComponents\DisplayDocumentNewComponent
 * 
 * @Tabs
 * 
 * @Tab
 * @path  v1\Code\src\pages\PlayersPages\Tabs\PlayerDetailsPage
 * 
 * @TabComponents
 * @path v1\Code\src\components\PlayersComponents\PlayersEmailAndPhoneComponent
 * @path v1\Code\src\components\PlayersComponents\PlayerStatusComponent
 * @path v1\Code\src\components\PlayersComponents\PlayerProfileComponent
 */

export const PLAYERS_SUPPORT_HUB_PAGE: PlayersSupportHubPageComponentInterface ={
           PlayerIDAndNameComponent:PLAYERS_COMPONENTS.PLAYER_ID_AND_NAME_COMPONENT,
           DisplayDocumentNewComponent:PLAYERS_COMPONENTS.DISPLAY_DOCUMENT_NEW_COMPONENT,
           TabsPlayersSupportHubPage:TABS_PLAYERS_SUPPORT_HUB_PAGE
    
}

/**
 * @Page 
 * @path v1\Code\src\pages\PlayersPages\ManualKYCPage 
 * 
 * @Components
 * @path v1\Code\src\components\PlayersComponents\DisplayDocumentNewComponent
 * @path v1\Code\src\components\PlayersComponents\UpdateProfileComponent
 * @path v1\Code\src\components\PlayersComponents\PlayerStatusComponent
 * @path v1\Code\src\components\PlayersComponents\IdentityDocumentsComponent
 * 
 */

export const MANUAL_KYC_PAGE:ManualKYCPageComponetsInterface = {

        DisplayDocumentNewComponent: PLAYERS_COMPONENTS.DISPLAY_DOCUMENT_NEW_COMPONENT,
        UpadteProfileComponent: PLAYERS_COMPONENTS.UPDATE_PROFILE_COMPONENT,
        PlayerStatusComponent: PLAYERS_COMPONENTS.PLAYER_STATUS_COMPONENT,
        IdentityDocumentsComponent:PLAYERS_COMPONENTS.IDENTITY_DOCUMENTS_COMPONENT

}

/**
 * @Pages
 * @path v1\Code\src\pages\PlayersPages 
 * 
 * @TODO
 * PLAYERS_SUPPORT_NEW_COMPONENT_PAGE
 * SEARCH_NEW_PAGE
 * 
 * 
 * @Page ManualKYCPage
 * @path v1\Code\src\pages\PlayersPages\ManualKYCPage 
 * 
 * @Components
 * @path v1\Code\src\components\PlayersComponents\DisplayDocumentNewComponent
 * @path v1\Code\src\components\PlayersComponents\UpdateProfileComponent
 * @path v1\Code\src\components\PlayersComponents\PlayerStatusComponent
 * @path v1\Code\src\components\PlayersComponents\IdentityDocumentsComponent
 * 
 * 
 * @Page PlayersSupportHubPage
 * @path v1\Code\src\pages\PlayersPages\PlayersSupportHubPage
 * 
 * @Components
 * @path v1\Code\src\components\PlayersComponents\PlayerIDAndNameComponent
 * @path v1\Code\src\components\PlayersComponents\DisplayDocumentNewComponent
 * 
 * @Tabs
 * 
 * @Tab
 * @path  v1\Code\src\pages\PlayersPages\TabsPlayersSupportHubPage\PlayerDetailsPage
 * 
 * @TabComponents
 * @path v1\Code\src\components\PlayersComponents\PlayersEmailAndPhoneComponent
 * @path v1\Code\src\components\PlayersComponents\PlayerStatusComponent
 * @path v1\Code\src\components\PlayersComponents\PlayerProfileComponent
 * 
 * @Page UpdateProfilePage
 * @path v1\Code\src\pages\PlayersPages\UpdateProfilePage
 * 
 * @Components
 * @path v1\Code\src\components\PlayersComponents\UpdateProfileComponent
 * @path v1\Code\src\components\PlayersComponents\DisplayDocumentNewComponent
 * 
 */

export const PLAYERS_PAGES:PlayersPagesInterfaces={
      MANUAL_KYC_PAGE:MANUAL_KYC_PAGE,
      PLAYERS_SUPPORT_HUB_PAGE:PLAYERS_SUPPORT_HUB_PAGE,
      PLAYERS_SUPPORT_NEW_COMPONENT_PAGE: "PLAYERS_SUPPORT_NEW_COMPONENT_PAGE",
      SEARCH_NEW_PAGE: "SEARCH_NEW_PAGE",
      UPDATE_PROFILE_PAGE:UPDATE_PROFILE_PAGE,

}