import React, { useEffect, useState } from "react"
import { FormPayeeDetails, SplitPaymentRequest } from "../../../../pages/PaymentsPages/Create5754SplitPage/types"
import { Create5754SplitTitleBarUI } from "../create-5754-split-title-bar/create-5754-split-title-bar-ui"
import './styles.scss'
import { BackButtonUI } from "../../../CommonComponents/BackButtonComponent/back-button-ui"
import { Create5754SplitChildPaymentsFormContainer } from "../create-5754-split-child-payments-form/create-5754-split-child-payments-form-container"
import { ExecuteDeleteSplit } from "../create-5754-split-child-payments-form/create-5754-split-child-payments-form-ui"
import { GenericErrorDialogComponent } from "../../../CommonComponents/GenericErrorDialogComponent"
import { formatDollarAmount } from "../../../../util"

export type DeleteSplitProps = {
  showPopup: boolean
  childIndex: number | null
}

export type Create5754SplitContainerUIProps = {
  splitPaymentError: string | undefined
  parentPaymentId: string
  childPayments: FormPayeeDetails[]
  originalGrossInDollars: number
  splitLoading: boolean
  fetchSplitPayment: (request: SplitPaymentRequest) => Promise<void>
}

const ROOT_CLASS = 'create-5754-split-page'
export const Create5754SplitContainerUI = (props: Create5754SplitContainerUIProps) => {
  // Props
  const { 
    splitPaymentError,
    childPayments, 
    parentPaymentId, 
    originalGrossInDollars,
    fetchSplitPayment
  } = props 

  // State
  const [ showSubmitSplitPopup, setShowSubmitSplitPopup ] = useState<boolean>(false)
  const [ submitButtonEnabled, setSubmitButtonEnabled ] = useState<boolean>(true)
  const [ showErrorPopup, setShowErrorPopup ] = useState<boolean>(false)
  const [oldSplitLoading, setOldSplitLoading] = useState(false)
  if(props.splitLoading !== oldSplitLoading) {
    if (splitPaymentError) {
      setShowErrorPopup(true)
    } else {
      setShowErrorPopup(false)
    }
    setOldSplitLoading(props.splitLoading)
  }

  // Render
  return (
    <div className={ ROOT_CLASS }>
      <BackButtonUI 
        tabIndex={ 0 } 
        message={ 'Back to Payment Details' }
      />

      <Create5754SplitTitleBarUI
        setShowSubmitSplitPopup={ setShowSubmitSplitPopup }
        submitButtonEnabled={ submitButtonEnabled }
      />

      <Create5754SplitChildPaymentsFormContainer
        childPayments={ childPayments }
        parentPaymentId={ parentPaymentId }
        originalGrossInDollars={ formatDollarAmount(originalGrossInDollars, false) }
        showSubmitSplitPopup={ showSubmitSplitPopup }
        setSubmitButtonEnabled={ setSubmitButtonEnabled }
        setShowSubmitSplitPopup={ setShowSubmitSplitPopup }
        fetchSplitPayment={ fetchSplitPayment }    
      />

      {showErrorPopup ? 
        <GenericErrorDialogComponent 
          setShowErrorDialog={ setShowErrorPopup }          
        /> : null
      } 
    </div>
  )
}