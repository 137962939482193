export enum Agent1099SweepType {
  CALENDAR = 'CALENDAR',
  TARGETED = 'TARGETED'
}

export type Agent1099AggregatesSearchRequestBody = {
  tin?: string
  year?: string
}

export type Agent1099AggregatesSearchResponse = {
  aggregates: Agent1099Aggregate[]
  additionalResults: boolean
}

export type Agent1099Aggregate = {
  tin: string
  year: string
  chainNumber: string
  taxName: string
  address: string
  city: string
  reportableAmountInCents: number
  digitalConsent: boolean
  queued: boolean
}

export type Agent1099AddToSweepRequest = {
  tin: string
  year: string
}

export type Agent1099RemoveFromSweepRequest = {
  tin: string
  year: string
  sweepId: string
}

export type Agent1099OpenSweepsResponse = {
  sweeps: Agent1099OpenSweep[]
}

export type Agent1099OpenSweep = {
  sweepId: string
  displayName: string
  sweepType: Agent1099SweepType
}

export type Agent1099SweepPaymentsResponse = {
  payments: Agent1099SweepPayment[]
  printable: boolean
}

export type Agent1099SweepPayment = {
  tin: string
  year: string
  name: string
  bonusInCents: number
  commissionInCents: number
  reportableInCents: number
  address: string
  city: string
  state: string
  zip: string
  physicalPrint: boolean
  digitalPrint: boolean
}

export type Agent1099DataYear = {
  year: string
  printed: boolean
}

export type Agent1099DataYearsResponse = {
  years: Agent1099DataYear[]
}
