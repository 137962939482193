import React, { useEffect, useState } from "react";
import styles from "./css/styles.module.css";
import {
  Switch,
  Route,
  useRouteMatch,
  Router,
  withRouter,
  useHistory,
} from "react-router-dom";
import { PrivateRoute } from "./../../routes.js";
import {
  playerSupportRoutePath,
  actionsRoutePath,
  claimsSupportRoutePath,
} from "./../../config.js";

import HeaderComponent from "../../components/HomeComponent/HeaderComponent";
import MenuContainerComponent from "../../components/HomeComponent/MenuComponent/MenuContainerComponent";

import PlayersSupportRoutesPage from "../PlayersPages/PlayersSupportRoutesPage";
import ActionsRoutesPage from "../ActionsPages/ActionsRoutesPage";
import ClaimsSupportRoutesPage from "../ClaimsPages/ClaimsSupportRoutesPage";
import { permissions } from "./../../constants.js";
import { checkRenderPermissions } from "./../../helpers.js";

export const Forbidden409Page = () => {
  return (
    <>
      <div className={styles["page-container"]}>
        <div className={styles["page-text"]}>
          You do not have permission to view this page! Please contact your
          System Administrator!
        </div>
      </div>
    </>
  );
};
