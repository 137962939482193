import React, { SetStateAction } from "react";
import { TimePaymentAccountPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { SubAccountTreeNode } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { PaymentScheduleEntry } from "../../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../../types/Annuities/responses/time-payment-account";
import { AnnuityChildrenSubAccountComponent } from "../AnnuityChildrenSubAccountComponent";
import { AnnuityPrimarySubAccountComponent } from "../AnnuityPrimarySubAccountComponent";

export const SubAccountComponent: React.FunctionComponent<{
  treeRoot: SubAccountTreeNode;
  isPrimary: boolean;
  widthOffset: number;
  tpaPayeeList: TimePaymentAccountPayee[];
  tpaDetails: TimePaymentAccountResponse;
  tpaPaymentSchedule: PaymentScheduleEntry[];
  isPageInEditModeObj: {
    isPageInEditMode: boolean;
    setIsPageInEditMode: React.Dispatch<SetStateAction<boolean>>;
  };
  parentIdentifier: string;
}> = ({
  treeRoot,
  isPrimary,
  widthOffset,
  tpaPayeeList,
  tpaDetails,
  tpaPaymentSchedule,
  isPageInEditModeObj,
  parentIdentifier,
  children,
}) => {
  if (isPrimary) {
    return (
      <>
        <AnnuityPrimarySubAccountComponent
          treeRoot={treeRoot}
          tpaPayeeList={tpaPayeeList}
          tpaDetails={tpaDetails}
          tpaPaymentSchedule={tpaPaymentSchedule}
          isPageInEditModeObj={isPageInEditModeObj}
        />
        {children}
      </>
    );
  } else {
    return (
      <>
        <AnnuityChildrenSubAccountComponent
          treeRoot={treeRoot}
          widthOffset={widthOffset}
          tpaPayeeList={tpaPayeeList}
          tpaDetails={tpaDetails}
          tpaPaymentSchedule={tpaPaymentSchedule}
          isPageInEditModeObj={isPageInEditModeObj}
          parentIdentifier={parentIdentifier}
        />
        {children}
      </>
    );
  }
};
