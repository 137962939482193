import * as React from 'react'
import moment from 'moment'
import { useFormik } from 'formik'
import { PromotionsHourlyReportUIProps } from '../promotions-hourly-report/promotions-hourly-report-ui'
import { CustomDropdownField } from '../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { getBlockClass } from '../../../utilities/helpers'
import { DateAndTimeInput } from '../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import { LoadingDots } from '../../CommonComponents/loading-dots/loading-dots-ui'
import { PromotionsHourlyReportProps } from '../../../api/promotions-fetcher/constants'
import './styles.scss'

export type PromotionsHourlyReportFilterUIProps = PromotionsHourlyReportUIProps

const FORM_CLASS = 'promotions-hourly-report-filter-ui'

const validate = (values: PromotionsHourlyReportProps) => {
  const errors: Record<string, string> = {}
  if (!values.selectedPromotion) {
    errors.selectedPromotion = 'A promotion needs to be selected'
  }
  if (!values.reportDate) {
    errors.reportDate = 'Report Date should be set'
  }
  return errors
}

export const PromotionsHourlyReportFilterUI = (
  props: PromotionsHourlyReportFilterUIProps
) => {
  const initialValues: PromotionsHourlyReportProps = {
    selectedPromotion: props.promotions?.length ? props.promotions[0].id : '',
    reportDate: '',
  }
  const promotionOptions =
    props.promotions?.map((promotion) => {
      return {
        key: promotion.id,
        value: promotion.id,
        display: promotion.name,
      }
    }) ?? []

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => props.generateReport(values),
    validate: validate,
  })

  return (
    <section className={FORM_CLASS}>
      <header className={getBlockClass(FORM_CLASS, 'header')}>Filter</header>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className={getBlockClass(FORM_CLASS, 'container')}
      >
        <div className={getBlockClass(FORM_CLASS, 'form-row')}>
          <div className={getBlockClass(FORM_CLASS, 'form-item')}>
            <label
              className={getBlockClass(FORM_CLASS, 'label')}
              htmlFor="selectedPromotion"
            >
              Select Promotion
            </label>
            <CustomDropdownField
              onChange={formik.handleChange}
              value={formik.values.selectedPromotion}
              onBlur={formik.handleBlur}
              id="promotion-dropdown"
              name="selectedPromotion"
              options={promotionOptions}
              disabled={props.reportLoading}
            />
          </div>
          <div className={getBlockClass(FORM_CLASS, 'form-item')}>
            <DateAndTimeInput
              label="Select Date"
              name="reportDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reportDate}
              touched={formik.touched.reportDate}
              disabled={props.reportLoading}
              error={undefined}
            />
          </div>
        </div>
        <div className={getBlockClass(FORM_CLASS, 'form-row')}>
          <div className={getBlockClass(FORM_CLASS, 'form-item')}>
            <button
              type="submit"
              className={getBlockClass(FORM_CLASS, 'generate-button')}
              aria-label="Generate"
              disabled={!formik.dirty || !formik.isValid || props.reportLoading}
            >
              {props.reportLoading ? (
                <div aria-busy={props.reportLoading} aria-describedby="loading">
                  <LoadingDots id="loading" color="white" />
                </div>
              ) : (
                'Generate'
              )}
            </button>
          </div>
          <div className={getBlockClass(FORM_CLASS, 'form-item')}>
            <button
              type="button"
              className={getBlockClass(FORM_CLASS, 'clear-button')}
              aria-label="Clear"
              disabled={props.reportLoading}
              onClick={() => {
                formik.resetForm()
                props.resetData()
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}
