import React, {useState} from "react";
import styles from './css/styles.module.css';
import {addTaxes, getPageData,} from "./functions";
import {PaginationCommonComponent} from '../../CommonComponents/PaginationCommonComponent'
import {keyPressedType} from "../../../services/Commons";
import moment from "moment-timezone";
import {TaxBatchDetailSearchResultInterface} from "../../../pages/FinancePages/ACHTaxBatchDetailsPage/interfaces";

export const ACHTaxBatchDetailTable = ({KYCPlayerData}: any) => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;
    }
    const createPages = (MCAPlayerData: any[], currentPage: any, setCurrentPage: any) => {
        const array: any = [];
        const pagesTotal = Math.ceil(MCAPlayerData.length / 10);

        if (currentPage > 2 && pagesTotal > 5) {
            array.push(
                <>
                    <div aria-live="assertive"
                         tabIndex={0} role='button'
                         aria-pressed={currentPage === 0 ? "true" : "false"}
                         style={{backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : ""}}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(0);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(0)} className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {1}
                        </div>
                    </div>
                    <div>{'...'}</div>
                </>
            );
        }
        if (currentPage <= 2 || pagesTotal <= 5) {

            for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
                array.push(
                    <div aria-live="assertive" tabIndex={0} role='button'
                         aria-pressed={currentPage === i ? "true" : "false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(i);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }
        } else if (currentPage > 2 && currentPage <= (pagesTotal - 1) - 2) {
            for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
                array.push(
                    <div
                        aria-live="assertive"
                        tabIndex={0}
                        role='button'
                        aria-pressed={currentPage === i ? "true" : "false"}
                        onKeyPress={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                setCurrentPage(i);
                            }
                        }
                        }
                        onClick={() => setCurrentPage(i)}
                        style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                        className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }
        } else if (currentPage > (pagesTotal - 1) - 2) {
            for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
                array.push(
                    <div aria-live="assertive" tabIndex={0} role='button'
                         aria-pressed={currentPage === i ? "true" : "false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(i);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }

        }
        if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
            array.push(
                <>
                    <div>{'...'}</div>
                    <div aria-live="assertive" tabIndex={0} role='button'
                         aria-pressed={currentPage === pagesTotal ? "true" : "false"}
                         style={{backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : ""}}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(pagesTotal - 1);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {pagesTotal}
                        </div>
                    </div>
                </>
            );
        }
        return array;
    }

    return (
        <>
            <div className={styles['KYCQUEUE-table']}>
                <div className={styles['KYCQUEUE-table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${KYCPlayerData.length <= 0 ? 0 : (currentPage * 10) + 1}-
                         ${normalizePage(KYCPlayerData.length, currentPage)} of ${KYCPlayerData.length} results`}</div>
                </div>
                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date Submitted</div>
                    <div tabIndex={0}>Time</div>
                    <div tabIndex={0}>Claim ID</div>
                    <div tabIndex={0}>Transaction ID</div>
                    <div tabIndex={0}>Tax Amount</div>
                    <div tabIndex={0}>Status</div>
                    <div tabIndex={0}>Trace Number</div>
                </div>
                <div className={styles['KYCQUEUE-table-data-body']}>

                    {KYCPlayerData.length > 0 && getPageData(KYCPlayerData, currentPage).map((e: TaxBatchDetailSearchResultInterface, i: number) =>
                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)')}}
                             className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{(moment.tz(e.timestamp, "America/New_York").format('MM-DD-YYYY'))}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.timestamp, "America/New_York").format('HH:mm')}</div>

                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.claimid}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.transactionid}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{addTaxes(e.tax)}</div>

                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.achpaymenttransactionstatus}</div>

                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.tracenumber}</div>

                        </div>
                    )}
                </div>
                <div className={styles['KYCQUEUE-table-data-footer']}>

                    <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((KYCPlayerData.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={KYCPlayerData} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}
                </div>
            </div>


        </>

    );
}
