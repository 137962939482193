import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {licenseeRoutePath} from "../../../../../config.js";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";

export const ViewHeadOfOrganizationDetailsComponent = ({licenseeDetails}:any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    useEffect(() => {

    });

    return (
        <>

            <div className={`${styles['view-head-of-org-details-container']}`}>

                <div className={`${styles['view-head-of-org-details-container-title-container']}`}>
                    <div className={`${styles['view-head-of-org-details-container-title']}`}>Head of Organization</div>
                    {checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                        <div tabIndex={0} className={`${styles['view-head-of-org-details-container-update-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     history.push(`${licenseeRoutePath}/update/head-of-org/${licenseeDetails.licenseDetails.licenseNumber}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${licenseeRoutePath}/update/head-of-org/${licenseeDetails.licenseDetails.licenseNumber}`);
                             }}>Update
                        </div> : null}
                </div>


                <div className={`${styles['view-head-of-org-details-controls-container']}`}>

                    <div className={`${styles['view-head-of-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Name</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.fullName}</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Home Phone</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.homePhone}</div>

                    <div className={`${styles['view-head-of-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Mailing Address</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.address.streetAddress}</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Business Phone</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.businessPhone}</div>

                    <div className={`${styles['view-head-of-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.address.city}</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Email Address</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.email}</div>

                    <div className={`${styles['view-head-of-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode</div>
                    <div className={`${styles['view-head-of-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.headOfOrg.address.zip}</div>

                </div>

            </div>


        </>

    );
};