import React from 'react';

function Notfound() {
  return (    
    <div>	
      Page Not found 
    </div>
  );
}

export default Notfound;
