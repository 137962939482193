import FocusTrap from "focus-trap-react"
import React from "react"
import './styles.scss'
import { DeleteSplitProps } from "../../create-5754-split-container/create-5754-split-container-ui"

export const defaultDeleteSplitProps = () => {
  return {
    showPopup: false,
    childIndex: null
  }
}

export type DeleteSplitConfirmationUIProps = {
  dsiplayIndex: number
  deleteSplit: () => void
  setDeleteSplitProps: (deleteSplitProps: DeleteSplitProps) => void
}

const ROOT_CLASS = 'delete-5754-split-confirmation-container'
export const DeleteSplitConfirmationUI = (props: DeleteSplitConfirmationUIProps) => {
  const { dsiplayIndex, deleteSplit, setDeleteSplitProps } = props
  return (
    <FocusTrap 
      focusTrapOptions={{initialFocus: false}}
    >
      <div className={ ROOT_CLASS }>
        <div className={ 'wrapper' }>
          <div className={ 'message' }>
            Are you sure you want to delete Payee #{ dsiplayIndex }?
          </div>
          <button 
            className={ 'delete-button' }
            onClick={() => {
              deleteSplit()
              setDeleteSplitProps(defaultDeleteSplitProps())
            }}
          >
            Confirm
          </button>
          <button 
            className={ 'go-back-button' }
            onClick={() => {
              setDeleteSplitProps(defaultDeleteSplitProps())
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </FocusTrap>
  )
}