let licenseNumber: string;
let formik: any;
let setRequestCompleted: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberString: string, formikObj: any, setRequestCompletedObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberString;
    formik = formikObj;
    setRequestCompleted = setRequestCompletedObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const handleCheckboxChange = (checkboxname: string) => {

    if (checkboxname === 'renewing') {
        formik.setFieldValue(checkboxname, !formik.values.renewing, true);
    } else if (checkboxname === 'submitting') {
        formik.setFieldValue(checkboxname, !formik.values.submitting, true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    //alert(JSON.stringify(values));
}

export const downloadLicenseFile = async () => {
    charitableGamingStore.downloadLicenseFile(licenseNumber, setRequestCompleted);
}

export const reactivateLicense = async () => {
    charitableGamingStore.reactivateLicense(licenseNumber, setRequestCompleted);
}

export const toggleLicenseRenewalStatus = async function () {
    charitableGamingStore.toggleLicenseRenewalStatus(licenseNumber, setRequestCompleted);
}

export const toggleLicenseSubmissionStatus = async function () {
    charitableGamingStore.toggleLicenseSubmissionStatus(licenseNumber, setRequestCompleted);
}