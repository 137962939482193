import { FormikValues } from "formik";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import config from "../../../../config";
import moment from "moment";
import { PaymentResponse } from "../../../../types/Annuities/TimePaymentAuthorization/PaymentResponse";

const validate = (values: FormikValues) => {
  const errors = {};

  if (values.fromDate === "") {
    Object.assign(errors, { fromDate: "This field is required" });
  }

  if (values.throughDate === "") {
    Object.assign(errors, { throughDate: "This field is required" });
  }

  if (values.fromDate !== "" && moment(values.throughDate).isBefore(moment(values.fromDate))) {
    Object.assign(errors, { throughDate: "Date must be after From Date" });
  }

  return errors;
};

const fetchLastThroughDate = async (): Promise<string> => {
  interface CheckRunLastThroughDate {
    throughDate: string;
    id: string;
    createdOn: string;
  }

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/`,
  };

  let checkRunHistory: CheckRunLastThroughDate[];

  try {
    const response = await makeHttpCall(options);
    checkRunHistory = response;

    if (checkRunHistory.length === 0) {
      return "No Last Through Date";
    } else {
      // convert createdOn from string to Date object to run sorting method
      const convertedCheckRunHistory = checkRunHistory.map(obj => {
        return { ...obj, createdOn: new Date(obj.createdOn) };
      });

      // sort array
      convertedCheckRunHistory.sort((objA, objB) => Number(objB.createdOn) - Number(objA.createdOn));

      // populate through Date
      const throughDate = moment(convertedCheckRunHistory[0].throughDate).format("MM/DD/YYYY");

      return throughDate;
    }
  } catch (error) {
    throw new Error("Something went wrong Getting Last Through Date");
  }
};

const getPaymentList = async (fromDate: string, throughDate: string): Promise<PaymentResponse[]> => {
  let paymentList: PaymentResponse[];
  const fromDateParam = fromDate !== "" ? `fromDate=${fromDate}` : undefined;
  const throughDateParam = throughDate !== "" ? `throughDate=${throughDate}` : undefined;
  const params = [fromDateParam, throughDateParam].filter(x => x).join("&");
  const url = params === "" ? `${config.SERVER_BASE_URL}/v1/annuities/payment/list/` : `${config.SERVER_BASE_URL}/v1/annuities/payment/list?${params}`;

  const options = {
    method: "get",
    url: `${url}`,
  };

  try {
    const response: PaymentResponse[] = await makeHttpCall(options);
    // convert createdOn from string to Date object to run sorting method
    const convertedCheckRunData = response.map((payment: PaymentResponse) => {
      return { ...payment, paymentDate: new Date(payment.paymentDate) };
    });

    // sort array
    convertedCheckRunData.sort((objA, objB) => Number(objA.paymentDate) - Number(objB.paymentDate));

    paymentList = convertedCheckRunData;
  } catch (error) {
    throw new Error("Something went wrong Fetching Payment List");
  }

  return paymentList;
};

// Csv
const exportToCsv = (paymentData: PaymentResponse[], csvExport: any) => {
  // Format currency strings
  const currencyFormatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  });

  // create new object per Authorization Data to produce csv
  const rows: any[] = Object.values(paymentData).map((payment: PaymentResponse) => {
    const newData = {
      documentId: payment.documentId,
      name: payment.name,
      tin: payment.tin,
      cadence: payment.payFrequency,
      anniversaryDate: moment(payment.anniversaryDate).format("MM/DD/YYYY"),
      paymentDate: moment(payment.paymentDate).format("MM/DD/YYYY"),
      grossPayment: currencyFormatter.format(payment.paymentAmount / 100),
      irsWithholding: currencyFormatter.format(payment.irsWithholding / 100),
      dorWithholding: currencyFormatter.format(payment.dorsWithholding / 100),
      netPayment: currencyFormatter.format((payment.paymentAmount - payment.irsWithholding - payment.dorsWithholding) / 100),
      lifetimePayment: payment.lifetimePayment,
      status: payment.paymentStatus
    };

    return newData;
  });

  csvExport.generateCsv(rows);
};

export { validate, fetchLastThroughDate, getPaymentList, exportToCsv };
