import React from "react";
import { SourceGuideEntry, SourceGuideUI, SourceGuideUIProps } from "../../SourceGuideComponent";

const reissueQueueSourceGuideEntries : SourceGuideEntry[] = [
  {
      abbreviation: 'AN',
      fullName: 'Annuities'
  },
  {
      abbreviation: 'CC',
      fullName: 'Claim Center'
  },
  {
      abbreviation: 'PR',
      fullName: 'Promotions'
  },
  {
      abbreviation: 'ST',
      fullName: 'Season Tickets'
  },
  {
      abbreviation: 'OTR',
      fullName: 'Other'
  }
]

export const ReissueQueueSourceGuideUI = (props: Omit<SourceGuideUIProps, 'entries'>) => {
  return <SourceGuideUI
      setPaymentSourceGuide={props.setPaymentSourceGuide}
      entries={reissueQueueSourceGuideEntries}/>
}