import * as React from 'react'
import { useFormik } from 'formik'
import { CircularProgress } from '@material-ui/core'
import {
  BarcodeInfo,
  CampaignFile,
} from '../../../../api/promotions-fetcher/coupon-campaigns/constants'
import { CircleInfo } from '../../../../icons/circle-info'
import {
  DEFAULT_EXPRESS_MAX_PAYLOAD_SIZE_BYTES,
  FormFileUpload,
} from '../../../FormFileUploadComponent/form-file-upload-component-ui'
import { CampaignsSideBarSubmitSection } from '../campaigns-side-bar-submit-section/campaigns-side-bar-submit-ui'
import { playerFileNameValidator } from '../side-bar-validation-functions'
import {
  getBarcodesStatusIcon,
  getBarcodeText,
  getFileStatusIcon,
} from './campaign-side-bar-players-helpers'
import { PlayerFileNamingToolTip } from './players-naming-tool-tip'
import './styles.scss'

const ROOT_CLASS = 'campaign-sidebar-barcode-details'
const DISCLAIMER_CLASS = `${ROOT_CLASS}__disclaimer`
const FIELD_CLASS = `${ROOT_CLASS}__field`

export type CampaignSideBarPlayersProps = {
  closeSideBar: () => void
  disabled: boolean
  campaignIdentifier?: string
  barcodes?: BarcodeInfo
  error?: string
  submissionLoading: boolean
  requestSuccessful: boolean
  submitPlayerFiles: (
    campaignIdentifier: string,
    playerFiles: File[]
  ) => Promise<void>
  fileStatus?: CampaignFile[]
  fileStatusLoading: boolean
  fileStatusError?: string
  fileStatusSuccessful: boolean
}

const DisabledDisclaimer = () => (
  <div className={`${DISCLAIMER_CLASS}`}>
    <div className={`${DISCLAIMER_CLASS}__icon`}>
      <CircleInfo />
    </div>
    <p className={`${DISCLAIMER_CLASS}__text`}>
      Complete the top form to enable player file uploads.
    </p>
  </div>
)

const LoadingBlock = () => (
  <div
    className={`${FIELD_CLASS}__loading`}
    aria-busy={true}
    aria-describedby="progress"
  >
    <CircularProgress
      id="progress"
      style={{ color: '#1e4b75' }}
      size={'50px'}
      thickness={3}
    />
  </div>
)

const ErrorBlock = (props: { error: string }) => (
  <div className={`${FIELD_CLASS}__error`}>{props.error}</div>
)

const PlayerFileBlock = (props: { file: CampaignFile }) => (
  <div className={`${FIELD_CLASS}__data`}>
    <img
      className={`${FIELD_CLASS}__icon`}
      src={getFileStatusIcon(props.file.fileStatus).toString()}
    />
    <p className={`${FIELD_CLASS}__text`}>{props.file.fileName}</p>
  </div>
)

const playerFilesName = 'playerAssignmentFiles'

interface FormValues {
  playerAssignmentFiles: File[]
}

export const CampaignSideBarPlayers = (props: CampaignSideBarPlayersProps) => {
  const initialValues: FormValues = {
    playerAssignmentFiles: [],
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (values.playerAssignmentFiles.length === 0) {
        formik.setFieldError(
          playerFilesName,
          'At least one file must be provided'
        )
        return
      }
      const existingFiles = props.fileStatus?.map((file) => file.fileName) ?? []
      if (
        existingFiles.some((fileName) =>
          values.playerAssignmentFiles.find((file) => file.name === fileName)
        )
      ) {
        formik.setFieldError(
          playerFilesName,
          'File names must be different from existing files'
        )
        return
      }
      await props.submitPlayerFiles(
        props.campaignIdentifier ?? '',
        values.playerAssignmentFiles
      )
    },
  })

  React.useEffect(() => {
    if (props.requestSuccessful) {
      formik.resetForm()
    }
  }, [props.requestSuccessful])

  const isFormDisabled =
    props.disabled ||
    props.submissionLoading ||
    !props.campaignIdentifier ||
    (props.barcodes?.barcodesAvailable ?? 0) === 0

  return (
    <div className={ROOT_CLASS}>
      <hr />
      {props.disabled && <DisabledDisclaimer />}
      <div className={`${FIELD_CLASS}`}>
        <div className={`${FIELD_CLASS}__title`}>Barcodes Attached</div>
        <div className={`${FIELD_CLASS}__data`}>
          <img
            className={`${FIELD_CLASS}__icon`}
            src={getBarcodesStatusIcon(props.barcodes).toString()}
          />
          <p className={`${FIELD_CLASS}__text`}>
            {getBarcodeText(props.barcodes)}
          </p>
        </div>
      </div>
      <div className={`${FIELD_CLASS}`}>
        <div className={`${FIELD_CLASS}__title`}>
          <div>Player File</div>
          <PlayerFileNamingToolTip />
        </div>
        {props.fileStatus &&
          props.fileStatusSuccessful &&
          props.fileStatus.map((status) => (
            <PlayerFileBlock key={status.fileName} file={status} />
          ))}
        {props.fileStatusLoading && <LoadingBlock />}
        {props.fileStatusError && (
          <ErrorBlock error={'Error fetching player files'} />
        )}
        <hr />
        {props.error && <ErrorBlock error={'Error uploading player files'} />}
      </div>
      <form onSubmit={formik.handleSubmit} noValidate>
        <FormFileUpload
          name={playerFilesName}
          onChange={(files: File | File[]) => {
            formik.setFieldValue(playerFilesName, files)
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.playerAssignmentFiles}
          onError={(errorMessage?: string) =>
            formik.setFieldError(playerFilesName, errorMessage)
          }
          acceptedFileTypes={{ 'text/csv': ['.csv'] }}
          dropzoneAriaLabel="Player files dropzone"
          imagePreview={false}
          maxFiles={5}
          maxPayloadSizeBytes={DEFAULT_EXPRESS_MAX_PAYLOAD_SIZE_BYTES}
          disabled={isFormDisabled}
          touched
          requestComplete={props.requestSuccessful}
          nameValidator={(file: File) =>
            playerFileNameValidator(file, props.campaignIdentifier)
          }
        />
        <CampaignsSideBarSubmitSection
          loading={props.submissionLoading}
          disabled={isFormDisabled}
          closeSideBar={props.closeSideBar}
        />
      </form>
    </div>
  )
}
