import React from 'react'
import { SearchResultsTableColumnMap, SearchResultsTableUI } from '../../../CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { CheckRunHistoryResponse, CheckRunStatus, HistoricalCheckRun } from '../../../../api/check-run-history-fetcher/api-types'
import moment from 'moment'
import { formatCentsToDollars } from '../../../../util'
import { useHistory } from 'react-router-dom'
import { checkRunQueueRoutePath } from '../../../../config.js'
import './styles.scss'

export type CheckRunHistoryTableProps = {
  data: CheckRunHistoryResponse
}

type CheckRunDisplayRow = {
  id: string
  dateSubmitted: string
  timeSubmitted: string
  grossPayment: string
  reportablePayDate: string
  status: 'Success' | 'Pending' | 'Failed'
}

const CHECK_RUN_HISTORY_COLUMN_MAP: SearchResultsTableColumnMap<CheckRunDisplayRow> =
  [
    { dataKey: 'dateSubmitted', label: 'Date Submitted', width: 14 },
    { dataKey: 'timeSubmitted', label: 'Time', width: 10 },
    { dataKey: 'grossPayment', label: 'Gross Payment', width: 14 },
    { dataKey: 'reportablePayDate', label: 'Reportable Pay Date', width: 18 },
    { dataKey: 'status', label: 'Status', width: 14 },
  ]
const mapCheckRunToRow = (checkRun: HistoricalCheckRun): CheckRunDisplayRow => {
  return {
    id: checkRun.id,
    dateSubmitted: moment(checkRun.initiatedDate).format('MM/DD/YYYY'),
    timeSubmitted: moment(checkRun.initiatedDate).format('HH:mm'),
    grossPayment: formatCentsToDollars(checkRun.totalGrossPaymentInCents),
    reportablePayDate: checkRun.reportablePaymentDate,
    status: mapCheckRunStatusToString(checkRun.status),
  }
}

export const mapCheckRunStatusToString = (status: CheckRunStatus) => {
  switch (status) {
    case CheckRunStatus.SUCCESS:
      return 'Success'
    case CheckRunStatus.ZIP_GENERATED:
    case CheckRunStatus.PENDING:
      return 'Pending'
    case CheckRunStatus.ERROR_TAXABLE_PLAYER_CSV:
    case CheckRunStatus.ERROR_NON_TAXABLE_PLAYER_CSV:
    case CheckRunStatus.ERROR_PLAYER_CSV:
    case CheckRunStatus.ERROR_TAX_ADJUSTMENTS:
    case CheckRunStatus.ERROR_UPLOAD:
    default:
      return 'Failed'
  }
}

const ROOT_CLASS = 'check-run-history-table'

export const CheckRunHistoryTableUI = (props: CheckRunHistoryTableProps) => {
  const history = useHistory();

  const checkRunCTAHandler = (id: string) => {
    history.push(`${checkRunQueueRoutePath}/${id}/payments`)
  }

  const generateCheckRunCTA = (checkRun: CheckRunDisplayRow) => {
    return (
      checkRun.status !== 'Failed' && (
        <button
          disabled={checkRun.status === 'Pending'}
          onClick={() => checkRunCTAHandler(checkRun.id)}
          className={`${ROOT_CLASS}_primary_button`}
        >
          Payment List
        </button>
      )
    )
  }

  return (<SearchResultsTableUI
    rowData={props.data.historicalCheckRuns.map(mapCheckRunToRow)}
    columnMap={CHECK_RUN_HISTORY_COLUMN_MAP}
    generateRowCTA={generateCheckRunCTA}
    additionalResults={false}
    noDataMessage="No Check Runs have been initiated"
  ></SearchResultsTableUI>)
}