import * as React from 'react'
import { Agent1099PrintHistoryPageUI } from './agent-1099-print-history-page-ui'
import {
  useFetchAgent1099PrintHistory,
  useFetchDownload1099PrintHistoryReport,
} from '../../../api/agent-1099-print-queue-history-fetcher'

export const Agent1099PrintHistoryPageContainer = () => {
  const { isLoading, error, data, fetchAgent1099PrintHistory } =
    useFetchAgent1099PrintHistory()
  const agent1099PrintHistoryReportFetcher =
    useFetchDownload1099PrintHistoryReport()

  React.useEffect(() => {
    fetchAgent1099PrintHistory()
  }, [])
  return (
    <Agent1099PrintHistoryPageUI
      isLoading={isLoading}
      error={error}
      data={data}
      downloadReport={agent1099PrintHistoryReportFetcher.preSignedUrlHandler}
    />
  )
}
