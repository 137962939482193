import * as React from 'react'
import { PropsWithChildren, useState } from 'react'
import { PaginationCommonComponent } from '../PaginationCommonComponent'
import './styles.scss'

const ROOT_CLASS = 'sr-table'
const DEFAULT_NUM_ROWS_PER_PAGE = 25

export type SearchResultsTableColumnMap<T extends { [key: string]: any }> = {
  dataKey: keyof T
  label: string
  width: number // the column width, as a percentage of total container width. Used in grid-template-columns style.
}[]

export type SearchResultsTableUIProps<T extends { [key: string]: any }> = {
  numRowsPerPage?: number
  columnMap: SearchResultsTableColumnMap<T>
  rowData: T[]
  noDataMessage?: string
  generateRowCTA?: (row: T) => React.ReactNode
  additionalResults: boolean
  showDisplayRange?: boolean
}

type TablePageDisplayInfo = {
  displayRangeMessage: string | null
  rowsToDisplay: React.ReactNode[]
  pageCountMessage: string
}

export const SearchResultsTableUI = <T extends { [key: string]: any }>(
  props: PropsWithChildren<SearchResultsTableUIProps<T>>
) => {
  const [currentPage, setCurrentPage] = useState<number>(0)

  // Automatically sets current page to 0 if the underlying data changes
  React.useEffect(() => setCurrentPage(0), [props.rowData])

  const numRowsPerPage = props.numRowsPerPage ?? DEFAULT_NUM_ROWS_PER_PAGE

  const columnWidths: string[] = props.columnMap.map(
    (column) => `${column.width.toString()}%`
  )
  if (props.generateRowCTA) {
    const widthOfColumns = props.columnMap.reduce(
      (prev, curr) => prev + curr.width,
      0
    )
    columnWidths.push(`${100 - widthOfColumns}%`)
  }
  const columnWidthOverrideString = columnWidths.join(' ')

  const tableHeaderColumns = (
    <div
      className={`${ROOT_CLASS}-header w-100 rounded-top font-weight-bold pr-2`}
      style={{ gridTemplateColumns: columnWidthOverrideString }}
    >
      {props.columnMap.map((column, index) => {
        return (
          <div className="pl-2" key={index}>
            {column.label}
          </div>
        )
      })}
    </div>
  )

  const noDataDisplay = (
    <div className="mt-3 text-center" style={{ fontSize: '1.3em' }}>
      <i className={`${ROOT_CLASS}-no-data-message`}>{props.noDataMessage ?? 'There are no results to display.'}</i>
    </div>
  )

  const generateTableRowsFromData = (
    startIndex: number,
    endIndex: number
  ): React.ReactNode[] => {
    return props.rowData.slice(startIndex, endIndex).map((row, i) => {
      return (
        <div
          key={i}
          className={`${ROOT_CLASS}-row pr-2`}
          style={{ gridTemplateColumns: columnWidthOverrideString }}
        >
          {props.columnMap.map((column, j) => {
            return (
              <div key={j} className={`${ROOT_CLASS}-body-text pl-2`}>
                {row[column.dataKey]}
              </div>
            )
          })}
          <div className={'cta-container'}>
            {props.generateRowCTA && props.generateRowCTA(row)}
          </div>
        </div>
      )
    })
  }

  const generateRowsAndDisplayMessageForPage = (showDisplayRange?: boolean): TablePageDisplayInfo => {
    const show = showDisplayRange ?? true
    if (props.rowData.length === 0) {
      return {
        displayRangeMessage: show ? 'Displaying 0 of 0 results' : null,
        rowsToDisplay: [],
        pageCountMessage: 'Page 0 of 0',
      }
    }

    const startIndex = currentPage * numRowsPerPage // inclusive
    const messageStartIndex = startIndex + 1 // startIndex is 0-indexed, but our message is 1-indexed

    const endIndex = startIndex + numRowsPerPage // exclusive
    const messageEndIndex = Math.min(endIndex, props.rowData.length) // 1-indexed, inclusive

    const numPages = Math.ceil(props.rowData.length / numRowsPerPage)

    return {
      displayRangeMessage: show ? `Displaying ${messageStartIndex} - ${messageEndIndex} of ${
        props.rowData.length
      }${props.additionalResults ? '+' : ''} results` : null,
      rowsToDisplay: generateTableRowsFromData(startIndex, endIndex),
      pageCountMessage: `Page ${currentPage + 1} of ${numPages}`,
    }
  }

  const tablePageDisplayInfo = generateRowsAndDisplayMessageForPage(props.showDisplayRange)

  return (
    <div className={`${ROOT_CLASS} w-100 rounded px-2 pb-2`}>
      <div
        className={`${ROOT_CLASS}-results d-flex align-items-center font-italic font-weight-bold pl-1`}
      >
        <div className={`${ROOT_CLASS}-display-range`} aria-live="assertive">
          {tablePageDisplayInfo.displayRangeMessage}
        </div>
        {props.children}
      </div>
      {tableHeaderColumns}
      <div className={`${ROOT_CLASS}-body`}>
        {tablePageDisplayInfo.rowsToDisplay.length > 0
          ? tablePageDisplayInfo.rowsToDisplay
          : noDataDisplay}
      </div>
      <div
        className={`${ROOT_CLASS}-footer p-2 rounded-bottom w-100 d-flex align-items-center justify-content-end`}
      >
        <div
          className={`${ROOT_CLASS}-page-count mr-2`}
          aria-live="assertive"
          style={{ fontSize: '0.875em' }}
        >
          {tablePageDisplayInfo.pageCountMessage}
        </div>
        {
          <PaginationCommonComponent
            playerData={props.rowData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={props.numRowsPerPage ?? DEFAULT_NUM_ROWS_PER_PAGE}
          />
        }
      </div>
    </div>
  )
}
