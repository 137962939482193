import React, { useState } from 'react'
import styles from './css/styles.module.css'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  doTicketSearchWithBarCode,
  doTicketSearchWithSerialNumber,
  onClearSearch,
  onSubmit,
  setFiledValue,
  setVariables,
} from './functions'
import { useFormik } from 'formik'
import {
  PageMessageCommonComponent,
  pageMessageDefault,
} from '../../../components/CommonComponents/PageMessageCommonComponent'
import { TicketSearchResult } from './interface'
import { TicketSearchFields } from '../../../components/TicketSearchComponents/TicketSearchFields'
import { TicketInformation } from '../../../components/TicketSearchComponents/TicketInformation'
import { EventHistory } from '../../../components/TicketSearchComponents/EventHistory'
import { PromotionEntryDetailsModalContainer } from '../../../components/CommonComponents/promotion-entry-details-modal/promotion-entry-details-modal-container'

const TicketSearchPage = ({ storesObject }: any) => {
  const history: any = useHistory()
  const [ticketSearchAPIResponse, setTicketSearchAPIResponse] =
    useState<TicketSearchResult>()
  const playerStore = storesObject.playerStore
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault)
  const [buttonClicked, setButtonClicked] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [openBarcode, setOpenBarcode] = React.useState<string | undefined>(
    undefined
  )
  const [playerId, setPlayerId] = React.useState<string>('')

  React.useEffect(() => {
    if (ticketSearchAPIResponse) {
      let ticketInfoString = JSON.stringify(ticketSearchAPIResponse)
      let ticketInfoJson = JSON.parse(ticketInfoString)
      let ticketSearchResponse = ticketInfoJson.ticket
      let MCAPlayerData = ticketSearchResponse.eventHistory
      if (MCAPlayerData.length > 0) {
        setPlayerId(MCAPlayerData[0].playerId)
      }
    }

    return () => {}
  }, [ticketSearchAPIResponse])

  const formik = useFormik({
    initialValues: {
      barCode: '',
      serialNumber: '',
    },
    onSubmit,
  })

  setVariables(
    formik,
    playerStore,
    history,
    pageMessageDefault,
    setPageMessage,
    setButtonClicked
  )

  return (
    <>
      {buttonClicked && pageMessage.message === '' && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
            zIndex: 1000,
          }}
        >
          <CircularProgress size="8em" color="inherit" thickness={2} />
        </div>
      )}

      <div
        onClick={(e: any) => {
          pageMessage.messageType !== '' &&
            setPageMessage({ message: '', messageType: '' })
        }}
        className={styles['page-container']}
      >
        {openBarcode && (
          <PromotionEntryDetailsModalContainer
            playerId={playerId}
            barcode={openBarcode}
            clearBarcode={() => setOpenBarcode(undefined)}
            showPlayerId
          />
        )}
        <div className={styles['top-ribbon']}>
          <TicketSearchFields
            onClearSearch={onClearSearch}
            setButtonClicked={setButtonClicked}
            setTicketSearchAPIResponse={setTicketSearchAPIResponse}
            doTicketSearchWithBarCode={doTicketSearchWithBarCode}
            doTicketSearchWithSerialNumber={doTicketSearchWithSerialNumber}
            formik={formik}
            setFieldValue={setFiledValue}
            setCurrentPage={setCurrentPage}
          />
        </div>
        {ticketSearchAPIResponse && (
          <div className={styles['top-ribbon']}>
            <div className={styles['top-ribbon-text']} tabIndex={0}>
              Ticket Information
            </div>
            <br />
            <TicketInformation
              MCAPlayerData={ticketSearchAPIResponse}
              setOpenBarcode={setOpenBarcode}
            />
          </div>
        )}
        {pageMessage.message != '' && (
          <PageMessageCommonComponent
            message={pageMessage.message}
            messageType={pageMessage.messageType}
          />
        )}
        <div className={styles['page-container-body-container']}>
          <div className={styles['page-container-body']}>
            {ticketSearchAPIResponse && (
              <div className={styles['top-ribbon']}>
                <div className={styles['top-ribbon-text']} tabIndex={0}>
                  Event History
                </div>
                <EventHistory
                  MCAPlayerData={ticketSearchAPIResponse}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
          {ticketSearchAPIResponse == null && (
            <div className={styles['page-container-body-center-text']}>
              <div>
                <span tabIndex={0}>
                  Enter search criteria above to see ticket event history{' '}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default TicketSearchPage
