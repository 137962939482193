import moment from 'moment'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { annuitiesRoutePath } from '../../../../../config.js'
import { PaymentResponse } from '../../../../../types/Annuities/TimePaymentAuthorization/PaymentResponse'
import { TimePaymentAuthSchedule } from '../../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization.js'
import { formatDollarAmount } from '../../../../../util/'
import styles from './css/styles.module.css'

export const PaymentListRowCmpt: React.FC<{
  paymentData: PaymentResponse | TimePaymentAuthSchedule
  paymentNumber: number
  paymentDataLength: number
  fromPage: string
}> = ({ paymentData, paymentNumber, paymentDataLength, fromPage }) => {
  const history: any = useHistory()

  // type guard
  const isOfTypeTimePaymentAuthSchedule = (
    paymentData: TimePaymentAuthSchedule | PaymentResponse
  ): paymentData is TimePaymentAuthSchedule => {
    return (paymentData as TimePaymentAuthSchedule).payee !== undefined
  }

  const getName = (
    paymentData: TimePaymentAuthSchedule | PaymentResponse
  ): string => {
    if (isOfTypeTimePaymentAuthSchedule(paymentData)) {
      if (paymentData.payee.payeeType === 'Individual') {
        return paymentData.payee.firstName + ' ' + paymentData.payee.lastName
      } else {
        return paymentData.payee.freeformName
      }
    } else {
      return paymentData.name
    }
  }

  const getLifetimePayment = (
    paymentData: TimePaymentAuthSchedule | PaymentResponse
  ): string => {
    if (isOfTypeTimePaymentAuthSchedule(paymentData)) {
      return paymentData.timePaymentAccount.lifetimePayment ? 'Yes' : 'No'
    } else {
      return paymentData.lifetimePayment ? 'Yes' : 'No'
    }
  }

  const getDetailsLink = (
    paymentData: TimePaymentAuthSchedule | PaymentResponse
  ) => {
    const id = isOfTypeTimePaymentAuthSchedule(paymentData)
      ? paymentData.payment.id
      : paymentData.id
    return `${annuitiesRoutePath}/authorization-payment-details/${id ?? ''}`
  }

  return (
    <div className={`${styles.table_body}`}>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? paymentData.timePaymentAccount.documentId
          : paymentData.documentId}
      </div>
      <div className={`${styles.table_body_text}`}>{getName(paymentData)}</div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? paymentData.payee.tin
          : paymentData.tin}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? paymentData.timePaymentAccount.payFrequency
          : paymentData.payFrequency}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? moment(paymentData.payment.paymentDate).format('MM/DD/YYYY')
          : moment(paymentData.paymentDate).format('MM/DD/YYYY')}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? formatDollarAmount(paymentData.payment.paymentAmount / 100)
          : formatDollarAmount(paymentData.paymentAmount / 100)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? formatDollarAmount(paymentData.payment.irsWithholding / 100)
          : formatDollarAmount(paymentData.irsWithholding / 100)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? formatDollarAmount(paymentData.payment.dorsWithholding / 100)
          : formatDollarAmount(paymentData.dorsWithholding / 100)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? formatDollarAmount(
              (paymentData.payment.paymentAmount -
                paymentData.payment.irsWithholding -
                paymentData.payment.dorsWithholding) /
                100
            )
          : formatDollarAmount(
              (paymentData.paymentAmount -
                paymentData.irsWithholding -
                paymentData.dorsWithholding) /
                100
            )}
      </div>
      <div className={`${styles.table_body_text}`}>
        {getLifetimePayment(paymentData)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {isOfTypeTimePaymentAuthSchedule(paymentData)
          ? paymentData.payment.paymentStatus
          : paymentData.paymentStatus}
      </div>
      <Link
        role="button"
        className={styles.details_button}
        to={{
          pathname: getDetailsLink(paymentData),
          state: {
            paymentDetails: paymentData,
            from: useLocation().pathname,
            params: useLocation().search,
            paymentNumber: paymentNumber,
            paymentDataLength: paymentDataLength,
          },
        }}
      >
        Details
      </Link>
    </div>
  )
}
