import {batchTaxesInterface} from "../../../pages/FinancePages/ACHTaxBatchDetailsPage/interfaces";

export const getPageData = (KYCPlayerData: any[], page: number = 0) => {
    const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(KYCPlayerData[i] === undefined))
            array.push(KYCPlayerData[i]);
    }
    return array;
}
export const normalizeAmount = (value: any) => {
    try {
        let isNegative: boolean = false;
        if (parseInt(value) < 0) {
            isNegative = true;
            value = value * -1;
        }
        let dollars: string[] = value.toString().split("");
        let amount: string[] = [];
        let y: number = -1;
        for (let i = dollars.length - 1; i >= 0; i--) {
            if (i === dollars.length - 3) {
                amount.push(`.`);
                y = 1;
            }
            amount.push(`${dollars[i]}`);
            if (y % 3 === 0 && y !== 0 && i !== 0)
                amount.push(`,`);
            y++;
        }
        return isNegative ? `$${amount.reverse().join("")}` : `$${amount.join("") === "0" ? "- -" : amount.reverse().join("")}`;
    } catch (e) {
        console.log(e);
        return "- -";
    }
}
export const addTaxes = (tax: any) => {

    if (typeof tax === 'string')
        tax = JSON.parse(tax);
    // console.log('tx', tax);
    let amount: any = 0;
    tax.forEach((e: batchTaxesInterface | any) => {
        if (e.type !== 'PRIZE')
            amount += parseInt(e.amountInCents);

    })

    return normalizeAmount(amount);

} 