import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { FormikErrors } from 'formik'
import { ErrorMessageFieldCommonComponent } from '../CommonComponents/ErrorMessageFieldCommonComponent'
import {
  FilePreview,
  FilePreviewUI,
} from './file-preview-component/file-preview-component-ui'
import UploadIcon from './../../icons/upload_icon.svg'
import './styles.scss'

export const DEFAULT_EXPRESS_MAX_PAYLOAD_SIZE_BYTES = 1048576

export type FormFileUploadProps = {
  label?: string
  name: string
  onChange: (file: File | File[]) => void
  onBlur: (e: any) => void
  onError: (errorMessage?: string) => void
  error: string | string[] | FormikErrors<File>[] | undefined
  dropzoneAriaLabel: string
  acceptedFileTypes?: { [key: string]: string[] }
  maxFiles?: number
  maxPayloadSizeBytes?: number
  allowIndividualImageRemoval?: boolean
  imagePreview?: boolean
  disabled: boolean
  touched: boolean | undefined
  requestComplete?: boolean
  nameValidator?: (file: File) => { code: string; message: string } | null
}

const ROOT_CLASS = 'form-file-upload'
const DROPZONE_CLASS = `${ROOT_CLASS}__image-dropzone`

const getDisabledClass = (isDisabled: boolean) =>
  isDisabled ? `${ROOT_CLASS}--disabled` : ''

const containsDuplicates = (files: File[]): boolean => {
  const uniqueFiles = new Set(files.map((file) => file.name))
  return uniqueFiles.size < files.length
}

const bytesToMB = (bytes: number): string => (bytes / (1024 * 1024)).toFixed(1)

export const FormFileUpload = (props: FormFileUploadProps) => {
  const [files, setFiles] = React.useState<File[]>([])
  const [filePreviews, setFilePreviews] = React.useState<FilePreview[]>([])

  React.useEffect(() => {
    if (props.maxFiles === 1) {
      props.onChange(files[0])
    } else {
      props.onChange(files)
    }
    setFilePreviews(
      files.map((file) => ({
        name: file.name,
        preview: URL.createObjectURL(file),
      }))
    )
  }, [files])

  React.useEffect(() => {
    return () => {
      filePreviews.forEach((imagePreview) =>
        URL.revokeObjectURL(imagePreview.preview)
      )
    }
  }, [])

  React.useEffect(() => {
    if (props.requestComplete) {
      setFiles([])
      setFilePreviews([])
    }
  }, [props.requestComplete])

  const handleChange = (newFiles: File[]) => {
    if (props.maxFiles === 1) {
      setFiles(newFiles.slice(0, 1))
    } else {
      setFiles(newFiles)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    disabled: props.disabled,
    accept: props.acceptedFileTypes,
    maxFiles: props.maxFiles,
    maxSize: props.maxPayloadSizeBytes,
    onDropAccepted: (acceptedFiles: File[]) => {
      props.onError(undefined)
      // If only 1 file accepted, replace file, otherwise append
      if (props.maxFiles === 1) {
        handleChange(acceptedFiles)
      } else {
        const newFiles = [...files, ...acceptedFiles]
        const newFilesPayloadSize = newFiles
          .map((file) => file.size)
          .reduce((a, b) => a + b)

        if (props.maxFiles && newFiles.length > props.maxFiles) {
          props.onError('Too many files')
        } else if (containsDuplicates(newFiles)) {
          props.onError('Duplicate files not allowed')
        } else if (
          props.maxPayloadSizeBytes &&
          newFilesPayloadSize > props.maxPayloadSizeBytes
        ) {
          props.onError(
            `Total payload size cannot exceed ${
              props.maxPayloadSizeBytes
            } bytes (${bytesToMB(props.maxPayloadSizeBytes)} MB)`
          )
        } else {
          handleChange(newFiles)
        }
      }
    },
    onDropRejected: (rejectedFiles: any) => {
      if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        props.onError(
          `File cannot exceed size limit of ${
            props.maxPayloadSizeBytes
          } bytes (${bytesToMB(props.maxPayloadSizeBytes ?? 0)} MB)`
        )
      } else {
        props.onError(rejectedFiles[0].errors[0].message)
      }
    },
    validator: props.nameValidator,
  })

  return (
    <div className={`${ROOT_CLASS} ${getDisabledClass(props.disabled)}`}>
      {props.label && (
        <label className={`${ROOT_CLASS}__label`} htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <FilePreviewUI
        filePreviews={filePreviews}
        imagePreview={props.imagePreview}
        removeFile={(filename: string) => {
          URL.revokeObjectURL(
            filePreviews.filter((file) => file.name == filename)[0].preview
          )
          handleChange(files.filter((file) => file.name !== filename))
        }}
        clearFiles={() => {
          handleChange([])
        }}
        allowIndividualRemoval={props.allowIndividualImageRemoval}
      />
      <div
        {...getRootProps({ className: DROPZONE_CLASS })}
        aria-label={props.dropzoneAriaLabel}
      >
        <input
          id={props.name}
          {...getInputProps()}
          className={`${ROOT_CLASS}__input`}
          type="file"
          name={props.name}
          onBlur={(e) => props.onBlur(e)}
        />
        <img className={`${DROPZONE_CLASS}__icon`} src={UploadIcon} />
        <div className={`${DROPZONE_CLASS}__text`}>
          Drag & Drop or{' '}
          <a className={`${DROPZONE_CLASS}__text--highlighted`}>
            choose a file
          </a>{' '}
          to upload
        </div>
      </div>
      {props.error && props.touched && (
        <ErrorMessageFieldCommonComponent errorMessage={props.error} />
      )}
    </div>
  )
}
