import React, { useState } from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import { FuturePaymentStatus } from '../../../../api/future-payments-fetcher/api-types'
import { ModalOverlayContainer } from '../../../CommonComponents/modal-container/modal-container-component'
import './styles.scss'
const ROOT_CLASS = 'future_payment_details_update_status'

type UpdateFuturePaymentStatusUIProps = {
  futurePayment: GetFuturePaymentDetailsResponse
  setShowStatusModal: React.Dispatch<React.SetStateAction<boolean>>
  updatePaymentStatus: (status: FuturePaymentStatus, notes: string) => void
}

export const UpdateFuturePaymentStatusUI = (
  props: UpdateFuturePaymentStatusUIProps
) => {
  const [status, setStatus] = useState<FuturePaymentStatus>(
    props.futurePayment.status
  )
  const [notes, setNotes] = useState(props.futurePayment.onHoldNotes ?? '')

  const submitHandler = () => {
    props.updatePaymentStatus(status, notes)
    props.setShowStatusModal(false)
  }
  return (
    <ModalOverlayContainer>
      <div className={ROOT_CLASS}>
        <header>Update Payment Status</header>
        <section>
          REMINDER: Putting a pending payment on hold will remove it from the
          next check run.
        </section>
        <section className="controls">
          <div className="control-wrapper">
            <input
              type="radio"
              name="on-hold"
              onChange={(e) => setStatus(e.target.value as FuturePaymentStatus)}
              value={FuturePaymentStatus.ON_HOLD}
              checked={status === FuturePaymentStatus.ON_HOLD}
            />
            <label htmlFor="on-hold">On Hold</label>
          </div>
          <div className="control-wrapper">
            <input
              type="radio"
              disabled={props.futurePayment.tin === null}
              name="pending"
              onChange={(e) => setStatus(e.target.value as FuturePaymentStatus)}
              value={FuturePaymentStatus.PENDING}
              checked={status === FuturePaymentStatus.PENDING}
            />
            <label htmlFor="on-hold">Pending</label>
          </div>
        </section>
        <section className="notes">
          <textarea
            className={status === FuturePaymentStatus.ON_HOLD ? 'show' : ''}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          ></textarea>
        </section>
        <button className="primary-button" onClick={() => submitHandler()}>
          Submit
        </button>
        <a role="button" onClick={() => props.setShowStatusModal(false)}>
          Cancel
        </a>
      </div>
    </ModalOverlayContainer>
  )
}
