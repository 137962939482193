import React from 'react';
import styles from './css/styles.module.css';

const ToggleSwitchComponent = ({id, name, isOn, handleToggle, disabled}: any) => {
    return (
        <>
            <div className={styles["toggle-switch-container"]}>
                <input
                    className={styles['toggle-switch-checkbox']}
                    checked={isOn}
                    onChange={handleToggle}
                    id={id}
                    name={name}
                    type="checkbox"
                    disabled={disabled}
                />
                <label
                    style={{
                        background: isOn ? '#50b96a' : '#db4a4a',
                        cursor: (disabled) ? "not-allowed" : "pointer"
                    }}
                    className={styles['toggle-switch-label']}
                    htmlFor={id}
                >{isOn ? <div className={styles['justify-left']}>Yes</div> :
                    <div className={styles['justify-right']}>No</div>}
                    <span
                        className={styles['toggle-switch-button']}
                        style={{
                            cursor: (disabled) ? "not-allowed" : "pointer"
                        }}
                    />
                </label>
            </div>
        </>
    );
};

export default ToggleSwitchComponent;