import * as React from 'react'
import { GenericTableUI } from '../../../../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { getBlockClass } from '../../../../../../utilities/helpers'
import {
  ScanToEnterRule,
  ScanToEnterRuleProgressEvent,
} from '../../../../../../api/promotions-fetcher/constants'
import {
  OpenGameDetails,
  ScanToEnterRuleGameSummary,
  mapScanToEnterRuleDetailsRow,
  scanToEnterRuleDetailsColumnMap,
} from '../helpers'
import './styles.scss'

const ROOT_CLASS = 'scan-to-enter-progress-rule-section'

type ScanToEnterProgressRuleSectionProps = {
  rewardGroupId: string
  rule: ScanToEnterRule
  rewardGroupIndex: number
  ruleIndex: number
  scans: ScanToEnterRuleProgressEvent[]
  setOpenGameDetails: (details: OpenGameDetails) => void
}

export const ScanToEnterProgressRuleSection = (
  props: ScanToEnterProgressRuleSectionProps
) => {
  const gameSummaries: ScanToEnterRuleGameSummary[] = props.rule.gameIds.map(
    (gameId) => ({
      gameId: gameId,
      scans: props.scans.filter((scan) => scan.gameId === gameId),
    })
  )

  const mappedData = gameSummaries.map((summary) =>
    mapScanToEnterRuleDetailsRow(
      summary,
      {
        rewardGroupId: props.rewardGroupId,
        rewardGroupIndex: props.rewardGroupIndex,
        ruleId: props.rule.ruleId,
        ruleIndex: props.ruleIndex,
      },
      props.setOpenGameDetails
    )
  )

  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'details')}>
        <h3>
          Reward Group {props.rewardGroupIndex + 1} - Rule {props.ruleIndex + 1}
        </h3>
        <h3>Quantity Required: {props.rule.ruleQuantity}</h3>
      </div>
      <GenericTableUI
        columnMap={scanToEnterRuleDetailsColumnMap}
        rowData={mappedData}
        customRowCellStyling={{ height: '48px' }}
      />
    </div>
  )
}
