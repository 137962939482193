import * as React from 'react'
import { inject, observer } from 'mobx-react'
import config from '../../config'

const FreshWorksWindow = (window as unknown as any).FreshworksWidget
const FreshWorksWidget = FreshWorksWindow
  ? FreshWorksWindow
  : (...args: any[]) =>
      console.log(`unable to process FreshworksWidget(${args})`)

const TOKEN_URL = `${config.SERVER_BASE_URL}/v1/auth/freshdesk-token`

export type FreshDeskTokenResponse = {
  freshworksWidgetAuthToken: string
}

export const fetchFreshworksToken = async (userFullName: string, userName: string) => {
  const response = await fetch(`${TOKEN_URL}?userName=${userFullName}&userEmail=${userName}`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return await response.json()
}

export const loginHelpWidget = async (userFullName: string, userName: string) => {
  try {
    const data = await fetchFreshworksToken(userFullName, userName)
    if (data?.freshworksWidgetAuthToken) {
      FreshWorksWidget('authenticate', {
        token: data.freshworksWidgetAuthToken,
        callback: fetchFreshworksToken,
      })
    }
  } catch (e) {
    console.error('Error authenticating FreshDesk', e)
  }
}

export const logoutHelpWidget = () => {
  FreshWorksWidget('logout')
}

export const AuthFreshDesk = inject('authStore')(
  observer(({ authStore }: any) => {
    React.useEffect(() => {
      if (!authStore.userId) {
        logoutHelpWidget()

      } else if (authStore.userFullName && authStore.userName) {
        loginHelpWidget(authStore.userFullName, authStore.userName)
      }
    }, [authStore.userName, authStore.userFullName, authStore.userId])

    return null
  })
)
