import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { checkRenderPermissions } from  './../../../helpers.js';
import { permissions } from  './../../../constants.js';

import { useHistory } from "react-router-dom";
import { playerSupportRoutePath } from './../../../config.js';
import { keyPressedType } from "../../../services/Commons";
import { playersIdentityValues } from './../../../services/Commons';
import { clearPageMessageDefault } from './../../CommonComponents/PageMessageCommonComponent'
import { CircularProgress } from "@material-ui/core";

import { success, denied } from "../../../services/Icons";

export const PlayerStatusComponent = ({ playerStore, isData = true, callingPage={}, pageType = undefined }: any) => {
    const history:any = useHistory();
    const userpolicy: string | any = localStorage.getItem('userpolicies');
    const _callingPage:Object = {};
   
   
    return (
        <>
            <div onFocus={()=> clearPageMessageDefault() } className={styles['page-container']}>
            {((playerStore.account.email !== "" && (callingPage === pageType) && !isData)) && <div style={{ position: 'relative', zIndex:50, top: 0, left: 0, width: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
                 <CircularProgress size="6em" color='inherit' thickness={2} />
            </div>}
                <div className={styles['players-status-title-container']} >
                    <div tabIndex={0} className={styles['players-status-label']}>Status</div>
                    {(checkRenderPermissions(permissions.SEE_PLAYER_VERIFICATION, JSON.parse(userpolicy)) && (!(callingPage === undefined))  && !(callingPage['PlayerDetailsPage'] === undefined)) 
                       && 
                       <div tabIndex={0} 
                            style={{ opacity:(playersIdentityValues.identityFieldIsUndefined === true ? 0.4 : playerStore.identity.identityCheckResult === "PASS" ? 0.4 : 1 ),
                                     cursor: playersIdentityValues.identityFieldIsUndefined === true || playerStore.identity.identityCheckResult === "PASS" ? "not-allowed" : "pointer",
                        }} 
                       className={styles['players-status-button']} 
                       aria-hidden={ `${playersIdentityValues.identityFieldIsUndefined}`    }
                       role='button'
                       aria-pressed={"false"}
                       onKeyPress={(e:any)=> keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32 ? 
                        ( !(playerStore.identity.identityCheckResult === "PASS" && playersIdentityValues.identityFieldIsUndefined === true)) && history.push(`${playerSupportRoutePath}/manual-kyc/${playerStore.account.id}`)
                        :
                        ""
                    }
                    onClick={() =>   (!(playerStore.identity.identityCheckResult === "PASS" || playersIdentityValues.identityFieldIsUndefined === true ))  && history.push(`${playerSupportRoutePath}/manual-kyc/${playerStore.account.id}`)}><div>Manually Approve KYC</div></div>}
                       
                </div>

                <div className={styles['player-status-data-container']}>
                    <div tabIndex={0} className={`${styles['player-status-kyc']} ${styles['opacity']}`}>KYC</div>
                    <div tabIndex={0} className={styles['player-status-data-container-column2']}>
                        { playerStore.identity.identityCheckResult === ""  || playerStore.identity.identityCheckResult === undefined  ?  "" : playerStore.identity.identityCheckResult === "PASS" ?
                            <div title={"Approved"}  className={styles['KYC-Status']}>
                                <img alt="approved icon" style={{ width: '1em', height: '1em' }} src={success} />&nbsp;&nbsp;Approved </div>
                            :
                            <div title={"Denied"} >
                                <img alt={"Denied icon"} style={{ width: '1em', height: '1em' }} src={denied} /> &nbsp;&nbsp;Denied</div>}
                    </div>
                    <div tabIndex={0} className={`${styles['player-status-account']} ${styles['opacity']}`}>Account</div>
                    <div tabIndex={0} className={`${styles['player-status-data-container-column2']} ${styles['player-status-account']}`}>
                        { playerStore.identity.status === "" || playerStore.identity.status === undefined || playerStore.identity.status === "NO_IDENTITY" || playerStore.identity.status === "NO_RECORD" ? "": playerStore.identity.status === "VALID_IDENTITY" ?
                            <div title={"Approved"}   className={styles['KYC-Status']}>
                                <img alt="approved icon"  style={{ width: '1em', height: '1em' }} src={success} />&nbsp;&nbsp;Approved </div>
                            :
                            <div  title={"Denied"}  >
                                <img  alt={"Denied icon"} tabIndex={0} style={{ width: '1em', height: '1em' }} src={denied} /> &nbsp;&nbsp;Denied</div>}
                    </div>
                </div>
            </div>

        </>

    );
}