import { W2gReissueFormFields } from '../../../../pages/PaymentsPages/ReviewW2GReissueIndividualPage/review-w2g-reissue-page-container'
import { ModalOverlayContainer } from '../../../CommonComponents/modal-container/modal-container-component'
import React from 'react'
import './../styles.scss'

const ROOT_CLASS = 'w2g-review-reissue'

export type ReissueW2gConfirmationUIProps = {
  submitAddToQueue: (request: W2gReissueFormFields) => Promise<void>
  submitReprintImmediately: (request: W2gReissueFormFields) => Promise<void>
  paymentDetails: W2gReissueFormFields
  setShowReissueOptionsModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReissueW2gConfirmationUI = (
  props: ReissueW2gConfirmationUIProps
) => {
  const {
    submitAddToQueue,
    submitReprintImmediately,
    paymentDetails,
    setShowReissueOptionsModal,
  } = props

  const addToQueueHandler = () => {
    submitAddToQueue(paymentDetails)
    setShowReissueOptionsModal(false)
  }

  const reprintImmediatelyHandler = () => {
    submitReprintImmediately(paymentDetails)
    setShowReissueOptionsModal(false)
  }

  return (
    <>
      <ModalOverlayContainer>
        <div className={`${ROOT_CLASS}-confirmation-ui`}>
          <header>What would you like to do?</header>
          <button
            className="add-to-queue-button"
            onClick={() => addToQueueHandler()}
          >
            Add to Queue
          </button>
          <button
            className="reprint-immediately-button"
            onClick={() => reprintImmediatelyHandler()}
          >
            Re-print Immediately
          </button>
          <p>
            <a
              className="go-back-link"
              role="button"
              onClick={() => setShowReissueOptionsModal(false)}
            >
              Go Back
            </a>
          </p>
        </div>
      </ModalOverlayContainer>
    </>
  )
}
