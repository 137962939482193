import * as React from 'react'
import { CampaignFileStatusMap } from '../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'

type CampaignBuilderContext = {
  defaultLegalCopy: string
  isDefaultLegalCopyLoading: boolean
  fetchAllCampaigns: () => Promise<void>
  openEditCampaign: string
  setOpenEditCampaign: (campaignIdentifier: string) => void
  playerFilesStatusData?: CampaignFileStatusMap
  playerFilesStatusLoading: boolean
  playerFilesStatusRequestSuccessful: boolean
  playerFilesStatusError?: string
  fetchPlayerFileStatuses: () => Promise<void>
}
export const defaultCampaignBuilderContext: CampaignBuilderContext = {
  defaultLegalCopy: '',
  isDefaultLegalCopyLoading: false,
  fetchAllCampaigns: async () => {
    throw new Error('FetchAllCampaigns Not Implemented')
  },
  openEditCampaign: '',
  setOpenEditCampaign: () => {
    throw new Error('setOpenEditCampaign Not Implemented')
  },
  playerFilesStatusRequestSuccessful: false,
  playerFilesStatusLoading: false,
  fetchPlayerFileStatuses: async () => {
    throw new Error('fetchPlayerFileStatuses Not Implemented')
  },
}
export const CampaignBuilderContext =
  React.createContext<CampaignBuilderContext>(defaultCampaignBuilderContext)
