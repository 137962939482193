import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import './styles.scss'

const ROOT_CLASS = 'agent-1099-queue-search-and-filter'

export type DropDownMenuOption = {
  id: string
  displayText: string
}

export type Agent1099QueueSearchAndFilterBarUIProps = {
  selectedId?: string
  searchedTin?: string
  options?: DropDownMenuOption[]
  selectLabel: string
  setOption: (id: string) => void
  search: (tin?: string) => void
  clearSearch: () => void
}

export const Agent1099QueueSearchAndFilterBarUI = (
  props: Agent1099QueueSearchAndFilterBarUIProps
) => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true)
  const [searchedTin, setSearchedTin] = useState<string | undefined>(props.searchedTin)

  const selectOptions = props.options ?? []

  const isSearchDisabled = () => {
    // Disable search button if TIN isn't valid
    if (!searchedTin || !(searchedTin.length === 0 || searchedTin.length === 9)) {
      return true
    }
    return false
  }

  const isClearDisabled = () => {
    // Disable clear button if TIN is empty
    if (!searchedTin || searchedTin.length === 0) {
      return true
    }
    return false
  }

  const clear = () => {
    setSearchedTin('')
    props.clearSearch()
  }


  return (
    <div className={ROOT_CLASS}>
      <div id={'search-title'}>Search</div>
      <section className="filter-section">
        <section className="filter-header">
          <header>
            Filter
            <button
              className="chevron"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              {isFilterOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </header>
        </section>
        <section
          className={`filter-control-section ${isFilterOpen ? 'open' : ''}`}
        >
          <div className="filter-control">
            <label htmlFor="year">{props.selectLabel}</label>
            <div className="select-container">
              <select
                onChange={(e) => {
                  props.setOption(e.target.value)
                }}
                name="year"
                tabIndex={isFilterOpen ? 0 : -1}
                value={props.selectedId}
              >
                <option value="" disabled selected>Select...</option>
                {selectOptions.map(({ id, displayText }, index) => (
                  <option key={index} value={id}>
                    {displayText}
                  </option>
                ))}
              </select>
              <span className="select-arrow"></span>
            </div>
          </div>
        </section>
      </section>
      <section className="search-section">
        <section className="search-control-section">
          <div className="search-control">
            <div className="label-container">
                <div className="primary-label">TIN</div>
                <div className="secondary-label">Must be exact match</div>
            </div>
            <input
              className="text-box"
              name="tin"
              aria-label="TIN"
              value={searchedTin}
              onChange={(e) => setSearchedTin(e.target.value)}
            ></input>
          </div>
        </section>
        <section className="search-action-section">
          <button
            className="search-button"
            onClick={() => {
              !!searchedTin && props.search(searchedTin)
            }}
            disabled={isSearchDisabled()}
          >
            Search
          </button>
          <button
            className="clear-button"
            disabled={isClearDisabled()}
            onClick={clear}
          >
            Clear Search
          </button>
        </section>
      </section>
    </div>
  )
}
