import { CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GenericErrorDialogComponent } from '../../../components/CommonComponents/GenericErrorDialogComponent'
import { ErrorMessageFieldCommonComponent } from '../../../components/CommonComponents/ErrorMessageFieldCommonComponent'
import goBackIcon from '../../../icons/Icon_arrows_carrot_white-left.svg'
import { keyPressedType } from '../../../services/Commons'
import { formatCentsToDollars } from '../../../util'
import styles from './css/styles.module.css'
import {
  clearSearch,
  exportCsvReport,
  exportPdfReport,
  onSubmit,
  setFieldValue,
  setVariables,
  validate,
} from './functions'
import { QuarterlyTaxTotal, QuarterlyTaxTotalsReport, TaxTotal } from './types'
import { SearchResultsTableColumnMap, SearchResultsTableUI } from '../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { QuarterlyTaxTotalsReportsInterface } from './interfaces'
import { ExportDropdown } from '../../../components/CommonComponents/export-dropdown/export-dropdown-ui'
import { BannerContext } from '../../../context/BannerContext'
import moment from 'moment-timezone'

type TaxTotalDisplayRow = {
  date: string
  irsAmount: string
  dorAmount: string
}

const QUARTERLY_TAX_TOTAL_COLUMN_MAP: SearchResultsTableColumnMap<TaxTotalDisplayRow> = [
  { dataKey: 'date', label: 'Date', width: 20 },
  { dataKey: 'irsAmount', label: 'IRS Amount', width: 20},
  { dataKey: 'dorAmount', label: 'DOR Amount', width: 20}
]

const mapTaxTotalToRow = (taxTotal: TaxTotal) : TaxTotalDisplayRow => {
  return {
    date: taxTotal.date,
    irsAmount: formatCentsToDollars(taxTotal.irsAmountInCents),
    dorAmount: formatCentsToDollars(taxTotal.dorAmountInCents)
  }
}

export const QuarterlyTaxTotalsReportingPage = () => {
  const bannerContext = useContext(BannerContext)
  const history: any = useHistory()

  const userpolicies: any = localStorage.getItem('userpolicies')

  const [quarterlyTaxTotalsReport, setQuarterlyTaxTotalsReport] =
    useState<QuarterlyTaxTotalsReport>()

  const currentYear = new Date().getFullYear()
  const [yearDropdownList, setYearDropdownList] = useState<string[]>([])

  const formik = useFormik({
    initialValues: {
      year: '',
    },
    validate,
    onSubmit,
  })

  const activateSearchButton = formik.isValid && formik.dirty
  const activateExportReport =
    quarterlyTaxTotalsReport &&
    quarterlyTaxTotalsReport.quarterlyTotals.length > 0
      ? true
      : false

  const [requestCompleted, setRequestCompleted] = useState<boolean>(true)
  const [displayedYear, setDisplayedYear] = useState<string>('undefined')

  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const fillYearDropdownList = () => {
    let yearDropdownList = [currentYear.toString()]
    for (let i = 1; i < 5; i++) {
      yearDropdownList.push((currentYear - i).toString())
    }
    setYearDropdownList(yearDropdownList)
  }

  useEffect(() => {
    fillYearDropdownList()
  }, [])

  setVariables(
    setQuarterlyTaxTotalsReport,
    setShowErrorDialog,
    setRequestCompleted,
    setDisplayedYear
  )

  return (
    <>
      {!requestCompleted && (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}

      <div
        className={`${styles['pulltab-games-page-container']} ${styles['']}`}
      >
        <div
          tabIndex={0}
          className={`${styles['pulltab-games-page-back-button-container']} ${styles['']}`}
        >
          <img
            style={{
              cursor: 'pointer',
            }}
            src={goBackIcon}
            className={styles['']}
            alt="go back icon"
            onKeyDown={(ev: any) => {
              if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                history.goBack()
              }
            }}
            onClick={() => {
              history.goBack()
            }}
          />
          <div
            className={`${styles['pulltab-games-page-back-button-container-message']} ${styles['']}`}
            style={{
              cursor: 'pointer',
            }}
            onKeyDown={(ev: any) => {
              if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                history.goBack()
              }
            }}
            onClick={() => {
              history.goBack()
            }}
          >
            Back to Reporting
          </div>
        </div>
        <div
          className={`${styles['pulltab-games-page-ribbon-container']} ${styles['']}`}
        >
          <div
            tabIndex={0}
            className={`${styles['pulltab-games-page-ribbon-title']} ${styles['']}`}
          >
            Quarterly Tax Totals
          </div>
          <div
            className={`${styles['pulltab-games-page-ribbon-buttons-container']}`}
          >
              <ExportDropdown 
                disabled={!activateExportReport}
                exportPDF={async () => exportPdfReport(displayedYear, quarterlyTaxTotalsReport?.base64Pdf, bannerContext.setBannerInfo)}
                exportCSV={async () => exportCsvReport(quarterlyTaxTotalsReport, formik)}
              />
          </div>
        </div>

        <div
          className={`${styles['pulltab-games-page-search-bar-and-notification-bar-container']} ${styles['']}`}
        >
          <div
            className={`${styles['pulltab-games-page-search-bar-container']} ${styles['']}`}
          >
            <div
              className={`${styles['pulltab-games-page-search-bar-title-container']}`}
            >
              <div
                className={`${styles['pulltab-games-page-search-bar-title']}`}
              >
                Generate Reports
              </div>
              {quarterlyTaxTotalsReport && <div className={`${styles['last-updated-label']}`}>
                <div className={styles['last-updated-title']}>
                  Last Updated At: 
                </div>
                &nbsp;
                { quarterlyTaxTotalsReport.lastUpdated ?
                    moment.tz(quarterlyTaxTotalsReport.lastUpdated, 'America/New_York').format('MM/DD/YYYY h:mm A')
                  : 'Never' }
              </div> }
            </div>
            <div
              className={`${styles['pulltab-games-page-search-bar-input-button-container']} ${styles['']}`}
            >
              <div
                className={`${styles['pulltab-games-page-search-bar-label-textbox-container']}`}
              >
                <div
                  tabIndex={0}
                  className={`${styles['mslc-label']} ${styles['']}`}
                >
                  Select Year
                </div>
                <div className={`${styles.custom_select_div}`}>
                  <select
                    className={styles['custom_select']}
                    id="year"
                    name="year"
                    onChange={(e: any) => {
                      setFieldValue(e, formik)
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select"
                  >
                    <option value="" selected>
                      Select...
                    </option>
                    {yearDropdownList.map((year: string) => (
                      <option
                        selected={
                          formik.values.year.toLowerCase() ===
                          year.toLowerCase()
                        }
                        value={year}
                      >
                        {year}
                      </option>
                    ))}
                  </select>
                  <span className={`${styles.custom_arrow}`}></span>
                </div>
                {formik.errors.year ? (
                  <ErrorMessageFieldCommonComponent
                    errorMessages={formik.errors.year}
                  />
                ) : null}
              </div>
              <div
                tabIndex={0}
                className={`${styles['mslc-search-button']} ${styles['']}`}
                role="button"
                style={{
                  opacity: activateSearchButton ? '1' : '0.4',
                  cursor: activateSearchButton ? 'pointer' : 'not-allowed',
                }}
                onKeyDown={(ev: any) => {
                  if (
                    keyPressedType(ev) === 13 ||
                    keyPressedType(ev) === 0 ||
                    keyPressedType(ev) === 32
                  ) {
                    if (activateSearchButton) {
                      formik.submitForm()
                    }
                  }
                }}
                onClick={() => {
                  if (activateSearchButton) {
                    formik.submitForm()
                  }
                }}
              >
                Search
              </div>
              <div
                tabIndex={0}
                className={`${styles['mslc-default-button']} ${styles['']}`}
                role="button"
                onKeyDown={(ev: any) => {
                  if (
                    keyPressedType(ev) === 13 ||
                    keyPressedType(ev) === 0 ||
                    keyPressedType(ev) === 32
                  ) {
                    clearSearch(formik)
                  }
                }}
                onClick={() => {
                  clearSearch(formik)
                }}
              >
                Clear Search
              </div>
            </div>
          </div>
        </div>
        {!quarterlyTaxTotalsReport ? (
          <div
            className={`${styles['pulltab-games-page-search-no-results-container']}`}
          >
            <div
              className={`${styles['pulltab-games-page-search-no-results-container-text']}`}
            >
              Select a year to generate reports
            </div>
          </div>
        ) : (
          quarterlyTaxTotalsReport.quarterlyTotals.map(
            (quarterlyTotal: QuarterlyTaxTotal) => (
              <div className={styles['table']}>
                <div className={styles['result-totals-container']}>
                  <div
                    className={
                      styles['result-totals-container-title-container']
                    }
                  >
                    <div className={styles['result-totals-container-title-1']}>
                      Quarterly Tax Totals - Q{quarterlyTotal.quarterNumber}
                    </div>
                    <div className={styles['result-totals-container-title-2']}>
                      totals the quarterly amounts, and has a running total of
                      each quarter
                    </div>
                  </div>
                  <div className={styles['result-totals-container-subtitle']}>
                    Aggregate Totals
                  </div>
                  <div
                    className={`${styles['check-details-controls-container']}`}
                  >
                    <div
                      className={`${styles['check-details-controls-container-column-1']} ${styles['mslc-label']}`}
                    >
                      IRS
                    </div>
                    <div
                      className={`${styles['check-details-controls-container-column-2']} ${styles['mslc-label']}`}
                    >
                      {formatCentsToDollars(quarterlyTotal.irsTotalInCents)}
                    </div>

                    <div
                      className={`${styles['check-details-controls-container-column-3']} ${styles['mslc-label']}`}
                    >
                      DOR
                    </div>
                    <div
                      className={`${styles['check-details-controls-container-column-4']} ${styles['mslc-label']}`}
                    >
                      {formatCentsToDollars(quarterlyTotal.dorTotalInCents)}
                    </div>

                    <div
                      className={`${styles['check-details-controls-container-column-1']} ${styles['mslc-label']}`}
                    >
                      IRS Grand Total
                    </div>
                    <div
                      className={`${styles['check-details-controls-container-column-2']} ${styles['mslc-label']}`}
                    >
                      {formatCentsToDollars(
                        quarterlyTotal.irsGrandTotalInCents
                      )}
                    </div>

                    <div
                      className={`${styles['check-details-controls-container-column-3']} ${styles['mslc-label']}`}
                    >
                      DOR Grand Total
                    </div>
                    <div
                      className={`${styles['check-details-controls-container-column-4']} ${styles['mslc-label']}`}
                    >
                      {formatCentsToDollars(
                        quarterlyTotal.dorGrandTotalInCents
                      )}
                    </div>
                  </div>
                </div>

                <hr className={styles['horizontal-line']} />

                <SearchResultsTableUI
                  rowData={quarterlyTotal.dailyTotals.map(mapTaxTotalToRow)}
                  columnMap={QUARTERLY_TAX_TOTAL_COLUMN_MAP}
                  numRowsPerPage={10}
                  additionalResults={false}
                />
              </div>
            )
          )
        )}
      </div>
      {showErrorDialog ? (
        <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog} />
      ) : null}
    </>
  )
}
