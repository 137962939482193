import * as React from 'react'
import { useFetchScanToEnterProgress } from '../../../../../api/promotions-fetcher/use-fetch-scan-to-enter-promotions'
import { ScanToEnterProgressTabUI } from './ScanToEnterProgressTabUI'

export type ScanToEnterProgressTabContainerProps = {
  playerId: string
}

export const ScanToEnterProgressTabContainer = (
  props: ScanToEnterProgressTabContainerProps
) => {
  const { isLoading, error, consolidatedData } = useFetchScanToEnterProgress(
    props.playerId
  )

  return (
    <ScanToEnterProgressTabUI
      data={consolidatedData}
      isLoading={isLoading}
      error={error}
    />
  )
}
