import React, { useRef } from 'react'
import { BannerContext } from '../../../context/BannerContext'
import { getFetchAgent1099InitiatePrint } from '../../../api/agent-1099-fetcher/get-fetch-agent-1099-initiate-print'
import { Agent1099QueuePageUI } from './agent-1099-queue-page-ui'

export const Agent1099QueuePageContainer = () => {
  const { isLoading, error, fetchAgent1099InitiatePrint } = getFetchAgent1099InitiatePrint()

  const prevIsLoading = useRef<boolean>()

  const bannerContext = React.useContext(BannerContext)
  React.useEffect(() => {
    if(!isLoading) {
      if(error) {
        bannerContext.setBannerInfo({ message: 'Error initiating agent 1099 print', error: true })
      }
      else if (prevIsLoading.current) {
        bannerContext.setBannerInfo({ message: 'The selected 1099s have been sent to Operations to print. If an agent opted into digital delivery, a new PDF will be generated on the SFTP.', error: false })
      }
    }
    prevIsLoading.current = isLoading
  }, [isLoading, error])

  return (
    <Agent1099QueuePageUI
      isLoading={isLoading}
      error={error}
      initiatePrint={fetchAgent1099InitiatePrint}
    />
  )
}
