import {CreateProfileRootInterface} from "./interfaces";
import config, {licenseeRoutePath} from "../../../../config";
import axios from "axios";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";

let formik: any;
let history: any;
let pageMessageDefault: any;
let setPageMessageType: any;
export const setVariables = (formikObj: any, historyObject: any, pageMessageDefaultObj: any,
                             setPageMessageObj: any) => {
    formik = formikObj;
    history = historyObject;
    pageMessageDefault = pageMessageDefaultObj;
    setPageMessageType = setPageMessageObj;
}

export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}

export const onSubmit = async (values: CreateProfileRootInterface) => {
    // console.log(">>>>>>>>>>>>>>> onSubmit");
    formik.setValues(values, true);
    let requestData: any = {
        orgDetails: formik.values.orgDetails,
        licenseDetails: formik.values.licenseDetails
    }
    const dateOfFirstLicenseOriValue = requestData.licenseDetails.dateOfFirstLicense;
    requestData.licenseDetails.dateOfFirstLicense = new Date(dateOfFirstLicenseOriValue)

    const occasionTimeOriValue = requestData.licenseDetails.occasionTime;
    if (occasionTimeOriValue == '6:00 P.M. to 12:00 MID') {
        requestData.licenseDetails.occasionTime = 'EVENING'
    } else if ('1:00 P.M to 6:00 P.M') {
        requestData.licenseDetails.occasionTime = 'AFTERNOON'
    }

    const orgCodeOriValue = requestData.orgDetails.orgCode;
    const orgCode3Digit = orgCodeOriValue.substring(0, 3);
    requestData.orgDetails.orgCode = orgCode3Digit
    // console.log(JSON.stringify(requestData))
    try {
        const options =
            {
                method: 'post',
                url: config.SERVER_BASE_URL + '/api/v1/licensee/create',
                data: requestData
            }
        const apiResponseData = await callBackEndApi(options);
        if (apiResponseData.status === 200 || apiResponseData.status === 201) {
            // pageMessageDefault.message = "Successfully created license number : " + apiResponseData.statusMessage;
            // pageMessageDefault.messageType = "SUCCESS";
            // setPageMessageType({...pageMessageDefault});
            history.push({
                pathname: `${licenseeRoutePath}/manage`,
                state: {detail: "Successfully created license number : " + apiResponseData.statusMessage}
            })
        } else {
            pageMessageDefault.message = "Api Error : " + apiResponseData.statusText + " : " + apiResponseData.statusMessage;
            pageMessageDefault.messageType = "ERROR";
            setPageMessageType({...pageMessageDefault});
        }
        // history.push(`${playerSupportRoutePath}/player-hub-new/${formik.values.account.id}`);
    } catch (e) {
        const er: any = e;
        if (er.toString().split(" ").find((value: any, i: any) => {
            if (value === "Network")
                return value
            else if (value === "ApiError") {
                setPageMessageType({message: value, messageType: "ERROR"});
            }
        }) !== undefined) {
            setPageMessageType({
                message: "Something went wrong. Network Error. Please try again.",
                messageType: "ERROR"
            });
        } else
            setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    }
    requestData.orgDetails.orgCode = orgCodeOriValue
    requestData.licenseDetails.dateOfFirstLicense = dateOfFirstLicenseOriValue
    requestData.licenseDetails.occasionTime = occasionTimeOriValue
}

export const validate = async (values: CreateProfileRootInterface | any) => {
    const errors = {};
// Organization Details validations - begins
    if (!values.orgDetails.orgName) {
        Object.assign(errors, {orgName: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.orgDateChartered) {
        Object.assign(errors, {orgDateChartered: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.orgAddress.streetAddress) {
        Object.assign(errors, {streetAddress10: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.orgFID) {
        Object.assign(errors, {orgFID: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.orgAddress.city) {
        Object.assign(errors, {city10: 'Please enter City/Town'});
    }
    if (!values.orgDetails.orgAddress.zip) {
        Object.assign(errors, {zip10: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.orgAddress.zip.length !== 5) {
        Object.assign(errors, {zip10: 'Zipcode must be 5 digits'});
    } else if (values.orgDetails.orgAddress.zip && !/^\(?(\d{5})$/.test(values.orgDetails.orgAddress.zip)) {
        Object.assign(errors, {zip10: 'Invalid Zipcode, enter numeric value'});
    }

    if (!values.licenseDetails.occasionDay) {
        Object.assign(errors, {occasionDay: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.occasionAddress.streetAddress) {
        Object.assign(errors, {streetAddress20: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.orgPhone) {
        Object.assign(errors, {orgPhone: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.orgPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.orgDetails.orgPhone)) {
        Object.assign(errors, {orgPhone: 'Invalid phone number, enter numeric value'});
    }

    if (!values.licenseDetails.occasionAddress.city) {
        Object.assign(errors, {city1: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.occasionAddress.zip) {
        Object.assign(errors, {zip1: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.occasionAddress.zip.length !== 5) {
        Object.assign(errors, {zip1: 'Zipcode must be 5 digits'});
    } else if (values.licenseDetails.occasionAddress.zip && !/^\(?(\d{5})$/.test(values.licenseDetails.occasionAddress.zip)) {
        Object.assign(errors, {zip1: 'Invalid Zipcode, enter numeric value'});
    }

    if (!values.licenseDetails.beanoHallPhone) {
        Object.assign(errors, {beanoHallPhone: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.beanoHallPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.beanoHallPhone)) {
        Object.assign(errors, {beanoHallPhone: 'Invalid phone number, enter numeric value'});
    }

    if (!values.orgDetails.mailAddress.streetAddress) {
        Object.assign(errors, {streetAddress1: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.occasionTime) {
        Object.assign(errors, {occasionTime: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.mailAddress.city) {
        Object.assign(errors, {city2: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.mailAddress.zip) {
        Object.assign(errors, {zip2: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.mailAddress.zip.length !== 5) {
        Object.assign(errors, {zip2: 'Zipcode must be 5 digits'});
    } else if (values.orgDetails.mailAddress.zip && !/^\(?(\d{5})$/.test(values.orgDetails.mailAddress.zip)) {
        Object.assign(errors, {zip2: 'Invalid Zipcode, enter numeric value'});
    }
// Organization Details validations - ends

// License Information - begins
    if (!values.licenseDetails.investCode) {
        Object.assign(errors, {investCode: InputValidationMessages.RequiredField});
    }

    if (!values.licenseDetails.type) {
        Object.assign(errors, {type: InputValidationMessages.RequiredField});
    }

    if (!values.licenseDetails.county) {
        Object.assign(errors, {county: InputValidationMessages.RequiredField});
    }

    if (!values.licenseDetails.city) {
        Object.assign(errors, {city3: InputValidationMessages.RequiredField});
    }

    if (!values.licenseDetails.dateOfFirstLicense) {
        Object.assign(errors, {dateOfFirstLicense: InputValidationMessages.RequiredField});
    }

// Head Of Organization Details - begins
    if (!values.orgDetails.headOfOrg.fullName) {
        Object.assign(errors, {fullName: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.headOfOrg.homePhone) {
        Object.assign(errors, {homePhone1: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.headOfOrg.homePhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.orgDetails.headOfOrg.homePhone)) {
        Object.assign(errors, {homePhone1: 'Invalid phone number, enter numeric value'});
    }

    if (!values.orgDetails.headOfOrg.address.streetAddress) {
        Object.assign(errors, {streetAddress4: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.headOfOrg.businessPhone) {
        Object.assign(errors, {businessPhone4: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.headOfOrg.businessPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.orgDetails.headOfOrg.businessPhone)) {
        Object.assign(errors, {businessPhone4: 'Invalid phone number, enter numeric value'});
    }

    if (!values.orgDetails.headOfOrg.address.city) {
        Object.assign(errors, {city4: InputValidationMessages.RequiredField});
    }
    if (!values.orgDetails.headOfOrg.address.zip) {
        Object.assign(errors, {zip4: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.headOfOrg.address.zip.length !== 5) {
        Object.assign(errors, {zip4: 'Zipcode must be 5 digits'});
    } else if (values.orgDetails.headOfOrg.address.zip && !/^\(?(\d{5})$/.test(values.orgDetails.headOfOrg.address.zip)) {
        Object.assign(errors, {zip4: 'Invalid Zipcode, enter numeric value'});
    }

    if (!values.orgDetails.headOfOrg.email) {
        Object.assign(errors, {email4: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.headOfOrg.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.orgDetails.headOfOrg.email)) {
        Object.assign(errors, {email4: 'Invalid email address'});
    }
// Member in charge Details - begins
    if (!values.licenseDetails.memberInCharge.fullName) {
        Object.assign(errors, {fullName5: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.memberInCharge.homePhone) {
        Object.assign(errors, {homePhone5: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.memberInCharge.homePhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.memberInCharge.homePhone)) {
        Object.assign(errors, {homePhone5: 'Invalid phone number, enter numeric value'});
    }
    if (!values.licenseDetails.memberInCharge.address.streetAddress) {
        Object.assign(errors, {streetAddress5: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.memberInCharge.businessPhone) {
        Object.assign(errors, {businessPhone5: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.memberInCharge.businessPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.memberInCharge.businessPhone)) {
        Object.assign(errors, {businessPhone5: 'Invalid phone number, enter numeric value'});
    }
    if (!values.licenseDetails.memberInCharge.address.city) {
        Object.assign(errors, {city5: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.memberInCharge.address.zip) {
        Object.assign(errors, {zip5: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.memberInCharge.address.zip.length !== 5) {
        Object.assign(errors, {zip5: 'Zipcode must be 5 digits'});
    } else if (values.licenseDetails.memberInCharge.address.zip && !/^\(?(\d{5})$/.test(values.licenseDetails.memberInCharge.address.zip)) {
        Object.assign(errors, {zip5: 'Invalid Zipcode, enter numeric value'});
    }

    if (!values.licenseDetails.memberInCharge.email) {
        Object.assign(errors, {email5: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.memberInCharge.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.licenseDetails.memberInCharge.email)) {
        Object.assign(errors, {email5: 'Invalid email address'});
    }
// Bookkeeper Details
    if (!values.licenseDetails.bookkeeper.fullName) {
        Object.assign(errors, {fullName6: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.bookkeeper.homePhone) {
        Object.assign(errors, {homePhone6: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.bookkeeper.homePhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.bookkeeper.homePhone)) {
        Object.assign(errors, {homePhone6: 'Invalid phone number, enter numeric value'});
    }

    if (!values.licenseDetails.bookkeeper.address.streetAddress) {
        Object.assign(errors, {streetAddress6: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.bookkeeper.businessPhone) {
        Object.assign(errors, {businessPhone6: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.bookkeeper.businessPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.bookkeeper.businessPhone)) {
        Object.assign(errors, {businessPhone6: 'Invalid phone number, enter numeric value'});
    }

    if (!values.licenseDetails.bookkeeper.address.city) {
        Object.assign(errors, {city6: InputValidationMessages.RequiredField});
    }
    if (!values.licenseDetails.bookkeeper.address.zip) {
        Object.assign(errors, {zip6: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.bookkeeper.address.zip.length !== 5) {
        Object.assign(errors, {zip6: 'Zipcode must be 5 digits'});
    } else if (values.licenseDetails.bookkeeper.address.zip && !/^\(?(\d{5})$/.test(values.licenseDetails.bookkeeper.address.zip)) {
        Object.assign(errors, {zip6: 'Invalid Zipcode, enter numeric value'});
    }

    if (!values.licenseDetails.bookkeeper.email) {
        Object.assign(errors, {email6: InputValidationMessages.RequiredField});
    } else if (values.licenseDetails.bookkeeper.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.licenseDetails.bookkeeper.email)) {
        Object.assign(errors, {email6: 'Invalid email address'});
    }

//Organization Type
    if (!values.orgDetails.orgCode) {
        Object.assign(errors, {orgCode: InputValidationMessages.RequiredField});
    } else if (values.orgDetails.orgCode === '010 - Other' && !values.orgDetails.orgCodeOther) {
        Object.assign(errors, {orgCodeOther: 'Please Select If type is other'});
    } else if (values.orgDetails.orgCode != '010 - Other') {
        formik.setFieldValue("orgDetails.orgCodeOther", "", false);
    }
    return errors;
}

const callBackEndApi = async (options: any) => {
    const headers = {
        'Content-Type': 'application/json'
    };
    Object.assign(options, {headers: headers});
    try {
        let response = await axios(options);
        apiResponseData.status = response.status
        apiResponseData.statusText = response.statusText
        apiResponseData.statusMessage = response.data.licenseNumber
    } catch (e) {
        // @ts-ignore
        apiResponseData.status = e.response.status;
        // @ts-ignore
        apiResponseData.statusText = e.response.statusText;
        // @ts-ignore
        apiResponseData.statusMessage = e.response.data.error;
        // @ts-ignore
    }
    return apiResponseData;
}

export const apiResponseData = {
    status: 0,
    statusText: "",
    statusMessage: ""
}