export interface PermitHolder {
    permitHolder: {
        cityCode: string,
        orgType: string,
        orgName: string,
        address: {
            streetAddress: string,
            city: string,
            zip: string
        },
        phone: string,
        effectiveDate: string,
        email: string,
        orgActive: boolean,
    }
}

export const PermitHolderDefaultData = {
    permitHolder: {
        cityCode: "",
        orgType: "",
        orgName: "",
        address: {
            streetAddress: "",
            city: "",
            zip: "",
        },
        phone: "",
        effectiveDate: "",
        email: "",
        orgActive: false,
    }
}



