import * as React from 'react'
import { LoadingDots } from '../../../CommonComponents/loading-dots/loading-dots-ui'
import './styles.scss'

type CampaignsSideBarSubmitSectionProps = {
  closeSideBar: () => void
  loading: boolean
  disabled?: boolean
}

const BUTTON_CLASS = `submit-buttons`

const getDisabledClass = (className: string, isDisabled?: boolean) =>
  isDisabled ? `${className}--disabled` : ''

export const CampaignsSideBarSubmitSection = (
  props: CampaignsSideBarSubmitSectionProps
) => {
  return (
    <div className={`${BUTTON_CLASS}`}>
      {props.loading ? (
        <div
          className={`${BUTTON_CLASS}__loading`}
          aria-busy={props.loading}
          aria-describedby="loading"
        >
          <LoadingDots id="loading" />
        </div>
      ) : (
        <>
          <button
            type="button"
            className={`${BUTTON_CLASS}__cancel-button ${getDisabledClass(
              `${BUTTON_CLASS}__cancel-button`,
              props.disabled
            )}`}
            onClick={props.closeSideBar}
            aria-label="Cancel"
            disabled={props.disabled}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`${BUTTON_CLASS}__save-button ${getDisabledClass(
              `${BUTTON_CLASS}__save-button`,
              props.disabled
            )}`}
            aria-label="Save"
            disabled={props.disabled}
          >
            Save
          </button>
        </>
      )}
    </div>
  )
}
