import React from "react";

export const OptionallyRenderSourceGuideCTA = ({setPaymentSourceGuide}: any) => {
    return (
      <button
        className="source-guide-button"
        onClick={() => {
          setPaymentSourceGuide(true);
        }}>Source Guide
      </button>
    )
}