import * as React from 'react'
import moment from 'moment'
import flatten from 'lodash/flatten'
import {
  ConsolidatedScanToEnterPromotion,
  EntryMethod,
  ScanToEnterRuleProgressEvent,
} from '../../../../../api/promotions-fetcher/constants'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
  GenericTableLink,
} from '../../../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import { PlayerPromotionFilterValues } from './ScanToEnterProgressFilterForm/ScanToEnterProgressFilterForm'
import { toEasternTime } from '../../../../../utilities/date-helpers'

export type ScanToEnterProgressTableRow = {
  promotionName: GenericTableLink
  totalScans: number
  progress: string
}

export const scanToEnterProgressTableColumnMap: GenericTableColumnMap<ScanToEnterProgressTableRow> =
  [
    {
      key: 'promotionName',
      columnText: 'Promotion',
      columnType: GenericTableColumnType.LINK,
    },
    {
      key: 'totalScans',
      columnText: 'Total Scans',
      columnType: GenericTableColumnType.DEFAULT,
      widthPx: 130,
    },
    {
      key: 'progress',
      columnText: 'Progress',
      columnType: GenericTableColumnType.DEFAULT,
      widthPx: 130,
    },
  ]

export const filterScanToEnterProgressData = (
  rows: ConsolidatedScanToEnterPromotion[],
  filterValues: PlayerPromotionFilterValues
) => {
  return rows.filter((promotion) => {
    if (
      !promotion.rewardGroups.find(
        (rewardGroup) => rewardGroup.currentProgress.length !== 0
      )
    ) {
      return false
    }
    if (
      filterValues.currentPromotions &&
      promotion.promotionEndDate &&
      moment(promotion.promotionEndDate).isBefore(moment())
    ) {
      return false
    }

    const fromScanDate = filterValues.fromScanDate
      ? moment(filterValues.fromScanDate)
      : undefined
    const throughScanDate = filterValues.throughScanDate
      ? moment(filterValues.throughScanDate)
      : undefined

    for (const group of promotion.rewardGroups) {
      if (
        group.currentProgress.some((scan) => {
          const scanDate = moment(scan.applicationTimestamp)
          if (fromScanDate && throughScanDate) {
            return scanDate.isBetween(
              fromScanDate,
              throughScanDate,
              'day',
              '[]'
            )
          } else if (fromScanDate) {
            return moment(scanDate).isSameOrAfter(fromScanDate, 'day')
          } else if (throughScanDate) {
            return moment(scanDate).isSameOrBefore(throughScanDate, 'day')
          } else {
            return true
          }
        })
      ) {
        return true
      }
    }
    return false
  })
}

type ScanToEnterRuleProgress = {
  scans: number
  required: number
}

export const mapScanToEnterProgressRow = (
  consolidatedPromotion: ConsolidatedScanToEnterPromotion,
  setOpenPromotion: (promotionId: string) => void
): ScanToEnterProgressTableRow => {
  // Will need to be adjusted with addition of multiple reward groups depending on how we determine that information should be displayed
  const progress: ScanToEnterRuleProgress[] = flatten(
    consolidatedPromotion.rewardGroups.map((rewardGroup) => {
      return rewardGroup.rules.map((rule) => {
        return {
          required: rule.ruleQuantity,
          scans: rewardGroup.currentProgress.filter(
            (scan) => scan.ruleId === rule.ruleId
          ).length,
        }
      })
    })
  )

  // If number of scans exceeds the number required for a given rule, cap progress at the rule quantity needed
  const overallProgress: ScanToEnterRuleProgress = progress.reduce(
    (prev: ScanToEnterRuleProgress, curr) => ({
      scans: prev.scans + Math.min(curr.required, curr.scans),
      required: prev.required + curr.required,
    }),
    { scans: 0, required: 0 }
  )

  return {
    promotionName: {
      value: consolidatedPromotion.promotionName,
      onClick: () => setOpenPromotion(consolidatedPromotion.promotionId),
      ariaLabel: `See Progress Details for ${consolidatedPromotion.promotionName}`,
    },
    totalScans: consolidatedPromotion.rewardGroups.reduce(
      (prev, curr) => prev + curr.currentProgress.length,
      0
    ),
    progress: overallProgress.scans + '/' + overallProgress.required,
  }
}

export type ScanToEnterRuleGameSummary = {
  gameId: string
  scans: ScanToEnterRuleProgressEvent[]
}

export type ScanToEnterRuleDetailsRow = {
  gameId: string
  numberOfScans: number
  viewScansButton: JSX.Element
}

export type RuleIdentifier = {
  rewardGroupId: string
  rewardGroupIndex: number
  ruleId: string
  ruleIndex: number
}
export type OpenGameDetails = {
  ruleIdentifier: RuleIdentifier
  gameId: string
  scans: ScanToEnterRuleProgressEvent[]
}

export const scanToEnterRuleDetailsColumnMap: GenericTableColumnMap<ScanToEnterRuleDetailsRow> =
  [
    {
      key: 'gameId',
      columnText: 'Game ID',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'numberOfScans',
      columnText: 'Scans',
      columnType: GenericTableColumnType.DEFAULT,
      widthPx: 80,
    },
    {
      key: 'viewScansButton',
      columnText: '',
      columnType: GenericTableColumnType.DEFAULT,
      widthPx: 120,
    },
  ]

export const mapScanToEnterRuleDetailsRow = (
  gameSummary: ScanToEnterRuleGameSummary,
  ruleIdentifier: RuleIdentifier,
  setOpenGameDetails: (details: OpenGameDetails) => void
): ScanToEnterRuleDetailsRow => {
  return {
    gameId: gameSummary.gameId,
    numberOfScans: gameSummary.scans.length,
    viewScansButton:
      gameSummary.scans.length > 0 ? (
        <button
          className={'scan-to-enter-progress-rule-section__button'}
          type="button"
          onClick={() =>
            setOpenGameDetails({
              ruleIdentifier: ruleIdentifier,
              gameId: gameSummary.gameId,
              scans: gameSummary.scans,
            })
          }
          aria-label="View Scans"
        >
          View Scans
        </button>
      ) : (
        <></>
      ),
  }
}

export type ScanToEnterProgressGameScanRow = {
  ticketNumber: string
  ticketEntryMethod: string
  scanDateTime: string
}

export const scanToEnterProgressGameScanColumnMap: GenericTableColumnMap<ScanToEnterProgressGameScanRow> =
  [
    {
      key: 'ticketNumber',
      columnText: 'Ticket Number',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'ticketEntryMethod',
      columnText: 'Ticket Entry Method',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'scanDateTime',
      columnText: 'Scan Date and Time',
      columnType: GenericTableColumnType.DEFAULT,
    },
  ]

const mapEntryMethodToString = (method: EntryMethod) => {
  switch (method) {
    case EntryMethod.mobile:
      return 'Mobile-Scan'
    case EntryMethod.web:
      return 'Manual-Web'
    default:
      return 'Unknown'
  }
}

export const mapScanToEnterProgressGameScanRow = (
  scan: ScanToEnterRuleProgressEvent
): ScanToEnterProgressGameScanRow => ({
  ticketNumber: scan.barcode,
  ticketEntryMethod: mapEntryMethodToString(scan.entryMethod),
  scanDateTime: toEasternTime(scan.applicationTimestamp).format(
    'MMM D, YYYY HH:mm:ss'
  ),
})
