import React, { useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import { setTicketHoldText, setVariables, TicketHoldText } from './functions'
import { CheckboxComponent } from '../CheckboxComponent'
import stylesMessageBox from './css/stylesMessageBox.module.css'
import infoIcon from '../../../icons/question-circle.svg'
import { keyPressedType } from '../../../services/Commons'
import { AccessibleKey } from '../../common'

// There is a toggle logic added to this code, which is not used in the UI.
// It is disabled on - setCancelToggle(!cancelToggle). In future if toggle is required to be added this part can be enabled.

export const TicketInformation = ({ MCAPlayerData, setOpenBarcode }: any) => {
  let ticketInfoString = JSON.stringify(MCAPlayerData)
  let ticketInfoJson = JSON.parse(ticketInfoString)
  let ticketSearchResponse = ticketInfoJson.ticket

  const ticketOnHoldValue = ticketSearchResponse.onHold
  const [cancelToggle, setCancelToggle] = useState<boolean>(false)
  const [ticketOnHold, setTicketOnHold] = useState<boolean>(false)

  const [ticketOnHoldText, setTicketOnHoldText] = useState<string>(
    TicketHoldText.TicketOnHold
  )
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [showPopup, setShowPop] = useState<boolean>(false)
  useEffect(() => {
    if (!dataLoaded) {
      setDataLoaded(true)
      setTicketHoldText(ticketOnHoldValue)
    }
  })

  setVariables(setTicketOnHold, setTicketOnHoldText)

  return (
    <>
      <div className={styles['MCA-table']}>
        <div
          className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-label']} `}
        >
          <div tabIndex={0}>Game ID</div>
          <div tabIndex={0}>Ticket Barcode</div>
          <div tabIndex={0}>Game Type</div>
          <div
            tabIndex={0}
            onClick={() => {
              setShowPop(true)
            }}
          >
            Ticket On Hold
            <img
              src={infoIcon}
              className={styles['menu-exclamation-icon']}
              alt={'infoIcon'}
            />
          </div>
        </div>
        <div className={styles['MCA-table-data-body']}>
          <div
            style={{ backgroundColor: 'rgb(30, 75, 117)' }}
            className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-data']}`}
          >
            <div tabIndex={0} className={styles['MCA-table-data-data-text']}>
              {ticketSearchResponse.gameId === null
                ? ''
                : ticketSearchResponse.gameId}
            </div>
            <div
              tabIndex={0}
              className={styles['MCA-table-data-data-link']}
              onClick={() => setOpenBarcode(ticketSearchResponse.barcode)}
              aria-label={`View Barcode Details for ${ticketSearchResponse.barcode}`}
              onKeyDown={(keyEvent) => {
                if (
                  keyEvent.key === AccessibleKey.enter ||
                  keyEvent.key === AccessibleKey.space
                ) {
                  keyEvent.preventDefault()
                  setOpenBarcode(ticketSearchResponse.barcode)
                }
              }}
            >
              {ticketSearchResponse.barcode === null
                ? ''
                : ticketSearchResponse.barcode}
            </div>
            <div tabIndex={0} className={styles['MCA-table-data-data-text']}>
              {ticketSearchResponse.gameType === null
                ? ''
                : ticketSearchResponse.gameType}
            </div>
            {
              <div
                tabIndex={0}
                className={styles['SA-inherit-btn']}
                onClick={
                  () => null
                  // setCancelToggle(!cancelToggle)
                }
              >
                <div className={styles['SA-inherit-btn-check']}>
                  <CheckboxComponent
                    isChecked={ticketOnHold}
                    text={ticketOnHoldText}
                  />
                </div>
              </div>
            }
            {cancelToggle && (
              <div className={stylesMessageBox['window-container']}>
                <div
                  id={stylesMessageBox['update-profile-save-box-container']}
                  className={
                    stylesMessageBox['update-profile-save-box-container']
                  }
                >
                  <div
                    tabIndex={0}
                    className={
                      stylesMessageBox['update-profile-save-box-message']
                    }
                  >
                    Confirm Ticket off Hold?
                  </div>
                  <div
                    role="button"
                    className={
                      stylesMessageBox['update-profile-save-box-button-yes']
                    }
                    onClick={() => {
                      setCancelToggle(!cancelToggle)
                      setTicketHoldText(!ticketOnHold)
                    }}
                  >
                    <div
                      className={
                        stylesMessageBox['update-profile-save-button-yes-text']
                      }
                    >
                      Yes, Confirm
                    </div>
                  </div>
                  <div
                    role="button"
                    className={
                      stylesMessageBox[
                        'player-email-and-phone-message-box-button-no'
                      ]
                    }
                    onClick={() => setCancelToggle(!cancelToggle)}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            )}
            {showPopup && (
              <div className={stylesMessageBox['window-container']}>
                <div
                  tabIndex={0}
                  id="manually-approve-kyc-container"
                  className={stylesMessageBox['manually-approve-kyc-container']}
                >
                  <div
                    tabIndex={0}
                    className={stylesMessageBox['manually-approve-kyc-text']}
                  >
                    Ticket on Hold Help
                  </div>
                  <div
                    tabIndex={0}
                    className={stylesMessageBox['manually-approve-kyc-message']}
                  >
                    Placing a ticket on hold in this system only affects digital
                    activities, such as preventing a mobile claim. It does not
                    affect the ticket status in the retail gaming system.
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e: any) => {
                      if (
                        keyPressedType(e) === 13 ||
                        keyPressedType(e) === 0 ||
                        keyPressedType(e) === 32
                      ) {
                        setShowPop(false)
                      }
                    }}
                    onClick={() => {
                      setShowPop(false)
                    }}
                    className={
                      stylesMessageBox['manually-approve-kyc-button-yes']
                    }
                  >
                    <div
                      className={
                        stylesMessageBox['manually-approve-kyc-yes-text']
                      }
                    >
                      Ok
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
