import moment from 'moment'
import React, { SetStateAction, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { annuitiesRoutePath } from '../../../../../config.js'
import { KEY_CODES } from '../../../../../constants.js'
import { AnnuityPaymentScheduleInfo } from '../../../../../pages/AnnuitiesPages/TPAManagement/AnnuityFullPaymentSchedulePage/interfaces'
import { PayTaxIndicatorMapping } from '../../../../../pages/PaymentsPages/Constants'
import { keyPressedType } from '../../../../../services/Commons'
import { PaginationCommonComponent } from '../../../../CommonComponents/PaginationCommonComponent'
import { getPageData } from '../../../../PlayersComponents/PlayerSearchTableComponent/functions'
import {
  getTaxDeclaration,
  mapTaxDeclarationCode,
} from '../../../Common/PaymentDetailsCmpt/functions'
import styles from './css/style.module.css'

export const PaymentScheduleListCmpt: React.FC<{
  currentPage: number
  setCurrentPage: React.Dispatch<SetStateAction<number>>
  paymentSchedule: AnnuityPaymentScheduleInfo[]
}> = ({ currentPage, setCurrentPage, paymentSchedule }) => {
  const history = useHistory()
  const tpaID =
    window.location.href.split('/')[window.location.href.split('/').length - 1]
  const normalizePage = (totalPages: number, currentPage: number) => {
    const number: number = totalPages - currentPage * 10
    if (number > 10) {
      return currentPage * 10 + 10
    } else {
      return totalPages
    }
  }

  return (
    <>
      <div className={styles['table']}>
        <div className={styles['table-data-header']}>
          <div aria-live="assertive">{`Displaying ${
            paymentSchedule.length <= 0 ? 0 : currentPage * 10 + 1
          }-${normalizePage(paymentSchedule.length, currentPage)} of ${
            paymentSchedule.length
          } results`}</div>
        </div>

        <div
          className={`${styles['table-data-grid-header']} ${styles['table-data-label']}`}
        >
          <div>Payment No.</div>
          <div>Payment Date</div>
          <div>Payee</div>
          <div>Status</div>
          <div>Pay Cycle</div>
          <div>Payment Amount</div>
          <div>Final Payment Date</div>
          <div>Tax Designation</div>
          <div></div>
          <div></div>
        </div>

        <div className={styles['table-data-body']}>
          {paymentSchedule.length > 0 &&
            getPageData(paymentSchedule, currentPage).map(
              (payment: AnnuityPaymentScheduleInfo, i: number) => (
                <div key={i} className={`${styles['table-data-grid-body']}`}>
                  <div className={styles['table-data-text']}>
                    {payment.paymentNumber} of {payment.lastPaymentNumber}
                  </div>
                  <div className={styles['table-data-text']}>
                    {moment(payment.paymentDate).format('MM/DD/YYYY')}
                  </div>
                  <div className={styles['table-data-text']}>
                    {payment.payeeName}
                  </div>
                  <div className={styles['table-data-text']}>
                    {payment.status}
                  </div>
                  <div className={styles['table-data-text']}>
                    {payment.payCycle}
                  </div>
                  <div className={styles['table-data-text']}>
                    {payment.paymentAmount}
                  </div>
                  <div className={styles['table-data-text']}>
                    {moment(payment.finalPaymentDate).format('MM/DD/YYYY')}
                  </div>
                  <div className={styles['table-data-text']}>
                    {mapTaxDeclarationCode(
                      getTaxDeclaration(payment.subAccount)
                    )}
                  </div>
                  <div></div>
                  <button
                    tabIndex={0}
                    className={styles['payment-details-btn']}
                    onKeyPress={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        history.push(
                          `${annuitiesRoutePath}/annuity-full-payment-details?id=${tpaID}&paymentId=${payment.paymentRuleId}&num=${payment.paymentNumber}`
                        )
                      }
                    }}
                    onClick={() => {
                      history.push(
                        `${annuitiesRoutePath}/annuity-full-payment-details?id=${tpaID}&paymentId=${payment.paymentRuleId}&num=${payment.paymentNumber}`
                      )
                    }}
                  >
                    Full Payment Details
                  </button>
                </div>
              )
            )}
        </div>
        <div className={styles['table-data-footer']}>
          <div
            aria-live="assertive"
            className={styles['table-page-label']}
          >{`Page ${currentPage + 1} of ${Math.ceil(
            paymentSchedule.length / 10
          ).toString()}`}</div>
          {
            <PaginationCommonComponent
              playerData={paymentSchedule}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        </div>
      </div>
    </>
  )
}
