import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import InputMask from 'react-input-mask'
import { AccountStatus } from '../../../../Constants/AnnuityFlowConstants/AccountStatus.js'
import {
  GameType,
  GameTypeOptions,
  Games,
} from '../../../../Constants/AnnuityFlowConstants/Games'
import { PayFrequency } from '../../../../Constants/AnnuityFlowConstants/PayFrequency.js'
import { ClaimCenterLocations } from '../../../../Constants/AnnuityFlowConstants/claim-center-locations'
import { YesOrNoValue } from '../../../../Constants/YesOrNo.js'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { generateEndDate } from '../../../../pages/AnnuitiesPages/CreationFlowPage/functions'
import { keyPressedType } from '../../../../services/Commons'
import { CheckboxComponent } from '../../../CommonComponents/CheckboxComponent'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { GraduatedPaymentSchedule } from '../GraduatedPaymentScheduleCmpt'
import styles from './css/styles.module.css'
import {
  getGraduatedPaymentSchedule,
  getLifetimeRecurringAmt,
} from './functions'

export const CreationFlowStep1Cmpt = inject('annuitiesStore')(
  observer(
    ({
      annuitiesStore,
      timePaymentAccountForm,
      firstPaymentForm,
      issueFirstPayment,
      setIssueFirstPayment,
    }: any) => {
      // check first payment if empty or not to see where user is coming from
      const [currentPage, setCurrentPage] = useState<number>(0)
      const [graduatedSchedule, setGraduatedSchedule] = useState<number[]>([])
      const [showGraduatedSchedule, setShowGraduatedSchedule] = useState(false)
      const checkFirstPaymentId = (firstPaymentID: string | null): boolean => {
        if (firstPaymentID === '' || firstPaymentID === null) {
          return true
        } else {
          return false
        }
      }

      const bannerContext = useContext(BannerContext)
      const isFirstPaymentIdEmpty: boolean = checkFirstPaymentId(
        annuitiesStore.firstPaymentID
      )

      const onLifetimePaymentChange = (name: string, value: string) => {
        timePaymentAccountForm.setFieldValue(name, value)
        if (
          !isFirstPaymentIdEmpty &&
          value === 'No' &&
          !timePaymentAccountForm.values.recurringPaymentAmount
        ) {
          timePaymentAccountForm.setFieldValue(
            'recurringPaymentAmount',
            getLifetimeRecurringAmt(
              timePaymentAccountForm.values.winAmount,
              timePaymentAccountForm.values.firstPaymentAmount,
              timePaymentAccountForm.values.remainingPayments
            )
          )
        }
      }

      // useEffect specific for updating the graduated payments data
      useEffect(() => {
        const fetchGraduatedPaymentData = async () => {
          const convertedGraduatedRate: string = parseFloat(
            timePaymentAccountForm.values.graduationRate
          ).toFixed(2)
          const convertedWinAmount: string = String(
            timePaymentAccountForm.values.winAmount
          )
          const convertedRemainingPayments: string = String(
            timePaymentAccountForm.values.remainingPayments
          )

          try {
            if (
              Games.find(
                (game) => game.name === timePaymentAccountForm.values.gameName
              )?.graduated &&
              timePaymentAccountForm.touched.remainingPayments &&
              timePaymentAccountForm.touched.graduationRate &&
              timePaymentAccountForm.touched.winAmount &&
              !timePaymentAccountForm.errors.remainingPayments &&
              !timePaymentAccountForm.errors.graduationRate &&
              !timePaymentAccountForm.errors.winAmount
            ) {
              if (isFirstPaymentIdEmpty && issueFirstPayment) {
                //exception flow, remaining payments are fine
                await getGraduatedPaymentSchedule(
                  convertedRemainingPayments,
                  convertedGraduatedRate,
                  convertedWinAmount,
                  setGraduatedSchedule
                )
              } else {
                const updatedRemainingPayments = (
                  parseInt(
                    timePaymentAccountForm.values.remainingPayments,
                    10
                  ) + 1
                ).toString()
                await getGraduatedPaymentSchedule(
                  updatedRemainingPayments,
                  convertedGraduatedRate,
                  convertedWinAmount,
                  setGraduatedSchedule
                )
              }
              setShowGraduatedSchedule(true)
            } else {
              setShowGraduatedSchedule(false)
            }
          } catch (error) {
            bannerContext.setBannerInfo({
              message: `${error}`,
              error: true,
            })
          }
        }

        fetchGraduatedPaymentData()
      }, [
        timePaymentAccountForm.values.winAmount,
        timePaymentAccountForm.values.graduationRate,
        timePaymentAccountForm.values.remainingPayments,
        issueFirstPayment,
      ])

      // useEffect specific for updating final Payment Date dynamic functionality
      useEffect(() => {
        if (timePaymentAccountForm.values.lifetimePayment === 'No') {
          if (
            timePaymentAccountForm.values.payFrequency !== '' &&
            timePaymentAccountForm.values.nextPaymentDate !== '' &&
            timePaymentAccountForm.values.remainingPayments !== ''
          ) {
            timePaymentAccountForm.setFieldValue(
              'finalPaymentDate',
              generateEndDate(
                timePaymentAccountForm.values.payFrequency,
                timePaymentAccountForm.values.nextPaymentDate,
                timePaymentAccountForm.values.remainingPayments
              )
            )
          } else {
            timePaymentAccountForm.setFieldValue(
              'finalPaymentDate',
              'More information required.'
            )
          }
        } else if (timePaymentAccountForm.values.lifetimePayment === 'Yes') {
          timePaymentAccountForm.setFieldValue(
            'finalPaymentDate',
            'Lifetime Payment'
          )
        } else {
          timePaymentAccountForm.setFieldValue(
            'finalPaymentDate',
            'More information required.'
          )
        }
      }, [
        timePaymentAccountForm.values.lifetimePayment,
        timePaymentAccountForm.values.payFrequency,
        timePaymentAccountForm.values.remainingPayments,
        timePaymentAccountForm.values.nextPaymentDate,
        timePaymentAccountForm.values.game,
      ])

      const handleIssueFirstPaymentChanges = () => {
        if (!issueFirstPayment) {
          // Reset first payment date if issuing first payment
          timePaymentAccountForm.setFieldValue('firstPaymentDate', '')
        }
        setIssueFirstPayment(!issueFirstPayment)

        timePaymentAccountForm.validateForm()
      }

      return (
        <>
          <div className={`${styles.main_page_container}`}>
            <div className={`${styles.grid_col_2}`}>
              {/* Column 1 */}
              <div className={`${styles.cmpnt_container}`}>
                <div className={` ${styles.flex_sp_bw}`}>
                  <h4>Annuity Info</h4>
                  {isFirstPaymentIdEmpty && (
                    <div className={`${styles.flex}`}>
                      <div
                        className={`${styles.checkbox_container}`}
                        tabIndex={0}
                        onKeyDown={(event) => {
                          if (
                            keyPressedType(event) === KEY_CODES.ENTER ||
                            keyPressedType(event) === KEY_CODES.SPACE
                          ) {
                            handleIssueFirstPaymentChanges()
                          }
                        }}
                        onClick={handleIssueFirstPaymentChanges}
                      >
                        <CheckboxComponent isChecked={issueFirstPayment} />
                      </div>
                      <p style={{ opacity: '0.6' }}>
                        <em>Issue first payment from Annuity System</em>
                      </p>
                    </div>
                  )}
                </div>
                <p>
                  Review the information below and make any necessary changes
                </p>
                <div className={`${styles.grid_col_2}`}>
                  <div className={`${styles.game_info_container}`}>
                    <div className={`${styles.grid_col_2}`}>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="game_type">Game Type*</label>
                        <div className={`${styles.custom_select_div}`}>
                          <select
                            onChange={(event) => {
                              // reset game name every time gameType changes
                              timePaymentAccountForm.setFieldValue(
                                'gameName',
                                ''
                              )
                              timePaymentAccountForm.setFieldValue(
                                event.target.name,
                                event.target.value
                              )
                              if (
                                event.target.value === GameTypeOptions.Instant
                              ) {
                                timePaymentAccountForm.setFieldValue(
                                  'drawDate',
                                  ''
                                )
                              }
                            }}
                            onBlur={timePaymentAccountForm.handleBlur}
                            className={`${styles.custom_select}`}
                            name="gameType"
                            id="gameType"
                            value={
                              timePaymentAccountForm.values.gameType === ''
                                ? 'default'
                                : timePaymentAccountForm.values.gameType
                            }
                          >
                            <option value="default" disabled>
                              Select Game...
                            </option>
                            {Object.values(GameTypeOptions).map(
                              (game: GameType) => (
                                <option value={game} key={game}>
                                  {game}
                                </option>
                              )
                            )}
                          </select>
                          <span className={`${styles.custom_arrow}`}></span>
                        </div>
                        {timePaymentAccountForm.errors.gameType &&
                          timePaymentAccountForm.touched.gameType && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={
                                timePaymentAccountForm.errors.gameType
                              }
                            />
                          )}
                      </div>
                      {timePaymentAccountForm.values.gameType === 'Draw' ? (
                        <div className={`${styles.info_detail}`}>
                          <label htmlFor="gameName">Game Name*</label>
                          <div className={`${styles.custom_select_div}`}>
                            <select
                              onChange={timePaymentAccountForm.handleChange}
                              onBlur={timePaymentAccountForm.handleBlur}
                              className={`${styles.custom_select}`}
                              name="gameName"
                              id="gameName"
                              value={
                                timePaymentAccountForm.values.gameName === ''
                                  ? 'default'
                                  : timePaymentAccountForm.values.gameName
                              }
                            >
                              <option value="default" disabled>
                                Select Game...
                              </option>
                              {Games.map((game: any, index: number) => (
                                <option
                                  value={game.name}
                                  key={index}
                                >{`${game.name}`}</option>
                              ))}
                            </select>
                            <span className={`${styles.custom_arrow}`}></span>
                          </div>
                          {timePaymentAccountForm.errors.gameName &&
                            timePaymentAccountForm.touched.gameName && (
                              <ErrorMessageFieldCommonComponent
                                errorMessage={
                                  timePaymentAccountForm.errors.gameName
                                }
                              />
                            )}
                        </div>
                      ) : (
                        <div className={`${styles.info_detail}`}>
                          <label htmlFor="gameName">Game Name*</label>
                          <input
                            type="text"
                            className={`${styles.custom_input} text-uppercase`}
                            onChange={timePaymentAccountForm.handleChange}
                            onBlur={timePaymentAccountForm.handleBlur}
                            name="gameName"
                            id="gameName"
                            value={timePaymentAccountForm.values.gameName}
                          />
                          {timePaymentAccountForm.errors.gameName &&
                            timePaymentAccountForm.touched.gameName && (
                              <ErrorMessageFieldCommonComponent
                                errorMessage={
                                  timePaymentAccountForm.errors.gameName
                                }
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="payFrequency">Cadence*</label>
                    <div className={`${styles.custom_select_div}`}>
                      <select
                        onChange={timePaymentAccountForm.handleChange}
                        onBlur={timePaymentAccountForm.handleBlur}
                        className={`${styles.custom_select}`}
                        name="payFrequency"
                        id="payFrequency"
                        value={
                          timePaymentAccountForm.values.payFrequency === ''
                            ? 'default'
                            : timePaymentAccountForm.values.payFrequency
                        }
                      >
                        <option value="default" disabled>
                          Select Frequency...
                        </option>
                        {PayFrequency.map(
                          (payFrequency: string, index: number) => (
                            <option
                              value={payFrequency}
                              key={index}
                            >{`${payFrequency}`}</option>
                          )
                        )}
                      </select>
                      <span className={`${styles.custom_arrow}`}></span>
                    </div>
                    {timePaymentAccountForm.errors.payFrequency &&
                      timePaymentAccountForm.touched.payFrequency && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.payFrequency
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="drawDate">
                      Draw Date
                      {timePaymentAccountForm.values.gameType === 'Instant'
                        ? ''
                        : '*'}
                    </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      className={`${styles.custom_input}`}
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="drawDate"
                      id="drawDate"
                      value={timePaymentAccountForm.values.drawDate}
                      disabled={
                        timePaymentAccountForm.values.gameType === 'Instant'
                      }
                    />
                    {timePaymentAccountForm.errors.drawDate &&
                      timePaymentAccountForm.touched.drawDate && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={timePaymentAccountForm.errors.drawDate}
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="claimDate">Claim Date*</label>
                    <input
                      type="date"
                      max="9999-12-31"
                      className={`${styles.custom_input}`}
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="claimDate"
                      id="claimDate"
                      value={timePaymentAccountForm.values.claimDate}
                    />
                    {timePaymentAccountForm.errors.claimDate &&
                      timePaymentAccountForm.touched.claimDate && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={timePaymentAccountForm.errors.claimDate}
                        />
                      )}
                  </div>
                  <div
                    style={{ position: 'relative' }}
                    className={`${styles.info_detail}`}
                  >
                    <label htmlFor="remainingPayments">
                      Remaining Payments*
                    </label>
                    <input
                      type="number"
                      min="0"
                      className={`${styles.custom_input}`}
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="remainingPayments"
                      id="remainingPayments"
                    />
                    {timePaymentAccountForm.errors.remainingPayments &&
                      timePaymentAccountForm.touched.remainingPayments && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.remainingPayments
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="lifetimePayment">Lifetime Payment*</label>
                    <div className={`${styles.custom_select_div}`}>
                      <select
                        onChange={(event) =>
                          onLifetimePaymentChange(
                            event.target.name,
                            event.target.value
                          )
                        }
                        onBlur={timePaymentAccountForm.handleBlur}
                        className={`${styles.custom_select}`}
                        name="lifetimePayment"
                        id="lifetimePayment"
                        value={
                          timePaymentAccountForm.values.lifetimePayment === ''
                            ? 'default'
                            : timePaymentAccountForm.values.lifetimePayment
                        }
                      >
                        <option value="default" disabled>
                          Select...
                        </option>
                        {YesOrNoValue.map((value: string, index: number) => (
                          <option
                            value={value}
                            key={index}
                          >{`${value}`}</option>
                        ))}
                      </select>
                      <span className={`${styles.custom_arrow}`}></span>
                    </div>
                    {timePaymentAccountForm.errors.lifetimePayment &&
                      timePaymentAccountForm.touched.lifetimePayment && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.lifetimePayment
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="tpaDocumentId">Document ID*</label>
                    <input
                      type="text"
                      className={`${styles.custom_input}`}
                      min="0"
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="tpaDocumentId"
                      id="tpaDocumentId"
                      value={timePaymentAccountForm.values.tpaDocumentId}
                    />
                    {timePaymentAccountForm.errors.tpaDocumentId &&
                      timePaymentAccountForm.touched.tpaDocumentId && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.tpaDocumentId
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="investmentAccountNumber">
                      Investment Account Number
                    </label>
                    <input
                      type="text"
                      className={`${styles.custom_input}`}
                      min="0"
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="investmentAccountNumber"
                      id="investmentAccountNumber"
                      value={
                        timePaymentAccountForm.values.investmentAccountNumber
                      }
                    />
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="accountStatus">Account Status*</label>
                    <div className={`${styles.custom_select_div}`}>
                      <select
                        onChange={timePaymentAccountForm.handleChange}
                        onBlur={timePaymentAccountForm.handleBlur}
                        className={`${styles.custom_select}`}
                        name="accountStatus"
                        id="accountStatus"
                        value={
                          timePaymentAccountForm.values.accountStatus === ''
                            ? 'default'
                            : timePaymentAccountForm.values.accountStatus
                        }
                      >
                        <option value="default" disabled>
                          Select...
                        </option>
                        {AccountStatus.map(
                          (accountStatus: string, index: number) => (
                            <option
                              value={accountStatus}
                              key={index}
                            >{`${accountStatus}`}</option>
                          )
                        )}
                      </select>
                      <span className={`${styles.custom_arrow}`}></span>
                    </div>
                    {timePaymentAccountForm.errors.accountStatus &&
                      timePaymentAccountForm.touched.accountStatus && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.accountStatus
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="finalPaymentDate">Final Payment Date</label>
                    <div className={`${styles.read_only}`}>
                      {timePaymentAccountForm.values.finalPaymentDate}
                    </div>
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="firstPaymentDate">
                      First Payment Date*
                    </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      disabled={issueFirstPayment}
                      className={`${styles.custom_input}`}
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="firstPaymentDate"
                      id="firstPaymentDate"
                      value={timePaymentAccountForm.values.firstPaymentDate}
                    />
                    {timePaymentAccountForm.errors.firstPaymentDate &&
                      timePaymentAccountForm.touched.firstPaymentDate && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.firstPaymentDate
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="nextPaymentDate">Next Payment Date*</label>
                    <input
                      type="date"
                      min={
                        timePaymentAccountForm.values.firstPaymentDate
                          ? moment(
                              timePaymentAccountForm.values.firstPaymentDate
                            )
                              .add(1, 'day')
                              .format('YYYY-MM-DD')
                          : undefined
                      }
                      max="9999-12-31"
                      className={`${styles.custom_input}`}
                      onChange={timePaymentAccountForm.handleChange}
                      onBlur={timePaymentAccountForm.handleBlur}
                      name="nextPaymentDate"
                      id="nextPaymentDate"
                      value={timePaymentAccountForm.values.nextPaymentDate}
                    />
                    {timePaymentAccountForm.errors.nextPaymentDate &&
                      timePaymentAccountForm.touched.nextPaymentDate && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={
                            timePaymentAccountForm.errors.nextPaymentDate
                          }
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="winAmount">Ticket Win Amount*</label>
                    <CurrencyInput
                      className={`${styles.custom_input}`}
                      name="winAmount"
                      id="winAmount"
                      prefix="$"
                      value={timePaymentAccountForm.values.winAmount}
                      onValueChange={(value, name) => {
                        if (value === undefined) {
                          timePaymentAccountForm.setFieldValue('winAmount', '')
                        } else {
                          timePaymentAccountForm.setFieldValue(
                            'winAmount',
                            value
                          )
                        }
                      }}
                      onBlur={timePaymentAccountForm.handleBlur}
                      decimalsLimit={2}
                      decimalScale={2}
                      disableAbbreviations={true}
                      allowNegativeValue={false}
                    />

                    {timePaymentAccountForm.errors.winAmount &&
                      timePaymentAccountForm.touched.winAmount && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={timePaymentAccountForm.errors.winAmount}
                        />
                      )}
                  </div>
                  {!Games.find(
                    (game) =>
                      game.name === timePaymentAccountForm.values.gameName
                  )?.graduated ? (
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="recurringPaymentAmount">
                        Recurring Payment Amount*
                      </label>
                      <CurrencyInput
                        className={`${styles.custom_input}`}
                        name="recurringPaymentAmount"
                        id="recurringPaymentAmount"
                        prefix="$"
                        value={
                          timePaymentAccountForm.values.recurringPaymentAmount
                        }
                        onValueChange={(value, name) => {
                          if (value === undefined) {
                            timePaymentAccountForm.setFieldValue(
                              'recurringPaymentAmount',
                              0
                            )
                          } else {
                            timePaymentAccountForm.setFieldValue(
                              'recurringPaymentAmount',
                              value
                            )
                          }
                        }}
                        onBlur={timePaymentAccountForm.handleBlur}
                        decimalsLimit={2}
                        decimalScale={2}
                        disableAbbreviations={true}
                        allowNegativeValue={false}
                      />

                      {timePaymentAccountForm.errors.recurringPaymentAmount &&
                        timePaymentAccountForm.touched
                          .recurringPaymentAmount && (
                          <ErrorMessageFieldCommonComponent
                            errorMessage={
                              timePaymentAccountForm.errors
                                .recurringPaymentAmount
                            }
                          />
                        )}
                    </div>
                  ) : (
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="graduationRate">Graduation Rate*</label>
                      <CurrencyInput
                        className={`${styles.custom_input}`}
                        name="graduationRate"
                        id="graduationRate"
                        prefix=""
                        value={timePaymentAccountForm.values.graduationRate}
                        onValueChange={(value, name) => {
                          if (value === undefined) {
                            timePaymentAccountForm.setFieldValue(
                              'graduationRate',
                              0
                            )
                          } else {
                            timePaymentAccountForm.setFieldValue(
                              'graduationRate',
                              value
                            )
                          }
                        }}
                        onBlur={timePaymentAccountForm.handleBlur}
                        decimalsLimit={2}
                        decimalScale={2}
                        disableAbbreviations={true}
                        allowNegativeValue={false}
                      />

                      {timePaymentAccountForm.errors.graduationRate &&
                        timePaymentAccountForm.touched.graduationRate && (
                          <ErrorMessageFieldCommonComponent
                            errorMessage={
                              timePaymentAccountForm.errors.graduationRate
                            }
                          />
                        )}
                    </div>
                  )}
                </div>
              </div>
              {/* Column 2 */}
              {isFirstPaymentIdEmpty && !issueFirstPayment && (
                <>
                  <div className={`${styles.first_pmt_container}`}>
                    <h4>Payment Info</h4>
                    <p>
                      Review the information below and make any necessary
                      changes
                    </p>
                    <div className={`${styles.grid_col_2}`}>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="payFileKey">Pay File Key*</label>
                        <input
                          type="text"
                          className={`${styles.custom_input} text-uppercase`}
                          onChange={firstPaymentForm.handleChange}
                          onBlur={firstPaymentForm.handleBlur}
                          name="payFileKey"
                          id="payFileKey"
                          value={firstPaymentForm.values.payFileKey}
                        />
                        {firstPaymentForm.errors.payFileKey &&
                          firstPaymentForm.touched.payFileKey && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={firstPaymentForm.errors.payFileKey}
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="checkNumber">Check No.*</label>
                        <input
                          type="text"
                          className={`${styles.custom_input} text-uppercase`}
                          onChange={firstPaymentForm.handleChange}
                          onBlur={firstPaymentForm.handleBlur}
                          name="checkNumber"
                          id="checkNumber"
                          pattern="^\S+$"
                          value={firstPaymentForm.values.checkNumber}
                        />
                        {firstPaymentForm.errors.checkNumber &&
                          firstPaymentForm.touched.checkNumber && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={firstPaymentForm.errors.checkNumber}
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        {/* TODO: Make a component for this select styling.
                          It's everywhere, no sense in copying it every time.*/}
                        <label htmlFor="claimCenter">
                          Claim Center Location*
                        </label>
                        <div className={styles.custom_select_div}>
                          <select
                            onChange={(event) => {
                              firstPaymentForm.setFieldValue(
                                event.target.name,
                                event.target.value
                              )
                            }}
                            onBlur={firstPaymentForm.handleBlur}
                            className={`${styles.custom_select}`}
                            name="claimCenter"
                            id="claimCenter"
                            value={
                              firstPaymentForm.values.claimCenter === ''
                                ? 'default'
                                : firstPaymentForm.values.claimCenter
                            }
                          >
                            <option value="default" disabled>
                              Select Location...
                            </option>
                            {ClaimCenterLocations.map(
                              (location: string, index: number) => (
                                <option value={location} key={index}>
                                  {location}
                                </option>
                              )
                            )}
                          </select>
                          <span className={`${styles.custom_arrow}`}></span>
                        </div>
                        {firstPaymentForm.errors.claimCenter &&
                          firstPaymentForm.touched.claimCenter && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={firstPaymentForm.errors.claimCenter}
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="firstCheckGross">Gross Payment*</label>
                        <CurrencyInput
                          className={`${styles.custom_input}`}
                          name="firstCheckGross"
                          id="firstCheckGross"
                          prefix="$"
                          value={firstPaymentForm.values.firstCheckGross}
                          onValueChange={(value, name) => {
                            if (value === undefined) {
                              firstPaymentForm.setFieldValue(
                                'firstCheckGross',
                                ''
                              )
                            } else {
                              firstPaymentForm.setFieldValue(
                                'firstCheckGross',
                                value
                              )
                            }
                          }}
                          onBlur={firstPaymentForm.handleBlur}
                          decimalsLimit={2}
                          decimalScale={2}
                          disableAbbreviations={true}
                          allowNegativeValue={false}
                        />

                        {firstPaymentForm.errors.firstCheckGross &&
                          firstPaymentForm.touched.firstCheckGross && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={
                                firstPaymentForm.errors.firstCheckGross
                              }
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="firstCheckIrs">
                          Federal Taxes Withheld*
                        </label>
                        <CurrencyInput
                          className={`${styles.custom_input}`}
                          name="firstCheckIrs"
                          id="firstCheckIrs"
                          prefix="$"
                          value={firstPaymentForm.values.firstCheckIrs}
                          onValueChange={(value, name) => {
                            if (value === undefined) {
                              firstPaymentForm.setFieldValue(
                                'firstCheckIrs',
                                ''
                              )
                            } else {
                              firstPaymentForm.setFieldValue(
                                'firstCheckIrs',
                                value
                              )
                            }
                          }}
                          onBlur={firstPaymentForm.handleBlur}
                          decimalsLimit={2}
                          decimalScale={2}
                          disableAbbreviations={true}
                          allowNegativeValue={false}
                        />

                        {firstPaymentForm.errors.firstCheckIrs &&
                          firstPaymentForm.touched.firstCheckIrs && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={
                                firstPaymentForm.errors.firstCheckIrs
                              }
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="firstCheckDor">
                          State Tax Withheld*
                        </label>
                        <CurrencyInput
                          className={`${styles.custom_input}`}
                          name="firstCheckDor"
                          id="firstCheckDor"
                          prefix="$"
                          value={firstPaymentForm.values.firstCheckDor}
                          onValueChange={(value, name) => {
                            if (value === undefined) {
                              firstPaymentForm.setFieldValue(
                                'firstCheckDor',
                                ''
                              )
                            } else {
                              firstPaymentForm.setFieldValue(
                                'firstCheckDor',
                                value
                              )
                            }
                          }}
                          onBlur={firstPaymentForm.handleBlur}
                          decimalsLimit={2}
                          decimalScale={2}
                          disableAbbreviations={true}
                          allowNegativeValue={false}
                        />

                        {firstPaymentForm.errors.firstCheckDor &&
                          firstPaymentForm.touched.firstCheckDor && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={
                                firstPaymentForm.errors.firstCheckDor
                              }
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="firstCheckDbd">Offsets Withheld*</label>
                        <CurrencyInput
                          className={`${styles.custom_input}`}
                          name="firstCheckDbd"
                          id="firstCheckDbd"
                          prefix="$"
                          value={firstPaymentForm.values.firstCheckDbd}
                          onValueChange={(value, name) => {
                            if (value === undefined) {
                              firstPaymentForm.setFieldValue(
                                'firstCheckDbd',
                                ''
                              )
                            } else {
                              firstPaymentForm.setFieldValue(
                                'firstCheckDbd',
                                value
                              )
                            }
                          }}
                          onBlur={(e) => firstPaymentForm.handleBlur(e)}
                          decimalsLimit={2}
                          decimalScale={2}
                          disableAbbreviations={true}
                          allowNegativeValue={false}
                        />

                        {firstPaymentForm.errors.firstCheckDbd &&
                          firstPaymentForm.touched.firstCheckDbd && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={
                                firstPaymentForm.errors.firstCheckDbd
                              }
                            />
                          )}
                      </div>
                      <div className={`${styles.info_detail}`}>
                        <label htmlFor="firstCheckNet">Net Payment*</label>
                        <CurrencyInput
                          className={`${styles.custom_input}`}
                          name="firstCheckNet"
                          id="firstCheckNet"
                          prefix="$"
                          value={firstPaymentForm.values.firstCheckNet}
                          onValueChange={(value, name) => {
                            if (value === undefined) {
                              firstPaymentForm.setFieldValue(
                                'firstCheckNet',
                                ''
                              )
                            } else {
                              firstPaymentForm.setFieldValue(
                                'firstCheckNet',
                                value
                              )
                            }
                          }}
                          onBlur={firstPaymentForm.handleBlur}
                          decimalsLimit={2}
                          decimalScale={2}
                          disableAbbreviations={true}
                          allowNegativeValue={false}
                        />

                        {firstPaymentForm.errors.firstCheckNet &&
                          firstPaymentForm.touched.firstCheckNet && (
                            <ErrorMessageFieldCommonComponent
                              errorMessage={
                                firstPaymentForm.errors.firstCheckNet
                              }
                            />
                          )}
                      </div>
                      {timePaymentAccountForm.values.gameType === 'Instant' && (
                        <>
                          <div className={`${styles.info_detail}`}>
                            <label htmlFor="bookNumber">Book No.*</label>
                            <InputMask
                              mask="9999999999999999999999999"
                              maskChar=""
                              className={`${styles.custom_input}`}
                              onChange={firstPaymentForm.handleChange}
                              onBlur={firstPaymentForm.handleBlur}
                              name="bookNumber"
                              id="bookNumber"
                              value={firstPaymentForm.values.bookNumber}
                            />
                            {firstPaymentForm.errors.bookNumber &&
                              firstPaymentForm.touched.bookNumber && (
                                <ErrorMessageFieldCommonComponent
                                  errorMessage={
                                    firstPaymentForm.errors.bookNumber
                                  }
                                />
                              )}
                          </div>
                          <div className={`${styles.info_detail}`}>
                            <label htmlFor="ticketNumber">Ticket No.*</label>
                            <InputMask
                              mask="9999999999999999999999999"
                              maskChar=""
                              className={`${styles.custom_input}`}
                              onChange={firstPaymentForm.handleChange}
                              onBlur={firstPaymentForm.handleBlur}
                              name="ticketNumber"
                              id="ticketNumber"
                              value={firstPaymentForm.values.ticketNumber}
                            />
                            {firstPaymentForm.errors.ticketNumber &&
                              firstPaymentForm.touched.ticketNumber && (
                                <ErrorMessageFieldCommonComponent
                                  errorMessage={
                                    firstPaymentForm.errors.ticketNumber
                                  }
                                />
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {Games.find(
                (game) =>
                  game.name === timePaymentAccountForm.values.gameName &&
                  game.type === timePaymentAccountForm.values.gameType
              )?.graduated &&
                showGraduatedSchedule && (
                  <div className={`${styles.first_pmt_container}`}>
                    <GraduatedPaymentSchedule
                      GraduatedPaymentSchedule={graduatedSchedule}
                      showDisclaimer={
                        !isFirstPaymentIdEmpty || !issueFirstPayment
                      }
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                )}
            </div>
          </div>
        </>
      )
    }
  )
)
