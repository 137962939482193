import * as React from 'react'
import { getFetchPendingReissues } from './get-fetch-pending-reissues'
import { getFetchInitiateReissueRun } from './get-fetch-initiate-reissue-run'
import { ReissueQueuePageUI } from './reissue-queue-page-ui'
import { BannerContext } from '../../../context/BannerContext'

export const ReissueQueuePageContainer = () => {
  const {
    isLoading: pendingReissuesLoading,
    error: pendingReissuesError,
    data: pendingReissuesData,
    fetchPendingReissues
  } = getFetchPendingReissues()

  const {
    isLoading: initiateReissueRunLoading,
    requestSuccessful: initiateReissueRunSuccessful,
    error: initiateReissueError,
    fetchInitiateReissueRun
  } = getFetchInitiateReissueRun()

  const bannerContext = React.useContext(BannerContext)

  React.useEffect(() => {
    if(!initiateReissueRunLoading) {
      fetchPendingReissues()
    }
    return () => {}
  }, [initiateReissueRunLoading])

  React.useEffect(() => {
    if(initiateReissueRunSuccessful) {
      bannerContext.setBannerInfo({message: 'Re-Issue Initiated', error: false})
    }
  }, [initiateReissueRunSuccessful])

  const isLoading = pendingReissuesLoading || initiateReissueRunLoading

  return (
    <ReissueQueuePageUI
      isLoading={isLoading}
      error={pendingReissuesError}
      pendingReissuesData={pendingReissuesData}
      fetchInitiateReissueRun={fetchInitiateReissueRun}
      initiateReissueRunError={initiateReissueError}
    />
  )
}