import * as React from 'react'
import { ExitButtonIcon } from '../../../../icons/exit-button-icon'
import './styles.scss'

export type TextPreview = { name: string }

type TextPreviewProps = {
  filePreviews: TextPreview[]
  removeFile?: (filename: string) => void
}

const ROOT_CLASS = 'text-preview'
const CONTAINER_CLASS = `${ROOT_CLASS}-container`

export const TextPreviewUI = (props: TextPreviewProps) => {
  if (!props.filePreviews.length) {
    return null
  }

  return (
    <div className={ROOT_CLASS}>
      {props.filePreviews.map((filePreview) => {
        return (
          <div key={filePreview.name} className={`${CONTAINER_CLASS}`}>
            <div className={`${CONTAINER_CLASS}__text`}>{filePreview.name}</div>
            {props.removeFile && (
              <button
                type="button"
                className={`${CONTAINER_CLASS}__remove-button`}
                aria-label="Remove file"
                onClick={() => {
                  props.removeFile!(filePreview.name)
                }}
              >
                <ExitButtonIcon />
              </button>
            )}
          </div>
        )
      })}
    </div>
  )
}
