import React, { SetStateAction } from "react";
import { KEY_CODES } from "../../../../../constants.js";
import { keyPressedType } from "../../../../../services/Commons";
import styles from "./css/styles.module.css";

export const ValidationNotificationComponent: React.FC<{
  setValidationErrorVisible: React.Dispatch<SetStateAction<boolean>>;
  validationMessage: string;
}> = ({ setValidationErrorVisible, validationMessage }) => {
  // package required to focus modal accessibility
  const FocusTrap = require("focus-trap-react");

  return (
    <FocusTrap focusTrapOptions={{ initialFocus: false }}>
      <div className={styles["overlay-container"]}>
        <div className={styles["main-modal"]}>
          <div>
            <div className={styles["modal-title"]}>Invalid Payment Rule</div>
            <div className={styles["modal-desc"]}>{validationMessage}</div>
          </div>
          <button
            type="button"
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                setValidationErrorVisible(false);
              }
            }}
            onClick={() => {
              setValidationErrorVisible(false);
            }}
            className={styles["modal-confirm-btn"]}
          >
            Ok
          </button>
        </div>
      </div>
    </FocusTrap>
  );
};
