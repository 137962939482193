import {PermitHolder} from "./interfaces";
import config, {permitRoutePath} from "../../../../config";
import axios from "axios";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";

let formik: any;
let history: any;
let pageMessageDefault: any;
let setPageMessageType: any;
export const setVariables = (formikObj: any, historyObject: any, pageMessageDefaultObj: any,
                             setPageMessageObj: any) => {
    formik = formikObj;
    history = historyObject;
    pageMessageDefault = pageMessageDefaultObj;
    setPageMessageType = setPageMessageObj;
}

export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}

export const onSubmit = async (values: PermitHolder) => {
    console.log(">>>>>>>>>>>>>>> onSubmit");
    formik.setValues(values, true);
    let requestData: any = {
        permitHolder: formik.values.permitHolder
    }
    const effectiveDate = requestData.permitHolder.effectiveDate;
    requestData.permitHolder.effectiveDate = new Date(effectiveDate)

    if (requestData.permitHolder.orgActive.toLowerCase() === 'active') {
        requestData.permitHolder.orgActive = true;
    } else if (requestData.permitHolder.orgActive.toLowerCase() === 'expired') {
        requestData.permitHolder.orgActive = false;
    }

    try {
        const options =
            {
                method: 'post',
                url: config.SERVER_BASE_URL + '/api/v1/permitHolder',
                data: requestData.permitHolder
            }
        const apiResponseData = await callBackEndApi(options);
        if (apiResponseData.status === 200 || apiResponseData.status === 201) {
            history.push({
                pathname: `${permitRoutePath}/manage`,
                state: {detail: "The permit profile " + apiResponseData.statusMessage + " was successfully created."}
            })
        } else {
            pageMessageDefault.message = "Api Error : " + apiResponseData.statusText + " : " + apiResponseData.statusMessage;
            pageMessageDefault.messageType = "ERROR";
            setPageMessageType({...pageMessageDefault});
        }
    } catch (e) {
        const er: any = e;
        if (er.toString().split(" ").find((value: any, i: any) => {
            if (value === "Network")
                return value
            else if (value === "ApiError") {
                setPageMessageType({message: value, messageType: "ERROR"});
            }
        }) !== undefined) {
            setPageMessageType({
                message: "Something went wrong. Network Error. Please try again.",
                messageType: "ERROR"
            });
        } else
            setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    }
    requestData.permitHolder.effectiveDate = effectiveDate
}

export const validate = async (values: PermitHolder) => {
    const errors = {};
    if (!values.permitHolder.orgName) {
        Object.assign(errors, {orgName: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.phone) {
        Object.assign(errors, {phone: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.address.streetAddress) {
        Object.assign(errors, {streetAddress: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.email) {
        Object.assign(errors, {email: InputValidationMessages.RequiredField});
    } else if (values.permitHolder.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.permitHolder.email)) {
        Object.assign(errors, {email: 'Invalid email address'});
    }
    if (!values.permitHolder.address.city) {
        Object.assign(errors, {city: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.cityCode) {
        Object.assign(errors, {cityCode: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.address.zip) {
        Object.assign(errors, {zip: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.effectiveDate) {
        Object.assign(errors, {effectiveDate: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.orgActive) {
        Object.assign(errors, {orgActive: InputValidationMessages.RequiredField});
    }
    if (!values.permitHolder.orgType) {
        Object.assign(errors, {orgType: InputValidationMessages.RequiredField});
    }
    return errors;
}

const callBackEndApi = async (options: any) => {
    const headers = {
        'Content-Type': 'application/json'
    };
    Object.assign(options, {headers: headers});
    try {
        let response = await axios(options);
        apiResponseData.status = response.status
        apiResponseData.statusText = response.statusText
        apiResponseData.statusMessage = response.data.raffleId
    } catch (e) {
        // @ts-ignore
        apiResponseData.status = e.response.status;
        // @ts-ignore
        apiResponseData.statusText = e.response.statusText;
        // @ts-ignore
        apiResponseData.statusMessage = e.response.data.error;
        // @ts-ignore
    }
    return apiResponseData;
}

export const apiResponseData = {
    status: 0,
    statusText: "",
    statusMessage: ""
}