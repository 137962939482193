import React from 'react'
import { useHistory } from 'react-router-dom'
import { paymentsRoutePath, futureReissuePaymentsRoutePath } from '../../../../config.js'
import './style.scss'

const ROOT_CLASS ='reissue-queue-cta-container'

export type ReissueQueueRowCTAUIProps = {
  id: string,
  voidedPaymentId: string
}

export const FuturePaymentsListRowCTAUI = (
  props: ReissueQueueRowCTAUIProps
) => {
  const history = useHistory()

  return (
    <div className={ROOT_CLASS}>
      <button
        className={`${ROOT_CLASS} dark-btn`}
        onClick={() => {
          history.push(
            `${paymentsRoutePath}/details/${props.voidedPaymentId}`
          )
        }}
      >
        Original
      </button>
      <button
        className={`${ROOT_CLASS} dark-btn`}
        onClick={() => {
          history.push(
            `${futureReissuePaymentsRoutePath}/details/${props.id}`
          )
        }}
      >
        Details
      </button>
    </div>
  )
}