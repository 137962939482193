import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import InputMask from 'react-input-mask';

export const CreateHeadOfOrganizationComponent = ({
                                                      formik,
                                                      setFiledValue,
                                                  }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Head Of Organization Details
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Name of Head of
                        Organization*
                    </div>
                    <input
                        autoComplete={"name"}
                        id="orgDetails.headOfOrg.fullName"
                        name="orgDetails.headOfOrg.fullName"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.orgDetails.headOfOrg.fullName}
                        onBlur={formik.handleBlur}
                    />
                    {(formik.touched.orgDetails?.headOfOrg?.fullName && formik.errors.fullName) ?
                        <ErrorMessageFieldCommonComponent
                            fullName={formik.errors.fullName}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Home Phone*
                    </div>
                    <InputMask
                        autoComplete={"tel-national"}
                        id="orgDetails.headOfOrg.homePhone"
                        name="orgDetails.headOfOrg.homePhone"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.orgDetails.headOfOrg.homePhone}
                        onBlur={formik.handleBlur}
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.orgDetails?.headOfOrg?.homePhone && formik.errors.homePhone1) ?
                        <ErrorMessageFieldCommonComponent
                            homePhone1={formik.errors.homePhone1}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing Address*</div>
                    <input
                        autoComplete={"street-address"}
                        id="orgDetails.headOfOrg.address.streetAddress"
                        name="orgDetails.headOfOrg.address.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.orgDetails.headOfOrg.address.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.orgDetails?.headOfOrg?.address?.streetAddress && formik.errors.streetAddress4) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress4={formik.errors.streetAddress4}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Business Phone*
                    </div>
                    <InputMask
                        autoComplete={"tel-national"}
                        id="orgDetails.headOfOrg.businessPhone"
                        name="orgDetails.headOfOrg.businessPhone"
                        onChange={(e: any) => setFiledValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.orgDetails.headOfOrg.businessPhone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.orgDetails?.headOfOrg?.businessPhone && formik.errors.businessPhone4) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress4={formik.errors.businessPhone4}/> : null}
                </div>
                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            id="orgDetails.headOfOrg.address.city"
                            name="orgDetails.headOfOrg.address.city"
                            value={formik.values.orgDetails.headOfOrg.address.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            autoComplete={"address-level2"}
                        />
                        {(formik.touched.orgDetails?.headOfOrg?.address?.city && formik.errors.city4) ?
                            <ErrorMessageFieldCommonComponent
                                city4={formik.errors.city4}/> : null}
                    </div>

                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            id="orgDetails.headOfOrg.address.zip"
                            name="orgDetails.headOfOrg.address.zip"
                            value={formik.values.orgDetails.headOfOrg.address.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            mask="99999"
                            maskChar=" "
                            autoComplete={"postal-code"}
                        />
                        {(formik.touched.orgDetails?.headOfOrg?.address?.zip && formik.errors.zip4) ?
                            <ErrorMessageFieldCommonComponent
                                zip4={formik.errors.zip4}/> : null}
                    </div>
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email Address*</div>
                    <input
                        id="orgDetails.headOfOrg.email"
                        name="orgDetails.headOfOrg.email"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.orgDetails.headOfOrg.email}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                        autoComplete={"email"}
                    />
                    {(formik.touched.orgDetails?.headOfOrg?.email && formik.errors.email4) ?
                        <ErrorMessageFieldCommonComponent
                            email4={formik.errors.email4}/> : null}
                </div>
            </form>
        </>
    );
}