export type CheckRunPayment = {
  name: string
  paymentId: string
  tin: string
  paymentDate: string
  grossPaymentInCents: number
  irsWithholdingInCents: number
  dorWithholdingInCents: number
  offsetsWithholdingInCents: number
  netPaymentInCents: number
  checkNumber: string
  annuityAccountId: string | null
}

export enum TaxAdjustmentStatus {
  ON_HOLD = 'ON_HOLD',
  PENDING = 'PENDING',
  DONE = 'DONE'
}

export type TaxAdjustmentSummary = {
  originalPaymentId: string
  taxAdjustmentId: string
  status: TaxAdjustmentStatus
  irsAdjustmentAmountInCents: number
  dorAdjustmentAmountInCents: number
  grossAdjustmentAmountInCents: number
  netAdjustmentAmountInCents: number
  documentNumber: string
  payeeName: string
}

export type CheckRunPaymentsResponse = {
  checkRun: {
    payments: CheckRunPayment[]
    taxAdjustments: TaxAdjustmentSummary[] | null
    numChecks: number
    totalGrossPaymentInCents: number
    totalIrsWithholdingInCents: number
    totalDorWithholdingInCents: number
    totalOffsetsWithholdingInCents: number
    totalNetPaymentInCents: number
  }
}
