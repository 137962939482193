import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../api/future-payment-details-fetcher/api-types'
import { FuturePaymentStatus } from '../../../api/future-payments-fetcher/api-types'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { FuturePaymentDetailsContainerUI } from '../../../components/PaymentComponents/FuturePaymentDetailsComponent/future-payment-details-container/future-payment-details-container-ui'

export type FuturePaymentDetailsPageUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse | undefined
  updatePaymentStatus: (status: FuturePaymentStatus, notes: string) => void
  deletePayment: () => void
  isLoading: boolean
  error: string | undefined
}

export const FuturePaymentDetailsPageUI = (
  props: FuturePaymentDetailsPageUIProps
) => {
  return (
    <AsyncContainerUI
      isLoading={props.isLoading}
      error={props.error}
      errorTextSize={20}
      color="white"
    >
      {props.futurePaymentDetailsData ? (
        <FuturePaymentDetailsContainerUI
          futurePaymentDetailsData={props.futurePaymentDetailsData}
          updatePaymentStatus={props.updatePaymentStatus}
          deletePayment={props.deletePayment}
        />
      ) : (
        <div>Something went wrong</div>
      )}
    </AsyncContainerUI>
  )
}
