import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'campaigns-list-header'
const CELL_CLASS = `${ROOT_CLASS}-cell`

const TableHeaders = [
  { key: 'Campaign Image', column_text: '' },
  { key: 'Campaign_Name', column_text: 'Campaign Name' },
  { key: 'Status', column_text: 'Status' },
  { key: 'Campaign Dates', column_text: 'Campaign Dates' },
  { key: 'Coupon Expiration', column_text: 'Coupon Expiration' },
  { key: 'Barcodes', column_text: 'Barcodes' },
  { key: 'Assigned', column_text: 'Assigned' },
  { key: 'Redeemed', column_text: 'Redeemed' },
  { key: 'Redeemed Pie Chart', column_text: '' },
  { key: 'Options Menu', column_text: '' },
]

export const CampaignsListHeaderUI = () => (
  <thead className={`${ROOT_CLASS}`}>
    <tr>
      {TableHeaders.map((header) => (
        <th className={CELL_CLASS} key={header.key}>
          {header.column_text}
        </th>
      ))}
    </tr>
  </thead>
)
