import moment from 'moment'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import {
  PromotionDrawingWinner,
  WinnerStatus,
} from '../../../../api/promotions-fetcher/constants'
import { playerSupportRoutePath } from '../../../../config.js'
import { IconInfoHollow } from '../../../../icons/icon-info-hollow'
import { formatCentsToDollars } from '../../../../util'
import { getBlockClass, getModifierClass } from '../../../../utilities/helpers'
import { WinnersManagementListRowActionMenuContainer } from './action-menu/winners-management-list-row-action-menu-container'
import './styles.scss'

const ROOT_CLASS = 'winners-management-list-body'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')
const CELL_CLASS = getBlockClass(ROOT_CLASS, 'cell')

export type WinnersManagementListRowUIProps = {
  winner: PromotionDrawingWinner
  prizeAmountInCents: number
  showActionMenu: boolean
  showOffsets: boolean
}

const getOffsetsText = (winner: PromotionDrawingWinner) => {
  if (winner.claimAcceptedDate) {
    return winner.hasOffsets ? 'Yes' : 'No'
  }
  return '-'
}

export const WinnersManagementListRowUI = (
  props: WinnersManagementListRowUIProps
) => {
  const history: any = useHistory()
  const claimDate = props.winner.claimAcceptedDate
  return (
    <tbody className={ROOT_CLASS}>
      {props.winner.status === WinnerStatus.DECLINED && (
        <tr>
          <td colSpan={100}>
            <div className={`${ROOT_CLASS}__message-bar`}>
              <div className={`${ROOT_CLASS}__icon`}>
                <IconInfoHollow />
              </div>
              Player rejected prize
            </div>
          </td>
        </tr>
      )}
      <tr className={ROW_CLASS}>
        <td className={CELL_CLASS}>
          {formatCentsToDollars(props.prizeAmountInCents, true)}
        </td>
        <td
          className={getModifierClass(
            CELL_CLASS,
            'link',
            Boolean(props.winner.playerId)
          )}
        >
          <div
            onClick={() => {
              if (props.winner.playerId) {
                history.push(
                  `${playerSupportRoutePath}/player-hub-new/${props.winner.playerId}`
                )
              }
            }}
          >
            {props.winner.fullName}
          </div>
        </td>
        <td className={CELL_CLASS}>{props.winner.address}</td>
        <td className={CELL_CLASS}>{props.winner.city}</td>
        <td className={CELL_CLASS}>{props.winner.state}</td>
        <td className={CELL_CLASS}>{props.winner.zip}</td>
        <td className={CELL_CLASS}>{props.winner.claimNumber}</td>
        <td className={CELL_CLASS}>
          {props.showOffsets ? getOffsetsText(props.winner) : 'N/A'}
        </td>
        <td className={CELL_CLASS}>
          {claimDate ? moment(claimDate).format('MM/DD/YYYY') : '-'}
        </td>
        {props.showActionMenu && (
          <td>
            <WinnersManagementListRowActionMenuContainer
              winner={props.winner}
              prizeAmountInCents={props.prizeAmountInCents}
            />
          </td>
        )}
      </tr>
    </tbody>
  )
}
