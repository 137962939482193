import React from 'react'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'

import { GetFutureReissuePaymentDetailsResponse } from '../../../api/future-reissue-payment-details-fetcher/api-types'

const FUTURE_REISSUE_PAYMENT_URL_BASE = `${config.SERVER_BASE_URL}/api/v1/payments/reissue/future`

export const useFetchFutureReissuePaymentDetails = (futurePaymentId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<GetFutureReissuePaymentDetailsResponse>()
  const fetchPaymentDetails = () => {
    const urlWithId = `${FUTURE_REISSUE_PAYMENT_URL_BASE}/${futurePaymentId}`
    return fetchUrl(urlWithId, 'GET', {})
  }
  React.useEffect(() => {
    fetchPaymentDetails()
    return () => {}
  }, [])

  return {
    isLoading,
    error,
    data
  }
}