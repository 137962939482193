import React, { useEffect, useState } from 'react'
import './styles.scss'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { Agent1099OpenSweep, Agent1099SweepPayment, Agent1099SweepType } from '../../../api/agent-1099-fetcher/api-types'
import { Agent1099Payment, Agent1099QueuePaymentsTableRow, Agent1099QueuePaymentsTableUI } from './agent-1099-queue-payments-table-ui'
import { Agent1099QueueSearchAndFilterBarUI } from './agent-1099-queue-search-and-filter-bar-ui'

export type Agent1099QueuePrintQueueTabUIProps = {
  isLoading: boolean
  error?: string
  sweeps?: Agent1099OpenSweep[]
  payments?: Agent1099SweepPayment[]
  sweepId?: string
  setSweepId: (year: string) => void
  removeFromQueue: (tin: string, year: string, sweepId: string) => void
}

const convertSweepPaymentToPayment = (sweepPayment: Agent1099SweepPayment): Agent1099Payment => {
  return {
    year: sweepPayment.year,
    fid: sweepPayment.tin,
    taxName: sweepPayment.name,
    address: sweepPayment.address,
    city: sweepPayment.city,
    reportableInCents: sweepPayment.reportableInCents,
    digitalConsent: sweepPayment.digitalPrint,
    queued: true
  }
}

export const Agent1099QueuePrintQueueTabUI = (
  props: Agent1099QueuePrintQueueTabUIProps
) => {
  const [payments, setPayments] = useState<Agent1099SweepPayment[] | undefined>(props.payments)

  const filterPaymentsByTin = (tin: string) => {
    setPayments(payments?.filter(payment => payment.tin === tin))
  }

  useEffect(() => {
    setPayments(props.payments)
  }, [props.payments])

  return (
    <AsyncContainerUI
        isLoading={props.isLoading}
        error={props.error}
        errorTextSize={20}
        color="white"
      >
        <Agent1099QueueSearchAndFilterBarUI 
          selectedId={props.sweepId}
          options={props.sweeps?.map(sweep => {
            return { id: sweep.sweepId, displayText: sweep.displayName }
          })}
          selectLabel={'Select View'}
          setOption={props.setSweepId}
          search={(tin?: string) => !!tin && filterPaymentsByTin(tin)}
          clearSearch={() => setPayments(props.payments)}
        />

        <Agent1099QueuePaymentsTableUI 
          payments={payments?.map(convertSweepPaymentToPayment)}
          sweepId={props.sweepId}
          additionalResults={false}
          showRowCTAs={props.sweeps?.find(sweep => sweep.sweepId === props.sweepId)?.sweepType === Agent1099SweepType.TARGETED}
          shouldDisableRowCTA={(_payment: Agent1099QueuePaymentsTableRow) => false}
          ctaText={'Remove'}
          ctaCallback={(tin: string, year: string) => props.sweepId && props.removeFromQueue(tin, year, props.sweepId)}
          noSelectionMessage={'Select a view to generate results.'}
        />

      </AsyncContainerUI>
  )
}
