import * as React from 'react'
import FocusLock from 'react-focus-lock'
import { useKeyDown } from '../../../../hooks/use-keydown/use-keydown'
import { AccessibleKey } from '../../../common'
import { CampaignsEndCampaignPopupContainer } from './campaigns-end-campaign-popup/campaigns-end-campaign-popup-container'
import { ActionMenuIcon } from '../../../../icons/action-menu-icon'
import './styles.scss'

export type CampaignsListRowActionMenuUIProps = {
  campaignIdentifier: string
  campaignStatus: string
  endCampaignDisabled: boolean
  openEditCampaign: () => void
}

const ROOT_CLASS = 'campaigns-list-row-action-menu'
const POPUP_CLASS = `${ROOT_CLASS}__popup`
const MENU_ITEM_CLASS = `${POPUP_CLASS}__menu-item`

const getVisibilityClass = (isOpen: boolean) =>
  isOpen ? `${POPUP_CLASS}--open` : `${POPUP_CLASS}--closed`

const getMenuItemEnabledClass = (enabled: boolean) =>
  enabled ? `${MENU_ITEM_CLASS}--enabled` : `${MENU_ITEM_CLASS}--disabled`

const viewAddEnabled = (status: string) => status.toLowerCase() === 'active'
const endCampaignEnabled = (status: string, propsDisabled: boolean) =>
  status.toLowerCase() !== 'expired' && !propsDisabled

export const CampaignsListRowActionMenuUI = (
  props: CampaignsListRowActionMenuUIProps
) => {
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState<boolean>(false)
  const [endCampaignModalIsOpen, setEndCampaignModalIsOpen] =
    React.useState<boolean>(false)

  const openEditCampaign = () => {
    setActionMenuIsOpen(false)
    props.openEditCampaign()
  }

  const showEndCampaignModal = () => {
    setActionMenuIsOpen(false)
    setEndCampaignModalIsOpen(true)
  }

  const handleKeyDown = () => {
    if (actionMenuIsOpen) {
      setActionMenuIsOpen(false)
    }
  }
  useKeyDown({
    key: AccessibleKey.escape,
    action: handleKeyDown,
    dependencies: [actionMenuIsOpen],
  })

  return (
    <>
      <CampaignsEndCampaignPopupContainer
        endCampaignIsOpen={endCampaignModalIsOpen}
        closeModal={() => setEndCampaignModalIsOpen(false)}
        campaignIdentifier={props.campaignIdentifier}
      />
      <div className={ROOT_CLASS}>
        <button
          className={`${ROOT_CLASS}__icon`}
          onClick={() => setActionMenuIsOpen(true)}
          aria-label={`Campaign Action Menu Icon for ${props.campaignIdentifier}`}
        >
          <ActionMenuIcon />
        </button>
        {actionMenuIsOpen && (
          <div
            className={`${ROOT_CLASS}__overlay`}
            onClick={() => setActionMenuIsOpen(false)}
          />
        )}
        <FocusLock disabled={!actionMenuIsOpen} autoFocus={false}>
          <div
            className={`${POPUP_CLASS} ${getVisibilityClass(actionMenuIsOpen)}`}
          >
            <button
              className={`${MENU_ITEM_CLASS} ${getMenuItemEnabledClass(
                viewAddEnabled(props.campaignStatus)
              )}`}
              onClick={openEditCampaign}
              disabled={!viewAddEnabled(props.campaignStatus)}
              aria-label={`Add files or view campaign ${props.campaignIdentifier}`}
            >
              View/Add Files
            </button>
            <button
              className={`${MENU_ITEM_CLASS} ${getMenuItemEnabledClass(
                endCampaignEnabled(
                  props.campaignStatus,
                  props.endCampaignDisabled
                )
              )}`}
              onClick={showEndCampaignModal}
              disabled={
                !endCampaignEnabled(
                  props.campaignStatus,
                  props.endCampaignDisabled
                )
              }
              aria-label={`End campaign ${props.campaignIdentifier}`}
            >
              End Campaign
            </button>
          </div>
        </FocusLock>
      </div>
    </>
  )
}
