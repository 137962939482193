import React from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import { KEY_CODES } from '../../../constants.js'
import { ANNUITY_PAGES } from '../../../pages/AnnuitiesPages/AnnuitiesRoutes'
import { keyPressedType } from '../../../services/Commons'
import { PaymentDetails } from '../../../types/Annuities/PaymentDetails'
import { PaymentDetailsComponent } from '../Common/PaymentDetailsCmpt'
import styles from './css/styles.module.css'

export type PaymentDetailsPageProps = {
  paymentDetails: PaymentDetails
  from: string
  params?: string
  paymentNumber: number
  paymentDataLength: number
}

export const PaymentDetailsContainer = (props: PaymentDetailsPageProps) => {
  const history = useHistory()

  const getNavigateBackText = (): string => {
    let backBtnText: string = ''
    const matchingTitle = findRoute()?.title
    return `Back to ${matchingTitle ?? 'Previous Screen'}`
  }

  const findRoute = () => {
    const matchingPage = Object.entries(ANNUITY_PAGES).find(
      (entry) => entry[1].path === props?.from
    )
    if (!matchingPage) {
      return undefined
    }
    return matchingPage[1]
  }

  const navigateBack = () => {
    if (!props.from) {
      history.go(-1)
    } else {
      history.push(`${props.from}${props.params ?? ''}`)
    }
  }

  return (
    <>
      <div className={styles['page-container']}>
        {/* Back Btn tsx */}
        <div className={styles['back-btn-container']}>
          <button
            type="button"
            onKeyUp={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                navigateBack()
              }
            }}
            onClick={navigateBack}
            className={styles['back-btn-inner-container']}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles['back-btn-custom']}
            />
            <div className={styles['back-btn-text']}>
              {getNavigateBackText()}
            </div>
          </button>
        </div>
        {/* Header */}
        <div className={styles['cmpnt-container']}>
          <div className={`${styles.title_header}`}>
            <div className={`${styles.title_text}`}>{`Payment ${
              props?.paymentNumber ?? 1
            } of ${props?.paymentDataLength ?? 1}`}</div>
            <div className={styles['flex-left']}>
              <div className={styles['document-id-label']}>Document ID</div>
              <div
                className={styles['document-id-value']}
              >{`${props?.paymentDetails.timePaymentAccount.documentId}`}</div>
            </div>
          </div>
          {/* Main Component */}
          <div className={styles['cmpnt-container']}>
            {props ? (
              <PaymentDetailsComponent data={props.paymentDetails} />
            ) : (
              <>No Payment Information in Props</>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
