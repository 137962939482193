import React, { SetStateAction } from "react"
import { Formik } from "formik"
import "./styles.scss"
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { BackButtonUI } from "../../../components/CommonComponents/BackButtonComponent/back-button-ui"
import { GenericErrorDialogComponent } from "../../../components/CommonComponents/GenericErrorDialogComponent"
import { FuturePaymentDetailsUpdateFormFields } from "./future-payment-details-update-page-container"
import { FuturePaymentDetailsUpdateTitleBar } from "../../../components/PaymentComponents/FuturePaymentDetailsUpdateComponents/future-payment-details-update-title-bar"
import { FuturePaymentDetailsUpdateFormUI } from "../../../components/PaymentComponents/FuturePaymentDetailsUpdateComponents/future-payment-details-update-form"
import { ConfirmationPopupUI } from "../../../components/CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui"

export type FuturePaymentDetailsUpdatePageUIProps = {
  payment: FuturePaymentDetailsUpdateFormFields
  getLoading: boolean
  updateLoading: boolean
  getError: string | undefined
  showSubmitPopup: boolean
  setShowSubmitPopup: React.Dispatch<SetStateAction<boolean>>
  showCancelPopup: boolean
  setShowCancelPopup: React.Dispatch<SetStateAction<boolean>>
  showErrorPopup: boolean
  setShowErrorPopup: React.Dispatch<SetStateAction<boolean>>
  submitFuturePaymentUpdate: (request: FuturePaymentDetailsUpdateFormFields) => Promise<void>
  cancelFuturePaymentUpdate: () => void
}

export const FuturePaymentDetailsUpdatePageUI = (props: FuturePaymentDetailsUpdatePageUIProps) => {
  const {
    payment,
    getLoading,
    updateLoading,
    getError,
    showSubmitPopup,
    setShowSubmitPopup,
    showCancelPopup,
    setShowCancelPopup,
    showErrorPopup,
    setShowErrorPopup,
    submitFuturePaymentUpdate,
    cancelFuturePaymentUpdate
  } = props

  const onSubmit = (values: FuturePaymentDetailsUpdateFormFields, _formikHelpers: any) => {
    submitFuturePaymentUpdate(values)
  }

  const ROOT_CLASS = 'future-payment-details-update-page'

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI 
        tabIndex={ 0 } 
        message={ 'Back to Future Payment Details' }
      />
      <AsyncContainerUI
        isLoading={ getLoading || updateLoading }
        error={ getError }
        color={ 'white' }
      >
        <Formik initialValues={payment} onSubmit={onSubmit}>
          {props => {
            return (
              <>
                <FuturePaymentDetailsUpdateTitleBar
                  setShowSubmitPopup={setShowSubmitPopup}
                  setShowCancelPopup={setShowCancelPopup}
                  submitButtonEnabled={props.isValid}
                />
                <form onSubmit={props.handleSubmit}>
                  <FuturePaymentDetailsUpdateFormUI
                    payment={payment}
                  />
                  {showSubmitPopup &&
                    <ConfirmationPopupUI 
                      message={ 'Are you sure you would like to save?' }
                      confirmText={ 'Yes, Save' }
                      confirmCallback={ () => {
                        setShowSubmitPopup(false)
                        props.handleSubmit()
                      }} 
                      closePopup={ () => setShowSubmitPopup(false) }
                    />
                  }
                  {showCancelPopup &&
                    <ConfirmationPopupUI 
                      message={ 'Are you sure you would like to cancel without saving?' }
                      confirmText={ 'Yes, Cancel' }
                      confirmCallback={ () => {
                        setShowCancelPopup(false)
                        cancelFuturePaymentUpdate()
                      }} 
                      closePopup={ () => setShowCancelPopup(false) }
                    />
                  }
                </form>
              </>
            )
          }}
        </Formik>
      </AsyncContainerUI>
      {showErrorPopup && 
        <GenericErrorDialogComponent 
          setShowErrorDialog={ setShowErrorPopup }          
        />
      }
    </div>
    
  )
}