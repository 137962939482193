import * as React from 'react'

export const CampaignArtworkGuidelines = () => (
  <svg
    width="192.459577px"
    height="252.111299px"
    viewBox="0 0 192.459577 252.111299"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Campaign Guidelines</title>
    <defs>
      <rect
        id="path-1"
        x="0"
        y="0"
        width="167.267722"
        height="227.662385"
        rx="4"
      ></rect>
      <style type="text/css">
        @import
        url('https://fonts.googleapis.com/css?family=Barlow-Regular|Barlow');
      </style>
    </defs>
    <g
      id="Guidlines"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-30" transform="translate(5.4281, 0)">
        <g id="Group-27" transform="translate(0, 4.9778)">
          <g id="Group-23">
            <g id="Group-16" transform="translate(19.415, 19.4711)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <rect
                stroke="#979797"
                x="0.5"
                y="0.5"
                width="166.267722"
                height="226.662385"
                rx="4"
              ></rect>
              <line
                x1="0.674466621"
                y1="0.669595251"
                x2="166.593255"
                y2="226.99279"
                id="Line"
                strokeOpacity="0.504124781"
                stroke="#979797"
                strokeLinecap="round"
                mask="url(#mask-2)"
              ></line>
              <line
                x1="166.593255"
                y1="0.669595251"
                x2="0.674466621"
                y2="226.99279"
                id="Line"
                strokeOpacity="0.504124781"
                stroke="#979797"
                strokeLinecap="round"
                mask="url(#mask-2)"
              ></line>
            </g>
            <g
              id="Group-34"
              transform="translate(67.5423, 126.3112)"
              fill="#211B12"
              fontFamily="Barlow-Regular, Barlow"
              fontSize="12"
              fontWeight="normal"
              letterSpacing="-0.257142857"
            >
              <text id="Text-Safe-Area">
                <tspan x="0" y="12">
                  Text Safe Area
                </tspan>
              </text>
            </g>
            <path
              d="M19.8823125,19.911154 L59.9692716,19.911154 L185.882312,19.911154 L185.882312,50.911154 L19.8823125,50.911154 L19.8823125,19.911154 Z"
              id="Rectangle-Copy-8"
              fillOpacity="0.181572334"
              fill="#FF7E00"
              transform="translate(102.8823, 35.4112) scale(1, -1) translate(-102.8823, -35.4112)"
            ></path>
            <path
              d="M19.8823125,215.911154 L59.9692716,215.911154 L185.882312,215.911154 L185.882312,246.911154 L19.8823125,246.911154 L19.8823125,215.911154 Z"
              id="Rectangle-Copy-10"
              fillOpacity="0.181572334"
              fill="#FF7E00"
              transform="translate(102.8823, 231.4112) scale(1, -1) translate(-102.8823, -231.4112)"
            ></path>
            <g
              id="Group-22"
              transform="translate(0, 19.4711)"
              stroke="#121921"
              strokeLinecap="square"
            >
              <line
                x1="3.73365451"
                y1="0.739163588"
                x2="3.73365451"
                y2="226.923222"
                id="Line-2"
              ></line>
              <line
                x1="0.746730902"
                y1="0.748889425"
                x2="6.72057811"
                y2="0.748889425"
                id="Line-4"
              ></line>
              <line
                x1="0.746730902"
                y1="226.913496"
                x2="6.72057811"
                y2="226.913496"
                id="Line-4-Copy"
              ></line>
            </g>
            <g
              id="Group-34-Copy"
              transform="translate(75.5423, 28.3112)"
              fill="#211B12"
              fontFamily="Barlow-Regular, Barlow"
              fontSize="12"
              fontWeight="normal"
              letterSpacing="-0.257142857"
            >
              <text id="Bleed-Area">
                <tspan x="0" y="12">
                  Bleed Area
                </tspan>
              </text>
            </g>
            <g
              id="Group-34-Copy-2"
              transform="translate(75.5423, 224.3112)"
              fill="#211B12"
              fontFamily="Barlow-Regular, Barlow"
              fontSize="12"
              fontWeight="normal"
              letterSpacing="-0.257142857"
            >
              <text id="Bleed-Area">
                <tspan x="0" y="12">
                  Bleed Area
                </tspan>
              </text>
            </g>
            <g
              id="Group-21"
              transform="translate(19.415, 0)"
              stroke="#121921"
              strokeLinecap="square"
            >
              <line
                x1="0.746730902"
                y1="0.748889425"
                x2="0.746730902"
                y2="6.74000482"
                id="Line-4-Copy-2"
              ></line>
              <line
                x1="166.520991"
                y1="0.748889425"
                x2="166.520991"
                y2="6.74000482"
                id="Line-4-Copy-3"
              ></line>
              <line
                x1="166.527541"
                y1="3.74444712"
                x2="0.746730902"
                y2="3.74444712"
                id="Line-3"
              ></line>
            </g>
          </g>
          <line
            x1="19.8157564"
            y1="50.6335102"
            x2="186.182725"
            y2="50.6335102"
            id="Line-5-Copy-2"
            stroke="#FF7E00"
            strokeDasharray="1,2"
          ></line>
          <line
            x1="20.1827254"
            y1="215.63351"
            x2="186.182725"
            y2="215.63351"
            id="Line-5-Copy-3"
            stroke="#FF7E00"
            strokeDasharray="1,2"
          ></line>
        </g>
        <g id="Group-26" transform="translate(79.6827, 0)">
          <rect
            id="Rectangle"
            fill="#F5F5F5"
            x="0"
            y="0.11129872"
            width="46"
            height="14"
          ></rect>
          <text
            id="375px"
            fontFamily="Barlow-Regular, Barlow"
            fontSize="12"
            fontWeight="normal"
            letterSpacing="-0.257142857"
            fill="#121921"
          >
            <tspan x="8.36613902" y="12">
              375px
            </tspan>
          </text>
        </g>
      </g>
      <g id="Group-31" transform="translate(0, 113.1113)">
        <rect
          id="Rectangle"
          fill="#F5F5F5"
          transform="translate(8.1108, 23.5) rotate(-90) translate(-8.1108, -23.5)"
          x="-15.3891948"
          y="16.5"
          width="47"
          height="14"
        ></rect>
        <text
          id="520px"
          transform="translate(7, 24.4199) rotate(-90) translate(-7, -24.4199)"
          fontFamily="Barlow-Regular, Barlow"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="-0.257142857"
          fill="#121921"
        >
          <tspan x="-8.5" y="29.419918">
            520px
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)
