export type OffsetSystemTotals = {
    systemTotals: {
        totalCheckWriterWithholdingsInCents: number
        totalClaimCenterWithholdingsInCents: number
        totalRTCWithholdingsInCents: number
    }
    grandTotalWithholdingsInCents: number
}

export const OffsetSystemTotalsDefaultData: OffsetSystemTotals = {
    systemTotals: {
        totalCheckWriterWithholdingsInCents: 0,
        totalClaimCenterWithholdingsInCents: 0,
        totalRTCWithholdingsInCents: 0
    },
    grandTotalWithholdingsInCents: 0
}