import React, { useState } from "react";
import { keyPressedType } from "../../../services/Commons";
import { ErrorMessageFieldCommonComponent } from "../../CommonComponents/ErrorMessageFieldCommonComponent";
import styles from './css/styles.module.css';
import { CheckboxComponent } from "../../../components/CommonComponents/CheckboxComponent";
import { testDate } from "./functions";



export const SessionActivityButtonsComponent = ({
  getSAPlayerData,
  resetFilters,
  setRequestPerformed,
  SABasePlayerData,
  SAStartDate,
  setSAStartDate,
  SAEndDate,
  setSAEndDate,
  SASignIn,
  setSASignIn,
  SASignUp,
  setSASignUp,
  setSAPlayerData,
  setCurrentPage,
  SAPlayerData,
  id,
  setSALoaded,
  setSABasePlayerData,
  setIsData,
  setPageMessage,
  setNetworkError,
  pageMessage
}: any) => {

  const [errorStartDate, setErrorStartDate] = useState<any>(undefined);
  const [errorEndDate, setErrorEndDate] = useState<any>(undefined);
  



  return (
    <>
      <div className={styles["sa-page-container-body-left-label-and-input-box-container"]}>
        <div tabIndex={0} className={styles["sa-page-container-body-left-labels-name"]}>Start Date</div>
        <input
          autoComplete={"off"}
          id="SAStartDate"
          name="SAStartDate"
          value={SAStartDate}
          onChange={(e: any) => { setSAStartDate(e.target.value); testDate(e.target.name, setErrorEndDate,setErrorStartDate, e.target.value, SAEndDate); }}
          className={`${styles["sa-page-container-body-left-input-box"]}`}
          type="date" />
        {errorStartDate && <ErrorMessageFieldCommonComponent errorMessages={errorStartDate} />}
      </div>
      <div className={styles["sa-page-container-body-left-label-and-input-box-container"]}>
        <div tabIndex={0} className={styles["sa-page-container-body-left-labels-name"]}>End Date</div>
        <input
          autoComplete={"off"}
          id="SAEndDate"
          name="SAEndDate"
          value={SAEndDate}
          // defaultValue={SAEndDate}
          aria-label={`${SAEndDate}.`}
          onChange={ (e: any) => {
           setSAEndDate(e.target.value);  testDate(e.target.name ,setErrorEndDate,setErrorStartDate, e.target.value, SAStartDate);
          }
          }
          className={`${styles["sa-page-container-body-left-input-box"]}`}
          type="date" />
        {errorEndDate && <ErrorMessageFieldCommonComponent errorMessages={errorEndDate} />}
      </div>
      <div className={styles["sa-page-container-body-left-label-and-input-box-container"]}>
        <div  className={styles["sa-page-container-body-left-labels-name"]}></div>
        <div tabIndex={0} className={styles['SA-inherit-btn']}
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setSASignIn(!SASignIn);
            }
          }
          }
          onClick={() => setSASignIn(!SASignIn)}>
          <div className={styles['SA-inherit-btn-check']}>
            <CheckboxComponent isChecked={SASignIn} />
          </div>
          <div className={styles['SA-inherit-btn-text']}>Sign In</div>
        </div>
      </div>
      <div className={styles["sa-page-container-body-left-label-and-input-box-container"]}>
        <div  className={styles["sa-page-container-body-left-labels-name"]}></div>
        <div tabIndex={0} className={styles['SA-inherit-btn']}
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setSASignUp(!SASignUp);
            }
          }
          }

          onClick={() => setSASignUp(!SASignUp)}>
          {/*<div className={SASignUp ? styles['SA-inherit-btn-check-hide'] : styles['SA-inherit-btn-check-show']}> </div>*/}
          <div className={styles['SA-inherit-btn-check']}>
            <CheckboxComponent isChecked={SASignUp} />
          </div>
          <div className={styles['SA-inherit-btn-text']}>Sign Up</div>
        </div>
      </div>
      <div className={styles["sa-page-container-body-left-label-and-input-box-container"]}>
        <div  className={styles["sa-page-container-body-left-labels-name"]}></div>
        <button
        tabIndex={0}
          style={{
            opacity: !(errorStartDate === undefined && errorEndDate === undefined) ? "0.4" : "1",
            cursor: !(errorStartDate === undefined && errorEndDate === undefined) ? "not-allowed" : "pointer",
          }}
          
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              if ((errorStartDate === undefined && errorEndDate === undefined)) {
                setRequestPerformed(true);
                getSAPlayerData(pageMessage, setNetworkError,setSALoaded, setRequestPerformed, setSAPlayerData, setSABasePlayerData, id, setIsData, SAStartDate, SAEndDate, setPageMessage, SABasePlayerData, setCurrentPage, SASignIn, SASignUp);

              }
            }
          }
          }

          onClick={() => {
            if ((errorStartDate === undefined && errorEndDate === undefined)) {
              setRequestPerformed(true);
              getSAPlayerData(pageMessage, setNetworkError, setSALoaded, setRequestPerformed, setSAPlayerData, setSABasePlayerData, id, setIsData, SAStartDate, SAEndDate, setPageMessage, SABasePlayerData, setCurrentPage, SASignIn, SASignUp);

            }
          }
          } className={styles['SA-filter-btn']}>Filter</button>
      </div>
      <div className={styles["sa-page-container-body-left-label-and-input-box-container"]}>
        <div  className={styles["sa-page-container-body-left-labels-name"]}></div>
        <button 
         tabIndex={0}
         onKeyPress={(e: any) => {
          if ( keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
               resetFilters( pageMessage,setNetworkError,setErrorStartDate, setErrorEndDate, setSAPlayerData, SABasePlayerData, setSAEndDate, setSAStartDate, setSASignIn, setSASignUp, setCurrentPage);
          }
        }
        }
        
        onClick={() => resetFilters( pageMessage, setNetworkError,setCurrentPage,setPageMessage, id, setIsData,setSABasePlayerData, setRequestPerformed, setSALoaded, setErrorStartDate, setErrorEndDate, setSAPlayerData, SABasePlayerData, setSAEndDate, setSAStartDate, setSASignIn, setSASignUp, setCurrentPage)} className={styles['SA-reset-filter-btn']}>Reset Filters</button>
      </div>
    </>
  );
}

