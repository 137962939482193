import React from "react"
import './styles.scss'

export type AddSplitButtonUIProps = {
  setShowAddSplitPopup: React.Dispatch<React.SetStateAction<boolean>>
  addSplitButtonEnabled: boolean
}

export const AddSplitButtonUI = (props: AddSplitButtonUIProps) => {
  const { setShowAddSplitPopup, addSplitButtonEnabled } = props

  // Callback
  const onClick = () => {
    setShowAddSplitPopup(true)
  }

  // Render
  return (
    <button
      tabIndex={ 0 }
      className={ `add-split-button` }
      onClick={ onClick }
      disabled={ !addSplitButtonEnabled }
    >
      Add Split +
    </button>
  )
}