import React, {useState} from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from "../../CommonComponents/ErrorMessageFieldCommonComponent";
import {keyPressedType} from "../../../services/Commons";

export const TicketSearchFields = ({
                                       onClearSearch,
                                       setButtonClicked,
                                       setTicketSearchAPIResponse,
                                       doTicketSearchWithBarCode,
                                       doTicketSearchWithSerialNumber,
                                       formik,
                                       setFieldValue,
                                       setCurrentPage
                                   }: any) => {
    const [checkSerialNumberError, setCheckSerialNumberError] = useState<any>(undefined);

    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Ticket Search</div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-1"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Search by ticket
                        barcode
                    </div>
                    <input
                        autoComplete={"off"}
                        id="barCode"
                        name="barCode"
                        onChange={(e: any) => setFieldValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.barCode}
                        aria-label={`${formik.values.barCode}.`}
                        type="text"
                    />
                    {formik.errors.barCode && <ErrorMessageFieldCommonComponent barCode={formik.errors.barCode}/>}
                </div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-2"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Search by cashing
                        serial number
                    </div>
                    <input
                        autoComplete={"off"}
                        id="serialNumber"
                        name="serialNumber"
                        onChange={(e: any) => setFieldValue(e)}
                        // onChange={(e: any) => checkValueIsNumber(e, formik, setCheckSerialNumberError)}
                        value={formik.values.serialNumber}
                        className={styles["page-container-body-left-input-box"]}
                        aria-label={`${formik.values.serialNumber}.`}
                        type="text"
                    />
                    {(formik.errors.serialNumber || formik.errors.serialNumber || checkSerialNumberError) &&
                    <>
                        <ErrorMessageFieldCommonComponent serialNumber={formik.errors.serialNumber}
                                                          checkSerialNumberError={checkSerialNumberError}/>
                    </>
                    }

                </div>
                <div className={`${styles["top-ribbon-button-container"]} ${styles["form-column-3"]}`}>
                    <div
                        role="button"
                        tabIndex={0}
                        aria-pressed="false"
                        style={{
                            opacity: ((formik.values.barCode !== '' || formik.values.serialNumber !== '') &&
                                Object.keys(formik.errors).length === 0) ? "1" : "0.4",
                            cursor: ((formik.values.barCode !== '' || formik.values.serialNumber !== '') &&
                                Object.keys(formik.errors).length === 0) ? "pointer" : "not-allowed",
                        }}
                        onKeyPress={(e: any) => {
                            if (
                                (keyPressedType(e) === 13 || keyPressedType(e) === 0) &&
                                Object.keys(formik.errors).length === 0
                            ) {
                                if (formik.values.barCode !== "" || formik.values.serialNumber !== "" &&
                                    Object.keys(formik.errors).length === 0) {
                                    setButtonClicked(true);
                                    onClearSearch(setTicketSearchAPIResponse);  // this is done to reset data before retrieval again.
                                    if (formik.values.barCode !== "" || formik.values.serialNumber !== "" &&
                                        Object.keys(formik.errors).length === 0) {
                                        setButtonClicked(true);
                                        if (formik.values.barCode !== "") {
                                            // console.log("inside WithBarCode")
                                            doTicketSearchWithBarCode(formik.values.barCode, setTicketSearchAPIResponse, setCurrentPage);
                                        } else if (formik.values.serialNumber !== "") {
                                            // alert('2');
                                            doTicketSearchWithSerialNumber(formik.values.serialNumber, setTicketSearchAPIResponse, setCurrentPage);
                                        }
                                        formik.submitForm();
                                    }
                                    formik.submitForm();
                                }
                            }
                        }}
                        onClick={() => {
                            if (formik.values.barCode !== "" || formik.values.serialNumber !== "" &&
                                Object.keys(formik.errors).length === 0) {
                                setButtonClicked(true);
                                onClearSearch(setTicketSearchAPIResponse);  // this is done to reset data before retrieval again.
                                if (formik.values.barCode !== "" || formik.values.serialNumber !== "" &&
                                    Object.keys(formik.errors).length === 0) {
                                    setButtonClicked(true);
                                    if (formik.values.barCode !== "") {
                                        // console.log("inside WithBarCode")
                                        doTicketSearchWithBarCode(formik.values.barCode, setTicketSearchAPIResponse, setCurrentPage);
                                    } else if (formik.values.serialNumber !== "") {
                                        // alert('2');
                                        doTicketSearchWithSerialNumber(formik.values.serialNumber, setTicketSearchAPIResponse, setCurrentPage);
                                    }
                                    formik.submitForm();
                                }
                                formik.submitForm();
                            }
                        }}
                        className={styles["top-ribbon-search-button"]}
                    >
                        Search
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        aria-pressed="false"
                        style={{
                            opacity: Object.keys(formik.errors).length > 0 ? "0.4" : "1",
                        }}
                        onKeyPress={(e: any) => {
                            if (
                                (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) &&
                                Object.keys(formik.errors).length === 0
                            ) {
                                formik.resetForm({
                                    values: {
                                        barCode: '',
                                        serialNumber: ''
                                    }
                                });
                                onClearSearch(setTicketSearchAPIResponse);
                            }
                        }}
                        onClick={() => {
                            formik.resetForm({
                                values: {
                                    barCode: '',
                                    serialNumber: ''
                                }
                            });
                            onClearSearch(setTicketSearchAPIResponse);
                        }}
                        className={styles["top-ribbon-clear-search-button"]}
                    >
                        Clear Search
                    </div>
                </div>
            </form>
        </>
    );
}