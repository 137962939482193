import * as React from 'react'
import { PromotionEntriesPermissions } from '../../../api/promotions-fetcher/permissions'
import {
  useFetchDisableBarcode,
  useFetchPromotionEntryDetails,
} from '../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { PromotionEntryDetailsModalUI } from './promotion-entry-details-modal-ui'

export type PromotionEntryDetailsModalContainerProps = {
  playerId: string
  barcode: string
  clearBarcode: () => void
  showPlayerId?: boolean
}
export const PromotionEntryDetailsModalContainer = (
  props: PromotionEntryDetailsModalContainerProps
) => {
  const { isLoading, error, data } = useFetchPromotionEntryDetails(
    props.playerId,
    props.barcode
  )

  const {
    isLoading: disableBarcodeLoading,
    error: disableBarcodeError,
    requestSuccessful: disableSuccessful,
    disableBarcode: fetchDisableBarcode,
  } = useFetchDisableBarcode()

  const disableBarcode = async () => await fetchDisableBarcode(props.barcode)

  React.useEffect(() => {
    if (disableSuccessful) {
      props.clearBarcode()
    }
  }, [disableSuccessful])

  return (
    <PromotionEntryDetailsModalUI
      barcode={props.barcode}
      barcodeDetails={data}
      barcodeDetailsLoading={isLoading}
      barcodeDetailsError={error}
      handleCloseModal={props.clearBarcode}
      allowDisable={userHasAnyOfPermissions([
        PromotionEntriesPermissions.CAN_DELETE_PROMOTION_ENTRIES,
      ])}
      disableBarcode={disableBarcode}
      disableBarcodeLoading={disableBarcodeLoading}
      disableBarcodeError={disableBarcodeError}
      playerId={props.playerId}
      showPlayerId={props.showPlayerId}
    />
  )
}
