import moment from "moment";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages";
import config from "../../../../config";
import {makeHttpCall} from "../../../../services/MakeHttpCall";

let formik: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setOffsetTotals: any;
let setACHBatchesDetails: any;

export const setVariables = (formikObj: any, setCurrentPageObj: any, setShowErrorDialogObj: any,
                             setRequestCompletedObj: any, setOffsetTotalsObj: any, setACHBatchesDetailsObj: any) => {

    formik = formikObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setOffsetTotals = setOffsetTotalsObj;
    setACHBatchesDetails = setACHBatchesDetailsObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    if (values.startDate && values.endDate) {
        const a = moment(values.startDate);
        const b = moment(values.endDate);
        if (b.diff(a, 'days') > 365) {
            Object.assign(errors, {startDate: InputValidationMessages.InvalidDateRange});
            // Object.assign(errors, {endDate: InputValidationMessages.InvalidDateRange});
        }
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    getOffsetTotals(values);
    getACHBatches(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
    });

    formik.submitForm();

}

export const getPageData = (itemizedTransactionReports: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(itemizedTransactionReports[i] === undefined)) {
            itemizedTransactionReports[i].index = index;
            index++;
            array.push(itemizedTransactionReports[i]);
        }
    }
    return array;
}

export const getOffsetTotals = async (values: any) => {

    // alert(JSON.stringify(values));

    setCurrentPage(0);

    setRequestCompleted(false); // Display Circular Progress

    // const startDate = values.startDate + 'T00%3A00%3A00.000Z';
    // const endDate = values.endDate + 'T23%3A59%3A59.999Z';

    const startDate = moment(values.startDate).startOf('day').utc().format().replace('Z', '.000Z');
    const endDate = moment(values.endDate).endOf('day').utc().format().replace('Z', '.999Z');

    // alert(`${startDate} - ${endDate}`);

    try {

        const options = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/offsets/systemTotals?startDate=${startDate}&endDate=${endDate}`,
        }

        let response = await makeHttpCall(options);

        setOffsetTotals(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
        console.log(e);
    }

}

const getACHBatches = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false); // Display Circular Progress

    try {

        let body: any = {
            // +1 day because ACH files are generated on the next day of receiving the withholding details
            startDate: moment(values.startDate).add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z',
            endDate: moment(values.endDate).add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.999Z'
            // endDate: values.endDate + 'T23:59:59.999Z'
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/offsets/achbatches`,
            data: body
        }

        let response = await makeHttpCall(options);

        setACHBatchesDetails(response.data);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
        console.log(e);
    }

}

