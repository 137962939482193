import React, {useState} from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import {useHistory} from "react-router-dom";
import {clearSearch} from "../../../../../pages/CharitableGamingPages/PullTab/Games/PullTabGamesPage/functions";

export const PullTabGameSearchBarComponent = ({
                                                  formik, setFieldValue
                                              }: any) => {

    const history: any = useHistory();

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const activateSearchButton = ((formik.values.licenseNumber !== '' ||
        formik.values.orgName !== '' ||
        formik.values.city !== '')
        && Object.keys(formik.errors).length === 0);

    return (
        <>

            <div className={`${styles['pulltab-games-page-search-bar-container']} ${styles['']}`}>
                <div tabIndex={0} className={`${styles['pulltab-games-page-search-bar-title']} ${styles['']}`}>Search</div>
                <div
                    className={`${styles['pulltab-games-page-search-bar-input-button-container']} ${styles['']}`}>

                    <div
                        className={`${styles['pulltab-games-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div tabIndex={0} className={`${styles['mslc-label']} ${styles['']}`}>Stock Number</div>
                        <input className={`${styles['mslc-textbox']}`}
                               type="text"
                               id="stockNumber"
                               name="stockNumber"
                               value={formik.values.stockNumber}
                               maxLength={5}
                               onBlur={formik.handleBlur}
                               onChange={(e: any) => {
                                   setFieldValue(e, formik);
                               }}/>
                        {formik.errors.stockNumber ?
                            <ErrorMessageFieldCommonComponent errorMessages={formik.errors.stockNumber}/> : null}
                    </div>
                    <div
                        className={`${styles['pulltab-games-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div tabIndex={0} className={`${styles['mslc-label']} ${styles['']}`}>Game Name</div>
                        <input className={`${styles['mslc-textbox']}`}
                               type="text"
                               id="gameName"
                               name="gameName"
                               value={formik.values.gameName}
                               onBlur={formik.handleBlur}
                               onChange={(e: any) => {
                                   setFieldValue(e, formik);
                               }}/>
                        {formik.errors.gameName ?
                            <ErrorMessageFieldCommonComponent errorMessages={formik.errors.gameName}/> : null}
                    </div>
                    <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                         role="button"
                         style={{
                             opacity: (activateSearchButton) ? "1" : "0.4",
                             cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                         }}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }
                         }}
                         onClick={() => {
                             if (activateSearchButton) {
                                 formik.submitForm();
                             }
                         }}>Search

                    </div>
                    <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                         role="button"
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                 clearSearch(formik);
                             }
                         }}
                         onClick={() => {
                             clearSearch(formik);
                         }}>Clear Search
                    </div>
                </div>
            </div>

        </>
    );
}