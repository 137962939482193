import { Field, FormikProps } from "formik"
import React, { ChangeEvent, useState } from "react"
import './styles.scss'
import { GrossPaymentTooltipUI } from "./gross-payment-tooltip-ui"
import { FormPayeeDetails } from "../../../../../../pages/PaymentsPages/Create5754SplitPage/types"
import { GrossPaymentErrorUI } from "./gross-payment-error-ui"
import { dollarsToCents } from "../../../../../../pages/PaymentsPages/Create5754SplitPage/util"
import { parseFloatWithCommas } from "../../../../../../util"

function isValidAmount(amountInDollars: string): boolean {
  const badCharacters = /[^0-9,\.]/
  if(amountInDollars.match(badCharacters)) {
    return false
  }

  const indexOfDecimal = amountInDollars.indexOf('.')
  if (indexOfDecimal > -1 && indexOfDecimal + 2 < amountInDollars.length - 1) {
    return false
  }
  const newValueInDollars = parseFloat(amountInDollars)
  if (newValueInDollars < 0) {
    return false 
  }
  return true
}

export type Create5754SplitGrossPaymentUIProps = {
  label: string
  name: string
  totalInDollars: string
  formikProps: FormikProps<{splits: FormPayeeDetails[]}>
  childIndex: number
  showGrossPaymentError: boolean 
  setSubmitButtonEnabled: (s: boolean) => void
  setGrossPaymentIndex: (s: number) => void
  onChange: (e: ChangeEvent) => void
}

const ROOT_CLASS = 'create-5754-split-gross-payment-container'
export const Create5754SplitGrossPaymentUI = (props: Create5754SplitGrossPaymentUIProps) => {
  const { 
    name, 
    label, 
    totalInDollars, 
    formikProps, 
    childIndex, 
    showGrossPaymentError, 
    setGrossPaymentIndex,
    setSubmitButtonEnabled,
    onChange 
  } = props
  const { splits } = formikProps.values
  const { grossPaymentInDollars } = splits[childIndex]

  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'gross-amount-header' }>
        <div className={ 'header-label' }>{ label }</div>
        <GrossPaymentTooltipUI 
          displayTotal={ totalInDollars }        
        />
      </div>
      <div className={ 'input' }>
        <label className={ 'dollar-sign' } htmlFor={ `5754-split-gross-payment-${childIndex}` }>$</label>
        <Field
          id={ `5754-split-gross-payment-${childIndex}` }
          className={ 'input-box' } 
          name={ name }
          type={ 'text' }
          onChange={(e: any) => {
            const value : string = e.target.value.replace(/,/g, '')
            if (!isValidAmount(value)) {
              return 
            }

            const grossInCents = dollarsToCents(parseFloatWithCommas(grossPaymentInDollars))
            const newValueInDollars = parseFloat(value)
            const newValueInCents = dollarsToCents(newValueInDollars)

            const prevSumInCents = splits.reduce((prev, curr) => {
              return prev + dollarsToCents(parseFloatWithCommas(curr.grossPaymentInDollars))
            }, 0)
            const currentSumInCents = prevSumInCents - grossInCents + newValueInCents
            const showErrorTooltip = currentSumInCents > dollarsToCents(parseFloatWithCommas(totalInDollars))
            const index = (showErrorTooltip) ? childIndex : -1
            setGrossPaymentIndex(index)

            const submitButtonEnabled = currentSumInCents === dollarsToCents(parseFloatWithCommas(totalInDollars))
            setSubmitButtonEnabled(submitButtonEnabled)

            // re-insert commas --
            // note that we do it this way instead of just using formatDollarAmount
            // in order to preserve the number of decimal places entered by the user
            const indexOfDecimal = value.indexOf('.')
            const dollarsString = (indexOfDecimal > -1) ? value.slice(0, indexOfDecimal) : value
            const suffix = (indexOfDecimal > -1) ? value.slice(indexOfDecimal) : ''

            const formattedDollars = dollarsString ? parseInt(dollarsString).toLocaleString('en-US') : '0'
            const formattedValue = `${formattedDollars}${suffix}`

            e.target.value = formattedValue
            return onChange(e)
          }}
        />

        {showGrossPaymentError ? 
          <GrossPaymentErrorUI/> : null
        }
      </div>
    </div>
  )
}