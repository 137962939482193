import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import { FuturePaymentStatus } from '../../../../api/future-payments-fetcher/api-types'
import { BackButtonUI } from '../../../CommonComponents/BackButtonComponent/back-button-ui'
import { FuturePaymentDetailsTitleBarUI } from '../future-payment-details-title-bar/future-payment-details-title-bar-ui'
import { FuturePaymentDetailsUI } from '../future-payment-details/future-payment-details-ui'
import './styles.scss'

const ROOT_CLASS = 'future-payment-details-page'

export type FuturePaymentDetailsContainerUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse
  updatePaymentStatus: (status: FuturePaymentStatus, notes: string) => void
  deletePayment: () => void
}

export const FuturePaymentDetailsContainerUI = (
  props: FuturePaymentDetailsContainerUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={0} message={`Back to Check Run Queue`} />
      <FuturePaymentDetailsTitleBarUI {...props} />
      <FuturePaymentDetailsUI {...props} />
    </div>
  )
}
