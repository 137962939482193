import { TaxAdjustmentSummary } from "../check-run-payments-fetcher/api-types"

export type FuturePayment = {
  name: string
  tin?: string
  grossPaymentInCents: number
  annuityAccountId: string | null
  source: FuturePaymentSource
  status: FuturePaymentStatus
  futurePaymentId: string
  onHoldNotes: string | null
}

export enum FuturePaymentGame {
  POWERBALL = 'POWERBALL',
  MEGABUCKS_DOUBLER = 'MEGABUCKS DOUBLER',
  MASS_CASH = 'MASS CASH',
  MEGA_MILLIONS = 'MEGA MILLIONS',
  ALL_OR_NOTHING = 'ALL OR NOTHING',
  KENO = 'KENO',
  CASH_WINFALL = 'CASH WINFALL',
  THE_NUMBERS_GAME = 'THE NUMBERS GAME',
  RAFFLE = 'RAFFLE',
  LUCKY_FOR_LIFE = 'LUCKY FOR LIFE',
  INSTANT_TICKET = 'INSTANT TICKET',
  JACKPOT_POKER = 'JACKPOT POKER',
  MASS_MILLIONS = 'MASS MILLIONS',
  UNKNOWN = 'UNKNOWN',
  ZEROZERO = '00',
}

export enum FuturePaymentSource {
  ANNUITIES = 'ANNUITIES',
  PROMOTIONS = 'PROMOTIONS',
  SECOND_CHANCE = 'SECOND_CHANCE',
  SEASON_TICKETS = 'SEASON_TICKETS',
  OTHER = 'OTHER',
}

export enum FuturePaymentStatus {
  PENDING = 'PENDING',
  ISSUING = 'ISSUING',
  ON_HOLD = 'ON_HOLD',
  DELETED = 'DELETED',
}

export type SearchFuturePaymentsResponse = {
  futurePayments: FuturePayment[]
  taxAdjustments: TaxAdjustmentSummary[]
  additionalResults: boolean
  totalAnnuitiesPaymentsInCents: number
  totalPromotionsPaymentsInCents: number
  totalSecondChancePaymentsInCents: number
  totalSeasonTicketsPaymentsInCents: number
  totalOtherPaymentsInCents: number
  totalGrossPaymentsInCents: number
}

export type SearchFuturePaymentsRequest = {
  source?: FuturePaymentSource
  game?: FuturePaymentGame
  status?: FuturePaymentStatus
  firstName?: string
  lastName?: string
  freeFormName?: string
  tin?: string
  annuityAccountId?: string
}
