import React, { ChangeEvent } from "react"
import './styles.scss'
import moment from 'moment-timezone'
import { keyPressedType } from "../../../../services/Commons"

export type Split5754ReportingFilterBarUIProps = {
  year: string
  setYear: (year: string) => void
  generateEnabled: boolean
  generateReport: (year: string, generatedBy: string) => Promise<void>
}

const ROOT_CLASS = 'split-5754-reporting-filter-bar'
export const Split5754ReportingFilterBarUI = (props: Split5754ReportingFilterBarUIProps) => {
  const { year, setYear, generateEnabled, generateReport } = props

  const currentYear = moment().tz('America/New_York').year()

  const yearDropdownList: string[] = []
  for (let i = 0; i < 5; i++) { // allow report generation for the last 5 years
    yearDropdownList.push((currentYear - i).toString())
  }

  return (
    <div className={ROOT_CLASS}>
      <div className={'outer-container'}>
        <div className={'title-container'}>
          <div className={`title`}>Generate Report</div>
        </div>
        <div className={'input-button-container'}>
          <div className={'select-label-container'}>
            <div className={'mslc-label'}>Select Year</div>
            <div className={'custom-select-container'}>
              <select
                className={'custom-select-5754'}
                id="year"
                name="year"
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  setYear(event.target.value)
                }}
                defaultValue=""
              >
                <option value="" disabled>Select...</option>
                {yearDropdownList.map((optionYear: string, index: number) => (
                  <option
                    key={index}
                    selected={year === optionYear}
                    value={optionYear}
                  >
                    {optionYear}
                  </option>
                ))}
              </select>
              <span className={'custom-arrow-5754'}></span>
            </div>
          </div>
          <button
            className={'mslc-search-button'}
            style={{
              opacity: generateEnabled ? '1' : '0.4',
              cursor: generateEnabled ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              const userName = localStorage.getItem('loggedInUserFullName')
              if (generateEnabled) {
                generateReport(year, userName ?? '')
              }
            }}
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  )
}
