export interface ChargesSearchResultInterface {
    charges: ChargesInterface[],
    weeklyBeanoTotalInCents: number,
    casinoFunctionTotalInCents: number,
    raffleBazaarTotalInCents: number,
    licenseRenewalTotalInCents: number,
    pulltabOrderTotalInCents: number,
    grandTotalInCents: number,
    creditTotalInCents: number
}

export interface ChargesInterface {
    chargeId: string,
    expenseType: string,
    associatedEntityId: string,
    date: string,
    status: string,
    baseAmountInCents: number,
    creditAmountInCents: number,
    totalAmountInCents: number,
    paymentMethod: string,
    checkReceived: boolean,
    orgDetails: {
        chargeType: string,
        identifier: string,
    }
}

export const ChargesSearchResultDefaultData =  {
    charges: [{
        chargeId: '',
        expenseType: '',
        associatedEntityId: '',
        date: '',
        status: '',
        baseAmountInCents: 0,
        creditAmountInCents: 0,
        totalAmountInCents: 0,
        paymentMethod: '',
        checkReceived: false,
        orgDetails: {
            chargeType: '',
            identifier: '',
        }
    }],
    weeklyBeanoTotalInCents: 0,
    casinoFunctionTotalInCents: 0,
    raffleBazaarTotalInCents: 0,
    licenseRenewalTotalInCents: 0,
    pulltabOrderTotalInCents: 0,
    grandTotalInCents: 0,
    creditTotalInCents: 0
}