import { FormPayeeDetails} from "./types"
import config from "../../../config"
import { useLocation } from "react-router-dom"
import { useFetchPayeeDetails } from "./use-fetch-form-payee-details"
import { useFetchPaymentDetails } from "../PaymentDetailsPage/use-fetch-payment-details"
import { GetPaymentDetailsResponse, PayeeType } from "../../../api/payment-details-fetcher/api-types"
import { omit } from "lodash"
import { useFetchSplitPayment } from "./use-fetch-split-payment"
import { formatDollarAmount } from "../../../util"
import { PayeeDetailsResponse } from "../../../api/payee-details-fetcher/api-types"
import { getMatchingKnownCountry } from "../../../Constants/Countries"

type GetChildPaymentFormDataProps = {
  payeeData: PayeeDetailsResponse | undefined
  paymentDetails: GetPaymentDetailsResponse | undefined
  initialPayeeType: PayeeType | null
}

export function getDefaultPayeeDetails(payeeType: PayeeType): FormPayeeDetails {
  return {
    payeeType: payeeType,
    payeeFirstName: payeeType === PayeeType.INDIVIDUAL ? '' : null,
    payeeMiddleInitial: null,
    payeeLastName: payeeType === PayeeType.INDIVIDUAL ? '' : null,
    payeeFreeformName: payeeType === PayeeType.ENTITY ? '' : null,
    tin: '',
    address1: '',
    address2: null,
    city: '',
    state: '',
    zip: '',
    country: 'UNITED STATES OF AMERICA',
    supplementalAddress1: null,
    supplementalAddress2: null,
    phone: null,
    email: null,
    grossPaymentInDollars: '0.00'
  }
}

function centsToDollars(cents: number): number { 
  return (cents / 100)
}

function getChildPaymentFormData(props: GetChildPaymentFormDataProps): FormPayeeDetails[] {
  const { payeeData, paymentDetails, initialPayeeType } = props 

  const childPayments: FormPayeeDetails[] = []
  if (payeeData && paymentDetails) {
    childPayments.push({
      ...omit(payeeData, ['payeeIsEntity', 'country']),
      payeeType: payeeData ? (payeeData.payeeIsEntity ? PayeeType.ENTITY : PayeeType.INDIVIDUAL) : PayeeType.NONE,
      grossPaymentInDollars: formatDollarAmount(centsToDollars(paymentDetails.payment.grossPaymentInCents), false),
      country: getMatchingKnownCountry(payeeData.country)
    })
  }
  if (initialPayeeType) {
    const defaultPayeeDetails = getDefaultPayeeDetails(initialPayeeType)
    childPayments.push(defaultPayeeDetails)
  } 

  return childPayments
}

function grossInCentsToDollars(paymentDetails?: GetPaymentDetailsResponse): number {
  const originalGrossInCents = (paymentDetails) ? paymentDetails.payment.grossPaymentInCents : 0
  return centsToDollars(originalGrossInCents)
}

const SUBMIT_SPLIT_BASE_URL = `${config.SERVER_BASE_URL}/api/v1/payments/split`
export const useFetch5754SplitData = (internalPaymentId: string) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const initialPayeeType = params.get('initial-payee-type') as PayeeType
  
  const { 
    data: payeeData, 
    isLoading: payeeIsLoading, 
    error: payeeError
  } = useFetchPayeeDetails(internalPaymentId)
  const {
    data: paymentDetails,
    isLoading: paymentIsLoading,
    error: paymentError 
  } = useFetchPaymentDetails(internalPaymentId)
  const {
    error: splitPaymentError, 
    requestSuccessful: splitPaymentRequestSucessful, 
    isLoading: splitLoading,
    fetchSplitPayment
  } = useFetchSplitPayment()

  const payeeFormData = getChildPaymentFormData({payeeData, paymentDetails, initialPayeeType})
  const originalGrossInDollars = grossInCentsToDollars(paymentDetails)

  return {
    isLoading: payeeIsLoading || paymentIsLoading,
    error: payeeError ?? paymentError,
    data: payeeFormData,
    originalGrossInDollars,
    splitPaymentError,
    splitPaymentRequestSucessful,
    splitLoading,
    fetchSplitPayment
  }
}