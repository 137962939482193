import React, { useEffect, useState } from "react";
import { ErrorMessageFieldCommonComponent } from "../../CommonComponents/ErrorMessageFieldCommonComponent";
import styles from './css/styles.module.css';
import { 
  testPhoneNumber,
  clearField,
  resetClickCount,
 } from "./functions";


export const UpdatePlayersEmailAndPhoneComponent = ({ formik , setFiledValue,ssnNumberIsBeenUsed,callingPage={} }: any) => {
    
     const[ phoneNumberErrors , setPhoneNumberErrors ] = useState(undefined);
    return (
        <>
           <form className={styles['page-container-body']}>
              <div tabIndex={0} className={styles['page-container-body-left-title']} >Email and Phone</div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email *</div>
                <input
                  id="account.email"
                  name="account.email"
                  autoComplete={"off"}
                  onClick={(e:any) => clearField(e,formik) }
                  onFocus={()=> resetClickCount()}
                  onChange={(e:any) =>   setFiledValue(e) } 
                  className={styles["page-container-body-left-input-box"]}
                  value={formik.values.account.email}
                  aria-label={`${formik.values.account.email}.`}
                  type="text"
                  />
                 {formik.errors.email &&  <ErrorMessageFieldCommonComponent email={formik.errors.email} />}
                 
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Phone *</div>
                <input
                  id="identity.phone"
                  name="identity.phone"
                  autoComplete={"off"}
                  className={styles["page-container-body-left-input-box"]}
                  type="text"
                  onFocus={()=> resetClickCount()}
                  onClick={(e:any) => clearField(e,formik) }
                  onChange={(e:any) =>   testPhoneNumber(e,formik,setPhoneNumberErrors) }
                  value={formik.values.identity.phone}
                  aria-label={`${formik.values.identity.phone}.`}
                   />
                   {formik.errors.phone &&  <ErrorMessageFieldCommonComponent phone={formik.errors.phone} phoneNumberErrors={phoneNumberErrors}  />}
                                 
              </div>           
            </form>

        </>

    );
}