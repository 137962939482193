import React from "react";
import { Switch, Route, useRouteMatch, Router, withRouter, useHistory } from "react-router-dom";

import { PrivateRoute } from './../../../routes';
import {
    CGReportingRoutePath,
    chargeRoutePath,
    charitableRoutePath,
    licenseeRoutePath,
    permitRoutePath,
    pulltabRoutePath,
    servicesRoutePath, taxformRoutePath
} from './../../../config.js';
import { NotFound404Page } from "../../CommonsPage/NotFound404Page";
import SystemDashboardComponent from "./../../../systemdashboard";
import SystemStatsComponent from "./../../../systemstats";
import SystemDetailsComponent from "./../../../systemdetails";
import {checkRenderPermissions} from "../../../helpers";
import {CommonLandingPage} from "../../CommonsPage/CommonLandingPage";
import {Forbidden409Page} from "../../CommonsPage/Forbidden409Page";
import {ButtonInfo, PageInfo} from "../../CommonsPage/CommonLandingPage/interfaces";
import {permissions} from "../../../constants";
import {OffsetsInquiryServicePage} from "../Offsets/OffsetsInquiryServicePage";

 const ServiceRoutesPage = ({ props, storesObject }: any) => {

     const userpolicies: any = localStorage.getItem("userpolicies");
     const landingObject: ButtonInfo[] = [
         {
             buttonTitle: "Offsets Inquiry Service",
             buttonPermission: `${permissions.CAN_SEE_OFFSETS_INQUIRY_SERVICE}`,
             buttonRoute: `${servicesRoutePath}/offsets/inquiry`,
         }
     ];

     const landingPageInfo: PageInfo = {
         title: "Services",
         policy: permissions.CAN_SEE_SYSTEM_SUPPORT,
         buttonData: landingObject,
         backButtonText: ''
     };
            
    return (

        <>            
            <Switch>

                    <PrivateRoute path={servicesRoutePath+"/system/stats"} component={(props:any) => <SystemStatsComponent title="System Stats" {...props} showBackButton={true} />} />
                    <PrivateRoute path={servicesRoutePath+"/system/details"} component={(props:any) => <SystemDetailsComponent title="System Details" {...props} showBackButton={true} />} />
                    {/*<PrivateRoute path={`/`} component={(props:any) =>  <NotFound404Page />}/>*/}

                <PrivateRoute
                    path={`${servicesRoutePath}/offsets/inquiry`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_OFFSETS_INQUIRY_SERVICE, JSON.parse(userpolicies)) ?
                            <OffsetsInquiryServicePage/> : <Forbidden409Page/>
                    }
                />

                <PrivateRoute
                    path={`${servicesRoutePath}`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_SYSTEM_SUPPORT, JSON.parse(userpolicies)) ?
                            <CommonLandingPage {...landingPageInfo} /> : <Forbidden409Page/>
                    }
                />
            </Switch>          
        </>

    );
}
export  default withRouter(ServiceRoutesPage)