import * as React from 'react'
import {
  GenericSidebar,
  GenericSidebarFormMode,
} from '../../GenericSidebar/generic-sidebar-ui'
import { AsyncContainerUI } from '../../CommonComponents/async-container-component/async-container-ui'
import { getBlockClass } from '../../../utilities/helpers'
import { PromotionalMessageSidebarFormContainer } from './promotional-message-sidebar-form/promotional-message-sidebar-form-container'
import { PlayslipsAllActivePlayslipsGamesResponse } from '../../../api/playslips-fetcher/constants'
import './styles.scss'

const HEADER_CLASS = 'promotional-messages-sidebar-header'

export type PromotionalMessagesSidebarUIProps = {
  formMode: GenericSidebarFormMode
  closeSidebar: () => void
  activeGamesLoading: boolean
  activeGamesError?: string
  activeGames?: PlayslipsAllActivePlayslipsGamesResponse
}

export const PromotionalMessagesSidebarUI = (
  props: PromotionalMessagesSidebarUIProps
) => {
  const getSidebarTitle = (formMode: GenericSidebarFormMode) => {
    switch (formMode) {
      case GenericSidebarFormMode.NEW:
        return 'New Message'
      case GenericSidebarFormMode.EDIT:
        return 'Edit Message'
      case GenericSidebarFormMode.CLOSED:
        return ''
    }
  }
  return (
    <GenericSidebar
      isOpen={props.formMode !== GenericSidebarFormMode.CLOSED}
      closeSideBar={props.closeSidebar}
    >
      <header className={HEADER_CLASS}>
        <div className={getBlockClass(HEADER_CLASS, 'title')}>
          {getSidebarTitle(props.formMode)}
        </div>
        <button
          className={getBlockClass(HEADER_CLASS, 'close-button')}
          onClick={props.closeSidebar}
          aria-label="Close"
        >
          <div>Close</div>
        </button>
      </header>
      <AsyncContainerUI
        isLoading={props.activeGamesLoading}
        error={props.activeGamesError}
        color="blue"
      >
        {props.activeGames &&
          props.formMode !== GenericSidebarFormMode.CLOSED && (
            <PromotionalMessageSidebarFormContainer
              activeGames={props.activeGames}
            />
          )}
      </AsyncContainerUI>
    </GenericSidebar>
  )
}
