export interface ElectronicsFundTransferFormInterface {
    licenseNumber: string,
    bankName: string,
    nameOnAccount: string,
    accountType: string,
    routingNumber: string,
    accountNumber: string,
    authorizingParty: string,
    emailAddress: string
}

export const EFTFormDefaultData = {
    licenseNumber: '',
    bankName: '',
    nameOnAccount: '',
    accountType: 'CHECKING',
    routingNumber: '',
    accountNumber: '',
    authorizingParty: '',
    emailAddress: ''
}