import React from "react";
import {useHistory} from "react-router-dom";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {licenseeRoutePath} from "../../../../../config.js";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import {HoursOfOperationMapping, StaffTrainedMapping} from "../../../../../Constants/CharitableConstant";
import moment from "moment";

export const ViewBeanoGameInformationComponent = ({licenseeDetails}:any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    return (
        <>

            <div className={`${styles['view-perjury-details-container']}`}>

                <div className={`${styles['view-perjury-details-container-title-container']}`}>
                    <div className={`${styles['view-perjury-details-container-title']}`}>Beano Game Information</div>
                    {checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                        <div tabIndex={0} className={`${styles['view-perjury-details-container-update-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     history.push(`${licenseeRoutePath}/update/beano-game/${licenseeDetails.licenseDetails.licenseNumber}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${licenseeRoutePath}/update/beano-game/${licenseeDetails.licenseDetails.licenseNumber}`);
                             }}>Update
                        </div> : null}
                </div>

                <div className={`${styles['view-perjury-details-controls-container']}`}>

                    <div className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Occasion Address</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.licenseDetails.occasionAddress.streetAddress}</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>Ocassion Day</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.licenseDetails.occasionDay.toUpperCase()}</div>

                    <div className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.licenseDetails.occasionAddress.city}</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>Hours of Operation</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{HoursOfOperationMapping[licenseeDetails.licenseDetails.occasionTime]}</div>

                    <div className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.licenseDetails.occasionAddress.zip}</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>Start Date</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{(licenseeDetails.licenseDetails.startDate) ? moment(licenseeDetails.licenseDetails.startDate).format('MM/DD/YYYY') : 'n/a'}</div>

                    <div className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Beano Hall Phone</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.licenseDetails.beanoHallPhone}</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>Staff Trained</div>
                    <div className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{StaffTrainedMapping[licenseeDetails.licenseDetails.staffTrained]}</div>

                </div>

            </div>


        </>

    );
};