import React from 'react'
import {
  AddPaymentToSweepRequest,
  W2GReissueReviewDetails,
} from '../../../api/w2g-reissue-payment-fetcher/api-types'
import config from '../../../config'
import { permissions } from '../../../constants'
import { useFetch } from '../../../hooks/use-fetch'

const W2G_REISSUE_URL = `${config.SERVER_BASE_URL}/api/v1/w2gQueue`

export const useFetchReviewW2gReissuePayment = (paymentId: string) => {
  const { isLoading, error, data, fetchUrl, requestSuccessful } =
    useFetch<W2GReissueReviewDetails>()

  const fetchReviewW2gReissuePayment = async () => {
    const reviewUrl = `${W2G_REISSUE_URL}/reviewReissue?paymentId=${paymentId}`
    return fetchUrl(reviewUrl, 'GET', {})
  }

  React.useEffect(() => {
    fetchReviewW2gReissuePayment()
    return () => {}
  }, [])

  return {
    fetchReissuePaymentisLoading: isLoading,
    fetchReissuePaymentError: error,
    fetchReissuePaymentData: data,
    fetchReissuePaymentRequestSuccessful: requestSuccessful,
  }
}

export const useFetchAddToW2gQueue = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } = useFetch()

  const fetchW2gReissueAddToQueue = async (
    request: AddPaymentToSweepRequest
  ) => {
    const addToQueueUrl = `${W2G_REISSUE_URL}/addToSweep`
    fetchUrl(addToQueueUrl, 'POST', {}, JSON.stringify(request), [
      permissions.CAN_REISSUE_W2G,
    ])
  }

  return {
    fetchAddToQueueIsLoading: isLoading,
    fetchAddToQueueError: error,
    fetchAddToQueueRequestSuccessful: requestSuccessful,
    fetchW2gReissueAddToQueue,
  }
}

export const useFetchReprintImmediately = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } = useFetch()
  const fetchW2gReissueReprintImmediately = async (
    request: AddPaymentToSweepRequest
  ) => {
    const reprintImmediatelyUrl = `${W2G_REISSUE_URL}/immediate`
    fetchUrl(reprintImmediatelyUrl, 'POST', {}, JSON.stringify(request), [
      permissions.CAN_REISSUE_W2G,
    ])
  }

  return {
    fetchReprintImmediatelyIsLoading: isLoading,
    fetchReprintImmediatelyError: error,
    fetchReprintImmediatelyRequestSuccessful: requestSuccessful,
    fetchW2gReissueReprintImmediately,
  }
}
