import React from "react"

export type FuturePaymentDetailsUpdateTitleBarProps = {
  submitButtonEnabled: boolean 
  setShowSubmitPopup: React.Dispatch<React.SetStateAction<boolean>>
  setShowCancelPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const ROOT_CLASS = 'future-payment-details-update-title-bar'
export const FuturePaymentDetailsUpdateTitleBar = (props: FuturePaymentDetailsUpdateTitleBarProps) => {
  const { 
    submitButtonEnabled, 
    setShowSubmitPopup,
    setShowCancelPopup
  } = props 

  const submitButtonClass = (submitButtonEnabled) ? 'submit-future-payment-details-update-button-enabled' : 'submit-future-payment-details-update-button-disabled'
  const onSaveClick = () => {
    setShowSubmitPopup(true)
  }

  const onCancelClick = () => {
    setShowCancelPopup(true)
  }

  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'title' }>Update Payee Details</div>
      <div className={ 'buttons-container' }>
        <button
          className={ `${ROOT_CLASS}-button cancel-future-payment-details-update-button` }
          onClick= { onCancelClick }
        >
          Cancel
        </button>
        <button
          className={ `${ROOT_CLASS}-button ${submitButtonClass}` }
          disabled={ !submitButtonEnabled }
          onClick={ onSaveClick }
        >
          Save
        </button>
      </div>
    </div>
  )
}