import {
  FuturePaymentGame,
  FuturePaymentSource,
  FuturePaymentStatus,
} from '../../../../api/future-payments-fetcher/api-types'

export const SOURCES: { value: FuturePaymentSource; label: string }[] = [
  { value: FuturePaymentSource.ANNUITIES, label: 'Annuities' },
  { value: FuturePaymentSource.PROMOTIONS, label: 'Promotions' },
  { value: FuturePaymentSource.SEASON_TICKETS, label: 'Season Tickets' },
  { value: FuturePaymentSource.OTHER, label: 'Other' },
]

export const GAMES: { value: FuturePaymentGame; label: string }[] = [
  { value: FuturePaymentGame.POWERBALL, label: 'Powerball' },
  { value: FuturePaymentGame.MEGABUCKS_DOUBLER, label: 'Megabucks Doubler' },
  { value: FuturePaymentGame.MASS_CASH, label: 'Mass Cash' },
  { value: FuturePaymentGame.MEGA_MILLIONS, label: 'Mega Millions' },
  { value: FuturePaymentGame.ALL_OR_NOTHING, label: 'All or Nothing' },
  { value: FuturePaymentGame.KENO, label: 'Keno' },
  { value: FuturePaymentGame.CASH_WINFALL, label: 'Cash Winfall' },
  { value: FuturePaymentGame.THE_NUMBERS_GAME, label: 'The Numbers Game' },
  { value: FuturePaymentGame.RAFFLE, label: 'Raffle' },
  { value: FuturePaymentGame.LUCKY_FOR_LIFE, label: 'Lucky for Life' },
  { value: FuturePaymentGame.INSTANT_TICKET, label: 'Instant Ticket' },
  { value: FuturePaymentGame.JACKPOT_POKER, label: 'Jackpot Poker' },
  { value: FuturePaymentGame.MASS_MILLIONS, label: 'Mass Millions' },
  { value: FuturePaymentGame.UNKNOWN, label: 'Unknown' },
  { value: FuturePaymentGame.ZEROZERO, label: '00' },
]

export const STATUSES: { value: FuturePaymentStatus; label: string }[] = [
  {
    value: FuturePaymentStatus.DELETED,
    label: 'Deleted',
  },
  {
    value: FuturePaymentStatus.ON_HOLD,
    label: 'On Hold',
  },
  {
    value: FuturePaymentStatus.PENDING,
    label: 'Pending',
  },
]
