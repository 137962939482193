import * as React from 'react'
import { Link } from 'react-router-dom'
import { AllPlayslipsStepDownPermissions } from '../../../api/playslips-fetcher/permissions'
import { AuthorizedContainer } from '../../../components/CommonComponents/authorized-container-component/authorized-container-ui'
import { playslipsStepDownRoutePath } from '../../../config.js'
import { getBlockClass } from '../../../utilities/helpers'
import './styles.scss'

const ROOT_CLASS = 'operations-landing-page-ui'

export const OperationsLandingPageUI = () => {
  return (
    <div className={ROOT_CLASS}>
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Operations
      </header>
      <div className={getBlockClass(ROOT_CLASS, 'container')}>
        <AuthorizedContainer permissions={AllPlayslipsStepDownPermissions}>
          <Link
            role="button"
            id="playslips-stepdown-button"
            className={getBlockClass(ROOT_CLASS, 'nav-button')}
            to={playslipsStepDownRoutePath}
          >
            Playslips Step Down
          </Link>
        </AuthorizedContainer>
      </div>
    </div>
  )
}
