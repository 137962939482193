import { permissions } from '../../constants.js'
import { AllPromotionalMessagesPermissions } from '../playslips-fetcher/permissions'

export const PromotionEntriesPermissions = {
  CAN_READ_PROMOTION_ENTRIES: permissions.CAN_READ_PROMOTION_ENTRIES,
  CAN_DELETE_PROMOTION_ENTRIES: permissions.CAN_DELETE_PROMOTION_ENTRIES,
}

export const AllPromotionEntriesPermissions = Object.values(
  PromotionEntriesPermissions
)

export const PromotionReportingPermissions = {
  CAN_READ_PROMOTION_REPORTS: permissions.CAN_READ_PROMOTION_REPORTS,
}

export const AllPromotionReportingPermissions = Object.values(
  PromotionReportingPermissions
)

export const WinnersManagementPermissions = {
  CAN_ADD_PROMOTION_CAMPAIGN_WINNERS:
    permissions.CAN_ADD_PROMOTION_CAMPAIGN_WINNERS,
  CAN_ADMIN_WINNERS_MANAGEMENT: permissions.CAN_ADMIN_WINNERS_MANAGEMENT,
  CAN_READ_WINNERS_MANAGEMENT: permissions.CAN_READ_WINNERS_MANAGEMENT,
}

export const AllWinnersManagementPermissions = Object.values(
  WinnersManagementPermissions
)

export const PromotionCampaignPermissions = {
  CAN_READ_PROMOTION_CAMPAIGNS: permissions.CAN_READ_PROMOTION_CAMPAIGNS,
  CAN_ADMIN_PROMOTION_CAMPAIGNS: permissions.CAN_ADMIN_PROMOTION_CAMPAIGNS,
  CAN_SCHEDULE_PROMOTION_CAMPAIGN_DRAWING:
    permissions.CAN_SCHEDULE_PROMOTION_CAMPAIGN_DRAWING,
}

export const AllPromotionCampaignPermissions = Object.values(
  PromotionCampaignPermissions
)

export const CouponCampaignPermissions = {
  CAN_READ_COUPON_CAMPAIGNS: permissions.CAN_READ_COUPON_CAMPAIGNS,
  CAN_WRITE_COUPON_CAMPAIGNS: permissions.CAN_WRITE_COUPON_CAMPAIGNS,
  CAN_END_COUPON_CAMPAIGNS: permissions.CAN_END_COUPON_CAMPAIGNS,
  CAN_ASSIGN_PLAYER_COUPONS: permissions.CAN_ASSIGN_PLAYER_COUPONS,
}

export const AllCouponCampaignPermissions = Object.values(
  CouponCampaignPermissions
)

export const PromotionTabPermissions = [
  ...AllCouponCampaignPermissions,
  ...AllWinnersManagementPermissions,
  ...AllPromotionalMessagesPermissions,
  ...AllPromotionCampaignPermissions,
]
