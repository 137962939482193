import { useEffect } from 'react'
import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { Agent1099DataYearsResponse } from './api-types'

export const useFetchAgent1099DataYears = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<Agent1099DataYearsResponse>()
  useEffect(() => {
    fetchUrl(`${config.SERVER_BASE_URL}/api/v1/agent1099Queue/dataYears`)
  }, [])

  return { isLoading, error, data }
}
