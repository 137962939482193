import React, { useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import stylesMessageBox from './css/stylesMessageBox.module.css'
import { DisplayDocumentNewComponent } from './../../../components/PlayersComponents/DisplayDocumentNewComponent'

import {
  IdentityInterface,
  AccountInterface,
  getPlayersDetailsInterface,
} from './interfaces'
import { useFormik } from 'formik'
import { UpdatePlayersEmailAndPhoneComponent } from '../../../components/PlayersComponents/UpdatePlayersEmailAndPhoneComponent'

import {
  validate,
  onSubmit,
  setVariables,
  setFiledValue,
  refreshComponent,
  setFirstPass,
} from './functions'

import eventEmitter from './../../../services/EventEmitter'
import { playerSupportRoutePath } from './../../../config.js'
import { useHistory } from 'react-router-dom'
import { PLAYERS_PAGES } from '../../../application-data-structure/players-page.data-structure'
import { CircularProgress } from '@material-ui/core'
import { keyPressedType } from '../../../services/Commons'
import {
  PageMessageCommonComponent,
  pageMessageDefault,
} from '../../../components/CommonComponents/PageMessageCommonComponent'

let eventEmitterInitialize: any = undefined
export const UpdatePlayerEmailAndPhonePage = ({ id, storesObject }: any) => {
  const playerStore: any = storesObject.playerStore
  const history = useHistory()
  const identity: IdentityInterface = {
    firstName: '',
    lastName: '',
    gender: '',
    ssn: undefined,
    dateOfBirth: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip5: '',
    phone: '',
    acceptedEmailCommunication: false,
    acceptedSmsCommunication: false,
    identityCheckResult: '',
    identityCheckSource: '',
    identityCheckNotes: '',
    termsAcceptedDate: '',
    status: '',
  }
  const account: AccountInterface = {
    id: '',
    email: '',
    emailVerified: true,
    creationDate: '',
    enabled: true,
    status: '',
  }
  const setTogglevalue: any = { value: false }
  const getPlayersDetailsData: getPlayersDetailsInterface = {
    identity,
    account,
  }

  const callingPage = PLAYERS_PAGES
  const [saveToggle, setSaveToggle] = useState<any>(setTogglevalue)
  const [cancelToggle, setCancelToggle] = useState<boolean>(false)
  const [emailAndPhoneChangeToggle, setemailAndPhoneChangeToggle] =
    useState<boolean>(false)
  const [pageMessage, setPageMessage] = useState<any>({
    message: '',
    messageType: '',
  })
  const [isData, setIsData] = useState<boolean>(true)

  const formik = useFormik({
    initialValues: playerStore.selectedPlayerDetails,
    onSubmit,
    validate,
  })
  try {
    let phoneArray = formik.values.identity.phone.split('')

    if (!(phoneArray[0] === '(') && formik.values.identity.phone.length > 0) {
      phoneArray = phoneArray.filter((e: any, i: number) => {
        if (!((e === '+' && i === 0) || (e === '1' && i === 1))) return e
      })
      formik.values.identity.phone = `(${phoneArray
        .slice(0, 3)
        .join('')})${phoneArray.slice(3, 6).join('')}-${phoneArray
        .slice(6, phoneArray.length)
        .join('')}`
    }
  } catch (e) {}
  useEffect(() => {
    document
      .getElementById('update-player-email-and-phone-save-box-container')
      ?.focus()
    document
      .getElementById('update-player-email-and-phone-confirmation-container')
      ?.focus()
  })
  setVariables(
    formik,
    storesObject.playerStore,
    history,
    setemailAndPhoneChangeToggle,
    pageMessage,
    setPageMessage,
    setIsData
  )
  if (eventEmitterInitialize === undefined)
    eventEmitter.addListener('refresh-data-ready', refreshComponent)

  return (
    <>
      {(((playerStore.selectedPlayerDetails.account.email === undefined ||
        playerStore.selectedPlayerDetails.account.email === '') &&
        pageMessage.message === '') ||
        (!isData && pageMessage.message === '')) && (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="8em" color="inherit" thickness={2} />
        </div>
      )}

      <div
        onClick={(e: any) => {
          pageMessage.message !== '' &&
            setPageMessage({ message: '', messageType: '' })
        }}
        className={styles['page-container']}
      >
        <div className={styles['top-ribbon']}>
          <div className={styles['top-ribbon-text']} tabIndex={0}>
            Update Email and Phone
          </div>
          <div className={styles['top-ribbon-button-container']}>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  setCancelToggle(!cancelToggle)
                }
              }}
              onClick={() => setCancelToggle(!cancelToggle)}
              className={styles['top-ribbon-cancel-button']}
            >
              Cancel
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              style={{
                opacity: Object.keys(formik.errors).length > 0 ? '0.4' : '1',
                cursor:
                  Object.keys(formik.errors).length === 0
                    ? 'pointer'
                    : 'not-allowed',
              }}
              onKeyPress={(e: any) => {
                if (
                  (keyPressedType(e) === 13 ||
                    keyPressedType(e) === 0 ||
                    keyPressedType(e) === 32) &&
                  Object.keys(formik.errors).length === 0
                ) {
                  setSaveToggle({ value: true })
                  saveToggle.value = true
                }
              }}
              onClick={() => {
                if (Object.keys(formik.errors).length === 0) {
                  setSaveToggle({ value: true })
                  saveToggle.value = true
                }
              }}
              className={styles['top-ribbon-save-button']}
            >
              Save
            </div>
          </div>
        </div>
        {pageMessage.message !== '' && (
          <PageMessageCommonComponent
            message={pageMessage.message}
            messageType={pageMessage.messageType}
          />
        )}
        <div className={styles['page-container-body-container']}>
          <div className={styles['page-container-body']}>
            <div className={styles['page-container-body-left']}>
              <UpdatePlayersEmailAndPhoneComponent
                formik={formik}
                setFiledValue={setFiledValue}
              />
            </div>
            <div className={styles['page-container-body-right']}></div>
          </div>
        </div>
      </div>
      {saveToggle.value && (
        <div className={stylesMessageBox['window-container']}>
          <div
            tabIndex={0}
            id={'update-player-email-and-phone-save-box-container'}
            className={
              stylesMessageBox[
                'update-player-email-and-phone-save-box-container'
              ]
            }
          >
            <div
              tabIndex={0}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-save-box-message'
                ]
              }
            >
              Are you sure you would like to save?
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  setIsData(false)
                  formik.submitForm()
                  playerStore.clearPlayerSearchResults()
                  setFirstPass(false)
                  saveToggle.value = false
                }
              }}
              onClick={() => {
                setIsData(false)
                formik.submitForm()
                playerStore.clearPlayerSearchResults()
                setFirstPass(false)
                saveToggle.value = false
              }}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-save-box-button-yes'
                ]
              }
            >
              <div
                className={
                  stylesMessageBox[
                    'update-player-email-and-phone-save-button-yes-text'
                  ]
                }
              >
                Yes, Save
              </div>
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  setSaveToggle({ value: !saveToggle.value })
                }
              }}
              onClick={() => setSaveToggle({ value: !saveToggle.value })}
              className={
                stylesMessageBox['player-email-and-phone-message-box-button-no']
              }
            >
              Go Back
            </div>
          </div>
        </div>
      )}
      {cancelToggle && (
        <div className={stylesMessageBox['window-container']}>
          <div
            tabIndex={0}
            id={'update-player-email-and-phone-save-box-container'}
            className={
              stylesMessageBox[
                'update-player-email-and-phone-save-box-container'
              ]
            }
          >
            <div
              tabIndex={0}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-save-box-message'
                ]
              }
            >
              Are you sure you would like to cancel without saving?
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  history.push(`${playerSupportRoutePath}/player-hub-new/${id}`)
                }
              }}
              onClick={() =>
                history.push(`${playerSupportRoutePath}/player-hub-new/${id}`)
              }
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-save-box-button-yes'
                ]
              }
            >
              <div
                className={
                  stylesMessageBox[
                    'update-player-email-and-phone-save-button-yes-text'
                  ]
                }
              >
                Yes, Cancel
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  setCancelToggle(!cancelToggle)
                }
              }}
              onClick={() => setCancelToggle(!cancelToggle)}
              className={
                stylesMessageBox['player-email-and-phone-message-box-button-no']
              }
            >
              Go Back
            </div>
          </div>
        </div>
      )}
      {emailAndPhoneChangeToggle && (
        <div className={stylesMessageBox['window-container']}>
          <div
            id={'update-player-email-and-phone-confirmation-container'}
            tabIndex={0}
            className={
              stylesMessageBox[
                'update-player-email-and-phone-confirmation-container'
              ]
            }
          >
            <div
              tabIndex={0}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-confirmation-text'
                ]
              }
            >
              Update Successful!
            </div>
            <div
              tabIndex={0}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-confirmation-message'
                ]
              }
            >
              The player now will receive an email confirmation that their
              information was updated.
            </div>
            <br />
            <div
              tabIndex={0}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-confirmation-message'
                ]
              }
            >
              If this email address was updated, the player will receive a
              6-digit verification code to the new email address immediately.
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  setemailAndPhoneChangeToggle(false)
                  history.push(
                    `${playerSupportRoutePath}/player-hub-new/${formik.values.account.id}`
                  )
                }
              }}
              onClick={() => {
                setemailAndPhoneChangeToggle(false)
                history.push(
                  `${playerSupportRoutePath}/player-hub-new/${formik.values.account.id}`
                )
              }}
              className={
                stylesMessageBox[
                  'update-player-email-and-phone-confirmation-button-close'
                ]
              }
            >
              <div
                className={
                  stylesMessageBox[
                    'update-player-email-and-phone-confirmation-close-text'
                  ]
                }
              >
                Close
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
