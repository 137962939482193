import { PayTaxIndicatorMapping } from '../../../../pages/PaymentsPages/Constants'
import { SubAccount } from '../../../../types/Annuities/entities/sub-account'

enum TaxDesignation {
  IRS_DOR = 1,
  IRS_ONLY,
  DOR_ONLY,
  NON,
}

export const getTaxDeclaration = (subAccount: SubAccount) => {
  const { irsTaxEnabled, dorTaxEnabled } = subAccount
  if (irsTaxEnabled && dorTaxEnabled) {
    return TaxDesignation.IRS_DOR
  }
  if (irsTaxEnabled) {
    return TaxDesignation.IRS_ONLY
  }
  if (dorTaxEnabled) {
    return TaxDesignation.DOR_ONLY
  }

  return TaxDesignation.NON
}

export const mapTaxDeclarationCode = (taxDesignationCode: TaxDesignation) => {
  switch (taxDesignationCode) {
    case 1:
      return PayTaxIndicatorMapping.IRS_DOR
    case 2:
      return PayTaxIndicatorMapping.IRS_ONLY
    case 3:
      return PayTaxIndicatorMapping.DOR_ONLY
    case 4:
    default:
      return PayTaxIndicatorMapping.NON
  }
}
