import { observer } from 'mobx-react'
import React from 'react'
import { permissions } from '../../constants.js'
import CustomCard from '../../dashboard/customCard.js'
import { checkRenderPermissions, checkAllRenderPermissions } from '../../helpers.js'
import { reportingRoutePath } from '../../config.js'

@observer
class CheckRunReportingMenu extends React.Component {
  navigateToCheckRunReporting = () => {
    this.props.history.push(`${reportingRoutePath}/finance/reporting/check-run`)
  }

  navigateToCheckLoadReports = () => {
    this.props.history.push(`${reportingRoutePath}/finance/reporting/check-load`)
  }

  render() {
    if (
      checkRenderPermissions(
        permissions.CAN_VIEW_CHECK_RUN_REPORTS,
        JSON.parse(localStorage.getItem('userpolicies'))
      ) ||
      checkAllRenderPermissions(
        [permissions.CAN_SEE_CHECK_REGISTER_REPORT, permissions.CAN_SEE_SSN],
        JSON.parse(localStorage.getItem('userpolicies'))
      )
    ) {
      return (
        <div
          style={{
            paddingTop: '8em',
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: 'rgb(22, 58, 95)',
          }}
        >
          {checkRenderPermissions(
            permissions.CAN_VIEW_CHECK_RUN_REPORTS,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="General Check Run Reporting"
              handleCallback={() => this.navigateToCheckRunReporting()}
            />
          ) : null}
          {checkAllRenderPermissions(
            [permissions.CAN_SEE_CHECK_LOAD_REPORT,
            permissions.CAN_SEE_SSN],
            JSON.parse(localStorage.getItem('userpolicies'))) ? (
            <CustomCard
              title="Check Load Reports"
              handleCallback={() => this.navigateToCheckLoadReports()}
            />
          ) : null}
        </div>
      )
    } else {
      return (
        <h3 className="unauthorized-header">
          You do not have permission to view this page! Please contact your
          System Administrator!
        </h3>
      )
    }
  }
}
export default CheckRunReportingMenu
