import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CreatePayeeCmpt } from "../../../components/AnnuitiesComponents/TPAManagement/CreatePayeeCmpt";
import { annuitiesRoutePath } from "../../../config";
import { KEY_CODES } from "../../../constants.js";
import { BannerContext } from "../../../context/BannerContext";
import { keyPressedType } from "../../../services/Commons";
import styles from "./css/styles.module.css";
import { onSubmit, submitPayee, validate } from "./functions";

export const AnnuitiesCreatePayeePage = () => {
  const history = useHistory();

  // Context
  const bannerContext = React.useContext(BannerContext);
  const idemToken = uuidv4();

  // payeeType from URL
  const payeeTypeNames = ["Individual", "Entity"] as const;
  type payeeTypeNames = typeof payeeTypeNames[number];

  const grabPayeeType: () => payeeTypeNames = () => {
    const URLFields = window.location.href.split("/");

    let payeeType = "";
    const defaultPayeeType = "Individual";

    if (URLFields[URLFields.length - 1] === "create-payee") {
      // Case if someone types /create-payee/ directly into browser
      payeeType = "Individual";
    } else if (URLFields[URLFields.length - 1].length >= 11) {
      // Case if tpaID is found at end of URL
      // TODO: further verification that this is actually an ID (regex perhaps?)
      payeeType = URLFields[URLFields.length - 2];
      //setPassedTPAID(URLFields[URLFields.length - 1]);
    } else {
      // Case if no tpa ID, just take last part of url for payee type
      payeeType = URLFields[URLFields.length - 1];
    }

    if (payeeType === "Individual") {
      return "Individual";
    } else if (payeeType === "Entity") {
      return "Entity";
    } else {
      // Catchall safety case
      return defaultPayeeType;
    }
  };

  //set state for page flow & Payee Type to know how to populate certain fields
  const [payeeType, setPayeeType] = useState<"Individual" | "Entity">(grabPayeeType());
  const [passedTPAID, setPassedTPAID] = useState<string>("");

  const fieldNames = ["firstName", "middleInitial", "lastName", "tin", "freeformName", "addr_1", "addr_2", "city", "state_region", "zip_code", "country", "phone", "email", "birthDate", "irsName"] as const;

  //helper field to export and use the array type
  type fieldNames = typeof fieldNames[number];

  const initialFieldValues: Record<fieldNames, string> = {
    firstName: "",
    middleInitial: "",
    lastName: "",
    tin: localStorage.getItem("tinNotFound") ?? "",
    freeformName: "",
    addr_1: "",
    addr_2: "",
    city: "",
    state_region: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
    birthDate: "",
    irsName: ""
  };

  //initialize formik
  const formik = useFormik({
    initialValues: { ...initialFieldValues },
    enableReinitialize: true,
    onSubmit,
    validate,
  });

  useEffect(() => {
    const URLFields = window.location.href.split("/");
    if (URLFields[URLFields.length - 1].length >= 11) {
      // Case if tpaID is found at end of URL
      // TODO: further verification that this is actually an ID (regex perhaps?)
      setPassedTPAID(URLFields[URLFields.length - 1]);
    }
  }, []);

  return (
    <>
      <div className={styles["page-container"]}>
        {/* Header component jsx */}
        <div className={styles["cmpnt-container"]}>
          <div className={styles["title-header"]}>
            <div className={styles["flex-space-bw"]}>
              <div className={styles["title-text"]}>Create Payee</div>
              <div>
                <button
                  className={`${styles.dark_btn}`}
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      if (localStorage.getItem("tinNotFound") !== null) localStorage.removeItem("tinNotFound");
                      passedTPAID.length > 0 ? history.push(`${annuitiesRoutePath}/annuity-management-details/${passedTPAID}`) : history.push(`${annuitiesRoutePath}`);
                    }
                  }}
                  onClick={() => {
                    if (localStorage.getItem("tinNotFound") !== null) localStorage.removeItem("tinNotFound");
                    passedTPAID.length > 0 ? history.push(`${annuitiesRoutePath}/annuity-management-details/${passedTPAID}`) : history.push(`${annuitiesRoutePath}`);
                  }}
                >
                  Cancel
                </button>
                <button
                  className={
                    Object.keys(formik.errors).length > 0
                      ? `${styles.light_btn_disabled}`
                      : `${styles.light_btn}`
                  }
                  disabled={
                   Object.keys(formik.errors).length > 0
                  }
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      if (formik.dirty) submitPayee(formik, payeeType, passedTPAID, history, bannerContext, idemToken);
                    }
                  }}
                  onClick={() => {
                    if (formik.dirty) submitPayee(formik, payeeType, passedTPAID, history, bannerContext, idemToken);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Main components */}
        <div className={styles["addtnl-cmpt-container"]}>
          <CreatePayeeCmpt formik={formik} payeeType={grabPayeeType()} />
        </div>
      </div>
    </>
  );
};
