import * as React from 'react'
import { permissions } from '../../../constants.js'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { PlayslipsStepDownPageUI } from './playslips-step-down-page-ui'
import { useFetchAllPlayslipsStepDowns } from '../../../api/playslips-fetcher/use-fetch-playslips'

export const PlayslipsStepDownPageContainer = () => {
  const { data, isLoading, error, fetchAllPlayslipsStepDowns } =
    useFetchAllPlayslipsStepDowns()

  return (
    <PlayslipsStepDownPageUI
      data={data}
      isLoading={isLoading}
      error={error}
      fetchAllPlayslipsStepDowns={fetchAllPlayslipsStepDowns}
      actionsDisabled={
        !userHasAnyOfPermissions([permissions.CAN_ADMIN_PLAYSLIPS_STEP_DOWN])
      }
    />
  )
}
