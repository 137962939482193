import config from "../../../../config.js";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { TimePaymentAccountPayee } from "../../../../types/Annuities/PayeeManagement/Payee";
import { SubAccountTreeNode } from "../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { PaymentScheduleEntry } from "../../../../types/Annuities/responses/payment-schedule.js";
import { TimePaymentAccountResponse } from "../../../../types/Annuities/responses/time-payment-account";
import { AccountStatusOptions } from "../../../../types/Annuities/type-options/account-status";

// Loading... sub account variable
const tempSubAccountLoadingProps: SubAccountTreeNode = {
  account: {
    timePaymentAccountPayeeId: "Loading...",
    irsTaxEnabled: true,
    dorTaxEnabled: true,
    acctStatus: "Loading...",
    parentTaxResponsibility: true,
    parentAccountId: "Loading...",
    supportingInformation1: "Loading...",
    supportingInformation2: "Loading...",
    id: "Loading...",
    validFrom: new Date("2022-01-01T05:00:00.000Z"),
    validTo: "string",
    createdOn: new Date("2022-01-01T05:00:00.000Z"),
    notes: "Loading...",
  },
  paymentRules: [
    {
      subAccountId: "Loading...",
      startDate: new Date("2022-01-01T05:00:00.000Z"),
      endDate: new Date("2022-01-01T05:00:00.000Z"),
      amount: 1000000,
      id: "Loading...",
      validFrom: new Date("2022-01-01T05:00:00.000Z"),
      validTo: new Date("2022-01-01T05:00:00.000Z"),
      createdOn: new Date("2022-01-01T05:00:00.000Z"),
      notes: "Loading...",
    },
  ],
  subAccountChildren: [],
  paymentMade: true,
};

// GET PlaceHolder Sub Accounts
export const getPlaceholderSubAccount = (): SubAccountTreeNode => {
  const placeholderTempSubAccountProps = {
    ...tempSubAccountLoadingProps,
  };

  return placeholderTempSubAccountProps;
};

// GET SubAccounts
export const getSubAccounts = async (
  tpaId: string
): Promise<SubAccountTreeNode> => {
  let subAccountTree: SubAccountTreeNode;

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaId}/sub-account`,
  };

  try {
    subAccountTree = await makeHttpCall(options);
  } catch (error) {
    subAccountTree = tempSubAccountLoadingProps;
    throw new Error("Something went wrong searching for Sub-Accounts");
  }

  return subAccountTree;
};

// GET PAYEE
export const getTpaPayeeList = async (
  tpaId: string
): Promise<TimePaymentAccountPayee[]> => {
  let payeeList: TimePaymentAccountPayee[];

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaId}/payee`,
  };

  try {
    const response = await makeHttpCall(options);
    payeeList = response;
  } catch (error) {
    payeeList = [];
    throw new Error("Something went wrong searching for Payees");
  }

  return payeeList;
};

// GET TPA details Placeholder
export const getPlaceholderTpa = (): TimePaymentAccountResponse => {
  const tempTpa: TimePaymentAccountResponse = {
    id: "Loading...",
    validFrom: new Date("2022-01-01T05:00:00.000Z"),
    validTo: new Date("2022-01-01T05:00:00.000Z"),
    createdOn: new Date("2022-01-01T05:00:00.000Z"),
    gameName: "Loading...",
    gameType: "Loading...",
    drawDate: new Date("2022-01-01T05:00:00.000Z"),
    claimDate: new Date("2022-01-01T05:00:00.000Z"),
    remainingPayments: 30,
    paymentsMade: 1,
    lifetimePayment: true,
    documentId: "Loading...",
    investAcctNum: "Loading...",
    payFrequency: "Loading...",
    tpaStatus: AccountStatusOptions.Active,
    grossAmount: 0,
    irsAmount: 0,
    dorsAmount: 0,
    offsetsAmount: 0,
    recurringPaymentAmount: 0,
    firstPaymentSrcId: "Loading...",
    notes: "Loading...",
    firstPaymentDate: new Date("2022-01-01T05:00:00.000Z"),
    prevPaymentDate: new Date("2022-01-01T05:00:00.000Z"),
    nextPaymentDate: new Date("2022-01-01T05:00:00.000Z"),
    finalPaymentDate: new Date("2022-01-01T05:00:00.000Z"),
    winAmount: 0,
  };
  return tempTpa;
};

// Get TPA Details
export const getTpaDetails = async (tpaId: string) => {
  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaId}`,
  };

  try {
    const response: TimePaymentAccountResponse = await makeHttpCall(options);
    return response;
  } catch (error) {
    throw new Error(
      "Something went wrong searching Time Payment Account Details"
    );
  }
};

// Get Payment Schedule
export const getPaymentSchedule = async (
  id: string
): Promise<PaymentScheduleEntry[]> => {
  let schedule: PaymentScheduleEntry[] = [];

  try {
    const options = {
      method: "get",
      url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${id}/payment?forecastPayments=true`,
    };

    schedule = await makeHttpCall(options);
  } catch (error) {
    throw new Error("Failed to get payment schedule");
  }

  return schedule;
};
