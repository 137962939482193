import * as React from 'react'
import { AllPromotionsResponse } from '../../../api/promotions-fetcher/constants'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { WinnersManagementPromotionListUI } from '../../../components/PromotionsComponents/winners-management/winners-management-promotion-list/winners-management-promotion-list-ui'
import { WinnersManagementWeeklyRunContainer } from '../../../components/PromotionsComponents/winners-management/winners-management-weekly-run/winners-management-weekly-run-container'
import { getBlockClass, getModifierClass } from '../../../utilities/helpers'
import './styles.scss'

type WinnersManagementLandingPageProps = {
  promotionsLoading: boolean
  promotions?: AllPromotionsResponse
  promotionsError?: string
}

const ROOT_CLASS = 'winners-management-landing-page-ui'

enum WINNERS_MANAGEMENT_TABS {
  PROMOTIONS = 1,
  WEEKLY_RUN,
}

export const WinnersManagementLandingPageUI = (
  props: WinnersManagementLandingPageProps
) => {
  const [selectedTab, setSelectedTab] = React.useState<WINNERS_MANAGEMENT_TABS>(
    WINNERS_MANAGEMENT_TABS.PROMOTIONS
  )

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI message="Back to Promotions" tabIndex={1} />
      <div className={getBlockClass(ROOT_CLASS, 'container')}>
        <header className={getBlockClass(ROOT_CLASS, 'header')}>
          Promotions
        </header>
        <div className={getBlockClass(ROOT_CLASS, 'tab-container')}>
          <button
            className={`${getBlockClass(ROOT_CLASS, 'tab')} ${getModifierClass(
              ROOT_CLASS,
              'tab-selected',
              selectedTab === WINNERS_MANAGEMENT_TABS.PROMOTIONS
            )}`}
            onClick={() => {
              setSelectedTab(WINNERS_MANAGEMENT_TABS.PROMOTIONS)
            }}
          >
            All Promotions
          </button>
          <button
            className={`${getBlockClass(ROOT_CLASS, 'tab')} ${getModifierClass(
              ROOT_CLASS,
              'tab-selected',
              selectedTab === WINNERS_MANAGEMENT_TABS.WEEKLY_RUN
            )}`}
            onClick={() => {
              setSelectedTab(WINNERS_MANAGEMENT_TABS.WEEKLY_RUN)
            }}
          >
            Weekly Run - Winners
          </button>
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          {selectedTab === WINNERS_MANAGEMENT_TABS.PROMOTIONS ? (
            <WinnersManagementPromotionListUI {...props} />
          ) : (
            <WinnersManagementWeeklyRunContainer {...props} />
          )}
        </div>
      </div>
    </div>
  )
}
