import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import InputMask from 'react-input-mask';

export const UpdateOrganizationComponent = ({formik, setFieldValue}: any) => {

    return (
        <>
            <form className={styles['update-org-details-component-container']}>
                <div tabIndex={0} className={styles['update-org-details-component-title']}>Organization</div>
                <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-org-details-component-label"]}>Name of Organization*
                    </div>
                    <input
                        id="orgDetails.orgName"
                        name="orgDetails.orgName"
                        className={styles["update-org-details-component-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.orgDetails.orgName}
                    />
                    {(formik.touched.orgDetails?.orgName && formik.errors.orgDetails?.orgName) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.orgDetails?.orgName}/> : null}
                </div>
                <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-org-details-component-label"]}>Org. Telephone*</div>
                    <InputMask
                        autoComplete={"text"}
                        id="orgDetails.orgPhone"
                        name="orgDetails.orgPhone"
                        className={styles["update-org-details-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.orgDetails.orgPhone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.orgDetails?.orgPhone && formik.errors.orgDetails?.orgPhone) ?
                        <ErrorMessageFieldCommonComponent
                            orgPhone={formik.errors.orgDetails?.orgPhone}/> : null}
                </div>
                <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-org-details-component-label"]}>Date Org. Chartered*
                    </div>
                    <input
                        autoComplete={"off"}
                        id="orgDetails.orgDateChartered"
                        name="orgDetails.orgDateChartered"
                        value={formik.values.orgDetails.orgDateChartered}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["update-org-details-component-input-box"]}`}
                        type="date"/>
                    {(formik.touched.orgDetails?.orgDateChartered && formik.errors.orgDetails?.orgDateChartered) ?
                        <ErrorMessageFieldCommonComponent
                            orgDateChartered={formik.errors.orgDetails?.orgDateChartered}/> : null}
                </div>
                <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-org-details-component-label"]}>Street Address*</div>
                    <input
                        autoComplete={"text"}
                        id="orgDetails.orgAddress.streetAddress"
                        name="orgDetails.orgAddress.streetAddress"
                        className={styles["update-org-details-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.orgDetails.orgAddress.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.orgDetails?.orgAddress?.streetAddress && formik.errors.orgDetails?.orgAddress?.streetAddress) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.orgDetails?.orgAddress?.streetAddress}/> : null}
                </div>
                <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-org-details-component-label"]}>Mailing and Shipping
                        Address*
                    </div>
                    <input
                        autoComplete={"text"}
                        id="orgDetails.mailAddress.streetAddress"
                        name="orgDetails.mailAddress.streetAddress"
                        className={styles["update-org-details-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.orgDetails.mailAddress.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.orgDetails?.mailAddress?.streetAddress && formik.errors.orgDetails?.mailAddress?.streetAddress) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.orgDetails?.mailAddress?.streetAddress}/> : null}
                </div>
                <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-org-details-component-label"]}>Organization F.I.D*
                    </div>
                    <input
                        id="orgDetails.orgFID"
                        name="orgDetails.orgFID"
                        onChange={(e: any) => setFieldValue(e)}
                        className={styles["update-org-details-component-input-box"]}
                        value={formik.values.orgDetails.orgFID}
                        onBlur={formik.handleBlur}
                        type="text"
                        disabled={true}
                    />
                </div>

                <div className={styles['update-org-details-component-city-and-zip-container']}>
                    <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["update-org-details-component-label"]}>City/Town*</div>
                        <input
                            autoComplete={"text"}
                            id="orgDetails.orgAddress.city"
                            name="orgDetails.orgAddress.city"
                            value={formik.values.orgDetails.orgAddress.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFieldValue(e)}
                            className={`${styles["update-org-details-component-input-box"]}`}
                            type="text"
                        />
                        {(formik.touched.orgDetails?.orgAddress?.city && formik.errors.orgDetails?.orgAddress?.city) ?
                            <ErrorMessageFieldCommonComponent
                                city={formik.errors.orgDetails?.orgAddress?.city}/> : null}
                    </div>
                    <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["update-org-details-component-label"]}>Zipcode*</div>
                        <input
                            id="orgDetails.orgAddress.zip"
                            name="orgDetails.orgAddress.zip"
                            value={formik.values.orgDetails.orgAddress.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFieldValue(e)}
                            className={`${styles["update-org-details-component-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            maxLength={5}
                        />
                        {(formik.touched.orgDetails?.orgAddress?.zip && formik.errors.orgDetails?.orgAddress?.zip) ?
                            <ErrorMessageFieldCommonComponent
                                zip={formik.errors.orgDetails?.orgAddress?.zip}/> : null}
                    </div>
                </div>

                <div className={styles['update-org-details-component-city-and-zip-container']}>
                    <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["update-org-details-component-label"]}>City/Town*</div>
                        <input
                            id="orgDetails.mailAddress.city"
                            name="orgDetails.mailAddress.city"
                            value={formik.values.orgDetails.mailAddress.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFieldValue(e)}
                            className={`${styles["update-org-details-component-input-box"]}`}
                            type="text"
                        />
                        {(formik.touched.orgDetails?.mailAddress?.city && formik.errors.orgDetails?.mailAddress?.city) ?
                            <ErrorMessageFieldCommonComponent
                                city={formik.errors.orgDetails?.mailAddress?.city}/> : null}
                    </div>

                    <div className={styles["update-org-details-component-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["update-org-details-component-label"]}>Zipcode*</div>
                        <input
                            autoComplete={"text"}
                            id="orgDetails.mailAddress.zip"
                            name="orgDetails.mailAddress.zip"
                            value={formik.values.orgDetails.mailAddress.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFieldValue(e)}
                            className={`${styles["update-org-details-component-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            maxLength={5}
                        />
                        {(formik.touched.orgDetails?.mailAddress?.zip && formik.errors.orgDetails?.mailAddress?.zip) ?
                            <ErrorMessageFieldCommonComponent
                                zip={formik.errors.orgDetails?.mailAddress?.zip}/> : null}
                    </div>
                </div>
            </form>
        </>

    );
}