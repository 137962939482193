import React, { useState } from 'react'
import './../styles.scss'
import { W2gReissueFormFields } from '../../../../pages/PaymentsPages/ReviewW2GReissueIndividualPage/review-w2g-reissue-page-container'
import { ReissueW2gConfirmationUI } from '../w2g-reissue-confirmation-ui/w2g-reissue-confirmation-ui'

export type W2gReissueTitleBarProps = {
  submitButtonEnabled: boolean
  paymentDetails: W2gReissueFormFields
  submitAddToQueue: (request: W2gReissueFormFields) => Promise<void>
  submitReprintImmediately: (request: W2gReissueFormFields) => Promise<void>
}

const ROOT_CLASS = 'w2g-review-reissue'

export const ReviewW2gReissueTitleBar = (props: W2gReissueTitleBarProps) => {
  const {
    submitButtonEnabled,
    submitAddToQueue,
    submitReprintImmediately,
    paymentDetails,
  } = props

  const [showReissueOptionsModal, setShowReissueOptionsModal] =
    useState<boolean>(false)

  const confirmReissueButtonClass = submitButtonEnabled
    ? 'reissue-button-enabled'
    : 'reissue-button-disabled'

  return (
    <div className={`${ROOT_CLASS}-title-bar`}>
      <div className={'title'}>Review W-2G Re-Issue</div>
      <div className={'buttons-container'}>
        <button
          tabIndex={0}
          className={confirmReissueButtonClass}
          disabled={!submitButtonEnabled}
          onClick={() => setShowReissueOptionsModal(true)}
        >
          Confirm & Re-issue
        </button>
        {showReissueOptionsModal && (
          <ReissueW2gConfirmationUI
            submitAddToQueue={submitAddToQueue}
            submitReprintImmediately={submitReprintImmediately}
            paymentDetails={paymentDetails}
            setShowReissueOptionsModal={setShowReissueOptionsModal}
          />
        )}
      </div>
    </div>
  )
}
