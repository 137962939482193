import { makeHttpCall } from '../../../services/MakeHttpCall';
import config, { playerSupportRoutePath } from './../../../config.js';
import {
    IdentityInterface,
    AccountInterface,
    getPlayersDetailsInterface
} from './interfaces';


let formik: any;
let playerStore: any;
let ssnIsPresent: boolean = false;
let history:any;
let firstPass:boolean = false;
let setemailAndPhoneChangeToggle:any; 
let pageMessage:any;
let setPageMessage:any;
let setIsData:any;

export const setVariables = (formikObj: any, playerStoreObject: any, historyObject:any, _setemailAndPhoneChangeToggle:any , pageMessageObj:any, setPageMessageObj:any,setIsDataObj:any ) => {
    formik = formikObj;
    playerStore = playerStoreObject;
    history = historyObject;
    setemailAndPhoneChangeToggle = _setemailAndPhoneChangeToggle;
    pageMessage = pageMessageObj;
    setPageMessage = setPageMessageObj;
    setIsData = setIsDataObj;
}

export const refreshComponent = (response:any)=>{

    console.log('refreshComponent',refreshComponent);
    if(!(response.error === undefined))
    {
        pageMessage.message = "Something went wrong. Please try again.";
        pageMessage.messageType = "ERROR"; 
        setPageMessage( JSON.parse(JSON.stringify(pageMessage)));           
    }
    else
    {
        formik.initialValues = response;
        formik.setValues(response,false);    
    } 
     

}

export const setFirstPass = (value:any)=>{
    firstPass = value;

}

export const onSubmit = async (values: getPlayersDetailsInterface) => {

    formik.setValues(values, true);

    if (Object.keys(formik.errors).length <= 0) {
       
        const req: any = {
            newData:{
                firstName:JSON.stringify(playerStore.selectedPlayerDetails.identity.firstName),
                lastName: JSON.stringify(playerStore.selectedPlayerDetails.identity.lastName),
                phone:'',
                email:"",
            },
            oldData:{
                firstName:JSON.stringify(playerStore.selectedPlayerDetails.identity.firstName),
                lastName: JSON.stringify(playerStore.selectedPlayerDetails.identity.lastName),
                phone: JSON.stringify(playerStore.selectedPlayerDetails.identity.phone),
                email:JSON.stringify(playerStore.selectedPlayerDetails.account.email),
            }
        }

        const identity: any = values.identity;
        const account:any = values.account;
        req.newData['phone'] = (typeof identity['phone'] === 'string' ? identity['phone'] : "") ;
        req.newData['email'] = (typeof account['email'] === 'string' ? account['email'] : "") ;
        
        req.newData.phone = req.newData.phone.split("").filter((e:any)=>
         {
      
            if( !(e === "(" || e === ")" || e === "-" ))
                return e;
         }).join("");
                   
        
        try {
            const options =
            {
                method: 'post',
                url: config.SERVER_BASE_URL + '/v1/player/' + playerStore.selectedPlayerDetails.account.id,
                data: req
            }

            const response = await makeHttpCall(options);
            playerStore.selectedPlayerDetails.identity.phone =  `${req.newData['phone']}`;
           
            playerStore.selectedPlayerDetails.account.email = req.newData['email'];
            setIsData(true);
            setemailAndPhoneChangeToggle(true);            
            
        } catch (e) {
            setIsData(true);
            const er:any = e;
            if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
                if(value === "Network")
                  return value
            }) !== undefined)
            {
               setPageMessage({message: "Something went wrong.Network Error. Please try again.", messageType: "ERROR"});
            }else
              setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
            
        }

    }
}

export const setFiledValue = (e: any) => {
   
    formik.setFieldValue(e.target.name, e.target.value, true);
   
}
export const validate = (values: getPlayersDetailsInterface|any) => {
    const errors = {};
    if(!values.account.email)
    {
        Object.assign(errors, { email: 'Email must not be empty' });
    }    
    if(!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(values.account.email))
    {
        Object.assign(errors, { email: 'Email characters not valid' });
    }

    if(!values.identity.phone)
    {
        Object.assign(errors, { email: 'Phone must not be empty' });
    }    
    else if(values.identity.phone.length !== 13)
    {
        Object.assign(errors, { phone: 'Phone must be 10 digits' });
    }
  
   
    return errors;
}


