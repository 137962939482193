import React, { useState } from 'react'
import styles from './css/styles.module.css'
import { useHistory } from 'react-router-dom'
import { keyPressedType } from '../../../services/Commons'
import { checkRenderPermissions } from './../../../helpers.js'
import { permissions } from './../../../constants.js'
import { CircularProgress } from '@material-ui/core'
import { success, denied } from '../../../services/Icons'
import infoIcon from '../../../icons/question-circle.svg'
import { PlayerAccountStatusInfoMessageComponent } from '../../CommonComponents/PlayerAccountStatusInfoMessageComponent'

const getStatusIcon = (
  activeStatus: boolean,
  isEnabledDisabledText: boolean
) => {
  return activeStatus ? (
    <div title={'Approved'} className={styles['KYC-Status']}>
      <img
        alt="approved icon"
        style={{ width: '1em', height: '1em' }}
        src={success}
      />
      &nbsp;&nbsp;{isEnabledDisabledText ? 'Enabled' : 'Open'}
    </div>
  ) : (
    <div title={'Denied'}>
      <img
        alt={'Denied icon'}
        style={{ width: '1em', height: '1em' }}
        src={denied}
      />{' '}
      &nbsp;&nbsp;{isEnabledDisabledText ? 'Disabled' : 'Closed'}
    </div>
  )
}

const getActivityActionButton = (
  isAccountStatusOpen: boolean,
  isAccountStatusAction: boolean,
  ariaPressed: 'true' | 'false' | 'mixed',
  action: () => void,
  buttonText?: string
) => {
  return (
    <div
      className={
        styles[
          'player-document-info-players-document-details-content-button-hide-show-review-container'
        ]
      }
    >
      <div
        aria-live={'assertive'}
        role="button"
        aria-pressed={ariaPressed}
        tabIndex={0}
        onKeyPress={(e: any) => {
          if (
            keyPressedType(e) === 13 ||
            keyPressedType(e) === 0 ||
            keyPressedType(e) === 32
          ) {
            action()
          }
        }}
        onClick={() => {
          action()
        }}
        className={
          styles[
            'player-document-info-players-document-details-content-button-review'
          ]
        }
      >
        <div
          className={
            styles[
              'player-document-info-players-document-details-content-button-review-text'
            ]
          }
          style={{
            cursor:
              isAccountStatusOpen || isAccountStatusAction
                ? 'pointer'
                : 'not-allowed',
          }}
        >
          {buttonText ?? 'Change'}
        </div>
      </div>
    </div>
  )
}

export const PermissionsActivityTableComponent = ({
  id,
  isSignInEnabled,
  isTicketScanning,
  getSignIn,
  getTicketScanning,
  pageMessage,
  setPageMessage,
  storesObject,
  setIsTicketScanning,
  setRequestTicket,
  setSignInEnabled,
  setRequestSignIn,
  requestSignIn,
  requestTicket,
  playerStore,
  setIsAccountStatusOpen,
  isAccountStatusOpen,
  toggleAccountStatus,
  isMobileClaimsEnabled,
  setMobileClaimsConfirmIsOpen,
}: any) => {
  const userpolicies: any = localStorage.getItem('userpolicies')
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [showAccountStatusInfoMessage, setShowAccountStatusInfoMessage] =
    useState({ show: false })

  return (
    <>
      <div className={styles['KYCQUEUE-table']}>
        {(requestSignIn || requestSignIn) && pageMessage.message === '' && (
          <div
            style={{
              position: 'absolute',
              zIndex: 50,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.3)',
              color: 'white',
            }}
          >
            <CircularProgress size="8em" color="inherit" thickness={2} />
          </div>
        )}
        <div className={styles['KYCQUEUE-table-data-header']}>
          <div className={styles['KYCQUEUE-table-data-header-inner-container']}>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-header-title']}
            >
              Activity Statuses
            </div>
          </div>
        </div>

        <div
          className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}
        >
          <div tabIndex={0}>Activity</div>
          <div tabIndex={0}>Status</div>
        </div>
        <div className={styles['KYCQUEUE-table-data-body']}>
          <div
            style={{ backgroundColor: 'rgb(20, 52, 85)' }}
            className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}
          >
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
              onMouseEnter={() => {
                setShowAccountStatusInfoMessage({ show: true })
              }}
              onMouseLeave={() => {
                setShowAccountStatusInfoMessage({ show: false })
              }}
            >
              Account Status
              <img
                src={infoIcon}
                className={styles['menu-exclamation-icon']}
                alt={'infoIcon'}
              />
              {showAccountStatusInfoMessage.show ? (
                <PlayerAccountStatusInfoMessageComponent />
              ) : null}
            </div>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              {getStatusIcon(isAccountStatusOpen.value, false)}
            </div>
            <div
              tabIndex={0}
              className={`${styles['KYCQUEUE-table-data-data-text']} ${styles['column-3']}`}
            >
              {checkRenderPermissions(
                permissions.CAN_ENABLE_DISABLE_ACCOUNT_STATUS,
                JSON.parse(userpolicies)
              ) &&
                getActivityActionButton(
                  isAccountStatusOpen.value,
                  true,
                  isAccountStatusOpen.value ? 'true' : 'false',
                  () =>
                    toggleAccountStatus(
                      pageMessage,
                      setPageMessage,
                      storesObject,
                      isAccountStatusOpen,
                      setIsAccountStatusOpen,
                      isTicketScanning,
                      setIsTicketScanning,
                      id
                    )
                )}
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'rgb(30, 75, 117)',
              opacity: isAccountStatusOpen.value ? '1' : '0.4',
            }}
            className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}
          >
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              Sign In
            </div>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              {getStatusIcon(isSignInEnabled.value, true)}
            </div>
            <div
              tabIndex={0}
              className={`${styles['KYCQUEUE-table-data-data-text']} ${styles['column-3']}`}
            >
              {checkRenderPermissions(
                permissions.UNLOCK_PLAYER_ACCOUNT,
                JSON.parse(userpolicies)
              ) &&
                getActivityActionButton(
                  isAccountStatusOpen.value,
                  false,
                  isSignInEnabled ? 'mixed' : 'false',
                  () => {
                    if (isAccountStatusOpen.value) {
                      getSignIn(
                        pageMessage,
                        setPageMessage,
                        storesObject,
                        isSignInEnabled,
                        setSignInEnabled,
                        setRequestSignIn,
                        id
                      )
                    }
                  }
                )}
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'rgb(20, 52, 85)',
              opacity: isAccountStatusOpen.value ? '1' : '0.4',
            }}
            className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}
          >
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              Ticket Scanning
            </div>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              {getStatusIcon(isTicketScanning.value, true)}
            </div>
            <div
              tabIndex={0}
              className={`${styles['KYCQUEUE-table-data-data-text']} ${styles['column-3']}`}
            >
              {checkRenderPermissions(
                permissions.ENABLE_PLAYER_SCANS,
                JSON.parse(userpolicies)
              ) &&
                getActivityActionButton(
                  isAccountStatusOpen.value,
                  false,
                  isTicketScanning.value ? 'mixed' : 'false',
                  () => {
                    if (isAccountStatusOpen.value) {
                      getTicketScanning(
                        pageMessage,
                        setPageMessage,
                        storesObject,
                        isTicketScanning,
                        setIsTicketScanning,
                        setRequestTicket,
                        id
                      )
                    }
                  }
                )}
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'rgb(30, 75, 117)',
              opacity: isAccountStatusOpen.value ? '1' : '0.4',
            }}
            className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}
          >
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              Mobile Claims
            </div>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-data-text']}
            >
              {getStatusIcon(isMobileClaimsEnabled, true)}
            </div>
            <div
              tabIndex={0}
              className={`${styles['KYCQUEUE-table-data-data-text']} ${styles['column-3']}`}
            >
              {checkRenderPermissions(
                permissions.CAN_ACCESS_FREQUENT_CASHER_MODULE,
                JSON.parse(userpolicies)
              ) &&
                checkRenderPermissions(
                  permissions.SEE_PLAYER_VERIFICATION,
                  JSON.parse(userpolicies)
                ) &&
                getActivityActionButton(
                  isAccountStatusOpen.value,
                  false,
                  isMobileClaimsEnabled ? 'mixed' : 'false',
                  () => {
                    if (isAccountStatusOpen.value) {
                      setMobileClaimsConfirmIsOpen(true)
                    }
                  },
                  isMobileClaimsEnabled ? "Disable": "Enable",
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
