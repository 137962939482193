import {PermitHolderSearchResponseInterface} from "./interfaces";
import FuzzySearch from "fuzzy-search";
import config from "../../../../config";
import {makeHttpCall} from "../../../../services/MakeHttpCall";

let charitableGamingStore: any;
let unfilteredPermitHolders: any;
let setUnfilteredPermitHolders: any;
let filteredPermitHolders: any;
let setFilteredPermitHolders: any;
let setRequestCompleted: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let showNotificationBanner: any;

export const setVariables = (charitableGamingStoreObj: any, unfilteredLicenseesObj: any, setUnfilteredLicenseesObj: any,
                             filteredLicenseesObj: any, setFilteredLicenseesObj: any, setRequestCompletedObj: any,
                             setCurrentPageObj: any, setShowErrorDialogObj: any, showNotificationBannerObj: any) => {

    charitableGamingStore = charitableGamingStoreObj;
    unfilteredPermitHolders = unfilteredLicenseesObj;
    setUnfilteredPermitHolders = setUnfilteredLicenseesObj;
    filteredPermitHolders = filteredLicenseesObj;
    setFilteredPermitHolders = setFilteredLicenseesObj;
    setRequestCompleted = setRequestCompletedObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    showNotificationBanner = showNotificationBannerObj;
}

export const setFieldValue = (e: any, formik: any) => {

    if (e.target.name === "streetAddress") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("orgName", "", true);
        formik.setFieldValue("city", "", true);
        formik.setFieldValue("raffleId", "", true);
    } else if (e.target.name === "orgName") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("streetAddress", "", true);
        formik.setFieldValue("city", "", true);
        formik.setFieldValue("raffleId", "", true);
    } else if (e.target.name === "city") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("streetAddress", "", true);
        formik.setFieldValue("orgName", "", true);
        formik.setFieldValue("raffleId", "", true);
    } else if (e.target.name === "raffleId") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("streetAddress", "", true);
        formik.setFieldValue("orgName", "", true);
        formik.setFieldValue("city", "", true);
    }
}

export const handleCheckboxChange = (checkboxname: string, formik: any) => {

    setCurrentPage(0);

    if (checkboxname === 'includeActivePermitHolders') {
        formik.setFieldValue(checkboxname, !formik.values.includeActivePermitHolders, true);
    } else if (checkboxname === 'includeInactivePermitHolders') {
        formik.setFieldValue(checkboxname, !formik.values.includeInactivePermitHolders, true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    //alert(JSON.stringify(values));
    searchPermitHolders(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            streetAddress: '',
            orgName: '',
            city: '',
            raffleId: '',
            includeActivePermitHolders: false,
            includeInactivePermitHolders: false
        }
    });

    resetFilteredLicensees();

}

export const getPageData = (permitHolders: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(permitHolders[i] === undefined)) {
            permitHolders[i].index = index;
            index++;
            array.push(permitHolders[i]);
        }
    }
    return array;
}

export const getAllCurrentPermitHolders = async () => {

    try {

        const options = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/permitHolder`,
        }

        const response = await makeHttpCall(options);

        // Deep copy
        setUnfilteredPermitHolders(JSON.parse(JSON.stringify(response.permitHolders)));

        // Filter response.licensees for Active licensees amd then assign to this.filteredLicensees
        filteredPermitHolders = response.permitHolders.filter((permitHolder: PermitHolderSearchResponseInterface) => permitHolder.orgActive);
        setFilteredPermitHolders(filteredPermitHolders);
        //setFilteredPermitHolders(JSON.parse(JSON.stringify(response.permitHolders)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

const searchPermitHolders = (values: any) => {

    setRequestCompleted(false);

    setCurrentPage(0);

    let searcher;
    let key;
    let permitHolders;

    // Filter
    if (values.includeActivePermitHolders && values.includeInactivePermitHolders) { // no filtering needed
        permitHolders = JSON.parse(JSON.stringify(unfilteredPermitHolders));
    } else if (!values.includeActivePermitHolders && !values.includeInactivePermitHolders) {
        filteredPermitHolders = [];
        setFilteredPermitHolders(filteredPermitHolders);
        return;
    } else if (values.includeActivePermitHolders) {
        permitHolders = unfilteredPermitHolders.filter((permitHolder: PermitHolderSearchResponseInterface) => permitHolder.orgActive);
    } else if (values.includeInactivePermitHolders) {
        permitHolders = unfilteredPermitHolders.filter((permitHolder: PermitHolderSearchResponseInterface) => !permitHolder.orgActive);
    }


    if (!values.streetAddress && !values.orgName && !values.city && !values.raffleId) {
        filteredPermitHolders = JSON.parse(JSON.stringify(permitHolders))
        setFilteredPermitHolders(filteredPermitHolders);
    } else {

        // Search
        if (values.streetAddress) {
            searcher = new FuzzySearch(permitHolders, ['address.streetAddress'], {
                caseSensitive: false, sort: true
            });
            key = values.streetAddress;
        } else if (values.orgName) {
            searcher = new FuzzySearch(permitHolders, ['orgName'], {
                caseSensitive: false, sort: true
            });
            key = values.orgName;
        } else if (values.city) {
            searcher = new FuzzySearch(permitHolders, ['address.city'], {
                caseSensitive: false, sort: true
            });
            key = values.city;
        } else if (values.raffleId) {
            searcher = new FuzzySearch(permitHolders, ['raffleId'], {
                caseSensitive: false, sort: true
            });
            key = values.raffleId;
        }

        filteredPermitHolders = searcher.search(key);
        setFilteredPermitHolders(filteredPermitHolders);
    }

    setRequestCompleted(true);

}

const resetFilteredLicensees = () => {
    filteredPermitHolders = [];
    setFilteredPermitHolders(filteredPermitHolders);
}
