import React from 'react'
import { CheckRunReportDisplayRow } from '../../../../pages/FinancePages/CheckRunReportingPage/check-run-reporting-page-ui'
import './styles.scss'

type CheckRunReportingDownloadProps = {
  row: CheckRunReportDisplayRow
  downloadHandler: (row: CheckRunReportDisplayRow) => Promise<void>
}

export const CheckRunReportingDownloadButton = (
  props: CheckRunReportingDownloadProps
) => {
  return (
    <button
      onClick={() => {
        props.downloadHandler(props.row)
      }}
      className={'download-btn'}
    >
      Download
    </button>
  )
}
