import React from 'react'
import { persist } from 'mobx-persist'
import { action, observable } from 'mobx'
import config, {
  playerSupportRoutePath,
  charitableRoutePath,
  annuitiesRoutePath,
  administrationRoutePath,
  forbiddenRoutePath,
} from '../config'
import { checkRenderPermissions } from '../helpers'
import { permissions } from '../constants'
import { logoutHelpWidget } from '../components/FreshDeskWidget/freshdesk-widget'

export class AuthStore {
  rootStore = null

  // Password Login
  @observable passwordAuthErrorMessage = null
  @persist @observable accessToken = null
  @persist @observable refreshToken = null

  // OTP Verify
  @observable otpAuthErrorMessage = null
  @persist @observable otpAccessToken = null

  // User Details
  @persist @observable userId = null
  @persist @observable userName = null
  @persist @observable userFullName = null
  @persist @observable userRole = null
  @persist @observable userPolicies = null

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  postLogin(props) {
    this.getPolicies(props)
  }

  /**
   *
   * @param userName
   * @param password
   */
  @action loginWithPassword(email, password, props) {
    let data = {
      username: email,
      password: Buffer.from(password).toString('base64'),
    }

    fetch(`${config.SERVER_BASE_URL}/v1/auth/login`, {
      credentials: 'include',
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          this.passwordAuthErrorMessage = res.error
        } else {
          this.otpAuthErrorMessage = null
          this.passwordAuthErrorMessage = null

          const { firstname, lastname, userid, OTPAccessToken } = res
          localStorage.setItem('userName', email)
          if (userid) {
            localStorage.setItem(
              'loggedInUserFullName',
              `${firstname} ${lastname}`
            )
            localStorage.setItem('loggedInUserID', userid)

            this.userId = userid
            this.userName = email
            this.userFullName = `${firstname} ${lastname}`
            this.postLogin(props)
          } else if (OTPAccessToken !== undefined) {
            localStorage.setItem('OTPAccessToken', OTPAccessToken)
            this.otpAccessToken = OTPAccessToken
            this.userName = email
            props.history.push('/login/mfa')
          }
        }
      })
      .catch((error) => {
        this.passwordAuthErrorMessage = error.toString()
      })

    /*data = {
            "username": email
        }

        fetch(`${config.SERVER_BASE_URL}/v1/users/querypolicies`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(res => {
                if (res && res.error) {
                    //this.setState({errorMessage: res.error});
                    this.passwordAuthErrorMessage = res.error;
                } else {
                    localStorage.setItem('userpolicies', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                //this.setState({errorMessage: error.toString()});
                this.passwordAuthErrorMessage = error.toString();
            });*/
  }

  /**
   *
   * @param userName
   * @param TOTP
   */
  @action loginWithTOTP(OTP, props) {
    let data = {
      OTP: OTP,
    }

    fetch(`${config.SERVER_BASE_URL}/v1/auth/login/mfa`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getOTPAccessToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          this.otpAuthErrorMessage = res.error
        } else {
          this.otpAuthErrorMessage = null
          this.passwordAuthErrorMessage = null

          const { firstname, lastname, userid, userName } = res
          localStorage.setItem(
            'loggedInUserFullName',
            `${firstname} ${lastname}`
          )
          localStorage.setItem('loggedInUserID', userid)

          this.userId = userid
          this.userFullName = `${firstname} ${lastname}`
          this.postLogin(props)
        }
      })
      .catch((error) => {
        this.otpAuthErrorMessage = error.toString()
      })
  }

  /**
   *
   */
  @action requestOTP() {
    const token = localStorage.getItem('OTPAccessToken')

    fetch(`${config.SERVER_BASE_URL}/v1/auth/otp`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          this.otpAuthErrorMessage = res.error
        } else if (res && res.message) {
          this.otpAuthErrorMessage = res.message
        }
      })
      .catch((error) => {
        this.errorMessage = error.toString()
      })
  }

  /**
   *
   */
  @action logout(props) {
    const username = this.getUserName()
    const url = `${config.SERVER_BASE_URL}/v1/auth/logout`
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          alert(res.error)
        } else {
          localStorage.clear()
          this.userId = null
          props.history.push('/')
          logoutHelpWidget()
        }
      })
      .catch((error) => {
        alert('Error:', error)
      })
  }

  @action getPolicies(props) {
    const data = {
      username: localStorage.getItem('userName'),
    }

    fetch(`${config.SERVER_BASE_URL}/v1/users/querypolicies`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          //this.setState({errorMessage: res.error});
          this.passwordAuthErrorMessage = res.error
        } else {
          // props.history.push('/dashboard');
          localStorage.setItem('userpolicies', JSON.stringify(res))

          const userPolicies = localStorage.getItem('userpolicies')

          if (
            checkRenderPermissions(
              permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
              JSON.parse(userPolicies)
            )
          ) {
            props.history.push(`${playerSupportRoutePath}/search-new`, {
              userName: this.userName,
            })
          } else if (
            checkRenderPermissions(
              permissions.CAN_SEE_CHARITABLE_GAMING,
              JSON.parse(userPolicies)
            )
          ) {
            props.history.push(`${charitableRoutePath}`)
          } else if (
            checkRenderPermissions(
              permissions.CAN_SEE_ANNUITIES,
              JSON.parse(userPolicies)
            )
          ) {
            props.history.push(`${annuitiesRoutePath}`)
          } else {
            // Since the Role has no permissions for RTC, CG and Annuities
            // delete the userPolicies so only the 'Logout' button is visible
            localStorage.setItem('userpolicies', JSON.stringify([]))
            props.history.push(`${forbiddenRoutePath}`)
          }
        }
      })
      .catch((error) => {
        console.log(error)
        //this.setState({errorMessage: error.toString()});
        this.passwordAuthErrorMessage = error.toString()
      })
  }

  getOTPAccessToken() {
    return this.otpAccessToken
  }

  getUserName() {
    return this.userName
  }
}
