import React, { useEffect, useState } from "react";
import { PaymentResponse } from "../../../../../types/Annuities/TimePaymentAuthorization/PaymentResponse";
import { TimePaymentAuthSchedule } from "../../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization";
import { PaginationCommonComponent } from "../../../../CommonComponents/PaginationCommonComponent";
import { PaymentListRowCmpt } from "../PaymentListRowCmpt";
import styles from "./css/styles.module.css";
import { getPageData, normalizePage } from "./functions";

export const PaymentTableCmpt: React.FC<{
  paymentListData: PaymentResponse[] | TimePaymentAuthSchedule[];
  fromPage: string;
}> = ({ paymentListData, fromPage }) => {
  // state variables
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Pagination reset UseEffect to go back to page 1 when data updates
  useEffect(() => {
    setCurrentPage(0);
  }, [paymentListData]);

  return (
    <>
      <main className={`${styles.initiate_chk_run_cmpt}`}>
        {/* Payment Table */}
        <div className={`${styles.table} w-100`}>
          <div className={`${styles.table_results_display} d-flex align-items-center font-italic font-weight-bold`}>
            <div aria-live="assertive">{`Displaying ${
              paymentListData.length <= 0 ? 0 : currentPage * 10 + 1
            }-${normalizePage(paymentListData.length, currentPage)} of ${paymentListData.length} results`}</div>
          </div>
          <div className={`${styles.table_header} rounded-top font-weight-bold`}>
            <div>Doc ID</div>
            <div>Name</div>
            <div>TIN</div>
            <div>Cadence</div>
            <div>Payment Date</div>
            <div>Gross Payment</div>
            <div>Est. Federal Tax</div>
            <div>Est. State Tax</div>
            <div>Est. Net Payment</div>
            <div>Lifetime Payment</div>
            <div>Status</div>
            <div></div>
          </div>

          <div className={`${styles.table_body}`}>
            {paymentListData.length > 0 ? (
              getPageData(paymentListData, currentPage).map((paymentData: PaymentResponse, index: number) => (
                <PaymentListRowCmpt
                  fromPage={fromPage}
                  paymentData={paymentData}
                  paymentNumber={index + 1}
                  paymentDataLength={paymentListData.length}
                  key={index++}
                />
              ))
            ) : (
              <div className={`${styles.table_body_text} text-center pt-2`}>
                <em>No Payment List</em>
              </div>
            )}
          </div>
          <div
            className={`${styles.table_footer} p-2 rounded-bottom w-100 d-flex align-items-center justify-content-end mb-2`}
          >
            <div aria-live="assertive" className={`${styles.table_page_label} mr-2`}>{`Page ${
              paymentListData.length === 0 ? 0 : currentPage + 1
            } of ${Math.ceil(paymentListData.length / 10).toString()}`}</div>
            {
              <PaginationCommonComponent
                playerData={paymentListData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            }
          </div>
        </div>
      </main>
    </>
  );
};
