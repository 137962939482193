import React, { useState } from "react";
import styles from './css/styles.module.css';
import {
    checkAccountNumber,
    checkAccountType,
    checkRoutingNumber,
    clearResubmitField,
    PaymentAccountTypeCodes
} from "./functions";
import {ErrorMessageFieldCommonComponent, SuccessMessageFieldCommonComponent} from "../../CommonComponents/ErrorMessageFieldCommonComponent";
import { CircularProgress } from "@material-ui/core";


export const PaymentMethodComponent = ({
                                           formikPayment,
                                           setFiledValue,
                                           bankInformation
                                       }: any) => {

    const [checkingAccoutTypeError,setCheckingAccoutTypeError ] = useState<any>(undefined);                                    
    const [checkRoutingNumberError, setCheckRoutingNumberError ] = useState<any>(undefined);
    const [ checkAccountNumberError, setCheckAccountNumberError ] = useState<any>(undefined);
    
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Payment Method</div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Account Type</div>
                    <select
                        id="identityValues.accountType"
                        name="identityValues.accountType"
                        onChange={(e: any) => checkAccountType(e, formikPayment, setCheckingAccoutTypeError)}
                        value={formikPayment.values.identityValues.accountType}
                        aria-label={`${formikPayment.values.identityValues.accountType}.`}
                        className={styles["page-container-body-left-input-box"]}>
                        {PaymentAccountTypeCodes.map((g: string) => <option
                            selected={formikPayment.values.identityValues.accountType === g}
                            value={g}>{g}</option>)}
                    </select>
                    { (formikPayment.errors.accountType)  &&
                    <ErrorMessageFieldCommonComponent  accountType={formikPayment.errors.accountType}/>}
                </div>
                <br/>
                <div  className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div  tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Routing Number</div>
                            
                    <span  style={{ position:"relative",width:"100%" }}>
                    {bankInformation.value === 'wait' && <div style={{ position: 'absolute', zIndex:50, top: 0, left: 0, width: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
                                        <CircularProgress size="1em" color='inherit' thickness={2} />
                                    </div>} 
                    <input
                        autoComplete={"off"}
                        id="identityValues.routingNumber"
                        name="identityValues.routingNumber"
                        onChange={(e: any) => checkRoutingNumber(e, formikPayment,setCheckRoutingNumberError)}
                        onClick={(e:any) => clearResubmitField(e,formikPayment) }
                        value={formikPayment.values.identityValues.routingNumber}
                        className={styles["page-container-body-left-input-box"]}
                        aria-label={`${formikPayment.values.identityValues.routingNumber}.`}
                        type="text"
                    />
                    </span>
                    {formikPayment.errors.routingNumber &&
                    <ErrorMessageFieldCommonComponent checkRoutingNumberError={checkRoutingNumberError} routingNumber={formikPayment.errors.routingNumber}/>}
                     { (bankInformation.value && bankInformation.value !== "wait") && <SuccessMessageFieldCommonComponent bankNumber={bankInformation.value} /> }  
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Re-Enter Routing
                        Number
                    </div>
                    <input
                        autoComplete={"off"}
                        id="identityValues.reEnterRoutingNumber"
                        name="identityValues.reEnterRoutingNumber"
                        onChange={(e: any) => checkRoutingNumber(e, formikPayment, setCheckRoutingNumberError)}
                        onClick={(e:any) => clearResubmitField(e,formikPayment) }
                        value={formikPayment.values.identityValues.reEnterRoutingNumber}
                        className={styles["page-container-body-left-input-box"]}
                        aria-label={`${formikPayment.values.identityValues.reEnterRoutingNumber}.`}
                        type="text"
                    />
                    {(formikPayment.errors.reEnterRoutingNumber || formikPayment.errors.routingNumberError || checkRoutingNumberError) &&
                    <>
                        <ErrorMessageFieldCommonComponent routingNumberError={formikPayment.errors.routingNumberError}
                                                          reEnterRoutingNumber={formikPayment.errors.reEnterRoutingNumber}
                                                         checkRoutingNumberError={checkRoutingNumberError}/>
                    </>
                    }
                   
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Account Number</div>
                    <input
                        autoComplete={"off"}
                        id="identityValues.accountNumber"
                        name="identityValues.accountNumber"
                        value={formikPayment.values.identityValues.accountNumber}
                        onChange={(e: any) => checkAccountNumber(e, formikPayment,
                            formikPayment.values.identityValues.accountNumber,
                            setCheckAccountNumberError
                            )}
                        onClick={(e:any) => clearResubmitField(e,formikPayment) }
                        className={styles["page-container-body-left-input-box"]}
                        aria-label={`${formikPayment.values.identityValues.accountNumber}.`}
                        type="text"
                    />
                    { (formikPayment.errors.accountNumber ||  checkAccountNumberError ) &&
                    <ErrorMessageFieldCommonComponent checkAccountNumberError={checkAccountNumberError} accountNumber={formikPayment.errors.accountNumber}/>}
                    
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Re-Enter Account
                        Number
                    </div>
                    <input
                        autoComplete={"off"}
                        id="identityValues.reEnterAccountNumber"
                        name="identityValues.reEnterAccountNumber"
                        onChange={(e: any) => checkAccountNumber(e, formikPayment,
                            formikPayment.values.identityValues.reEnterAccountNumber,setCheckAccountNumberError)}
                        onClick={(e:any) => clearResubmitField(e,formikPayment) }
                        value={formikPayment.values.identityValues.reEnterAccountNumber}
                        className={styles["page-container-body-left-input-box"]}
                        aria-label={`${formikPayment.values.identityValues.reEnterAccountNumber}.`}
                        type="text"
                    />
                    {
                    (
                    formikPayment.errors.reEnterAccountNumber 
                    ||
                    checkAccountNumberError )
                    &&
                    <ErrorMessageFieldCommonComponent
                        checkAccountNumberError={checkAccountNumberError}
                        reEnterAccountNumber={formikPayment.errors.reEnterAccountNumber}/>}
                </div>
            </form>

        </>

    );
}