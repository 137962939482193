import * as React from 'react'
import { PlayerPromotionEntriesPage } from './PromotionEntriesTab/PlayerPromotionEntriesPage'
import { getBlockClass, getModifierClass } from '../../../../utilities/helpers'
import { ScanToEnterProgressTabContainer } from './ScanToEnterProgressTab/ScanToEnterProgressTabContainer'
import './styles.scss'

export type PlayerPromotionsPageProps = {
  playerId: string
}

enum PlayerPromotionTab {
  PROMOTION_ENTRIES = 1,
  SCAN_TO_ENTER_PROGRESS,
}

const ROOT_CLASS = 'player-promotions-page'
const TAB_CLASS = getBlockClass(ROOT_CLASS, 'tab')

export const PlayerPromotionsPage = (props: PlayerPromotionsPageProps) => {
  const [selectedTab, setSelectedTab] = React.useState<PlayerPromotionTab>(
    PlayerPromotionTab.PROMOTION_ENTRIES
  )

  return (
    <>
      <div className={getBlockClass(ROOT_CLASS, 'tab-container')}>
        <button
          className={`${TAB_CLASS} ${getModifierClass(
            TAB_CLASS,
            'selected',
            selectedTab === PlayerPromotionTab.PROMOTION_ENTRIES
          )}`}
          onClick={() => {
            setSelectedTab(PlayerPromotionTab.PROMOTION_ENTRIES)
          }}
        >
          Promotion Entries
        </button>
        <button
          className={`${TAB_CLASS} ${getModifierClass(
            TAB_CLASS,
            'selected',
            selectedTab === PlayerPromotionTab.SCAN_TO_ENTER_PROGRESS
          )}`}
          onClick={() => {
            setSelectedTab(PlayerPromotionTab.SCAN_TO_ENTER_PROGRESS)
          }}
        >
          Scan to Enter Progress
        </button>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        {selectedTab === PlayerPromotionTab.PROMOTION_ENTRIES ? (
          <PlayerPromotionEntriesPage playerId={props.playerId} />
        ) : (
          <ScanToEnterProgressTabContainer playerId={props.playerId} />
        )}
      </div>
    </>
  )
}
