import * as React from 'react'
import { GenericToolTip } from '../../../GenericToolTip/generic-tool-tip-ui'
import './styles.scss'

const ROOT_CLASS = 'campaign-sidebar-barcode-details__field__tool-tip'

export const PlayerFileNamingToolTip = () => {
  const toolTipElement = (
    <>
      <div className={`${ROOT_CLASS}__title`}>File Naming Requirements</div>
      <div className={`${ROOT_CLASS}__message`}>
        {`Player files must be named in the following format: `}
        <span
          className={`${ROOT_CLASS}__highlighted`}
        >{`player_barcodes_{campaignIdentifier}.csv`}</span>
        <br />
        or
        <br />
        <span
          className={`${ROOT_CLASS}__highlighted`}
        >{`player_barcodes_{campaignIdentifier}_{unique}.csv`}</span>
      </div>
    </>
  )
  return (
    <div className={ROOT_CLASS}>
      <GenericToolTip
        sizePx={15}
        leftPx={-62}
        altText="Player File Naming Requirements Tooltip"
        hiddenElement={toolTipElement}
      />
    </div>
  )
}
