import React, {useEffect} from "react";
import styles from './css/styles.module.css'
import {keyPressedType} from "../../../services/Commons";

export const NotificationBannerComponent = (notification: any) => {

    const className = notification.type === NotificationType.Success ? 'notification-banner-container-success' : 'notification-banner-container-error'

    useEffect(() => {
        setTimeout(function () {
            notification.handleHide({show: false, text: ''});
        }, 3000);
    }, []);

    return (
        <div tabIndex={0} className={styles[`${className}`]}
             onKeyPress={(e: any) => {
                 if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                     notification.handleHide({show: false, text: ''});
                 }
             }}
             onClick={() => {
                 //alert('onClick');
                 notification.handleHide({show: false, text: ''});
             }}
        >
            <div tabIndex={0} className={styles['notification-banner-text']}>{notification.text}</div>
        </div>
    )
};

export const NotificationType: any = {
    Success: "Success",
    Error: "Error"
}
