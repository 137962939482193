import { Field } from "formik"
import React from "react"
import './styles.scss'

export enum TextFieldType {
  TEXT = 'text',
  TEL = 'tel',
  EMAIL = 'email'
}

export type TextInputUIProps = {
  label: string
  name: string
  type: TextFieldType
  disabled?: boolean
}

const ROOT_CLASS = 'label-and-input-container'

export const TextInputUI = (props: TextInputUIProps) => {
  const { label, name, type, disabled } = props
  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'label' }>{ label }</div>
      <Field
        className={ 'input-box' } 
        type={ type }
        name={ name }
        disabled={ disabled }
      />
    </div>
  )
}

export type SelectOption = {
  value: string 
  label: string
}

export type SelectInputUIProps = {
  label: string
  name: string
  options: SelectOption[]
  disabled?: boolean
}

export const SelectInputUI = (props: SelectInputUIProps) => {
  const { label, name, options, disabled } = props
  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'label' }>{ label }</div>
      <Field
        className={ 'input-box' } 
        name={ name }
        as={ 'select' }
        disabled={ disabled }
      >
        {
          options.map((option: SelectOption) => {
            return (
              <option value={ option.value }>{ option.label }</option>
            )
          })
        }
      </Field>
    </div>
  )
}