import {
  AccountInterface,
  getPlayersDetailsInterface,
  IdentityInterface,
} from '../../../pages/PlayersPages/UpdateProfilePage/interfaces'

const identity: IdentityInterface = {
  firstName: '',
  lastName: '',
  gender: '',
  ssn: undefined,
  dateOfBirth: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip5: '',
  phone: '',
  acceptedEmailCommunication: false,
  acceptedSmsCommunication: false,
  identityCheckResult: '',
  identityCheckSource: '',
  identityCheckNotes: '',
  termsAcceptedDate: '',
  status: '',
  playerId: '',
  scanStatus: '',
  frequentCasher: false,
}
const account: AccountInterface = {
  id: '',
  email: '',
  emailVerified: false,
  creationDate: '',
  enabled: false,
  status: '',
}
const getPlayersDetailsData: getPlayersDetailsInterface = {
  identity: identity,
  account: account,
}
const playerDocImages: any = []

export { getPlayersDetailsData, playerDocImages }
