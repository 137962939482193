export interface PullTabGameInterface {
    stockNumber: string,
    gameName: string,
    unitPriceInCents: number,
    unitsPerCase: number,
    casePriceInCents: number,
    unitWeight: number,
    caseWeight: number,
    unitPriceInDollars: number,
    casePriceInDollars: number
}

export const PullTabGameDefaultValue = {
    stockNumber: '',
    gameName: '',
    unitPriceInCents: 0,
    unitsPerCase: 0,
    casePriceInCents: 0,
    unitWeight: 0,
    caseWeight: 0,
    unitPriceInDollars: 0,
    casePriceInDollars: 0
}