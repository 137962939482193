import * as React from 'react'

type Props = {
  width?: number
  height?: number
}

export const Caret = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 18} height={props.height || 18} viewBox="0 0 18 18">
    <title>Caret Icon</title>
    <g fill="#7FCBC4" fillRule="evenodd">
      <path d="M16.417 5.943v3.122a.416.416 0 0 1-.245.38L9.21 12.567a.416.416 0 0 1-.341 0L1.907 9.444a.416.416 0 0 1-.246-.38V5.944c0-.302.311-.503.586-.38L8.87 8.534a.416.416 0 0 0 .34 0l6.622-2.971a.416.416 0 0 1 .586.38z" />
      <path
        fillRule="nonzero"
        d="M2.562 4.864L9.04 7.77l6.477-2.907a1.183 1.183 0 0 1 1.668 1.079v3.122c0 .466-.274.889-.699 1.08l-6.962 3.124c-.308.138-.66.138-.969 0l-6.962-3.124a1.183 1.183 0 0 1-.698-1.08V5.943c0-.858.884-1.43 1.667-1.08zm-.133 1.621v2.353l6.61 2.966 6.612-2.966V6.485l-6.127 2.75c-.308.138-.66.138-.969 0L2.43 6.485z"
      />
    </g>
  </svg>
)
