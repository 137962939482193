import moment from "moment-timezone";
import React from "react";

export const getPageData = (KYCPlayerData: any[], page: number = 0) => {
    const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(KYCPlayerData[i] === undefined))
            array.push(KYCPlayerData[i]);
    }
    return array;
}

export const normalizeAmount = (value: any) => {
    try {
        let isNegative: boolean = false;
        if (parseInt(value) < 0) {
            isNegative = true;
            value = value * -1;
        }
        let dollars: string[] = value.toString().split("");
        let amount: string[] = [];
        let y: number = -1;
        for (let i = dollars.length - 1; i >= 0; i--) {
            if (i === dollars.length - 3) {
                amount.push(`.`);
                y = 1;
            }
            amount.push(`${dollars[i]}`);
            if (y % 3 === 0 && y !== 0 && i !== 0)
                amount.push(`,`);
            y++;
        }
        return isNegative ? `-$${amount.reverse().join("")}` : `$${amount.join("") === "0" ? "- -" : amount.reverse().join("")}`;
    } catch (e) {
        return "- -";
    }
}

export const claimEligibleDefaultToNo = (dataValue: boolean) => {
    let retValue = ''
    if (dataValue) {
        return `Yes`;
    } else {
        retValue = 'No'
    }
    return retValue;
}

export const convertAmount = (amount: number) => {
    if (amount === 0 || amount === null) {
        return `$ 0.00`;
    }
    return `$ ${[amount.toString().slice(0, amount.toString().length - 2), '.', amount.toString().slice(amount.toString().length - 2)].join('')}`;
}

export const downloadClicked = (data: any[], csvExport: any) => {
    const rows: any[] = Object.values(data).map(d => {
        let eventDate: string = moment.tz(d.eventDate, "America/New_York").format('MM/DD/YYYY') + " " + moment.tz(d.eventDate, "America/New_York").format('h:mm:ss a');
        let eventType: string = d.eventType;
        let tempAmount: string = normalizeAmount(d.prizeAmountInCents);
        let rejectReason: string = d.rejectReason;
        let claimIp: string = d.clientIpAddress;
        let playerId: string = d.playerId;
        d = {
            date: eventDate,
            eventType: eventType,
            amount: tempAmount,
            rejectReason: rejectReason,
            claimIpAddress: claimIp,
            playerId: playerId
        }
        return d;
    });
    csvExport.generateCsv(rows);
    return true;
}

export const normalizeDate = (date: string) => {
    let dateArray = date.split('Z');
    dateArray = dateArray[0].split('T');
    date = dateArray[0].split("-").join('/');
    let time = dateArray[dateArray.length - 1].split('.');
    return `${date} ${time[0]}`;
}