export const PaymentStatusOptions = {
  Pending: "Pending",
  Canceled: "Canceled",
  Paid: "Paid",
  OnHold: "On Hold",
  Upcoming: "Upcoming",
} as const;

export type PaymentStatus =
  (typeof PaymentStatusOptions)[keyof typeof PaymentStatusOptions];
