import * as React from 'react'
import { Switch } from 'react-router-dom'
import { AllPromotionalMessagesPermissions } from '../../api/playslips-fetcher/permissions'
import {
  AllCouponCampaignPermissions,
  AllPromotionCampaignPermissions,
  AllWinnersManagementPermissions,
  PromotionTabPermissions,
} from '../../api/promotions-fetcher/permissions'
import {
  campaignsRoutePath,
  promotionCampaignBuilderRoutePath,
  promotionMessagingRoutePath,
  promotionsBasePath,
  winnersManagementRoutePath,
} from '../../config.js'
import { PrivateRoute } from '../../routes.js'
import { userHasAnyOfPermissions } from '../../util/access-helpers'
import { CampaignsPageContainer } from '../CampaignsPages/campaigns-page-container'
import { Forbidden409Page } from '../CommonsPage/Forbidden409Page'
import { PromotionCampaignBuilderPageContainer } from './promotion-campaign-builder/promotion-campaign-builder-page-container'
import { PromotionalMessagesPageContainer } from './promotional-messages-page/promotional-messages-page-container'
import { PromotionsLandingPageContainer } from './promotions-landing-page/promotions-landing-page-container'
import { WinnersManagementLandingPageContainer } from './winners-management-landing-page/winners-management-landing-page-container'
import { WinnersManagementPage } from './winners-management/winners-management-page'

export const PromotionsRouter = () => {
  return (
    <Switch>
      <PrivateRoute
        path={`${winnersManagementRoutePath}/:promotionId`}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllWinnersManagementPermissions) ? (
            <WinnersManagementPage />
          ) : (
            <Forbidden409Page />
          )
        }
      />
      <PrivateRoute
        path={campaignsRoutePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllCouponCampaignPermissions) ? (
            <CampaignsPageContainer />
          ) : (
            <Forbidden409Page />
          )
        }
      />
      <PrivateRoute
        path={winnersManagementRoutePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllWinnersManagementPermissions) ? (
            <WinnersManagementLandingPageContainer />
          ) : (
            <Forbidden409Page />
          )
        }
      />
      <PrivateRoute
        path={promotionMessagingRoutePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllPromotionalMessagesPermissions) ? (
            <PromotionalMessagesPageContainer />
          ) : (
            <Forbidden409Page />
          )
        }
      />
      <PrivateRoute
        path={promotionCampaignBuilderRoutePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllPromotionCampaignPermissions) ? (
            <PromotionCampaignBuilderPageContainer />
          ) : (
            <Forbidden409Page />
          )
        }
      />
      <PrivateRoute
        path={promotionsBasePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(PromotionTabPermissions) ? (
            <PromotionsLandingPageContainer />
          ) : (
            <Forbidden409Page />
          )
        }
      />
    </Switch>
  )
}
