import React, {useState} from "react";
import styles from "./css/styles.module.css";
import {
    UpdateEFTOrganizationBankInfoComponent
} from "../../../../components/CharitableGamingComponents/License/Update/UpdateEFTOrganizationBankInfoComponent";
import {
    UpdateEFTOrganizationInfoComponent
} from "../../../../components/CharitableGamingComponents/License/Update/UpdateEFTOrganizationInfoComponent";
import {useHistory} from "react-router-dom";
import {useFormik} from "formik";
import {onSubmit, setFieldValue, setVariables, validate, YesCancel, YesSave} from "./functions"
import {keyPressedType} from "../../../../services/Commons";
import {
    CancelConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/CancelConfirmationComponent";
import {
    SaveConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/SaveConfirmationComponent";
import {
    PageMessageCommonComponent,
    pageMessageDefault
} from "../../../../components/CommonComponents/PageMessageCommonComponent";
import {inject, observer} from "mobx-react";

export const UpdateEFTDetailsPage = inject("charitableGamingStore")(observer(({licenseNumber, charitableGamingStore}: any) => {

        const history: any = useHistory();

        let {selectedLicenseEFTInfo, errorNotificationBanner} = charitableGamingStore;

        const formik = useFormik({
            initialValues: selectedLicenseEFTInfo,
            validate,
            onSubmit
        });

        const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
        const [saveToggle, setSaveToggle] = useState<boolean>(false);
        const [cancelToggle, setCancelToggle] = useState<boolean>(false);

        const ActivateSavebutton = (formik.isValid && formik.dirty);

        setVariables(formik, history, setPageMessage, licenseNumber, charitableGamingStore);


        const hideErrorNotificationBanner = () => {
            charitableGamingStore.showErrorNotificationBanner('');
        }

        return (
            <>
                <div className={`${styles['EFT-form-page-container']}`}
                     onClick={(e: any) => {
                         if (errorNotificationBanner.message !== "") {
                             hideErrorNotificationBanner();
                         }
                     }}
                >
                    <div className={`${styles["EFT-form-ribbon-container"]}`}>
                        <div className={styles["EFT-form-ribbon-title"]}>Update EFT Information</div>
                        <div className={`${styles['EFT-form-ribbon-button-container']}`}>
                            <div tabIndex={0} className={styles["EFT-form-ribbon-cancel-button"]}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         setCancelToggle(true);
                                     }
                                 }}
                                 onClick={() => {
                                     setCancelToggle(true);
                                 }}
                            >Cancel
                            </div>
                            <div tabIndex={0} className={styles["EFT-form-ribbon-save-button"]}
                                 role="button"
                                 style={{
                                     opacity: ActivateSavebutton ? "1" : "0.4",
                                     cursor: ActivateSavebutton ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (ActivateSavebutton) {
                                             setSaveToggle(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (ActivateSavebutton) {
                                         setSaveToggle(true);
                                     }
                                 }}>Save
                            </div>
                        </div>
                    </div>

                    <div className={`${styles["EFT-form-error-notification-container"]}`}>
                        {(errorNotificationBanner.message != "") ?
                            <PageMessageCommonComponent
                                message={errorNotificationBanner.message}
                                messageType={errorNotificationBanner.messageType}/>
                            : null
                        }
                    </div>

                    <div className={`${styles["EFT-form-main-content-container"]}`}>
                        <div className={`${styles["EFT-form-main-content-column-1"]}`}>
                            <UpdateEFTOrganizationBankInfoComponent formik={formik} setFieldValue={setFieldValue}/>
                        </div>
                        <div className={`${styles["EFT-form-main-content-column-2"]}`}>
                            <UpdateEFTOrganizationInfoComponent formik={formik} setFieldValue={setFieldValue}/>
                        </div>
                    </div>

                </div>
                {saveToggle ?
                    <SaveConfirmationComponent
                        YesSave={YesSave}
                        saveToggle={saveToggle}
                        setSaveToggle={setSaveToggle}>
                    </SaveConfirmationComponent> : null}
                {cancelToggle ?
                    <CancelConfirmationComponent
                        YesCancel={YesCancel}
                        cancelToggle={cancelToggle}
                        setCancelToggle={setCancelToggle}>
                    </CancelConfirmationComponent> : null}
            </>
        );
    })
);

