import React, { SetStateAction, useState } from "react";
import styles from "./css/styles.module.css";
import { keyPressedType } from "../../../../../services/Commons";
import { KEY_CODES } from "../../../../../constants.js";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import {FormikValues, useFormik} from "formik";

export const UpdateSupportingInformationComponent: React.FunctionComponent<{
  formik: FormikValues;
  setShowSupportingInfoPopupModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
    formik,
    setShowSupportingInfoPopupModal,
}) => {

  // Required For Modal focus
  const FocusTrap = require("focus-trap-react");

  return (
    <FocusTrap focusTrapOptions={{ initialFocus: false }}>
      <div className={styles["overlay-container"]}>
        <div className={styles["main-modal"]}>
          <div>
            <div className={styles["modal-title"]}>Supporting Address Information</div>
            <div className={styles["modal-desc"]}>
              This is a continuation of the payee address, please add any
              relevant address line information. i.e, 'Re: FBO Johnson', or
              'C/O Bank of New York Mellon'.
            </div>
            <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-start my-2">
                <label htmlFor="line1">Line 1</label>
                    <input
                        type="text"
                        className={`${styles.custom_input}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="supportingInformation1"
                        id="supportingInformation1"
                        value={formik.values.supportingInformation1}
                    />
                    {formik.errors.supportingInformation1 && formik.touched.supportingInformation1 &&
                        <ErrorMessageFieldCommonComponent errorMessage={formik.errors.supportingInformation1} />}
                </div>
                <div className="d-flex flex-column align-items-start my-2">
                <label htmlFor="line2">Line 2</label>
                    <input
                        type="text"
                        className={`${styles.custom_input}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="supportingInformation2"
                        id="supportingInformation2"
                        value={formik.values.supportingInformation2}
                    />
                    {formik.errors.supportingInformation2 && formik.touched.supportingInformation2 &&
                        <ErrorMessageFieldCommonComponent errorMessage={formik.errors.supportingInformation2} />}
                </div>
            </div>
            <div>
              <div>
                <button
                    type="button"
                    className={styles["modal-confirm-btn"]}
                    onKeyPress={event => {
                      if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        setShowSupportingInfoPopupModal(false);
                      }
                    }}
                    onClick={() => {
                        setShowSupportingInfoPopupModal(false);
                    }}
                >
                  Save
                </button>
              </div>
              <div>
                <button
                    type="button"
                    className={styles["modal-cancel-btn"]}
                    onKeyPress={event => {
                      if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        setShowSupportingInfoPopupModal(false);
                      }
                    }}
                    onClick={() => {
                      setShowSupportingInfoPopupModal(false);
                    }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
