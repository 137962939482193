import {LicenseeInterface} from "./interfaces";
import FuzzySearch from "fuzzy-search";
import config from "../../../../config.js";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";
import axios from "axios";

let charitableGamingStore: any;
let unfilteredLicensees: any;
let setUnfilteredLicensees: any;
let filteredLicensees: any;
let setFilteredLicensees: any;
let setCurrentPage: any;
let setPageMessage: any;
let setShowErrorDialog: any;
let showNotificationBanner: any;

export const setVariables = (charitableGamingStoreObj: any, unfilteredLicenseesObj: any, setUnfilteredLicenseesObj: any,
                             filteredLicenseesObj: any, setFilteredLicenseesObj: any, setCurrentPageObj: any,
                             setPageMessageObj: any, setShowErrorDialogObj: any, showNotificationBannerObj: any) => {

    charitableGamingStore = charitableGamingStoreObj;
    unfilteredLicensees = unfilteredLicenseesObj;
    setUnfilteredLicensees = setUnfilteredLicenseesObj;
    filteredLicensees = filteredLicenseesObj;
    setFilteredLicensees = setFilteredLicenseesObj;
    setCurrentPage = setCurrentPageObj;
    setPageMessage = setPageMessageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    showNotificationBanner = showNotificationBannerObj;
}

export const setFieldValue = (e: any, formik: any) => {

    if (e.target.name === "licenseNumber") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("orgName", "", true);
        formik.setFieldValue("city", "", true);
    } else if (e.target.name === "orgName") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("licenseNumber", "", true);
        formik.setFieldValue("city", "", true);
    } else if (e.target.name === "city") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("licenseNumber", "", true);
        formik.setFieldValue("orgName", "", true);
    }
}

export const handleCheckboxChange = (checkboxname: string, formik: any) => {

    setCurrentPage(0);

    if (checkboxname === 'includeActiveLicensees') {
        formik.setFieldValue(checkboxname, !formik.values.includeActiveLicensees, true);
    } else if (checkboxname === 'includeInactiveLicensees') {
        formik.setFieldValue(checkboxname, !formik.values.includeInactiveLicensees, true);
    } else if (checkboxname === 'renewing') {
        formik.setFieldValue(checkboxname, !formik.values.renewing, true);
    } else if (checkboxname === 'notRenewing') {
        formik.setFieldValue(checkboxname, !formik.values.notRenewing, true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    // alert(JSON.stringify(values));
    searchLicensees(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            licenseNumber: '',
            orgName: '',
            city: '',
            includeActiveLicensees: false,
            includeInactiveLicensees: false,
            renewing: false,
            notRenewing: false
        }
    });

    resetFilteredLicensees();

}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

export const getLicensees = async (setRequestCompleted: any) => {

    try {

        const body = {
            "filterActiveLicensees": false,
            "filterNonRenewing": false
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/licensee/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        // Deep copy
        setUnfilteredLicensees(JSON.parse(JSON.stringify(response.licensees)));

        // Filter response.licensees for Active licensees amd then assign to this.filteredLicensees
        filteredLicensees = response.licensees.filter((licensee: LicenseeInterface) => licensee.licenseDetails.active === true);
        setFilteredLicensees(filteredLicensees);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

const searchLicensees = (values: any) => {

    setCurrentPage(0);

    let searcher;
    let key;
    let licenses;

    // Filter
    if (values.includeActiveLicensees && values.includeInactiveLicensees) { // no filtering needed
        licenses = JSON.parse(JSON.stringify(unfilteredLicensees));
    } else if (!values.includeActiveLicensees && !values.includeInactiveLicensees) {
        filteredLicensees = [];
        setFilteredLicensees(filteredLicensees);
        return;
    } else if (values.includeActiveLicensees) {
        licenses = unfilteredLicensees.filter((licensee: LicenseeInterface) => licensee.licenseDetails.active === true);
    } else if (values.includeInactiveLicensees) {
        licenses = unfilteredLicensees.filter((licensee: LicenseeInterface) => licensee.licenseDetails.active === false);
    }

    if (values.renewing && values.notRenewing) { // no filtering needed

    } else if (!values.renewing && !values.notRenewing) {
        filteredLicensees = [];
        setFilteredLicensees(filteredLicensees);
        return;
    } else if (values.renewing) {
        licenses = licenses.filter((licensee: LicenseeInterface) => licensee.licenseDetails.markedForRenewal === true);
    } else if (values.notRenewing) {
        licenses = licenses.filter((licensee: LicenseeInterface) => licensee.licenseDetails.markedForRenewal === false);
    }

    if (!values.licenseNumber && !values.orgName && !values.city) {
        filteredLicensees = JSON.parse(JSON.stringify(licenses))
        setFilteredLicensees(filteredLicensees);
    } else {

        // Search
        if (values.licenseNumber) {
            searcher = new FuzzySearch(licenses, ['licenseDetails.licenseNumber'], {
                caseSensitive: false, sort: true
            });
            key = values.licenseNumber;
        } else if (values.orgName) {
            searcher = new FuzzySearch(licenses, ['orgDetails.orgName'], {
                caseSensitive: false, sort: true
            });
            key = values.orgName;
        } else if (values.city) {
            searcher = new FuzzySearch(licenses, ['licenseDetails.occasionAddress.city'], {
                caseSensitive: false, sort: true
            });
            key = values.city;
        }

        filteredLicensees = searcher.search(key);
        setFilteredLicensees(filteredLicensees);
    }

}

const resetFilteredLicensees = () => {
    filteredLicensees = [];
    setFilteredLicensees(filteredLicensees);
}

export const RenewLicenses = async function () {

    try {

        const options: any = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/licensee/renewal`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json text/csv',
            }
        }

        const response = await axios(options);

        let file =  new Blob([response.data], {type: 'text/csv'});
        let fileURL = URL.createObjectURL(file);

        // Create <a> tag dynamically
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;

        // Forces the name of the downloaded file
        fileLink.download = response.headers['download-file-name'] ? response.headers['download-file-name'] : `License_Renewal_${new Date().getFullYear()}.csv`;

        // Trigger the click event
        fileLink.click();

        showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The license profiles were successfully renewed.'
        });

    } catch (e) {
        setShowErrorDialog(true);
    }

}