import {TicketSearchCriteria} from './interface';
import {makeHttpCall} from '../../../services/MakeHttpCall';
import config from './../../../config.js';

let formik: any;
let playerStore: any;
let history: any;
let pageMessageDefault: any;
let setPageMessageType: any;
let setButtonClicked: any;

export const setVariables = (formikObj: any, playerStoreObject: any, historyObject: any, pageMessageDefaultObj: any,
                             setPageMessageObj: any, setButtonClickedObj: any) => {
    formik = formikObj;
    playerStore = playerStoreObject;
    history = historyObject;
    pageMessageDefault = pageMessageDefaultObj;
    setPageMessageType = setPageMessageObj;
    setButtonClicked = setButtonClickedObj;
}

export const refreshComponent = (response: any) => {
    if (!(response.error === undefined)) {
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}

export const onSubmit = async (values: TicketSearchCriteria) => {

}

export const doTicketSearchWithBarCode = async (barCode: string, setTicketSearchAPIResponse: any, setCurrentPage: any) => {

    setCurrentPage(0);

    if (Object.keys(formik.errors).length <= 0) {
        try {
            const options =
                {
                    method: 'post',
                    url: `${config.SERVER_BASE_URL}/v1/player/ticket-barcode-search/${barCode}`
                }
            const response = await makeHttpCall(options);
            // console.log("got response :" + response);
            setTicketSearchAPIResponse(response);
            setButtonClicked(false);
        } catch (e) {
            const er: any = e;
            if (er.toString().split(" ").find((value: any, i: any) => {
                console.log("value", value);
                if (value === "Network")
                    return value
            }) !== undefined) {
                setPageMessageType({
                    message: "Something went wrong. Network Error. Please try again.",
                    messageType: "ERROR"
                });
            } else {
                setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
                setButtonClicked(false);
            }

        }
    } else {
        setButtonClicked(false);
    }
}

export const doTicketSearchWithSerialNumber = async (serialNumber: string, setTicketSearchAPIResponse: any, setCurrentPage: any) => {

    setCurrentPage(0);

    if (Object.keys(formik.errors).length <= 0) {
        try {
            const options =
                {
                    method: 'POST',
                    url: `${config.SERVER_BASE_URL}/v1/player/ticket-serial-search/${serialNumber}`,
                }
            const response = await makeHttpCall(options);
            setTicketSearchAPIResponse(response);
            if (response.ticket.eventHistory.length === 0) {
                setPageMessageType({
                    message: "No results have been found matching the search criteria.",
                    messageType: "SUCCESS"
                });
            }
            setButtonClicked(false);
        } catch (e) {
            const er: any = e;
            if (er.toString().split(" ").find((value: any, i: any) => {
                if (value === "Network")
                    return value
            }) !== undefined) {
                setPageMessageType({
                    message: "Something went wrong.Network Error. Please try again.",
                    messageType: "ERROR"
                });
            } else
                setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
            setButtonClicked(false);
        }
    } else {
        setButtonClicked(false);
    }
}

export const setFiledValue = (e: any) => {
    if (e.target.name === 'barCode') {
        formik.setFieldValue("serialNumber", "", false);
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    } else if (e.target.name === 'serialNumber') {
        // validation is handled in - checkValueIsNumber
        formik.setFieldValue("barCode", "", false);
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }
}

export const onClearSearch = (setTicketSearchAPIResponse: any) => {
    setTicketSearchAPIResponse(null);
}
