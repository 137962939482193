import * as React from 'react'
import { Switch } from 'react-router-dom'
import { AllPlayslipsStepDownPermissions } from '../../api/playslips-fetcher/permissions'
import { operationsBasePath, playslipsStepDownRoutePath } from '../../config.js'
import { PrivateRoute } from '../../routes.js'
import { userHasAnyOfPermissions } from '../../util/access-helpers'
import { Forbidden409Page } from '../CommonsPage/Forbidden409Page'
import { OperationsLandingPageUI } from './operations-landing-page/operations-landing-page-ui'
import { PlayslipsStepDownPageContainer } from './playslips-step-down-page/playslips-step-down-page-container'

export const OperationsRouter = () => {
  return (
    <Switch>
      <PrivateRoute
        path={playslipsStepDownRoutePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllPlayslipsStepDownPermissions) ? (
            <PlayslipsStepDownPageContainer />
          ) : (
            <Forbidden409Page />
          )
        }
      />
      <PrivateRoute
        path={operationsBasePath}
        component={(_props: any) =>
          userHasAnyOfPermissions(AllPlayslipsStepDownPermissions) ? (
            <OperationsLandingPageUI />
          ) : (
            <Forbidden409Page />
          )
        }
      />
    </Switch>
  )
}
