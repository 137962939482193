import React from "react"
import { TaxAdjustmentStatus } from "../../../../api/check-run-payments-fetcher/api-types"
import { BsPencilSquare } from "react-icons/bs"
import { checkUserRenderPermissions } from '../../../../helpers'
import { permissions } from '../../../../constants'
import '../styles.scss'

export type FutureTaxAdjustmentStatusCtaUIProps = {
  taxAdjustmentId: string
  currentStatus: TaxAdjustmentStatus
  showUpdateDialog: () => void
}

function formatTaxAdjustmentStatus(status: TaxAdjustmentStatus): string {
  switch(status) {
    case TaxAdjustmentStatus.DONE:
      return 'Done'
    case TaxAdjustmentStatus.ON_HOLD:
      return 'On Hold'
    case TaxAdjustmentStatus.PENDING:
      return 'Pending'
    default:
      return 'Unknown'
  }
}

const ROOT_CLASS = 'future-tax-adjustment-status'

export const FutureTaxAdjustmentStatusCtaUI = (props : FutureTaxAdjustmentStatusCtaUIProps) => {
  return <div className={ROOT_CLASS}>
    {formatTaxAdjustmentStatus(props.currentStatus)}
    {
      checkUserRenderPermissions(permissions.CAN_UPDATE_TAX_ADJUSTMENT_STATUS) &&
      <BsPencilSquare
        className="edit-icon"
        onClick={props.showUpdateDialog} />
    }
    </div>
  
}