import * as React from 'react'
import { getBlockClass } from '../../../utilities/helpers'
import { GenericPopupModal } from '../../GenericPopupModal/generic-popup-modal-ui'
import { LoadingDots } from '../loading-dots/loading-dots-ui'
import './styles.scss'

const ROOT_CLASS = 'confirm-modal'
const BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'button')

export type ConfirmPopupModalProps = {
  headerText: string
  subheaderText?: string
  buttonText: string
  isOpen: boolean
  closeModal: () => void
  loading: boolean
  error?: string
  actionSuccessful: boolean
  onSuccess?: () => void
  submitAction: () => Promise<void> | void
}

export const ConfirmPopupModal = (props: ConfirmPopupModalProps) => {
  React.useEffect(() => {
    if (props.actionSuccessful) {
      props.onSuccess?.()
      props.closeModal()
    }
  }, [props.actionSuccessful])

  return (
    <GenericPopupModal
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      roundedEdges
      hideCloseButton
    >
      <div className={ROOT_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'text')}>
          <h1>{props.headerText}</h1>
          {props.subheaderText && <h2>{props.subheaderText}</h2>}
          {props.error && (
            <div className={getBlockClass(ROOT_CLASS, 'error-message')}>
              {props.error}
            </div>
          )}
        </div>

        <button
          type="submit"
          className={BUTTON_CLASS}
          onClick={async () => await props.submitAction()}
          aria-label="Submit"
          disabled={props.loading}
        >
          {props.loading ? (
            <div className={`${BUTTON_CLASS}__loading`}>
              <LoadingDots id="loading" color="white" />
            </div>
          ) : (
            props.buttonText
          )}
        </button>
        <button
          className={getBlockClass(ROOT_CLASS, 'back-button')}
          onClick={props.closeModal}
          aria-label="Go Back"
          disabled={props.loading}
        >
          Go Back
        </button>
      </div>
    </GenericPopupModal>
  )
}
