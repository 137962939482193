import * as React from 'react'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'

type AuthorizedContainerProps = {
  permissions: string[]
}

export const AuthorizedContainer = (
  props: React.PropsWithChildren<AuthorizedContainerProps>
) => {
  return userHasAnyOfPermissions(props.permissions) ? (
    <>{props.children}</>
  ) : null
}
