import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {downloadOffsetsPdfReport, getPageData, onSubmit, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import { checkRenderPermissions } from '../../../../helpers.js'
import {permissions} from '../../../../constants.js';
import {formatCentsToDollars} from "../../../../util";
import {batchInformation, batchInformationDefaultData} from "./interfaces";
import moment from "moment";
import {PaginationCommonComponent} from "../../../../components/CommonComponents/PaginationCommonComponent";
import { BannerContext } from "../../../../context/BannerContext";
import { OffsetsSystemBreakdownUI } from "../../../../components/OffsetsComponents/OffsetsSystemBreakdownComponent/offsets-system-breakdown-ui";

export const OffsetsByACHPage = ({batchNumber}: any) => {

    const bannerContext = useContext(BannerContext)
    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    let backButtonText = (location.state?.backButtonText === undefined) ? 'Back' : location.state.backButtonText;

    const formik = useFormik({
        initialValues: {
            batchNumber: batchNumber
        }, validate, onSubmit
    });

    const [batchFileDetails, setBatchFileDetails] = useState<any>();
    const [withholdings, setWithholdings] = useState<batchInformation>(batchInformationDefaultData);
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        if (requestCompleted === false) {
            formik.submitForm();
        }
    }, []);

    setVariables(formik, setCurrentPage, setShowErrorDialog, setRequestCompleted, setBatchFileDetails, setWithholdings);

    const triggerPdfExport = async () => {
        await downloadOffsetsPdfReport(batchNumber, bannerContext.setBannerInfo)
    }

    const formattedFileSubmissionDate = batchFileDetails ? moment(batchFileDetails.filesubmissiondatetime).format('MM/DD/YYYY') : ''

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['reporting-page-container']}`}
                 onClick={() => {

                 }}
            >
                <div tabIndex={0}
                     className={`${styles['reporting-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['reporting-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >{backButtonText}
                    </div>
                </div>
                <div className={`${styles['reporting-page-ribbon-container']}`}>
                    <div
                        className={`${styles['reporting-page-ribbon-title']} ${styles['']}`}>{`Offsets by ACH: Batch ${batchNumber}`}
                    </div>
                    {
                        checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies)) &&
                        <button className={`${styles['export-button']}`} onClick={triggerPdfExport}>Export</button>
                    }
                    
                </div>

                {batchFileDetails ? <div className={styles['table-1']}>

                    <div className={styles['table-1-data-body']}>

                        <div className={`${styles['table-1-data-grid']} ${styles['table-1-data-label']}`}>
                            <div>File Creation Date</div>
                            <div>Effective Transfer Date</div>
                            <div>Status</div>
                            <div>Amount</div>
                            <div>Total Debit #</div>
                            <div>Total Debit Amt</div>
                            <div>Total Credit #</div>
                            <div>Total Credit Amt</div>
                            <div>File Addenda Ct</div>
                            <div>File Hash Ct</div>
                        </div>

                        <div key={0} style={{backgroundColor: (0 % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-1-data-grid']} ${styles['table-1-data-data']}`}>
                            <div className={styles["table-1-data-data-text"]}>
                                {moment(batchFileDetails.filecreationdatetime).format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {moment(batchFileDetails.effectiveentrydate).utc().format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {batchFileDetails.status}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {formatCentsToDollars(batchFileDetails.amount)}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {batchFileDetails.totaldebits}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {formatCentsToDollars(batchFileDetails.totaldebitamount)}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {batchFileDetails.totalcredits}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {formatCentsToDollars(batchFileDetails.totalcreditamount)}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {batchFileDetails.addendacount}
                            </div>
                            <div className={styles["table-1-data-data-text"]}>
                                {batchFileDetails.entryhashcount}
                            </div>
                        </div>
                    </div>
                </div> : null}

                {batchFileDetails ? <div className={styles['table-2']}>

                    <div className={styles['result-totals-container']}>
                        <div className={styles['result-totals-container-title']}>Agency Breakdown</div>
                    </div>

                    <div className={styles['table-2-data-header']}>
                        {/*<div className={styles['table-2-data-header-text']} aria-live="assertive">
                            {`Displaying ${batchFileDetails.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(batchFileDetails.length, currentPage)}
                            of ${batchFileDetails.length} results`}
                        </div>*/}
                        <div className={styles['table-2-data-header-text']} aria-live="assertive">
                            {`Displaying 3-3 of 3 results`}
                        </div>
                    </div>

                    <div className={styles['table-2-data-body']}>

                        <div className={`${styles['table-2-data-grid']} ${styles['table-2-data-label']}`}>
                            <div>Agency ID</div>
                            <div>Date Submitted</div>
                            <div>Net Amount</div>
                        </div>

                        <div key={0} style={{backgroundColor: (0 % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-2-data-grid']} ${styles['table-2-data-data']}`}>
                            <div className={styles["table-2-data-data-text"]}>
                                {'001 - DOR Child Services'}
                            </div>
                            <div className={styles["table-2-data-data-text"]}>
                                {formattedFileSubmissionDate}
                            </div>
                            <div className={styles["table-2-data-data-text"]}>
                                {formatCentsToDollars(batchFileDetails.totaldorchildservicesamount)}
                            </div>
                        </div>

                        <div key={1} style={{backgroundColor: (1 % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-2-data-grid']} ${styles['table-2-data-data']}`}>
                            <div className={styles["table-2-data-data-text"]}>
                                {'002 - DOR Tax'}
                            </div>
                            <div className={styles["table-2-data-data-text"]}>
                                {formattedFileSubmissionDate}
                            </div>
                            <div className={styles["table-2-data-data-text"]}>
                                {formatCentsToDollars(batchFileDetails.totaldortaxamount)}
                            </div>
                        </div>

                        <div key={2} style={{backgroundColor: (2 % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-2-data-grid']} ${styles['table-2-data-data']}`}>
                            <div className={styles["table-2-data-data-text"]}>
                                {'004 - Comptroller'}
                            </div>
                            <div className={styles["table-2-data-data-text"]}>
                                {formattedFileSubmissionDate}
                            </div>
                            <div className={styles["table-2-data-data-text"]}>
                                {formatCentsToDollars(batchFileDetails.totalcomptrolleramount)}
                            </div>
                        </div>

                    </div>
                </div> : null}

                {withholdings.withholdingIds.length ? <div className={styles['table']}>

                    <div className={styles['result-totals-container']}>
                        <div className={styles['result-totals-container-title']}>Player & Agency Breakdown</div>
                    </div>

                    <div className={styles['table-data-header']}>
                        <div className={styles['table-data-header-text']} aria-live="assertive">
                            {`Displaying ${withholdings.withholdingIds.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(withholdings.withholdingIds.length, currentPage)}
                            of ${withholdings.withholdingIds.length} results`}
                        </div>
                    </div>

                    <div className={styles['table-data-body']}>

                        <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                            <div>Name</div>
                            <div>TIN</div>
                            <div>Intercepts</div>
                            <div>Offset</div>
                            <div>Agency ID</div>
                            <div>System</div>
                            <div>Doc No.</div>
                            <div>Ext. Reference Number</div>
                            <div>Debt Received Date</div>
                            <div>Date of Withholding</div>
                        </div>

                        {withholdings.withholdingIds.length > 0 && getPageData(withholdings.withholdingIds, currentPage).map((e: any, i: number) =>
                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div className={styles["table-data-data-text"]}>
                                    {e.name}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.ssn}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.intercepts}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.offsetAmountInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.agencyId}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.clientName}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.docNumber}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.externalReferenceNumber}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {moment(e.debtReceivedDate).format('MM/DD/YYYY')}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {moment(e.interceptedDate).format('MM/DD/YYYY')}
                                </div>
                            </div>)}
                    </div>
                    <div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((withholdings.withholdingIds.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={withholdings.withholdingIds}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>
                </div> : <div className={`${styles['reporting-page-search-no-results-container']}`}>
                    <div className={`${styles['reporting-page-search-no-results-container-text']}`}>
                        No Results. Please try a different search.
                    </div>
                </div>}

                <OffsetsSystemBreakdownUI 
                    aggregates={withholdings.aggregates}
                    dateSubmitted={formattedFileSubmissionDate}
                />
            </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
        </>);
};