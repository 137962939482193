import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { getModifierClass, joinClasses } from '../../../utilities/helpers'
import './styles.scss'

const ROOT_CLASS = 'async-container'
const ERROR_CLASS = `${ROOT_CLASS}__error-container`
const PROGRESS_CLASS = `${ROOT_CLASS}__progress-container`
const DEFAULT_BLUE = '#1e4b75'
const DEFAULT_SIZE_EM = 14
const DEFAULT_FONT_SIZE_PX = 14

export type AsyncContainerUIProps = {
  isLoading: boolean
  circularProgressSizeEm?: number
  errorLabel?: string
  error?: string
  errorTextSize?: number
  errorTextColor?: 'white' | 'black'
  color: 'white' | 'blue'
  onBack?: () => void
  fitted?: boolean
}

const getColor = (color: AsyncContainerUIProps['color']) =>
  color === 'white' ? color : DEFAULT_BLUE

export const AsyncContainerUI = (
  props: React.PropsWithChildren<AsyncContainerUIProps>
) => {
  return props.isLoading ? (
    <div
      className={joinClasses([
        PROGRESS_CLASS,
        getModifierClass(ROOT_CLASS, 'fitted', Boolean(props.fitted)),
      ])}
      aria-busy={props.isLoading}
      aria-describedby="progress"
    >
      <CircularProgress
        id="progress"
        style={{ color: getColor(props.color) }}
        size={`${props.circularProgressSizeEm ?? DEFAULT_SIZE_EM}em`}
        thickness={2}
      />
    </div>
  ) : props.error ? (
    <div
      className={joinClasses([
        ERROR_CLASS,
        getModifierClass(ROOT_CLASS, 'fitted', Boolean(props.fitted)),
      ])}
    >
      {props.errorLabel && (
        <div className={`${ERROR_CLASS}__label`}>{props.errorLabel}</div>
      )}
      <p
        style={{
          fontSize: `${props.errorTextSize ?? DEFAULT_FONT_SIZE_PX}px`,
          color: `${props.errorTextColor ?? 'black'}`,
        }}
        className={`${ERROR_CLASS}__text`}
      >
        Something Went Wrong: "{props.error}"
      </p>
      {props.onBack && (
        <button
          className={`${ERROR_CLASS}__back-button`}
          aria-label="Back"
          type="button"
          onClick={props.onBack}
        >
          Back
        </button>
      )}
    </div>
  ) : (
    <>{props.children}</>
  )
}
