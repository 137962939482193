import moment from 'moment'
import React from 'react'
import { HistoricalCheckRun } from '../../../api/check-run-history-fetcher/api-types'
import {
  CheckRunPayment,
  CheckRunPaymentsResponse,
  TaxAdjustmentStatus,
  TaxAdjustmentSummary,
} from '../../../api/check-run-payments-fetcher/api-types'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { MaskedSsnUI } from '../../../components/CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import {
  SearchResultsTableColumnMap,
  SearchResultsTableUI,
} from '../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { formatCentsToDollars } from '../../../util'
import { toEasternTime } from '../../../utilities/date-helpers'
import './styles.scss'
import { isNull } from 'lodash'
import { useHistory } from 'react-router-dom'
import { paymentsRoutePath } from '../../../config'
import { HistoricalReissueRun } from '../../../api/reissue-run-history-fetcher/api-types'

type CheckRunPaymentListPageUIProps = {
  paymentList: CheckRunPaymentsResponse
  checkRun?: HistoricalCheckRun | HistoricalReissueRun
}

type CheckRunPaymentDisplayRow = {
  name: string
  paymentId: string
  annuityId: string
  tin: React.JSX.Element
  paymentDate: string
  grossAmount: string
  irsWithholding: string
  dorWithholding: string
  offsetsWithholding: string
  netAmount: string
  checkNumber: string
}

const ROOT_CLASS = 'check-run-payment-list'
export const CheckRunPaymentListPageUI = (
  props: CheckRunPaymentListPageUIProps
) => {
  const {
    totalGrossPaymentInCents,
    totalIrsWithholdingInCents,
    totalDorWithholdingInCents,
    totalNetPaymentInCents,
    totalOffsetsWithholdingInCents,
  } = props.paymentList.checkRun

  const history = useHistory()

  const checkRunDate = props.checkRun
    ? toEasternTime(props.checkRun?.initiatedDate).format('MM/DD/YYYY')
    : 'N/A'

  const CHECK_RUN_PAYMENTS_COLUMN_MAP: SearchResultsTableColumnMap<CheckRunPaymentDisplayRow> =
    [
      { dataKey: 'annuityId', label: 'Annuity ID', width: 8 },
      { dataKey: 'name', label: 'Payee Name', width: 10 },
      { dataKey: 'tin', label: 'TIN', width: 8 },
      { dataKey: 'paymentDate', label: 'Payment Date', width: 7 },
      { dataKey: 'grossAmount', label: 'Gross Payment', width: 10 },
      { dataKey: 'irsWithholding', label: 'IRS Withholding', width: 10 },
      { dataKey: 'dorWithholding', label: 'DOR Withholding', width: 10 },
      {
        dataKey: 'offsetsWithholding',
        label: 'Offsets Withholding',
        width: 10,
      },
      { dataKey: 'netAmount', label: 'Net Payment', width: 10 },
      { dataKey: 'checkNumber', label: 'Check #', width: 10 },
    ]

  type TaxAdjustmentDisplayRow = {
    name: string 
    grossAmount: string 
    irsAdjustments: string
    dorAdjustments: string
    netAmount: string
    checkNumber: string
    blank: string // NOTE: this field is a hack-y way of aligning the netAmount and checkNumber columns in the 2 tables
    status: TaxAdjustmentStatus
    paymentId: string
  }

  const TAX_ADJUSTMENT_COLUMN_MAP: SearchResultsTableColumnMap<TaxAdjustmentDisplayRow> =
    [
      { dataKey: 'name', label: 'Payee Name', width: 33 },
      { dataKey: 'grossAmount', label: 'Gross Adjustment', width: 10 },
      { dataKey: 'irsAdjustments', label: 'IRS Adjustment', width: 10 },
      { dataKey: 'dorAdjustments', label: 'DOR Adjustment', width: 10 },
      { dataKey: 'blank', label: '', width: 10 },
      { dataKey: 'netAmount', label: 'Net Adjustment', width: 10 },
      { dataKey: 'checkNumber', label: 'Check #', width: 10 }
    ]

  const mapTaxAdjustmentToRow = (
    taxAdjustment: TaxAdjustmentSummary
  ): TaxAdjustmentDisplayRow => {
    return {
      name: taxAdjustment.payeeName,
      grossAmount: formatCentsToDollars(taxAdjustment.grossAdjustmentAmountInCents),
      irsAdjustments: formatCentsToDollars(taxAdjustment.irsAdjustmentAmountInCents),
      dorAdjustments: formatCentsToDollars(taxAdjustment.dorAdjustmentAmountInCents),
      netAmount: formatCentsToDollars(taxAdjustment.netAdjustmentAmountInCents),
      checkNumber: taxAdjustment.documentNumber,
      status: taxAdjustment.status,
      paymentId: taxAdjustment.originalPaymentId,
      blank: ''
    }
  }

  const aggregateTotalValues = [
    {
      label: '# of Checks',
      value: props.paymentList.checkRun.numChecks,
    },
    {
      label: 'Gross Payment',
      value: formatCentsToDollars(totalGrossPaymentInCents),
    },
    {
      label: 'IRS Withheld',
      value: formatCentsToDollars(totalIrsWithholdingInCents),
    },
    {
      label: 'DOR Withheld',
      value: formatCentsToDollars(totalDorWithholdingInCents),
    },
    {
      label: 'Offsets Withheld',
      value: formatCentsToDollars(totalOffsetsWithholdingInCents),
    },
  ]

  const mapPaymentToRow = (
    payment: CheckRunPayment
  ): CheckRunPaymentDisplayRow => {
    return {
      name: payment.name,
      paymentId: payment.paymentId,
      annuityId: payment.annuityAccountId ?? '',
      tin: <MaskedSsnUI ssn={payment.tin} />,
      paymentDate: moment(payment.paymentDate).format('MM/DD/YYYY'),
      grossAmount: formatCentsToDollars(payment.grossPaymentInCents),
      irsWithholding: formatCentsToDollars(payment.irsWithholdingInCents),
      dorWithholding: formatCentsToDollars(payment.dorWithholdingInCents),
      offsetsWithholding: formatCentsToDollars(
        payment.offsetsWithholdingInCents
      ),
      netAmount: formatCentsToDollars(payment.netPaymentInCents),
      checkNumber: payment.checkNumber,
    }
  }

  const paymentCTA = (payment: CheckRunPaymentDisplayRow) => {
    return (
      <button
        className="dark-btn"
        onClick={() =>
          history.push(`${paymentsRoutePath}/details/${payment.paymentId}`)
        }
      >
        Details
      </button>
    )
  }

  const taxAdjustmentCTA = (taxAdjustment: TaxAdjustmentDisplayRow) => {
    const history = useHistory()

    return (
      <button
        className="dark-btn"
        onClick={() => {
          history.push(`${paymentsRoutePath}/details/${taxAdjustment.paymentId}`)
        }}
      >
        Original
      </button>
    )
  }

  const conditionallyRenderTaxAdjustments = (taxAdjustments: TaxAdjustmentSummary[] | null) => {
    if (!isNull(taxAdjustments)) {
      return (
        <div className={ ROOT_CLASS }>
          <section className={`${ROOT_CLASS}_aggregate-totals-container`}>
            <header>Payment & Tax Adjustments</header>
          </section>
          <SearchResultsTableUI
            rowData={taxAdjustments.map(mapTaxAdjustmentToRow)} 
            columnMap={TAX_ADJUSTMENT_COLUMN_MAP} 
            additionalResults={false}    
            generateRowCTA={taxAdjustmentCTA}  
            noDataMessage={`No tax adjustments were applied to this check run`}  
          />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <div className={ROOT_CLASS}>
        <BackButtonUI tabIndex={0} message="Back to Check Run History" />
        <header className={`${ROOT_CLASS}_header`}>
          <section>Payment List</section>
          <section className={`${ROOT_CLASS}_subheader`}>
            Check Run Date: {checkRunDate}
          </section>
        </header>
        <section className={`${ROOT_CLASS}_aggregate-totals-container`}>
          <header>Aggregate Totals</header>
          <section className={`${ROOT_CLASS}_aggregate-total-values-container`}>
            <section className={`${ROOT_CLASS}_left-aligned-values`}>
              {aggregateTotalValues.map((entry) => (
                <section className={`${ROOT_CLASS}_aggregate-total-value`}>
                  <span>{entry.label}</span>
                  <span>{entry.value}</span>
                </section>
              ))}
            </section>
            <section className={`${ROOT_CLASS}_aggregate-total-value`}>
              <span>Net Payment</span>
              <span>{formatCentsToDollars(totalNetPaymentInCents)}</span>
            </section>
          </section>
        </section>
        <hr />
        <SearchResultsTableUI
          rowData={props.paymentList.checkRun.payments.map(mapPaymentToRow)}
          columnMap={CHECK_RUN_PAYMENTS_COLUMN_MAP}
          additionalResults={false}
          generateRowCTA={paymentCTA}
        />
      </div>
      { conditionallyRenderTaxAdjustments(props.paymentList.checkRun.taxAdjustments) }
    </>
  )
}
