import React from 'react'
import { useFetchCheckRunHistory } from '../../../api/check-run-history-fetcher/use-fetch-check-run-history'
import { UseFetchCheckRunPayments } from '../../../api/check-run-payments-fetcher/use-fetch-check-run-payments'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { CheckRunPaymentListPageUI } from './check-run-payment-list-page-ui'
import { useFetchHistoricalReissueRuns } from '../../../api/reissue-run-history-fetcher/use-fetch-historical-reissue-runs'

type CheckRunPaymentListPageProps = {
  id: string
}
export const CheckRunPaymentListPageContainer = (
  props: CheckRunPaymentListPageProps
) => {
  const paymentsFetch = UseFetchCheckRunPayments(props.id)
  const checkRunHistoryFetch = useFetchCheckRunHistory()
  const reissueRunHistoryFetch = useFetchHistoricalReissueRuns()

  return (
    <AsyncContainerUI
      isLoading={paymentsFetch.isLoading || checkRunHistoryFetch.isLoading}
      error={paymentsFetch.error ?? checkRunHistoryFetch.error}
      color="white"
    >
      {paymentsFetch.data && checkRunHistoryFetch.data && reissueRunHistoryFetch.data ? (
        <CheckRunPaymentListPageUI
          paymentList={paymentsFetch.data}
          checkRun={
          checkRunHistoryFetch.data.historicalCheckRuns.find(
            (entry) => entry.id === props.id
          ) ?? 
          reissueRunHistoryFetch.data.historicalCheckRuns.find(
            (entry) => entry.id === props.id
          )
        }
        />
      ) : (
        <span>Something went wrong.</span>
      )}
    </AsyncContainerUI>
  )
}
