import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import {
  PromotionEntryDetailsResponse,
  ScanEventStatus,
} from '../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { getBlockClass, sum } from '../../../utilities/helpers'
import { GenericPopupModal } from '../../GenericPopupModal/generic-popup-modal-ui'
import {
  DeleteConfirmationModal,
  EntryDeletionConfirmationModalProps,
} from './delete-confirmation-modal-ui'
import { EntryDetailsTable } from './entry-details-table-ui'
import './styles.scss'

export type PromotionEntryDetailsModalProps = {
  barcode: string
  barcodeDetails?: PromotionEntryDetailsResponse
  handleCloseModal: () => void
  barcodeDetailsLoading: boolean
  barcodeDetailsError?: string
  allowDisable: boolean
  disableBarcodeLoading: boolean
  disableBarcodeError?: string
  playerId?: string
  showPlayerId?: boolean
} & Pick<EntryDeletionConfirmationModalProps, 'disableBarcode'>

const ROOT_CLASS = 'promotion-entry-details-modal'
const TITLE_ROW_CLASS = getBlockClass(ROOT_CLASS, 'title-row')
const TITLE_LABEL_CLASS = getBlockClass(ROOT_CLASS, 'title-label')
const TITLE_VALUE_CLASS = getBlockClass(ROOT_CLASS, 'title-value')

export const PromotionEntryDetailsModalUI = (
  props: PromotionEntryDetailsModalProps
) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState<boolean>(false)

  const quantities: number[] =
    props.barcodeDetails?.promotions.map((promotion) => promotion.quantity) ??
    []
  const numEntries = quantities.length > 0 ? quantities.reduce(sum) : 0
  const isPlural = numEntries !== 1
  const entriesAreDisabled =
    props.barcodeDetails?.promotions.some((entry) => !entry.active) ?? false

  const EntryDetailsContents = () => {
    if (props.barcodeDetailsError) {
      return (
        <div className={getBlockClass(ROOT_CLASS, 'error')}>
          {props.barcodeDetailsError}
        </div>
      )
    } else if (props.barcodeDetailsLoading || !props.barcodeDetails) {
      return (
        <div
          className={getBlockClass(ROOT_CLASS, 'progress-container')}
          aria-busy={props.barcodeDetailsLoading}
          aria-describedby="progress"
        >
          <CircularProgress
            id="progress"
            style={{ color: 'gray' }}
            size="5em"
            thickness={2}
          />
        </div>
      )
    } else if (
      props.barcodeDetails &&
      props.barcodeDetails.status === ScanEventStatus.noPromotion
    ) {
      return (
        <h3 className={getBlockClass(ROOT_CLASS, 'no-promotions')}>
          No promotions for this ticket.
        </h3>
      )
    }
    return (
      <div className={getBlockClass(ROOT_CLASS, 'details')}>
        <div className={getBlockClass(ROOT_CLASS, 'title')}>
          {entriesAreDisabled && (
            <div className={getBlockClass(ROOT_CLASS, 'disabled-notice')}>
              <span className={getBlockClass(ROOT_CLASS, 'exclamation')}>
                !
              </span>
              <b>Entries Disabled</b>
              <span
                className={getBlockClass(ROOT_CLASS, 'disabled-notice-copy')}
              >
                {isPlural
                  ? 'The entries related to this ticket scan have been disabled'
                  : 'The entry related to this ticket scan has been disabled'}
              </span>
            </div>
          )}
          <div className={TITLE_ROW_CLASS}>
            <h3 className={TITLE_LABEL_CLASS}>Barcode</h3>
            <p className={TITLE_VALUE_CLASS}>{props.barcode}</p>
          </div>
          {props.playerId && props.showPlayerId && (
            <div className={TITLE_ROW_CLASS}>
              <h3 className={TITLE_LABEL_CLASS}>Player ID</h3>
              <p className={TITLE_VALUE_CLASS}>{props.playerId}</p>
            </div>
          )}
        </div>
        <EntryDetailsTable
          entriesAreDisabled={entriesAreDisabled}
          promotions={props.barcodeDetails.promotions}
        />
      </div>
    )
  }

  const EntryDetails = () => (
    <>
      <EntryDetailsContents />
      {props.barcodeDetails?.status !== ScanEventStatus.noPromotion && (
        <button
          className={getBlockClass(ROOT_CLASS, 'delete-button')}
          onClick={() => setConfirmationModalOpen(true)}
          aria-label="Disable Entries"
          disabled={
            entriesAreDisabled ||
            props.barcodeDetailsLoading ||
            Boolean(props.barcodeDetailsError) ||
            !props.allowDisable
          }
        >
          {isPlural ? 'Disable Entries' : 'Disable Entry'}
        </button>
      )}
      <button
        className={getBlockClass(ROOT_CLASS, 'back-button')}
        onClick={props.handleCloseModal}
        aria-label="Go Back"
      >
        Go Back
      </button>
    </>
  )

  return (
    <GenericPopupModal
      isOpen={Boolean(props.barcodeDetails)}
      closeModal={props.handleCloseModal}
      roundedEdges
      hideCloseButton
    >
      <div className={ROOT_CLASS}>
        {isConfirmationModalOpen ? (
          <DeleteConfirmationModal
            isLoading={props.disableBarcodeLoading}
            error={props.disableBarcodeError}
            isPlural={isPlural}
            cancelDeletion={() => setConfirmationModalOpen(false)}
            disableBarcode={props.disableBarcode}
          />
        ) : (
          <EntryDetails />
        )}
      </div>
    </GenericPopupModal>
  )
}
