import { makeHttpCall } from "../../../services/MakeHttpCall"
import config from './../../../config.js';
import { testPlayerStoreObjectData } from './../../../services/StoresObject';
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";


export const manuallyVerifyEmail = async (playerId:string,setPlayerStore:any,setemailAndPhoneChangeToggle:any , setPageMessage:any, emailAndPhoneChangeToggle:any)=>{
    console.log(">>>>>>>>>>>>>>>>>>>>>>>> manuallyVerifyEmail");
    setemailAndPhoneChangeToggle({value:true});
    try {
        const options =
        {
            method: 'post',
            url: config.SERVER_BASE_URL + '/v1/player/' + playerId + '/verify-email',
        }
        
        const response:any = await makeHttpCall(options);

        const userpolicies: any = localStorage.getItem('userpolicies');
        const maskSsn = !checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies));
        let queryParam = `?maskSsn=${maskSsn}`;
        const options1 =
        {
            method: 'get',
            url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
        }
        
        const response1:any = await makeHttpCall(options1);  
        
        setemailAndPhoneChangeToggle({value:true});
        setPlayerStore(testPlayerStoreObjectData(response1));
       
      
    } catch (e) {
        setPageMessage({ message: "Something went wrong. Please try again." ,  messageType:"ERROR"  } );
        document.scrollingElement?.scrollTo(0,0);

    }
}
export const normilizeAmount = ( value:any) => {
    let isNegative:boolean = false;
    if( parseInt(value) < 0 )
    {
        isNegative = true;
        value = value * -1;
    }

    let dollars:string[] =  value.toString().split("");         
    
    let amount:string[] = [];
    let y:number = -1;    
    for( let i = dollars.length - 1;  i >= 0 ; i-- )
    {
            if( i === dollars.length - 3 )
            {
                amount.push(`.`);
                y = 1;
            } 

            amount.push(`${dollars[i]}`);  
            if( y %  3 === 0  &&  y !== 0 && i !== 0)     
                amount.push(`,`);
            y++;
                   
    }
    
    return isNegative ? `-$${amount.reverse().join("")}`: `$${amount.join("") === "0"? "0.00" : amount.reverse().join("")}`;

} 