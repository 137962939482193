import React, { useEffect, useState } from "react";
import { normilizeAmount } from "../WinningInformationComponent/functions";
import styles from './css/styles.module.css';


export const ClaimIDAndNetPaymentComponent = ({ netPayment = undefined, claimID = undefined }: any) => {

   
   
   
    return (
        <>
            <div className={styles['page-container']}>
                <div className={styles['claims-data-container']} >
                    <div className={styles['claims-data-id-container']}>
                        <div tabIndex={0} className={styles['claims-data-id-label']}>Claim ID</div>
                        <div tabIndex={0} className={styles['claims-data-name']}>{claimID}</div>
                    </div>
                    <div tabIndex={0} className={styles['claims-data-id-container-2']}>
                        <div className={styles['claims-data-id-label-net-payment']}>Net Payment</div>
                        <div className={styles['claims-data-id-label-net-payment-text']}>{`${normilizeAmount(netPayment)}`}</div>
                    </div>
                </div>
                
            </div>

        </>

    );
}