import * as React from 'react'
import { ConfirmPopupModal } from '../../../CommonComponents/ConfirmPopupModal/confirm-popup-modal'
import { useFetchDeletePlayslipsStepDown } from '../../../../api/playslips-fetcher/use-fetch-playslips'

export type PlayslipsStepDownDeleteStepDownContainerProps = {
  closeModal: () => void
  gameIdentifier: string
  fetchAllPlayslipsStepDowns: () => Promise<void>
}

export const PlayslipsStepDownDeleteStepDownContainer = (
  props: PlayslipsStepDownDeleteStepDownContainerProps
) => {
  const { isLoading, error, requestSuccessful, deletePlayslipsStepDown } =
    useFetchDeletePlayslipsStepDown(props.gameIdentifier)

  return (
    <ConfirmPopupModal
      isOpen
      headerText="Are you sure you want delete this step down?"
      buttonText="Confirm"
      closeModal={props.closeModal}
      error={error}
      loading={isLoading}
      actionSuccessful={requestSuccessful}
      onSuccess={props.fetchAllPlayslipsStepDowns}
      submitAction={deletePlayslipsStepDown}
    />
  )
}
