export const UserActionCategory = {
    PAGE_VIEW: 'Page View',
    TAB_VIEW: 'Tab View',
    API_CALL: 'API Call',
    BUTTON_CLICK: 'Button Click',
    FILTER_RESULTS: 'Filter Results'
}

export const UserAction = {
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    RESET_USER_PASSWORD: 'Reset User Password',
    ADD_USER: 'Add User',
    EDIT_USER: 'Edit User',
    UPDATE_USER: 'Update User',
    DEACTIVATE_USER: 'Deactivate User',
    DELETE_USER: 'Delete User',
    USER_AUDIT_HISTORY_PAGE: 'User Audit History Page',
    USER_ACTION_HISTORY_SEARCH: 'User Action History Search',
    EDIT_ROLE: 'Edit Role',
    UPDATE_ROLE: 'Update Role',
    DELETE_ROLE: 'Delete Role',
    ADD_ROLE: 'Add Role',
    SEARCH_PLAYER: 'Search Player',
    PLAYER_DETAILS: 'Player Details',
    UPDATE_PLAYER_PROFILE: 'Update Player Profile',
    RESET_PLAYER_PASSWORD: 'Reset Player Password',
    UNLOCK_PLAYER: 'Unlock Player',
    LOCK_PLAYER: 'Lock Player',
    VERIFY_PLAYER: 'Verify Player',
    LAUNCH_EXPERIAN: 'Launch Experian',
    TICKET_HISTORY: 'Ticket History',
    EVENT_HISTORY: 'Event History',
    SEARCH_TICKET_HISTORY: 'Search Ticket History',
    PLAYER_REPORTS_LOCKED_ACCOUNTS: 'Player Reports - Locked Accounts',
    PLAYER_REPORTS_IDOLOGY_FAILED_STATUS: 'Player Reports - Idology Failed Status',
    PLAYER_REPORTS_NO_IDOLOGY_STATUS: 'Player Reports - No Idology Status',
    CUSTOM_PLAYER_REPORT: 'Custom Player Report',
    DOWNLOAD_PLAYER_REPORT: 'Download Player Report',
    PRINT_PLAYER_REPORT: 'Print Player Report',
    SEARCH_CLAIM: 'Search Claim',
    READ_ONLY_CLAIM_DETAILS: 'Read-Only Claim Details',
    UPDATE_CLAIM: 'Update Claim',
    PLAYER_MAINTENANCE: 'Player Maintenance',
    PLAYER_SERVICE_REPORTS: 'Player Service Reports',
    CLAIM_SUPPORT: 'Claim Support',
    PLAYER_SUPPORT: 'Player Support',
    SYSTEM_DASHBOARD: 'System Dashboard',
    USER_MANAGEMENT: 'User Management',
    ROLE_MANAGEMENT: 'Role Management',
    LOCK_UNLOCK_PLAYER: 'Lock Unlock Player',
    FREQUENT_CASHER: 'Frequent Casher',
    CONFIRM_PLAYER_EMAIL: 'Confirm Player Email',
    VIEW_CLAIMS: 'View Claims',
    CLAIM_DETAILS: 'Claim Details',
    VIEW_PAYMENT_DETAILS: 'View Payment Details',
    SUBMIT_PAYMENT_DETAILS: 'Submit Payment',
    VIEW_W2G: "View W2G",
    ACH_ACTIVITY: "ACH Activity",
    ACH_BATCH_DETAILS: "ACH Batch Details",
    SHOW_BATCH_FILE_PII: "Show Batch File PII",
    SHOW_REJECT_FILE_PII: "Show Reject File PII",
    VIEW_REJECTED_CLAIMS: "View Rejected Claims Queue",
    VIEW_ESIGNAURE: "View E-Signature",
    VIEW_ESIGNAURE_FAILED: "View E-Signature Failed",
    VIEW_ESIGNAURE_EXPIRED: "View E-Signature Expired",
    DOCUMENT_VIEWED: 'Document Viewed',
    DOCUMENT_APPROVED: 'Document Approved',
    DOCUMENT_DENIED: 'Document Denied',
    PLAYER_SUPPORT_NEW: 'Player Support New',
    REJECTED_PAYMENTS_QUEUE: 'Rejected Payments Queue',
    PAPER_CHECK_FILE_DETAILS: "Paper Check File Details",
    ACTIVATE_PLAYER_ACCOUNT: 'Activate Player Account',
    DEACTIVATE_PLAYER_ACCOUNT: 'Deactivate Player Account',
    TICKET_BARCODE_SEARCH: 'Ticket barcode search',
    TICKET_SERIAL_SEARCH: 'Ticket serial search',
    TOGGLE_SSN_VISIBILITY: 'Toggle SSN Visibility',
    CHARITABLE_CREATE_LICENSEE: 'Create Licensee',
    CHARITABLE_UPDATE_LICENSEE: 'Update Licensee',
    CHARITABLE_GET_LICENSEE: 'Get Licensee',
    CHARITABLE_LICENSEE_SEARCH: 'Licensee search',
    CHARITABLE_TAX_FORM_SEARCH: 'Tax form search',

    GET_ANNUAL_BEANO_TAX_FORM_DETAILS: 'Get Annual Beano Tax Form Details',
    SEARCH_ANNUAL_BEANO_TAX_FORMS: 'Search Annual Beano Tax Forms',
    UPDATE_ANNUAL_BEANO_TAX_FORM: 'Annual Beano Tax Form Update',

    GET_WEEKLY_BEANO_TAX_FORM_DETAILS: 'Get Weekly Beano Tax Form Details',
    SEARCH_WEEKLY_BEANO_TAX_FORMS: 'Weekly Beano Tax Form Search',
    UPDATE_WEEKLY_BEANO_TAX_FORM: 'Weekly Beano Tax Form Update',

    GET_CASINO_TAX_FORM_DETAILS: 'Get Casino Tax Form details',
    SEARCH_CASINO_TAX_FORMS: 'Casino Tax Form Search',
    UPDATE_CASINO_TAX_FORM: 'Casino Tax Form Update',

    GET_RAFFLE_TAX_FORM_DETAILS: 'Get Raffle Bazaar Tax Form Details',
    SEARCH_RAFFLE_TAX_FORMS: 'Raffle Bazaar Tax Form Search',
    UPDATE_RAFFLE_TAX_FORM: 'Raffle Bazaar Tax Form Update',

    SEARCH_DELINQUENT_REPORTS: 'Delinquent Reports Search',

    SEARCH_CASINO_RAFFLE_REPORT: 'Search Casino Raffle Report',
    SEARCH_ANNUAL_BEANO_REPORT: 'Search Annual Beano Report',
    SEARCH_WEEKLY_BEANO_REPORT: 'Search Weekly Beano Report',

    DOWNLOAD_ANNUAL_BEANO_REPORT: 'Download Annual Beano Report',
    DOWNLOAD_WEEKLY_BEANO_REPORT: 'Download Weekly Beano Report',
    DOWNLOAD_RAFFLE_CASINO_REPORT: 'Download Raffle/Casino Report',

    CHARITABLE_UNSUBMITTED: 'Unsubmitted details',
    CHARITABLE_LICENSE_RENEW: 'Licensee renew',
    CHARITABLE_LICENSE_RENEW_ALL: 'Licensee renew all',
    CHARITABLE_LICENSE_RENEW_NOT: 'Licensee not renew',
    CHARITABLE_MARK_LICENSE_SUBMITTING: 'Mark License as Submitting',
    CHARITABLE_MARK_LICENSE_NOT_SUBMITTING: 'Mark License as Not-Submitting',
    REACTIVATE_LICENSE: 'Reactivate License',
    DOWNLOAD_LICENSE_FILE: 'Download License File',
    PULL_TAB_PURCHASE_ORDER: 'Create purchase Order',
    PULL_TAB_PURCHASE_ORDER_SEARCH: 'Search pull tab purchase Order',
    PULL_TAB_INVENTORY_SEARCH: 'Search pull tab inventory',
    PULL_TAB_PURCHASE_ORDER_ID_SEARCH: 'Search purchase order by id',
    PULL_TAB_GAMES_SEARCH: 'Search pulltab games',
    PULL_TAB_BARCODE_SCAN: 'PullTab Barcode Scan',
    PERMIT_HOLDER_SEARCH_ALL: 'Search all permits',
    PERMIT_HOLDER_UPDATE: 'Update permit',
    PERMIT_HOLDER_CREATE: 'Create permit',
    GET_EFT_ACCOUNT_INFO: 'Get EFT Account Info',
    UPDATE_EFT_ACCOUNT_INFO: 'Update EFT Account Info',
    PERMIT_HOLDER_GET_DETAILS: 'Fetch a Permit Holder Details',
    CREATE_PULL_TAB_GAME: 'Create PullTab Game',
    UPDATE_PULL_TAB_GAME: 'Update PullTab Game',
    UPDATE_PERMIT_DOI: 'Update Permit Date of Issuance',
    FETCH_ALL_WAREHOUSES: 'Fetch all Warehouses',
    ADD_NEW_PULL_TAB_INVENTORY: 'Add New PullTab Inventory',
    TRANSFER_PULL_TAB_INVENTORY: 'Transfer PullTab Inventory',
    SEARCH_CHARGES: 'Search Charges',
    MARK_CHARGES_SETTLED: 'Mark Charges as Settled',
    MARK_CHARGES_REJECTED: 'Mark Charges as Rejected',
    MARK_CHARGES_ON_HOLD: 'Mark Charges as On-Hold',
    MARK_CHARGES_READY_FOR_SWEEP: 'Mark Charges as Ready-For-Sweep',
    RUN_EFT_SWEEP: 'Run EFT Sweep',

    OFFSETS_INQUIRY: "Offsets Inquiry",
    GET_OFFSETS_ACH_BATCH_DETAILS: "Get Offsets ACH Batch Details",
    GET_OFFSETS_WITHHOLDING_DETAILS: "Get Offsets Withholding Details",
    GET_WITHHOLDING_SUMS_AGGREGATE: "Get Withholding Sums Aggregate",
    SEARCH_PAYMENTS: "Search Payments",
    GET_PAYMENT_DETAILS: "Get Payment Details",
    GET_ACTIVE_AND_ALIVE_PAYEES: "Get Active And Alive Payees",
    GENERATE_YEAR_END_REPORT: "Generate Year End Report",
    GET_REPORT_BUNDLES: "Get Report Bundles",
    GENERATE_YEAR_END_REPORT_BUNDLE_URL: "Generate Year End Report Bundle URL",
    GENERATE_QUARTERLY_TAX_REPORT: "Generate Quarterly Tax Report",
    GET_PAYMENT_DETAILS_FOR_REVIEW: "Get Payment Details For Review",
    GET_W2G_QUEUE: "Get W-2G Queue",
    GET_OPEN_CALENDAR_W2G_SWEEPS: "Get Open Calendar W-2G Sweeps",
    GET_ALL_PAST_W2G_SWEEP_PRINTS: "Get All Past W-2G Sweep Prints",
    ADD_PAYMENT_TO_W2G_SWEEP: "Add Payment To W-2G Sweep",
    TRIGGER_REPRINT_PAYMENT_CSV: "Trigger Reprint Payment CSV",
    TRIGGER_W2G_SWEEP: "Trigger W-2G Sweep",
}

export const PagesName = {
    HOME_PAGE: "HOME-PAGE",
    UPDATE_PROFILE_PAGE: "UPDATE-PROFILE",
    PLAYERS_SUPPORT_HUB_PAGE: "PLAYER-SUPPORT-HUB",
    MANUAL_KYC_PAGE: "MANUAL-KYC-PAGE",


} 