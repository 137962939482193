import { PreSignedUrlResponse } from "../../../api/finserv-general-reports/api-types"
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { permissions } from '../../../constants'
import { Split5754ReportingDisplayRow } from './split-5754-reporting-page-ui'
import { saveFromUrl } from "../../../util"

const downloadSplitReportUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/5754Split/reportUrl`

export const getFetchDownload5754SplitReport = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<PreSignedUrlResponse>()
  const preSignedUrlHandler = async (row: Split5754ReportingDisplayRow) => {
    const urlWithParams = `${downloadSplitReportUrl}?reportId=${row.id}`
    const preSignedUrl = await fetchUrl(urlWithParams, 'GET', undefined, undefined, [permissions.CAN_DOWNLOAD_5754_SPLIT_REPORTS])
    if (!preSignedUrl) {
      return
    }
    saveFromUrl(preSignedUrl.url)
  }

  return { isLoading, error, data, preSignedUrlHandler }
}
