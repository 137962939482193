import { parseFloatWithCommas } from "../../../util";
import { FormPayeeDetails, NewPaymentDetails } from "./types";

export function convertFormPayeeDetailsToNewPaymentDetails(formPayeeDetails: FormPayeeDetails[]): NewPaymentDetails[] {
  return formPayeeDetails.map((payment: FormPayeeDetails) => {
    return {
      payeeFirstName: payment.payeeFirstName,
      payeeMiddleInitial: payment.payeeMiddleInitial,
      payeeLastName: payment.payeeLastName,
      payeeFreeformName: payment.payeeFreeformName,
      tin: payment.tin,
      payeeAddress1: payment.address1,
      payeeAddress2: payment.address2,
      payeeCity: payment.city,
      payeeState: payment.state,
      payeeZip: payment.zip,
      payeeCountry: payment.country,
      payeePhone: payment.phone,
      payeeEmail: payment.email,
      grossPaymentInCents: dollarsToCents(parseFloatWithCommas(payment.grossPaymentInDollars))
    }
  })
}

export function dollarsToCents(dollars: number): number {
  return Math.round(dollars * 100)
}