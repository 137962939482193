import { makeHttpCall } from './../../../../services/MakeHttpCall'
import config from './../../../../config.js'
import { checkRenderPermissions } from '../../../../helpers'
import { permissions } from '../../../../constants'

const userpolicies: any = localStorage.getItem('userpolicies')

function swap(items: any, leftIndex: any, rightIndex: any) {
  var temp = items[leftIndex]
  items[leftIndex] = items[rightIndex]
  items[rightIndex] = temp
}

function partition(items: any, left: any, right: any) {
  var pivot = items[Math.floor((right + left) / 2)], //middle element
    i = left, //left pointer
    j = right //right pointer

  while (i <= j) {
    while (items[i].createdAt > pivot.createdAt) {
      i++
    }

    while (items[j].createdAt < pivot.createdAt) {
      j--
    }
    if (i <= j) {
      swap(items, i, j) //swap two elements
      i++
      j--
    }
  }
  return i
}

function quickSort(items: any, left: any, right: any) {
  var index
  if (items.length > 1) {
    index = partition(items, left, right) //index returned from partition
    if (left < index - 1) {
      //more elements on the left side of the pivot
      quickSort(items, left, index - 1)
    }
    if (index < right) {
      //more elements on the right side of the pivot
      quickSort(items, index, right)
    }
  }
  return items
}

let setPageMessage: any
let setSignInEnabled: any
let setIsTicketScanning: any
let setIsAccountStatusOpen: any
export const setVariables = (
  setPageMessageObj: any,
  setSignInEnabledObj: any,
  setIsTicketScanningObj: any,
  setIsAccountStatusOpenObj: any
) => {
  setPageMessage = setPageMessageObj
  setSignInEnabled = setSignInEnabledObj
  setIsTicketScanning = setIsTicketScanningObj
  setIsAccountStatusOpen = setIsAccountStatusOpenObj
}

export const refreshComponent = (response: any) => {
  if (!(response.error === undefined)) {
    setPageMessage({
      message: 'Something went wrong. Please try again.',
      messageType: 'ERROR',
    })
  } else {
    setSignInEnabled({ value: response.account.enabled })
    setIsTicketScanning({
      value: response.identity.scanStatus === 'ENABLED' ? true : false,
    })
    setIsAccountStatusOpen({
      value: response.identity.status === 'DEACTIVATED_IDENTITY' ? false : true,
    })
  }
}
export const getSignIn = async (
  pageMessage: any,
  setPageMessage: any,
  storesObject: any,
  isSignInEnabled: any,
  setSignInEnabled: any,
  setRequestSignIn: any,
  id: any
) => {
  try {
    let identifier: any = id

    const body = {
      firstName:
        storesObject.playerStore.selectedPlayerDetails.identity.firstName,
      lastName:
        storesObject.playerStore.selectedPlayerDetails.identity.lastName,
    }

    const options = {
      method: 'post',
      url: `${config.SERVER_BASE_URL}/v1/player/${identifier}/${
        !isSignInEnabled.value === true ? 'enable' : 'disable'
      }`,
      body,
    }

    const response: any = await makeHttpCall(options)

    if (
      localStorage.getItem('PlayersName') !==
      `${storesObject.playerStore.selectedPlayerDetails.identity.firstName} ${storesObject.playerStore.selectedPlayerDetails.identity.lastName}`
    )
      localStorage.setItem(
        'PlayersName',
        `${storesObject.playerStore.selectedPlayerDetails.identity.firstName} ${storesObject.playerStore.selectedPlayerDetails.identity.lastName}`
      )
    storesObject.playerStore.selectedPlayerDetails.account.enabled =
      !isSignInEnabled.value
    setSignInEnabled({ value: !isSignInEnabled.value })
    const data: any = response.data

    if (pageMessage.messageType === 'ERROR')
      setPageMessage({ message: '', messageType: '' })

    setRequestSignIn(false)
  } catch (er) {
    const e: any = er
    setRequestSignIn(false)

    if (
      e
        .toString()
        .split(' ')
        .find((value: any, i: any) => {
          if (value === 'Network') return value
        }) !== undefined
    ) {
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    }
    if (
      e
        .toString()
        .split(' ')
        .find((e: any) => e === '409') !== undefined
    )
      setPageMessage({
        message: 'The permission is already disabled for this person',
        messageType: 'ERROR',
      })
    else
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
  }
}

export const getTicketScanning = async (
  pageMessage: any,
  setPageMessage: any,
  storesObject: any,
  isTicketScanning: any,
  setIsTicketScanning: any,
  setRequestTicket: any,
  id: any
) => {
  let identifier: any = id

  try {
    const body = {
      firstName:
        storesObject.playerStore.selectedPlayerDetails.identity.firstName,
      lastName:
        storesObject.playerStore.selectedPlayerDetails.identity.lastName,
    }

    const options = {
      method: 'post',
      url: `${config.SERVER_BASE_URL}/v1/player/${identifier}/${
        !isTicketScanning.value ? 'enable-scans' : 'disable-scans?ignoreExemptionList=true'
      }`,
      body,
    }

    const response: any = await makeHttpCall(options)

    storesObject.playerStore.selectedPlayerDetails.identity.scanStatus =
      !isTicketScanning.value === true ? 'ENABLED' : 'DISABLED'

    setIsTicketScanning({ value: !isTicketScanning.value })

    const data: any = response.claims

    setRequestTicket(false)

    if (pageMessage.messageType === 'ERROR')
      setPageMessage({ message: '', messageType: '' })
  } catch (er) {
    const e: any = er
    setRequestTicket(false)

    if (
      e
        .toString()
        .split(' ')
        .find((value: any, i: any) => {
          if (value === 'Network') if (value === 'Error') return value
        }) !== undefined
    ) {
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    }
    if (
      e
        .toString()
        .split(' ')
        .find((e: any) => e === '409') !== undefined
    )
      setPageMessage({
        message: 'The permission is already disabled for this person',
        messageType: 'ERROR',
      })
    else
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
  }
}

export const toggleAccountStatus = async (
  pageMessage: any,
  setPageMessage: any,
  storesObject: any,
  isAccountStatusOpen: any,
  setIsAccountStatusOpen: any,
  isTicketScanning: any,
  setIsTicketScanning: any,
  id: any
) => {
  let identifier: any = id

  try {
    let options = {
      method: 'put',
      url: `${config.SERVER_BASE_URL}/v1/player/${
        !isAccountStatusOpen.value ? 'activate-account' : 'deactivate-account'
      }/${identifier}`,
    }
    //const response: any = await makeHttpCall(options);
    await makeHttpCall(options)
    //storesObject.playerStore.selectedPlayerDetails.account.enabled  = !isAccountStatusOpen.value;
    //setIsAccountStatusOpen({ value: !isAccountStatusOpen.value });

    const maskSsn = !checkRenderPermissions(
      permissions.CAN_SEE_SSN,
      JSON.parse(userpolicies)
    )
    let queryParam = `?maskSsn=${maskSsn}`
    options = {
      method: 'get',
      url: config.SERVER_BASE_URL + '/v1/player/' + identifier + queryParam,
    }

    let response: any = await makeHttpCall(options)
    //response = testPlayerStoreObjectData(response);
    storesObject.playerStore.selectedPlayerDetails = response
    refreshComponent(response)

    if (pageMessage.messageType === 'ERROR')
      setPageMessage({ message: '', messageType: '' })
  } catch (er) {
    const e: any = er

    if (
      e
        .toString()
        .split(' ')
        .find((value: any, i: any) => {
          if (value === 'Network') if (value === 'Error') return value
        }) !== undefined
    ) {
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    }
    if (
      e
        .toString()
        .split(' ')
        .find((e: any) => e === '409') !== undefined
    )
      setPageMessage({
        message: 'The permission is already disabled for this person',
        messageType: 'ERROR',
      })
    else
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
  }
}

export const toggleMobileClaims = async (
  pageMessage: any,
  setPageMessage: any,
  isMobileClaimsEnabled: boolean,
  storesObject: any,
  setMobileClaimsToggleIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setMobileClaimsToggleSuccessful: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setMobileClaimsToggleIsLoading(true)
  setMobileClaimsToggleSuccessful(false)
  const { firstName, lastName, ssn } =
    storesObject.playerStore.selectedPlayerDetails.identity
  let data = {
    firstName: firstName,
    lastName: lastName,
    ssn: ssn.split('-').join(''),
  }
  const willBeFrequentCasher = isMobileClaimsEnabled ? true : false
  try {
    const options = {
      method: 'post',
      url:
        config.SERVER_BASE_URL +
        `/v1/player/${willBeFrequentCasher ? 'add' : 'remove'}-frequent-casher`,
      data,
    }

    await makeHttpCall(options)

    storesObject.playerStore.selectedPlayerDetails.identity.frequentCasher =
      willBeFrequentCasher

    if (pageMessage.messageType === 'ERROR') {
      setPageMessage({ message: '', messageType: '' })
    }
    setMobileClaimsToggleIsLoading(false)
    setMobileClaimsToggleSuccessful(true)
  } catch (er: any) {
    setMobileClaimsToggleIsLoading(false)
    const errorPieces = er.toString().split(' ')
    if (errorPieces.includes('Network') && errorPieces.includes('Error')) {
      // Handle generic network errors
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    }
    if (errorPieces.includes('409')) {
      // Handling 409 Conflict Error
      setPageMessage({
        message: 'The permission is already disabled for this person',
        messageType: 'ERROR',
      })
    } else {
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
    }
  }
}
