import * as React from 'react'

export const ExitButtonIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Exit Button</title>
    <g id="exit_button" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z"
        id="Path"
        fill="#FFFFFF"
        fillRule="nonzero"
        transform="translate(8, 8) scale(-1, 1) translate(-8, -8)"
      ></path>
      <path
        d="M4.2326454,4.2326454 C3.92245153,4.54283927 3.92245153,5.05315822 4.2326454,5.3633521 L6.86929331,8 L4.2326454,10.6366479 C3.92245153,10.9468418 3.92245153,11.4571607 4.2326454,11.7673546 C4.54283927,12.0775485 5.05315822,12.0775485 5.3633521,11.7673546 L8,9.13070669 L10.6366479,11.7673546 C10.9468418,12.0775485 11.4571607,12.0775485 11.7673546,11.7673546 C12.0775485,11.4571607 12.0775485,10.9468418 11.7673546,10.6366479 L9.13070669,8 L11.7673546,5.3633521 C12.0775485,5.05315822 12.0775485,4.54283927 11.7673546,4.2326454 C11.4571607,3.92245153 10.9468418,3.92245153 10.6366479,4.2326454 L8,6.86929331 L5.3633521,4.2326454 C5.05315822,3.92245153 4.54283927,3.92245153 4.2326454,4.2326454 Z"
        id="Path"
        fill="#121921"
        fillRule="nonzero"
        transform="translate(8, 8) scale(-1, 1) translate(-8, -8)"
      ></path>
    </g>
  </svg>
)
