import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useFormik} from "formik";
import {setFieldValue, validate, onSubmit, setVariables} from "./functions";
import {CircularProgress} from "@material-ui/core";
import styles from "./css/styles.module.css";
import {keyPressedType} from "../../../services/Commons";
import goBackIcon from "../../../icons/Icon_arrows_carrot_white-left.svg";
import {
    FrequentCasherAddConfirmationComponent
} from "../../../components/AdminComponents/FrequentCasherAddConfirmationComponent";
import {ErrorMessageFieldCommonComponent} from "../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {
    GenericErrorDialogComponent
} from "../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent, NotificationType
} from "../../../components/HomeComponent/NotificationBannerComponent";
import {Modes} from "./constants.js";
import InputMask from 'react-input-mask';
import {
    FrequentCasherRemoveConfirmationComponent
} from "../../../components/AdminComponents/FrequentCasherRemoveConfirmationComponent";

export const FrequentCasherModulePage = () => {

    const history: any = useHistory();

    const userPolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            firstName: '', lastName: '', SSN: ''
        }, validate, onSubmit
    });

    const activateAddRemoveButtons = (formik.isValid && formik.dirty);

    const [requestCompleted, setRequestCompleted] = useState<boolean>(true);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const [showAddConfirmation, setShowAddConfirmation] = useState<boolean>(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);

    const [notificationBanner, showNotificationBanner] = useState<any>({show: false, type: NotificationType.Success, text: ''});

    const [mode, setMode] = useState<string>(); // Add, Remove

    const hideNotificationBanner = () => {
        showNotificationBanner({show: false, text: ''});
    }

    setVariables(formik, mode, showNotificationBanner, setShowErrorDialog, setRequestCompleted);

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['page-container']}`}
            >
                <div tabIndex={0}
                     className={`${styles['page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to Admin Menu
                    </div>
                </div>
                <div className={`${styles['page-ribbon-container']}`}>
                    <div className={`${styles['page-ribbon-title']} ${styles['']}`}>Frequent Casher
                    </div>
                </div>
                <div className={`${styles['main-container']}`}>
                    <div className={`${styles['main-inner-container']}`}>
                        <div className={`${styles['label-input-box-container']}`}>
                            <div className={`${styles['mslc-label']}`}>First Name</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="firstName"
                                   name="firstName"
                                   value={formik.values.firstName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.firstName && formik.errors.firstName ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.firstName}/> : null}
                        </div>
                        <div className={`${styles['label-input-box-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Last Name</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="lastName"
                                   name="lastName"
                                   value={formik.values.lastName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.lastName && formik.errors.lastName ? <ErrorMessageFieldCommonComponent
                                lastName={formik.errors.lastName}/> : null}
                        </div>
                        <div className={`${styles['label-input-box-container']}`}>
                            <div className={`${styles['mslc-label']}`}>SSN</div>
                            <InputMask className={`${styles['mslc-textbox']}`}
                                       id="SSN"
                                       name="SSN"
                                       value={formik.values.SSN}
                                       onBlur={formik.handleBlur}
                                       placeholder="000-00-0000"
                                       mask="999-99-9999"
                                       maskChar=" "
                                       onChange={(e: any) => {
                                           setFieldValue(e, formik);
                                       }}/>
                            {formik.touched.SSN && formik.errors.SSN ? <ErrorMessageFieldCommonComponent
                                SSN={formik.errors.SSN}/> : null}
                        </div>
                        <div className={`${styles['buttons-container']}`}>
                            <div className={`${styles['button-1']}`}
                                 role="button"
                                 style={{
                                     opacity: (activateAddRemoveButtons) ? "1" : "0.4",
                                     cursor: (activateAddRemoveButtons) ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (activateAddRemoveButtons) {
                                             setMode(Modes.Add);
                                             setShowAddConfirmation(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (activateAddRemoveButtons) {
                                         setMode(Modes.Add);
                                         setShowAddConfirmation(true);
                                     }
                                 }}>Add
                            </div>
                            <div className={`${styles['button-1']}`}
                                 role="button"
                                 style={{
                                     opacity: (activateAddRemoveButtons) ? "1" : "0.4",
                                     cursor: (activateAddRemoveButtons) ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (activateAddRemoveButtons) {
                                             setMode(Modes.Remove);
                                             setShowRemoveConfirmation(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (activateAddRemoveButtons) {
                                         setMode(Modes.Remove);
                                         setShowRemoveConfirmation(true);
                                     }
                                 }}>Remove
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showAddConfirmation ?
                <FrequentCasherAddConfirmationComponent
                    formik={formik}
                    setShowAddConfirmation={setShowAddConfirmation}
                /> : null}
            {showRemoveConfirmation ?
                <FrequentCasherRemoveConfirmationComponent
                    formik={formik}
                    setShowRemoveConfirmation={setShowRemoveConfirmation}
                /> : null}
            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ?
                <NotificationBannerComponent
                    type={notificationBanner.type}
                    text={notificationBanner.text}
                    handleHide={hideNotificationBanner}
                /> : null}
        </>

    );
}
