import * as React from 'react'
import {
  PromotionBuilderRequestBody,
  PromotionStatus,
} from '../../../../api/promotions-fetcher/constants'
import { AsyncContainerUI } from '../../../CommonComponents/async-container-component/async-container-ui'
import {
  GenericSidebar,
  GenericSidebarFormMode,
} from '../../../GenericSidebar/generic-sidebar-ui'
import {
  promotionIsComplete,
  promotionIsNotComplete,
} from './sidebar-forms/helpers'
import { PromotionCampaignSidebarFormContainer } from './sidebar-forms/promotion-campaign-sidebar-form-container'

export type PromotionCampaignBuilderSidebarUIProps = {
  formMode: GenericSidebarFormMode
  promotionStatus?: PromotionStatus
  canEditPromotionCampaigns: boolean
  error?: string
  isLoading: boolean
  promotion?: PromotionBuilderRequestBody
  closeSidebar: () => void
  refreshPromotions: () => Promise<void>
}

export const PromotionCampaignBuilderSidebarUI = (
  props: PromotionCampaignBuilderSidebarUIProps
) => {
  const { error, isLoading, ...formProps } = props

  const getSidebarTitle = (
    formMode: GenericSidebarFormMode,
    promotionStatus?: PromotionStatus
  ) => {
    switch (formMode) {
      case GenericSidebarFormMode.NEW:
        return 'New Campaign'
      case GenericSidebarFormMode.EDIT:
        return promotionIsComplete(promotionStatus)
          ? 'View Campaign'
          : 'Edit Campaign'
      case GenericSidebarFormMode.CLOSED:
        return ''
    }
  }
  return (
    <GenericSidebar
      isOpen={props.formMode !== GenericSidebarFormMode.CLOSED}
      closeSideBar={props.closeSidebar}
      headerText={getSidebarTitle(props.formMode, props.promotionStatus)}
      useConfirm={
        promotionIsNotComplete(props.promotionStatus) &&
        props.canEditPromotionCampaigns
      }
      confirmHeaderText="Are you sure you want to close out of the Promotion Builder?"
    >
      <AsyncContainerUI isLoading={isLoading} error={error} color="blue">
        {props.formMode !== GenericSidebarFormMode.CLOSED && (
          <PromotionCampaignSidebarFormContainer {...formProps} />
        )}
      </AsyncContainerUI>
    </GenericSidebar>
  )
}
