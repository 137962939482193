import { useHistory, useParams } from "react-router-dom"
import { Create5754SplitPageUI } from "./create-5754-split-page-ui"
import { CreateSplitPageProps } from "./types"
import React, { useContext, useEffect } from "react"
import { useFetch5754SplitData } from "./use-fetch-5754-split-data"
import { BannerContext } from "../../../context/BannerContext"
import { paymentsRoutePath } from "../../../config"

export type Create5754SplitPageContainerProps = {

}

export const Create5754SplitPageContainer = (props: Create5754SplitPageContainerProps) => {
  const params = useParams<CreateSplitPageProps>()
  const parentPaymentId = params.internalPaymentId

  // Data
  const {
    data,
    isLoading,
    error,
    splitPaymentError,
    splitPaymentRequestSucessful,
    splitLoading,
    originalGrossInDollars,
    fetchSplitPayment
  } = useFetch5754SplitData(parentPaymentId)

  // Hooks
  const history = useHistory()
  const bannerContext = useContext(BannerContext)
  useEffect(() => {
    if (splitPaymentRequestSucessful) {
      bannerContext.setBannerInfo({ message: 'The 5754 Split has been added; W-2G\'s will be included in the next distribution cycle', error: false })
      history.goBack()
    }
  }, [splitPaymentRequestSucessful])
  
  return (
    <Create5754SplitPageUI
      isLoading={ isLoading }
      error={ error }
      splitPaymentError={ splitPaymentError }
      parentPaymentId={ parentPaymentId }
      childPayments={ data }
      originalGrossInDollars={ originalGrossInDollars }
      splitLoading={ splitLoading }
      fetchSplitPayment={ fetchSplitPayment }
    />
  )
}