import { CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { TimePaymentAuthSchedule } from '../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { ExportDropdown } from '../../../CommonComponents/export-dropdown/export-dropdown-ui'
import { AggregateTotalsCmpt } from '../Common/AuthorizationAggregateTotalsCmpt'
import { PaymentTableCmpt } from '../Common/PaymentTableCmpt'
import { generateCheckRunPDF } from '../Common/functions'
import { exportAsXLSX } from '../Common/time-payment-authorization-excel-export'
import styles from './css/styles.module.css'
import { getPaymentData } from './functions'

export const AuthReportingCmpt = () => {
  const bannerContext = useContext(BannerContext)

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const [originalPaymentData, setOriginalPaymentData] = useState<
    TimePaymentAuthSchedule[]
  >([])
  const [filteredPaymentData, setFilteredPaymentData] = useState<
    TimePaymentAuthSchedule[]
  >([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [tinSelected, setTinSelected] = useState<string>('')
  const [statusSelected, setStatusSelected] = useState<string>('')
  const [availableTins, setAvailableTins] = useState<string[]>([])
  const [availablePaymentStatus, setAvailablePaymentStatus] = useState<
    string[]
  >([])

  //initialize formik
  const formik = useFormik({
    initialValues: { fromDate: '', throughDate: '' },
    initialTouched: {},
    enableReinitialize: true,
    onSubmit: (values) => {},
    validate: (values) => {
      const errors = {}

      if (values.fromDate === '') {
        Object.assign(errors, { fromDate: 'This field is Required' })
      }

      if (values.throughDate === '') {
        Object.assign(errors, { throughDate: 'This field is Required' })
      }

      if (moment(values.fromDate) > moment(values.throughDate)) {
        Object.assign(errors, {
          throughDate: 'Through date must be after from date',
        })
      }
      return errors
    },
  })

  const fetchPaymentData = async () => {
    setShowSpinner(true)
    try {
      const response = await getPaymentData(
        formik.values.fromDate,
        formik.values.throughDate
      )
      const listOfTins = response
        .map((item) => {
          return item.payee.tin
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        })

      const listOfPaymentStatuses = response
        .map((item) => {
          return item.payment.paymentStatus
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        })

      setAvailablePaymentStatus(listOfPaymentStatuses)
      setAvailableTins(listOfTins)
      setOriginalPaymentData(response)
      setFilteredPaymentData(response)
    } catch (error) {
      bannerContext.setBannerInfo({ message: `${error}`, error: true })
    }
    setShowSpinner(false)
  }

  // useEffect to filter Payment Data
  useEffect(() => {
    let filteredPaymentData = originalPaymentData

    if (tinSelected !== '' && tinSelected !== 'All') {
      filteredPaymentData = filteredPaymentData.filter(
        (paymentData) => paymentData.payee.tin === tinSelected
      )
    }
    if (statusSelected !== '' && statusSelected !== 'All') {
      filteredPaymentData = filteredPaymentData.filter((paymentData) => {
        return paymentData.payment.paymentStatus === statusSelected
      })
    }
    setFilteredPaymentData(filteredPaymentData)
  }, [tinSelected, statusSelected])

  const exportPDF = async () => {
    setShowSpinner(true)
    try {
      await generateCheckRunPDF(
        formik.values.throughDate,
        formik.values.fromDate
      )
    } catch (err) {
      bannerContext.setBannerInfo({
        message: 'Error exporting PDF',
        error: true,
      })
    }
    setShowSpinner(false)
  }

  const exportXLSX = () =>
    exportAsXLSX(
      filteredPaymentData,
      formik.values.throughDate,
      formik.values.fromDate
    )

  return (
    <>
      {showSpinner && (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}
      <div className={`${styles.search_container} w-100 my-2 pb-3 rounded`}>
        <div className="d-flex justify-content-between m-2">
          <span className={styles.section_header}>Search</span>
          <ExportDropdown
            disabled={filteredPaymentData.length === 0}
            exportPDF={exportPDF}
            exportXLSX={exportXLSX}
          ></ExportDropdown>
        </div>
        <div className={`${styles.filter_container} m-2`}>
          <span className={styles.section_header}>Filter</span>
          <button
            className={styles.filter_chevron}
            onKeyPress={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                setIsFilterOpen(!isFilterOpen)
              }
            }}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            {isFilterOpen ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          <div
            className={
              isFilterOpen
                ? `${styles.filter_options_open}`
                : `${styles.filter_options_closed}`
            }
          >
            <div>
              <label htmlFor="tin">TIN</label>
              <div className={`${styles.custom_select_div}`}>
                <select
                  onChange={(event) => {
                    setTinSelected(event.target.value)
                  }}
                  className={`${styles.custom_select}`}
                  name="tin"
                  id="tin"
                  defaultValue={tinSelected === '' ? 'default' : tinSelected}
                >
                  <option value="default">All</option>
                  {availableTins.map((tin: string, index: number) => (
                    <option
                      value={tin}
                      key={index}
                      selected={tin === tinSelected}
                    >{`${tin}`}</option>
                  ))}
                </select>
                <span className={`${styles.custom_arrow}`}></span>
              </div>
            </div>
            <div className="mx-2">
              <label htmlFor="status">Status</label>
              <div className={`${styles.custom_select_div}`}>
                <select
                  onChange={(event) => {
                    setStatusSelected(event.target.value)
                  }}
                  className={`${styles.custom_select}`}
                  name="status"
                  id="status"
                  defaultValue={
                    statusSelected === '' ? 'default' : statusSelected
                  }
                >
                  <option value="default">All</option>
                  {availablePaymentStatus.map(
                    (status: string, index: number) => (
                      <option
                        value={status}
                        key={index}
                        selected={status === statusSelected ? true : false}
                      >{`${status}`}</option>
                    )
                  )}
                </select>
                <span className={`${styles.custom_arrow}`}></span>
              </div>
            </div>
            <button
              type="button"
              className={`${styles.dark_btn}`}
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  setTinSelected('')
                  setStatusSelected('')
                  setFilteredPaymentData(originalPaymentData)
                }
              }}
              onClick={async () => {
                setTinSelected('')
                setStatusSelected('')
                setFilteredPaymentData(originalPaymentData)
              }}
            >
              Clear
            </button>
          </div>
        </div>
        <div></div>
        <main className={`${styles.main_container} rounded pr-2`}>
          {/* Search Input Fields */}
          <div className={`${styles.payment_search} ml-2`}>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="fromDate">Select From Date</label>
                    <input
                      type="date"
                      max="9999-12-31"
                      className={`${styles.custom_input}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="fromDate"
                      id="fromDate"
                      value={formik.values.fromDate}
                    />
                  </div>
                  {formik.errors.fromDate && formik.touched.fromDate && (
                    <div
                      className={`${styles.info_detail} ${styles.error_message}`}
                    >
                      <ErrorMessageFieldCommonComponent
                        errorMessage={formik.errors.fromDate}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="throughDate">Select Through Date</label>
                    <input
                      disabled={!formik.values.fromDate}
                      type="date"
                      min={formik.values.fromDate}
                      max="9999-12-31"
                      className={`${styles.custom_input}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="throughDate"
                      id="throughDate"
                      value={formik.values.throughDate}
                    />
                  </div>
                  {formik.errors.throughDate && formik.touched.throughDate && (
                    <div
                      className={`${styles.info_detail} ${styles.error_message}`}
                    >
                      <ErrorMessageFieldCommonComponent
                        errorMessage={formik.errors.throughDate}
                      />
                    </div>
                  )}
                </div>
                {/* Search Button */}
                <button
                  type="button"
                  className={
                    formik.values.fromDate === '' || formik.errors.throughDate
                      ? `${styles.search_btn_disabled}`
                      : `${styles.search_btn}`
                  }
                  disabled={Object.keys(formik.errors).length > 0}
                  onKeyPress={async (event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      await fetchPaymentData()
                    }
                  }}
                  onClick={async () => {
                    await fetchPaymentData()
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className={`m-2 w-100 border-bottom border-white`}>
            <AggregateTotalsCmpt
              paymentResponse={filteredPaymentData}
              showOnHoldDisclaimer={false}
            />
          </div>
          {/* Payment Table */}
          <div>
            <PaymentTableCmpt
              paymentListData={filteredPaymentData}
              fromPage={'Reporting'}
            />
          </div>
        </main>
      </div>
    </>
  )
}
