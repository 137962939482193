import * as React from 'react'
import { CampaignData } from '../../../api/promotions-fetcher/coupon-campaigns/constants'
import { getPageData } from '../../../util'
import {
  CAMPAIGNS_PER_PAGE,
  CampaignsListFooterUI,
} from '../campaigns-list-footer/campaigns-list-footer-ui'
import { CampaignsListHeaderUI } from '../campaigns-list-header/campaigns-list-header-ui'
import { CampaignsListRowContainer } from '../campaigns-list-row/campaigns-list-row-container'
import './styles.scss'

export type CampaignsListUIProps = {
  campaigns: CampaignData[]
  setOpenPreviewImage: (imageUrl: string | undefined) => void
  showEditCampaign: (campaignIdentifier: string) => void
}

const ROOT_CLASS = 'campaigns-list'

export const CampaignsListUI = (props: CampaignsListUIProps) => {
  const [currentPage, setCurrentPage] = React.useState<number>(0)

  const pageData: CampaignData[] = getPageData(
    props.campaigns,
    currentPage,
    CAMPAIGNS_PER_PAGE
  )
  return (
    <>
      <table className={ROOT_CLASS}>
        <CampaignsListHeaderUI />
        {pageData.map((row) => (
          <CampaignsListRowContainer
            key={row.campaignIdentifier}
            rowData={row}
            setOpenPreviewImage={props.setOpenPreviewImage}
            showEditCampaign={props.showEditCampaign}
          />
        ))}
      </table>
      <CampaignsListFooterUI
        campaigns={props.campaigns}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  )
}
