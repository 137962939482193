import { useFormik } from "formik";
import moment from "moment-timezone";
import React, { useContext } from "react";
import InputMask from "react-input-mask";
import { Countries } from "../../../../../Constants/Countries";
import { KEY_CODES } from "../../../../../constants.js";
import { BannerContext } from "../../../../../context/BannerContext";
import { keyPressedType } from "../../../../../services/Commons";
import { FormPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { ErrorMessageFieldCommonComponent } from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import styles from "./css/styles.module.css";
import { updatePayee, validate } from "./functions";

export const PayeeDetailsEdit: React.FC<{
  payee: FormPayee;
  setPayee: React.Dispatch<React.SetStateAction<any>>;
  toggleEditable: () => void;
}> = ({ payee, setPayee, toggleEditable }) => {
  const bannerContext = useContext(BannerContext);

  //initialize formik
  const formik = useFormik({
    initialValues: {
      id: payee.id,
      createdOn: payee.createdOn,
      validFrom: payee.validFrom,
      validTo: payee.validTo,
      address1: payee.address1,
      address2: payee.address2,
      city: payee.city,
      country: payee.country,
      dateOfBirth: !payee.dateOfBirth ? "" : moment(payee.dateOfBirth).format("YYYY-MM-DD"),
      dateOfDeath: !payee.dateOfDeath ? "" : moment(payee.dateOfDeath).format("YYYY-MM-DD"),
      firstName: payee.firstName ?? "",
      lastName: payee.lastName ?? "",
      middleInitial: payee.middleInitial ?? "",
      notes: payee.notes,
      payeeType: payee.payeeType,
      email: payee.email,
      phone: payee.phone,
      tin: payee.tin,
      tinType: payee.tinType ?? "",
      state: payee.state,
      zip: payee.zip,
      freeformName: payee.freeformName ?? "",
      irsName: payee.irsName ?? "",
    },
    onSubmit: values => {},
    validate,
  });

  return (
    <form>
      <div className={styles["cmpnt-container"]}>
        <div className={`${styles.grid_master_col_2}`}>
          {/* payee info */}
          <div>
            <div className={`${styles.payee_container}`}>
              <div className={`${styles.component_title}`}>Primary Info</div>
              <div className={`${styles.grid_col_2}`}>
                {formik.values.payeeType === "Individual" ? (
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      className={`${styles.custom_input} text-uppercase`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="firstName"
                      id="firstName"
                      value={formik.values.firstName}
                    />
                    {formik.errors.firstName && formik.touched.firstName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.firstName} />}
                  </div>
                ) : (
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="freeformName">Freeform Name*</label>
                    <input
                      type="text"
                      className={`${styles.custom_input} text-uppercase`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="freeformName"
                      id="freeformName"
                      value={formik.values.freeformName}
                    />
                    {formik.errors.freeformName && formik.touched.freeformName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.freeformName} />}
                  </div>
                )}
                {formik.values.payeeType === "Individual" && (
                  <>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="middleInitial">Middle Initial</label>
                      <InputMask
                        mask="a"
                        maskChar=""
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="middleInitial"
                        id="middleInitial"
                        value={formik.values.middleInitial}
                      ></InputMask>
                    </div>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="lastName">Last Name*</label>
                      <input
                        type="text"
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="lastName"
                        id="lastName"
                        value={formik.values.lastName}
                      />
                      {formik.errors.lastName && formik.touched.lastName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.lastName} />}
                    </div>
                  </>
                )}
                <div className={`${styles.info_detail}`}>
                    <label htmlFor="irsName">IRS Name*</label>
                    <InputMask
                        mask="aaaa"
                        maskChar=""
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="irsName"
                        id="irsName"
                        value={formik.values.irsName}
                      ></InputMask>
                    {formik.errors.irsName && formik.touched.irsName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.irsName} />}
                  </div>
              </div>
            </div>
            {/* demo info */}
            <div className={`${styles.payee_container}`}>
              <div className={`${styles.component_title}`}>Demographic &amp; Contact Info</div>
              <div className={`${styles.grid_col_2}`}>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="address1">Mailing Address 1*</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="address1"
                    id="address1"
                    value={formik.values.address1}
                  />
                  {formik.errors.address1 && formik.touched.address1 && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.address1} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="address2">Mailing Address 2</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="address2"
                    id="address2"
                    value={formik.values.address2}
                  />
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="city">City*</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="city"
                    id="city"
                    value={formik.values.city}
                  />
                  {formik.errors.city && formik.touched.city && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.city} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="state">State/Region*</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="state"
                    id="state"
                    value={formik.values.state}
                  />
                  {formik.errors.state && formik.touched.state && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.state} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="zip">Zip/Postal Code*</label>
                  <InputMask
                    mask="99999"
                    maskChar=""
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="zip"
                    id="zip"
                    value={formik.values.zip}
                  ></InputMask>
                  {formik.errors.zip && formik.touched.zip && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.zip} />}
                </div>

                <div className={`${styles.info_detail}`}>
                  <label htmlFor="country">Country*</label>
                  <div className={`${styles.custom_select_div}`}>
                    <select
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${styles.custom_select}`}
                      name="country"
                      id="country"
                    >
                      {Countries.map((country: any) => (
                        <option
                          value={country}
                          selected={country === formik.values.country ? true : false}
                        >{`${country}`}</option>
                      ))}
                    </select>
                    <span className={`${styles.custom_arrow}`}></span>
                  </div>
                  {formik.errors.country && formik.touched.country && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.country} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="phone">Phone</label>
                  <InputMask
                    mask="999-999-9999"
                    maskChar=""
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="phone"
                    name="phone"
                    placeholder={"888-555-6789"}
                    value={formik.values.phone}
                  ></InputMask>
                  {formik.errors.phone && formik.touched.phone && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.phone} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="email"
                    id="email"
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.email} />}
                </div>
                {formik.values.payeeType === "Individual" && (
                  <>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="dateOfBirth">Birth Date</label>
                      <input
                        type="date"
                        max={moment().format("YYYY-MM-DD")}
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="dateOfBirth"
                        id="dateOfBirth"
                        value={formik.values.dateOfBirth}
                      />
                      {formik.errors.dateOfBirth && formik.touched.dateOfBirth && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.dateOfBirth} />}
                    </div>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="dateOfDeath">Death Date</label>
                      <input
                        type="date"
                        max={moment().format("YYYY-MM-DD")}
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="dateOfDeath"
                        id="dateOfDeath"
                        value={formik.values.dateOfDeath}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className={`${styles.btn_container}`}>
            <button
              type="button"
              className={
                `mb-2 ${Object.keys(formik.errors).length
                  ? `${styles.light_btn_disabled}`
                  : `${styles.light_btn}`}`
              }
              disabled={
                Object.keys(formik.errors).length
                  ? true
                  : false
              }
              onKeyUp={async event => {
                if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                  if (formik.dirty) {
                    try {
                      await updatePayee(formik.values.id, formik.values);
                      bannerContext.setBannerInfo({ message: "Successfully Updated Payee", error: false });
                      setPayee(formik.values);
                      toggleEditable();
                    } catch (error) {
                      formik.resetForm();
                      bannerContext.setBannerInfo({ message: `${error}`, error: true });
                      toggleEditable();
                    }
                  } else {
                    toggleEditable();
                  }
                }
              }}
              onClick={async () => {
                // API call here to submit new payee information
                if (formik.dirty) {
                  try {
                    await updatePayee(formik.values.id, formik.values);
                    bannerContext.setBannerInfo({ message: "Successfully Updated Payee", error: false });
                    setPayee(formik.values);
                    toggleEditable();
                  } catch (error) {
                    formik.resetForm();
                    bannerContext.setBannerInfo({ message: `${error}`, error: true });
                    toggleEditable();
                  }
                } else {
                  toggleEditable();
                }
              }}
            >
              Save
            </button>
            <CancelButton
              resetForm={() => formik.resetForm()}
              toggleEditable={() => toggleEditable()}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const CancelButton: React.FC<{
  resetForm: () => void;
  toggleEditable: () => void;
}> = ({ resetForm, toggleEditable }) => {
  return (
    <button
      type="button"
      className={`${styles.dark_btn}`}
      onKeyUp={event => {
        if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
          resetForm();
          toggleEditable();
        }
      }}
      onClick={() => {
        resetForm();
        toggleEditable();
      }}
    >
      Cancel
    </button>
  );
};
