import * as React from 'react'
import {
  PromotionBuilderReward,
  RewardType,
} from '../../../../../../api/promotions-fetcher/constants'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { GenericSidebarFormMode } from '../../../../../GenericSidebar/generic-sidebar-ui'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  promotionIsNewOrPending,
} from '../helpers'
import { ErrorMessageFieldCommonComponent } from '../../../../../CommonComponents/ErrorMessageFieldCommonComponent'
import {
  CollapsableSection,
  useForceOpen,
} from '../../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import { PromotionEntryDistributionFormUI } from './single-entry-distribution-form-ui'
import { PromotionEntriesDistributionViewModeUI } from './entries-distribution-view-mode-ui'
import '../styles.scss'

type PromotionEntriesDistributionDetailsUIProps =
  PromotionCampaignBuilderSubFormProps

export const PromotionEntriesDistributionDetailsFormUI = (
  props: PromotionEntriesDistributionDetailsUIProps
) => {
  const [editMode, setEditMode] = React.useState<boolean>(
    props.formMode === GenericSidebarFormMode.NEW
  )
  const { isForcedOpen, forceOpen } = useForceOpen()

  const openEditMode = () => {
    forceOpen()
    setEditMode(true)
  }

  const { removeElement, addElement } = useFormArray<PromotionBuilderReward>({
    defaultFieldValue: {
      id: undefined,
      rewardType: RewardType.DRAWING_ENTRY,
      quantity: 1,
      distributionPercentage: 100,
    },
    fieldArray: props.formik.values.promotionRewardGroups[0].promotionRewards,
    setFieldArray: async (rewards: PromotionBuilderReward[]) => {
      await props.formik.setFieldValue(
        'promotionRewardGroups[0].promotionRewards',
        rewards
      )
    },
  })

  const addEntryDistribution = async () => {
    await addElement()
    props.formik.setFieldTouched('hasEntriesDistribution')
  }

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'entries-distribution-details-container'
      )}
    >
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'entries-distribution-details'
        )}
      >
        <CollapsableSection
          title={`Entries Distribution`}
          isForcedOpen={isForcedOpen}
          backgroundColor="light_blue"
          textSize="small"
          connectedBottom
          smallHeader
          defaultOpen
        >
          {editMode ? (
            <>
              {props.formik.values.promotionRewardGroups[0].promotionRewards.map(
                (reward, index) => (
                  <PromotionEntryDistributionFormUI
                    index={index}
                    entryDistribution={reward}
                    formik={props.formik}
                    isLoading={props.isLoading}
                    removeElement={() => removeElement(index)}
                    key={`entry-distribution-${index}`}
                  />
                )
              )}
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_CLASS,
                  'add-distribution-button-container'
                )}
              >
                <button
                  type="button"
                  className={getBlockClass(
                    PROMOTION_SIDEBAR_FORM_CLASS,
                    'add-distribution-button'
                  )}
                  aria-label="Add new distribution"
                  disabled={props.isLoading}
                  onClick={addEntryDistribution}
                >
                  Add New Distribution
                </button>
              </div>
            </>
          ) : (
            <PromotionEntriesDistributionViewModeUI
              entryDistributions={
                props.formik.values.promotionRewardGroups[0].promotionRewards
              }
            />
          )}
        </CollapsableSection>
        <div
          className={getBlockClass(PROMOTION_SIDEBAR_FORM_ROOT_CLASS, 'footer')}
        >
          {!editMode &&
            promotionIsNewOrPending(props.promotionStatus) &&
            props.canEditPromotionCampaigns && (
              <button onClick={openEditMode}>Edit</button>
            )}
        </div>
      </div>
      {props.formik.errors['hasEntriesDistribution'] &&
        props.formik.touched['hasEntriesDistribution'] && (
          <ErrorMessageFieldCommonComponent
            errorMessage={props.formik.errors['hasEntriesDistribution']}
          />
        )}
    </div>
  )
}
