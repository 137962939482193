import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const RunEFTSweepFirstConfirmationComponent = ({setEFTSweepFinalConfirmationToggle, setEFTSweepFirstConfirmationToggle}: any) => {

    return (

        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["confirmation-dialog-main-container"]}>
                    <div id="confirmation-dialog-container"
                         className={stylesMessageBox["confirmation-dialog-container"]}>
                        <div className={stylesMessageBox["confirmation-dialog-text-container"]}>
                            <div className={stylesMessageBox["confirmation-dialog-title"]}>Run EFT Sweep</div>
                            <div className={stylesMessageBox["confirmation-dialog-message"]}>
                                Attention! This EFT Sweep will only apply to the results created by the search and filter
                                engine. Any charges checked for “Hold Charges” will also be included in the sweep.
                            </div>
                        </div>
                        <div className={stylesMessageBox["confirmation-dialog-button-container"]}>
                            <div tabIndex={0} className={stylesMessageBox["confirmation-dialog-yes-button"]}
                                 role="button"
                                 onKeyDown={(e: any) => {
                                     if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                         setEFTSweepFinalConfirmationToggle(true);
                                         setEFTSweepFirstConfirmationToggle(false);
                                     }
                                 }}
                                 onClick={() => {
                                     setEFTSweepFinalConfirmationToggle(true);
                                     setEFTSweepFirstConfirmationToggle(false);
                                 }}
                            >Run Sweep
                            </div>
                            <div
                                tabIndex={0}
                                className={stylesMessageBox["confirmation-dialog-no-button"]}
                                role="button"
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        setEFTSweepFirstConfirmationToggle(false);
                                    }
                                }}
                                onClick={() => {
                                    setEFTSweepFirstConfirmationToggle(false);
                                }}
                            >Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};