import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {
    applyOccasionDateSortingOrder,
    clearSearch,
    handleCheckboxChange,
    onSubmit,
    setFieldValue,
    setVariables,
    validate
} from "./functions";
import {CircularProgress} from "@material-ui/core";
import moment from "moment";
import {
    AnnualBeanoSearchResultsDefaultData,
    AnnualBeanoSearchResultsInterface,
    CasinoTaxFormsSearchResultsDefaultData,
    CasinoTaxFormsSearchResultsInterface,
    DelinquencyReportsSearchResultsDefaultData,
    DelinquencyReportsSearchResultsInterface,
    WeeklyBeanoSearchResultsDefaultData,
    WeeklyBeanoSearchResultsInterface
} from "./interfaces";
import {
    TaxFormManagementSearchBarComponent
} from "../../../../components/CharitableGamingComponents/TaxForm/TaxFormManagementSearchBarComponent";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {Tab, Tabs} from 'react-bootstrap';
import {NotificationBannerComponent} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {
    WeeklyBeanoSearchResultComponent
} from "../../../../components/CharitableGamingComponents/TaxForm/WeeklyBeanoSearchResultComponent";
import {
    AnnualBeanoSearchResultComponent
} from "../../../../components/CharitableGamingComponents/TaxForm/AnnualBeanoSearchResultComponent";
import {
    CasinoFunctionSearchResultComponent
} from "../../../../components/CharitableGamingComponents/TaxForm/CasinoFunctionSearchResultComponent";
import {
    RaffleBazaarSearchResultComponent
} from "../../../../components/CharitableGamingComponents/TaxForm/RaffleBazaarSearchResultComponent";
import {charitableRoutePath} from "../../../../config.js";
import {
    DelinquencyReportsSearchResultComponent
} from "../../../../components/CharitableGamingComponents/TaxForm/DelinquencyReportsSearchResultComponent";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";

export const TaxFormManagementPage = inject("charitableGamingStore")(observer(({
                                                                                   stockNumber, charitableGamingStore
                                                                               }: any) => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const [currentTab, setCurrentTab] = useState<string | null>('weeklyBeano');

    const previousYear = new Date().getFullYear() - 1;

    const formik = useFormik({
        initialValues: {
            sortByOccasionDate: 'Newest First',
            startOccasionDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endOccasionDate: moment().format('YYYY-MM-DD'),
            licenseNumber: '',
            raffleId: '',
            averageIndicatorOnly: false,
            showSubmittingOrgsOnly: true,
            taxYear: ''
        }, validate, onSubmit
    });

    const [weeklyBeanoTaxForms, setWeeklyBeanoTaxForms] = useState<WeeklyBeanoSearchResultsInterface>(WeeklyBeanoSearchResultsDefaultData);
    const [annualBeanoTaxForms, setAnnualBeanoTaxForms] = useState<AnnualBeanoSearchResultsInterface>(AnnualBeanoSearchResultsDefaultData);
    const [casinoTaxForms, setCasinoTaxForms] = useState<CasinoTaxFormsSearchResultsInterface>(CasinoTaxFormsSearchResultsDefaultData);
    const [raffleBazaarTaxForms, setRaffleBazaarTaxForms] = useState<CasinoTaxFormsSearchResultsInterface>(CasinoTaxFormsSearchResultsDefaultData);
    const [delinquencyReports, setDelinquencyReports] = useState<DelinquencyReportsSearchResultsInterface>(DelinquencyReportsSearchResultsDefaultData);

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const {notificationBanner} = charitableGamingStore;

    useEffect(() => {
        if (location.state !== undefined) {
            resetForm(location.state.tabName);
        }
        formik.submitForm();
    }, []);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    setVariables(formik, charitableGamingStore, weeklyBeanoTaxForms, setWeeklyBeanoTaxForms, annualBeanoTaxForms, setAnnualBeanoTaxForms, casinoTaxForms, setCasinoTaxForms, raffleBazaarTaxForms, setRaffleBazaarTaxForms, delinquencyReports, setDelinquencyReports, setCurrentPage, setRequestCompleted, setShowErrorDialog, currentTab);

    const resetForm = (tabName: any) => {

        if (tabName === 'annualBeano') {
            formik.resetForm({
                values: {
                    sortByOccasionDate: 'Newest First',
                    startOccasionDate: '',
                    endOccasionDate: '',
                    licenseNumber: '',
                    raffleId: '',
                    averageIndicatorOnly: false,
                    showSubmittingOrgsOnly: true,
                    taxYear: previousYear.toString()
                }
            });
        } else {
            formik.resetForm({
                values: {
                    sortByOccasionDate: 'Newest First',
                    startOccasionDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
                    endOccasionDate: moment().format('YYYY-MM-DD'),
                    licenseNumber: '',
                    raffleId: '',
                    averageIndicatorOnly: false,
                    showSubmittingOrgsOnly: true,
                    taxYear: ''
                }
            });
        }

        setCurrentTab(tabName);

    }

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['pulltab-stock-inventory-page-container']} ${styles['']}`}>
                <div tabIndex={0}
                     className={`${styles['pulltab-stock-inventory-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.push(charitableRoutePath);
                         }
                     }}
                     onClick={() => {
                         history.push(charitableRoutePath);
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['pulltab-stock-inventory-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to Charitable Gaming
                    </div>
                </div>
                <div className={`${styles['pulltab-stock-inventory-page-ribbon-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-stock-inventory-page-ribbon-title']} ${styles['']}`}>Tax Form
                        Management
                    </div>

                </div>

                <div
                    className={`${styles['pulltab-stock-inventory-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                    <TaxFormManagementSearchBarComponent
                        formik={formik}
                        currentTab={currentTab}
                        setFieldValue={setFieldValue}
                        clearSearch={clearSearch}
                        handleCheckboxChange={handleCheckboxChange}
                        applyOccasionDateSortingOrder={applyOccasionDateSortingOrder}
                    />
                </div>
                <div className={`${styles["license-details-page-tabs-container"]}`}>
                    <Tabs
                        defaultActiveKey={'weeklyBeano'}
                        activeKey={currentTab ? currentTab : 'weeklyBeano'}
                        className={`${styles["license-details-tabs"]}`}
                        id="license-details-tabs"
                        onSelect={(tabName) => {
                            resetForm(tabName);
                            formik.submitForm();
                        }}
                    >
                        {checkRenderPermissions(permissions.CAN_SEE_WEEKLY_BEANO_TAX_TAB, JSON.parse(userpolicies)) ?
                            <Tab eventKey="weeklyBeano" title="Weekly Beano">
                                <div className={`${styles["license-details-tab-main-container"]}`}>
                                    {weeklyBeanoTaxForms.forms.length > 0 ? <WeeklyBeanoSearchResultComponent
                                        weeklyBeanoTaxForms={weeklyBeanoTaxForms}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    /> : <div
                                        className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                                            No Results. Please try a different search.
                                        </div>
                                    </div>}
                                </div>
                            </Tab> : null}
                        {checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_TAX_TAB, JSON.parse(userpolicies)) ?
                            <Tab eventKey="annualBeano" title="Annual Beano">
                                <div className={`${styles["license-details-tab-main-container"]}`}>
                                    {annualBeanoTaxForms.forms.length > 0 ? <AnnualBeanoSearchResultComponent
                                        annualBeanoTaxForms={annualBeanoTaxForms}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    /> : <div
                                        className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                                            No Results. Please try a different search.
                                        </div>
                                    </div>}
                                </div>
                            </Tab> : null}
                        {checkRenderPermissions(permissions.CAN_SEE_CASINO_TAX_FORM_TAB, JSON.parse(userpolicies)) ?
                            <Tab eventKey="casino" title="Casino">
                                <div className={`${styles["license-details-tab-main-container"]}`}>
                                    {casinoTaxForms.forms.length > 0 ? <CasinoFunctionSearchResultComponent
                                        casinoTaxForms={casinoTaxForms}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    /> : <div
                                        className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                                            No Results. Please try a different search.
                                        </div>
                                    </div>}
                                </div>
                            </Tab> : null}
                        {checkRenderPermissions(permissions.CAN_SEE_RAFFLE_TAX_FORM_TAB, JSON.parse(userpolicies)) ?
                            <Tab eventKey="raffle" title="Raffle">
                                <div className={`${styles["license-details-tab-main-container"]}`}>
                                    {raffleBazaarTaxForms.forms.length > 0 ? <RaffleBazaarSearchResultComponent
                                        raffleBazaarTaxForms={raffleBazaarTaxForms}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    /> : <div
                                        className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                                            No Results. Please try a different search.
                                        </div>
                                    </div>}
                                </div>
                            </Tab> : null}
                        {checkRenderPermissions(permissions.CAN_SEE_DELINQUENT_REPORTS_TAB, JSON.parse(userpolicies)) ?
                            <Tab eventKey="delinquencyReports" title="Delinquency Reports">
                                <div className={`${styles["license-details-tab-main-container"]}`}>
                                    {delinquencyReports.forms.length > 0 ? <DelinquencyReportsSearchResultComponent
                                        delinquencyReports={delinquencyReports}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    /> : <div
                                        className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                                        <div
                                            className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                                            No Results. Please try a different search.
                                        </div>
                                    </div>}
                                </div>
                            </Tab> : null}
                    </Tabs>

                </div>
            </div>
            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                text={notificationBanner.text}
                type={notificationBanner.type}
                handleHide={hideNotificationBanner}
            /> : null}
        </>

    );
}));