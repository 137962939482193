import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {keyPressedType} from "../../../../../services/Commons";
import {getIn, useFormik} from "formik";
import styles from "./css/styles.module.css";
import {
    onSubmit,
    setFieldValue,
    setVariables,
    setWarehouse,
    validate,
    YesCancel,
    YesDeleteBarcode,
    YesResetAllBarcodes,
    YesSave
} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {
    CancelConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/CancelConfirmationComponent";
import {PageMessageCommonComponent} from "../../../../../components/CommonComponents/PageMessageCommonComponent";
import {
    SaveConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/SaveConfirmationComponent";
import {FaPlus} from "react-icons/fa";
import {BsArrowCounterclockwise} from "react-icons/bs";
import {
    ErrorMessageFieldCommonComponent
} from "../../../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {RiDeleteBin6Line} from "react-icons/ri";
import {
    DeleteBarcodeConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/DeleteBarcodeConfirmationComponent";
import {
    ResetAllBarcodesConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/ResetAllBarcodesConfirmationComponent";

export const TransferInventoryPage = inject("charitableGamingStore")(observer(({stockNumber, charitableGamingStore}: any) => {

        const history: any = useHistory();

        let {errorNotificationBanner} = charitableGamingStore;

        const formik = useFormik({
            initialValues: {
                warehouse: '',
                barcodeInformation: [{
                    serialNumber: '1.',
                    barcodeNumber: '',
                    stockNumber: '-',
                    serialNumbers: '-',
                    quantity: '0',
                    currentWarehouse: '-',
                    error: ''
                }]
            },
            validate,
            onSubmit
        });

        const [saveToggle, setSaveToggle] = useState<boolean>(false);
        const [cancelToggle, setCancelToggle] = useState<boolean>(false);
        const [deleteBarcodeToggle, setDeleteBarcodeToggle] = useState<any>({show: false, deleteIndex: -1});
        const [resetBarcodesToggle, setResetBarcodesToggle] = useState<boolean>(false);

        const [validWarehouses, setValidWarehouses] = useState<string[]>([]);
        const [warehouses, setWarehouses] = useState<string[]>([]);

        const ActivateSavebutton = (formik.isValid && formik.dirty);

        setVariables(stockNumber, formik, history, warehouses, setValidWarehouses, charitableGamingStore);

        const hideErrorNotificationBanner = () => {
            charitableGamingStore.showErrorNotificationBanner('');
        }

        useEffect(() => {
            charitableGamingStore.GetAllWarehouses(setWarehouses, setValidWarehouses);
        }, []);

        return (
            <>
                {(false) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['add-new-inventory-page-container']} ${styles['']}`}
                     onClick={(e: any) => {
                         if (errorNotificationBanner.message !== "") {
                             hideErrorNotificationBanner();
                         }
                     }}
                >
                    <div className={`${styles["add-new-inventory-page-ribbon-container"]}`}>
                        <div className={styles["add-new-inventory-page-ribbon-title"]}>Transfer Inventory</div>
                        <div className={`${styles['add-new-inventory-page-ribbon-button-container']}`}>
                            <div tabIndex={0} className={styles["add-new-inventory-page-ribbon-cancel-button"]}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         setCancelToggle(true);
                                     }
                                 }}
                                 onClick={() => {
                                     setCancelToggle(true);
                                 }}
                            >Cancel
                            </div>
                            <div tabIndex={0} className={styles["add-new-inventory-page-ribbon-save-button"]}
                                 role="button"
                                 style={{
                                     opacity: ActivateSavebutton ? "1" : "0.4",
                                     cursor: ActivateSavebutton ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (ActivateSavebutton) {
                                             setSaveToggle(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (ActivateSavebutton) {
                                         setSaveToggle(true);
                                     }
                                 }}>Submit
                            </div>
                        </div>
                    </div>
                    <div className={`${styles["add-new-inventory-page-error-notification-container"]}`}>
                        {(errorNotificationBanner.message !== "") ?
                            <PageMessageCommonComponent
                                message={errorNotificationBanner.message}
                                messageType={errorNotificationBanner.messageType}/>
                            : null
                        }
                    </div>
                    <div className={`${styles["add-new-inventory-page-main-content-container"]}`}>
                        <div className={`${styles["row-1-container"]}`}>
                            <div className={`${styles["row-container-title"]}`}>Select
                                Designated Warehouse
                            </div>
                            <div className={`${styles["row-container-sub-title"]}`}>Select the designated warehouse
                                to where the inventory will be transferred to.
                            </div>
                            <div className={styles["label-and-input-box-container"]}>
                                <div className={styles["add-new-inventory-page-label"]}>Warehouse</div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select className={styles["custom_select"]}
                                            id="warehouse"
                                            name="warehouse"
                                            onChange={(e: any) => setWarehouse(e)}
                                            onBlur={formik.handleBlur}
                                    >
                                        <option value="" selected>Select...</option>
                                        {
                                            validWarehouses.map((warehouse: string) =>
                                                <option
                                                    selected={formik.values.warehouse.toLowerCase() === warehouse.toLowerCase()}
                                                    value={warehouse}>{warehouse}
                                                </option>)
                                        }

                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                                {(formik.errors.warehouse) ?
                                    <ErrorMessageFieldCommonComponent warehouse={formik.errors.warehouse}/> : null}
                            </div>

                        </div>
                        <div className={`${styles["row-2-container"]}`}>
                            <div className={`${styles["row-container-title"]}`}>Enter Barcode
                                Information
                            </div>
                            <div className={`${styles["row-container-sub-title"]}`}>Scan or
                                manually input the barcode number for each game.
                            </div>
                            <div className={styles['table']}>
                                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                                    <div></div>
                                    <div>Barcode No.*</div>
                                    <div>Stock No.</div>
                                    <div>Serial Numbers</div>
                                    <div>Quantity</div>
                                    <div>Current Warehouse</div>
                                </div>
                                <hr className={styles['horizontal-line']}/>
                                <div className={styles['table-data-body']}>
                                    {
                                        formik.values.barcodeInformation.length > 0 && formik.values.barcodeInformation.map((e: any, i: number) =>
                                            <div>
                                                <div key={i}
                                                     className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                                    <div className={styles['table-data-data-text']}>
                                                        {i + 1}.
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        <div
                                                            className={styles['barcode-input-error-message-field-container']}>
                                                            <input
                                                                tabIndex={0}
                                                                className={styles["input-box"]}
                                                                id={`barcodeInformation.${i}.barcodeNumber`}
                                                                name={`barcodeInformation.${i}.barcodeNumber`}
                                                                type="text"
                                                                onChange={(e: any) => {
                                                                    setFieldValue(e, i);
                                                                }}
                                                                onBlur={() => formik.setFieldTouched(`barcodeInformation.${i}.barcodeNumber`, true)}
                                                                value={formik.values.barcodeInformation && formik.values.barcodeInformation[i].barcodeNumber || ''}
                                                                maxLength={18}
                                                            />
                                                            {(getIn(formik.errors, `barcodeInformation-${i}-barcodeNumber`)) ?
                                                                <ErrorMessageFieldCommonComponent
                                                                    barcode={getIn(formik.errors, `barcodeInformation-${i}-barcodeNumber`)}/> : null}
                                                        </div>

                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.stockNumber}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.serialNumbers}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.quantity}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.currentWarehouse}
                                                    </div>
                                                    {(formik.values.barcodeInformation.length > 1) ?
                                                        <div tabIndex={0} className={styles['table-data-data-text']}
                                                             onKeyDown={(ev: any) => {
                                                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                                     setDeleteBarcodeToggle({
                                                                         show: true,
                                                                         deleteIndex: i
                                                                     });
                                                                 }
                                                             }}
                                                             onClick={() => {
                                                                 setDeleteBarcodeToggle({show: true, deleteIndex: i});
                                                             }}
                                                        >
                                                            <div className={styles['justify-right']}>
                                                                <RiDeleteBin6Line
                                                                    style={{cursor: "pointer"}}
                                                                />
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                <hr className={styles['horizontal-line']}/>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            <div className={styles["barcode-button-container"]}>
                                <div tabIndex={0} className={styles["add-barcode-button"]}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             formik.setFieldValue('barcodeInformation', [...formik.values.barcodeInformation, {
                                                 serialNumber: formik.values.barcodeInformation.length + 1,
                                                 barcodeNumber: '',
                                                 stockNumber: '-',
                                                 serialNumbers: '-',
                                                 quantity: '0',
                                                 currentWarehouse: '-',
                                                 error: ''
                                             }]);
                                         }
                                     }}
                                     onClick={() => {
                                         formik.setFieldValue('barcodeInformation', [...formik.values.barcodeInformation, {
                                             serialNumber: `${formik.values.barcodeInformation.length + 1}`,
                                             barcodeNumber: '',
                                             stockNumber: '-',
                                             serialNumbers: '-',
                                             quantity: '0',
                                             currentWarehouse: '-',
                                             error: ''
                                         }]);

                                     }}>
                                    <div className={styles["icon-button-label-container"]}>
                                        <FaPlus style={{cursor: "pointer"}}/>
                                        <div className={styles["add-barcode-button-label"]}>
                                            Add Barcode
                                        </div>
                                    </div>
                                </div>
                                <div tabIndex={0} className={styles["reset-all-barcodes-button"]}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             setResetBarcodesToggle(true);
                                         }
                                     }}
                                     onClick={() => {
                                         setResetBarcodesToggle(true);
                                     }}>
                                    <div className={styles["icon-button-label-container"]}>
                                        <BsArrowCounterclockwise style={{cursor: "pointer"}}/>
                                        <div className={styles["add-barcode-button-label"]}>
                                            Reset All Barcodes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {deleteBarcodeToggle.show ?
                    <DeleteBarcodeConfirmationComponent
                        YesDeleteBarcode={YesDeleteBarcode}
                        deleteBarcodeToggle={deleteBarcodeToggle}
                        setDeleteBarcodeToggle={setDeleteBarcodeToggle}>
                    </DeleteBarcodeConfirmationComponent> : null}
                {resetBarcodesToggle ?
                    <ResetAllBarcodesConfirmationComponent
                        YesResetAllBarcodes={YesResetAllBarcodes}
                        resetBarcodesToggle={resetBarcodesToggle}
                        setResetBarcodesToggle={setResetBarcodesToggle}>
                    </ResetAllBarcodesConfirmationComponent> : null}
                {saveToggle ?
                    <SaveConfirmationComponent
                        YesSave={YesSave}
                        saveToggle={saveToggle}
                        setSaveToggle={setSaveToggle}>
                    </SaveConfirmationComponent> : null}
                {cancelToggle ?
                    <CancelConfirmationComponent
                        YesCancel={YesCancel}
                        cancelToggle={cancelToggle}
                        setCancelToggle={setCancelToggle}>
                    </CancelConfirmationComponent> : null}
            </>
        );
    })
);