import React from "react";
import { Switch , withRouter } from "react-router-dom";
import { PrivateRoute } from './../../../routes';
import RoleManagementComponent from './../../../roles';
import UserManagementComponent from "./../../../users";
import AdministrationButtons from './../AdministrationButtons';
import { knoledgeRoutePath } from './../../../config.js';
import ActionAuditComponent from "./../../..//users/ActionAudit";
import SettingsHubComponent from "./../../../Admin/Settings/Hub";
import KnoledgeBasePage from './../KnoledgeBasePage';
import AuthLayoutHOC from './../../../Layout/AuthLayoutHOC.js';



 const KnoledgeRoutesPage = ({ props, storesObject }: any) => {

       const playerId:string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 2]}`;
       const claimsId:string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`;
       const urlVariables:string =  `${playerId}/${claimsId}`;
     
    
    return (
        <>            
            <Switch>
                    <PrivateRoute path={ `${knoledgeRoutePath}` } component={(props:any) => <KnoledgeBasePage title="Knowledge" {...props} showBackButton={true} />} />
                    
            </Switch>
       </>

    );
}


export  default withRouter( KnoledgeRoutesPage ) 