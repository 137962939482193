import * as React from 'react'
import { useFormik } from 'formik'
import { getBlockClass } from '../../../../../utilities/helpers'
import { LoadingDots } from '../../../../CommonComponents/loading-dots/loading-dots-ui'
import { GenericPopupModal } from '../../../../GenericPopupModal/generic-popup-modal-ui'
import { SideBarTextInput } from '../../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'
import { getFormattedPromotionID } from '../../../../../pages/PromotionsPages/promotion-campaign-builder/helpers'
import './styles.scss'

const ROOT_CLASS = 'promotion-campaign-end-campaign-ui'
const BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'button')

export type PromotionCampaignEndCampaignUIProps = {
  closeModal: () => void
  loading: boolean
  error?: string
  actionSuccessful: boolean
  onSuccess?: () => void
  endPromotion: (promotionId: string) => Promise<void>
  promotionId: string
}

export const PromotionCampaignEndCampaignUI = (
  props: PromotionCampaignEndCampaignUIProps
) => {
  React.useEffect(() => {
    if (props.actionSuccessful) {
      props.onSuccess?.()
      props.closeModal()
    }
  }, [props.actionSuccessful])

  const validateEndCampaign = (values: any) => {
    const errors = {}
    if (values.promotionId === '') {
      Object.assign(errors, { promotionId: 'This field is required' })
    } else if (!/^\d+$/.test(values.promotionId)) {
      Object.assign(errors, {
        promotionId: 'Promotion ID must only contain digits 0-9',
      })
    } else if (
      values.promotionId !== getFormattedPromotionID(props.promotionId)
    ) {
      Object.assign(errors, {
        promotionId: 'Promotion ID provided is incorrect',
      })
    }
    return errors
  }

  const initialValues = {
    promotionId: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      props.endPromotion(props.promotionId)
    },
    validate: validateEndCampaign,
  })

  return (
    <GenericPopupModal
      isOpen
      closeModal={props.closeModal}
      roundedEdges
      hideCloseButton
    >
      <form className={ROOT_CLASS} onSubmit={formik.handleSubmit} noValidate>
        <div className={getBlockClass(ROOT_CLASS, 'header')}>
          <h1>Are you sure you want end this promotion campaign?</h1>
          <h2>By ending this promotion, no entries will be collected.</h2>
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <SideBarTextInput
            label={'Enter the Promotion ID'}
            name={`promotionId`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.promotionId}
            error={formik.errors.promotionId}
            touched={formik.touched.promotionId}
            disabled={props.loading}
            placeholderText="01234"
          />
          {props.error && (
            <div className={getBlockClass(ROOT_CLASS, 'error-message')}>
              {props.error}
            </div>
          )}
        </div>
        <button
          type="submit"
          className={BUTTON_CLASS}
          aria-label="Confirm"
          disabled={!formik.dirty || !formik.isValid || props.loading}
        >
          {props.loading ? (
            <div className={`${BUTTON_CLASS}__loading`}>
              <LoadingDots id="loading" color="white" />
            </div>
          ) : (
            'Confirm'
          )}
        </button>

        <button
          className={getBlockClass(ROOT_CLASS, 'back-button')}
          onClick={props.closeModal}
          aria-label="Go Back"
          disabled={props.loading}
        >
          Go Back
        </button>
      </form>
    </GenericPopupModal>
  )
}
