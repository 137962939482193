import React from "react";
import {useHistory} from "react-router-dom";
import styles from './css/styles.module.css';
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import {keyPressedType} from "../../../../../services/Commons";
import {permitRoutePath} from "../../../../../config";

export const ViewPermitInformationComponent = ({permitHolderDetails}: any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    return (
        <>

            <div className={`${styles['view-permit-information-container']}`}>

                <div className={`${styles['view-permit-information-container-title-container']}`}>
                    <div className={`${styles['view-permit-information-container-title']}`}>Permit</div>
                    {checkRenderPermissions(permissions.CAN_UPDATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                        <div tabIndex={0} className={`${styles['view-license-information-container-update-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     history.push(`${permitRoutePath}/update/permit/${permitHolderDetails.raffleId}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${permitRoutePath}/update/permit/${permitHolderDetails.raffleId}`);
                             }}>Update
                        </div> : null}
                </div>

                <div className={`${styles['view-permit-information-controls-container']}`}>

                    <div
                        className={`${styles['view-permit-information-controls-container-column-1']} ${styles['mslc-label']}`}>Org.
                        Status
                    </div>
                    <div
                        className={`${styles['view-permit-information-controls-container-column-2']} ${styles['mslc-label']}`}>{(permitHolderDetails.orgActive) ? 'Active' : 'Expired'}</div>

                </div>

            </div>


        </>

    );
};