import { PreSignedUrlResponse } from "../../../api/finserv-general-reports/api-types"
import config from '../../../config'
import { useFetch } from "../../../hooks/use-fetch"
import { saveFromUrl } from "../../../util"
import { CheckRegisterReportDisplayRow } from "./check-register-report-page-ui"

const downloadCheckRegisterReportUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/checkRegister/reportUrl`

export const useFetchDownloadCheckRegisterReport = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<PreSignedUrlResponse>()
  const preSignedUrlHandler = async (row: CheckRegisterReportDisplayRow) => {
    const urlWithParams = `${downloadCheckRegisterReportUrl}?reportId=${row.id}`
    const preSignedUrl = await fetchUrl(urlWithParams, 'GET')
    if (!preSignedUrl) {
      return
    }
    saveFromUrl(preSignedUrl.url)
  }

  return { isLoading, error, data, preSignedUrlHandler }
}