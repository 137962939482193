import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { annuitiesRoutePath } from "../../../../../config";
import { KEY_CODES } from "../../../../../constants.js";
import { BannerContext } from "../../../../../context/BannerContext";
import { getPaymentSchedule } from "../../../../../pages/AnnuitiesPages/TPAManagement/AnnuitySubAccountsPage/functions";
import { keyPressedType } from "../../../../../services/Commons";
import { SubAccount } from "../../../../../types/Annuities/entities/sub-account";
import { PaymentScheduleEntry } from "../../../../../types/Annuities/responses/payment-schedule";
import { formatCentsToDollars } from "../../../../../util";
import styles from "./css/styles.module.css";
import { getTpaPayeeSubAccounts } from "./functions";

export const PayeeDetailsPaymentComponent: React.FC<{
  payeeId: string;
  tpaId: string;
}> = ({ payeeId, tpaId }) => {
  // routing config
  // TODO: Define a generic type for this pattern. Can be used everywhere we have a
  // LABEL: VALUE element in the UI. Maybe we shouldn't be doing this???
  type PaymentDataRow = {
    label: string;
    value: string | number;
  };
  const history = useHistory();
  const bannerContext = React.useContext(BannerContext);
  const [paymentData, setPaymentData] = useState<PaymentDataRow[]>([]);

  const handleHistory = () =>
    history.push(
      `${annuitiesRoutePath}/annuity-update-sub-accounts?tpaId=${tpaId}&payeeId=${payeeId}`
    );

  useEffect(() => {
    const fetchPayeeFinanceValues = async () => {
      // Initialize aggregate variables
      let subAccounts: SubAccount[] = [];
      let pastPayments: PaymentScheduleEntry[] = [];
      let paymentSchedule: PaymentScheduleEntry[] = [];
      let futurePayments: PaymentScheduleEntry[] = [];
      try {
        paymentSchedule = await getPaymentSchedule(tpaId);
        subAccounts = await getTpaPayeeSubAccounts(tpaId, payeeId);
        const subAccountIds = subAccounts.map((subAccount) => subAccount.id);
        pastPayments = paymentSchedule.filter(
          (entry) =>
            subAccountIds.includes(entry.subAccount.id) &&
            entry.paymentStatus === "Paid"
        );
        futurePayments = paymentSchedule.filter(
          (entry) =>
            subAccountIds.includes(entry.subAccount.id) &&
            entry.paymentStatus !== "Paid"
        );
      } catch (error) {
        const msg =
          error instanceof Error ? error.message : "An error occurred";
        bannerContext.setBannerInfo({
          message: msg,
          error: true,
        });
      }
      const grossAmountPaid = pastPayments.reduce(
        (prev, curr) => prev + curr.payment.paymentAmount,
        0
      );
      const irsWithheld = pastPayments.reduce(
        (prev, curr) => prev + curr.payment.irsWithholding,
        0
      );
      const dorWithheld = pastPayments.reduce(
        (prev, curr) => prev + curr.payment.dorsWithholding,
        0
      );
      const offsetsWithheld = pastPayments.reduce(
        (prev, curr) => prev + curr.payment.offsetsWithholding,
        0
      );
      const netAmountPaid =
        grossAmountPaid - irsWithheld - dorWithheld - offsetsWithheld;
      const remainingBalance = futurePayments.reduce(
        (prev, curr) => prev + curr.payment.paymentAmount,
        0
      );
      const data: PaymentDataRow[] = [
        {
          label: "Payments Made",
          value: pastPayments.length,
        },
        {
          label: "Payments Pending",
          value: futurePayments.length,
        },
        {
          label: "Gross Amount Paid",
          value: formatCentsToDollars(grossAmountPaid),
        },
        {
          label: "IRS Withheld",
          value: formatCentsToDollars(irsWithheld),
        },
        {
          label: "DOR Withheld",
          value: formatCentsToDollars(dorWithheld),
        },
        {
          label: "Offsets Withheld",
          value: formatCentsToDollars(offsetsWithheld),
        },
        {
          label: "Net Amount Paid",
          value: formatCentsToDollars(netAmountPaid),
        },
        {
          label: "Remaining Balance",
          value: formatCentsToDollars(remainingBalance),
        },
      ];
      setPaymentData(data);
    };

    fetchPayeeFinanceValues();
  }, []);

  return (
    <div className={styles["cmpnt-container"]}>
      <div className={`${styles.grid_master_col_2}`}>
        {/* payee info */}
        <div>
          <div className={`${styles.payee_container}`}>
            <div className={`${styles.component_title}`}>Payment Info</div>
            <div className={`${styles.subtitle_margin}`}>
              The amounts below are the aggregate totals to date.
            </div>
            <div className={`${styles.grid_col_2}`}>
              {paymentData.map((entry, index) => {
                return (
                  <div className={styles.payment_info_entry} key={index}>
                    <div>{entry.label}</div>
                    <div>{entry.value}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* edit sub account button */}
        <div className={`${styles.btn_container}`}>
          <button
            className={`${styles.light_btn}`}
            onKeyUp={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                handleHistory();
              }
            }}
            onClick={handleHistory}
          >
            Update Sub-Accounts
          </button>
        </div>
      </div>
    </div>
  );
};
