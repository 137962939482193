export interface LicenseeDetailsInterface {
    orgDetails: {
        orgFID: string,
        orgName: string,
        orgCode: string,
        orgCodeOther: string,
        orgDateChartered: string,
        orgPhone: string,
        orgAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        mailAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        headOfOrg: {
            fullName: string,
            email: string,
            address: {
                streetAddress: string,
                city: string,
                zip: string
            },
            homePhone: string,
            businessPhone: string
        }
    },
    licenseDetails: {
        licenseNumber: string,
        occasionAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        occasionDay: string,
        occasionTime: string,
        dateOfFirstLicense: string,
        expirationDate: string,
        beanoHallPhone: string,
        memberInCharge: {
            fullName: string,
            email: string,
            address: {
                streetAddress: string,
                city: string,
                zip: string
            },
            homePhone: string,
            businessPhone: string
        },
        bookkeeper: {
            fullName: string,
            email: string,
            address: {
                streetAddress: string,
                city: string,
                zip: string
            },
            homePhone: string,
            businessPhone: string
        },
        investCode: string,
        county: string,
        city: string,
        type: string,
        staffTrained: boolean,
        startDate: string,
        submitting: boolean,
        markedForRenewal: boolean,
        active: boolean
        creditBalanceInCents: number
    }
}

export const LicenseeDetailsDefaultData = {
    orgDetails: {
        orgFID: '',
        orgName: '',
        orgCode: '',
        orgCodeOther: '',
        orgDateChartered: '',
        orgPhone: '',
        orgAddress: {
            streetAddress: '',
            city: '',
            zip: ''
        },
        mailAddress: {
            streetAddress: '',
            city: '',
            zip: ''
        },
        headOfOrg: {
            fullName: '',
            email: '',
            address: {
                streetAddress: '',
                city: '',
                zip: ''
            },
            homePhone: '',
            businessPhone: ''
        }
    },
    licenseDetails: {
        licenseNumber: '',
        occasionAddress: {
            streetAddress: '',
            city: '',
            zip: ''
        },
        occasionDay: '',
        occasionTime: '',
        dateOfFirstLicense: '',
        expirationDate: '',
        beanoHallPhone: '',
        memberInCharge: {
            fullName: '',
            email: '',
            address: {
                streetAddress: '',
                city: '',
                zip: ''
            },
            homePhone: '',
            businessPhone: ''
        },
        bookkeeper: {
            fullName: '',
            email: '',
            address: {
                streetAddress: '',
                city: '',
                zip: ''
            },
            homePhone: '',
            businessPhone: ''
        },
        investCode: '',
        county: '',
        city: '',
        type: '',
        staffTrained: false,
        startDate: '',
        submitting: false,
        markedForRenewal: false,
        active: false,
        creditBalanceInCents: 0
    }
}

export interface UpdateLicenseInterface {
    orgDetails: {
        orgName: string,
        orgDateChartered: string,
        orgPhone: string,
        orgAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        mailAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        headOfOrg: {
            fullName: string,
            address: {
                streetAddress: string,
                city: string,
                zip: string
            },
            homePhone: string,
            businessPhone: string,
            email: string
        }
    },
    licenseDetails: {
        occasionAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        occasionDay: string,
        occasionTime: string,
        expirationDate: string,
        beanoHallPhone: string,
        memberInCharge: {
            fullName: string,
            address: {
                streetAddress: string,
                city: string,
                zip: string
            },
            homePhone: string,
            businessPhone: string,
            email: string
        },
        bookkeeper: {
            fullName: string,
            address: {
                streetAddress: string,
                city: string,
                zip: string
            },
            homePhone: string,
            businessPhone: string,
            email: string
        },
        investCode: string,
        county: string,
        city: string,
        type: string,
        dateOfFirstLicense: string,
        staffTrained: boolean,
        startDate: string
    }
}
