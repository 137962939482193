export interface PurchaseOrderDetailsInterface {
    purchaseOrder: {
        warehouseId: string,
        licenseNumber: string,
        orgDetails: {
            orgName: string,
            orgAddress: {
                streetAddress: string,
                city: string,
                zip: string
            }
        },
        date: string,
        shippingPriceInCents: number,
        summedProductPriceInCents: number,
        totalPricePaidInCents: number,
        shipMethod: string,
        paymentMethod: string,
        totalWeightInLbs: string,
        totalUnits: number,
        receiver: string,
        authSignature: string,
        purchaseOrderId: string,
        productDetails:
            {
                stockNumber: string,
                serialNumberStart: string,
                serialNumberEnd: string,
                unitPriceInCents: number,
                numUnits: number,
                totalProductPriceInCents: number,
                gameName: string
            }[]
    }
}

export const PurchaseOrderDetailsDefaultData = {
    purchaseOrder: {
        warehouseId: '',
        licenseNumber: '',
        orgDetails: {
            orgName: '',
            orgAddress: {
                streetAddress: '',
                city: '',
                zip: ''
            }
        },
        date: '',
        shippingPriceInCents: 0,
        summedProductPriceInCents: 0,
        totalPricePaidInCents: 0,
        shipMethod: '',
        paymentMethod: '',
        totalWeightInLbs: '',
        totalUnits: 0,
        receiver: '',
        authSignature: '',
        purchaseOrderId: '',
        productDetails: [
            {
                stockNumber: '',
                serialNumberStart: '',
                serialNumberEnd: '',
                unitPriceInCents: 0,
                numUnits: 0,
                totalProductPriceInCents: 0,
                gameName: ''
            }
        ]
    }
}
