import React from 'react'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { permissions } from '../../../constants'

import { GetFuturePaymentPayeeDetailsReponse, UpdateFuturePaymentPayeeDetailsRequest } from '../../../api/future-payment-payee-details-fetcher/api-types'

const FUTURE_PAYMENT_URL_BASE = `${config.SERVER_BASE_URL}/api/v1/payments/future`

export const useFetchGetFuturePaymentPayeeDetails = (futurePaymentId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<GetFuturePaymentPayeeDetailsReponse>()
  const fetchFuturePaymentPayeeDetails = () => {
    const urlWithId = `${FUTURE_PAYMENT_URL_BASE}/${futurePaymentId}/payeeDetails`
    return fetchUrl(urlWithId, 'GET', {})
  }
  React.useEffect(() => {
    fetchFuturePaymentPayeeDetails()
    return () => {}
  }, [])

  return { isLoading, error, data, fetchFuturePaymentPayeeDetails }
}

export const useFetchUpdateFuturePaymentPayeeDetails = (futurePaymentId: string) => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch()

  const updateFuturePaymentPayeeDetails = (update: UpdateFuturePaymentPayeeDetailsRequest) => {
    const urlWithId = `${FUTURE_PAYMENT_URL_BASE}/${futurePaymentId}/payeeDetails`
    return fetchUrl(urlWithId, 'PUT', null, JSON.stringify(update), [permissions.CAN_UPDATE_FUTURE_PAYMENT_DETAILS])
  }

  return { updateFuturePaymentPayeeDetails, isLoading, error, requestSuccessful }
}
