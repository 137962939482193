import moment, { Moment } from "moment-timezone";
import { PostPaymentRules } from "../../../../types/Annuities/TPAManagement/SubAccountManagement/PostPaymentRules";
import { SubAccountTreeNode } from "../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { TempPaymentRules } from "../../../../types/Annuities/TPAManagement/SubAccountManagement/TempPaymentRules";
import { PaymentRule } from "../../../../types/Annuities/entities/payment-rule";
import { PaymentScheduleEntry } from "../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../types/Annuities/responses/time-payment-account";

// Count number of payments in a given rule
export const countPaymentsInRule = (
  subAccountId: string,
  startDate: string | Date,
  endDate: string | Date,
  paymentRecords: PaymentScheduleEntry[],
  context: any
) => {
  let paymentCount = 0;
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);
  try {
    // Loop through each individual payment to look for matches
    paymentRecords.forEach((paymentRecord: PaymentScheduleEntry) => {
      if (subAccountId === paymentRecord.subAccount.id) {
        if (
          moment(paymentRecord.paymentDate).isBetween(
            mStartDate,
            mEndDate,
            "days",
            "[]"
          )
        ) {
          paymentCount++;
        }
      }
    });
  } catch (e) {
    context.setBannerInfo({
      message: "Failed to Calculate Payment Count.",
      error: true,
    });
  }

  return paymentCount;
};

const rulesOverlap = (
  rule1: PaymentRule | TempPaymentRules,
  rule2: PaymentRule | TempPaymentRules
) => {
  if (rule1.endDate && rule2.endDate) {
    return (
      moment(rule1.startDate) <= moment(rule2.endDate) &&
      moment(rule2.startDate) <= moment(rule1.endDate)
    );
  }
  if (!rule1.endDate && rule2.endDate) {
    return moment(rule1.startDate) <= moment(rule2.endDate);
  }
  if (rule1.endDate && !rule2.endDate) {
    return moment(rule2.startDate) <= moment(rule1.endDate);
  }
  if (!rule1.endDate && !rule2.endDate) {
    return true;
  }

  return false;
};

const getPaymentSchedule = (
  tpa: TimePaymentAccountResponse,
  throughDate: Moment
) => {
  const {
    payFrequency,
    remainingPayments,
    firstPaymentDate,
    nextPaymentDate,
    lifetimePayment,
    paymentsMade,
  } = tpa;
  const payments: Date[] = [];
  const duration = {
    year: 0,
    month: 0,
    week: 0,
  };
  const durationKey =
    payFrequency === "Weekly"
      ? "week"
      : payFrequency === "Monthly"
      ? "month"
      : "year";

  let paymentDate = moment(nextPaymentDate);
  let totalPayments = paymentsMade + remainingPayments;
  if (moment(firstPaymentDate) !== moment(nextPaymentDate)) {
    totalPayments--;
    payments.push(moment(firstPaymentDate).toDate());
  }
  let paymentNum = 0;
  while (
    (lifetimePayment && paymentDate.isSameOrBefore(moment(throughDate))) ||
    paymentNum < totalPayments
  ) {
    duration[durationKey] = paymentNum;
    paymentDate = moment(nextPaymentDate).add(duration);
    payments.push(paymentDate.toDate());
    paymentNum++;
  }
  return payments;
};

// validation Payment Rules
export const validatePaymentRule = (
  parentTreeNode: SubAccountTreeNode,
  tpaDetails: TimePaymentAccountResponse,
  newPaymentRules: TempPaymentRules[],
  existingPaymentRules: PaymentRule[]
) => {
  //Find earliest Start Date and Latest End Date of Parent Node
  const lifetimeParent = parentTreeNode.paymentRules.find(
    (rule) => !rule.endDate
  );
  const parentStartDate = moment
    .min(parentTreeNode.paymentRules.map((rule) => moment(rule.startDate)))
    .toDate();
  const parentEndDate = moment
    .max(
      parentTreeNode.paymentRules
        .filter((rule) => rule.endDate)
        .map((rule) => moment(rule.endDate))
    )
    .toDate();
  const latestEndDateToVerify = moment.max(
    ...newPaymentRules.map((rule) => moment(rule.endDate)),
    ...existingPaymentRules.map((rule) => moment(rule.endDate))
  );
  // get all dates for payment schedule
  const paymentSchedule: Date[] = getPaymentSchedule(
    tpaDetails,
    latestEndDateToVerify
  );

  // Hash Map to hold Payment Rule ID and Payment Schedule dates that overlap the current Rule Date
  const paymentDatesByRuleId: Map<string, Date[]> = new Map<string, Date[]>();

  // this allows us to grab all the dates by ID
  for (const rule of parentTreeNode.paymentRules) {
    paymentDatesByRuleId.set(
      rule.id,
      paymentSchedule.filter(
        (x) =>
          moment(x) >= moment(rule.startDate) &&
          (!rule.endDate || moment(x) <= moment(rule.endDate))
      )
    );
  }

  // existing payment rule validation
  for (const rule of existingPaymentRules) {
    if (isNaN(rule.amount)) {
      throw new Error("Amount is not a valid number");
    }

    // First validate that the rules is defined within the confines of the parent start/end
    if (moment(rule.startDate).isBefore(parentStartDate)) {
      throw new Error(
        `Rule must start after Parent Start Date of ${moment(
          parentStartDate
        ).format("MM/DD/YYYY")}`
      );
    }

    if (!lifetimeParent && moment(rule.endDate).isAfter(parentEndDate)) {
      throw new Error(
        `Rule must end before Parent End Date of ${moment(parentEndDate).format(
          "MM/DD/YYYY"
        )}`
      );
    }

    const overlappingParentRules = parentTreeNode.paymentRules.filter(
      (parent) => rulesOverlap(parent, rule)
    );
    const overlappingSiblingRules = parentTreeNode.subAccountChildren.map(
      (child) =>
        child.paymentRules.filter((childRule) => rulesOverlap(childRule, rule))
    );

    let siblingMaximumPaymentRules: PaymentRule[] = [];

    if (!overlappingSiblingRules.length || !overlappingSiblingRules[0].length) {
      siblingMaximumPaymentRules = [];
    } else {
      overlappingSiblingRules.forEach((siblingRules) => {
        const maximumRuleFound = siblingRules.reduce((prev, curr) => {
          return curr.amount > prev.amount ? curr : prev;
        }, siblingRules[0]);
        if (maximumRuleFound !== undefined) {
          siblingMaximumPaymentRules.push(maximumRuleFound);
        }
      });
    }

    validatePaymentRulePaymentDates(rule, paymentSchedule);
    validateExistingPaymentRuleDates(
      rule,
      existingPaymentRules,
      newPaymentRules
    );

    // amount validation
    validatePaymentRuleAmount(
      overlappingParentRules,
      siblingMaximumPaymentRules,
      rule
    );
  }

  // new payment rules validation
  for (const rule of newPaymentRules) {
    if (isNaN(rule.amount)) {
      throw new Error("Amount is not a valid number");
    }

    // First validate that the rules is defined within the confines of the parent start/end
    if (moment(rule.startDate).isBefore(parentStartDate)) {
      throw new Error(
        `Rule must start after Parent Start Date of ${moment(
          parentStartDate
        ).format("MM/DD/YYYY")}`
      );
    }

    if (!lifetimeParent && moment(rule.endDate).isAfter(parentEndDate)) {
      throw new Error(
        `Rule must end before Parent End Date of ${moment(parentEndDate).format(
          "MM/DD/YYYY"
        )}`
      );
    }

    const overlappingParentRules = parentTreeNode.paymentRules.filter(
      (parent) => rulesOverlap(parent, rule)
    );
    const overlappingSiblingRules = parentTreeNode.subAccountChildren.map(
      (child) =>
        child.paymentRules.filter((childRule) => rulesOverlap(childRule, rule))
    );

    let siblingMaximumPaymentRules: PaymentRule[] = [];

    if (!overlappingSiblingRules.length || !overlappingSiblingRules[0].length) {
      siblingMaximumPaymentRules = [];
    } else {
      overlappingSiblingRules.forEach((siblingRules) => {
        const maximumRuleFound = siblingRules.reduce((prev, curr) => {
          return curr.amount > prev.amount ? curr : prev;
        }, siblingRules[0]);
        if (maximumRuleFound !== undefined) {
          siblingMaximumPaymentRules.push(maximumRuleFound);
        }
      });
    }
    validatePaymentRulePaymentDates(rule, paymentSchedule);
    validateNewPaymentRuleDates(
      overlappingParentRules,
      rule,
      newPaymentRules,
      existingPaymentRules,
      paymentDatesByRuleId,
      paymentSchedule
    );
    // amount validation
    validatePaymentRuleAmount(
      overlappingParentRules,
      siblingMaximumPaymentRules,
      rule
    );
  }
};

const validatePaymentRuleAmount = (
  overlappingParentRules: PaymentRule[],
  siblingMaximumPaymentRules: PaymentRule[],
  rule: PaymentRule | TempPaymentRules
) => {
  // type guard
  const isTempPaymentRules = (
    x: PaymentRule | TempPaymentRules
  ): x is TempPaymentRules => {
    return Boolean((x as TempPaymentRules).temporaryId);
  };

  const minimumAmountFromParent = overlappingParentRules.reduce(
    (prev, curr) => (curr.amount < prev ? curr.amount : prev),
    overlappingParentRules[0].amount
  );

  // This is to remove the the payment rule from the existing payment rule so it does not add to the overall Max Sibling amount
  let filteredSiblingMaximumPaymentRules;
  if (isTempPaymentRules(rule)) {
    filteredSiblingMaximumPaymentRules = siblingMaximumPaymentRules;
  } else {
    filteredSiblingMaximumPaymentRules = siblingMaximumPaymentRules.filter(
      (x) => x.id !== rule.id
    );
  }

  const maximumAmountGivenToSiblings =
    filteredSiblingMaximumPaymentRules.reduce(
      (prev, curr) => prev + curr.amount,
      0
    );

  if (minimumAmountFromParent - maximumAmountGivenToSiblings < rule.amount) {
    throw new Error("Rule is over-funded");
  }
};

const validatePaymentRulePaymentDates = (
  rule: PaymentRule | TempPaymentRules,
  schedule: Date[]
) => {
  const paymentsWithinRule = schedule.filter(
    (x) =>
      moment(x).isSameOrAfter(moment(rule.startDate)) &&
      moment(x).isSameOrBefore(moment(rule.endDate))
  );
  if (!paymentsWithinRule.length) {
    throw new Error("No payments within rule");
  }
};

const validateNewPaymentRuleDates = (
  overlappingParentRules: PaymentRule[],
  rule: TempPaymentRules,
  newPaymentRules: TempPaymentRules[],
  existingPaymentRules: PaymentRule[],
  paymentDatesByRuleId: Map<string, Date[]>,
  paymentSchedule: Date[]
) => {
  // Find the parent rules that overlap with this rule.

  const overlappingNewSubAccountRules: TempPaymentRules[] =
    newPaymentRules.filter(
      (subAccountSibling) =>
        rulesOverlap(subAccountSibling, rule) &&
        subAccountSibling.temporaryId !== (rule as TempPaymentRules).temporaryId
    );
  const overlappingExistingSubAccountRules: PaymentRule[] =
    existingPaymentRules.filter((subAccountSibling) =>
      rulesOverlap(subAccountSibling, rule)
    );

  //check if rules within a sub account overlap with new rules OR existing rules
  if (
    overlappingNewSubAccountRules.length > 0 ||
    overlappingExistingSubAccountRules.length > 0
  ) {
    throw new Error("Payment rules within a single sub-account cannot overlap");
  }

  const emptyDateArray: Date[] = [];
  const parentPaymentDates = overlappingParentRules.reduce((prev, curr) => {
    const dates = paymentDatesByRuleId.get(curr.id) ?? [];

    return [...prev, ...dates];
  }, emptyDateArray);
  const paymentDatesForCurrentRule = paymentSchedule.filter(
    (x) =>
      moment(x).isSameOrAfter(rule.startDate) &&
      moment(x).isSameOrBefore(rule.endDate)
  );

  const paymentDatesNotCovered = paymentDatesForCurrentRule.filter(
    (x) => !parentPaymentDates.includes(x)
  );
  if (paymentDatesNotCovered.length) {
    throw new Error("Rule attempts to pay a payment not covered by parent");
  }
};

const validateExistingPaymentRuleDates = (
  rule: PaymentRule,
  existingPaymentRules: PaymentRule[],
  newPaymentRules: TempPaymentRules[]
) => {
  const overlappingNewSubAccountRules: TempPaymentRules[] =
    newPaymentRules.filter((subAccountSibling) =>
      rulesOverlap(subAccountSibling, rule)
    );
  const overlappingExistingSubAccountRules: PaymentRule[] =
    existingPaymentRules.filter(
      (subAccountSibling) =>
        rulesOverlap(subAccountSibling, rule) &&
        subAccountSibling.id !== rule.id
    );

  //check if rules within a sub account overlap
  if (
    overlappingExistingSubAccountRules.length > 0 ||
    overlappingNewSubAccountRules.length > 0
  ) {
    throw new Error("Payment rules within a single sub-account cannot overlap");
  }
};

export const convertPaymentRules = (
  paymentRuleProps: TempPaymentRules[],
  subAccountId: string
) => {
  let newPaymentRules: PostPaymentRules[] = paymentRuleProps.map(
    (ruleProps) => {
      const { temporaryId, ...paymentRule } = ruleProps;
      return {
        ...paymentRule,
        subAccountId,
      };
    }
  );

  return newPaymentRules;
};
