import { CircularProgress } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { annuitiesRoutePath } from '../../../../config.js'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { TimePaymentAccountPayee } from '../../../../types/Annuities/PayeeManagement/Payee'
import { ShortPaginationCommonComponent } from '../../../CommonComponents/ShortPaginationCommonComponent'
import { AccountStatusCmpt } from '../../Common/AccountStatusCmpt'
import styles from './css/style.module.css'
import { getPageData } from './functions'

export const AnnuityManagementPayeeListComponent = inject('annuitiesStore')(
  observer(({ currentPage, setCurrentPage, tpaID, annuitiesStore }: any) => {
    const history = useHistory()

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false)

    const bannerContext = React.useContext(BannerContext)

    useEffect(() => {
      const fetchPayeeList = async () => {
        try {
          annuitiesStore.populateAnnuityAccountPayeeList(tpaID)
          setRequestCompleted(true)
        } catch (error) {
          setRequestCompleted(true)
          bannerContext.setBannerInfo({ message: `${error}`, error: true })
        }
      }

      fetchPayeeList()
    }, [])

    return (
      <>
        <div className={styles['table']}>
          {/* Circular Progress initial Load screen */}
          {annuitiesStore.annuityAccountPayeeList.length === 0 &&
            requestCompleted === false && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 50,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  color: 'white',
                }}
              >
                <CircularProgress size="8em" color="inherit" thickness={2} />
              </div>
            )}
          <div
            className={`${styles['table-data-grid-header']} ${styles['table-data-label']}`}
          >
            <div>Name</div>
            <div>Date Created</div>
            <div>TIN</div>
            <div>Status</div>
            <div></div>
          </div>

          <div className={styles['table-data-body']}>
            {annuitiesStore.annuityAccountPayeeList.length > 0 &&
              getPageData(
                annuitiesStore.annuityAccountPayeeList,
                currentPage
              ).map((annuitant: TimePaymentAccountPayee | any, i: number) => (
                <div key={i} className={`${styles['table-data-grid-body']}`}>
                  <div className={styles['table-data-text']}>
                    {annuitant.payeeType === 'Individual'
                      ? `${annuitant.firstName} ${annuitant.lastName}`
                      : annuitant.freeformName}
                    {annuitant.isPrimaryPayee && (
                      <FaTrophy className="ml-2 mb-1" />
                    )}
                  </div>
                  <div className={styles['table-data-text']}>
                    {moment(annuitant.dateCreated).format('MM/DD/YYYY')}
                  </div>
                  <div>
                    <div>{annuitant.tin}</div>
                  </div>
                  <AccountStatusCmpt status={annuitant.acctStatus} />
                  <button
                    type="button"
                    className={styles['annuity-details-btn']}
                    onKeyPress={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        history.push(
                          `${annuitiesRoutePath}/payee-details?payeeId=${annuitant.id}&tpaId=${tpaID}`
                        )
                      }
                    }}
                    onClick={() => {
                      history.push(
                        `${annuitiesRoutePath}/payee-details?payeeId=${annuitant.id}&tpaId=${tpaID}`
                      )
                    }}
                  >
                    View Payee
                  </button>
                </div>
              ))}
          </div>
          <div className={styles['table-data-footer']}>
            <div aria-live="assertive" className={styles['table-page-label']}>
              {`Page ${currentPage + 1} of ${Math.ceil(
                annuitiesStore.annuityAccountPayeeList.length / 5
              ).toString()}`}
            </div>
            {
              <ShortPaginationCommonComponent
                playerData={annuitiesStore.annuityAccountPayeeList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            }
          </div>
        </div>
      </>
    )
  })
)
