import React from "react";
import {Switch, withRouter} from "react-router-dom";
import {PrivateRoute} from './../../../routes';
import {ticketSearchRoutePath} from '../../../config.js';
import TicketSearchPage from './../TicketSearchPage/index';


const TicketSearchRoutesPage = ({props, storesObject}: any) => {
    const playerId: string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 2]}`;
    const claimsId: string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`;
    return (
        <>
            <Switch>
                <PrivateRoute path={`${ticketSearchRoutePath}`}
                              component={(props: any) => <TicketSearchPage
                                  storesObject={storesObject}
                                  title="Ticket Search" {...props}
                                  showBackButton={true}/>}/>
            </Switch>
        </>
    );
}
export default withRouter(TicketSearchRoutesPage)