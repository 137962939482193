import moment from "moment-timezone";

export const getPageData = (KYCPlayerData:any[], page:number = 0) =>{
    
    const array:any= []; 
   for(let i = page * 10; i < ((page * 10) + 10); i++)
   {
         if(!(KYCPlayerData[i] === undefined))
              array.push(KYCPlayerData[i]);  
   }
   return array; 
}

export const generateLink = (claimid:string, playerid:string) => {
    return claimid;
}

export const convertAmount = (amount:number) => {
    if (amount == 0){
        return `$ 0.00`;
    }
    return `$ ${[amount.toString().slice(0, amount.toString().length - 2), '.', amount.toString().slice(amount.toString().length - 2)].join('')}`;
}

export const downloadClicked = (data:any[], csvExport:any) => {
    if(data.length > 0){
        const rows:any[] = Object.values(data).map(d => {
            let tempDate:string = moment.tz(d.eventTimestamp, "America/New_York").format('MM/DD/YYYY h:mm:ss a');
            let tempActivity:string = d.authEvent;
            let tempDevice:string = d.clientOs;
            
            d = {
                date: tempDate,
                activity: tempActivity,
                device: tempDevice
            }

            return d;
        });

        csvExport.generateCsv(rows);
    }
    return true;
}

export const normilizeDate = (date:string)=>{
    
    let dateArray = date.split('Z');
    dateArray = dateArray[0].split('T');
    date = dateArray[0].split("-").join('/');
    let time = dateArray[dateArray.length -1].split('.');

    return `${date} ${time[0]}`;


}
