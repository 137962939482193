import React from "react";
import styles from "./css/styles.module.css";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";

export const UpdateEFTOrganizationBankInfoComponent = ({formik, setFieldValue}: any) => {

    return (
        <>

            <div className={`${styles['org-bank-info-container']}`}>
                <div className={`${styles['org-bank-info-container-title']}`}>Bank
                    Information
                </div>
                <div className={`${styles['org-bank-info-controls-container']}`}>
                    <div className={`${styles['org-bank-info-controls-container-col-1']}`}>
                        <div className={`${styles['org-bank-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Bank Name*</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="bankName"
                                   name="bankName"
                                   value={formik.values.bankName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}>
                            </input>
                            {(formik.touched.bankName && formik.errors.bankName) ?
                                <ErrorMessageFieldCommonComponent
                                    bankName={formik.errors.bankName}/> : null}
                        </div>
                        <div className={`${styles['org-bank-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Bank Transit Number*</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="routingNumber"
                                   name="routingNumber"
                                   value={formik.values.routingNumber}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}
                                   maxLength={9}
                            >
                            </input>
                            {(formik.touched.routingNumber && formik.errors.routingNumber) ?
                                <ErrorMessageFieldCommonComponent
                                    routingNumber={formik.errors.routingNumber}/> : null}
                        </div>
                        <div className={`${styles['org-bank-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Name as shown on Account*</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="nameOnAccount"
                                   name="nameOnAccount"
                                   value={formik.values.nameOnAccount}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}>
                            </input>
                            {(formik.touched.nameOnAccount && formik.errors.nameOnAccount) ?
                                <ErrorMessageFieldCommonComponent
                                    nameOnAccount={formik.errors.nameOnAccount}/> : null}
                        </div>
                    </div>
                    <div className={`${styles['org-bank-info-controls-container-col-2']}`}>
                        <div className={`${styles['org-bank-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Account Number*</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="accountNumber"
                                   name="accountNumber"
                                   value={formik.values.accountNumber}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}>
                            </input>
                            {(formik.touched.accountNumber && formik.errors.accountNumber) ?
                                <ErrorMessageFieldCommonComponent
                                    accountNumber={formik.errors.accountNumber}/> : null}
                        </div>
                        <div className={`${styles['org-bank-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Account Type*</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   id="accountType"
                                   name="accountType"
                                   type="text"
                                   value={formik.values.accountType}
                                   readOnly={true}>
                            </input>
                            {/*{(formik.touched.accountType && formik.errors.accountType) ?
                                <ErrorMessageFieldCommonComponent
                                    errorMessages={formik.errors.accountType}/> : null}*/}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};