import * as React from 'react'
import {
  PlayslipsStepDownSidebarUI,
  PlayslipsStepDownSidebarUIProps,
} from './playslips-step-down-sidebar-ui'
import { useFetchAllActivePlayslipsGames } from '../../../api/playslips-fetcher/use-fetch-playslips'

export type PlayslipsStepDownSidebarContainerProps = Pick<
  PlayslipsStepDownSidebarUIProps,
  'isOpen' | 'closeSidebar'
>

export const PlayslipsStepDownSidebarContainer = (
  props: PlayslipsStepDownSidebarContainerProps
) => {
  const { data, isLoading, error } = useFetchAllActivePlayslipsGames(true)
  return (
    <PlayslipsStepDownSidebarUI
      {...props}
      activeGamesLoading={isLoading}
      activeGamesError={error}
      activeGames={data}
    />
  )
}
