export enum CheckRunReportStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
}

export enum CheckRunReportDisplayOptions {
  ALL_PAYMENTS = 'Preview - All Payments',
  HOLD_PAYMENTS = 'Preview - Hold Payments',
  NO_HOLD = 'Preview - No Hold',
  RELEASED_HOLDS = 'Preview - Released Holds',
  CHECK_RUN_REPORT = 'Check Run Report',
  REISSUE_CHECK_RUN_REPORT = 'Re-Issue Check Run Report',
  UNKNOWN = 'Unknown Report Type',
}

export enum CheckRunReportType {
  PREVIEW_ON_HOLD_PAYMENTS_ONLY = 'PREVIEW_ON_HOLD_PAYMENTS_ONLY',
  PREVIEW_PENDING_PAYMENTS_ONLY = 'PREVIEW_PENDING_PAYMENTS_ONLY',
  PREVIEW_ALL_PAYMENTS = 'PREVIEW_ALL_PAYMENTS',
  PREVIEW_RELEASED_PAYMENTS_ONLY = 'PREVIEW_RELEASED_PAYMENTS_ONLY',
  CHECK_RUN_REPORT = 'CHECK_RUN_REPORT',
  REISSUE_CHECK_RUN_REPORT = 'REISSUE_CHECK_RUN_REPORT'
}

export type ListCheckRunReportsResponse = {
  checkRunReports: CheckRunReportListEntry[]
}

export type CheckRunReportListEntry = {
  id: string
  dateGenerated: string
  generatedBy: string
  status: CheckRunReportStatus
  reportType: CheckRunReportType
}

export type PreSignedUrlResponse = {
  url: string
}

export type PreviewReportProps = {
  reportName?: CheckRunReportType
  generatedBy: string
}
