import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import filterExpandIcon from "../../../../../icons/Icon_arrows_carrot_teal-down.svg";
import filterCollapseIcon from "../../../../../icons/Icon_arrows_carrot_teal-up.svg";
import moment from "moment";
import {inject, observer} from "mobx-react";

export const PullTabOrderOverviewHistorySearchBarComponent = inject("charitableGamingStore")(observer(({formik, setFieldValue, clearSearch, charitableGamingStore}: any) => {

        const [warehouses, setWarehouses] = useState<string[]>([]);
        const [showFilters, setShowFilters] = useState<boolean>(false);

        /*const activateSearchButton = ((formik.values.startOrderDate !== '' &&
            formik.values.endOrderDate !== '') ||
            formik.values.purchaseOrderId !== '' ||
            formik.values.orgName !== '' ||
            formik.values.licenseNumber !== '' &&
            Object.keys(formik.errors).length === 0);*/

        const activateSearchButton = (formik.isValid && formik.dirty);

        useEffect(() => {
            charitableGamingStore.GetAllWarehouses(setWarehouses);
        }, []);

        return (
            <>

                <div className={`${styles['pulltab-stock-inventory-page-search-bar-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-stock-inventory-page-search-bar-title']} ${styles['']}`}>Search
                    </div>
                    <div className={`${styles['pulltab-stock-inventory-page-search-bar-filter-container']} ${styles['']}`}>

                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title-bar']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title']} ${styles['']}`}>Filter
                            </div>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}>
                                <img
                                    tabIndex={0}
                                    src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                    className={styles[""]}
                                    alt="filter expander icon"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onKeyDown={(e: any) => {
                                        if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                            setShowFilters(!showFilters);
                                        }
                                    }}
                                    onClick={() => {
                                        setShowFilters(!showFilters);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {showFilters ?
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-expanded-container']} ${styles['']}`}>

                            <div
                                className={`${styles['order-item-history-search-page-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>Filter by Warehouse
                                </div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select className={styles["custom_select"]}
                                            id="warehouse"
                                            name="warehouse"
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                                formik.submitForm();
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder="Select"
                                    >
                                        <option value="All" selected>All</option>
                                        {
                                            warehouses.map((warehouse: string) =>
                                                <option
                                                    selected={formik.values.warehouse.toLowerCase() === warehouse.toLowerCase()}
                                                    value={warehouse}>{warehouse}
                                                </option>)
                                        }
                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                            </div>
                            <div className={`${styles['pulltab-stock-inventory-page-datetime-picker-container']}`}>
                                <div
                                    className={`${styles['order-item-history-search-page-label-textbox-container']} ${styles['']}`}>
                                    <div className={`${styles['mslc-label']} ${styles['']}`}>Start Order Date
                                    </div>
                                    <input
                                        className={`${styles['mslc-datetime-picker']}`}
                                        type={'date'}
                                        id="startOrderDate"
                                        name="startOrderDate"
                                        value={formik.values.startOrderDate}
                                        onBlur={formik.handleBlur}
                                        onChange={(e: any) => {
                                            setFieldValue(e, formik);
                                        }}
                                        max={moment().format('YYYY-MM-DD')}
                                        placeholder={'mm/dd/yyyy'}
                                    />
                                    {formik.errors.startOrderDate ?
                                        <ErrorMessageFieldCommonComponent
                                            errorMessages={formik.errors.startOrderDate}/> : null}
                                </div>
                                <div
                                    className={`${styles['order-item-history-search-page-label-textbox-container']} ${styles['']}`}>
                                    <div className={`${styles['mslc-label']} ${styles['']}`}>End Order Date
                                    </div>
                                    <input
                                        className={`${styles['mslc-datetime-picker']}`}
                                        type={'date'}
                                        id="endOrderDate"
                                        name="endOrderDate"
                                        value={formik.values.endOrderDate}
                                        onBlur={formik.handleBlur}
                                        onChange={(e: any) => {
                                            setFieldValue(e, formik);
                                        }}
                                        min={formik.values.startOrderDate}
                                        max={moment().format('YYYY-MM-DD')}
                                        placeholder={'mm/dd/yyyy'}
                                        disabled={!formik.values.startOrderDate}
                                    />
                                    {formik.errors.endOrderDate ?
                                        <ErrorMessageFieldCommonComponent
                                            errorMessages={formik.errors.endOrderDate}/> : null}
                                </div>
                            </div>
                        </div> : null}

                    <div
                        className={`${styles['pulltab-stock-inventory-page-search-bar-input-button-container']} ${styles['']}`}>
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Purchase Order Number</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="purchaseOrderId"
                                   name="purchaseOrderId"
                                   value={formik.values.purchaseOrderId}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.purchaseOrderId ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.purchaseOrderId}/> : null}
                        </div>
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Name of Organization</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="orgName"
                                   name="orgName"
                                   value={formik.values.orgName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.orgName ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.orgName}/> : null}
                        </div>
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>License Number</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="licenseNumber"
                                   name="licenseNumber"
                                   value={formik.values.licenseNumber}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.licenseNumber && formik.errors.licenseNumber ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.licenseNumber}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>
                </div>

            </>
        );
    })
);