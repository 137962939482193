import { claim, achAccountTransactionInterface, checkMailingAddressInterface, claimHistoryInterface, ClaimReferenceNumberInterface, claimTransactionInterface, currStateInterface, detailsTransactionInterface,  lineItemsInterface,  lineItemsTransactionInterface,  prevStatesInterface, w2gInterface } from "../../../pages/ClaimsPages/MobileClaimDetailsPage/interfaces";


const currState:currStateInterface = {

    referenceNumber:"",
    playerId:"",
    playerFirstName:"",
    playerLastName:"",
    serialNumber:"",
    barcode:"",
    prizeAmountInCents:"",
    ticketValidationRequest:"",
    status:"",
    validationAttemptTimesstamp:"",
    gameName:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    zip5:"",
    activeFrom:"",
    activeTo:"",
    achAccountType:"",
    achAccountName:"",


}

const  _prevStates:prevStatesInterface = {

    referenceNumber:"",
    playerId:"",
    playerFirstName:"",
    playerLastName:"",
    serialName:"",
    barcode:"",
    prizeAmountInCents:"",
    ticketValidationRequest:"",
    status:"",
    validationAttemptTimestamp:"",
    gameName:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    zip5:"",
    activeFrom:"",
    activeTostring:"",
    achAccountNumber:"",
    achRoutingNumber:"",
    achAccountType:"",
    achAccountName:""


};

const prevStates:prevStatesInterface[] = [{..._prevStates}];

const _lineItems: lineItemsInterface ={

    id:"",
    claimReferenceNumber:"",
    type:"",
    amountInCents:""

}

const lineItems:lineItemsInterface[] = [{ ..._lineItems}]

const _claim:claim = {
    currState,
    prevStates,
    lineItems,
    netPaymentAmountInCents:0,
    eSignatureUrl:""

}

const claimReferenceNumber:ClaimReferenceNumberInterface = {

    claim:_claim

}



const _claimHistory:claimHistoryInterface = {

    referenceNumber: "",
    playerId: "",
    playerFirstName: "",
    playerLastName: "",
    serialNumber: "",
    barcode: "",
    prizeAmountInCents: "",
    ticketValidationRequest: "",
    status: "",
    validationAttemptTimestamp: "",
    gameName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip5: "",
    activeFrom: "",
    activeTo: ""

}
const claimHistory:claimHistoryInterface[]=[];


const details:detailsTransactionInterface = {

    createdAt: "",
    type: "",
    amountInCents: "",
    paymentMethod: "",

}

const achAccount:achAccountTransactionInterface = {

    achAccountNumber: "",
    achRoutingNumber: "",
    achAccountType: "",

}
 const checkMailingAddress:checkMailingAddressInterface = {

     address1: "",
     address2: "",
     city: "",
     state: "",
     zip5: ""

 }

 const _lineItemsTransactionInterface:lineItemsTransactionInterface = {

        type: "",
        amountInCents: ""
       
 }

 const lineItemsTransaction:lineItemsTransactionInterface[] =[]

 const  _claimTransaction:claimTransactionInterface = { 
    transactionId:"",
    status:"",
    details,
    achAccount,
    checkMailingAddress,
    lineItems:lineItemsTransaction
 }

const  claimTransaction:claimTransactionInterface[] = [];

const w2g:w2gInterface ={
    binary:"",
}

export { claimReferenceNumber , claimHistory , claimTransaction , w2g };