import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import filterExpandIcon from "../../../../../icons/Icon_arrows_carrot_teal-down.svg";
import filterCollapseIcon from "../../../../../icons/Icon_arrows_carrot_teal-up.svg";
import moment from "moment";
import {CheckboxComponent} from "../../../../CommonComponents/CheckboxComponent";
import {inject, observer} from "mobx-react";

export const PullTabStockInventorySearchBarComponent = inject("charitableGamingStore")(observer(({formik, setFieldValue, clearSearch, handleCheckboxChange, charitableGamingStore}: any) => {

        const [warehouses, setWarehouses] = useState<string[]>([]);
        const [showFilters, setShowFilters] = useState<boolean>(false);

        const activateSearchButton = ((formik.values.startDateRecorded !== '' &&
            formik.values.endDateRecorded !== '') ||
            formik.values.serialNumber !== '' &&
            Object.keys(formik.errors).length === 0);

        useEffect(() => {
            charitableGamingStore.GetAllWarehouses(setWarehouses);
        }, []);

        return (
            <>

                <div className={`${styles['pulltab-stock-inventory-page-search-bar-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-stock-inventory-page-search-bar-title']} ${styles['']}`}>Search
                    </div>
                    <div className={`${styles['pulltab-stock-inventory-page-search-bar-filter-container']} ${styles['']}`}>

                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title-bar']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title']} ${styles['']}`}>Filter
                            </div>
                            <div tabIndex={0}
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                        setShowFilters(!showFilters);
                                    }
                                }}
                                onClick={() => {
                                    setShowFilters(!showFilters);
                                }}
                            >
                                <img
                                    src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                    className={styles[""]}
                                    alt="filter expander icon"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {showFilters ?
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-filter-expanded-container']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-inner-container-1']}`}>
                                <div
                                    className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                    <div className={`${styles['mslc-label']} ${styles['']}`}>Filter by Warehouse</div>
                                    <div className={`${styles.custom_select_div}`}>
                                        <select
                                            id="warehouse"
                                            name="warehouse"
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                                formik.submitForm();
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder="Select"
                                            className={styles["custom_select"]}>
                                            <option value="All" selected>All</option>
                                            {
                                                warehouses.map((warehouse: string) =>
                                                    <option
                                                        selected={formik.values.warehouse.toLowerCase() === warehouse.toLowerCase()}
                                                        value={warehouse}>{warehouse}
                                                    </option>)
                                            }
                                        </select>
                                        <span className={`${styles.custom_arrow}`}></span>
                                    </div>
                                </div>
                                <div className={`${styles['pulltab-stock-inventory-page-datetime-picker-container']}`}>
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                        <div className={`${styles['mslc-label']} ${styles['']}`}>Start Date Recorded
                                        </div>
                                        <input
                                            className={`${styles['mslc-datetime-picker']}`}
                                            type={'date'}
                                            id="startDateRecorded"
                                            name="startDateRecorded"
                                            value={formik.values.startDateRecorded}
                                            onBlur={formik.handleBlur}
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                            }}
                                            max={moment().format('YYYY-MM-DD')}
                                            placeholder={'mm/dd/yyyy'}
                                        />
                                        {formik.errors.startDateRecorded ?
                                            <ErrorMessageFieldCommonComponent
                                                errorMessages={formik.errors.startDateRecorded}/> : null}
                                    </div>
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                        <div className={`${styles['mslc-label']} ${styles['']}`}>End Date Recorded</div>
                                        <input
                                            className={`${styles['mslc-datetime-picker']}`}
                                            type={'date'}
                                            id="endDateRecorded"
                                            name="endDateRecorded"
                                            value={formik.values.endDateRecorded}
                                            onBlur={formik.handleBlur}
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                            }}
                                            max={moment().format('YYYY-MM-DD')}
                                            placeholder={'mm/dd/yyyy'}
                                            disabled={!formik.values.startDateRecorded}
                                        />
                                        {formik.errors.endDateRecorded ?
                                            <ErrorMessageFieldCommonComponent
                                                errorMessages={formik.errors.endDateRecorded}/> : null}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles['pulltab-stock-inventory-page-search-bar-filter-inner-container-2']}`}>
                                <div
                                    className={`${styles['pulltab-stock-inventory-page-checkbox-container']} ${styles['']}`}>
                                    <div tabIndex={0}
                                         className={`${styles['pulltab-stock-inventory-page-checkbox']} ${styles['']}`}
                                         style={{
                                             cursor: "pointer"
                                         }}
                                         onKeyDown={(e: any) => {
                                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                                 handleCheckboxChange('showSoldOnly', formik);
                                                 formik.submitForm();
                                             }
                                         }}
                                         onClick={(e: any) => {
                                             handleCheckboxChange('showSoldOnly', formik);
                                             formik.submitForm();
                                         }}
                                    >
                                        <CheckboxComponent isChecked={formik.values.showSoldOnly}/>
                                    </div>
                                    <div
                                        className={`${styles['pulltab-stock-inventory-page-search-bar-filter-active']} ${styles['']}`}>Show
                                        Sold Only
                                    </div>
                                </div>
                            </div>
                        </div> : null}

                    <div
                        className={`${styles['pulltab-stock-inventory-page-search-bar-input-button-container']} ${styles['']}`}>
                        <div
                            className={`${styles['pulltab-stock-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Serial Number</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="serialNumber"
                                   name="serialNumber"
                                   value={formik.values.serialNumber}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.serialNumber ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.serialNumber}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>
                </div>

            </>
        );
    })
);