import React from 'react'
import config from '../../config'
import { permissions } from '../../constants.js'
import { useFetch } from '../../hooks/use-fetch'
import {
  ConsolidatedScanToEnterPromotion,
  ScanToEnterProgressResponse,
  ScanToEnterPromotionProgress,
  ScanToEnterRuleProgressEvent,
} from './constants'
import { PromotionEntriesPermissions } from './permissions'

const scanToEnterBaseUrl = `${config.SERVER_BASE_URL}/api/v1/scan-to-enter`

const getScanToEnterProgressUrl = (playerId: string) =>
  `${scanToEnterBaseUrl}/progress/${playerId}`

export const consolidateScanToEnterProgressRows = (
  scanToEnterPromotions: ScanToEnterPromotionProgress[]
) => {
  let dict: { [key: string]: ScanToEnterPromotionProgress[] } = {}
  scanToEnterPromotions.forEach((promotion) => {
    if (!dict[promotion.promotionId]) {
      dict[promotion.promotionId] = [promotion]
    } else {
      dict[promotion.promotionId].push(promotion)
    }
  })

  let newList: ConsolidatedScanToEnterPromotion[] = []

  for (const key in dict) {
    const promotions: ScanToEnterPromotionProgress[] = dict[key]
    let progress: ConsolidatedScanToEnterPromotion = {
      ...dict[key][0],
      promotionId: key,
      rewardGroups: promotions[0].rewardGroups.map((rewardGroup) => ({
        rewardGroupId: rewardGroup.rewardGroupId,
        currentProgress: promotions.reduce(
          (prev: ScanToEnterRuleProgressEvent[], curr) => {
            let scans: ScanToEnterRuleProgressEvent[] = []
            for (const group of curr.rewardGroups) {
              if (group.rewardGroupId === rewardGroup.rewardGroupId) {
                scans = scans.concat(group.currentProgress)
              }
            }
            return prev.concat(scans)
          },
          []
        ),
        rules: rewardGroup.rules,
      })),
    }

    newList.push(progress)
  }
  return newList
}

export const useFetchScanToEnterProgress = (playerId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<ScanToEnterProgressResponse>()

  const [consolidatedData, setConsolidatedData] = React.useState<
    ConsolidatedScanToEnterPromotion[]
  >([])

  React.useEffect(() => {
    fetchUrl(getScanToEnterProgressUrl(playerId), 'GET', {}, undefined, [
      PromotionEntriesPermissions.CAN_READ_PROMOTION_ENTRIES,
      permissions.CAN_SEE_TICKET_SCANNING_ACTIVITY,
    ])
    return () => {}
  }, [])

  React.useEffect(() => {
    setConsolidatedData(
      consolidateScanToEnterProgressRows(data?.promotions ?? [])
    )
  }, [data])

  return {
    isLoading,
    error,
    consolidatedData,
  }
}
