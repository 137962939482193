import React from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { FuturePaymentStatus } from '../../../../api/future-payments-fetcher/api-types'
import './styles.scss'

const ROOT_CLASS = 'payment-status-ui'

type FuturePaymentStatusUIProps = {
  status: FuturePaymentStatus
  notes?: string | null
  position: 'left' | 'right'
}

const FUTURE_PAYMENT_DISPLAY_STATUS_MAP: {
  [key in FuturePaymentStatus]: string
} = {
  PENDING: 'Pending',
  DELETED: 'Deleted',
  ON_HOLD: 'On Hold',
  ISSUING: 'Issuing',
}
export const FuturePaymentStatusUI = (props: FuturePaymentStatusUIProps) => {
  return (
    <span className={ROOT_CLASS}>
      {FUTURE_PAYMENT_DISPLAY_STATUS_MAP[props.status]}
      {props.notes &&
        props.notes !== null &&
        props.status === FuturePaymentStatus.ON_HOLD && (
          <span className="status-icon-container">
            <FaRegQuestionCircle />
            <div className={`notes-tooltip ${props.position}`}>
              <header className="title">Notes</header>
              <span className="content">{props.notes}</span>
            </div>
          </span>
        )}
    </span>
  )
}
