import React from 'react'
import { useFetchEndPromotion } from '../../../../../api/promotions-fetcher/use-fetch-base-promotions'
import { PromotionCampaignEndCampaignUI } from './promotion-campaign-end-campaign-ui'

export type PromotionCampaignEndCampaignContainerProps = {
  closeModal: () => void
  promotionId: string
  fetchAllPromotions: () => Promise<void>
}

export const PromotionCampaignEndCampaignContainer = (
  props: PromotionCampaignEndCampaignContainerProps
) => {
  const { isLoading, error, requestSuccessful, endPromotion } =
    useFetchEndPromotion()

  return (
    <PromotionCampaignEndCampaignUI
      closeModal={props.closeModal}
      error={error}
      loading={isLoading}
      actionSuccessful={requestSuccessful}
      onSuccess={props.fetchAllPromotions}
      endPromotion={endPromotion}
      promotionId={props.promotionId}
    />
  )
}
