import config from '../../../config'
import { ListReconciliationPdfReportsResponse } from "../../../api/reconciliation-pdf-reports-fetcher/api-types"
import { useFetch } from "../../../hooks/use-fetch"

const getReportsUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/reconciliation/reports`

export const useFetchReports = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<ListReconciliationPdfReportsResponse>()
  const fetchReports = () => {
    fetchUrl(getReportsUrl, 'GET')
  }

  return {
    isLoading,
    error,
    data,
    fetchReports
  }
}