import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import stylesMessageBox from './css/stylesMessageBox.module.css';
import { DisplayDocumentNewComponent } from './../../../components/PlayersComponents/DisplayDocumentNewComponent';


import { useFormik } from 'formik';
import { UpadteProfileComponent } from './../../../components/PlayersComponents/UpdateProfileComponent';
import {
  validate,
  setFiledValue,
  onSubmit,
  setVariables,
  ssnNumberIsBeenUsed,
  refreshComponent,
  getPlayersDeatils,
  displayErrors,
  setFirstPass,
  getKYCStatus,
} from "./functions";

import eventEmitter from './../../../services/EventEmitter';
import { getPlayersDetailsData } from './../../../services/StoresObject';

import { useHistory } from "react-router-dom";
import { PlayerStatusComponent } from "../../../components/PlayersComponents/PlayerStatusComponent";
import { IdentityDocumentsComponent } from './../../../components/PlayersComponents/IdentityDocumentsComponent'

import { keyPressedType } from "../../../services/Commons";
import { PageMessageCommonComponent } from "../../../components/CommonComponents/PageMessageCommonComponent";
import { PLAYERS_PAGES } from "../../../application-data-structure/players-page.data-structure";
import { CircularProgress } from "@material-ui/core";


let eventEmitterInitialize: any = undefined;
let callingPage:any = undefined;
const timerArray:any=[];
export const ManualKYCPage = ({ id, storesObject }: any) => {
  
  const history:any = useHistory();
  const pageType:any = PLAYERS_PAGES['MANUAL_KYC_PAGE'];

  const identityDocument:any ={
    identification1 : "",
    identification2 : "",
    comment1 :"",
    comment2 :"",
    ssn:"",
    reenterssn:"",

  }
  const pageMessageDefault:any ={
    messageType:"",
    message:""
  }
  const approveToggleValue:any ={ value:false}
  callingPage = localStorage.getItem('callingPage');
  let kycUpdate = false;
  if( storesObject.playerStore.selectedPlayerDetails.account.email === '' )
  { 
     storesObject.playerStore.selectedPlayerDetails = getPlayersDetailsData;
     kycUpdate = true;  
  }
  storesObject.playerStore.selectedPlayerDetails.identityDocument = identityDocument;

  const [playerStore, setPlayerStore]= useState(storesObject.playerStore.selectedPlayerDetails);
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
  
  const [requestPerformed, setRequestPerformed ] = useState<any>(false);
  const [isData, setIsData ] = useState<any>(kycUpdate);
  const [ remainDeniedToggle, setRemainDeniedToggle] = useState<boolean>(false);
  const [ approvedToggle, setApprovedToggle] = useState<any>(approveToggleValue);

  const formik = useFormik({
    initialValues: playerStore,
    initialErrors:{},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
    validate,
  }); 
 
  setVariables(id,formik, storesObject.playerStore, history,pageMessage,setPageMessage);

  const sleep = (ms:any)=>{
   
    const timer = setTimeout((timerArray:any)=>{     
      timerArray.map((e:any)=> clearTimeout(e));
      setRequestPerformed(false);

    },ms,timerArray)
    timerArray.push(timer)

  }
  useEffect(()=>{
    document.getElementById("update-prodile-save-box-container")?.focus();
    if((playerStore.account.email === "" || playerStore.account.email === null || playerStore.account.email === undefined)  && requestPerformed === false)
    {
      document.getElementById("page-container")?.focus();
       getPlayersDeatils(id,setPlayerStore,setPageMessage,pageMessage);
    }

    if(playerStore.account.email !== "" && requestPerformed === false && isData === false)
    {
      document.getElementById("page-container")?.focus();
      getKYCStatus(id,setPlayerStore,setPageMessage,pageMessage, setIsData);
    }
   
    if((playerStore.account.email === "" || playerStore.account.email === null || playerStore.account.email === undefined))
    {
      setRequestPerformed(true);
      sleep(5000);
    }

  })


 
  if (eventEmitterInitialize === undefined)
      eventEmitter.addListener('refresh-data-ready', refreshComponent);


  

  return (
    <>   
       {((playerStore.account.email === undefined || playerStore.account.email === "") && pageMessage.message === "" && (storesObject.playerStore.playerDocImages.find((e:any)=> e.url.split("?")[0].split("/") !== playerStore.account.id ) !== undefined)) && <div style={{ zIndex:1000, position: 'fixed', top: 0, left: 0, width: "100vw", height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
        <CircularProgress size="8em" color='inherit' thickness={2} />
      </div>} 
      <div  onClick={(e:any) => { pageMessage.messageType !== "" && setPageMessage({ message:"" , messageType:"" })  } } id="page-container" className={styles['page-container']}>
        <div className={styles['top-ribbon']}>
          <div className={styles['top-ribbon-text']} tabIndex={0}>Manually Approve KYC</div>
          <div className={styles['top-ribbon-button-container']}>
            <div 
            role="button" 
            tabIndex={0} 
            aria-pressed="false"
            onKeyPress={(ev: any) => {
              if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                setRemainDeniedToggle(!remainDeniedToggle)  
              }
            }
            }    
            onClick={() => setRemainDeniedToggle(!remainDeniedToggle)} 
            className={styles['top-ribbon-cancel-button']}>Remain Denied</div>
            <div 
            role="button"
            style={{
                    opacity: Object.keys(formik.errors).length > 0 ? "0.4":"1", 
                    cursor: Object.keys(formik.errors).length > 0 ? "not-allowed" : "pointer",
            }} 
            tabIndex={0} 
            aria-pressed="false"
            onKeyPress={(ev: any) => {
              if ((keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) && Object.keys(formik.errors).length === 0) {
                setApprovedToggle({value: true}); approvedToggle.value = true;  
              }
            }
            }    
            onClick={() =>{
              if(Object.keys(formik.errors).length === 0)
              { 
                 setApprovedToggle({value: true}); approvedToggle.value = true;
              } 
            }
          } 
            className={styles['top-ribbon-save-button']}>Approve</div>
          </div>
        </div>
        {pageMessage.message !="" &&  document.scrollingElement?.scrollTo(0,0)}
        {pageMessage.message !="" &&  <PageMessageCommonComponent message={pageMessage.message}  messageType={pageMessage.messageType}/>}
        <div className={styles['page-container-body-container']}>
          <div className={styles['page-container-body']}>
            <div className={styles['page-container-body-left']} >
            <div className={styles['manual-kyc-identity-documents-container']}>
                 <IdentityDocumentsComponent formik={formik} setFiledValue={setFiledValue} ssnNumberIsBeenUsed={ssnNumberIsBeenUsed} />
            </div>
              <div className={styles['manual-kyc-status-component-container']}>
                <PlayerStatusComponent playerStore={playerStore} isData={isData} callingPage={pageType} pageType={pageType} />
              </div>
              <div className={styles['manual-kyc-profile-component-container']}>
                   <UpadteProfileComponent formik={formik} setFiledValue={setFiledValue} ssnNumberIsBeenUsed={ssnNumberIsBeenUsed} callingPage={'MANUAL_KYC_PAGE'} />
              </div>
              
            </div>
            <div className={styles['page-container-body-right']}>
               <DisplayDocumentNewComponent storesObject={storesObject} playerId={id}  setRequestPerformed={setRequestPerformed} requestPerformed={requestPerformed} setPageMessage={setPageMessage} pageMessage={pageMessage} />
            </div>
          </div>
        </div>
      </div>
      {approvedToggle.value && <div className={stylesMessageBox['window-container']}>
        <div tabIndex={0} id="update-prodile-save-box-container" className={stylesMessageBox['update-prodile-save-box-container']}>
          <div tabIndex={0} className={stylesMessageBox['update-prodile-save-box-message']}>Are you sure you would like to Approve KYC for this player?</div>
          <div 
          tabIndex={0} 
          role='button'
           onKeyPress={(ev: any) => {
            if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
              formik.submitForm(); (approvedToggle.value = false ); 
            }
          }
          }    
            onClick={() => { formik.submitForm(); setFirstPass(false);(approvedToggle.value = false ) } }
            className={stylesMessageBox['update-prodile-save-box-button-yes']} >
            <div className={stylesMessageBox['update-prodile-save-button-yes-text']}>Yes, Approve</div>
          </div>
          <div 
          tabIndex={0} 
          role='button'
           onKeyPress={(ev: any) => {
            if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
              setApprovedToggle({value:!approvedToggle.value}); 
              document.getElementById("page-container")?.focus();
            }
          }
          }    
          onClick={() => { setApprovedToggle({value:!approvedToggle.value});}} 
          className={stylesMessageBox['player-email-and-phone-message-box-button-no']} >Go Back</div>
        </div>
      </div>}
      {remainDeniedToggle && <div className={stylesMessageBox['window-container']}>
        <div tabIndex={0} id="update-prodile-save-box-container" className={stylesMessageBox['update-prodile-save-box-container']}>
          <div tabIndex={0} 
          className={stylesMessageBox['update-prodile-save-box-message']}>Are you sure you would like to cancel without Approving KYC?</div>
          <div
          tabIndex={0} 
          role='button'
           onKeyPress={(ev: any) => {
            if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
              history.push(`${callingPage}`); 
            }
          }
          }    
            onClick={() =>{ history.push(`${callingPage}`); }}
            className={stylesMessageBox['update-prodile-save-box-button-yes']} >
            <div className={stylesMessageBox['update-prodile-save-button-yes-text']}>Yes, Cancel</div>
          </div>
          <div
          tabIndex={0} 
          role='button'
          onKeyPress={(ev: any) => {
            if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
              setRemainDeniedToggle(!remainDeniedToggle); 
              document.getElementById("page-container")?.focus();
            }
          }
          }     
          onClick={() => setRemainDeniedToggle(!remainDeniedToggle)} 
          className={stylesMessageBox['player-email-and-phone-message-box-button-no']} >Go Back</div>
        </div>
      </div>}
     
    </>

  );
}

