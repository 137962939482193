import { action, observable } from 'mobx'
import config from '../config'
import { AnnuityPaymentScheduleInfo } from '../pages/AnnuitiesPages/TPAManagement/AnnuityFullPaymentSchedulePage/interfaces'
import { makeHttpCall } from '../services/MakeHttpCall'
import { TimePaymentAccountPayee } from '../types/Annuities/PayeeManagement/Payee'
import { TimePaymentAccount } from '../types/Annuities/entities/time-payment-account'

export class AnnuitiesStore {
  @observable firstPaymentID: string = ''
  @observable annuityAccountPayeeList: TimePaymentAccountPayee[] = []
  @observable annuityPaymentList: AnnuityPaymentScheduleInfo[] = []

  // Actions
  @action setFirstPaymentID(id: string) {
    this.firstPaymentID = id
  }

  // populateAnnuityAccountList updated to allow for more generic use
  @action async populateAnnuityAccountList() {
    const options = {
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/v1/annuities/payee/time-payment-account`,
    }

    let annuityAccountList

    try {
      const response = await makeHttpCall(options)
      annuityAccountList = response
    } catch (e) {
      // no need for creating a blank annuityAccountList during API fail b/c it is being handled at Page level during initial load
      throw new Error('Failed to pull back Time Payment Account List')
    }

    return annuityAccountList
  }

  // populateAnnuityAccountDetails updated to allow for more generic use
  @action async populateAnnuityAccountDetails(id: string) {
    const options = {
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${id}`,
    }

    let annuityAccountDetails: TimePaymentAccount

    try {
      const response = await makeHttpCall(options)
      annuityAccountDetails = response
    } catch (e) {
      // no need for creating a blank annuityAccountDetails during API fail b/c it is being handled at Page level during initial load
      throw new Error('Failed to pull back Time Payment Account Details')
    }
    return annuityAccountDetails
  }
  // populateAnnuityAccountPayeeList updated to allow for more generic use
  @action async populateAnnuityAccountPayeeList(id: string) {
    const options = {
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${id}/payee`,
    }

    try {
      const response = await makeHttpCall(options)

      this.annuityAccountPayeeList = response
    } catch (error) {
      throw new Error('Failed to pull back Time Payment Account Payees')
    }
  }
}
