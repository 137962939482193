import * as React from 'react'
import { getFetchSplit5754Reports } from './get-fetch-split-5754-reports'
import { Split5754ReportingPageUI } from './split-5754-reporting-page-ui'
import { getFetchDownload5754SplitReport } from './get-fetch-download-split-5754-report'
import { getFetchGenerate5754SplitReport } from './get-fetch-generate-split-5754-report'

export const Split5754ReportingPageContainer = () => {
  const { isLoading: isGetReportsLoading, error: getReportsError, data: getReportsData, fetchSplit5754Reports } = getFetchSplit5754Reports()
  const { isLoading: isDownloadReportLoading, error: downloadError, preSignedUrlHandler } = getFetchDownload5754SplitReport()
  const { isLoading: isGenerateReportLoading, error: generateError, generate5754SplitReport } = getFetchGenerate5754SplitReport()

  const [year, setYear] = React.useState<string>('')

  React.useEffect(() => {
    fetchSplit5754Reports(year)
  }, [year])

  const generateReportAndRefreshList = async (requestYear: string, generatedBy: string) => {
    await generate5754SplitReport(requestYear, generatedBy)
    fetchSplit5754Reports(year)
  }

  return (
    <Split5754ReportingPageUI
      isLoading={isGetReportsLoading || isDownloadReportLoading || isGenerateReportLoading}
      error={getReportsError ?? downloadError ?? generateError}
      reports={getReportsData ? getReportsData.reports : []}
      year={year}
      setYear={setYear}
      generateReport={generateReportAndRefreshList}
      downloadReport={preSignedUrlHandler}
    />
  )
}
