import * as React from 'react'
import { PlayslipsStepDownPageContext } from '../../../../pages/OperationsPages/playslips-step-down-page/playslips-step-down-context'
import {
  PlayslipsStepDownSidebarFormUI,
  PlayslipsStepDownSidebarFormUIProps,
} from './playslips-step-down-sidebar-form-ui'
import { useFetchCreatePlayslipsStepDown } from '../../../../api/playslips-fetcher/use-fetch-playslips'

export type PlayslipsStepDownSidebarFormContainerProps = Pick<
  PlayslipsStepDownSidebarFormUIProps,
  'activeGames' | 'closeSidebar'
>

export const PlayslipsStepDownSidebarFormContainer = (
  props: PlayslipsStepDownSidebarFormContainerProps
) => {
  const { fetchAllPlayslipsStepDowns } = React.useContext(
    PlayslipsStepDownPageContext
  )
  const { isLoading, error, requestSuccessful, createPlayslipsStepDown } =
    useFetchCreatePlayslipsStepDown()

  return (
    <PlayslipsStepDownSidebarFormUI
      fetchAllPlayslipsStepDowns={fetchAllPlayslipsStepDowns}
      activeGames={props.activeGames}
      closeSidebar={props.closeSidebar}
      createIsLoading={isLoading}
      createError={error}
      createSuccessful={requestSuccessful}
      createPlayslipsStepDown={createPlayslipsStepDown}
    />
  )
}
