import { useFetch } from '../../hooks/use-fetch'
import config from '../../config'
import {
  Agent1099PrintDetailsResponse,
  Agent1099PrintHistoryResponse,
} from './api-types'
import {
  downloadFile,
  getContentDispositionFilename,
} from '../../utilities/helpers'
import React from 'react'
import { permissions } from '../../constants.js'
import { PreSignedUrlResponse } from '../finserv-general-reports/api-types'
import { saveFromUrl } from '../../util'

const baseUrl = `${config.SERVER_BASE_URL}/api/v1`

export const useFetchAgent1099PrintHistory = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<Agent1099PrintHistoryResponse>()
  const fetchAgent1099PrintHistory = () => {
    const url = `${baseUrl}/agent1099Queue/prints`
    fetchUrl(url)
  }
  return { isLoading, error, data, fetchAgent1099PrintHistory }
}

export const useFetchAgent1099PrintDetails = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<Agent1099PrintDetailsResponse>()
  const fetchAgent1099PrintDetails = (sweepId: string) => {
    const url = `${baseUrl}/agent1099Queue/sweep/${sweepId}`
    fetchUrl(url)
  }
  return { isLoading, error, data, fetchAgent1099PrintDetails }
}

export const useFetchDownload1099PrintHistoryReport = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<PreSignedUrlResponse>()

  const preSignedUrlHandler = async (sweepId: string) => {
    const url = `${baseUrl}/agent1099Queue/${sweepId}/printReportUrl`
    const preSignedUrl = await fetchUrl(url, 'GET', {}, undefined, [
      permissions.CAN_SEE_1099_REPORTS,
    ])
    if (!preSignedUrl) {
      return
    }
    saveFromUrl(preSignedUrl.url)
  }

  return { isLoading, error, data, preSignedUrlHandler }
}
