import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import {HoursOfOperationCodes, OccasionDayCodes} from "../../../../../Constants/CharitableConstant";
import InputMask from 'react-input-mask';

export const CreateOrganizationDetailsComponent = ({
                                                       formik,
                                                       setFiledValue,
                                                   }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Organization Details</div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Name of Organization*
                    </div>
                    <input
                        id="orgDetails.orgName"
                        name="orgDetails.orgName"
                        autoComplete={"organization"}
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.orgDetails.orgName}
                    />
                    {(formik.touched.orgDetails?.orgName && formik.errors.orgName) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.orgName}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Date Org. Chartered*
                    </div>
                    <input
                        autoComplete={"off"}
                        id="orgDetails.orgDateChartered"
                        name="orgDetails.orgDateChartered"
                        value={formik.values.orgDetails.orgDateChartered}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["page-container-body-left-input-box"]}`}
                        type="date"/>
                    {(formik.touched.orgDetails?.orgDateChartered && formik.errors.orgDateChartered) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.orgDateChartered}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Street Address*</div>
                    <input
                        autoComplete={"street-address"}
                        id="orgDetails.orgAddress.streetAddress"
                        name="orgDetails.orgAddress.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.orgDetails.orgAddress.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.orgDetails?.orgAddress?.streetAddress && formik.errors.streetAddress10) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress10={formik.errors.streetAddress10}/> : null}
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Organization F.I.D*
                    </div>
                    <input
                        id="orgDetails.orgFID"
                        name="orgDetails.orgFID"
                        onChange={(e: any) => setFiledValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.orgDetails.orgFID}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.orgDetails?.orgFID && formik.errors.orgFID) ?
                        <ErrorMessageFieldCommonComponent
                            orgFID={formik.errors.orgFID}/> : null}
                </div>

                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            autoComplete={"address-level2"}
                            id="orgDetails.orgAddress.city"
                            name="orgDetails.orgAddress.city"
                            value={formik.values.orgDetails.orgAddress.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                        />
                        {(formik.touched.orgDetails?.orgAddress?.city && formik.errors.city10) ?
                            <ErrorMessageFieldCommonComponent
                                city10={formik.errors.city10}/> : null}
                    </div>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            id="orgDetails.orgAddress.zip"
                            name="orgDetails.orgAddress.zip"
                            className={styles["page-container-body-left-input-box"]}
                            onChange={(e: any) => setFiledValue(e)}
                            value={formik.values.orgDetails.orgAddress.zip}
                            onBlur={formik.handleBlur}
                            mask="99999"
                            placeholder="Ex. 12345"
                            autoComplete={"postal-code"}
                            maskChar=" "
                        />
                        {(formik.touched.orgDetails?.orgAddress?.zip && formik.errors.zip10) ?
                            <ErrorMessageFieldCommonComponent
                                zip10={formik.errors.zip10}/> : null}
                    </div>
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Occasion Day*</div>
                    <select
                        id="licenseDetails.occasionDay"
                        name="licenseDetails.occasionDay"
                        onChange={(e: any) => setFiledValue(e)}
                        onBlur={formik.handleBlur}
                        placeholder="Select"
                        className={styles["page-container-body-left-input-box"]}>
                        <option value="" selected disabled>Select...</option>
                        {
                            OccasionDayCodes.map((dayCode: string) =>
                                <option
                                    selected={formik.values.licenseDetails.occasionDay === dayCode}
                                    value={dayCode}>{dayCode}
                                </option>)
                        }

                    </select>
                    {(formik.touched.licenseDetails?.occasionDay && formik.errors.occasionDay) ?
                        <ErrorMessageFieldCommonComponent
                            orgFID={formik.errors.occasionDay}/> : null}
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Occasion Address*</div>
                    <input
                        autoComplete={"street-address"}
                        id="licenseDetails.occasionAddress.streetAddress"
                        name="licenseDetails.occasionAddress.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.occasionAddress.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.licenseDetails?.occasionAddress?.streetAddress && formik.errors.streetAddress20) ?
                        <ErrorMessageFieldCommonComponent
                            orgFID={formik.errors.streetAddress20}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Org. Telephone*</div>
                    <InputMask
                        id="orgDetails.orgPhone"
                        name="orgDetails.orgPhone"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.orgDetails.orgPhone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                        autoComplete={"tel-national"}
                    />
                    {(formik.touched.orgDetails?.orgPhone && formik.errors.orgPhone) ?
                        <ErrorMessageFieldCommonComponent
                            orgPhone={formik.errors.orgPhone}/> : null}
                </div>
                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            autoComplete={"address-level2"}
                            id="licenseDetails.occasionAddress.city"
                            name="licenseDetails.occasionAddress.city"
                            value={formik.values.licenseDetails.occasionAddress.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                        />
                        {(formik.touched.licenseDetails?.occasionAddress?.city && formik.errors.city1) ?
                            <ErrorMessageFieldCommonComponent
                                city1={formik.errors.city1}/> : null}
                    </div>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            id="licenseDetails.occasionAddress.zip"
                            name="licenseDetails.occasionAddress.zip"
                            value={formik.values.licenseDetails.occasionAddress.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            mask="99999"
                            autoComplete={"postal-code"}
                            maskChar=" "
                        />
                        {(formik.touched.licenseDetails?.occasionAddress?.zip && formik.errors.zip1) ?
                            <ErrorMessageFieldCommonComponent
                                zip1={formik.errors.zip1}/> : null}
                    </div>
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Beano Hall Phone*</div>
                    <InputMask
                        autoComplete={"tel-national"}
                        id="licenseDetails.beanoHallPhone"
                        name="licenseDetails.beanoHallPhone"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.beanoHallPhone}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.licenseDetails?.beanoHallPhone && formik.errors.beanoHallPhone) ?
                        <ErrorMessageFieldCommonComponent
                            beanoHallPhone={formik.errors.beanoHallPhone}/> : null}
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing and Shipping
                        Address*
                    </div>
                    <input
                        autoComplete={"street-address"}
                        id="orgDetails.mailAddress.streetAddress"
                        name="orgDetails.mailAddress.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.orgDetails.mailAddress.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.orgDetails?.mailAddress?.streetAddress && formik.errors.streetAddress1) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress1={formik.errors.streetAddress1}/> : null}
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Hours of Operation*
                    </div>
                    <select
                        id="licenseDetails.occasionTime"
                        name="licenseDetails.occasionTime"
                        onChange={(e: any) => setFiledValue(e)}
                        onBlur={formik.handleBlur}
                        placeholder="Select"
                        className={styles["page-container-body-left-input-box"]}>
                        <option value="" selected disabled>Select...</option>
                        {
                            HoursOfOperationCodes.map((operationCodes: string) =>
                                <option
                                    selected={formik.values.licenseDetails.occasionTime === operationCodes}
                                    value={operationCodes}>{operationCodes}
                                </option>)
                        }
                    </select>

                    {(formik.touched.licenseDetails?.occasionTime && formik.errors.occasionTime) ?
                        <ErrorMessageFieldCommonComponent
                            occasionTime={formik.errors.occasionTime}/> : null}
                </div>

                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            autoComplete={"address-level2"}
                            id="orgDetails.mailAddress.city"
                            name="orgDetails.mailAddress.city"
                            value={formik.values.orgDetails.mailAddress.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                        />
                        {(formik.touched.orgDetails?.mailAddress?.city && formik.errors.city2) ?
                            <ErrorMessageFieldCommonComponent
                                city2={formik.errors.city2}/> : null}
                    </div>

                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            id="orgDetails.mailAddress.zip"
                            name="orgDetails.mailAddress.zip"
                            value={formik.values.orgDetails.mailAddress.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            mask="99999"
                            autoComplete={"postal-code"}
                            maskChar=" "
                        />
                        {(formik.touched.orgDetails?.mailAddress?.zip && formik.errors.zip2) ?
                            <ErrorMessageFieldCommonComponent
                                zip2={formik.errors.zip2}/> : null}
                    </div>
                </div>

            </form>
        </>

    );
}