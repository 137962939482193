import React from "react";
import {Button} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {Line} from 'react-chartjs-2';
import SystemSearch from '../systemsearch';
import ReactTable from "../PaginatedTable/ReactTable";
import AppToast from "../toast";
import config from "../config";
import moment from "moment";
import {ExportToCsv} from 'export-to-csv';
import {inject, observer} from "mobx-react";

import './SystemDetails.css';

@inject('claimStore')
@observer
class SystemDetails extends React.Component {  

    constructor(props){
        super(props);
        this.state = {
            category: '',
            datetime: '',
            sourceFile: '',
            description: '',
            logLine: '',
            logContext: '',
            showError: false,
            showToast: false
        };
    }

    fetchSystemDetails(id = ''){
        if(id === ''){
            console.log('no query string passed');
        } else {
            var queryStr = `?id=${id}`;

            let url = `${config.SERVER_BASE_URL}/v1/log/eventbyid${queryStr}`;
            fetch(url, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
                })
                .then(response => response.json())
                .then(res => {
                    if (res.error) {
                        console.log(res.error);
                    } else {
                        this.setSystemDetails(res);
                    }
                })
                .catch((error) => {
                    this.setState({showError: true, errorMessage: error.toString()})
                });
        }
    }

    handlePlayerClick = (event, id) => {
        event.preventDefault();
        this.navigateTo(`/new-layout/player-support-new/player-hub-new/${id}`);
    }

    navigateTo = (path, id) => {
        if(id !== ''){
            this.props.history.push({pathname: path, state: { detail: id }});
        }
        else{
            this.props.history.push(path);
        }
    }

    setSystemDetails = (res) => {
        var data = res.hits.hits;

        let tempID = '';
        if(data[0]._source.playerID && data[0]._source.playerID.length > 0){
            if (data[0]._source.playerID.split(' ').length > 1){
                tempID = data[0]._source.playerID.split(' ')[2]
            } else {
                tempID = tempID = data[0]._source.playerID;
            }
        }     

        this.setState({
            'category': data[0]._source.category,
            'datetime': data[0]._source.eventDateTime,
            'sourceFile': data[0]._source.fileName,
            'description': data[0]._source.eventDescription,
            'logLine': data[0]._source.eventResult,
            'logContext': data[0]._source.context,
            '_playerID': tempID,
        });
    }

    componentDidMount() {
        const {systemDetailsId} = this.props.claimStore;

        if (this.props.location.state === undefined) {
            if (systemDetailsId !== undefined || systemDetailsId.length < 0) {
                this.fetchSystemDetails(systemDetailsId);
            }
        }
        else {
            if (systemDetailsId === undefined || systemDetailsId.length === 0){
                var query = this.props.location.state.detail;
                this.fetchSystemDetails(query); 
            } else {
                this.fetchSystemDetails(systemDetailsId); 
            }            
        }
    }

    downloadClicked(data) {
        this.csvExporter.generateCsv(data);
    }

    render() {
        const {category, datetime, sourceFile, description, logLine, logContext, _playerID} = this.state;        

        return(
            <div className='system-details-panel'>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Category:</h4>
                    <p className='system-details-text'>{category}</p>
                </div>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Local Time:</h4>
                    <p className='system-details-text'>{datetime}</p>
                </div>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Source File:</h4>
                    <p className='system-details-text'>{sourceFile}</p>
                </div>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Description:</h4>
                    <p className='system-details-text'>{description}</p>
                </div>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Player ID:</h4>
                    <a onClick={(e) => this.handlePlayerClick(e, _playerID)} href='' className='details-select-link'>{_playerID}</a>             
                </div>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Log Line:</h4>
                    <p className='system-details-text'>{logLine}</p>
                </div>
                <div className='system-details-rows'>
                    <h4 className='system-details-header'>Log Context:</h4>
                    <p className='system-details-text'>{logContext}</p>
                </div>
            </div>
        ) 
    }
}

export default SystemDetails;