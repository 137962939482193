import React from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";

export const PullTabOrderCreationStepOneSearchBarComponent = ({formik, setFieldValue, clearSearch}: any) => {

    const activateSearchButton = ((formik.values.licenseNumber !== '' ||
        formik.values.orgName !== '' ||
        formik.values.city !== '')
        && Object.keys(formik.errors).length === 0);

    return (
        <>

            <div className={`${styles['pulltab-games-page-search-bar-container']} ${styles['']}`}>
                <div className={`${styles['pulltab-games-page-search-bar-title']} ${styles['']}`}>Search
                </div>
                <div
                    className={`${styles['pulltab-games-page-search-bar-input-button-container']} ${styles['']}`}>

                    <div
                        className={`${styles['pulltab-games-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div className={`${styles['mslc-label']} ${styles['']}`}>License Number</div>
                        <input className={`${styles['mslc-textbox']}`}
                               type="text"
                               id="licenseNumber"
                               name="licenseNumber"
                               value={formik.values.licenseNumber}
                               maxLength={5}
                               onBlur={formik.handleBlur}
                               onChange={(e: any) => {
                                   setFieldValue(e, formik);
                               }}/>
                        {formik.errors.licenseNumber ?
                            <ErrorMessageFieldCommonComponent errorMessages={formik.errors.licenseNumber}/> : null}
                    </div>
                    <div
                        className={`${styles['pulltab-games-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div className={`${styles['mslc-label']} ${styles['']}`}>Name of Organization</div>
                        <input className={`${styles['mslc-textbox']}`}
                               type="text"
                               id="orgName"
                               name="orgName"
                               value={formik.values.orgName}
                               onBlur={formik.handleBlur}
                               onChange={(e: any) => {
                                   setFieldValue(e, formik);
                               }}/>
                        {formik.errors.orgName ?
                            <ErrorMessageFieldCommonComponent errorMessages={formik.errors.orgName}/> : null}
                    </div>
                    <div
                        className={`${styles['pulltab-games-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div className={`${styles['mslc-label']} ${styles['']}`}>City/Town</div>
                        <input className={`${styles['mslc-textbox']}`}
                               type="text"
                               id="city"
                               name="city"
                               value={formik.values.city}
                               onBlur={formik.handleBlur}
                               onChange={(e: any) => {
                                   setFieldValue(e, formik);
                               }}/>
                        {formik.errors.city ?
                            <ErrorMessageFieldCommonComponent errorMessages={formik.errors.city}/> : null}
                    </div>
                    <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                         role="button"
                         style={{
                             opacity: (activateSearchButton) ? "1" : "0.4",
                             cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                         }}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }
                         }}
                         onClick={() => {
                             if (activateSearchButton) {
                                 formik.submitForm();
                             }
                         }}>Search

                    </div>
                    <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                         role="button"
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                 clearSearch(formik);
                             }
                         }}
                         onClick={() => {
                             clearSearch(formik);
                         }}>Clear Search
                    </div>
                </div>
            </div>

        </>
    );
}