import moment from "moment";
import {makeHttpCall} from "../../../services/MakeHttpCall";
import config from './../../../config.js';

const arrayOfTimers: any = [];
export const getPageData = (setIsData: any, setNetworkError: any, FilteredClaims: any[], storesObject: any, setFilteredClaims: any, setPageMessage: any, page: number = 0) => {
  const body = {
        firstName: storesObject.playerStore.selectedPlayerDetails.identity.firstName,
    lastName: storesObject.playerStore.selectedPlayerDetails.identity.lastName,
    }
  arrayOfTimers.map((e: any) => clearTimeout(e));
  const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {


    if (!(FilteredClaims[i] === undefined)) {
      array.push(FilteredClaims[i]);

      if (FilteredClaims[i].claim.status === "READY_FOR_PAYMENT") {

                const ms = ((i + 1 * 100) % 10 === 0 ? 100 : (i + 1 * 100));
        const a: any = setTimeout(async () => {
          try {
                        const options =
                            {
                                method: 'POST',
                                url: config.SERVER_BASE_URL + '/v1/claim/' + FilteredClaims[i].claim.referenceNumber + '/transaction',
                                body
                            }
            const response = await makeHttpCall(options);
                        FilteredClaims[i].claim.status = getTransactionStatusType(response.message.transactions[response.message.transactions.length - 1].status);
            setFilteredClaims(FilteredClaims.slice());
                        if (i === ((page * 10) + 10) - 1) {
              setIsData(true);
              setNetworkError(false);
                            setPageMessage({message: "", messageType: ""});
            }
          } catch (e) {
            setIsData(true);
            const er: any = e;
                        if (er.toString().split(" ").find((value: any, i: any) => {
                            if (value === "Network")
                                return value;
                        }) !== undefined) {
              setNetworkError(true);
              setPageMessage({
                                message: "Something went wrong. Network Error. Please try again.",
                                messageType: "ERROR"
              });
            } else {
              setNetworkError(false);
                            setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
            }
          }
        }, ms);
        arrayOfTimers.push(a);
      }
    }
  }
  return array;
}

export const READY_FOR_PAYMENT: any = {
  CREATED: "Validated - Payment Initialized",
  SUBMITTED: "Validated - Payment Pending",
  SETTLED: "Validated - Payment Complete",
  REJECTED: "Validated - Payment Rejected",

}

export const ClaimStatus: any = {
  ERROR_HOST_REJECTED: "Validation Failed - Ineligible Ticket",
  ATTEMPTING_TO_VALIDATE: "In Progress - Claim Attempting to validate",
  ERROR_ALREADY_VALIDATED: "Validation Failed - Already Paid",
  ERROR_DOWNTIME: "Validation Failed - Downtime",
  ERROR_FREQUENT_CASHER: "Blocked - Frequent Casher",
  ERROR_INVALID_RESPONSE: "Validation Failed - Ineligible Ticket",
  ERROR_OFFSET: "Blocked - Player Offsets",
  ERROR_PLAYER_SUSPENDED: "Blocked - Player Suspended",
  ERROR_RTC_TICKET_HOLD: "Blocked- Ticket on Hold",
  ERROR_UNABLE_TO_VALIDATE: "Validation Failed - API Error",
  INITIATED: "In progress - Claim Started",
  PLAYER_ERROR: "Blocked - Player ID",
    READY_FOR_PAYMENT: 'READY_FOR_PAYMENT',
  RECEIVED: "In progress - Claim Received",
  VALIDATED: "Validated - Transmitting Info",

}
export const getTransactionStatusType = (value: string) => {

    const type: any = Object.keys(READY_FOR_PAYMENT).find((key: any) => key === value)
  return type === undefined ? "" : READY_FOR_PAYMENT[value];

}

export const getHistoryStatusType = (value: string) => {

    const type: any = Object.keys(ClaimStatus).find((key: any) => key === value)
  return type === undefined ? "" : ClaimStatus[type];
}

export const generateLink = (claimid: string, playerid: string) => {
  return claimid;
}

export const convertAmount = (amount: number) => {
  if (amount === 0) {
    return `$ 0.00`;
  }
    const amountString = [amount.toString().slice(0, amount.toString().length - 2), '.', amount.toString().slice(amount.toString().length - 2)].join('');
  return `$ ${amountString.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`;
}

export const downloadClicked = async (FilteredClaims: any[], setFilteredClaims: any, storesObject: any, csvExport: any, setCSVDownloadComplete: any) => {

  if (FilteredClaims.length > 0) {

    setCSVDownloadComplete(false);

    const body = {
            firstName: storesObject.playerStore.selectedPlayerDetails.identity.firstName,
            lastName: storesObject.playerStore.selectedPlayerDetails.identity.lastName,
        }

    let options;
    let rows = [];
    let row;

    for (let i = 0; i < FilteredClaims.length; i++) {

      let tempDate: string = normalizeDate(FilteredClaims[i].claim.activeFrom);
      let tempClaim: string = FilteredClaims[i].claim.referenceNumber;
            let tempStatus: string = '';
      if (FilteredClaims[i].claim.status === "READY_FOR_PAYMENT") {
        options = {
                    method: 'POST',
                    url: config.SERVER_BASE_URL + '/v1/claim/' + FilteredClaims[i].claim.referenceNumber + '/transaction',
                    body
                }
        const response = await makeHttpCall(options);
                tempStatus = getTransactionStatusType(response.message.transactions[response.message.transactions.length - 1].status);
      } else {
        tempStatus = getHistoryStatusType(FilteredClaims[i].claim.status);
      }
            if (tempStatus === '') {
        tempStatus = FilteredClaims[i].claim.status;
      }
            let tempAmount: string = convertAmount(FilteredClaims[i].netPaymentAmountInCents);
      let tempBarCode: string = FilteredClaims[i].claim.barcode;

      FilteredClaims[i].claim.status = tempStatus;

      row = {
        date: tempDate,
        claim: tempClaim,
        status: tempStatus,
        amount: tempAmount,
        barcode: tempBarCode,
      };

      rows.push(row);
    }

    csvExport.generateCsv(rows);
    setFilteredClaims(FilteredClaims);
    setCSVDownloadComplete(true);
  }

  return true;
}

export const normalizeDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY h:mm:ss a');
}
