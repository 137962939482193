import * as React from 'react'

type WinnersManagementListContext = {
  refreshTiers: () => Promise<void>
  promotionId: string
  drawingGroupId: string
  drawingId: string
}
export const defaultWinnersManagementTierContext: WinnersManagementListContext =
  {
    refreshTiers: async () => {
      throw new Error('refreshTiers Not Implemented')
    },
    promotionId: '',
    drawingGroupId: '',
    drawingId: '',
  }

export const WinnersManagementTierContext =
  React.createContext<WinnersManagementListContext>(
    defaultWinnersManagementTierContext
  )
