import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {keyPressedType} from "../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, exportPdfReport, getPageData, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../components/CommonComponents/GenericErrorDialogComponent";
import filterCollapseIcon from "../../../icons/Icon_arrows_carrot_teal-up.svg";
import filterExpandIcon from "../../../icons/Icon_arrows_carrot_teal-down.svg";
import {ErrorMessageFieldCommonComponent} from "../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {PaginationCommonComponent} from "../../../components/CommonComponents/PaginationCommonComponent";
import moment from "moment/moment";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";
import {paymentsRoutePath} from "../../../config";
import InputMask from 'react-input-mask';
import {formatCentsToDollars} from "../../../util";
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {
    SearchResultExeceededInfoMessageComponent
} from "../../../components/CharitableGamingComponents/Common/SearchResultExeceededInfoMessageComponent";
import ExclamationIcon from "../../../icons/icon_status_flagged.svg";
import { PaymentSourceGuideUI } from "../../../components/PaymentComponents/PaymentSourceGuideComponent";
import { BannerContext } from '../../../context/BannerContext'
import { MobXProviderContext, observer } from "mobx-react";
 
export const PaymentSearchPage = observer(() => {

    const bannerContext = useContext(BannerContext)
    const mobxContext = useContext(MobXProviderContext)
    const store = mobxContext.paymentSearchStore
    const { payments, searchPageActive: currentPage } = store

    const history: any = useHistory();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const [showFilters, setShowFilters] = useState<boolean>(true);

    const formik = useFormik({
        initialValues: {
            startDate: store.startDate,
            endDate: store.endDate,
            firstName: store.firstName,
            lastName: store.lastName,
            ssn: store.ssn,
            freeformName: store.freeformName,
            checkNumber: store.checkNumber,
            annuityId: store.annuityId,
            paymentSource: store.paymentSource
        }, validate, onSubmit
    });

    const paymentSource = ['Annuities', 'Claim Center', 'Promotions', 'Second Chance', 'Remote Ticket Cashing', 'Season Tickets', 'Other', 'Split'];

    const paymentSourceAbbrv: any = {
        RTC: 'RTC', ES: 'CC', PROMOTIONS: 'PR', SECOND_CHANCE: 'SC', ANNUITIES: 'AN', SEASON_TICKETS: 'ST', OTHER: 'OTR', SPLIT: 'SP'
    };

    const activateSearchButton = (formik.isValid && (formik.dirty || store.isNonempty()));

    const [unmaskedSSNs, setUnmaskedSSNs] = useState(new Set());
    const [requestCompleted, setRequestCompleted] = useState<boolean>(true);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);
    const [paymentSourceGuide, setPaymentSourceGuide] = useState<boolean>(false);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    /*    useEffect(() => {
            if (requestCompleted === false) {
                formik.submitForm();
            }
        }, []);*/

    const handleEyeIconToggle = (e: any) => {

        if (!unmaskedSSNs.has(e.index)) {
            setUnmaskedSSNs(new Set(unmaskedSSNs.add(e.index)));
        } else {
            unmaskedSSNs.delete(e.index)
            setUnmaskedSSNs(new Set(Array.from(unmaskedSSNs)));
        }

    }

    setVariables(
        formik,
        store.setSearchPageActive.bind(store),
        setShowErrorDialog,
        setRequestCompleted,
        store.setPayments.bind(store),
        store.setSearchParams.bind(store)
    );

    return (<>
        {(!requestCompleted) ? <div style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: "100vw",
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "rgba(0,0,0,0.3)",
            color: 'white'
        }}>
            <CircularProgress size="4em" color='inherit' thickness={2}/>
        </div> : null}

        <div className={`${styles['reporting-page-container']}`}
             onClick={() => {

             }}
        >
            <div tabIndex={0}
                 className={`${styles['reporting-page-back-button-container']} ${styles['']}`}
                 onKeyDown={(ev: any) => {
                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                         history.push(`${paymentsRoutePath}`);
                     }
                 }}
                 onClick={() => {
                     history.push(`${paymentsRoutePath}`);
                 }}
            >
                <img
                    style={{
                        cursor: "pointer"
                    }}
                    src={goBackIcon}
                    className={styles[""]}
                    alt="go back icon"
                />
                <div
                    className={`${styles['reporting-page-back-button-container-message']} ${styles['']}`}
                    style={{
                        cursor: "pointer"
                    }}
                >Back to Payments
                </div>
            </div>
            <div className={`${styles['reporting-page-ribbon-container']}`}>
                <div className={`${styles['reporting-page-ribbon-title']} ${styles['']}`}>Payment Search
                </div>
                {checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies)) &&
                    <button
                        className={`${styles['payment-search-page-ribbon-export-pdf-button']} ${styles['']}`}
                        onClick={() => {
                            exportPdfReport(payments.pdfBytes, bannerContext.setBannerInfo)
                        }}
                        disabled={!payments || !payments.pdfBytes}
                    >
                        <div
                            className={`${styles['payment-search-page-ribbon-export-pdf-button-label']}`}
                        >
                            Export PDF
                        </div>
                    </button>
                }
            </div>

            <div
                className={`${styles['reporting-page-search-bar-and-notification-bar-container']}`}>
                <div
                    className={`${styles['reporting-page-search-bar-filter-container']} ${styles['']}`}>

                    <div
                        className={`${styles['reporting-page-search-bar-filter-title-bar']} ${styles['']}`}>
                        <div
                            className={`${styles['reporting-page-search-bar-filter-title']} ${styles['']}`}>Filter
                        </div>
                        <div
                            className={`${styles['reporting-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}>
                            <img
                                tabIndex={0}
                                src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                className={styles[""]}
                                alt="filter expander icon"
                                style={{
                                    cursor: "pointer"
                                }}
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                        setShowFilters(!showFilters);
                                    }
                                }}
                                onClick={() => {
                                    setShowFilters(!showFilters);
                                }}
                            />
                        </div>
                    </div>
                </div>

                {showFilters ? <div
                    className={`${styles['reporting-page-search-bar-filter-expanded-container']} ${styles['']}`}>
                    <div
                        className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div className={`${styles['mslc-label']} ${styles['']}`}>Reportable Start Date
                        </div>
                        <input
                            className={`${styles['mslc-datetime-picker']}`}
                            type={'date'}
                            id="startDate"
                            name="startDate"
                            value={formik.values.startDate}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => {
                                setFieldValue(e, formik);
                            }}
                            max={moment().format('YYYY-MM-DD')}
                            placeholder={'mm/dd/yyyy'}
                        />
                        {(formik.touched.startDate && formik.errors.startDate) ? <ErrorMessageFieldCommonComponent
                            errorMessages={formik.errors.startDate}/> : null}
                    </div>
                    <div
                        className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div className={`${styles['mslc-label']} ${styles['']}`}>Reportable End Date
                        </div>
                        <input
                            className={`${styles['mslc-datetime-picker']}`}
                            type={'date'}
                            id="endDate"
                            name="endDate"
                            value={formik.values.endDate}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => {
                                setFieldValue(e, formik);
                            }}
                            max={moment().format('YYYY-MM-DD')}
                            placeholder={'mm/dd/yyyy'}
                            disabled={!formik.values.startDate}
                        />
                        {(formik.touched.endDate && formik.errors.endDate) ? <ErrorMessageFieldCommonComponent
                            errorMessages={formik.errors.endDate}/> : null}
                    </div>
                    <div
                        className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                        <div className={`${styles['mslc-label']} ${styles['']}`}>Source</div>
                        <div className={`${styles.custom_select_div}`}>
                            <select className={styles["custom_select"]}
                                    id="paymentSource"
                                    name="paymentSource"
                                    onChange={(e: any) => {
                                        setFieldValue(e, formik);
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="Select"
                            >
                                <option value="" selected>Select...</option>
                                {paymentSource.map((source: string) => <option
                                    selected={formik.values.paymentSource.toLowerCase() === source.toLowerCase()}
                                    value={source}>{source}
                                </option>)}
                            </select>
                            <span className={`${styles.custom_arrow}`}></span>
                        </div>
                        {(formik.touched.paymentSource && formik.errors.paymentSource) ?
                            <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.paymentSource}/> : null}
                    </div>
                </div> : null}

                <div
                    className={`${styles['reporting-page-search-bar-input-button-container']} ${styles['']}`}>
                    <div className={`${styles['reporting-page-search-bar-input-button-container-row-1']}`}>
                        <div
                            className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>First Name</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="firstName"
                                   name="firstName"
                                   value={formik.values.firstName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.firstName && formik.errors.firstName ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.firstName}/> : null}
                        </div>
                        <div
                            className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Last Name</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="lastName"
                                   name="lastName"
                                   value={formik.values.lastName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.lastName && formik.errors.lastName ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.lastName}/> : null}
                        </div>
                        <div
                            className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>TIN&nbsp;&nbsp;&nbsp;Must be
                                exact match
                            </div>
                            <InputMask className={`${styles['mslc-textbox']}`}
                                       type="text"
                                       id="ssn"
                                       name="ssn"
                                       value={formik.values.ssn}
                                       onBlur={formik.handleBlur}
                                       mask="999-99-9999"
                                       maskChar=" "
                                       placeholder={'000-00-0000'}
                                       onChange={(e: any) => {
                                           setFieldValue(e, formik);
                                       }}/>
                            {formik.touched.ssn && formik.errors.ssn ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.ssn}/> : null}
                        </div>
                    </div>
                    <div className={`${styles['reporting-page-search-bar-input-button-container-row-2']}`}>
                        <div
                            className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Freeform Name</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="freeformName"
                                   name="freeformName"
                                   value={formik.values.freeformName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.freeformName && formik.errors.freeformName ?
                                <ErrorMessageFieldCommonComponent
                                    errorMessages={formik.errors.freeformName}/> : null}
                        </div>
                        <div
                            className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Check No.&nbsp;&nbsp;&nbsp;Must
                                be exact
                                match
                            </div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="checkNumber"
                                   name="checkNumber"
                                   value={formik.values.checkNumber}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.checkNumber && formik.errors.checkNumber ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.checkNumber}/> : null}
                        </div>
                        <div
                            className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Annuity ID
                            </div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="annuityId"
                                   name="annuityId"
                                   value={formik.values.annuityId}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.touched.annuityId && formik.errors.annuityId ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.annuityId}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         setUnmaskedSSNs(new Set());
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     setUnmaskedSSNs(new Set());
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                     setUnmaskedSSNs(new Set());
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 setUnmaskedSSNs(new Set());
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['table']}>

                {payments && payments.results.length > 0 ? <div className={`${styles['table-data-header-container']}`}>
                    <div className={styles['table-data-header']}>
                        <div className={styles['table-data-header-text']} aria-live="assertive">
                            {`Displaying ${payments.results.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(payments.results.length, currentPage)}
                            of ${payments.results.length} results`}
                        </div>
                        {payments && payments.additionalResults ? <div
                            style={{
                                cursor: "pointer"
                            }}
                            onMouseEnter={() => {
                                setShowSearchResultExceededInfoMessage(true);
                            }}
                            onMouseLeave={() => {
                                setShowSearchResultExceededInfoMessage(false);
                            }}
                        >
                            <img
                                src={ExclamationIcon}
                                height={20}
                                width={20}
                                alt="exclamation icon"
                            />
                            {payments && payments.additionalResults && showSearchResultExceededInfoMessage ?
                                <SearchResultExeceededInfoMessageComponent/> : null}
                        </div> : null}
                    </div>
                    <div tabIndex={0} className={`${styles['source-guide-button']}`}
                         role="button"
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 setPaymentSourceGuide(true);
                             }
                         }}
                         onClick={() => {
                             setPaymentSourceGuide(true);
                         }}>Source Guide

                    </div>
                </div> : null}


                {payments && payments.results.length > 0 ? <div className={styles['table-data-body']}>

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div>Check No.</div>
                        <div>Annuity ID</div>
                        <div>Payee Name</div>
                        <div>TIN</div>
                        <div>Reportable Date</div>
                        <div>Gross Payment</div>
                        <div>Federal Tax</div>
                        <div>State Tax</div>
                        <div>Offsets</div>
                        <div>Net Payment</div>
                        <div>Source</div>
                        <div>Status</div>
                    </div>

                    {payments.results.length > 0 && getPageData(payments.results, currentPage).map((e: any, i: number) =>
                        <div
                            key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                            className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                <Link to={`${paymentsRoutePath}/details/${e.internalPaymentId}`}>
                                    {e.checkNumber}
                                </Link>
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.annuityId}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.name}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                <div className={styles["tin-eye-icon-container"]}>
                                    <div>{unmaskedSSNs.has(e.index) ? e.ssn : `*****${e.ssn.slice(-4)}`}</div>
                                    <div>
                                        {unmaskedSSNs.has(e.index) ?
                                            <FaEyeSlash style={{cursor: "pointer"}} onClick={() => {
                                                handleEyeIconToggle(e);
                                            }}/> : <FaEye style={{cursor: "pointer"}} onClick={() => {
                                                handleEyeIconToggle(e);
                                            }}/>}
                                    </div>
                                </div>
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.reportablePaymentDate).format('MM/DD/yyyy')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.grossPaymentInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.irsTaxInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.dorTaxInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.offsetsWithheldInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.netPaymentInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {paymentSourceAbbrv[e.source]}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.status}
                            </div>
                            <div className={styles['table-details-button-container']}>
                                {checkRenderPermissions(permissions.CAN_SEE_GLOBAL_PAYMENT_DETAILS, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (keyPressedType(en) === 13 || keyPressedType(en) === 32) {
                                                 history.push(`${paymentsRoutePath}/details/${e.internalPaymentId}`);
                                             }
                                         }}
                                         onClick={() => {
                                             history.push(`${paymentsRoutePath}/details/${e.internalPaymentId}`);
                                         }}
                                         className={styles['table-details-button']}>
                                        <div aria-label={'Licensee Details Button.'}
                                             className={styles['table-details-button-text']}>
                                            Details
                                        </div>
                                    </div> : null}
                            </div>
                        </div>)}
                </div> : payments && payments.results.length <= 0 ?
                    <div className={`${styles['reporting-page-search-no-results-container']}`}>
                        <div className={`${styles['reporting-page-search-no-results-container-text']}`}>
                            No Results
                        </div>
                    </div> : <div className={`${styles['reporting-page-search-no-results-container']}`}>
                        <div className={`${styles['reporting-page-search-no-results-container-text']}`}>
                            Select a date range or search to generate results
                        </div>
                    </div>}

                {payments && payments.results.length > 0 ? <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((payments.results.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={payments.results}
                                                currentPage={currentPage}
                                                setCurrentPage={store.setSearchPageActive.bind(store)}/>}

                </div> : null}
            </div>
        </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {paymentSourceGuide ? <PaymentSourceGuideUI 
                setPaymentSourceGuide={setPaymentSourceGuide}
            /> : null}
        </>);
});