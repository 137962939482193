import * as React from 'react'
import {
  useFetchAllCampaigns,
  useFetchCampaignPlayerFileStatuses,
  useFetchDefaultLegalCopy,
} from '../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import { CouponCampaignPermissions } from '../../api/promotions-fetcher/permissions'
import { useRecurringAction } from '../../hooks/use-recurring-action'
import { minutesToMilliseconds } from '../../util'
import { userHasAnyOfPermissions } from '../../util/access-helpers'
import {
  CampaignBuilderContext,
  defaultCampaignBuilderContext,
} from './campaign-builder-context'
import { CampaignsPageUI } from './campaigns-page-ui'

export const CampaignsPageContainer = () => {
  const { isLoading, error, data, fetchAllCampaigns } = useFetchAllCampaigns()
  const { isLoading: defaultLegalCopyIsLoading, data: defaultLegalCopyData } =
    useFetchDefaultLegalCopy()
  const {
    isLoading: fileStatusesLoading,
    error: fileStatusesError,
    data: fileStatusesData,
    requestSuccessful: fileStatusesSuccessful,
    fetchPlayerFileStatuses,
  } = useFetchCampaignPlayerFileStatuses()

  const [openEditCampaign, setOpenEditCampaign] = React.useState<string>(
    defaultCampaignBuilderContext.openEditCampaign
  )

  const refreshCampaignsList = async () => {
    await fetchAllCampaigns()
    await fetchPlayerFileStatuses()
  }

  useRecurringAction(refreshCampaignsList, minutesToMilliseconds(5))

  return (
    <CampaignBuilderContext.Provider
      value={{
        defaultLegalCopy:
          defaultLegalCopyData?.data.defaultLegalCopy ??
          defaultCampaignBuilderContext.defaultLegalCopy,
        isDefaultLegalCopyLoading: defaultLegalCopyIsLoading,
        fetchAllCampaigns,
        openEditCampaign: openEditCampaign,
        setOpenEditCampaign: setOpenEditCampaign,
        playerFilesStatusData: fileStatusesData,
        playerFilesStatusLoading: fileStatusesLoading,
        playerFilesStatusError: fileStatusesError,
        playerFilesStatusRequestSuccessful: fileStatusesSuccessful,
        fetchPlayerFileStatuses: fetchPlayerFileStatuses,
      }}
    >
      <CampaignsPageUI
        campaignsLoading={isLoading}
        campaignsError={error}
        campaigns={data?.data?.campaigns ?? []}
        createCampaignDisabled={
          !userHasAnyOfPermissions([
            CouponCampaignPermissions.CAN_WRITE_COUPON_CAMPAIGNS,
          ])
        }
      />
    </CampaignBuilderContext.Provider>
  )
}
