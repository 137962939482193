import { useEffect } from 'react'
import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { CheckRunPaymentsResponse } from './api-types'

const BASE_URL = `${config.SERVER_BASE_URL}/api/v1/checkRun`
export const UseFetchCheckRunPayments = (id: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<CheckRunPaymentsResponse>()

  useEffect(() => {
    fetchUrl(`${BASE_URL}/${id}/payments`)
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}
