import React from "react"
import { VoidReissueFormFields } from "../../../../pages/PaymentsPages/CreateVoidReissuePage/create-void-reissue-page-container"
import { SelectInputUI, SelectOption, TextFieldType, TextInputUI } from "../../../CommonComponents/FormikInputComponent/input-ui"
import { Countries } from "../../../../Constants/Countries"
import { PayeeType, PaymentSource } from "../../../../api/payment-details-fetcher/api-types"
import './styles.scss'

export type CreateVoidReissueFormUIProps = {
  payment: VoidReissueFormFields
  paymentSource: PaymentSource
}

const countryOptions: SelectOption[] = Countries.map((country: string) => {
  return {
    label: country,
    value: country
  }
})

const annuitiesFormMessage = 'The information below must be updated through the Annuities system.'
const nonAnnuitiesFormMessage = 'Make sure the information below is correct.'
export const CreateVoidReissueFormUI = (props: CreateVoidReissueFormUIProps) => {
  const { payment, paymentSource } = props
  
  const isAnnuities = paymentSource === PaymentSource.ANNUITIES
  const formMessage = isAnnuities ? annuitiesFormMessage : nonAnnuitiesFormMessage

  const ROOT_CLASS = 'create-void-reissue-form'
  return (
    <div className={`${ROOT_CLASS}-container`}>
      <div className={`${ROOT_CLASS}-header`}>
        <h4>Winner Info</h4>
        <p>{ formMessage }</p>
      </div>
      <div className={ROOT_CLASS}>
        {/* Name Fields */}
        {payment.payeeType === PayeeType.ENTITY ?
          <TextInputUI
            type={ TextFieldType.TEXT }
            label={ 'Freeform Name *' }
            name={ 'payeeFreeformName' }
            disabled={ isAnnuities }
          /> : <>
            <TextInputUI
              type={ TextFieldType.TEXT }
              label={ 'First Name *' }
              name={ 'payeeFirstName' }
              disabled={ isAnnuities }
            />
            <TextInputUI
              type={ TextFieldType.TEXT } 
              label={ 'Middle Initial' }
              name={ 'payeeMiddleInitial' }
              disabled={ isAnnuities }
            />
            <TextInputUI
              type={ TextFieldType.TEXT } 
              label={ 'Last Name *' }
              name={ 'payeeLastName' }
              disabled={ isAnnuities }
            />
          </>
        }

        {/* Remaining Payee Details */}
        <TextInputUI
          type={ TextFieldType.TEXT } 
          label={ 'TIN *' } 
          name={ 'tin' }
          disabled={ isAnnuities }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Mailing Address 1 *' } 
          name={ 'address1' }
          disabled={ isAnnuities }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Mailing Address 2' } 
          name={ 'address2' }
          disabled={ isAnnuities }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'City *' } 
          name={ 'city' }
          disabled={ isAnnuities }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'State/Region *' }
          name={ 'state' }
          disabled={ isAnnuities }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Zip/Postal Code *' }
          name={ 'zip' }
          disabled={ isAnnuities }
        />
        <SelectInputUI
          label={ 'Country' }
          name={ 'country' }
          options={ countryOptions }
          disabled={ isAnnuities }
        />
        {
          isAnnuities ? 
            <>
              <TextInputUI
                type={ TextFieldType.TEXT } 
                label={ 'Supplemental Address - Line 1' }
                name={ 'supplementalAddress1' }
                disabled={ isAnnuities }
              />
              <TextInputUI
                type={ TextFieldType.TEXT } 
                label={ 'Supplemental Address - Line 2' }
                name={ 'supplementalAddress2' }
                disabled={ isAnnuities }
              />
            </> : null
        }
        <TextInputUI 
          type={ TextFieldType.TEL } 
          label={ 'Phone Number' }
          name={ 'phone' }
          disabled={ isAnnuities }
        />
        <TextInputUI 
          type={ TextFieldType.EMAIL } 
          label={ 'Email' } 
          name={ 'email' }
          disabled={ isAnnuities }
        />
      </div>
    </div>
        
  )
}
