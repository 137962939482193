import * as React from 'react'
import { Link } from 'react-router-dom'
import { AllPromotionalMessagesPermissions } from '../../../api/playslips-fetcher/permissions'
import {
  AllCouponCampaignPermissions,
  AllPromotionCampaignPermissions,
  AllWinnersManagementPermissions,
} from '../../../api/promotions-fetcher/permissions'
import { AuthorizedContainer } from '../../../components/CommonComponents/authorized-container-component/authorized-container-ui'
import {
  campaignsRoutePath,
  promotionCampaignBuilderRoutePath,
  promotionMessagingRoutePath,
  winnersManagementRoutePath,
} from '../../../config.js'
import { getBlockClass } from '../../../utilities/helpers'
import './styles.scss'

const ROOT_CLASS = 'promotions-landing-page-ui'

export const PromotionsLandingPageUI = () => {
  return (
    <div className={ROOT_CLASS}>
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Promotions
      </header>
      <div className={getBlockClass(ROOT_CLASS, 'container')}>
        <AuthorizedContainer permissions={AllCouponCampaignPermissions}>
          <Link
            role="button"
            className={getBlockClass(ROOT_CLASS, 'nav-button')}
            to={campaignsRoutePath}
          >
            Coupons
          </Link>
        </AuthorizedContainer>
        <AuthorizedContainer permissions={AllWinnersManagementPermissions}>
          <Link
            role="button"
            className={getBlockClass(ROOT_CLASS, 'nav-button')}
            to={winnersManagementRoutePath}
          >
            Winners Management
          </Link>
        </AuthorizedContainer>
        <AuthorizedContainer permissions={AllPromotionalMessagesPermissions}>
          <Link
            role="button"
            className={getBlockClass(ROOT_CLASS, 'nav-button')}
            to={promotionMessagingRoutePath}
          >
            Playslip Promotion Messaging
          </Link>
        </AuthorizedContainer>
        <AuthorizedContainer permissions={AllPromotionCampaignPermissions}>
          <Link
            role="button"
            className={getBlockClass(ROOT_CLASS, 'nav-button')}
            to={promotionCampaignBuilderRoutePath}
          >
            Promotion Campaign Builder
          </Link>
        </AuthorizedContainer>
      </div>
    </div>
  )
}
