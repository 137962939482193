import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {licenseeRoutePath} from "../../../../../config.js";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import moment from "moment";

export const ViewOrganizationDetailsComponent = ({licenseeDetails}: any) => {

    const history: any = useHistory();

    const userpolicies: any = localStorage.getItem("userpolicies");

    useEffect(() => {
    });

    return (
        <>
            <div className={`${styles['view-org-details-container']}`}>
                <div className={`${styles['view-org-details-container-title-container']}`}>
                    <div className={`${styles['view-org-details-container-title']}`}>Organization</div>
                    {checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                        <div tabIndex={0} className={`${styles['view-org-details-container-update-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     history.push(`${licenseeRoutePath}/update/org/${licenseeDetails.licenseDetails.licenseNumber}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${licenseeRoutePath}/update/org/${licenseeDetails.licenseDetails.licenseNumber}`);
                             }}>Update
                        </div> : null}
                </div>
                <div className={`${styles['view-org-details-controls-container']}`}>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Org. Name</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.orgName}</div>
                    <div className={`${styles['view-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Org. Telephone</div>
                    <div className={`${styles['view-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.orgPhone}</div>
                    <div className={`${styles['view-org-details-controls-container-column-5']} ${styles['mslc-label']}`}>Date Org. Chartered</div>
                    <div className={`${styles['view-org-details-controls-container-column-6']} ${styles['mslc-label']}`}>{moment(licenseeDetails.orgDetails.orgDateChartered).format('MM/DD/YYYY')}</div>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Street Address</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.orgAddress.streetAddress}</div>
                    <div className={`${styles['view-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Mailing & Shipping Address</div>
                    <div className={`${styles['view-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.mailAddress.streetAddress}</div>
                    <div className={`${styles['view-org-details-controls-container-column-5']} ${styles['mslc-label']}`}>Organization F.I.D</div>
                    <div className={`${styles['view-org-details-controls-container-column-6']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.orgFID}</div>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.orgAddress.city}</div>
                    <div className={`${styles['view-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>City/Town</div>
                    <div className={`${styles['view-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.mailAddress.city}</div>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.orgAddress.zip}</div>
                    <div className={`${styles['view-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Zipcode</div>
                    <div className={`${styles['view-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{licenseeDetails.orgDetails.mailAddress.zip}</div>

                </div>
            </div>


        </>
    );
};