import {makeHttpCall} from '../../../services/MakeHttpCall';
import config from './../../../config.js';
import {RejectFileDetailsSearchCriteria} from './interfaces';


let formik: any;
let setPageMessageType: any;
let setButtonClicked: any;
let history: any;
let setRejectFileDetailsSearchResponse: any
let setRejectFileHeaderTitle: any
export const setVariables = (formikObj: any, historyObject: any,
                             pageMessageDefaultObj: any, setPageMessageObj: any, setButtonClickedObj: any,
                             setRejectFileDetailsSearchResponseObj: any,
                             setRejectFileHeaderTitleObj: any) => {
    formik = formikObj;
    history = historyObject;
    setPageMessageType = setPageMessageObj;
    setButtonClicked = setButtonClickedObj;
    setRejectFileDetailsSearchResponse = setRejectFileDetailsSearchResponseObj
    setRejectFileHeaderTitle = setRejectFileHeaderTitleObj

}

export const refreshComponent = (response: any) => {
    console.log('UpdateProfile', response);
    if (!(response.error === undefined)) {
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}

export const onSubmit = async (values: RejectFileDetailsSearchCriteria) => {
}

export const getACHRejectFileDetails = async (rejectFileReferenceNumber: string) => {
    try {
        const options =
            {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/ach/reject/${rejectFileReferenceNumber}`,
            }
        const response:any = await makeHttpCall(options);
        // console.log(JSON.stringify(response))
        let responseData:any = response.data;
        if (response.data.length === 0) {
            setRejectFileDetailsSearchResponse([]);
            setRejectFileHeaderTitle("")
            setPageMessageType({message: "No Results Found.", messageType: "SUCCESS"});
        } else {
            setRejectFileHeaderTitle("Reject File ");
            setButtonClicked(true);
            setRejectFileDetailsSearchResponse(response.data);
        }
    } catch (e) {
        setButtonClicked(true);
        setRejectFileDetailsSearchResponse([]);
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    }

}


export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: RejectFileDetailsSearchCriteria) => {
    const errors = {};
    /*if (values.rejectFileReferenceNumber) {
        if (values.rejectFileReferenceNumber.length > 0 && values.rejectFileReferenceNumber.length !== 7) {
            Object.assign(errors, {batchNumber: 'Invalid Batch Number'});
        }
    }*/
    return errors;
}


