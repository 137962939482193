import React, { useEffect, useState } from 'react'
import { useFetchFuturePaymentsForSearch } from '../../../../pages/FinancePages/CheckRunQueuePage/use-fetch-future-payments-search'
import { GenericErrorDialogComponent } from '../../../CommonComponents/GenericErrorDialogComponent'
import { LoadingSpinner } from '../../../loading-spinner'
import { AllFuturePaymentsUI } from './all-future-payments-ui'
import { SearchFuturePaymentsRequest } from '../../../../api/future-payments-fetcher/api-types'

export type AllFuturePaymentsContainerProps = {
  taxAdjustmentStatusLoading: boolean
}

export const AllFuturePaymentsContainer = (props: AllFuturePaymentsContainerProps) => {
  const [ searchCriteria, setSearchCriteria ] = useState<SearchFuturePaymentsRequest>()

  const { isLoading, data, error, searchHandler } =
    useFetchFuturePaymentsForSearch()
  const [showErrorDialog, setShowErrorDialog] = useState<Boolean>()
  useEffect(() => {
    // Only show error dialog if a request isn't in-flight.
    setShowErrorDialog(!isLoading && Boolean(error))
  }, [isLoading, error])

  const [oldTaxAdjustmentStatusLoading, setOldTaxAdjustmentStatusLoading] = useState(false)
  if(oldTaxAdjustmentStatusLoading !== props.taxAdjustmentStatusLoading) {
    if(!props.taxAdjustmentStatusLoading) {
      // tax adjustments just finished loading, so re-send the search to get the new tax adjustment statuses
      searchHandler(searchCriteria)
    }
    setOldTaxAdjustmentStatusLoading(props.taxAdjustmentStatusLoading)
  }

  return (
    <>
      <LoadingSpinner showSpinner={isLoading} />
      <AllFuturePaymentsUI
        futurePaymentsData={data}
        searchHandler={searchHandler}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
      />
      {showErrorDialog && (
        <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog} />
      )}
    </>
  )
}
