import { FormikValues } from "formik";
import config from "../../../../config.js";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { TimePaymentAccountResponse } from "../../../../types/Annuities/responses/time-payment-account.js";

export const validate = (values: FormikValues) => {
  const errors = {};

  if (values.investAcctNum === "") {
    Object.assign(errors, { investAcctNum: "This field is Required" });
  }
  return errors;
};

export const updateTpaAccount = async (
  tpaDetails: TimePaymentAccountResponse,
  values: FormikValues
) => {
  // create body for TPA object with updates on status, notes, IAN
  const body = {
    ...tpaDetails,
    tpaStatus: values.status,
    notes: values.notes,
    investAcctNum: values.investAcctNum === "" ? null : values.investAcctNum,
  };

  const options = {
    method: "PUT",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaDetails.id}`,
    data: body,
  };

  try {
    await makeHttpCall(options);
  } catch (e) {
    throw new Error("Failed to Update Status");
  }
};
