import React from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { AllPromotionReportingPermissions } from '../../../api/promotions-fetcher/permissions'
import { reportingRoutePath } from '../../../config'
import { permissions } from '../../../constants'
import FinanceComponent from '../../../Finance'
import CheckRunReportingMenuComponent from '../../../Finance/CheckRunReportingMenu'
import DailyReconciliationReportComponent from '../../../Finance/DailyReconciliationReport'
import ReconciliationReportMenuComponent from '../../../Finance/ReconciliationReportMenu'
import {
  checkAllRenderPermissions,
  checkRenderPermissions,
} from '../../../helpers'
import { PrivateRoute } from '../../../routes'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { Forbidden409Page } from '../../CommonsPage/Forbidden409Page'
import { ACHActivityDetailPage } from '../../FinancePages/ACHActivityDetailPage'
import { ACHActivityPage } from '../../FinancePages/ACHActivityPage'
import { ACHRejectFilePage } from '../../FinancePages/ACHRejectFilePage'
import { ACHTaxBatchDetailsPage } from '../../FinancePages/ACHTaxBatchDetailsPage'
import { CheckLoadReportPageContainer } from '../../FinancePages/CheckLoadReportPage/check-load-report-page-container'
import { CheckRegisterReportPageContainer } from '../../FinancePages/CheckRegisterReportPage/check-register-report-page-container'
import { CheckRunReportingPageContainer } from '../../FinancePages/CheckRunReportingPage/check-run-reporting-page-container'
import { OffsetsByACHPage } from '../../FinancePages/Offsets/OffsetsByACHPage'
import { OffsetsReportingPage } from '../../FinancePages/Offsets/OffsetsReportingPage'
import { PaperCheckFileDetailsPage } from '../../FinancePages/PaperCheckFileDetailsPage'
import { QuarterlyTaxTotalsReportingPage } from '../../FinancePages/QuarterlyTaxTotalsReportingPage'
import { ReconciliationPdfReportsPageContainer } from '../../FinancePages/ReconciliationPdfReportsPage/reconciliation-pdf-reports-page-container'
import { Split5754ReportingPageContainer } from '../../FinancePages/Split5754ReportingPage/split-5754-reporting-page-container'
import { W2GYearEndReportingPage } from '../../FinancePages/YearEndReportingPage'
import { PromotionsReportingPageContainer } from '../../PromotionsPages/promotions-reporting-page/promotions-reporting-page-container'
import { YearEndReportingPage } from '../year-end-reporting-page/year-end-reporting-page-ui'
import { Agent1099YearEndReportingPageContainer } from '../agent-1099-year-end-reporting-page/agent-1099-year-end-reporting-page-container'
import { Agent1099ReportingPageContainer } from '../agent-1099-reporting-page/agent-1099-report-container'

const ClaimsSupportRoutes = ({ props, storesObject }: any) => {
  const playerId: string = `${
    props.location.pathname.split('/')[
      props.location.pathname.split('/').length - 2
    ]
  }`
  const claimsId: string = `${
    props.location.pathname.split('/')[
      props.location.pathname.split('/').length - 1
    ]
  }`
  const urlVariables: string = `${playerId}/${claimsId}`

  const userpolicies: any = localStorage.getItem('userpolicies')

  return (
    <>
      <Switch>
        <PrivateRoute
          path={`${reportingRoutePath}/finance/reconciliation-menu`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_DAILY_RECONCILIATION,
              JSON.parse(userpolicies)
            ) ? (
              <ReconciliationReportMenuComponent
                title="Daily Reconciliation Report"
                {...props}
                showBackButton={true}
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />
        <PrivateRoute
          path={`${reportingRoutePath}/finance/check-run-reporting-menu`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_VIEW_CHECK_RUN_REPORTS,
              JSON.parse(userpolicies)
            ) ||
            checkAllRenderPermissions(
              [permissions.CAN_SEE_CHECK_LOAD_REPORT, permissions.CAN_SEE_SSN],
              JSON.parse(userpolicies)
            ) ? (
              <CheckRunReportingMenuComponent
                title="Check Run Reporting"
                {...props}
                showBackButton={true}
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />
        <PrivateRoute
          path={`${reportingRoutePath}/finance/claims/dailyreconciliation`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_DAILY_RECONCILIATION,
              JSON.parse(userpolicies)
            ) ? (
              <DailyReconciliationReportComponent
                title="Mobile Claims Daily Reconciliation Report"
                {...props}
                showBackButton={true}
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />
        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/general-reconciliation`}
          component={(props: any) =>
            checkAllRenderPermissions(
              [
                permissions.CAN_SEE_DAILY_RECONCILIATION,
                permissions.CAN_SEE_SSN,
              ],
              JSON.parse(userpolicies)
            ) ? (
              <ReconciliationPdfReportsPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/claims/papercheck/details`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_BATCH_REJECT_FILE_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <PaperCheckFileDetailsPage
                id={
                  props.location.pathname.split('/')[
                    props.location.pathname.split('/').length - 1
                  ]
                }
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/claims/batch/details`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_BATCH_REJECT_FILE_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <ACHActivityDetailPage
                title="Batch Details"
                id={
                  props.location.pathname.split('/')[
                    props.location.pathname.split('/').length - 1
                  ]
                }
                showBackButton={true}
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/claims/reject-file/details`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_BATCH_REJECT_FILE_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <ACHRejectFilePage
                id={
                  props.location.pathname.split('/')[
                    props.location.pathname.split('/').length - 1
                  ]
                }
                title="Tax Batch Details"
                {...props}
                showBackButton={true}
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/claims/tax-file/details`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_BATCH_REJECT_FILE_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <ACHTaxBatchDetailsPage
                id={
                  props.location.pathname.split('/')[
                    props.location.pathname.split('/').length - 1
                  ]
                }
                title="Tax Batch Details"
                {...props}
                showBackButton={true}
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/claims/transactions`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_ACH_ACTIVITY,
              JSON.parse(userpolicies)
            ) ? (
              <ACHActivityPage storesObject={storesObject} />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/offsets/reporting/ach`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_OFFSETS_BY_ACH,
              JSON.parse(userpolicies)
            ) ? (
              <OffsetsByACHPage
                batchNumber={
                  props.location.pathname.split('/')[
                    props.location.pathname.split('/').length - 1
                  ]
                }
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/offsets/reporting`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_OFFSETS_REPORTING,
              JSON.parse(userpolicies)
            ) ? (
              <OffsetsReportingPage />
            ) : (
              <Forbidden409Page />
            )
          }
        />


        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/year-end/agent-1099`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_YEAR_END_REPORTS,
              JSON.parse(userpolicies)
            ) ? (
              <Agent1099YearEndReportingPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/year-end/w2g`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_YEAR_END_REPORTS,
              JSON.parse(userpolicies)
            ) ? (
              <W2GYearEndReportingPage />
            ) : (
              <Forbidden409Page />
            )
          }
        />
        
        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/year-end/`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_YEAR_END_REPORTS,
              JSON.parse(userpolicies)
            ) ? (
              <YearEndReportingPage />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/split-5754`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_5754_SPLIT_REPORTS,
              JSON.parse(userpolicies)
            ) ? (
              <Split5754ReportingPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/quarterly-tax-totals`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_QUARTERLY_TAX_TOTAL_REPORTS,
              JSON.parse(userpolicies)
            ) ? (
              <QuarterlyTaxTotalsReportingPage />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/check-run`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_VIEW_CHECK_RUN_REPORTS,
              JSON.parse(userpolicies)
            ) ? (
              <CheckRunReportingPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/check-register`}
          component={(props: any) =>
            checkAllRenderPermissions(
              [
                permissions.CAN_SEE_CHECK_REGISTER_REPORT,
                permissions.CAN_SEE_SSN,
              ],
              JSON.parse(userpolicies)
            ) ? (
              <CheckRegisterReportPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance/reporting/check-load`}
          component={(props: any) =>
            checkAllRenderPermissions(
              [permissions.CAN_SEE_CHECK_LOAD_REPORT, permissions.CAN_SEE_SSN],
              JSON.parse(userpolicies)
            ) ? (
              <CheckLoadReportPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reportingRoutePath}/finance`}
          component={(props: any) => (
            <FinanceComponent
              title="Finance/Payments"
              {...props}
              showBackButton={true}
            />
          )}
        />
        <PrivateRoute
          path={`${reportingRoutePath}/promotions`}
          component={(_props: any) =>
            userHasAnyOfPermissions(AllPromotionReportingPermissions) ? (
              <PromotionsReportingPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />
        <PrivateRoute
          path={`${reportingRoutePath}/agent-1099-report`}
          component={(_props: any) =>
            userHasAnyOfPermissions([permissions.CAN_SEE_1099_REPORTS]) ? (
              <Agent1099ReportingPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />
      </Switch>
    </>
  )
}
export default withRouter(ClaimsSupportRoutes)
