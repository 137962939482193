import React, { useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import { getPageData, normilizeAmount, normilizeDate } from './functions'
import { useHistory } from 'react-router-dom'
import { PlayerTicketScanningActivityInterface } from './interface'
import { keyPressedType } from '../../../services/Commons'
import { setDateFormat } from '../PlayerSessionActivityTableComponent/functions'

import { claimEligibleDefaultToNo } from '../TicketScanningActivityTableComponent/functions'
import { playerSupportRoutePath } from './../../../config.js'
import { CircularProgress } from '@material-ui/core'

import { checkRenderPermissions } from './../../../helpers.js'
import { permissions } from './../../../constants.js'

import moment from 'moment-timezone'
import { AccessibleKey } from '../../common'

export const PlayerTicketScanningActivityTableComponent = ({
  PlayerTicketScanningActivityData,
  isDataPerformedTicket,
  pageMessage,
  id,
  setOpenBarcode,
}: any) => {
  const userpolicies: any = localStorage.getItem('userpolicies')
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState<number>(0)

  return (
    <>
      <div className={styles['KYCQUEUE-table']}>
        {PlayerTicketScanningActivityData.length <= 0 &&
          !isDataPerformedTicket.value &&
          pageMessage.message === '' && (
            <div
              style={{
                position: 'absolute',
                zIndex: 50,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)',
                color: 'white',
              }}
            >
              <CircularProgress size="8em" color="inherit" thickness={2} />
            </div>
          )}
        <div className={styles['KYCQUEUE-table-data-header']}>
          <div className={styles['KYCQUEUE-table-data-header-inner-container']}>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-header-title']}
            >
              Ticket Scanning Activity
            </div>
            <div
              tabIndex={0}
              className={styles['KYCQUEUE-table-data-header-text']}
            >
              10 most recent
            </div>
          </div>
          {checkRenderPermissions(
            permissions.CAN_SEE_TICKET_SCANNING_ACTIVITY,
            JSON.parse(userpolicies)
          ) && (
            <div
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  history.push(
                    `${playerSupportRoutePath}/ticket-scanning/${id}`
                  )
                }
              }}
              onClick={() =>
                history.push(`${playerSupportRoutePath}/ticket-scanning/${id}`)
              }
              role="button"
              className={styles['table-profile-content-button']}
            >
              <div className={styles['table-profile-content-button-text']}>
                View All
              </div>
            </div>
          )}
        </div>

        <div
          className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}
        >
          <div tabIndex={0}>Date</div>
          <div tabIndex={0}>Barcode</div>
          <div tabIndex={0}>Game</div>
          <div tabIndex={0}>Ticket Status</div>
          <div tabIndex={0}>Winning Amount</div>
          <div className={styles['KYCQUEUE-table-data-column-4']} tabIndex={0}>
            Mobile Claim Eligible
          </div>
        </div>
        <div className={styles['KYCQUEUE-table-data-body']}>
          {PlayerTicketScanningActivityData.length > 0 &&
            getPageData(PlayerTicketScanningActivityData, currentPage).map(
              (e: PlayerTicketScanningActivityInterface | any, i: number) => (
                <div
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)',
                  }}
                  className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}
                >
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-text']}
                  >
                    {e.requestTimestamp === null
                      ? ''
                      : moment
                          .tz(e.requestTimestamp, 'America/New_York')
                          .format('MM/DD/YYYY')}
                    &nbsp;&nbsp;&nbsp;
                    {moment
                      .tz(e.requestTimestamp, 'America/New_York')
                      .format('h:mm:ss a')}
                  </div>
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-link']}
                    onClick={() => setOpenBarcode(e.barcode)}
                    onKeyDown={(keyEvent) => {
                      if (
                        keyEvent.key === AccessibleKey.enter ||
                        keyEvent.key === AccessibleKey.space
                      ) {
                        keyEvent.preventDefault()
                        setOpenBarcode(e.barcode)
                      }
                    }}
                    aria-label={`View Barcode Details for ${e.barcode}`}
                  >
                    {e.barcode === null ? '' : e.barcode}
                  </div>
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-text']}
                  >
                    {e.gameType === null ? '' : e.gameType}
                  </div>
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-text']}
                  >
                    {e.status === null ? '' : e.status}
                  </div>
                  <div
                    tabIndex={0}
                    className={`${styles['KYCQUEUE-table-data-data-text']} ${styles['KYCQUEUE-table-data-column-3']}`}
                  >
                    {e.prizeAmount === null
                      ? '- -'
                      : normilizeAmount(e.prizeAmount)}
                  </div>
                  <div
                    tabIndex={0}
                    className={`${styles['KYCQUEUE-table-data-data-text']} ${styles['KYCQUEUE-table-data-column-4']}`}
                  >
                    {claimEligibleDefaultToNo(e.claimOffered)}
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </>
  )
}
