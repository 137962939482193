import * as React from 'react'

export const useRecurringAction = (
  action: () => void,
  intervalMs: number,
  deps?: React.DependencyList,
  condition = true
) => {
  React.useEffect(() => {
    if (condition) {
      const id = setInterval(action, intervalMs)
      return () => clearInterval(id)
    }
  }, deps)
}
