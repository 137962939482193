import * as React from 'react'
import moment from 'moment'
import { getBlockClass } from '../../../../../utilities/helpers'
import { CheckboxComponent } from '../../../../CommonComponents/CheckboxComponent'
import {
  ContactChecklistRequestBody,
  ContactChecklistResponse,
  PromotionDrawingWinner,
} from '../../../../../api/promotions-fetcher/constants'
import './styles.scss'

const ROOT_CLASS = `contact-checklist-contact-info`
const CHECKBOX_CLASS = getBlockClass(ROOT_CLASS, 'checkbox-container')

export type ToggleChecklistItems = {
  firstContact?: boolean
  secondContact?: boolean
  thirdContact?: boolean
}

type WinnersManagementContactChecklistSectionProps = {
  winner: PromotionDrawingWinner
  checklistDisabled: boolean
  setRefreshRequired: (required: boolean) => void
  submitUpdateContactChecklist: (
    updatedList: ContactChecklistRequestBody
  ) => Promise<void>
  updatedChecklist?: ContactChecklistResponse
}

const getContactedDate = (contactedDate?: string) =>
  contactedDate ? ` • ${moment(contactedDate).format('MM/DD/YYYY')}` : ''

export const WinnersManagementContactChecklistSection = (
  props: WinnersManagementContactChecklistSectionProps
) => {
  const [contactList, setContactList] =
    React.useState<ContactChecklistResponse>({
      firstContact: props.winner.firstContact,
      secondContact: props.winner.secondContact,
      thirdContact: props.winner.thirdContact,
    })

  const submitUpdatedList = async (toggleList: ToggleChecklistItems) => {
    const currentChecklist = {
      firstContact: contactList.firstContact.contacted,
      secondContact: contactList.secondContact.contacted,
      thirdContact: contactList.thirdContact.contacted,
    }
    const updatedList = { ...currentChecklist, ...toggleList }
    await props.submitUpdateContactChecklist(updatedList)
    props.setRefreshRequired(true)
  }

  React.useEffect(() => {
    setContactList(props.updatedChecklist ?? contactList)
  }, [props.updatedChecklist])

  return (
    <div className={ROOT_CLASS}>
      <header>Contact Checklist</header>
      <div>
        <button
          className={CHECKBOX_CLASS}
          tabIndex={0}
          onClick={async () => {
            if (!props.checklistDisabled) {
              await submitUpdatedList({
                firstContact: !contactList.firstContact.contacted,
              })
            }
          }}
          aria-label="Toggle first contact"
        >
          <CheckboxComponent
            isChecked={contactList.firstContact.contacted}
            disabled={props.checklistDisabled}
            color="black"
          />
        </button>
        <span>
          14 days • letter
          {getContactedDate(contactList.firstContact.date)}
        </span>
      </div>
      <div>
        <button
          className={CHECKBOX_CLASS}
          tabIndex={0}
          onClick={async () => {
            if (!props.checklistDisabled) {
              await submitUpdatedList({
                secondContact: !contactList.secondContact.contacted,
              })
            }
          }}
          aria-label="Toggle second contact"
        >
          <CheckboxComponent
            isChecked={contactList.secondContact.contacted}
            disabled={props.checklistDisabled}
            color="black"
          />
        </button>
        <span>
          1 month • call winner
          {getContactedDate(contactList.secondContact.date)}
        </span>
      </div>
      <div>
        <button
          className={CHECKBOX_CLASS}
          tabIndex={0}
          onClick={async () => {
            if (!props.checklistDisabled) {
              await submitUpdatedList({
                thirdContact: !contactList.thirdContact.contacted,
              })
            }
          }}
          aria-label="Toggle third contact"
        >
          <CheckboxComponent
            isChecked={contactList.thirdContact.contacted}
            disabled={props.checklistDisabled}
            color="black"
          />
        </button>
        <span>
          2 months • call winner
          {getContactedDate(contactList.thirdContact.date)}
        </span>
      </div>
    </div>
  )
}
