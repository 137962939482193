import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import styles from './css/styles.module.css'
import { PLAYERS_PAGES } from '../../../../application-data-structure/players-page.data-structure'
import eventEmitter from './../../../../services/EventEmitter'
import { PermissionsActivityTableComponent } from '../../../../components/PermissionsComponents/PermissionsActivityTableComponent'
import {
  getSignIn,
  getTicketScanning,
  refreshComponent,
  setVariables,
  toggleAccountStatus,
  toggleMobileClaims,
} from './functions'
import { ConfirmPopupModal } from '../../../../components/CommonComponents/ConfirmPopupModal/confirm-popup-modal'

const timerArray: any = []
let eventEmitterInitialize: any = undefined
const PlayerPermissionPage = ({
  id,
  storesObject,
  pageMessage,
  setPageMessage,
}: any) => {
  const [isSignInEnabled, setSignInEnabled] = useState<any>(false)
  const [isTicketScanning, setIsTicketScanning] = useState<any>(false)

  const [isMobileClaimsEnabled, setIsMobileClaimsEnabled] = useState<any>(false)
  const [mobileClaimsConfirmIsOpen, setMobileClaimsConfirmIsOpen] =
    useState<boolean>(false)
  const [mobileClaimsToggleIsLoading, setMobileClaimsToggleIsLoading] =
    useState<boolean>(false)
  const [mobileClaimsToggleSuccessful, setMobileClaimsToggleSuccessful] =
    useState<boolean>(false)

  const [isManageMobile, setIsManageMobile] = useState<any>({ value: false })
  const [requestSignIn, setRequestSignIn] = useState<any>(false)
  const [requestTicket, setRequestTicket] = useState<any>(false)
  const [requestMobile, setRequestMobile] = useState<any>(false)

  const [isAccountStatusOpen, setIsAccountStatusOpen] = useState<any>({
    value:
      storesObject.playerStore.selectedPlayerDetails.identity.status ===
      'DEACTIVATED_IDENTITY'
        ? false
        : true,
  })

  const callingPage =
    PLAYERS_PAGES['PLAYERS_SUPPORT_HUB_PAGE']['TabsPlayersSupportHubPage']

  const sleep = (ms: any) => {
    const timer1 =
      requestSignIn &&
      setTimeout(
        (timerArray: any) => {
          timerArray.map((e: any) => {
            Object.keys(e).map((key: any) => {
              if (key === 'requestPerformedSession') clearTimeout(e[key])
            })
          })
          setRequestSignIn(false)
        },
        ms,
        timerArray
      )
    timerArray.push({ requestPerformedStatus: timer1 })
    const timer2 =
      requestTicket &&
      setTimeout(
        (timerArray: any) => {
          timerArray.map((e: any) => {
            Object.keys(e).map((key: any) => {
              if (key === 'requestPerformedMobile') clearTimeout(e[key])
            })
          })
          setRequestTicket(false)
        },
        ms,
        timerArray
      )
    timerArray.push({ requestPerformedTicket: timer2 })
    const timer3: any =
      requestMobile &&
      setTimeout(
        (timerArray: any) => {
          timerArray.map((e: any) => {
            Object.keys(e).map((key: any) => {
              if (key === 'requestPerformedTicket') clearTimeout(e[key])
            })
          })
          setRequestMobile(false)
        },
        ms,
        timerArray
      )
    timerArray.push({ requestPerformedMobile: timer3 })
  }
  setVariables(
    setPageMessage,
    setSignInEnabled,
    setIsTicketScanning,
    setIsAccountStatusOpen
  )
  if (eventEmitterInitialize === undefined) {
    eventEmitter.addListener('refresh-data-ready', refreshComponent)
  }

  useEffect(() => {
    setSignInEnabled({
      value: storesObject.playerStore.selectedPlayerDetails.account.enabled,
    })
    setIsTicketScanning({
      value:
        storesObject.playerStore.selectedPlayerDetails.identity.scanStatus ===
        'ENABLED'
          ? true
          : false,
    })
    setIsAccountStatusOpen({
      value:
        storesObject.playerStore.selectedPlayerDetails.identity.status ===
        'DEACTIVATED_IDENTITY'
          ? false
          : true,
    })
  }, [storesObject.playerStore.selectedPlayerDetails])

  useEffect(() => {
    setIsMobileClaimsEnabled(
      storesObject.playerStore.selectedPlayerDetails.identity.frequentCasher
        ? false
        : true
    )
  }, [storesObject.playerStore.selectedPlayerDetails.identity.frequentCasher])

  return (
    <>
      <ConfirmPopupModal
        headerText={`Are you sure you would like to ${
          isMobileClaimsEnabled ? 'disable' : 'enable'
        } Mobile Claims?`}
        buttonText="Confirm"
        isOpen={mobileClaimsConfirmIsOpen}
        closeModal={() => setMobileClaimsConfirmIsOpen(false)}
        error={pageMessage.message || undefined}
        loading={mobileClaimsToggleIsLoading}
        actionSuccessful={mobileClaimsToggleSuccessful}
        submitAction={() =>
          toggleMobileClaims(
            pageMessage,
            setPageMessage,
            isMobileClaimsEnabled,
            storesObject,
            setMobileClaimsToggleIsLoading,
            setMobileClaimsToggleSuccessful
          )
        }
      />

      <div className={styles['page-container']}>
        <div className={styles['players-details-container']}>
          <div className={styles['player-status-component-container']}>
            <PermissionsActivityTableComponent
              requestSignIn={requestSignIn}
              requestTicket={requestTicket}
              id={id}
              isSignInEnabled={isSignInEnabled}
              isTicketScanning={isTicketScanning}
              getSignIn={getSignIn}
              getTicketScanning={getTicketScanning}
              pageMessage={pageMessage}
              setPageMessage={setPageMessage}
              storesObject={storesObject}
              setIsTicketScanning={setIsTicketScanning}
              setRequestTicket={setRequestTicket}
              setSignInEnabled={setSignInEnabled}
              setRequestSignIn={setRequestSignIn}
              setIsAccountStatusOpen={setIsAccountStatusOpen}
              isAccountStatusOpen={isAccountStatusOpen}
              toggleAccountStatus={toggleAccountStatus}
              isMobileClaimsEnabled={isMobileClaimsEnabled}
              setMobileClaimsConfirmIsOpen={setMobileClaimsConfirmIsOpen}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(PlayerPermissionPage)
