export type OffsetsSystemAggregate = {
    childServicesTotalInCents: number
    dorTotalInCents: number
    comptrollerTotalInCents: number
    grandTotalInCents: number
  }
  
  export type OffsetsSystemAggregates = {
    checkWriterAggregates: OffsetsSystemAggregate
    claimCenterAggregates: OffsetsSystemAggregate
    rtcAggregates: OffsetsSystemAggregate
  }

export interface batchInformation {
    withholdingIds: {
            ssn: string,
            clientName: string,
            externalReferenceNumber: string,
            debtReceivedDate: string,
            interceptedDate: string,
            name: string,
            intercepts: string,
            offsetAmountInCents: number,
            agencyId: string,
            docNumber: string
        }[],
    aggregates: OffsetsSystemAggregates
}

const defaultSystemAggregate: OffsetsSystemAggregate = {
    childServicesTotalInCents: 0,
    dorTotalInCents: 0,
    comptrollerTotalInCents: 0,
    grandTotalInCents: 0
}

export const batchInformationDefaultData : batchInformation =  {
    withholdingIds: [{
        ssn: '',
        clientName: '',
        externalReferenceNumber: '',
        debtReceivedDate: '',
        interceptedDate: '',
        name: '',
        intercepts: '',
        offsetAmountInCents: 0,
        agencyId: '',
        docNumber: ''
    }],
    aggregates: {
        checkWriterAggregates: {...defaultSystemAggregate},
        claimCenterAggregates: {...defaultSystemAggregate},
        rtcAggregates: {...defaultSystemAggregate}
    }
}
