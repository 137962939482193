let resetSsnCount:string ="";
export const setSSNValue= (e:any,formik:any,setSsnErrorMessage:any) => {

   
   const testForLetters = e.target.value.split("-").join(""); 
   if(isNaN(testForLetters))
   {
      
       setSsnErrorMessage( "Letters are not allowed!" );
       return;
   }

    if( e.target.value.length === 3 && resetSsnCount < e.target.value)
       e.target.value +="-";
    else if(e.target.value.length === 6 && resetSsnCount < e.target.value)
       e.target.value +="-";
    else if(e.target.value.length > 11)
    {
      setSsnErrorMessage( "Only 9 digits allowed!" );
       return;
    }
    resetSsnCount = e.target.value;
    setSsnErrorMessage(undefined);
    formik.setFieldValue(e.target.name, e.target.value, true);
}


let numberClearOfClick = 0;
let fieldName:string = "";
export const clearSSNField = (e:any, formik:any) =>{
   
      
   if(!(e.target.value.split("").find((e:any) => e === "*") === undefined))
   {
      formik.setFieldValue(e.target.name, "", false);
      
   }
   else
    numberClearOfClick++;

}

export const clearField = (e:any, formik:any) =>{

}

export const resetClickCount = ()=>{
   numberClearOfClick = 0;

}
const getMonth = (val:any) => {
   switch (val) {
       case '01': return 'Jan'
       case '02': return 'Feb'
       case '03': return 'Mar'
       case '04': return 'Apr'
       case '05': return 'May'
       case '06': return 'Jun'
       case '07': return 'Jul'
       case '08': return 'Aug'
       case '09': return "Sep"
       case '10': return 'Oct'
       case '11': return 'Nov'
       case '12': return 'Dec'
       default: return "undefined"
   }
}

export const setDateFormat = (value:any) => {
   let date:any = value.split('T')[0];
   date = date.split('-');
   let year:any = date[0];
   let time:any = value.split('T')[1];
   time = time.split(".");
   time = time[0].split(":");
   let hr:any =parseInt(time[0]); 
   let period = hr >= 12 ? "pm" : "am";
   if(hr > 12)
   {
       hr = hr - 12;
   }

   return `${getMonth(date[1])} ${date[2]}, ${year}, ${hr}:${time[1]}:${time[2]} ${period}`;

}