import React, { SetStateAction, useState } from "react";
import styles from "./css/styles.module.css";
import { keyPressedType } from "../../../../../services/Commons";
import { KEY_CODES } from "../../../../../constants.js";
import { TimePaymentAccountPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";

export const SelectPayeeComponent: React.FunctionComponent<{
  setShowSelectPayee: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddSubAccount: React.Dispatch<React.SetStateAction<boolean>>;
  setPayeeObjectToAdd: React.Dispatch<React.SetStateAction<TimePaymentAccountPayee | undefined>>;
  tpaPayeeList: TimePaymentAccountPayee[];
  setIsPageInEditMode: React.Dispatch<SetStateAction<boolean>>;
}> = ({
  setShowSelectPayee,
  setShowAddSubAccount,
  tpaPayeeList,
  setIsPageInEditMode,
  setPayeeObjectToAdd,
}) => {
  // Required For Modal focus
  const FocusTrap = require("focus-trap-react");

  //state variable
  const [payeeId, setPayeeId] = useState<string>("");

  // function to set payee Object when selected
  const setPayeeObject = (payeeId: string) => {
    const selectedPayeeObject = tpaPayeeList.find(payee => payee.id === payeeId);
    setPayeeObjectToAdd(selectedPayeeObject);
  };

  return (
    <FocusTrap focusTrapOptions={{ initialFocus: false }}>
      <div className={styles["overlay-container"]}>
        <div className={styles["main-modal"]}>
          <div>
            <div className={styles["modal-title"]}>Select a Payee</div>
            <div className={styles["modal-desc"]}>
              Select a payee from the dropdown menu to assign it to the new sub-account.
            </div>
            <div className={`${styles.custom_select_div}`}>
              <select
                className={`${styles.custom_select}`}
                onChange={event => {
                  setPayeeId(event.target.value);
                }}
                name="selectPayee"
                id="selectPayee"
                defaultValue=""
              >
                <option value="" disabled>
                  Select Payee...
                </option>
                {tpaPayeeList.map((payee: TimePaymentAccountPayee, index: number) => (
                  <option key={index} value={payee.id}>
                    {payee.payeeType === "Individual"
                      ? `${payee.firstName} ${payee.lastName}`
                      : `${payee.freeformName}`}
                  </option>
                ))}
              </select>
              <span className={`${styles.custom_arrow}`}></span>
            </div>
          </div>
          <button
            type="button"
            onKeyPress={event => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                setShowAddSubAccount(true);
                setShowSelectPayee(false);
              }
            }}
            onClick={() => {
              if (payeeId === undefined || payeeId === "") {
                window.alert("Please select a Payee from the List");

                setShowSelectPayee(true);
              } else {
                setPayeeObject(payeeId);
                setShowAddSubAccount(true);
                setShowSelectPayee(false);
              }
            }}
            className={styles["modal-confirm-btn"]}
          >
            Add Payee &amp; Create
          </button>
          <button
            type="button"
            onKeyPress={event => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                setShowSelectPayee(false);
                setIsPageInEditMode(false);
              }
            }}
            onClick={() => {
              setShowSelectPayee(false);
              setIsPageInEditMode(false);
            }}
            className={styles["modal-cancel-btn"]}
          >
            Cancel
          </button>
        </div>
      </div>
    </FocusTrap>
  );
};
