import React, { useState, useContext, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { normalizePage, getPageData, getAuthorizationHistory } from "./functions";
import styles from "./css/styles.module.css";
import { BannerContext } from "../../../../context/BannerContext";
import { AuthorizationHistoryData } from "../../../../types/Annuities/TimePaymentAuthorization/AuthorizationHistory";
import { PaginationCommonComponent } from "../../../CommonComponents/PaginationCommonComponent";
import { AuthorizationHistoryListRowCmpt } from "../AuthorizationHistoryListRowCmpt";

export const AuthorizationHistoryCmpt: React.FC = () => {
  const bannerContext = useContext(BannerContext);

  // state variables
  const [authorizationHistoryData, setAuthorizationHistoryData] = useState<AuthorizationHistoryData[]>([]);
  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  //   useEffect here to get data
  useEffect(() => {
    const getAuthorizationHistoryData = async () => {
      try {
        const authorizationHistory = await getAuthorizationHistory();
        setAuthorizationHistoryData(authorizationHistory);
        setRequestCompleted(true);
      } catch (error) {
        setRequestCompleted(true);
        bannerContext.setBannerInfo({ message: `${error}`, error: true });
      }
    };

    getAuthorizationHistoryData();
  }, []);

  return (
    <>
      {/* Circular Loading Component */}
      {!requestCompleted && (
        <div
          style={{
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.3)",
            color: "white",
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}
      <main className={`${styles.initiate_chk_run_cmpt} w-100 px-2 rounded`}>
        {/* Authorization Table */}
        <div className={`${styles.table}`}>
          <div className={`${styles.table_results_display} d-flex align-items-center font-italic font-weight-bold`}>
            <div aria-live="assertive">{`Displaying ${authorizationHistoryData.length <= 0 ? 0 : currentPage * 10 + 1}-${normalizePage(authorizationHistoryData.length, currentPage)} of ${
              authorizationHistoryData.length
            } results`}</div>
          </div>
          <div className={`${styles.table_header} rounded-top font-weight-bold`}>
            <div>Date Submitted</div>
            <div>Time</div>
            <div>Gross Payment</div>
            <div>Net Payment</div>
            <div>Federal Tax</div>
            <div>State Tax</div>
          </div>

          <div className={`${styles.table_body}`}>
            {authorizationHistoryData.length > 0 ? (
              getPageData(authorizationHistoryData, currentPage).map((authorizationHistory: AuthorizationHistoryData, index: number) => <AuthorizationHistoryListRowCmpt authorizationHistory={authorizationHistory} key={index++} />)
            ) : (
              <div className={`${styles.table_body_text} text-center pt-2`}>
                <em>No Authorization History</em>
              </div>
            )}
          </div>
          <div className={`${styles.table_footer} p-2 rounded-bottom d-flex align-items-center justify-content-end mb-2`}>
            <div aria-live="assertive" className={`${styles.table_page_label} mr-2`}>{`Page ${authorizationHistoryData.length === 0 ? 0 : currentPage + 1} of ${Math.ceil(authorizationHistoryData.length / 10).toString()}`}</div>
            {<PaginationCommonComponent playerData={authorizationHistoryData} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
          </div>
        </div>
      </main>
    </>
  );
};
