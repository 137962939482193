import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { GenderCodes } from './../../../Constants/GenderCodes.js';
import { AddressStateCodes } from './../../../Constants/AddressStateCodes.js';
import { checkRenderPermissions } from './../../../helpers.js';
import { permissions } from  './../../../constants.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSSNValue, clearField, resetClickCount , clearSSNField,setDateFormat} from "./functions";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessageFieldCommonComponent } from './../../CommonComponents/ErrorMessageFieldCommonComponent';



export const UpadteProfileComponent = ({ formik , setFiledValue,ssnNumberIsBeenUsed,callingPage={} }: any) => {
   
    const [ ssnErrorMessage,setSsnErrorMessage] =useState<any>(undefined);
    const [ toggleSSN, setToggleSSN ] = useState(false);
    const userpolicy: string | any = localStorage.getItem('userpolicies');
    
    return (
        <>
           <form className={styles['page-container-body']}>
              <div tabIndex={0} className={styles['page-container-body-left-title']} >Profile</div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>First Name *</div>
                <input
                  autoComplete={"off"}
                  id="identity.firstName"
                  name="identity.firstName"
                  onClick={(e)=> clearField(e,formik) }
                  onChange={(e:any) =>  setFiledValue(e) }
                  onFocus={()=> resetClickCount()} 
                  className={styles["page-container-body-left-input-box"]}
                  value={formik.values.identity.firstName}
                  aria-label={`${formik.values.identity.firstName}.`}
                  type="text"
                  />
                  {formik.errors.firstName &&  <ErrorMessageFieldCommonComponent firstName={formik.errors.firstName} />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing Address 1 *</div>
                <input
                   autoComplete={"text"}            
                  id="identity.address1"
                  name="identity.address1"
                  onClick={(e)=> clearField(e,formik) }
                  onFocus={()=> resetClickCount()}
                  className={styles["page-container-body-left-input-box"]}
                  type="text"
                  onChange={(e:any) =>  {setFiledValue(e);}}
                  value={formik.values.identity.address1}
                  aria-label={`${formik.values.identity.address1}.`}
                   />
                   {formik.errors.address1 &&  <ErrorMessageFieldCommonComponent address1={formik.errors.address1} />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Last Name *</div>
                <input
                  autoComplete={"off"}
                  id="identity.lastName"
                  name="identity.lastName"
                  onFocus={()=> resetClickCount()}
                  onClick={(e)=> clearField(e,formik) }
                  onChange={(e:any) =>  setFiledValue(e) }
                  value={formik.values.identity.lastName}
                  className={styles["page-container-body-left-input-box"]}
                  aria-label={`${formik.values.identity.lastName}.`}
                  type="text"
                  />
                  {formik.errors.lastName &&  <ErrorMessageFieldCommonComponent lastName={formik.errors.lastName} />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing Address 2</div>
                <input
                   autoComplete={"text"}
                  id="identity.address2"
                  name="identity.address2"
                  onFocus={()=> resetClickCount()}
                
                  className={styles["page-container-body-left-input-box"]}
                  onChange={(e:any) =>  setFiledValue(e) }
                  value={formik.values.identity.address2}
                  aria-label={`${formik.values.identity.address2}.`}
                  type="text"
                  />
                    {formik.errors.address2 &&  <ErrorMessageFieldCommonComponent address2={formik.errors.address2} />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Date of Birth *</div>
                <input
                  autoComplete={"off"}
                  id="identity.dateOfBirth"
                  name="identity.dateOfBirth"
                  onFocus={()=> resetClickCount()}                
                  value={formik.values.identity.dateOfBirth}
                  aria-label={`${formik.values.identity.dateOfBirth}.`}
                  onChange={(e:any) =>  setFiledValue(e) }
                  className={`${styles["page-container-body-left-input-box"]} ${styles["page-container-body-left-input-box-date"]}`}
                  type="date" />
                    {formik.errors.dateOfBirth &&  <ErrorMessageFieldCommonComponent address2={formik.errors.dateOfBirth} />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City *</div>
                <input
                  autoComplete={"text"}
                  id="identity.city"
                  name="identity.city"
                  onFocus={()=> resetClickCount()}
                  onChange={(e:any) =>  setFiledValue(e) }
                  value={formik.values.identity.city}
                  aria-label = {`${formik.values.identity.city}.`}
                  className={styles["page-container-body-left-input-box"]}
                  type="text"
                   />
                   {formik.errors.city && <ErrorMessageFieldCommonComponent city={formik.errors.city} />}
              </div>
             
              
               { (callingPage['UPDATE_PROFILE_PAGE'] && checkRenderPermissions(permissions.CAN_UPDATE_PLAYER_SSN, JSON.parse(userpolicy))) && 
               
               <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                  {ssnNumberIsBeenUsed(true)}
                  <div tabIndex={0} className={styles["page-container-body-left-labels-name"]} >SSN *</div>
                  <div className={styles["page-container-body-left-input-box"]} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>                   
                      <input
                      autoComplete={"off"}
                      style={{width:'100%',height:'100%',border:'none'}}
                      id="identity.ssn"
                      name="identity.ssn"
                      onFocus={()=> resetClickCount()}
                      value={formik.values.identity.ssn } 
                      aria-label={`${formik.values.identity.ssn}.`}
                      onClick={(e:any) => clearSSNField(e,formik) }
                      onChange={(e:any) =>  setSSNValue(e,formik,setSsnErrorMessage) }                     
                      type={"text"}                     
                       />                      
                  </div>
                  
                  { (ssnErrorMessage || formik.errors.ssn) && <ErrorMessageFieldCommonComponent ssn={formik.errors.ssn} ssnErrorMessage={ssnErrorMessage} />}  
              </div>}
              
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Gender *</div>
                <select 
                id="identity.gender" 
                name="identity.gender"  
                onChange={(e:any) =>  setFiledValue(e) } 
                aria-label={`${formik.values.identity.gender}.`}
                className={styles["page-container-body-left-input-box"]}  >
                  {GenderCodes.map((g: string) => <option selected={formik.values.identity.gender === g ? true : false} value={g}>{g}</option>)}
                </select>
                {formik.errors.gender &&  <ErrorMessageFieldCommonComponent gender={formik.errors.gender} />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>State *</div>
                <select 
                 id="identity.state"
                 name="identity.state"
                 onFocus={()=> resetClickCount()}
                 onChange={(e:any) =>  setFiledValue(e) }
                 aria-label={`${formik.values.identity.state}.`}
                className={styles["page-container-body-left-input-box"]}  >
                  {
                    AddressStateCodes.map((g: string) =>
                      <option value={g} selected={formik.values.identity.state === g ? true : false}>{g}</option>
                    )
                  }
                </select>
                {formik.errors.state &&  <div style={{color:'red'}}>{formik.errors.state}</div>}
              </div>
               {(callingPage === 'MANUAL_KYC_PAGE') &&
               <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                   <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email *</div>
                   <input
                       autoComplete={"text"}
                       id="account.email"
                       name="account.email"
                       onFocus={() => resetClickCount()}
                       onChange={(e: any) => setFiledValue(e)}
                       value={formik.values.account.email}
                       aria-label={`${formik.values.account.email}.`}
                       className={styles["page-container-body-left-input-box"]}
                       type="text"
                   />
                   {formik.errors.email && <ErrorMessageFieldCommonComponent city={formik.errors.email}/>}
               </div>}
              <div className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["page-container-body-left-label-date-field"]}` }>
                <div tabIndex={0} >{`T&C's Date`}&nbsp;&nbsp;&nbsp;&nbsp;{`${formik.values.identity.termsAcceptedDate !== "" && setDateFormat(formik.values.identity.termsAcceptedDate)}`}</div>
              </div>
              <div className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["page-container-body-left-label-zip-field"]}`}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode *</div>
                <input
                  autoComplete={"text"}
                  id="identity.zip5"
                  name="identity.zip5"
                  value={formik.values.identity.zip5}
                  aria-label={`${formik.values.identity.zip5}.`}
                  onFocus={()=> resetClickCount()}
                  onClick={(e)=> clearField(e,formik) }
                  onChange={(e:any) =>  setFiledValue(e) } 
                  className={`${styles["page-container-body-left-input-box"]} ${styles['page-container-body-left-input-box-date']}`}
                  type="text"
                   />
                   {formik.errors.zip5 &&  <ErrorMessageFieldCommonComponent zip5={formik.errors.zip5} />}
              </div>
            </form>

        </>

    );
}