let raffleId: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (raffleIdObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    raffleId = raffleIdObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;

}


export const onSubmit = async (values: any) => {
    charitableGamingStore.updatePermitHolder(values, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {
    history.goBack();
}



