import * as React from 'react'
import moment from 'moment'
import { useFormik } from 'formik'
import { PromotionResponse } from '../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../utilities/helpers'
import { LoadingDots } from '../../../../CommonComponents/loading-dots/loading-dots-ui'
import { GenericPopupModal } from '../../../../GenericPopupModal/generic-popup-modal-ui'
import { validateScheduleDrawing } from './validation'
import { CustomDropdownField } from '../../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { DateAndTimeInput } from '../../../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import { FORMIK_DATE_FORMAT } from '../../promotion-campaign-sidebar/sidebar-forms/helpers'
import './styles.scss'

const ROOT_CLASS = 'promotion-campaign-schedule-drawing-ui'
const BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'button')

export type PromotionCampaignScheduleDrawingUIProps = {
  closeModal: () => void
  loading: boolean
  error?: string
  actionSuccessful: boolean
  onSuccess?: () => void
  scheduleDrawing: (
    promotionId: string,
    drawingGroupId: string,
    scheduledDrawTime: string,
    scheduledDrawCollectionEndTime: string
  ) => Promise<void>
  promotion: PromotionResponse
}

export const PromotionCampaignScheduleDrawingUI = (
  props: PromotionCampaignScheduleDrawingUIProps
) => {
  React.useEffect(() => {
    if (props.actionSuccessful) {
      props.onSuccess?.()
      props.closeModal()
    }
  }, [props.actionSuccessful])

  const drawingGroupsWithActiveDrawings = props.promotion.drawingGroups.filter(
    (group) => group.drawings.some((drawing) => drawing.isActive)
  )

  const initialValues = {
    drawingGroupIdentifier: drawingGroupsWithActiveDrawings[0].id,
    scheduledDrawTime: '',
    scheduledDrawCollectionEndTime: '',
  }

  const drawingGroupOptions = [
    ...drawingGroupsWithActiveDrawings.map((drawingGroup) => {
      return {
        key: drawingGroup.name,
        value: drawingGroup.id,
        display: drawingGroup.name,
      }
    }),
  ]

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      props.scheduleDrawing(
        props.promotion.id,
        values.drawingGroupIdentifier,
        moment(values.scheduledDrawTime, 'YYYY-MM-DDTHH:mm')
          .tz('America/New_York')
          .toISOString(),
        moment(values.scheduledDrawCollectionEndTime, 'YYYY-MM-DDTHH:mm')
          .tz('America/New_York')
          .toISOString()
      )
    },
    validate: validateScheduleDrawing,
  })

  const minDrawDate = moment
    .max([
      moment(),
      moment(
        formik.values.scheduledDrawCollectionEndTime
          ? formik.values.scheduledDrawCollectionEndTime
          : undefined
      ),
    ])
    .format(FORMIK_DATE_FORMAT)

  return (
    <GenericPopupModal
      isOpen
      closeModal={props.closeModal}
      roundedEdges
      hideCloseButton
    >
      <form className={ROOT_CLASS} onSubmit={formik.handleSubmit} noValidate>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <label
            className={getBlockClass(ROOT_CLASS, 'label')}
            htmlFor="drawingGroupIdentifier"
          >
            Drawing Group
          </label>
          <CustomDropdownField
            onChange={formik.handleChange}
            value={formik.values.drawingGroupIdentifier}
            onBlur={formik.handleBlur}
            id="drawing-group-dropdown"
            name="drawingGroupIdentifier"
            options={drawingGroupOptions}
            disabled={
              drawingGroupsWithActiveDrawings.length == 1 || props.loading
            }
            error={formik.errors.drawingGroupIdentifier}
          />
          <DateAndTimeInput
            label={'Scan Collection End Date and Time'}
            name={'scheduledDrawCollectionEndTime'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scheduledDrawCollectionEndTime}
            error={formik.errors.scheduledDrawCollectionEndTime}
            touched={formik.touched.scheduledDrawCollectionEndTime}
            minDate={moment().format(FORMIK_DATE_FORMAT)}
            disabled={props.loading}
            includeTime
          />
          <DateAndTimeInput
            label={'Drawing Schedule Time'}
            name={'scheduledDrawTime'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scheduledDrawTime}
            error={formik.errors.scheduledDrawTime}
            touched={formik.touched.scheduledDrawTime}
            minDate={minDrawDate}
            disabled={props.loading}
            includeTime
          />
          {props.error && (
            <div className={getBlockClass(ROOT_CLASS, 'error-message')}>
              {props.error}
            </div>
          )}
        </div>
        <button
          type="submit"
          className={BUTTON_CLASS}
          aria-label="Save"
          disabled={!formik.dirty || !formik.isValid || props.loading}
        >
          {props.loading ? (
            <div className={`${BUTTON_CLASS}__loading`}>
              <LoadingDots id="loading" color="white" />
            </div>
          ) : (
            'Save'
          )}
        </button>

        <button
          className={getBlockClass(ROOT_CLASS, 'back-button')}
          onClick={props.closeModal}
          aria-label="Go Back"
          disabled={props.loading}
        >
          Go Back
        </button>
      </form>
    </GenericPopupModal>
  )
}
