import * as React from 'react'
import { GenericSidebar } from '../../GenericSidebar/generic-sidebar-ui'
import {
  NewCampaignSidebarContainer,
  NewCampaignSidebarContainerProps,
} from './new-campaign-side-bar/new-campaign-side-bar-container'
import { EditCampaignContainer } from './edit-campaign-side-bar/edit-campaign-container'

export enum CampaignFormMode {
  NEW = 'new',
  EDIT = 'edit',
  CLOSED = 'closed',
}
export type CampaignsSidebarProps = NewCampaignSidebarContainerProps & {
  showEditCampaign: (campaignIdentifier: string) => void
  formMode: CampaignFormMode
  campaignIdentifier?: string
}

export const CampaignsSideBarUI = (props: CampaignsSidebarProps) => {
  return (
    <GenericSidebar
      isOpen={props.formMode !== CampaignFormMode.CLOSED}
      closeSideBar={props.closeSidebar}
    >
      {props.formMode === CampaignFormMode.NEW && (
        <NewCampaignSidebarContainer
          closeSidebar={props.closeSidebar}
          showEditCampaign={props.showEditCampaign}
        />
      )}
      {props.formMode === CampaignFormMode.EDIT && props.campaignIdentifier && (
        <EditCampaignContainer
          closeSidebar={props.closeSidebar}
          campaignIdentifier={props.campaignIdentifier}
        />
      )}
    </GenericSidebar>
  )
}
