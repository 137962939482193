import FuzzySearch from "fuzzy-search";
import config from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";
import {PullTabInventoryInterface} from "./interfaces";

let charitableGamingStore: any;
let unfilteredPullTabInventory: any;
let setUnfilteredPullTabInventory: any;
let filteredPullTabInventory: any;
let setFilteredPullTabInventory: any;
let setCurrentPage: any;
let setPageMessage: any;
let setShowErrorDialog: any;

export const setVariables = (charitableGamingStoreObj: any, unfilteredPullTabInventoryObj: any, setUnfilteredPullTabInventoryObj: any,
                             filteredPullTabInventoryObj: any, setFilteredPullTabInventoryObj: any, setCurrentPageObj: any,
                             setPageMessageObj: any, setShowErrorDialogObj: any) => {

    charitableGamingStore = charitableGamingStoreObj;
    unfilteredPullTabInventory = unfilteredPullTabInventoryObj;
    setUnfilteredPullTabInventory = setUnfilteredPullTabInventoryObj;
    filteredPullTabInventory = filteredPullTabInventoryObj;
    setFilteredPullTabInventory = setFilteredPullTabInventoryObj;
    setCurrentPage = setCurrentPageObj;
    setPageMessage = setPageMessageObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    //alert(JSON.stringify(values));
    filterPullTabInventory(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            warehouse: '',
            stockNumber: ''
        }
    });

    resetFilteredPullTabInventory();

}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

export const getAllPullTabInventory = async (setRequestCompleted: any) => {

    try {

        const options = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/inventory/overview`
        }

        const response = await makeHttpCall(options);

        // Deep copy
        setUnfilteredPullTabInventory(JSON.parse(JSON.stringify(response.data)));

        setFilteredPullTabInventory(JSON.parse(JSON.stringify(response.data)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

const filterPullTabInventory = (values: any) => {

    setCurrentPage(0);

    let searcher;
    let key;
    let pullTabInventory;

    pullTabInventory = JSON.parse(JSON.stringify(unfilteredPullTabInventory));

    if(values.warehouse && values.warehouse.toLowerCase() !== 'all') {
        const warehouse = values.warehouse.split('-')[0].trim();
        pullTabInventory = unfilteredPullTabInventory.filter((pullTabItem: PullTabInventoryInterface) => pullTabItem.warehouseId === warehouse);
    }

    // Search
    if (values.stockNumber && values.stockNumber !== '') {
        searcher = new FuzzySearch(pullTabInventory, ['stockNumber'], {
            caseSensitive: false, sort: true
        });
        key = values.stockNumber;
        pullTabInventory = searcher.search(key);
    }

    setFilteredPullTabInventory(pullTabInventory);

}

const resetFilteredPullTabInventory = () => {
    setFilteredPullTabInventory(JSON.parse(JSON.stringify(unfilteredPullTabInventory)));
}