import { FormikValues } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { annuitiesRoutePath } from '../../../../config.js'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { Payee } from '../../../../types/Annuities/PayeeManagement/Payee'
import { validateTIN } from '../../../../util'
import { MultiplePayeeResult } from '../MultiplePayeeResultCmpt'
import { SinglePayeeResult } from '../SinglePayeeResultCmpt'
import styles from './css/styles.module.css'
import { addPayee, getPayee } from './functions'

export const PayeeSearchCmpt: React.FC<{
  isFromCreationFlow: boolean
  formik?: FormikValues
  setBeginPayeeSearchFlow: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
  initialPayeeSearchTIN?: string
  payeeType?: 'Individual' | 'Entity' | ''
  setPayeeType?: React.Dispatch<
    React.SetStateAction<'Individual' | 'Entity' | ''>
  >
  setSearchedPayeeID?: React.Dispatch<React.SetStateAction<string>>
  accountId?: string
  annuitiesStore?: any
}> = ({
  isFromCreationFlow,
  formik,
  setBeginPayeeSearchFlow,
  initialPayeeSearchTIN,
  setCurrentPage,
  payeeType,
  setPayeeType,
  setSearchedPayeeID,
  accountId,
  annuitiesStore,
}) => {
  const FocusTrap = require('focus-trap-react')
  const history = useHistory()
  const bannerContext = React.useContext(BannerContext)
  const idemToken = uuidv4()
  //state for page flow
  const [TIN, setTIN] = useState<string>(
    initialPayeeSearchTIN ? initialPayeeSearchTIN : ''
  )
  const [payeeResults, setPayeeResults] = useState<Payee[]>([])
  const [localPayeeType, setLocalPayeeType] = useState<
    'Individual' | 'Entity' | ''
  >('')
  const [isSelectPayeeVisible, setIsSelectPayeeVisible] =
    useState<boolean>(true)
  const [isPayeeSearchVisible, setIsPayeeSearchVisible] =
    useState<boolean>(false)
  const [isPayeeResultsVisible, setIsPayeeResultsVisible] =
    useState<boolean>(false)
  const [isNoPayeeFoundVisible, setIsNoPayeeFoundVisible] =
    useState<boolean>(false)

  const initialSinglePayee: Payee = {
    id: 'Loading...',
    createdOn: 'Loading...',
    validFrom: 'Loading...',
    validTo: 'Loading...',
    firstName: 'loading...',
    middleInitial: 'loading...',
    lastName: 'loading...',
    address1: 'loading...',
    address2: 'loading...',
    city: 'loading...',
    state: 'loading...',
    zip: 'loading...',
    dateOfBirth: 'loading...',
    dateOfDeath: 'loading...',
    tin: 'loading...',
    email: 'loading...',
    phone: 'loading...',
    payeeType: 'Individual',
    notes: 'Loading...',
    country: 'loading...',
    irsName: 'loading...',
  }

  const searchForPayee = async () => {
    // Clear Searched Payee in case flow is exited abruptly
    if (setSearchedPayeeID !== undefined) {
      setSearchedPayeeID('')
    }
    if (formik !== undefined) {
      formik.setFieldValue('tin', TIN)
    }
    let payeeInfo = {
      payeeType: '',
      tin: '',
    }

    if (isFromCreationFlow && payeeType !== undefined) {
      payeeInfo = {
        payeeType: payeeType,
        tin: TIN,
      }
    } else {
      payeeInfo = {
        payeeType: localPayeeType,
        tin: TIN,
      }
    }

    try {
      const response = await getPayee(payeeInfo)
      if (response.status === 404) {
        setIsPayeeSearchVisible(false)
        setIsNoPayeeFoundVisible(true)
      } else {
        setPayeeResults(response.data)
        setIsPayeeSearchVisible(false)
        setIsPayeeResultsVisible(true)
      }
    } catch (error) {
      setIsPayeeSearchVisible(false)
      setBeginPayeeSearchFlow(false)
      bannerContext.setBannerInfo({ message: `${error}`, error: true })
    }
  }

  const createPayeeSelected = (isFromPayeeResults: boolean) => {
    if (isFromPayeeResults) {
      setIsPayeeResultsVisible(false)
    } else {
      setIsNoPayeeFoundVisible(false)
    }
    setBeginPayeeSearchFlow(false)
    if (isFromCreationFlow) {
      if (formik) {
        formik.values.tin = TIN
      }
      if (setCurrentPage !== undefined) {
        setCurrentPage(2)
      }
    } else {
      localStorage.setItem('tinNotFound', TIN)
      history.push(
        `${annuitiesRoutePath}/create-payee/${localPayeeType}/${accountId}`
      )
    }
  }

  const setSelectedPayee = async (
    id: string,
    selectedPayeeType: 'Individual' | 'Entity' | ''
  ) => {
    const payeeFound: Payee =
      payeeResults.find((payee) => payee.id === id) ?? initialSinglePayee

    if (setSearchedPayeeID !== undefined) {
      setSearchedPayeeID(payeeFound.id)
    }
    if (setPayeeType !== undefined) {
      setPayeeType(selectedPayeeType)
    }

    if (formik !== undefined) {
      if (payeeFound.payeeType === 'Individual') {
        formik.values.firstName = payeeFound.firstName
        formik.values.middleInitial = payeeFound.middleInitial
        formik.values.lastName = payeeFound.lastName
        formik.values.birthDate = moment(payeeFound.dateOfBirth).format(
          'YYYY-MM-DD'
        )
      } else {
        formik.values.freeformName = payeeFound.freeformName
      }
      formik.values.tin = payeeFound.tin
      formik.values.addr_1 = payeeFound.address1
      formik.values.addr_2 = payeeFound.address2
      formik.values.city = payeeFound.city
      formik.values.state_region = payeeFound.state
      formik.values.zip_code = payeeFound.zip
      formik.values.country = payeeFound.country
      formik.values.phone = payeeFound.phone
      formik.values.email = payeeFound.email
      formik.values.irsName = payeeFound.irsName
      formik.values.payeeAccountStatus = ''
      formik.values.payeeCreationDate = ''
      formik.values.payeeEndDate = ''
      formik.values.payeePreviousPayDate = ''
      formik.values.payeeNextPayDate = ''
    }

    setIsPayeeResultsVisible(false)
    setBeginPayeeSearchFlow(false)

    if (isFromCreationFlow) {
      if (setCurrentPage !== undefined) {
        setCurrentPage(2)
      }
    } else {
      try {
        if (accountId !== undefined) {
          await addPayee(payeeFound.id, accountId, annuitiesStore, idemToken)
          bannerContext.setBannerInfo({
            message: 'Successfully Added Payee',
            error: false,
          })
        }
      } catch (error) {
        bannerContext.setBannerInfo({ message: `${error}`, error: true })
      }
    }
  }

  const cancelPayeeSelectionFlow = () => {
    setIsPayeeResultsVisible(false)
    setBeginPayeeSearchFlow(false)
  }

  return (
    <>
      {/* Modal for Selecting payee Type */}
      {isSelectPayeeVisible && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles['overlay-container']}>
            <div className={styles['main-modal']}>
              <div>
                <div className={styles['modal-title']}>Select Payee Type</div>
                <div className={styles['modal-desc']}>
                  <div className={`${styles.select_payee} ${styles.flex_row}`}>
                    <input
                      type="radio"
                      className={styles['input_radio']}
                      onKeyPress={(event: any) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          if (setPayeeType !== undefined) {
                            setPayeeType(event.target.value)
                          } else {
                            setLocalPayeeType(event.target.value)
                          }
                        }
                      }}
                      onChange={(event: any) => {
                        if (setPayeeType !== undefined) {
                          setPayeeType(event.target.value)
                        } else {
                          setLocalPayeeType(event.target.value)
                        }
                      }}
                      name="payee"
                      id="Individual"
                      value="Individual"
                    />
                    <label htmlFor="Individual">Individual</label>
                  </div>
                  <div className={`${styles.select_payee} ${styles.flex_row}`}>
                    <input
                      type="radio"
                      onKeyPress={(event: any) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          if (setPayeeType !== undefined) {
                            setPayeeType(event.target.value)
                          } else {
                            setLocalPayeeType(event.target.value)
                          }
                        }
                      }}
                      onChange={(event: any) => {
                        if (setPayeeType !== undefined) {
                          setPayeeType(event.target.value)
                        } else {
                          setLocalPayeeType(event.target.value)
                        }
                      }}
                      name="payee"
                      id="Entity"
                      value="Entity"
                    />
                    <label htmlFor="Entity">Entity</label>
                  </div>
                </div>
              </div>
              <button
                type="button"
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    if (
                      (isFromCreationFlow && payeeType === '') ||
                      (!isFromCreationFlow && localPayeeType === '')
                    ) {
                      window.alert('Please select a Payee Type')
                    } else {
                      setIsSelectPayeeVisible(false)
                      setIsPayeeSearchVisible(true)
                    }
                  }
                }}
                onClick={() => {
                  if (
                    (isFromCreationFlow && payeeType === '') ||
                    (!isFromCreationFlow && localPayeeType === '')
                  ) {
                    window.alert('Please select a Payee Type')
                  } else {
                    setIsSelectPayeeVisible(false)
                    setIsPayeeSearchVisible(true)
                  }
                }}
                className={styles['modal-confirm-btn']}
              >
                Select
              </button>
              <button
                type="button"
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setIsSelectPayeeVisible(false)
                    setBeginPayeeSearchFlow(false)
                  }
                }}
                onClick={() => {
                  setIsSelectPayeeVisible(false)
                  setBeginPayeeSearchFlow(false)
                }}
                className={styles['modal-cancel-btn']}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
      {/* Modal for searching Existing payee */}
      {isPayeeSearchVisible && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles['overlay-container']}>
            <div className={styles['main-modal']}>
              <div>
                <div className={styles['modal-title']}>
                  Existing Payee Search
                </div>
                <div className={styles['modal-desc']}>
                  <p>
                    Use the field below to search and ensure the new payee does
                    not already exist in the annuitant database. Searches must
                    be an exact match.
                  </p>
                </div>
                <div className={styles['payee_search']}>
                  <label htmlFor="tin">TIN</label>
                  <InputMask
                    mask="999999999"
                    value={TIN}
                    maskChar=""
                    id="tin"
                    name="tin"
                    placeholder={' 123456789'}
                    onChange={(e: any) => {
                      setTIN(e.target.value)
                    }}
                  ></InputMask>
                </div>
              </div>
              <button
                disabled={!validateTIN(TIN)}
                type="button"
                onKeyPress={async (event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    if (TIN === '' || TIN === null) {
                      window.alert('Please enter a valid TIN')
                    } else {
                      await searchForPayee()
                    }
                  }
                }}
                onClick={async () => {
                  if (TIN === '' || TIN === null) {
                    window.alert('Please enter a valid TIN')
                  } else {
                    await searchForPayee()
                  }
                }}
                className={styles['modal-confirm-btn']}
              >
                Search
              </button>
              <button
                type="button"
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setIsPayeeSearchVisible(false)
                    setBeginPayeeSearchFlow(false)
                  }
                }}
                onClick={() => {
                  setIsPayeeSearchVisible(false)
                  setBeginPayeeSearchFlow(false)
                }}
                className={styles['modal-cancel-btn']}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
      {/* Modal for Payee Results  */}
      {isPayeeResultsVisible && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          {payeeResults.length === 1 ? (
            <SinglePayeeResult
              payee={payeeResults[0]}
              setSelectedPayee={setSelectedPayee}
              cancelPayeeSelectionFlow={cancelPayeeSelectionFlow}
              createPayeeSelected={createPayeeSelected}
            />
          ) : (
            payeeResults.length > 1 && (
              <MultiplePayeeResult
                payees={payeeResults}
                setSelectedPayee={setSelectedPayee}
                setIsPayeeSearchVisible={setIsPayeeSearchVisible}
                setIsPayeeResultsVisible={setIsPayeeResultsVisible}
                createPayeeSelected={createPayeeSelected}
              />
            )
          )}
        </FocusTrap>
      )}
      {/* Modal for No Payee Found Results  */}
      {isNoPayeeFoundVisible && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles['overlay-container']}>
            <div className={styles['main-modal']}>
              <div>
                <div className={styles['modal-title']}>
                  Existing Payee Results
                </div>
                <div className={styles['modal-desc']}>
                  <p>
                    No payees matching the criteria have been located in the
                    annuitant database.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    createPayeeSelected(false)
                  }
                }}
                onClick={() => {
                  createPayeeSelected(false)
                }}
                className={styles['modal-confirm-btn']}
              >
                Create New Payee
              </button>
              <button
                type="button"
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setBeginPayeeSearchFlow(false)
                    setIsNoPayeeFoundVisible(false)
                  }
                }}
                onClick={() => {
                  setBeginPayeeSearchFlow(false)
                  setIsNoPayeeFoundVisible(false)
                }}
                className={styles['modal-cancel-btn']}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  )
}
