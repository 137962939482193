import config from "../../../config";
import {makeHttpCall} from "../../../services/MakeHttpCall";
import axios from "axios";

let formik: any;
let setYearEndReports: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setReportBundleDownloadCompleted: any;
let previousPageValue: any;

export const setVariables = (formikObj: any, setYearEndReportsObj: any, setCurrentPageObj: any,
                             setShowErrorDialogObj: any, setRequestCompletedObj: any,
                             setReportBundleDownloadCompletedObj: any, previousPageValueObj: any) => {

    formik = formikObj;
    setYearEndReports = setYearEndReportsObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setReportBundleDownloadCompleted = setReportBundleDownloadCompletedObj;
    previousPageValue = previousPageValueObj;
}

export const setFieldValue = async (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    getYearEndReports(values);
}

export const getPageData = (pullTabGames: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(pullTabGames[i] === undefined)) {
            pullTabGames[i].index = index;
            index++;
            array.push(pullTabGames[i]);
        }
    }
    return array;
}

export const getYearEndReports = async (values: any) => {

    if (values.year === '') {
        return;
    }

    setCurrentPage(previousPageValue.current);

    setRequestCompleted(false);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/yearEndReporting/reportBundles?year=${values.year}`,
        }

        const response = await makeHttpCall(options);

        setYearEndReports(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const generateYearEndReports = async (values: any) => {

    setRequestCompleted(false);

    try {

        const body: any = {
            generatedBy: localStorage.getItem('loggedInUserFullName'), year: values.year
        }

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/yearEndReporting/generate`, data: body
        }

        await makeHttpCall(options);

        formik.submitForm();

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        //setShowErrorDialog(true);
    }
}

export const generateYearEndReportBundleURL = async (id: string) => {

    setRequestCompleted(false);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/yearEndReporting/reportBundleUrl?id=${id}`,
        }

        const response = await makeHttpCall(options);

        setRequestCompleted(true);

        downloadYearEndReportsBundle(response.url);

    } catch (e) {
        setRequestCompleted(true);
        //setShowErrorDialog(true);
    }
}

export const downloadYearEndReportsBundle = async (url: any) => {

    try {

        setReportBundleDownloadCompleted(false);

        const options: any = {
            // method: 'POST',
            url: url, responseType: 'blob'
            // responseType: 'arraybuffer',
            /*headers: {
                'Content-Type': 'application/json',
                // 'Accept': 'application/zip',
            }*/
        }

        const response = await axios(options);

        let file = new Blob([response.data], {type: 'application/zip'});
        let fileURL = URL.createObjectURL(file);

        // Create <a> tag dynamically
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;

        // Forces the name of the downloaded file
        // fileLink.download = response.headers['content-disposition'] ? response.headers['content-disposition'].split(' ')[1].split('=')[1].slice(1, -1) : `sample`;

        fileLink.download = `year-end-reporting-${formik.values.year}`;

        // Trigger the click event
        fileLink.click();

        setReportBundleDownloadCompleted(true);

    } catch (e) {
        setReportBundleDownloadCompleted(true);
        setShowErrorDialog(true)
    }

}