import * as React from 'react'

type Props = {
  width?: number
  height?: number
}

export const SmallCaret = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 17}
    height={props.height || 9}
    viewBox="0 0 17 9"
    fill="none"
  >
    <title>Caret Icon</title>
    <path
      d="M8.1289 0.536649C8.53764 0.175284 9.19857 0.175284 9.60296 0.536649L16.5645 6.68754C16.9733 7.0489 16.9733 7.63324 16.5645 7.99076C16.1558 8.34828 15.4949 8.35212 15.0905 7.99076L8.87245 2.4934L2.65008 7.9946C2.24134 8.35597 1.58041 8.35597 1.17602 7.9946C0.771629 7.63324 0.767281 7.0489 1.17602 6.69138L8.1289 0.536649Z"
      fill="#0F1821"
    />
  </svg>
)
