import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../../CommonComponents/ErrorMessageFieldCommonComponent'
import { CircleX } from '../../../icons/circle-x-icon'
import './styles.scss'

export type NumberInputProps = {
  label: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: any) => void
  value: number | string
  error?: string
  touched?: boolean
  required: boolean
  disabled: boolean
  min?: number
  max?: number
  step?: number
  greyOnDisabled?: boolean
  placeholder?: string
  onRemove?: () => void
  removeButtonAriaLabel?: string
  trailingText?: string
  inputWidthPx?: number
  margin?: string
}

const ROOT_CLASS = 'number-input'
const INPUT_CLASS = getBlockClass(ROOT_CLASS, 'input')

export const NumberInput = (props: NumberInputProps) => {
  return (
    <div className={ROOT_CLASS} style={{ margin: props.margin }}>
      <label
        className={getBlockClass(ROOT_CLASS, 'label')}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <input
          id={props.name}
          className={joinClasses([
            INPUT_CLASS,
            getModifierClass(
              INPUT_CLASS,
              'grey',
              props.disabled && Boolean(props.greyOnDisabled)
            ),
          ])}
          name={props.name}
          type="number"
          required={props.required}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          min={props.min}
          max={props.max}
          step={props.step}
          placeholder={props.placeholder}
          style={{ width: `${props.inputWidthPx}px` }}
        />
        <span className={getBlockClass(ROOT_CLASS, 'trailing-text')}>
          {props.trailingText}
        </span>
        {props.onRemove && (
          <button
            className={getBlockClass(INPUT_CLASS, 'remove-button')}
            onClick={props.onRemove}
            aria-label={props.removeButtonAriaLabel}
            type="button"
          >
            <CircleX />
          </button>
        )}
      </div>
      {props.error && props.touched && (
        <ErrorMessageFieldCommonComponent errorMessage={props.error} />
      )}
    </div>
  )
}
