import * as React from 'react'
import { CampaignsImagePreview } from '../../components/CampaignsComponents/campaigns-image-preview/campaigns-image-preview-ui'
import {
  CampaignsListUIProps,
  CampaignsListUI,
} from '../../components/CampaignsComponents/campaigns-list/campaigns-list-ui'
import {
  CampaignFormMode,
  CampaignsSideBarUI,
} from '../../components/CampaignsComponents/campaigns-side-bar/campaigns-side-bar-ui'
import { AsyncContainerUI } from '../../components/CommonComponents/async-container-component/async-container-ui'
import { CampaignBuilderContext } from './campaign-builder-context'
import './styles.scss'

const ROOT_CLASS = 'campaigns-builder'

export type CampaignsPageUIProps = Pick<CampaignsListUIProps, 'campaigns'> & {
  createCampaignDisabled: boolean
  campaignsLoading: boolean
  campaignsError?: string
}

export const CampaignsPageUI = (props: CampaignsPageUIProps) => {
  const [openPreviewImage, setOpenPreviewImage] = React.useState<
    string | undefined
  >(undefined)
  const [campaignFormMode, setCampaignFormMode] =
    React.useState<CampaignFormMode>(CampaignFormMode.CLOSED)
  const { openEditCampaign, setOpenEditCampaign } = React.useContext(
    CampaignBuilderContext
  )

  const closeSidebar = () => {
    setCampaignFormMode(CampaignFormMode.CLOSED)
    setOpenEditCampaign('')
  }

  const showNewCampaign = () => {
    setCampaignFormMode(CampaignFormMode.NEW)
    setOpenEditCampaign('')
  }

  const showEditCampaign = (campaignIdentifier: string) => {
    setCampaignFormMode(CampaignFormMode.EDIT)
    setOpenEditCampaign(campaignIdentifier)
  }

  return (
    <>
      <CampaignsSideBarUI
        campaignIdentifier={openEditCampaign}
        showEditCampaign={showEditCampaign}
        closeSidebar={closeSidebar}
        formMode={campaignFormMode}
      />
      <CampaignsImagePreview
        imageUrl={openPreviewImage}
        clearImageUrl={() => setOpenPreviewImage(undefined)}
      />
      <section className={ROOT_CLASS}>
        <header className={`${ROOT_CLASS}-header`}>
          <div tabIndex={0} className={`${ROOT_CLASS}-header__title`}>
            Campaign Builder
          </div>
          <button
            className={`${ROOT_CLASS}-header__create-button`}
            onClick={showNewCampaign}
            aria-label="Create Campaign"
            disabled={props.createCampaignDisabled}
          >
            <div>Create</div>
          </button>
        </header>
        <div className={`${ROOT_CLASS}__content`}>
          <AsyncContainerUI
            isLoading={props.campaignsLoading}
            error={props.campaignsError}
            errorTextSize={20}
            color="white"
          >
            <CampaignsListUI
              {...props}
              setOpenPreviewImage={setOpenPreviewImage}
              showEditCampaign={showEditCampaign}
            />
          </AsyncContainerUI>
        </div>
      </section>
    </>
  )
}
