import React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../utilities/helpers'
import './styles.scss'

export type GenericToolTipWrapperProps = {
  position?: 'top' | 'bottom'
  sizePx?: number
  leftPx?: number
  noBorder?: boolean
  shadow?: boolean
  triggerElement: JSX.Element
  hiddenElement: JSX.Element
  showArrow?: boolean
}

const ROOT_CLASS = 'generic-tool-tip'
const POP_UP_CLASS = getBlockClass(ROOT_CLASS, 'pop-up')

export const GenericToolTipWrapper = (props: GenericToolTipWrapperProps) => {
  return (
    <>
      <div
        className={ROOT_CLASS}
        style={{
          '--sizePx': props.sizePx ? `${props.sizePx}px` : '20px',
          '--leftOffsetPx': props.leftPx ? `${props.leftPx}px` : '0px',
        }}
      >
        {props.triggerElement}
        <div
          className={joinClasses([
            POP_UP_CLASS,
            getModifierClass(POP_UP_CLASS, props.position ?? 'bottom'),
            getModifierClass(
              POP_UP_CLASS,
              'no-border',
              Boolean(props.noBorder)
            ),
            getModifierClass(POP_UP_CLASS, 'shadow', Boolean(props.shadow)),
            getModifierClass(
              POP_UP_CLASS,
              props.position === 'top' ? 'bottom-arrow' : 'top-arrow',
              Boolean(props.showArrow)
            ),
          ])}
        >
          {props.hiddenElement}
        </div>
      </div>
    </>
  )
}
