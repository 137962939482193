import React, { SetStateAction, useEffect, useState } from "react"
import { Formik } from "formik"
import "./styles.scss"
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { VoidReissueFormFields } from "./create-void-reissue-page-container"
import { CreateVoidReissueConfirmationUI } from "../../../components/PaymentComponents/CreateVoidReissueComponents/create-void-reissue-confirmation/create-void-reissue-confirmation-ui"
import { CreateVoidReissueFormUI } from "../../../components/PaymentComponents/CreateVoidReissueComponents/create-void-reissue-form/create-void-reissue-form-ui"
import { CreateVoidReissueTitleBar } from "../../../components/PaymentComponents/CreateVoidReissueComponents/create-void-reissue-title-bar/create-void-reissue-title-bar-ui"
import { BackButtonUI } from "../../../components/CommonComponents/BackButtonComponent/back-button-ui"
import { GenericErrorDialogComponent } from "../../../components/CommonComponents/GenericErrorDialogComponent"
import { PaymentSource } from "../../../api/payment-details-fetcher/api-types"

export type CreateVoidReissuePageUIProps = {
  payment: VoidReissueFormFields
  paymentSource: PaymentSource
  payeeLoading: boolean
  voidReissueLoading: boolean
  paymentLoading: boolean
  payeeError: string | undefined
  showSubmitPopup: boolean
  setShowSubmitPopup: React.Dispatch<SetStateAction<boolean>>
  showErrorPopup: boolean
  setShowErrorPopup: React.Dispatch<SetStateAction<boolean>>
  submitVoidReissue: (request: VoidReissueFormFields) => Promise<void>
}

export const CreateVoidReissuePageUI = (props: CreateVoidReissuePageUIProps) => {
  const { 
    payment, 
    paymentSource,
    payeeLoading, 
    voidReissueLoading, 
    paymentLoading, 
    payeeError, 
    submitVoidReissue, 
    showSubmitPopup, 
    setShowSubmitPopup, 
    showErrorPopup, 
    setShowErrorPopup 
  } = props

  const onSubmit = (values: VoidReissueFormFields, _formikHelpers: any) => {
    submitVoidReissue(values)
  }

  const ROOT_CLASS = 'create-void-reissue-page'

  return (
    <>
      <BackButtonUI 
        tabIndex={ 0 } 
        message={ 'Back to Payment Details' }
      />
      <AsyncContainerUI
        isLoading={ payeeLoading || voidReissueLoading || paymentLoading }
        error={ payeeError }
        color={ 'white' }
      >
        <Formik initialValues={payment} onSubmit={onSubmit}>
          {props => {
            return (
              <div className={ROOT_CLASS}>
                <CreateVoidReissueTitleBar
                  setShowSubmitPopup={setShowSubmitPopup}
                  submitButtonEnabled={props.isValid}
                />
                <form onSubmit={props.handleSubmit}>
                  <CreateVoidReissueFormUI
                    payment={payment}
                    paymentSource={paymentSource}
                  />
                  {showSubmitPopup && 
                    <CreateVoidReissueConfirmationUI 
                      setShowSubmitPopup={setShowSubmitPopup}
                      submitVoidReissue={() => props.handleSubmit()}        
                    />
                  }
                </form>
              </div>
            )
          }}
        </Formik>
      </AsyncContainerUI>
      {showErrorPopup && 
        <GenericErrorDialogComponent 
          setShowErrorDialog={ setShowErrorPopup }          
        />
      }
    </>
    
  )
}