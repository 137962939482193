import React, { createContext, useState } from "react";
import { SubAccountTreeNode } from "../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";

const initialParentNode: SubAccountTreeNode = {
  account: {
    timePaymentAccountPayeeId: "Loading...",
    irsTaxEnabled: true,
    dorTaxEnabled: true,
    acctStatus: "Loading...",
    parentTaxResponsibility: true,
    parentAccountId: "Loading...",
    supportingInformation1: "Loading...",
    supportingInformation2: "Loading...",
    id: "Loading...",
    validFrom: new Date("2022-01-01T05:00:00.000Z"),
    validTo: "string",
    createdOn: new Date("2022-01-01T05:00:00.000Z"),
    notes: "Loading...",
  },
  paymentRules: [
    {
      subAccountId: "Loading...",
      startDate: new Date("2022-01-01T05:00:00.000Z"),
      endDate: new Date("2022-01-01T05:00:00.000Z"),
      amount: 1000000,
      id: "Loading...",
      validFrom: new Date("2022-01-01T05:00:00.000Z"),
      validTo: new Date("2022-01-01T05:00:00.000Z"),
      createdOn: new Date("2022-01-01T05:00:00.000Z"),
      notes: "Loading...",
    },
  ],
  subAccountChildren: [],
  paymentMade: true,
};

const SubAccountContext = createContext({ parentNode: initialParentNode, setNewParentNode: (parentNode: SubAccountTreeNode) => {} });

const SubAccountProvider = ({ children }: any) => {
  const [parentNode, setParentNode] = useState<SubAccountTreeNode>(initialParentNode);

  const setNewParentNode = (parentNode: SubAccountTreeNode) => {
    setParentNode(parentNode);
  };

  return <SubAccountContext.Provider value={{ parentNode, setNewParentNode }}>{children}</SubAccountContext.Provider>;
};

export { SubAccountContext, SubAccountProvider };
