import { useEffect } from 'react'
import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { CheckRunHistoryResponse } from './api-types'

const URL = `${config.SERVER_BASE_URL}/api/v1/checkRun/history`
export const useFetchCheckRunHistory = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<CheckRunHistoryResponse>()
  useEffect(() => {
    fetchUrl(URL, 'GET')
  }, [])
  return { isLoading, error, data }
}
