import * as React from 'react'
import { ArtworkTemplateExtension } from '../../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import { GenericDownloadButton } from '../../GenericDownloadButton/generic-download-button-ui'
import { GenericPopupModal } from '../../GenericPopupModal/generic-popup-modal-ui'
import { CampaignArtworkGuidelines } from './campaign-artwork-guidelines'
import './styles.scss'

export type CampaignsArtworkRequirementsProps = {
  closeModal: () => void
  isOpen: boolean
  fetchArtworkRequirements: (
    fileType: ArtworkTemplateExtension
  ) => Promise<void>
}

const ROOT_CLASS = 'campaigns-artwork-requirements'
const ROOT_CLASS_GUIDELINES = `${ROOT_CLASS}__guidelines`
const ROOT_CLASS_TEMPLATES = `${ROOT_CLASS}__templates`
const ROOT_CLASS_TEMPLATES_ITEM = `${ROOT_CLASS_TEMPLATES}__item`

export const CampaignsArtworkRequirements = (
  props: CampaignsArtworkRequirementsProps
) => {
  return (
    <GenericPopupModal
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      roundedEdges={true}
    >
      <div className={ROOT_CLASS}>
        <header>Artwork Design Requirements</header>
        <div className={`${ROOT_CLASS}__text`}>
          To ensure text in the artwork design appears in its entirety, please
          keep it within the Text Safe Area. Use the guidelines within the
          template to align your text.
        </div>
        <div className={ROOT_CLASS_GUIDELINES}>
          <CampaignArtworkGuidelines />
        </div>
        <div className={ROOT_CLASS_TEMPLATES}>
          <h1>Download Templates</h1>
          <div className={ROOT_CLASS_TEMPLATES_ITEM}>
            <div className={`${ROOT_CLASS_TEMPLATES_ITEM}__label`}>
              Adobe Illustrator Template
            </div>
            <div className={`${ROOT_CLASS_TEMPLATES_ITEM}__button`}>
              <GenericDownloadButton
                onClick={async () =>
                  await props.fetchArtworkRequirements(
                    ArtworkTemplateExtension.AI
                  )
                }
              />
            </div>
          </div>
          <div className={ROOT_CLASS_TEMPLATES_ITEM}>
            <div className={`${ROOT_CLASS_TEMPLATES_ITEM}__label`}>
              Adobe Photoshop Template
            </div>
            <div className={`${ROOT_CLASS_TEMPLATES_ITEM}__button`}>
              <GenericDownloadButton
                onClick={async () =>
                  await props.fetchArtworkRequirements(
                    ArtworkTemplateExtension.PSD
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </GenericPopupModal>
  )
}
