import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const ResetAllBarcodesConfirmationComponent = ({YesResetAllBarcodes, resetBarcodesToggle, setResetBarcodesToggle}: any) => {

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["save-confirmation-dialog-main-container"]}>
                    <div
                        id="save-confirmation-dialog-container"
                        className={stylesMessageBox["save-confirmation-dialog-container"]}
                    >
                        <div
                            className={stylesMessageBox["save-confirmation-dialog-message"]}
                        >
                            Are you sure you would like to reset all barcodes?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    YesResetAllBarcodes();
                                    setResetBarcodesToggle(false);
                                }
                            }}
                            onClick={() => {
                                YesResetAllBarcodes();
                                setResetBarcodesToggle(false);
                            }}
                            className={stylesMessageBox["save-confirmation-dialog-yes-button"]}
                        >
                            <div
                                className={
                                    stylesMessageBox["save-confirmation-dialog-yes-button-text"]
                                }
                            >
                                Yes, Reset All
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    setResetBarcodesToggle(!resetBarcodesToggle);
                                }
                            }}
                            role="button"
                            onClick={() => {
                                setResetBarcodesToggle(!resetBarcodesToggle);
                            }}
                            className={
                                stylesMessageBox["save-confirmation-dialog-no-button"]
                            }
                        >
                            Go Back
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};