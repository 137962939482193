import React from 'react'
import {
  CheckRunHistoryResponse
} from '../../../api/check-run-history-fetcher/api-types'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import './styles.scss'
import { CheckRunHistoryTableUI } from '../../../components/PaymentComponents/CheckRunHistoryComponents/check-run-history-table/check-run-history-table-ui'
import { useHistory } from 'react-router-dom'
import { reportingRoutePath } from '../../../config'

export type CheckRunHistoryPageProps = {
  isLoading: boolean
  error?: string
  data?: CheckRunHistoryResponse
}

const ROOT_CLASS = 'check-run-history-page-ui'

export const CheckRunHistoryPageUI = (props: CheckRunHistoryPageProps) => {
  const history = useHistory()

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={0} message="Back to Check Run Queue" />
      <header>
        <div className='title'>Check Run History</div>
        <button className='button blue' onClick={() => history.push(`${reportingRoutePath}/finance/reporting/check-run`)}>
          Check Run Reports
        </button>
      </header>
      <AsyncContainerUI
        isLoading={props.isLoading}
        error={props.error}
        color="white"
      > {
        props.data ?
        <CheckRunHistoryTableUI 
        data={props.data}/> :
        <div>Something went wrong</div>
      }
      </AsyncContainerUI>
    </div>
  )
}
