import {makeHttpCall} from '../../../services/MakeHttpCall';
import config from './../../../config.js';
import {PaperCheckFileDetailsSearchCriteria} from './interfaces';


let formik: any;
let setPageMessageType: any;
let setButtonClicked: any;
let history: any;
let setPaperCheckFileDetailsSearchResponse: any
let setPaperCheckFileHeaderTitle: any
export const setVariables = (formikObj: any, historyObject: any,
                             pageMessageDefaultObj: any, setPageMessageObj: any, setButtonClickedObj: any,
                             setPaperCheckFileDetailsSearchResponseObj: any,
                             setPaperCheckFileHeaderTitleObj: any) => {
    formik = formikObj;
    history = historyObject;
    setPageMessageType = setPageMessageObj;
    setButtonClicked = setButtonClickedObj;
    setPaperCheckFileDetailsSearchResponse = setPaperCheckFileDetailsSearchResponseObj
    setPaperCheckFileHeaderTitle = setPaperCheckFileHeaderTitleObj

}

export const refreshComponent = (response: any) => {
    console.log('UpdateProfile', response);
    if (!(response.error === undefined)) {
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}

export const onSubmit = async (values: PaperCheckFileDetailsSearchCriteria) => {
}

export const getPaperCheckFileDetails = async (paperCheckFileNumber: string) => {
    try {
        const options =
            {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/ach/papercheck/${paperCheckFileNumber}`,
            }
        const response:any = await makeHttpCall(options);
        // console.log(JSON.stringify(response))
        let responseData:any = response.data;
        if (response.data.length === 0) {
            setPaperCheckFileDetailsSearchResponse([]);
            setPaperCheckFileHeaderTitle("")
            setPageMessageType({message: "No Results Found.", messageType: "SUCCESS"});
        } else {
            setPaperCheckFileHeaderTitle("Payment Retries by Paper Check: Batch ")
            setButtonClicked(true);
            setPaperCheckFileDetailsSearchResponse(response.data);
        }
    } catch (e) {
        setButtonClicked(true);
        setPaperCheckFileDetailsSearchResponse([]);
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    }

}


export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: PaperCheckFileDetailsSearchCriteria) => {
    const errors = {};
    if (values.paperCheckFileNumber) {
        if (values.paperCheckFileNumber.length > 0 && values.paperCheckFileNumber.length !== 7) {
            Object.assign(errors, {batchNumber: 'Invalid Paper Check File Number'});
        }
    }
    return errors;
}


