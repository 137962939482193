export const ExpenseTypes = [
    'All',
    'Weekly Taxes',
    'Pull Tab Order',
    'License Renewal Fee'
];

export const ChargeStatuses = [
    'All',
    'Out of Balance',
    'Scheduled',
    'On Hold',
    'Pending',
    'Submitted',
    'Rejected',
    'Paid',
    'Awaiting Check'
];