import * as React from 'react'
import { Agent1099PrintPayment } from '../../../api/agent-1099-print-queue-history-fetcher/api-types'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
} from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import { GenericTableUI } from '../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { getBlockClass } from '../../../utilities/helpers'
import './styles.scss'
import { formatCentsToDollars } from '../../../util'
export type Agent1099PrintDetailsPageUIProps = {
  dateGenerated?: string
  payments?: Agent1099PrintPayment[]
  isLoading: boolean
  error?: any
}

type MappedAgent1099PrintPayment = {
  year: string
  fid: string
  corpName: string
  address: string
  city: string
  total: string
}

const COLUMN_MAP: GenericTableColumnMap<MappedAgent1099PrintPayment> = [
  {
    key: 'year',
    columnText: 'Year',
    columnType: GenericTableColumnType.DEFAULT,
  },
  { key: 'fid', columnText: 'FID', columnType: GenericTableColumnType.DEFAULT },
  {
    key: 'corpName',
    columnText: 'Corp Name',
    columnType: GenericTableColumnType.DEFAULT,
  },
  {
    key: 'address',
    columnText: 'Address',
    columnType: GenericTableColumnType.DEFAULT,
  },
  {
    key: 'city',
    columnText: 'City',
    columnType: GenericTableColumnType.DEFAULT,
  },
  {
    key: 'total',
    columnText: 'Total',
    columnType: GenericTableColumnType.DEFAULT,
  },
]

const ROOT_CLASS = 'agent-1099-print-details-page-ui'
export const Agent1099PrintDetailsPageUI = (
  props: Agent1099PrintDetailsPageUIProps
) => {
  const rows = !props.payments
    ? []
    : props.payments.map((val) => {
        return {
          year: val.year,
          fid: val.tin,
          corpName: val.name,
          address: val.address,
          city: val.city,
          total: formatCentsToDollars(val.reportableInCents),
        }
      })
  return (
    <section className={ROOT_CLASS}>
      <BackButtonUI message="Back to Agent 1099 Print Queue" />
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Print Details
        <span className={getBlockClass(ROOT_CLASS, 'header-subtitle')}>
          Date Generated: {props.dateGenerated}{' '}
        </span>
      </header>
      <section className={getBlockClass(ROOT_CLASS, 'content-container')}>
        <AsyncContainerUI
          isLoading={props.isLoading}
          error={props.error}
          color="white"
          errorTextColor="white"
          fitted
        >
          <GenericTableUI
            columnMap={COLUMN_MAP}
            rowData={rows}
            usePages
            rowsPerPage={15}
          />
        </AsyncContainerUI>
      </section>
    </section>
  )
}
