import React from 'react'
import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import {
  CreatePlayslipsStepDownRequest,
  CreatePromotionMessageRequest,
  CreatePromotionMessageResponse,
  PlayslipsAllActivePlayslipsGamesResponse,
  PlayslipsAllPromotionMessagesResponse,
  PlayslipsAllStepDownsResponse,
  UpdatePromotionMessageRequest,
} from './constants'
import {
  AllPlayslipsStepDownPermissions,
  AllPromotionalMessagesPermissions,
  PlayslipsStepDownPermissions,
  PromotionalMessagesPermissions,
} from './permissions'

const playslipsBaseUrl = `${config.SERVER_BASE_URL}/playslips/api/v1`
const getAllActivePlayslipsGamesUrl = (excludePendingStepDowns = false) =>
  `${playslipsBaseUrl}/active-games/mslc/mslc?excludePendingStepDowns=${excludePendingStepDowns}`

export const promotionMessagesUrl = `${playslipsBaseUrl}/promotion`
export const basePromotionMessageUrl = (promotionId: string) =>
  `${promotionMessagesUrl}/${promotionId}`
export const playslipsStepDownBaseUrl = `${config.SERVER_BASE_URL}/playslips/api/v1/config`
export const getAllPlayslipsStepDownsUrl = `${playslipsStepDownBaseUrl}/mslc/mslc/step-down`
export const basePlayslipsStepDownUrl = (gameIdentifier: string) =>
  `${playslipsStepDownBaseUrl}/mslc/mslc/${gameIdentifier}/step-down`

export const useFetchAllActivePlayslipsGames = (
  excludePendingStepDown = false
) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PlayslipsAllActivePlayslipsGamesResponse>()

  React.useEffect(() => {
    fetchUrl(
      getAllActivePlayslipsGamesUrl(excludePendingStepDown),
      'GET',
      {},
      undefined,
      [...AllPromotionalMessagesPermissions, ...AllPlayslipsStepDownPermissions]
    )
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}

export const useFetchAllPromotionMessages = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PlayslipsAllPromotionMessagesResponse>()

  const fetchAllPromotionMessages = async () =>
    await fetchUrl(
      promotionMessagesUrl,
      'GET',
      {},
      undefined,
      AllPromotionalMessagesPermissions
    )

  React.useEffect(() => {
    fetchAllPromotionMessages()
  }, [])

  return {
    isLoading,
    error,
    data,
    fetchAllPromotionMessages,
  }
}

export const useFetchCreatePromotionMessage = () => {
  const { isLoading, error, data, requestSuccessful, fetchUrl } =
    useFetch<CreatePromotionMessageResponse>()

  const createPromotionMessage = async (
    promotionMessage: CreatePromotionMessageRequest
  ) =>
    await fetchUrl(
      promotionMessagesUrl,
      'POST',
      {},
      JSON.stringify(promotionMessage),
      [PromotionalMessagesPermissions.CAN_ADMIN_PROMOTIONAL_MESSAGES]
    )

  return {
    createPromotionMessage,
    requestSuccessful,
    isLoading,
    error,
    data,
  }
}

export const useFetchUpdatePromotionMessage = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } =
    useFetch<undefined>()

  const updatePromotionMessage = async (
    promotionMessage: UpdatePromotionMessageRequest
  ) => {
    const { id, ...body } = promotionMessage
    if (!id) {
      throw new Error('Id must be present')
    }
    await fetchUrl(
      basePromotionMessageUrl(id.toString()),
      'PUT',
      {},
      JSON.stringify(body),
      [PromotionalMessagesPermissions.CAN_ADMIN_PROMOTIONAL_MESSAGES]
    )
  }

  return {
    updatePromotionMessage,
    requestSuccessful,
    isLoading,
    error,
  }
}

export const useFetchDeletePromotionMessage = (id: number) => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch<void>()

  const deletePromotionMessage = async () =>
    await fetchUrl(
      basePromotionMessageUrl(id.toString()),
      'DELETE',
      {},
      undefined,
      [PromotionalMessagesPermissions.CAN_ADMIN_PROMOTIONAL_MESSAGES]
    )

  return {
    deletePromotionMessage,
    requestSuccessful,
    isLoading,
    error,
  }
}

export const useFetchAllPlayslipsStepDowns = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PlayslipsAllStepDownsResponse>()

  const fetchAllPlayslipsStepDowns = async () =>
    await fetchUrl(
      getAllPlayslipsStepDownsUrl,
      'GET',
      {},
      undefined,
      AllPlayslipsStepDownPermissions
    )

  React.useEffect(() => {
    fetchAllPlayslipsStepDowns()
  }, [])

  return {
    isLoading,
    error,
    data,
    fetchAllPlayslipsStepDowns,
  }
}

export const useFetchCreatePlayslipsStepDown = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch<void>()

  const createPlayslipsStepDown = async (
    stepDown: CreatePlayslipsStepDownRequest
  ) =>
    await fetchUrl(
      basePlayslipsStepDownUrl(stepDown.gameIdentifier),
      'POST',
      {},
      JSON.stringify({
        stepDownTimeISO: stepDown.stepDownTimeISO,
      }),
      [PlayslipsStepDownPermissions.CAN_ADMIN_PLAYSLIPS_STEP_DOWN]
    )

  return {
    createPlayslipsStepDown,
    requestSuccessful,
    isLoading,
    error,
  }
}

export const useFetchDeletePlayslipsStepDown = (gameIdentifier: string) => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch<void>()

  const deletePlayslipsStepDown = async () =>
    await fetchUrl(
      basePlayslipsStepDownUrl(gameIdentifier),
      'DELETE',
      {},
      undefined,
      [PlayslipsStepDownPermissions.CAN_ADMIN_PLAYSLIPS_STEP_DOWN]
    )

  return {
    deletePlayslipsStepDown,
    requestSuccessful,
    isLoading,
    error,
  }
}
