import * as React from 'react'
import { PromotionBuilderReward } from '../../../../../../api/promotions-fetcher/constants'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../../../utilities/helpers'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
} from '../helpers'
import { NumberInput } from '../../../../../CommonComponents/number-input-component/number-input-ui'
import { FormikProps, getIn } from 'formik'
import { ErrorMessageFieldCommonComponent } from '../../../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { PromotionCampaignValidation } from '../validation'
import '../styles.scss'

type PromotionEntryDistributionFormUIProps = {
  index: number
  entryDistribution: PromotionBuilderReward
  formik: FormikProps<PromotionCampaignValidation>
  isLoading: boolean
  removeElement: () => Promise<void>
}

export const PromotionEntryDistributionFormUI = (
  props: PromotionEntryDistributionFormUIProps
) => {
  return (
    <div className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'container')}>
      <div
        className={getModifierClass(
          PROMOTION_SIDEBAR_FORM_CLASS,
          'horizontal',
          true
        )}
      >
        <div
          className={joinClasses([
            PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
            getModifierClass(
              PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
              'no-margin',
              true
            ),
          ])}
        >
          <NumberInput
            label={'Entry Quantity'}
            name={`promotionRewardGroups[0].promotionRewards[${props.index}].quantity`}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            value={props.entryDistribution.quantity ?? ''}
            touched={getIn(
              props.formik.touched,
              `promotionRewardGroups[0].promotionRewards[${props.index}].quantity`
            )}
            disabled={props.isLoading}
            min={1}
            step={1}
            required
            inputWidthPx={136}
            margin={'8px 0 0 0'}
          />
        </div>
        <div
          className={joinClasses([
            PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
            getModifierClass(
              PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
              'no-margin',
              true
            ),
          ])}
        >
          <NumberInput
            label={'Distribution Percentage'}
            name={`promotionRewardGroups[0].promotionRewards[${props.index}].distributionPercentage`}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            value={
              props.entryDistribution.distributionPercentage.toString() ?? ''
            }
            touched={getIn(
              props.formik.touched,
              `promotionRewardGroups[0].promotionRewards[${props.index}].distributionPercentage`
            )}
            disabled={props.isLoading}
            min={1}
            max={100}
            step={1}
            required
            onRemove={
              props.index > 0 && !Boolean(props.entryDistribution.id)
                ? props.removeElement
                : undefined
            }
            removeButtonAriaLabel={`Remove Distribution ${props.index}`}
            trailingText="%"
            inputWidthPx={112}
            margin={'8px 0 0 0'}
          />
        </div>
      </div>
      {getIn(props.formik.errors, `promotionRewards[${props.index}]`) && (
        <ErrorMessageFieldCommonComponent
          errorMessage={getIn(
            props.formik.errors,
            `promotionRewards[${props.index}]`
          )}
        />
      )}
    </div>
  )
}
