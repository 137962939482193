import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import styles from './css/styles.module.css';
import {getPurchaseOrderDetails} from "./functions";
import goBackIcon from "../../../../../icons/Icon_arrows_carrot_white-left.svg";
import {keyPressedType} from "../../../../../services/Commons";
import {Tab, Tabs} from 'react-bootstrap';
import {NotificationBannerComponent} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import {pulltabRoutePath} from "../../../../../config";
import {PurchaseOrderDetailsDefaultData, PurchaseOrderDetailsInterface} from "./interfaces";
import {formatCentsToDollars} from "../../../../../util";

export const PullTabOrderDetailsPage = inject("charitableGamingStore")(observer(({purchaseOrderId, charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const backButtonText = localStorage.getItem("PullTabOrderDetailsPageBackButtonText");

        const {notificationBanner} = charitableGamingStore;

        const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<PurchaseOrderDetailsInterface>(PurchaseOrderDetailsDefaultData);
        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);

        useEffect(() => {
            if(location.state) {
                localStorage.setItem("PullTabOrderDetailsPageBackButtonText", location.state.backButtonText);
            }
            if (requestCompleted === false) {
                getPurchaseOrderDetails(purchaseOrderId, setSelectedPurchaseOrder, setRequestCompleted);
                setRequestCompleted(false);
            }
        }, []);

        const hideNotificationBanner = () => {
            charitableGamingStore.showNotificationBanner({show: false, text: ''});
        }

        return (
            <>
                <div className={`${styles['permit-holder-details-page-container']}`}
                     onClick={() => {

                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['permit-holder-details-page-back-button-container']} ${styles['']}`}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 localStorage.setItem("backButtonText", "");
                                 history.goBack();
                             }
                         }}
                         onClick={() => {
                             localStorage.setItem("backButtonText", "");
                             history.goBack();
                         }}
                    >
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['permit-holder-details-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                        >{backButtonText}
                        </div>
                    </div>

                    <div className={`${styles["permit-holder-details-page-ribbon-container"]}`}>
                        <div className={`${styles["permit-holder-details-page-ribbon-container-col-1"]}`}>
                            <div className={styles["permit-holder-details-page-ribbon-title-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-title-label"]}>Order
                                    #{selectedPurchaseOrder.purchaseOrder.purchaseOrderId}</div>
                                {/*                                <div
                                    className={styles["permit-holder-details-page-ribbon-title-value"]}>{`${purchaseOrderId}`}</div>*/}
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                    <div
                                        className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Organization
                                    </div>
                                    <div
                                        className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedPurchaseOrder.purchaseOrder.orgDetails.orgName}</div>
                                </div>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                    <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>License
                                        No.
                                    </div>
                                    <div
                                        className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedPurchaseOrder.purchaseOrder.licenseNumber}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["permit-holder-details-page-ribbon-container-col-2"]}`}>
                            {checkRenderPermissions(permissions.CAN_RETURN_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['license-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             history.push({
                                                 pathname: `${pulltabRoutePath}/orders/return-order/${selectedPurchaseOrder.purchaseOrder.purchaseOrderId}`,
                                                 state: selectedPurchaseOrder
                                             });
                                         }
                                     }}
                                     onClick={() => {
                                         history.push({
                                             pathname: `${pulltabRoutePath}/orders/return-order/${selectedPurchaseOrder.purchaseOrder.purchaseOrderId}`,
                                             state: selectedPurchaseOrder
                                         });
                                     }}>
                                    <div
                                        className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                        Return Order
                                    </div>
                                </div> : null}
                        </div>
                    </div>

                    <div className={`${styles["permit-holder-details-page-tabs-container"]}`}>
                        <Tabs className={`${styles["permit-holder-details-tabs"]}`} id="permit-holder-details-tabs">
                            <Tab eventKey="orderDetails" title="Order Details">
                                <div className={`${styles["row-1-container"]}`}>
                                    <div className={`${styles["row-1-col-1-container"]}`}>
                                        <div className={`${styles["row-container-title"]}`}>Organization</div>
                                        <div className={`${styles['view-perjury-details-controls-container']}`}>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Org.
                                                Name
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.orgDetails.orgName}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Street
                                                Address
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.orgDetails.orgAddress.streetAddress}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.orgDetails.orgAddress.city}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.orgDetails.orgAddress.zip}</div>

                                        </div>
                                    </div>
                                    <div className={`${styles["row-1-col-2-container"]}`}>
                                        <div className={`${styles["row-container-title"]}`}>License</div>
                                        <div className={`${styles['view-perjury-details-controls-container']}`}>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>License
                                                No.
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.licenseNumber}</div>

                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles["row-2-container"]}`}>
                                    <div className={`${styles["row-2-row-1-container"]}`}>
                                        <div className={`${styles["row-container-title"]}`}>Order Summary</div>
                                        <div className={`${styles['view-perjury-details-controls-container']}`}>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Order
                                                Date
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{moment(selectedPurchaseOrder.purchaseOrder.date).format('MM/DD/YYYY')}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>Shipping
                                                Method
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.shipMethod}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Payment
                                                Method
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.paymentMethod}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>Receiver
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{(selectedPurchaseOrder.purchaseOrder.receiver) ? selectedPurchaseOrder.purchaseOrder.receiver : 'n/a'}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Warehouse
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedPurchaseOrder.purchaseOrder.warehouseId}</div>

                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-3']} ${styles['mslc-label']}`}>MSLC
                                                Signature
                                            </div>
                                            <div
                                                className={`${styles['view-perjury-details-controls-container-column-4']} ${styles['mslc-label']}`}>{(selectedPurchaseOrder.purchaseOrder.authSignature) ? selectedPurchaseOrder.purchaseOrder.authSignature : 'n/a'}</div>

                                        </div>
                                    </div>
                                    <div className={`${styles["row-2-row-2-container"]}`}>
                                        <div className={`${styles["row-2-row-2-col-1-container"]}`}>
                                            <div className={styles['table']}>
                                                <div
                                                    className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                                                    <div>Stock No.</div>
                                                    <div>Serial Numbers</div>
                                                    <div>Quantity</div>
                                                    <div>Unit Price</div>
                                                    <div>Total Price</div>
                                                </div>
                                                <hr className={styles['horizontal-line']}/>
                                                <div className={styles['table-data-body']}>
                                                    {
                                                        selectedPurchaseOrder.purchaseOrder.productDetails.length > 0 && selectedPurchaseOrder.purchaseOrder.productDetails.map((e: any, i: number) =>
                                                            <div>
                                                                <div key={i}
                                                                     className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                                                    <div className={styles['table-data-data-text']}>
                                                                        {e.stockNumber}
                                                                    </div>
                                                                    <div className={styles['table-data-data-text']}>
                                                                        {`${e.serialNumberStart} - ${e.serialNumberEnd}`}
                                                                    </div>
                                                                    <div className={styles['table-data-data-text']}>
                                                                        {e.numUnits}
                                                                    </div>
                                                                    <div className={styles['table-data-data-text']}>
                                                                        {formatCentsToDollars(e.unitPriceInCents)}
                                                                    </div>
                                                                    <div className={styles['table-data-data-text']}>
                                                                        {formatCentsToDollars(e.totalProductPriceInCents)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${styles["row-2-row-2-col-2-container"]}`}>
                                            <div className={`${styles["row-container-title"]}`}>Total Order</div>
                                            <div className={`${styles['total-order-summary-controls-container']}`}>

                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-1']} ${styles['padding-top']}`}>Total
                                                    Quantity
                                                </div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-2']} ${styles['padding-top']}`}>{selectedPurchaseOrder.purchaseOrder.totalUnits}</div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-3']} ${styles['padding-top']}`}>Subtotal
                                                </div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-4']} ${styles['padding-top']}`}>{formatCentsToDollars(selectedPurchaseOrder.purchaseOrder.summedProductPriceInCents)}</div>

                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-1']} ${styles['padding-top-1']}`}>Net
                                                    Weight
                                                </div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-2']} ${styles['padding-top-1']}`}>{(selectedPurchaseOrder.purchaseOrder.totalWeightInLbs) ? `${selectedPurchaseOrder.purchaseOrder.totalWeightInLbs} Lbs` : 'n/a'}</div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-3']} ${styles['padding-top-1']}`}>Shipping
                                                    Cost
                                                </div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-4']} ${styles['padding-top-1']}`}>{formatCentsToDollars(selectedPurchaseOrder.purchaseOrder.shippingPriceInCents)}</div>

                                            </div>
                                            <hr className={styles['horizontal-line']}/>
                                            <div className={`${styles['total-order-summary-controls-container']}`}>

                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-3']}`}>Grand
                                                    Total
                                                </div>
                                                <div
                                                    className={`${styles['total-order-summary-controls-container-column-4']}`}>{formatCentsToDollars(selectedPurchaseOrder.purchaseOrder.totalPricePaidInCents)}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>

                </div>
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        text={notificationBanner.text}
                        type={notificationBanner.type}
                        handleHide={hideNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);