import * as React from 'react'
import { PropsWithChildren, useState } from 'react'
import { PaginationCommonComponent } from '../PaginationCommonComponent'
import { getBlockClass } from '../../../utilities/helpers'
import { getPageData } from '../../../util'
import {
  GenericTableColumnMap,
  GenericTableRow,
  GenericTableRowUI,
} from './generic-table-row/generic-table-row-ui'
import './styles.scss'

const ROOT_CLASS = 'generic-table'
const HEADER_CLASS = `${ROOT_CLASS}-header`
const FOOTER_CLASS = `${ROOT_CLASS}-footer`

const DEFAULT_ROWS_PER_PAGE = 10

export type GenericTableUIProps<T> = {
  columnMap: GenericTableColumnMap<T>
  rowData: GenericTableRow<T>[]
  useColumnWidths?: boolean
  usePages?: boolean
  rowsPerPage?: number
  useActionMenu?: boolean
  customRowCellStyling?: React.CSSProperties
  customHeaderCellStyling?: React.CSSProperties
}

export const GenericTableUI = <T extends Record<string, any>>(
  props: PropsWithChildren<GenericTableUIProps<T>>
) => {
  const [currentPage, setCurrentPage] = useState<number>(0)

  const numRowsPerPage = props.usePages
    ? props.rowsPerPage ?? DEFAULT_ROWS_PER_PAGE
    : undefined

  const totalPages =
    props.usePages && numRowsPerPage
      ? Math.max(1, Math.ceil(props.rowData.length / numRowsPerPage))
      : 0

  const pageData: GenericTableRow<T>[] = props.usePages
    ? getPageData(props.rowData, currentPage, numRowsPerPage)
    : props.rowData

  const GenericTableHeader = () => (
    <thead className={HEADER_CLASS}>
      <tr>
        {props.columnMap.map((column, index) => {
          return (
            <th
              className={getBlockClass(HEADER_CLASS, 'cell')}
              key={index}
              style={{
                ...props.customHeaderCellStyling,
                width: column.widthPx ?? 'fit-content',
                textAlign: column.textAlign
              }}
            >
              {column.columnText}
            </th>
          )
        })}
        {props.useActionMenu && (
          <th
            key="action-menu"
            className={getBlockClass(HEADER_CLASS, 'cell')}
          />
        )}
      </tr>
    </thead>
  )

  React.useEffect(() => {
    // Decrement pages until value found if filtering
    if (pageData.length === 0 && props.rowData.length != 0) {
      setCurrentPage(currentPage - 1)
    }
  }, [pageData])

  const GenericTableFooter = () => (
    <div className={FOOTER_CLASS}>
      <div
        tabIndex={0}
        className={getBlockClass(FOOTER_CLASS, 'page-label')}
      >{`Page ${currentPage + 1} of ${totalPages}`}</div>
      <PaginationCommonComponent
        playerData={props.rowData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={numRowsPerPage}
      />
    </div>
  )

  return (
    <div className={`${ROOT_CLASS}-content`}>
      <table className={ROOT_CLASS}>
        <GenericTableHeader />
        {pageData.map((row, i) => (
          <GenericTableRowUI
            key={i}
            row={row}
            columnMap={props.columnMap}
            index={i}
            customCellStyling={props.customRowCellStyling}
          />
        ))}
      </table>
      {props.usePages && <GenericTableFooter />}
    </div>
  )
}
