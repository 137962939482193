import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import {OrganizationTypeCodes} from "../../../../../Constants/CharitableConstant";


export const CreateOrganizationTypeComponent = ({
                                                    formik,
                                                    setFiledValue,
                                                }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Organization Type
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Select which type best
                        describe organization:*
                    </div>
                    <select
                        id="orgDetails.orgCode"
                        name="orgDetails.orgCode"
                        onChange={(e: any) => setFiledValue(e)}
                        onBlur={formik.handleBlur}
                        className={styles["page-container-body-left-input-box"]}>
                        <option value="" selected disabled>Select...</option>
                        {
                            OrganizationTypeCodes.map((typeCode: string) =>
                                <option
                                    selected={formik.values.orgDetails.orgCode === typeCode}
                                    value={typeCode}>{typeCode}
                                </option>)
                        }
                    </select>
                    {(formik.touched.orgDetails?.orgCode && formik.errors.orgCode) ?
                        <ErrorMessageFieldCommonComponent
                            orgCode={formik.errors.orgCode}/> : null}
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>If type is other,
                        specify below.*
                    </div>
                    <input
                        id="orgDetails.orgCodeOther"
                        name="orgDetails.orgCodeOther"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.orgDetails.orgCodeOther}
                        onBlur={formik.handleBlur}
                    />
                    {(formik.touched.orgDetails?.orgCodeOther && formik.errors.orgCodeOther) ?
                        <ErrorMessageFieldCommonComponent
                            orgCodeOther={formik.errors.orgCodeOther}/> : null}
                </div>
            </form>
        </>

    );
}