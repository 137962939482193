import * as React from 'react'
import { getBlockClass } from '../../../utilities/helpers'
import { LoadingDots } from '../loading-dots/loading-dots-ui'
import './deletion-modal.styles.scss'

export type EntryDeletionConfirmationModalProps = {
  isLoading: boolean
  error?: string
  isPlural: boolean
  disableBarcode: () => Promise<void>
  cancelDeletion: () => void
}

const ROOT_CLASS = 'promotion-entry-details-delete-modal'

const EntryDeletionConfirmationContent = (
  props: EntryDeletionConfirmationModalProps
) => {
  if (props.isLoading) {
    return (
      <div
        className={getBlockClass(ROOT_CLASS, `loading`)}
        aria-describedby="loading"
        aria-busy
      >
        <LoadingDots id="loading" />
      </div>
    )
  }
  return (
    <>
      {props.error && (
        <div className={getBlockClass(ROOT_CLASS, `error`)}>{props.error}</div>
      )}
      <button
        className={getBlockClass(ROOT_CLASS, `delete-button`)}
        onClick={async () => await props.disableBarcode()}
        aria-label="Confirm Disabling Entries"
      >
        Disable
      </button>
    </>
  )
}

export const DeleteConfirmationModal = (
  props: EntryDeletionConfirmationModalProps
) => (
  <>
    <h3 className={getBlockClass(ROOT_CLASS, `confirm-delete`)}>
      {`Are you sure you would like to disable ${
        props.isPlural ? 'these entries' : 'this entry'
      }?`}
    </h3>
    <EntryDeletionConfirmationContent {...props} />
    <button
      className={getBlockClass(ROOT_CLASS, `back-button`)}
      onClick={props.cancelDeletion}
      aria-label="Go Back"
      disabled={props.isLoading}
    >
      Go Back
    </button>
  </>
)
