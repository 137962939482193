import {UpdateLicenseSuccessMessage} from "../Common/UpdateLicenseSuccessMessage";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {licenseeRoutePath} from "../../../../config";

let licenseNumber: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === 'orgDetails.orgAddress.zip' || e.target.name === 'orgDetails.mailAddress.zip') {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value, true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.orgDetails.orgName) {
        Object.assign(errors, {orgDetails: {orgName: InputValidationMessages.RequiredField}});
    }

    /*    if (!values.orgDetails.orgFID) {
            Object.assign(errors, {orgDetails: {orgFID: 'Required'}});
        }*/

    if (!values.orgDetails.orgPhone) {
        Object.assign(errors, {orgDetails: {orgPhone: InputValidationMessages.RequiredField}});
    } else if (values.orgDetails.orgPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.orgDetails.orgPhone)) {
        Object.assign(errors, {orgDetails: {orgPhone: InputValidationMessages.InvalidTelephone}});
    }

    if (!values.orgDetails.orgDateChartered) {
        Object.assign(errors, {orgDetails: {orgDateChartered: InputValidationMessages.RequiredField}});
    }

    if (!values.orgDetails.orgAddress.streetAddress) {
        Object.assign(errors, {orgDetails: {orgAddress: {streetAddress: InputValidationMessages.RequiredField}}});
    }

    if (!values.orgDetails.orgAddress.city) {
        Object.assign(errors, {orgDetails: {orgAddress: {city: InputValidationMessages.RequiredField}}});
    }

    if (!values.orgDetails.orgAddress.zip) {
        Object.assign(errors, {orgDetails: {orgAddress: {zip: InputValidationMessages.RequiredField}}});
    } else if (values.orgDetails.orgAddress.zip && values.orgDetails.orgAddress.zip.length !== 5) {
        Object.assign(errors, {orgDetails: {orgAddress: {zip: InputValidationMessages.InvalidZip}}});
    }

    if (!values.orgDetails.mailAddress.streetAddress) {
        Object.assign(errors, {orgDetails: {mailAddress: {streetAddress: InputValidationMessages.RequiredField}}});
    }

    if (!values.orgDetails.mailAddress.city) {
        Object.assign(errors, {orgDetails: {mailAddress: {city: InputValidationMessages.RequiredField}}});
    }

    if (!values.orgDetails.mailAddress.zip) {
        Object.assign(errors, {orgDetails: {mailAddress: {zip: InputValidationMessages.RequiredField}}});
    } else if (values.orgDetails.mailAddress.zip && values.orgDetails.mailAddress.zip.length !== 5) {
        Object.assign(errors, {orgDetails: {mailAddress: {zip: InputValidationMessages.InvalidZip}}});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updateLicense(values, UpdateLicenseSuccessMessage.ORGANIZATION, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {

    // history.goBack();

    history.push({
        pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
        state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
    });

}



