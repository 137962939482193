import React, { useState } from 'react'
import {
  CheckRunReportDisplayOptions,
  CheckRunReportType,
} from '../../../../api/check-run-reporting-fetcher/api-types'
import { CheckRunReportingPageUIProps } from '../../../../pages/FinancePages/CheckRunReportingPage/check-run-reporting-page-ui'
import { PreviewReportGenerateButton } from '../check-run-reporting-generate-button/check-run-reporting-generate-preview-button'
import './styles.scss'

const ROOT_CLASS = 'check-run-reporting-action'

export const CHECK_RUN_REPORTING_OPTIONS = [
  {
    value: CheckRunReportType.PREVIEW_ALL_PAYMENTS,
    label: CheckRunReportDisplayOptions.ALL_PAYMENTS,
  },
  {
    value: CheckRunReportType.PREVIEW_ON_HOLD_PAYMENTS_ONLY,
    label: CheckRunReportDisplayOptions.HOLD_PAYMENTS,
  },
  {
    value: CheckRunReportType.PREVIEW_PENDING_PAYMENTS_ONLY,
    label: CheckRunReportDisplayOptions.NO_HOLD,
  },
  {
    value: CheckRunReportType.PREVIEW_RELEASED_PAYMENTS_ONLY,
    label: CheckRunReportDisplayOptions.RELEASED_HOLDS,
  },
]

export const CheckRunReportingActionUI = (
  props: CheckRunReportingPageUIProps
) => {
  const [selected, setSelected] = useState<CheckRunReportType | undefined>()
  const [generateEnabled, setGenerateEnabled] = useState<boolean>(false)
  const userName =
    localStorage.getItem('loggedInUserFullName') ?? 'UNKNOWN USER'
  const handleSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value as CheckRunReportType
    setSelected(selectedValue)
    setGenerateEnabled(!!selectedValue)
  }

  return (
    <div className={ROOT_CLASS}>
      <section>
        <header className="action-header">Generate Report</header>
        <span className="select-control">
          <label className="select-label" htmlFor="select-option">
            Select Option
          </label>
          <span className="select-container">
            <select defaultValue="" value={selected} onChange={handleSelection}>
              <option disabled={true} value="">
                Select...
              </option>
              {CHECK_RUN_REPORTING_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <span className="select-arrow"></span>
            <PreviewReportGenerateButton
              generateRequestProps={{
                reportName: selected,
                generatedBy: userName,
              }}
              generateEnabled={generateEnabled}
              generateHandler={props.generateHandler}
            />
          </span>
        </span>
      </section>
    </div>
  )
}
