import * as React from 'react'
import { GenericPopupModal } from '../../../../../../components/GenericPopupModal/generic-popup-modal-ui'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { ConsolidatedScanToEnterPromotion } from '../../../../../../api/promotions-fetcher/constants'
import { ScanToEnterProgressRuleSection } from './ScanToEnterProgressRuleSection'
import { OpenGameDetails } from '../helpers'
import { ScanToEnterProgressGameScansTable } from './ScanToEnterProgressGameScansTable'
import './styles.scss'

const ROOT_CLASS = 'scan-to-enter-progress-details-modal'

export type ScanToEnterProgressDetailsModalUIProps = {
  closeModal: () => void
  data: ConsolidatedScanToEnterPromotion
}

export const ScanToEnterProgressDetailsModalUI = (
  props: ScanToEnterProgressDetailsModalUIProps
) => {
  const [openGameDetails, setOpenGameDetails] =
    React.useState<OpenGameDetails>()
  return (
    <GenericPopupModal
      isOpen
      closeModal={props.closeModal}
      roundedEdges
      hideCloseButton
    >
      <div className={ROOT_CLASS}>
        <h2 className={getBlockClass(ROOT_CLASS, 'header')}>
          {props.data.promotionName}
          {openGameDetails
            ? ` - Reward Group ${
                openGameDetails.ruleIdentifier.rewardGroupIndex + 1
              } - Rule ${
                openGameDetails.ruleIdentifier.ruleIndex + 1
              } - Game ID ${openGameDetails.gameId}`
            : ''}
        </h2>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          {openGameDetails ? (
            <ScanToEnterProgressGameScansTable scans={openGameDetails.scans} />
          ) : (
            props.data.rewardGroups.map((rewardGroup, rewardGroupIndex) =>
              rewardGroup.rules.map((rule, ruleIndex) => (
                <ScanToEnterProgressRuleSection
                  key={`rg${rewardGroup.rewardGroupId}-r${rule.ruleId}`}
                  rewardGroupId={rewardGroup.rewardGroupId}
                  rule={rule}
                  rewardGroupIndex={rewardGroupIndex}
                  ruleIndex={ruleIndex}
                  scans={rewardGroup.currentProgress.filter(
                    (scan) => scan.ruleId === rule.ruleId
                  )}
                  setOpenGameDetails={setOpenGameDetails}
                />
              ))
            )
          )}
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'buttons')}>
          {openGameDetails && (
            <button
              className={getBlockClass(ROOT_CLASS, 'go-back-button')}
              onClick={() => setOpenGameDetails(undefined)}
              type="button"
              aria-label="Go Back"
            >
              Go Back
            </button>
          )}
          <button
            className={getBlockClass(ROOT_CLASS, 'close-button')}
            onClick={props.closeModal}
            type="button"
            aria-label="Close Modal"
          >
            Close
          </button>
        </div>
      </div>
    </GenericPopupModal>
  )
}
