import { useHistory } from "react-router-dom"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useFetchPayeeDetails } from "../Create5754SplitPage/use-fetch-form-payee-details"
import { useFetchVoidReissuePayment } from "./use-fetch-void-reissue-payment"
import { BannerContext } from "../../../context/BannerContext"
import { VoidReissueRequest } from "../../../api/void-reissue-payment-fetcher/api-types"
import { PayeeDetailsResponse } from "../../../api/payee-details-fetcher/api-types"
import { CreateVoidReissuePageUI } from "./create-void-reissue-page-ui"
import { PayeeType, PaymentSource } from "../../../api/payment-details-fetcher/api-types"
import { omit } from "lodash"
import { getMatchingKnownCountry } from "../../../Constants/Countries"
import { useFetchPaymentDetails } from "../PaymentDetailsPage/use-fetch-payment-details"

export type CreateVoidReissuePageContainerProps = {
  internalPaymentId: string
}

type VoidReissueFormFieldsIndividual = Omit<VoidReissueRequest, 'payeeFreeformName'> & {
  payeeType: PayeeType.INDIVIDUAL
}
type VoidReissueFormFieldsEntity = Omit<VoidReissueRequest, 'payeeFirstName' | 'payeeMiddleInitial' | 'payeeLastName'> & {
  payeeType: PayeeType.ENTITY
}

export type VoidReissueFormFields = VoidReissueFormFieldsIndividual | VoidReissueFormFieldsEntity

const mapPayeeDetailsToForm = (paymentId: string, payeeDetails?: PayeeDetailsResponse, ): VoidReissueFormFields => {
  if (!payeeDetails) {
    return {
      paymentId,
      tin: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      supplementalAddress1: '',
      supplementalAddress2: '',
      payeeType: PayeeType.ENTITY,
      payeeFreeformName: ''
    }
  }

  const formDetailsBase = {
    paymentId,
    tin: payeeDetails.tin,
    address1: payeeDetails.address1,
    address2: payeeDetails.address2 ?? undefined,
    city: payeeDetails.city,
    state: payeeDetails.state,
    zip: payeeDetails.zip,
    country: getMatchingKnownCountry(payeeDetails.country),
    supplementalAddress1: payeeDetails.supplementalAddress1 ?? undefined,
    supplementalAddress2: payeeDetails.supplementalAddress2 ?? undefined,
    phone: payeeDetails.phone ?? undefined,
    email: payeeDetails.email ?? undefined
  }

  if (payeeDetails.payeeIsEntity) {
    return {
      ...formDetailsBase,
      payeeType: PayeeType.ENTITY,
      payeeFreeformName: payeeDetails.payeeFreeformName ?? ''
    }
  }

  return {
    ...formDetailsBase,
    payeeType: PayeeType.INDIVIDUAL,
    payeeFirstName: payeeDetails.payeeFirstName ?? '',
    payeeMiddleInitial: payeeDetails.payeeMiddleInitial ?? undefined,
    payeeLastName: payeeDetails.payeeLastName ?? '',
  }
}

export const CreateVoidReissuePageContainer = (props: CreateVoidReissuePageContainerProps) => {
  const { internalPaymentId } = props

  const { isLoading: paymentDetailsLoading, error: paymentDetailsError, data: paymentDetails } = useFetchPaymentDetails(internalPaymentId)
  const { isLoading: payeeLoading, error: payeeError, data: existingPayeeDetails } = useFetchPayeeDetails(internalPaymentId)
  const { isLoading: voidReissueLoading, error: voidReissueError, requestSuccessful: voidReissueSuccessful, fetchVoidReissuePayment } = useFetchVoidReissuePayment()

  const history = useHistory()
  const bannerContext = useContext(BannerContext)

  const [showSubmitPopup, setShowSubmitPopup] = useState<boolean>(false)
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false)

  const submittedPayeeDetails = useRef<VoidReissueFormFields | undefined>()

  const submitVoidReissue = async (formFields: VoidReissueFormFields) => {
    submittedPayeeDetails.current = { ...formFields }
    await fetchVoidReissuePayment(omit(formFields, ['payeeType']))
  }

  useEffect(() => {
    if (voidReissueSuccessful) {
      bannerContext.setBannerInfo({ message: 'The payment has been voided and the re-issue process has begun.', error: false })
      history.goBack()
    } else if (!voidReissueLoading && voidReissueError) {
      setShowErrorPopup(true)
    }
  }, [voidReissueLoading])
  
  return (
    <CreateVoidReissuePageUI
      payment={submittedPayeeDetails.current ?? mapPayeeDetailsToForm(internalPaymentId, existingPayeeDetails)}
      paymentSource={paymentDetails ? paymentDetails.payment.source : PaymentSource.OTHER}
      paymentLoading={paymentDetailsLoading}
      payeeLoading={payeeLoading}
      voidReissueLoading={voidReissueLoading}
      payeeError={payeeError}
      submitVoidReissue={submitVoidReissue}
      showSubmitPopup={showSubmitPopup}
      setShowSubmitPopup={setShowSubmitPopup}
      showErrorPopup={showErrorPopup}
      setShowErrorPopup={setShowErrorPopup}
    />
  )
}