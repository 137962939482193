import React from "react";
import styles from "./css/style.module.css";
import { PayeeListRowComponent } from "../PayeeListRowCmpt";
import { PaginationCommonComponent } from "../../../CommonComponents/PaginationCommonComponent";
import { Payee } from "../../../../types/Annuities/PayeeManagement/Payee";
import { getPageData } from "./functions";

export const PayeeManagementListComponent: React.FC<{
  payeeList: Payee[];
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
}> = ({ payeeList, currentPage, setCurrentPage }) => {
  const normilizePage = (totalPages: number, currentPage: number) => {
    const number: number = totalPages - currentPage * 10;
    if (number > 10) return currentPage * 10 + 10;
    else return totalPages;
  };

  return (
    <>
      <div className={`${styles.table} w-100 rounded px-2 pb-2`}>
        <div className={`${styles.table_results} d-flex align-items-center font-italic font-weight-bold pl-1`}>
          <div aria-live="assertive">{`Displaying ${payeeList.length <= 0 ? 0 : currentPage * 10 + 1}-${normilizePage(payeeList.length, currentPage)} of ${payeeList.length} results`}</div>
        </div>
        <div className={`${styles.table_header} w-100 rounded-top font-weight-bold pl-2`}>
          <div>Payee Name</div>
          <div>TIN</div>
          <div>Payee Type</div>
          <div></div>
        </div>

        <div className={styles.table_body}>
          {payeeList.length > 0 ? (
            getPageData(payeeList, currentPage).map((payee: Payee, index: 0) => <PayeeListRowComponent key={index++} payee={payee} />)
          ) : (
            <div className="mt-3 text-center" style={{fontSize: "1.3em"}}>
              <i>There are no results to display.</i>
            </div>
          )}
        </div>
        <div className={`${styles.table_footer} p-2 rounded-bottom w-100 d-flex align-items-center justify-content-end`}>
          <div aria-live="assertive" className="mr-2" style={{fontSize: "0.875em"}}>{`Page ${payeeList.length === 0 ? 0 : currentPage + 1} of ${Math.ceil(payeeList.length / 10).toString()}`}</div>
          {<PaginationCommonComponent playerData={payeeList} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
        </div>
      </div>
    </>
  );
};
