import React from "react";
import styles from './css/styles.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const RenewLicensesInfoMessageComponent = () => {

    return (
        <>
            <div tabIndex={0} className={styles['renew-license-info-message-container']}>
                <div className={styles['renew-license-info-message-carret']}>
                    <FontAwesomeIcon icon={faCaretUp as IconProp}/>
                </div>
                <div role="alert" tabIndex={0} aria-live={"assertive"}
                     className={styles['renew-license-info-message-title']}>
                    Attention!
                </div>
                <div role="alert" tabIndex={0} aria-live={"assertive"}
                     className={styles['renew-license-info-message-text']}>
                    The ability to renew licenses for the upcoming license period will not be available until the month
                    of October.
                </div>

            </div>

        </>

    );
}