import React from "react"
import './styles.scss'
import { Link } from "react-router-dom"
import { paymentsRoutePath } from '../../../../../config'
import { HiExternalLink } from "react-icons/hi"
import { VoidOrReissueHeader } from "../header/header"
import { VoidOrReissueTableUI } from "../void-or-reissue-details-table/void-or-reissue-details-table"

type VoidOrReissueDetailsProps = {
  voidOrReissueDate: string | null
  voidOrReissuePaymentId: string
  columns: string[]
  title: string 
  tooltipMessage: string
}

export type VoidOrReissueDetailsDisplayRow = {
  voidOrReissueDate: string | null
  voidedOrReissuedPaymentLink: any
}

const convertVoidOrReissueDetailsToRow = (details: VoidOrReissueDetailsProps): VoidOrReissueDetailsDisplayRow => {
  return {
    voidOrReissueDate: details.voidOrReissueDate,
    voidedOrReissuedPaymentLink: (
      <Link 
        to={ `${paymentsRoutePath}/details/${details.voidOrReissuePaymentId}` }
      >
        <HiExternalLink size={20} color={ 'white' }/>
      </Link>
     )
  }
}

const ROOT_CLASS = `payment-details-void-or-reissue-details`
export const VoidOrReissueDetails = (props: VoidOrReissueDetailsProps) => {
  return (
    <div className={ ROOT_CLASS }>
      <VoidOrReissueHeader
        title={props.title}
        tooltipMessage={props.tooltipMessage}
      />
      <VoidOrReissueTableUI 
        columns={props.columns} 
        data={convertVoidOrReissueDetailsToRow(props)}
      />
    </div>
  )
}