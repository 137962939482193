import moment from 'moment'

export type ScheduleDrawingValidation = {
  drawingGroupIdentifier: string
  scheduledDrawTime: string
  scheduledDrawCollectionEndTime: string
}

const ERROR_TEXT = 'This field is required'
const DRAW_DATE_MUST_BE_AFTER_COLLECTION_END =
  'Schedule draw time must be after scheduled draw collection end time'

export const validateScheduleDrawing = (values: ScheduleDrawingValidation) => {
  let errors = {}

  if (!values.drawingGroupIdentifier) {
    Object.assign(errors, { drawingGroupIdentifier: ERROR_TEXT })
  }

  if (!values.scheduledDrawTime) {
    Object.assign(errors, { scheduledDrawTime: ERROR_TEXT })
  }

  if (!values.scheduledDrawCollectionEndTime) {
    Object.assign(errors, { scheduledDrawCollectionEndTime: ERROR_TEXT })
  }

  if (
    values.scheduledDrawTime &&
    values.scheduledDrawCollectionEndTime &&
    moment(values.scheduledDrawTime).isSameOrBefore(
      values.scheduledDrawCollectionEndTime
    )
  ) {
    Object.assign(errors, {
      scheduledDrawTime: DRAW_DATE_MUST_BE_AFTER_COLLECTION_END,
    })
  }

  return errors
}
