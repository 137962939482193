import moment from 'moment'
import { useFetch } from '../../hooks/use-fetch'
import { objToQueryParams } from '../../utilities/helpers'
import {
  PromotionsDateRangeReportProps,
  PromotionsHourlyReportProps,
  PromotionsHourlyReportResponse,
} from './constants'
import { AllPromotionReportingPermissions } from './permissions'
import { promotionsBaseUrl } from './use-fetch-base-promotions'

export const useFetchPromotionDateRangeReport = () => {
  const { isLoading, error, response, fetchUrl } = useFetch<any>()
  const email = localStorage.getItem('userName')
  const generateReport = (props: PromotionsDateRangeReportProps) => {
    const url = `${promotionsBaseUrl}/${props.selectedPromotion}/reporting/summary`
    const body = {
      dateRangeStart: moment(props.startDate).toISOString(),
      dateRangeEnd: moment(props.endDate).toISOString(),
      includeDeleted: String(props.disabledEntries),
      recipientEmailAddress: email,
    }
    return fetchUrl(
      url,
      'POST',
      {},
      JSON.stringify(body),
      AllPromotionReportingPermissions
    )
  }

  return {
    isLoading,
    response,
    error,
    generateReport,
  }
}

export const useFetchPromotionHourlyReport = () => {
  const { isLoading, error, data, fetchUrl, resetData } =
    useFetch<PromotionsHourlyReportResponse>()

  const generateReport = (props: PromotionsHourlyReportProps) => {
    const url = `${promotionsBaseUrl}/${props.selectedPromotion}/hourly-report`
    const queryParams = {
      date: moment(props.reportDate).toISOString(),
    }
    const params = objToQueryParams(queryParams)
    const urlWithParams = `${url}?${params}`
    fetchUrl(
      urlWithParams,
      'GET',
      {},
      undefined,
      AllPromotionReportingPermissions
    )
  }

  return {
    isLoading,
    error,
    data,
    resetData,
    generateReport,
  }
}
