import React from 'react'
import { TimePaymentAccountResponse } from '../../../../types/Annuities/responses/time-payment-account'
import { formatCentsToDollars } from '../../../../util'
import styles from './styles.module.css'

export const PaymentInfoComponent: React.FC<{
  timePaymentAccount: TimePaymentAccountResponse
}> = ({ timePaymentAccount }) => {
  const values = [
    {
      label: 'Gross Payment',
      value: formatCentsToDollars(timePaymentAccount.grossAmount),
    },
    {
      label: 'IRS Withheld',
      value: formatCentsToDollars(timePaymentAccount.irsAmount),
    },
    {
      label: 'DOR Withheld',
      value: formatCentsToDollars(timePaymentAccount.dorsAmount),
    },
    {
      label: 'Offsets Withheld',
      value: formatCentsToDollars(timePaymentAccount.offsetsAmount),
    },
    {
      label: 'Net Payment',
      value: formatCentsToDollars(
        timePaymentAccount.grossAmount -
          timePaymentAccount.irsAmount -
          timePaymentAccount.dorsAmount -
          timePaymentAccount.offsetsAmount
      ),
    },
    {
      label: 'Recurring Payment Amt',
      value: formatCentsToDollars(timePaymentAccount.recurringPaymentAmount),
    },
    {
      label: 'Payments Made',
      value: timePaymentAccount.paymentsMade,
    },
    {
      label: 'Payments Pending',
      value: timePaymentAccount.remainingPayments,
    },
  ]
  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <span className={styles.title}>Payment Info</span>
          <span className={styles.disclaimer}>
            The amounts below are the aggregate totals to date.
          </span>
        </header>

        <div className={styles.grid}>
          {values.map((entry, index) => {
            return (
              <div key={index} className={`d-flex justify-content-between`}>
                <span className={styles.label}>{entry.label}</span>
                <span className={`${styles.padding} font-weight-bold`}>
                  {entry.value}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
