import {makeHttpCall} from '../../../services/MakeHttpCall';
import config from './../../../config.js';
import {
    ACHActivitySearchCriteria
} from './interfaces';
import moment from "moment";


let formik: any;
let claimStore: any;
let history: any;
let pageMessageDefault: any;
let setPageMessageType: any;
let setButtonClicked: any;
export const setVariables = (formikObj: any, claimStoreObject: any, historyObject: any, pageMessageDefaultObj: any, setPageMessageObj: any, setButtonClickedObj: any) => {
    formik = formikObj;
    claimStore = claimStoreObject;
    history = historyObject;
    pageMessageDefault = pageMessageDefaultObj;
    setPageMessageType = setPageMessageObj;
    setButtonClicked = setButtonClickedObj;
}

export const refreshComponent = (response: any) => {

    if (!(response.error === undefined)) {
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }

}

export const onSubmit = async (values: ACHActivitySearchCriteria) => {
}


export const doACHActivitySearch = async (startDate: string, endDate: string, batchNumber: string,
                                          rejectFileReference: string, papercheckFileNumber: string,
                                          setACHActivitySearchAPIResponse: any, setCurrentPage: any) => {

    setCurrentPage(0);

    if (Object.keys(formik.errors).length <= 0) {

        let queryParams = '?';
        if (startDate && endDate) {
            const sDate = moment(startDate).format('YYYY-MM-DD');
            const eDate = moment(endDate).format('YYYY-MM-DD');
            queryParams += `queryDateRangeStart=${sDate}&queryDateRangeEnd=${eDate}`;
        }
        if (batchNumber) {
            queryParams += `&batchNumber=${batchNumber.toString()}`;
        }
        if (rejectFileReference) {
            queryParams += `&rejectFileReferenceNumber=${rejectFileReference}`;
        }
        if (papercheckFileNumber) {
            queryParams += `&papercheckFileNumber=${papercheckFileNumber.toString()}`
        }

        try {
            const options =
                {
                    method: 'get',
                    url: `${config.SERVER_BASE_URL}/v1/ach/batch/reports${queryParams}`,
                }


            const response = await makeHttpCall(options);
            claimStore.ACHActivitySearchAPIResponse = response.data;
            setACHActivitySearchAPIResponse(response.data);

            if (response.data.length === 0) {
                setPageMessageType({message: "No results have been found matching the search criteria.", messageType: "SUCCESS"});
            }
            setButtonClicked(false);

        } catch (e) {

            const er:any = e;
            if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
                if(value === "Network")
                  return value
            }) !== undefined)
            {
                setPageMessageType({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
            }else
                setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
          
            setButtonClicked(false);
        }

    } else {
        setButtonClicked(false);
    }
}


export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const onClearSearch = (setACHActivitySearchAPIResponse: any) => {
    setACHActivitySearchAPIResponse([]);
}

export const validate = (values: ACHActivitySearchCriteria) => {

    const errors = {};

    if (values.batchNumber) {
        if (values.batchNumber.length > 0 && values.batchNumber.length !== 7) {
            Object.assign(errors, {batchNumber: 'Invalid Batch Number'});
        }
    }

    if (values.papercheckFileNumber) {
        if (values.papercheckFileNumber.length > 0 && values.papercheckFileNumber.length !== 7) {
            Object.assign(errors, {papercheckFileNumber: 'Invalid Paper Check File Number'});
        }
    }

    if (values.endDate) {
        if (values.endDate < values.startDate) {
            Object.assign(errors, {endDate: 'End Date should be greater than Star Date'});
        }
    }
    if(values.startDate !== "")
    {
        let isValidDate = Date.parse(values.startDate);
        let isValidEndDate = Date.parse(values.endDate);
        if (isNaN(isValidDate)) {
            Object.assign(errors, { startDate: 'StartDate is not a valid date' });
        } else if (isNaN(isValidEndDate)) {
        Object.assign(errors, { endDate: 'EndDate is not a valid date' });
        }
    }


    return errors;
}


