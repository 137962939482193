import React from "react"
import { FaRegQuestionCircle } from "react-icons/fa"
import './styles.scss'

export type VoidOrReissueHeaderProps = {
  title: string
  tooltipMessage: string
}

const ROOT_CLASS = `header-tooltip`
export const VoidOrReissueHeader = (props: VoidOrReissueHeaderProps) => {
  return (
    <div className={ ROOT_CLASS }>
      { props.title }
      <FaRegQuestionCircle className={ 'tooltip-icon' }/>
      <div className={ 'tooltip-message-container' }>
        <div>{props.tooltipMessage}</div>
      </div>
    </div>
  )
}