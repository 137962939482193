import * as React from 'react'
import moment from 'moment'
import {
  PromotionDrawingGroup,
  PromotionStatus,
} from '../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../utilities/helpers'
import { promotionIsNewOrPending } from '../promotion-campaign-builder/promotion-campaign-sidebar/sidebar-forms/helpers'

const ROOT_CLASS = 'promotion-drawing-tooltip'

export const generateDrawingGroupTooltip = (
  drawingGroup?: PromotionDrawingGroup
) => {
  if (!drawingGroup) {
    return (
      <div className={getBlockClass(ROOT_CLASS, 'tooltip')}>
        No drawing information available
      </div>
    )
  }
  let toolTip = <></>
  const now = moment()
  const drawingDates = drawingGroup.drawings
    .filter((drawing) => Boolean(drawing.scheduledDrawTime))
    .map((drawing) => moment(drawing.scheduledDrawTime))

  const previousDrawings = drawingDates.filter((drawDate) =>
    drawDate.isSameOrBefore(now)
  )
  const upcomingDrawings = drawingDates.filter((drawDate) =>
    drawDate.isAfter(now)
  )
  const lastDrawing = previousDrawings.length
    ? moment.max(previousDrawings)
    : undefined
  const nextDrawing = upcomingDrawings.length
    ? moment.min(upcomingDrawings)
    : undefined

  const nextDrawingObject = nextDrawing ? (
    <>
      <span className={getBlockClass(ROOT_CLASS, 'tooltip-header')}>
        Upcoming Drawing
      </span>
      <span>{nextDrawing.format('MM/DD/YYYY')}</span>
    </>
  ) : (
    <></>
  )
  const lastDrawingObject = lastDrawing ? (
    <>
      <span className={getBlockClass(ROOT_CLASS, 'tooltip-header')}>
        Most Recent Drawing
      </span>
      <span>{lastDrawing.format('MM/DD/YYYY')}</span>
    </>
  ) : (
    <></>
  )
  if (lastDrawing || nextDrawing) {
    toolTip = (
      <div className={getBlockClass(ROOT_CLASS, 'tooltip')}>
        {nextDrawingObject}
        {lastDrawingObject}
      </div>
    )
  } else {
    toolTip = (
      <div className={getBlockClass(ROOT_CLASS, 'tooltip')}>
        No drawing information available
      </div>
    )
  }
  return toolTip
}

export const generateDrawingGroupText = (
  promotionStatus: PromotionStatus,
  drawingGroup?: PromotionDrawingGroup,
  isBonusDrawing: boolean = false
) => {
  let drawingStatusText

  const now = moment()

  const totalNumberDrawings = drawingGroup?.drawings.length ?? 0

  if (!drawingGroup || totalNumberDrawings === 0) {
    return 'No drawings available'
  }

  const drawingDates = drawingGroup.drawings
    .filter((drawing) => Boolean(drawing.scheduledDrawTime))
    .map((drawing) => moment(drawing.scheduledDrawTime))

  const previousDrawings = drawingDates.filter((drawDate) =>
    drawDate.isSameOrBefore(now)
  )

  const drawingText = `${isBonusDrawing ? 'Bonus ' : ''}${
    totalNumberDrawings === 1 ? 'Drawing' : 'Drawings'
  }`

  drawingStatusText = promotionIsNewOrPending(promotionStatus)
    ? `${totalNumberDrawings} ${drawingText} Available`
    : `${previousDrawings.length}/${totalNumberDrawings} ${drawingText} Complete`

  return drawingStatusText
}
