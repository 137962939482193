import { ActionsComponentInterface, PlayersComponentInterface } from "../../application.interfaces/application.components.interfaces/players.component.interfaces";

export const ACTIONS_COMPONENTS:ActionsComponentInterface = {
    KYC_QUEUE_TABLE_COMPONENT:'KYC_QUEUE_TABLE_COMPONENT',
}


export const PLAYERS_COMPONENTS:PlayersComponentInterface={
    IDENTITY_DOCUMENTS_COMPONENT:'IDENTITY_DOCUMENTS_COMPONENT',
    PLAYER_ID_AND_NAME_COMPONENT: 'PLAYER_ID_AND_NAME_COMPONENT',
    PLAYER_PROFILE_COMPONENT: 'PLAYER_PROFILE_COMPONENT',
    PLAYERS_EMAIL_AND_PHONE_COMPONENT: 'PLAYERS_EMAIL_AND_PHONE_COMPONENT',
    PLAYER_STATUS_COMPONENT:'PLAYER_STATUS_COMPONENT',
    UPDATE_PROFILE_COMPONENT: 'UPDATE_PROFILE_COMPONENT',
    DISPLAY_DOCUMENT_NEW_COMPONENT:'DISPLAY_DOCUMENT_NEW_COMPONENT' 
}

