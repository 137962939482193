let resetSsnCount:string ="";
let resetReSsnCount:string = "";
export const setSSNValue= (e:any,formik:any,setSsnErrorMessage:any) => {
   
   const testForLetters = e.target.value.split("-").join(""); 
   if(isNaN(testForLetters))
   {
      
       setSsnErrorMessage( "Letters are not allowed!" );
       return;
   }

    if( e.target.value.length === 3 && resetSsnCount < e.target.value)
       e.target.value +="-";
    else if(e.target.value.length === 6 && resetSsnCount < e.target.value)
       e.target.value +="-";
    else if(e.target.value.length > 11)
    {
      setSsnErrorMessage( "Only 9 digits allowed!" );
       return;
    }
    resetSsnCount = e.target.value;
    setSsnErrorMessage(undefined);
    formik.setFieldValue(e.target.name, e.target.value, true);
}
export const setReSSNValue= (e:any,formik:any,setReSsnErrorMessage:any) => {
  
   const testForLetters = e.target.value.split("-").join(""); 
   if(isNaN(testForLetters))
   {
      
       setReSsnErrorMessage( "Letters are not allowed!" );
       return;
   }

    if( e.target.value.length === 3 && resetReSsnCount < e.target.value)
       e.target.value +="-";
    else if(e.target.value.length === 6 && resetReSsnCount < e.target.value)
       e.target.value +="-";
    else if(e.target.value.length > 11)
    {
      setReSsnErrorMessage( "Only 9 digits allowed!" );
       return;
    }
    resetReSsnCount = e.target.value;
    setReSsnErrorMessage(undefined);
    formik.setFieldValue(e.target.name, e.target.value, true)
}

let numberClearOfClick = 0;
let fieldName:string = "";

export const clearSSNField = (e:any, formik:any) =>{
   
    if(numberClearOfClick === 0)
    {
       fieldName = e.target.name; 
    }  
   if(fieldName !== e.target.name )
   {
      fieldName = e.target.name;
      numberClearOfClick = 0;
   }
   if(numberClearOfClick >= 2)
   {
      formik.setFieldValue(e.target.name, "", false);
      numberClearOfClick = 0
   }
   else
    numberClearOfClick++;

}
export const resetSnnCount = ()=>{
   resetSsnCount ="";

}
export const resetReSnnCount = ()=>{
   resetSsnCount ="";

}