import * as React from 'react'
import { PlayerPromotionEntriesContainer } from './PromotionalEntryDetailsContainer'
import { PromotionDrawingDetailsModalContainer } from './PromotionDrawingDetailsModalContainer'
import { PromotionDrawing } from './PromotionalEntryDetailsUI'
import { PlayerPromotionFilterValues } from '../PromotionsForm/PlayerPromotionsForm'

export type PlayerPromotionEntriesPageProps = {
  playerId: string
}

export const PlayerPromotionEntriesPage = (
  props: PlayerPromotionEntriesPageProps
) => {
  const [openPromotionDrawing, setOpenPromotionDrawing] =
    React.useState<PromotionDrawing>()

  return (
    <>
      {openPromotionDrawing && (
        <PromotionDrawingDetailsModalContainer
          playerId={props.playerId}
          promotionId={openPromotionDrawing.promotionId}
          drawingId={openPromotionDrawing.drawingId}
          clearPromotionDrawing={() => setOpenPromotionDrawing(undefined)}
        />
      )}
      <PlayerPromotionEntriesContainer
        playerId={props.playerId}
        setOpenPromotionDrawing={setOpenPromotionDrawing}
      />
    </>
  )
}
