import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {
    PageMessageCommonComponent,
    pageMessageDefault
} from "../../../../components/CommonComponents/PageMessageCommonComponent";
import {useFormik} from "formik";
import {onSubmit, setFiledValue, setVariables, validate} from "./functions";
import {keyPressedType} from "../../../../services/Commons";
import stylesMessageBox from "../../../PlayersPages/UpdateProfilePage/css/stylesMessageBox.module.css";
import {CreateProfileRootInterface, LicenseDetails, OrgDetails} from "./interfaces";
import styles from "./css/styles.module.css";
import {licenseeRoutePath} from "../../../../config.js";
import {
    CreateMemberInChargeComponent
} from "../../../../components/CharitableGamingComponents/License/Create/CreateMemberInChargeComponent";
import {
    CreateBookKeeperComponent
} from "../../../../components/CharitableGamingComponents/License/Create/CreateBookKeeperComponent";
import {
    CreateLicenseInformationComponent
} from "../../../../components/CharitableGamingComponents/License/Create/CreateLicenseInformationComponent";
import {
    CreateHeadOfOrganizationComponent
} from "../../../../components/CharitableGamingComponents/License/Create/CreateHeadOfOrganizationComponent";
import {
    CreateOrganizationTypeComponent
} from "../../../../components/CharitableGamingComponents/License/Create/CreateOrganizationTypeComponent";
import {
    CreateOrganizationDetailsComponent
} from "../../../../components/CharitableGamingComponents/License/Create/CreateOrganizationDetailsComponent";

const OrgDetailsValues1: OrgDetails = {
    orgFID: "11148",
    orgName: "Example11148",
    orgCode: "",
    orgCodeOther: "Some other type of org",
    orgDateChartered: "2022-05-05",
    orgPhone: "1435870012",
    orgAddress: {
        streetAddress: "123 Main St.",
        city: "Boston",
        zip: "02110",
    },
    mailAddress: {
        streetAddress: "123 Main St.",
        city: "Boston",
        zip: "02110",
    },
    headOfOrg: {
        fullName: "John Doe",
        address: {
            streetAddress: "123 Main St.",
            city: "Boston",
            zip: "02110",
        },
        homePhone: "1234560089",
        businessPhone: "4430987745",
        email: "fake@email.com",
    }
}
const licenseDetailsValues1: LicenseDetails = {
    occasionAddress: {
        streetAddress: "123 Main St.",
        city: "Boston",
        zip: "02110",
    },
    occasionDay: "Monday",
    occasionTime: "",
    dateOfFirstLicense: "2022-05-05",
    beanoHallPhone: "9764381125",
    memberInCharge: {
        fullName: "John Doe",
        address: {
            streetAddress: "123 Main St.",
            city: "Boston",
            zip: "02110",
        },
        homePhone: "1234560089",
        businessPhone: "4430987745",
        email: "fake@email.com",
    },
    bookkeeper: {
        fullName: "John Doe",
        address: {
            streetAddress: "123 Main St.",
            city: "Boston",
            zip: "02110",
        },
        homePhone: "4564564564",
        businessPhone: "4564564561",
        email: "SDFSDFSD"
    },
    investCode: "22",
    county: "Suffolk",
    city: "Boston",
    type: ""
}

const OrgDetailsValues: OrgDetails = {
    orgFID: "",
    orgName: "",
    orgCode: "",
    orgCodeOther: "",
    orgDateChartered: "",
    orgPhone: "",
    orgAddress: {
        streetAddress: "",
        city: "",
        zip: "",
    },
    mailAddress: {
        streetAddress: "",
        city: "",
        zip: "",
    },
    headOfOrg: {
        fullName: "",
        address: {
            streetAddress: "",
            city: "",
            zip: "",
        },
        homePhone: "",
        businessPhone: "",
        email: "",
    }
}
const licenseDetailsValues: LicenseDetails = {
    occasionAddress: {
        streetAddress: "",
        city: "",
        zip: ""
    },
    occasionDay: "",
    occasionTime: "",
    dateOfFirstLicense: "",
    beanoHallPhone: "",
    memberInCharge: {
        fullName: "",
        address: {
            streetAddress: "",
            city: "",
            zip: ""
        },
        homePhone: "",
        businessPhone: "",
        email: "",
    },
    bookkeeper: {
        fullName: "",
        address: {
            streetAddress: "",
            city: "",
            zip: ""
        },
        homePhone: "",
        businessPhone: "",
        email: ""
    },
    investCode: "",
    county: "",
    city: "",
    type: ""
}

export const CreateProfilePage = () => {

    const history = useHistory();
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [saveToggle, setSaveToggle] = useState<any>(false);
    const [cancelToggle, setCancelToggle] = useState<boolean>(false);

    const defaultProfileData: CreateProfileRootInterface | any = {
        orgDetails: OrgDetailsValues,
        licenseDetails: licenseDetailsValues
    }

    const formik = useFormik({
        initialValues: defaultProfileData,
        onSubmit,
        validate
    });

    const ActivateSavebutton = (formik.isValid && formik.dirty);

    setVariables(formik, history, pageMessageDefault, setPageMessage);

    return (
        <>
            <div
                onClick={(e: any) => {
                    pageMessage.messageType !== "" &&
                    setPageMessage({message: "", messageType: ""});
                }}
                className={styles["page-container"]}
            >

                <div className={styles["top-ribbon"]}>
                    <div className={styles["top-ribbon-text"]} tabIndex={0}>
                        License Creation
                    </div>
                    <div className={styles["top-ribbon-button-container"]}>
                        <div
                            role="button"
                            tabIndex={0}
                            aria-pressed="false"
                            onClick={() => setCancelToggle(!cancelToggle)}
                            onKeyDown={(e: any) =>
                                keyPressedType(e) === 13 || keyPressedType(e) === 32
                                    ? setCancelToggle(!cancelToggle)
                                    : ""
                            }
                            className={styles["top-ribbon-cancel-button"]}
                        >
                            Cancel
                        </div>
                        <div
                            style={{
                                opacity: ActivateSavebutton ? "1" : "0.4",
                                cursor: ActivateSavebutton ? "pointer" : "not-allowed",
                            }}
                            role="button"
                            tabIndex={0}
                            aria-pressed="false"
                            onKeyDown={(e: any) => {
                                if (
                                    (keyPressedType(e) === 13 || keyPressedType(e) === 32)
                                ) {
                                    if (ActivateSavebutton) {
                                        setSaveToggle(true);
                                    }
                                }
                            }}
                            onClick={() => {
                                if (ActivateSavebutton) {
                                    setSaveToggle(true);
                                }
                            }}
                            className={styles["top-ribbon-save-button"]}
                        >
                            Submit
                        </div>
                    </div>
                </div>
                {pageMessage.message !== "" && (
                    <PageMessageCommonComponent
                        message={pageMessage.message}
                        messageType={pageMessage.messageType}
                    />
                )}
                <div className={styles["page-container-body-container"]}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-left"]}>
                            <CreateOrganizationDetailsComponent formik={formik} setFiledValue={setFiledValue}/>
                        </div>
                        <div className={styles["page-container-body-left1"]}>
                            <div className={styles["page-container-body-left-padding-top"]}>
                                <CreateOrganizationTypeComponent formik={formik} setFiledValue={setFiledValue}/>
                            </div>
                            <div
                                className={`${styles["page-container-body-left-padding-top"]} ${styles["page-container-body-left-add-margin"]}`}>
                                <CreateLicenseInformationComponent formik={formik} setFiledValue={setFiledValue}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles["page-container-body-container"]}`}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-left"]}>
                            <CreateHeadOfOrganizationComponent formik={formik} setFiledValue={setFiledValue}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles["page-container-body-container"]}`}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-left"]}>
                            <CreateMemberInChargeComponent formik={formik} setFiledValue={setFiledValue}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles["page-container-body-container"]}`}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-left-bottom"]}>
                            <CreateBookKeeperComponent formik={formik} setFiledValue={setFiledValue}/>
                        </div>
                    </div>
                </div>

            </div>

            {saveToggle && (
                <div className={stylesMessageBox["window-container"]}>
                    <div
                        tabIndex={0}
                        id="update-profile-save-box-container"
                        className={stylesMessageBox["update-profile-save-box-container"]}
                    >
                        <div
                            tabIndex={0}
                            className={stylesMessageBox["update-profile-save-box-message"]}
                        >
                            Are you sure you would like to create this license profile?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                    formik.submitForm();
                                    setSaveToggle(false)
                                }
                            }}
                            onClick={() => {
                                formik.submitForm();
                                setSaveToggle(false)
                            }}
                            className={stylesMessageBox["update-profile-save-box-button-yes"]}
                        >
                            <div
                                className={
                                    stylesMessageBox["update-profile-save-button-yes-text"]
                                }
                            >
                                Yes, Create
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                    setSaveToggle(false);
                                }
                            }}
                            role="button"
                            onClick={() => setSaveToggle(false)}
                            className={
                                stylesMessageBox["player-email-and-phone-message-box-button-no"]
                            }
                        >
                            Go Back
                        </div>
                    </div>
                </div>
            )}
            {cancelToggle && (
                <div className={stylesMessageBox["window-container"]}>
                    <div
                        tabIndex={0}
                        id="update-profile-save-box-container"
                        className={stylesMessageBox["update-profile-save-box-container"]}
                    >
                        <div
                            tabIndex={0}
                            className={stylesMessageBox["update-profile-save-box-message"]}
                        >
                            Are you sure you would like to cancel creating this license profile?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                    pageMessage.messageType !== "" &&
                                    setPageMessage({message: "", messageType: ""});
                                    setCancelToggle(!cancelToggle);
                                    history.push(`${licenseeRoutePath}/manage`);
                                }
                            }}
                            onClick={() => {
                                pageMessage.messageType !== "" &&
                                setPageMessage({message: "", messageType: ""});
                                setCancelToggle(!cancelToggle);
                                history.push(`${licenseeRoutePath}/manage`);
                            }}
                            className={stylesMessageBox["update-profile-save-box-button-yes"]}
                        >
                            <div
                                className={
                                    stylesMessageBox["update-profile-save-button-yes-text"]
                                }
                            >
                                Yes, Cancel
                            </div>
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                    setCancelToggle(!cancelToggle);
                                }
                            }}
                            onClick={() => setCancelToggle(!cancelToggle)}
                            className={
                                stylesMessageBox["player-email-and-phone-message-box-button-no"]
                            }
                        >
                            Go Back
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

