export const PaymentAccountTypeCodes = [
    '',
    'CHECKING',
    'SAVINGS'
]

export const checkAccountType = (e: any, formik: any, setCheckingAccoutTypeError:any) => {
    
    const testForLetters = e.target.value;
    
    if (testForLetters === '') {
        setCheckingAccoutTypeError("Please select account type!");
        return;
    }
   
    setCheckingAccoutTypeError(undefined);
    formik.setFieldValue(e.target.name, e.target.value, true)
}


export const checkRoutingNumber = (e: any, formik: any, setCheckRoutingNumberError:any ) => {
    const testForLetters = e.target.value;
    
    if (isNaN(testForLetters)) {
        setCheckRoutingNumberError("Letters are not allowed!");
        return;
    }
    if( testForLetters.length > 9 )
    {
       setCheckRoutingNumberError( "Only 9 digits allowed!" );
       return;
    }
    setCheckRoutingNumberError(undefined);
    formik.setFieldValue(e.target.name, e.target.value, true)
}

export const checkAccountNumber = (e: any, formik: any, oriRoutingNumber: any,setCheckAccountNumberError:any) => {
    
    const testForLetters = e.target.value;
    
    if (isNaN(testForLetters)) {
        setCheckAccountNumberError("Letters are not allowed!");
        return;
    }
    if( testForLetters.length > 17 )
    {
       setCheckAccountNumberError( "Only 17 digits allowed!" );
       return;
    }
   
    setCheckAccountNumberError(undefined);
    formik.setFieldValue(e.target.name, e.target.value, true)
}

export const clearResubmitField = (e: any, formik: any) => {

      if(e.target.value === "0" && e.target.value.length <= 1) 
         formik.setFieldValue(e.target.name, "", false);

}
