import * as React from 'react'
import moment from 'moment'
import { useFormik } from 'formik'
import { GenericSidebarFormMode } from '../../../GenericSidebar/generic-sidebar-ui'
import {
  PromotionalMessageValidation,
  validateCreatePromotionalMessageForm,
} from '../validation'
import { getBlockClass } from '../../../../utilities/helpers'
import { AutoFocusInside } from 'react-focus-lock'
import { SideBarTextInput } from '../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'
import { SideBarTextArea } from '../../../SidebarFormComponents/side-bar-text-area-component/side-bar-text-area-ui'
import { CustomDropdownField } from '../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { DateAndTimeInput } from '../../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import { LoadingDots } from '../../../CommonComponents/loading-dots/loading-dots-ui'
import {
  CreatePromotionMessageRequest,
  PlayslipsAllActivePlayslipsGamesResponse,
  PlayslipsPromotionMessage,
  PromotionMessageRequestBody,
  UpdatePromotionMessageRequest,
} from '../../../../api/playslips-fetcher/constants'
import './styles.scss'

const FORMIK_DATE_FORMAT = 'YYYY-MM-DDTHH:mm'

const ROOT_CLASS = 'promotional-messages-sidebar-form'
const FORM_CLASS = getBlockClass(ROOT_CLASS, 'form')

export type PromotionalMessageSidebarFormUIProps = {
  message?: PlayslipsPromotionMessage
  setFormMode: (
    formMode: GenericSidebarFormMode,
    message?: PlayslipsPromotionMessage
  ) => void
  isLoading: boolean
  error?: string
  activeGames: PlayslipsAllActivePlayslipsGamesResponse
  messageId?: number
  createSuccessful: boolean
  updateSuccessful: boolean
  updatePromotionMessage: (
    promotionMessage: UpdatePromotionMessageRequest
  ) => Promise<void>
  createPromotionMessage: (
    promotionMessage: CreatePromotionMessageRequest
  ) => Promise<void>
  fetchAllPromotionMessages: () => Promise<void>
}

export const PromotionalMessageSidebarFormUI = (
  props: PromotionalMessageSidebarFormUIProps
) => {
  const [message, setMessage] = React.useState<
    PromotionMessageRequestBody | undefined
  >(props.message)

  React.useEffect(() => {
    if (props.createSuccessful && props.messageId && message) {
      props.setFormMode(GenericSidebarFormMode.EDIT, {
        ...message,
        id: props.messageId,
      })
    }
  }, [props.createSuccessful, props.messageId, message])

  const refreshPromotions = async () => await props.fetchAllPromotionMessages()

  React.useEffect(() => {
    if (props.createSuccessful || props.updateSuccessful) {
      refreshPromotions()
    }
  }, [props.createSuccessful, props.updateSuccessful])

  const initialValues: PromotionalMessageValidation = {
    title: props.message?.title ?? '',
    message: props.message?.message ?? '',
    gameIdentifier: props.message?.gameIdentifier ?? '',
    startDate: props.message?.activeFrom
      ? moment(props.message?.activeFrom).format(FORMIK_DATE_FORMAT)
      : '',
    endDate: props.message?.activeTo
      ? moment(props.message?.activeTo).format(FORMIK_DATE_FORMAT)
      : '',
  }

  const promotionGameOptions = [
    {
      key: '',
      value: '',
      display: '',
    },
    ...props.activeGames
      .map((game) => {
        return {
          key: game.name,
          value: game.identifier,
          display: game.name,
        }
      })
      .sort((a, b) => a.key.localeCompare(b.key)),
  ]

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const submissionPayload: PromotionMessageRequestBody = {
        title: values.title,
        message: values.message,
        gameIdentifier: values.gameIdentifier || undefined,
        activeFrom: values.startDate || undefined,
        activeTo: values.endDate || undefined,
      }
      if (props.message && props.message.id) {
        const updatePayload: UpdatePromotionMessageRequest = {
          id: props.message.id ?? props.messageId,
          ...submissionPayload,
        }
        await props.updatePromotionMessage(updatePayload)
        setMessage(updatePayload)
      } else {
        await props.createPromotionMessage(submissionPayload)
        setMessage(submissionPayload)
      }
    },
    validate: validateCreatePromotionalMessageForm,
  })

  return (
    <div className={ROOT_CLASS}>
      <form className={FORM_CLASS} onSubmit={formik.handleSubmit} noValidate>
        <AutoFocusInside>
          <div className={getBlockClass(FORM_CLASS, 'form-item')}>
            <SideBarTextInput
              label={'Title'}
              name={'title'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={formik.errors.title}
              touched={formik.touched.title}
              disabled={props.isLoading}
            />
          </div>
        </AutoFocusInside>
        <div className={getBlockClass(FORM_CLASS, 'form-item')}>
          <SideBarTextArea
            label={'Message'}
            name={'message'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            error={formik.errors.message}
            touched={formik.touched.message}
            disabled={props.isLoading}
          />
        </div>
        <div className={getBlockClass(FORM_CLASS, 'form-item')}>
          <label
            className={getBlockClass(FORM_CLASS, 'label')}
            htmlFor="selectedPromotion"
          >
            Select Game
          </label>
          <CustomDropdownField
            onChange={formik.handleChange}
            value={formik.values.gameIdentifier}
            onBlur={formik.handleBlur}
            id="promotion-game-dropdown"
            name="gameIdentifier"
            options={promotionGameOptions}
            disabled={props.isLoading}
          />
        </div>
        <div className={getBlockClass(FORM_CLASS, 'form-item')}>
          <DateAndTimeInput
            label={'Messaging Start Date & Time'}
            name={'startDate'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            error={formik.errors.startDate}
            touched={formik.touched.startDate}
            maxDate={formik.values.endDate}
            minDate={moment().format(FORMIK_DATE_FORMAT)}
            disabled={props.isLoading}
            includeTime
          />
        </div>
        <div className={getBlockClass(FORM_CLASS, 'form-item')}>
          <DateAndTimeInput
            label={'Messaging End Date & Time'}
            name={'endDate'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            error={formik.errors.endDate}
            touched={formik.touched.endDate}
            minDate={formik.values.startDate}
            disabled={props.isLoading}
            includeTime
          />
        </div>
        {props.error && (
          <span className={`${FORM_CLASS}__error-text`}>
            Error saving promotion message: "{props.error}"
          </span>
        )}
        <div className={getBlockClass(FORM_CLASS, 'save-button-container')}>
          <button
            type="submit"
            className={getBlockClass(FORM_CLASS, 'save-button')}
            aria-label="Save promotional message"
            disabled={!formik.dirty || !formik.isValid || props.isLoading}
          >
            {props.isLoading ? (
              <div aria-busy={props.isLoading} aria-describedby="loading">
                <LoadingDots id="loading" color="white" />
              </div>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
