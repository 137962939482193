import React from "react";
import { Switch, withRouter} from "react-router-dom";
import { PrivateRoute } from './../../../routes';
import { claimsSupportRoutePath } from './../../../config.js';
import { MobileClaimDetailsPage } from "../MobileClaimDetailsPage";
import { NotFound404Page } from "../../CommonsPage/NotFound404Page";
import ReSubmitPaymentPage from "../ReSubmitPaymentPage";
import { checkRenderPermissions } from  './../../../helpers.js';
import { permissions } from './../../../constants.js';


 const ClaimsSupportRoutes = ({ props, storesObject }: any) => {
       const userpolicies: any = localStorage.getItem('userpolicies');
       const playerId:string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 2]}`;
       const claimsId:string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`;
       const urlVariables:string =  `${playerId}/${claimsId}`;
    
    return (
        <>
            
                     <Switch>
                            <PrivateRoute path={`${claimsSupportRoutePath}/mobile-claims-details/${urlVariables}`} component={(props:any) => <MobileClaimDetailsPage  id={playerId} claimsId={claimsId}   storesObject={storesObject} />} />
                            { checkRenderPermissions(permissions.CAN_RESUBMIT_PAYMENT, JSON.parse(userpolicies)) &&   <PrivateRoute path={`${claimsSupportRoutePath}/resubmitPayment`}
                              component={(props: any) => <ReSubmitPaymentPage
                                  storesObject={storesObject}
                                  id={playerId}
                                  claimId={claimsId}
                              />}/> }
                            <PrivateRoute path={`/`} component={(props:any) =>  <NotFound404Page />}/>
                           
                     </Switch> 
                
                             
    
            
        </>

    );
}
export  default withRouter(ClaimsSupportRoutes) 