import React, {useEffect} from "react";
import InputMask from "react-input-mask";
import styles from "./css/styles.module.css";
import moment from "moment";
import { ErrorMessageFieldCommonComponent } from "../../../CommonComponents/ErrorMessageFieldCommonComponent";
import { Countries } from "../../../../Constants/Countries";
import { FormikValues } from "formik";

export const CreatePayeeCmpt: React.FC<{
  formik: FormikValues;
  payeeType: "Individual" | "Entity";
}> = ({ formik, payeeType }) => {


  useEffect(() => {
    if (formik.values.country === "") {
      formik.setFieldValue("country", "UNITED STATES OF AMERICA");
    }
  });

  return (
    <>
      <div className={`${styles.main_page_container}`}>
        <div className={`${styles.grid_col_2}`}>
          {/* Column 1 */}

          <form>
            <div className={`${styles.cmpnt_container}`}>
              <h4>Payee Info</h4>
              <p>Review the enter the information below</p>
              <div className={`${styles.grid_col_2}`}>
                {payeeType === "Individual" ? (
                  <>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="firstName">First Name*</label>
                      <input
                        type="text"
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="firstName"
                        id="firstName"
                        value={formik.values.firstName}
                      />
                      {formik.errors.firstName && formik.touched.firstName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.firstName} />}
                    </div>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="middleInitial">Middle Initial</label>
                      <input
                        type="text"
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="middleInitial"
                        id="middleInitial"
                        value={formik.values.middleInitial}
                      />
                    </div>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="lastName">Last Name*</label>
                      <input
                        type="text"
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="lastName"
                        id="lastName"
                        value={formik.values.lastName}
                      />
                      {formik.errors.lastName && formik.touched.lastName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.lastName} />}
                    </div>
                    <div className={`${styles.info_detail}`}>
                      <label htmlFor="birthDate">Birth Date</label>
                      <input
                        type="date"
                        max={moment().format("YYYY-MM-DD")}
                        className={`${styles.custom_input}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="birthDate"
                        id="birthDate"
                        value={formik.values.birthDate}
                      />
                    </div>
                  </>
                ) : (
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="freeformName">Freeform Name*</label>
                    <input
                      type="text"
                      className={`${styles.custom_input} text-uppercase`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="freeformName"
                      id="freeformName"
                      value={formik.values.freeformName}
                    />
                    {formik.errors.freeformName && formik.touched.freeformName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.freeformName} />}
                  </div>
                )}
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="tin">TIN*</label>
                  <InputMask
                    mask="999999999"
                    maskChar=""
                    className={`${styles.custom_input}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="tin"
                    name="tin"
                    placeholder={"123456789"}
                    value={formik.values.tin}
                  ></InputMask>
                  {formik.errors.tin && formik.touched.tin && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.tin} />}
                </div>
                <div className={`${styles.info_detail}`}>
                    <label htmlFor="irsName">IRS Name*</label>
                    <InputMask
                        mask="aaaa"
                        maskChar=""
                        className={`${styles.custom_input} text-uppercase`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="irsName"
                        id="irsName"
                        value={formik.values.irsName}
                      ></InputMask>
                    {formik.errors.irsName && formik.touched.irsName && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.irsName} />}
                  </div>
              </div>
            </div>
          </form>

          {/* Column 2 */}
          <form>
            <div className={`${styles.demo_info_container}`}>
              <h4>Demographic &amp; Contact Info</h4>
              <div className={`${styles.grid_col_2}`}>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="addr_1">Mailing Address 1*</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="addr_1"
                    id="addr_1"
                    value={formik.values.addr_1}
                  />
                  {formik.errors.addr_1 && formik.touched.addr_1 && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.addr_1} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="addr_2">Mailing Address 2</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="addr_2"
                    id="addr_2"
                    value={formik.values.addr_2}
                  />
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="city">City*</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="city"
                    id="city"
                    value={formik.values.city}
                  />
                  {formik.errors.city && formik.touched.city && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.city} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="state_region">State/Region*</label>
                  <InputMask
                    mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
                    maskChar=""
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="state_region"
                    id="state_region"
                    value={formik.values.state_region}
                  ></InputMask>
                  {formik.errors.state_region && formik.touched.state_region && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.state_region} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="zip_code">Zip/Postal Code*</label>
                  <InputMask
                    mask="99999"
                    maskChar=""
                    className={`${styles.custom_input} ${styles.custom_zip_code}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="zip_code"
                    id="zip_code"
                    value={formik.values.zip_code}
                  ></InputMask>
                  {formik.errors.zip_code && formik.touched.zip_code && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.zip_code} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="country">Country*</label>
                  <div className={`${styles.custom_select_div}`}>
                    <select
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${styles.custom_select}`}
                      name="country"
                      id="country"
                      defaultValue={"default"}
                    >
                      <option
                        value="default"
                        disabled
                      >
                        Select Country...
                      </option>
                      {Countries.map((country: string, index: number) => (
                        <option
                          value={country}
                          key={index}
                          selected={country === formik.values.country ? true : false}
                        >{`${country}`}</option>
                      ))}
                    </select>
                    <span className={`${styles.custom_arrow}`}></span>
                  </div>
                  {formik.errors.country && formik.touched.country && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.country} />}
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="email"
                    id="email"
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.email} />}
                </div>

                <div className={`${styles.info_detail}`}>
                  <label htmlFor="phone">Phone</label>
                  <InputMask
                    mask="999-999-9999"
                    maskChar=""
                    className={`${styles.custom_input}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="phone"
                    name="phone"
                    placeholder={"888-555-6789"}
                    value={formik.values.phone}
                  ></InputMask>
                  {formik.errors.phone && formik.touched.phone && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.phone} />}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
