import React, {useEffect} from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const RenewLicensesConfirmationComponent = ({RenewLicenses, setShowRenewLicensesConfirmation}: any) => {

    useEffect(() => {
    });

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["renew-license-confirmation-dialog-main-container"]}>
                    <div id="renew-license-confirmation-dialog-container"
                         className={stylesMessageBox["renew-license-confirmation-dialog-container"]}>
                        <div className={stylesMessageBox["renew-license-confirmation-dialog-title"]}>
                            Renew Licenses
                        </div>
                        <div className={stylesMessageBox["renew-license-confirmation-dialog-message"]}>
                            Renew all licenses that are checked for “Renewing” for the upcoming license period
                            from November 01, 2022 to October 31, 2023.
                        </div>
                        <div tabIndex={0} className={stylesMessageBox["renew-license-confirmation-dialog-yes-button"]}
                             role="button"
                             onKeyDown={(e: any) => {
                                 if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                     RenewLicenses();
                                     setShowRenewLicensesConfirmation(false);
                                 }
                             }}
                             onClick={() => {
                                 RenewLicenses();
                                 setShowRenewLicensesConfirmation(false);
                             }}>
                            <div className={stylesMessageBox["renew-license-confirmation-dialog-yes-button-text"]}>
                                Renew
                            </div>
                        </div>
                        <div tabIndex={0} className={stylesMessageBox["renew-license-confirmation-dialog-no-button"]}
                             role="button"
                             onKeyDown={(e: any) => {
                                 if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                     setShowRenewLicensesConfirmation(false);
                                 }
                             }}
                             onClick={() => {
                                 setShowRenewLicensesConfirmation(false);
                             }}>
                            Cancel
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};