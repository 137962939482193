import * as React from 'react'
import {
  useFetchPaymentDetails,
  useFetchVoidPayment,
} from './use-fetch-payment-details'
import { PaymentDetailsPageUI } from './payment-details-page-ui'
import { BannerContext } from '../../../context/BannerContext'

export type PaymentDetailsPageContainerProps = {
  internalPaymentId: string
}

export const PaymentDetailsPageContainer = (
  props: PaymentDetailsPageContainerProps
) => {
  const bannerContext = React.useContext(BannerContext)
  const {
    isLoading: isFetchPaymentDetailsLoading,
    error: fetchPaymentDetailsError,
    data,
    fetchPaymentDetails,
  } = useFetchPaymentDetails(props.internalPaymentId)

  const {
    isLoading: isVoidPaymentLoading,
    error: voidPaymentError,
    requestSuccessful: voidRequestSuccessful,
    fetchVoidPayment,
  } = useFetchVoidPayment()

  const updateFetch = () => {
    fetchVoidPayment(props.internalPaymentId).then(async () => await fetchPaymentDetails())
  }

  React.useEffect(() => {
    if (voidRequestSuccessful) {
      bannerContext.setBannerInfo({
        message: 'The payment has been voided',
        error: false,
      })
    }
  }, [voidRequestSuccessful])

  return (
    <div>
      <PaymentDetailsPageUI
        isLoading={isFetchPaymentDetailsLoading || isVoidPaymentLoading}
        voidPaymentError={voidPaymentError}
        paymentDetailsError={fetchPaymentDetailsError}
        paymentDetailsData={data}
        voidPayment={updateFetch}
      />
    </div>
  )
}
