import * as React from 'react'
import { userHasAnyOfPermissions } from '../util/access-helpers'

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export const useFetch = <T,>(postFetchCallback?: () => void) => {
  const [requestSuccessful, setRequestSuccessful] =
    React.useState<boolean>(false)
  const [data, setData] = React.useState<T>()
  const [response, setResponse] = React.useState<Response>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | undefined>(undefined)

  const fetchBlob = (
    url: string,
    headers: any = {},
    permissions?: string[],
    omitCredentials: boolean = true
  ) =>
    fetchUrl(url, 'GET', headers, undefined, permissions, true, omitCredentials)

  const fetchUrl = async (
    url: string,
    method: HTTPMethod = 'GET',
    headers: any = {},
    body?: BodyInit,
    permissions?: string[],
    isBlob?: boolean,
    omitCredentials?: boolean,
    parseErrorFromResponseError?: boolean
  ) => {
    let responseData

    try {
      setError(undefined)
      setIsLoading(true)
      setRequestSuccessful(false)
      if (permissions && !userHasAnyOfPermissions(permissions)) {
        throw new Error('Insufficient access')
      }

      const fetchOptions: RequestInit = {
        method,
        credentials: omitCredentials ? 'omit' : 'include',
        headers:
          body instanceof FormData
            ? headers
            : {
                'Content-Type': 'application/json',
                ...headers,
              },
        body: method === 'GET' ? undefined : body,
      }

      const response = await fetch(url, fetchOptions)
      if (response.status !== 204) {
        responseData = isBlob ? await response.blob() : await response.json()
      }
      if (!response.ok) {
        throw new Error('Bad response from server')
      }

      setData(responseData)
      setResponse(response)
      setError(undefined)
      setRequestSuccessful(true)
      return responseData
    } catch (e) {
      if (parseErrorFromResponseError && responseData?.error) {
        const regex = new RegExp(/("message":")([^"]*)/g)
        const captureGroups = regex.exec(responseData.error)

        if (captureGroups && captureGroups.length == 3) {
          setError(captureGroups[2].replace(/\\n/g, '; '))
        } else {
          setError((e as Error).message)
        }
      } else {
        setError(responseData?.message ?? (e as Error).message)
      }
      setRequestSuccessful(false)
    } finally {
      setIsLoading(false)
      if (postFetchCallback) {
        postFetchCallback()
      }
    }
  }

  const resetData = () => {
    setData(undefined)
    setIsLoading(false)
    setError(undefined)
    setResponse(undefined)
  }

  return {
    data,
    isLoading,
    error,
    requestSuccessful,
    response,
    fetchUrl,
    fetchBlob,
    resetData,
  }
}
