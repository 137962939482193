import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {keyPressedType} from "../../../../../services/Commons";
import {getIn, useFormik} from "formik";
import styles from "./css/styles.module.css";
import {
    getLicenseeDetailsByLicenseNumber,
    onSubmit,
    setBarcodeValue,
    setFieldValue,
    setVariables,
    updateTotalOrderSummary,
    validate,
    YesCancel,
    YesDeleteBarcode,
    YesResetAllBarcodes,
    YesSave
} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {
    CancelConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/CancelConfirmationComponent";
import {PageMessageCommonComponent} from "../../../../../components/CommonComponents/PageMessageCommonComponent";
import {
    SaveConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/SaveConfirmationComponent";
import {
    ResetAllBarcodesConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/ResetAllBarcodesConfirmationComponent";
import {
    DeleteBarcodeConfirmationComponent
} from "../../../../../components/CharitableGamingComponents/Common/DeleteBarcodeConfirmationComponent";
import {FaPlus} from "react-icons/fa";
import {BsArrowCounterclockwise} from "react-icons/bs";
import {RiDeleteBin6Line} from "react-icons/ri";
import {PaymentMethods, ShippingMethods} from "../../../../../Constants/CharitableConstant.js";
import {
    ErrorMessageFieldCommonComponent
} from "../../../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {formatCentsToDollars} from "../../../../../util";
import {LicenseeDetailsDefaultData, LicenseeDetailsInterface} from "../../../License/LicenseeDetailsPage/interfaces";
import CurrencyInput from "react-currency-input-field";

export const PullTabOrderCreationStepTwoPage = inject("charitableGamingStore")(observer(({licenseNumber, charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        let backButtonText = (location.state?.backButtonText === undefined) ? 'Back' : location.state.backButtonText;

        let {errorNotificationBanner} = charitableGamingStore;

        const formik = useFormik({
            initialValues: {
                warehouse: '',
                barcodeInformation: [{
                    serialNumber: 1,
                    barcodeNumber: '',
                    stockNumber: '-',
                    serialNumbers: '-',
                    quantity: 0,
                    unitPrice: '',
                    totalPrice: '',
                    currentWarehouse: '-',
                    error: ''
                }],
                shippingMethod: '',
                paymentMethod: '',
                netWeight: 'TBD',
                shippingCost: 'TBD',
                receiver: '',
                authorizationSignature: '',
                quantity: 0,
                summedProductPriceInCents: 0,
                totalPricePaidInCents: 0
            },
            validate,
            onSubmit
        });

        const shippingCost = formik.values.shippingCost;

        const [saveToggle, setSaveToggle] = useState<boolean>(false);
        const [resetBarcodesToggle, setResetBarcodesToggle] = useState<boolean>(false);
        const [deleteBarcodeToggle, setDeleteBarcodeToggle] = useState<any>({show: false, deleteIndex: -1});
        const [cancelToggle, setCancelToggle] = useState<boolean>(false);

        const [warehouses, setWarehouses] = useState<string[]>([]);
        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [selectedLicensee, setSelectedLicensee] = useState<LicenseeDetailsInterface>(LicenseeDetailsDefaultData);
        const ActivateSavebutton = (formik.isValid && formik.dirty);

        setVariables(licenseNumber, formik, history, backButtonText, charitableGamingStore);

        const hideErrorNotificationBanner = () => {
            charitableGamingStore.showErrorNotificationBanner('');
        }

        useEffect(() => {
            if (requestCompleted === false) {
                getLicenseeDetailsByLicenseNumber(licenseNumber, setSelectedLicensee, setRequestCompleted)
                charitableGamingStore.GetAllWarehouses(setWarehouses);
            }

        }, []);

        const computeShippingCostValue = (shippingcostValue: any) => {
            if (shippingcostValue === 'TBD' || shippingcostValue === '') {
                return 'TBD';
            } else {
                return `$${shippingcostValue}`;
            }
        }

        const computeNetWeightValue = (netWeightValue: any) => {
            if (netWeightValue === 'TBD') {
                return 'TBD';
            } else if (netWeightValue === 'n/a') {
                return 'n/a';
            } else {
                return `${netWeightValue} Lbs`;
            }
        }

        return (
            <>
                {(false) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['add-new-inventory-page-container']} ${styles['']}`}
                     onClick={(e: any) => {
                         if (errorNotificationBanner.message !== "") {
                             hideErrorNotificationBanner();
                         }
                     }}
                >
                    <div className={`${styles["add-new-inventory-page-ribbon-container"]}`}>
                        <div className={styles["add-new-inventory-page-ribbon-title"]}>Pull Tab Order Creation (Step 2 of
                            2)
                        </div>
                        <div className={`${styles['add-new-inventory-page-ribbon-button-container']}`}>
                            <div tabIndex={0} className={styles["add-new-inventory-page-ribbon-cancel-button"]}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         setCancelToggle(true);
                                     }
                                 }}
                                 onClick={() => {
                                     setCancelToggle(true);
                                 }}
                            >Cancel
                            </div>
                            <div tabIndex={0} className={styles["add-new-inventory-page-ribbon-save-button"]}
                                 role="button"
                                 style={{
                                     opacity: ActivateSavebutton ? "1" : "0.4",
                                     cursor: ActivateSavebutton ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (ActivateSavebutton) {
                                             setSaveToggle(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (ActivateSavebutton) {
                                         setSaveToggle(true);
                                     }
                                 }}>Submit
                            </div>
                        </div>
                    </div>
                    <div className={`${styles["add-new-inventory-page-error-notification-container"]}`}>
                        {(errorNotificationBanner.message != "") ?
                            <PageMessageCommonComponent
                                message={errorNotificationBanner.message}
                                messageType={errorNotificationBanner.messageType}/>
                            : null
                        }
                    </div>
                    <div className={`${styles["add-new-inventory-page-main-content-container"]}`}>
                        <div className={`${styles["row-1-container"]}`}>
                            <div className={`${styles["row-1-col-1-container"]}`}>
                                <div className={`${styles["row-container-title"]}`}>Organization</div>
                                <div className={`${styles['view-perjury-details-controls-container']}`}>

                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Org.
                                        Name
                                    </div>
                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedLicensee.orgDetails.orgName}</div>

                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Street
                                        Address
                                    </div>
                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedLicensee.orgDetails.orgAddress.streetAddress}</div>

                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town
                                    </div>
                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedLicensee.orgDetails.orgAddress.city}</div>

                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode
                                    </div>
                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedLicensee.orgDetails.orgAddress.zip}</div>

                                </div>
                            </div>
                            <div className={`${styles["row-1-col-2-container"]}`}>
                                <div className={`${styles["row-container-title"]}`}>License</div>
                                <div className={`${styles['view-perjury-details-controls-container']}`}>

                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-1']} ${styles['mslc-label']}`}>License
                                        No.
                                    </div>
                                    <div
                                        className={`${styles['view-perjury-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedLicensee.licenseDetails.licenseNumber}</div>

                                </div>
                            </div>
                        </div>
                        <div className={`${styles["row-2-container"]}`}>
                            <div className={`${styles["row-container-title"]}`}>Select
                                Designated Warehouse
                            </div>
                            <div className={`${styles["row-container-sub-title"]}`}>Select the warehouse from which the
                                order is being fulfilled.
                            </div>
                            <div className={styles["label-and-input-box-container"]}>
                                <div className={styles["add-new-inventory-page-label"]}>Warehouse
                                </div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select className={styles["custom_select"]}
                                            id="warehouse"
                                            name="warehouse"
                                            onChange={(e: any) => setFieldValue(e)}
                                            onBlur={formik.handleBlur}
                                    >
                                        <option value="" selected>Select...</option>
                                        {
                                            warehouses.map((warehouse: string) =>
                                                <option
                                                    selected={formik.values.warehouse.toLowerCase() === warehouse.toLowerCase()}
                                                    value={warehouse}>{warehouse}
                                                </option>)
                                        }
                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                                {(formik.touched.warehouse && formik.errors.warehouse) ?
                                    <ErrorMessageFieldCommonComponent warehouse={formik.errors.warehouse}/> : null}
                            </div>
                        </div>
                        <div className={`${styles["row-3-container"]}`}>
                            <div className={`${styles["row-container-title"]}`}>Enter Barcode
                                Information
                            </div>
                            <div className={`${styles["row-container-sub-title"]}`}>Scan or manually input the barcode
                                number for each game.
                            </div>
                            <div className={styles['table']}>
                                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                                    <div></div>
                                    <div>Barcode No.*</div>
                                    <div>Stock No.</div>
                                    <div>Serial Numbers</div>
                                    <div>Quantity</div>
                                    <div>Unit Price</div>
                                    <div>Total Price</div>
                                    <div>Warehouse</div>
                                </div>
                                <hr className={styles['horizontal-line']}/>
                                <div className={styles['table-data-body']}>
                                    {
                                        formik.values.barcodeInformation.length > 0 && formik.values.barcodeInformation.map((e: any, i: number) =>
                                            <div>
                                                <div key={i}
                                                     className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                                    <div className={styles['table-data-data-text']}>
                                                        {i + 1}.
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        <div
                                                            className={styles['barcode-input-error-message-field-container']}>
                                                            <input
                                                                tabIndex={0}
                                                                className={styles["input-box"]}
                                                                id={`barcodeInformation.${i}.barcodeNumber`}
                                                                name={`barcodeInformation.${i}.barcodeNumber`}
                                                                type="text"
                                                                onChange={(e: any) => {
                                                                    setBarcodeValue(e, i);
                                                                }}
                                                                onBlur={() => formik.setFieldTouched(`barcodeInformation.${i}.barcodeNumber`, true)}
                                                                value={formik.values.barcodeInformation && formik.values.barcodeInformation[i].barcodeNumber || ''}
                                                                maxLength={18}
                                                            />
                                                            {(getIn(formik.errors, `barcodeInformation-${i}-barcodeNumber`)) ?
                                                                <ErrorMessageFieldCommonComponent
                                                                    barcode={getIn(formik.errors, `barcodeInformation-${i}-barcodeNumber`)}/> : null}
                                                        </div>

                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.stockNumber}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.serialNumbers}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.quantity}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {formatCentsToDollars(e.unitPrice)}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {formatCentsToDollars(e.totalPrice)}
                                                    </div>
                                                    <div className={styles['table-data-data-text']}>
                                                        {e.currentWarehouse}
                                                    </div>
                                                    {(formik.values.barcodeInformation.length > 1) ?
                                                        <div tabIndex={0} className={styles['table-data-data-text']}
                                                             onKeyDown={(ev: any) => {
                                                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                                     setDeleteBarcodeToggle({
                                                                         show: true,
                                                                         deleteIndex: i
                                                                     });
                                                                 }
                                                             }}
                                                             onClick={() => {
                                                                 setDeleteBarcodeToggle({show: true, deleteIndex: i});
                                                             }}
                                                        >
                                                            <div className={styles['justify-right']}>
                                                                <RiDeleteBin6Line
                                                                    style={{cursor: "pointer"}}

                                                                />
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                <hr className={styles['horizontal-line']}/>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            <div className={styles["barcode-button-container"]}>
                                <div tabIndex={0} className={styles["add-barcode-button"]}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             formik.setFieldValue('barcodeInformation', [...formik.values.barcodeInformation, {
                                                 serialNumber: formik.values.barcodeInformation.length + 1,
                                                 barcodeNumber: '',
                                                 stockNumber: '-',
                                                 serialNumbers: '-',
                                                 quantity: '0',
                                                 unitPrice: '',
                                                 totalPrice: '',
                                                 currentWarehouse: '-',
                                                 error: ''
                                             }]);
                                         }
                                     }}
                                     onClick={() => {
                                         formik.setFieldValue('barcodeInformation', [...formik.values.barcodeInformation, {
                                             serialNumber: formik.values.barcodeInformation.length + 1,
                                             barcodeNumber: '',
                                             stockNumber: '-',
                                             serialNumbers: '-',
                                             quantity: '0',
                                             unitPrice: '',
                                             totalPrice: '',
                                             currentWarehouse: '-',
                                             error: ''
                                         }]);

                                     }}>
                                    <div className={styles["icon-button-label-container"]}>
                                        <FaPlus style={{cursor: "pointer"}}/>
                                        <div className={styles["add-barcode-button-label"]}>
                                            Add Barcode
                                        </div>
                                    </div>
                                </div>
                                <div tabIndex={0} className={styles["reset-all-barcodes-button"]}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             setResetBarcodesToggle(true);
                                         }
                                     }}
                                     onClick={() => {
                                         setResetBarcodesToggle(true);
                                     }}>
                                    <div className={styles["icon-button-label-container"]}>
                                        <BsArrowCounterclockwise style={{cursor: "pointer"}}/>
                                        <div className={styles["add-barcode-button-label"]}>
                                            Reset All Barcodes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["row-4-container"]}`}>
                            <div className={`${styles["row-4-col-1-container"]}`}>
                                <div className={`${styles["row-container-title"]}`}>Enter Order Information</div>
                                <div className={`${styles["row-4-col-1-row-1-container"]}`}>
                                    <div className={styles["label-and-input-box-container"]}>
                                        <div className={styles["add-new-inventory-page-label"]}>Shipping
                                            Method*
                                        </div>
                                        <div className={`${styles.custom_select_div}`}>
                                            <select className={styles["custom_select"]}
                                                    id="shippingMethod"
                                                    name="shippingMethod"
                                                    onChange={(e: any) => setFieldValue(e)}
                                                    onBlur={formik.handleBlur}
                                            >
                                                <option value="" selected>Select...</option>
                                                {
                                                    ShippingMethods.map((shippingMethod: string) =>
                                                        <option
                                                            selected={formik.values.shippingMethod.toLowerCase() === shippingMethod.toLowerCase()}
                                                            value={shippingMethod}>{shippingMethod}
                                                        </option>)
                                                }
                                            </select>
                                            <span className={`${styles.custom_arrow}`}></span>
                                        </div>
                                        {(formik.touched.shippingMethod && formik.errors.shippingMethod) ?
                                            <ErrorMessageFieldCommonComponent
                                                shippingMethod={formik.errors.shippingMethod}/> : null}
                                    </div>
                                    <div className={styles["label-and-input-box-container"]}>
                                        <div className={styles["add-new-inventory-page-label"]}>Payment Method*
                                        </div>
                                        <div className={`${styles.custom_select_div}`}>
                                            <select className={styles["custom_select"]}
                                                    id="paymentMethod"
                                                    name="paymentMethod"
                                                    onChange={async (e: any) => {
                                                        await setFieldValue(e);
                                                        updateTotalOrderSummary();
                                                    }}
                                                    onBlur={formik.handleBlur}
                                            >
                                                <option value="" selected>Select...</option>
                                                {
                                                    PaymentMethods.map((paymentMethod: string) =>
                                                        <option
                                                            selected={formik.values.paymentMethod.toLowerCase() === paymentMethod.toLowerCase()}
                                                            value={paymentMethod}>{paymentMethod}
                                                        </option>)
                                                }
                                            </select>
                                            <span className={`${styles.custom_arrow}`}></span>
                                        </div>
                                        {(formik.touched.paymentMethod && formik.errors.paymentMethod) ?
                                            <ErrorMessageFieldCommonComponent
                                                paymentMethod={formik.errors.paymentMethod}/> : null}
                                    </div>
                                </div>
                                {(formik.values.shippingMethod !== '') ?
                                    (formik.values.shippingMethod.toLowerCase() === 'pick-up') ?
                                        <div className={`${styles["row-4-col-1-row-2-container-1"]}`}>If shipping method
                                            is pick-up, please provide Receiver and Authorization Signature (MSLC)
                                            information:
                                        </div> :
                                        <div className={`${styles["row-4-col-1-row-2-container-2"]}`}>If shipping method
                                            is UPS, please provide the net weight and shipping cost of the order*:
                                        </div> : null}
                                {(formik.values.shippingMethod !== '') ?
                                    (formik.values.shippingMethod.toLowerCase() === 'pick-up') ?
                                        <div className={`${styles["row-4-col-1-row-3-container"]}`}>
                                            <div className={styles["label-and-input-box-container"]}>
                                                <div
                                                    className={styles["add-new-inventory-page-label"]}>Receiver*
                                                </div>
                                                <input className={styles["dropdown-list"]}
                                                       type={'text'}
                                                       id="receiver"
                                                       name="receiver"
                                                       onChange={(e: any) => setFieldValue(e)}
                                                       onBlur={formik.handleBlur}
                                                />
                                                {(formik.touched.receiver && formik.errors.receiver) ?
                                                    <ErrorMessageFieldCommonComponent
                                                        receiver={formik.errors.receiver}/> : null}
                                            </div>
                                            <div className={styles["label-and-input-box-container"]}>
                                                <div className={styles["add-new-inventory-page-label"]}>Authorization
                                                    Signature (MSLC)*
                                                </div>
                                                <input className={styles["dropdown-list"]}
                                                       type={'text'}
                                                       id="authorizationSignature"
                                                       name="authorizationSignature"
                                                       onChange={(e: any) => setFieldValue(e)}
                                                       onBlur={formik.handleBlur}
                                                />
                                                {(formik.touched.authorizationSignature && formik.errors.authorizationSignature) ?
                                                    <ErrorMessageFieldCommonComponent
                                                        authorizationSignature={formik.errors.authorizationSignature}/> : null}
                                            </div>
                                        </div> :
                                        <div className={`${styles["row-4-col-1-row-3-container"]}`}>
                                            <div className={styles["label-and-input-box-container"]}>
                                                <div className={styles["add-new-inventory-page-label"]}>Net
                                                    Weight*
                                                </div>
                                                <CurrencyInput
                                                    className={styles["dropdown-list"]}
                                                    id="netWeight"
                                                    name="netWeight"
                                                    suffix=" lbs"
                                                    // defaultValue={formik.values.netWeight}
                                                    onBlur={formik.handleBlur}
                                                    decimalsLimit={2}
                                                    disableAbbreviations={true}
                                                    onValueChange={(value, name) => {
                                                        formik.setFieldValue('netWeight', value)
                                                    }}
                                                />
                                                {(formik.touched.netWeight && formik.errors.netWeight) ?
                                                    <ErrorMessageFieldCommonComponent
                                                        netWeight={formik.errors.netWeight}/> : null}
                                            </div>
                                            <div className={styles["label-and-input-box-container"]}>
                                                <div className={styles["add-new-inventory-page-label"]}>Shipping Cost*
                                                </div>
                                                <CurrencyInput
                                                    className={styles["dropdown-list"]}
                                                    id="shippingCost"
                                                    name="shippingCost"
                                                    prefix="$"
                                                    value={isNaN(Number(formik.values.shippingCost)) ? '' : formik.values.shippingCost}
                                                    onBlur={formik.handleBlur}
                                                    decimalsLimit={2}
                                                    disableAbbreviations={true}
                                                    disabled={formik.values.paymentMethod.toLowerCase() === 'free'}
                                                    onValueChange={(value, name) => {
                                                        formik.setFieldValue('shippingCost', value)
                                                    }}
                                                />
                                                {(formik.touched.shippingCost && formik.errors.shippingCost) ?
                                                    <ErrorMessageFieldCommonComponent
                                                        shippingCost={formik.errors.shippingCost}/> : null}
                                            </div>
                                        </div> : null}
                            </div>
                            <div className={`${styles["row-4-col-2-container"]}`}>
                                <div className={`${styles["row-container-title"]}`}>Total Order</div>
                                <div className={`${styles['total-order-summary-controls-container']}`}>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Total
                                        Quantity
                                    </div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formik.values.quantity}</div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-3']} ${styles['mslc-label']}`}>Subtotal
                                    </div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-4']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formatCentsToDollars(formik.values.totalPricePaidInCents)}</div>

                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Net
                                        Weight
                                    </div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>{computeNetWeightValue(formik.values.netWeight)}</div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-3']} ${styles['mslc-label']}`}>Shipping
                                        Cost
                                    </div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-4']} ${styles['mslc-label']} ${styles['justify-right']}`}>{computeShippingCostValue(formik.values.shippingCost)}</div>
                                </div>
                                <hr className={styles['horizontal-line']}/>
                                <div className={`${styles['total-order-summary-controls-container']}`}>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-3']} ${styles['mslc-label']}`}>Grand
                                        Total
                                    </div>
                                    <div
                                        className={`${styles['total-order-summary-controls-container-column-4']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formatCentsToDollars(Number(formik.values.totalPricePaidInCents + (Number(formik.values.shippingCost) * 100)))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {deleteBarcodeToggle.show ?
                    <DeleteBarcodeConfirmationComponent
                        YesDeleteBarcode={YesDeleteBarcode}
                        deleteBarcodeToggle={deleteBarcodeToggle}
                        setDeleteBarcodeToggle={setDeleteBarcodeToggle}>
                    </DeleteBarcodeConfirmationComponent> : null}
                {resetBarcodesToggle ?
                    <ResetAllBarcodesConfirmationComponent
                        YesResetAllBarcodes={YesResetAllBarcodes}
                        resetBarcodesToggle={resetBarcodesToggle}
                        setResetBarcodesToggle={setResetBarcodesToggle}>
                    </ResetAllBarcodesConfirmationComponent> : null}
                {saveToggle ?
                    <SaveConfirmationComponent
                        YesSave={YesSave}
                        saveToggle={saveToggle}
                        setSaveToggle={setSaveToggle}>
                    </SaveConfirmationComponent> : null}
                {cancelToggle ?
                    <CancelConfirmationComponent
                        YesCancel={YesCancel}
                        cancelToggle={cancelToggle}
                        setCancelToggle={setCancelToggle}>
                    </CancelConfirmationComponent> : null}
            </>
        );
    })
);
