import React, {useState} from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const SettleOrRejectChargeComponent = ({settleOrRejectCharge, chargeId, setShowSettleRejectDialog}: any) => {

    const [chargeStatus, setChargeStatus] = useState<string>('');

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["permit-update-DOI-dialog-main-container"]}>
                    <div className={stylesMessageBox["permit-update-DOI-dialog-container"]}>
                        <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-1"]}>
                            <div className={stylesMessageBox["permit-update-DOI-dialog-title"]}>
                                Settle/Reject Charge
                            </div>
                            <div className={stylesMessageBox["permit-update-DOI-dialog-message"]}>
                                Update the status of the charge from “Submitted” to either “Paid” or “Rejected” below.
                            </div>
                            <div className={`${stylesMessageBox["radio-buttons-container"]}`}>
                                <div className={`${stylesMessageBox["radio-button-label-container"]}`}>
                                    <input
                                        tabIndex={0}
                                        className={`${stylesMessageBox["radio-button"]}`}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        type='radio'
                                        id="paid"
                                        name="chargeStatus"
                                        value={"Settle"}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setChargeStatus(e.target.value);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setChargeStatus(e.target.value);
                                        }}
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        htmlFor="paid">Paid
                                    </label>
                                </div>
                                <div className={`${stylesMessageBox["radio-button-label-container"]}`}>
                                    <input
                                        tabIndex={0}
                                        className={`${stylesMessageBox["radio-button"]}`}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        type='radio'
                                        id="rejected"
                                        name="chargeStatus"
                                        value={"Reject"}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setChargeStatus(e.target.value);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setChargeStatus(e.target.value);
                                        }}
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        htmlFor="rejected">Rejected
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-2"]}>
                            <div
                                tabIndex={0}
                                role="button"
                                style={{
                                    opacity: (chargeStatus !== '') ? "1" : "0.4",
                                    cursor: (chargeStatus !== '') ? "pointer" : "not-allowed",
                                }}
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0 && chargeStatus !== '') {
                                        settleOrRejectCharge(chargeId, chargeStatus, false);
                                        setShowSettleRejectDialog(false);
                                    }
                                }}
                                onClick={() => {
                                    if (chargeStatus !== '') {
                                        settleOrRejectCharge(chargeId, chargeStatus, false);
                                        setShowSettleRejectDialog(false);
                                    }
                                }}
                                className={stylesMessageBox["permit-update-DOI-dialog-yes-button"]}
                            >
                                <div
                                    className={
                                        stylesMessageBox["permit-update-DOI-dialog-yes-button-text"]
                                    }
                                >
                                    Update
                                </div>
                            </div>
                            <div
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        setShowSettleRejectDialog(false);
                                    }
                                }}
                                onClick={() => {
                                    setShowSettleRejectDialog(false);
                                }}
                                className={
                                    stylesMessageBox["permit-update-DOI-dialog-no-button"]
                                }
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};