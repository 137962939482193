import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, downloadCSV, getPageData, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent, NotificationType
} from "../../../../components/HomeComponent/NotificationBannerComponent";
import filterCollapseIcon from "../../../../icons/Icon_arrows_carrot_teal-up.svg";
import filterExpandIcon from "../../../../icons/Icon_arrows_carrot_teal-down.svg";
import {
    ErrorMessageFieldCommonComponent
} from "../../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {PaginationCommonComponent} from "../../../../components/CommonComponents/PaginationCommonComponent";
import {formatCentsToDollars} from "../../../../util";

export const WeeklyBeanoReportingPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

    const history: any = useHistory();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const currentYear = new Date().getFullYear();

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            year: currentYear.toString(), licenseNumber: ''
        }, validate, onSubmit
    });

    const activateSearchButton = (formik.isValid && formik.dirty);

    const [weeklyBeanoReports, setWeeklyBeanoReports] = useState<any>([]);
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [yearDropdownList, setYearDropdownList] = useState<string[]>([]);

    const {notificationBanner} = charitableGamingStore;

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    const fillYearDropdownList = () => {
        let yearDropdownList = [currentYear.toString()];
        for (let i = 1; i <= 7; i++) {
            yearDropdownList.push((currentYear - i).toString());
        }
        setYearDropdownList(yearDropdownList);
    };

    useEffect(() => {
        fillYearDropdownList();
        if (requestCompleted === false) {
            formik.submitForm();
        }
    }, []);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    setVariables(formik, charitableGamingStore, setCurrentPage, setShowErrorDialog, setRequestCompleted, setWeeklyBeanoReports);

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['reporting-page-container']}`}
                 onClick={() => {

                 }}
            >
                <div tabIndex={0}
                     className={`${styles['reporting-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['reporting-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to Reporting
                    </div>
                </div>
                <div className={`${styles['reporting-page-ribbon-container']}`}>
                    <div className={`${styles['reporting-page-ribbon-title']} ${styles['']}`}>Weekly Beano
                        Reporting
                    </div>
                    <div className={`${styles['reporting-page-ribbon-buttons-container']}`}>
                        <div tabIndex={0}
                             className={`${styles['reporting-page-ribbon-create-license-button']} ${styles['']}`}
                             style={{
                                 cursor: weeklyBeanoReports.length > 0 ? "pointer" : "not-allowed",
                                 opacity: weeklyBeanoReports.length > 0 ? 1 : 0.4
                             }}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32 && weeklyBeanoReports.length > 0) {
                                     downloadCSV();
                                 }
                             }}
                             onClick={() => {
                                 if (weeklyBeanoReports.length > 0) {
                                     downloadCSV();
                                 }
                             }}>
                            <div
                                className={`${styles['reporting-page-ribbon-create-license-button-label']} ${styles['']}`}>Download
                                CSV File
                            </div>
                        </div>
                    </div>

                </div>

                <div
                    className={`${styles['reporting-page-search-bar-and-notification-bar-container']}`}>
                    <div className={`${styles['reporting-page-search-bar-container']} ${styles['']}`}>
                        <div
                            className={`${styles['reporting-page-search-bar-title']} ${styles['']}`}>Search
                        </div>
                        <div
                            className={`${styles['reporting-page-search-bar-filter-container']} ${styles['']}`}>

                            <div
                                className={`${styles['reporting-page-search-bar-filter-title-bar']} ${styles['']}`}>
                                <div
                                    className={`${styles['reporting-page-search-bar-filter-title']} ${styles['']}`}>Filter
                                </div>
                                <div
                                    className={`${styles['reporting-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}>
                                    <img
                                        tabIndex={0}
                                        src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                        className={styles[""]}
                                        alt="filter expander icon"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setShowFilters(!showFilters);
                                            }
                                        }}
                                        onClick={() => {
                                            setShowFilters(!showFilters);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {showFilters ? <div
                            className={`${styles['reporting-page-search-bar-filter-expanded-container']} ${styles['']}`}>
                            <div
                                className={`${styles['reporting-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>Filter by Year
                                </div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select className={styles["custom_select"]}
                                            id="year"
                                            name="year"
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                                formik.submitForm();
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder="Select"
                                    >
                                        {yearDropdownList.map((year: string) => <option
                                            selected={formik.values.year.toLowerCase() === year.toLowerCase()}
                                            value={year}>{year}
                                        </option>)}
                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                            </div>
                        </div> : null}

                        <div
                            className={`${styles['reporting-page-search-bar-input-button-container']}`}>
                            <div
                                className={`${styles['reporting-page-search-bar-label-textbox-container']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>License Number (must be exact match)</div>
                                <input className={`${styles['mslc-textbox']}`}
                                       type="text"
                                       id="licenseNumber"
                                       name="licenseNumber"
                                       value={formik.values.licenseNumber}
                                       onBlur={formik.handleBlur}
                                       maxLength={5}
                                       onChange={(e: any) => {
                                           setFieldValue(e, formik);
                                       }}/>
                                {formik.touched.licenseNumber && formik.errors.licenseNumber ?
                                    <ErrorMessageFieldCommonComponent
                                        errorMessages={formik.errors.licenseNumber}/> : null}
                            </div>
                            <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                                 role="button"
                                 style={{
                                     opacity: (activateSearchButton) ? "1" : "0.4",
                                     cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (activateSearchButton) {
                                             formik.submitForm();
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }}>Search

                            </div>
                            <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         clearSearch(formik);
                                     }
                                 }}
                                 onClick={() => {
                                     clearSearch(formik);
                                 }}>Clear Search
                            </div>
                        </div>
                    </div>
                </div>
                {weeklyBeanoReports.length > 0 ? <div className={styles['table']}>

                    <div className={styles['table-data-header']}>
                        <div className={styles['table-data-header-text']} aria-live="assertive">
                            {`Displaying ${weeklyBeanoReports.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(weeklyBeanoReports.length, currentPage)}
                            of ${weeklyBeanoReports.length} results`}
                        </div>
                    </div>

                    <div className={styles['table-data-body']}>

                        <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                            <div>License No.</div>
                            <div>Organization Name</div>
                            <div>Gross Receipts</div>
                            <div>Prizes</div>
                            <div>Expenses</div>
                            <div>Tax</div>
                            <div>Profit</div>
                            <div>Charity Tickets - Purchased</div>
                            <div>Charity Tickets - Sold</div>
                        </div>

                        {weeklyBeanoReports.length > 0 && getPageData(weeklyBeanoReports, currentPage).map((e: any, i: number) =>
                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div className={styles["table-data-data-text"]}>
                                    {e.licenseNumber}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.orgName}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.grossReceiptsInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.totalPrizesInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.expensesInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.taxesInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.totalNetProfitOrLossInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.charityTicketsPurchasedInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.charityTicketsSoldInCents)}
                                </div>
                            </div>)}
                    </div>
                    <div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((weeklyBeanoReports.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={weeklyBeanoReports}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>
                </div> : <div className={`${styles['reporting-page-search-no-results-container']}`}>
                    <div className={`${styles['reporting-page-search-no-results-container-text']}`}>
                        No Results. Please try a different search.
                    </div>
                </div>}
            </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                type={NotificationType.Success}
                text={notificationBanner.text}
                handleHide={hideNotificationBanner}
            /> : null}
        </>

    );
}));