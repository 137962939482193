import * as React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import {
  ManualWinner,
  ManualWinnersForm,
  PromotionDrawingTier,
  PromotionResponse,
  TriggerEvent,
} from '../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../utilities/helpers'
import {
  CollapsableSection,
  useForceOpen,
} from '../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import { ConfirmPopupModal } from '../../../CommonComponents/ConfirmPopupModal/confirm-popup-modal'
import { DrawingSelection } from '../winners-management-drawing-form/winners-management-drawing-form'
import { WinnersManagementListUI } from '../winners-management-list/winners-management-list-ui'
import { WinnersManagementWinnerFormUI } from '../winners-management-winner-form/winners-management-winner-form-ui'
import { getSubtitle, validateNewWinnersForm } from './helpers'
import './styles.scss'

type WinnersManagementTierUIProps = {
  tier: PromotionDrawingTier
  acceptedWinners: number
  totalWinners: number
  promotion: PromotionResponse
  selectedDrawing: DrawingSelection
  fetchDownloadWinnersCSV: (
    promotionId: string,
    drawingGroupId: string,
    drawingId: string
  ) => Promise<void>
  refreshTiers: () => Promise<void>
  saveWinners: (values: ManualWinner[]) => Promise<void>
  isLoading: boolean
  error: any
  requestSuccessful: boolean
}

const ROOT_CLASS = 'winners-management-tier-ui'

const DEFAULT_MANUAL_WINNER = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
}

export const WinnersManagementTierUI = (
  props: WinnersManagementTierUIProps
) => {
  const [isSaveWinnersModalOpen, setSaveWinnersModalOpen] =
    React.useState<boolean>(false)
  const { isForcedOpen, forceOpen } = useForceOpen()
  const { tier, acceptedWinners, totalWinners, selectedDrawing } = props
  const winnersText = `Accepted Winners • ${acceptedWinners}/${totalWinners}`
  const isManualWinnerPromotion =
    props.promotion.triggerEventType === TriggerEvent.MANUAL

  const submitForm = async (
    values: ManualWinnersForm,
    helpers: FormikHelpers<ManualWinnersForm>
  ) => {
    await props.saveWinners(values.winners)
    await props.refreshTiers()
    helpers.resetForm()
  }

  const manualWinnersForm = useFormik<ManualWinnersForm>({
    initialValues: { winners: [] },
    onSubmit: submitForm,
    validate: validateNewWinnersForm,
  })

  const addWinner = async () => {
    forceOpen()
    await manualWinnersForm.setFieldValue(`winners`, [
      ...manualWinnersForm.values.winners,
      DEFAULT_MANUAL_WINNER,
    ])
  }

  const removeWinner = async (index: number) => {
    const existingWinners = [...manualWinnersForm.values.winners]
    existingWinners.splice(index, 1)
    await manualWinnersForm.setFieldValue('winners', [...existingWinners])
    const touched = { ...manualWinnersForm.touched }
    if (touched.winners) {
      touched.winners.splice(index, 1)
      await manualWinnersForm.setTouched(touched)
    }
  }

  return (
    <>
      <ConfirmPopupModal
        headerText="Are you sure you want to save these winners to this Prize Tier?"
        buttonText="Confirm"
        isOpen={isSaveWinnersModalOpen}
        closeModal={() => setSaveWinnersModalOpen(false)}
        error={props.error}
        loading={props.isLoading}
        actionSuccessful={props.requestSuccessful}
        submitAction={manualWinnersForm.submitForm}
      />
      <CollapsableSection
        title={`Tier ${tier.prizeTier} - ${tier.description}`}
        subtitle={getSubtitle(
          tier,
          winnersText,
          props.promotion,
          addWinner,
          manualWinnersForm.values.winners,
          () => setSaveWinnersModalOpen(true),
          manualWinnersForm.isValid
        )}
        isForcedOpen={isForcedOpen}
      >
        {tier.winners.length > 0 ? (
          <WinnersManagementListUI
            tierData={tier}
            showActionMenu={!isManualWinnerPromotion}
            showOffsets={!isManualWinnerPromotion}
          />
        ) : (
          <span className={getBlockClass(ROOT_CLASS, 'no-winners')}>
            No existing winners
          </span>
        )}
        {props.promotion.triggerEventType === TriggerEvent.MANUAL && (
          <WinnersManagementWinnerFormUI
            formik={manualWinnersForm}
            tierId={tier.prizeTier}
            removeWinner={removeWinner}
          />
        )}
      </CollapsableSection>
    </>
  )
}
