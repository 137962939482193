import moment from 'moment'
import { GenericTableActionMenu } from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-action-menu/generic-table-row-action-menu-ui'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
} from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import {
  StepDownStatus,
  PlayslipsStepDown,
} from '../../../api/playslips-fetcher/constants'

const playslipsStepDownStatusColor: Record<StepDownStatus, string> = {
  [StepDownStatus.PENDING]: '#FFEB00',
  [StepDownStatus.COMPLETE]: '#676767',
}

export const PlayslipsStepDownStatusOrder: Record<StepDownStatus, number> = {
  [StepDownStatus.PENDING]: 1,
  [StepDownStatus.COMPLETE]: 2,
}

export type PlayslipsStepDownTableRow = {
  game: string
  status: StepDownStatus
  effectiveDate: string
  actionMenu: GenericTableActionMenu
}

export const playslipsStepDownTableColumnMap: GenericTableColumnMap<PlayslipsStepDownTableRow> =
  [
    {
      key: 'game',
      columnText: 'Game',
      columnType: GenericTableColumnType.BOLD,
    },
    {
      key: 'status',
      columnText: 'Status',
      columnType: GenericTableColumnType.STATUS,
      statusMap: playslipsStepDownStatusColor,
    },
    {
      key: 'effectiveDate',
      columnText: 'Effective Date',
      columnType: GenericTableColumnType.DEFAULT,
      widthPx: 260,
    },
  ]

export const mapPlayslipsStepDownDataToRow = (
  stepDown: PlayslipsStepDown,
  actionsDisabled: boolean,
  openDeleteModal: () => void
): PlayslipsStepDownTableRow => {
  let status =
    stepDown.status.toLowerCase() == StepDownStatus.PENDING.toLowerCase()
      ? StepDownStatus.PENDING
      : StepDownStatus.COMPLETE
  const longDashCharacter = String.fromCharCode(8212)
  const stepDownDate = moment(stepDown.stepDownDate).tz('America/New_York')
  const formattedStepDownDate =
    stepDownDate?.format('MM/DD/YYYY - h:mm A') ?? longDashCharacter

  return {
    game: stepDown.name,
    status: status,
    effectiveDate: `${formattedStepDownDate} EST`,
    actionMenu: {
      ariaLabel: `Actions for ${stepDown.name}`,
      menuItems: [
        {
          onClick: openDeleteModal,
          label: 'Delete',
          disabled: actionsDisabled,
          ariaLabel: `Delete pending stepdown for ${stepDown.name}`,
          key: 'delete',
        },
      ],
      hideMenu: status === StepDownStatus.COMPLETE,
    },
  }
}
