import { observer } from 'mobx-react'
import React from 'react'
import { permissions } from '../../constants'
import CustomCard from '../../dashboard/customCard'
import { checkRenderPermissions } from '../../helpers'
import { reportingRoutePath } from '../../config.js'

@observer
class ReconciliationReportMenu extends React.Component {
  navigateToMobileClaimsReconciliation = () => {
    this.props.history.push(
      `${reportingRoutePath}/finance/claims/dailyreconciliation`
    )
  }

  navigateToGeneralReconciliation = () => {
    this.props.history.push(
      `${reportingRoutePath}/finance/reporting/general-reconciliation`
    )
  }

  render() {
    if (
      checkRenderPermissions(
        permissions.CAN_SEE_DAILY_RECONCILIATION,
        JSON.parse(localStorage.getItem('userpolicies'))
      )
    ) {
      return (
        <div
          style={{
            paddingTop: '8em',
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: 'rgb(22, 58, 95)',
          }}
        >
          <CustomCard
            title="Mobile Claims Reconciliation"
            handleCallback={() => this.navigateToMobileClaimsReconciliation()}
          />
          {checkRenderPermissions(permissions.CAN_SEE_SSN,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ?
            <CustomCard
              title="General Reconciliation"
              handleCallback={() => this.navigateToGeneralReconciliation()}
            /> : null
          }
        </div>
      )
    } else {
      return (
        <h3 className="unauthorized-header">
          You do not have permission to view this page! Please contact your
          System Administrator!
        </h3>
      )
    }
  }
}
export default ReconciliationReportMenu
