import React, { useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import stylesMessageBox from './css/stylesMessageBox.module.css'
import { DisplayDocumentNewComponent } from './../../../components/PlayersComponents/DisplayDocumentNewComponent'

import { setNestedObjectValues, useFormik } from 'formik'

import { UpadteProfileComponent } from './../../../components/PlayersComponents/UpdateProfileComponent'

import {
  validate,
  setFiledValue,
  onSubmit,
  setVariables,
  ssnNumberIsBeenUsed,
  refreshComponent,
} from './functions'

import eventEmitter from './../../../services/EventEmitter'
import { playerSupportRoutePath } from './../../../config.js'
import { useHistory } from 'react-router-dom'
import { PLAYERS_PAGES } from './../../../application-data-structure/players-page.data-structure'
import { keyPressedType } from '../../../services/Commons'

import {
  PageMessageCommonComponent,
  pageMessageDefault,
} from './../../../components/CommonComponents/PageMessageCommonComponent'

import { CircularProgress } from '@material-ui/core'

let eventEmitterInitialize: any = undefined
const timerArray: any = []
export const UpdateProfilePage = ({ id, storesObject }: any) => {
  const playerStore: any = storesObject.playerStore
  const history = useHistory()

  const setTogglevalue: any = { value: false }

  const callingPage = PLAYERS_PAGES
  const [saveToggle, setSaveToggle] = useState<any>(setTogglevalue)
  const [cancelToggle, setCancelToggle] = useState<boolean>(false)
  const [requestPerformed, setRequestPerformed] = useState<any>(false)
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault)
  const [isData, setIsDate] = useState<boolean>(true)
  const [networkError, setNetworkError] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: playerStore.selectedPlayerDetails,
    onSubmit,
    validate,
  })

  const sleep = (ms: any) => {
    const timer = setTimeout(
      (timerArray: any) => {
        timerArray.map((e: any) => clearTimeout(e))
        setRequestPerformed(false)
      },
      ms,
      timerArray
    )
    timerArray.push(timer)
  }
  useEffect(() => {
    if (document.getElementById('update-profile-save-box-container') !== null)
      document.getElementById('update-profile-save-box-container')?.focus()
  })

  setVariables(
    formik,
    playerStore,
    history,
    pageMessageDefault,
    setPageMessage,
    setIsDate
  )
  if (eventEmitterInitialize === undefined)
    eventEmitter.addListener('refresh-data-ready', refreshComponent)

  return (
    <>
      {(((playerStore.selectedPlayerDetails.account.email === undefined ||
        playerStore.selectedPlayerDetails.account.email === '') &&
        pageMessage.message === '') ||
        (!isData && pageMessage.message === '')) && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
            zIndex: 1000,
          }}
        >
          <CircularProgress size="8em" color="inherit" thickness={2} />
        </div>
      )}
      <div
        onClick={(e: any) => {
          pageMessage.messageType !== '' &&
            setPageMessage({ message: '', messageType: '' })
        }}
        className={styles['page-container']}
      >
        <div className={styles['top-ribbon']}>
          <div className={styles['top-ribbon-text']} tabIndex={0}>
            Update Profile
          </div>
          <div className={styles['top-ribbon-button-container']}>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              onClick={() => setCancelToggle(!cancelToggle)}
              onKeyPress={(e: any) =>
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
                  ? setCancelToggle(!cancelToggle)
                  : ''
              }
              className={styles['top-ribbon-cancel-button']}
            >
              Cancel
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              style={{
                opacity: Object.keys(formik.errors).length > 0 ? '0.4' : '1',
                cursor:
                  Object.keys(formik.errors).length > 0
                    ? 'not-allowed'
                    : 'pointer',
              }}
              onKeyPress={(e: any) => {
                if (
                  (keyPressedType(e) === 13 || keyPressedType(e) === 0) &&
                  Object.keys(formik.errors).length === 0
                ) {
                  setSaveToggle({ value: true })
                  saveToggle.value = true
                }
              }}
              onClick={() => {
                if (Object.keys(formik.errors).length === 0) {
                  setSaveToggle({ value: true })
                  saveToggle.value = true
                }
              }}
              className={styles['top-ribbon-save-button']}
            >
              Save
            </div>
          </div>
        </div>
        {pageMessage.message !== '' && (
          <PageMessageCommonComponent
            message={pageMessage.message}
            messageType={pageMessage.messageType}
          />
        )}
        <div className={styles['page-container-body-container']}>
          <div className={styles['page-container-body']}>
            <div className={styles['page-container-body-left']}>
              <UpadteProfileComponent
                formik={formik}
                setFiledValue={setFiledValue}
                ssnNumberIsBeenUsed={ssnNumberIsBeenUsed}
                callingPage={callingPage}
              />
            </div>
            <div className={styles['page-container-body-right']}>
              <DisplayDocumentNewComponent
                storesObject={storesObject}
                setRequestPerformed={setRequestPerformed}
                requestPerformed={requestPerformed}
                playerId={playerStore.selectedPlayerDetails.account.id}
                setPageMessage={setPageMessage}
                pageMessage={pageMessage}
              />
            </div>
          </div>
        </div>
      </div>
      {saveToggle.value && (
        <div className={stylesMessageBox['window-container']}>
          <div
            tabIndex={0}
            id="update-profile-save-box-container"
            className={stylesMessageBox['update-profile-save-box-container']}
          >
            <div
              tabIndex={0}
              className={stylesMessageBox['update-profile-save-box-message']}
            >
              Are you sure you would like to save?
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyPress={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                  setIsDate(false)
                  playerStore.clearPlayerSearchResults()
                  formik.submitForm()
                  saveToggle.value = false
                }
              }}
              onClick={() => {
                setIsDate(false)
                playerStore.clearPlayerSearchResults()
                formik.submitForm()
                saveToggle.value = false
              }}
              className={stylesMessageBox['update-profile-save-box-button-yes']}
            >
              <div
                className={
                  stylesMessageBox['update-profile-save-button-yes-text']
                }
              >
                Yes, Save
              </div>
            </div>
            <div
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                  setSaveToggle({ value: !saveToggle.value })
                }
              }}
              role="button"
              onClick={() => setSaveToggle({ value: !saveToggle.value })}
              className={
                stylesMessageBox['player-email-and-phone-message-box-button-no']
              }
            >
              Go Back
            </div>
          </div>
        </div>
      )}
      {cancelToggle && (
        <div className={stylesMessageBox['window-container']}>
          <div
            tabIndex={0}
            id="update-profile-save-box-container"
            className={stylesMessageBox['update-profile-save-box-container']}
          >
            <div
              tabIndex={0}
              className={stylesMessageBox['update-profile-save-box-message']}
            >
              Are you sure you would like to cancel without saving?
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyPress={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                  pageMessage.messageType !== '' &&
                    setPageMessage({ message: '', messageType: '' })
                  history.push(`${playerSupportRoutePath}/player-hub-new/${id}`)
                }
              }}
              onClick={() => {
                pageMessage.messageType !== '' &&
                  setPageMessage({ message: '', messageType: '' })
                history.push(`${playerSupportRoutePath}/player-hub-new/${id}`)
              }}
              className={stylesMessageBox['update-profile-save-box-button-yes']}
            >
              <div
                className={
                  stylesMessageBox['update-profile-save-button-yes-text']
                }
              >
                Yes, Cancel
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                  setCancelToggle(!cancelToggle)
                }
              }}
              onClick={() => setCancelToggle(!cancelToggle)}
              className={
                stylesMessageBox['player-email-and-phone-message-box-button-no']
              }
            >
              Go Back
            </div>
          </div>
        </div>
      )}
    </>
  )
}
