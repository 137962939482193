import { PayTaxIndicator } from '../future-payment-details-fetcher/api-types'

export enum PlayerPaymentStatus {
  PAID = 'PAID',
  VOID = 'VOID',
}

export enum PayeeType {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTITY = 'ENTITY',
  NONE = 'NONE',
}

export enum SplitIndicator {
  SPLIT_NONE = 'SPLIT_NONE',
  SPLIT_5754 = 'SPLIT_5754',
  SPLIT_COURT = 'SPLIT_COURT',
  SPLIT_BOTH = 'SPLIT_BOTH',
}

export enum PaymentSource {
  RTC = 'RTC',
  ES = 'ES',
  PROMOTIONS = 'PROMOTIONS',
  ANNUITIES = 'ANNUITIES',
  SPLIT = 'SPLIT',
  SEASON_TICKETS = 'SEASON_TICKETS',
  OTHER = 'OTHER',
}

export enum PaymentType {
  CHECK = 'CHECK',
  ACH = 'ACH',
  SPLIT = 'SPLIT',
}

type ChildPaymentSummary = {
  name: string
  grossAmountInCents: number
  splitDate: string
  paymentId: string
}

export type PaymentDetails = {
  internalPaymentId: string
  documentNumber: string
  paymentStatus: PlayerPaymentStatus
  game: string
  reportablePaymentDate: string // YYYYMMDD
  paymentDate: string // YYYYMMDD
  grossPaymentInCents: number
  taxableMerchandiseInCents: number
  nonTaxableMerchandiseInCents: number
  grossNonTaxableInCents: number
  grossDorTaxableOnlyInCents: number
  grossDorAndIrsTaxableInCents: number
  reportableWinningsInCents: number
  payTaxIndicator: PayTaxIndicator
  irsTaxInCents: number
  dorTaxInCents: number
  offsetsWithheldInCents: number
  netPaymentInCents: number
  name: string
  ssn: string
  payeeAddress1: string
  payeeAddress2: string
  payeeCity: string
  payeeState: string
  payeeZip5: string
  payeeCountry: string
  payeePhone: string
  payeeEmail: string
  payeeBirthdate: string
  splitIndicator: SplitIndicator
  source: PaymentSource
  annuityId: string
  parentPaymentId: string
  paymentType: PaymentType
  childPaymentSummaries: ChildPaymentSummary[]
  payeeSupplementalAddress1: string
  payeeSupplementalAddress2: string
  voidDetails: VoidData | null
  reissueDetails: ReissueData | null
}

export type VoidData = {
  voidDate: string | null
  voidedPaymentId: string 
}

export type ReissueData = {
  reissueDate: string | null 
  reissuedPaymentId: string 
}

export type GetPaymentDetailsResponse = {
  payment: PaymentDetails
}
