import * as React from 'react'
import { getBlockClass } from '../../../utilities/helpers'
import { CheckboxComponent } from '../../CommonComponents/CheckboxComponent'
import { CollapsableSection } from '../../CommonComponents/collapsable-section-component/collapsable-section-ui'

type WinnersManagementNotAcceptedFilterProps = {
  openClaimsFilterChecked: boolean
  toggleOpenClaimsFilter: () => void
}

const ROOT_CLASS = 'winners-management-filter'

export const WinnersManagementOpenClaimsFilter = (
  props: WinnersManagementNotAcceptedFilterProps
) => {
  return (
    <div className={`${ROOT_CLASS}-collapsable`}>
      <CollapsableSection
        title="Filter"
        backgroundColor="dark_blue"
        connectedBottom
        textSize="large"
      >
        <div className={ROOT_CLASS}>
          <button
            className={getBlockClass(ROOT_CLASS, 'checkbox-container')}
            tabIndex={0}
            onClick={props.toggleOpenClaimsFilter}
            aria-label="Toggle open claims filter"
          >
            <CheckboxComponent isChecked={props.openClaimsFilterChecked} />
          </button>
          <span>Open Claims</span>
        </div>
      </CollapsableSection>
    </div>
  )
}
