import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import {useHistory} from "react-router-dom";
import filterExpandIcon from "../../../../../icons/Icon_arrows_carrot_teal-down.svg";
import filterCollapseIcon from "../../../../../icons/Icon_arrows_carrot_teal-up.svg";
import {clearSearch,} from "../../../../../pages/CharitableGamingPages/PullTab/Inventory/PullTabInventoryPage/functions";
import {inject, observer} from "mobx-react";

export const PullTabInventorySearchBarComponent = inject("charitableGamingStore")(observer(({formik, setFieldValue, charitableGamingStore}: any) => {

        const history: any = useHistory();

        const [warehouses, setWarehouses] = useState<string[]>([]);
        const [showFilters, setShowFilters] = useState<boolean>(false);

        const activateSearchButton = (formik.values.stockNumber !== '' && Object.keys(formik.errors).length === 0);

        useEffect(() => {
            charitableGamingStore.GetAllWarehouses(setWarehouses);
        }, []);

        return (
            <>

                <div className={`${styles['pulltab-inventory-page-search-bar-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-inventory-page-search-bar-title']} ${styles['']}`}>Search
                    </div>
                    <div className={`${styles['pulltab-inventory-page-search-bar-filter-container']} ${styles['']}`}>

                        <div className={`${styles['pulltab-inventory-page-search-bar-filter-title-bar']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-inventory-page-search-bar-filter-title']} ${styles['']}`}>Filter
                            </div>
                            <div tabIndex={0}
                                className={`${styles['pulltab-inventory-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}
                                 onKeyDown={(e: any) => {
                                     if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                         setShowFilters(!showFilters);
                                     }
                                 }}
                                 onClick={() => {
                                     setShowFilters(!showFilters);
                                 }}
                            >
                                <img
                                    src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                    className={styles[""]}
                                    alt="filter expander icon"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {showFilters ?
                        <div
                            className={`${styles['pulltab-inventory-page-search-bar-filter-expanded-container']}`}>
                            <div className={`${styles['pulltab-inventory-page-checkbox-container']}`}>
                                <div className={`${styles['mslc-label']}`}>Filter by Warehouse</div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select
                                        id="warehouse"
                                        name="warehouse"
                                        onChange={(e: any) => {
                                            setFieldValue(e, formik);
                                            formik.submitForm();
                                        }}
                                        onBlur={formik.handleBlur}
                                        placeholder="Select"
                                        className={styles["custom_select"]}>
                                        <option value="All" selected>All</option>
                                        {
                                            warehouses.map((warehouse: string) =>
                                                <option
                                                    selected={formik.values.warehouse.toLowerCase() === warehouse.toLowerCase()}
                                                    value={warehouse}>{warehouse}
                                                </option>)
                                        }
                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                            </div>

                        </div> : null}

                    <div
                        className={`${styles['pulltab-inventory-page-search-bar-input-button-container']} ${styles['']}`}>

                        <div
                            className={`${styles['pulltab-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Stock Number</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="stockNumber"
                                   name="stockNumber"
                                   value={formik.values.stockNumber}
                                   maxLength={5}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>

                            {formik.errors.stockNumber ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.stockNumber}/> : null}
                        </div>

                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>
                </div>

            </>
        );
    })
);