export const SystemStatsCategories = [
    'tickets',
    'rtc winner',
    'non-rtc winner',
    'non-winner',
    'registration',
    'kyc-accepted',
    'kyc-rejected',
    'environment',
    'api-error',
    'error',
    'tickets',
    'ticket-scan-threshold',
    'cashed-tickets'
]