export type PromotionalMessageValidation = {
  title: string
  message: string
  gameIdentifier: string
  startDate: string
  endDate: string
}

const TITLE_MAX_LENGTH = 37
const MESSAGE_MAX_LENGTH = 150

const ERROR_TITLE_EXCEEDS_MAX_LENGTH = `Title exceeds max length of ${TITLE_MAX_LENGTH} characters`
const ERROR_MESSAGE_EXCEEDS_MAX_LENGTH = `Message exceeds max length of ${MESSAGE_MAX_LENGTH} characters`
const ERROR_TEXT = 'This field is required'

export const validateCreatePromotionalMessageForm = (
  values: PromotionalMessageValidation
) => {
  let errors = {}

  if (!values.title) {
    Object.assign(errors, { title: ERROR_TEXT })
  }

  if (values.title && values.title.length > TITLE_MAX_LENGTH) {
    Object.assign(errors, { title: ERROR_TITLE_EXCEEDS_MAX_LENGTH })
  }

  if (!values.message) {
    Object.assign(errors, { message: ERROR_TEXT })
  }

  if (values.message && values.message.length > MESSAGE_MAX_LENGTH) {
    Object.assign(errors, { message: ERROR_MESSAGE_EXCEEDS_MAX_LENGTH })
  }

  if (
    values.startDate &&
    values.endDate &&
    new Date(values.startDate) > new Date(values.endDate)
  ) {
    Object.assign(errors, {
      endDate: 'End date must be same or after the start date',
    })
  }

  return errors
}
