import React, { SetStateAction, useState } from "react";
import { KEY_CODES } from "../../../../../constants.js";
import { BannerContext } from "../../../../../context/BannerContext";
import { keyPressedType } from "../../../../../services/Commons";
import { SubAccount } from "../../../../../types/Annuities/entities/sub-account";
import styles from "./css/styles.module.css";
import { updateSubAccount } from "./functions";

export const UpdateSubAccountStatusComponent: React.FC<{
  setShowUpdateAccountStatusModal: React.Dispatch<SetStateAction<boolean>>;
  subAccountDetails: SubAccount;
}> = ({
  setShowUpdateAccountStatusModal: setAnnuityBtnChangeStatus,
  subAccountDetails,
}) => {
  const bannerContext = React.useContext(BannerContext);

  // State Variable for Component
  const [accountStatus, setAccountStatus] = useState<string>(
    subAccountDetails.acctStatus
  );
  const [statusNotes, setStatusNotes] = useState<string | undefined>(
    subAccountDetails.notes
  );

  // Change updateStatus when a radio button is selected
  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountStatus(event.target.value);
  };

  // Change updateStatus when a radio button is selected
  const notesHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStatusNotes(event.target.value);
  };

  // package required to focus modal accessibility
  const FocusTrap = require("focus-trap-react");

  return (
    <FocusTrap focusTrapOptions={{ initialFocus: false }}>
      <div className={styles["overlay-container"]}>
        <div className={styles["main-modal"]}>
          <div>
            <div className={styles["modal-title"]}>Update Status</div>
            <div className={styles["modal-desc"]}>
              REMINDER: Changing the status from Hold to Active will
              automatically release any pending hold payments.
            </div>
            <div className={styles["radio_container"]}>
              <div>
                <input
                  type="radio"
                  className={styles["radio_input"]}
                  name="status"
                  value="Active"
                  id="active"
                  checked={accountStatus === "Active"}
                  onChange={radioHandler}
                />
                <label htmlFor="active" className={styles["radio_label"]}>
                  Active
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  className={styles["radio_input"]}
                  name="status"
                  value="On Hold"
                  id="onHold"
                  checked={accountStatus === "On Hold"}
                  onChange={radioHandler}
                />
                <label htmlFor="onHold" className={styles["radio_label"]}>
                  On Hold
                </label>
              </div>
            </div>
          </div>
          <div>
            <textarea
              className={`${styles["text-area-input"]}`}
              id="statusNotes"
              name="statusNotes"
              value={statusNotes}
              placeholder="Include any additional context here..."
              onChange={notesHandler}
            ></textarea>
          </div>
          <div>&nbsp;</div>
          <button
            type="button"
            onKeyPress={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                subAccountDetails.acctStatus = accountStatus;
                subAccountDetails.notes = statusNotes;
                updateSubAccount(subAccountDetails, bannerContext);
                setAnnuityBtnChangeStatus(false);
              }
            }}
            onClick={() => {
              subAccountDetails.acctStatus = accountStatus;
              subAccountDetails.notes = statusNotes;
              updateSubAccount(subAccountDetails, bannerContext);
              setAnnuityBtnChangeStatus(false);
            }}
            className={styles["modal-confirm-btn"]}
          >
            Confirm
          </button>
          <button
            type="button"
            onKeyPress={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                setAnnuityBtnChangeStatus(false);
              }
            }}
            onClick={() => {
              setAnnuityBtnChangeStatus(false);
            }}
            className={styles["modal-cancel-btn"]}
          >
            Cancel
          </button>
        </div>
      </div>
    </FocusTrap>
  );
};
