import * as React from 'react'
import { useFetchCampaignDetails } from '../../../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import { AsyncContainerUI } from '../../../CommonComponents/async-container-component/async-container-ui'
import {
  EditCampaignSideBarProps,
  EditCampaignSideBarUI,
} from './edit-campaign-side-bar-ui'

export type EditCampaignContainerProps = {
  campaignIdentifier: string
} & Pick<EditCampaignSideBarProps, 'closeSidebar'>

export const EditCampaignContainer = (props: EditCampaignContainerProps) => {
  const {
    isLoading: detailsLoading,
    error: detailsError,
    data,
  } = useFetchCampaignDetails(props.campaignIdentifier)

  return (
    <AsyncContainerUI
      error={detailsError}
      isLoading={detailsLoading}
      color="blue"
      errorLabel={'Error getting campaign details'}
      onBack={props.closeSidebar}
    >
      <EditCampaignSideBarUI
        closeSidebar={props.closeSidebar}
        campaignDetails={data?.data}
      />
    </AsyncContainerUI>
  )
}
