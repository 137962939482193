import * as React from 'react'
import { useSubmitPlayerCouponFiles } from '../../../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import { CouponCampaignPermissions } from '../../../../api/promotions-fetcher/permissions'
import { useRecurringAction } from '../../../../hooks/use-recurring-action'
import { CampaignBuilderContext } from '../../../../pages/CampaignsPages/campaign-builder-context'
import { userHasAnyOfPermissions } from '../../../../util/access-helpers'
import {
  CampaignSideBarPlayers,
  CampaignSideBarPlayersProps,
} from './campaign-side-bar-players-ui'

export type CampaignSideBarPlayersContainerProps = Pick<
  CampaignSideBarPlayersProps,
  'closeSideBar' | 'disabled' | 'campaignIdentifier' | 'barcodes'
>

export const CampaignSideBarPlayersContainer = (
  props: CampaignSideBarPlayersContainerProps
) => {
  const fileStatusesContext = React.useContext(CampaignBuilderContext)
  const { error, isLoading, requestSuccessful, submitPlayerFiles } =
    useSubmitPlayerCouponFiles()

  const addPlayerFiles = async (
    campaignIdentifier: string,
    playerFiles: File[]
  ) => {
    await submitPlayerFiles(campaignIdentifier, playerFiles)
    await fileStatusesContext.fetchPlayerFileStatuses()
  }

  const fetchFiles = async () => {
    await fileStatusesContext.fetchPlayerFileStatuses()
  }

  const campaignFileStatuses = fileStatusesContext.playerFilesStatusData?.get(
    props.campaignIdentifier ?? ''
  )
  useRecurringAction(
    fetchFiles,
    20000,
    [fileStatusesContext.playerFilesStatusData],
    Boolean(
      campaignFileStatuses &&
        campaignFileStatuses.find((file) => file.fileStatus === 'Pending')
    )
  )

  return (
    <CampaignSideBarPlayers
      {...props}
      disabled={
        props.disabled ||
        !userHasAnyOfPermissions([
          CouponCampaignPermissions.CAN_ASSIGN_PLAYER_COUPONS,
        ])
      }
      error={error}
      submissionLoading={isLoading}
      requestSuccessful={requestSuccessful}
      submitPlayerFiles={addPlayerFiles}
      fileStatus={fileStatusesContext.playerFilesStatusData?.get(
        props.campaignIdentifier ?? ''
      )}
      fileStatusLoading={fileStatusesContext.playerFilesStatusLoading}
      fileStatusError={fileStatusesContext.playerFilesStatusError}
      fileStatusSuccessful={
        fileStatusesContext.playerFilesStatusRequestSuccessful
      }
    />
  )
}
