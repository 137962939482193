import * as React from 'react'
import { GenericTableUI } from '../../../../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { ScanToEnterRuleProgressEvent } from '../../../../../../api/promotions-fetcher/constants'
import {
  mapScanToEnterProgressGameScanRow,
  scanToEnterProgressGameScanColumnMap,
} from '../helpers'
import './styles.scss'
import moment from 'moment'

type ScanToEnterProgressGameScansTableProps = {
  scans: ScanToEnterRuleProgressEvent[]
}

export const ScanToEnterProgressGameScansTable = (
  props: ScanToEnterProgressGameScansTableProps
) => (
  <GenericTableUI
    columnMap={scanToEnterProgressGameScanColumnMap}
    rowData={props.scans
      .sort((a, b) =>
        moment(a.applicationTimestamp).isBefore(b.applicationTimestamp) ? 1 : -1
      )
      .map(mapScanToEnterProgressGameScanRow)}
    customRowCellStyling={{ height: '48px' }}
    usePages
    rowsPerPage={6}
  />
)
