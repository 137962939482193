import React from "react";
import {keyPressedType} from "../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../CommonComponents/ErrorMessageFieldCommonComponent";
import styles from './css/styles.module.css';
import moment from 'moment';

export const TicketScanningActivityButtonsAndFieldsComponent = ({formik, setFiledValue, setRequestPerformed}: any) => {


    return (
        <>
            <form className={styles['page-container-body']}>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Start Date</div>
                    <input
                        autoComplete={"off"}
                        id="startDate"
                        name="startDate"
                        value={formik.values.startDate}
                        aria-label={`${formik.values.startDate}.`}

                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["page-container-body-left-input-box"]} ${styles["page-container-body-left-input-box-date"]}`}
                        type="date"/>
                   
                   {(formik.errors.startDate || formik.errors.outOfRangeDate) && <ErrorMessageFieldCommonComponent startDate={formik.errors.startDate} errorMessages={formik.errors.outOfRangeDate}/>}
                </div>

                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>End Date</div>
                    <input
                        autoComplete={"off"}
                        id="endDate"
                        name="endDate"
                        value={formik.values.endDate}
              aria-label={`${formik.values.endDate}.`}              
              onChange={(e: any) => setFiledValue(e)}
              className={`${styles["page-container-body-left-input-box"]} ${styles["page-container-body-left-input-box-date"]}`}
              type="date" />
               {formik.errors.endDate && <ErrorMessageFieldCommonComponent endDate={formik.errors.endDate}/>}
          </div>
          <div className={`${styles["top-ribbon-button-container"]} ${styles['column-3']}`}>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              style={{
                opacity: Object.keys(formik.errors).length > 0 || formik.values.endDate > new Date().toISOString().split("T")[0] ? "0.4" : "1",
                cursor: Object.keys(formik.errors).length > 0  || formik.values.endDate > new Date().toISOString().split("T")[0] ? "not-allowed" : "pointer",
              }}
              onClick={() =>{if(Object.keys(formik.errors).length === 0){
                setRequestPerformed(true);
                formik.submitForm();
              }} }
              onKeyPress={(e: any) =>{
                if(keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32)
                     if(Object.keys(formik.errors).length === 0){
                                setRequestPerformed(true);
                                formik.submitForm(); 
                     }
                }
              }
              className={styles["top-ribbon-save-button"]}
            >
              Filter
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              onKeyPress={(e: any) =>{
                if(keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32)
                    formik.resetForm();
                }
              }
              onClick={() => {
                formik.resetForm();
              }}
              className={styles["top-ribbon-cancel-button"]}
            >
              Reset Filter
            </div>
          </div>       
      </form>


    </>

  );
}