import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {keyPressedType} from "../../../../../services/Commons";
import {PaginationCommonComponent} from "../../../../CommonComponents/PaginationCommonComponent";

import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import {getPageData} from "../../../../../pages/CharitableGamingPages/PullTab/Inventory/PullTabInventoryPage/functions";
import {pulltabRoutePath} from "../../../../../config";
import {PullTabInventoryInterface} from "../../../../../pages/CharitableGamingPages/PullTab/Inventory/PullTabInventoryPage/interfaces";


export const PullTabInventorySearchResultComponent = ({LicenseeList, currentPage, setCurrentPage,}: any) => {

            const history = useHistory();
            const userpolicies: any = localStorage.getItem("userpolicies");

            const normalizePage = (totalPages: number, currentPage: number) => {
                const number: number = totalPages - currentPage * 10;
                if (number > 10) return currentPage * 10 + 10;
                else return totalPages;
            };

            return (
                <>

                    <div className={styles['table']}>
                        <div className={styles['table-data-header']}>
                            <div aria-live="assertive">{`Displaying ${LicenseeList.length <= 0 ? 0 :
                                (currentPage * 10) + 1}-${normalizePage(LicenseeList.length, currentPage)} 
                        of ${LicenseeList.length} results`}</div>
                        </div>

                        <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                            <div>Stock No.</div>
                            <div>Warehouse</div>
                            <div>Qty of Packets</div>
                        </div>

                        <div className={styles['table-data-body']}>
                            {LicenseeList.length > 0 &&
                            getPageData(LicenseeList, currentPage).map((e: PullTabInventoryInterface, i: number) =>

                                <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                     className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                    <div className={styles['table-data-data-text']}>
                                        {e.stockNumber}
                                    </div>
                                    <div className={styles['table-data-data-text']}>
                                        {e.warehouseId}
                                    </div>
                                    <div className={styles['table-data-data-text']}>
                                        {e.numPackets}
                                    </div>
                                    <div className={styles['table-details-button-container']}>
                                        {checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_STOCK_INVENTORY, JSON.parse(userpolicies)) ?
                                            <div tabIndex={0} role='button' aria-pressed={"false"}
                                                 onKeyDown={(en: any) => {
                                                     if (
                                                         keyPressedType(en) === 13 ||
                                                         keyPressedType(en) === 0 ||
                                                         keyPressedType(en) === 32
                                                     ) {
                                                         history.push(`${pulltabRoutePath}/inventory/stock/${e.stockNumber}`);
                                                     }
                                                 }}
                                                 onClick={() => {
                                                     // alert('hi')
                                                     history.push(`${pulltabRoutePath}/inventory/stock/${e.stockNumber}`);
                                                 }}
                                                 className={styles['table-details-button']}>
                                                <div aria-label={'Licensee Details Button.'}
                                                     className={styles['table-details-button-text']}>
                                                    Details
                                                </div>
                                            </div> : null}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles['table-data-footer']}>

                            <div aria-live="assertive"
                                 className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((LicenseeList.length / 10)).toString()}`}</div>
                            {<PaginationCommonComponent playerData={LicenseeList} currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}/>}

                        </div>
                    </div>


                </>
            );
};
