import * as React from 'react'
import FocusLock from 'react-focus-lock'
import { Button } from 'react-bootstrap'
import { AccessibleKey } from '../common'
import { useKeyDown } from '../../hooks/use-keydown/use-keydown'
import { ExitButtonIcon } from '../../icons/exit-button-icon'
import './styles.scss'

export type GenericPopupModalProps = {
  isOpen: boolean
  closeModal: () => void
  roundedEdges?: boolean
  hideCloseButton?: boolean
}

const ROOT_CLASS = 'generic-popup-modal'

const getVisibilityClass = (isOpen: boolean) =>
  isOpen ? `${ROOT_CLASS}--open` : `${ROOT_CLASS}--closed`

export const GenericPopupModal = (
  props: React.PropsWithChildren<GenericPopupModalProps>
) => {
  const roundedEdgesClass = props.roundedEdges
    ? `${ROOT_CLASS}--rounded-edges`
    : ''

  const handleKeyDown = () => {
    if (props.isOpen) {
      props.closeModal()
    }
  }
  useKeyDown({
    key: AccessibleKey.escape,
    action: handleKeyDown,
    dependencies: [props.isOpen],
  })

  return (
    <>
      {props.isOpen && (
        <div
          className={`${ROOT_CLASS}__overlay`}
          onClick={() => props.closeModal()}
        />
      )}
      <FocusLock disabled={!props.isOpen} autoFocus={false}>
        <div
          className={`${ROOT_CLASS} ${getVisibilityClass(
            props.isOpen
          )} ${roundedEdgesClass}`}
        >
          {!props.hideCloseButton && (
            <Button
              className={`${ROOT_CLASS}__close-button`}
              aria-label="Close"
              onClick={() => props.closeModal()}
            >
              <ExitButtonIcon />
            </Button>
          )}
          <div className={`${ROOT_CLASS}__content`}>{props.children}</div>
        </div>
      </FocusLock>
    </>
  )
}
