import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { GetPendingReissuePaymentsResponse } from '../../../api/pending-reissue-payments-fetcher/api-types'

const getPendingReissuesUrl = `${config.SERVER_BASE_URL}/api/v1/payments/reissue/pending`

export const getFetchPendingReissues = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<GetPendingReissuePaymentsResponse>()
  const fetchPendingReissues = () => {
    fetchUrl(getPendingReissuesUrl, 'GET')
  }

  return {
    isLoading,
    error,
    data,
    fetchPendingReissues
  }
}