import * as React from 'react'

type IconInfoHollowProps = {
  altText?: string
}

export const IconInfoHollow = (props: IconInfoHollowProps) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{props.altText ?? 'Info Icon'}</title>
    <g id="hollow-info">
      <path
        id="Stroke 1 Copy 9"
        d="M8.70224 0.0304856C13.1046 0.412928 16.3563 4.2923 15.9686 8.69225C15.581 13.0923 11.7003 16.352 7.29787 15.9695C5.27677 15.7939 3.43854 14.8719 2.09724 13.4064C1.79797 13.0794 1.8223 12.5735 2.15159 12.2763C2.48087 11.9792 2.99041 12.0033 3.28967 12.3303C4.36125 13.5011 5.8254 14.2355 7.43831 14.3756C10.9513 14.6808 14.0531 12.0754 14.3634 8.5528C14.6738 5.03031 12.0747 1.92956 8.5618 1.62439C5.04885 1.31921 1.94686 3.92472 1.6365 7.44717C1.5891 7.98515 1.60893 8.51964 1.69379 9.04218C1.76462 9.47837 1.46593 9.88899 1.02665 9.95932C0.58737 10.0297 0.173838 9.73308 0.103003 9.29689C-0.00296984 8.64433 -0.0277094 7.97746 0.0313 7.30773C0.418972 2.90781 4.29989 -0.351959 8.70224 0.0304856Z"
        fill="white"
      />
      <path
        id="Path"
        d="M8.25 10C8.52614 10 8.75 9.77614 8.75 9.5V4.5C8.75 4.22386 8.52614 4 8.25 4H7.75C7.47386 4 7.25 4.22386 7.25 4.5V9.5C7.25 9.77614 7.47386 10 7.75 10H8.25Z"
        fill="white"
      />
      <path
        id="Path_2"
        d="M8.25 12.5C8.52614 12.5 8.75 12.2761 8.75 12V11.5C8.75 11.2239 8.52614 11 8.25 11H7.75C7.47386 11 7.25 11.2239 7.25 11.5V12C7.25 12.2761 7.47386 12.5 7.75 12.5H8.25Z"
        fill="white"
      />
    </g>
  </svg>
)
