import React from 'react'
import styles from './css/styles.module.css'

type CheckboxComponentProps = {
  isChecked: boolean
  disabled?: boolean
  color?: 'black' | 'white'
}

export const CheckboxComponent = (props: CheckboxComponentProps) => {
  const srcColor = props.color ?? 'white'
  const srcFilledCheckbox = `/icons/icon_checkbox_filled_${srcColor}.svg`
  const srcEmptyCheckbox = `/icons/icon_checkbox_empty_${srcColor}.svg`

  return (
    <div
      className={styles['checkbox-container']}
      style={{
        cursor: props.disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <img
        alt="checkbox-img"
        className={styles['checkbox-img']}
        src={props.isChecked ? srcFilledCheckbox : srcEmptyCheckbox}
      />
    </div>
  )
}
