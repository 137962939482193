import FuzzySearch from "fuzzy-search";
import config from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";

let charitableGamingStore: any;
let unfilteredLicensees: any;
let setUnfilteredLicensees: any;
let filteredLicensees: any;
let setFilteredLicensees: any;
let setCurrentPage: any;
let setPageMessage: any;
let setShowErrorDialog: any;

export const setVariables = (charitableGamingStoreObj: any, unfilteredLicenseesObj: any, setUnfilteredLicenseesObj: any,
                             filteredLicenseesObj: any, setFilteredLicenseesObj: any, setCurrentPageObj: any,
                             setPageMessageObj: any, setShowErrorDialogObj: any) => {

    charitableGamingStore = charitableGamingStoreObj;
    unfilteredLicensees = unfilteredLicenseesObj;
    setUnfilteredLicensees = setUnfilteredLicenseesObj;
    filteredLicensees = filteredLicenseesObj;
    setFilteredLicensees = setFilteredLicenseesObj;
    setCurrentPage = setCurrentPageObj;
    setPageMessage = setPageMessageObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const setFieldValue = (e: any, formik: any) => {

    if (e.target.name === "licenseNumber") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("orgName", "", true);
        formik.setFieldValue("city", "", true);
    } else if (e.target.name === "orgName") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("licenseNumber", "", true);
        formik.setFieldValue("city", "", true);
    } else if (e.target.name === "city") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue("licenseNumber", "", true);
        formik.setFieldValue("orgName", "", true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    searchLicensees(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            licenseNumber: '',
            orgName: '',
            city: ''
        }
    });

    resetFilteredLicensees();

}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

export const getAllActiveLicensees = async (setRequestCompleted: any) => {

    try {

        const body = {
            "filterActiveLicensees": true,
            "filterNonRenewing": false
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/licensee/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        // Deep copy
        setUnfilteredLicensees(JSON.parse(JSON.stringify(response.licensees)));

        // Filter response.licensees for Active licensees amd then assign to this.filteredLicensees
        //filteredLicensees = response.licensees.filter((licensee: LicenseeInterface) => licensee.licenseDetails.active === true);
        //setFilteredLicensees(filteredLicensees);
        setFilteredLicensees(JSON.parse(JSON.stringify(response.licensees)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

const searchLicensees = (values: any) => {

    setCurrentPage(0);

    let searcher;
    let key;
    let licenses = JSON.parse(JSON.stringify(unfilteredLicensees));;

    if (!values.licenseNumber && !values.orgName && !values.city) {
        filteredLicensees = JSON.parse(JSON.stringify(licenses))
        setFilteredLicensees(filteredLicensees);
    } else {

        // Search
        if (values.licenseNumber) {
            searcher = new FuzzySearch(licenses, ['licenseDetails.licenseNumber'], {
                caseSensitive: false, sort: true
            });
            key = values.licenseNumber;
        } else if (values.orgName) {
            searcher = new FuzzySearch(licenses, ['orgDetails.orgName'], {
                caseSensitive: false, sort: true
            });
            key = values.orgName;
        } else if (values.city) {
            searcher = new FuzzySearch(licenses, ['licenseDetails.occasionAddress.city'], {
                caseSensitive: false, sort: true
            });
            key = values.city;
        }

        filteredLicensees = searcher.search(key);
        setFilteredLicensees(filteredLicensees);
    }

}

const resetFilteredLicensees = () => {
    setFilteredLicensees(JSON.parse(JSON.stringify(unfilteredLicensees)));
}
