import {UpdateLicenseSuccessMessage} from "../Common/UpdateLicenseSuccessMessage";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {licenseeRoutePath} from "../../../../config.js";

let licenseNumber: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === 'licenseDetails.memberInCharge.address.zip') {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value, true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.licenseDetails.memberInCharge.fullName) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {fullName: InputValidationMessages.RequiredField}}});
    }

    if (!values.licenseDetails.memberInCharge.homePhone) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {homePhone: InputValidationMessages.RequiredField}}});
    } else if (values.licenseDetails.memberInCharge.homePhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.memberInCharge.homePhone)) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {homePhone: InputValidationMessages.InvalidTelephone}}});
    }

    if (!values.licenseDetails.memberInCharge.address.streetAddress) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {address: {streetAddress: InputValidationMessages.RequiredField}}}});
    }

    if (!values.licenseDetails.memberInCharge.businessPhone) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {businessPhone: InputValidationMessages.RequiredField}}});
    } else if (values.licenseDetails.memberInCharge.businessPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.memberInCharge.businessPhone)) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {businessPhone: InputValidationMessages.InvalidTelephone}}});
    }

    if (!values.licenseDetails.memberInCharge.address.city) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {address: {city: InputValidationMessages.RequiredField}}}});
    }

    if (!values.licenseDetails.memberInCharge.address.zip) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {address: {zip: InputValidationMessages.RequiredField}}}});
    } else if (values.licenseDetails.memberInCharge.address.zip && values.licenseDetails.memberInCharge.address.zip.length !== 5) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {address: {zip: InputValidationMessages.InvalidZip}}}});
    }

    if (!values.licenseDetails.memberInCharge.email) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {email: InputValidationMessages.RequiredField}}});
    } else if (values.licenseDetails.memberInCharge.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.licenseDetails.memberInCharge.email)) {
        Object.assign(errors, {licenseDetails: {memberInCharge: {email: InputValidationMessages.InvalidEmail}}});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updateLicense(values, UpdateLicenseSuccessMessage.MEMBER_IN_CHARGE, history);
}

export const YesSave = function () {
    formik.submitForm();
}


export const YesCancel = function () {

    // history.goBack();

    history.push({
        pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
        state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
    });
}


