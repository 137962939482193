import * as React from 'react'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import {
  AllPromotionsResponse,
  PromotionCampaignStatusOrder,
  PromotionResponse,
} from '../../../../api/promotions-fetcher/constants'
import {
  mapPromotionToRow,
  winnersManagementTableColumnMapping,
} from '../../../../pages/PromotionsPages/winners-management-landing-page/helpers'
import { getBlockClass } from '../../../../utilities/helpers'
import { AsyncContainerUI } from '../../../CommonComponents/async-container-component/async-container-ui'
import { GenericTableUI } from '../../../CommonComponents/generic-table-components/generic-table-ui'
import './styles.scss'

type WinnersManagementPromotionListProps = {
  promotionsLoading: boolean
  promotions?: AllPromotionsResponse
  promotionsError?: string
}

const ROOT_CLASS = 'winners-management-promotion-list'

export const WinnersManagementPromotionListUI = (
  props: WinnersManagementPromotionListProps
) => {
  const sortedData = sortBy(props.promotions ?? [], [
    (item: PromotionResponse) => PromotionCampaignStatusOrder[item.status],
    (item: PromotionResponse) => moment(item.startDate),
  ])
  const mappedData = sortedData.map(mapPromotionToRow)
  return (
    <div className={ROOT_CLASS}>
      <AsyncContainerUI isLoading={props.promotionsLoading} color="white">
        {props.promotions && props.promotions.length > 0 ? (
          <GenericTableUI
            columnMap={winnersManagementTableColumnMapping}
            rowData={mappedData}
            usePages
            rowsPerPage={10}
            customRowCellStyling={{ padding: '0 16px', height: '60px' }}
            customHeaderCellStyling={{ paddingLeft: '16px' }}
          />
        ) : (
          <span className={getBlockClass(ROOT_CLASS, 'empty-message')}>
            No active promotions at this time
          </span>
        )}
      </AsyncContainerUI>
    </div>
  )
}
