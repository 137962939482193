export interface PullTabOrderOverviewHistoryInterface {
    additionalResults: boolean,
    records: {
        purchaseOrderId: string, // Purchase Order No.
        date: string, // Order Date
        numUnits: string, // Quantity
        totalPricePaidInCents: number, // Order Amount
        summedProductPriceInCents: number, // Stock Amount
        shippingPriceInCents: number, // Ship Amount
        shipMethod: string, // Ship Method
        paymentMethod: string, // Payment Method
        licenseNumber: string, // License Number
        orgName: string // Org. Name
        warehouseId: string // Warehouse Id
    }[]
}

export const PullTabOrderOverviewHistoryDefaultData = {
    additionalResults: false,
    records: [{
        purchaseOrderId: '', // Purchase Order No.
        date: '', // Order Date
        numUnits: '', // Quantity
        totalPricePaidInCents: 0, // Order Amount
        summedProductPriceInCents: 0, // Stock Amount
        shippingPriceInCents: 0, // Ship Amount
        shipMethod: '', // Ship Method
        paymentMethod: '', // Payment Method
        licenseNumber: '', // License Number
        orgName: '', // Org. Name
        warehouseId: '' // Warehouse Id
    }]
}


export interface PullTabOrderItemHistoryInterface {
    additionalResults: boolean,
    records: {
        purchaseOrderId: string, // Purchase Order No.
        date: string, // Order Date
        stockNumber: string, // Stock#
        serialNumbers: string, // Serial Numbers
        numUnits: string, // Quantity
        summedProductPriceInCents: number, // Stock Amount
        warehouseId: string,
        licenseNumber: string,
        orgName: string  // Org. Name
    }[]
}

export const PullTabOrderItemHistoryDefaultData = {
    additionalResults: false,
    records: [{
        purchaseOrderId: '', // Purchase Order No.
        date: '', // Order Date
        stockNumber: '', // Stock#
        serialNumbers: '', // Serial Numbers
        numUnits: '', // Quantity
        summedProductPriceInCents: 0, // Stock Amount
        warehouseId: '',
        licenseNumber: '',
        orgName: ''  // Org. Name
    }]
}
