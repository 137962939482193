import { CircularProgress } from "@material-ui/core";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { PaymentTableCmpt } from "../../../../components/AnnuitiesComponents/TimePaymentAuthorization/Common/PaymentTableCmpt/";
import { annuitiesRoutePath } from "../../../../config.js";
import { KEY_CODES } from "../../../../constants.js";
import { BannerContext } from "../../../../context/BannerContext";
import { keyPressedType } from "../../../../services/Commons";
import { PaymentResponse } from "../../../../types/Annuities/TimePaymentAuthorization/PaymentResponse.js";
import { CheckRun } from "../../../../types/Annuities/entities/check-run.js";
import styles from "./css/styles.module.css";
import { exportToCsv, getCheckRunData, getPaymentList } from "./functions";

export const PaymentListPage = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checkRunId: string | null = searchParams.get("id");
  const bannerContext = useContext(BannerContext);

  const initialCheckRunData = {
    throughDate: "Loading...",
    id: "Loading...",
    createdOn: "Loading...",
  };

  // CSV Options
  const csvOptions = {
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: `PaymentListExport`,
    showTitle: false,
    headers: [
      "Document ID",
      "Name",
      "TIN",
      "Cadence",
      "Anniversary Date",
      "Payment Date",
      "Gross Payment",
      "Federal Tax",
      "State Tax",
      "Net Payment",
    ],
  };

  const csvExporter: ExportToCsv = new ExportToCsv(csvOptions);

  const [paymentListData, setPaymentListData] = useState<PaymentResponse[]>([]);
  const [checkRunData, setCheckRunData] =
    useState<CheckRun>(initialCheckRunData);
  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);

  useEffect(() => {
    const fetchCheckRunData = async (checkRunId: string | null) => {
      if (checkRunId === null || checkRunId === "") {
        setRequestCompleted(true);
        bannerContext.setBannerInfo({
          message: "Authorization ID is required to populate a Payment List",
          error: true,
        });
      } else {
        try {
          const checkRunData: CheckRun = await getCheckRunData(checkRunId);
          setCheckRunData(checkRunData);
          setRequestCompleted(true);
        } catch (error) {
          setRequestCompleted(true);
          bannerContext.setBannerInfo({ message: `${error}`, error: true });
        }
      }
    };

    const fetchPaymentList = async (checkRunId: string | null) => {
      if (checkRunId === null || checkRunId === "") {
        setRequestCompleted(true);
        bannerContext.setBannerInfo({
          message: "Authorization ID is required to populate a Payment List",
          error: true,
        });
      } else {
        try {
          const paymentList: PaymentResponse[] = await getPaymentList(
            checkRunId
          );
          setPaymentListData(paymentList);
          setRequestCompleted(true);
        } catch (error) {
          setRequestCompleted(true);
          bannerContext.setBannerInfo({ message: `${error}`, error: true });
        }
      }
    };

    fetchCheckRunData(checkRunId);
    fetchPaymentList(checkRunId);
  }, []);

  return (
    <>
      {!requestCompleted && (
        <div
          style={{
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.3)",
            color: "white",
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}
      <div className={styles["page-container"]}>
        {/* Back Btn tsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                history.push(
                  `${annuitiesRoutePath}/time-payment-authorization-history`
                );
              }
            }}
            onClick={() => {
              history.push(
                `${annuitiesRoutePath}/time-payment-authorization-history`
              );
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles["back-btn-custom"]}
            />
            <div className={styles["back-btn-text"]}>
              Back to Authorization History
            </div>
          </button>
        </div>
        {/* Header */}
        <div className={styles["cmpnt-container"]}>
          <div className={`${styles.title_header}`}>
            <div>
              <div className={`${styles.title_text}`}>Payment List</div>
              <div>
                Date Submitted:{" "}
                {moment(checkRunData.throughDate).format("MM/DD/YYYY")}
              </div>
            </div>
            <div>
              {/* Export CSV */}
              <button
                type="button"
                className={
                  paymentListData.length > 0
                    ? `${styles.dark_btn}`
                    : `${styles.dark_btn_disabled}`
                }
                disabled={paymentListData.length > 0 ? false : true}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    exportToCsv(paymentListData, csvExporter);
                  }
                }}
                onClick={() => {
                  exportToCsv(paymentListData, csvExporter);
                }}
              >
                Export
              </button>
            </div>
          </div>

          {/* Main Component */}
          <div className={styles["cmpnt-container"]}>
            <PaymentTableCmpt
              paymentListData={paymentListData}
              fromPage={"PaymentList"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
