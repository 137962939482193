import config from "../../../../config";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {ChargeDetailsInterface} from "./interfaces";

let chargeId: string;
let charitableGamingStore: any;
let setRequestCompleted: any;
let setShowErrorDialog: any;
let selectedCharge: ChargeDetailsInterface;
let setSelectedCharge: any;

export const setVariables = (chargeIdString: string, charitableGamingStoreObj: any, setRequestCompletedObj: any,
                             setShowErrorDialogObj: any, selectedChargeObj: ChargeDetailsInterface, setSelectedChargeObj: any) => {
    chargeId = chargeIdString;
    charitableGamingStore = charitableGamingStoreObj;
    setRequestCompleted = setRequestCompletedObj;
    setShowErrorDialog = setShowErrorDialogObj;
    selectedCharge = selectedChargeObj;
    setSelectedCharge = setSelectedChargeObj;
}

export const getChargeDetails = async () => {

    try {

        const options = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/charge/${chargeId}?orgIdentifierType=raffleId`,
        }

        let response = await makeHttpCall(options);

        setRequestCompleted(true);

        setSelectedCharge(response);

    } catch (e) {
        setRequestCompleted(true);
        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Error,
            text: 'There was a network error. Please try again later.'
        });
    }

}

export const settleCharge = async (chargeId: any) => {

    // alert(JSON.stringify(chargeIds));

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        chargeIds: [chargeId]
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'PATCH',
            url: `${config.SERVER_BASE_URL}/api/v1/charge/settled`,
            data: body
        }

        let response = await makeHttpCall(options);

        let selectedChargeUpdated = JSON.parse(JSON.stringify(selectedCharge));
        selectedCharge.checkReceived = !selectedCharge.checkReceived;
        selectedCharge.status = 'SETTLED';
        setSelectedCharge(selectedCharge);

        setRequestCompleted(true);

        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The charge status was successfully updated.'
        });

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        //setShowErrorDialog(true);
        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Error,
            text: 'There was a network error. Please try again later.'
        });
    }

}

export const rejectCharge = async (chargeId: any) => {

    // alert(JSON.stringify(chargeIds));

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        chargeIds: [chargeId]
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'PATCH',
            url: `${config.SERVER_BASE_URL}/api/v1/charge/rejected`,
            data: body
        }

        let response = await makeHttpCall(options);

        let selectedChargeUpdated = JSON.parse(JSON.stringify(selectedCharge));
        selectedCharge.checkReceived = !selectedCharge.checkReceived;
        selectedCharge.status = 'REJECTED';
        setSelectedCharge(selectedCharge);

        setRequestCompleted(true);

        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The charge status was successfully updated.'
        });

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        //setShowErrorDialog(true);
        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Error,
            text: 'There was a network error. Please try again later.'
        });
    }

}



