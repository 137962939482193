import React from "react";
import styles from './css/styles.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {denied, success} from "../../../services/Icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const PlayerAccountStatusInfoMessageComponent = () => {

    return (
        <>
            <div tabIndex={0} className={styles['message-container']}>
                <div className={styles['message-carret']}>
                    <FontAwesomeIcon icon={faCaretUp as IconProp}/>
                </div>
                <div role="alert" tabIndex={0} aria-live={"assertive"} className={styles['message-text-container']}>
                    <div style={{marginTop: ".7em"}} className={styles['pop-up-status-message']}>
                        <div className={styles['header-row']}>
                            <div className={styles['header1']}>Account Status</div>
                        </div>
                        <div className={styles['description']}>This indicates whether a player's account
                            is <b>Closed</b> or <b>Open</b>. See below for more details.
                        </div>
                    </div>
                    <div style={{marginTop: ".7em"}} className={styles['pop-up-status-message']}>
                        <div className={styles['header-row']}>
                            <img alt={"Denied icon"} style={{width: '1em', height: '1em'}} src={denied}/>
                            <div className={styles['header']}>Closed Account</div>
                        </div>
                        <div className={styles['description']}>This means a player's account has been deactivated and
                            they cannot sign back in.
                        </div>
                    </div>
                    <div style={{marginBottom: ".7em"}} className={styles['pop-up-status-message']}>
                        <div className={styles['header-row']}>
                            <img alt="approved icon" style={{width: '1em', height: '1em'}} src={success}/>
                            <div className={styles['header']}>Open Account</div>
                        </div>
                        <div className={styles['description']}>This means a player's account is open and they can sign
                            in and access their account normally
                        </div>
                    </div>
                </div>

            </div>

        </>

    );
}