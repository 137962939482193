import React from 'react'
import { PromotionResponse } from '../../../../../api/promotions-fetcher/constants'
import { PromotionCampaignScheduleDrawingUI } from './promotion-campaign-schedule-drawing-ui'
import { useFetchPutSchedulePromotionDrawing } from '../../../../../api/promotions-fetcher/use-fetch-base-promotions'

export type PromotionCampaignScheduleDrawingContainerProps = {
  closeModal: () => void
  promotion: PromotionResponse
  fetchAllPromotions: () => Promise<void>
}

export const PromotionCampaignScheduleDrawingContainer = (
  props: PromotionCampaignScheduleDrawingContainerProps
) => {
  const { isLoading, error, requestSuccessful, schedulePromotionDrawing } =
    useFetchPutSchedulePromotionDrawing()
  return (
    <PromotionCampaignScheduleDrawingUI
      closeModal={props.closeModal}
      error={error}
      loading={isLoading}
      actionSuccessful={requestSuccessful}
      onSuccess={props.fetchAllPromotions}
      scheduleDrawing={schedulePromotionDrawing}
      promotion={props.promotion}
    />
  )
}
