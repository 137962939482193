import * as React from 'react'
import { CampaignData } from '../../../api/promotions-fetcher/coupon-campaigns/constants'
import { PaginationCommonComponent } from '../../CommonComponents/PaginationCommonComponent'
import './styles.scss'

export type CampaignsListFooterUIProps = {
  campaigns: CampaignData[]
  currentPage: number
  setCurrentPage: (pageNumber: number) => void
}

const ROOT_CLASS = 'campaigns-list-footer'
export const CAMPAIGNS_PER_PAGE = 6

const getTotalPages = (dataLength: number): number =>
  dataLength > 0 ? Math.ceil(dataLength / CAMPAIGNS_PER_PAGE) : 1

export const CampaignsListFooterUI = (props: CampaignsListFooterUIProps) => (
  <div className={`${ROOT_CLASS}`}>
    <div tabIndex={0} className={`${ROOT_CLASS}__page-label`}>{`Page ${
      props.currentPage + 1
    } of ${getTotalPages(props.campaigns.length)}`}</div>
    <PaginationCommonComponent
      playerData={props.campaigns}
      currentPage={props.currentPage}
      setCurrentPage={props.setCurrentPage}
      rowsPerPage={CAMPAIGNS_PER_PAGE}
    />
  </div>
)
