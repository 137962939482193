import * as React from 'react'
import { CheckRunQueuePageUI } from './check-run-queue-page-ui'
import { getFetchInitiateCheckRun } from './get-fetch-initiate-check-run'
import { getFetchPendingPayments } from './get-fetch-pending-payments'
import { useFetchSetTaxAdjustmentStatus } from './use-fetch-set-tax-adjustment-status'
import { BannerContext } from '../../../context/BannerContext'
import { TaxAdjustmentFetchesContext } from '../../../context/TaxAdjustmentFetchesContext'

export const CheckRunQueuePageContainer = () => {
  const {
    isLoading: pendingPaymentsLoading,
    error: pendingPaymentsError,
    data: pendingPaymentsData,
    fetchPendingPayments,
  } = getFetchPendingPayments()
  const {
    isLoading: initiateCheckRunLoading,
    error: initiateCheckRunError,
    fetchInitiateCheckRun,
  } = getFetchInitiateCheckRun(fetchPendingPayments)
  const {
    isLoading: taxAdjustmentStatusLoading,
    error: taxAdjustmentStatusError,
    setStatus
  } = useFetchSetTaxAdjustmentStatus()

  const bannerContext = React.useContext(BannerContext)
  React.useEffect(() => {
    if(!taxAdjustmentStatusLoading) {
      if(taxAdjustmentStatusError) {
        bannerContext.setBannerInfo({message: 'Error updating tax adjustment status', error: true})
      }
      // after submitting tax adjustment status change (and on initial page load), reload pending payments
      fetchPendingPayments()
    }
    return () => {}
  }, [taxAdjustmentStatusLoading, taxAdjustmentStatusError])

  const isLoading = pendingPaymentsLoading || initiateCheckRunLoading
  const fetchesContextValue: TaxAdjustmentFetchesContext = {
    setTaxAdjustmentStatus: setStatus
  }

  return (
    <TaxAdjustmentFetchesContext.Provider value={fetchesContextValue}>
      <CheckRunQueuePageUI
        isLoading={isLoading}
        error={pendingPaymentsError}
        pendingPaymentsData={pendingPaymentsData}
        fetchInitiateCheckRun={fetchInitiateCheckRun}
        initiateCheckRunError={initiateCheckRunError}
        taxAdjustmentStatusLoading={taxAdjustmentStatusLoading}
      />
    </TaxAdjustmentFetchesContext.Provider>
  )
}
