import { makeHttpCall } from '../../../services/MakeHttpCall'
import {
  getPlayersDetailsData,
  testPlayerStoreObjectData,
} from '../../../services/StoresObject'
import config from './../../../config.js'
import { PlayerSearchCriteria } from './interfaces'
import { checkRenderPermissions } from '../../../helpers'
import { permissions } from '../../../constants'

let formik: any
let playerStore: any
let history: any
let pageMessageDefault: any
let setPageMessageType: any
let setButtonClicked: any
export const setVariables = (
  formikObj: any,
  playerStoreObject: any,
  historyObject: any,
  pageMessageDefaultObj: any,
  setPageMessageObj: any,
  setButtonClickedObj: any
) => {
  formik = formikObj
  playerStore = playerStoreObject
  history = historyObject
  pageMessageDefault = pageMessageDefaultObj
  setPageMessageType = setPageMessageObj
  setButtonClicked = setButtonClickedObj
}

export const refreshComponent = (response: any) => {
  if (!(response.error === undefined)) {
    setPageMessageType({
      message: 'Something went wrong. Please try again.',
      messageType: 'ERROR',
    })
  } else {
    formik.initialValues = response
    formik.setValues(response, false)
  }
}

export const onSubmit = async (values: PlayerSearchCriteria) => {}

export const doPlayerSearchWithName = async (
  firstName: string,
  lastName: string,
  phone: string,
  setPlayerSearchAPIResponse: any,
  setCurrentPage: any
) => {
  setCurrentPage(0)
  if (Object.keys(formik.errors).length <= 0) {
    let queryParams = '?'
    if (firstName && firstName !== '') {
      queryParams += `firstName=${firstName}`
    }
    if (lastName && lastName !== '') {
      if (queryParams.endsWith('?')) queryParams += `lastName=${lastName}`
      else queryParams += `&lastName=${lastName}`
    }
    if (phone && phone !== '') {
      if (queryParams.endsWith('?')) queryParams += `phone=${phone}`
      else queryParams += `&phone=${phone}`
    }

    try {
      const options = {
        method: 'get',
        url: `${config.SERVER_BASE_URL}/v1/player/search${queryParams}`,
      }
      const response = await makeHttpCall(options)
      const normalizeData = []
      for (let i = 0; i < response.data.length; i++) {
        const dummyPlayerData: any = JSON.parse(JSON.stringify(getPlayersDetailsData))

        let identity: any = dummyPlayerData.identity
        let account: any = dummyPlayerData.account
        Object.keys(response.data[i]).forEach((key: any) => {
          identity[key] = response.data[i][key]
          account[key] = response.data[i][key]
        })
        account['email'] = response.data[i]['email']
        normalizeData.push(JSON.parse(JSON.stringify(testPlayerStoreObjectData(dummyPlayerData))))
      }
      setPlayerSearchAPIResponse(normalizeData)
      if (response.data.length === 0) {
        setPageMessageType({
          message: 'No results have been found matching the search criteria.',
          messageType: 'SUCCESS',
        })
      }
      playerStore.setPlayerSearchResults(
        '',
        '',
        firstName,
        lastName,
        phone,
        '',
        normalizeData,
        0
      )
      setButtonClicked(false)
    } catch (e) {
      const er: any = e
      if (
        er
          .toString()
          .split(' ')
          .find((value: any, i: any) => {
            console.log('value', value)
            if (value === 'Network') return value
          }) !== undefined
      ) {
        setPageMessageType({
          message: 'Something went wrong. Network Error. Please try again.',
          messageType: 'ERROR',
        })
      } else
        setPageMessageType({
          message: 'Something went wrong. Please try again.',
          messageType: 'ERROR',
        })
      setButtonClicked(false)
      setPlayerSearchAPIResponse([])
    }
  } else {
    setButtonClicked(false)
  }
}

export const doPlayerSearchWithIdOrEmail = async (
  searchTerm: string,
  setPlayerSearchAPIResponse: any,
  setCurrentPage: any,
  searchType: 'email' | 'id'
) => {
  setCurrentPage(0)

  if (Object.keys(formik.errors).length <= 0) {
    try {
      const userpolicies: any = localStorage.getItem('userpolicies')
      const maskSsn = !checkRenderPermissions(
        permissions.CAN_SEE_SSN,
        JSON.parse(userpolicies)
      )
      let queryParam = `?maskSsn=${maskSsn}`

      const options = {
        method: 'get',
        url: `${config.SERVER_BASE_URL}/v1/player/${searchTerm}${queryParam}`,
      }

      const response = await makeHttpCall(options)
      const parsedResponse = [JSON.parse(JSON.stringify(testPlayerStoreObjectData(response)))]
      setPlayerSearchAPIResponse(parsedResponse)
      if (searchType === 'email') {
        playerStore.setPlayerSearchResults(
          searchTerm,
          '',
          '',
          '',
          '',
          '',
          parsedResponse,
          0
        )
      } else {
        playerStore.setPlayerSearchResults(
          '',
          searchTerm,
          '',
          '',
          '',
          '',
          parsedResponse,
          0
        )
      }
      setButtonClicked(false)
    } catch (e) {
      const er: any = e
      if (
        er
          .toString()
          .split(' ')
          .find((value: any, i: any) => {
            if (value === 'Network') return value
          }) !== undefined
      ) {
        setPageMessageType({
          message: 'Something went wrong.Network Error. Please try again.',
          messageType: 'ERROR',
        })
      } else if (er.toString().includes('404')) {
        setPageMessageType({
          message: 'No results have been found matching the search criteria.',
          messageType: 'SUCCESS',
        })
      } else {
        setPageMessageType({
          message: 'Something went wrong. Please try again.',
          messageType: 'ERROR',
        })
      }
      setPlayerSearchAPIResponse([])
      setButtonClicked(false)
    }
  } else {
    setButtonClicked(false)
  }
}

export const doPlayerSearchWithSSN = async (
  ssn: string,
  setPlayerSearchAPIResponse: any,
  setCurrentPage: any
) => {
  setCurrentPage(0)
  if (Object.keys(formik.errors).length <= 0) {
    try {
      const body = { ssn: ssn }
      const options = {
        method: 'POST',
        url: `${config.SERVER_BASE_URL}/v1/player/ssn-search`,
        data: body,
      }
      const response = await makeHttpCall(options)

      if (response.hasOwnProperty('data') && response.data.length > 0) {
        // Create a player object because the JSON shape for ssn-search API is different than player search by Id/Email
        const normalizeData = []
        let player = {}
        for (let i = 0; i < response.data.length; i++) {
          // console.log("current record - " + JSON.stringify(response.data[i]))
          player = {
            identity: {
              playerId: response.data[i].playerId,
              firstName: response.data[i].firstName,
              lastName: response.data[i].lastName,
              status: response.data[i].status,
              identityCheckResult: response.data[i].identityCheckResult,
            },
            account: {
              email: response.data[i].email,
            },
          }
          normalizeData.push(JSON.parse(JSON.stringify(testPlayerStoreObjectData(player))))
        }
        setPlayerSearchAPIResponse(normalizeData)
        playerStore.setPlayerSearchResults(
          '',
          '',
          '',
          '',
          '',
          ssn,
          normalizeData,
          0
        )
      } else if (response.hasOwnProperty('data') && response.data.length <= 0) {
        setPageMessageType({
          message: 'No results have been found matching the search criteria.',
          messageType: 'SUCCESS',
        })
      }
      setButtonClicked(false)
    } catch (e) {
      const er: any = e
      if (
        er
          .toString()
          .split(' ')
          .find((value: any, i: any) => {
            if (value === 'Network') return value
          }) !== undefined
      ) {
        setPageMessageType({
          message: 'Something went wrong.Network Error. Please try again.',
          messageType: 'ERROR',
        })
      } else if (er.toString().includes('404')) {
        setPageMessageType({
          message: 'No results have been found matching the search criteria.',
          messageType: 'SUCCESS',
        })
      } else {
        setPageMessageType({
          message: 'Something went wrong. Please try again.',
          messageType: 'ERROR',
        })
      }
      setPlayerSearchAPIResponse([])
      setButtonClicked(false)
    }
  } else {
    setButtonClicked(false)
  }
}

export const setPhoneValue = (e: any) => {
  // if(e.target.value.length < 16){
  //     let tempPhone = e.target.value.replace(/\D/g,'');
  //     formik.setFieldValue(e.target.name, tempPhone, true);
  // }

  e.target.value = e.target.value.replace(/\D/g, '')

  if (e.target.value.trim().length > 10) {
    return
  }
  //setPhoneNumberErrors("");
  formik.setFieldValue(e.target.name, e.target.value, true)
}

export const setFiledValue = (e: any) => {
  if (e.target.name === 'playerId' || e.target.name === 'email') {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true)
  } else {
    formik.setFieldValue(e.target.name, e.target.value, true)
  }
}

export const onClearSearch = (setPlayerSearchResults: any) => {
  setPlayerSearchResults([])
  playerStore.clearPlayerSearchResults()
}

export const validate = (values: PlayerSearchCriteria) => {
  const errors = {}

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    Object.assign(errors, { email: 'Invalid email address' })
  }

  if (
    values.socialSecurityNumber &&
    !/^\d{3}-?\d{2}-?\d{4}$/.test(values.socialSecurityNumber)
  ) {
    Object.assign(errors, { socialSecurityNumber: 'Invalid SSN' })
  }

  return errors
}
