import React from "react"
import { ConfirmationPopupUI } from "../../../CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui"


export type CreateVoidReissueConfirmationUIProps = {
  setShowSubmitPopup: React.Dispatch<React.SetStateAction<boolean>>
  submitVoidReissue: () => void
}

const ROOT_CLASS = 'create-void-reissue-confirmation-container'
export const CreateVoidReissueConfirmationUI = (props: CreateVoidReissueConfirmationUIProps) => {
  const { submitVoidReissue, setShowSubmitPopup } = props

  const hideSubmitPopup = () => {
    setShowSubmitPopup(false)
  }

  const onClickSubmit = () => {
    hideSubmitPopup()
    submitVoidReissue()
  }

  return (
    <ConfirmationPopupUI 
      message={ 'Are you sure you want to void & re-issue this payment?' } 
      confirmCallback={ onClickSubmit } 
      closePopup={ hideSubmitPopup }
    />
  )
}