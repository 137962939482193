import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {PaginationCommonComponent} from "../../../../CommonComponents/PaginationCommonComponent";
import {
    getPageData
} from "../../../../../pages/CharitableGamingPages/PullTab/Inventory/PullTabStockInventoryPage/functions";
import moment from "moment";
import ExclamationIcon from "../../../../../icons/icon_status_flagged.svg";
import {SearchResultExeceededInfoMessageComponent} from "../../../Common/SearchResultExeceededInfoMessageComponent";

export const PullTabStockInventorySearchResultComponent = ({stockInventory, currentPage, setCurrentPage,}: any) => {

    const history = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        setTimeout(function () {
            setShowSearchResultExceededInfoMessage(false);
        }, 3000);
    }, []);

    const determineDateSoldValue = function (warehouseId: string, latestPurchaseDate: any) {

        let DateSoldValue;

        if (warehouseId && !latestPurchaseDate) {
            DateSoldValue = 'n/a';
        } else if (warehouseId && latestPurchaseDate) {
            DateSoldValue = 'Returned';
        } else if (!warehouseId && latestPurchaseDate) {
            DateSoldValue = moment(latestPurchaseDate).format('MM/DD/YYYY');
        }

        return DateSoldValue;
    }

    return (<>

            <div className={styles['table']}>
                <div className={styles['table-data-header']}>
                    <div className={styles['table-data-header-text']}
                         aria-live="assertive">{`Displaying ${stockInventory.packets.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(stockInventory.packets.length, currentPage)} 
                        of ${stockInventory.packets.length}${stockInventory.additionalResults ? "+" : ""} results`}
                    </div>
                    {stockInventory.additionalResults ? <div
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                            setShowSearchResultExceededInfoMessage(true);
                        }}
                        onMouseLeave={() => {
                            setShowSearchResultExceededInfoMessage(false);
                        }}
                    >
                        <img
                            src={ExclamationIcon}
                            height={20}
                            width={20}
                            alt="exclamation icon"
                        />
                        {stockInventory.additionalResults && showSearchResultExceededInfoMessage ?
                            <SearchResultExeceededInfoMessageComponent/> : null}
                    </div> : null}
                </div>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>Stock Number</div>
                    <div>Serial No.</div>
                    <div>Date Sold</div>
                    <div>Warehouse</div>
                    <div>License No.</div>
                    <div>Date Recorded</div>
                    <div>Original Warehouse</div>
                </div>

                <div className={styles['table-data-body']}>
                    {stockInventory.packets.length > 0 && getPageData(stockInventory.packets, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles['table-data-data-text']}>
                                {e.stockNumber}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.serialNumber}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {determineDateSoldValue(e.warehouseId, e.latestPurchaseDate)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {(e.warehouseId) ? e.warehouseId : 'n/a'}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {(e.licenseNumber) ? e.licenseNumber : 'n/a'}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {moment(e.dateRecorded).utc().format('MM/DD/YYYY')}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.originalWarehouse}
                            </div>

                        </div>)}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((stockInventory.packets.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={stockInventory.packets} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>
        </>);
};
