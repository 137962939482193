import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import {
  getPageData,
  normilizeDate,
  convertAmount,
  downloadClicked,
  generateLink
} from "./functions";
import { playerSupportRoutePath } from './../../../config.js';
import { useHistory } from "react-router-dom";
import { keyPressedType } from "../../../services/Commons";
import {checkRenderPermissions} from "./../../../helpers";
import {permissions} from "./../../../constants";

import {ExportToCsv} from 'export-to-csv';
import { setDateFormat } from "../PlayerMobileClaimsActivityTableComponent/functions";

import moment from "moment-timezone";

const array:any = [];
for(let i = 0; i < 105; i++)
{
  array .push({  createdAt: "2021-08-25T17:01:09.706Z",
  firstName: "PlayerNameCharacterCountTestAccount",
  lastName: "PlayerNameCharacterCountTest Account",
  playerId: "2474b81b-3086-4987-9084-866655f53501"})
}


export const SessionActivityTableComponent = ({pageMessage, SAPlayerData, name, currentPage, setCurrentPage , id }: any) => {

  const userpolicies: any = localStorage.getItem('userpolicies');
  const history = useHistory();
  const normilizePage = (totalPages:number, currentPage:number) =>{
    const number:number = totalPages - currentPage * 10;
    if(number > 10)
        return currentPage * 10 + 10;
    else
      return totalPages;
  } 
/* 
  if (currentPage > 0 && (currentPage - 1) * 10 >= SAPlayerData.data.length) {
    setCurrentPage(0);
  }
 */
  let CSVoptions = {};
 
  try {
    CSVoptions = {
      showLabels: true,
      useTextFile: false,
      useBom: true,
      filename: `${name.split(' ').slice(name.split(' ').length - 1, name.split(' ').length  ).join(" ")}_Session_Activity_Export`,
      showTitle: true,
      title: `${ name.split(' ').slice(name.split(' ').length - 1, name.split(' ').length  ).join("")} Session Activity`,
      //useKeysAsHeaders: true,
      headers: ['Date', 'Activity', 'Device']
    };
  } catch (error) {
    CSVoptions = {
      showLabels: true,
      useTextFile: false,
      useBom: true,
      filename: `Unknown_Player_Session_Activity_Export`,
      showTitle: true,
      title: `Unknown Player Session Activity`,
      //useKeysAsHeaders: true,
      headers: ['Date', 'Activity', 'Device']
    };
  }

  let csvExporter:ExportToCsv = new ExportToCsv(CSVoptions);

  // SAPlayerData.data = array;
  const createPages = (SAPlayerData: any[], currentPage: any, setCurrentPage: any) => {
    const array: any = [];
    const pagesTotal = Math.ceil(SAPlayerData.length / 10);
     
    if (currentPage > 2 && pagesTotal > 5) {

      array.push(
        <>
          <div aria-live="assertive" 
          tabIndex={0} role='button'  
          aria-pressed={ currentPage === 0 ? "true" : "false" } 
          style={{ backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : "" }}
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setCurrentPage(0);
            }
          }
          }   
          onClick={() => setCurrentPage(0)} className={styles['SA-table-page-box']}>
            <div  className={styles['SA-table-page-box-text']}>
              {1}
            </div>
          </div>
          <div>{'...'}</div>
        </>
      );
    }
    if (currentPage <= 2 || pagesTotal <= 5) {

      for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
        array.push(
          <div  aria-live="assertive" tabIndex={0} role='button' aria-pressed={ currentPage === i ? "true" : "false" }
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setCurrentPage(i);
            }
          }
          }   
          onClick={() => setCurrentPage(i)} style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} 
          className={styles['SA-table-page-box']}>
            <div  className={styles['SA-table-page-box-text']}>
              {i + 1}
            </div>
          </div>
        );
      }
    }
    else if (currentPage > 2  && currentPage <= (pagesTotal - 1) - 2) {

      for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
        array.push(
          <div 
          aria-live="assertive" 
          tabIndex={0} 
          role='button' 
          aria-pressed={ currentPage === i ? "true" : "false" } 
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setCurrentPage(i);
            }
          }
          }   
          onClick={() => setCurrentPage(i)} style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} className={styles['SA-table-page-box']}>
            <div   className={styles['SA-table-page-box-text']}>
              {i + 1}
            </div>
          </div>
        );
      }
    }
    else if ( currentPage > (pagesTotal - 1) - 2) {
      for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
        array.push(
          <div   aria-live="assertive" tabIndex={0} role='button' aria-pressed={ currentPage === i ? "true" : "false" } 
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setCurrentPage(i);
            }
          }
          }   
          onClick={() => setCurrentPage(i)} 
          style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} 
          className={styles['SA-table-page-box']}>
            <div   className={styles['SA-table-page-box-text']}>
              {i + 1}
            </div>
          </div>
        );
      }

    }
    if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
      array.push(
        <>
          <div>{'...'}</div>
          <div aria-live="assertive" tabIndex={0} role='button' aria-pressed={ currentPage === pagesTotal ? "true" : "false" } 
          style={{ backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : "" }}
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
              setCurrentPage(pagesTotal - 1);
            }
          }
          }    
          onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['SA-table-page-box']}>
            <div  className={styles['SA-table-page-box-text']}>
              {pagesTotal}
            </div>
          </div>
        </>
      );
    }
    if(pagesTotal <= currentPage){
      setCurrentPage(0);
    }
    return array;
  }



  return (
    <>
   
      <div className={styles['SA-table']}>
        <div className={styles['SA-table-data-header']}>
          <div aria-live="assertive" tabIndex={0} >{`Displaying ${ SAPlayerData.length > 0 &&  SAPlayerData[0].id === id ? 0 : (currentPage * 10) + 1}-${normilizePage(SAPlayerData.length, currentPage)} of ${SAPlayerData.length} results`}</div>
            <div className={styles['SA-table-export-button-container']}>
              <button 
              style={{
                opacity: ( SAPlayerData.length > 0 &&  SAPlayerData[0].id !== id ) ? "1":"0.4", 
                cursor: ( SAPlayerData.length > 0 &&  SAPlayerData[0].id !== id ) ? "pointer" : "not-allowed" 
              }
            }
                      className={styles['SA-table-export-button']}
                      onClick={() =>  ( SAPlayerData.length > 0 &&  SAPlayerData[0].id !== id ) && downloadClicked(SAPlayerData, csvExporter)}>Download Table as CSV</button>
            </div>
        </div>

        <div className={`${styles['SA-table-data-grid']} ${styles['SA-table-data-label']}`}>
          <div tabIndex={0}>Date</div>
          <div tabIndex={0}>Activity</div>
          <div tabIndex={0}>Device</div>
        </div>
        <div className={styles['SA-table-data-body']} >
          { (SAPlayerData.length > 0 && SAPlayerData[0].id !== id) && getPageData(SAPlayerData, currentPage).map((e: any, i: number) =>

            <div style={{ backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)') }} className={`${styles['SA-table-data-grid']} ${styles['SA-table-data-data']}`}>
              <div tabIndex={0} className={styles['SA-table-data-data-text']}>{  moment.tz(e.eventTimestamp, "America/New_York").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;{moment.tz(e.eventTimestamp, "America/New_York").format('h:mm:ss a') }</div>
              <div tabIndex={0} className={styles['SA-table-data-data-text']}>{e.authEvent}</div>
              <div tabIndex={0} className={styles['SA-table-data-data-text']}>{e.clientOs}</div>
            </div>
          )}
        </div>
        <div className={styles['SA-table-data-footer']}>

          <div aria-live="assertive" tabIndex={0} className={styles['SA-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((SAPlayerData.length / 10)).toString()}`}</div>
          { (SAPlayerData.length > 0 && SAPlayerData[0] !== id) && createPages(SAPlayerData, currentPage, setCurrentPage)}

        </div>
      </div>


    </>

  );
}

function updateButtonStates(arg0: string) {
  throw new Error("Function not implemented.");
}
