import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GetPendingPaymentsResponse } from '../../../../api/pending-payments-fetcher/api-types'
import { checkRunQueueRoutePath } from '../../../../config.js'
import { permissions } from '../../../../constants'
import { checkRenderPermissions } from '../../../../helpers'
import { ConfirmationPopupUI } from '../../../CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui'
import { initiateCheckRun } from './initiate-check-run'
import './styles.scss'

const ROOT_CLASS = 'check-run-title-bar'

export type CheckRunQueueTitleBarUIProps = {
  showInititateCTA: boolean
  activateInitiateButton: boolean
  pendingPaymentsData: GetPendingPaymentsResponse
  fetchInitiateCheckRun: (futurePaymentIds: string[]) => void
}

export const CheckRunQueueTitleBarUI = (
  props: CheckRunQueueTitleBarUIProps
) => {
  const userpolicies: any = localStorage.getItem('userpolicies')
  const [showInitiateConfirmation, setShowInitiateConfirmation] =
    useState<boolean>(false)

  const history = useHistory()

  const optionallyRenderInitiateCTA = (
    activateInitiateButton: boolean
  ): React.ReactNode | undefined => {
    if (!props.showInititateCTA) {
      return undefined
    }
    return checkRenderPermissions(
      permissions.CAN_INITIATE_CHECK_RUN,
      JSON.parse(userpolicies)
    ) ? (
      <button
        className={`green button`}
        style={{
          opacity: activateInitiateButton ? '1' : '0.4',
          cursor: activateInitiateButton ? 'pointer' : 'not-allowed',
        }}
        onClick={() => {
          if (activateInitiateButton) {
            setShowInitiateConfirmation(true)
          }
        }}
      >
        <div className={`button-label`}>Initiate Check Run</div>
      </button>
    ) : null
  }

  return (
    <div className={ROOT_CLASS}>
      <div className={`title`}>Check Run Queue</div>
      <div className={`buttons-container`}>
        {checkRenderPermissions(
          permissions.CAN_SEE_CHECK_RUN_HISTORY,
          JSON.parse(userpolicies)
        ) ? (
          <button
            className={`blue button`}
            onClick={() => {
              history.push(`${checkRunQueueRoutePath}/history`)
            }}
          >
            <div className={`button-label`}>Check Run History</div>
          </button>
        ) : null}
        {optionallyRenderInitiateCTA(props.activateInitiateButton)}
      </div>
      {showInitiateConfirmation && (
        <ConfirmationPopupUI
          message="Are you sure you want to initiate a check run?"
          subMessage="This action cannot be undone."
          confirmText="Initiate"
          confirmCallback={() => {
            initiateCheckRun(
              props.pendingPaymentsData,
              props.fetchInitiateCheckRun
            )
          }}
          closePopup={() => {
            setShowInitiateConfirmation(false)
          }}
        />
      )}
    </div>
  )
}
