import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { Agent1099AggregatesSearchRequestBody, Agent1099AggregatesSearchResponse } from './api-types'

export const getFetchAgent1099AggregatesSearch = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<Agent1099AggregatesSearchResponse>()
  const fetchAggregatesForSearch = (body: Agent1099AggregatesSearchRequestBody) => {
    fetchUrl(
      `${config.SERVER_BASE_URL}/api/v1/agent1099/search`,
      'POST',
      {},
      JSON.stringify(body)
    )
  }

  return {
    isLoading,
    error,
    data,
    fetchAggregatesForSearch
  }
}
