import React from 'react'
import { RouteProps, useHistory } from 'react-router-dom'
import {
  PaymentDetailsContainer,
  PaymentDetailsPageProps,
} from '../../../../components/AnnuitiesComponents/payment-details-container'
import { ANNUITY_PAGES } from '../../AnnuitiesRoutes'

export const PreAuthorizationPaymentDetails = ({ location }: RouteProps) => {
  let props: PaymentDetailsPageProps | undefined = location?.state
    ? (location.state as PaymentDetailsPageProps)
    : undefined
  const history = useHistory()

  if (!props) {
    history.push(ANNUITY_PAGES.LandingPage.path)
    return <></>
  }

  return <PaymentDetailsContainer {...props}></PaymentDetailsContainer>
}
