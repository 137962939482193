import axios from 'axios';
import { pageMessageDefault } from "../../../components/CommonComponents/PageMessageCommonComponent";
import config from "../../../config";
import { makeHttpCall } from "../../../services/MakeHttpCall";
import { claimHistory, claimReferenceNumber, claimTransaction, w2g } from "../../../services/StoresObject/Claims";
import { claimsSupportRoutePath } from './../../../config.js';
import { getPaymentDataInterface } from "./interfaces";

const identityDocument: any = {
    accountType: "",
    routingNumber: 0,
    reEnterRoutingNumber: 0,
    accountNumber: 0,
    reEnterAccountNumber: 0,
}

let formik: any;
let history: any;
let firstPass: boolean = false;
let setPageMessage: any;
let pageMessage: any;
let claimId: any;
let playerId: any;
let bankInformation: any;
let storesObject: any;
export const setVariables = (storesObjectProp: any, formikObj: any, historyObject: any, setPageMessageObj: any, claimIdProps: any, playerIdProps: any, bankInformationObj: any) => {

    formik = formikObj;
    history = historyObject;
    setPageMessage = setPageMessageObj;
    claimId = claimIdProps;
    playerId = playerIdProps;
    bankInformation = bankInformationObj;
    storesObject = storesObjectProp;

}

export const CallingPage = {
    PaymentMethod: 'PaymentMethod'
}

export const refreshComponent = (response: any) => {

    if (!(response.error === undefined)) {

        setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}

export const onSubmit = async (values: any) => {

    formik.setValues(values, true);
    const data = {
        "claimReferenceNumber": claimId,
        "newAchAccount": {
            "achAccountNumber": values.identityValues.accountNumber,
            "achRoutingNumber": values.identityValues.routingNumber,
            "achAccountType": values.identityValues.accountType
        },
        firstName: storesObject.playerStore.selectedPlayerDetails.identity.firstName,
        lastName: storesObject.playerStore.selectedPlayerDetails.identity.lastName,
    };

    try {

        const options =
            {
                method: 'post',
                url: `${config.SERVER_BASE_URL}/v1/ach/payment/resubmit`,
                data
            }

        const response = await makeHttpCall(options);

        storesObject.claimStore.claimTransaction = JSON.parse(JSON.stringify(claimTransaction));
        storesObject.claimStore.claimReferenceNumberr = JSON.parse(JSON.stringify(claimReferenceNumber));
        storesObject.claimStore.claimHistory = JSON.parse(JSON.stringify(claimHistory));
        storesObject.claimStore.claimW2G = JSON.parse(JSON.stringify(w2g));

        localStorage.setItem('activeTab', 'activity');
        pageMessageDefault.message = `Player's bank information updated successfully.`;
        pageMessageDefault.messageType = "SUCCESS";
        history.push(`${claimsSupportRoutePath}/mobile-claims-details/${playerId}/${claimId}`);

    } catch (e) {
        const er: any = e;
        if (er.toString().split(" ").find((value: any, i: any) => {
            if (value === "Network")
                {if (value[i + 1] === "Error")
                    {return value;}}
        }) !== undefined) {
            setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        } else
            {setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});}


    }
}

export const displayErrors = (formik: any) => {
    if (!firstPass && Object.keys(formik.errors).length > 0) {
        formik.setErrors({});
        firstPass = true;
        return false;
    }
}

export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true)
}

let httpResponse: any = "";
// @ts-ignore
export const validate = async (values: getPaymentDataInterface | any) => {
    const errors = {};
    if (values.identityValues.routingNumber !== values.identityValues.reEnterRoutingNumber)
        {bankInformation.value = undefined;}

    if (!values.identityValues.accountType) {
        Object.assign(errors, {accountType: 'Please select account type'});
    }

    if (!values.identityValues.accountNumber) {
        Object.assign(errors, {accountNumber: 'Account number is Required'});
    } else if (!values.identityValues.accountNumber.match(/^[0-9\b]+$/)) {
        Object.assign(errors, {accountNumber: 'Account number must be digits only'});
    } else if (values.identityValues.accountNumber !== values.identityValues.reEnterAccountNumber) {
        Object.assign(errors, {accountNumber: 'Account number does not match'});
    } else if (values.identityValues.accountNumber <= 1) {
        Object.assign(errors, {accountNumber: 'Account number must be a minimum of 2 digits!'});
    }

    if (!values.identityValues.routingNumber) {
        httpResponse = "";
        Object.assign(errors, {routingNumber: 'Routing number is Required'});
    } else if (!values.identityValues.routingNumber.match(/^[0-9\b]+$/)) {
        httpResponse = "";
        Object.assign(errors, {routingNumber: 'Routing number must be digits only'});
    } else if (values.identityValues.routingNumber !== values.identityValues.reEnterRoutingNumber) {
        httpResponse = "";
        Object.assign(errors, {routingNumber: 'Routing number does not match'});
    } else if (values.identityValues.routingNumber === values.identityValues.reEnterRoutingNumber) {
        // try {


        //     const data = {
        //             "achRoutingNumber": values.identityValues.routingNumber,
        //              firstName: storesObject.playerStore.selectedPlayerDetails.identity.firstName,
        //              lastName:  storesObject.playerStore.selectedPlayerDetails.identity.lastName,       
        //         }

        //     const options =
        //     {
        //         method: 'post',
        //         url: `${config.SERVER_BASE_URL}/v1/ach/payment/bank`,
        //         data  
        //     }

        //     if(httpResponse !== 200)
        //        bankInformation.value = "wait"; 

        //     const response = await makeHttpCall(options);   

        //    console.log("response", response);
        //     if (response.message.code === 200) { // response - object, eg { status: 200, message: 'OK' }
        //         httpResponse = 200;
        //         bankInformation.value = response.message.name;

        //     } else if (response.message.code === 404) {
        //         httpResponse="";
        //         bankInformation.value =  undefined ; 
        //         Object.assign(errors, {routingNumber: 'No bank found for this routing number'});
        //     } else if (response.message.code !== 200) {
        //         httpResponse="";
        //         bankInformation.value =  undefined;
        //         Object.assign(errors, {routingNumber: response.message.message});
        //     }
        // } catch (e) {
        //     httpResponse="";
        //     bankInformation.value = undefined;
        //     setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"})
        // }

        try {
            const url = `https://www.routingnumbers.info/api/name.json?rn=${values.identityValues.routingNumber}`;
            if (httpResponse !== 200)
                {bankInformation.value = "wait";}
            const response = await axios.get(url, {withCredentials: false})
            if (response.data.code === 200) { // response - object, eg { status: 200, message: 'OK' }
                httpResponse = 200;
                bankInformation.value = response.data.name;

            } else if (response.data.code === 404) {
                httpResponse = "";
                bankInformation.value = undefined;
                Object.assign(errors, {routingNumber: 'No bank found for this routing number'});
            } else if (response.data.code !== 200) {
                httpResponse = "";
                bankInformation.value = undefined;
                Object.assign(errors, {routingNumber: response.data.message});
            }
        } catch (e) {
            httpResponse = "";
            bankInformation.value = undefined;
            const er: any = e;
            if (er.toString().split(" ").find((value: any, i: any) => {
                if (value === "Network")
                    {return value}
            }) !== undefined) {
                setPageMessage({
                    message: "Something went wrong. Network Error. Please try again.",
                    messageType: "ERROR"
                });
            } else
                {setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});}


        }
    }
    return errors;
}