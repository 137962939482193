export const keyPressedType = (e:any) =>{

  
    if(window.event) { // IE                  
        return e.keyCode;
      } else if(e.which){ // Netscape/Firefox/Opera                 
        return  e.which;
      }
      
    
}
export const playersIdentityValues ={identityFieldIsUndefined:false, accountFieldIsUndefined:false};