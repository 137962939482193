import { FormikValues } from 'formik'
import config from '../../../../config'
import { makeHttpCall } from '../../../../services/MakeHttpCall'
import { makeHttpCallFullResponse } from '../../../../services/MakeHttpCallFullResponse'

export const getPayee = async (
  payeeInfo: { payeeType: string; tin: string } //TIN
) => {
  let rawData = {
    searchParams: { ...payeeInfo },
  }
  const sendData = JSON.stringify(rawData)

  const options = {
    method: 'POST',
    url: `${config.SERVER_BASE_URL}/v1/annuities/payee-search`,
    data: sendData,
    validateStatus: (status: number) => {
      return (status >= 200 && status <= 300) || status === 404
    },
  }

  try {
    return await makeHttpCallFullResponse(options)
  } catch (error) {
    throw error
  }
}

export const resetPayeeData = (formik: FormikValues) => {
  formik.values.firstName = ''
  formik.values.middleInitial = ''
  formik.values.lastName = ''
  formik.values.tin = ''
  formik.values.freeformName = ''
  formik.values.addr_1 = ''
  formik.values.addr_2 = ''
  formik.values.city = ''
  formik.values.state_region = ''
  formik.values.zip_code = ''
  formik.values.country = ''
  formik.values.phone = ''
  formik.values.email = ''
  formik.values.birthDate = ''
  formik.values.payeeAccountStatus = ''
  formik.values.payeeCreationDate = ''
  formik.values.payeeEndDate = ''
  formik.values.payeePreviousPayDate = ''
  formik.values.payeeNextPayDate = ''
  formik.values.irsName = ''
}

export const addPayee = async (
  payeeId: string,
  receivingAccountId: string,
  annuitiesStore: any,
  idemToken: string
) => {
  const isPayeeAlreadyAdded = annuitiesStore.annuityAccountPayeeList.some(
    (individualPayee: any) => individualPayee.id === payeeId
  )

  if (isPayeeAlreadyAdded) {
    throw new Error('Payee is already on this Time Payment Account')
  } else {
    const data = { payeeId: payeeId }

    const options = {
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${receivingAccountId}/payee`,
      data: data,
      headers: {
        'Content-Type': `application/json`,
        'x-idempotency-token': idemToken,
      },
    }

    try {
      await makeHttpCall(options)
      annuitiesStore.populateAnnuityAccountPayeeList(receivingAccountId)
    } catch (error) {
      throw new Error('Something went wrong Adding Payee')
    }
  }
}
