import { PreSignedUrlResponse } from "../../../api/finserv-general-reports/api-types"
import config from '../../../config'
import { useFetch } from "../../../hooks/use-fetch"
import { saveFromUrl } from "../../../util"
import { ReconciliationPdfReportDisplayRow } from "./reconciliation-pdf-reports-page-ui"

const downloadReconciliationPdfReportUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/reconciliation/reportUrl`

export const useFetchDownloadReconciliationReport = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<PreSignedUrlResponse>()
  const preSignedUrlHandler = async (row: ReconciliationPdfReportDisplayRow) => {
    const urlWithParams = `${downloadReconciliationPdfReportUrl}?reportId=${row.id}`
    const preSignedUrl = await fetchUrl(urlWithParams, 'GET')
    if (!preSignedUrl) {
      return
    }
    saveFromUrl(preSignedUrl.url)
  }

  return { isLoading, error, data, preSignedUrlHandler }
}