import React, {useEffect, useState} from "react";
import styles from "./css/styles.module.css";

import {useFormik} from "formik";
import {doACHActivitySearch, onClearSearch, onSubmit, setFiledValue, setVariables, validate,} from "./functions";
import {useHistory} from "react-router-dom";
import {PLAYERS_PAGES} from "../../../application-data-structure/players-page.data-structure";
import {
    PageMessageCommonComponent,
    pageMessageDefault,
} from "../../../components/CommonComponents/PageMessageCommonComponent";
import {CircularProgress} from "@material-ui/core";
import {ACHActivitySearchTableComponent} from "../../../components/FinanceComponents/ACHActivitySearchTableComponent";
import {ACHActivitySearchComponent} from "../../../components/FinanceComponents/ACHActivitySearchComponent";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {reportingRoutePath} from "../../../config";
import {ACHActivitySearchResultInterface} from "../../../components/FinanceComponents/ACHActivitySearchTableComponent/interface";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

let eventEmitterInitialize: any = undefined;
const timerArray: any = [];
export const ACHActivityPage = ({id, storesObject}: any) => {
    const claimStore: any = storesObject.claimStore;
    const history = useHistory();

    const setTogglevalue: any = {value: false};

    const callingPage = PLAYERS_PAGES;
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [ACHActivitySearchAPIResponse, setACHActivitySearchAPIResponse] = useState<ACHActivitySearchResultInterface[] | any>(storesObject.claimStore.ACHActivitySearchAPIResponse);
    const [buttonClicked, setButtonClicked] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const formik = useFormik({
        initialValues: {
            startDate: '',
            endDate: '',
            batchNumber: '',
            rejectFileRefNumber: '',
            papercheckFileNumber: ''
        },
        onSubmit,
        validate,
    });


    useEffect(() => {
        if (formik.values.startDate !== '' || formik.values.endDate !== '' ||
            formik.values.batchNumber !== '' || formik.values.rejectFileRefNumber !== '' ||
            formik.values.papercheckFileNumber !== '') {
        }
    });

    setVariables(formik, claimStore, history, pageMessageDefault, setPageMessage, setButtonClicked);

    return (
        <>
            {(buttonClicked && pageMessage.message === "") && <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="8em" color='inherit' thickness={2}/>
            </div>}
            <div
                onClick={(e: any) => {
                    pageMessage.messageType !== "" &&
                    setPageMessage({message: "", messageType: ""});
                }}
                className={styles["page-container"]}
            >
                <div className={styles['back-to-player-search-container']}>
                    <div tabIndex={0} role="button" aria-pressed={"false"} onClick={() => {
                        pageMessageDefault.message = "";
                        pageMessageDefault.messageType = "";
                        history.push(`${reportingRoutePath}/finance`);
                        storesObject.claimStore.ACHActivitySearchAPIResponse = [];
                        setACHActivitySearchAPIResponse([]);
                    }} className={styles['back-to-player-search-inner-container']}>
                        <i title='left arrow icon' className={styles['back-to-player-search-icon']}>
                            <FontAwesomeIcon size="2x" arial-label='left arrow icon.' icon={faAngleLeft as IconProp}/>
                        </i>
                    </div>
                </div>
                <div className={styles["top-ribbon"]}>
                    <ACHActivitySearchComponent
                        onClearSearch={onClearSearch}
                        setButtonClicked={setButtonClicked}
                        setACHActivitySearchAPIResponse={setACHActivitySearchAPIResponse}
                        doACHActivitySearch={doACHActivitySearch}
                        formik={formik}
                        setFieldValue={setFiledValue}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                {pageMessage.message !== "" &&
                <PageMessageCommonComponent message={pageMessage.message} messageType={pageMessage.messageType}/>}
                <div className={styles["page-container-body-container"]}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-center"]}>
                            {ACHActivitySearchAPIResponse.length > 0 &&
                            <ACHActivitySearchTableComponent ACHActivitySearchResult={ACHActivitySearchAPIResponse}
                                                             currentPage={currentPage}
                                                             setCurrentPage={setCurrentPage}/>}
                            {ACHActivitySearchAPIResponse.length <= 0 &&
                            <div className={styles["page-container-body-center-text"]}>
                                <div tabIndex={0}>Enter search criteria above to see ACH Activity</div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
