import * as React from 'react'
import { GetPendingReissuePaymentsResponse } from '../../../../api/pending-reissue-payments-fetcher/api-types'
import { BackButtonUI } from '../../../CommonComponents/BackButtonComponent/back-button-ui'
import { ReissueQueueTitleBarUI } from '../reissue-queue-title-bar/reissue-queue-title-bar-ui'
import { ReissueQueueUI } from '../reissue-queue-ui/reissue-queue-ui'
import { useState } from 'react'
import { GenericErrorDialogComponent } from '../../../CommonComponents/GenericErrorDialogComponent'

const ROOT_CLASS = 'check-run-queue-page'

export type CheckRunQueueContainerUIProps = {
  pendingReissuesData: GetPendingReissuePaymentsResponse
  fetchInitiateReissueRun: (futurePayments: string[]) => void
  initiateReissueRunError: string | undefined
}

export const ReissueQueueContainerUI = (
  props: CheckRunQueueContainerUIProps
) => {
  const [showErrorDialog, setShowErrorDialog] = useState(
    props.initiateReissueRunError !== undefined
  ) // this is a bit of a hack imo -- it really relies on the fact that this component goes away & gets re-rendered every time we hit "initiate check run" to make sure the message gets displayed...

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI
        tabIndex={0}
        message='Back to Payments'
      />

      <ReissueQueueTitleBarUI
        {...props}
        activateInitiateButton={
          props.pendingReissuesData.pendingReissuePayments.length > 0
        }
      />
      <ReissueQueueUI {...props} />
      {showErrorDialog ? (
        <GenericErrorDialogComponent
          setShowErrorDialog={setShowErrorDialog}
          message='Something went wrong'
          subMessage="We weren't able to initiate the re-issue. Please try again."
          closeButtonLabel='Close'
        />
      ) : null}
    </div>
  )
}