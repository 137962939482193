import { useHistory } from 'react-router-dom'
import {
  PayeeType,
  PaymentSource,
} from '../../../api/payment-details-fetcher/api-types'
import {
  AddPaymentToSweepRequest,
  W2GReissueReviewDetails,
} from '../../../api/w2g-reissue-payment-fetcher/api-types'
import { getMatchingKnownCountry } from '../../../Constants/Countries'
import { useFetchPaymentDetails } from '../PaymentDetailsPage/use-fetch-payment-details'
import {
  useFetchAddToW2gQueue,
  useFetchReprintImmediately,
  useFetchReviewW2gReissuePayment,
} from './use-fetch-w2g-reissue-payment'
import React, { useContext, useEffect, useRef } from 'react'
import { BannerContext } from '../../../context/BannerContext'
import { omit } from 'lodash'
import { ReviewW2gReissuePageUI } from './review-w2g-reissue-page-ui'

export type ReviewW2GReissuePageContainerProps = {
  paymentId: string
}

type W2GReissueReviewIndividual = Omit<
  AddPaymentToSweepRequest,
  'payeeFreeformName'
> & {
  payeeType: PayeeType.INDIVIDUAL
}
type W2GReissueReviewEntity = Omit<
  AddPaymentToSweepRequest,
  'payeeFirstName' | 'payeeMiddleInitial' | 'payeeLastName'
> & {
  payeeType: PayeeType.ENTITY
}

export type W2gReissueFormFields =
  | W2GReissueReviewEntity
  | W2GReissueReviewIndividual

const mapPayeeDetailsToForm = (
  paymentId: string,
  payeeDetails?: W2GReissueReviewDetails
): W2gReissueFormFields => {
  if (!payeeDetails) {
    return {
      paymentId,
      tin: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      supplementalAddress1: undefined,
      supplementalAddress2: undefined,
      payeeType: PayeeType.ENTITY,
      payeeFreeformName: '',
      ssn: '',
    }
  }
  const formDetailsBase: AddPaymentToSweepRequest = {
    paymentId,
    tin: payeeDetails.payment.ssn,
    // sending both ssn and tin for now. FinServ accepts ssn in this request
    ssn: payeeDetails.payment.ssn,
    address1: payeeDetails.payment.address1,
    address2: payeeDetails.payment.address2 ?? undefined,
    city: payeeDetails.payment.city,
    state: payeeDetails.payment.state,
    zip: payeeDetails.payment.zip,
    country: getMatchingKnownCountry(payeeDetails.payment.country),
    supplementalAddress1:
      payeeDetails.payment.supplementalAddress1 ?? undefined,
    supplementalAddress2:
      payeeDetails.payment.supplementalAddress2 ?? undefined,
  }

  if (payeeDetails.payment.payeeIsEntity) {
    return {
      ...formDetailsBase,
      payeeType: PayeeType.ENTITY,
      payeeFreeformName: payeeDetails.payment.payeeFreeformName ?? '',
    }
  }

  return {
    ...formDetailsBase,
    payeeType: PayeeType.INDIVIDUAL,
    payeeFirstName: payeeDetails.payment.payeeFirstName ?? '',
    payeeMiddleInitial: payeeDetails.payment.payeeMiddleInitial ?? undefined,
    payeeLastName: payeeDetails.payment.payeeLastName ?? '',
  }
}

export const ReviewW2gReissuePageContainer = (
  props: ReviewW2GReissuePageContainerProps
) => {
  const { paymentId } = props

  const {
    isLoading: paymentDetailsLoading,
    error: paymentDetailsError,
    data: paymentDetails,
  } = useFetchPaymentDetails(paymentId)

  const {
    fetchReissuePaymentisLoading,
    fetchReissuePaymentError,
    fetchReissuePaymentData,
  } = useFetchReviewW2gReissuePayment(paymentId)

  const {
    fetchAddToQueueIsLoading,
    fetchAddToQueueError,
    fetchAddToQueueRequestSuccessful,
    fetchW2gReissueAddToQueue,
  } = useFetchAddToW2gQueue()

  const {
    fetchReprintImmediatelyIsLoading,
    fetchReprintImmediatelyError,
    fetchReprintImmediatelyRequestSuccessful,
    fetchW2gReissueReprintImmediately,
  } = useFetchReprintImmediately()

  const history = useHistory()
  const bannerContext = useContext(BannerContext)

  const submittedPayeeDetails = useRef<W2gReissueFormFields | undefined>()

  const submitW2gReissueAddToQueue = async (
    formFields: W2gReissueFormFields
  ) => {
    submittedPayeeDetails.current = { ...formFields }
    await fetchW2gReissueAddToQueue(omit(formFields, ['payeeType']))
  }

  const submitW2gReissueReprintImmediately = async (
    formFields: W2gReissueFormFields
  ) => {
    submittedPayeeDetails.current = { ...formFields }
    await fetchW2gReissueReprintImmediately(omit(formFields, ['payeeType']))
  }

  useEffect(() => {
    if (!fetchReissuePaymentisLoading && fetchReissuePaymentError) {
      bannerContext.setBannerInfo({
        message:
          'Something went wrong. This payment could not be found in our system.',
        error: true,
      })
      history.goBack()
    }
  }, [fetchReissuePaymentisLoading])

  useEffect(() => {
    if (!fetchAddToQueueIsLoading && fetchAddToQueueRequestSuccessful) {
      bannerContext.setBannerInfo({
        message: 'The payment has been added to the W-2G Queue',
        error: false,
      })
      history.goBack()
    } else if (!fetchAddToQueueIsLoading && fetchAddToQueueError) {
      bannerContext.setBannerInfo({
        message:
          'Something went wrong. The payment was not added to the queue.',
        error: true,
      })
      history.goBack()
    }
  }, [fetchAddToQueueIsLoading])

  useEffect(() => {
    if (!fetchReprintImmediatelyIsLoading && fetchReprintImmediatelyRequestSuccessful) {
      bannerContext.setBannerInfo({
        message: 'The payment has been printed.',
        error: false,
      })
      history.goBack()
    } else if (
      !fetchReprintImmediatelyIsLoading &&
      fetchReprintImmediatelyError
    ) {
      bannerContext.setBannerInfo({
        message: 'Something went wrong. The W2G was not printed.',
        error: true,
      })
      history.goBack()
    }
  }, [fetchReprintImmediatelyIsLoading])

  return (
    <ReviewW2gReissuePageUI
      paymentDetails={
        submittedPayeeDetails.current ??
        mapPayeeDetailsToForm(paymentId, fetchReissuePaymentData)
      }
      payeeError={fetchReissuePaymentError || paymentDetailsError}
      paymentSource={
        paymentDetails ? paymentDetails.payment.source : PaymentSource.OTHER
      }
      paymentLoading={paymentDetailsLoading}
      payeeLoading={fetchReissuePaymentisLoading}
      addToQueueLoading={fetchAddToQueueIsLoading}
      addToQueueError={fetchAddToQueueError}
      submitAddToQueue={submitW2gReissueAddToQueue}
      reprintImmediatelyLoading={fetchReprintImmediatelyIsLoading}
      reprintImmediatelyError={fetchReprintImmediatelyError}
      submitReprintImmediately={submitW2gReissueReprintImmediately}
    />
  )
}
