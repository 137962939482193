import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import InputMask from 'react-input-mask';


export const CreateMemberInChargeComponent = ({
                                                  formik,
                                                  setFiledValue,
                                              }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Member in charge Details
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Name of Member in
                        Charge*
                    </div>
                    <input
                        id="licenseDetails.memberInCharge.fullName"
                        name="licenseDetails.memberInCharge.fullName"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.memberInCharge.fullName}
                        onBlur={formik.handleBlur}
                        autoComplete={"name"}
                    />
                    {(formik.touched.licenseDetails?.memberInCharge?.fullName && formik.errors.fullName5) ?
                        <ErrorMessageFieldCommonComponent
                            fullName5={formik.errors.fullName5}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Home Phone*
                    </div>
                    <InputMask
                        id="licenseDetails.memberInCharge.homePhone"
                        name="licenseDetails.memberInCharge.homePhone"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.memberInCharge.homePhone}
                        onBlur={formik.handleBlur}
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                        autoComplete={"tel-national"}
                    />
                    {(formik.touched.licenseDetails?.memberInCharge?.homePhone && formik.errors.homePhone5) ?
                        <ErrorMessageFieldCommonComponent
                            homePhone5={formik.errors.homePhone5}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing Address*</div>
                    <input
                        id="licenseDetails.memberInCharge.address.streetAddress"
                        name="licenseDetails.memberInCharge.address.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.memberInCharge.address.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                        autoComplete={"street-address"}
                    />
                    {(formik.touched.licenseDetails?.memberInCharge?.address?.streetAddress && formik.errors.streetAddress5) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress5={formik.errors.streetAddress5}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Business Phone*
                    </div>
                    <InputMask
                        id="licenseDetails.memberInCharge.businessPhone"
                        name="licenseDetails.memberInCharge.businessPhone"
                        onChange={(e: any) => setFiledValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.licenseDetails.memberInCharge.businessPhone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                        autoComplete={"tel-national"}
                    />
                    {(formik.touched.licenseDetails?.memberInCharge?.businessPhone && formik.errors.businessPhone5) ?
                        <ErrorMessageFieldCommonComponent
                            businessPhone5={formik.errors.businessPhone5}/> : null}
                </div>
                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            id="licenseDetails.memberInCharge.address.city"
                            name="licenseDetails.memberInCharge.address.city"
                            value={formik.values.licenseDetails.memberInCharge.address.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            autoComplete={"address-level2"}
                        />
                        {(formik.touched.licenseDetails?.memberInCharge?.address?.city && formik.errors.city5) ?
                            <ErrorMessageFieldCommonComponent
                                city5={formik.errors.city5}/> : null}
                    </div>

                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            id="licenseDetails.memberInCharge.address.zip"
                            name="licenseDetails.memberInCharge.address.zip"
                            value={formik.values.licenseDetails.memberInCharge.address.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            mask="99999"
                            maskChar=" "
                            autoComplete={"postal-code"}
                        />
                        {(formik.touched.licenseDetails?.memberInCharge?.address?.zip && formik.errors.zip5) ?
                            <ErrorMessageFieldCommonComponent
                                zip5={formik.errors.zip5}/> : null}
                    </div>
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email Address*</div>
                    <input
                        id="licenseDetails.memberInCharge.email"
                        name="licenseDetails.memberInCharge.email"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.memberInCharge.email}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                        autoComplete={"email"}
                    />
                    {(formik.touched.licenseDetails?.memberInCharge?.email && formik.errors.email5) ?
                        <ErrorMessageFieldCommonComponent
                            email5={formik.errors.email5}/> : null}
                </div>
            </form>
        </>
    );
}