import React from "react";
import { SourceGuideEntry, SourceGuideUI, SourceGuideUIProps } from "../../SourceGuideComponent";

const checkRunQueueSourceGuideEntries : SourceGuideEntry[] = [
    {
        abbreviation: 'AN',
        fullName: 'Annuities'
    },
    {
        abbreviation: 'PR',
        fullName: 'Promotions'
    },
    {
        abbreviation: 'ST',
        fullName: 'Season Tickets'
    },
    {
        abbreviation: 'OTR',
        fullName: 'Other'
    }
]

export const CheckRunQueueSourceGuideUI = (props: Omit<SourceGuideUIProps, 'entries'>) => {
    return <SourceGuideUI
        setPaymentSourceGuide={props.setPaymentSourceGuide}
        entries={checkRunQueueSourceGuideEntries}/>
}