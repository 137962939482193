import React, { useState, useEffect, useContext } from "react";
import styles from "./css/styles.module.css";
import moment from "moment-timezone";
import { getFirstPaymentDetailsData } from "./functions";
import { CircularProgress } from "@material-ui/core";
import { FirstPaymentDetailsData } from "../../../../types/Annuities/FirstPayment/FirstPayment";
import { BannerContext } from "../../../../context/BannerContext";
import { formatDollarAmount } from "../../../../util";

const initialFirstPaymentDetailsData: FirstPaymentDetailsData = {
  paymentInfo: {
    payFileKey: "Loading...",
    investmentAccountNo: "Loading...",
    paymentDate: "Loading...",
    checkNumber: "Loading...",
    documentId: "Loading...",
    grossPayment: 0,
    federalTaxes: 0,
    stateTaxes: 0,
    offsets: 0,
    netPayment: 0,
    claimCenter: "Loading...",
  },
  claimantInfo: {
    firstName: "Loading...",
    lastName: "Loading...",
    tin: "Loading...",
    accountNo: "Loading...",
    country: "Loading...",
    address1: "Loading...",
    address2: "Loading...",
    city: "Loading...",
    state: "Loading...",
    postalCode: "Loading...",
    freeformNameInd: false,
    freeformName: "Loading...",
  },
  ticketInfo: {
    gameName: "Loading...",
    drawDate: "Loading...",
    claimDate: "Loading...",
    lifetimePayment: false,
    payFrequency: "Loading...",
    winAmount: 0,
    bookNumber: "Loading...",
  },
};

export const FirstPaymentDetails = () => {
  const bannerContext = useContext(BannerContext);
  const [firstPaymentDetails, setFirstPaymentDetails] = useState<any>(initialFirstPaymentDetailsData);
  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
  const firstPaymentId = window.location.href.split("/")[window.location.href.split("/").length - 1];

  useEffect(() => {
    const getFirstPaymentDetails = async () => {
      try {
        const tempFirstPaymentDetails = await getFirstPaymentDetailsData(firstPaymentId);
        setFirstPaymentDetails(tempFirstPaymentDetails);
        setRequestCompleted(true);
      } catch (error) {
        setRequestCompleted(true);
        bannerContext.setBannerInfo({ message: `${error}`, error: true });
      }
    };

    getFirstPaymentDetails();
  }, []);

  const getGameType = (gameName: string): string => {
    switch (gameName) {
      case "Instant":
        return "Instant";
      case "Promotion":
        return "Promotion";
      default:
        return "Draw";
    }
  };
  return (
    <>
      {/* Circular Progress initial Load screen */}
      {requestCompleted === false && (
        <div
          style={{
            position: "absolute",
            zIndex: 50,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.3)",
            color: "white",
          }}
        >
          <CircularProgress
            size="8em"
            color="inherit"
            thickness={2}
          />
        </div>
      )}
      <div className={styles["page-container"]}>
        <div className={`${styles.flex_space_bw} ${styles.inner_container}`}>
          <div className={`${styles.inherit_container}`}>
            <div className={`${styles.game_ticket_container}`}>
              <h4>Ticket Info</h4>
              <div className={`${styles.grid_col_2}`}>
                <div className={`${styles.game_ticket_detail} ${styles.flex_left}`}>
                  <div>Game Name</div>
                  <div>{firstPaymentDetails.ticketInfo.gameName}</div>
                </div>
                <div className={`${styles.game_ticket_detail} ${styles.flex_left}`}>
                  <div>Game Type</div>
                  <div>{getGameType(firstPaymentDetails.ticketInfo.gameName)}</div>
                </div>
                <div className={`${styles.game_ticket_detail} ${styles.flex_left}`}>
                  <div>Book Number</div>
                  <div>{firstPaymentDetails.ticketInfo.bookNumber}</div>
                </div>
                <div className={`${styles.game_ticket_detail} ${styles.flex_left}`}>
                  <div>Lifetime Winner</div>
                  <div>{firstPaymentDetails.ticketInfo.lifetimePayment ? "Yes" : "No"}</div>
                </div>
                <div className={`${styles.game_ticket_detail} ${styles.flex_left}`}>
                  <div>Pay Frequency</div>
                  <div>{firstPaymentDetails.ticketInfo.payFrequency}</div>
                </div>
              </div>
              <div className={styles["separator"]}></div>
              <div className={`${styles.game_ticket_detail_total} ${styles.flex_left}`}>
                <div>Ticket Win Amount</div>
                <div>{formatDollarAmount(firstPaymentDetails.ticketInfo.winAmount / 100)}</div>
              </div>
            </div>
            <div className={`${styles.original_clmnt_container}`}>
              <h4>Original Claimant Info</h4>
              <div className={`${styles.grid_col_2}`}>
                {firstPaymentDetails.freeformNameInd ? (
                  <>
                    <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                      <div>Freeform Name</div>
                      <div>{firstPaymentDetails.claimantInfo.freeformName}</div>
                    </div>
                    <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                      <div>Mailing Address 1</div>
                      <div>{firstPaymentDetails.claimantInfo.address1}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                      <div>First Name</div>
                      <div>{firstPaymentDetails.claimantInfo.firstName}</div>
                    </div>
                    <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                      <div>Mailing Address 1</div>
                      <div>{firstPaymentDetails.claimantInfo.address1}</div>
                    </div>
                    <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                      <div>Last Name</div>
                      <div>{firstPaymentDetails.claimantInfo.lastName}</div>
                    </div>
                  </>
                )}

                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>Mailing Address 2</div>
                  <div>{firstPaymentDetails.claimantInfo.address2}</div>
                </div>
                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>TIN</div>
                  <div>{firstPaymentDetails.claimantInfo.tin}</div>
                </div>
                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>City</div>
                  <div>{firstPaymentDetails.claimantInfo.city}</div>
                </div>
                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>Account #</div>
                  <div>{firstPaymentDetails.claimantInfo.accountNo}</div>
                </div>
                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>State/Region</div>
                  <div>{firstPaymentDetails.claimantInfo.state}</div>
                </div>
                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>Country</div>
                  <div>{firstPaymentDetails.claimantInfo.country}</div>
                </div>
                <div className={`${styles.original_clmnt_detail} ${styles.flex_left}`}>
                  <div>Zip/Postal Code</div>
                  <div>{firstPaymentDetails.claimantInfo.postalCode}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.payment_container}`}>
            <h4>Payment Info</h4>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Payment Date</div>
              <div>{moment(firstPaymentDetails.paymentInfo.paymentDate).tz("America/New_York").format("MM/DD/YYYY")}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Check #</div>
              <div>{firstPaymentDetails.paymentInfo.checkNumber}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Investment Account No.</div>
              <div>{firstPaymentDetails.paymentInfo.investmentAccountNo}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Document ID No.</div>
              <div>{firstPaymentDetails.paymentInfo.tpaDocumentId}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Pay File Key</div>
              <div>{firstPaymentDetails.paymentInfo.payFileKey}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Claim Center Location</div>
              <div>{firstPaymentDetails.paymentInfo.claimCenter}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Gross Payment</div>
              <div>{formatDollarAmount(firstPaymentDetails.paymentInfo.grossPayment / 100)}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Federal Taxes Withheld</div>
              <div>{formatDollarAmount(firstPaymentDetails.paymentInfo.federalTaxes / 100)}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>State Taxes Withheld</div>
              <div>{formatDollarAmount(firstPaymentDetails.paymentInfo.stateTaxes / 100)}</div>
            </div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Offsets Withheld</div>
              <div>{formatDollarAmount(firstPaymentDetails.paymentInfo.offsets / 100)}</div>
            </div>
            <div className={styles["separator"]}></div>
            <div className={`${styles.payment_detail} ${styles.flex_left}`}>
              <div>Net Payment</div>
              <div>{formatDollarAmount(firstPaymentDetails.paymentInfo.netPayment / 100)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
