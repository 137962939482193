import React from "react"
import './styles.scss'
import { VoidOrReissueDetailsDisplayRow } from "../void-or-reissue-details/void-or-reissue-details"

export type VoidOrReissueTableUIProps = {
  columns: string[]
  data: VoidOrReissueDetailsDisplayRow
}

const ROOT_CLASS = 'table-ui'
export const VoidOrReissueTableUI = (props: VoidOrReissueTableUIProps) => {
  return (
    <div className={ ROOT_CLASS }> 
      <div className={ 'header-row' }>
        <div className={ 'pl-2' }>{props.columns[0]}</div>
        <div className={ 'pr-2 right-column' }>{props.columns[1]}</div>
      </div>
      <div className={ 'data-row' }>
        { 
          Object.values(props.data).map((val, idx) => {
            return <div className={ idx === 0 ? 'pl-2' : 'pr-2 right-column' }>{val}</div>
          })
        }
      </div>
    </div>
  )
}