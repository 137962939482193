import config from './../../../config'

import axios from 'axios'
const data = require('./__test__/test_data.json')

export const makeHttpCall = async (options) => {
  const headers = { 'Content-Type': 'application/json' }
  Object.assign(options, { headers: headers })

  try {
    let response = await axios(options)
    return response.data
  } catch (e) {
    throw new Error('makeHttpCall____________' + e.message)
  }
}

export const getData = async (playerId, setPageMessage) => {
  const options = {
    method: 'get',
    url: config.SERVER_BASE_URL + '/v1/player/getdocument/' + playerId,
  }

  try {
    const data = await makeHttpCall(options)

    if (data.message !== undefined) {
      setPageMessage({
        message: `Player ${playerId} not fount`,
        messageType: 'ERROR',
      })
      return []
    }

    return data.documents
  } catch (e) {
    setPageMessage({
      message: `Something went wrong. Please try again.`,
      messageType: 'ERROR',
    })
  }
}

function getMonth(val) {
  switch (val) {
    case '01':
      return 'Jan'
    case '02':
      return 'Feb'
    case '03':
      return 'Mar'
    case '04':
      return 'Apr'
    case '05':
      return 'May'
    case '06':
      return 'Jun'
    case '07':
      return 'Jul'
    case '08':
      return 'Aug'
    case '09':
      return 'Sep'
    case '10':
      return 'Oct'
    case '11':
      return 'Nov'
    case '12':
      return 'Dec'
    default:
      return 'undefined'
  }
}

export function getFormattedCreationDate(value) {
  let date = value.split('T')[0]
  date = date.split('-')
  let year = date[0]
  return `${getMonth(date[1])} ${date[2]}, ${year}`
}
