import { Buffer, Workbook, Worksheet } from "exceljs";

const savePDF = (contents: any, fileName: string) => {
  fileName = `${fileName}.pdf`;
  const blob = new Blob([contents], { type: "application/pdf" });
  saveFile(blob, fileName);
};

const saveXLSX = (contents: Buffer, fileName: string) => {
  fileName = `${fileName}.xlsx`;
  const responseType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const blob = new Blob([contents], { type: responseType });
  saveFile(blob, fileName);
};

const saveFile = (blob: Blob, fileName: string) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
};

const autoFitColumns = (worksheet: Worksheet) => {
  const minWidth = 10;
  worksheet.columns.forEach((column) => {
    if (column.values) {
      // Each column should have a minimum width of 10, even if no values are present.
      const maxWidth = Math.max(
        ...column.values
          .map((value) => value?.toString().length ?? minWidth)
          .filter((value) => !isNaN(value))
      );
      column.width = Math.max(minWidth, maxWidth);
    }
  });
  return worksheet;
};
const createSheetWithHeaders = (
  workbook: Workbook,
  columnNames: string[],
  sheetTitle: string
) => {
  const sheet = workbook.addWorksheet(sheetTitle);
  sheet.addRow(columnNames);
  sheet.getRow(1).font = { bold: true };
  return sheet;
};

export { autoFitColumns, createSheetWithHeaders, savePDF, saveXLSX };
