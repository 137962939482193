import React from "react";
import styles from "./css/styles.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const ErrorMessageFieldCommonComponent = (errorMessages: any) => {
  return (
    <>
      <div className={styles["error-message-container"]}>
        <div className={styles["error-message-carret"]}>
          <FontAwesomeIcon icon={faCaretUp as IconProp} />
        </div>
        <div
          role="alert"
          aria-live={"assertive"}
          className={styles["error-message-text-container"]}
        >
          {Object.keys(errorMessages).map((e: any, index: number) => (
            <div key={index} className={styles["error-message-text"]}>
              {errorMessages[e]}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const SuccessMessageFieldCommonComponent = (errorMessages: any) => {
  return (
    <>
      <div tabIndex={0} className={styles["error-message-container"]}>
        <div className={styles["success-message-carret"]}>
          <FontAwesomeIcon icon={faCaretUp as IconProp} />
        </div>
        <div
          role="alert"
          tabIndex={0}
          aria-live={"assertive"}
          className={styles["success-message-text-container"]}
        >
          {Object.keys(errorMessages).map((e: any) => (
            <div className={styles["success-message-text"]}>{errorMessages[e]}</div>
          ))}
        </div>
      </div>
    </>
  );
};
