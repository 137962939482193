import * as React from 'react'

type CircleInfoProps = {
  altText?: string
}

export const CircleInfo = (props: CircleInfoProps) => (
  <svg
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{props.altText ?? 'Info Icon'}</title>
    <g
      id="circle-info"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.328125,9.84375 C5.93847656,9.84375 5.625,10.1572266 5.625,10.546875 C5.625,10.9365234 5.93847656,11.25 6.328125,11.25 L8.671875,11.25 C9.06152344,11.25 9.375,10.9365234 9.375,10.546875 C9.375,10.1572266 9.06152344,9.84375 8.671875,9.84375 L8.4375,9.84375 L8.4375,7.265625 C8.4375,6.87597656 8.12402344,6.5625 7.734375,6.5625 L6.328125,6.5625 C5.93847656,6.5625 5.625,6.87597656 5.625,7.265625 C5.625,7.65527344 5.93847656,7.96875 6.328125,7.96875 L7.03125,7.96875 L7.03125,9.84375 L6.328125,9.84375 Z M7.5,5.625 C8.01776695,5.625 8.4375,5.20526695 8.4375,4.6875 C8.4375,4.16973305 8.01776695,3.75 7.5,3.75 C6.98223305,3.75 6.5625,4.16973305 6.5625,4.6875 C6.5625,5.20526695 6.98223305,5.625 7.5,5.625 Z"
        id="Shape"
        fill="#1D4ED8"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
