import { FormikValues } from "formik";
import config from "../../../../../config.js";
import { makeHttpCall } from "../../../../../services/MakeHttpCall";
import { Payee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { emptyStringToUndefined } from "../../../../../util/";


//formik values validate
export const validate = (values: Payee) => {
  let errors = {};

  if (values.address1 === "") {
    Object.assign(errors, { address1: "This field is Required" });
  }

  if (values.city === "") {
    Object.assign(errors, { city: "This field is Required" });
  }

  if (values.state === "") {
    Object.assign(errors, { state: "This field is Required" });
  }

  if (values.zip === "") {
    Object.assign(errors, { zip: "This field is Required" });
  }

  if (values.zip.length < 5) {
    Object.assign(errors, {
      zip: "This field must be at least 5 digits long",
    });
  }

  if (values.phone) {
    const trimmedPhone = values.phone.replace(/\-/g, "");
    if (trimmedPhone.length < 10) {
      Object.assign(errors, { phone: "Invalid Phone Number" });
    }
  }

  if (values.country === "") {
    Object.assign(errors, { country: "This field is Required" });
  }

  if (values.email && values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    Object.assign(errors, { email: "Invalid email address" });
  }

  if (values.irsName === "") {
    Object.assign(errors, { irsName: "This field is Required" });
  }

  // Individual validation
  if (values.payeeType === "Individual") {
    if (values.firstName === "") {
      Object.assign(errors, { firstName: "This field is Required" });
    }
    if (values.lastName === "") {
      Object.assign(errors, { lastName: "This field is Required" });
    }
  }

  // Entity validation
  if (values.payeeType === "Entity") {
    if (values.freeformName === "") {
      Object.assign(errors, { freeformName: "This field is Required" });
    }
  }

  return errors;
};

//update Payee
export const updatePayee = async (id: string, newPayeeInfo: FormikValues) => {
  const payee = {
    address1: newPayeeInfo.address1,
    address2: newPayeeInfo.address2 === "" ? undefined : newPayeeInfo.address2,
    city: newPayeeInfo.city,
    country: newPayeeInfo.country,
    dateOfBirth: newPayeeInfo.dateOfBirth === "" ? undefined : newPayeeInfo.dateOfBirth,
    dateOfDeath: newPayeeInfo.dateOfDeath === "" ? undefined : newPayeeInfo.dateOfDeath,
    tin: newPayeeInfo.tin,
    firstName: newPayeeInfo.firstName === "" ? undefined : newPayeeInfo.firstName,
    freeformName: newPayeeInfo.freeformName === "" ? undefined : newPayeeInfo.freeformName,
    lastName: newPayeeInfo.lastName === "" ? undefined : newPayeeInfo.lastName,
    middleInitial: newPayeeInfo.middleInitial === "" ? undefined : newPayeeInfo.middleInitial,
    payeeType: newPayeeInfo.payeeType,
    phone: emptyStringToUndefined(newPayeeInfo.phone),
    email: emptyStringToUndefined(newPayeeInfo.email),
    state: newPayeeInfo.state,
    zip: newPayeeInfo.zip,
    id: newPayeeInfo.id,
    irsName: newPayeeInfo.irsName !== "" ? newPayeeInfo.irsName : undefined,
    createdOn: newPayeeInfo.createdOn,
    validFrom: newPayeeInfo.validFrom,
  };

  const options = {
    method: "PUT",
    url: `${config.SERVER_BASE_URL}/v1/annuities/payee/${id}`,
    data: payee,
  };

  try {
    await makeHttpCall(options);
  } catch {
    throw new Error("Something went wrong Updating Payee");
  }
};
