import axios from 'axios'

export const makeHttpCallFullResponse = async (options: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Content-Type": "application/json",
    ...options.headers,
  };
  Object.assign(options, { headers: headers });
  try {
    return await axios(options);
  } catch (e) {
    throw new Error("makeHttpCall" + e);
  }
};
