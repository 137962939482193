import * as React from 'react'
import { GetFutureReissuePaymentDetailsResponse } from '../../../../api/future-reissue-payment-details-fetcher/api-types'
import { formatCentsToDollars } from '../../../../util'
import './styles.scss'

export type PaymentCheckDetailsUIProps = {
  futureReissuePaymentDetailsData: GetFutureReissuePaymentDetailsResponse
}

const ROOT_CLASS = 'future-reissue-payment-check-details'

export const FutureReissuePaymentDetailsCheckDetailsUI = (
  props: PaymentCheckDetailsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className="check-details-container">
        <div className="row-container-title">Check Details</div>
        <div className={`column-1`}>Check Status</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.status}
        </div>
        <div className={`column-3`}>Gross Payment</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.futureReissuePaymentDetailsData.grossPaymentInCents
          )}
        </div>
        <div className={`column-1`}>Source</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.source}
        </div>
        <div className={`column-3`}>Federal Taxes Withheld</div>
        <div className={`column-4`}>
          {formatCentsToDollars(props.futureReissuePaymentDetailsData.irsTaxInCents)}
        </div>
        <div className={`column-1`}>Annuity ID</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.annuityAccountId}
        </div>
        <div className={`column-3`}>State Taxes Withheld</div>
        <div className={`column-4`}>
          {formatCentsToDollars(props.futureReissuePaymentDetailsData.dorTaxInCents)}
        </div>
        <div className={`column-1`}>Payment Type</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.paymentType}
        </div>
        <div className={`column-3`}>Offsets Collected</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.futureReissuePaymentDetailsData.offsetsWithheldInCents
          )}
        </div>
        <hr className="horizontal-line"></hr>
        <div className={`column-3`}>Net Payment</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.futureReissuePaymentDetailsData.netPaymentInCents
          )}
        </div>
      </div>
    </div>
  )
}
