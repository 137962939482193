import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import styles from './css/styles.module.css';
import {
    ViewOrganizationDetailsComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewOrganizationDetailsComponent";
import {
    ViewHeadOfOrganizationDetailsComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewHeadOfOrganizationDetailsComponent";
import {
    ViewMemberInChargeDetailsComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewMemberInChargeDetailsComponent";
import {
    ViewBookkeeperDetailsComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewBookkeeperDetailsComponent";
import {
    ViewLicenseInformationComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewLicenseInformationComponent";
import {
    ViewBeanoGameInformationComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewBeanoGameInformationComponent";
import {
    downloadLicenseFile,
    handleCheckboxChange,
    onSubmit,
    reactivateLicense,
    setVariables,
    toggleLicenseRenewalStatus,
    toggleLicenseSubmissionStatus,
    validate
} from "./functions";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {keyPressedType} from "../../../../services/Commons";
import {licenseeRoutePath} from "../../../../config";
import {Tab, Tabs} from 'react-bootstrap';
import {
    ViewEFTInformationComponent
} from "../../../../components/CharitableGamingComponents/License/View/ViewEFTInformationComponent";
import licenseActiveIcon from "../../../../icons/Icon_status_approved.svg";
import licenseInactiveIcon from "../../../../icons/icon_denied.svg";
import {useFormik} from "formik";
import {
    ReactivateLicenseConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/ReactivateLicenseConfirmationComponent";
import {NotificationBannerComponent} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import ToggleSwitchComponent from "../../../../components/CommonComponents/ToggleSwitchComponent";
import {
    PullTabOrderItemHistorySummaryComponent
} from "../../../../components/CharitableGamingComponents/PullTab/Orders/PullTabOrderItemHistorySummaryComponent";
import {
    PullTabOrderOverviewHistorySummaryComponent
} from "../../../../components/CharitableGamingComponents/PullTab/Orders/PullTabOrderOverviewHistorySummaryComponent";
import {formatCentsToDollars} from "../../../../util";
import {CircularProgress} from "@material-ui/core";

export const LicenseeDetailsPage = inject("charitableGamingStore")(observer(({licenseNumber, charitableGamingStore}: any) => {

        const history: any = useHistory();
        const userpolicies: any = localStorage.getItem("userpolicies");
        const location = useLocation();

        const {selectedLicense, selectedLicenseEFTInfo, notificationBanner} = charitableGamingStore;

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
        const [showReactivateLicenseConfirmation, setShowReactivateLicenseConfirmation] = useState<boolean>(false);

        const isLicenseActive = selectedLicense.licenseDetails.active;

        const formik = useFormik({
            initialValues: {
                renewing: selectedLicense.licenseDetails.markedForRenewal,
                submitting: selectedLicense.licenseDetails.submitting
            },
            validate,
            onSubmit
        });

        const [currentTab, setCurrentTab] = useState<string | null>('licenseDetails');

        useEffect(() => {
            if (requestCompleted === false) {
                charitableGamingStore.getLicenseeDetailsByLicenseNumber(licenseNumber, setRequestCompleted);
                charitableGamingStore.getEFTInformation(licenseNumber, setRequestCompleted);
                charitableGamingStore.getPullTabOrderHistorySummary(licenseNumber, setRequestCompleted, setShowErrorDialog);
            }
            if (location.state !== undefined) {
                setCurrentTab(location.state.tabName);
            } else {
                setCurrentTab('pullTabOrderHistory');
            }
        }, []);

        const hideNotificationBanner = () => {
            charitableGamingStore.showNotificationBanner({show: false, text: ''});
        }

        setVariables(licenseNumber, formik, setRequestCompleted, charitableGamingStore);

        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['license-details-page-container']}`}
                     onClick={() => {
                         if (notificationBanner.text !== "") {
                             // hideNotificationBanner();
                         }
                     }}
                >
                    <div className={`${styles['license-details-page-back-button-container']} ${styles['']}`}>
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['license-details-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${licenseeRoutePath}/manage`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${licenseeRoutePath}/manage`);
                            }}
                        >Back to License Profile Management
                        </div>
                    </div>

                    <div className={`${styles["license-details-page-ribbon-container"]}`}>
                        <div className={`${styles["license-details-page-ribbon-container-col-1"]}`}>
                            <div className={styles["license-details-page-ribbon-title-container"]}>
                                <div className={styles["license-details-page-ribbon-title-label"]}>License</div>
                                <div
                                    className={styles["license-details-page-ribbon-title-value"]}>{`#${selectedLicense.licenseDetails.licenseNumber}`}</div>
                            </div>
                            <div className={styles["license-details-page-ribbon-subtitle-container"]}>
                                <div className={styles["license-details-page-ribbon-subtitle-label"]}>Organization</div>
                                <div
                                    className={styles["license-details-page-ribbon-subtitle-value"]}>{selectedLicense.orgDetails.orgName}</div>
                                <div className={styles["license-details-page-ribbon-subtitle-label"]}>Credit Balance</div>
                                <div
                                    className={styles["license-details-page-ribbon-subtitle-value"]}>{formatCentsToDollars(selectedLicense.licenseDetails.creditBalanceInCents)}</div>
                                <div className={styles["table-data-data-text-icon-container"]}>
                                    <div
                                        className={styles["table-data-data-text"]}>{(selectedLicense.licenseDetails.active) ? "Active" : "Expired"}</div>
                                    <img
                                        src={(selectedLicense.licenseDetails.active) ? licenseActiveIcon : licenseInactiveIcon}
                                        alt="status icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["license-details-page-ribbon-container-col-2"]}`}>
                            <div
                                className={`${styles['license-profile-mgmt-page-renewing-checkbox-container']} ${styles['']}`}
                            >
                                {/*<div tabIndex={0}
                                     className={`${styles['license-profile-mgmt-page-checkbox']} ${styles['']}`}
                                     onKeyDown={(e: any) => {
                                         if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                             if (isLicenseActive) {
                                                 handleCheckboxChange('renewing');
                                                 toggleLicenseRenewalStatus();
                                             }
                                         }
                                     }}
                                     onClick={(e: any) => {
                                         if (isLicenseActive) {
                                             handleCheckboxChange('renewing');
                                             toggleLicenseRenewalStatus();
                                         }
                                     }}
                                >
                                    <CheckboxComponent isChecked={selectedLicense.licenseDetails.markedForRenewal}/>
                                </div>*/}
                                <div
                                    className={`${styles['license-profile-mgmt-page-search-bar-filter-active']} ${styles['']}`}>Renewing
                                </div>
                                <ToggleSwitchComponent
                                    id={`renewing`}
                                    name={`renewing`}
                                    isOn={selectedLicense.licenseDetails.markedForRenewal}
                                    handleToggle={() => {
                                        if (isLicenseActive) {
                                            handleCheckboxChange('renewing');
                                            toggleLicenseRenewalStatus();
                                        }
                                    }}
                                />

                            </div>
                            <div
                                className={`${styles['license-profile-mgmt-page-submitting-checkbox-container']} ${styles['']}`}
                            >
                                {/*<div tabIndex={0}
                                     className={`${styles['license-profile-mgmt-page-checkbox']} ${styles['']}`}

                                     onKeyDown={(e: any) => {
                                         if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                             if (isLicenseActive) {
                                                 handleCheckboxChange('submitting');
                                                 toggleLicenseSubmissionStatus();
                                             }
                                         }
                                     }}
                                     onClick={(e: any) => {
                                         if (isLicenseActive) {
                                             handleCheckboxChange('submitting');
                                             toggleLicenseSubmissionStatus();
                                         }
                                     }}
                                >
                                    <CheckboxComponent isChecked={selectedLicense.licenseDetails.submitting}/>
                                </div>*/}
                                <div
                                    className={`${styles['license-profile-mgmt-page-search-bar-filter-active']} ${styles['']}`}>Submitting
                                </div>
                                <ToggleSwitchComponent
                                    id={`submitting`}
                                    name={`submitting`}
                                    isOn={selectedLicense.licenseDetails.submitting}
                                    handleToggle={() => {
                                        if (isLicenseActive) {
                                            handleCheckboxChange('submitting');
                                            toggleLicenseSubmissionStatus();
                                        }
                                    }}
                                />

                            </div>
                            {isLicenseActive ?
                                checkRenderPermissions(permissions.CAN_DOWNLOAD_LICENSE_FILE, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0}
                                         className={`${styles['license-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                         role="button"
                                         onKeyDown={(ev: any) => {
                                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                 downloadLicenseFile();
                                             }
                                         }}
                                         onClick={() => {
                                             downloadLicenseFile();
                                         }}>
                                        <div
                                            className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                            Download License File
                                        </div>
                                    </div> : null
                                :
                                checkRenderPermissions(permissions.CAN_REACTIVATE_LICENSE, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0}
                                         className={`${styles['license-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                         role="button"
                                         onKeyDown={(ev: any) => {
                                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                 setShowReactivateLicenseConfirmation(true);
                                             }
                                         }}
                                         onClick={() => {
                                             setShowReactivateLicenseConfirmation(true);
                                         }}>
                                        <div
                                            className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                            Reactivate
                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div>

                    <div className={`${styles["license-details-page-tabs-container"]}`}>
                        <Tabs
                            defaultActiveKey={'licenseDetails'}
                            activeKey={currentTab ? currentTab : 'licenseDetails'}
                            className={`${styles["license-details-tabs"]}`}
                            id="license-details-tabs"
                            onSelect={(k) => setCurrentTab(k)}
                        >
                            <Tab eventKey="licenseDetails" title="License Details">
                                <div className={`${styles["license-details-tab-main-container"]}`}>
                                    <div className={`${styles["license-details-tab-main-container-row-1"]}`}>
                                        <ViewOrganizationDetailsComponent licenseeDetails={selectedLicense}/>
                                    </div>
                                    <div className={`${styles["license-details-tab-main-container-row-2"]}`}>
                                        <ViewBeanoGameInformationComponent licenseeDetails={selectedLicense}/>
                                    </div>
                                    <div className={`${styles["license-details-tab-main-container-row-3"]}`}>
                                        <div className={`${styles["license-details-tab-main-container-row-3-col-1"]}`}>
                                            <ViewLicenseInformationComponent licenseeDetails={selectedLicense}/>
                                        </div>
                                        <div className={`${styles["license-details-tab-main-container-row-3-col-2"]}`}>
                                            <ViewMemberInChargeDetailsComponent licenseeDetails={selectedLicense}/>
                                        </div>
                                    </div>
                                    <div className={`${styles["license-details-tab-main-container-row-4"]}`}>
                                        <div className={`${styles["license-details-tab-main-container-row-4-col-1"]}`}>
                                            <ViewHeadOfOrganizationDetailsComponent licenseeDetails={selectedLicense}/>
                                        </div>
                                        <div className={`${styles["license-details-tab-main-container-row-4-col-2"]}`}>
                                            <ViewBookkeeperDetailsComponent licenseeDetails={selectedLicense}/>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="EFTInformation" title="EFT Information">
                                <div className={`${styles["eft-info-tab-main-container"]}`}>
                                    {checkRenderPermissions(permissions.CAN_VIEW_EFT_DETAILS, JSON.parse(userpolicies)) ?
                                        <ViewEFTInformationComponent licenseNumber={licenseNumber}
                                                                     EFTInformation={selectedLicenseEFTInfo}/> : null}
                                </div>
                            </Tab>
                            <Tab eventKey="pullTabOrderHistory" title="Purchase Order History">
                                <div className={`${styles["pull-tab-history-tab-main-container"]}`}>
                                    <PullTabOrderItemHistorySummaryComponent/>
                                    <PullTabOrderOverviewHistorySummaryComponent/>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>

                </div>
                {showReactivateLicenseConfirmation ?
                    <ReactivateLicenseConfirmationComponent
                        reactivateLicense={reactivateLicense}
                        setShowReactivateLicenseConfirmation={setShowReactivateLicenseConfirmation}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        text={notificationBanner.text}
                        type={notificationBanner.type}
                        handleHide={hideNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);