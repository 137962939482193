import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { PayeeFilter, Payee } from "../../../../types/Annuities/PayeeManagement/Payee";
import config from "../../../../config.js";
import axios from "axios";
import moment from "moment/moment";
import {ExportToCsv} from "export-to-csv";

export const getPayeeList = async (): Promise<Payee[]> => {
  let payeeList: Payee[];

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/payee`,
  };

  try {
    payeeList = await makeHttpCall(options);
  } catch (error) {
    payeeList = [];
    throw new Error("Something went wrong Getting Payees");
  }

  return payeeList;
};

export const applyFilters = (setPayeeList: any, unfilteredPayeeList: Payee[], payeeFilterData: PayeeFilter) => {
  let tempList: Payee[] = unfilteredPayeeList;

  if(payeeFilterData.firstName !== "") {
    tempList = tempList.filter(payee => payee.payeeType === "Individual" ? (payee.firstName.toLowerCase() === payeeFilterData.firstName.toLowerCase()) : false);
  }

  if(payeeFilterData.lastName !== "") {
    tempList = tempList.filter(payee => payee.payeeType === "Individual" ? (payee.lastName.toLowerCase() === payeeFilterData.lastName.toLowerCase()) : false);
  }

  if(payeeFilterData.tin !== "") {
    tempList = tempList.filter(payee => payee.tin === payeeFilterData.tin);
  }

  setPayeeList(tempList);
}

// Csv
export const exportToCsv = (payeeData: Payee[], csvExport: any) => {
  // create new object per Check Run Data to produce csv
  const rows: any[] = Object.values(payeeData).map(individualPayeeData => {
    let name;

    if (individualPayeeData.payeeType === "Individual") {
      name = `${individualPayeeData.firstName} ${individualPayeeData.lastName}`;
    } else {
      name = `${individualPayeeData.freeformName}`;
    }

    const newData = {
      name: name,
      tin: individualPayeeData.tin,
      type: individualPayeeData.payeeType,
    };

    return newData;
  });

  csvExport.generateCsv(rows);
};


export const downloadCtiReportCSV = async function () {
  try {
    const options: any = {
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/v1/annuities/payee/pbiexport`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json text/csv',
      }
    }
    const response = await axios(options);
    const fileOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: 'PBI_Export_' + moment().format("YYYYMMDD"),
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ["AccountNumber", "TIN", "FirstName", "MiddleName", "LastName", "DateOfBirth", "City",
        "State", "Zip"]
    };
    const csvExporter = new ExportToCsv(fileOptions);
    await csvExporter.generateCsv(response.data);
  } catch (e) {
    console.log(e);
  }
}