import moment from "moment-timezone";
import React, { useState } from "react";
import { KEY_CODES, permissions } from "../../../../../constants.js";
import { checkRenderPermissions } from "../../../../../helpers.js";
import { keyPressedType } from "../../../../../services/Commons";
import { FormPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import styles from "./css/styles.module.css";

export const PayeeDetailsReadOnly: React.FC<{
  payee: FormPayee;
  toggleEditable: () => void;
}> = ({ payee, toggleEditable }) => {
  const userpolicies: any = localStorage.getItem("userpolicies");
  const canSeeSSN = checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies));
  const [ssnUnmasked, setSsnUnmasked] = useState<boolean>(false);

  return (
    <div className={styles["cmpnt-container"]}>
      <div className={`${styles.grid_master_col_2}`}>
        {/* payee info */}
        <div>
          <div className={`${styles.payee_container}`}>
            <div className={`${styles.component_title}`}>Primary Info</div>
            <div className={`${styles.grid_col_2}`}>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                {payee.payeeType === "Individual" ? (
                  <>
                    <div>First Name</div>
                    <div>{payee.firstName}</div>
                  </>
                ) : (
                  <>
                    <div>Freeform Name</div>
                    <div>{payee.freeformName}</div>
                  </>
                )}
              </div>
              {payee.payeeType === "Individual" && (
                <>
                  <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                    <div>Middle Initial</div>
                    <div>{payee.middleInitial}</div>
                  </div>
                  <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                    <div>Last Name</div>
                    <div>{payee.lastName}</div>
                  </div>
                </>
              )}

              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Creation Date</div>
                <div>{moment(payee.createdOn).tz("America/New_York").format("MM/DD/YYYY")}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>TIN</div>
                <div>{payee.tin}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>IRS Name</div>
                <div>{payee.irsName}</div>
              </div>
            </div>
          </div>
          <div className={`${styles.payee_container}`}>
            <div className={`${styles.component_title}`}>Demographic &amp; Contact Info</div>
            <div className={`${styles.grid_col_2}`}>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Mailing Address 1</div>
                <div>{payee.address1}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Mailing Address 2</div>
                <div>{payee.address2}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>City</div>
                <div>{payee.city}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>State/Region</div>
                <div>{payee.state}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Zip/Postal Code</div>
                <div>{payee.zip}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Country</div>
                <div>{payee.country}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Phone</div>
                <div>{payee.phone}</div>
              </div>
              <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                <div>Email</div>
                <div>{payee.email}</div>
              </div>
              {payee.payeeType === "Individual" && (
                <>
                  <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                    <div>Birth Date</div>
                    <div>{payee.dateOfBirth ? moment(payee.dateOfBirth).format("MM/DD/YYYY") : "-"}</div>
                  </div>
                  <div className={`${styles.payee_detail} ${styles.flex_left}`}>
                    <div>Death Date</div>
                    <div>{payee.dateOfDeath ? moment(payee.dateOfDeath).format("MM/DD/YYYY") : "-"}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* edit button */}
        <div className={`${styles.btn_container}`}>
          <button
            // added random CHECK field to check if data hasn't been loaded yet which disables button until load
            className={payee.state === "" ? `${styles.light_btn_disabled}` : `${styles.light_btn}`}
            disabled={payee.state === "" ? true : false}
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                toggleEditable();
              }
            }}
            onClick={() => {
              toggleEditable();
            }}
          >
            Update Payee Details
          </button>
        </div>
      </div>
    </div>
  );
};
