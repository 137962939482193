import * as React from 'react'

export const ActionMenuIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Action Menu</title>
    <g
      id="circle-ellipsis-vertical"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M27,0 C29.7614237,-5.07265313e-16 32,2.23857625 32,5 L32,27 C32,29.7614237 29.7614237,32 27,32 L5,32 C2.23857625,32 3.38176876e-16,29.7614237 0,27 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L27,0 Z M25.5384615,2.46153846 L6.46153846,2.46153846 C4.25239946,2.46153846 2.46153846,4.25239946 2.46153846,6.46153846 L2.46153846,25.5384615 C2.46153846,27.7476005 4.25239946,29.5384615 6.46153846,29.5384615 L25.5384615,29.5384615 C27.7476005,29.5384615 29.5384615,27.7476005 29.5384615,25.5384615 L29.5384615,6.46153846 C29.5384615,4.25239946 27.7476005,2.46153846 25.5384615,2.46153846 Z M16,20 C17.1045695,20 18,20.8954305 18,22 C18,23.1045695 17.1045695,24 16,24 C14.8954305,24 14,23.1045695 14,22 C14,20.8954305 14.8954305,20 16,20 Z M16,14 C17.1045695,14 18,14.8954305 18,16 C18,17.1045695 17.1045695,18 16,18 C14.8954305,18 14,17.1045695 14,16 C14,14.8954305 14.8954305,14 16,14 Z M16,8 C17.1045695,8 18,8.8954305 18,10 C18,11.1045695 17.1045695,12 16,12 C14.8954305,12 14,11.1045695 14,10 C14,8.8954305 14.8954305,8 16,8 Z"
        id="Combined-Shape"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
