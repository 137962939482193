import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, getPageData, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import moment from "moment";
import {
    ErrorMessageFieldCommonComponent
} from "../../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {PaginationCommonComponent} from "../../../../components/CommonComponents/PaginationCommonComponent";
import {reportingRoutePath} from "../../../../config";
import {formatCentsToDollars} from "../../../../util";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import { OffsetSystemTotals, OffsetSystemTotalsDefaultData } from "./types";

export const OffsetsReportingPage = () => {

    const history: any = useHistory();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }, validate, onSubmit
    });

    const activateSearchButton = (formik.isValid && formik.dirty);

    const [offsetTotals, setOffsetTotals] = useState<OffsetSystemTotals>(OffsetSystemTotalsDefaultData);
    const [itemizedTransactionReports, setACHBatchesDetails] = useState<any>([]);

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    useEffect(() => {
        if (requestCompleted === false) {
            formik.submitForm();
        }
    }, []);

    setVariables(formik, setCurrentPage, setShowErrorDialog, setRequestCompleted, setOffsetTotals, setACHBatchesDetails);

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['licensee-charges-page-container']}`}
            >
                <div tabIndex={0}
                     className={`${styles['licensee-charges-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['licensee-charges-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to Reporting
                    </div>
                </div>
                <div className={`${styles['licensee-charges-page-ribbon-container']}`}>
                    <div className={`${styles['licensee-charges-page-ribbon-title']} ${styles['']}`}>Offsets Reporting
                    </div>
                </div>

                <div className={`${styles['licensee-charges-page-search-bar-container']} ${styles['']}`}>

                    <div
                        className={`${styles['licensee-charges-page-search-bar-filter-title']} ${styles['']}`}>Filter
                    </div>

                    <div className={`${styles['licensee-charges-page-datetime-picker-container']}`}>
                        <div
                            className={`${styles['licensee-charges-page-label-datepicker-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Select From Date
                            </div>
                            <input
                                className={`${styles['mslc-datetime-picker']}`}
                                type={'date'}
                                id="startDate"
                                name="startDate"
                                value={formik.values.startDate}
                                onBlur={formik.handleBlur}
                                onChange={(e: any) => {
                                    setFieldValue(e, formik);
                                }}
                                max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
                                placeholder={'mm/dd/yyyy'}
                            />
                            {(formik.touched.startDate && formik.errors.startDate) ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.startDate}/> : null}
                        </div>
                        <div
                            className={`${styles['licensee-charges-page-label-datepicker-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>Select Through Date
                            </div>
                            <input
                                className={`${styles['mslc-datetime-picker']}`}
                                type={'date'}
                                id="endDate"
                                name="endDate"
                                value={formik.values.endDate}
                                onBlur={formik.handleBlur}
                                onChange={(e: any) => {
                                    setFieldValue(e, formik);
                                }}
                                min={formik.values.startDate}
                                max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
                                placeholder={'mm/dd/yyyy'}
                                disabled={!formik.values.startDate}
                            />
                            {(formik.touched.endDate && formik.errors.endDate) ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.endDate}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>

                </div>
            <div className={`${styles["row-4-col-2-container"]}`}>
                <div className={`${styles["row-container-title"]}`}>Summary</div>
                <div className={`${styles['total-order-summary-controls-container']}`}>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-1']} ${styles['mslc-label']}`}>
                        Check Writer Offsets Withheld
                    </div>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>
                        {formatCentsToDollars(offsetTotals.systemTotals.totalCheckWriterWithholdingsInCents)}
                    </div>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-1']} ${styles['mslc-label']}`}>
                        Claim Center Offsets Withheld
                    </div>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>
                        {formatCentsToDollars(offsetTotals.systemTotals.totalClaimCenterWithholdingsInCents)}
                    </div>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-1']} ${styles['mslc-label']}`}>
                        Mobile Claim Center Offsets Withheld
                    </div>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>
                        {formatCentsToDollars(offsetTotals.systemTotals.totalRTCWithholdingsInCents)}
                    </div>
                </div>
                <hr className={styles['horizontal-line']} />
                <div className={`${styles['total-order-summary-controls-container']}`}>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Grand
                        Total Offsets Withheld
                    </div>
                    <div
                        className={`${styles['total-order-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>
                        {formatCentsToDollars(offsetTotals.grandTotalWithholdingsInCents)}
                    </div>
                </div>
            </div>

                {itemizedTransactionReports.length > 0 ? <div className={styles['table']}>

                    <div className={styles['result-totals-container']}>
                        <div className={styles['result-totals-container-title']}>Itemized Transaction Reports</div>
                    </div>

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div>Report</div>
                        <div>Batch Ref.</div>
                        <div>Record Count</div>
                        <div>Total Amount</div>
                        <div>Date</div>
                    </div>

                    <div className={styles['table-data-body']}>
                        {itemizedTransactionReports.length > 0 && getPageData(itemizedTransactionReports, currentPage).map((e: any, i: number) =>

                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div className={styles["table-data-data-text"]}>
                                    {checkRenderPermissions(permissions.CAN_SEE_OFFSETS_BY_ACH, JSON.parse(userpolicies)) ?
                                        <div
                                            tabIndex={0}
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                fontFamily: '\'Barlow-Regular\', \'Barlow\'',
                                            }}
                                            onKeyDown={(ev: any) => {
                                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                    history.push({
                                                        pathname: `${reportingRoutePath}/finance/offsets/reporting/ach/${e.batchnumber}`,
                                                        state: {
                                                            backButtonText: 'Back to Offsets Reporting'
                                                        },
                                                    });
                                                }
                                            }}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `${reportingRoutePath}/finance/offsets/reporting/ach/${e.batchnumber}`,
                                                    state: {
                                                        backButtonText: 'Back to Offsets Reporting'
                                                    },
                                                });
                                            }}
                                        >Offsets by ACH
                                        </div> : null}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.batchnumber}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.totalnumberofrecords}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.amount)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {moment(e.filesubmissiondatetime).format('MM/DD/YYYY hh:mm:ss A')}
                                </div>
                            </div>)}
                    </div>
                    <div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((itemizedTransactionReports.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={itemizedTransactionReports}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>
                </div> : <div className={`${styles['licensee-charges-page-search-no-results-container']}`}>
                    <div className={`${styles['licensee-charges-page-search-no-results-container-text']}`}>
                        No Results. Please try a different search.
                    </div>
                </div>}
            </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
        </>);
};