import * as React from 'react'
import { FormikProps, getIn } from 'formik'
import { PromotionStatus } from '../../../../../../api/promotions-fetcher/constants'
import { CircleX } from '../../../../../../icons/circle-x-icon'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../../../utilities/helpers'
import { NumberInput } from '../../../../../CommonComponents/number-input-component/number-input-ui'
import { SideBarTextInput } from '../../../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  promotionIsNewOrPending,
} from '../helpers'
import {
  PromotionCampaignValidation,
  PromotionCampaignValidationRule,
} from '../validation'
import '../styles.scss'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import { PromotionCampaignTooltip } from '../../../promotion-campaign-tooltip/promotion-campaign-tooltip-ui'

type PromotionRuleFormUIProps = {
  rule: PromotionCampaignValidationRule
  index: number
  isLoading: boolean
  formik: FormikProps<PromotionCampaignValidation>
  removeRule: () => Promise<void>
  promotionStatus?: PromotionStatus
}

export const PromotionRuleFormUI = (props: PromotionRuleFormUIProps) => {
  const ruleNumber = props.index + 1

  const updateGameIds = async (gameIds: string[]) => {
    await props.formik.setFieldValue(
      `promotionRewardGroups[0].rules[${props.index}].gameIds`,
      gameIds
    )
  }

  const { addElement: addGameId, removeElement: removeGameId } =
    useFormArray<string>({
      defaultFieldValue: '',
      fieldArray:
        props.formik.values.promotionRewardGroups[0].rules[props.index].gameIds,
      setFieldArray: updateGameIds,
    })

  return (
    <div>
      <div className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'header')}>
        {`Rule ${ruleNumber}`}
        {!props.rule.id && (
          <button
            className={getBlockClass(
              PROMOTION_SIDEBAR_FORM_CLASS,
              'remove-button'
            )}
            onClick={props.removeRule}
            aria-label={`Remove Rule ${ruleNumber}`}
            type="button"
          >
            <CircleX />
          </button>
        )}
      </div>
      <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
        <NumberInput
          label={'Number of Scans'}
          name={`promotionRewardGroups[0].rules[${props.index}].quantity`}
          onChange={props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          value={props.rule.quantity ?? ''}
          error={getIn(
            props.formik.errors,
            `promotionRewardGroups[0].rules[${props.index}].quantity`
          )}
          touched={getIn(
            props.formik.touched,
            `promotionRewardGroups[0].rules[${props.index}].quantity`
          )}
          disabled={props.isLoading}
          min={0}
          step={1}
          required
        />
      </div>
      {props.formik.values.promotionRewardGroups[0].rules[
        props.index
      ].gameIds.map((gameId, index) => {
        return (
          <div
            className={joinClasses([
              PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
              getModifierClass(
                PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                'removable',
                index > 0
              ),
            ])}
            key={`rule-${props.index}-game-id-${index}`}
          >
            <SideBarTextInput
              label={
                index === 0 ? (
                  <div
                    className={getBlockClass(
                      PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                      'label'
                    )}
                  >
                    Game ID
                  </div>
                ) : (
                  'Game ID'
                )
              }
              name={`promotionRewardGroups[0].rules[${props.index}].gameIds[${index}]`}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={gameId}
              error={getIn(
                props.formik.errors,
                `promotionRewardGroups[0].rules[${props.index}].gameIds[${index}]`
              )}
              touched={getIn(
                props.formik.touched,
                `promotionRewardGroups[0].rules[${props.index}].gameIds[${index}]`
              )}
              disabled={
                props.isLoading ||
                !promotionIsNewOrPending(props.promotionStatus)
              }
              greyOnDisabled
              placeholderText="0123"
              onRemove={
                index > 0 && promotionIsNewOrPending(props.promotionStatus)
                  ? () => removeGameId(index)
                  : undefined
              }
              removeButtonAriaLabel={`Remove Game ID ${index}`}
            />
          </div>
        )
      })}
      {promotionIsNewOrPending(props.promotionStatus) && (
        <div
          className={getBlockClass(
            PROMOTION_SIDEBAR_FORM_CLASS,
            'add-game-id-button-container'
          )}
        >
          <button
            type="button"
            className={getBlockClass(
              PROMOTION_SIDEBAR_FORM_CLASS,
              'add-game-id-button'
            )}
            aria-label="Add game ID"
            disabled={props.isLoading}
            onClick={addGameId}
          >
            Add Game ID
          </button>
        </div>
      )}
    </div>
  )
}
