import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  getPlayersDetailsData,
  storesObject,
  testPlayerStoreObjectData,
} from './services/StoresObject'
import eventEmitter from './services/EventEmitter'
import { makeHttpCall } from './services/MakeHttpCall'
import config, {
  playerSupportRoutePath,
  claimsSupportRoutePath,
} from './config'
import {
  claimReferenceNumber,
  claimHistory,
  claimTransaction,
  w2g,
} from './services/StoresObject/Claims'
import { playerDocImages } from './services/StoresObject/Player'
import { checkRenderPermissions } from './helpers.js'
import { permissions } from './constants.js'
import { validate as isValidUUID } from 'uuid'
const userpolicies = localStorage.getItem('userpolicies')
@inject('playerStore')
@inject('claimStore')
@inject('authStore')
@observer
class StoreObjectClass extends React.Component {
  constructor(props) {
    super(props)

    props.playerStore.ticketScannerEvents = []
    props.playerStore.selectedPlayerDetails = JSON.parse(
      JSON.stringify(getPlayersDetailsData)
    )
    props.playerStore.playerDocImages = JSON.parse(
      JSON.stringify(playerDocImages)
    )
    props.claimStore.claimTransaction = JSON.parse(
      JSON.stringify(claimTransaction)
    )
    props.claimStore.claimReferenceNumberr = JSON.parse(
      JSON.stringify(claimReferenceNumber)
    )
    props.claimStore.claimHistory = JSON.parse(JSON.stringify(claimHistory))
    props.claimStore.claimW2G = JSON.parse(JSON.stringify(w2g))

    let routePath = window.location.href.split('/')

    Object.assign(storesObject, { playerStore: props.playerStore })
    Object.assign(storesObject, { claimStore: props.claimStore })
    Object.assign(storesObject, { authStore: props.authStore })

    if (
      routePath.find(
        (e) =>
          (e ===
            playerSupportRoutePath.split('/')[
              playerSupportRoutePath.split('/').length - 1
            ]) !==
          undefined
      )
    ) {
      routePath = routePath[routePath.length - 1]
      if (routePath !== 'search-new') {
        this.getDataFromTheServerForPlayerId(routePath)
      }
    } else if (
      routePath.find(
        (e) =>
          e ===
          claimsSupportRoutePath.split('/')[
            claimsSupportRoutePath.split('/').length - 1
          ]
      ) !== undefined
    ) {
      let claimId = routePath[routePath.length - 1]
      let playerId = routePath[routePath.length - 2]
      playerId = playerId.split('-')
      playerId = playerId.join('-')

      //this.getDataFromTheServerForClaimIdAndPlayerId(playerId, claimId, userpolicies);
    }
  }

  async getDataFromTheServerForPlayerId(playerId) {
    if (!isValidUUID(playerId)) {
      return
    }

    try {
      if (
        checkRenderPermissions(
          permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
          JSON.parse(userpolicies)
        )
      ) {
        const maskSsn = !checkRenderPermissions(
          permissions.CAN_SEE_SSN,
          JSON.parse(userpolicies)
        )
        let queryParam = `?maskSsn=${maskSsn}`
        const options = {
          method: 'get',
          url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
        }

        const response = await makeHttpCall(options)
        storesObject.playerStore.selectedPlayerDetails =
          testPlayerStoreObjectData(response)

        if (
          storesObject.playerStore.selectedPlayerDetails.account.id === playerId
        ) {
          eventEmitter.emit(
            'refresh-data-ready',
            storesObject.playerStore.selectedPlayerDetails
          )
        }
      }
      if (
        checkRenderPermissions(
          permissions.CAN_SEE_CLAIM_ACTIVITY,
          JSON.parse(userpolicies)
        )
      ) {
        const options = {
          method: 'get',
          url: config.SERVER_BASE_URL + `/v1/player/${playerId}/claims`,
        }

        const response = await makeHttpCall(options)
        storesObject.claimStore.claims = response.claims
      }
    } catch (e) {
      eventEmitter.emit('refresh-data-ready', {
        error: 'Something went wrong. Please try again.',
      })
    }
  }

  render() {
    return <></>
  }
}

export default StoreObjectClass
