import moment from 'moment-timezone'
import React from 'react'
import { FaTrophy } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { annuitiesRoutePath } from '../../../../config.js'
import { KEY_CODES } from '../../../../constants.js'
import { PayTaxIndicatorMapping } from '../../../../pages/PaymentsPages/Constants'
import { keyPressedType } from '../../../../services/Commons'
import { PaymentDetails } from '../../../../types/Annuities/PaymentDetails'
import { formatDollarAmount } from '../../../../util'
import { AccountStatusCmpt } from '../AccountStatusCmpt'
import styles from './css/styles.module.css'
import { getTaxDeclaration, mapTaxDeclarationCode } from './functions'

export const PaymentDetailsComponent: React.FC<{
  data: PaymentDetails
}> = ({ data }) => {
  const history = useHistory()

  return (
    <div className={`${styles.cmpnt_container} d-flex flex-column mt-1`}>
      <div
        className={`${styles.main_container} d-flex flex-column rounded mb-3 p-3`}
      >
        {/* account info */}
        <div
          className={`${styles.account_details_container} d-flex flex-column`}
        >
          <div
            className={`${styles.account_details_header} d-flex align-items-center rounded p-3`}
          >
            {data.subAccount.parentAccountId === null ? (
              <>
                <FaTrophy />
                <div
                  className={`${styles.account_details_header_title} font-weight-bold mx-3`}
                >
                  Primary-Account
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.account_details_header_title} font-weight-bold mr-3 `}
                >
                  Sub-Account
                </div>
              </>
            )}
            <div className={`${styles.account_details_header_name}`}>
              {data.payee.payeeType === 'Individual'
                ? `${data.payee.firstName} ${data.payee.lastName}`
                : `${data.payee.freeformName}`}
            </div>
            <div className={`ml-auto mb-1`}>
              <AccountStatusCmpt
                status={data.subAccount.acctStatus}
                notes={data.subAccount.notes}
              />
            </div>
          </div>
          <div className={`p-3 d-flex justify-content-between`}>
            {/* Payment Details */}
            <div
              className={`${styles.payment_details_container} d-flex flex-column`}
            >
              <div className={`d-flex justify-content-between mb-3`}>
                <div>Authorization Date</div>
                <div className="font-weight-bold">
                  {data.hasOwnProperty('checkRun')
                    ? moment(data.checkRun?.createdOn)
                        .tz('America/New_York')
                        .format('MM/DD/YYYY')
                    : '-'}
                </div>
              </div>
              <div className={`d-flex justify-content-between mb-3`}>
                <div>Disbursement Date</div>
                <div className="font-weight-bold">
                  {data.payment.disbursementDate
                    ? moment(data.payment.disbursementDate)
                        .tz('America/New_York')
                        .format('MM/DD/YYYY')
                    : '-'}
                </div>
              </div>
              <div className={`d-flex justify-content-between mb-3`}>
                <div>Payment Date</div>
                <div className="font-weight-bold">
                  {moment(data.payment.paymentDate)
                    .tz('America/New_York')
                    .format('MM/DD/YYYY')}
                </div>
              </div>
              <div className={`d-flex justify-content-between mb-3`}>
                <div>Cadence</div>
                <div className="font-weight-bold">
                  {data.timePaymentAccount.payFrequency}
                </div>
              </div>
              <div className={`d-flex justify-content-between mb-3`}>
                <div>Final Payment Date</div>
                <div className="font-weight-bold">
                  {data.paymentRule.endDate
                    ? moment(data.paymentRule.endDate)
                        .tz('America/New_York')
                        .format('MM/DD/YYYY')
                    : 'Lifetime'}
                </div>
              </div>
              <div className={`d-flex justify-content-between`}>
                <div>Tax ID No.</div>
                <div className="font-weight-bold">
                  {mapTaxDeclarationCode(
                    getTaxDeclaration(data.subAccount)
                  )}
                </div>
              </div>
            </div>
            {/* Check Details */}
            <div
              className={`${styles.check_details_container} d-flex flex-column rounded p-3`}
            >
              <h2 className={`${styles.check_details_header} font-weight-bold`}>
                Check Details
              </h2>
              <div
                className={`container text-left my-2 border-bottom border-light`}
              >
                <div className={`row`}>
                  <div
                    className={`col-5 mr-3 d-flex ml-0 pl-0 mb-3 justify-content-between`}
                  >
                    <div className="mr-1">Document ID</div>
                    <div className={`font-weight-bold`}>
                      {data.timePaymentAccount.documentId}
                    </div>
                  </div>
                  <div
                    className={`col d-flex justify-content-between ml-0 pl-0 mb-3`}
                  >
                    <div>Gross Payment</div>
                    <div className="font-weight-bold">
                      {formatDollarAmount(data.payment.paymentAmount / 100)}
                    </div>
                  </div>
                </div>
                <div className={`row`}>
                  <div className={`col-5 mr-3 d-flex ml-0 pl-0 mb-3`}>
                    <div></div>
                  </div>
                  <div
                    className={`col d-flex justify-content-between ml-0 pl-0 mb-3`}
                  >
                    <div className="mr-1"> Est. Federal Taxes Withheld</div>
                    <div className="font-weight-bold">
                      {formatDollarAmount(data.payment.irsWithholding / 100)}
                    </div>
                  </div>
                </div>
                <div className={`row`}>
                  <div className={`col-5 mr-3 d-flex ml-0 pl-0 mb-3`}>
                    <div></div>
                  </div>
                  <div
                    className={`col d-flex justify-content-between ml-0 pl-0 mb-3`}
                  >
                    <div className="mr-1"> Est. State Taxes Withheld</div>
                    <div className="font-weight-bold">
                      {formatDollarAmount(data.payment.dorsWithholding / 100)}
                    </div>
                  </div>
                </div>
                <div className={`row`}>
                  <div className={`col-5 mr-3 d-flex ml-0 pl-0 mb-2`}>
                    <div></div>
                  </div>
                  <div
                    className={`col d-flex justify-content-between ml-0 pl-0 mb-2`}
                  >
                    <div className="mr-1">Offsets Collected</div>
                    {/* todo - this field will be applied once annuities integrates with offsets */}
                    <div className="font-weight-bold">--</div>
                  </div>
                </div>
              </div>
              <div className={`container text-left mt-2`}>
                <div className={`row`}>
                  <div className={`col-5 mr-3 d-flex ml-0 pl-0 mb-3`}>
                    <div style={{ fontStyle: 'italic' }}>
                      These amounts are only a sample payment
                    </div>
                  </div>
                  <div
                    className={`col d-flex justify-content-between ml-0 pl-0 mb-3`}
                  >
                    <div>Est. Net Payment</div>
                    <div className="font-weight-bold">
                      {formatDollarAmount(
                        (data.payment.paymentAmount -
                          data.payment.irsWithholding -
                          data.payment.dorsWithholding) /
                          100
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* payee info */}
        <div className={`m-3`}>
          <div className={`d-flex justify-content-between`}>
            <div
              className={`font-weight-bold mb-2`}
              style={{ fontSize: '1.5em' }}
            >
              Payee Details
            </div>
            <button
              // added random CHECK field to check if data hasn't been loaded yet which disables button until load
              className={
                data.payee.state === ''
                  ? `${styles.light_btn_disabled}`
                  : `${styles.light_btn}`
              }
              disabled={data.payee.state === '' ? true : false}
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  history.push(
                    `${annuitiesRoutePath}/payee-details?payeeId=${data.payee.id}&fromPaymentDetails=true`
                  )
                }
              }}
              onClick={() => {
                history.push(
                  `${annuitiesRoutePath}/payee-details?payeeId=${data.payee.id}&fromPaymentDetails=true`
                )
              }}
            >
              Update Payee Details
            </button>
          </div>
          <div className={`container ml-0 pl-0 mt-3`}>
            <div className={`row mb-3`}>
              <div className={`col d-flex justify-content-between`}>
                <div>Payee Status</div>
                <div>
                  <AccountStatusCmpt
                    status={data.subAccount.acctStatus}
                    notes={data.subAccount.notes}
                  />
                </div>
              </div>
              <div className={`col d-flex justify-content-between`}>
                <div>TIN</div>
                <div className="font-weight-bold">{data.payee.tin}</div>
              </div>
            </div>
            <div className={`row mb-3`}>
              <div className={`col d-flex justify-content-between`}>
                <div>Creation Date</div>
                <div className="font-weight-bold">
                  {moment(data.timePaymentAccount.createdOn)
                    .tz('America/New_York')
                    .format('MM/DD/YYYY')}
                </div>
              </div>
              <div className={`col d-flex justify-content-between`}>
                <div>TIN Type</div>
                <div className="font-weight-bold">
                  {data.payee.tinType ?? '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`m-3`}>
          <div className="font-weight-bold" style={{ fontSize: '1.5em' }}>
            Demographic &amp; Contact Info
          </div>
          <div className={`container ml-0 pl-0 mt-4`}>
            <div className={`row mb-3`}>
              <div className={`col d-flex justify-content-between`}>
                <div className="mr-1">Mailing Address 1</div>
                <div className="font-weight-bold text-right">
                  {data.payee.address1}
                </div>
              </div>
              <div className={`col d-flex justify-content-between`}>
                <div className={`mr-1`}>Mailing Address 2</div>
                <div className="font-weight-bold text-right">
                  {data.payee.address2}
                </div>
              </div>
            </div>
            <div className={`row mb-3`}>
              <div className={`col d-flex justify-content-between`}>
                <div className="mr-1">City</div>
                <div className="font-weight-bold text-right">
                  {data.payee.city}
                </div>
              </div>
              <div className={`col d-flex justify-content-between`}>
                <div className={`mr-1`}>State/Region</div>
                <div className="font-weight-bold text-right">
                  {data.payee.state}
                </div>
              </div>
            </div>
            <div className={`row mb-3`}>
              <div className={`col d-flex justify-content-between`}>
                <div className="mr-1">Zip/Postal Code</div>
                <div className="font-weight-bold text-right">
                  {data.payee.zip}
                </div>
              </div>
              <div className={`col d-flex justify-content-between`}>
                <div className={`mr-1`}>Country</div>
                <div className="font-weight-bold text-right">
                  {data.payee.country}
                </div>
              </div>
            </div>
            <div className={`row mb-3`}>
              <div className={`col d-flex justify-content-between`}>
                <div className="mr-1">Phone</div>
                <div className="font-weight-bold text-right">
                  {data.payee.phone}
                </div>
              </div>
              <div className={`col d-flex justify-content-between`}>
                <div className={`mr-1`}>Email</div>
                <div className="font-weight-bold text-right">
                  {data.payee.email}
                </div>
              </div>
            </div>
            {data.payee.payeeType === 'Individual' && (
              <div className={`row mb-3`}>
                <div className={`col d-flex justify-content-between`}>
                  <div className="mr-1">Birth Date</div>
                  <div className="font-weight-bold text-right">
                    {data.payee.dateOfBirth
                      ? moment(data.payee.dateOfBirth).format('MM/DD/YYYY')
                      : '-'}
                  </div>
                </div>
                <div className={`col d-flex justify-content-between`}>
                  <div className={`mr-1`}>Death Date</div>
                  <div className="font-weight-bold text-right">
                    {data.payee.dateOfDeath
                      ? moment(data.payee.dateOfDeath).format('MM/DD/YYYY')
                      : '-'}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
