import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { PaymentScheduleFilterSort } from "../../../../components/AnnuitiesComponents/TPAManagement/PaymentSchedule/PaymentScheduleFilterSortCmpt";
import { PaymentScheduleListCmpt } from "../../../../components/AnnuitiesComponents/TPAManagement/PaymentSchedule/PaymentScheduleListCmpt";
import { annuitiesRoutePath } from "../../../../config.js";
import { KEY_CODES } from "../../../../constants.js";
import { BannerContext } from "../../../../context/BannerContext";
import { keyPressedType } from "../../../../services/Commons";
import styles from "./css/styles.module.css";
import { getPaymentSchedule } from "./functions";
import { AnnuityPaymentScheduleInfo } from "./interfaces";

export const AnnuityFullPaymentSchedulePage: React.FC = () => {
  const history = useHistory();
  const bannerContext = React.useContext(BannerContext);
  const tpaID = window.location.href.split("/")[window.location.href.split("/").length - 1];

  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
  const [originalPaymentSchedule, setOriginalPaymentSchedule] = useState<AnnuityPaymentScheduleInfo[]>([]);
  const [filteredPaymentSchedule, setFilteredPaymentSchedule] = useState<AnnuityPaymentScheduleInfo[]>([]);
  const [availablePayeeNames, setAvailablePayeeNames] = useState<string[]>([]);
  const [selectedPayee, setSelectedPayee] = useState<string>("");
  const [isPastPaymentVisible, setIsPastPaymentsVisible] = useState<boolean>(false);
  const [tinSelected, setTinSelected] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);

  // get initial Payment Schedule && set Available Payee Names
  useEffect(() => {
    const fetchPaymentSchedule = async () => {
      try {
        const response: AnnuityPaymentScheduleInfo[] = await getPaymentSchedule(tpaID);
        const listOfPayeeNames = response
          .map((item) => {
            return item.payeeName;
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        setAvailablePayeeNames(listOfPayeeNames);
        setOriginalPaymentSchedule(response);
        setFilteredPaymentSchedule(
          response.filter((paymentLine) => {
            return paymentLine.status !== "Paid";
          })
        );
        setRequestCompleted(true);
      } catch (error) {
        setRequestCompleted(true);
        bannerContext.setBannerInfo({
          message: `${error}`,
          error: true,
        });
      }
    };

    fetchPaymentSchedule();
  }, []);

  // useEffect to filter Schedule
  useEffect(() => {
    let schedule = originalPaymentSchedule;
    if (!isPastPaymentVisible) {
      schedule = schedule.filter((paymentLine) => {
        return paymentLine.status !== "Paid";
      });
    }
    if (tinSelected !== "") {
      schedule = schedule.filter((paymentLine) => paymentLine.tin === tinSelected);
    }
    if (selectedPayee !== "") {
      schedule = schedule.filter((paymentLine) => {
        return paymentLine.payeeName === selectedPayee;
      });
    }
    setFilteredPaymentSchedule(schedule);
  }, [isPastPaymentVisible, selectedPayee, tinSelected]);

  // set current page to 0 when schedule data has changed
  useEffect(() => {
    setCurrentPage(0);
  }, [filteredPaymentSchedule]);

  return (
    <>
      <div className={styles["page-container"]}>
        {/* Circular Progress initial Load screen */}
        {!requestCompleted && (
          <div
            style={{
              position: "absolute",
              zIndex: 50,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.3)",
              color: "white",
            }}
          >
            <CircularProgress size="8em" color="inherit" thickness={2} />
          </div>
        )}
        {/* Back Btn tsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={(event) => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                history.push(`${annuitiesRoutePath}/annuity-management-details/${tpaID}`);
              }
            }}
            onClick={() => {
              history.push(`${annuitiesRoutePath}/annuity-management-details/${tpaID}`);
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft title="left arrow icon" className={styles["back-btn-custom"]} />
            <div className={styles["back-btn-text"]}>Back to Time Payment Account Details Page</div>
          </button>
        </div>
        {/* Header */}
        <div className={styles["cmpnt-container"]}>
          <div className={styles["top-ribbon-text"]}>Payment Schedule</div>
          {/* Search Component */}
          <PaymentScheduleFilterSort
            payeeNames={availablePayeeNames}
            selectedPayee={selectedPayee}
            isPastPaymentVisible={isPastPaymentVisible}
            tinSelected={tinSelected}
            setIsPastPaymentsVisible={(value: boolean) => setIsPastPaymentsVisible(value)}
            setSelectedPayee={(value: string) => setSelectedPayee(value)}
            setTinSelected={(value: string) => setTinSelected(value)}
          />
          {/* Main Component */}
          <div className={styles["table-container"]}>
            <PaymentScheduleListCmpt
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paymentSchedule={filteredPaymentSchedule}
            />
          </div>
        </div>
      </div>
    </>
  );
};
