import { makeHttpCall } from "../../../services/MakeHttpCall"
import config from './../../../config.js';
import { testPlayerStoreObjectData } from './../../../services/StoresObject';
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";


export const manuallyVerifyEmail = async (playerId:string,setPlayerStore:any,setemailAndPhoneChangeToggle:any , setPageMessage:any, emailAndPhoneChangeToggle:any)=>{
    console.log(">>>>>>>>>>>>>>>>>>>>>>>> manuallyVerifyEmail");
    setemailAndPhoneChangeToggle({value:true});
    try {
        const options =
        {
            method: 'post',
            url: config.SERVER_BASE_URL + '/v1/player/' + playerId + '/verify-email',
        }
        
        const response:any = await makeHttpCall(options);

        const userpolicies: any = localStorage.getItem('userpolicies');
        const maskSsn = !checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies));
        let queryParam = `?maskSsn=${maskSsn}`;
        const options1 =
        {
            method: 'get',
            url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
        }
        
        const response1:any = await makeHttpCall(options1);  
        
        setemailAndPhoneChangeToggle({value:true});
        setPlayerStore(testPlayerStoreObjectData(response1));
       
      
    } catch (e) {
        setPageMessage({ message: "Something went wrong. Please try again." ,  messageType:"ERROR"  } );
        document.scrollingElement?.scrollTo(0,0);

    }
}