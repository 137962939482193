import moment from "moment";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import config from "../../../../config";
import FuzzySearch from "fuzzy-search";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";

let formik: any;
let charitableGamingStore: any;

let weeklyBeanoTaxForms: any;
let setWeeklyBeanoTaxForms: any;

let annualBeanoTaxForms: any;
let setAnnualBeanoTaxForms: any;

let casinoTaxForms: any;
let setCasinoTaxForms: any;

let raffleBazaarTaxForms: any;
let setRaffleBazaarTaxForms: any;

let delinquencyReports: any;
let setDelinquencyReports: any;

let setCurrentPage: any;
let setRequestCompleted: any;
let setShowErrorDialog: any;
let currentTab: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, weeklyBeanoTaxFormsObj: any, setWeeklyBeanoTaxFormsObj: any, annualBeanoTaxFormsObj: any, setAnnualBeanoTaxFormsObj: any, casinoTaxFormsObj: any, setCasinoTaxFormsObj: any, raffleBazaarTaxFormsObj: any, setRaffleBazaarTaxFormsObj: any, delinquencyReportsObj: any, setDelinquencyReportsObj: any, setCurrentPageObj: any, setRequestCompletedObj: any, setShowErrorDialogObj: any, currentTabObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;

    weeklyBeanoTaxForms = weeklyBeanoTaxFormsObj;
    setWeeklyBeanoTaxForms = setWeeklyBeanoTaxFormsObj;

    annualBeanoTaxForms = annualBeanoTaxFormsObj;
    setAnnualBeanoTaxForms = setAnnualBeanoTaxFormsObj;

    casinoTaxForms = casinoTaxFormsObj;
    setCasinoTaxForms = setCasinoTaxFormsObj;

    raffleBazaarTaxForms = raffleBazaarTaxFormsObj;
    setRaffleBazaarTaxForms = setRaffleBazaarTaxFormsObj;

    delinquencyReports = delinquencyReportsObj;
    setDelinquencyReports = setDelinquencyReportsObj;

    setCurrentPage = setCurrentPageObj;
    setRequestCompleted = setRequestCompletedObj;
    setShowErrorDialog = setShowErrorDialogObj;
    currentTab = currentTabObj;
}

export const setFieldValue = async (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === "taxYear") {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue('licenseNumber', '', true);
            formik.setFieldValue('raffleId', '', true);
            formik.setFieldValue(e.target.name, e.target.value, true);
        }
    } else if (e.target.name === "licenseNumber" || e.target.name === "raffleId") {
        formik.setFieldValue('taxYear', '', true);
        formik.setFieldValue(e.target.name, e.target.value, true);
    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }

}

export const handleCheckboxChange = (checkboxname: string, formik: any) => {

    setCurrentPage(0);

    if (checkboxname === 'averageIndicatorOnly') {
        formik.setFieldValue(checkboxname, !formik.values.averageIndicatorOnly, true);
    } else if (checkboxname === 'showSubmittingOrgsOnly') {
        formik.setFieldValue(checkboxname, !formik.values.showSubmittingOrgsOnly, true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    if (values.startOccasionDate && values.endOccasionDate) {
        const a = moment(values.startOccasionDate);
        const b = moment(values.endOccasionDate);
        if (b.diff(a, 'days') > 365) {
            Object.assign(errors, {startOccasionDate: InputValidationMessages.InvalidDateRange});
            // Object.assign(errors, {endOccasionDate: InputValidationMessages.InvalidDateRange});
        }
    }

    if (values.taxYear && values.taxYear.length !== 4) {
        Object.assign(errors, {taxYear: InputValidationMessages.InvalidYearValue});
    }

    return errors;
}

export const onSubmit = async (values: any) => {

    if (currentTab === 'weeklyBeano') {
        searchWeeklyBeanoForms(values);
    } else if (currentTab === 'annualBeano') {
        searchAnnualBeanoForms(values);
    } else if (currentTab === 'casino') {
        searchCasinoForms(values);
    } else if (currentTab === 'raffle') {
        searchRaffleForms(values);
    } else if (currentTab === 'delinquencyReports') {
        searchDelinquencyReports(values);
    }

}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    const previousYear = new Date().getFullYear() - 1;

    if (currentTab === 'annualBeano') {
        formik.resetForm({
            values: {
                sortByOccasionDate: 'Newest First',
                startOccasionDate: '',
                endOccasionDate: '',
                licenseNumber: '',
                raffleId: '',
                averageIndicatorOnly: false,
                showSubmittingOrgsOnly: true,
                taxYear: previousYear.toString()
            }
        });
    } else {
        formik.resetForm({
            values: {
                sortByOccasionDate: 'Newest First',
                startOccasionDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
                endOccasionDate: moment().format('YYYY-MM-DD'),
                licenseNumber: '',
                raffleId: '',
                averageIndicatorOnly: false,
                showSubmittingOrgsOnly: true,
                taxYear: ''
            }
        });
    }

    formik.submitForm();
}

export const getPageData = (taxForms: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(taxForms[i] === undefined)) {
            taxForms[i].index = index;
            index++;
            array.push(taxForms[i]);
        }
    }
    return array;
}

function newestFirst(a: any, b: any) {
    return new Date(a.occasionDate) < new Date(b.occasionDate) ? 1 : -1;
}

function oldestFirst(a: any, b: any) {
    return new Date(a.occasionDate) > new Date(b.occasionDate) ? 1 : -1;
}

export const searchWeeklyBeanoForms = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false);

    try {

        const body: any = {
            dateRange: {
                startDate: values.startOccasionDate + 'T00:00:00.000Z',
                endDate: values.endOccasionDate + 'T23:59:59.999Z'
            },
        }

        if (values.licenseNumber !== '') {
            body.licenseNumber = values.licenseNumber;
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/weeklyBeano/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        if (values.sortByOccasionDate.toLowerCase() === 'newest first') {
            response.forms = response.forms.sort(newestFirst);
        } else if (values.sortByOccasionDate.toLowerCase() === 'oldest first') {
            response.forms = response.forms.sort(oldestFirst);
        }

        if (values.averageIndicatorOnly) {
            response.forms = response.forms.filter((form: any) => form.unbalanced === true);
        }

        if (values.showSubmittingOrgsOnly) {
            response.forms = response.forms.filter((form: any) => form.submitting === true);
        }

        // Deep copy
        setWeeklyBeanoTaxForms(JSON.parse(JSON.stringify(response)));

        setRequestCompleted(true);

    } catch (e) {
        alert(e)
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const searchAnnualBeanoForms = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false);

    try {

        const body: any = {
            dateRange: {
                startDate: moment().subtract(90, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z',
                endDate: moment().format('YYYY-MM-DD') + 'T23:59:59.999Z'
            },
        }

        if (values.startOccasionDate !== '') {
            body.dateRange.startDate = values.startOccasionDate + 'T00:00:00.000Z';
        }

        if (values.endOccasionDate !== '') {
            body.dateRange.endDate = values.endOccasionDate + 'T23:59:59.999Z';
        }

        if (values.licenseNumber !== '') {
            body.licenseNumber = values.licenseNumber;
        }

        if (values.taxYear !== '') {
            body.year = values.taxYear
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/annualBeano/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        if (values.sortByOccasionDate.toLowerCase() === 'newest first') {
            response.forms = response.forms.sort(newestFirst);
        } else if (values.sortByOccasionDate.toLowerCase() === 'oldest first') {
            response.forms = response.forms.sort(oldestFirst);
        }

        // Deep copy
        setAnnualBeanoTaxForms(JSON.parse(JSON.stringify(response)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const searchCasinoForms = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false);

    try {

        const body: any = {
            dateRange: {
                startDate: values.startOccasionDate + 'T00:00:00.000Z',
                endDate: values.endOccasionDate + 'T23:59:59.999Z'
            },
        }

        if (values.raffleId !== '') {
            body.raffleId = values.raffleId;
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/casinoFunction/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        if (values.sortByOccasionDate.toLowerCase() === 'newest first') {
            response.forms = response.forms.sort(newestFirst);
        } else if (values.sortByOccasionDate.toLowerCase() === 'oldest first') {
            response.forms = response.forms.sort(oldestFirst);
        }

        // Deep copy
        setCasinoTaxForms(JSON.parse(JSON.stringify(response)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const searchRaffleForms = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false);

    try {

        const body: any = {
            dateRange: {
                startDate: values.startOccasionDate + 'T00:00:00.000Z',
                endDate: values.endOccasionDate + 'T23:59:59.999Z'
            },
        }

        if (values.raffleId !== '') {
            body.raffleId = values.raffleId;
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/raffleBazaar/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        if (values.sortByOccasionDate.toLowerCase() === 'newest first') {
            response.forms = response.forms.sort(newestFirst);
        } else if (values.sortByOccasionDate.toLowerCase() === 'oldest first') {
            response.forms = response.forms.sort(oldestFirst);
        }

        // Deep copy
        setRaffleBazaarTaxForms(JSON.parse(JSON.stringify(response)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const searchDelinquencyReports = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false);

    try {

        const body: any = {
            dateRange: {
                startDate: values.startOccasionDate + 'T00:00:00.000Z',
                endDate: values.endOccasionDate + 'T23:59:59.999Z'
            }
        }

        if (values.licenseNumber !== '') {
            body.licenseNumber = values.licenseNumber;
        }

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/delinquency/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        if (values.sortByOccasionDate.toLowerCase() === 'newest first') {
            response.forms = response.forms.sort(newestFirst);
        } else if (values.sortByOccasionDate.toLowerCase() === 'oldest first') {
            response.forms = response.forms.sort(oldestFirst);
        }

        // Deep copy
        setDelinquencyReports(JSON.parse(JSON.stringify(response)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const applyOccasionDateSortingOrder = async () => {

    // alert(JSON.stringify(values));

    const sortByOccasionDate: any = formik.values.sortByOccasionDate;
    let sorter: any;
    if (sortByOccasionDate.toLowerCase() === 'newest first') {
        sorter = newestFirst;
    } else if (sortByOccasionDate.toLowerCase() === 'oldest first') {
        sorter = oldestFirst;
    }

    // alert(sortByOccasionDate.toLowerCase())

    setRequestCompleted(false);

    try {

        if (currentTab === 'weeklyBeano') {
            weeklyBeanoTaxForms.forms = weeklyBeanoTaxForms.forms.sort(sorter);
            setWeeklyBeanoTaxForms(JSON.parse(JSON.stringify(weeklyBeanoTaxForms)));
        } else if (currentTab === 'annualBeano') {
            annualBeanoTaxForms.forms = annualBeanoTaxForms.forms.sort(sorter);
            setAnnualBeanoTaxForms(JSON.parse(JSON.stringify(annualBeanoTaxForms)));
        } else if (currentTab === 'casino') {
            casinoTaxForms.forms = casinoTaxForms.forms.sort(sorter);
            setCasinoTaxForms(JSON.parse(JSON.stringify(casinoTaxForms)));
        } else if (currentTab === 'raffle') {
            raffleBazaarTaxForms.forms = raffleBazaarTaxForms.forms.sort(sorter);
            setRaffleBazaarTaxForms(JSON.parse(JSON.stringify(raffleBazaarTaxForms)));
        } else if (currentTab === 'delinquencyReports') {
            delinquencyReports.forms = delinquencyReports.forms.sort(sorter);
            setDelinquencyReports(JSON.parse(JSON.stringify(delinquencyReports)));
        }

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}

export const approveOutOfBalanceTaxForm = async (taxFormId: any) => {

    setRequestCompleted(false);

    try {

        const options = {
            method: 'PATCH',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/weeklyBeano/${taxFormId}/approved`,
        }

        await makeHttpCall(options);

        // setRequestCompleted(true);

        formik.submitForm();

        charitableGamingStore.showNotificationBanner({
            show: true, type: NotificationType.Success, text: `Tax form ${taxFormId} has been approved.`
        });

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}
