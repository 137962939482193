export interface WeeklyBeanoSearchResultsInterface {
    forms: {
        taxFormId: string,
        licenseNumber: string,
        orgName: string,
        submitting: boolean,
        occasionDate: string,
        submissionDate: string,
        grossReceiptsInCents: number,
        prizesInCents: number,
        expensesInCents: number,
        taxesInCents: number,
        netProfitOrLossInCents: number,
        gamePlayed: boolean,
        unbalanced: boolean
    }[],
    additionalResults: boolean
}

export const WeeklyBeanoSearchResultsDefaultData = {
    forms: [{
        taxFormId: "",
        licenseNumber: "",
        orgName: "",
        submitting: false,
        occasionDate: "",
        submissionDate: "",
        grossReceiptsInCents: 0,
        prizesInCents: 0,
        expensesInCents: 0,
        taxesInCents: 0,
        netProfitOrLossInCents: 0,
        gamePlayed: false,
        unbalanced: false
    }],
    additionalResults: false
}

export interface AnnualBeanoSearchResultsInterface {
    forms: {
        taxFormId: string,
        licenseNumber: string,
        orgName: string,
        year: string,
        submissionDate: string,
        grossReceiptsInCents: number,
        prizesInCents: number,
        expensesInCents: number,
        taxesInCents: number,
        netProfitOrLossInCents: number,
        submitting: boolean
    }[],
    additionalResults: boolean
}

export const AnnualBeanoSearchResultsDefaultData = {
    forms: [{
        taxFormId: "",
        licenseNumber: "",
        orgName: "",
        year: "",
        submissionDate: "",
        grossReceiptsInCents: 0,
        prizesInCents: 0,
        expensesInCents: 0,
        taxesInCents: 0,
        netProfitOrLossInCents: 0,
        submitting: false
    }],
    additionalResults: false
}

export interface CasinoTaxFormsSearchResultsInterface {
    forms: {
        taxFormId: string,
        raffleId: string,
        licenseNumber: string,
        orgName: string,
        occasionDate: string,
        submissionDate: string,
        grossReceiptsInCents: number,
        prizesInCents: number,
        expensesInCents: number,
        taxesInCents: number,
        netProfitOrLossInCents: number
    }[],
    additionalResults: boolean
}

export const CasinoTaxFormsSearchResultsDefaultData = {
    forms: [{
        taxFormId: "",
        raffleId: "",
        licenseNumber: "",
        orgName: "",
        occasionDate: "",
        submissionDate: "",
        grossReceiptsInCents: 0,
        prizesInCents: 0,
        expensesInCents: 0,
        taxesInCents: 0,
        netProfitOrLossInCents: 0
    }],
    additionalResults: false
}

export interface DelinquencyReportsSearchResultsInterface {
    forms: {
        licenseNumber: string,
        occasionDate: string,
        occasionDay: string,
        occasionAddress: {
            streetAddress: string,
            city: string,
            zip: string,
        },
        occasionTime: string,
        formType: string,
        orgName: string,
        daysDelinquent: number
    }[]
}

export const DelinquencyReportsSearchResultsDefaultData = {
    forms: [{
        licenseNumber: "",
        occasionDate: "",
        occasionDay: "",
        occasionAddress: {
            streetAddress: "",
            city: "",
            zip: "",
        },
        occasionTime: "",
        formType: "",
        orgName: "",
        daysDelinquent: 0
    }]
}

