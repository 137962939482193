import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'

const initiateCheckRunUrl = `${config.SERVER_BASE_URL}/api/v1/checkRun/`

export const getFetchInitiateCheckRun = (fetchPendingPayments : () => void) => {
  const { isLoading, error, fetchUrl } = useFetch<void>(fetchPendingPayments)
  const fetchInitiateCheckRun = (futurePaymentIds: string[]) => {
    const jsonRequestBody = JSON.stringify({
      type: 'INITIAL',
      futurePaymentIds
    })
    fetchUrl(initiateCheckRunUrl, 'POST', {}, jsonRequestBody)
  }
  return { isLoading, error, fetchInitiateCheckRun }
}