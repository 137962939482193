import { FormikValues } from "formik";
import moment from "moment";
import config from "../../../../config";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { TimePaymentAuthSchedule } from "../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization";

const validate = (values: FormikValues) => {
  const errors = {};

  if (values.throughDate === "" || values.throughDate === null) {
    Object.assign(errors, { throughDate: "This field is Required" });
  }
  return errors;
};

const getAuthorizationData = async (throughDate: string): Promise<TimePaymentAuthSchedule[]> => {
  const options = {
    method: "get",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/schedule?throughDate=${throughDate}`,
  };

  let authorizationData;

  try {
    const response: any = await makeHttpCall(options);

    // convert createdOn from string to Date object to run sorting method
    const convertedAuthorizationData = response.map((authorization: any) => {
      return { ...authorization, payment: { ...authorization.payment, paymentDate: new Date(authorization.payment.paymentDate) } };
    });

    // sort array
    convertedAuthorizationData.sort((objA: any, objB: any) => Number(objA.payment.paymentDate) - Number(objB.payment.paymentDate));

    authorizationData = convertedAuthorizationData;
  } catch (error) {
    throw new Error("Something went wrong capturing Authorization Data");
  }

  return authorizationData;
};

const startAuthorization = async (throughDate: string, idemToken: string) => {
  const data = { throughDate: throughDate };

  const options = {
    method: "POST",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/`,
    data: data,
    headers: {
      'Content-Type': `application/json`,
      'x-idempotency-token': idemToken,
    }
  };

  try {
    await makeHttpCall(options);
  } catch (error) {
    throw new Error("Something went wrong creating Authorizing");
  }
};

const fetchLastThroughDate = async (): Promise<string> => {
  interface AuthorizationLastThroughDate {
    throughDate: string;
    id: string;
    createdOn: string;
  }

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/`,
  };

  let authorizationHistory: AuthorizationLastThroughDate[];

  try {
    const response = await makeHttpCall(options);
    authorizationHistory = response;

    if (authorizationHistory.length === 0) {
      return "No Last Through Date";
    } else {
      // convert createdOn from string to Date object to run sorting method
      const convertedAuthorizationHistory = authorizationHistory.map(obj => {
        return { ...obj, createdOn: new Date(obj.createdOn) };
      });

      // sort array
      convertedAuthorizationHistory.sort((objA, objB) => Number(objB.createdOn) - Number(objA.createdOn));

      // populate through Date
      const throughDate = moment(convertedAuthorizationHistory[0].throughDate).format("MM/DD/YYYY");

      return throughDate;
    }
  } catch (error) {
    throw new Error("Something went wrong Getting Last Through Date");
  }
};

// pagination
const normalizePage = (totalPages: number, currentPage: number) => {
  const number: number = totalPages - currentPage * 10;
  if (number > 10) {return currentPage * 10 + 10;}
  else {return totalPages;}
};

const getPageData = (KYCPlayerData: any[], page: number = 0) => {
  const array: any = [];
  for (let i = page * 10; i < page * 10 + 10; i++) {
    if (!(KYCPlayerData[i] === undefined)) {array.push(KYCPlayerData[i]);}
  }
  return array;
};


export { fetchLastThroughDate, getAuthorizationData, getPageData, normalizePage, startAuthorization, validate };

