import * as React from 'react'

export const getBlockClass = (rootClass: string, blockName: string) =>
  `${rootClass}__${blockName}`
export const getModifierClass = (
  rootClass: string,
  modiferName: string,
  predicate: boolean = true
) => (predicate ? `${rootClass}--${modiferName}` : undefined)
export const joinClasses = (classNames: (string | undefined)[]) =>
  Array.from(new Set(classNames)).filter(Boolean).join(' ')

export const getCustomCssProperties = (
  properties: Record<string, string>,
  styles: React.CSSProperties = {}
) =>
  ({
    ...styles,
    ...properties,
  }) as React.CSSProperties

export const sum = (a: number, b: number) => a + b
export const diff = (a: number, b: number) => a - b

export const objToQueryParams = (obj: Record<string, any>) =>
  Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

export const downloadFile = (filename: string, data: Blob) => {
  var a = document.createElement('a')
  a.href = window.URL.createObjectURL(data)
  a.download = filename
  a.click()
  a.remove()
}

export const getContentDispositionFilename = (
  response: Response,
  alternateFilename: string
) => {
  const contentDisposition =
    response.headers.get('content-disposition') ?? undefined
  const filenameFromHeader =
    contentDisposition?.match(/filename="([^"]+)"/)?.[1]
  return filenameFromHeader ?? alternateFilename
}
