import React from "react";
import {action, observable} from "mobx";
import config, {licenseeRoutePath} from "../config.js";
import {makeHttpCall} from "../services/MakeHttpCall";
import {
    LicenseeDetailsDefaultData,
    LicenseeDetailsInterface
} from "../pages/CharitableGamingPages/License/LicenseeDetailsPage/interfaces";
import {NotificationType} from "../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {pageMessageDefault} from "../components/CommonComponents/PageMessageCommonComponent";
import {
    PermitHolderDetailsDefaultData,
    PermitHolderDetailsInterface
} from "../pages/CharitableGamingPages/Permit/PermitProfilePage/interfaces";
import {
    EFTFormDefaultData,
    ElectronicsFundTransferFormInterface
} from "../pages/CharitableGamingPages/License/UpdateEFTDetailsPage/interfaces";
import {UpdateLicenseSuccessMessage} from "../pages/CharitableGamingPages/License/Common/UpdateLicenseSuccessMessage";
import FuzzySearch from "fuzzy-search";
import {
    PullTabGameDefaultValue,
    PullTabGameInterface
} from "../pages/CharitableGamingPages/PullTab/Games/PullTabGamesPage/interfaces";
import {
    PullTabOrderItemHistoryDefaultData,
    PullTabOrderItemHistoryInterface,
    PullTabOrderOverviewHistoryDefaultData,
    PullTabOrderOverviewHistoryInterface
} from "../pages/CharitableGamingPages/PullTab/Orders/PullTabOrdersPage/interfaces";
import {convertDollarsToCents} from "../util";
import axios from 'axios';


export class CharitableGamingStore {

    rootStore: any = null;

    @observable notificationBanner: any = {show: false, type: NotificationType.Success, text: ''};
    @observable errorNotificationBanner: any = pageMessageDefault;

    constructor(rootStore: any) {
        this.rootStore = rootStore;
    }

    // >>>>>>>>>>>> View/Edit Licensee Details Screen >>>>>>>>>>>>>>

    @observable selectedLicense: LicenseeDetailsInterface = LicenseeDetailsDefaultData;

    @observable selectedLicenseEFTInfo: ElectronicsFundTransferFormInterface = EFTFormDefaultData;

    @action
    async getLicenseeDetailsByLicenseNumber(licenseNumber: string, setRequestCompleted: any) {

        setRequestCompleted(false);

        try {

            const options = {
                method: 'GET',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/retrieve/${licenseNumber}`,
            }

            const response = await makeHttpCall(options);

            // Format date fields so the date picker displays these values
            if (response.licensee.licenseDetails.startDate) {
                response.licensee.licenseDetails.startDate = moment(response.licensee.licenseDetails.startDate).format('YYYY-MM-DD');
            }

            this.setSelectedLicense(response.licensee);

            setRequestCompleted(true);

        } catch (e) {
            setRequestCompleted(true);
        }
    }

    @action
    async getEFTInformation(licenseNumber: string, setRequestCompleted: any) {

        try {

            const options = {
                method: 'GET',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/${licenseNumber}/eft-account`,
            }

            let response = await makeHttpCall(options);

            if (Object.keys(response).length === 0) {
                response = EFTFormDefaultData;
            }

            this.setSelectedLicenseEFTInfo(response);
            setRequestCompleted(true);

        } catch (e) {
            setRequestCompleted(true);
        }

    }

    @action
    async updateLicense(license: any, successMessage: string, history: any) {

        const licenseNumber = license.licenseDetails.licenseNumber;

        const body = JSON.parse(JSON.stringify(license)); // deep copy

        // >>>>>>>>>>>>> Prepare the request body >>>>>>>>>>>>>>

        delete body.orgDetails.orgFID
        delete body.orgDetails.orgCode
        delete body.orgDetails.orgCodeOther
        delete body.licenseDetails.submitting
        delete body.licenseDetails.markedForRenewal
        delete body.licenseDetails.licenseNumber
        delete body.licenseDetails.active
        delete body.licenseDetails.creditBalanceInCents

        body.licenseDetails.dateOfFirstLicense = moment(body.licenseDetails.dateOfFirstLicense).format('YYYY-MM-DD') + 'T00:00:00.000Z';
        body.licenseDetails.expirationDate = moment(body.licenseDetails.expirationDate).format('YYYY-MM-DD') + 'T23:59:59.999Z';
        if (moment(body.licenseDetails.startDate).isValid()) {
            body.licenseDetails.startDate = moment(body.licenseDetails.startDate).format('MM/DD/YYYY');
        } else {
            body.licenseDetails.startDate = null;
        }

        if (body.licenseDetails.occasionTime === '1:00 P.M to 6:00 P.M') {
            body.licenseDetails.occasionTime = 'AFTERNOON';
        } else if (body.licenseDetails.occasionTime === '6:00 P.M. to 12:00 MID.') {
            body.licenseDetails.occasionTime = 'EVENING';
        }

        // <<<<<<<<<<<<< Prepare the request body <<<<<<<<<<<<<<<<

        //alert(JSON.stringify(body));
        //console.log(JSON.stringify(body))

        try {

            const options = {
                method: 'PUT',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/update/${licenseNumber}`,
                data: body
            }

            const response = await makeHttpCall(options);

            // Format date fields so the date picker displays these values
            response.licensee.licenseDetails.dateOfFirstLicense = moment(response.licensee.licenseDetails.dateOfFirstLicense).format('YYYY-MM-DD');
            response.licensee.licenseDetails.expirationDate = moment(response.licensee.licenseDetails.expirationDate).format('YYYY-MM-DD');
            response.licensee.licenseDetails.startDate = moment(response.licensee.licenseDetails.startDate).format('YYYY-MM-DD');

            this.setSelectedLicense(response.licensee);

            history.push({
                pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
                state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
            });

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: successMessage
            });

        } catch (e) {
            this.showErrorNotificationBanner('Something went wrong. Please try again');
        }
    }

    @action
    async updateEFTInformation(licenseNumber: string, EFTInfo: ElectronicsFundTransferFormInterface, history: any) {

        const body = JSON.parse(JSON.stringify(EFTInfo));

        delete body.id;
        delete body.licenseNumber;
        delete body.activeFrom;
        delete body.activeTo;

        try {

            const options = {
                method: 'PUT',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/${licenseNumber}/eft-account`,
                data: body
            }

            const response = await makeHttpCall(options);

            // this.setSelectedLicense(response);

            history.push({
                pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
                state: {tabName: 'EFTInformation'}
            });

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: UpdateLicenseSuccessMessage.EFT
            });

        } catch (e) {
            this.showErrorNotificationBanner('Something went wrong. Please try again');
        }
    }

    @action
    setSelectedLicense(response: LicenseeDetailsInterface) {
        this.selectedLicense = response;
    }

    @action
    setSelectedLicenseEFTInfo(response: ElectronicsFundTransferFormInterface) {
        this.selectedLicenseEFTInfo = response;
    }

    @action
    async toggleLicenseRenewalStatus(licenseNumber: string, setRequestCompleted: any) {

        setRequestCompleted(false);

        try {

            const toggle = this.selectedLicense.licenseDetails.markedForRenewal ? "not-renewing" : "renewing";

            const options = {
                method: 'PATCH',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/${licenseNumber}/${toggle}`,
            }

            const response = await makeHttpCall(options);

            setRequestCompleted(true);

            this.setSelectedLicense(response.licensee);

            let successMessage = '';
            if (toggle === 'renewing') {
                successMessage = `The license profile was successfully marked "Yes" for renewing.`
            } else {
                successMessage = `The license profile was successfully marked "No" for renewing.`;
            }

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: successMessage
            });

        } catch (e) {
            setRequestCompleted(true);
            this.showNotificationBanner({
                show: true,
                type: NotificationType.Error,
                text: 'There was a network error. Please try again later.'
            });
        }
    }

    @action
    async toggleLicenseSubmissionStatus(licenseNumber: string, setRequestCompleted: any) {

        setRequestCompleted(false);

        try {

            const toggle = this.selectedLicense.licenseDetails.submitting ? "not-submitting" : "submitting";

            const options = {
                method: 'PATCH',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/${licenseNumber}/${toggle}`,
            }

            const response = await makeHttpCall(options);

            setRequestCompleted(true);

            this.selectedLicense.licenseDetails.submitting = !this.selectedLicense.licenseDetails.submitting;
            // this.setSelectedLicense(response.licensee);

            let successMessage = '';
            if (toggle === 'submitting') {
                successMessage = `The license profile was successfully marked "Yes" for submitting.`;
            } else {
                successMessage = `The license profile was successfully marked "No" for submitting.`;
            }

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: successMessage
            });

        } catch (e) {
            setRequestCompleted(true);
            this.showNotificationBanner({
                show: true,
                type: NotificationType.Error,
                text: 'There was a network error. Please try again later.'
            });
        }
    }

    /*
      This method is used for Single License Reactivation by [Reactivate]
     */
    @action
    async reactivateLicense(licenseNumber: string, setRequestCompleted: any) {

        setRequestCompleted(false);

        try {

            const options: any = {
                method: 'POST',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/${licenseNumber}/renewal`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json text/csv',
                }
            }

            const response = await axios(options);

            let file = new Blob([response.data], {type: 'text/csv'});
            let fileURL = URL.createObjectURL(file);

            // Create <a> tag dynamically
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;

            // Forces the name of the downloaded file
            fileLink.download = response.headers['download-file-name'] ? response.headers['download-file-name'] : `License_Renewal_${licenseNumber}.csv`;

            // Trigger the click event
            fileLink.click();

            this.getLicenseeDetailsByLicenseNumber(licenseNumber, setRequestCompleted);

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: 'The license profile was successfully reactivated.'
            });

        } catch (e) {
            setRequestCompleted(true);
            this.showNotificationBanner({
                show: true,
                type: NotificationType.Error,
                text: 'There was a network error. Please try again later.'
            });
        }

    }

    /*
      This method is used by [Download License File]
     */
    @action
    async downloadLicenseFile(licenseNumber: string, setRequestCompleted: any) {

        setRequestCompleted(false);

        try {

            const options: any = {
                method: 'POST',
                url: `${config.SERVER_BASE_URL}/api/v1/licensee/${licenseNumber}/download`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json text/csv',
                }
            }

            const response = await axios(options);

            let file = new Blob([response.data], {type: 'text/csv'});
            let fileURL = URL.createObjectURL(file);

            // Create <a> tag dynamically
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;

            // Forces the name of the downloaded file
            fileLink.download = response.headers['download-file-name'] ? response.headers['download-file-name'] : `License_${licenseNumber}.csv`;

            // Trigger the click event
            fileLink.click();

            setRequestCompleted(true);

        } catch (e) {
            setRequestCompleted(true);
            this.showNotificationBanner({
                show: true,
                type: NotificationType.Error,
                text: 'There was a network error. Please try again later.'
            });
        }

    }

    // <<<<<<<<<<<< View/Edit Licensee Details Screen <<<<<<<<<<<<<<


    // >>>>>>>>>>>> View/Edit Permit Holder Details Screen >>>>>>>>>>>>>>

    @observable selectedPermitHolder: PermitHolderDetailsInterface = PermitHolderDetailsDefaultData;

    @action
    async getPermitHolderDetailsByRaffleID(raffleId: string, setRequestCompleted: any) {

        try {

            const options = {
                method: 'GET',
                url: `${config.SERVER_BASE_URL}/api/v1/permitholder/${raffleId}`,
            }

            const response = await makeHttpCall(options);

            this.setSelectedPermitHolder(response.permitHolder);

            setRequestCompleted(true);

        } catch (e) {

        }
    }

    @action
    async updatePermitHolder(permitHolder: PermitHolderDetailsInterface, history: any) {

        // alert(JSON.stringify(permitHolder))

        try {

            const body = JSON.parse(JSON.stringify(permitHolder));
            const raffleId = body.raffleId;

            if (typeof body.orgActive === 'string') {
                if (body.orgActive.toLowerCase() === 'active') {
                    body.orgActive = true;
                } else if (body.orgActive.toLowerCase() === 'expired') {
                    body.orgActive = false;
                }
            }

            // Delete the fields who are redundant for the Update API
            delete body.expirationDate;
            delete body.effectiveDate;
            delete body.dateOfFirstPermit;
            delete body.status;


            const options = {
                method: 'PUT',
                url: `${config.SERVER_BASE_URL}/api/v1/permitHolder/${raffleId}`,
                data: body
            }

            const response = await makeHttpCall(options);

            history.goBack();

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: UpdateLicenseSuccessMessage.PERMIT_PROFILE
            });

        } catch (e) {
            this.showErrorNotificationBanner('Something went wrong. Please try again');
        }

    }

    @action
    async updatePermitDateOfIssuance(permitHolder: any, raffleId: string) {

        //alert(moment(permitHolder.effectiveDate))

        permitHolder.effectiveDate = permitHolder.effectiveDate + 'T00:00:00.000Z';

        try {

            const body = JSON.parse(JSON.stringify(permitHolder));

            const options = {
                method: 'PUT',
                url: `${config.SERVER_BASE_URL}/api/v1/permitHolder/${raffleId}/effective-date`,
                data: body
            }

            const response = await makeHttpCall(options);

            // Now update the effectiveDate in this.selectedPermitHolder
            const selectedPermitHolder: PermitHolderDetailsInterface = JSON.parse(JSON.stringify(this.selectedPermitHolder));
            selectedPermitHolder.effectiveDate = body.effectiveDate;
            this.setSelectedPermitHolder(selectedPermitHolder);

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: UpdateLicenseSuccessMessage.PERMIT_DOI
            });

        } catch (e) {
            this.showErrorNotificationBanner('Something went wrong. Please try again');
        }

    }

    @action
    setSelectedPermitHolder(response: PermitHolderDetailsInterface) {
        this.selectedPermitHolder = response;
    }

    // <<<<<<<<<<<< View/Edit Permit Holder Details Screen <<<<<<<<<<<<<<

    // >>>>>>>>>>>> Pull-Tab Games Screens >>>>>>>>>>>>>>

    @observable selectedPullTabGame: PullTabGameInterface = PullTabGameDefaultValue;

    @observable unfilteredPullTabGames: (PullTabGameInterface)[] = [PullTabGameDefaultValue];
    @observable filteredPullTabGames: (PullTabGameInterface)[] = [PullTabGameDefaultValue];

    @action
    getAllPullTabGames = async (setRequestCompleted: any) => {

        try {

            const options = {
                method: 'GET',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/game`,
            }

            const response = await makeHttpCall(options);

            // Deep copy
            this.setUnfilteredPullTabGames(JSON.parse(JSON.stringify(response.games)));

            this.setFilteredPullTabGames(JSON.parse(JSON.stringify(response.games)));

            setRequestCompleted(true);

        } catch (e) {
            setRequestCompleted(true);
            //setShowErrorDialog(true);
        }
    }

    @action
    searchPullTabGames = (values: any) => {

        let searcher;
        let key;
        let pullTabGames;

        // Filter

        if (values.stockNumber) {
            searcher = new FuzzySearch(this.unfilteredPullTabGames, ['stockNumber'], {
                caseSensitive: false, sort: true
            });
            key = values.stockNumber;
        } else if (values.gameName) {
            searcher = new FuzzySearch(this.unfilteredPullTabGames, ['gameName'], {
                caseSensitive: false, sort: true
            });
            key = values.gameName;
        }

        this.filteredPullTabGames = searcher.search(key);
        this.setFilteredPullTabGames(this.filteredPullTabGames);
    }

    @action
    resetFilteredPullTabGames = () => {
        this.setFilteredPullTabGames(JSON.parse(JSON.stringify(this.unfilteredPullTabGames)));
    }

    @action
    setFilteredPullTabGames(filteredPullTabGames: any) {
        this.filteredPullTabGames = filteredPullTabGames;
    }

    @action
    setUnfilteredPullTabGames(unfilteredPullTabGames: any) {
        this.unfilteredPullTabGames = unfilteredPullTabGames;
    }

    @action
    getPullTabGameByStockNumber(stockNumber: string) {

        let selectedPullTabGame = null;
        this.setSelectedPullTabGame(selectedPullTabGame);

        for (let filteredPullTabGame of this.filteredPullTabGames) {
            if (filteredPullTabGame.stockNumber === stockNumber) {
                selectedPullTabGame = filteredPullTabGame;
                break;
            }
        }

        this.setSelectedPullTabGame(selectedPullTabGame);
    }

    @action
    setSelectedPullTabGame(selectedPullTabGame: any) {
        this.selectedPullTabGame = selectedPullTabGame;
    }

    @action
    async updatePullTabGame(pullTabGame: PullTabGameInterface, history: any) {

        // alert(JSON.stringify(permitHolder))

        try {

            const body = JSON.parse(JSON.stringify(pullTabGame));
            const stockNumber = body.stockNumber;

            // Delete the fields who are redundant for the Update API
            delete body.stockNumber;
            delete body.index;

            // Convert Dollar Amount to Cents
            body.unitPriceInCents = await convertDollarsToCents(body.unitPriceInDollars);
            body.casePriceInCents = await convertDollarsToCents(body.casePriceInDollars);

            delete body.unitPriceInDollars;
            delete body.casePriceInDollars;

            // alert(JSON.stringify(body))

            const options = {
                method: 'PUT',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/game/${stockNumber}`,
                data: body
            }

            const response = await makeHttpCall(options);

            history.goBack();

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: UpdateLicenseSuccessMessage.PULL_TAB_GAME_UPDATE
            });

        } catch (e) {
            this.showErrorNotificationBanner('Something went wrong. Please try again');
        }

    }

    @action
    async createPullTabGame(pullTabGame: PullTabGameInterface, history: any) {
        try {

            const body = JSON.parse(JSON.stringify(pullTabGame));

            // Convert Dollar Amount to Cents
            body.unitPriceInCents = await convertDollarsToCents(body.unitPriceInCents);
            body.casePriceInCents = await convertDollarsToCents(body.casePriceInCents);

            // alert(JSON.stringify(body))

            const options = {
                method: 'POST',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/game`,
                data: body
            }

            const response = await makeHttpCall(options);

            history.goBack();

            this.showNotificationBanner({
                show: true,
                type: NotificationType.Success,
                text: UpdateLicenseSuccessMessage.PULL_TAB_GAME_CREATE
            });

        } catch (e) {
            this.showErrorNotificationBanner('Something went wrong. Please try again');
        }

    }

    // <<<<<<<<<<<< Pull-Tab Games Screens <<<<<<<<<<<<<<

    // >>>>>>>>>>>> Pull-Tab Orders Screens >>>>>>>>>>>>>>

    @observable pullTabOrderItemHistory: PullTabOrderItemHistoryInterface = PullTabOrderItemHistoryDefaultData;
    @observable filteredPullTabOrderItemHistory: PullTabOrderItemHistoryInterface = PullTabOrderItemHistoryDefaultData;

    @observable pullTabOrderOverviewHistory: PullTabOrderOverviewHistoryInterface = PullTabOrderOverviewHistoryDefaultData;
    @observable filteredPullTabOrderOverviewHistory: PullTabOrderOverviewHistoryInterface = PullTabOrderOverviewHistoryDefaultData;

    @action
    async getPullTabOrderHistorySummary(licenseNumber: string, setRequestCompleted: any, setShowErrorDialog: any) {

        const body: any = {
            dateRange: {
                startDate: '',
                endDate: ''
            },
            /* warehouseId: '',
            stockNumber: null,
            serialNumber: null,
            orgName: null,
            purchaseOrderId: null*/
        }

        if (licenseNumber !== '') {
            body.licenseNumber = licenseNumber;
        }
        body.dateRange.startDate = moment().subtract(90, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
        body.dateRange.endDate = moment().format('YYYY-MM-DD') + 'T23:59:59.999Z';

        try {

            const options = {
                method: 'POST',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/purchase-order/search?maxRecords=5`,
                data: body
            }

            let response = await makeHttpCall(options);
            setRequestCompleted(true);

            this.setPullTabOrderItemHistory(response);
            this.setFilteredPullTabOrderItemHistory(response);

            this.setPullTabOrderOverviewHistory(response);
            this.setFilteredPullTabOrderOverviewHistory(response);

        } catch (e) {
            setRequestCompleted(true);
            setShowErrorDialog(true);
        }

    }

    /*
     This is called from Pull-Tab Orders Summary Page -> [Details] button
    */
    @action
    async searchPullTabOrderItemHistory(values: any, setRequestCompleted: any) {

        // alert(JSON.stringify(values));

        const body: any = {
            dateRange: {
                startDate: '',
                endDate: ''
            },
            /* warehouseId: '',
            licenseNumber: null,
            stockNumber: null,
            serialNumber: null,
            orgName: null,
            purchaseOrderId: null*/
        }

        body.dateRange.startDate = values.startOrderDate + 'T00:00:00.000Z';
        body.dateRange.endDate = values.endOrderDate + 'T23:59:59.999Z';
        body.warehouseId = (values.warehouse === '' || values.warehouse.toLowerCase() === 'all') ? '' : values.warehouse.split('-')[0].trim();
        if (values.licenseNumber !== '') {
            body.licenseNumber = values.licenseNumber;
        }
        body.stockNumber = values.stockNumber;
        body.serialNumber = values.serialNumber;
        body.orgName = values.orgName;
        body.purchaseOrderId = values.purchaseOrderId;

        try {

            const options = {
                method: 'POST',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/purchase-order/search`,
                data: body
            }

            let response = await makeHttpCall(options);
            setRequestCompleted(true);

            this.setPullTabOrderItemHistory(response);
            this.setPullTabOrderOverviewHistory(response);

        } catch (e) {
            console.log(e);
            setRequestCompleted(true);
        }

    }

    @action
    async resetPullTabOrderItemHistory() {
        this.setPullTabOrderItemHistory([]);
    }

    @action
    async setPullTabOrderItemHistory(response: any) {
        this.pullTabOrderItemHistory.additionalResults = response.additionalResults;
        this.pullTabOrderItemHistory.records = response.orderItemHistory;
    }

    @action
    async setFilteredPullTabOrderItemHistory(response: any) {
        this.filteredPullTabOrderItemHistory.additionalResults = response.additionalResults;
        this.filteredPullTabOrderItemHistory.records = response.orderItemHistory;
    }

    @action
    async resetPullTabOrderOverviewHistory() {
        this.setPullTabOrderOverviewHistory([]);
    }

    @action
    async setPullTabOrderOverviewHistory(response: any) {
        this.pullTabOrderOverviewHistory.additionalResults = response.additionalResults;
        this.pullTabOrderOverviewHistory.records = response.orderOverviewHistory;
    }

    @action
    async setFilteredPullTabOrderOverviewHistory(response: any) {
        this.filteredPullTabOrderOverviewHistory.additionalResults = response.additionalResults;
        this.filteredPullTabOrderOverviewHistory.records = response.orderOverviewHistory;
    }

    @action
    async getPurchaseOrderDetails(purchaseOrderId: string, setSelectedPurchaseOrder: any, setLicenseNumber: any, setRequestCompleted: any) {

        try {

            const options = {
                method: 'GET',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/purchase-order/${purchaseOrderId}`,
            }

            let response = await makeHttpCall(options);

            if (response.hasOwnProperty('purchaseOrder')) {
                setSelectedPurchaseOrder(response);
                setLicenseNumber(response.purchaseOrder.licenseNumber);
            }

            setRequestCompleted(true);

        } catch (e) {
            setRequestCompleted(true);
        }

    }

    // <<<<<<<<<<<< Pull-Tab Orders Screens <<<<<<<<<<<<<<

    @action
    showNotificationBanner(notificationBanner: any) {
        this.notificationBanner.show = notificationBanner.show;
        this.notificationBanner.type = notificationBanner.type;
        this.notificationBanner.text = notificationBanner.text;
    }

    @action
    showErrorNotificationBanner(message: string) {
        this.errorNotificationBanner.message = message;
        this.errorNotificationBanner.messageType = 'ERROR';
    }

    @action
    async GetAllWarehouses(setWarehouses: any, setValidWarehouses: any = null) {

        try {

            const options = {
                method: 'GET',
                url: `${config.SERVER_BASE_URL}/api/v1/pulltab/warehouses`,
            }

            const response = await makeHttpCall(options);

            let warehouses = []
            for (let warehouse of response.warehouses) {
                warehouses.push(`${warehouse.warehouseId} - ${warehouse.warehouseName}`);
            }
            setWarehouses(warehouses);

            if (setValidWarehouses) {
                setValidWarehouses(warehouses);
            }

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Resets all the observable variables to their default values
     */
    @action
    reset() {
        this.selectedLicense = LicenseeDetailsDefaultData;
        this.selectedLicenseEFTInfo = EFTFormDefaultData;
        this.selectedPermitHolder = PermitHolderDetailsDefaultData;
        this.pullTabOrderItemHistory = PullTabOrderItemHistoryDefaultData;
        this.filteredPullTabOrderItemHistory = PullTabOrderItemHistoryDefaultData;
        this.pullTabOrderOverviewHistory = PullTabOrderOverviewHistoryDefaultData;
        this.filteredPullTabOrderOverviewHistory = PullTabOrderOverviewHistoryDefaultData;
    }

}

