import {UpdateLicenseSuccessMessage} from "../Common/UpdateLicenseSuccessMessage";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {licenseeRoutePath} from "../../../../config.js";

let licenseNumber: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === 'licenseDetails.occasionAddress.zip') {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else if (e.target.name === 'licenseDetails.staffTrained') {
        const value = (e.target.value.toLowerCase() === 'yes');
        formik.setFieldValue(e.target.name, value, true);
    } else {
        formik.setFieldValue(e.target.name, e.target.value, true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.licenseDetails.occasionAddress.streetAddress) {
        Object.assign(errors, {licenseDetails: {occasionAddress: {streetAddress: InputValidationMessages.RequiredField}}});
    }

    /*    if (!values.licenseDetails.occasionDay) {
            Object.assign(errors, {licenseDetails: {occasionDay: 'Required'}});
        }*/

    /*    if (!values.licenseDetails.occasionTime) {
            Object.assign(errors, {licenseDetails: {occasionTime: 'Required'}});
        }*/

    if (!values.licenseDetails.occasionAddress.city) {
        Object.assign(errors, {licenseDetails: {occasionAddress: {city: InputValidationMessages.RequiredField}}});
    }

    if (!values.licenseDetails.occasionAddress.zip) {
        Object.assign(errors, {licenseDetails: {occasionAddress: {zip: InputValidationMessages.RequiredField}}});
    } else if (values.licenseDetails.occasionAddress.zip && values.licenseDetails.occasionAddress.zip.length !== 5) {
        Object.assign(errors, {licenseDetails: {occasionAddress: {zip: InputValidationMessages.InvalidZip}}});
    }

    if (!values.licenseDetails.startDate) {
        Object.assign(errors, {licenseDetails: {startDate: InputValidationMessages.RequiredField}});
    }

    if (!values.licenseDetails.beanoHallPhone) {
        Object.assign(errors, {licenseDetails: {beanoHallPhone: InputValidationMessages.RequiredField}});
    } else if (values.licenseDetails.beanoHallPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.licenseDetails.beanoHallPhone)) {
        Object.assign(errors, {licenseDetails: {beanoHallPhone: InputValidationMessages.InvalidTelephone}});
    }

    /*    if (!values.licenseDetails.staffTrained) {
            Object.assign(errors, {licenseDetails: {staffTrained: 'Required'}});
        }*/

    return errors;
}

export const onSubmit = async (values: any) => {
    // alert(JSON.stringify(values));
    charitableGamingStore.updateLicense(values, UpdateLicenseSuccessMessage.BEANO_GAME_INFO, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {

    // history.goBack();

    history.push({
        pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
        state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
    });
}



