import React, { useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import { PLAYERS_PAGES } from '../../../../application-data-structure/players-page.data-structure'

import { PlayerSessionActivityInterface } from './interfaces'

import {
  getPlayerMobileClaimsActivity,
  getPlayerSessionActivity,
  getPlayerTicketScanningActivityData,
} from './functions'

import { checkRenderPermissions } from './../../../../helpers.js'
import { permissions } from './../../../../constants.js'

import { PlayerSessionActivityTableComponent } from '../../../../components/PlayersComponents/PlayerSessionActivityTableComponent'
import { PlayerTicketScanningActivityTableComponent } from '../../../../components/PlayersComponents/PlayerTicketScanningActivityTableComponent'
import { PlayerTicketScanningActivityInterface } from '../../../../components/PlayersComponents/PlayerTicketScanningActivityTableComponent/interface'
import { PlayerMobileClaimsActivityTableComponent } from '../../../../components/PlayersComponents/PlayerMobileClaimsActivityTableComponent'
import { PlayerMobileClaimsActivityInterface } from '../../../../components/PlayersComponents/PlayerMobileClaimsActivityTableComponent/interfaces'
import { PromotionEntryDetailsModalContainer } from '../../../../components/CommonComponents/promotion-entry-details-modal/promotion-entry-details-modal-container'

const playerSessionActivityDefaultData: PlayerSessionActivityInterface[] = []
const playerMobileClaimsActivityDefaultData: PlayerMobileClaimsActivityInterface[] =
  []
const playerTicketScanningActivityDefaultData: PlayerTicketScanningActivityInterface[] =
  []

const timerArray: any = []

export const PlayerActivityDetailsPage = ({
  id,
  storesObject,
  pageMessage,
  setPageMessage,
}: any) => {
  const userpolicies: any = localStorage.getItem('userpolicies')

  const [isDataPerformedSession, setIsDataPerformedSession] = useState<any>({
    value: !checkRenderPermissions(
      permissions.CAN_SEE_SESSION_ACTIVITY,
      JSON.parse(userpolicies)
    ),
  })
  const [isDataPerformedMobile, setIsDataPerformedMobile] = useState<any>({
    value: !checkRenderPermissions(
      permissions.CAN_SEE_CLAIM_ACTIVITY,
      JSON.parse(userpolicies)
    ),
  })
  const [isDataPerformedTicket, setIsDataPerformedTicket] = useState<any>({
    value: !checkRenderPermissions(
      permissions.CAN_SEE_TICKET_SCANNING_ACTIVITY,
      JSON.parse(userpolicies)
    ),
  })

  const [requestPerformedSession, setRequestPerformedSession] =
    useState<any>(false)
  const [requestPerformedMobile, setRequestPerformedMobile] =
    useState<any>(false)
  const [requestPerformedTicket, setRequestPerformedTicket] =
    useState<any>(false)

  const [PlayerSessionActivityData, setPlayerSessionActivityData] = useState<
    PlayerSessionActivityInterface[]
  >(playerSessionActivityDefaultData)
  const [PlayerMobileClaimsActivityData, setPlayerMobileClaimsActivityData] =
    useState<PlayerMobileClaimsActivityInterface[]>(
      playerMobileClaimsActivityDefaultData
    )
  const [
    PlayerTicketScanningActivityData,
    setPlayerTicketScanningActivityData,
  ] = useState<PlayerTicketScanningActivityInterface[]>(
    playerTicketScanningActivityDefaultData
  )

  const [openBarcode, setOpenBarcode] = React.useState<string | undefined>(
    undefined
  )

  const callingPage =
    PLAYERS_PAGES['PLAYERS_SUPPORT_HUB_PAGE']['TabsPlayersSupportHubPage']

  const sleep = (ms: any) => {
    const timer1 =
      requestPerformedSession &&
      setTimeout(
        (timerArray: any) => {
          timerArray.map((e: any) => {
            Object.keys(e).map((key: any) => {
              if (key === 'requestPerformedSession') clearTimeout(e[key])
            })
          })
          setRequestPerformedSession(false)
        },
        ms,
        timerArray
      )
    timerArray.push({ requestPerformedSession: timer1 })
    const timer2 =
      requestPerformedMobile &&
      setTimeout(
        (timerArray: any) => {
          timerArray.map((e: any) => {
            Object.keys(e).map((key: any) => {
              if (key === 'requestPerformedMobile') clearTimeout(e[key])
            })
          })
          setRequestPerformedMobile(false)
        },
        ms,
        timerArray
      )
    timerArray.push({ requestPerformedMobile: timer2 })
    const timer3: any =
      requestPerformedTicket &&
      setTimeout(
        (timerArray: any) => {
          timerArray.map((e: any) => {
            Object.keys(e).map((key: any) => {
              if (key === 'requestPerformedTicket') clearTimeout(e[key])
            })
          })
          setRequestPerformedTicket(false)
        },
        ms,
        timerArray
      )
    timerArray.push({ requestPerformedTicket: timer3 })
  }

  useEffect(() => {
    if (
      PlayerSessionActivityData.length <= 0 &&
      !isDataPerformedSession.value &&
      requestPerformedSession === false
    ) {
      Object.assign(isDataPerformedSession, { value: false })
      checkRenderPermissions(
        permissions.CAN_SEE_SESSION_ACTIVITY,
        JSON.parse(userpolicies)
      ) &&
        getPlayerSessionActivity(
          setPlayerSessionActivityData,
          setIsDataPerformedSession,
          setPageMessage,
          pageMessage,
          storesObject,
          id
        )
    }

    if (
      PlayerMobileClaimsActivityData.length <= 0 &&
      !isDataPerformedMobile.value &&
      requestPerformedMobile === false
    ) {
      Object.assign(isDataPerformedMobile, { value: false })
      checkRenderPermissions(
        permissions.CAN_SEE_CLAIM_ACTIVITY,
        JSON.parse(userpolicies)
      ) &&
        getPlayerMobileClaimsActivity(
          setPlayerMobileClaimsActivityData,
          setIsDataPerformedMobile,
          setPageMessage,
          pageMessage,
          storesObject,
          id
        )
    }

    if (
      PlayerTicketScanningActivityData.length <= 0 &&
      !isDataPerformedTicket.value &&
      requestPerformedTicket === false
    ) {
      Object.assign(isDataPerformedTicket, { value: false })
      checkRenderPermissions(
        permissions.CAN_SEE_TICKET_SCANNING_ACTIVITY,
        JSON.parse(userpolicies)
      ) &&
        getPlayerTicketScanningActivityData(
          setPlayerTicketScanningActivityData,
          setIsDataPerformedTicket,
          setPageMessage,
          pageMessage,
          storesObject,
          id
        )
    }

    if (
      isDataPerformedSession.value ||
      isDataPerformedMobile.value ||
      isDataPerformedTicket.value
    ) {
      document.getElementById('page-container')?.focus()
    }

    if (
      !isDataPerformedSession.value ||
      !isDataPerformedMobile.value ||
      !isDataPerformedTicket.value
    ) {
      !requestPerformedSession.value && setRequestPerformedSession(true)
      !requestPerformedMobile.value && setRequestPerformedMobile(true)
      !requestPerformedTicket.value && setRequestPerformedTicket(true)

      sleep(5000)
    }
  })

  return (
    <>
      <div className={styles['page-container']}>
        {openBarcode && (
          <PromotionEntryDetailsModalContainer
            playerId={id}
            barcode={openBarcode}
            clearBarcode={() => setOpenBarcode(undefined)}
          />
        )}
        <div className={styles['players-details-container']}>
          <div className={styles['player-status-component-container']}>
            <PlayerSessionActivityTableComponent
              id={id}
              isDataPerformedSession={isDataPerformedSession}
              pageMessage={pageMessage}
              PlayerSessionActivityData={PlayerSessionActivityData}
            />
          </div>

          <div className={styles['player-profile-component-container']}>
            <PlayerMobileClaimsActivityTableComponent
              setPlayerMobileClaimsActivityData={
                setPlayerMobileClaimsActivityData
              }
              id={id}
              isDataPerformedMobile={isDataPerformedMobile}
              pageMessage={pageMessage}
              PlayerMobileClaimsActivityData={PlayerMobileClaimsActivityData}
              storesObject={storesObject}
            />
          </div>
          <div className={styles['players-details-center-container']}>
            <PlayerTicketScanningActivityTableComponent
              id={id}
              isDataPerformedTicket={isDataPerformedTicket}
              pageMessage={pageMessage}
              PlayerTicketScanningActivityData={
                PlayerTicketScanningActivityData
              }
              setOpenBarcode={setOpenBarcode}
            />
          </div>
        </div>
      </div>
    </>
  )
}
