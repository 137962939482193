import * as React from 'react'
import { useFetchAgent1099ReportMetadata, useFetchDownloadAgent1099Report } from '../../../api/agent-1099-reports'
import { Agent1099ReportPageUI } from './agent-1099-report-page-ui'

export const Agent1099ReportingPageContainer = () => {
  const {
    data: dataPage,
    isLoading: isLoadingPage,
    error: errorPage,
  } = useFetchAgent1099ReportMetadata()

  const { 
    isLoading: isLoadingDownload, 
    error: errorDownload, 
    preSignedUrlHandler 
  } = useFetchDownloadAgent1099Report()

  return (
    <Agent1099ReportPageUI
      isLoading={isLoadingPage || isLoadingDownload}
      error={errorPage ?? errorDownload}
      reports={dataPage?.reports ?? []}
      presignedUrlHandler={preSignedUrlHandler}
    />
  )
}
