export const UpdateLicenseSuccessMessage = {
    ORGANIZATION: 'Organization details were successfully updated.',
    BEANO_GAME_INFO: 'Beano Game Information were successfully updated.',
    LICENSE: 'License details were successfully updated.',
    MEMBER_IN_CHARGE: 'Member in Charge details were successfully updated.',
    HEAD_OF_ORG: 'Head of Organization details were successfully updated.',
    BOOKKEEPER: 'Bookkeeper details were successfully updated.',
    EFT: 'EFT details were successfully updated.',
    PERMIT_DOI: 'The date of Issuance was successfully updated.',
    PERMIT_PROFILE: 'The permit profile was successfully updated.',
    PULL_TAB_GAME_CREATE: 'The pull tab game was successfully created.',
    PULL_TAB_GAME_UPDATE: 'The pull tab game was successfully updated.'
}