import config from "../../../../config";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { FirstPaymentDetailsData } from "../../../../types/Annuities/FirstPayment/FirstPayment";

export const getFirstPaymentDetailsData: (id: string) => Promise<FirstPaymentDetailsData> = async id => {
  let firstPaymentDetails: FirstPaymentDetailsData;
  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/first-payment/${id}/details`,
  };

  try {
    const response = await makeHttpCall(options);
    firstPaymentDetails = response;
  } catch (error) {
    throw new Error("Failed to pull back First Payment List");
  }

  return firstPaymentDetails;
};
