import { CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { AnnuityManagementFilterSortComponent } from '../../../../components/AnnuitiesComponents/TPAManagement/TpaFilterSortCmpt'
import { AnnuityManagementListComponent } from '../../../../components/AnnuitiesComponents/TPAManagement/TpaListCmpt'
import { annuitiesRoutePath } from '../../../../config'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import styles from './css/styles.module.css'
import { onSubmit, setFieldValue, setVariables, validate } from './functions'
import { AnnuityAccountInfo } from './interfaces'

export const AnnuityManagementPage = inject('annuitiesStore')(
  observer(({ annuitiesStore }: any) => {
    const history = useHistory()
    const bannerContext = React.useContext(BannerContext)
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false)
    const [annuityAccountList, setAnnuityAccountList] = useState<
      AnnuityAccountInfo[]
    >([])
    const [filteredAccountList, setFilteredAccountList] = useState<any>([])

    const formik = useFormik({
      initialValues: {
        statusCode: '',
        payeeName: '',
        tin: '',
        documentId: '',
      },
      validate,
      onSubmit,
    })

    setVariables(
      formik,
      annuityAccountList,
      filteredAccountList,
      setFilteredAccountList
    )

    useEffect(() => {
      const fetchAnnuityAccounts = async () => {
        try {
          const response = await annuitiesStore.populateAnnuityAccountList()
          setAnnuityAccountList(response)
          setFilteredAccountList(response)
          setRequestCompleted(true)
        } catch (error) {
          setAnnuityAccountList([])
          setRequestCompleted(true)
          bannerContext.setBannerInfo({
            message: `${error}`,
            error: true,
          })
        }
      }
      fetchAnnuityAccounts().then()
    }, [])

    return (
      <>
        <div className={styles['page-container']}>
          {/* Circular Progress initial Load screen */}
          {!requestCompleted && (
            <div
              style={{
                position: 'absolute',
                zIndex: 50,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)',
                color: 'white',
              }}
            >
              <CircularProgress size="8em" color="inherit" thickness={2} />
            </div>
          )}
          {/* Back Btn tsx */}
          <div className={styles['back-btn-container']}>
            <button
              type="button"
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  history.push(`${annuitiesRoutePath}/`)
                }
              }}
              onClick={() => {
                history.push(`${annuitiesRoutePath}/`)
              }}
              className={styles['back-btn-inner-container']}
            >
              <FaAngleLeft
                title="left arrow icon"
                className={styles['back-btn-custom']}
              />
              <div className={styles['back-btn-text']}>
                Back to Annuities Landing Page
              </div>
            </button>
          </div>
          {/* Header */}
          <div className={styles['cmpnt-container']}>
            <div className={styles['top-ribbon-text']}>
              Time Payment Account Management
            </div>
            {/* Main Component */}
            <div className={styles['search_container']}>
              <AnnuityManagementFilterSortComponent
                formik={formik}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className={styles['table-container']}>
              <AnnuityManagementListComponent
                AnnuityAccountData={filteredAccountList}
              />
            </div>
          </div>
        </div>
      </>
    )
  })
)
