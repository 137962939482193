import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { keyPressedType } from '../../../services/Commons'
import goBackIcon from '../../../icons/Icon_arrows_carrot_white-left.svg'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { Agent1099QueueAllPaymentsTabContainer } from '../../../components/PaymentComponents/Agent1099QueueComponents/agent-1099-queue-all-payments-tab-container'
import { Agent1099QueuePrintQueueTabContainer } from '../../../components/PaymentComponents/Agent1099QueueComponents/agent-1099-queue-print-queue-tab-container'
import { Agent1099QueueTitleBarUI } from '../../../components/PaymentComponents/Agent1099QueueComponents/agent-1099-queue-title-bar-ui'
import './styles.scss'

const ROOT_CLASS = 'agent-1099-queue-page'

export type Agent1099QueuePageUIProps = {
  isLoading: boolean
  error?: string
  initiatePrint: (sweepId: string) => void
}

export const Agent1099QueuePageUI = (props: Agent1099QueuePageUIProps) => {
  const [allPaymentsTabSelected, setAllPaymentsTabSelected] = useState<boolean>(true)
  const [selectedSweepId, setSelectedSweepId] = useState<string>()
  const [printEnabled, setPrintEnabled] = useState<boolean>(false)

  const history = useHistory()

  return (
      <AsyncContainerUI
        isLoading={props.isLoading}
        error={props.error}
        errorTextSize={20}
        color="white"
      >
        <div className={ROOT_CLASS}>
          <div
            tabIndex={0}
            className={`back-button-container`}
            onClick={() => {
              history.goBack()
            }}
            onKeyDown={(ev: any) => {
              if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                history.goBack()
              }
            }}
          >
            <img
              style={{ cursor: 'pointer' }}
              src={goBackIcon}
              alt="go back icon"
            />
            <div className={`back-message`} style={{ cursor: 'pointer' }}>
              Back to Payments
            </div>
          </div>
          <Agent1099QueueTitleBarUI
            showPrintCTA={!allPaymentsTabSelected}
            activatePrintCTA={printEnabled}
            initiatePrint={() => {
              if (selectedSweepId) {
                props.initiatePrint(selectedSweepId)
                setSelectedSweepId(undefined)
              }
            }}
          />
          <div className="w-100 d-flex mt-3 border-bottom border-white">
          <button
            className={`tab-button ml-2 pt-2 text-center rounded-top`}
            style={
              allPaymentsTabSelected
                ? { background: '#fff', color: 'rgb(22, 58, 95)' }
                : { background: 'rgb(22, 58, 95)' }
            }
            onClick={() => {
              setAllPaymentsTabSelected(true)
            }}
          >
            All Agent 1099s
          </button>
          <button
            className={`tab-button ml-2 pt-2 text-center rounded-top`}
            style={
              allPaymentsTabSelected
                ? { background: 'rgb(22, 58, 95)' }
                : { background: '#fff', color: 'rgb(22, 58, 95)' }
            }
            onClick={() => {
              setAllPaymentsTabSelected(false)
            }}
          >
            Print Queue
          </button>
        </div>
          {allPaymentsTabSelected ?
            <Agent1099QueueAllPaymentsTabContainer />
            :
            <Agent1099QueuePrintQueueTabContainer
              setPrintEnabled={setPrintEnabled}
              sweepId={selectedSweepId}
              setSweepId={setSelectedSweepId}
            />
          }
        </div>
      </AsyncContainerUI>
  )
}
