import * as React from 'react'
import {
  PromotionBuilderRequestBody,
  PromotionStatus,
} from '../../../../../api/promotions-fetcher/constants'
import {
  useFetchPostPromotionCampaignBuilder,
  useFetchPutPromotionCampaignBuilder,
} from '../../../../../api/promotions-fetcher/use-fetch-base-promotions'
import { GenericSidebarFormMode } from '../../../../GenericSidebar/generic-sidebar-ui'
import { PromotionCampaignSidebarFormUI } from './promotion-campaign-sidebar-form-ui'

export type PromotionCampaignSidebarFormContainerProps = {
  promotion?: PromotionBuilderRequestBody
  promotionStatus?: PromotionStatus
  formMode: GenericSidebarFormMode
  canEditPromotionCampaigns: boolean
  closeSidebar: () => void
  refreshPromotions: () => Promise<void>
}

export const PromotionCampaignSidebarFormContainer = (
  props: PromotionCampaignSidebarFormContainerProps
) => {
  const {
    isLoading: createLoading,
    error: createError,
    requestSuccessful: createSuccessful,
    createPromotionCampaign,
  } = useFetchPostPromotionCampaignBuilder()

  const {
    isLoading: updateLoading,
    error: updateError,
    requestSuccessful: updateSuccessful,
    updatePromotionCampaign,
  } = useFetchPutPromotionCampaignBuilder()

  return (
    <PromotionCampaignSidebarFormUI
      {...props}
      isLoading={createLoading || updateLoading}
      error={createError ?? updateError}
      submitSuccessful={createSuccessful || updateSuccessful}
      createPromotionCampaign={createPromotionCampaign}
      updatePromotionCampaign={updatePromotionCampaign}
    />
  )
}
