import React, {useState} from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { KEY_CODES } from "../../../../constants.js";
import { keyPressedType } from "../../../../services/Commons";
import { annuitiesRoutePath } from "../../../../config.js";
import styles from "./css/styles.module.css";
import { InitiateAuthorizationCmpt } from "../../../../components/AnnuitiesComponents/TimePaymentAuthorization/InitiateAuthorizationCmpt/";
import { AuthReportingCmpt } from "../../../../components/AnnuitiesComponents/TimePaymentAuthorization/AuthReportingCmpt/index";

export const AuthorizationManagementPage = () => {
  const history = useHistory();

  const [isReportingSelected, setIsReportingSelected] = useState<boolean>(false)

  return (
    <>
      <div className={styles["page-container"]}>
        {/* Back Btn tsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                history.push(`${annuitiesRoutePath}/`);
              }
            }}
            onClick={() => {
              history.push(`${annuitiesRoutePath}/`);
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles["back-btn-custom"]}
            />
            <div className={styles["back-btn-text"]}>Back to Annuities Landing Page</div>
          </button>
        </div>
        {/* Header */}
        <div className={styles["cmpnt-container"]}>
          <div className={`${styles.title_header}`}>
            <div className={`${styles.flex_sp_bw}`}>
              <div className={`${styles.title_text}`}>Time Payment Authorization</div>
              <div>
                <button
                  type="button"
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      history.push(`${annuitiesRoutePath}/time-payment-authorization-search`);
                    }
                  }}
                  onClick={() => {
                    history.push(`${annuitiesRoutePath}/time-payment-authorization-search`);
                  }}
                  className={`${styles.dark_btn}`}
                >
                  Payment Search
                </button>
                <button
                  type="button"
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      history.push(`${annuitiesRoutePath}/time-payment-authorization-history`);
                    }
                  }}
                  onClick={() => {
                    history.push(`${annuitiesRoutePath}/time-payment-authorization-history`);
                  }}
                  className={`${styles.dark_btn}`}
                >
                  Authorization History
                </button>
              </div>
            </div>
          </div>
          {/* Tabs */}
          <div className="w-100 d-flex mt-3 border-bottom border-white" >
              <div 
                className={` ${styles.tab_btn} ml-2 pt-2 text-center rounded-top`} 
                style={isReportingSelected ? {background: "rgb(22, 58, 95)"} : {background: "#fff", color:"rgb(22, 58, 95)"}}
                onClick={() => {setIsReportingSelected(false)}}
                >Authorization</div>
              <div 
                className={` ${styles.tab_btn} ml-2 pt-2 text-center rounded-top`} 
                style={isReportingSelected ? {background: "#fff", color:"rgb(22, 58, 95)"} : {background: "rgb(22, 58, 95)"}}
                onClick={() => {setIsReportingSelected(true)}}
                >Reporting</div>
          </div>
          {/* Main Component */}
          <div className={styles["cmpnt-container"]}>
            {!isReportingSelected ? <InitiateAuthorizationCmpt /> : <AuthReportingCmpt />}
          </div>
        </div>
      </div>
    </>
  );
};
