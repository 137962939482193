import * as React from 'react'

type UseFormArrayProps<T> = {
  defaultFieldValue: T
  fieldArray: T[]
  setFieldArray: (newFieldValues: T[]) => Promise<void>
  expectedNumberOfValues?: number
  setExpectedNumberOfValues?: (newNumber: number) => Promise<void>
  mapFieldValue?: (value: T, index?: number) => T
}

export const useFormArray = <T,>(props: UseFormArrayProps<T>) => {
  const addElement = async () => {
    const newElements = [...props.fieldArray]
    newElements.push(props.defaultFieldValue)
    await props.setExpectedNumberOfValues?.(newElements.length)
    await props.setFieldArray(newElements)
  }

  const removeElement = async (index: number) => {
    let newElements = [...props.fieldArray]
    newElements.splice(index, 1)
    newElements = props.mapFieldValue
      ? newElements.map(props.mapFieldValue)
      : newElements
    await props.setExpectedNumberOfValues?.(newElements.length)
    await props.setFieldArray(newElements)
  }

  React.useEffect(() => {
    let newNumberOfElements = props.expectedNumberOfValues
    const previousNumberOfElements = props.fieldArray.length

    if (
      newNumberOfElements === undefined ||
      newNumberOfElements === previousNumberOfElements
    ) {
      return
    }

    let newElements = [...props.fieldArray]

    if (newNumberOfElements > previousNumberOfElements) {
      newElements.push(
        ...Array(newNumberOfElements - previousNumberOfElements).fill({})
      )
    } else {
      newElements = newElements.slice(
        0,
        newNumberOfElements - previousNumberOfElements
      )
    }

    newElements = props.mapFieldValue
      ? newElements.map(props.mapFieldValue)
      : newElements

    if (props.setExpectedNumberOfValues) {
      props.setExpectedNumberOfValues(newElements.length)
    }
    props.setFieldArray(newElements)
  }, [props.expectedNumberOfValues, props.fieldArray])

  return {
    removeElement,
    addElement,
  }
}
