import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, handleCheckboxChange, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {pulltabRoutePath} from "../../../../../config.js";
import {CircularProgress} from "@material-ui/core";
import {
    PullTabStockInventorySearchBarComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Inventory/PullTabStockInventorySearchBarComponent";
import {
    PullTabStockInventorySearchResultComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Inventory/PullTabStockInventorySearchResultComponent";
import {
    GenericErrorDialogComponent
} from "../../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {PullTabStockInventory, PullTabStockInventoryDefaultData} from "./interfaces";

export const PullTabStockInventoryPage = inject("charitableGamingStore")(observer(({stockNumber, charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const formik = useFormik({
            initialValues: {
                warehouse: '',
                stockNumber: stockNumber,
                startDateRecorded: moment().subtract(90, 'days').format('YYYY-MM-DD'),
                endDateRecorded: moment().format('YYYY-MM-DD'),
                serialNumber: '',
                showSoldOnly: false
            },
            validate,
            onSubmit
        });

        const [unfilteredStockInventory, setUnfilteredStockInventory] = useState<PullTabStockInventory>(PullTabStockInventoryDefaultData);
        const [filteredStockInventory, setFilteredStockInventory] = useState<PullTabStockInventory>(PullTabStockInventoryDefaultData);

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(0);

        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
        const [notificationBanner, showNotificationBanner] = useState<any>({show: false, text: ''});

        useEffect(() => {
            if (requestCompleted === false) {
                formik.submitForm();
            }
        }, []);

        setVariables(formik, charitableGamingStore, unfilteredStockInventory, setUnfilteredStockInventory, filteredStockInventory,
            setFilteredStockInventory, setCurrentPage, setRequestCompleted, setShowErrorDialog, showNotificationBanner);

        return (
            <>
                {(false) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['pulltab-stock-inventory-page-container']} ${styles['']}`}
                     onClick={() => {
                         // if (pageMessage.messageType !== "") setPageMessage({message: "", messageType: ""});
                         if (notificationBanner.text !== "") {
                             showNotificationBanner({show: false, text: ""});
                         }
                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['pulltab-stock-inventory-page-back-button-container']} ${styles['']}`}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 history.push(`${pulltabRoutePath}/inventory`);
                             }
                         }}
                         onClick={() => {
                             history.push(`${pulltabRoutePath}/inventory`);
                         }}
                    >
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['pulltab-stock-inventory-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                        >Back
                            to Pull Tab Inventory
                        </div>
                    </div>
                    <div className={`${styles['pulltab-stock-inventory-page-ribbon-container']} ${styles['']}`}>
                        <div className={`${styles['pulltab-stock-inventory-page-ribbon-title']} ${styles['']}`}>Stock
                            #{stockNumber} Inventory
                        </div>

                    </div>

                    <div
                        className={`${styles['pulltab-stock-inventory-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                        <PullTabStockInventorySearchBarComponent
                            formik={formik}
                            setFieldValue={setFieldValue}
                            clearSearch={clearSearch}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    </div>
                    {filteredStockInventory.packets.length <= 0 ?
                        <div className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                            <div className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                                No Results. Please try a different search.
                            </div>
                        </div>
                        :
                        <PullTabStockInventorySearchResultComponent
                            stockInventory={filteredStockInventory}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        type={NotificationType.Success}
                        text={notificationBanner.text}
                        handleHide={showNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);