import * as React from 'react'
type TrashIconProps = {
  altText?: string
  className?: string
}
export const TrashIcon = (props: TrashIconProps) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <title>{props.altText ?? 'Trash Icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0234 0.0356445C5.39602 0.0356445 0.0234375 5.40823 0.0234375 12.0356C0.0234375 18.6631 5.39602 24.0356 12.0234 24.0356C18.6509 24.0356 24.0234 18.6631 24.0234 12.0356C24.0234 5.40823 18.6509 0.0356445 12.0234 0.0356445ZM12.0234 2.28564C17.4082 2.28564 21.7734 6.65087 21.7734 12.0356C21.7734 17.4204 17.4082 21.7856 12.0234 21.7856C6.63866 21.7856 2.27344 17.4204 2.27344 12.0356C2.27344 6.65087 6.63866 2.28564 12.0234 2.28564ZM7.52344 6.03564H9.46406L9.99375 5.50596C10.1344 5.36533 10.3266 5.28564 10.5234 5.28564H13.5234C13.7203 5.28564 13.9125 5.36533 14.0531 5.50596L14.5828 6.03564H16.5234C16.9359 6.03564 17.2734 6.37314 17.2734 6.78564C17.2734 7.19814 16.9359 7.53564 16.5234 7.53564H7.52344C7.11094 7.53564 6.77344 7.19814 6.77344 6.78564C6.77344 6.37314 7.11094 6.03564 7.52344 6.03564ZM15.8859 16.6622L16.5234 9.03564H7.52344L8.15625 16.6622C8.22188 17.4356 8.87344 18.0356 9.65156 18.0356H14.3906C15.1734 18.0356 15.8203 17.4403 15.8859 16.6622Z"
        fill="white"
      />
    </svg>
  )
}
