import React from 'react'
import stylesMessageBox from './css/stylesMessageBox.module.css'
import { keyPressedType } from '../../../services/Commons'
import { useHistory } from 'react-router-dom'

export type GenericErrorDialogProps = {
  setShowErrorDialog: (value: boolean) => void
  message?: string
  subMessage?: string
  closeButtonLabel?: string
  useHistoryForCloseButton?: boolean
}

export const GenericErrorDialogComponent = (props: GenericErrorDialogProps) => {
  const message = props.message ?? 'Error'
  const subMessage =
    props.subMessage ?? 'Oops, something went wrong. Please try again later.'
  const closeButtonLabel = props.closeButtonLabel ?? 'Okay'
  const useHistoryForCloseButton = props.useHistoryForCloseButton ?? false

  const history = useHistory() // Get the history object

  const handleCloseButtonClick = () => {
    props.setShowErrorDialog(false)
    if (useHistoryForCloseButton) {
      history.goBack()
    }
  }

  return (
    <>
      <div className={stylesMessageBox['generic-error-dialog-main-container']}>
        <div
          tabIndex={0}
          id="generic-error-dialog-container"
          className={stylesMessageBox['generic-error-dialog-container']}
        >
          <div
            tabIndex={0}
            className={stylesMessageBox['generic-error-dialog-title']}
          >
            {message}
          </div>
          <div
            tabIndex={0}
            className={stylesMessageBox['generic-error-dialog-message']}
          >
            {subMessage}
          </div>
          <div
            tabIndex={0}
            className={stylesMessageBox['generic-error-dialog-yes-button']}
            role="button"
            onKeyDown={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                props.setShowErrorDialog(false)
              }
            }}
            onClick={handleCloseButtonClick}
          >
            <div
              className={
                stylesMessageBox['generic-error-dialog-yes-button-text']
              }
            >
              {closeButtonLabel}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
