import * as React from 'react'
import { useFormik } from 'formik'
import {
  PromotionBuilderRequestBody,
  PromotionStatus,
  PromotionType,
} from '../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../utilities/helpers'
import { LoadingDots } from '../../../../CommonComponents/loading-dots/loading-dots-ui'
import { GenericSidebarFormMode } from '../../../../GenericSidebar/generic-sidebar-ui'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  promotionIsNotComplete,
} from './helpers'
import { PromotionBonusDrawingsFormUI } from './sub-forms/bonus-drawings-form-ui'
import { PromotionDetailsFormUI } from './sub-forms/promotion-details-form-ui'
import { PromotionDrawingsFormUI } from './sub-forms/promotion-drawings-form-ui'
import { PromotionPrizeTiersFormUI } from './sub-forms/promotion-prize-tiers-form-ui'
import {
  getInitialValues,
  mapSubmissionBody,
  validatePromotionCampaignForm,
} from './validation'
import './styles.scss'
import { PromotionRulesFormUI } from './sub-forms/promotion-rules-form-ui'

export type PromotionCampaignSidebarFormUIProps = {
  formMode: GenericSidebarFormMode
  promotion?: PromotionBuilderRequestBody
  promotionStatus?: PromotionStatus
  closeSidebar: () => void
  refreshPromotions: () => Promise<void>
  isLoading: boolean
  error?: string
  submitSuccessful: boolean
  createPromotionCampaign: (
    promotion: PromotionBuilderRequestBody
  ) => Promise<void>
  updatePromotionCampaign: (
    promotion: PromotionBuilderRequestBody
  ) => Promise<void>
  canEditPromotionCampaigns: boolean
}

export const PromotionCampaignSidebarFormUI = (
  props: PromotionCampaignSidebarFormUIProps
) => {
  const refreshPromotions = async () => await props.refreshPromotions()

  React.useEffect(() => {
    if (props.submitSuccessful) {
      refreshPromotions()
      props.closeSidebar()
    }
  }, [props.submitSuccessful])

  const formik = useFormik({
    initialValues: getInitialValues(props.promotion),
    onSubmit: async (values) => {
      let submissionPayload: PromotionBuilderRequestBody
      try {
        submissionPayload = mapSubmissionBody(values, props.promotion)
      } catch {
        return
      }

      if (props.formMode === GenericSidebarFormMode.NEW) {
        await props.createPromotionCampaign(submissionPayload)
      } else {
        await props.updatePromotionCampaign(submissionPayload)
      }
    },
    validate: validatePromotionCampaignForm,
  })

  const subFormProps = {
    formik: formik,
    formMode: props.formMode,
    isLoading: props.isLoading,
    promotionStatus: props.promotionStatus,
    canEditPromotionCampaigns: props.canEditPromotionCampaigns,
  }

  return (
    <div className={PROMOTION_SIDEBAR_FORM_ROOT_CLASS}>
      <form
        className={PROMOTION_SIDEBAR_FORM_CLASS}
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'content')}>
          <PromotionDetailsFormUI {...subFormProps} />
          <PromotionDrawingsFormUI
            {...subFormProps}
            numberOfDrawingsField={formik.values.numberOfDrawings}
            drawingsField={formik.values.drawings}
          />
          {formik.values.promotionType === PromotionType.SCAN_TO_ENTER && (
            <PromotionRulesFormUI
              rules={formik.values.promotionRewardGroups[0].rules}
              {...subFormProps}
            />
          )}
          <PromotionPrizeTiersFormUI
            {...subFormProps}
            prizeTiersField={formik.values.prizeTiers}
          />
          {formik.values.promotionType === PromotionType.SECOND_CHANCE && (
            <PromotionBonusDrawingsFormUI
              {...subFormProps}
              numberOfDrawingsField={formik.values.numberOfBonusDrawings}
              drawingsField={formik.values.bonusDrawings}
              prizeTiersField={formik.values.bonusPrizeTiers}
            />
          )}
        </div>
        {props.error && (
          <span
            className={getBlockClass(
              PROMOTION_SIDEBAR_FORM_CLASS,
              'error-text'
            )}
          >
            Error: "{props.error}"
          </span>
        )}
        {props.canEditPromotionCampaigns &&
          promotionIsNotComplete(props.promotionStatus) && (
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_CLASS,
                'save-button-container'
              )}
            >
              <button
                type="submit"
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_CLASS,
                  'save-button'
                )}
                aria-label="Save promotion campaign"
                disabled={!formik.dirty || !formik.isValid || props.isLoading}
              >
                {props.isLoading ? (
                  <div aria-busy={props.isLoading} aria-describedby="loading">
                    <LoadingDots id="loading" color="white" />
                  </div>
                ) : (
                  <>
                    {props.formMode === GenericSidebarFormMode.NEW
                      ? 'Create'
                      : 'Save'}
                  </>
                )}
              </button>
            </div>
          )}
      </form>
    </div>
  )
}
