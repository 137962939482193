import React, { useState } from "react";
import { useFormik } from "formik";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { keyPressedType } from "../../../../../services/Commons";
import KEY_CODES from "../../../../../config.js";
import { validate } from "./functions";
import styles from "./css/styles.module.css";
import InputMask from "react-input-mask";
import { CheckboxComponent } from "../../../../CommonComponents/CheckboxComponent";
import { ErrorMessageFieldCommonComponent } from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";

export const PaymentScheduleFilterSort: React.FC<{
  payeeNames: string[];
  isPastPaymentVisible: boolean;
  selectedPayee: string;
  tinSelected: string;
  setIsPastPaymentsVisible: (value: boolean) => void;
  setSelectedPayee: (value: string) => void;
  setTinSelected: (value: string) => void;
}> = ({ payeeNames, isPastPaymentVisible, selectedPayee, tinSelected, setIsPastPaymentsVisible, setSelectedPayee, setTinSelected }) => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const clearSearch = () => {
    setSelectedPayee("");
    setTinSelected("");
    setIsPastPaymentsVisible(true);
  };

  //initialize formik
  const formik = useFormik({
    initialValues: { tin: tinSelected },
    initialTouched: { tin: true },
    enableReinitialize: true,
    onSubmit: values => {},
    validate,
  });

  return (
    <div className={`${styles.search_container}`}>
      <h3>Search</h3>
      <div className={`${styles.filter_container}`}>
        <h3>
          Filter
          <button
            style={{ marginLeft: "0.25em" }}
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                setIsFilterOpen(!isFilterOpen);
              }
            }}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            {isFilterOpen ? <FaChevronUp style={{ color: "rgb(127, 203, 196)" }} /> : <FaChevronDown style={{ color: "rgb(127, 203, 196)" }} />}
          </button>
        </h3>
        <div className={isFilterOpen ? `${styles.payee_container_open}` : `${styles.payee_container_closed}`}>
          <div className={`${styles.flex_col}`}>
            <label htmlFor="payee">Payee</label>
            <div className={`${styles.custom_select_div}`}>
              <select
                className={`${styles.custom_select}`}
                onChange={event => {
                  setSelectedPayee(event.target.value);
                }}
                name="selectPayee"
                id="selectPayee"
                defaultValue=""
                tabIndex={isFilterOpen ? 0 : -1}
              >
                <option
                  value=""
                  disabled
                  selected={selectedPayee === "" ? true : false}
                >
                  Select Payee...
                </option>
                {payeeNames.map((payee: string, index: number) => (
                  <option
                    key={index}
                    value={payee}
                    selected={payee === selectedPayee ? true : false}
                  >
                    {`${payee}`}
                  </option>
                ))}
              </select>
              <span className={`${styles.custom_arrow}`}></span>
            </div>
          </div>
          <div className={`${styles.past_pymt_container}`}>
            <button
              tabIndex={isFilterOpen ? 0 : -1}
              style={{ marginRight: "0.3em" }}
              onKeyPress={event => {
                if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                  setIsPastPaymentsVisible(!isPastPaymentVisible);
                }
              }}
              onClick={() => {
                setIsPastPaymentsVisible(!isPastPaymentVisible);
              }}
            >
              <CheckboxComponent isChecked={isPastPaymentVisible} />
            </button>
            Show Past Payments
          </div>
        </div>
      </div>
      <div>
        <div className={`${styles.tin_container}`}>
          <div className={`${styles.flex_col} ${styles.input_container}`}>
            <label htmlFor="tin">TIN*</label>
            <InputMask
              mask="999999999"
              maskChar=""
              className={`${styles.custom_input}`}
              id="tin"
              name="tin"
              placeholder={"123456789"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tin}
            ></InputMask>
            {formik.errors.tin && formik.touched.tin && <ErrorMessageFieldCommonComponent errorMessage={formik.errors.tin} />}
          </div>
          <div className={`${styles.btn_container}`}>
            <button
              type="button"
              className={`${styles.light_btn}`}
              disabled={Object.keys(formik.errors).length > 0 || formik.values.tin === "" ? true : false}
              onKeyPress={() => setTinSelected(formik.values.tin)}
              onClick={() => setTinSelected(formik.values.tin)}
            >
              Search
            </button>
            <button
              type="button"
              className={`${styles.dark_btn}`}
              onKeyPress={event => {
                if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                  clearSearch();
                }
              }}
              onClick={() => clearSearch()}
            >
              Clear Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
