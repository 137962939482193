import * as React from 'react'
import {
  CampaignData,
  CampaignFile,
} from '../../../api/promotions-fetcher/coupon-campaigns/constants'
import { CampaignsRedemptionPieChart } from '../campaigns-redemption-pie-chart/campaigns-redemption-pie-chart-ui'
import { CampaignsListRowActionMenuUI } from './campaigns-list-row-action-menu/campaigns-list-row-action-menu-ui'
import './styles.scss'

const ROOT_CLASS = 'campaigns-list-row'
const ROW_CLASS = 'campaigns-list-row__details'
const BOLD_FONT_CLASS = `${ROW_CLASS}__semi-bold-font`
const REGULAR_FONT_CLASS = `${ROW_CLASS}__regular-font`
const ITALIC_REGULAR_FONT_CLASS = `${ROW_CLASS}__italic-font`

export type CampaignsListRowUIProps = {
  rowData: CampaignData
  fileStatus?: CampaignFile[]
  endCampaignDisabled: boolean
  setOpenPreviewImage: (imageUrl?: string) => void
  showEditCampaign: (campaignIdentifier: string) => void
}

function formatNumberWithCommas(number: number) {
  return number.toLocaleString()
}

function calcRedeemedPercentage(redeemed: number, total: number) {
  return redeemed > 0 ? Math.floor((redeemed / total) * 100) : undefined
}

const getMessageClass = (className: string, status?: string) =>
  status ? `${className}--${status}` : className

export const CampaignsListRowUI = (props: CampaignsListRowUIProps) => {
  const [fileStatusBar, setFileStatusBar] = React.useState<
    'error' | 'pending' | undefined
  >(undefined)

  React.useEffect(() => {
    if (props.fileStatus) {
      if (props.fileStatus.find((file) => file.fileStatus === 'Pending')) {
        setFileStatusBar('pending')
      } else if (props.fileStatus.find((file) => file.fileStatus === 'Error')) {
        setFileStatusBar('error')
      } else {
        setFileStatusBar(undefined)
      }
    }
  }, [props.fileStatus])

  /* Total Barcodes = Total Available + Total Assigned + Total Redeemed */
  /* Total Assigned and Total Redeemed are mutually exclusive, if an assigned barcode was redeemed, it is ONLY counted in redeemed */
  const totalAssigned =
    props.rowData.barcodes.barcodesAssigned +
    props.rowData.barcodes.barcodesRedeemed
  const totalRedeemed = props.rowData.barcodes.barcodesRedeemed
  const redeemedPercentage = calcRedeemedPercentage(
    totalRedeemed,
    totalAssigned
  )

  return (
    <tbody
      className={`${ROOT_CLASS} ${getMessageClass(ROOT_CLASS, fileStatusBar)}`}
    >
      {fileStatusBar && (
        <tr>
          <td colSpan={100}>
            <div
              className={`${ROOT_CLASS}__message-bar ${getMessageClass(
                `${ROOT_CLASS}__message-bar`,
                fileStatusBar
              )}`}
            >
              {fileStatusBar === 'error'
                ? `One or more player files is currently in error status for this campaign`
                : `Player files processing`}
            </div>
          </td>
        </tr>
      )}
      <tr className={ROW_CLASS}>
        <td>
          <div className={`${ROW_CLASS}__image-container`}>
            <img
              className={`${ROW_CLASS}__image`}
              src={props.rowData.coverImageUrl}
              onClick={() =>
                props.setOpenPreviewImage(props.rowData.coverImageUrl)
              }
              alt={`Campaign cover image for ${props.rowData.campaignIdentifier}`}
              aria-label="Campaign Image Preview"
            />
          </div>
        </td>
        <td>
          <div className={BOLD_FONT_CLASS}>{props.rowData.displayName}</div>
          <div className={REGULAR_FONT_CLASS}>
            UID {props.rowData.campaignIdentifier}
          </div>
        </td>
        <td>
          <div className={`${ROW_CLASS}-status`}>
            <div
              className={`${ROW_CLASS}-status--${props.rowData.status.toLowerCase()}`}
            />
            <div className={`${ROW_CLASS}-status__text ${BOLD_FONT_CLASS}`}>
              {props.rowData.status}
            </div>
          </div>
        </td>
        <td>
          <div className={`${ROW_CLASS}__date-range ${REGULAR_FONT_CLASS}`}>
            {props.rowData.dateRangeDisplayText}
          </div>
        </td>
        <td>
          <div className={REGULAR_FONT_CLASS}>
            {props.rowData.expirationDisplayText}
          </div>
          {props.rowData.expirationDaysLeftText && (
            <div
              className={`${ROW_CLASS}__days-left ${ITALIC_REGULAR_FONT_CLASS}`}
            >
              {props.rowData.expirationDaysLeftText}
            </div>
          )}
        </td>
        <td className={REGULAR_FONT_CLASS}>
          {props.rowData.barcodes.totalBarcodes > 0 &&
            formatNumberWithCommas(props.rowData.barcodes.totalBarcodes)}
        </td>
        <td className={REGULAR_FONT_CLASS}>
          {totalAssigned > 0 && formatNumberWithCommas(totalAssigned)}
        </td>
        <td>
          {totalRedeemed > 0 && (
            <div>
              <div className={REGULAR_FONT_CLASS}>
                {formatNumberWithCommas(totalRedeemed)}
              </div>
              <div className={`${ROW_CLASS}-redeemed`}>
                <div className={`${ROW_CLASS}-redeemed__indicator`} />
                <div
                  className={`${ROW_CLASS}-redeemed__text ${ITALIC_REGULAR_FONT_CLASS}`}
                >
                  {redeemedPercentage}%
                </div>
              </div>
            </div>
          )}
        </td>
        <td>
          <CampaignsRedemptionPieChart percentRedeemed={redeemedPercentage} />
        </td>
        <td>
          <CampaignsListRowActionMenuUI
            campaignIdentifier={props.rowData.campaignIdentifier}
            campaignStatus={props.rowData.status}
            openEditCampaign={() =>
              props.showEditCampaign(props.rowData.campaignIdentifier)
            }
            endCampaignDisabled={props.endCampaignDisabled}
          />
        </td>
      </tr>
    </tbody>
  )
}
