import React from "react";
import styles from './css/styels.module.css'
import logo from  './../../../icons/logo_white-fill_no-secondary.png';

const HeaderComponent = () => {
    return (
        <div tabIndex={0} className={styles['header-page']}>
               <img title="logo" src={logo} alt={"logo"} />
        </div>
    )
};

export default HeaderComponent;