import React from "react";
import {SourceGuideUI, SourceGuideEntry, SourceGuideUIProps} from "../SourceGuideComponent";

const paymentSourceGuideEntries : SourceGuideEntry[] = [
  {
      abbreviation: 'AN',
      fullName: 'Annuities'
  },
  {
      abbreviation: 'CC',
      fullName: 'Claim Center'
  },
  {
      abbreviation: 'PR',
      fullName: 'Promotions'
  },
  {
      abbreviation: 'RTC',
      fullName: 'Remote Ticket Cashing'
  },
  {
      abbreviation: 'ST',
      fullName: 'Season Tickets'
  },
  {
      abbreviation: 'OTR',
      fullName: 'Other'
  },
  {
      abbreviation: 'SP',
      fullName: 'Split'
  },
  {
      abbreviation: 'SC',
      fullName: 'Second Chance'
  }
]

export const PaymentSourceGuideUI = (props: Omit<SourceGuideUIProps, 'entries'>) => {
  return <SourceGuideUI
      setPaymentSourceGuide={props.setPaymentSourceGuide}
      entries={paymentSourceGuideEntries}/>
}