import * as React from 'react'
import { useFetchAllPromotions } from '../../../api/promotions-fetcher/use-fetch-base-promotions'
import { WinnersManagementLandingPageUI } from './winners-management-landing-page-ui'

export const WinnersManagementLandingPageContainer = () => {
  const { data, isLoading, error } = useFetchAllPromotions()

  return (
    <WinnersManagementLandingPageUI
      promotionsLoading={isLoading}
      promotions={data}
      promotionsError={error}
    />
  )
}
