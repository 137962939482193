import * as React from 'react'
import { useFetch } from '../../hooks/use-fetch'
import config from '../../config'
import { permissions } from '../../constants.js'
import { PreSignedUrlResponse } from '../finserv-general-reports/api-types'
import { saveFromUrl } from '../../util'
import {
  Agent1099ReportMetadataResponse,
  Agent1099YearsResponse,
} from './api-types'

const baseUrl = `${config.SERVER_BASE_URL}/api/v1`

export const useFetchAgent1099ReportMetadata = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<Agent1099ReportMetadataResponse>()

  const reloadIntervalId = React.useRef<NodeJS.Timeout>()

  React.useEffect(() => {
    return () => {
      clearInterval(reloadIntervalId.current)
    }
  }, [])

  const fetch1099ReportsMetadata = async () => {
    await fetchUrl(
      `${baseUrl}/reporting/agent1099/reports`,
      'GET',
      {},
      undefined,
      [permissions.CAN_SEE_1099_REPORTS]
    )
  }

  const autoReload = () => {
    if (!reloadIntervalId.current) {
      const interval = setInterval(() => {
        fetch1099ReportsMetadata()
      }, 10000)
      reloadIntervalId.current = interval
    }
  }

  React.useEffect(() => {
    fetch1099ReportsMetadata()
  }, [])

  return {
    isLoading,
    error,
    data,
    fetch1099ReportsMetadata,
    autoReload,
  }
}

export const useFetchDownloadAgent1099Report = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<PreSignedUrlResponse>()
  const preSignedUrlHandler = async (id: string) => {
    const urlWithParams = `${baseUrl}/reporting/agent1099/reportUrl?reportId=${id}`
    const preSignedUrl = await fetchUrl(urlWithParams, 'GET', {}, undefined, [
      permissions.CAN_SEE_1099_REPORTS,
    ])
    if (!preSignedUrl) {
      return
    }
    saveFromUrl(preSignedUrl.url)
  }
  return { isLoading, error, data, preSignedUrlHandler }
}

export const useFetchAgent1099AvailableYears = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<Agent1099YearsResponse>()
  const fetchAvailableYears = async () => {
    const url = `${baseUrl}/agent1099Queue/dataYears`
    fetchUrl(url)
  }
  return { isLoading, data, error, fetchAvailableYears }
}

export const useFetchGenerateYearEnd1099Report = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<Agent1099YearsResponse>()
  const generateReportBundleForYear = async (year: string) => {
    const url = `${baseUrl}/yearEndReporting/agent1099/generate`
    const requestBody = {
      year,
      generatedBy: localStorage.getItem('loggedInUserFullName'),
      isTestFile: false,
    }
    fetchUrl(url, 'POST', undefined, JSON.stringify(requestBody), [
      permissions.CAN_GENERATE_1099_REPORTS,
    ])
  }
  return { isLoading, data, error, generateReportBundleForYear }
}
