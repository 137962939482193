import { FormPayee } from "../../../../types/Annuities/PayeeManagement/Payee";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import config from "../../../../config.js";

// GetPayee
export const getPayee = async (id: string): Promise<FormPayee> => {
  let payee: FormPayee;
  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/payee/${id}`,
  };

  try {
    payee = await makeHttpCall(options);
  } catch (error) {
    throw new Error("Something went wrong getting Payee");
  }

  return payee;
};

export const getPlaceholderPayee = (): FormPayee => {
  const loadingPayee: FormPayee = {
    id: "Loading...",
    createdOn: "11/11/1111",
    validFrom: "Loading...",
    validTo: "Loading...",
    address1: "Loading...",
    address2: "Loading...",
    city: "Loading...",
    country: "Loading...",
    dateOfBirth: "11/09/1111",
    dateOfDeath: null,
    firstName: "Loading...",
    freeformName: "Loading...",
    lastName: "Loading...",
    middleInitial: "Loading...",
    notes: "",
    payeeType: "Individual",
    email: "Loading...",
    phone: "Loading...",
    tin: "*********",
    state: "Loading...",
    zip: "Loading...",
    irsName: "Loading...",
  };
  return loadingPayee;
};
