import React from 'react'
import styles from './css/styles.module.css'
import {
  claimEligibleDefaultToNo,
  convertAmount,
  downloadClicked,
  getPageData,
} from './functions'
import { keyPressedType } from '../../../services/Commons'

import { ExportToCsv } from 'export-to-csv'
import moment from 'moment-timezone'
import { normilizeAmount } from '../PlayerTicketScanningActivityTableComponent/functions'
import { AccessibleKey } from '../../common'

const CsvOptions = {
  showLabels: true,
  useTextFile: false,
  useBom: true,
  filename: `ClaimsActivityExport`,
  showTitle: true,
  headers: [
    'Date',
    'Barcode',
    'Game',
    'Ticket Status',
    'Winning Amount',
    'Mobile Claim Eligible',
  ],
}

let csvExporter: ExportToCsv = new ExportToCsv(CsvOptions)

export const TicketScanningActivityTableComponent = ({
  id,
  MCAPlayerData,
  currentPage,
  setCurrentPage,
  setOpenBarcode,
}: any) => {
  const normalizePage = (totalPages: number, currentPage: number) => {
    const number = totalPages - currentPage * 10
    return number > 10 ? currentPage * 10 + 10 : totalPages
  }
  // MCAPlayerData = array;
  const createPages = (
    MCAPlayerData: any[],
    currentPage: any,
    setCurrentPage: any
  ) => {
    const array: any = []
    const pagesTotal = Math.ceil(MCAPlayerData.length / 10)

    if (currentPage > 2 && pagesTotal > 5) {
      array.push(
        <>
          <div
            aria-live="assertive"
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === 0 ? 'true' : 'false'}
            style={{
              backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : '',
            }}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                setCurrentPage(0)
              }
            }}
            onClick={() => setCurrentPage(0)}
            className={styles['MCA-table-page-box']}
          >
            <div className={styles['MCA-table-page-box-text']}>{1}</div>
          </div>
          <div>{'...'}</div>
        </>
      )
    }
    if (currentPage <= 2 || pagesTotal <= 5) {
      for (
        let i = 0;
        i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1;
        i++
      ) {
        array.push(
          <div
            key={i}
            aria-live="assertive"
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === i ? 'true' : 'false'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                setCurrentPage(i)
              }
            }}
            onClick={() => setCurrentPage(i)}
            style={{
              backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : '',
            }}
            className={styles['MCA-table-page-box']}
          >
            <div className={styles['MCA-table-page-box-text']}>{i + 1}</div>
          </div>
        )
      }
    } else if (currentPage > 2 && currentPage <= pagesTotal - 1 - 2) {
      for (
        let i = currentPage - 2;
        i < currentPage + 3 && currentPage <= pagesTotal - 1;
        i++
      ) {
        array.push(
          <div
            aria-live="assertive"
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === i ? 'true' : 'false'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                setCurrentPage(i)
              }
            }}
            onClick={() => setCurrentPage(i)}
            style={{
              backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : '',
            }}
            className={styles['MCA-table-page-box']}
          >
            <div className={styles['MCA-table-page-box-text']}>{i + 1}</div>
          </div>
        )
      }
    } else if (currentPage > pagesTotal - 1 - 2) {
      for (let i = pagesTotal - 1 - 4; i < pagesTotal; i++) {
        array.push(
          <div
            aria-live="assertive"
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === i ? 'true' : 'false'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                setCurrentPage(i)
              }
            }}
            onClick={() => setCurrentPage(i)}
            style={{
              backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : '',
            }}
            className={styles['MCA-table-page-box']}
          >
            <div className={styles['MCA-table-page-box-text']}>{i + 1}</div>
          </div>
        )
      }
    }
    if (pagesTotal > 5 && currentPage < pagesTotal - 1 - 2) {
      array.push(
        <>
          <div>{'...'}</div>
          <div
            aria-live="assertive"
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === pagesTotal ? 'true' : 'false'}
            style={{
              backgroundColor:
                currentPage === pagesTotal ? 'rgb(49, 109, 156)' : '',
            }}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                setCurrentPage(pagesTotal - 1)
              }
            }}
            onClick={() => setCurrentPage(pagesTotal - 1)}
            className={styles['MCA-table-page-box']}
          >
            <div className={styles['MCA-table-page-box-text']}>
              {pagesTotal}
            </div>
          </div>
        </>
      )
    }
    return array
  }

  return (
    <>
      <div className={styles['MCA-table']}>
        <div className={styles['MCA-table-data-header']}>
          <div aria-live="assertive" tabIndex={0}>{`Displaying ${
            MCAPlayerData.length > 0 && MCAPlayerData[0].gameId === id
              ? 0
              : currentPage * 10 + 1
          } - 
                            ${normalizePage(MCAPlayerData.length, currentPage)} 
                         of ${
                           MCAPlayerData.length > 0 &&
                           MCAPlayerData[0].gameId === id
                             ? 0
                             : MCAPlayerData.length
                         } results`}</div>
          <div className={styles['MCA-table-export-button-container']}>
            <button
              style={{
                opacity:
                  MCAPlayerData.length > 0 && MCAPlayerData[0].gameId !== id
                    ? '1'
                    : '0.4',
                cursor:
                  MCAPlayerData.length > 0 && MCAPlayerData[0].gameId !== id
                    ? 'pointer'
                    : 'not-allowed',
              }}
              className={styles['MCA-table-export-button']}
              onClick={() =>
                MCAPlayerData.length > 0 &&
                MCAPlayerData[0].gameId !== id &&
                downloadClicked(MCAPlayerData, csvExporter)
              }
            >
              Download Table as CSV
            </button>
          </div>
        </div>

        <div
          className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-label']} `}
        >
          <div tabIndex={0}>Date</div>
          <div tabIndex={0}>Barcode</div>
          <div tabIndex={0}>Game</div>
          <div tabIndex={0}>Ticket Status</div>
          <div tabIndex={0}>Winning Amount</div>
          <div className={styles['KYCQUEUE-table-data-column-4']} tabIndex={0}>
            Mobile Claim Eligible
          </div>
        </div>
        <div className={styles['MCA-table-data-body']}>
          {MCAPlayerData.length > 0 &&
            MCAPlayerData[0].gameId !== id &&
            getPageData(MCAPlayerData, currentPage).map((e: any, i: number) => (
              <div
                key={i}
                style={{
                  backgroundColor:
                    i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)',
                }}
                className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-data']}`}
              >
                <div
                  tabIndex={0}
                  className={styles['MCA-table-data-data-text']}
                >
                  {moment
                    .tz(e.requestTimestamp, 'America/New_York')
                    .format('MM/DD/YYYY')}
                  &nbsp;&nbsp;&nbsp;
                  {moment
                    .tz(e.requestTimestamp, 'America/New_York')
                    .format('h:mm:ss a')}
                </div>
                <div
                  tabIndex={0}
                  className={styles['MCA-table-data-data-link']}
                  onClick={() => {
                    setOpenBarcode(e.barcode)
                  }}
                  onKeyDown={(keyEvent) => {
                    if (
                      keyEvent.key === AccessibleKey.enter ||
                      keyEvent.key === AccessibleKey.space
                    ) {
                      keyEvent.preventDefault()
                      setOpenBarcode(e.barcode)
                    }
                  }}
                  aria-label={`View Barcode Details for ${e.barcode}`}
                >
                  {e.barcode === null ? '' : e.barcode}
                </div>
                <div
                  tabIndex={0}
                  className={styles['MCA-table-data-data-text']}
                >
                  {e.winStatus === null ? '' : e.winStatus}
                </div>
                <div
                  tabIndex={0}
                  className={styles['MCA-table-data-data-text']}
                >
                  {e.status === null ? '' : e.status}
                </div>
                <div
                  tabIndex={0}
                  className={`${styles['MCA-table-data-data-text']} ${styles['KYCQUEUE-table-data-column-3']}`}
                >
                  {e.prizeAmount === null
                    ? '- -'
                    : normilizeAmount(e.prizeAmount)}
                </div>
                <div
                  tabIndex={0}
                  className={`${styles['MCA-table-data-data-text']} ${styles['KYCQUEUE-table-data-column-4']}`}
                >
                  {claimEligibleDefaultToNo(e.claimOffered)}
                </div>
              </div>
            ))}
        </div>
        <div className={styles['MCA-table-data-footer']}>
          <div
            aria-live="assertive"
            tabIndex={0}
            className={styles['MCA-table-page-label']}
          >{`Page ${currentPage + 1} of ${
            MCAPlayerData.length > 0 && MCAPlayerData[0].gameId === id
              ? 0
              : Math.ceil(MCAPlayerData.length / 10).toString()
          }`}</div>
          {MCAPlayerData.length > 0 &&
            MCAPlayerData[0].gameId !== id &&
            createPages(MCAPlayerData, currentPage, setCurrentPage)}
        </div>
      </div>
    </>
  )
}
