import React, { useContext } from "react"
import { TaxAdjustmentStatus } from "../../../../api/check-run-payments-fetcher/api-types"
import { ModalOverlayContainer } from "../../../CommonComponents/modal-container/modal-container-component"
import { TaxAdjustmentFetchesContext } from "../../../../context/TaxAdjustmentFetchesContext"

export type UpdateTaxAdjustmentStatusUIProps = {
  setStatus: React.Dispatch<React.SetStateAction<TaxAdjustmentStatus | undefined>>
  status: TaxAdjustmentStatus | undefined
  taxAdjustmentId: string | undefined
  closeDialog: () => void
}

const ROOT_CLASS ='update-tax-adjustment'

export const UpdateTaxAdjustmentStatusUI = (props: UpdateTaxAdjustmentStatusUIProps) => {
  const taxAdjustmentFetches = useContext(TaxAdjustmentFetchesContext)

  return (
    <ModalOverlayContainer>
      <div className={ROOT_CLASS}>
        <header>Update Tax Adjustment Status</header>
        <div>
          REMINDER: Changing the status from On Hold to Pending
          will automatically release pending tax adjustments.
        </div>
        <section className="controls">
          <div className="control-wrapper">
            <input
              type="radio"
              name="newStatus"
              id="onHoldId"
              onChange={(e) => props.setStatus(e.target.value as TaxAdjustmentStatus)}
              value={TaxAdjustmentStatus.ON_HOLD}
              checked={props.status === TaxAdjustmentStatus.ON_HOLD}
            />
            <label htmlFor="onHoldId">On Hold</label>
          </div>
          <div className="control-wrapper">
            <input
              type="radio"
              name="newStatus"
              id="pendingId"
              onChange={(e) => props.setStatus(e.target.value as TaxAdjustmentStatus)}
              value={TaxAdjustmentStatus.PENDING}
              checked={props.status === TaxAdjustmentStatus.PENDING}
            />
            <label htmlFor="pendingId">Pending</label>
          </div>
        </section>
        <button className="primary-button" disabled={!props.status || !props.taxAdjustmentId} onClick={
          () => { 
            if(props.status && props.taxAdjustmentId) {
              taxAdjustmentFetches.setTaxAdjustmentStatus(props.taxAdjustmentId, props.status)
            }
          }
        }>
          Submit
        </button>
        <button className="cancel-button" onClick={() => props.closeDialog()}>
          Cancel
        </button>
        </div>
    </ModalOverlayContainer>
  )
}