import { Formik } from "formik"
import React from "react"
import { FormPayeeDetails, SplitPaymentRequest } from "../../../../pages/PaymentsPages/Create5754SplitPage/types"
import { Create5754SplitChildPaymentsFormUI } from "./create-5754-split-child-payments-form-ui"
import { convertFormPayeeDetailsToNewPaymentDetails } from "../../../../pages/PaymentsPages/Create5754SplitPage/util"
import { SubmitSplitConfirmationUI } from "../create-5754-split-dialogs/submit-split-confirmation/submit-split-confirmation-ui"

export type Create5754SplitChildPaymentsFormContainerProps = {
  childPayments: FormPayeeDetails[]
  parentPaymentId: string
  showSubmitSplitPopup: boolean
  originalGrossInDollars: string
  setSubmitButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>
  setShowSubmitSplitPopup: React.Dispatch<React.SetStateAction<boolean>>
  fetchSplitPayment: (request: SplitPaymentRequest) => Promise<void>
}

export const Create5754SplitChildPaymentsFormContainer = (props: Create5754SplitChildPaymentsFormContainerProps) => {
  // Props
  const { 
    childPayments, 
    parentPaymentId, 
    originalGrossInDollars, 
    showSubmitSplitPopup, 
    setSubmitButtonEnabled,
    setShowSubmitSplitPopup,
    fetchSplitPayment
  } = props

  // Callbacks 
  const onSubmit = (values: any, formikHelpers: any) => {}

  // Render
  return (
    <Formik
      initialValues={{ splits: childPayments }} 
      onSubmit={ onSubmit }
    >
      {props => {
        return (
          <>
            <Create5754SplitChildPaymentsFormUI
              formikProps={ props } 
              parentPaymentId={ parentPaymentId }
              originalGrossInDollars={ originalGrossInDollars }
              setSubmitButtonEnabled={ setSubmitButtonEnabled }
            />
            {showSubmitSplitPopup ? 
              <SubmitSplitConfirmationUI 
                setShowSubmitSplitPopup={ setShowSubmitSplitPopup }
                submitSplits={async () => {
                  fetchSplitPayment({
                    parentPaymentId,
                    newPaymentsDetails: convertFormPayeeDetailsToNewPaymentDetails(props.values.splits)
                  })
                }}         
              /> : null
            }
          </>
        )
      }}
    </Formik>
  )
}