import React from "react";
import styles from './css/styles.module.css';
import {downloadClicked, getPageData, normalizeAmount} from "./functions";
import {keyPressedType} from "../../../services/Commons";
import {playerSupportRoutePath} from '../../../config';

import {ExportToCsv} from 'export-to-csv';
import moment from "moment-timezone";
import {useHistory} from "react-router-dom";


const CsvOptions = {
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: `TicketEventHistoryExport`,
    showTitle: true,
    headers: ['Event Date', 'Event Type', 'Prize Amount', 'Reject Reason', 'Claim IP Address', 'Player ID']
};

let csvExporter: ExportToCsv = new ExportToCsv(CsvOptions);
const dateFormat = "YYYY-MM-DD HH:mm:ss a"

export const EventHistory = ({id, MCAPlayerData, currentPage, setCurrentPage}: any) => {
    let ticketInfoString = JSON.stringify(MCAPlayerData)
    let ticketInfoJson = JSON.parse(ticketInfoString);
    let ticketSearchResponse = ticketInfoJson.ticket
    MCAPlayerData = ticketSearchResponse.eventHistory;
    const history = useHistory();

    const normilizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;
    }
    // MCAPlayerData = array;
    const createPages = (MCAPlayerData: any[], currentPage: any, setCurrentPage: any) => {
        const array: any = [];
        const pagesTotal = Math.ceil(MCAPlayerData.length / 10);
        if (currentPage > 2 && pagesTotal > 5) {
            array.push(
                <>
                    <div aria-live="assertive"
                         tabIndex={0} role='button'
                         aria-pressed={currentPage === 0 ? "true" : "false"}
                         style={{backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : ""}}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(0);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(0)} className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {1}
                        </div>
                    </div>
                    <div>{'...'}</div>
                </>
            );
        }
        if (currentPage <= 2 || pagesTotal <= 5) {
            for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
                array.push(
                    <div aria-live="assertive" tabIndex={0} role='button'
                         aria-pressed={currentPage === i ? "true" : "false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(i);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }
        } else if (currentPage > 2 && currentPage <= (pagesTotal - 1) - 2) {
            for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
                array.push(
                    <div
                        aria-live="assertive"
                        tabIndex={0}
                        role='button'
                        aria-pressed={currentPage === i ? "true" : "false"}
                        onKeyPress={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                setCurrentPage(i);
                            }
                        }
                        }
                        onClick={() => setCurrentPage(i)}
                        style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                        className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }
        } else if (currentPage > (pagesTotal - 1) - 2) {
            for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
                array.push(
                    <div aria-live="assertive" tabIndex={0} role='button'
                         aria-pressed={currentPage === i ? "true" : "false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(i);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }

        }
        if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
            array.push(
                <>
                    <div>{'...'}</div>
                    <div aria-live="assertive" tabIndex={0} role='button'
                         aria-pressed={currentPage === pagesTotal ? "true" : "false"}
                         style={{backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : ""}}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 setCurrentPage(pagesTotal - 1);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {pagesTotal}
                        </div>
                    </div>
                </>
            );
        }
        return array;
    }

    return (
        <>
            <div className={styles['MCA-table']}>
                <div className={styles['MCA-table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${MCAPlayerData.length <= 0 ? 0 : (currentPage * 10) + 1}-${normilizePage(MCAPlayerData.length, currentPage)} of ${MCAPlayerData.length} results`}</div>
                    <div className={styles['MCA-table-export-button-container']}>
                        <button
                            style={{
                                opacity: (MCAPlayerData.length > 0) ? "1" : "0.4",
                                cursor: (MCAPlayerData.length > 0) ? "pointer" : "not-allowed"
                            }}
                            className={styles['MCA-table-export-button']}
                            onClick={() => (MCAPlayerData.length > 0) && downloadClicked(MCAPlayerData, csvExporter)}>Export
                        </button>
                    </div>
                </div>

                <div className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-label']} `}>
                    <div tabIndex={0}>Date</div>
                    <div tabIndex={0}>Event Type</div>
                    <div tabIndex={0}>Prize Amount</div>
                    <div tabIndex={0}>Reject Reason</div>
                    <div tabIndex={0}>Claim IP Address</div>
                    <div tabIndex={0}>Player ID</div>
                </div>
                <div className={styles['MCA-table-data-body']}>
                    {(MCAPlayerData.length > 0) && getPageData(MCAPlayerData, currentPage).map((e: any, i: number) =>
                        <div style={{backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)')}}
                             className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-data']}`}>
                            <div tabIndex={0}
                                 className={styles['MCA-table-data-data-text']}>{moment.tz(e.eventDate, "America/New_York").format('MM/DD/YYYY')}&nbsp;at&nbsp;{moment.tz(e.eventDate, "America/New_York").format('h:mm:ss a')}</div>
                            <div tabIndex={0}
                                 className={styles['MCA-table-data-data-text']}>{e.eventType === null ? "" : e.eventType}</div>
                            <div tabIndex={0}
                                 className={`${styles['MCA-table-data-data-text']}`}> {normalizeAmount(e.prizeAmountInCents)}</div>
                            <div tabIndex={0}
                                 className={styles['MCA-table-data-data-text']}>{e.rejectReason === null ? "" : e.rejectReason}</div>
                            <div tabIndex={0}
                                 className={styles['MCA-table-data-data-text']}>{e.clientIpAddress === null ? "" : e.clientIpAddress}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-email-text']}
                                 onKeyPress={(en: any) => {
                                     if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {
                                         history.push(`${playerSupportRoutePath}/player-hub-new/${e.playerId}`);
                                     }
                                 }
                                 }
                                 onClick={() => {
                                     history.push(`${playerSupportRoutePath}/player-hub-new/${e.playerId}`);
                                 }
                                 }
                            >{e.playerId}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles['MCA-table-data-footer']}>
                    <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((MCAPlayerData.length / 10)).toString()}`}</div>
                    {(MCAPlayerData.length > 0) && createPages(MCAPlayerData, currentPage, setCurrentPage)}
                </div>
            </div>
        </>
    );
}
