import * as React from 'react'
import { useFetchPlayerPromotions } from '../../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import {
  PlayerPromotionEntriesUI,
  PromotionDrawing,
} from './PromotionalEntryDetailsUI'

export type PlayerPromotionEntriesContainerProps = {
  playerId: string
  setOpenPromotionDrawing: (promotionDrawing: PromotionDrawing) => void
}

export const PlayerPromotionEntriesContainer = (
  props: PlayerPromotionEntriesContainerProps
) => {
  const { isLoading, error, data } = useFetchPlayerPromotions(props.playerId)

  return (
    <PlayerPromotionEntriesUI
      setOpenPromotionDrawing={props.setOpenPromotionDrawing}
      promotions={data}
      isLoading={isLoading}
      error={error}
    />
  )
}
