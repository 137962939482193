import React from 'react'
import './styles.scss'
import { isNull } from 'lodash'
import { ReissueData, VoidData } from '../../../../api/payment-details-fetcher/api-types'
import { VoidOrReissueDetails } from './void-or-reissue-details/void-or-reissue-details'

type PaymentDetailsVoidReissueDetailsProps = {
  voidDetails: VoidData | null
  reissueDetails: ReissueData | null
}

const REISSUE_DETAILS_COLUMNS = ['Date of Reissue', 'Reissued Payment']
const VOID_DETAILS_COLUMNS = ['Date of Void', 'Voided Payment']

const optionallyRenderVoidDetails = (voidDetails: VoidData | null) => {
  if (!isNull(voidDetails)) {
    return (
      <VoidOrReissueDetails 
        voidOrReissueDate={voidDetails.voidDate}
        voidOrReissuePaymentId={voidDetails.voidedPaymentId}
        columns={VOID_DETAILS_COLUMNS}      
        title={'Void Details'}
        tooltipMessage={'This is the payment that was voided and reissued as the current payment'}
      />
    )
  }
  return null
}

const optionallyRenderReissueDetails = (reissueDetails: ReissueData | null) => {
  if (!isNull(reissueDetails)) {
    return (
      <VoidOrReissueDetails 
        voidOrReissueDate={reissueDetails.reissueDate}
        voidOrReissuePaymentId={reissueDetails.reissuedPaymentId}
        columns={REISSUE_DETAILS_COLUMNS}      
        title={'Reissue Details'}
        tooltipMessage={'This is the payment that was created as a result of voiding and reissuing the current payment'}
      />
    )
  }
  return null
}

const ROOT_CLASS = `payment-details-void-reissue-details`
export const PaymentDetailsVoidReissueDetails = (props: PaymentDetailsVoidReissueDetailsProps) => {
  const { voidDetails, reissueDetails } = props
  return (
    <div className={ ROOT_CLASS }>
      { optionallyRenderVoidDetails(voidDetails) }
      { optionallyRenderReissueDetails(reissueDetails) }
    </div>
  )
}