import { makeHttpCall } from './../../../services/MakeHttpCall';
import config from  './../../../config.js'; 



const array:any= [];
let requestPerformed:any = 0;


export const READY_FOR_PAYMENT: any = {

    
    CREATED: "Validated - Payment Initialized",
    SUBMITTED: "Validated - Payment Pending",
    SETTLED: "Validated - Payment Complete",
    REJECTED: "Validated - Payment Rejected",

}

export const ClaimStatus: any = {


    ERROR_HOST_REJECTED:"Validation Failed - Ineligible Ticket",
    ATTEMPTING_TO_VALIDATE: "In Progress - Claim Attempting to validate", 
    ERROR_ALREADY_VALIDATED: "Validation Failed - Already Paid",
    ERROR_DOWNTIME: "Validation Failed - Downtime",
    ERROR_FREQUENT_CASHER: "Blocked - Frequent Casher",
    ERROR_INVALID_RESPONSE: "Validation Failed - Ineligible Ticket",
    ERROR_OFFSET: "Blocked - Player Offsets",
    ERROR_PLAYER_SUSPENDED: "Blocked - Player Suspended",
    ERROR_RTC_TICKET_HOLD:"Blocked- Ticket on Hold",
    ERROR_UNABLE_TO_VALIDATE: "Validation Failed - API Error",
    INITIATED:"In progress - Claim Started",
    PLAYER_ERROR: "Blocked - Player ID",
    READY_FOR_PAYMENT:READY_FOR_PAYMENT,
    RECEIVED: "In progress - Claim Received",
    VALIDATED:"Validated - Transmitting Info",       

}
export const getTransactionStatusType = (value: string) => {
    
    const type: any = Object.keys(READY_FOR_PAYMENT).find((key: any) => key === value)
    return type === undefined ? "" : READY_FOR_PAYMENT[value];

}


export const getHistoryStatusType = (value: string) => {
   
    const type: any = Object.keys(ClaimStatus).find((key: any) => key === value)   
     
    return type === undefined ? "" : ClaimStatus[type];
}

const arrayOfTimers:any = [];
export const getPageData = (storesObject:any ,isDataPerformedMobile:any, setPlayerMobileClaimsActivityData:any,KYCPlayerData:any[], page:number = 0) =>{
    const array2:any=[];    
    
    arrayOfTimers.map((e:any)=>{
        clearTimeout(e);
      

    });
    
    if( array.length >= KYCPlayerData.slice(0,5).length)
        array.splice(0, array.length);
      
    
    const body = {
        firstName: storesObject.playerStore.selectedPlayerDetails.identity.firstName,
        lastName: storesObject.playerStore.selectedPlayerDetails.identity.lastName,
    }
   
   
        
    for(let i = page * 5; i < KYCPlayerData.slice(0,5).length; i++)
    {      
        
        if(!(KYCPlayerData[i] === undefined))
        {
           
           
            if(array.length < KYCPlayerData.slice(0,5).length )
            {
               array.push( JSON.parse(JSON.stringify(KYCPlayerData[i])))
               
            }              
           
            array2.push(KYCPlayerData[i]);            
                        
            if (array2[i].claim.status === "READY_FOR_PAYMENT") {
               
                const ms = ((i + 1 * 100) % 10 === 0 ? 100 : (i + 1 * 100));
               const a = setTimeout(async () => {
                    try {
                        const options =
                        {
                            method: 'POST',
                            url: config.SERVER_BASE_URL + '/v1/claim/' + KYCPlayerData[i].claim.referenceNumber + '/transaction',
                            body
                        }
                        const response = await makeHttpCall(options);
                     
                        KYCPlayerData[i].claim.status = getTransactionStatusType(response.message.transactions[response.message.transactions.length - 1].status);
                        array2[i].claim.status = KYCPlayerData[i].claim.status; 
                      
                        array2.map((e:any,i:number)=>{

                           if( ( array2[i].claim.status !== array[i].claim.status ) || ( array2[i].claim.activeFrom !== array[i].claim.activeFrom ) ||  ( array2[i].claim.referenceNumber !== array[i].claim.referenceNumber ))
                                  array[i] = array2[i] ;
                            
                         

                        })
                        //setPlayerMobileClaimsActivityData(array2.slice());
                        
                    }
                    catch (e) {
                        // setPageMessage({ message: "Something went wrong. Please try again.", messageType: "ERROR" });

                    }

                }, ms);
                arrayOfTimers.push(a);
            }else {             
                            
                let value:any = getHistoryStatusType(array2[i].claim.status);
                if( value !== "" )
                {
                    array2[i].claim.status = value;
                    array[i].claim.status = value;
                }                
                                      
            }
        }

        
    }
    return array.slice();
}



export const buildClaimsArrayByDate = (claimsHistory:any, claimsTransactions:any) =>{
    const claimsArray:any[] =[];
    let claimsHistoryIndex:number = 0;

    
    for(let i = 0 ; i < claimsTransactions.length; i++ )
    {
         if(claimsHistory[claimsHistoryIndex] === undefined)
            claimsArray.push({type:"T",data:claimsTransactions[i]});
        else if( claimsHistory[claimsHistoryIndex].activeFrom > claimsTransactions[i].details.createdAt )
        {
            claimsArray.push({type:"H",data:claimsHistory[claimsHistoryIndex]});
            claimsHistoryIndex++;
        }else{
            claimsArray.push({type:"T",data:claimsTransactions[i]});
        }
    }
    for(let y = claimsHistoryIndex ; y < claimsHistory.length; y++ )
    {
         claimsArray.push({type:"H",data:claimsHistory[y]});
    }
    return claimsArray
}


export const setDateFormat = (value:any) => {
    
    let date:any = value.split('T')[0];
    date = date.split('-');
    let year:any = date[0];
    let time:any = value.split('T')[1];
    time = time.split(".");
    time = time[0].split(":");
    let hr:any =parseInt(time[0]); 
    let period = hr >= 12 ? "pm" : "am";
    if(hr > 12)
    {
        hr = hr - 12;
    }

    return `${date[1]}/${date[2]}/${year}&${hr}:${time[1]}:${time[2]} ${period}`;

}