import * as React from 'react'
import { Agent1099PrintDetailsPageUI } from './agent-1099-print-details-page-ui'
import {
  useFetchAgent1099PrintDetails,
  useFetchAgent1099PrintHistory,
} from '../../../api/agent-1099-print-queue-history-fetcher'
import moment from 'moment'

type Agent1099PrintDetailsPageContainerProps = {
  id: string
}

export const Agent1099PrintDetailsPageContainer = (
  props: Agent1099PrintDetailsPageContainerProps
) => {
  const agent1099PrintHistoryFetcher = useFetchAgent1099PrintHistory()
  const agent1099PrintDetailsFetcher = useFetchAgent1099PrintDetails()

  React.useEffect(() => {
    agent1099PrintHistoryFetcher.fetchAgent1099PrintHistory()
    agent1099PrintDetailsFetcher.fetchAgent1099PrintDetails(props.id)
  }, [])

  const sweep = agent1099PrintHistoryFetcher.data?.prints.find(
    (val) => val.sweepId === props.id
  )

  const sweepDate = sweep ? new Date(sweep.initiatedDate) : undefined
  const formattedSweepDate = sweep
    ? moment(sweep.initiatedDate).format('MM/DD/YYYY')
    : ''

  let error =
    agent1099PrintDetailsFetcher.error ?? agent1099PrintHistoryFetcher.error

  return (
    <Agent1099PrintDetailsPageUI
      isLoading={false}
      dateGenerated={formattedSweepDate}
      error={error}
      payments={agent1099PrintDetailsFetcher.data?.payments}
    />
  )
}
