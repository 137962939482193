import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../CommonComponents/ErrorMessageFieldCommonComponent'
import './styles.scss'

export type CustomRadioButtonOptions = {
  id: string
  value: string | number
  display: string
  defaultChecked: boolean
}

export type RadioButtonInputProps = {
  label: string | JSX.Element
  name: string
  options: CustomRadioButtonOptions[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: any) => void
  value: string | number
  error: string | undefined
  touched: boolean | undefined
  disabled: boolean
  optional?: boolean
  greyOnDisabled?: boolean
}

const ROOT_CLASS = 'radio-button-input'
const CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'content')
const LABEL_CLASS = getBlockClass(CONTENT_CLASS, 'label')

export const RadioButtonInput = (props: RadioButtonInputProps) => {
  return (
    <div className={ROOT_CLASS}>
      <label className={getBlockClass(ROOT_CLASS, 'label')}>
        {props.label}
        {props.optional && (
          <span className={getBlockClass(ROOT_CLASS, 'optional-label')}>
            {' '}
            Optional
          </span>
        )}
      </label>
      <div className={CONTENT_CLASS}>
        {props.options.map((option) => {
          return (
            <div
              key={option.id}
              className={getBlockClass(CONTENT_CLASS, 'option')}
            >
              <input
                className={getBlockClass(CONTENT_CLASS, 'input')}
                type="radio"
                id={option.id}
                name={props.name}
                value={option.value}
                onChange={props.onChange}
                defaultChecked={option.defaultChecked}
                disabled={props.disabled}
                required={!props.optional}
              />
              <label
                className={joinClasses([
                  LABEL_CLASS,
                  getModifierClass(
                    LABEL_CLASS,
                    'grey',
                    props.disabled && Boolean(props.greyOnDisabled)
                  ),
                ])}
                htmlFor={option.id}
              >
                {option.display}
              </label>
            </div>
          )
        })}
      </div>

      {props.error && props.touched && (
        <ErrorMessageFieldCommonComponent errorMessage={props.error} />
      )}
    </div>
  )
}
