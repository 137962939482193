import React from "react";
import {Switch, withRouter} from "react-router-dom";
import {PrivateRoute} from "./../../../routes.js";
import {
    CGReportingRoutePath,
    chargeRoutePath,
    charitableRoutePath,
    licenseeRoutePath,
    permitRoutePath,
    pulltabRoutePath,
    taxformRoutePath
} from "./../../../config.js";
import {CommonLandingPage} from "../../CommonsPage/CommonLandingPage";
import {checkRenderPermissions} from "../../../helpers.js";
import {permissions} from "../../../constants";
import {ButtonInfo, PageInfo,} from "../../CommonsPage/CommonLandingPage/interfaces";
import {Forbidden409Page} from "../../CommonsPage/Forbidden409Page";
import {LicenseProfileManagementPage} from "../License/LicenseProfileManagementPage";
import {CreateProfilePage} from "../License/CreateProfilePage";
import {LicenseeDetailsPage} from "../License/LicenseeDetailsPage";
import {UpdateOrganizationDetailsPage} from "../License/UpdateOrganizationDetailsPage";
import {UpdateBeanoGameInformationPage} from "../License/UpdateBeanoGameInformationPage";
import {UpdateLicenseDetailsPage} from "../License/UpdateLicenseDetailsPage";
import {UpdateMemberInChargeDetailsPage} from "../License/UpdateMemberInChargeDetailsPage";
import {UpdateBookkeeperDetailsPage} from "../License/UpdateBookkeeperDetailsPage";
import {UpdateEFTDetailsPage} from "../License/UpdateEFTDetailsPage";
import {PermitProfileManagementPage} from "../Permit/PermitProfileManagementPage";
import {PermitProfilePage} from "../Permit/PermitProfilePage";
import {UpdatePermitOrganizationDetailsPage} from "../Permit/UpdatePermitOrganizationDetailsPage";
import {UpdatePermitInformationPage} from "../Permit/UpdatePermitInformationPage";
import {PermitProfileCreationPage} from "../Permit/PermitProfileCreationPage";
import {UpdateHeadOfOrganizationDetailsPage} from "../License/UpdateHeadOfOrganizationDetailsPage";
import {PullTabManagementPage} from "../PullTab/PullTabManagementPage";
import {PullTabGamesPage} from "../PullTab/Games/PullTabGamesPage";
import {CreatePullTabGamePage} from "../PullTab/Games/CreatePullTabGamePage";
import {UpdatePullTabGamePage} from "../PullTab/Games/UpdatePullTabGamePage";
import {PullTabInventoryPage} from "../PullTab/Inventory/PullTabInventoryPage";
import {AddNewInventoryPage} from "../PullTab/Inventory/AddNewInventoryPage";
import {TransferInventoryPage} from "../PullTab/Inventory/TransferInventoryPage";
import {PullTabStockInventoryPage} from "../PullTab/Inventory/PullTabStockInventoryPage";
import {PullTabOrdersPage} from "../PullTab/Orders/PullTabOrdersPage";
import {PullTabOrderCreationStepOnePage} from "../PullTab/Orders/PullTabOrderCreationStepOnePage";
import {PullTabOrderItemHistoryPage} from "../PullTab/Orders/PullTabOrderItemHistoryPage";
import {PullTabOrderOverviewHistoryPage} from "../PullTab/Orders/PullTabOrderOverviewHistoryPage";
import {PullTabOrderCreationStepTwoPage} from "../PullTab/Orders/PullTabOrderCreationStepTwoPage";
import {PullTabOrderReturnGlobalLevelPage} from "../PullTab/Orders/PullTabOrderReturnGlobalLevelPage";
import {PullTabOrderDetailsPage} from "../PullTab/Orders/PullTabOrderDetailsPage";
import {PullTabOrderReturnOrderLevelPage} from "../PullTab/Orders/PullTabOrderReturnOrderLevelPage";
import {ChargeManagementPage} from "../Charge/ChargeManagementPage";
import {LicenseeChargesPage} from "../Charge/LicenseeChargesPage";
import {EFTLedgerPage} from "../Charge/EFTLedgerPage";
import {PermitHolderChargesPage} from "../Charge/PermitHolderChargesPage";
import {LicenseeChargeDetailsPage} from "../Charge/LicenseeChargeDetailsPage";
import {PermitHolderChargeDetailsPage} from "../Charge/PermitHolderChargeDetailsPage";
import {TaxFormManagementPage} from "../TaxForm/TaxFormManagementPage";
import {WeeklyBeanoTaxFormPage} from "../TaxForm/WeeklyBeanoTaxFormPage";
import {AnnualBeanoTaxFormPage} from "../TaxForm/AnnualBeanoTaxFormPage";
import {CasinoTaxFormPage} from "../TaxForm/CasinoTaxFormPage";
import {RaffleTaxFormPage} from "../TaxForm/RaffleTaxFormPage";
import {ReportingHomePage} from "../Reporting/ReportingHomePage";
import {WeeklyBeanoReportingPage} from "../Reporting/WeeklyBeanoReportingPage";
import {AnnualBeanoReportingPage} from "../Reporting/AnnualBeanoReportingPage";
import {RaffleCasinoBeanoReportingPage} from "../Reporting/CasinoRaffleBeanoReportingPage";

const CharitableGamingRoutesPage = ({props, storesObject}: any) => {
    const userpolicies: any = localStorage.getItem("userpolicies");
    const landingObject: ButtonInfo[] = [
        {
            buttonTitle: "License Management",
            buttonPermission: `${permissions.CAN_MANAGE_LICENSE_PROFILES}`,
            buttonRoute: `${licenseeRoutePath}/manage`,
        },
        {
            buttonTitle: "Permit Management",
            buttonPermission: `${permissions.CAN_MANAGE_PERMITS}`,
            buttonRoute: `${permitRoutePath}/manage`,
        },
        {
            buttonTitle: "Pull Tab Management",
            buttonPermission: `${permissions.CAN_MANAGE_PULL_TABS}`,
            buttonRoute: `${pulltabRoutePath}/manage`,
        },
        {
            buttonTitle: "Tax Form Management",
            buttonPermission: `${permissions.CAN_MANAGE_TAX_FORMS}`,
            buttonRoute: `${taxformRoutePath}/manage`,
        },
        {
            buttonTitle: "Charge Management",
            buttonPermission: `${permissions.CAN_SEE_CHARGE_MANAGEMENT}`,
            buttonRoute: `${chargeRoutePath}/manage`,
        },
        {
            buttonTitle: "Reporting",
            buttonPermission: `${permissions.CAN_SEE_CHARITABLE_GAMING_REPORTS}`,
            buttonRoute: `${CGReportingRoutePath}`,
        }
    ];

    const landingPageInfo: PageInfo = {
        title: "Charitable Gaming",
        policy: permissions.CAN_SEE_CHARITABLE_GAMING,
        buttonData: landingObject,
        backButtonText: ''
    };
    return (
        <>
            <Switch>

                <PrivateRoute
                    path={`${licenseeRoutePath}/manage`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_MANAGE_LICENSE_PROFILES, JSON.parse(userpolicies)) ?
                            <LicenseProfileManagementPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/create`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_CREATE_LICENSE_PROFILES, JSON.parse(userpolicies)) ?
                            <CreateProfilePage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/view`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_VIEW_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <LicenseeDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/org`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateOrganizationDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/beano-game`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateBeanoGameInformationPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/license`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateLicenseDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/head-of-org`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateHeadOfOrganizationDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/mic`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateMemberInChargeDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/bk`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateBookkeeperDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${licenseeRoutePath}/update/eft-account`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_EFT_DETAILS, JSON.parse(userpolicies)) ?
                            <UpdateEFTDetailsPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${permitRoutePath}/manage`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_MANAGE_PERMITS, JSON.parse(userpolicies)) ?
                            <PermitProfileManagementPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${permitRoutePath}/create`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_CREATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                            <PermitProfileCreationPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${permitRoutePath}/view`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_VIEW_PERMIT_DETAILS, JSON.parse(userpolicies)) ?
                            <PermitProfilePage
                                raffleId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${permitRoutePath}/update/org`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                            <UpdatePermitOrganizationDetailsPage
                                raffleId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${permitRoutePath}/update/permit`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                            <UpdatePermitInformationPage
                                raffleId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                            : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/manage`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_MANAGE_PULL_TABS, JSON.parse(userpolicies)) ?
                            <PullTabManagementPage/> : <Forbidden409Page/>}
                />

                {/*>>>>>>>>>>>>>>>> Pull-Tab Games >>>>>>>>>>>>>>*/}

                <PrivateRoute
                    path={`${pulltabRoutePath}/games`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_GAMES, JSON.parse(userpolicies)) ?
                            <PullTabGamesPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/create`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_CREATE_PULL_TAB_GAMES, JSON.parse(userpolicies)) ?
                            <CreatePullTabGamePage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/update`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_UPDATE_PULL_TAB_GAMES, JSON.parse(userpolicies)) ?
                            <UpdatePullTabGamePage
                                stockNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                {/*<<<<<<<<<<<<<< Pull-Tab Games <<<<<<<<<<<<<<<*/}

                {/*>>>>>>>>>>>>>>>> Pull-Tab Inventory >>>>>>>>>>>>>>*/}

                <PrivateRoute
                    path={`${pulltabRoutePath}/inventory/add`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_ADD_NEW_PULL_TAB_INVENTORY, JSON.parse(userpolicies)) ?
                            <AddNewInventoryPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/inventory/transfer`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_TRANSFER_PULL_TAB_INVENTORY, JSON.parse(userpolicies)) ?
                            <TransferInventoryPage
                                stockNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/inventory/stock`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_STOCK_INVENTORY, JSON.parse(userpolicies)) ?
                            <PullTabStockInventoryPage
                                stockNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/inventory`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_INVENTORY, JSON.parse(userpolicies)) ?
                            <PullTabInventoryPage/> : <Forbidden409Page/>}
                />


                {/*<<<<<<<<<<<<<< Pull-Tab Inventory <<<<<<<<<<<<<<<*/}

                {/*>>>>>>>>>>>>>>>> Pull-Tab Orders >>>>>>>>>>>>>>*/}

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/create/step-1`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_CREATE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrderCreationStepOnePage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/create/step-2`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_CREATE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrderCreationStepTwoPage
                                licenseNumber={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/return-global`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_RETURN_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrderReturnGlobalLevelPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/return-order`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_RETURN_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrderReturnOrderLevelPage
                                purchaseOrderId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/items-history`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrderItemHistoryPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/overview-history`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrderOverviewHistoryPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders/details`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDER_DETAILS, JSON.parse(userpolicies)) ?
                            <PullTabOrderDetailsPage
                                purchaseOrderId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${pulltabRoutePath}/orders`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <PullTabOrdersPage/> : <Forbidden409Page/>}
                />

                {/*<<<<<<<<<<<<<< Pull-Tab Orders <<<<<<<<<<<<<<<*/}

                {/*>>>>>>>>>>>>>>>> Tax-Form Management >>>>>>>>>>>>>>*/}

                <PrivateRoute
                    path={`${taxformRoutePath}/manage`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_MANAGE_TAX_FORMS, JSON.parse(userpolicies)) ?
                            <TaxFormManagementPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/edit/weeklybeano`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_WEEKLY_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <WeeklyBeanoTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/edit/annualbeano`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <AnnualBeanoTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/edit/casino`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <CasinoTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/edit/raffle`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <RaffleTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/view/weeklybeano`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_WEEKLY_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <WeeklyBeanoTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/view/annualbeano`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <AnnualBeanoTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/view/casino`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <CasinoTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${taxformRoutePath}/view/raffle`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                            <RaffleTaxFormPage
                                taxFormId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />


                {/*<<<<<<<<<<<<<< Tax-Form Management <<<<<<<<<<<<<<<*/}

                {/*>>>>>>>>>>>>>>>> Charge Management >>>>>>>>>>>>>>*/}

                <PrivateRoute
                    path={`${chargeRoutePath}/manage`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_CHARGE_MANAGEMENT, JSON.parse(userpolicies)) ?
                            <ChargeManagementPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${chargeRoutePath}/licensee-charges/details`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_LICENSEE_CHARGE_DETAILS, JSON.parse(userpolicies)) ?
                            <LicenseeChargeDetailsPage
                                chargeId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${chargeRoutePath}/licensee-charges`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_LICENSEE_CHARGES, JSON.parse(userpolicies)) ?
                            <LicenseeChargesPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${chargeRoutePath}/eft-ledger/details`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_EFT_CHARGE_DETAILS, JSON.parse(userpolicies)) ?
                            <LicenseeChargeDetailsPage
                                chargeId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${chargeRoutePath}/eft-ledger`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_EFT_LEDGER, JSON.parse(userpolicies)) ?
                            <EFTLedgerPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${chargeRoutePath}/permit-holder-charges/details`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PERMIT_HOLDER_CHARGE_DETAILS, JSON.parse(userpolicies)) ?
                            <PermitHolderChargeDetailsPage
                                chargeId={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/> :
                            <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${chargeRoutePath}/permit-holder-charges`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_PERMIT_HOLDER_CHARGES, JSON.parse(userpolicies)) ?
                            <PermitHolderChargesPage/> : <Forbidden409Page/>}
                />

                {/*<<<<<<<<<<<<<< Charge Management <<<<<<<<<<<<<<<*/}

                {/*>>>>>>>>>>>>>>>> Reporting >>>>>>>>>>>>>>*/}

                <PrivateRoute
                    path={`${CGReportingRoutePath}/weekly-beano`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_WEEKLY_BEANO_REPORTS, JSON.parse(userpolicies)) ?
                            <WeeklyBeanoReportingPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${CGReportingRoutePath}/annual-beano`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_REPORTS, JSON.parse(userpolicies)) ?
                            <AnnualBeanoReportingPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${CGReportingRoutePath}/raffle-casino`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_RAFFLE_CASINO_REPORTS, JSON.parse(userpolicies)) ?
                            <RaffleCasinoBeanoReportingPage/> : <Forbidden409Page/>}
                />

                <PrivateRoute
                    path={`${CGReportingRoutePath}`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_CHARITABLE_GAMING_REPORTS, JSON.parse(userpolicies)) ?
                            <ReportingHomePage/> : <Forbidden409Page/>}
                />

                {/*<<<<<<<<<<<<<< Reporting <<<<<<<<<<<<<<<*/}

                <PrivateRoute
                    path={`${charitableRoutePath}/`}
                    component={(props: any) =>
                        checkRenderPermissions(permissions.CAN_SEE_CHARITABLE_GAMING, JSON.parse(userpolicies)) ?
                            <CommonLandingPage {...landingPageInfo} /> : <Forbidden409Page/>
                    }
                />

            </Switch>
        </>
    );
};
export default withRouter(CharitableGamingRoutesPage);
