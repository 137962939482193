import React from "react";
import CustomCard from '../../../dashboard/customCard';
import './Playersupport.css'
import {checkRenderPermissions} from '../../../helpers';
import {permissions} from '../../../constants';
import {UserAction, UserActionCategory} from "../../../UserActionCategory";
import {inject} from "mobx-react";
import {actionsRoutePath } from './../../../config';
import { Forbidden409Page } from './../../CommonsPage/Forbidden409Page'

let hasPermission = false;

@inject('playerStore', 'authStore')
class PlayersupportnewPage extends React.Component {

      
    constructor(props){
        super(props)
        hasPermission = false;
    }
    componentDidMount() {
        localStorage.removeItem('searchFirstName');
        localStorage.removeItem('searchLastName');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
    }

    navigateTo = (path) => {
       
        if (path === '/player-support-new/search-new')
            {this.props.playerStore.logAction(UserActionCategory.PAGE_VIEW, UserAction.PLAYER_MAINTENANCE);}
        else if (path === '/playerreports')
            {this.props.playerStore.logAction(UserActionCategory.PAGE_VIEW, UserAction.PLAYER_SERVICE_REPORTS);}
        
        hasPermission = false;
        this.props.history.push(path);
    }

    renderPlayerSupport() {
        console.log('PlayersupportnewPage', checkRenderPermissions(permissions.CAN_SEE_PLAYER_SUPPORT_NEW, JSON.parse(localStorage.getItem('userpolicies'))));
        return (
            <div className='app-center-body'>
                 {checkRenderPermissions(permissions.CAN_SEE_ACTIONS, JSON.parse(localStorage.getItem('userpolicies'))) && 
                 <>
                 {(hasPermission = true)}       
                <CustomCard
                        title='Actions'
                        handleCallback={() => this.props.history.push(`${actionsRoutePath}/kyc-queue`)}
                    />
                </> }
                { checkRenderPermissions(permissions.CAN_SEE_PLAYER_SUPPORT_NEW, JSON.parse(localStorage.getItem('userpolicies'))) && <>
                    { hasPermission = true }
                    <CustomCard
                        title='Player Support New'
                        handleCallback={() => this.navigateTo('/new-layout/player-support-new/search-new')}

                    /> 
                    </>}
                {checkRenderPermissions(permissions.SEE_PLAYER_SERVICE_REPORTS, JSON.parse(localStorage.getItem('userpolicies'))) &&
                    <>
                    {hasPermission = true}
                    <CustomCard
                        title='Player Services Reports'
                        handleCallback={() => this.navigateTo('/playerreports')}
                    />
                    </>}
                    
                { !hasPermission &&  <Forbidden409Page /> }    
            </div>
        );
    }

    render() {
        var renderObj;

        renderObj = this.renderPlayerSupport();
       
        return (
            renderObj
        );
    }
}

export default PlayersupportnewPage;