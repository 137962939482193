import * as React from 'react'
import { OffsetsSystemBreakdownTableRowUI } from './offsets-system-breakdown-table-row-ui'
import { OffsetsSystemAggregates } from '../../../pages/FinancePages/Offsets/OffsetsByACHPage/interfaces'
import './styles.scss'

const ROOT_CLASS = 'offsets-system-breakdown'

export type OffsetsSystemBreakdownUIProps = {
  aggregates: OffsetsSystemAggregates
  dateSubmitted: string // YYYY-MM-DD
}

export const OffsetsSystemBreakdownUI = (props: OffsetsSystemBreakdownUIProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={`offsets-system-breakdown-title-container`}>
        <div className={`offsets-system-breakdown-title`}>System Breakdown</div>
      </div>
      <div className={`offsets-system-breakdown-results-count-container`}>
        <div className={`offsets-system-breakdown-results-count-text`}>Displaying 3-3 of 3 results</div>
      </div>
      
      <div className={`offsets-system-breakdown-table`}>
        <div className={`offsets-system-breakdown-table-header offsets-system-breakdown-table-grid`}>
          <div>System</div>
          <div>Date Submitted</div>
          <div>Net Amount</div>
          <div>DOR Child Services</div>
          <div>DOR Tax</div>
          <div>Comptroller</div>
        </div>
        <OffsetsSystemBreakdownTableRowUI
          systemName='Check Writer'
          dateSubmitted={props.dateSubmitted}
          {...props.aggregates.checkWriterAggregates} />
        <OffsetsSystemBreakdownTableRowUI
          systemName='Claim Center'
          dateSubmitted={props.dateSubmitted}
          {...props.aggregates.claimCenterAggregates} />
        <OffsetsSystemBreakdownTableRowUI
          systemName='RTC'
          dateSubmitted={props.dateSubmitted}
          {...props.aggregates.rtcAggregates} />
      </div>
    </div>
  )
}