export const ExpenseTypes = [
    'All',
    'Raffle Taxes',
    'Casino Night Taxes'
];

export const ChargeStatuses = [
    'All',
    'Paid',
    'Awaiting Check'
];