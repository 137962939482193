import { useFetch } from "../../../hooks/use-fetch"
import config from "../../../config"
import { VoidReissueRequest } from "../../../api/void-reissue-payment-fetcher/api-types"
import { permissions } from '../../../constants'

const VOID_REISSUE_URL = `${config.SERVER_BASE_URL}/api/v1/payments/voidReissue`
export const useFetchVoidReissuePayment = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch()

  const fetchVoidReissuePayment = async (request: VoidReissueRequest) => {
    fetchUrl(VOID_REISSUE_URL, 'POST', {}, JSON.stringify(request), [permissions.CAN_VOID_AND_REISSUE_PAYMENT])
  }

  return {
    isLoading,
    error,
    requestSuccessful,
    fetchVoidReissuePayment
  }
}