import React from "react";
import styles from './css/styles.module.css';
import { BannerContext } from "../../../context/BannerContext";

export const pageMessageDefault:any ={
    messageType:"",
    message:""
  }

export const clearPageMessageDefault = ()=>{
    pageMessageDefault.messageType = "";
    pageMessageDefault.message = "";
}

export const PageMessageCommonContextComponent = () => {
    const bannerContext = React.useContext(BannerContext);

    return (
        <>
            {bannerContext.bannerInfo.message.length > 0 ? 
            <div onClick={ () => bannerContext.clearBannerInfo()} 
                                style={{backgroundColor: bannerContext.bannerInfo.error ? "#db4a4a" :"#059f9f"}}
                                tabIndex={0} 
                                className={styles['error-message-container']}>
                <div role="alert" aria-live={"assertive"} className={styles['error-message-text']}>
                    {bannerContext.bannerInfo.message}
                </div>
            </div> : null}
        </>
    );
}

export const PageMessagePopUpCommonContextComponent = () => {
    const bannerContext = React.useContext(BannerContext);
    
    return (
        <>
            <div style={{backgroundColor: bannerContext.bannerInfo.error ? "#db4a4a" :"#059f9f",justifyContent:"center"}}tabIndex={0} className={styles['error-message-container']} >                
                <div role="alert" aria-live={"assertive"} className={styles['error-message-text']} >
                    {bannerContext.bannerInfo.message}
                </div>
            </div>
        </>
    );
}