import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import moment from "moment";

export const UpdateLicenseDetailsComponent = ({formik, setFieldValue}: any) => {

    return (
        <>
            <form className={styles['update-license-details-component-container']}>
                <div tabIndex={0} className={styles['update-license-details-component-title']}>License</div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-license-details-component-label"]}>Investigator ID*
                    </div>
                    <input
                        id="licenseDetails.investCode"
                        name="licenseDetails.investCode"
                        className={styles["update-license-details-component-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.licenseDetails.investCode}
                        onBlur={formik.handleBlur}
                        placeholder={'Ex. 00'}
                    />
                    {(formik.touched.licenseDetails?.investCode && formik.errors.licenseDetails?.investCode) ?
                        <ErrorMessageFieldCommonComponent
                            investCode={formik.errors.licenseDetails?.investCode}/> : null}
                </div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-license-details-component-label"]}>End Date*</div>
                    <input
                        autoComplete={"off"}
                        id="licenseDetails.expirationDate"
                        name="licenseDetails.expirationDate"
                        onChange={(e: any) => setFieldValue(e)}
                        value={moment(formik.values.licenseDetails.expirationDate).utc().format('YYYY-MM-DD')}
                        className={styles["update-license-details-component-input-box"]}
                        onBlur={formik.handleBlur}
                        type="date"
                        disabled={true}
                    />
                </div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-license-details-component-label"]}>County*
                    </div>
                    <input
                        id="licenseDetails.county"
                        name="licenseDetails.county"
                        className={styles["update-license-details-component-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.licenseDetails.county}
                        onBlur={formik.handleBlur}
                        placeholder={'Ex. 00'}
                    />
                    {(formik.touched.licenseDetails?.county && formik.errors.licenseDetails?.county) ?
                        <ErrorMessageFieldCommonComponent
                            county={formik.errors.licenseDetails?.county}/> : null}
                </div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-license-details-component-label"]}>Date of First License*</div>
                    <input
                        id="licenseDetails.dateOfFirstLicense"
                        name="licenseDetails.dateOfFirstLicense"
                        onChange={(e: any) => setFieldValue(e)}
                        value={moment(formik.values.licenseDetails.dateOfFirstLicense).utc().format('YYYY-MM-DD')}
                        className={styles["update-license-details-component-input-box"]}
                        onBlur={formik.handleBlur}
                        type="date"
                        disabled={true}
                    />
                </div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-license-details-component-label"]}>City/Town*</div>
                    <input
                        id="licenseDetails.city"
                        name="licenseDetails.city"
                        value={formik.values.licenseDetails.city}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["update-license-details-component-input-box"]}`}
                        type="text"
                        placeholder={'Ex. 000'}
                    />
                    {(formik.touched.licenseDetails?.city && formik.errors.licenseDetails?.city) ?
                        <ErrorMessageFieldCommonComponent
                            city3={formik.errors.licenseDetails?.city}/> : null}
                </div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>

                </div>
                <div className={styles["update-license-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-license-details-component-label"]}>Type ID*
                    </div>
                    <input
                        id="licenseDetails.type"
                        name="licenseDetails.type"
                        className={styles["update-license-details-component-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.licenseDetails.type}
                        onBlur={formik.handleBlur}
                        placeholder={'Ex. 000'}
                    />
                    {(formik.touched.licenseDetails?.type && formik.errors.licenseDetails?.type) ?
                        <ErrorMessageFieldCommonComponent
                            type={formik.errors.licenseDetails?.type}/> : null}
                </div>
            </form>
        </>

    );
}