import React from "react";
import styles from './css/styles.module.css';



export const pageMessageDefault:any ={
    messageType:"",
    message:""
  }

export const clearPageMessageDefault = ()=>{
    pageMessageDefault.messageType = "";
    pageMessageDefault.message = "";
}

export const PageMessageCommonComponent = ({message, messageType }: any) => {
    
  
   
    return (
        <>
            <div style={{backgroundColor: messageType === "ERROR" ? "#db4a4a" :"#059f9f"}}tabIndex={0} className={styles['error-message-container']}>
                
                <div role="alert" aria-live={"assertive"} className={styles['error-message-text']}>
                    {message}
                </div>

            </div>

        </>

    );
}
export const PageMessagePopUpCommonComponent = ({message, messageType }: any) => {
    
  
   
    return (
        <>
            <div style={{backgroundColor: messageType === "ERROR" ? "#db4a4a" :"#059f9f",justifyContent:"center"}}tabIndex={0} className={styles['error-message-container']} >
                
                <div role="alert" aria-live={"assertive"} className={styles['error-message-text']} >
                    {message}
                </div>

            </div>

        </>

    );
}