import React from "react"
import { RiDeleteBin6Line } from "react-icons/ri"
import './styles.scss'

export type Create5754SplitChildPaymentFormHeaderUIProps = {
  title: string
  showDeleteButton: boolean
  onClickDelete: () => void
}

const ROOT_CLASS = 'create-5754-split-payee-form-header'
export const Create5754SplitChildPaymentFormHeaderUI = (props: Create5754SplitChildPaymentFormHeaderUIProps) => {
  const { title, showDeleteButton, onClickDelete } = props 

  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'title' }>{ title }</div>
      
      {showDeleteButton ?
        <button 
          className={ 'delete-split-button' }
          onClick={ onClickDelete }
        >
          <RiDeleteBin6Line style={{cursor: "pointer", color: "white"}}/>
        </button> : null
      }
    </div>
  )
}