import React, { useEffect, useState, useContext } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { annuitiesRoutePath } from "../../../../config";
import { KEY_CODES } from "../../../../constants.js";
import { keyPressedType } from "../../../../services/Commons";
import styles from "./css/styles.module.css";
import { getFirstPaymentList } from "./functions";
import { BannerContext } from "../../../../context/BannerContext";
import { FirstPaymentListComponent } from "../../../../components/AnnuitiesComponents/FirstPayment/FirstPaymentListCmpt";
import { FirstPaymentListData } from "../../../../types/Annuities/FirstPayment/FirstPayment";

//inject adds store to props, observer rerenders component on update to store observables
export const AnnuitiesFirstPaymentPage = inject("annuitiesStore")(
  observer(({ annuitiesStore }: any) => {
    const history = useHistory();
    const bannerContext = useContext(BannerContext);
    const [firstPaymentList, setFirstPaymentList] = useState<Array<FirstPaymentListData>>([]);
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [annuityBtnConfirm, setAnnuityBtnConfirm] = useState<Boolean>(false);

    // package required to focus modal accessibility
    const FocusTrap = require("focus-trap-react");

    useEffect(() => {
      const getPaymentList = async () => {
        try {
          const paymentList = await getFirstPaymentList();
          setFirstPaymentList(paymentList);
          setRequestCompleted(true);
        } catch (error) {
          setRequestCompleted(true);
          bannerContext.setBannerInfo({ message: `${error}`, error: true });
        }
      };
      getPaymentList();
    }, []);

    return (
      <>
        <div className={styles["page-container"]}>
          {/* Circular Progress initial Load screen */}
          {requestCompleted === false && (
            <div
              style={{
                position: "absolute",
                zIndex: 50,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.3)",
                color: "white",
              }}
            >
              <CircularProgress
                size="8em"
                color="inherit"
                thickness={2}
              />
            </div>
          )}
          {/* Pop Up Modal to begin annuity */}
          {annuityBtnConfirm && (
            <FocusTrap focusTrapOptions={{ initialFocus: false }}>
              <div className={styles["overlay-container"]}>
                <div className={styles["main-modal"]}>
                  <div>
                    <div className={styles["modal-title"]}>Attention!</div>
                    <div className={styles["modal-desc"]}>
                      During the annuity set up, you will not be able to save and exit. Please be sure to have all of the relevant information and documents ready to go before you begin.
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => history.push(`${annuitiesRoutePath}/annuity-creation-flow`)}
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        history.push(`${annuitiesRoutePath}/annuity-creation-flow`);
                      }
                    }}
                    className={styles["modal-confirm-btn"]}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className={styles["modal-cancel-btn"]}
                    onClick={() => {
                      annuitiesStore.setFirstPaymentID("");
                      setAnnuityBtnConfirm(!annuityBtnConfirm);
                    }}
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        annuitiesStore.setFirstPaymentID("");
                        setAnnuityBtnConfirm(!annuityBtnConfirm);
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </FocusTrap>
          )}
          {/* Back Btn tsx */}
          <div className={styles["back-btn-container"]}>
            <button
              type="button"
              onKeyPress={event => {
                if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                  history.push(`${annuitiesRoutePath}/`);
                }
              }}
              onClick={() => {
                history.push(`${annuitiesRoutePath}/`);
              }}
              className={styles["back-btn-inner-container"]}
            >
              <FaAngleLeft
                title="left arrow icon"
                className={styles["back-btn-custom"]}
              />
              <div className={styles["back-btn-text"]}>Back to Annuities Landing Page</div>
            </button>
          </div>
          {/* Header */}
          <div className={styles["cmpnt-container"]}>
            <div className={styles["top-ribbon-text"]}>First Payment List</div>
            {/* Main Component */}
            <div className={styles["table-container"]}>
              <FirstPaymentListComponent
                FirstPaymentData={firstPaymentList}
                annuityBtnConfirm={annuityBtnConfirm}
                setAnnuityBtnConfirm={setAnnuityBtnConfirm}
              />
            </div>
          </div>
        </div>
      </>
    );
  })
);
