import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import InputMask from 'react-input-mask';

export const UpdateBookKeeperComponent = ({formik, setFiledValue,}: any) => {

    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Bookkeeper Details
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Name of Bookkeeper*
                    </div>
                    <input
                        id="licenseDetails.bookkeeper.fullName"
                        name="licenseDetails.bookkeeper.fullName"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.bookkeeper.fullName}
                        onBlur={formik.handleBlur}
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.fullName && formik.errors.licenseDetails?.bookkeeper?.fullName) ?
                        <ErrorMessageFieldCommonComponent
                            fullName={formik.errors.licenseDetails?.bookkeeper?.fullName}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Home Phone*
                    </div>
                    <InputMask
                        id="licenseDetails.bookkeeper.homePhone"
                        name="licenseDetails.bookkeeper.homePhone"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.bookkeeper.homePhone}
                        onBlur={formik.handleBlur}
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.homePhone && formik.errors.licenseDetails?.bookkeeper?.homePhone) ?
                        <ErrorMessageFieldCommonComponent
                            homePhone={formik.errors.licenseDetails?.bookkeeper?.homePhone}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing Address*</div>
                    <input
                        id="licenseDetails.bookkeeper.address.streetAddress"
                        name="licenseDetails.bookkeeper.address.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.bookkeeper.address.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.address?.streetAddress && formik.errors.licenseDetails?.bookkeeper?.address?.streetAddress) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.licenseDetails?.bookkeeper?.address?.streetAddress}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Business Phone*
                    </div>
                    <InputMask
                        id="licenseDetails.bookkeeper.businessPhone"
                        name="licenseDetails.bookkeeper.businessPhone"
                        onChange={(e: any) => setFiledValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.licenseDetails.bookkeeper.businessPhone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.businessPhone && formik.errors.licenseDetails?.bookkeeper?.businessPhone) ?
                        <ErrorMessageFieldCommonComponent
                            businessPhone={formik.errors.licenseDetails?.bookkeeper?.businessPhone}/> : null}
                </div>
                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            id="licenseDetails.bookkeeper.address.city"
                            name="licenseDetails.bookkeeper.address.city"
                            value={formik.values.licenseDetails.bookkeeper.address.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                        />
                        {(formik.touched.licenseDetails?.bookkeeper?.address?.city && formik.errors.licenseDetails?.bookkeeper?.address?.city) ?
                            <ErrorMessageFieldCommonComponent
                                city={formik.errors.licenseDetails?.bookkeeper?.address?.city}/> : null}
                    </div>

                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <input
                            id="licenseDetails.bookkeeper.address.zip"
                            name="licenseDetails.bookkeeper.address.zip"
                            value={formik.values.licenseDetails.bookkeeper.address.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            maxLength={5}
                        />
                        {(formik.touched.licenseDetails?.bookkeeper?.address?.zip && formik.errors.licenseDetails?.bookkeeper?.address?.zip) ?
                            <ErrorMessageFieldCommonComponent
                                zip={formik.errors.licenseDetails?.bookkeeper?.address?.zip}/> : null}
                    </div>
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email Address*</div>
                    <input
                        id="licenseDetails.bookkeeper.email"
                        name="licenseDetails.bookkeeper.email"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.bookkeeper.email}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.email && formik.errors.licenseDetails?.bookkeeper?.email) ?
                        <ErrorMessageFieldCommonComponent
                            email={formik.errors.licenseDetails?.bookkeeper?.email}/> : null}
                </div>
            </form>
        </>
    );
}