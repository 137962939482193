import * as React from 'react'
import { GenericSidebarFormMode } from '../../../components/GenericSidebar/generic-sidebar-ui'
import { PlayslipsPromotionMessage } from '../../../api/playslips-fetcher/constants'

type PromotionalMessagesContext = {
  message?: PlayslipsPromotionMessage
  setFormMode: (
    formMode: GenericSidebarFormMode,
    message?: PlayslipsPromotionMessage
  ) => void
  fetchAllPromotionMessages: () => Promise<void>
}
export const defaultPromotionalMessagesContext: PromotionalMessagesContext = {
  setFormMode: () => {
    throw new Error('setFormMode Not Implemented')
  },
  fetchAllPromotionMessages: async () => {
    throw new Error('fetchAllPromotionMessages Not Implemented')
  },
}

export const PromotionalMessagesPageContext =
  React.createContext<PromotionalMessagesContext>(
    defaultPromotionalMessagesContext
  )
