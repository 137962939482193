import { makeHttpCall } from './../../../services/MakeHttpCall';
import config, { playerSupportRoutePath } from './../../../config.js';
import { getPlayersDetailsInterface } from '../UpdateProfilePage/interfaces';
import { mobileClaimDetailsPageInterface } from './interfaces';
import { checkRenderPermissions } from './../../../helpers.js';
import { permissions } from './../../../constants.js';




export const getData = async (playerId: number, claimId: any, setPageMessage: any, pageMessage: any, setMobileClaimDetailsPage: any, mobileClaimDetailsPage: mobileClaimDetailsPageInterface, setIsData: any, storesObject: any) => {

    const userpolicies: any = localStorage.getItem('userpolicies');

    try {
        let response: any;
        let options: any

        if (mobileClaimDetailsPage.player.account.email === "") {
            const maskSsn = !checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies));
            let queryParam = `?maskSsn=${maskSsn}`;
            options =
            {
                method: 'GET',
                url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
            }

            response = await makeHttpCall(options);
            mobileClaimDetailsPage.player = response;

        }

        const body = {
            firstName: mobileClaimDetailsPage.player.identity.firstName,
            lastName: mobileClaimDetailsPage.player.identity.lastName,
        }
        if (checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies))) {

            options =
            {
                method: 'POST',
                url: config.SERVER_BASE_URL + '/v1/claim/' + claimId + '/details',
                body
            }

            response = await makeHttpCall(options);

            mobileClaimDetailsPage.claimReferenceNumber = response;
            storesObject.claimStore.claimReferenceNumber = response;

        }
        if (!(mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId === mobileClaimDetailsPage.player.account.id)) {
            setPageMessage({ message: "Something went wrong. Please try again.", messageType: "ERROR" });
            Object.assign(body, { error: `Player Id and claim id don't match ${mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId} ${mobileClaimDetailsPage.player.account.id}` })
            return

        }


        if (checkRenderPermissions(permissions.CAN_SEE_CLAIM_TRANSACTION_DETAILS, JSON.parse(userpolicies))) {

            options =
            {
                method: 'POST',
                url: config.SERVER_BASE_URL + '/v1/claim/' + claimId + '/history',
                body
            }

            response = await makeHttpCall(options);
            mobileClaimDetailsPage.claimHistory = response.message.claim;
            storesObject.claimStore.claimHistory = response.message.claim;

        }
        if (checkRenderPermissions(permissions.CAN_SEE_CLAIM_W2G, JSON.parse(userpolicies))) {

            options =
                options =
                {
                    method: 'POST',
                    url: config.SERVER_BASE_URL + '/v1/claim/' + claimId + '/w2g',
                    headers: {
                        'Content-Type': "application/pdf"
                    },
                    body
                }

            response = await makeHttpCall(options);
            mobileClaimDetailsPage.w2g = response;
            storesObject.claimStore.claimW2G = response;

        }


            options =
            {
                method: 'POST',
                url: config.SERVER_BASE_URL + '/v1/claim/' + claimId + '/transaction',
                body
            }
            response = await makeHttpCall(options);
            mobileClaimDetailsPage.claimTransaction = response.message.transactions;
            storesObject.claimStore.claimTransaction = response.message.transactions;
            /*mobileClaimDetailsPage.claimTransaction.forEach(async (e: any) => {
               
                if ( e.status === 'SUBMITTED' || e.status === 'CREATED') {
                    
                    try {
                        options =
                        {
                            method: 'POST',
                            url: config.SERVER_BASE_URL + '/v1/ach/trace-number/' + claimId,
                            body
                        }
                        response = await makeHttpCall(options);
                       
                        mobileClaimDetailsPage.achTraceNumber = response.data.rows.length > 0 ? response.data.rows[response.data.rows.length - 1].tracenumber : ""; 
                        
                        setMobileClaimDetailsPage({ ...mobileClaimDetailsPage });
                       
                    } catch (e) {
                        console.log("error", e);

                    }
                }
            });*/

        if (pageMessage.messageType === "ERROR")
            setPageMessage({ message: "", messageType: "" });

        setMobileClaimDetailsPage({ ...mobileClaimDetailsPage });
        setIsData(true);
    } catch (e) {

        setIsData(true);
        const er:any = e;
        if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
            if(value === "Network")
                   return value;
        }) !== undefined)
        {
            setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        }else
            setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
      
        
    }

}