import FocusTrap from "focus-trap-react"
import React from "react"
import './styles.scss'
import { ConfirmationPopupUI } from "../../../../CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui"


export type SubmitSplitConfirmationUIProps = {
  setShowSubmitSplitPopup: React.Dispatch<React.SetStateAction<boolean>>
  submitSplits: () => Promise<void>
}

const ROOT_CLASS = 'submit-5754-split-confirmation-container'
export const SubmitSplitConfirmationUI = (props: SubmitSplitConfirmationUIProps) => {
  const { submitSplits, setShowSubmitSplitPopup } = props

  // Callbacks
  const hideSubmitSplitPopup = () => {
    setShowSubmitSplitPopup(false)
  }
  const onClickSubmit = () => {
    hideSubmitSplitPopup()
    submitSplits()
  }

  // Render
  return (
    <div className={ ROOT_CLASS }>
      <ConfirmationPopupUI 
        message={ 'Are you sure you want to create one or more new splits?' } 
        confirmCallback={ onClickSubmit } 
        closePopup={ hideSubmitSplitPopup }
      />
    </div>
  )
}