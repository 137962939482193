export const GameTypeOptions = {
  Draw: 'Draw',
  Instant: 'Instant',
  Promotion: 'Promotion',
} as const
export type GameType = (typeof GameTypeOptions)[keyof typeof GameTypeOptions]
type Game = {
  name: string
  type: GameType
  graduated?: boolean
}

export const Games: Game[] = [
  { name: 'Powerball', type: 'Draw', graduated: true },
  { name: 'Megabucks', type: 'Draw', graduated: false },
  { name: 'Mass Cash', type: 'Draw', graduated: false },
  { name: 'Mega Millions', type: 'Draw', graduated: true },
  { name: 'Keno', type: 'Draw', graduated: false },
  { name: 'The Numbers Game', type: 'Draw', graduated: false },
  { name: 'Lucky for Life', type: 'Draw', graduated: false },
  { name: 'Mass Million', type: 'Draw', graduated: false },
  { name: 'All or Nothing', type: 'Draw', graduated: false },
]
