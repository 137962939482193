import moment from 'moment';

// export const setStartDate = async (startDate:any, setMCAStartDate:any) => {
//     setMCAStartDate(startDate.target.value);
// }

// export const setEndDate = async (endDate:any, setMCAEndDate:any) => {
//     setMCAEndDate(endDate.target.value);
// }

// export const resetFilters = async (setMCAPlayerData:any, MCABasePlayerData:any, setMCAEndDate:any, setMCAStartDate:any, setMCAInitiated:any,setCurrentPage:any,id:any) => {

//     setMCAEndDate( (MCABasePlayerData.length > 0 &&  MCABasePlayerData[0].claim.referenceNumber !== id) ?   MCABasePlayerData[0].claim.activeFrom.split("T")[0]  : new Date().toISOString().split("T")[0]);
//     setMCAStartDate((MCABasePlayerData.length > 0 &&  MCABasePlayerData[0].claim.referenceNumber !== id) ?   MCABasePlayerData[MCABasePlayerData.length - 1].claim.activeFrom.split("T")[0]  : `${new Date().getUTCFullYear()}-01-01`);
//     setMCAInitiated(true);
//     setMCAPlayerData(MCABasePlayerData);
//     setCurrentPage(0);

// }

// export const applyFilters = async (setMCAPlayerData:any, MCABasePlayerData:any[], startDate:string, endDate:string, initiated:boolean,setCurrentPage:any,id:any) => {

//     setCurrentPage(0);

//     let result = MCABasePlayerData.filter(row => {
//         if (initiated == true){
//             return moment(row.claim.activeFrom).isSameOrAfter(startDate, 'day') && moment(row.claim.activeFrom).isSameOrBefore(endDate, 'day');
//         } else {
//             return moment(row.claim.activeFrom).isSameOrAfter(startDate, 'day') && moment(row.claim.activeFrom).isSameOrBefore(endDate, 'day') && row.claim.status.toLowerCase().includes('initiated') == false;
//         }
//     });

//     if(result.length <= 0 )
//     {
//         const defaultData:any = {
//             activeFrom: "",
//             referenceNumber:id,

//         }
//         result.push({claim:defaultData});
//     }
//     setMCAPlayerData(result);

// }

export const testDate = async (name:any, setErrorEndDate:any,setErrorStartDate:any,comparingValue:any , valueToCompare:any ) => {   
   
  // const startDateForDiff = new Date(comparingValue);
  // const endDateForDiff = new Date(valueToCompare);
  // const timeDifference = Math.abs(endDateForDiff.getTime() - startDateForDiff.getTime())
  // let differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

  // if (differenceInDays > 31) {
  //     setErrorStartDate( 'The startDate and EndDate must be less than 31 days' );
  //     return
  // }
    if(comparingValue > new Date( new Date().toLocaleDateString()).toISOString().split("T")[0]) 
    {
        name === "MCAStartDate" && setErrorStartDate(`The end Date is out of range current date  is ${new Date( new Date().toLocaleDateString() ).toISOString().split("T")[0]}`);
        name === "MCAEndDate" && setErrorEndDate(`The end Date is out of range current date  is ${new Date( new Date().toLocaleDateString() ).toISOString().split("T")[0]}`);
    return;
  }
    if(comparingValue === "")
    {
        name === "MCAStartDate" && setErrorStartDate(`The end Date is out of range and or is not a valid date `);
        name === "MCAEndDate" &&  setErrorEndDate(`The end Date is out of range and or is not a valid date `);
    return;
  }
    if( comparingValue > valueToCompare && name === "MCAStartDate")
    {
        setErrorStartDate(`The Start Date has to be less or equal to the End Date!`);
    return;
  }
    if( comparingValue < valueToCompare && name === "MCAEndDate")
    {
    setErrorEndDate(`The Start Date has to be less or equal to the End Date!`);
    return;
  }
  setErrorEndDate(undefined);
  setErrorStartDate(undefined);
}