import * as React from 'react'
import { useFormik } from 'formik'
import { getBlockClass } from '../../../../../utilities/helpers'
import { CollapsableSection } from '../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import {
  CustomDropdownField,
  CustomDropdownOption,
} from '../../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { ErrorMessageFieldCommonComponent } from '../../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { LoadingDots } from '../../../../CommonComponents/loading-dots/loading-dots-ui'
import './styles.scss'

export type WeeklyRunPromotionFormProps = {
  loading: boolean
  promotionsList?: PromotionSelection[]
  promotionsError?: string
  generateCheckRunReport: (promotionId?: string) => Promise<void>
  setSelectedPromotion: (promotion?: string) => void
}

const FORM_CLASS = 'weekly-run-promotion-form'

export type PromotionSelection = {
  promotionId: string
  promotionName: string
}

export const WeeklyRunPromotionForm = (props: WeeklyRunPromotionFormProps) => {
  const [promotionOptions, setPromotionOptions] = React.useState<
    CustomDropdownOption[]
  >([])

  React.useEffect(() => {
    const options: CustomDropdownOption[] = [
      {
        key: 'default',
        value: undefined,
        display: '',
      },
    ]
    props.promotionsList?.forEach((promotion) => {
      options.push({
        key: promotion.promotionId,
        value: promotion.promotionId,
        display: promotion.promotionName,
      })
    })
    setPromotionOptions(options)
  }, [props.promotionsList])

  const initialValues = {
    selection: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      await props.generateCheckRunReport(values.selection)
      props.setSelectedPromotion(values.selection)
      return
    },
  })

  return (
    <div className={`${FORM_CLASS}-collapsable`}>
      <CollapsableSection title="Filter" textSize="large" defaultOpen>
        <div className={FORM_CLASS}>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className={`${FORM_CLASS}-container`}
          >
            <div className={FORM_CLASS}>
              <label
                className={getBlockClass(FORM_CLASS, 'label')}
                htmlFor={'promotion'}
              >
                Promotion
              </label>
              <CustomDropdownField
                onChange={(event) => {
                  formik.setFieldValue('selection', event.target.value)
                }}
                onBlur={formik.handleBlur}
                id="promotion"
                name="promotion"
                options={promotionOptions}
                disabled={props.loading}
              />
              {props.promotionsError && (
                <div className={getBlockClass(FORM_CLASS, 'error')}>
                  <ErrorMessageFieldCommonComponent
                    errorMessage={props.promotionsError}
                  />
                </div>
              )}
            </div>
            <button
              type="submit"
              className={`${FORM_CLASS}__select-button`}
              aria-label="Select"
              disabled={props.loading}
            >
              {props.loading ? (
                <div
                  className={`${FORM_CLASS}__loading`}
                  aria-busy={props.loading}
                  aria-describedby="loading"
                >
                  <LoadingDots id="loading" color="white" />
                </div>
              ) : (
                'Select'
              )}
            </button>
          </form>
        </div>
      </CollapsableSection>
    </div>
  )
}
