import * as React from 'react'
import moment from 'moment'
import { useFormik } from 'formik'
import { getBlockClass } from '../../../../utilities/helpers'
import { AutoFocusInside } from 'react-focus-lock'
import { CustomDropdownField } from '../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { DateAndTimeInput } from '../../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import { LoadingDots } from '../../../CommonComponents/loading-dots/loading-dots-ui'
import {
  PlayslipsStepDownValidation,
  validateCreatePlayslipsStepDownForm,
} from '../validation'
import {
  CreatePlayslipsStepDownRequest,
  PlayslipsAllActivePlayslipsGamesResponse,
} from '../../../../api/playslips-fetcher/constants'
import './styles.scss'

const FORMIK_DATE_FORMAT = 'YYYY-MM-DDTHH:mm'

const ROOT_CLASS = 'playslips-step-down-sidebar-form'
const FORM_CLASS = getBlockClass(ROOT_CLASS, 'form')

const initialValues: PlayslipsStepDownValidation = {
  gameIdentifier: '',
  stepDownDate: '',
}

export type PlayslipsStepDownSidebarFormUIProps = {
  closeSidebar: () => void
  createIsLoading: boolean
  createError?: string
  createSuccessful: boolean
  createPlayslipsStepDown: (
    stepDown: CreatePlayslipsStepDownRequest
  ) => Promise<void>
  activeGames: PlayslipsAllActivePlayslipsGamesResponse
  fetchAllPlayslipsStepDowns: () => Promise<void>
}

export const PlayslipsStepDownSidebarFormUI = (
  props: PlayslipsStepDownSidebarFormUIProps
) => {
  const refreshPromotions = async () => await props.fetchAllPlayslipsStepDowns()

  React.useEffect(() => {
    if (props.createSuccessful) {
      refreshPromotions()
      props.closeSidebar()
    }
  }, [props.createSuccessful])

  const promotionGameOptions = React.useMemo(() => {
    const options = props.activeGames.map((game) => ({
      key: game.name,
      value: game.identifier,
      display: game.name,
    }))

    options.sort((a, b) => a.key.localeCompare(b.key))

    return [
      {
        key: '',
        value: '',
        display: '',
      },
      ...options,
    ]
  }, [props.activeGames])

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const submissionPayload: CreatePlayslipsStepDownRequest = {
        gameIdentifier: values.gameIdentifier,
        stepDownTimeISO: moment(values.stepDownDate)
          .tz('America/New_York', true)
          .toISOString(),
      }
      await props.createPlayslipsStepDown(submissionPayload)
    },
    validate: validateCreatePlayslipsStepDownForm,
  })

  return (
    <div className={ROOT_CLASS}>
      <form className={FORM_CLASS} onSubmit={formik.handleSubmit} noValidate>
        <AutoFocusInside>
          <div className={getBlockClass(FORM_CLASS, 'form-item')}>
            <label
              className={getBlockClass(FORM_CLASS, 'label')}
              htmlFor="selectedPromotion"
            >
              Select Game
            </label>
            <CustomDropdownField
              onChange={formik.handleChange}
              value={formik.values.gameIdentifier}
              onBlur={formik.handleBlur}
              id="promotion-game-dropdown"
              name="gameIdentifier"
              options={promotionGameOptions}
              error={formik.errors.gameIdentifier}
              touched={formik.touched.gameIdentifier}
              disabled={props.createIsLoading}
            />
          </div>
        </AutoFocusInside>
        <div className={getBlockClass(FORM_CLASS, 'form-item')}>
          <DateAndTimeInput
            label={'Step Down Effective End Date and Time'}
            name={'stepDownDate'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stepDownDate}
            error={formik.errors.stepDownDate}
            touched={formik.touched.stepDownDate}
            minDate={moment().format(FORMIK_DATE_FORMAT)}
            disabled={props.createIsLoading}
            includeTime
          />
        </div>
        {props.createError && (
          <span className={`${FORM_CLASS}__error-text`}>
            Error saving playslips step down: "{props.createError}"
          </span>
        )}
        <div className={getBlockClass(FORM_CLASS, 'save-button-container')}>
          <button
            type="submit"
            className={getBlockClass(FORM_CLASS, 'save-button')}
            aria-label="Save playslips step down"
            disabled={!formik.dirty || !formik.isValid || props.createIsLoading}
          >
            {props.createIsLoading ? (
              <div aria-busy={props.createIsLoading} aria-describedby="loading">
                <LoadingDots id="loading" color="white" />
              </div>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
