import React, { SetStateAction, useState } from "react";
import styles from "./css/styles.module.css";
import { normalizePage, getPageData } from "./functions";
import { keyPressedType } from "../../../../services/Commons";
import { KEY_CODES } from "../../../../constants.js";
import { Payee } from "../../../../types/Annuities/PayeeManagement/Payee";
import { PaginationCommonComponent } from "../../../CommonComponents/PaginationCommonComponent";

// any type reference needs to change here, this is initial layout
export const MultiplePayeeResult: React.FC<{
  payees: Payee[];
  setSelectedPayee: (id: string, payeeType: "Individual" | "Entity" | "") => void;
  setIsPayeeSearchVisible: React.Dispatch<SetStateAction<boolean>>;
  setIsPayeeResultsVisible: React.Dispatch<SetStateAction<boolean>>;
  createPayeeSelected: (isFromPayeeResults: boolean) => void;
}> = ({ payees, setSelectedPayee, setIsPayeeSearchVisible, setIsPayeeResultsVisible, createPayeeSelected }) => {
  const initialSinglePayee: Payee = {
    id: "Loading...",
    createdOn: "Loading...",
    validFrom: "Loading...",
    validTo: "Loading...",
    firstName: "loading...",
    middleInitial: "loading...",
    lastName: "loading...",
    address1: "loading...",
    address2: "loading...",
    city: "loading...",
    state: "loading...",
    zip: "loading...",
    dateOfBirth: "loading...",
    dateOfDeath: "loading...",
    tin: "loading...",
    email: "loading...",
    phone: "loading...",
    payeeType: "Individual",
    notes: "Loading...",
    country: "loading...",
    irsName: "loading..."
  };

  const [singePayeeSelected, setSinglePayeeSelected] = useState<Payee>(initialSinglePayee);
  const [isMultiplePayeeModalVisible, setIsMultiplePayeeModalVisible] = useState<boolean>(true);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const selectPayee = (individualPayeeId: string) => {
    const payeeFound = payees.find(payee => payee.id === individualPayeeId);
    setSinglePayeeSelected(payeeFound!);
    setIsMultiplePayeeModalVisible(false);
    setIsConfirmationModalVisible(true);
  };

  return (
    <div className={styles["overlay-container"]}>
      <div className={styles["main-modal"]}>
        <div>
          <div className={styles["modal-title"]}>Existing Payee Results</div>
          {/* Multiple Payee Table */}
          {isMultiplePayeeModalVisible && (
            <>
              <div className={styles["table"]}>
                <div className={styles["table-data-header"]}>
                  <div aria-live="assertive">{`Displaying ${payees.length <= 0 ? 0 : currentPage * 10 + 1}-${normalizePage(payees.length, currentPage)} of ${payees.length} results`}</div>
                </div>
                <div className={`${styles["table-data-grid-header"]} ${styles["table-data-label"]}`}>
                  <div>Name</div>
                  <div>Address</div>
                  <div></div>
                </div>

                <div className={styles["table-data-body"]}>
                  {getPageData(payees, currentPage).map((individualPayee: Payee, index: 0) => (
                    <div className={`${styles["table-data-grid-body"]}`}>
                      <div className={styles["table-data-data-text"]}>
                        {individualPayee.payeeType === "Individual" ? `${individualPayee.firstName} ${individualPayee.lastName}` : `${individualPayee.freeformName}`}
                      </div>
                      <div className={styles["table-data-data-text"]}>{`${individualPayee.address1} ${individualPayee.city}, ${individualPayee.state} ${individualPayee.zip} `}</div>
                      <button
                        className={`${styles.light_btn}`}
                        onKeyPress={event => {
                          if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                            selectPayee(individualPayee.id);
                          }
                        }}
                        onClick={() => {
                          selectPayee(individualPayee.id);
                        }}
                      >
                        Select Payee
                      </button>
                    </div>
                  ))}
                </div>
                <div className={styles["table-data-footer"]}>
                  <div
                    aria-live="assertive"
                    className={styles["table-page-label"]}
                  >{`Page ${currentPage + 1} of ${Math.ceil(payees.length / 10).toString()}`}</div>
                  {
                    <PaginationCommonComponent
                      playerData={payees}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
              <div className={`${styles.modal_btn_container}`}>
                <button
                  className={styles["modal-cancel-btn"]}
                  type="button"
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      setIsPayeeResultsVisible(false);
                      setIsPayeeSearchVisible(true);
                    }
                  }}
                  onClick={() => {
                    setIsPayeeResultsVisible(false);
                    setIsPayeeSearchVisible(true);
                  }}
                >
                  Back to Payee Search
                </button>
              </div>
            </>
          )}
          {/* Confirmation Modal */}
          {isConfirmationModalVisible && (
            <>
              <div className={styles["payee_result"]}>
                <h5>Primary Info</h5>
                <div className={styles["payee_result_primary_info"]}>
                  <div className={styles["grid_col_2"]}>
                    {singePayeeSelected.payeeType === "Individual" ? (
                      <>
                        <div className={styles["flex_row"]}>
                          <div>First Name</div>
                          <div>{singePayeeSelected.firstName}</div>
                        </div>
                        <div className={styles["flex_row"]}>
                          <div>Middle Initial</div>
                          <div>{singePayeeSelected.middleInitial}</div>
                        </div>
                        <div className={styles["flex_row"]}>
                          <div>Last Name</div>
                          <div>{singePayeeSelected.lastName}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles["flex_row"]}>
                          <div>Freeform Name</div>
                          <div>{singePayeeSelected.freeformName}</div>
                        </div>
                      </>
                    )}
                    <div className={styles["flex_row"]}>
                      <div>TIN</div>
                      <div>{singePayeeSelected.tin}</div>
                    </div>
                  </div>
                </div>
                <h5>Demographic &amp; Contact Info</h5>
                <div className={styles["payee_result_demo_info"]}>
                  <div className={styles["grid_col_2"]}>
                    <div className={styles["flex_row"]}>
                      <div>Mailing Address 1</div>
                      <div>{singePayeeSelected.address1}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Mailing Address 2</div>
                      <div>{singePayeeSelected.address2}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>City</div>
                      <div>{singePayeeSelected.city}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>State/Region</div>
                      <div>{singePayeeSelected.state}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Zip/Postal Code</div>
                      <div>{singePayeeSelected.zip}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Country</div>
                      <div>{singePayeeSelected.country}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Phone</div>
                      <div>{singePayeeSelected.phone}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Email</div>
                      <div>{singePayeeSelected.email}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.modal_btn_container}`}>
                <button
                  className={styles["modal-confirm-btn"]}
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      setSelectedPayee(singePayeeSelected.id, singePayeeSelected.payeeType);
                    }
                  }}
                  onClick={() => {
                    setSelectedPayee(singePayeeSelected.id, singePayeeSelected.payeeType);
                  }}
                >
                  Confirm
                </button>
                <button
                  onKeyPress={async event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      await createPayeeSelected(true);
                    }
                  }}
                  onClick={async () => {
                    await createPayeeSelected(true);
                  }}
                  className={styles["modal-confirm-btn"]}
                >
                  Create New Payee
                </button>
                <button
                  className={styles["modal-cancel-btn"]}
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      setIsConfirmationModalVisible(false);
                      setIsMultiplePayeeModalVisible(true);
                    }
                  }}
                  onClick={() => {
                    setIsConfirmationModalVisible(false);
                    setIsMultiplePayeeModalVisible(true);
                  }}
                >
                  Back to Multiple Results
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
