import * as React from 'react'
import moment from 'moment'
import { PromotionBuilderDrawing } from '../../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PROMOTION_SIDEBAR_FORM_ROOT_CLASS } from '../helpers'
import '../styles.scss'

type PromotionDrawingsViewModeUI = {
  drawings: PromotionBuilderDrawing[]
  getStartDate: (index: number) => string | undefined
  isBonus?: boolean
}

export const PromotionDrawingsViewModeUI = (
  props: PromotionDrawingsViewModeUI
) => {
  const bonusText = props.isBonus ? 'Bonus ' : ''
  return (
    <>
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'display-field'
        )}
      >
        Number of {bonusText}Drawings
        <span>{props.drawings.length}</span>
      </div>
      {props.drawings
        .sort((a, b) => a.drawingNumber - b.drawingNumber)
        .map((drawing, index) => {
          const startDate = props.getStartDate(index)
          const startDrawingText =
            startDate || drawing.scheduledDrawCollectionEndTime
              ? `${
                  Boolean(startDate)
                    ? moment(startDate).format('MM/DD/YYYY • h:mm:ss A')
                    : 'TBD'
                } -`
              : 'TBD'
          const endDrawingText =
            startDate || drawing.scheduledDrawCollectionEndTime
              ? `${
                  Boolean(drawing.scheduledDrawCollectionEndTime)
                    ? moment(drawing.scheduledDrawCollectionEndTime).format(
                        'MM/DD/YYYY • h:mm:ss A'
                      )
                    : 'TBD'
                }`
              : undefined

          const scheduledDrawingText = drawing.scheduledDrawTime
            ? moment(drawing.scheduledDrawTime).format('MM/DD/YYYY • h:mm:ss A')
            : 'TBD'
          return (
            <div
              key={`drawing-${index}`}
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              {bonusText}Drawing {drawing.drawingNumber}
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'drawing-dates'
                )}
              >
                <span>{startDrawingText}</span>
                {endDrawingText && <span>{endDrawingText}</span>}
                <span>
                  Drawing {index + 1} Schedule Time - {scheduledDrawingText}
                </span>
              </div>
            </div>
          )
        })}
    </>
  )
}
