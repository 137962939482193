import axios from "axios";
import moment from "moment";
import config from "../../../../config.js";
import { savePDF } from "../../../../util/excel-export-util";

export const formatDate = (date: string) => moment(date).format('YYYYMMDD');

export const generateCheckRunPDF = async (
  throughDate: string,
  fromDate?: string
) => {
    const reportType = fromDate ? "Reporting": "Authorization";
    const reportDates = `${fromDate ? `${formatDate(fromDate)}-` : ""}${formatDate(throughDate)}`;
    const fileName = `Time_Payment_${reportType}_${reportDates}`
  
    const params = `?throughDate=${throughDate}${fromDate ?`&fromDate=${fromDate}` : ""}`;

    const url = `${config.SERVER_BASE_URL}/v1/annuities/check-run/export${params}`;

    // This is kinda bad. I hate that the shared makeHttpCall method doesn't just return the response.
    // Auth header will go away once the switch to cookies is complete in all envs.
    return await axios
    .get(url, { 
        responseType: "arraybuffer"
    })
    .then((response) => savePDF(response.data, fileName));
};
