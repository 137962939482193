import React from "react"
import './styles.scss'

export type Create5754SplitTitleBarUIProps = {
  submitButtonEnabled: boolean 
  setShowSubmitSplitPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const ROOT_CLASS = 'create-5754-split-title-bar'
export const Create5754SplitTitleBarUI = (props: Create5754SplitTitleBarUIProps) => {
  const { 
    submitButtonEnabled, 
    setShowSubmitSplitPopup
  } = props 

  const submitButtonClass = (submitButtonEnabled) ? 'submit-split-button-enabled' : 'submit-split-button-disabled'
  const onClick = () => {
    setShowSubmitSplitPopup(true)
  }

  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'title' }>Create 5754 Split</div>
      <div className={ 'buttons-container' }>
        <button
          tabIndex={ 0 }
          className={ submitButtonClass }
          disabled={ !submitButtonEnabled }
          onClick={ onClick }
        >
          Submit
        </button>
      </div>
    </div>
  )
}