import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import moment from "moment";

export const UpdatePermitDateOfIssuanceComponent = ({formik, setUpdateDialogToggle}: any) => {

    const ActivateUpdatebutton = (formik.isValid && formik.dirty);

    return (
        <>

            <div className={stylesMessageBox["permit-update-DOI-dialog-main-container"]}>
                <div className={stylesMessageBox["permit-update-DOI-dialog-container"]}>
                    <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-1"]}>
                        <div tabIndex={0} className={stylesMessageBox["permit-update-DOI-dialog-title"]}>
                            Update Date of Issuance
                        </div>
                        <div tabIndex={0} className={stylesMessageBox["permit-update-DOI-dialog-message"]}>
                            Update the date of issuance based on the “Date of Issue” field on the Notice of Issuance
                            received.
                        </div>
                        <div className={`${stylesMessageBox["permit-update-DOI-datepicker-error-container"]}`}>
                            <input
                                className={`${stylesMessageBox["permit-update-DOI-datepicker"]}`}
                                type='date'
                                id="effectiveDate"
                                name="effectiveDate"
                                value={formik.values.effectiveDate}
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('effectiveDate', e.target.value.trim(), true);
                                }}
                                max={moment().format('YYYY-MM-DD')}
                            />
                            {(formik.errors.effectiveDate) ?
                                <ErrorMessageFieldCommonComponent
                                    effectiveDate={formik.errors.effectiveDate}/> : null}
                        </div>
                    </div>
                    <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-2"]}>
                        <div
                            tabIndex={0}
                            role="button"
                            style={{
                                opacity: ActivateUpdatebutton ? "1" : "0.4",
                                cursor: ActivateUpdatebutton ? "pointer" : "not-allowed",
                            }}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    if (ActivateUpdatebutton) {
                                        formik.submitForm();
                                        setUpdateDialogToggle(false);
                                    }
                                }
                            }}
                            onClick={() => {
                                if (ActivateUpdatebutton) {
                                    formik.submitForm();
                                    setUpdateDialogToggle(false);
                                }
                            }}
                            className={stylesMessageBox["permit-update-DOI-dialog-yes-button"]}
                        >
                            <div
                                className={
                                    stylesMessageBox["permit-update-DOI-dialog-yes-button-text"]
                                }
                            >
                                Update
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    formik.resetForm({
                                        values: {
                                            effectiveDate: ''
                                        }
                                    });
                                    setUpdateDialogToggle(false);
                                }
                            }}
                            role="button"
                            onClick={() => {
                                formik.resetForm({
                                    values: {
                                        effectiveDate: ''
                                    }
                                });
                                setUpdateDialogToggle(false);
                            }}
                            className={
                                stylesMessageBox["permit-update-DOI-dialog-no-button"]
                            }
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};