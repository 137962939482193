import * as React from 'react'
import { GetPaymentDetailsResponse } from '../../../../api/payment-details-fetcher/api-types'
import { MaskedSsnUI } from '../../../CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import { PayTaxIndicatorMapping } from '../../../../pages/PaymentsPages/Constants'
import './styles.scss'

const ROOT_CLASS = 'payment-recipient-details'

export type PaymentRecipientDetailsUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
}

export const PaymentRecipientDetailsUI = (
  props: PaymentRecipientDetailsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <section className={`recipient-details-container`}>
        <div className={`row-container-title`}>Recipient Details</div>
        <div className={`column-1`}>Payee</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.name}
        </div>
        <div className={`column-3`}>Tax ID No.</div>
        <div className={`column-4`}>
          {
            PayTaxIndicatorMapping[
              props.paymentDetailsData.payment.payTaxIndicator
            ]
          }
        </div>
        <div className={`column-1`}>TIN</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.ssn ? (
            <MaskedSsnUI ssn={props.paymentDetailsData.payment.ssn} />
          ) : (
            <span>--</span>
          )}
        </div>
        <div className={`column-3`}>TIN Type</div>
        <div className={`column-4`}>
          <span>--</span>
        </div>
      </section>
      <section className="demographic-details-container">
        <div className={`row-container-title`}>
          Demographic &amp; Contact Info
        </div>
        <div className={`column-1`}>Mailing Address 1</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.payeeAddress1}
        </div>
        <div className={`column-3`}>Mailing Address 2</div>
        <div className={`column-4`}>
          {props.paymentDetailsData.payment.payeeAddress2}
        </div>
        <div className={`column-1`}>City</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.payeeCity}
        </div>
        <div className={`column-3`}>State/Region</div>
        <div className={`column-4`}>
          {props.paymentDetailsData.payment.payeeState}
        </div>
        <div className={`column-1`}>Zip/Postal Code</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.payeeZip5}
        </div>
        <div className={`column-3`}>Country</div>
        <div className={`column-4`}>
          {props.paymentDetailsData.payment.payeeCountry}
        </div>
        <div className={`column-1`}>Supplemental Line 1</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.payeeSupplementalAddress1 ?? (
            <span>--</span>
          )}
        </div>
        <div className={`column-3`}>Supplemental Line 2</div>
        <div className={`column-4`}>
          {props.paymentDetailsData.payment.payeeSupplementalAddress2 ?? (
            <span>--</span>
          )}
        </div>
        <div className={`column-1`}>Phone</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.payeePhone}
        </div>
        <div className={`column-3`}>Email</div>
        <div className={`column-4`}>
          {props.paymentDetailsData.payment.payeeEmail}
        </div>
      </section>
    </div>
  )
}
