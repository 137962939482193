import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'

export const getFetchAgent1099InitiatePrint = () => {
  const { isLoading, error, fetchUrl } = useFetch<void>()
  const fetchAgent1099InitiatePrint = (sweepId: string) => {
    fetchUrl(`${config.SERVER_BASE_URL}/api/v1/agent1099Queue/process`, 'POST', {}, JSON.stringify({ sweepId }))
  }
  return { isLoading, error, fetchAgent1099InitiatePrint }
}
