import * as React from 'react'
import moment from 'moment'
import { FormikProps, getIn } from 'formik'
import { PromotionBuilderDrawing } from '../../../../../../api/promotions-fetcher/constants'
import { CircleX } from '../../../../../../icons/circle-x-icon'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { DateAndTimeInput } from '../../../../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import {
  FORMIK_DATE_FORMAT,
  getMaximumDateForDrawing,
  getMinimumDateForDrawing,
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
} from '../helpers'
import { PromotionCampaignValidation } from '../validation'
import '../styles.scss'

type PromotionDrawingFormUIProps = {
  drawing: PromotionBuilderDrawing
  index: number
  isLoading: boolean
  drawingsField: PromotionBuilderDrawing[]
  formik: FormikProps<PromotionCampaignValidation>
  removeDrawing: () => Promise<void>
  startDate: string
  isBonus?: boolean
}

export const PromotionDrawingFormUI = (props: PromotionDrawingFormUIProps) => {
  const minCollectionDate = getMinimumDateForDrawing(
    props.formik.values.startDate,
    props.drawingsField,
    props.index,
    false
  ).format(FORMIK_DATE_FORMAT)
  const maxCollectionDate = getMaximumDateForDrawing(
    props.drawingsField,
    props.index,
    false
  )?.format(FORMIK_DATE_FORMAT)

  const minDrawingDate = getMinimumDateForDrawing(
    props.formik.values.startDate,
    props.drawingsField,
    props.index,
    true
  ).format(FORMIK_DATE_FORMAT)
  const maxDrawingDate = getMaximumDateForDrawing(
    props.drawingsField,
    props.index,
    true
  )?.format(FORMIK_DATE_FORMAT)

  const scheduledDrawingTimePassed =
    props.drawingsField[props.index]?.id &&
    props.drawingsField[props.index]?.scheduledDrawTime
      ? moment(props.drawingsField[props.index].scheduledDrawTime).isBefore(
          moment()
        )
      : false

  const scheduledDrawCollectionTimePassed =
    props.drawingsField[props.index]?.id &&
    props.drawingsField[props.index]?.scheduledDrawCollectionEndTime
      ? moment(
          props.drawingsField[props.index].scheduledDrawCollectionEndTime
        ).isBefore(moment())
      : false

  const drawingsFieldName = props.isBonus ? 'bonusDrawings' : 'drawings'

  return (
    <div>
      <div
        key={props.index}
        className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'header')}
      >
        {props.isBonus ? 'Bonus ' : ''}Drawing {props.drawing.drawingNumber}
        {!props.drawing?.id && (
          <button
            className={getBlockClass(
              PROMOTION_SIDEBAR_FORM_CLASS,
              'remove-button'
            )}
            onClick={props.removeDrawing}
            aria-label={`Remove Drawing ${props.drawing.drawingNumber}`}
            type="button"
          >
            <CircleX />
          </button>
        )}
      </div>
      <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
        <div className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'label')}>
          {props.isBonus ? 'Bonus ' : ''}Drawing Start Date & Time
        </div>
        <p className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'text')}>
          {props.startDate}
        </p>
      </div>
      <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
        <DateAndTimeInput
          label={'Scan Collection End Date and Time'}
          name={`${drawingsFieldName}[${props.index}].scheduledDrawCollectionEndTime`}
          onChange={props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          value={
            props.drawingsField[props.index]?.scheduledDrawCollectionEndTime ??
            ''
          }
          error={getIn(
            props.formik.errors,
            `${drawingsFieldName}[${props.index}].scheduledDrawCollectionEndTime`
          )}
          touched={getIn(
            props.formik.touched,
            `${drawingsFieldName}[${props.index}].scheduledDrawCollectionEndTime`
          )}
          maxDate={maxCollectionDate}
          minDate={minCollectionDate}
          disabled={
            (props.isLoading || scheduledDrawCollectionTimePassed) &&
            (props.formik.isSubmitting || !!props.formik.initialValues.drawings[props.index].initialCollectionTime)
          }
          noValidate={scheduledDrawCollectionTimePassed}
          includeTime
          includeSeconds
          optionalLabel
          greyOnDisabled
        />
      </div>
      <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
        <DateAndTimeInput
          label={'Drawing Schedule Time'}
          name={`${drawingsFieldName}[${props.index}].scheduledDrawTime`}
          onChange={props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          value={props.drawingsField[props.index]?.scheduledDrawTime ?? ''}
          error={getIn(
            props.formik.errors,
            `${drawingsFieldName}[${props.index}].scheduledDrawTime`
          )}
          touched={getIn(
            props.formik.touched,
            `${drawingsFieldName}[${props.index}].scheduledDrawTime`
          )}
          maxDate={maxDrawingDate}
          minDate={minDrawingDate}
          disabled={
            (props.isLoading || scheduledDrawingTimePassed) &&
            (props.formik.isSubmitting || !!props.formik.initialValues.drawings[props.index].initialDrawTime)
          }
          noValidate={scheduledDrawingTimePassed}
          includeTime
          includeSeconds
          optionalLabel
          greyOnDisabled
        />
      </div>
    </div>
  )
}
