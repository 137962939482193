import * as React from 'react'
import moment from 'moment'
import { useFormik } from 'formik'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'
import { DateAndTimeInput } from '../../../../../../components/CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import { getBlockClass } from '../../../../../../utilities/helpers'
import './styles.scss'

export type PlayerPromotionFilterValues = {
  fromScanDate: string
  throughScanDate: string
  currentPromotions: boolean
}

export const initialPlayerPromotionFilterValues: PlayerPromotionFilterValues = {
  fromScanDate: '',
  throughScanDate: '',
  currentPromotions: false,
}

export type PlayerPromotionsFormProps = {
  disabled: boolean
  filterValues: PlayerPromotionFilterValues
  setFilterValues: (newValues: PlayerPromotionFilterValues) => void
  clearFilterValues: () => void
}

const FORM_CLASS = 'scan-to-enter-progress-filter-form'

const DATE_FORMAT = 'YYYY-MM-DD'

export const ScanToEnterProgressFilterForm = (
  props: PlayerPromotionsFormProps
) => {
  const initialValues: PlayerPromotionFilterValues =
    initialPlayerPromotionFilterValues

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: PlayerPromotionFilterValues) => {
      props.setFilterValues(values)
      return
    },
    validate: (values: PlayerPromotionFilterValues) => {
      let errors = {}
      if (
        values.fromScanDate &&
        values.throughScanDate &&
        new Date(values.fromScanDate) > new Date(values.throughScanDate)
      ) {
        Object.assign(errors, {
          fromScanDate: 'From Scan Date must be before Through Scan Date',
        })
      }
      return errors
    },
  })

  const resetForm = () => {
    props.clearFilterValues()
    formik.resetForm()
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className={`${FORM_CLASS}-container`}
    >
      <h2 className={getBlockClass(FORM_CLASS, 'header')}>Filter</h2>
      <div className={FORM_CLASS}>
        <DateAndTimeInput
          label={'Select From Scan Date'}
          labelColor="white"
          name={'fromScanDate'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fromScanDate}
          error={formik.errors.fromScanDate}
          touched={formik.touched.fromScanDate}
          maxDate={moment().format(DATE_FORMAT)}
          disabled={props.disabled}
          greyOnDisabled
        />
        <DateAndTimeInput
          label={'Select Through Scan Date'}
          labelColor="white"
          name={'throughScanDate'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.throughScanDate}
          error={formik.errors.throughScanDate}
          touched={formik.touched.throughScanDate}
          maxDate={moment().format(DATE_FORMAT)}
          minDate={formik.values.fromScanDate}
          disabled={props.disabled}
          greyOnDisabled
        />
        <button
          aria-label={`Toggle Current Promotion Filter`}
          onClick={() =>
            formik.setFieldValue(
              'currentPromotions',
              !formik.values.currentPromotions
            )
          }
          className={getBlockClass(FORM_CLASS, 'toggle')}
          disabled={props.disabled}
          type="button"
        >
          {formik.values.currentPromotions ? (
            <FaRegCheckSquare color="white" strokeWidth="20px" />
          ) : (
            <FaRegSquare color="white" strokeWidth="20px" />
          )}
          Current Promotions
        </button>
        <button
          type="submit"
          className={getBlockClass(FORM_CLASS, 'search-button')}
          aria-label="Search"
          disabled={props.disabled || !formik.isValid}
        >
          Search
        </button>
        <button
          type="button"
          className={getBlockClass(FORM_CLASS, 'clear-button')}
          aria-label="Clear Search"
          disabled={props.disabled}
          onClick={resetForm}
        >
          Clear Search
        </button>
      </div>
    </form>
  )
}
