import React, { useEffect, useState } from "react";
import styles from "./css/styles.module.css";

import { permissions } from "./../../constants.js";
import { checkRenderPermissions } from "./../../../helpers.js";
import { Forbidden409Page } from "../Forbidden409Page";
import CustomCard from "../../../dashboard/customCard";
import { useHistory } from "react-router-dom";
import { PageInfo, ButtonInfo } from "./interfaces";

//@todo- create interface for parameters passing in
export const CommonLandingPage = ({ title, policy, buttonData, backButtonText }: PageInfo) => {
  const history = useHistory();
  const userpolicies: any = localStorage.getItem("userpolicies");

  //routing function
  const navigationCallback = (route: string) => {
    history.push(route);
  };

  return (
    <>
      {checkRenderPermissions(policy, JSON.parse(userpolicies)) ? (
        <div className={styles["page-container"]}>
          <div className={styles["page-title"]}>{title}</div>
          <div className={styles["page-content"]}>
            <div className={styles["btn-align"]}>
              {buttonData.map(
                (button: any, index: number) =>
                  checkRenderPermissions(button.buttonPermission, JSON.parse(userpolicies)) && (
                    <CustomCard
                      key={index}
                      title={button.buttonTitle}
                      iconPath={null}
                      handleCallback={() => navigationCallback(button.buttonRoute)}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      ) : (
        <Forbidden409Page />
      )}
    </>
  );
};
