import React, { useState, useEffect, useRef } from 'react'
import { useFetchAgent1099DataYears } from '../../../api/agent-1099-fetcher/use-fetch-agent-1099-data-years'
import { getFetchAgent1099AggregatesSearch } from '../../../api/agent-1099-fetcher/get-fetch-agent-1099-aggregates-search'
import { Agent1099QueueAllPaymentsTabUI } from './agent-1099-queue-all-payments-tab-ui'
import { getFetchAgent1099AddToSweep } from '../../../api/agent-1099-fetcher/get-fetch-agent-1099-add-to-sweep'
import { Agent1099AggregatesSearchRequestBody, Agent1099AggregatesSearchResponse } from '../../../api/agent-1099-fetcher/api-types'

export const Agent1099QueueAllPaymentsTabContainer = () => {
  const {
    isLoading: dataYearsLoading,
    error: dataYearsError,
    data: dataYearsData
  } = useFetchAgent1099DataYears()
  const {
    isLoading: searchLoading,
    error: searchError,
    data: searchData,
    fetchAggregatesForSearch
  } = getFetchAgent1099AggregatesSearch()
  const {
    isLoading: addToSweepLoading,
    error: addToSweepError,
    fetchAgent1099AddToSweep
  } = getFetchAgent1099AddToSweep()

  const [searchYear, setSearchYear] = useState<string>()
  const [searchTin, setSearchTin] = useState<string | undefined>()

  useEffect(() => {
    if (searchTin || searchYear) {
      fetchAggregatesForSearch({ tin: searchTin, year: searchYear })
    }
  }, [searchYear, searchTin])

  const prevIsAddLoading = useRef<boolean>()

  useEffect(() => {
    if(!addToSweepLoading) {
      if(!addToSweepError && (searchTin || searchYear) && prevIsAddLoading.current) {
        fetchAggregatesForSearch({ tin: searchTin, year: searchYear })
      }
    }
    prevIsAddLoading.current = addToSweepLoading
  }, [addToSweepLoading])

  return (
    <Agent1099QueueAllPaymentsTabUI 
      isLoading={dataYearsLoading || searchLoading || addToSweepLoading}
      error={dataYearsError ?? searchError ?? addToSweepError}
      yearOptions={dataYearsData?.years}
      searchData={searchData}
      searchedYear={searchYear}
      searchedTin={searchTin}
      setSearchedYear={setSearchYear}
      setSearchedTin={setSearchTin}
      reprint={(tin: string, year: string) => fetchAgent1099AddToSweep({ tin, year })}
    />
  )
}
