import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, getPageData, onSubmit, setFieldValue, setVariables, validate, settleCharges} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent, NotificationType
} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import moment from "moment";
import filterCollapseIcon from "../../../../icons/Icon_arrows_carrot_teal-up.svg";
import filterExpandIcon from "../../../../icons/Icon_arrows_carrot_teal-down.svg";
import {
    ErrorMessageFieldCommonComponent
} from "../../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {PaginationCommonComponent} from "../../../../components/CommonComponents/PaginationCommonComponent";
import {CheckboxComponent} from "../../../../components/CommonComponents/CheckboxComponent";
import {
    CheckReceivedInfoMessageComponent
} from "../../../../components/CharitableGamingComponents/Common/CheckReceivedInfoMessageComponent";
import InfoIcon from "./../../../../icons/info-white.svg";
import {ChargeStatuses, ExpenseTypes} from "./constants.js";
import {chargeRoutePath} from "../../../../config";
import {
    ChargesInterface, ChargesSearchResultDefaultData, ChargesSearchResultInterface
} from "../LicenseeChargesPage/interfaces";
import {formatCentsToDollars} from "../../../../util";
import {ChargeStatusMapping, ExpenseTypeMapping} from "../../../../Constants/CharitableConstant.js";
import ExclamationIcon from "../../../../icons/icon_status_flagged.svg";
import {
    SearchResultExeceededInfoMessageComponent
} from "../../../../components/CharitableGamingComponents/Common/SearchResultExeceededInfoMessageComponent";


export const PermitHolderChargesPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

    const history: any = useHistory();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const MAX_DISPLAYABLE_RESULT = 500;

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            expenseType: '',
            chargeStatus: '',
            startChargeDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endChargeDate: moment().format('YYYY-MM-DD'),
            raffleId: ''
        }, validate, onSubmit
    });

    const activateSearchButton = (formik.isValid && formik.dirty);

    const [permitHolderCharges, setPermitHolderCharges] = useState<ChargesSearchResultInterface>(ChargesSearchResultDefaultData);
    const [selectedChargeIds, setSelectedChargeIds] = useState(new Set());

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const {notificationBanner} = charitableGamingStore;

    const [showCheckReceivedInfoMessage, setShowCheckReceivedInfoMessage] = useState<boolean>(true);
    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        if (requestCompleted === false) {
            formik.submitForm();
        }
        setTimeout(function () {
            setShowCheckReceivedInfoMessage(false);
            setShowSearchResultExceededInfoMessage(false);
        }, 3000);
    }, []);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    setVariables(formik, charitableGamingStore, setCurrentPage, setShowErrorDialog, setRequestCompleted, setPermitHolderCharges);

    const handleCheckReceivedCheckboxChange = (e: ChargesInterface) => {

        if (!selectedChargeIds.has(e.chargeId)) {
            setSelectedChargeIds(new Set(selectedChargeIds.add(e.chargeId)));
        } else {
            selectedChargeIds.delete(e.chargeId)
            setSelectedChargeIds(new Set(Array.from(selectedChargeIds)));
        }

    }

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['permit-holder-charges-page-container']}`}
            >
                <div tabIndex={0}
                     className={`${styles['permit-holder-charges-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             //history.push(`${pulltabRoutePath}/orders`);
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         //history.push(`${pulltabRoutePath}/orders`);
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['permit-holder-charges-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to Charge Management
                    </div>
                </div>
                <div className={`${styles['permit-holder-charges-page-ribbon-container']}`}>
                    <div className={`${styles['permit-holder-charges-page-ribbon-title']} ${styles['']}`}>Permit Holder
                        Charges
                    </div>
                    <div className={`${styles['permit-holder-charges-page-ribbon-buttons-container']}`}>
                        {checkRenderPermissions(permissions.CAN_MARK_CHECK_RECEIVED, JSON.parse(userpolicies)) ?
                            <div tabIndex={0}
                                 className={`${styles['permit-holder-charges-page-ribbon-renew-license-button']} ${styles['']}`}
                                 role="button"
                                 style={{
                                     opacity: (selectedChargeIds.size > 0) ? "1" : "0.4",
                                     cursor: (selectedChargeIds.size > 0) ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32 && selectedChargeIds.size > 0) {
                                         settleCharges(Array.from(selectedChargeIds), setSelectedChargeIds);
                                     }
                                 }}
                                 onClick={() => {
                                     if (selectedChargeIds.size > 0) {
                                         settleCharges(Array.from(selectedChargeIds), setSelectedChargeIds);
                                     }
                                 }}>
                                <div
                                    className={`${styles['permit-holder-charges-page-ribbon-create-license-button-label']}`}
                                >
                                    Mark Check Received
                                </div>
                            </div> : null}


                    </div>

                </div>

                <div
                    className={`${styles['permit-holder-charges-page-search-bar-and-notification-bar-container']}`}>
                    <div className={`${styles['permit-holder-charges-page-search-bar-container']} ${styles['']}`}>
                        <div
                            className={`${styles['permit-holder-charges-page-search-bar-title']} ${styles['']}`}>Search
                        </div>
                        <div
                            className={`${styles['permit-holder-charges-page-search-bar-filter-container']} ${styles['']}`}>

                            <div
                                className={`${styles['permit-holder-charges-page-search-bar-filter-title-bar']} ${styles['']}`}>
                                <div
                                    className={`${styles['permit-holder-charges-page-search-bar-filter-title']} ${styles['']}`}>Filter
                                </div>
                                <div
                                    className={`${styles['permit-holder-charges-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}>
                                    <img
                                        tabIndex={0}
                                        src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                        className={styles[""]}
                                        alt="filter expander icon"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setShowFilters(!showFilters);
                                            }
                                        }}
                                        onClick={() => {
                                            setShowFilters(!showFilters);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {showFilters ? <div
                            className={`${styles['permit-holder-charges-page-search-bar-filter-expanded-container']} ${styles['']}`}>
                            <div
                                className={`${styles['permit-holder-charges-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>Filter by Expense Type
                                </div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select className={styles["custom_select"]}
                                            id="expenseType"
                                            name="expenseType"
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                                formik.submitForm();
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder="Select"
                                    >
                                        {ExpenseTypes.map((expenseType: string) => <option
                                            selected={formik.values.expenseType.toLowerCase() === expenseType.toLowerCase()}
                                            value={expenseType}>{expenseType}
                                        </option>)}
                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                            </div>
                            <div
                                className={`${styles['permit-holder-charges-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>Filter by Charge Status
                                </div>
                                <div className={`${styles.custom_select_div}`}>
                                    <select className={styles["custom_select"]}
                                            id="chargeStatus"
                                            name="chargeStatus"
                                            onChange={(e: any) => {
                                                setFieldValue(e, formik);
                                                formik.submitForm();
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder="Select"
                                    >
                                        {ChargeStatuses.map((chargeStatus: string) => <option
                                            selected={formik.values.chargeStatus.toLowerCase() === chargeStatus.toLowerCase()}
                                            value={chargeStatus}>{chargeStatus}
                                        </option>)}
                                    </select>
                                    <span className={`${styles.custom_arrow}`}></span>
                                </div>
                            </div>
                            <div className={`${styles['permit-holder-charges-page-datetime-picker-container']}`}>
                                <div
                                    className={`${styles['permit-holder-charges-page-label-datepicker-container']} ${styles['']}`}>
                                    <div className={`${styles['mslc-label']} ${styles['']}`}>Start Charge Date
                                    </div>
                                    <input
                                        className={`${styles['mslc-datetime-picker']}`}
                                        type={'date'}
                                        id="startChargeDate"
                                        name="startChargeDate"
                                        value={formik.values.startChargeDate}
                                        onBlur={formik.handleBlur}
                                        onChange={(e: any) => {
                                            setFieldValue(e, formik);
                                        }}
                                        max={moment().format('YYYY-MM-DD')}
                                        placeholder={'mm/dd/yyyy'}
                                    />
                                    {(formik.touched.startChargeDate && formik.errors.startChargeDate) ?
                                        <ErrorMessageFieldCommonComponent
                                            errorMessages={formik.errors.startChargeDate}/> : null}
                                </div>
                                <div
                                    className={`${styles['permit-holder-charges-page-label-datepicker-container']} ${styles['']}`}>
                                    <div className={`${styles['mslc-label']} ${styles['']}`}>End Charge Date
                                    </div>
                                    <input
                                        className={`${styles['mslc-datetime-picker']}`}
                                        type={'date'}
                                        id="endChargeDate"
                                        name="endChargeDate"
                                        value={formik.values.endChargeDate}
                                        onBlur={formik.handleBlur}
                                        onChange={(e: any) => {
                                            setFieldValue(e, formik);
                                        }}
                                        min={formik.values.startChargeDate}
                                        max={moment().format('YYYY-MM-DD')}
                                        placeholder={'mm/dd/yyyy'}
                                        disabled={!formik.values.startChargeDate}
                                    />
                                    {(formik.touched.endChargeDate && formik.errors.endChargeDate) ?
                                        <ErrorMessageFieldCommonComponent
                                            errorMessages={formik.errors.endChargeDate}/> : null}
                                </div>
                            </div>
                        </div> : null}

                        <div
                            className={`${styles['permit-holder-charges-page-search-bar-input-button-container']} ${styles['']}`}>
                            <div
                                className={`${styles['permit-holder-charges-page-search-bar-label-textbox-container']} ${styles['']}`}>
                                <div className={`${styles['mslc-label']} ${styles['']}`}>Raffle ID</div>
                                <input className={`${styles['mslc-textbox']}`}
                                       type="text"
                                       id="raffleId"
                                       name="raffleId"
                                       value={formik.values.raffleId}
                                       onBlur={formik.handleBlur}
                                       onChange={(e: any) => {
                                           setFieldValue(e, formik);
                                       }}/>
                                {formik.errors.raffleId ? <ErrorMessageFieldCommonComponent
                                    errorMessages={formik.errors.raffleId}/> : null}
                            </div>
                            <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                                 role="button"
                                 style={{
                                     opacity: (activateSearchButton) ? "1" : "0.4",
                                     cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (activateSearchButton) {
                                             formik.submitForm();
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }}>Search

                            </div>
                            <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         clearSearch(formik);
                                         setSelectedChargeIds(new Set());
                                     }
                                 }}
                                 onClick={() => {
                                     clearSearch(formik);
                                     setSelectedChargeIds(new Set());
                                 }}>Clear Search
                            </div>
                        </div>
                    </div>
                </div>

                {permitHolderCharges.charges.length > 0 ? <div className={styles['table']}>

                    <div className={styles['result-totals-container']}>
                        <div className={styles['result-totals-container-title']}>Result Totals</div>
                        <div className={styles['result-totals-inner-container']}>
                            <div className={styles['result-totals-label-value-container']}>
                                <div className={styles['result-totals-label']}>Raffle Taxes</div>
                                <div
                                    className={styles['result-totals-value']}>{formatCentsToDollars(permitHolderCharges.raffleBazaarTotalInCents)}</div>
                            </div>
                            <div className={styles['result-totals-label-value-container']}>
                                <div className={styles['result-totals-label']}>Casino Night Taxes</div>
                                <div
                                    className={styles['result-totals-value']}>{formatCentsToDollars(permitHolderCharges.casinoFunctionTotalInCents)}</div>
                            </div>
                            <div className={styles['result-totals-label-value-container']}>
                                <div className={styles['result-totals-label']}>Grand Total</div>
                                <div
                                    className={styles['result-totals-value']}>{formatCentsToDollars(permitHolderCharges.grandTotalInCents)}</div>
                            </div>
                        </div>
                    </div>

                    <hr className={styles['horizontal-line']}/>


                    <div className={styles['table-data-header']}>
                        <div className={styles['table-data-header-text']}
                             aria-live="assertive">{`Displaying ${permitHolderCharges.charges.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(permitHolderCharges.charges.length, currentPage)} 
                        of ${permitHolderCharges.charges.length}${permitHolderCharges.charges.length > MAX_DISPLAYABLE_RESULT ? "+" : ""} results`}
                        </div>

                        {permitHolderCharges.charges.length > MAX_DISPLAYABLE_RESULT ? <div
                            style={{
                                cursor: "pointer"
                            }}
                            onMouseEnter={() => {
                                setShowSearchResultExceededInfoMessage(true);
                            }}
                            onMouseLeave={() => {
                                setShowSearchResultExceededInfoMessage(false);
                            }}
                        >
                            <img
                                src={ExclamationIcon}
                                height={20}
                                width={20}
                                alt="exclamation icon"
                            />
                            {permitHolderCharges.charges.length > MAX_DISPLAYABLE_RESULT && showSearchResultExceededInfoMessage ?
                                <SearchResultExeceededInfoMessageComponent/> : null}
                        </div> : null}
                    </div>

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div>Raffle ID.</div>
                        <div>Charge Date</div>
                        <div>Expense Type</div>
                        <div>Charge Status</div>
                        <div>Amount Due</div>
                        <div>Payment Method</div>
                        <div className={styles['table-header-icon-container']}>
                            <div>Check Received</div>
                            <div
                                style={{
                                    cursor: "pointer"
                                }}
                                onMouseEnter={() => {
                                    setShowCheckReceivedInfoMessage(true);
                                }}
                                onMouseLeave={() => {
                                    setShowCheckReceivedInfoMessage(false);
                                }}
                            >
                                <img src={InfoIcon} width="20" height="20" alt="Info Icon"/>
                                {showCheckReceivedInfoMessage ? <CheckReceivedInfoMessageComponent/> : null}
                            </div>
                        </div>

                    </div>

                    <div className={styles['table-data-body']}>
                        {permitHolderCharges.charges.length > 0 && getPageData(permitHolderCharges.charges, currentPage).map((e: ChargesInterface, i: number) =>

                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div className={styles["table-data-data-text"]}>
                                    {e.orgDetails.identifier}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {moment(e.date).utc().format('MM/DD/YYYY')}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {ExpenseTypeMapping[e.expenseType]}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {ChargeStatusMapping[e.status]}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {formatCentsToDollars(e.totalAmountInCents)}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {e.paymentMethod}
                                </div>
                                <div className={styles["table-data-data-text"]}>
                                    {checkRenderPermissions(permissions.CAN_MARK_CHECK_RECEIVED, JSON.parse(userpolicies)) ? ((e.paymentMethod === 'CHECK') ? ((e.checkReceived) ?
                                        <div className={`${styles['permit-holder-charges-page-checkbox']}`}>
                                            <CheckboxComponent isChecked={e.checkReceived} disabled={true}/>
                                        </div> : <div tabIndex={0}
                                                      className={`${styles['permit-holder-charges-page-checkbox']}`}
                                                      style={{cursor: "pointer"}}
                                                      onKeyDown={(ev: any) => {
                                                          if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                              handleCheckReceivedCheckboxChange(e);
                                                          }
                                                      }}
                                                      onClick={() => {
                                                          handleCheckReceivedCheckboxChange(e);
                                                      }}
                                        >
                                            <CheckboxComponent isChecked={selectedChargeIds.has(e.chargeId)}/>
                                        </div>) : ('n/a')) : ((e.paymentMethod === 'CHECK') ? ((e.checkReceived) ?
                                        <div className={`${styles['permit-holder-charges-page-checkbox']}`}>
                                            <CheckboxComponent isChecked={e.checkReceived} disabled={true}/>
                                        </div> : null) : ('n/a'))}
                                </div>
                                <div className={styles['table-details-button-container']}>
                                    {checkRenderPermissions(permissions.CAN_SEE_PERMIT_HOLDER_CHARGE_DETAILS, JSON.parse(userpolicies)) ?
                                        <div tabIndex={0} role='button' aria-pressed={"false"}
                                             onKeyDown={(en: any) => {
                                                 if (keyPressedType(en) === 13 || keyPressedType(en) === 32) {
                                                     history.push(`${chargeRoutePath}/permit-holder-charges/details/${e.chargeId}`);
                                                 }
                                             }}
                                             onClick={() => {
                                                 history.push(`${chargeRoutePath}/permit-holder-charges/details/${e.chargeId}`);
                                             }}
                                             className={styles['table-details-button']}>
                                            <div aria-label={'Pull-tab Order Item Details Button.'}
                                                 className={styles['table-details-button-text']}>
                                                Details
                                            </div>
                                        </div> : null}
                                </div>
                            </div>)}
                    </div>
                    <div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((permitHolderCharges.charges.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={permitHolderCharges.charges}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>
                </div> : <div className={`${styles['permit-holder-charges-page-search-no-results-container']}`}>
                    <div className={`${styles['permit-holder-charges-page-search-no-results-container-text']}`}>
                        No Results. Please try a different search.
                    </div>
                </div>}

            </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                type={NotificationType.Success}
                text={notificationBanner.text}
                handleHide={hideNotificationBanner}
            /> : null}
        </>

    );
}));