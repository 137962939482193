import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import CustomCard from "../../../../dashboard/customCard.js";
import {chargeRoutePath} from "../../../../config";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";

export const ChargeManagementPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        return (
            <>

                <div className={`${styles['license-profile-mgmt-page-container']} ${styles['']}`}>
                    <div tabIndex={0}
                         className={`${styles['license-profile-mgmt-page-back-button-container']} ${styles['']}`}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 history.goBack();
                             }
                         }}
                         onClick={() => {
                             history.goBack();
                         }}
                    >
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['license-profile-mgmt-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                        >Back
                            to Charitable Gaming
                        </div>
                    </div>
                    <div className={`${styles['license-profile-mgmt-page-ribbon-container']} ${styles['']}`}>
                        <div
                             className={`${styles['license-profile-mgmt-page-ribbon-title']} ${styles['']}`}>Charge Management
                        </div>
                    </div>

                    <div className={styles["page-content"]}>
                        <div className={styles["btn-align"]}>
                            {checkRenderPermissions(permissions.CAN_SEE_LICENSEE_CHARGES, JSON.parse(userpolicies)) ?
                                <CustomCard
                                    title={'Licensee Charges'}
                                    iconPath={null}
                                    handleCallback={() => {
                                        history.push(`${chargeRoutePath}/licensee-charges`);
                                    }}
                                /> : null}
                            {checkRenderPermissions(permissions.CAN_SEE_EFT_LEDGER, JSON.parse(userpolicies)) ?
                                <CustomCard
                                    title={'EFT Ledger'}
                                    iconPath={null}
                                    handleCallback={() => {
                                        history.push(`${chargeRoutePath}/eft-ledger`);
                                    }}
                                /> : null}
                            {checkRenderPermissions(permissions.CAN_SEE_PERMIT_HOLDER_CHARGES, JSON.parse(userpolicies)) ?
                                <CustomCard
                                    title={'Permit Holder Charges'}
                                    iconPath={null}
                                    handleCallback={() => {
                                        history.push(`${chargeRoutePath}/permit-holder-charges`);
                                    }}
                                /> : null}
                        </div>
                    </div>

                </div>

            </>

        );
    })
);