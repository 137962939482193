import * as React from 'react'
import capitalize from 'lodash/capitalize'
import { useKeyDown } from '../../../../hooks/use-keydown/use-keydown'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../utilities/helpers'
import { AccessibleKey } from '../../../common'
import {
  GenericTableActionMenu,
  GenericTableRowActionMenuUI,
} from './generic-table-row-action-menu/generic-table-row-action-menu-ui'
import './styles.scss'

export enum GenericTableColumnType {
  DEFAULT = '',
  BOLD = 'bold',
  SMALL = 'small',
  STATUS = 'status',
  LINK = 'link',
}

export type GenericTableColumnMap<T> = {
  key: keyof T
  columnText: string
  columnType: GenericTableColumnType
  textAlign?: 'right' | 'center' | 'left'
  statusMap?: Record<string, string>
  widthPx?: number
}[]

export type GenericTableLink = {
  value: string
  onClick: () => void
  ariaLabel: string
  disabled?: boolean
}

type RowMessage = {
  message: string
  icon?: React.JSX.Element
  backgroundColor: string
}

export type GenericTableRow<T> = T & {
  rowMessage?: RowMessage
  actionMenu?: GenericTableActionMenu
}

type GenericTableRowProps<T> = {
  columnMap: GenericTableColumnMap<T>
  row: GenericTableRow<T>
  index: number
  customCellStyling?: React.CSSProperties
}

const ROW_CLASS = `generic-table-row`
const CELL_CLASS = getBlockClass(ROW_CLASS, 'cell')
const STATUS_CLASS = getBlockClass(CELL_CLASS, 'status')
const LINK_CLASS = getBlockClass(CELL_CLASS, 'link')

const mapStatusObject = (key: string, record: Record<string, string>) => (
  <div className={STATUS_CLASS}>
    <div
      className={getBlockClass(STATUS_CLASS, 'circle')}
      style={{ backgroundColor: record[key] }}
    />
    <div className={getBlockClass(STATUS_CLASS, 'text')}>{capitalize(key)}</div>
  </div>
)

const mapLinkObject = (linkObject: GenericTableLink) => (
  <button
    className={LINK_CLASS}
    aria-label={linkObject.ariaLabel}
    onClick={linkObject.onClick}
    role="link"
    type="button"
    disabled={linkObject.disabled ?? false}
  >
    {linkObject.value}
  </button>
)

export const GenericTableRowUI = <T extends Record<string, any>>(
  props: GenericTableRowProps<T>
) => {
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState<boolean>(false)

  const handleEscKey = () => {
    if (actionMenuIsOpen) {
      setActionMenuIsOpen(false)
    }
  }
  useKeyDown({
    key: AccessibleKey.escape,
    action: handleEscKey,
    dependencies: [actionMenuIsOpen],
  })

  return (
    <tbody key={props.index}>
      {props.row.rowMessage && (
        <tr>
          <td colSpan={100}>
            <div
              className={getBlockClass(ROW_CLASS, 'message-bar')}
              style={{ backgroundColor: props.row.rowMessage.backgroundColor }}
            >
              {props.row.rowMessage.icon && (
                <div className={getBlockClass(ROW_CLASS, 'message-bar-icon')}>
                  {props.row.rowMessage.icon}
                </div>
              )}
              {props.row.rowMessage.message}
            </div>
          </td>
        </tr>
      )}
      <tr className={ROW_CLASS}>
        {props.columnMap.map((column, j) => {
          let cellContents

          switch (column.columnType) {
            case GenericTableColumnType.STATUS:
              if (column.statusMap) {
                cellContents = mapStatusObject(
                  props.row[column.key],
                  column.statusMap
                )
              }
              break
            case GenericTableColumnType.LINK:
              cellContents = mapLinkObject(props.row[column.key])
              break
            default:
              cellContents = props.row[column.key]
              break
          }

          return (
            <td
              key={j}
              className={joinClasses([
                CELL_CLASS,
                getModifierClass(CELL_CLASS, column.columnType, true),
              ])}
              style={{
                ...props.customCellStyling,
                width: column.widthPx ?? 'fit-content',
                textAlign: column.textAlign,
              }}
            >
              {cellContents}
            </td>
          )
        })}
        {props.row.actionMenu && (
          <td className={getBlockClass(CELL_CLASS, 'action-menu')}>
            <GenericTableRowActionMenuUI
              actionMenu={props.row.actionMenu}
              isOpen={actionMenuIsOpen}
              setIsOpen={setActionMenuIsOpen}
            />
          </td>
        )}
      </tr>
    </tbody>
  )
}
