import * as React from 'react'
import downloadIcon from './../../icons/circle-arrow-down.svg'
import './styles.scss'

export type GenericDownloadButtonProps = {
  onClick: () => void
}

const ROOT_CLASS = 'generic-download-button'

export const GenericDownloadButton = (props: GenericDownloadButtonProps) => {
  return (
    <button className={ROOT_CLASS} onClick={props.onClick} aria-label="Download">
      <img className={`${ROOT_CLASS}__icon`} src={downloadIcon} />
      <div className={`${ROOT_CLASS}__text`}>Download</div>
    </button>
  )
}
