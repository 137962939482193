import * as React from 'react'
import {
  AllPromotionsResponse,
  PromotionsDateRangeReportResponse,
  PromotionsDateRangeReportProps,
} from '../../../api/promotions-fetcher/constants'
import { PromotionsDateRangeReportFilterUI } from '../promotions-date-range-report-filter/promotions-date-range-report-filter-ui'
import './styles.scss'

type PromotionsDateRangeReportUIProps = {
  promotions?: AllPromotionsResponse
  reportLoading: boolean
  reportError: any
  requestReportGeneration: (props: PromotionsDateRangeReportProps) => void
}

const ROOT_CLASS = 'promotions-date-range-report-ui'
export const PromotionsDateRangeReportUI = (
  props: PromotionsDateRangeReportUIProps
) => {
  return (
    <section className={ROOT_CLASS}>
      <PromotionsDateRangeReportFilterUI {...props} />
    </section>
  )
}
