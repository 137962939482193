import moment from "moment-timezone";
import config from "../../../config";
import { makeHttpCall } from "../../../services/MakeHttpCall";
import { TicketScanningActivityDateInterface } from "./interface";

let formik: any;
let playerStore: any;
let history: any;
let pageMessageDefault: any;
let setPageMessageType: any;
let setRequestPerformed: any;
let setMCAPlayerData: any;
let setCurrentPage: any;
let MCAPlayerDataReset: any;
let playerId:any;
let pageMessage:any;
let setNetworkError:any;
let setIsData:any;
export const setVariables = (setIsDataObj:any,id:any,MCAPlayerDataObjReset: any, formikObj: any, playerStoreObject: any, historyObject: any,
    pageMessageDefaultObj: any, setPageMessageObj: any, setRequestPerformedObj: any, setMCAPlayerDataObj: any,
    setCurrentPageObj: any, pageMessageObj:any, setNetworkErrorObj:any) => {
    formik = formikObj;
    playerStore = playerStoreObject;
    history = historyObject;
    pageMessageDefault = pageMessageDefaultObj;
    setPageMessageType = setPageMessageObj;
    setRequestPerformed = setRequestPerformedObj;
    setMCAPlayerData = setMCAPlayerDataObj;
    setCurrentPage = setCurrentPageObj;
    playerId = id;
    MCAPlayerDataReset = MCAPlayerDataObjReset;
    pageMessage = pageMessageObj;
    setNetworkError = setNetworkErrorObj;
    setIsData = setIsDataObj;
}

export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}

export const validate = (values: TicketScanningActivityDateInterface | any) => {
    const errors = {};
    const startDate = values.startDate;
    const endDate = values.endDate;

    let isValidDate = Date.parse(startDate);
    let isValidEndDate = Date.parse(endDate);
    if (isNaN(isValidDate)) {
        Object.assign(errors, { startDate: 'StartDate is not a valid date' });
    } else if (isNaN(isValidEndDate)) {
        // console.log("isValidEndDate :" + isValidEndDate)
        Object.assign(errors, { endDate: 'EndDate is not a valid date' });
    }else if (endDate < startDate) {
        Object.assign(errors, { startDate: 'The startDate must be smaller than EndDate' });
    }
    const startDateForDiff = new Date(startDate);
    const endDateForDiff = new Date(endDate);
    const timeDifference = Math.abs(endDateForDiff.getTime() - startDateForDiff.getTime())
    let differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
    // console.log("differentDays : " + differenceInDays)
    if (differenceInDays > 31) {
        Object.assign(errors, { startDate: 'The startDate and EndDate must be less than 31 days' });
    }
    if (values.endDate > new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]) {
        Object.assign(errors, { endDate: `The end Date is out of range current date  is ${new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]}` });
    }
    if (values.startDate > new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]) {
        Object.assign(errors, { outOfRangeDate: `The end Date is out of range current date  is ${new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]}` });
    }
    return errors;
}

export const onReset = async (values: TicketScanningActivityDateInterface) => {
    formik.setValues(values, true);
   
    setMCAPlayerData(MCAPlayerDataReset);
    setCurrentPage(0);
    // console.log("inside reset", "reset done");
}


let oldStartDate:any = undefined;
let oldEndtDate:any = undefined;
export const applyFilters = async (SABasePlayerData: any[], startDate: string, endDate: string) => {
    let result:any = [];
    setCurrentPage(0);   
    if( SABasePlayerData[0]?.gameId !==  playerId )
    {    
         result =   SABasePlayerData.filter(row => row.requestTimestamp !== null && (row.requestTimestamp.split("T")[0] >= startDate.split("T")[0] && row.requestTimestamp.split("T")[0] <= endDate.split("T")[0]) ); 
    } 
    if( oldStartDate === undefined )
    {
        oldStartDate = startDate;
        oldEndtDate = endDate; 
        
        return [];
    }
    

    if(result.length > 0 &&  ( oldStartDate > startDate  ||  oldEndtDate < endDate ) )
    {          
            oldStartDate = startDate;
            oldEndtDate = endDate
            return [];
    }
    return result;
    
}
export const onSubmit = async (values: TicketScanningActivityDateInterface) => {
    setCurrentPage(0);
    if(pageMessage.messageType === "ERROR")
        setPageMessageType({message:"", messageType:""}); 
    formik.setValues(values, true);
    const result: any = await applyFilters(MCAPlayerDataReset.slice(), new Date(values.startDate).toISOString(), new Date(values.endDate).toISOString());
    
    if (result.length > 0) {
        setRequestPerformed(false);
        setMCAPlayerData(result);
        return;
    }



    const data = {
        "startDate": new Date(values.startDate).toISOString(),
        "endDate": moment(values.endDate).endOf('day').format('YYYY-MM-DD:HH:mm:ss'),
    };
    try {
        const options =
        {
            method: 'post',
            url: `${config.SERVER_BASE_URL}/v1/player/ticket-scanner-events/${playerStore.selectedPlayerDetails.account.id}`,
            data: data
        }
        const response = await makeHttpCall(options);
        setIsData(true);
        setNetworkError(false);
        setRequestPerformed(false);
        setMCAPlayerData(response.message.data);
    } catch (e) {
        setIsData(true);
        setRequestPerformed(false);
        const er:any = e;
        if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
            if(value === "Network")
                return value;
        }) !== undefined)
        {
            setNetworkError(true);
           setPageMessageType({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        }else
        {
            setNetworkError(false);  
            setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
        }
        
    }
}
export const onSubmitRefresh = async (values: TicketScanningActivityDateInterface, id: any, setMCALoaded: any, setIsData:any,pageMessage:any,setNetworkError:any) => {
    setCurrentPage(0);
    if(pageMessage.messageType === "ERROR")
        setPageMessageType({message:"", messageType:""}); 
    const data = {
        "startDate": new Date(values.startDate).toISOString(),
        "endDate": moment(values.endDate).endOf('day').format('YYYY-MM-DD:HH:mm:ss'),
    };
    try {
        const options =
        {
            method: 'post',
            url: `${config.SERVER_BASE_URL}/v1/player/ticket-scanner-events/${id}`,
            data: data
        }
        const response = await makeHttpCall(options);
        setNetworkError(false);
        setIsData(true);
        setMCALoaded(true);
        setMCAPlayerData(response.message.data);
        MCAPlayerDataReset = response.message.data.slice();
        playerStore.ticketScannerEvents = response.message.data.slice();
    } catch (e) {
        setIsData(true);
        setMCALoaded(true);
        const er:any = e;
        if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
            if(value === "Network")
                return value;
        }) !== undefined)
        {
            setNetworkError(true);
           setPageMessageType({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        }else
        {
          setNetworkError(false);
          setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
        }
    }
}

export const refreshComponent = (response: any) => {
    if (!(response.error === undefined)) {
        setPageMessageType({ message: "Something went wrong. Please try again.", messageType: "ERROR" });
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}