import React, {useState} from "react";
import styles from './css/styles.module.css';
import {getPageData,} from "./functions";
import {PaginationCommonComponent} from '../../CommonComponents/PaginationCommonComponent'
import moment from "moment-timezone";
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";
import {PaperCheckFileDetailsSearchResult} from "../../../pages/FinancePages/PaperCheckFileDetailsPage/interfaces";

export const PaperCheckFileDetailsTable = ({paperCheckFileTransactionRowsData}: any) => {

    const [currentPage, setCurrentPage] = useState<number>(0);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;
    }

    return (
        <>
            <div className={styles['KYCQUEUE-table']}>
                <div className={styles['KYCQUEUE-table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${paperCheckFileTransactionRowsData.length <= 0 ? 0 : (currentPage * 10) + 1}-
                         ${normalizePage(paperCheckFileTransactionRowsData.length, currentPage)} of ${paperCheckFileTransactionRowsData.length} results`}</div>

                </div>
                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date Submitted</div>
                    <div tabIndex={0}>Time</div>
                    <div tabIndex={0}>Claim ID</div>
                    <div tabIndex={0}>Transaction ID</div>
                    <div tabIndex={0}>Net Amount</div>
                    <div tabIndex={0}>Status</div>
                    <div tabIndex={0}>Paper Check ID</div>
                </div>
                {console.log('PaperCheckFileTransactionRowsData', paperCheckFileTransactionRowsData)}
                <div className={styles['KYCQUEUE-table-data-body']}>

                    {paperCheckFileTransactionRowsData.length > 0 && getPageData(paperCheckFileTransactionRowsData, currentPage).map((e: PaperCheckFileDetailsSearchResult, i: number) =>
                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)')}}
                             className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.timestamp, "America/New_York").format('MM-DD-YYYY')}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.timestamp, "America/New_York").format('HH:mm')}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e?.claimid}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e?.transactionid}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{normilizeAmount(e?.amount)}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e?.achpaymenttransactionstatus}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e?.papercheckid}</div>
                        </div>
                    )}
                </div>
                <div className={styles['KYCQUEUE-table-data-footer']}>
                    <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((paperCheckFileTransactionRowsData.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={paperCheckFileTransactionRowsData} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}
                </div>

            </div>


        </>

    );
}
