import React from "react";
import moment from "moment-timezone";
import {timeOfDayFormat} from "../../../constants";
import styles from './css/styles.module.css';
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";

export const ACHBatchDetailHeader = ({batchDetailSearchResponse}: any) => {
   
    return (
        <>
            <div
                className={`${styles["page-container-body-left-label-and-input-box-container"]}`}>
                <table style={{width:"76%"}}>
                    <thead>
                    <tr>
                        <td>File Creation Date</td>
                        <td>File Creation Time</td>
                        <td>Date Transmitted</td>
                        <td>Time Transmitted</td>
                        <td>Effective Entry Date</td>
                        <td>Status</td>
                        <td>Amount</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td >{batchDetailSearchResponse[0]?.filecreationdatetime === undefined ? "": moment.tz(batchDetailSearchResponse[0].filecreationdatetime, "America/New_York").format('MM-DD-YYYY')}</td>
                        <td >{batchDetailSearchResponse[0]?.filecreationdatetime === undefined ? "": moment.tz(batchDetailSearchResponse[0].filecreationdatetime, "America/New_York").format(timeOfDayFormat)}</td>
                        <td >{batchDetailSearchResponse[0]?.filesubmissiondatetime === undefined ? "" : moment.tz(batchDetailSearchResponse[0].filesubmissiondatetime, "America/New_York").format('MM-DD-YYYY')}</td>
                        <td >{batchDetailSearchResponse[0]?.filesubmissiondatetime === undefined ? "": moment.tz(batchDetailSearchResponse[0].filesubmissiondatetime, "America/New_York").format(timeOfDayFormat)}</td>
                        <td >{batchDetailSearchResponse[0]?.effectiveentrydate === undefined ? "": moment(batchDetailSearchResponse[0].effectiveentrydate).format('MM-DD-YYYY')}</td>
                        <td >{batchDetailSearchResponse[0]?.status === undefined ? "" : batchDetailSearchResponse[0].status}</td>
                        <td >{batchDetailSearchResponse[0]?.amount === undefined ? "" : normilizeAmount(batchDetailSearchResponse[0].amount)}</td>
                        {/*<td>${[batchHeaderData[0].amount.toString().slice(0, batchHeaderData[0].amount.toString().length - 2), '.',*/}
                        {/*    batchHeaderData[0].amount.toString().slice(batchHeaderData[0].amount.toString().length - 2)].join('')}</td>*/}
                    </tr>
                    </tbody>

                </table>
            </div>
        </>
    );
}