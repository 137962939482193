import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { KEY_CODES } from "../../../../constants.js";
import { keyPressedType } from "../../../../services/Commons";
import { annuitiesRoutePath } from "../../../../config.js";
import styles from "./css/styles.module.css";
import { PaymentSearchCmpt } from "../../../../components/AnnuitiesComponents/TimePaymentAuthorization/PaymentSearchCmpt/";

export const PaymentSearchPage: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles["page-container"]}>
        {/* Back Btn tsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                history.push(`${annuitiesRoutePath}/time-payment-authorization`);
              }
            }}
            onClick={() => {
              history.push(`${annuitiesRoutePath}/time-payment-authorization`);
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles["back-btn-custom"]}
            />
            <div className={styles["back-btn-text"]}>Back Time Payment Authorization</div>
          </button>
        </div>
        {/* Header */}
        <div className={styles["cmpnt-container"]}>
          <div className={`${styles.title_header}`}>
            <div className={`${styles.title_text}`}>Payment Search</div>
          </div>
          {/* Main Component */}
          <div className={styles["cmpnt-container"]}>
            <PaymentSearchCmpt />
          </div>
        </div>
      </div>
    </>
  );
};
