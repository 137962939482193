import React from "react";
import './iconStyle.css';

const ResetPasswordIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className='reset-password-icon' height='24px' width='24px' viewBox="0 0 1151.04 1333.33"  fill={props.color || '#FFFFFF'} onClick={props.onclick}>
            <path d="M468.17 676.45L351.45 881.88l21.82 81.44 80.86-21.67-16.4-61.22 67-17.95-17.95-67 56.33-3.68 2.35-65.35c34.78 11.78 73.69 13.71 112.28 3.37 101.08-27.09 161.89-128.1 135.76-225.57-26.12-97.48-129.28-154.56-230.36-127.48-101.07 27.08-161.87 128.09-135.75 225.58 7.65 28.54 21.91 53.6 40.77 74.11zm217.51 611.85c10.15 10.67 9.72 27.54-.94 37.69-10.67 10.15-27.54 9.72-37.69-.94L561.58 1235c-9.74-10.23-9.74-26.19-.3-36.43l85.45-93.06c9.98-10.84 26.85-11.54 37.7-1.57 10.84 9.98 11.54 26.85 1.57 37.69l-35.98 39.17c169.94-30.38 289.05-113.89 361.31-220.96 50.61-74.99 78.46-161.65 84.88-249.75 6.43-88.46-8.65-178.24-43.94-259.09-56.17-128.72-163.63-234.93-316.96-276.98-14.19-3.86-22.55-18.5-18.69-32.68 3.86-14.19 18.5-22.55 32.68-18.69 170.33 46.71 289.6 164.44 351.83 307.04 38.77 88.85 55.37 187.31 48.32 284.15-7.07 97.19-37.87 192.91-93.85 275.85-83.04 123.03-221.06 217.76-418.62 247.28l48.69 51.31zM467.29 45.04c-10.15-10.67-9.72-27.54.94-37.69 10.67-10.15 27.55-9.72 37.69.94l85.46 90.05c9.74 10.23 9.74 26.19.3 36.43l-85.45 93.06c-9.98 10.84-26.85 11.54-37.69 1.57-10.84-9.98-11.54-26.85-1.57-37.69l38.66-42.09c-141.74 16.98-259.6 94.15-339.42 200.03-54.87 72.79-91.77 158.98-106.15 248.46-14.33 89.16-6.3 181.62 28.58 267.29 50.53 124.06 157.93 234.28 336.1 300.24 13.84 5.07 20.94 20.41 15.87 34.25-5.07 13.84-20.41 20.94-34.24 15.87-193.9-71.79-311.36-193.16-367.22-330.31C.5 790.56-8.41 688.28 7.43 589.76 23.21 491.55 63.6 397.1 123.6 317.5c90.96-120.66 227.04-207.57 391.09-222.49l-47.41-49.96zm154.3 444.65c-18.04 4.83-28.76 23.39-23.92 41.44 4.83 18.05 23.4 28.76 41.44 23.93 18.05-4.84 28.76-23.39 23.93-41.44-4.83-18.05-23.39-28.76-41.44-23.93z"/>
        </svg>
    )
}

export default ResetPasswordIcon;
