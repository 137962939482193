import React, { useEffect, useState } from "react";
import styles from "./css/styles.module.css";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import { getPlayersDetailsData } from "../../../services/StoresObject";

import {
  getData,

} from "./functions";

import {
  PageMessageCommonComponent,
  pageMessageDefault,
} from "../../../components/CommonComponents/PageMessageCommonComponent";

import { ClaimIDAndNetPaymentComponent } from "../../../components/ClaimsComponents/ClaimIDAndNetPaymentComponent";
import { ClaimOwnerInformationComponent } from "../../../components/ClaimsComponents/ClaimOwnerInformationComponent";
import { TicketInformationComponent } from "../../../components/ClaimsComponents/TicketInformationComponent";
import { WinningInformationComponent } from "../../../components/ClaimsComponents/WinningInformationComponent";
import { MobileClaimStatusTableComponent } from "../../../components/ClaimsComponents/MobileClaimStatusTableComponent";
import { BackToMobileClaimActivity } from "../../../components/ClaimsComponents/BackButtonComponent";
import { MobileClaimDocumentsComponent } from "../../../components/ClaimsComponents/MobileClaimDocumentsComponent";
import { mobileClaimDetailsPageInterface } from "./interfaces";
import { keyPressedType } from "../../../services/Commons";
import { CircularProgress } from "@material-ui/core";
import { claimHistory, claimReferenceNumber, claimTransaction, w2g } from "../../../services/StoresObject/Claims";






const timerArray: any = [];

export const MobileClaimDetailsPage = ({ id, claimsId, storesObject }: any) => {


 
  const _mobileClaimDetailsPage: mobileClaimDetailsPageInterface = {

    claimReferenceNumber : JSON.parse(JSON.stringify(claimReferenceNumber)),
    claimHistory : JSON.parse(JSON.stringify(claimHistory)),
    claimTransaction : JSON.parse(JSON.stringify(claimTransaction)),
    w2g : JSON.parse(JSON.stringify(w2g)),
    player: storesObject.playerStore.selectedPlayerDetails.account.email === "" ? getPlayersDetailsData : storesObject.playerStore.selectedPlayerDetails,
    achTraceNumber:"",
  }
  const viewPaymentInfoData:any = {
    
    toogle:false,
    accountType:"",
    accountNumber:"",
    routingNumber:"",
    traceID:"",

  }
 
 

 

  
  const [ pageMessage , setPageMessage ] = useState<any>(pageMessageDefault);
  const [ requestPerformed , setRequestPerformed ] = useState<any>(false);
  const [ mobileClaimDetailsPage , setMobileClaimDetailsPage ] = useState<mobileClaimDetailsPageInterface>(_mobileClaimDetailsPage);
  const [ isData , setIsData ] = useState<any>(false);
  const [ viewPaymentInfo , setViewPaymentInfo ] = useState<any>(viewPaymentInfoData);
  
  const sleep = (ms:any)=>{
   
    const timer = setTimeout((timerArray:any)=>{     
      timerArray.map((e:any)=> clearTimeout(e));
      setIsData(false);
      setRequestPerformed(false);

    },ms,timerArray)
    timerArray.push(timer)

  }

  useEffect(() => {

   
    
    if(  mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId === "" && !isData && requestPerformed === false)
       getData( id, claimsId, setPageMessage, pageMessage, setMobileClaimDetailsPage , mobileClaimDetailsPage , setIsData , storesObject);
     
    if ( mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId === "" && isData === true) {
            
            setRequestPerformed(true);
            sleep(5000);
    }   
    document.getElementById("manually-approve-kyc-container")?.focus();
  });

  // if (eventEmitterInitialize === undefined)
  //   eventEmitter.addListener("refresh-data-ready", refreshComponent);

  return (
    <>
       {(( mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId === undefined || mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId === "") && (pageMessage.message === "" || !isData) ) && <div style={{ position: 'fixed', top: 0, left: 0, width: "100vw", height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white',zIndex:1000 }}>
        <CircularProgress size="8em" color='inherit' thickness={2} />
      </div>}
      <div
        onClick={(e: any) => {
          pageMessage.messageType !== "" &&
            setPageMessage({ message: "", messageType: "" });
        }}
        id="page-container"
        className={styles["page-container"]}
      >
        {pageMessage.message != "" && document.scrollingElement?.scrollTo(0, 0)}
        {pageMessage.message != "" && (
          <PageMessageCommonComponent
            message={pageMessage.message}
            messageType={pageMessage.messageType}
          />
        )}
        <div className={styles["page-container-body-container"]}>
          <div className={styles["back-to-search-container"]}>
            <BackToMobileClaimActivity id={id} />
          </div>
          <div className={styles["page-container-body"]}>

            <div className={styles["page-container-body-left"]}>
              <div className={styles["claimsIDAndNetPayment-container"]}>
                <ClaimIDAndNetPaymentComponent netPayment={mobileClaimDetailsPage.claimReferenceNumber.claim.netPaymentAmountInCents} claimID={claimsId} />
              </div>
              <div className={styles["claim-owner-information-container"]}>               
                <ClaimOwnerInformationComponent mobileClaimDetailsPage={mobileClaimDetailsPage} />
              </div>
              <div className={styles["ticket-information-container"]}>
                <TicketInformationComponent mobileClaimDetailsPage={mobileClaimDetailsPage} />
              </div>
              <div className={styles["winning-information-container"]}>
                <WinningInformationComponent mobileClaimDetailsPage={mobileClaimDetailsPage} />
              </div>
              <div className={styles["mobile-claim-documents-container"]}>
                <MobileClaimDocumentsComponent setRequestPerformed={setRequestPerformed}  mobileClaimDetailsPage={mobileClaimDetailsPage} requestPerformed={requestPerformed}  setPageMessage={setPageMessage} pageMessage={pageMessage} />
              </div>
            </div>
            <div className={styles["page-container-body-right"]}>
              <div className={styles["mobile-claim-status-table-container"]}>
                <MobileClaimStatusTableComponent id={id} claimsId={claimsId} viewPaymentInfo={viewPaymentInfo} setViewPaymentInfo={setViewPaymentInfo} mobileClaimDetailsPage={ mobileClaimDetailsPage } />
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewPaymentInfo.toogle && 
      
      <div className={stylesMessageBox['window-container']}>
      <div tabIndex={0} id='manually-approve-kyc-container' className={stylesMessageBox['manually-approve-kyc-container']}>
       
        <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-text']}>Payment Info</div>
        <div  className={stylesMessageBox['manually-approve-kyc-account-info-container']}>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-label']}>Account Type</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{ viewPaymentInfo.accountType}</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-label']}>Account Number</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{ viewPaymentInfo.accountNumber}</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-label']}>Routing Number</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{ viewPaymentInfo.routingNumber}</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-label']}>TraceID</div>
          <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{viewPaymentInfo.traceID}</div>          
        </div>
        <div role='button'
        tabIndex={0}
         onKeyPress={(e: any) => {
          if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
            viewPaymentInfo.toogle = false;
            setViewPaymentInfo({...viewPaymentInfo})
          }
        }
        }   
          onClick={() =>  { viewPaymentInfo.toogle = false; setViewPaymentInfo({...viewPaymentInfo});}}
          className={stylesMessageBox['manually-approve-kyc-button-yes']} >
          <div className={stylesMessageBox['manually-approve-kyc-yes-text']}>Close</div>
        </div>
        
      </div>
    </div>}
    </>
  );
};

