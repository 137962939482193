import { PromotionStatus } from '../../../api/promotions-fetcher/constants'
import { CustomDropdownOption } from '../../../components/CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import {
  PromotionCampaignTableRow,
  sortByPromotionName,
  sortByPromotionId,
} from './helpers'

export enum SORT_OPTION_KEYS {
  ALL = 'all',
  PROMO_ID_ASC = 'promo_id_asc',
  PROMO_ID_DESC = 'promo_id_desc',
  NAME_ABC = 'name_abc',
  NAME_ZXY = 'name_zxy',
}

export const SORT_FUNCTIONS: Record<
  SORT_OPTION_KEYS,
  (a: PromotionCampaignTableRow, b: PromotionCampaignTableRow) => number
> = {
  [SORT_OPTION_KEYS.ALL]: (_a, _b) => 0,
  [SORT_OPTION_KEYS.NAME_ABC]: sortByPromotionName('desc'),
  [SORT_OPTION_KEYS.PROMO_ID_ASC]: sortByPromotionId('asc'),
  [SORT_OPTION_KEYS.PROMO_ID_DESC]: sortByPromotionId('desc'),
  [SORT_OPTION_KEYS.NAME_ZXY]: sortByPromotionName('asc'),
}

export const SORT_OPTIONS: CustomDropdownOption[] = [
  {
    key: SORT_OPTION_KEYS.ALL,
    value: SORT_OPTION_KEYS.ALL,
    display: 'All',
  },
  {
    key: SORT_OPTION_KEYS.PROMO_ID_ASC,
    value: SORT_OPTION_KEYS.PROMO_ID_ASC,
    display: 'Sort by Ascending (Promo ID)',
  },
  {
    key: SORT_OPTION_KEYS.PROMO_ID_DESC,
    value: SORT_OPTION_KEYS.PROMO_ID_DESC,
    display: 'Sort by Descending (Promo ID)',
  },
  {
    key: SORT_OPTION_KEYS.NAME_ABC,
    value: SORT_OPTION_KEYS.NAME_ABC,
    display: 'Sort ABC',
  },
  {
    key: SORT_OPTION_KEYS.NAME_ZXY,
    value: SORT_OPTION_KEYS.NAME_ZXY,
    display: 'Sort ZXY',
  },
]

export const STATUS_FILTERS = [
  PromotionStatus.ACTIVE,
  PromotionStatus.COMPLETE,
  PromotionStatus.PENDING,
]
