import * as React from 'react'
import { ErrorMessageFieldCommonComponent } from '../../CommonComponents/ErrorMessageFieldCommonComponent'
import './styles.scss'

export type SideBarDateAndTimeInputProps = {
  label: string
  name: string
  onChange: (e: Date | any) => void
  onBlur: (e: any) => void
  value: string
  error: string | undefined
  touched: boolean | undefined
  minDate: string
  disabled: boolean
}

const ROOT_CLASS = 'side-bar-date-and-time-input'

export const SideBarDateAndTimeInput = (props: SideBarDateAndTimeInputProps) => {
  const onChangeDate = (e: { target: { value: string | number | Date } }) => {
    props.onChange(e)
  }

  return (
    <div className={ROOT_CLASS}>
      <label className={`${ROOT_CLASS}-label`} htmlFor={props.name}>
        {props.label}
      </label>
      <input
        id={props.name}
        className={`${ROOT_CLASS}-content`}
        type="datetime-local"
        min={props.minDate}
        name={props.name}
        value={props.value}
        onChange={onChangeDate}
        onBlur={(e) => props.onBlur(e)}
        disabled={props.disabled}
        required
      />
      {props.error && props.touched && <ErrorMessageFieldCommonComponent errorMessage={props.error} />}
    </div>
  )
}
