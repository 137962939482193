import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';
import {getRejectedPaymentsQueue} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {RejectedPaymentsQueueResponseInterface} from "./interface";
import {useHistory} from "react-router-dom";
import {
    PageMessageCommonComponent,
    pageMessageDefault
} from "../../../components/CommonComponents/PageMessageCommonComponent";
import {RejectedPaymentsQueueTableComponent} from "../../../components/ActionsComponent/RejectedPaymentsQueueTableComponent";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {actionsRoutePath} from "../../../config";
import {keyPressedType} from "../../../services/Commons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const timerArray: any = [];
export const RejectedPaymentsQueuePage = ({storesObject}: any) => {

    const playersStore = storesObject.playerStore;
    const defaultData: RejectedPaymentsQueueResponseInterface[] = [];

    const [isData, setIsData] = useState(false);
    const [RejectedPaymentsQueueAPIResponse, setRejectedPaymentsQueueAPIResponse] = useState<RejectedPaymentsQueueResponseInterface[]>(defaultData);
    const [requestPerformed, setRequestPerformed] = useState<any>(false);
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [networkError, setNetworkError] = useState<boolean>(false);
    const history: any = useHistory();

    localStorage.setItem('callingPage', history.location.pathname);

    const sleep = (ms: any) => {

        const timer = setTimeout((timerArray: any) => {
            timerArray.map((e: any) => clearTimeout(e));
            setRequestPerformed(false);

        }, ms, timerArray)
        timerArray.push(timer)

    }

    useEffect(() => {

        document.getElementById('manually-approve-kyc-container')?.focus();
        if (RejectedPaymentsQueueAPIResponse.length <= 0 && !isData && requestPerformed === false) {
            setNetworkError(false);
            setIsData(false);
            getRejectedPaymentsQueue(setRejectedPaymentsQueueAPIResponse, setIsData, setPageMessage, pageMessage, setNetworkError);
            document.getElementById("page-container")?.focus();
        }

        if (networkError && isData) {
            setRequestPerformed(true);
            setIsData(false);
            setNetworkError(false);
            sleep(5000);

        }
    });


    return (
        <>
            {((!isData && pageMessage.message === "") || (networkError && !isData && pageMessage.message === "")) &&
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="8em" color='inherit' thickness={2}/>
            </div>}
            <div onClick={(e: any) => {
                pageMessage.message !== "" && setPageMessage({message: "", messageType: ""})
            }} id="page-container" className={styles['page-container']}>
                <div className={styles['back-to-actions-home-container']}>
                    <div tabIndex={0} role="button" aria-pressed={"false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 history.push(`${actionsRoutePath}/home`);
                             }
                         }
                         }
                         onClick={() => {
                             history.push(`${actionsRoutePath}/home`);
                         }}
                         className={styles['back-to-actions-home-inner-container']}>
                        <i title='left arrow icon' className={styles['back-to-actions-home-icon']}>
                            <FontAwesomeIcon size="2x" arial-label='left arrow icon.' icon={faAngleLeft as IconProp}/>
                        </i>
                        <div className={styles['back-to-actions-home-inner-container-text']}>Back
                        </div>
                    </div>
                </div>
                <div className={styles['top-ribbon']}>
                    <div className={styles['top-ribbon-text']} tabIndex={0}>Rejected Payments Queue</div>
                </div>
                {pageMessage.message !== "" &&
                <PageMessageCommonComponent message={pageMessage.message} messageType={pageMessage.messageType}/>}
                <div className={styles['page-container-body-container']}>
                    <div className={styles['page-container-body']}>
                        <RejectedPaymentsQueueTableComponent
                            RejectedPaymentsQueueData={RejectedPaymentsQueueAPIResponse}/>
                    </div>
                </div>
            </div>
        </>

    );
}

