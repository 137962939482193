import React from "react";
import styles from './css/styles.module.css';
import InputMask from 'react-input-mask';
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";

export const UpdatePermitOrganizationDetailsComponent = ({formik, setFieldValue}: any) => {

    return (
        <>
            <form className={styles['update-permit-org-details-component-container']}>
                <div tabIndex={0} className={styles['update-permit-org-details-component-title']}>Organization</div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>Name of Organization*
                    </div>
                    <input
                        id="orgName"
                        name="orgName"
                        className={styles["update-permit-org-details-component-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.orgName}
                    />
                    {(formik.touched.orgName && formik.errors.orgName) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.orgName}/> : null}
                </div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>Org. Telephone*</div>
                    <InputMask
                        autoComplete={"text"}
                        id="phone"
                        name="phone"
                        className={styles["update-permit-org-details-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.phone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.phone && formik.errors.phone) ?
                        <ErrorMessageFieldCommonComponent
                            orgPhone={formik.errors.phone}/> : null}
                </div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>Street Address*
                    </div>
                    <input
                        autoComplete={"off"}
                        id="address.streetAddress"
                        name="address.streetAddress"
                        value={formik.values.address.streetAddress}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["update-permit-org-details-component-input-box"]}`}
                        type="text"/>
                    {(formik.touched.address?.streetAddress && formik.errors.address?.streetAddress) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.address?.streetAddress}/> : null}
                </div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>Email*</div>
                    <input
                        autoComplete={"text"}
                        id="email"
                        name="email"
                        className={styles["update-permit-org-details-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.email && formik.errors.email) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress10={formik.errors.email}/> : null}
                </div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>City/Town*
                    </div>
                    <input
                        autoComplete={"text"}
                        id="address.city"
                        name="address.city"
                        className={styles["update-permit-org-details-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.address.city}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.address?.city && formik.errors.address?.city) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress1={formik.errors.address?.city}/> : null}
                </div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>City Code*
                    </div>
                    <input
                        id="cityCode"
                        name="cityCode"
                        onChange={(e: any) => setFieldValue(e)}
                        className={styles["update-permit-org-details-component-input-box"]}
                        value={formik.values.cityCode}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder={'Ex. 000'}
                        maxLength={3}
                    />
                    {(formik.touched.cityCode && formik.errors.cityCode) ?
                        <ErrorMessageFieldCommonComponent
                            orgFID={formik.errors.cityCode}/> : null}
                </div>

                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>Zipcode*</div>
                    <input
                        autoComplete={"text"}
                        id="address.zip"
                        name="address.zip"
                        value={formik.values.address.zip}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["update-permit-org-details-component-input-box"]}`}
                        type="text"
                        placeholder={'Ex. 12345'}
                        maxLength={5}
                    />
                    {(formik.touched.address?.zip && formik.errors.address?.zip) ?
                        <ErrorMessageFieldCommonComponent
                            city10={formik.errors.address?.zip}/> : null}
                </div>
                <div className={styles["update-permit-org-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-org-details-component-label"]}>Org. Type*</div>
                    <input
                        id="orgType"
                        name="orgType"
                        value={formik.values.orgType}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["update-permit-org-details-component-input-box"]}`}
                        type="text"
                        placeholder={'Ex. 000'}
                        maxLength={3}
                    />
                    {(formik.touched.orgType && formik.errors.orgType) ?
                        <ErrorMessageFieldCommonComponent
                            zip10={formik.errors.orgType}/> : null}
                </div>
            </form>
        </>

    );
}