import React, { useState } from 'react'
import { GetPendingReissuePaymentsResponse, PendingReissuePayment, PendingReissuePaymentSource } from '../../../../api/pending-reissue-payments-fetcher/api-types'
import './styles.scss'
import { ReissueQueueAggregateTotalsUI } from '../reissue-queue-aggregate-totals/reissue-queue-aggregate-totals-ui'
import { SearchResultsTableColumnMap, SearchResultsTableUI } from '../../../CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { MaskedSsnUI } from '../../../CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import { formatCentsToDollars } from '../../../../util'
import { OptionallyRenderSourceGuideCTA } from '../../SourceGuideButtonComponent'
import { generateReissueQueueCTA } from '../reissue-queue-row-cta/generate-reissue-queue-row-cta'
import { ReissueQueueSourceGuideUI } from '../reissue-queue-source-guide/reissue-queue-source-guide-ui'

const ROOT_CLASS = 'reissue-queue'

export type ReissueQueueDisplayRow = Omit<PendingReissuePayment,
  'netPaymentInCents' | 'source' | 'tin'
> & {
  netPaymentDisplay: string
  sourceDisplay: string
  tin: React.JSX.Element
}

const REISSUE_QUEUE_DISPLAY_SOURCE_MAP: {
  [key in PendingReissuePaymentSource]: string
} = {
  ANNUITIES: 'AN',
  PROMOTIONS: 'PR',
  SECOND_CHANCE: 'SC',
  SEASON_TICKETS: 'ST',
  ES: 'CC', // ES -> "Claim Center" -> CC
  OTHER: 'OTR'
}

const REISSUE_QUEUE_COLUMN_MAP: SearchResultsTableColumnMap<ReissueQueueDisplayRow> = [
  { dataKey: 'payeeName', label: 'Payee Name', width: 20 },
  { dataKey: 'tin', label: 'TIN', width: 15 },
  { dataKey: 'netPaymentDisplay', label: 'Net Payment', width: 15},
  { dataKey: 'annuityAccountId', label: 'Annuity ID', width: 15},
  { dataKey: 'sourceDisplay', label: 'Source', width: 5} // leave 30% for the two CTA buttons?
]

const mapPendingReissuesToDisplayRows = (
  pendingReissues: PendingReissuePayment[]
): ReissueQueueDisplayRow[] => {
  return pendingReissues.map((pendingReissue) => {
    return {
      id: pendingReissue.id,
      voidedPaymentId: pendingReissue.voidedPaymentId,
      payeeName: pendingReissue.payeeName,
      tin: <MaskedSsnUI ssn={pendingReissue.tin} />,
      annuityAccountId: pendingReissue.annuityAccountId,
      sourceDisplay:
        REISSUE_QUEUE_DISPLAY_SOURCE_MAP[pendingReissue.source],
      netPaymentDisplay: formatCentsToDollars(
        pendingReissue.netPaymentInCents
      )
    }
  })
}

export type ReissueQueueUIProps = {
  pendingReissuesData: GetPendingReissuePaymentsResponse
}

export const ReissueQueueUI = (
  props: ReissueQueueUIProps
) => {
  const [showPaymentSourceGuide, setPaymentSourceGuide] =
    useState<boolean>(false)

  return (<div className={ROOT_CLASS}>
    <div className={`${ROOT_CLASS}_header`}>
      <ReissueQueueAggregateTotalsUI
        {...props.pendingReissuesData.aggregates}
      />
    </div>
    <hr className={`${ROOT_CLASS}_horizontal-line`} />
    <SearchResultsTableUI
      rowData={mapPendingReissuesToDisplayRows(
        props.pendingReissuesData.pendingReissuePayments
      )}
      columnMap={REISSUE_QUEUE_COLUMN_MAP}
      generateRowCTA={generateReissueQueueCTA}
      additionalResults={false}
      noDataMessage='There are no pending checks at this time'
    >
      <OptionallyRenderSourceGuideCTA
        setPaymentSourceGuide={setPaymentSourceGuide}
      />
    </SearchResultsTableUI>
    {showPaymentSourceGuide && (
        <ReissueQueueSourceGuideUI
          setPaymentSourceGuide={setPaymentSourceGuide}
        />
      )}
  </div>)
}