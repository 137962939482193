import React from "react";
import {useHistory} from "react-router-dom";
import {inject, observer} from "mobx-react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {pulltabRoutePath} from "../../../../../config.js";
import moment from "moment";
import {formatCentsToDollars} from "../../../../../util";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";

export const PullTabOrderOverviewHistorySummaryComponent = inject("charitableGamingStore")(observer(({
                                                                                                         licenseNumber,
                                                                                                         charitableGamingStore
                                                                                                     }: any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const {pullTabOrderOverviewHistory} = charitableGamingStore;

    const currentURL = window.location.href;


    return (<>

        <div className={`${styles['order-overview-history-summary-container']}`}>
            <div className={`${styles['order-overview-history-summary-container-title-bar']}`}>
                <div className={`${styles['order-overview-history-summary-container-text-container']}`}>
                    <div className={`${styles['order-overview-history-summary-container-title']}`}>Order Overview
                        History
                    </div>
                    <div className={`${styles['order-overview-history-summary-container-subtitle']}`}>5 Most
                        Recent
                    </div>
                </div>
                <div className={`${styles['order-overview-history-summary-results-container']}`}>
                    {checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                        <div tabIndex={0}
                             className={`${styles['order-overview-history-summary-container-view-all-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDERS, JSON.parse(userpolicies))) {
                                         let backButtonText = '';
                                         if (currentURL.includes('charitable-gaming/license/view')) {
                                             backButtonText = "Back to License Profile"
                                         } else if (currentURL.includes('pull-tab/orders')) {
                                             backButtonText = "Back to Pull Tab Orders";
                                         }
                                         history.push({
                                             pathname: `${pulltabRoutePath}/orders/overview-history`, state: {
                                                 backButtonText: backButtonText
                                             },
                                         });
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDERS, JSON.parse(userpolicies))) {
                                     let backButtonText = '';
                                     if (currentURL.includes('charitable-gaming/license/view')) {
                                         backButtonText = "Back to License Profile"
                                     } else if (currentURL.includes('pull-tab/orders')) {
                                         backButtonText = "Back to Pull Tab Orders";
                                     }
                                     history.push({
                                         pathname: `${pulltabRoutePath}/orders/overview-history`, state: {
                                             backButtonText: backButtonText
                                         },
                                     });
                                 }
                             }}>View All
                        </div> : null}
                </div>
            </div>

            <div className={styles['table']}>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>Purchase Order No.</div>
                    <div>Organization Name</div>
                    <div>License No.</div>
                    <div>Order Date</div>
                    <div>Subtotal</div>
                    <div>Ship Amount</div>
                    <div>Grand Total</div>
                    <div>Payment Method</div>
                </div>

                <div className={styles['table-data-body']}>

                    {pullTabOrderOverviewHistory.records.length > 0 && pullTabOrderOverviewHistory.records.slice(0, 5).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                {e.purchaseOrderId}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.orgName}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.licenseNumber}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.date).utc().format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.summedProductPriceInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.shippingPriceInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.summedProductPriceInCents + e.shippingPriceInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.paymentMethod}
                            </div>
                            <div className={styles['table-details-button-container']}>
                                {checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDER_DETAILS, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {
                                                 let backButtonText = '';
                                                 if (currentURL.includes('charitable-gaming/license/view')) {
                                                     backButtonText = "Back to License Profile"
                                                 } else if (currentURL.includes('pull-tab/orders')) {
                                                     backButtonText = "Back to Pull Tab Orders";
                                                 }
                                                 history.push({
                                                     pathname: `${pulltabRoutePath}/orders/details/${e.purchaseOrderId}`,
                                                     state: {
                                                         backButtonText: backButtonText
                                                     },
                                                 });
                                             }
                                         }}
                                         onClick={() => {
                                             let backButtonText = '';
                                             if (currentURL.includes('charitable-gaming/license/view')) {
                                                 backButtonText = "Back to License Profile"
                                             } else if (currentURL.includes('pull-tab/orders')) {
                                                 backButtonText = "Back to Pull Tab Orders";
                                             }
                                             history.push({
                                                 pathname: `${pulltabRoutePath}/orders/details/${e.purchaseOrderId}`,
                                                 state: {
                                                     backButtonText: backButtonText
                                                 },
                                             });
                                         }}
                                         className={styles['table-details-button']}>
                                        <div aria-label={'Pull-tab Order Item Details Button.'}
                                             className={styles['table-details-button-text']}>
                                            Details
                                        </div>
                                    </div> : null}
                            </div>
                        </div>)}
                </div>
            </div>

        </div>

    </>);
}));