import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./css/style.module.css";
import { keyPressedType } from "../../../../services/Commons";
import { KEY_CODES } from "../../../../constants.js";
import { annuitiesRoutePath } from "../../../../config.js";
import { Payee } from "../../../../types/Annuities/PayeeManagement/Payee";

export const PayeeListRowComponent: React.FC<{ payee: Payee }> = ({ payee }) => {
  const history = useHistory();

  return (
    <>
      <div className={`${styles.table_body}`}>
        <div className={`${styles.table_body_text} pl-2`}>{payee.payeeType === "Individual" ? `${payee.firstName} ${payee.lastName}` : payee.freeformName}</div>
        <div className={`${styles.table_body_text} pl-2`}>{payee.tin}</div>
        <div className={`${styles.table_body_text} pl-2`}>{payee.payeeType}</div>
        <button
          className={styles.dark_btn}
          onKeyPress={event => {
            if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
              history.push(`${annuitiesRoutePath}/payee-details?payeeId=${payee.id}`);
            }
          }}
          onClick={() => {
            history.push(`${annuitiesRoutePath}/payee-details?payeeId=${payee.id}`);
          }}
        >
          Details
        </button>
      </div>
    </>
  );
};
