import {InputValidationMessages} from "../../../Constants/InputValidationMessages.js";
import {makeHttpCall} from "../../../services/MakeHttpCall";
import config from "../../../config";
import {NotificationType} from "../../../components/HomeComponent/NotificationBannerComponent";
import {Modes} from "./constants";

let formik: any;
let mode: any;
let showNotificationBanner: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;

export const setVariables = (formikObj: any, modeObj: any, showNotificationBannerObj: any, setShowErrorDialogObj: any, setRequestCompletedObj: any) => {

    formik = formikObj;
    mode = modeObj;
    showNotificationBanner = showNotificationBannerObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
}

export const setFieldValue = (e: any, formik: any) => {

    // const nameRegex = /^[a-zA-Z]*$/;
    const nameRegex = /^[a-z ,.'-]+$/i;

    if (e.target.name === 'firstName' || e.target.name === 'lastName') {
        if (e.target.value.trim() === "" || nameRegex.test(e.target.value)) {
            formik.setFieldValue(e.target.name, e.target.value, true);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    if (!values.firstName) {
        Object.assign(errors, {firstName: InputValidationMessages.RequiredField});
    }

    if (!values.lastName) {
        Object.assign(errors, {lastName: InputValidationMessages.RequiredField});
    }

    if (!values.SSN) {
        Object.assign(errors, {SSN: InputValidationMessages.RequiredField});
    } else if (values.SSN && values.SSN.trim().length !== 11) {
        Object.assign(errors, {SSN: InputValidationMessages.InvalidSSNValue});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    if (mode === Modes.Add) {
        addFrequentCasher(values);
    } else if (mode === Modes.Remove) {
        removeFrequentCasher(values);
    }
}

export const addFrequentCasher = async (values: any) => {

    setRequestCompleted(false); // Display Circular Progress

    const body: any = {
        firstName: values.firstName, lastName: values.lastName, ssn: values.SSN.replace(/-/g, '') // Remove hyphens
    }

    try {
        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/v1/player/add-frequent-casher`, data: body
        }

        let response = await makeHttpCall(options);

        setRequestCompleted(true);

        showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'This Player has been successfully added to the Frequent Casher List'
        });

        formik.resetForm({values: {firstName: '', lastName: '', SSN: ''}});

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        showNotificationBanner({
            show: true, type: NotificationType.Error, text: 'Failed to add this Player to the Frequent Casher List'
        });
    }

}

export const removeFrequentCasher = async (values: any) => {

    setRequestCompleted(false); // Display Circular Progress

    const body: any = {
        firstName: values.firstName, lastName: values.lastName, ssn: values.SSN.replace(/-/g, '') // Remove hyphens
    }

    try {

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/v1/player/remove-frequent-casher`, data: body
        }

        let response = await makeHttpCall(options);

        setRequestCompleted(true);

        showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'This Player has been successfully removed from the Frequent Casher List'
        });

        formik.resetForm({values: {firstName: '', lastName: '', SSN: ''}});

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        showNotificationBanner({
            show: true, type: NotificationType.Error, text: 'Failed to remove this Player from the Frequent Casher List'
        });
    }

}

