import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { annuitiesRoutePath } from '../../../../config.js'
import { KEY_CODES } from '../../../../constants.js'
import { AnnuityAccountInfo } from '../../../../pages/AnnuitiesPages/TPAManagement/AnnuityManagementPage/interfaces'
import { keyPressedType } from '../../../../services/Commons'
import { PaginationCommonComponent } from '../../../CommonComponents/PaginationCommonComponent'
import { getPageData } from '../../../PlayersComponents/PlayerSearchTableComponent/functions'
import { AccountStatusCmpt } from '../../Common/AccountStatusCmpt'
import styles from './css/style.module.css'

export const AnnuityManagementListComponent = inject('annuitiesStore')(
  observer(({ AnnuityAccountData }: any) => {
    const [currentPage, setCurrentPage] = useState<number>(0)
    const history = useHistory()
    const normalizePage = (totalPages: number, currentPage: number) => {
      const number: number = totalPages - currentPage * 10
      if (number > 10) {
        return currentPage * 10 + 10
      } else {
        return totalPages
      }
    }
    useEffect(() => {
      // If data changes, reset page count
      setCurrentPage(0)
    }, [AnnuityAccountData])
    return (
      <>
        <div className={`${styles.table} rounded px-1 mb-2`}>
          <div
            className={`${styles.table_display_results} d-flex align-items-center font-italic font-weight-bold mt-2 `}
          >
            <div aria-live="assertive">{`Displaying ${
              AnnuityAccountData.length <= 0 ? 0 : currentPage * 10 + 1
            }-${normalizePage(AnnuityAccountData.length, currentPage)} of ${
              AnnuityAccountData.length
            } results`}</div>
          </div>

          <div
            className={`${styles.table_header} rounded-top font-weight-bold pl-2`}
          >
            <div>Document ID</div>
            <div>Payee Name</div>
            <div>TIN</div>
            <div>Final Payment Date</div>
            <div>Status</div>
            <div># of Accounts on Hold</div>
            <div></div>
          </div>

          <div style={{ minHeight: '20em' }}>
            {AnnuityAccountData.length > 0 &&
              getPageData(AnnuityAccountData, currentPage).map(
                (annuitant: AnnuityAccountInfo | any, i: number) => (
                  <div key={i} className={`${styles.table_body}`}>
                    <div className="text-break pl-2">
                      {annuitant.documentId}
                    </div>
                    <div className="text-break pl-2">{annuitant.name}</div>
                    <div>{annuitant.tin}</div>
                    <div className="text-break pl-2">
                      {annuitant.lifetimePayment
                        ? 'Lifetime Payment'
                        : moment(annuitant.finalPaymentDate).format(
                            'MM/DD/YYYY'
                          )}
                    </div>
                    <AccountStatusCmpt status={annuitant.status} />
                    <div className="text-break pl-2">{`${annuitant.onHoldSubAccountCount} of ${annuitant.totalSubAccountCount}`}</div>
                    <button
                      type="button"
                      className={`${styles.details_btn} mr-2 rounded font-weight-bold text-center`}
                      onKeyPress={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          history.push(
                            `${annuitiesRoutePath}/annuity-management-details/${annuitant.id}`
                          )
                        }
                      }}
                      onClick={() => {
                        history.push(
                          `${annuitiesRoutePath}/annuity-management-details/${annuitant.id}`
                        )
                      }}
                    >
                      Details
                    </button>
                  </div>
                )
              )}
          </div>
          <div
            className={`${styles.table_footer} p-2 rounded-bottom d-flex align-items-center justify-content-end`}
          >
            <div
              aria-live="assertive"
              className={`${styles.table_page_label} mr-2`}
            >{`Page ${currentPage + 1} of ${Math.ceil(
              AnnuityAccountData.length / 10
            ).toString()}`}</div>
            {
              <PaginationCommonComponent
                playerData={AnnuityAccountData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            }
          </div>
        </div>
      </>
    )
  })
)
