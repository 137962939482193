import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import CurrencyInput from 'react-currency-input-field';

export const CreatePullTabGameComponent = ({formik, setFieldValue}: any) => {

    return (
        <>
            <form className={styles['pulltab-game-creation-component-container']}>
                <div tabIndex={0} className={styles['pulltab-game-creation-component-title']}>Pull Tab Details</div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Stock Number*
                    </div>
                    <input
                        id="stockNumber"
                        name="stockNumber"
                        className={styles["pulltab-game-creation-component-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.stockNumber}
                    />
                    {(formik.touched.stockNumber && formik.errors.stockNumber) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.stockNumber}/> : null}
                </div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Units Per Case*</div>
                    <input
                        id="unitsPerCase"
                        name="unitsPerCase"
                        value={formik.values.unitsPerCase}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["pulltab-game-creation-component-input-box"]}`}
                        type="text"
                    />
                    {(formik.touched.unitsPerCase && formik.errors.unitsPerCase) ?
                        <ErrorMessageFieldCommonComponent
                            city={formik.errors.unitsPerCase}/> : null}
                </div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Game Name*</div>
                    <input
                        autoComplete={"text"}
                        id="gameName"
                        name="gameName"
                        className={styles["pulltab-game-creation-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.gameName}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.gameName && formik.errors.gameName) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.gameName}/> : null}
                </div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Case Cost*
                    </div>
                    <CurrencyInput
                        className={styles["pulltab-game-creation-component-input-box"]}
                        id="casePriceInCents"
                        name="casePriceInCents"
                        prefix="$"
                        defaultValue={formik.values.casePriceInCents}
                        onBlur={formik.handleBlur}
                        decimalsLimit={2}
                        disableAbbreviations={true}
                        onValueChange={(value, name) => formik.setFieldValue(name, value)}
                    />
                    {(formik.touched.casePriceInCents && formik.errors.casePriceInCents) ?
                        <ErrorMessageFieldCommonComponent
                            orgDateChartered={formik.errors.casePriceInCents}/> : null}
                </div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Unit Cost*</div>
                    <CurrencyInput
                        className={styles["pulltab-game-creation-component-input-box"]}
                        id="unitPriceInCents"
                        name="unitPriceInCents"
                        prefix="$"
                        defaultValue={formik.values.unitPriceInCents}
                        onBlur={formik.handleBlur}
                        decimalsLimit={2}
                        disableAbbreviations={true}
                        onValueChange={(value, name) => formik.setFieldValue(name, value)}
                    />
                    {(formik.touched.unitPriceInCents && formik.errors.unitPriceInCents) ?
                        <ErrorMessageFieldCommonComponent
                            orgPhone={formik.errors.unitPriceInCents}/> : null}
                </div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Case Weight*
                    </div>
                    <CurrencyInput
                        className={styles["pulltab-game-creation-component-input-box"]}
                        id="caseWeight"
                        name="caseWeight"
                        defaultValue={formik.values.caseWeight}
                        onBlur={formik.handleBlur}
                        suffix={' lbs'}
                        decimalsLimit={2}
                        disableGroupSeparators={true}
                        disableAbbreviations={true}
                        placeholder={'Ex. 0.00 lbs'}
                        onValueChange={(value, name) => formik.setFieldValue('caseWeight', value)}
                    />
                    {(formik.touched.caseWeight && formik.errors.caseWeight) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.caseWeight}/> : null}
                </div>
                <div className={styles["pulltab-game-creation-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["pulltab-game-creation-component-label"]}>Unit Weight*
                    </div>
                    <CurrencyInput
                        className={styles["pulltab-game-creation-component-input-box"]}
                        id="unitWeight"
                        name="unitWeight"
                        defaultValue={formik.values.unitWeight}
                        onBlur={formik.handleBlur}
                        suffix={' lbs'}
                        decimalsLimit={2}
                        disableGroupSeparators={true}
                        disableAbbreviations={true}
                        placeholder={'Ex. 0.00 lbs'}
                        onValueChange={(value, name) => formik.setFieldValue('unitWeight', value)}
                    />
                    {(formik.touched.unitWeight && formik.errors.unitWeight) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.unitWeight}/> : null}
                </div>
            </form>
        </>

    );
}