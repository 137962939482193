import moment from "moment";

export const getPageData = (KYCPlayerData:any[], page:number = 0) =>{
    const array:any= [];
    for(let i = page * 10; i < ((page * 10) + 10); i++)
    {
        if(!(KYCPlayerData[i] === undefined))
            array.push(KYCPlayerData[i]);
    }
    return array;
}

export const normilizeDate = (date:string)=>{
    
    return moment(date).format('MM/DD/YYYY h:mm:ss a');

}

export const setDateFormat = (value:any) => {
    try
    {
        let date:any = value.split('T')[0];
        date = date.split('-');
        let year:any = date[0];
        let time:any = value.split('T')[1];
        time = time.split(".");
        time = time[0].split(":");
        let hr:any =parseInt(time[0]); 
        let period = hr >= 12 ? "pm" : "am";
        if(hr > 12)
        {
            hr = hr - 12;
        }

        return `${date[1]}/${date[2]}/${year}&${hr}:${time[1]}:${time[2]} ${period}`;
   }catch(e){
    return `No Date&Available`;
   }
}