import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import moment from "moment-timezone";
import {
  buildClaimsArrayByDate,
  getHistoryStatusType,
  getTransactionStatusType,
  normilizeDate,
  numberOfRejectedRequest,
  quickSort,
  quickSortTransaction,
  setDateFormat
} from "./functions";

import { useHistory } from "react-router-dom";
import { keyPressedType } from "../../../services/Commons";

import { claimHistoryInterface, claimTransactionInterface } from "../../../pages/ClaimsPages/MobileClaimDetailsPage/interfaces";
import { checkRenderPermissions } from './../../../helpers.js'
import { permissions } from './../../../constants.js';
import { claimsSupportRoutePath } from './../../../config.js'
import { dataHistory } from './__test__/claimHistoryStatusDummyData';
import { dataTransaction } from "./__test__/claimTransactionDummyData";



const array:any = [];
for(let i = 0; i < 105; i++)
{
  array.push({  createdAt: "2021-08-25T17:01:09.706Z",
  firstName: "PlayerNameCharacterCountTestAccount",
  lastName: "PlayerNameCharacterCountTest Account",
  playerId: "2474b81b-3086-4987-9084-866655f53501"})
}




export const MobileClaimStatusTableComponent = ({id,claimsId, mobileClaimDetailsPage, setViewPaymentInfo,viewPaymentInfo }: any) => {

  let i:number  = 0;
  const history = useHistory();
  const userpolicies: any = localStorage.getItem('userpolicies');
 // mobileClaimDetailsPage.claimTransaction =  dataTransaction.claim;
  //mobileClaimDetailsPage.claimHistory = dataHistory.claim;
  const claimHistorySorted:any = quickSort( mobileClaimDetailsPage.claimHistory , 0 , mobileClaimDetailsPage.claimHistory.length - 1 );
  const claimTransactionSorted:any = quickSortTransaction(mobileClaimDetailsPage.claimTransaction,0,mobileClaimDetailsPage.claimTransaction.length -1)
  const claims:any = buildClaimsArrayByDate(claimHistorySorted,claimTransactionSorted); 
  
  //const claimHistorySorted:any = quickSort( data.claim , 0 , data.claim.length - 1 );
  
  
  
  // // KYCPlayerData = array;
  // const createPages = (KYCPlayerData: any[], currentPage: any, setCurrentPage: any) => {
  //   const array: any = [];
  //   const pagesTotal = Math.ceil(KYCPlayerData.length / 10);
     
  //   if (currentPage > 2 && pagesTotal > 5) {

  //     array.push(
  //       <>
  //         <div  
  //         tabIndex={0}
  //         role='button'  
  //         aria-pressed={ currentPage === 0 ? "true" : "false" } 
  //         style={{ backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : "" }}
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(0);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(0)} className={styles['KYCQUEUE-table-page-box']}>
  //           <div className={styles['KYCQUEUE-table-page-box-text']}>
  //             {1}
  //           </div>
  //         </div>
  //         <div>{'...'}</div>
  //       </>
  //     );
  //   }
  //   if (currentPage <= 2 || pagesTotal <= 5) {

  //     for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
  //       array.push(
  //         <div tabIndex={0} role='button' aria-pressed={ currentPage === i ? "true" : "false" }
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(i);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(i)} style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} 
  //         className={styles['KYCQUEUE-table-page-box']}>
  //           <div className={styles['KYCQUEUE-table-page-box-text']}>
  //             {i + 1}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   else if (currentPage > 2  && currentPage <= (pagesTotal - 1) - 2) {

  //     for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
  //       array.push(
  //         <div 
  //         tabIndex={0} 
  //         role='button' 
  //         aria-pressed={ currentPage === i ? "true" : "false" } 
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(i);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(i)} style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} className={styles['KYCQUEUE-table-page-box']}>
  //           <div   className={styles['KYCQUEUE-table-page-box-text']}>
  //             {i + 1}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   else if ( currentPage > (pagesTotal - 1) - 2) {
  //     for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
  //       array.push(
  //         <div tabIndex={0} role='button' aria-pressed={ currentPage === i ? "true" : "false" } 
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(i);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(i)} 
  //         style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} 
  //         className={styles['KYCQUEUE-table-page-box']}>
  //           <div className={styles['KYCQUEUE-table-page-box-text']}>
  //             {i + 1}
  //           </div>
  //         </div>
  //       );
  //     }

  //   }
  //   if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
  //     array.push(
  //       <>
  //         <div>{'...'}</div>
  //         <div  tabIndex={0} role='button' aria-pressed={ currentPage === pagesTotal ? "true" : "false" } 
  //         style={{ backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : "" }}
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(pagesTotal - 1);
  //           }
  //         }
  //         }    
  //         onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['KYCQUEUE-table-page-box']}>
  //           <div  className={styles['KYCQUEUE-table-page-box-text']}>
  //             {pagesTotal}
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }
  //   return array;


  // }

  const increaseCount = () => {
    i++;    
  }

  return (
    <>
   
      <div className={styles['KYCQUEUE-table']}>
        <div  className={styles['KYCQUEUE-table-data-header-container']} >
          <div className={styles['KYCQUEUE-table-data-header']} >
            <div tabIndex={0} className={styles['KYCQUEUE-table-header-title']}>Mobile Claim Status</div>
            <div tabIndex={0} className={styles['KYCQUEUE-table-header-text-container']}>
              <div className={styles['KYCQUEUE-table-header-text-label']}>Current Status</div>
              <div className={styles['KYCQUEUE-table-header-text-data']}>
                { 
                  claims.length <= 0 ? "" : claims[0].type === "T" ?  getTransactionStatusType(claims[0].data.status) : getHistoryStatusType(claims[0].data.status) 
                  }
                </div>
              </div>
          </div>
          {
            numberOfRejectedRequest < 2 && claimHistorySorted.length > 0  && claims.length > 0  && (claims[0].data.type === "T" ?  claims[0].data.details.status : claims[0].data.status === "REJECTED" ) && checkRenderPermissions(permissions.CAN_RESUBMIT_PAYMENT, JSON.parse(userpolicies)) &&  
                <div style={{ minHeight: '2.725em',width:'9.3125em', backgroundColor:'rgb(5, 159, 159)', color:"white" }}tabIndex={0} role='button' aria-pressed={"false"}
                   onKeyPress={(ev: any) => {
                    if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                      history.push(`${ claimsSupportRoutePath }/resubmitPayment/${id}/${claimsId}`);  
                    }
                  }
                  }    
                onClick={() => { history.push(`${ claimsSupportRoutePath }/resubmitPayment/${id}/${claimsId}`);  } } className={styles['KYCQUEUE-table-review-kyc-button']}>
                  <div aria-label={'Resubmit Payment button.'} className={styles['KYCQUEUE-table-review-kyc-button-text']}>
                    Re-Submit Payment
                  </div>
                </div>
            }
        </div>

        <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
          <div tabIndex={0}>Date</div>
          <div tabIndex={0}>Status</div>
          <div tabIndex={0}>Action</div>
        </div>
        <div className={styles['KYCQUEUE-table-data-body']} >
       
          { claims.length > 0 && claims.map((e: {type:string,data:claimHistoryInterface | claimTransactionInterface | any}) =>
             <>
             
             { 
              
            (e.type === "H" && e.data.status !== "READY_FOR_PAYMENT") &&
            <div style={{ backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(29, 69, 111)') }} className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
               {increaseCount()}  
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.data.activeFrom, "America/New_York").format("MM/DD/YYYY")}&nbsp;&nbsp;&nbsp;{moment.tz(e.data.activeFrom, "America/New_York").format("h:mm:ss a")}</div>
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{ getHistoryStatusType(e.data.status)}</div>
            </div>
              }
             { 
            e.type === "T" && 
            <div style={{ backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(29, 69, 111)') }} className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
              
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.data.details.createdAt, "America/New_York").format("MM/DD/YYYY")}&nbsp;&nbsp;&nbsp;{moment.tz(e.data.details.createdAt, "America/New_York").format("h:mm:ss a")}</div>
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{ getTransactionStatusType(e.data.status)}</div>
              <div>
               {
                 ((getTransactionStatusType(e.data.status) === "Validated - Payment Complete" || 
                 getTransactionStatusType(e.data.status) === "Validated - Payment Rejected" || 
                 getTransactionStatusType(e.data.status) === "Validated - Payment Pending") && 
                 e.data.checkMailingAddress.zip5 === null &&
                 checkRenderPermissions(permissions.CAN_SEE_CLAIM_TRANSACTION_PII, JSON.parse(userpolicies))) &&  
                <div tabIndex={0} role='button' aria-pressed={"false"}
                   onKeyPress={(ev: any) => {
                    if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                        viewPaymentInfo.toogle = true;
                        viewPaymentInfo.accountType = e.data.achAccount.achAccountType;
                        viewPaymentInfo.accountNumber = e.data.achAccount.achAccountNumber;
                        viewPaymentInfo.routingNumber = e.data.achAccount.achRoutingNumber;
                        viewPaymentInfo.traceID =  e.data.traceId;
                        setViewPaymentInfo({...viewPaymentInfo});  
                    }
                  }
                  }    
                onClick={() => { 
                   viewPaymentInfo.toogle = true;
                   viewPaymentInfo.accountType = e.data.achAccount.achAccountType;
                   viewPaymentInfo.accountNumber = e.data.achAccount.achAccountNumber;
                   viewPaymentInfo.routingNumber = e.data.achAccount.achRoutingNumber;
                   viewPaymentInfo.traceID =  e.data.traceId;
                   setViewPaymentInfo({...viewPaymentInfo});  
                   } 
                   } 
                   className={styles['KYCQUEUE-table-review-kyc-button']}>
                  <div aria-label={'View Payment Info.'} className={styles['KYCQUEUE-table-review-kyc-button-text']}>
                    View Payment Info
                  </div>
                </div>
               }{
                ((getTransactionStatusType(e.data.status) === "Validated - Payment Complete" || 
                getTransactionStatusType(e.data.status) === "Validated - Payment Rejected" || 
                getTransactionStatusType(e.data.status) === "Validated - Payment Pending") &&
                e.data.checkMailingAddress.zip5 !== null) ?  
                  <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>
                  </div> : null
               }
              </div>
              {increaseCount()}
            </div>
              }
               
            </>
             
          )}
        </div>
      
      </div>


    </>

  );
}

