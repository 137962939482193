import { BarcodeInfo } from '../../../../api/promotions-fetcher/coupon-campaigns/constants'
import neutralIcon from '../../../../icons/icon_alert_blue.svg'
import goodIcon from '../../../../icons/icon_approved.svg'
import pendingIcon from '../../../../icons/icon_caution_2x.svg'
import errorIcon from '../../../../icons/icon_error.svg'
import flaggedIcon from '../../../../icons/icon_status_flagged.svg'

enum StatusIcon {
  GOOD = goodIcon,
  FLAGGED = flaggedIcon,
  PENDING = pendingIcon,
  NEUTRAL = neutralIcon,
  ERROR = errorIcon,
}

export const getBarcodesStatusIcon = (barcodes?: BarcodeInfo) => {
  if (!barcodes?.totalBarcodes) {
    return StatusIcon.NEUTRAL
  } else if (barcodes.barcodesAvailable === 0) {
    return StatusIcon.FLAGGED
  } else {
    return StatusIcon.GOOD
  }
}

export const getFileStatusIcon = (
  fileStatus: 'Success' | 'Pending' | 'Error'
) => {
  switch (fileStatus) {
    case 'Success':
      return StatusIcon.GOOD
    case 'Pending':
      return StatusIcon.PENDING
    default:
      return StatusIcon.ERROR
  }
}

export const getBarcodeText = (barcodes?: BarcodeInfo) => {
  if (!barcodes?.totalBarcodes) {
    return `No barcodes yet`
  } else {
    return `${barcodes.barcodesAvailable.toLocaleString()} barcodes available`
  }
}
