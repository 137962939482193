import React, { useContext } from 'react'
import {
  PromotionDrawingTier,
  PromotionResponse,
} from '../../../../api/promotions-fetcher/constants'
import { DrawingSelection } from '../winners-management-drawing-form/winners-management-drawing-form'
import { WinnersManagementTierContext } from '../winners-management-list-context'
import { WinnersManagementTierUI } from './winners-management-tier-ui'
import { useFetchPostPromotionCampaignWinners } from '../../../../api/promotions-fetcher/use-fetch-winners-management'

type WinnersManagementTierContainerProps = {
  tier: PromotionDrawingTier
  acceptedWinners: number
  totalWinners: number
  promotion: PromotionResponse
  selectedDrawing: DrawingSelection
  fetchDownloadWinnersCSV: (
    promotionId: string,
    drawingGroupId: string,
    drawingId: string
  ) => Promise<void>
}

export const WinnersManagementTierContainer = (
  props: WinnersManagementTierContainerProps
) => {
  const { promotion, selectedDrawing, tier } = props
  const context = useContext(WinnersManagementTierContext)
  const { isLoading, error, requestSuccessful, saveWinners } =
    useFetchPostPromotionCampaignWinners(
      promotion.id,
      selectedDrawing.drawingGroupId,
      selectedDrawing.drawingId,
      tier.prizeTier
    )
  return (
    <WinnersManagementTierUI
      {...props}
      refreshTiers={context.refreshTiers}
      isLoading={isLoading}
      error={error}
      requestSuccessful={requestSuccessful}
      saveWinners={saveWinners}
    />
  )
}
