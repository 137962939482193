import React from "react"
import { useFetchCheckRegisterReports } from "./use-fetch-check-register-reports"
import { useFetchDownloadCheckRegisterReport } from "./use-fetch-download-check-register-report"
import { CheckRegisterReportPageUI } from "./check-register-report-page-ui"

export const CheckRegisterReportPageContainer = () => {
  const {
    isLoading: isGetReportsLoading,
    error: getReportsError,
    data: getReportsData,
    fetchReports
  } = useFetchCheckRegisterReports()

  const {
    isLoading: isDownloadReportLoading,
    error: downloadError,
    preSignedUrlHandler
  } = useFetchDownloadCheckRegisterReport()

  React.useEffect(() => {
    fetchReports()
  }, [])

  return (
    <CheckRegisterReportPageUI
      isLoading={isGetReportsLoading || isDownloadReportLoading}
      error={getReportsError || downloadError}
      reports={getReportsData?.reports ?? []}
      downloadReport={preSignedUrlHandler}
    />
  )
}