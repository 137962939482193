import config from "../../../../../config.js";
import { makeHttpCall } from "../../../../../services/MakeHttpCall";
import { SubAccount } from "../../../../../types/Annuities/entities/sub-account.js";

// Put updated Sub Account values
export const updateSubAccount = async (
  subAccount: SubAccount,
  context: any
) => {
  const options = {
    method: "PUT",
    url: `${config.SERVER_BASE_URL}/v1/annuities/sub-account/${subAccount.id}`,
    data: subAccount,
  };

  try {
    const response = await makeHttpCall(options);
    context.setBannerInfo({
      message: "Successfully Updated the Sub Account",
      error: false,
    });
    return response;
  } catch (error) {
    context.setBannerInfo({
      message: "Something went wrong updating the Sub Account",
      error: true,
    });
    console.log(error);
  }
};
