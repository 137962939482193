import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import styles from './css/styles.module.css';
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {keyPressedType} from "../../../../services/Commons";
import {Tab, Tabs} from 'react-bootstrap';
import {NotificationBannerComponent} from "../../../../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import {ChargeDetailsDefaultData, ChargeDetailsInterface} from "./interfaces";
import ToggleSwitchComponent from "../../../../components/CommonComponents/ToggleSwitchComponent";
import {getChargeDetails, rejectCharge, settleCharge, setVariables} from "./functions";
import {formatCentsToDollars} from "../../../../util";
import {ChargeStatusMapping, ExpenseTypeMapping} from "../../../../Constants/CharitableConstant";
import {CircularProgress} from "@material-ui/core";
import {taxformRoutePath} from "../../../../config";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";

export const PermitHolderChargeDetailsPage = inject("charitableGamingStore")(observer(({
                                                                                           chargeId,
                                                                                           charitableGamingStore
                                                                                       }: any) => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    let backButtonText = 'Back to Permit Holder Charges';

    const {notificationBanner} = charitableGamingStore;

    const [selectedCharge, setSelectedCharge] = useState<ChargeDetailsInterface>(ChargeDetailsDefaultData);
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    setVariables(chargeId, charitableGamingStore, setRequestCompleted, setShowErrorDialog, selectedCharge, setSelectedCharge);

    useEffect(() => {
        if (requestCompleted === false) {
            getChargeDetails();
        }
    }, []);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    const handleViewExpenseDetails = () => {

        if (selectedCharge.expenseType === 'RAFFLE_BAZAAR') {
            history.push({
                pathname: `${taxformRoutePath}/edit/raffle/${selectedCharge.associatedEntityId}`,
                state: {
                    backButtonText: 'Back to Charge Details'
                },
            });
        } else if (selectedCharge.expenseType === 'CASINO_FUNCTION') {
            history.push({
                pathname: `${taxformRoutePath}/edit/casino/${selectedCharge.associatedEntityId}`,
                state: {
                    backButtonText: 'Back to Charge Details'
                },
            });
        }
    }

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}
            <div className={`${styles['permit-holder-details-page-container']}`}
                 onClick={() => {

                 }}
            >
                <div tabIndex={0}
                     className={`${styles['permit-holder-details-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['permit-holder-details-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >{backButtonText}
                    </div>
                </div>

                <div className={`${styles["permit-holder-details-page-ribbon-container"]}`}>
                    <div className={`${styles["permit-holder-details-page-ribbon-container-col-1"]}`}>
                        <div className={styles["permit-holder-details-page-ribbon-title-container"]}>
                            <div className={styles["permit-holder-details-page-ribbon-title-label"]}>Charge Details
                            </div>
                        </div>
                        <div className={styles["permit-holder-details-page-ribbon-subtitle-container"]}>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Raffle ID
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedCharge.orgDetails.identifier}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Expense Type
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{ExpenseTypeMapping[selectedCharge.expenseType]}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Payment
                                    Method
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedCharge.paymentMethod}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Status
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{ChargeStatusMapping[selectedCharge.status]}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles["permit-holder-details-page-tabs-container"]}`}>
                    <Tabs className={`${styles["permit-holder-details-tabs"]}`} id="permit-holder-details-tabs">
                        <Tab eventKey="chargeDetails" title="Charge Details">
                            <div className={`${styles["row-1-container"]}`}>
                                <div className={`${styles["row-1-col-1-container"]}`}>
                                    <div className={`${styles["row-container-title"]}`}>Organization</div>
                                    <div className={`${styles['org-details-controls-container']}`}>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Org.
                                            Name
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.orgName}</div>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Street
                                            Address
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.streetAddress}</div>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.city}</div>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.zip}</div>

                                    </div>
                                </div>
                                <div className={`${styles["row-1-col-2-container"]}`}>
                                    <div className={`${styles["row-container-title"]}`}>Permit</div>
                                    <div className={`${styles['permit-details-controls-container']}`}>
                                        <div
                                            className={`${styles['permit-details-controls-container-column-1']} ${styles['mslc-label']}`}>Raffle
                                            ID
                                        </div>
                                        <div
                                            className={`${styles['permit-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.identifier}</div>

                                    </div>
                                </div>
                            </div>
                            <div className={`${styles["row-2-container"]}`}>
                                <div className={`${styles["row-2-col-1-container"]}`}>
                                    <div className={`${styles["row-container-title"]}`}>Charge Information</div>
                                    <div className={`${styles['charge-details-controls-container']}`}>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-1']} ${styles['mslc-label']}`}>Charge
                                            Date
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-2']} ${styles['mslc-label']}`}>{moment(selectedCharge.date).utc().format('MM/DD/YYYY')}</div>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-3']} ${styles['mslc-label']}`}>Charge
                                            Status
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-4']} ${styles['mslc-label']}`}>{ChargeStatusMapping[selectedCharge.status]}</div>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-1']} ${styles['mslc-label']}`}>Expense
                                            Type
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-2']} ${styles['mslc-label']}`}>{ExpenseTypeMapping[selectedCharge.expenseType]}</div>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-3']} ${styles['mslc-label']}`}>Check
                                            Received
                                        </div>
                                        {checkRenderPermissions(permissions.CAN_MARK_CHECK_RECEIVED, JSON.parse(userpolicies)) ?
                                            <div tabIndex={0}
                                                 className={`${styles['charge-details-controls-container-toggle-switch']}`}
                                                 onKeyDown={(ev: any) => {
                                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                         if (selectedCharge.paymentMethod === 'CHECK') {
                                                             if (selectedCharge.checkReceived) {
                                                                 rejectCharge(selectedCharge.chargeId);
                                                             } else {
                                                                 settleCharge(selectedCharge.chargeId);
                                                             }
                                                         }
                                                     }
                                                 }}
                                            >
                                                <ToggleSwitchComponent
                                                    id={`checkReceived`}
                                                    name={`checkReceived`}
                                                    isOn={selectedCharge.checkReceived}
                                                    disabled={selectedCharge.paymentMethod !== 'CHECK'}
                                                    handleToggle={() => {
                                                        if (selectedCharge.paymentMethod === 'CHECK') {
                                                            if (selectedCharge.checkReceived) {
                                                                rejectCharge(selectedCharge.chargeId);
                                                            } else {
                                                                settleCharge(selectedCharge.chargeId);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div> : null}
                                        <div
                                            className={`${styles['charge-details-controls-container-column-1']} ${styles['mslc-label']}`}>Payment
                                            Method
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.paymentMethod}
                                        </div>

                                    </div>
                                </div>
                                <div className={`${styles["row-2-col-2-container"]}`}>
                                    <div className={`${styles["row-2-row-2-col-2-container"]}`}>
                                        <div className={`${styles["row-container-title"]}`}>Charge Amount</div>
                                        <div
                                            className={`${styles['charge-amount-summary-controls-container']} ${styles['padding-top-075']}`}>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Subtotal
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formatCentsToDollars(selectedCharge.baseAmountInCents)}
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-1']} ${styles['mslc-label']} ${styles['padding-top-1']}`}>Credit
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['padding-top-1']} ${styles['justify-right']}`}>{(selectedCharge.creditAmountInCents === 0) ? `n/a` : `-${formatCentsToDollars(selectedCharge.creditAmountInCents)}`}
                                            </div>
                                        </div>
                                        <hr className={styles['horizontal-line']}/>
                                        <div className={`${styles['charge-amount-summary-controls-container']}`}>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Grand
                                                Total
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formatCentsToDollars(selectedCharge.totalAmountInCents)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles["row-2-col-3-container"]}`}>
                                    {(checkRenderPermissions(permissions.CAN_VIEW_EXPENSE_DETAILS, JSON.parse(userpolicies))) ?
                                        <div tabIndex={0}
                                             className={`${styles['view-expense-detail-button']} ${styles['']}`}
                                             role="button"
                                             onKeyDown={(ev: any) => {
                                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                     handleViewExpenseDetails();
                                                 }
                                             }}
                                             onClick={() => {
                                                 handleViewExpenseDetails();
                                             }}>
                                            <div
                                                className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                                View Form
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                </div>

            </div>
            {notificationBanner.show ? <NotificationBannerComponent
                text={notificationBanner.text}
                type={notificationBanner.type}
                handleHide={hideNotificationBanner}
            /> : null}
            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
        </>

    );
}));