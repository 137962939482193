import * as React from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { ImageViewerControls } from '../ImageViewerControls/image-viewer-controls'
import { getCustomCssProperties } from '../../../utilities/helpers'
import './styles.scss'

export type ImageViewerProps = {
  src: string
  alt: string
}

const ROOT_CLASS = 'image-viewer'
const IMAGE_CLASS = `${ROOT_CLASS}__image`

const ROTATION_DEGREES_DELTA = 90

export const ImageViewer = (props: ImageViewerProps) => {
  const [rotationDegrees, setRotationDegrees] = React.useState<number>(0)

  const rotateImage = () => {
    setRotationDegrees(rotationDegrees + ROTATION_DEGREES_DELTA)
  }

  return (
    <div className={ROOT_CLASS}>
      <TransformWrapper minScale={0.5}>
        <TransformComponent
          wrapperClass={`${ROOT_CLASS}__wrapper`}
          contentClass={`${ROOT_CLASS}__transformer`}
        >
          <img
            className={IMAGE_CLASS}
            style={getCustomCssProperties({
              '--rotation-degrees': `${rotationDegrees}deg`,
            })}
            src={props.src}
            alt={props.alt}
          />
        </TransformComponent>
        <ImageViewerControls handleRotateClick={rotateImage} />
      </TransformWrapper>
    </div>
  )
}
