import * as React from 'react'
import { useState } from 'react'
import './styles.scss'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export type MaskedSsnUIProps = {
  ssn: string
}

export const MaskedSsnUI = (props: MaskedSsnUIProps) => {
  const [isUnmasked, setUnmasked] = useState(false)
  return (
    <div className={`tin-eye-icon-container`}>
      <div>{isUnmasked ? props.ssn : `*****${props.ssn.slice(-4)}`}</div>
      <div>
        {isUnmasked ?
          <FaEyeSlash style={{ cursor: "pointer" }} onClick={() => {
            setUnmasked(false);
          }} /> : <FaEye style={{ cursor: "pointer" }} onClick={() => {
            setUnmasked(true);
          }} />}
      </div>
    </div>
  )
}