import config from "../../../config";
import {makeHttpCall} from "../../../services/MakeHttpCall";
import {NotificationType} from "../../../components/HomeComponent/NotificationBannerComponent";
import {InputValidationMessages} from "../../../Constants/InputValidationMessages";

let formik: any;
let setRequestCompleted: any;
let setOpenCalendarSweeps: any;
let setW2GQueue: any;
let setCurrentPage: any;
let showNotificationBanner: any;
let setShowErrorDialog: any;

export const setVariables = (formikObj: any, setRequestCompletedObj: any, setOpenCalendarSweepsObj: any,
                             setW2GQueueObj: any, setCurrentPageObj: any, showNotificationBannerObj: any,
                             setShowErrorDialogObj: any) => {

    formik = formikObj;
    setRequestCompleted = setRequestCompletedObj;
    setOpenCalendarSweeps = setOpenCalendarSweepsObj;
    setW2GQueue = setW2GQueueObj;
    setCurrentPage = setCurrentPageObj;
    showNotificationBanner = showNotificationBannerObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const setFieldValue = (e: any, formik: any) => {

    if (e.target.name === "year") {
        try {
            const value = JSON.parse(e.target.value);
            formik.setFieldValue(e.target.name, value.displayName, true);
            formik.setFieldValue('sweepId', value.sweepId, true);
        } catch (ex) {
            clearSearch(formik);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.sweepId) {
        Object.assign(errors, {sweepId: InputValidationMessages.RequiredField});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    //alert(JSON.stringify(values));
    getW2GQueue(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            year: '', sweepId: '', SSN: ''
        }
    });

    setW2GQueue(null);

}

export const getPageData = (payments: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(payments[i] === undefined)) {
            payments[i].index = index;
            index++;
            array.push(payments[i]);
        }
    }
    return array;
}

const getW2GQueue = async (values: any) => {

    setCurrentPage(0);

    setRequestCompleted(false);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/w2gQueue/${values.sweepId}`
        }

        const response = await makeHttpCall(options);

        // Compute Aggregate Totals

        let irsTotalInCents = 0;
        let dorTotalInCents = 0;
        let reportableTotalInCents = 0;
        let grandTotal = 0;

        for (let i = 0; i < response.paymentsInSweep.length; i++) {
            irsTotalInCents += response.paymentsInSweep[i].irsTaxInCents;
            dorTotalInCents += response.paymentsInSweep[i].dorTaxInCents;
            reportableTotalInCents += response.paymentsInSweep[i].reportableWinningsInCents;
        }

        grandTotal = irsTotalInCents + dorTotalInCents + reportableTotalInCents;

        response.irsTotalInCents = irsTotalInCents;
        response.dorTotalInCents = dorTotalInCents;
        response.reportableTotalInCents = reportableTotalInCents;
        response.grandTotal = grandTotal;

        setW2GQueue(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const getOpenCalendarSweeps = async () => {

    setCurrentPage(0);

    setRequestCompleted(false);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/w2gQueue/open`
        }

        const response = await makeHttpCall(options);

        setOpenCalendarSweeps(response.sweeps);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const processW2GSweep = async (sweepId: string) => {

    setCurrentPage(0);

    setRequestCompleted(false);

    try {

        const body: any = {sweepId: sweepId};

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/w2gQueue/process`, data: body
        }

        await makeHttpCall(options);

        setRequestCompleted(true);

        showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The selected W-2Gs have been sent to Operations for print'
        });

        clearSearch(formik);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const searchBySSN = async (W2GQueue: any, SSN: string) => {

    setCurrentPage(0);

    setRequestCompleted(false);

    try {

        const result = W2GQueue.paymentsInSweep.filter(function (payment: any) {
            return payment.payeeSsn === SSN;
        });

        W2GQueue.paymentsInSweep = result ? result : [];

        let irsTotalInCents = 0;
        let dorTotalInCents = 0;
        let reportableTotalInCents = 0;
        let grandTotal = 0;

        for (let i = 0; i < W2GQueue.paymentsInSweep.length; i++) {
            irsTotalInCents += W2GQueue.paymentsInSweep[i].irsTaxInCents;
            dorTotalInCents += W2GQueue.paymentsInSweep[i].dorTaxInCents;
            reportableTotalInCents += W2GQueue.paymentsInSweep[i].reportableWinningsInCents;
        }

        grandTotal = irsTotalInCents + dorTotalInCents + reportableTotalInCents;

        W2GQueue.irsTotalInCents = irsTotalInCents;
        W2GQueue.dorTotalInCents = dorTotalInCents;
        W2GQueue.reportableTotalInCents = reportableTotalInCents;
        W2GQueue.grandTotal = grandTotal;

        // Deep Copy
        setW2GQueue(JSON.parse(JSON.stringify(W2GQueue)));

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}