import * as React from 'react'
import { ConfirmPopupModal } from '../../../CommonComponents/ConfirmPopupModal/confirm-popup-modal'
import { useFetchDeletePromotionMessage } from '../../../../api/playslips-fetcher/use-fetch-playslips'

export type PromotionalMessagesDeleteMessageContainerProps = {
  closeModal: () => void
  messageId: number
  fetchAllPromotionMessages: () => Promise<void>
}

export const PromotionalMessagesDeleteMessageContainer = (
  props: PromotionalMessagesDeleteMessageContainerProps
) => {
  const { isLoading, error, requestSuccessful, deletePromotionMessage } =
    useFetchDeletePromotionMessage(props.messageId)

  return (
    <ConfirmPopupModal
      isOpen
      headerText="Are you sure you want delete this promotional message?"
      buttonText="Confirm"
      closeModal={props.closeModal}
      error={error}
      loading={isLoading}
      actionSuccessful={requestSuccessful}
      onSuccess={props.fetchAllPromotionMessages}
      submitAction={deletePromotionMessage}
    />
  )
}
