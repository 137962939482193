import React from 'react'
import { TimePaymentAuthSchedule } from '../../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization'
import { formatCentsToDollars, formatDollarAmount } from '../../../../../util'
import styles from './css/styles.module.css'

export const AggregateTotalsCmpt: React.FC<{
  paymentResponse: TimePaymentAuthSchedule[]
  showOnHoldDisclaimer: boolean
}> = ({ paymentResponse, showOnHoldDisclaimer }) => {
  interface AggregateTotals {
    grossAmount: number
    irsAmount: number
    dorAmount: number
    netAmount: number
  }

  const aggregateTotals: AggregateTotals = {
    grossAmount: paymentResponse.reduce((prev, curr) => {
      return prev + curr.payment.paymentAmount
    }, 0),
    irsAmount: paymentResponse.reduce((prev, curr) => {
      return prev + curr.payment.irsWithholding
    }, 0),
    dorAmount: paymentResponse.reduce((prev, curr) => {
      return prev + curr.payment.dorsWithholding
    }, 0),
    netAmount: paymentResponse.reduce((prev, curr) => {
      return (
        prev +
        (curr.payment.paymentAmount -
          curr.payment.irsWithholding -
          curr.payment.dorsWithholding)
      )
    }, 0),
  }

  return (
    <div className={`${styles.aggregate_container}`}>
      <div className="d-flex">
        <h2>Aggregate Totals</h2>
        {showOnHoldDisclaimer && (
          <p className={`font-italic mt-auto mb-auto ml-2`}>
            these totals do not include on hold payments
          </p>
        )}
      </div>
      <div className={`d-flex justify-content-between mt-3`}>
        <div className="d-flex">
          <div className={` d-flex ${styles.individual_total}`}>
            <p className="mr-2" style={{ color: '#ccc' }}>
              Gross Payment
            </p>
            <p>{formatCentsToDollars(aggregateTotals.grossAmount)}</p>
          </div>
          <div className={`d-flex ${styles.individual_total}`}>
            <p className="mr-2" style={{ color: '#ccc' }}>
              Federal Tax
            </p>
            <p>{formatCentsToDollars(aggregateTotals.irsAmount)}</p>
          </div>
          <div className={`d-flex mr-4`}>
            <p className="mr-2" style={{ color: '#ccc' }}>
              State Tax
            </p>
            <p>{formatCentsToDollars(aggregateTotals.dorAmount)}</p>
          </div>
        </div>
        <div>
          <div className={`d-flex`}>
            <p className="mr-2" style={{ color: '#ccc' }}>
              Net Payment
            </p>
            <p>{formatCentsToDollars(aggregateTotals.netAmount)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
