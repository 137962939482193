import React, { useState } from "react";
import { keyPressedType } from "../../../services/Commons";
import { ErrorMessageFieldCommonComponent } from "../../CommonComponents/ErrorMessageFieldCommonComponent";
import styles from './css/styles.module.css';
import { CheckboxComponent } from "../../../components/CommonComponents/CheckboxComponent";
import { testDate  } from "./functions";
import { applyFilters } from "../../../pages/PlayersPages/MobileClaimsActivityPage/functions";


export const MobileClaimsActivityButtonsComponent = ({ UnfilteredClaims ,
  MCAStartDate,
  setMCAStartDate,
  MCAEndDate,
  setMCAEndDate,
  MCAInitiated,
  setMCAInitiated,
  setFilteredClaims,
  setCurrentPage,
  FilteredClaims,
  resetFilters,
  setStartDate,
  setEndDate,
  id }: any) => {


  const [errorStartDate, setErrorStartDate] = useState<any>(undefined);
  const [errorEndDate, setErrorEndDate] = useState<any>(undefined);
  return (
    <>
      <div className={styles['filter-container']}>
        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
          <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Start Date</div>
          <input
            autoComplete={"off"}
            id="MCAStartDate"
            name="MCAStartDate"
            value={MCAStartDate}
            onChange={(e: any) => {setStartDate(e, setMCAStartDate);testDate(e.target.name,setErrorEndDate, setErrorStartDate, e.target.value, MCAEndDate);}}
            className={`${styles["page-container-body-left-input-box"]}`}
            type="date" />
          {errorStartDate && <ErrorMessageFieldCommonComponent errorMessages={errorStartDate} />}
        </div>
        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
          <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>End Date</div>
          <input
            autoComplete={"off"}
            id="MCAEndDate"
            name="MCAEndDate"
            value={MCAEndDate}
            aria-label={`${MCAEndDate}.`}
            onChange={(e: any) =>{ setEndDate(e, setMCAEndDate); testDate(e.target.name, setErrorEndDate,setErrorStartDate, e.target.value, MCAStartDate); }}
            className={`${styles["page-container-body-left-input-box"]}`}
            type="date" />
            {errorEndDate && <ErrorMessageFieldCommonComponent errorMessages={errorEndDate} />}
        </div>
        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
          <div className={styles["page-container-body-left-labels-name"]}></div>
          <div tabIndex={0} className={styles['MCA-inherit-btn']}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                setMCAInitiated(!MCAInitiated);
              }
            }
            }

            onClick={() => setMCAInitiated(!MCAInitiated)}>
            {/*<div className={MCAInitiated ? styles['MCA-inherit-btn-check-show'] : styles['MCA-inherit-btn-check-hide']}> </div>*/}
            <div className={styles['MCA-inherit-btn-check']}>
              <CheckboxComponent isChecked={MCAInitiated} />
            </div>
            <div className={styles['MCA-inherit-btn-text']}>Hide Initiated Claims</div>
          </div>
        </div>
        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
          <div className={styles["page-container-body-left-labels-name"]}></div>
          <button
              tabIndex={0}
              style={{
              opacity: !(errorStartDate === undefined && errorEndDate === undefined) ? "0.4" : "1",
              cursor: !(errorStartDate === undefined && errorEndDate === undefined) ? "not-allowed" : "pointer",
            }}
              onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                if ((FilteredClaims.length > 0 && FilteredClaims[0]?.referenceNumber !== id) && (errorStartDate === undefined && errorEndDate === undefined)) {
                  applyFilters(setFilteredClaims, UnfilteredClaims, MCAStartDate, MCAEndDate, MCAInitiated, setCurrentPage, id);

                }
              }
            }
            }
              onClick={() => ((FilteredClaims.length > 0 && FilteredClaims[0]?.referenceNumber !== id) && (errorStartDate === undefined && errorEndDate === undefined)) && applyFilters(setFilteredClaims, UnfilteredClaims, MCAStartDate, MCAEndDate, MCAInitiated, setCurrentPage, id)} className={styles['MCA-filter-btn']}>Filter</button>
        </div>
        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
          <div className={styles["page-container-body-left-labels-name"]}></div>
          <button
              tabIndex={0}
              onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                resetFilters(setFilteredClaims, UnfilteredClaims, setMCAEndDate, setMCAStartDate, setMCAInitiated, setCurrentPage, id)
              }
            }
            }

              onClick={() => resetFilters(setFilteredClaims, UnfilteredClaims, setMCAEndDate, setMCAStartDate, setMCAInitiated, setCurrentPage, id)} className={styles['MCA-reset-filter-btn']}>Reset Filters</button>
        </div>
      </div>
    </>
  );
}