
export const normilizePhoneStyle = (phone:any)=>{
    try {
        if(phone === "" || phone === undefined)
            return "";  
        let phoneArray = phone.split("");
    
        if (!(phoneArray[0] === "(") && phone.length > 0) {
    
          phoneArray = phoneArray.filter((e: any, i: number) => {
    
            if (!(e === "+" && i === 0 || e === "1" && i === 1))
                 return e;
          });
          return `(${phoneArray.slice(0, 3).join("")}) ${phoneArray.slice(3, 6).join("")}-${phoneArray.slice(6, phoneArray.length).join("")}`;
        }
      } catch (e) {
        
      }

}