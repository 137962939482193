import * as React from 'react'
import {
  useFetchPromotion,
  useFetchPromotionDrawingDetails,
  useFetchPromotionDrawings,
} from '../../../api/promotions-fetcher/use-fetch-base-promotions'
import {
  useFetchDownloadExperientialWinnersCSV,
  useFetchDownloadTaxablePrizeWinnersCSV,
} from '../../../api/promotions-fetcher/use-fetch-winners-management'
import { WinnersManagementUI } from './winners-management-ui'

type WinnersManagementContainerProps = {
  promotionId: string
}

export const WinnersManagementContainer = (
  props: WinnersManagementContainerProps
) => {
  const { data: promotion, isLoading: promotionLoading } = useFetchPromotion(
    props.promotionId
  )
  const {
    data: drawings,
    isLoading: drawingsLoading,
    error: drawingsError,
  } = useFetchPromotionDrawings(props.promotionId)
  const { fetchDrawingDetails, data, isLoading, error, firstLoad } =
    useFetchPromotionDrawingDetails(props.promotionId)

  const { fetchDownloadExperientialWinnersCSV } =
    useFetchDownloadExperientialWinnersCSV()
  const { fetchDownloadTaxablePrizeWinnersCSV } =
    useFetchDownloadTaxablePrizeWinnersCSV()

  return (
    <WinnersManagementUI
      promotionLoading={promotionLoading}
      promotionId={props.promotionId}
      promotion={promotion}
      fetchDrawingDetails={fetchDrawingDetails}
      drawingsLoading={drawingsLoading}
      drawingsError={drawingsError}
      drawings={drawings}
      tiersLoading={isLoading}
      tiersError={error}
      firstTierLoad={firstLoad}
      drawingTiers={data}
      fetchDownloadExperientialWinnersCSV={fetchDownloadExperientialWinnersCSV}
      fetchDownloadTaxablePrizeWinnersCSV={fetchDownloadTaxablePrizeWinnersCSV}
    />
  )
}
