import * as React from 'react'
import { useHistory } from 'react-router-dom'
import goBackIcon from '../../../icons/Icon_arrows_carrot_white-left.svg'
import { keyPressedType } from '../../../services/Commons'
import { getModifierClass, joinClasses } from '../../../utilities/helpers'
import './styles.scss'

export type BackButtonUIProps = {
  tabIndex?: number
  message: string
  removeLeftMargin?: boolean
}

const CONTAINER_CLASS = `back-button-container`

export const BackButtonUI = (props: BackButtonUIProps) => {
  const history = useHistory()

  return (
    <div
      tabIndex={props.tabIndex ?? 0}
      className={joinClasses([
        CONTAINER_CLASS,
        getModifierClass(
          CONTAINER_CLASS,
          'remove-left-margin',
          props.removeLeftMargin
        ),
      ])}
      onClick={() => {
        history.goBack()
      }}
      onKeyDown={(ev: any) => {
        if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
          history.goBack()
        }
      }}
    >
      <img style={{ cursor: 'pointer' }} src={goBackIcon} alt="go back icon" />
      <div className={`back-message`} style={{ cursor: 'pointer' }}>
        {props.message}
      </div>
    </div>
  )
}
