import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { SubAccountTree } from "../../../../components/AnnuitiesComponents/TPAManagement/SubAccountManagement/SubAccountTree";
import { KEY_CODES } from "../../../../constants.js";
import { BannerContext } from "../../../../context/BannerContext";
import { SubAccountContext } from "../../../../context/SubAccountContext";
import { keyPressedType } from "../../../../services/Commons";
import { TimePaymentAccountPayee } from "../../../../types/Annuities/PayeeManagement/Payee";
import { SubAccountTreeNode } from "../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { PaymentScheduleEntry } from "../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../types/Annuities/responses/time-payment-account";
import styles from "./css/styles.module.css";
import {
  getPaymentSchedule,
  getPlaceholderSubAccount,
  getPlaceholderTpa,
  getSubAccounts,
  getTpaDetails,
  getTpaPayeeList,
} from "./functions";

export const AnnuitySubAccountsPage: React.FC = () => {
  // routing config
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // get params
  const payeeId: string | null = searchParams.get("payeeId")!;
  const tpaId: string | null = searchParams.get("tpaId");
  if (!tpaId) {
    throw new Error("hello");
  }
  // Context
  const bannerContext = React.useContext(BannerContext);
  const subAccountContext = React.useContext(SubAccountContext);

  // State values
  const [parentNode, setParentNode] = useState<SubAccountTreeNode>(
    getPlaceholderSubAccount()
  );
  const [timePaymentAccountDetails, setTimePaymentAccountDetails] =
    useState<TimePaymentAccountResponse>(getPlaceholderTpa());
  const [tpaPayeeList, setTpaPayeeList] = useState<TimePaymentAccountPayee[]>(
    []
  );
  const [tpaPaymentSchedule, setTpaPaymentSchedule] = useState<
    PaymentScheduleEntry[]
  >([]);
  const [isPageInEditMode, setIsPageInEditMode] = useState<boolean>(false);

  useEffect(() => {
    const fetchSubAccounts = async () => {
      try {
        if (!tpaId) {
          throw new Error("Missing TPA ID in URL");
        }
        const parentNode = await getSubAccounts(tpaId);
        subAccountContext.setNewParentNode(parentNode);
        setParentNode(parentNode);
      } catch (error) {
        bannerContext.setBannerInfo({
          message: `${error}`,
          error: true,
        });
      }
    };

    // fetch payees for specific TPA
    const fetchPayees = async () => {
      try {
        if (!tpaId) {
          throw new Error("Missing TPA ID in URL");
        }
        const payeeList = await getTpaPayeeList(tpaId);
        setTpaPayeeList(payeeList);
      } catch (error) {
        bannerContext.setBannerInfo({
          message: `${error}`,
          error: true,
        });
      }
    };

    // fetch TPA Details
    const fetchTpa = async () => {
      try {
        if (!tpaId) {
          throw new Error("Missing TPA ID in URL");
        }
        const tpa = await getTpaDetails(tpaId);
        if (tpa) {
          setTimePaymentAccountDetails(tpa);
        }
      } catch (error) {
        bannerContext.setBannerInfo({
          message: `${error}`,
          error: true,
        });
      }
    };

    const fetchPaymentSchedule = async () => {
      try {
        if (!tpaId) {
          throw new Error("Missing TPA ID in URL");
        }
        const paymentSchedule = await getPaymentSchedule(tpaId);
        setTpaPaymentSchedule(paymentSchedule);
      } catch (error) {
        bannerContext.setBannerInfo({
          message: `${error}`,
          error: true,
        });
      }
    };

    fetchSubAccounts();
    fetchPayees();
    fetchTpa();
    fetchPaymentSchedule();
  }, []);

  return (
    <>
      <div className={styles["page-container"]}>
        {/* back btn jsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                history.go(-1);
              }
            }}
            onClick={() => {
              history.go(-1);
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles["back-btn-custom"]}
            />
            {payeeId === null ? (
              <div className={styles["back-btn-text"]}>
                Back to Time Payment Account Details
              </div>
            ) : (
              <div className={styles["back-btn-text"]}>
                Back to Payee Details
              </div>
            )}
          </button>
        </div>
        {/* Header component jsx */}
        <div className={styles["cmpnt-container"]}>
          <div className={styles["title-container"]}>
            <h2 className={styles["title-text"]}>Update Sub-Accounts</h2>
            <div className={styles["flex-right"]}>
              <div className={styles["document-id-label"]}>Document ID:</div>
              <div className={styles["document-id-value"]}>
                {timePaymentAccountDetails.documentId}
              </div>
            </div>
          </div>
        </div>
        {/* Main component */}
        <div className={styles["addtnl-cmpt-container"]}>
          <SubAccountTree
            treeRoot={parentNode}
            isPrimary={true}
            widthOffset={0}
            tpaPayeeList={tpaPayeeList}
            tpaDetails={timePaymentAccountDetails}
            tpaPaymentSchedule={tpaPaymentSchedule}
            isPageInEditModeObj={{ isPageInEditMode, setIsPageInEditMode }}
            parentIdentifier={""}
            index={0}
          />
        </div>
      </div>
    </>
  );
};
