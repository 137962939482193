
let increasing  = "";
export const testPhoneNumber = (e: any, formik: any,setPhoneNumberErrors:any) => {
   if(e.target.value.trim().length <= 0)
      return;
   const testForLetters = e.target.value.trim().split("").filter((e:any)=>
   {

      if( !(e === "(" || e === ")" || e === "-" ))
          return e;
   }).join("");
            
             
   
   if (isNaN(testForLetters)) {

        setPhoneNumberErrors("Letters are not allowed!");
         return;
      }
   

   if (e.target.value.trim().length === 1 && e.target.value > increasing)
      e.target.value = `(${e.target.value}`;
   else if (e.target.value.trim().length === 4 && e.target.value > increasing)
      e.target.value = `${e.target.value})`
   else if (e.target.value.trim().length === 8 && e.target.value > increasing)
      e.target.value += "-";
   else if (e.target.value.trim().length > 13) {
      setPhoneNumberErrors("Only 10 digits allowed!");
      return;
   }
   const testLeadingZero = e.target.value.trim().split("");
   if (testLeadingZero[1] === '0') {
      setPhoneNumberErrors("Leading zero is not allowed!");
      return;
   }
   increasing = e.target.value;
   setPhoneNumberErrors("");
   formik.setFieldValue(e.target.name, e.target.value, true);
}

let numberClearOfClick = 0;
let fieldName: string = "";
export const clearField = (e: any, formik: any) => {

   // if (numberClearOfClick === 0) {
   //    fieldName = e.target.name;
   // }
   // if (fieldName !== e.target.name) {
   //    fieldName = e.target.name;
   //    numberClearOfClick = 0;
   // }
   // if (numberClearOfClick >= 2) {
   //    formik.setFieldValue(e.target.name, "", false);
   //    numberClearOfClick = 0
   // }
   // else
   //    numberClearOfClick++;

}
export const resetClickCount = ()=>{
   numberClearOfClick = 0;
   increasing ="";

}