import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { agent1099QueueRoutePath } from '../../../config.js'
import { permissions } from '../../../constants.js'
import { checkRenderPermissions } from '../../../helpers.js'
import './styles.scss'
import { ConfirmationPopupUI } from '../../../components/CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui'

const ROOT_CLASS = 'agent-1099-queue-title-bar'

export type Agent1099QueueTitleBarUIProps = {
  showPrintCTA: boolean
  activatePrintCTA: boolean
  initiatePrint: () => void
}

export const Agent1099QueueTitleBarUI = (
  props: Agent1099QueueTitleBarUIProps
) => {
  const userpolicies: any = localStorage.getItem('userpolicies')
  const history = useHistory()

  const [showPrintConfirmation, setShowPrintConfirmation] = useState<boolean>(false)

  const optionallyRenderPrintCTA = (): React.ReactNode | undefined => {
    if (!props.showPrintCTA) {
      return undefined
    }

    return checkRenderPermissions(
      permissions.CAN_PRINT_1099_QUEUE,
      JSON.parse(userpolicies)
    ) ? (
      <button
        className={`green button`}
        style={{
          opacity: props.activatePrintCTA ? '1' : '0.4',
          cursor: props.activatePrintCTA ? 'pointer' : 'not-allowed',
        }}
        onClick={() => {
          if (props.activatePrintCTA) {
            setShowPrintConfirmation(true)
          }
        }}
      >
        <div className={`button-label`}>Print</div>
      </button>
    ) : null
  }

  return (
    <div className={ROOT_CLASS}>
      <div className={`title`}>Agent 1099 Search & Print Queue</div>
      <div className={`buttons-container`}>
        {optionallyRenderPrintCTA()}
        {checkRenderPermissions(
          permissions.CAN_SEE_1099_PRINT_HISTORY,
          JSON.parse(userpolicies)
        ) ? (
          <button
            className={`blue button`}
            onClick={() => {
              history.push(`${agent1099QueueRoutePath}/history`)
            }}
          >
            <div className={`button-label`}>Print History</div>
          </button>
        ) : null}
      </div>
      {showPrintConfirmation && (
        <ConfirmationPopupUI
          message="Are you sure you want to print these forms?"
          confirmCallback={() => {
            props.initiatePrint()
          }}
          closePopup={() => {
            setShowPrintConfirmation(false)
          }}
        />
      )}
    </div>
  )
}
