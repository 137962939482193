import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {keyPressedType} from "../../../../services/Commons";
import {PaginationCommonComponent} from "../../../CommonComponents/PaginationCommonComponent";
import licenseActiveIcon from "../../../../icons/Icon_status_approved.svg";
import licenseInactiveIcon from "../../../../icons/icon_denied.svg";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import {PermitHolderSearchResponseInterface} from "../../../../pages/CharitableGamingPages/Permit/PermitProfileManagementPage/interfaces";
import {getPageData} from "../../../../pages/CharitableGamingPages/Permit/PermitProfileManagementPage/functions";
import {permitRoutePath} from "../../../../config";
import moment from "moment";

export const PermitSearchResultComponent = ({permitHoldersList, currentPage, setCurrentPage,}: any) => {

    const history = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10;
        else return totalPages;
    };

    return (
        <>

            <div className={styles['table']}>
                <div className={styles['table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${permitHoldersList.length <= 0 ? 0 :
                        (currentPage * 10) + 1}-${normalizePage(permitHoldersList.length, currentPage)} 
                        of ${permitHoldersList.length} results`}</div>
                </div>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div tabIndex={0}>Raffle ID</div>
                    <div tabIndex={0}>Organization Name</div>
                    <div tabIndex={0}>Address</div>
                    <div tabIndex={0}>City/Town</div>
                    <div tabIndex={0}>Phone</div>
                    <div tabIndex={0}>Date of Issuance</div>
                    <div tabIndex={0}>Status</div>
                </div>

                <div className={styles['table-data-body']}>
                    {permitHoldersList.length > 0 &&
                    getPageData(permitHoldersList, currentPage).map((e: PermitHolderSearchResponseInterface, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div tabIndex={0} className={styles['table-data-data-text']}>{e.raffleId}</div>
                            <div tabIndex={0} className={styles['table-data-data-text']}>{e.orgName}</div>
                            <div tabIndex={0}
                                 className={styles['table-data-data-text']}>{e.address.streetAddress}</div>
                            <div tabIndex={0} className={styles['table-data-data-text']}>{e.address.city}</div>
                            <div tabIndex={0} className={styles['table-data-data-text']}>{e.phone}</div>
                            <div tabIndex={0}
                                 className={styles['table-data-data-text']}>{moment(e.effectiveDate).utc().format('MM/DD/YYYY')}</div>
                            <div tabIndex={0} className={styles['table-data-data-text']}>
                                {(e.orgActive) ? "Active" : "Expired"}
                                <img
                                    src={(e.orgActive) ? licenseActiveIcon : licenseInactiveIcon}
                                    alt="status icon"
                                />
                            </div>
                            <div className={styles['table-details-button-container']}>
                                {checkRenderPermissions(permissions.CAN_VIEW_PERMIT_DETAILS, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (
                                                 keyPressedType(en) === 13 ||
                                                 keyPressedType(en) === 0 ||
                                                 keyPressedType(en) === 32
                                             ) {
                                                 history.push(`${permitRoutePath}/view/${e.raffleId}`);
                                             }
                                         }}
                                         onClick={() => {
                                             history.push(`${permitRoutePath}/view/${e.raffleId}`);
                                         }}
                                         className={styles['table-details-button']}>
                                        <div aria-label={'Permit Details Button.'}
                                             className={styles['table-details-button-text']}>
                                            Details
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive" tabIndex={0}
                         className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((permitHoldersList.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={permitHoldersList} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>


        </>
    );
};
