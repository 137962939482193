import * as React from 'react'
import {
  WinnersManagementContactChecklistUIProps,
  WinnersManagementContactChecklistUI,
} from './winners-management-contact-checklist-ui'
import { WinnersManagementTierContext } from '../../winners-management-list-context'
import { useFetchUpdateContactChecklist } from '../../../../../api/promotions-fetcher/use-fetch-winners-management'

export type WinnersManagementContactChecklistContainerProps = Omit<
  WinnersManagementContactChecklistUIProps,
  | 'updateChecklistError'
  | 'updateChecklistLoading'
  | 'updatedChecklist'
  | 'submitUpdateContactChecklist'
>

export const WinnersManagementContactChecklistContainer = (
  props: WinnersManagementContactChecklistContainerProps
) => {
  const winnersManagementTierContext = React.useContext(
    WinnersManagementTierContext
  )

  const { data, isLoading, error, updateChecklist } =
    useFetchUpdateContactChecklist(
      winnersManagementTierContext.promotionId,
      winnersManagementTierContext.drawingGroupId,
      winnersManagementTierContext.drawingId,
      props.winner.id
    )

  return (
    <WinnersManagementContactChecklistUI
      {...props}
      updateChecklistError={error}
      updateChecklistLoading={isLoading}
      updatedChecklist={data}
      submitUpdateContactChecklist={updateChecklist}
    />
  )
}
