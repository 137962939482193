import * as React from 'react'
import { CouponCampaignPermissions } from '../../../api/promotions-fetcher/permissions'
import { CampaignBuilderContext } from '../../../pages/CampaignsPages/campaign-builder-context'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import {
  CampaignsListRowUI,
  CampaignsListRowUIProps,
} from './campaigns-list-row-ui'

type CampaignsListRowContainerProps = Omit<
  CampaignsListRowUIProps,
  'fileStatus' | 'endCampaignDisabled'
>

export const CampaignsListRowContainer = (
  props: CampaignsListRowContainerProps
) => {
  const fileStatusesContext = React.useContext(CampaignBuilderContext)

  return (
    <CampaignsListRowUI
      key={props.rowData.campaignIdentifier}
      rowData={props.rowData}
      setOpenPreviewImage={props.setOpenPreviewImage}
      showEditCampaign={props.showEditCampaign}
      fileStatus={fileStatusesContext.playerFilesStatusData?.get(
        props.rowData.campaignIdentifier
      )}
      endCampaignDisabled={
        !userHasAnyOfPermissions([
          CouponCampaignPermissions.CAN_END_COUPON_CAMPAIGNS,
        ])
      }
    />
  )
}
