import * as React from 'react'
import { WinnersManagementTierContext } from '../../winners-management-list-context'
import { ConfirmPopupModal } from '../../../../CommonComponents/ConfirmPopupModal/confirm-popup-modal'
import { useFetchPromoteAlternate } from '../../../../../api/promotions-fetcher/use-fetch-winners-management'

export type WinnersManagementPromoteAlternateContainerProps = {
  isOpen: boolean
  closeModal: () => void
  winnerId: string
}

export const WinnersManagementPromoteAlternateContainer = (
  props: WinnersManagementPromoteAlternateContainerProps
) => {
  const winnersManagementTierContext = React.useContext(
    WinnersManagementTierContext
  )

  const { isLoading, error, requestSuccessful, promoteAlternate } =
    useFetchPromoteAlternate(
      winnersManagementTierContext.promotionId,
      winnersManagementTierContext.drawingGroupId,
      winnersManagementTierContext.drawingId,
      props.winnerId
    )

  return (
    <ConfirmPopupModal
      headerText="Are you sure you would like to promote an alternate?"
      buttonText="Continue"
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      error={error}
      loading={isLoading}
      actionSuccessful={requestSuccessful}
      onSuccess={winnersManagementTierContext.refreshTiers}
      submitAction={promoteAlternate}
    />
  )
}
