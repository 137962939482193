import React, { SetStateAction, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaPlus, FaTrophy } from "react-icons/fa";
import { KEY_CODES } from "../../../../../constants.js";
import { keyPressedType } from "../../../../../services/Commons";
import { TimePaymentAccountPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { SubAccountTreeNode } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { PaymentScheduleEntry } from "../../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../../types/Annuities/responses/time-payment-account";
import { CheckboxComponent } from "../../../../CommonComponents/CheckboxComponent";
import { AccountStatusCmpt } from "../../../Common/AccountStatusCmpt";
import { AddSubAccountComponent } from "../AddSubAccountComponent";
import { SelectPayeeComponent } from "../SelectPayeeComponent";
import { UpdateSubAccountStatusComponent } from "../UpdateSubAccountStatusComponent";
import styles from "./css/styles.module.css";

export const AnnuityPrimarySubAccountComponent: React.FC<{
  treeRoot: SubAccountTreeNode;
  tpaPayeeList: TimePaymentAccountPayee[];
  tpaDetails: TimePaymentAccountResponse;
  tpaPaymentSchedule: PaymentScheduleEntry[];
  isPageInEditModeObj: {
    isPageInEditMode: boolean;
    setIsPageInEditMode: React.Dispatch<SetStateAction<boolean>>;
  };
}> = ({
  treeRoot,
  tpaPayeeList,
  tpaDetails,
  tpaPaymentSchedule,
  isPageInEditModeObj,
}) => {
  // destructuring the SubAccount Props
  const { account } = treeRoot;
  const { isPageInEditMode, setIsPageInEditMode } = isPageInEditModeObj;
  const { irsTaxEnabled, dorTaxEnabled, validFrom, validTo, id, acctStatus } =
    account;

  // State Variable for Component
  const [showSelectPayeeModal, setShowSelectPayeeModal] =
    useState<boolean>(false);
  const [showAddSubAccount, setShowAddSubAccount] = useState<boolean>(false);
  const [payeeObjectToAdd, setPayeeObjectToAdd] =
    useState<TimePaymentAccountPayee>();
  const [showUpdateAccountStatusModal, setShowUpdateAccountStatusModal] =
    useState<boolean>(false);
  const [showOnHoldAttentionModal, setShowOnHoldAttentionModal] =
    useState<boolean>(false);

  // package required to focus modal accessibility
  const FocusTrap = require("focus-trap-react");

  // initial federalTax and StateTax
  const initialFederalTax = "24";
  const initialStateTax = "5";

  const currencyFormatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  });

  // get specific Payee Name for this component
  const getPayeeSpecificName = (): string => {
    const payeeSpecific = tpaPayeeList.find(
      (payee) =>
        account.timePaymentAccountPayeeId === payee.timePaymentAccountPayeeId
    );

    if (payeeSpecific === undefined) {
      return "Name Not Found";
    } else {
      if (payeeSpecific.payeeType === "Individual") {
        return `${payeeSpecific.firstName} ${payeeSpecific.lastName}`;
      } else {
        return `${payeeSpecific.freeformName}`;
      }
    }
  };

  return (
    <>
      {/* Pop Up Modal for on hold attention */}
      {showOnHoldAttentionModal && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles["overlay-container"]}>
            <div className={styles["main-modal"]}>
              <div>
                <div className={styles["modal-title"]}>Attention!</div>
                <div className={styles["modal-desc"]}>
                  The primary account is currently on hold. Any sub-accounts
                  created will inherit the on hold status until the primary
                  account is updated to active. Do you want to proceed?
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setShowOnHoldAttentionModal(false);
                  setShowSelectPayeeModal(true);
                  setIsPageInEditMode(true);
                }}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setShowOnHoldAttentionModal(false);
                    setShowSelectPayeeModal(true);
                    setIsPageInEditMode(true);
                  }
                }}
                className={styles["modal-confirm-btn"]}
              >
                Add Sub-Account
              </button>
              <button
                type="button"
                onClick={() => setShowOnHoldAttentionModal(false)}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setShowOnHoldAttentionModal(false);
                  }
                }}
                role="button"
                className={styles["modal-cancel-btn"]}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
      {showSelectPayeeModal && (
        <SelectPayeeComponent
          setShowSelectPayee={setShowSelectPayeeModal}
          setShowAddSubAccount={setShowAddSubAccount}
          setPayeeObjectToAdd={setPayeeObjectToAdd}
          tpaPayeeList={tpaPayeeList}
          setIsPageInEditMode={setIsPageInEditMode}
        />
      )}
      {showUpdateAccountStatusModal && (
        <UpdateSubAccountStatusComponent
          setShowUpdateAccountStatusModal={setShowUpdateAccountStatusModal}
          subAccountDetails={account}
        />
      )}
      <div className={`${styles.page_container} w-100 `}>
        <div
          className={`${styles.sub_account_header} rounded d-flex justify-content-between align-items-center mt-3`}
        >
          <div className="d-flex  align-items-center ml-3">
            <div
              className={`${styles.primary_account_icon_container} text-center rounded mr-2`}
            >
              <FaTrophy />
            </div>
            <div
              className={`${styles.primary_account_text} font-weight-bold mr-3`}
            >
              Primary Account
            </div>
            <div className="d-flex align-items-center mr-2">
              <div className={`${styles.primary_account_text} mr-2`}>
                {getPayeeSpecificName()}
              </div>
              <div className={`${styles.icon_container}`}>
                <AiOutlineQuestionCircle size={25} />
                <div
                  className={`${styles.notes_container} rounded text-left ml-2 px-3 py-3`}
                >
                  <div className="font-weight-bold">Supporting Information</div>
                  {!account.supportingInformation1 &&
                  !account.supportingInformation2 ? (
                    <>
                      <p className="my-2">
                        No supporting information has been added.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="my-2">{account.supportingInformation1}</p>
                      <p>{account.supportingInformation2}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex align-items-center`}>
            <div className="mr-2">
              <AccountStatusCmpt
                status={account.acctStatus}
                notes={account.notes}
              />
            </div>
            <button
              className={`${styles.update_btn}`}
              disabled={isPageInEditMode || account.acctStatus === "Completed"}
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  setShowUpdateAccountStatusModal(true);
                }
              }}
              onClick={() => setShowUpdateAccountStatusModal(true)}
            >
              Update Status
            </button>
            <button
              disabled={isPageInEditMode}
              className={`${styles.light_btn}`}
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  if (account.acctStatus === "On Hold") {
                    setShowOnHoldAttentionModal(true);
                  } else {
                    setShowSelectPayeeModal(true);
                    setIsPageInEditMode(true);
                  }
                }
              }}
              onClick={() => {
                if (account.acctStatus === "On Hold") {
                  setShowOnHoldAttentionModal(true);
                } else {
                  setShowSelectPayeeModal(true);
                  setIsPageInEditMode(true);
                }
              }}
            >
              Add Sub-Account <FaPlus />
            </button>
          </div>
        </div>
        <div className={`${styles.sub_account_body}`}>
          <div className={`${styles.grid_col_2}`}>
            {/* Column 1 */}
            <div>
              <p>{` The gross payment and total recurring amount for this annuity account is ${currencyFormatter.format(
                tpaDetails.recurringPaymentAmount / 100
              )}`}</p>
            </div>
            {/* Column 2 */}
            <div className={`${styles.tax_info_container} rounded w-100`}>
              <h4>Tax Designation</h4>
              <p>
                Setting these tax designations relates only to this annuity
                account.
              </p>
              <div className={`d-flex`}>
                {/* Checkboxes */}
                <div className={`${styles.checkbox} mr-2`}>
                  <div className={`d-flex`}>
                    <p>Federal {initialFederalTax}% </p>
                    <CheckboxComponent isChecked={irsTaxEnabled} />
                  </div>
                </div>
                <div className={`${styles.checkbox}`}>
                  <div className="d-flex">
                    <p>State {initialStateTax}% </p>
                    <CheckboxComponent isChecked={dorTaxEnabled} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddSubAccount && (
        <AddSubAccountComponent
          parentNode={treeRoot}
          payee={payeeObjectToAdd}
          setShowAddSubAccount={setShowAddSubAccount}
          tpaDetails={tpaDetails}
          tpaPaymentSchedule={tpaPaymentSchedule}
          predeterminedWidth={100}
          setIsPageInEditMode={setIsPageInEditMode}
        />
      )}
    </>
  );
};
