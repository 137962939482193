import React from "react";
import FocusTrap from "focus-trap-react";
import './styles.scss'

export type ConfirmationPopupComponentUIProps = {
  message: string
  subMessage?: string
  confirmText?: string
  goBackText?: string
  confirmCallback: () => void
  closePopup: () => void
}

export const ConfirmationPopupUI = (props: ConfirmationPopupComponentUIProps) => {
    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={"confirmation-dialog-main-container"}>
                    <div
                        id="confirmation-dialog-container"
                        className={"confirmation-dialog-container"}
                    >
                        <div className={"confirmation-dialog-message"}>
                            { props.message }
                        </div>
                        {
                        props.subMessage ? 
                        <div className={`confirmation-dialog-sub-message`}>
                          { props.subMessage }
                        </div> : null
                        }
                        <button
                            onClick={() => {
                                props.confirmCallback()
                                props.closePopup()
                            }}
                            className={"confirmation-dialog-yes-button"}
                        >
                            <div className={"confirmation-dialog-yes-button-text"}>
                                {props.confirmText ?? 'Confirm'}
                            </div>
                        </button>
                        <button
                            onClick={() => {
                                props.closePopup()
                            }}
                            className={"confirmation-dialog-no-button"}>
                            {props.goBackText ?? 'Go back'}
                        </button>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};