import * as React from 'react'
import styles from './css/styles.module.css'

export const DocumentTableHeaderWithData = () => (
  <>
    <div
      className={
        styles['player-document-info-players-document-label-container']
      }
    >
      <div
        className={styles['player-document-info-players-document-text']}
        aria-label="Player's Documents"
        tabIndex={0}
      >
        Player's Documents
      </div>
    </div>
    <div
      className={`${styles['player-document-info-players-document-details-labels-container']}`}
    >
      <div
        className={`${styles['player-document-info-players-document-details-labels-text']} ${styles['player-document-info-players-document-details-labels-text-upload-date']}`}
        aria-label="Upload Date"
        tabIndex={0}
      >
        Upload Date
      </div>
      <div
        className={
          styles['player-document-info-players-document-details-labels-text']
        }
        aria-label="File Type"
        tabIndex={0}
      >
        File Type
      </div>
      <div
        className={
          styles['player-document-info-players-document-details-labels-text']
        }
        aria-label="Document Type"
        tabIndex={0}
      >
        Document Type
      </div>
    </div>
    <div style={{ width: '100%', height: '.7em' }}>
      <hr className={styles['document-image-outer-container-hr']} />
    </div>
  </>
)
