import React, {useState} from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const UpdateChargePaymentMethodComponent = ({updatePaymentMethod, setShowUpdateChargePaymentMethodDialog}: any) => {

    const [paymentMethod, setPaymentMethod] = useState<string>('');

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["permit-update-DOI-dialog-main-container"]}>
                    <div className={stylesMessageBox["permit-update-DOI-dialog-container"]}>
                        <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-1"]}>
                            <div className={stylesMessageBox["permit-update-DOI-dialog-title"]}>
                                Update Payment Method
                            </div>
                            <div className={stylesMessageBox["permit-update-DOI-dialog-message"]}>
                                Update the payment method for the charge by selecting an option below.
                            </div>
                            <div className={`${stylesMessageBox["radio-buttons-container"]}`}>
                                <div className={`${stylesMessageBox["radio-button-label-container"]}`}>
                                    <input
                                        tabIndex={0}
                                        className={`${stylesMessageBox["radio-button"]}`}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        type='radio'
                                        id="eft"
                                        name="paymentMethod"
                                        value={"EFT"}
                                        checked={paymentMethod === "EFT"}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setPaymentMethod(e.target.value);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setPaymentMethod(e.target.value);
                                        }}
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        htmlFor="eft">EFT
                                    </label>
                                </div>
                                <div className={`${stylesMessageBox["radio-button-label-container"]}`}>
                                    <input
                                        tabIndex={0}
                                        className={`${stylesMessageBox["radio-button"]}`}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        type='radio'
                                        id="cheque"
                                        name="paymentMethod"
                                        value={"CHECK"}
                                        checked={paymentMethod === "CHECK"}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setPaymentMethod(e.target.value);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setPaymentMethod(e.target.value);
                                        }}
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        htmlFor="cheque">Check
                                    </label>
                                </div>
                                <div className={`${stylesMessageBox["radio-button-label-container"]}`}>
                                    <input
                                        tabIndex={0}
                                        className={`${stylesMessageBox["radio-button"]}`}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        type='radio'
                                        id="free"
                                        name="paymentMethod"
                                        value={"FREE"}
                                        checked={paymentMethod === "FREE"}
                                        onKeyDown={(e: any) => {
                                            if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                                setPaymentMethod(e.target.value);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setPaymentMethod(e.target.value);
                                        }}
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        htmlFor="free">Free
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-2"]}>
                            <div
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        updatePaymentMethod(paymentMethod, false);
                                        setShowUpdateChargePaymentMethodDialog(false);
                                    }
                                }}
                                onClick={() => {
                                    updatePaymentMethod(paymentMethod, false);
                                    setShowUpdateChargePaymentMethodDialog(false);
                                }}
                                className={stylesMessageBox["permit-update-DOI-dialog-yes-button"]}
                            >
                                <div
                                    className={
                                        stylesMessageBox["permit-update-DOI-dialog-yes-button-text"]
                                    }
                                >
                                    Update
                                </div>
                            </div>
                            <div
                                tabIndex={0}
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        setShowUpdateChargePaymentMethodDialog(false);
                                    }
                                }}
                                role="button"
                                onClick={() => {
                                    setShowUpdateChargePaymentMethodDialog(false);
                                }}
                                className={
                                    stylesMessageBox["permit-update-DOI-dialog-no-button"]
                                }
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};