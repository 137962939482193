import * as React from 'react'
import { CampaignDetails } from '../../../../api/promotions-fetcher/coupon-campaigns/constants'
import { ImagePreviewUI } from '../../../FormFileUploadComponent/file-preview-component/image-preview-component/image-preview-component-ui'
import { CampaignSideBarPlayersContainer } from '../campaign-side-bar-barcode-details/campaign-side-bar-players-container'
import './styles.scss'

const ROOT_CLASS = 'edit-campaign-sidebar'
const HEADER_CLASS = `${ROOT_CLASS}__header`
const DETAILS_CLASS = `${ROOT_CLASS}__details`
const FIELD_CLASS = `${DETAILS_CLASS}__field`

export type EditCampaignSideBarProps = {
  closeSidebar: () => void
  campaignDetails?: CampaignDetails
}

const getTextField = (fieldName: string, fieldText: string | undefined) => {
  return (
    <div className={`${FIELD_CLASS}`}>
      <div className={`${FIELD_CLASS}__title`}>{fieldName}</div>
      <p className={`${FIELD_CLASS}__text`}>{fieldText}</p>
    </div>
  )
}

export const EditCampaignSideBarUI = (props: EditCampaignSideBarProps) => (
  <div className={ROOT_CLASS}>
    <header className={HEADER_CLASS}>
      <div className={`${HEADER_CLASS}__title`}>Edit Campaign</div>
      <button
        className={`${HEADER_CLASS}__dismiss-button`}
        onClick={props.closeSidebar}
        aria-label="Close"
      >
        <div>Close</div>
      </button>
    </header>
    {props.campaignDetails && (
      <>
        <div className={DETAILS_CLASS}>
          {getTextField('Campaign Name', props.campaignDetails.displayName)}
          {getTextField(
            'Campaign Start & End Dates',
            `${props.campaignDetails.activeStartDate}-${props.campaignDetails.activeEndDate}`
          )}
          {getTextField(
            'Coupon Expiration Date',
            props.campaignDetails.expirationDate
          )}
          {getTextField('Legal Text', props.campaignDetails.legalText)}
          <div className={`${FIELD_CLASS}`}>
            <div className={`${FIELD_CLASS}__title`}>Artwork</div>
            <ImagePreviewUI
              imagePreviews={[
                {
                  name: `${props.campaignDetails.campaignIdentifier} Artwork Preview`,
                  preview: props.campaignDetails.coverImageUrl,
                },
              ]}
              previewOnly
            />
          </div>
          {getTextField(
            'Campaign Promotion ID',
            props.campaignDetails.campaignIdentifier
          )}
        </div>
        <CampaignSideBarPlayersContainer
          closeSideBar={props.closeSidebar}
          disabled={false}
          campaignIdentifier={props.campaignDetails.campaignIdentifier}
          barcodes={props.campaignDetails.barcodes}
        />
      </>
    )}
  </div>
)
