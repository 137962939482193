import moment from "moment";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages";
import config from "../../../../config";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";

let formik: any;
let charitableGamingStore: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let EFTLedger: any;
let setEFTLedger: any;
let selectedChargeIds: any;
let setSelectedChargeIds: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, setCurrentPageObj: any, setShowErrorDialogObj: any, setRequestCompletedObj: any, EFTLedgerObj: any, setEFTLedgerObj: any, selectedChargeIdsObj: any, setSelectedChargeIdsObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    EFTLedger = EFTLedgerObj;
    setEFTLedger = setEFTLedgerObj;
    selectedChargeIds = selectedChargeIdsObj;
    setSelectedChargeIds = setSelectedChargeIdsObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    if (values.startChargeDate && values.endChargeDate) {
        const a = moment(values.startChargeDate);
        const b = moment(values.endChargeDate);
        if (b.diff(a, 'days') > 365) {
            Object.assign(errors, {startChargeDate: InputValidationMessages.InvalidDateRange});
            // Object.assign(errors, {endChargeDate: InputValidationMessages.InvalidDateRange});
        }
    }

    if (values.licenseNumber && values.licenseNumber.trim().length < 3) {
        Object.assign(errors, {licenseNumber: InputValidationMessages.InvalidLicenseNumberLength});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    getEFTLedger(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            expenseType: '',
            chargeStatus: '',
            startChargeDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endChargeDate: moment().format('YYYY-MM-DD'),
            licenseNumber: ''
        }
    });

    formik.submitForm();

}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

export const getEFTLedger = async (values: any) => {

    // alert(JSON.stringify(values));

    setCurrentPage(0);

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        /*expenseType: null,*/
        chargeStatus: 'READY_FOR_SWEEP', dateRange: {
            startDate: values.startChargeDate + 'T00:00:00.000Z',
            endDate: values.endChargeDate + 'T23:59:59.999Z',
        }, orgDetails: {
            chargeType: 'LICENSEE'
        }
    }

    if (values.licenseNumber !== '') {
        body.orgDetails.identifier = values.licenseNumber;
    }

    const expenseType = values.expenseType.toLowerCase();
    if (expenseType === 'weekly taxes') {
        body.expenseType = 'WEEKLY_BEANO';
    } else if (expenseType === 'pull tab order') {
        body.expenseType = 'PURCHASE_ORDER';
    } else if (expenseType === 'license renewal fee') {
        body.expenseType = 'LICENSE_RENEWAL';
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/charge/Search`, data: body
        }

        let response = await makeHttpCall(options);

        setEFTLedger(response);

        setRequestCompleted(true);

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const YesHoldCharges = async () => {

    // alert(JSON.stringify(chargeIds));

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        chargeIds: Array.from(selectedChargeIds)
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'PATCH', url: `${config.SERVER_BASE_URL}/api/v1/charge/on-hold`, data: body
        }

        let response = await makeHttpCall(options);

        setRequestCompleted(true);

        setSelectedChargeIds(new Set());

        formik.submitForm();

        charitableGamingStore.showNotificationBanner({
            show: true, type: NotificationType.Success, text: 'The charge status was successfully updated.'
        });

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const RunEFTSweep = async () => {

    // alert(chargeIds.length);

    let chargeIds = [];
    for (let charge of EFTLedger.charges) {
        chargeIds.push(charge.chargeId);
    }

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        chargeIds: chargeIds
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/charge/eft-sweep`, data: body
        }

        let response = await makeHttpCall(options);

        setRequestCompleted(true);

        formik.submitForm();

        charitableGamingStore.showNotificationBanner({
            show: true, type: NotificationType.Success, text: 'The EFT sweep run was successful.'
        });

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}
