import React from 'react';
import "./ComingSoon.css";

function ComingSoon() {
    return (
        <div className="comingsoon">
            <h1>Coming Soon...</h1>
        </div>
    );
}

export default ComingSoon;
