import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {PaginationCommonComponent} from "../../../../CommonComponents/PaginationCommonComponent";
import {
    getPageData
} from "../../../../../pages/CharitableGamingPages/PullTab/Inventory/PullTabStockInventoryPage/functions";
import moment from "moment";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../../services/Commons";
import {pulltabRoutePath} from "../../../../../config.js";
import {permissions} from "../../../../../constants";
import {checkRenderPermissions} from "../../../../../helpers";
import {SearchResultExeceededInfoMessageComponent} from "../../../Common/SearchResultExeceededInfoMessageComponent";
import ExclamationIcon from "../../../../../icons/icon_status_flagged.svg";

export const PullTabOrderItemHistorySearchResultComponent = inject("charitableGamingStore")(observer(({
                                                                                                          charitableGamingStore,
                                                                                                          currentPage,
                                                                                                          setCurrentPage
                                                                                                      }: any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const MAX_RESULT = 500;

    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);

    const {pullTabOrderItemHistory} = charitableGamingStore;

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        setTimeout(function () {
            setShowSearchResultExceededInfoMessage(false);
        }, 3000);
    }, []);

    return (<>

            <div className={styles['table']}>
                <div className={styles['table-data-header']}>
                    <div className={styles['table-data-header-text']}
                         aria-live="assertive">{`Displaying ${pullTabOrderItemHistory.records.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(pullTabOrderItemHistory.records.length, currentPage)} 
                        of ${pullTabOrderItemHistory.records.length}${pullTabOrderItemHistory.additionalResults ? "+" : ""} results`}</div>
                    {pullTabOrderItemHistory.additionalResults ? <div
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                            setShowSearchResultExceededInfoMessage(true);
                        }}
                        onMouseLeave={() => {
                            setShowSearchResultExceededInfoMessage(false);
                        }}
                    >
                        <img
                            src={ExclamationIcon}
                            height={20}
                            width={20}
                            alt="exclamation icon"
                        />
                        {pullTabOrderItemHistory.additionalResults && showSearchResultExceededInfoMessage ?
                            <SearchResultExeceededInfoMessageComponent/> : null}
                    </div> : null}

                </div>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>Purchase Order No.</div>
                    <div>Stock No.</div>
                    <div>Serial Numbers</div>
                    <div>Quantity</div>
                    <div>Warehouse</div>
                    <div>Organization Name</div>
                    <div>License No.</div>
                    <div>Order Date</div>
                </div>

                <div className={styles['table-data-body']}>
                    {pullTabOrderItemHistory.records.length > 0 && getPageData(pullTabOrderItemHistory.records, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                {e.purchaseOrderId}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.stockNumber}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.serialNumbers}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.numUnits}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.warehouseId}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.orgName}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.licenseNumber}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.date).utc().format('MM/DD/YYYY')}
                            </div>
                            <div className={styles['table-details-button-container']}>
                                {checkRenderPermissions(permissions.CAN_SEE_PULL_TAB_ORDER_DETAILS, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {
                                                 history.push({
                                                     pathname: `${pulltabRoutePath}/orders/details/${e.purchaseOrderId}`,
                                                     state: {
                                                         backButtonText: 'Back to Order Item History'
                                                     },
                                                 });
                                             }
                                         }}
                                         onClick={() => {
                                             history.push({
                                                 pathname: `${pulltabRoutePath}/orders/details/${e.purchaseOrderId}`,
                                                 state: {
                                                     backButtonText: 'Back to Order Item History'
                                                 },
                                             });
                                         }}
                                         className={styles['table-details-button']}>
                                        <div aria-label={'Pull-tab Order Item Details Button.'}
                                             className={styles['table-details-button-text']}>
                                            Details
                                        </div>
                                    </div> : null}
                            </div>
                        </div>)}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((pullTabOrderItemHistory.records.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={pullTabOrderItemHistory.records} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>

        </>);
}));
