import React from 'react'
import { RouteProps, Switch, withRouter } from 'react-router-dom'
import { permissions } from '../../../constants'
import { checkRenderPermissions } from '../../../helpers.js'
import { CommonLandingPage } from '../../CommonsPage/CommonLandingPage'
import {
  ButtonInfo,
  PageInfo,
} from '../../CommonsPage/CommonLandingPage/interfaces'
import { NotFound404Page } from '../../CommonsPage/NotFound404Page'
import { AnnuitiesCreatePayeePage } from '../CreatePayeePage'
import { AnnuitiesCreationFlow } from '../CreationFlowPage'
import { FirstPaymentDetailPage } from '../FirstPayment/AnnuitiesFirstPaymentDetailPage'
import { AnnuitiesFirstPaymentPage } from '../FirstPayment/AnnuitiesFirstPaymentPage'
import { AnnuitiesPayeeDetailsPage } from '../PayeeManagement/AnnuitiesPayeeDetailsPage'
import { AnnuitiesPayeeManagementPage } from '../PayeeManagement/AnnuitiesPayeeManagementPage'
import { AnnuityFullPaymentSchedulePage } from '../TPAManagement/AnnuityFullPaymentSchedulePage'
import { AnnuityManagementDetailsPage } from '../TPAManagement/AnnuityManagementDetailsPage'
import { AnnuityManagementPage } from '../TPAManagement/AnnuityManagementPage'
import { AnnuitySubAccountsPage } from '../TPAManagement/AnnuitySubAccountsPage'
import { AuthorizationHistoryPage } from '../TimePaymentAuthorization/AuthorizationHistoryPage'
import { AuthorizationManagementPage } from '../TimePaymentAuthorization/AuthorizationManagementPage'
import { PaymentListPage } from '../TimePaymentAuthorization/PaymentListPage'
import { PaymentSearchPage } from '../TimePaymentAuthorization/PaymentSearchPage'
import { AuthorizedPaymentDetails } from '../payment-details/authorized-payment-details'
import { PreAuthorizationPaymentDetails } from '../payment-details/pre-authorization-payment-details'
import { PaymentDetailsPage } from '../payment-details/time-payment-account-payment-details'
import { annuitiesRoutePath } from './../../../config.js'
import { PrivateRoute } from './../../../routes'

export const ANNUITY_PAGES = {
  LandingPage: { path: `${annuitiesRoutePath}/`, title: '' },
  FirstPaymentList: {
    path: `${annuitiesRoutePath}/first-payment-list`,
    title: 'First Payment List',
  },
  FirstPaymentDetails: {
    path: `${annuitiesRoutePath}/first-payment-details`,
    title: 'First Payment Details',
  },
  TimePaymentAccountCreation: {
    path: `${annuitiesRoutePath}/annuity-creation-flow`,
    title: 'Time Payment Account Creation',
  },
  TimePaymentAccountManagement: {
    path: `${annuitiesRoutePath}/annuity-management`,
    title: 'Time Payment Account Management',
  },
  TimePaymentAccountDetails: {
    path: `${annuitiesRoutePath}/annuity-management-details`,
    title: 'Time Payment Account Details',
  },
  TimePaymentAccountPaymentDetails: {
    path: `${annuitiesRoutePath}/annuity-full-payment-details`,
    title: 'Payment Details',
  },
  UpdateSubAccounts: {
    path: `${annuitiesRoutePath}/annuity-update-sub-accounts`,
    title: 'Update Sub-Accounts',
  },
  TimePaymentAccountPaymentSchedule: {
    path: `${annuitiesRoutePath}/annuity-full-payment-schedule`,
    title: 'Payment Schedule',
  },
  PayeeManagement: {
    path: `${annuitiesRoutePath}/payee-management`,
    title: 'Payee Management',
  },
  PayeeDetails: {
    path: `${annuitiesRoutePath}/payee-details`,
    title: 'Payee Details',
  },
  PayeeCreation: {
    path: `${annuitiesRoutePath}/create-payee`,
    title: 'Create Payee',
  },
  TimePaymentAuthorization: {
    path: `${annuitiesRoutePath}/time-payment-authorization`,
    title: 'Time Payment Authorization',
  },
  TimePaymentAuthorizationHistory: {
    path: `${annuitiesRoutePath}/time-payment-authorization-history`,
    title: 'Authorization History',
  },
  TimePaymentAuthorizationPaymentList: {
    path: `${annuitiesRoutePath}/time-payment-authorization-list`,
    title: 'Payment List',
  },
  PaymentSearch: {
    path: `${annuitiesRoutePath}/time-payment-authorization-search`,
    title: 'Payment Search',
  },
  PreAuthorizationPaymentDetails: {
    path: `${annuitiesRoutePath}/authorization-payment-details`,
    title: 'Payment Details',
  },
  AuthorizedPaymentDetails: {
    path: `${annuitiesRoutePath}/authorization-payment-details/:paymentId`,
    title: 'Payment Details',
  },
}

const AnnuitiesRoutes = ({ props, storesObject }: any) => {
  const userpolicies: any = localStorage.getItem('userpolicies')

  const landingObject: ButtonInfo[] = [
    {
      buttonTitle: 'Locate Payment and Create Annuity',
      buttonPermission: `${permissions.CAN_SEE_LOCATE_PAYMENT_CREATE_ANNUITY}`,
      buttonRoute: `${annuitiesRoutePath}/first-payment-list`,
    },
    {
      buttonTitle: 'Create Exception Annuity',
      buttonPermission: `${permissions.CAN_SEE_CREATE_EXCEPTION_ANNUITY}`,
      buttonRoute: `${annuitiesRoutePath}/annuity-creation-flow`,
    },
    {
      buttonTitle: 'Manage Annuity',
      buttonPermission: `${permissions.CAN_SEE_MANAGE_ANNUITIY}`,
      buttonRoute: `${annuitiesRoutePath}/annuity-management`,
    },
    {
      buttonTitle: 'Payee Management',
      buttonPermission: `${permissions.CAN_SEE_MANAGE_PAYEE}`,
      buttonRoute: `${annuitiesRoutePath}/payee-management`,
    },
    {
      buttonTitle: 'Time Payment Authorization',
      buttonPermission: `${permissions.CAN_SEE_MANAGE_TIME_PYMT_AUTH}`,
      buttonRoute: `${annuitiesRoutePath}/time-payment-authorization`,
    },
  ]

  const landingPageInfo: PageInfo = {
    title: 'Annuities',
    policy: permissions.CAN_SEE_ANNUITIES,
    buttonData: landingObject,
    backButtonText: '',
  }

  return (
    <>
      <Switch>
        {checkRenderPermissions(
          permissions.CAN_SEE_LOCATE_PAYMENT_CREATE_ANNUITY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.FirstPaymentList.path}
            component={(props: any) => <AnnuitiesFirstPaymentPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_LOCATE_PAYMENT_CREATE_ANNUITY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.FirstPaymentDetails.path}
            component={(props: any) => <FirstPaymentDetailPage />}
          />
        ) : null}

        {checkRenderPermissions(
          permissions.CAN_SEE_CREATE_EXCEPTION_ANNUITY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAccountCreation.path}
            component={(props: any) => <AnnuitiesCreationFlow />}
          />
        ) : null}

        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_ANNUITIY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAccountManagement.path}
            component={(props: any) => <AnnuityManagementPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_ANNUITIY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAccountDetails.path}
            component={(props: any) => <AnnuityManagementDetailsPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_ANNUITIY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAccountPaymentSchedule.path}
            component={(props: any) => <AnnuityFullPaymentSchedulePage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_ANNUITIY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAccountPaymentDetails.path}
            component={(props: any) => <PaymentDetailsPage />}
          />
        ) : null}

        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_ANNUITIY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.UpdateSubAccounts.path}
            component={(props: any) => <AnnuitySubAccountsPage />}
          />
        ) : null}

        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_PAYEE,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.PayeeManagement.path}
            component={(props: any) => <AnnuitiesPayeeManagementPage />}
          />
        ) : null}

        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_PAYEE,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.PayeeDetails.path}
            component={(props: any) => <AnnuitiesPayeeDetailsPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_ANNUITIES,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.PayeeCreation.path}
            component={(props: any) => <AnnuitiesCreatePayeePage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_MANAGE_TIME_PYMT_AUTH,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAuthorization.path}
            component={(props: any) => <AuthorizationManagementPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_TIME_PYMT_AUTH_SEARCH,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.PaymentSearch.path}
            component={(props: any) => <PaymentSearchPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_TIME_PYMT_AUTH_LIST,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAuthorizationPaymentList.path}
            component={(props: any) => <PaymentListPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_TIME_PYMT_AUTH_HISTORY,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.TimePaymentAuthorizationHistory.path}
            component={(props: any) => <AuthorizationHistoryPage />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_TIME_PYMT_AUTH_DETAILS,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.AuthorizedPaymentDetails.path}
            component={(props: any) => <AuthorizedPaymentDetails />}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_TIME_PYMT_AUTH_DETAILS,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.PreAuthorizationPaymentDetails.path}
            component={(props: RouteProps) => (
              <PreAuthorizationPaymentDetails location={props.location} />
            )}
          />
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_ANNUITIES,
          JSON.parse(userpolicies)
        ) ? (
          <PrivateRoute
            path={ANNUITY_PAGES.LandingPage.path}
            component={(props: any) => (
              <CommonLandingPage {...landingPageInfo} />
            )}
          />
        ) : null}

        <PrivateRoute
          path={`/`}
          component={(props: any) => <NotFound404Page />}
        />
      </Switch>
    </>
  )
}
export default withRouter(AnnuitiesRoutes)
