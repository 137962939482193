import * as React from 'react'
import { permissions } from '../../../../constants'
import { checkRenderPermissions } from '../../../../helpers'
import { GetPendingReissuePaymentsResponse } from '../../../../api/pending-reissue-payments-fetcher/api-types'
import './styles.scss'
import { useState } from 'react'
import { ConfirmationPopupUI } from '../../../CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui'
import { initiateReissueRun } from './initiate-reissue-run'
import { useHistory } from 'react-router-dom'
import { reissueHistoryRoutePath } from '../../../../config.js'

const ROOT_CLASS = 'reissue-queue-title-bar'

export type ReissueQueueTitleBarUIProps = {
  activateInitiateButton: boolean
  pendingReissuesData: GetPendingReissuePaymentsResponse
  fetchInitiateReissueRun: (futurePaymentIds: string[]) => void
}

export const ReissueQueueTitleBarUI = (
  props: ReissueQueueTitleBarUIProps
) => {
  const history = useHistory()

  const userpolicies: any = localStorage.getItem('userpolicies')
  const [showInitiateConfirmation, setShowInitiateConfirmation] =
    useState<boolean>(false)

  const optionallyRenderInitiateCTA = (
    activateInitiateButton: boolean
  ): React.ReactNode => {
    return checkRenderPermissions(
      permissions.CAN_INITIATE_CHECK_RUN,
      JSON.parse(userpolicies)
    ) ? <button
      className={`green button`}
      style={{
        opacity: activateInitiateButton ? '1' : '0.4',
        cursor: activateInitiateButton ? 'pointer' : 'not-allowed'
      }}
      onClick={() => {
        if(activateInitiateButton) {
          setShowInitiateConfirmation(true)
        }
      }}
    >
      <div className={`button-label`}>Initiate Re-Issue</div>
    </button> : null
  }

  return (
    <div className={ROOT_CLASS}>
      <div className={`title`}>Re-Issue Queue</div>
      <div className={`buttons-container`}>
        {checkRenderPermissions(
          permissions.CAN_SEE_REISSUE_HISTORY,
          JSON.parse(userpolicies)
        ) ? (
          <button
            className={`blue button`}
            onClick={() => {
              history.push(`${reissueHistoryRoutePath}`)
            }}>
              <div className={`button-label`}>Re-Issue History</div>
          </button>
        ) : null
        }
        {optionallyRenderInitiateCTA(props.activateInitiateButton)}
      </div>
      {showInitiateConfirmation && (
        <ConfirmationPopupUI
          message="Are you sure you want to initiate a re-issue?"
          subMessage="This action cannot be undone."
          confirmText="Initiate"
          confirmCallback={() => {
            initiateReissueRun(props.pendingReissuesData, props.fetchInitiateReissueRun)
          }}
          closePopup={() => {
            setShowInitiateConfirmation(false)
          }}
        />
      )}
    </div>
  )
}