export const getPageData = (PageData: any[], page: number = 0) => {
    //debugger;
    const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(PageData[i] === undefined)) {
            array.push(PageData[i]);
        }
    }
    return array;
}

export const normilizeDate = (date: string) => {
    let dateArray = date.split('Z');
    dateArray = dateArray[0].split('T');
    date = dateArray[0].split("-").join('/');
    let time = dateArray[dateArray.length - 1].split('.');

    return `${date} at ${time[0]}`;

}