import * as React from 'react'
import {
  PromotionalMessageSidebarFormUI,
  PromotionalMessageSidebarFormUIProps,
} from './promotional-message-sidebar-form-ui'
import { PromotionalMessagesPageContext } from '../../../../pages/PromotionsPages/promotional-messages-page/promotional-messages-context'
import {
  useFetchCreatePromotionMessage,
  useFetchUpdatePromotionMessage,
} from '../../../../api/playslips-fetcher/use-fetch-playslips'

export type PromotionalMessageSidebarFormContainerProps = Pick<
  PromotionalMessageSidebarFormUIProps,
  'activeGames'
>

export const PromotionalMessageSidebarFormContainer = (
  props: PromotionalMessageSidebarFormContainerProps
) => {
  const promotionalMessagesContext = React.useContext(
    PromotionalMessagesPageContext
  )

  const {
    data: createData,
    isLoading: createIsLoading,
    error: createError,
    requestSuccessful: createSuccessful,
    createPromotionMessage,
  } = useFetchCreatePromotionMessage()

  const {
    isLoading: updateIsLoading,
    error: updateError,
    requestSuccessful: updateSuccessful,
    updatePromotionMessage,
  } = useFetchUpdatePromotionMessage()

  return (
    <PromotionalMessageSidebarFormUI
      {...promotionalMessagesContext}
      activeGames={props.activeGames}
      isLoading={createIsLoading || updateIsLoading}
      error={createError ?? updateError}
      messageId={createData?.id}
      createSuccessful={createSuccessful}
      updateSuccessful={updateSuccessful}
      createPromotionMessage={createPromotionMessage}
      updatePromotionMessage={updatePromotionMessage}
    />
  )
}
