import {InputValidationMessages} from "../../../../Constants/InputValidationMessages";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import config from "../../../../config";
import axios from "axios";

let formik: any;
let charitableGamingStore: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setAnnualBeanoReports: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, setCurrentPageObj: any, setShowErrorDialogObj: any, setRequestCompletedObj: any, setAnnualBeanoReportsObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setAnnualBeanoReports = setAnnualBeanoReportsObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    if (values.licenseNumber && values.licenseNumber.trim().length < 3) {
        Object.assign(errors, {licenseNumber: InputValidationMessages.InvalidLicenseNumberLength});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    getAnnualBeanoReports(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            year: (new Date().getFullYear() - 1).toString(), licenseNumber: ''
        }
    });

    formik.submitForm();

}

export const getPageData = (annualBeanoReports: any[], page: number = 0) => {
    const array: any = [];
    // let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(annualBeanoReports[i] === undefined)) {
            // annualBeanoReports[i].index = index;
            // index++;
            array.push(annualBeanoReports[i]);
        }
    }
    return array;
}

export const getAnnualBeanoReports = async (values: any) => {

    // alert(JSON.stringify(values));

    setCurrentPage(0);

    setRequestCompleted(false); // Display Circular Progress

    let query = '';

    if (values.year !== '') {
        query = `?year=${Number(values.year)}`
    }

    if (values.licenseNumber !== '') {
        query = query + `&licenseNumber=${values.licenseNumber}`;
    }

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/report/annual-beano-year${query}`
        }

        // alert(options.url);

        let response = await makeHttpCall(options);

        setAnnualBeanoReports(response.yearlyLicenseeData);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const downloadCSV = async function () {

    const values: any = formik.values;

    let query = '';

    if (values.year !== '') {
        query = `?year=${Number(values.year)}`
    }

    if (values.licenseNumber !== '') {
        query = query + `&licenseNumber=${values.licenseNumber}`;
    }

    try {

        const options: any = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/report/annual-beano-year/csv${query}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json text/csv',
            }
        }

        // alert(options.url);

        const response = await axios(options);

        let file = new Blob([response.data], {type: 'text/csv'});
        let fileURL = URL.createObjectURL(file);

        // Create <a> tag dynamically
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;

        let csvName = `annual_beano_report_${formik.values.year}.csv`;

        if (formik.values.licenseNumber) {
            csvName = `annual_beano_report_${formik.values.licenseNumber}_${formik.values.year}.csv`;
        }

        // Forces the name of the downloaded file
        fileLink.download = response.headers['download-file-name'] ? response.headers['download-file-name'] : csvName;

        // Trigger the click event
        fileLink.click();

    } catch (e) {
        setShowErrorDialog(true);
    }

}