import React from 'react'
import { GetPaymentDetailsResponse } from '../../../../api/payment-details-fetcher/api-types'
import { ModalOverlayContainer } from '../../../CommonComponents/modal-container/modal-container-component'
import {useHistory} from "react-router-dom";
import {paymentsRoutePath} from "../../../../config";
import { checkRenderPermissions, checkAllRenderPermissions } from "../../../../helpers"
import { permissions } from "../../../../constants"
import './styles.scss'


const ROOT_CLASS = 'payment_details_void_options'

type VoidOptionsUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
  setShowVoidOptionsModal: React.Dispatch<React.SetStateAction<boolean>>
  showConfirmVoidDialog: () => void
}

export const VoidOptionsUI = (props: VoidOptionsUIProps) => {
  const voidPaymentHandler = () => {
    props.setShowVoidOptionsModal(false)
    props.showConfirmVoidDialog()
  }

  const history: any = useHistory()

  const userpolicies: any = localStorage.getItem("userpolicies")

  const canSeeVoidReissueButton = checkAllRenderPermissions([permissions.CAN_VOID_AND_REISSUE_PAYMENT, permissions.CAN_SEE_SSN], JSON.parse(userpolicies))
  const canSeeVoidButton = checkRenderPermissions(permissions.CAN_VOID_PAYMENTS, JSON.parse(userpolicies))

  return (
    <ModalOverlayContainer>
      <div className={ROOT_CLASS}>
        <header>What would you like to do with this payment?</header>
        {
          canSeeVoidReissueButton &&
          <button
            className="void-re-issue-button"
            onClick={() => history.push(`${paymentsRoutePath}/create-void-reissue/${props.paymentDetailsData.payment.internalPaymentId}`)}
          >
          Void &amp; Re-Issue
          </button>
        }
        {
          canSeeVoidButton &&
          <button
            className="void-button"
            onClick={() => voidPaymentHandler()}
          >
            Void
          </button>
        }
        <p>
          <a className="cancel-link" role="button" onClick={() => props.setShowVoidOptionsModal(false)}>
            Cancel
          </a>
        </p>
      </div>
    </ModalOverlayContainer>
  )
}
