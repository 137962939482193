import config from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";


let raffleId: string;
let charitableGamingStore: any;

export const setVariables = (purchaseOrderIdString: string, charitableGamingStoreObj: any) => {
    raffleId = purchaseOrderIdString;
    charitableGamingStore = charitableGamingStoreObj;
}

export const getPurchaseOrderDetails = async (purchaseOrderId: string, setSelectedPurchaseOrder: any, setRequestCompleted: any) => {

    try {

        const options = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/purchase-order/${purchaseOrderId}`,
        }

        let response = await makeHttpCall(options);

        if (response.hasOwnProperty('purchaseOrder')) {
            setSelectedPurchaseOrder(response);
        }

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
    }

}



