import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, getAllActiveLicensees, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../../components/CommonComponents/GenericErrorDialogComponent";
import {NotificationBannerComponent} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {
    PullTabOrderCreationStepOneSearchBarComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Orders/PullTabOrderCreationStepOneSearchBarComponent";
import {
    PullTabOrderCreationStepOneSearchResultComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Orders/PullTabOrderCreationStepOneSearchResultComponent";
import {pageMessageDefault} from "../../../../../components/CommonComponents/PageMessageCommonComponent";

export const PullTabOrderCreationStepOnePage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        let backButtonText = localStorage.getItem("PullTabOrderCreationStepOnePageBackButtonText");
        let backURL = localStorage.getItem("PullTabOrderCreationStepOnePageBackURL");

        const userpolicies: any = localStorage.getItem("userpolicies");

        const formik = useFormik({
            initialValues: {
                licenseNumber: '',
                orgName: '',
                city: ''
            },
            validate,
            onSubmit
        });

        const [unfilteredLicensees, setUnfilteredLicensees] = useState<any>([]);
        const [filteredLicensees, setFilteredLicensees] = useState<any>([]);

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(0);
        const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);

        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
        const {notificationBanner} = charitableGamingStore;

        useEffect(() => {
            if(location.state) {
                localStorage.setItem("PullTabOrderCreationStepOnePageBackButtonText", location.state.backButtonText);
                localStorage.setItem("PullTabOrderCreationStepOnePageBackURL", location.state.backURL);
            }
            if (requestCompleted === false) {
                getAllActiveLicensees(setRequestCompleted);
            }
        }, []);


        const hideNotificationBanner = () => {
            charitableGamingStore.showNotificationBanner({show: false, text: ''});
        }

        setVariables(charitableGamingStore, unfilteredLicensees, setUnfilteredLicensees, filteredLicensees,
            setFilteredLicensees, setCurrentPage, setPageMessage, setShowErrorDialog);

        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['pulltab-games-page-container']} ${styles['']}`}
                     onClick={() => {

                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['pulltab-games-page-back-button-container']} ${styles['']}`}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 if (backURL && backURL !== '') {
                                     history.push(backURL);
                                 } else {
                                     history.push(backURL);
                                 }
                             }
                         }}
                         onClick={() => {
                             if (backURL && backURL !== '') {
                                 history.push(backURL);
                             } else {
                                 history.push(backURL);
                             }
                         }}
                    >
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['pulltab-games-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                        >{backButtonText}
                        </div>
                    </div>
                    <div className={`${styles['pulltab-games-page-ribbon-container']} ${styles['']}`}>
                        <div className={`${styles['pulltab-games-page-ribbon-title']} ${styles['']}`}>Pull Tab Order
                            Creation (Step 1 of 2)
                        </div>
                    </div>

                    <div
                        className={`${styles['pulltab-games-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                        <PullTabOrderCreationStepOneSearchBarComponent
                            formik={formik}
                            setFieldValue={setFieldValue}
                            clearSearch={clearSearch}
                        />
                    </div>
                    {filteredLicensees.length <= 0 ?
                        <div className={`${styles['pulltab-games-page-search-no-results-container']}`}>
                            <div className={`${styles['pulltab-games-page-search-no-results-container-text']}`}>
                                No Results. Please try a different search.
                            </div>
                        </div>
                        :
                        <PullTabOrderCreationStepOneSearchResultComponent
                            licenseeList={filteredLicensees}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            backButtonText={backButtonText}
                        />
                    }
                </div>

                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        text={notificationBanner.text}
                        type={notificationBanner.type}
                        handleHide={hideNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);