import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';

export const CreatePermitInformationComponent = ({
                                                     formik,
                                                     setFiledValue,
                                                 }: any) => {

    const OrgStatusMapping: any = {
        true: 'Active',
        false: 'Expired'
    }

    const OrgStatus = [
        'Active',
        'Expired'
    ];

    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Permit Information</div>
                <div>
                    <div>
                        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                            <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Date of
                                Issuance*
                            </div>
                            <input
                                id="permitHolder.effectiveDate"
                                name="permitHolder.effectiveDate"
                                value={formik.values.permitHolder.effectiveDate}
                                className={styles["page-container-body-left-input-box"]}
                                type="date"
                                onBlur={formik.handleBlur}
                                onChange={(e: any) => {
                                    setFiledValue(e);
                                }}
                            />
                            {(formik.touched.permitHolder?.effectiveDate && formik.errors.effectiveDate) ?
                                <ErrorMessageFieldCommonComponent
                                    effectiveDate={formik.errors.effectiveDate}/> : null}
                        </div>
                    </div>
                    <div className={styles["page-container-body-left1"]}>
                        <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                            <div tabIndex={1} className={styles["page-container-body-left-labels-name"]}>Org. Status*
                            </div>
                            {/*<input
                                id="permitHolder.orgActive"
                                name="permitHolder.orgActive"
                                className={styles["page-container-body-left-input-box"]}
                                onChange={(e: any) => setFiledValue(e)}
                                value={formik.values.permitHolder.orgActive}
                                onBlur={formik.handleBlur}
                                type="text"
                            />*/}
                            <div className={`${styles.custom_select_div}`}>
                                <select className={styles["custom_select"]}
                                        id="permitHolder.orgActive"
                                        name="permitHolder.orgActive"
                                        onChange={(e: any) => {
                                            setFiledValue(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        placeholder="Select"
                                >
                                    <option value="" selected disabled>Select...</option>
                                    {
                                        OrgStatus.map((expenseType: string) =>
                                            <option
                                                selected={OrgStatusMapping[formik.values.permitHolder.orgActive] === expenseType.toLowerCase()}
                                                value={expenseType}>{expenseType}
                                            </option>)
                                    }
                                </select>
                                <span className={`${styles.custom_arrow}`}></span>
                            </div>
                            {/*{(formik.touched.permitHolder?.orgActive && formik.errors.orgActive) ?
                                <ErrorMessageFieldCommonComponent
                                    orgActive={formik.errors.orgActive}/> : null}*/}
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}