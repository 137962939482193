import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const RunEFTSweepFinalConfirmationComponent = ({RunEFTSweep, setEFTSweepFinalConfirmationToggle}: any) => {

    return (

        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["cancel-confirmation-dialog-main-container"]}>
                    <div
                        id="cancel-confirmation-dialog-container"
                        className={stylesMessageBox["cancel-confirmation-dialog-container"]}
                    >
                        <div className={stylesMessageBox["cancel-confirmation-dialog-message"]}>
                            Are you sure you would like to run the EFT sweep?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    RunEFTSweep();
                                    setEFTSweepFinalConfirmationToggle(false);
                                }
                            }}
                            onClick={() => {
                                RunEFTSweep();
                                setEFTSweepFinalConfirmationToggle(false);
                            }}
                            className={stylesMessageBox["cancel-confirmation-dialog-yes-button"]}
                        >
                            <div className={stylesMessageBox["cancel-confirmation-dialog-yes-button-text"]}>
                                Yes, Run Sweep
                            </div>
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    setEFTSweepFinalConfirmationToggle(false);
                                }
                            }}
                            onClick={() => {
                                setEFTSweepFinalConfirmationToggle(false);
                            }}
                            className={stylesMessageBox["cancel-confirmation-dialog-no-button"]}>
                            Go Back
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};