import * as React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Agent1099Print,
  Agent1099PrintHistoryResponse,
  Agent1099PrintStatus,
} from '../../../api/agent-1099-print-queue-history-fetcher/api-types'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
} from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import { GenericTableUI } from '../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { permissions } from '../../../constants'
import { formatCentsToDollars } from '../../../util'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { getBlockClass } from '../../../utilities/helpers'
import { generateReportDateString } from '../../ReportingPages/helper'
import { agent1099HistoryRoutePath } from './../../../config.js'
import './styles.scss'
type Agent1099PrintHistoryPageUIProps = {
  isLoading: boolean
  error?: any
  data?: Agent1099PrintHistoryResponse
  downloadReport: (sweepId: string) => Promise<void>
}
type MappedAgent1099Print = {
  dateGenerated: string
  reportablePayment: string
  ctas: JSX.Element
  status: string
}
const COLUMN_MAP: GenericTableColumnMap<MappedAgent1099Print> = [
  {
    key: 'dateGenerated',
    columnText: 'Date Generated',
    widthPx: 200,
    columnType: GenericTableColumnType.DEFAULT,
  },
  {
    key: 'reportablePayment',
    columnText: 'Reportable Payment',
    widthPx: 150,
    columnType: GenericTableColumnType.DEFAULT,
  },
  {
    key: 'status',
    columnText: 'Status',
    widthPx: 100,
    columnType: GenericTableColumnType.DEFAULT,
  },
  {
    key: 'ctas',
    columnText: '',
    widthPx: 200,
    columnType: GenericTableColumnType.DEFAULT,
  },
]
const ROOT_CLASS = 'agent-1099-print-history-page-ui'
export const Agent1099PrintHistoryPageUI = (
  props: Agent1099PrintHistoryPageUIProps
) => {
  const history = useHistory()
  const getCTAsForPrint = (print: Agent1099Print) => {
    const primaryClass = getBlockClass(ROOT_CLASS, 'cta-button-primary')
    const secondaryClass = getBlockClass(ROOT_CLASS, 'cta-button-secondary')
    const isDisabled = print.status === Agent1099PrintStatus.PENDING
    if (
      ![Agent1099PrintStatus.SUCCESS, Agent1099PrintStatus.PENDING].includes(
        print.status
      )
    ) {
      // Empty container in the event that neither button should appear.
      return <></>
    }
    return (
      <section className={getBlockClass(ROOT_CLASS, 'cta-button-container')}>
        {userHasAnyOfPermissions([permissions.CAN_SEE_1099_REPORTS]) && (
          //TODO: Update button functionality when endpoints have been updated
          <button
            disabled={isDisabled}
            className={secondaryClass}
            onClick={() => props.downloadReport(print.sweepId)}
          >
            Download Report
          </button>
        )}
        <button
          role="link"
          className={primaryClass}
          disabled={isDisabled}
          onClick={() =>
            history.push(`${agent1099HistoryRoutePath}/${print.sweepId}`)
          }
        >
          View Print Details
        </button>
      </section>
    )
  }
  const mappedData = props.data?.prints.map((row): MappedAgent1099Print => {
    let status: string = ''

    switch (row.status) {
      case Agent1099PrintStatus.PENDING:
        status = 'Pending'
        break
      case Agent1099PrintStatus.SUCCESS:
        status = 'Success'
        break
      default:
        status = 'Error'
        break
    }

    return {
      dateGenerated: generateReportDateString(row.initiatedDate),
      status,
      ctas: getCTAsForPrint(row),
      reportablePayment:
        status === 'Error'
          ? '$0.00'
          : formatCentsToDollars(
              row.metadata.totalReportableInCentsDigital +
                row.metadata.totalReportableInCentsPhysical
            ),
    }
  })
  return (
    <section className={ROOT_CLASS}>
      <BackButtonUI message="Back to Agent 1099 Search & Print Queue" />
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Agent 1099 Search & Print Queue History
      </header>
      <section className={getBlockClass(ROOT_CLASS, 'content-container')}>
        <AsyncContainerUI
          isLoading={props.isLoading}
          error={props.error}
          color="white"
          errorTextColor="white"
          fitted
        >
          <GenericTableUI
            columnMap={COLUMN_MAP}
            rowData={mappedData ?? []}
            usePages
            rowsPerPage={15}
          />
        </AsyncContainerUI>
      </section>
    </section>
  )
}
