
export function getSentryConfig() {
  if (!process.env.SENTRY_DSN) {
    const msg = `Sentry DSN not defined.`
    console.log(msg)
    throw new Error(msg)
  }

  return {
    dsn: process.env.SENTRY_DSN,
    release: process.env.RELEASE_HASH
  }
}
