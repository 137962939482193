import {UpdateLicenseSuccessMessage} from "../Common/UpdateLicenseSuccessMessage";
import {LicenseeDetailsInterface} from "../LicenseeDetailsPage/interfaces";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {licenseeRoutePath} from "../../../../config.js";

let licenseNumber: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}

export const validate = (values: LicenseeDetailsInterface) => {

    const errors = {};

    if (!values.licenseDetails.investCode) {
        Object.assign(errors, {licenseDetails: {investCode: InputValidationMessages.RequiredField}});
    }

    if (!values.licenseDetails.expirationDate) {
        Object.assign(errors, {licenseDetails: {expirationDate: InputValidationMessages.RequiredField}});
    }

    if (!values.licenseDetails.county) {
        Object.assign(errors, {licenseDetails: {county: InputValidationMessages.RequiredField}});
    }

    if (!values.licenseDetails.dateOfFirstLicense) {
        Object.assign(errors, {licenseDetails: {dateOfFirstLicense: InputValidationMessages.RequiredField}});
    }

    if (!values.licenseDetails.city) {
        Object.assign(errors, {licenseDetails: {city: InputValidationMessages.RequiredField}});
    }

    if (!values.licenseDetails.type) {
        Object.assign(errors, {licenseDetails: {type: InputValidationMessages.RequiredField}});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updateLicense(values, UpdateLicenseSuccessMessage.LICENSE, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {

    // history.goBack();

    history.push({
        pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
        state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
    });
}



