import * as React from 'react'
import { useParams } from 'react-router-dom'
import { WinnersManagementContainer } from '../../../components/PromotionsComponents/winners-management/winners-management-container'

type WinnersManagementPageParams = {
  promotionId: string
}

export const WinnersManagementPage = () => {
  const { promotionId } = useParams<WinnersManagementPageParams>()
  return <WinnersManagementContainer promotionId={promotionId} />
}
