export type Agent1099PrintMetadata = {
  numRecordsPhysical: number
  numRecordsDigital: number
  totalBonusInCentsPhysical: number
  totalCommissionInCentsPhysical: number
  totalReportableInCentsPhysical: number
  totalBonusInCentsDigital: number
  totalCommissionInCentsDigital: number
  totalReportableInCentsDigital: number
}

export enum Agent1099PrintStatus {
  PENDING = 'PENDING',
  ERROR_CSV_GENERATION = 'ERROR_CSV_GENERATION',
  ERROR_CSV_UPLOAD = 'ERROR_CSV_UPLOAD',
  ERROR_DIGITAL_GENERATION = 'ERROR_DIGITAL_GENERATION',
  ERROR_DIGITAL_UPLOAD = 'ERROR_DIGITAL_UPLOAD',
  ERROR_PRINT_REPORT_GENERATION = 'ERROR_PRINT_REPORT_GENERATION',
  ERROR_PRINT_REPORT_UPLOAD = 'ERROR_PRINT_REPORT_UPLOAD',
  ERROR_STUCK_PENDING = 'ERROR_STUCK_PENDING',
  SUCCESS = 'SUCCESS',
  ERROR_DATABASE = 'ERROR_DATABASE',
}

export type Agent1099Print = {
  sweepId: string
  initiatedDate: Date
  // Will be added in a future API update.
  printReportId?: string
  status: Agent1099PrintStatus
  metadata: Agent1099PrintMetadata
}

export type Agent1099PrintPayment = {
  tin: string
  year: string
  name: string
  bonusInCents: number
  commissionInCents: number
  reportableInCents: number
  address: string
  city: string
  state: string
  zip: string
  physicalPrint: boolean
  digitalPrint: boolean
}

export type Agent1099PrintHistoryResponse = {
  prints: Agent1099Print[]
}

export type Agent1099PrintDetailsResponse = {
  payments: Agent1099PrintPayment[]
  printable: boolean
}
