import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from './css/styles.module.css';
import stylesMessageBox from './css/stylesMessageBox.module.css';
import stylesMessageBoxManualVerifyEmailUpdate from './css/stylesMessageBoxManualVerifyEmailUpdate.module.css';
import { manuallyVerifyEmail } from './functions';
import { playerSupportRoutePath } from './../../../config.js';
import { keyPressedType } from "../../../services/Commons";
import { playersIdentityValues } from './../../../services/Commons';
import { clearPageMessageDefault } from "../../CommonComponents/PageMessageCommonComponent";



export const PlayerEmailAndPhoneComponent = ({ playerStore, setPlayerStore, setPageMessage , pageMessage }: any) => {

    const [toggleState, setToggleState] = useState(false);
    const [emailAndPhoneChangeToggle, setemailAndPhoneChangeToggle] = useState<any>({value:false});
    if(playerStore.identity.phone.split("").slice(0,2).join("") !== "+1")
       playerStore.identity.phone = `+1${playerStore.identity.phone}`
       playerStore.identity.phone = playerStore.identity.phone.split("").filter((e:any)=>
                  {
               
                     if( !(e === "(" || e === ")" || e === "-"))
                         return e;
                  }).join("");
    const history = useHistory();

    useEffect(() => {

        if (document.getElementById("player-email-and-phone-message-box-container") !== null) {
            document.getElementById("player-email-and-phone-message-box-container")?.focus();
        }

    })
   
    return (
        <>
       
            <div onFocus={()=> clearPageMessageDefault() } className={styles['page-container']}>
                <div className={styles['player-email-and-phone-content-title-container']}>
                    <div tabIndex={0} className={styles['player-email-and-phone-content-title']} >Email and Phone</div>
                    <div className={styles['player-email-and-phone-content-button-container']}>
                        <div tabIndex={0} style={{ opacity: playersIdentityValues.accountFieldIsUndefined === true ? "0.4" : playerStore.account.emailVerified ? "0.4" : "1",
                                                    cursor: playersIdentityValues.accountFieldIsUndefined === true ?  "not-allowed" : playerStore.account.emailVerified ? "not-allowed" : "pointer",   
                                                    }}
                            onClick={() => playersIdentityValues.accountFieldIsUndefined === true? "" : playerStore.account.emailVerified ? "" : setToggleState(!toggleState)}
                            onKeyPress={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32)
                                    return playersIdentityValues.accountFieldIsUndefined === true ? "": playerStore.account.emailVerified ? "" : setToggleState(!toggleState)

                            }

                            }
                            role="button"
                            aria-pressed={`${toggleState}`}
                            aria-hidden={`${playersIdentityValues.accountFieldIsUndefined}`}
                            className={styles['player-email-and-phone-content-button-verity']}>
                            <div className={styles['player-email-and-phone-content-button-verity-text']}>Manually Verify Email</div>
                        </div>
                        <div
                            tabIndex={0}
                            onKeyPress={(e: any) =>
                                keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32 ?
                                    (playersIdentityValues.identityFieldIsUndefined === false) && history.push(`${playerSupportRoutePath}/update-email-and-phone/${playerStore.account.id}`)
                                    :
                                    ""
                            }
                            style={{ opacity: (playersIdentityValues.identityFieldIsUndefined === true) ? "0.4" : "1",
                                     cursor: playersIdentityValues.identityFieldIsUndefined === true ? "not-allowed" : "pointer",     
                            }}
                            onClick={() => (playersIdentityValues.identityFieldIsUndefined === false) && history.push(`${playerSupportRoutePath}/update-email-and-phone/${playerStore.account.id}`)}
                            role="button"
                            aria-pressed="false"
                            aria-hidden={`${playersIdentityValues.identityFieldIsUndefined}`}
                            className={styles['player-email-and-phone-content-button-update']}>
                            <div className={styles['player-email-and-phone-content-button-update-text']}>Update</div>
                        </div>
                    </div>
                </div>
                <div className={styles['player-email-and-phone-content-container']}>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name"]}`}>Email </div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name-bold"]}`}>{playerStore.account.email}</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name"]}`}>Email Opt-In?</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name-bold"]}`}>{`${playerStore.account.email === '' ? "" : playerStore.identity.acceptedEmailCommunication}`}</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name"]}`}>Email Status</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name-bold"]}`}>{playerStore.account.emailVerified ? "Verified" : "Not Verified"}</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name"]}`}>Phone Number</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name-bold"]}`}>{playerStore.identity.phone}</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name"]}`}>Phone Opt-In?</div>
                    <div tabIndex={0} className={`${styles["player-email-and-phone-content-labels-name-bold"]}`}>{`${playerStore.account.email === '' ? "" : playerStore.identity.acceptedSmsCommunication}`}</div>
                    
                
                </div>
            </div>
            {toggleState && <div id="window-container" className={stylesMessageBox['window-container']}>
                <div tabIndex={0} id="player-email-and-phone-message-box-container" className={stylesMessageBox['player-email-and-phone-message-box-container']}>
                    <div id="player-email-and-phone-message-box-message" tabIndex={0} className={stylesMessageBox['player-email-and-phone-message-box-message']}>Would you like to verify the following email address for this player</div>
                    <div tabIndex={0} className={stylesMessageBox['player-email-and-phone-message-box-email']}>{playerStore.account.email}</div>
                    <div tabIndex={0} role='button'
                        aria-pressed={"false"}
                        onKeyPress={(e) => {
                         
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32)
                            {
                                setToggleState(!toggleState);
                                return playerStore.account.emailVerified ? "" : manuallyVerifyEmail(playerStore.account.id, setPlayerStore , setemailAndPhoneChangeToggle, setPageMessage , emailAndPhoneChangeToggle );
                            } 
                        }}
                        onClick={() => { setToggleState(!toggleState); return (playerStore.account.emailVerified ? "" : manuallyVerifyEmail( playerStore.account.id , setPlayerStore , setemailAndPhoneChangeToggle , setPageMessage ,emailAndPhoneChangeToggle )) }}
                        className={stylesMessageBox['player-email-and-phone-message-box-button-yes']} >
                        <div className={stylesMessageBox['player-email-and-phone-message-box-button-yes-text']}>Yes, Verify</div>
                    </div>
                    <div tabIndex={0} role='button' aria-pressed={"false"}
                        onKeyPress={(e) => keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32
                            ?
                            setToggleState(!toggleState)
                            :
                            ""
                        }

                        onClick={() => setToggleState(!toggleState)} className={stylesMessageBox['player-email-and-phone-message-box-button-no']} >No, Do Not Verify</div>
                </div>
            </div>}
           
            {emailAndPhoneChangeToggle.value &&

                <div className={stylesMessageBoxManualVerifyEmailUpdate['window-container']}>
                    <div id={"update-player-email-and-phone-confirmation-container"}
                        tabIndex={0}
                        className={stylesMessageBoxManualVerifyEmailUpdate['update-player-email-and-phone-confirmation-container']}>

                        <div tabIndex={0} className={stylesMessageBoxManualVerifyEmailUpdate['update-player-email-and-phone-confirmation-text']}>Update Successful!</div>
                        <div tabIndex={0} className={stylesMessageBoxManualVerifyEmailUpdate['update-player-email-and-phone-confirmation-message']}>
                            The player now will receive an email confirmation that their information was updated.
                        </div>
                        <div tabIndex={0} className={stylesMessageBoxManualVerifyEmailUpdate['update-player-email-and-phone-confirmation-message']}>
                            if this email address was updated, the player will receive a 6-digit verification code to the new email address immediately.
                        </div>
                        <div role='button'
                            tabIndex={0}
                            onKeyPress={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                    setemailAndPhoneChangeToggle(false);
                                    
                                }
                            }
                            }
                            onClick={() => { setemailAndPhoneChangeToggle(false); }}
                            className={stylesMessageBoxManualVerifyEmailUpdate['update-player-email-and-phone-confirmation-button-close']} >
                            <div className={stylesMessageBoxManualVerifyEmailUpdate['update-player-email-and-phone-confirmation-close-text']}>Close</div>
                        </div>

                    </div>
                </div>}
        </>

    );
}