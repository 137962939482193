import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';


import {getSAPlayerData, getSAPlayerRefreshData, resetFilters} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {SessionActivityTableComponent} from './../../../components/PlayersComponents/SessionActivityTableComponent';
import {useHistory} from "react-router-dom";

import {keyPressedType} from "../../../services/Commons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {playerSupportRoutePath} from './../../../config.js'
import {SessionActivityButtonsComponent} from "../../../components/PlayersComponents/SessionActivityButtonsComponent";
import {
    PageMessageCommonComponent,
    pageMessageDefault
} from "../../../components/CommonComponents/PageMessageCommonComponent";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const timerArray: any = [];
export const SessionActivityPage = ({storesObject, id}: any) => {

    const [isData, setIsData] = useState(false);
    const temp = new Date(new Date().toLocaleDateString());
    temp.setDate(temp.getDate() - 31);
    const [SABasePlayerData, setSABasePlayerData] = useState<any[]>(storesObject.playerStore.playerEvents);
    const [SAPlayerData, setSAPlayerData] = useState<any[]>(storesObject.playerStore.playerEvents);
    const [SAStartDate, setSAStartDate] = useState<any>(`${temp.toISOString().split("T")[0]}`);
    const [errorStartDate, setErrorStartDate] = useState<any>(undefined);
    const [errorEndDate, setErrorEndDate] = useState<any>(undefined);
    const [SAEndDate, setSAEndDate] = useState<any>(new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]);
    const [SASignIn, setSASignIn] = useState<boolean>(true);
    const [SASignUp, setSASignUp] = useState<boolean>(true);
    const [SALoaded, setSALoaded] = useState<boolean>(storesObject.playerStore.playerEvents.length <= 0 ? false : SABasePlayerData[0]?.id === undefined ? false : true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [requestPerformed, setRequestPerformed] = useState<any>(false);
    const [networkError, setNetworkError] = useState<boolean>(false);
    const history: any = useHistory();

    localStorage.setItem('callingPage', history.location.pathname);
    const sleep = (ms: any) => {

        const timer = setTimeout((timerArray: any) => {
            timerArray.map((e: any) => clearTimeout(e));
            setSALoaded(false);

        }, ms, timerArray)
        timerArray.push(timer)

    }

    useEffect(() => {

        if (SABasePlayerData[0]?.id === undefined && SALoaded === false) {
            setNetworkError(false);
            setIsData(false);
            getSAPlayerRefreshData(pageMessage, setSALoaded, setRequestPerformed, setSAPlayerData, setSABasePlayerData, id, setIsData, SAStartDate, SAEndDate, setPageMessage, SABasePlayerData, setCurrentPage, SASignIn, SASignUp, setNetworkError);


            document.getElementById("page-container")?.focus();

        }

        if (networkError && isData) {

            setIsData(false);
            setNetworkError(false);
            sleep(5000);

        }


    });


    return (
        <>

            {((SALoaded === false || requestPerformed) && pageMessage.message === "" || (networkError && !isData && pageMessage.message === "")) &&
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="8em" color='inherit' thickness={2}/>
            </div>}
            <div
                onClick={(e: any) => {
                    pageMessage.messageType !== "" &&
                    setPageMessage({message: "", messageType: ""});
                }}
                id="page-container"
                className={styles['page-container']}>
                <div className={styles['back-to-player-hub-container']}>
                    <div tabIndex={0} role="button" aria-pressed={"false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 localStorage.setItem('activeTab', 'activity');
                                 history.push(`${playerSupportRoutePath}/player-hub-new/${id}`);

                             }
                         }
                         }
                         onClick={() => {
                             localStorage.setItem('activeTab', 'activity');
                             history.push(`${playerSupportRoutePath}/player-hub-new/${window.location.href.split('/')[window.location.href.split('/').length - 1]}`);
                         }}
                         className={styles['back-to-player-hub-inner-container']}>
                        <i title='left arrow icon' className={styles['back-to-player-hub-icon']}>
                            <FontAwesomeIcon size="2x" arial-label='left arrow icon.' icon={faAngleLeft as IconProp}/>
                        </i>
                        <div className={styles['back-to-player-hub-inner-container-text']}>Back
                            to {localStorage.getItem("PlayersName")}</div>
                    </div>
                </div>
                {pageMessage.message !== "" && (
                    <PageMessageCommonComponent
                        message={pageMessage.message}
                        messageType={pageMessage.messageType}
                    />
                )}
                <div className={styles['top-ribbon']}>
                    <div className={styles['top-ribbon-text']} tabIndex={0}>Session Activity</div>

                    <div className={styles['filter-container']}>
                        <SessionActivityButtonsComponent
                            getSAPlayerData={getSAPlayerData}
                            resetFilters={resetFilters}
                            setRequestPerformed={setRequestPerformed}
                            SABasePlayerData={SABasePlayerData}
                            SAStartDate={SAStartDate}
                            setSAStartDate={setSAStartDate}
                            SAEndDate={SAEndDate}
                            setSAEndDate={setSAEndDate}
                            SASignIn={SASignIn}
                            setSASignIn={setSASignIn}
                            SASignUp={SASignUp}
                            setSASignUp={setSASignUp}
                            setSAPlayerData={setSAPlayerData}
                            setCurrentPage={setCurrentPage}
                            SAPlayerData={SAPlayerData}
                            id={id}
                            setSALoaded={setSALoaded}
                            setSABasePlayerData={setSABasePlayerData}
                            setIsData={setIsData}
                            setPageMessage={setPageMessage}
                            setNetworkError={setNetworkError}
                            pageMessage={pageMessage}
                        />
                    </div>
                    <div className={styles["sa-table-container"]}>
                        <SessionActivityTableComponent pageMessage={pageMessage} id={id} SAPlayerData={SAPlayerData}
                                                       name={localStorage.getItem("PlayersName")}
                                                       currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                    </div>
                </div>
            </div>
        </>

    );
}

//