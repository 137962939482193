import * as React from 'react'
import { useFormik } from 'formik'
import {
  CustomDropdownOption,
  CustomDropdownField,
} from '../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { getBlockClass } from '../../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { LoadingDots } from '../../../CommonComponents/loading-dots/loading-dots-ui'
import './styles.scss'

export type WinnersManagementDrawingFormProps = {
  loading: boolean
  drawingsList?: DrawingSelection[]
  drawingsError?: string
  fetchDrawingDetails: (
    drawingGroupId: string,
    drawingId: string
  ) => Promise<void>
  setSelectedDrawing: (drawing: DrawingSelection) => void
}

const FORM_CLASS = 'winners-management-form'

export type DrawingSelection = {
  drawingGroupId: string
  drawingId: string
  displayName: string
  displayNameWithDate: string
}

export const WinnersManagementDrawingForm = (
  props: WinnersManagementDrawingFormProps
) => {
  const [drawingOptions, setDrawingOptions] = React.useState<
    CustomDropdownOption[]
  >([])

  React.useEffect(() => {
    const options: CustomDropdownOption[] = [
      {
        key: 'default',
        value: '',
        disabled: Boolean(formik.values.selection),
        display: 'Select Drawing...',
      },
    ]
    props.drawingsList?.forEach((drawing) => {
      options.push({
        key: drawing.displayName,
        value: JSON.stringify(drawing),
        display: drawing.displayNameWithDate,
      })
    })
    setDrawingOptions(options)
  }, [props.drawingsList])

  const initialValues = {
    selection: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (values.selection === '') {
        return
      }
      const selection: DrawingSelection = JSON.parse(values.selection)
      await props.fetchDrawingDetails(
        selection.drawingGroupId,
        selection.drawingId
      )
      props.setSelectedDrawing(selection)
      return
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className={`${FORM_CLASS}-container`}
    >
      <div className={FORM_CLASS}>
        <label
          className={getBlockClass(FORM_CLASS, 'label')}
          htmlFor={'drawing'}
        >
          Drawing
        </label>
        <CustomDropdownField
          onChange={(event) => {
            formik.setFieldValue('selection', event.target.value)
          }}
          onBlur={formik.handleBlur}
          id="drawing"
          name="drawing"
          options={drawingOptions}
          disabled={props.loading}
        />
        {props.drawingsError && (
          <div className={getBlockClass(FORM_CLASS, 'error')}>
            <ErrorMessageFieldCommonComponent
              errorMessage={props.drawingsError}
            />
          </div>
        )}
      </div>
      <button
        type="submit"
        className={`${FORM_CLASS}__select-button`}
        aria-label="Select"
        disabled={props.loading}
      >
        {props.loading ? (
          <div
            className={`${FORM_CLASS}__loading`}
            aria-busy={props.loading}
            aria-describedby="loading"
          >
            <LoadingDots id="loading" color="white" />
          </div>
        ) : (
          'Select'
        )}
      </button>
    </form>
  )
}
