import moment from "moment";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import config from "../../../../config";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";

let formik: any;
let charitableGamingStore: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setLicenseeCharges: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, setCurrentPageObj: any,
                             setShowErrorDialogObj: any, setRequestCompletedObj: any, setLicenseeChargesObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setLicenseeCharges = setLicenseeChargesObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    if (values.startChargeDate && values.endChargeDate) {
        const a = moment(values.startChargeDate);
        const b = moment(values.endChargeDate);
        if (b.diff(a, 'days') > 365) {
            Object.assign(errors, {startChargeDate: InputValidationMessages.InvalidDateRange});
            // Object.assign(errors, {endChargeDate: InputValidationMessages.InvalidDateRange});
        }
    }

    if (values.licenseNumber && values.licenseNumber.trim().length < 3) {
        Object.assign(errors, {licenseNumber: InputValidationMessages.InvalidLicenseNumberLength});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    getLicenseeCharges(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            expenseType: '',
            chargeStatus: '',
            startChargeDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endChargeDate: moment().format('YYYY-MM-DD'),
            licenseNumber: ''
        }
    });

    formik.submitForm();

}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

export const settleCharges = async (chargeIds: any, setSelectedChargeIds: any = null) => {

    // alert(JSON.stringify(chargeIds));

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        chargeIds: chargeIds
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'PATCH',
            url: `${config.SERVER_BASE_URL}/api/v1/charge/settled`,
            data: body
        }

        let response = await makeHttpCall(options);

        setRequestCompleted(true);

        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The charge status was successfully updated.'
        });

        // Clear the selected Charge Ids
        if (setSelectedChargeIds) {
            setSelectedChargeIds(new Set());
        }

        formik.submitForm();

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const getLicenseeCharges = async (values: any) => {

    // alert(JSON.stringify(values));

    setCurrentPage(0);

    setRequestCompleted(false); // Display Circular Progress

    let body: any = {
        /*expenseType: null,
        chargeStatus: null,*/
        dateRange: {
            startDate: values.startChargeDate + 'T00:00:00.000Z',
            endDate: values.endChargeDate + 'T23:59:59.999Z',
        },
        orgDetails: {
            chargeType: "LICENSEE"
        }
    }

    if (values.licenseNumber !== '') {
        body.orgDetails.identifier = values.licenseNumber;
    }

    const expenseType = values.expenseType.toLowerCase();
    if (expenseType === 'weekly taxes') {
        body.expenseType = 'WEEKLY_BEANO';
    } else if (expenseType === 'pull tab order') {
        body.expenseType = 'PURCHASE_ORDER';
    } else if (expenseType === 'license renewal fee') {
        body.expenseType = 'LICENSE_RENEWAL';
    }

    const chargeStatus = values.chargeStatus.toLowerCase();
    if (chargeStatus === 'out of balance') {
        body.chargeStatus = 'AWAITING_APPROVAL';
    } else if (chargeStatus === 'scheduled') {
        body.chargeStatus = 'READY_FOR_SWEEP';
    } else if (chargeStatus === 'on hold') {
        body.chargeStatus = 'ON_HOLD';
    } else if (chargeStatus === 'pending') {
        body.chargeStatus = 'SWEEP_PENDING';
    } else if (chargeStatus === 'submitted') {
        body.chargeStatus = 'SUBMITTED';
    } else if (chargeStatus === 'rejected') {
        body.chargeStatus = 'REJECTED';
    } else if (chargeStatus === 'paid') {
        body.chargeStatus = 'SETTLED';
    } else if (chargeStatus === 'awaiting check') {
        body.chargeStatus = 'AWAITING_CHECK';
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/charge/Search`,
            data: body
        }

        let response = await makeHttpCall(options);

        setLicenseeCharges(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
        console.log(e);
    }

}
