export interface PullTabStockInventory {
    additionalResults: boolean
    packets: {
        serialNumber: string,
        stockNumber: string,
        originalWarehouse: string,
        dateRecorded: string,
        warehouseId: string,
        licenseNumber: string,
        latestPurchaseDate: string
    }[]
}

export const PullTabStockInventoryDefaultData = {
    additionalResults: false,
    packets: [{
        serialNumber: '',
        stockNumber: '',
        originalWarehouse: '',
        dateRecorded: '',
        warehouseId: '',
        licenseNumber: '',
        latestPurchaseDate: ''
    }]
}