import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./css/style.module.css";
import { getPageData } from "../../../PlayersComponents/PlayerSearchTableComponent/functions";
import { keyPressedType } from "../../../../services/Commons";
import { KEY_CODES } from "../../../../constants.js";
import { annuitiesRoutePath } from "./../../../../config.js";
import { normalizePage } from "./functions";
import { PaginationCommonComponent } from "../../../CommonComponents/PaginationCommonComponent";
import { FirstPaymentListData } from "../../../../types/Annuities/FirstPayment/FirstPayment";
import { formatDollarAmount } from "../../../../util";
import { inject, observer } from "mobx-react";

export const FirstPaymentListComponent = inject("annuitiesStore")(
  observer(({ FirstPaymentData, annuityBtnConfirm, setAnnuityBtnConfirm, annuitiesStore }: any) => {
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState<number>(0);

    return (
      <>
        <div className={styles["table"]}>
          <div className={styles["table-data-header"]}>
            <div aria-live="assertive">{`Displaying ${FirstPaymentData.length <= 0 ? 0 : currentPage * 10 + 1}-${normalizePage(FirstPaymentData.length, currentPage)} of ${
              FirstPaymentData.length
            } results`}</div>
          </div>

          <div className={`${styles["table-data-grid-header"]} ${styles["table-data-label"]}`}>
            <div>Original Claimant</div>
            <div>Game</div>
            <div>Gross Payment Amount</div>
            <div>Net Payment Amount</div>
            <div></div>
            <div></div>
          </div>

          <div className={styles["table-data-body"]}>
            {FirstPaymentData.length > 0 &&
              getPageData(FirstPaymentData, currentPage).map((firstPaymentData: FirstPaymentListData, i: number) => (
                <div
                  key={i}
                  className={`${styles["table-data-grid-body"]}`}
                >
                  <div className={styles["table-data-data-text"]}>{firstPaymentData.claimantName}</div>
                  <div className={styles["table-data-data-text"]}>{firstPaymentData.gameName}</div>
                  <div className={styles["table-data-data-text"]}>{formatDollarAmount(firstPaymentData.grossAmount / 100)}</div>
                  <div className={styles["table-data-data-text"]}>{formatDollarAmount(firstPaymentData.netAmount / 100)}</div>
                  <button
                    className={styles["dark-btn"]}
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        history.push(`${annuitiesRoutePath}/first-payment-details/${firstPaymentData.id}`);
                      }
                    }}
                    onClick={() => {
                      history.push(`${annuitiesRoutePath}/first-payment-details/${firstPaymentData.id}`);
                    }}
                  >
                    First Payment Details
                  </button>
                  <button
                    className={styles["light-btn"]}
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        annuitiesStore.setFirstPaymentID(firstPaymentData.id);
                        setAnnuityBtnConfirm(!annuityBtnConfirm);
                      }
                    }}
                    onClick={() => {
                      annuitiesStore.setFirstPaymentID(firstPaymentData.id);
                      setAnnuityBtnConfirm(!annuityBtnConfirm);
                    }}
                  >
                    Begin Annuity Creation
                  </button>
                </div>
              ))}
          </div>
          <div className={styles["table-data-footer"]}>
            <div
              aria-live="assertive"
              className={styles["table-page-label"]}
            >{`Page ${currentPage + 1} of ${Math.ceil(FirstPaymentData.length / 10).toString()}`}</div>
            {
              <PaginationCommonComponent
                playerData={FirstPaymentData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            }
          </div>
        </div>
      </>
    );
  })
);
