import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import styles from './css/styles.module.css';
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {keyPressedType} from "../../../../services/Commons";
import {Tab, Tabs} from 'react-bootstrap';
import {NotificationBannerComponent} from "../../../../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import ToggleSwitchComponent from "../../../../components/CommonComponents/ToggleSwitchComponent";
import {
    getChargeDetails,
    holdCharge,
    rejectCharge,
    scheduleChargeForEFTSweep,
    settleCharge,
    settleOrRejectCharge,
    setVariables,
    updatePaymentMethod
} from "./functions";
import {ChargeDetailsDefaultData, ChargeDetailsInterface} from "../PermitHolderChargeDetailsPage/interfaces";
import {ChargeStatusMapping, ExpenseTypeMapping} from "../../../../Constants/CharitableConstant.js";
import {formatCentsToDollars} from "../../../../util";
import {
    UpdateChargePaymentMethodComponent
} from "../../../../components/CharitableGamingComponents/Common/UpdateChargePaymentMethodComponent";
import {pulltabRoutePath, taxformRoutePath} from "../../../../config";
import {
    SettleOrRejectChargeComponent
} from "../../../../components/CharitableGamingComponents/Common/SettleOrRejectChargeComponent";
import {
    SettleChargeConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/SettleChargeConfirmationComponent";
import {
    RejectChargeConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/RejectChargeConfirmationComponent";
import {
    HoldChargeConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/HoldChargeConfirmationComponent";
import {
    ScheduleChargeConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/ScheduleChargeConfirmationComponent";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";

export const LicenseeChargeDetailsPage = inject("charitableGamingStore")(observer(({
                                                                                       chargeId, charitableGamingStore
                                                                                   }: any) => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    let backButtonText = localStorage.getItem("LicenseeChargeDetailsPageBackButtonText");

    const {notificationBanner} = charitableGamingStore;

    const [selectedCharge, setSelectedCharge] = useState<ChargeDetailsInterface>(ChargeDetailsDefaultData);
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const [showUpdateChargePaymentMethodDialog, setShowUpdateChargePaymentMethodDialog] = useState<boolean>(false);
    const [showSettleRejectChargeDialog, setShowSettleRejectChargeDialog] = useState<boolean>(false);
    const [showHoldChargeConfirmationDialog, setShowHoldChargeConfirmationDialog] = useState<boolean>(false);
    const [showScheduleChargeConfirmationDialog, setShowScheduleChargeConfirmationDialog] = useState<boolean>(false);
    const [showSettleChargeConfirmationDialog, setShowSettleChargeConfirmationDialog] = useState<boolean>(false);
    const [showRejectChargeConfirmationDialog, setShowRejectChargeConfirmationDialog] = useState<boolean>(false);

    const chargeStatusesPaymentMethodUpdateNotAllowed = ['REJECTED_AWAITING_CHECK', 'AWAITING_APPROVAL', 'SWEEP_PENDING', 'SUBMITTED', 'SETTLED'];

    setVariables(chargeId, charitableGamingStore, setRequestCompleted, setShowErrorDialog, selectedCharge, setSelectedCharge);

    useEffect(() => {
        if (location.state) {
            localStorage.setItem("LicenseeChargeDetailsPageBackButtonText", location.state.backButtonText);
        }
        if (requestCompleted === false) {
            getChargeDetails();
        }
    }, [selectedCharge]);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    const checkPermissionForChargeStatusUpdate = () => {
        if (selectedCharge.status === 'READY_FOR_SWEEP' || selectedCharge.status === 'ON_HOLD') {
            return checkRenderPermissions(permissions.CAN_SCHEDULE_AND_HOLD_CHARGES, JSON.parse(userpolicies));
        } else if (selectedCharge.status === 'SUBMITTED' || selectedCharge.status === 'REJECTED' || selectedCharge.status === 'SETTLED') {
            return checkRenderPermissions(permissions.CAN_SETTLE_AND_REJECT_CHARGES, JSON.parse(userpolicies));
        } else {
            return false;
        }
    }

    const handleViewExpenseDetails = () => {

        if (selectedCharge.expenseType === 'PURCHASE_ORDER') {
            history.push({
                pathname: `${pulltabRoutePath}/orders/details/${selectedCharge.associatedEntityId}`, state: {
                    backButtonText: 'Back to Charge Details'
                },
            });
        } else if (selectedCharge.expenseType === 'WEEKLY_BEANO') {
            history.push({
                pathname: `${taxformRoutePath}/edit/weeklybeano/${selectedCharge.associatedEntityId}`, state: {
                    backButtonText: 'Back to Charge Details'
                },
            });
        }

    }

    const ChargeStatusUpdateButtonTextMapping: any = {
        READY_FOR_SWEEP: 'Hold Charge',
        ON_HOLD: 'Schedule Charge',
        SUBMITTED: 'Settle/Reject Charge',
        REJECTED: 'Settle Charge',
        SETTLED: 'Reject Charge'
    }

    const ExpenseDetailsButtonTextMapping: any = {
        PURCHASE_ORDER: 'View Expense Detail', WEEKLY_BEANO: 'View Form'
    }

    return (<>
            <div className={`${styles['permit-holder-details-page-container']}`}
                 onClick={() => {

                 }}
            >
                <div tabIndex={0}
                     className={`${styles['permit-holder-details-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['permit-holder-details-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >{backButtonText}
                    </div>
                </div>

                <div className={`${styles["permit-holder-details-page-ribbon-container"]}`}>
                    <div className={`${styles["permit-holder-details-page-ribbon-container-col-1"]}`}>
                        <div className={styles["permit-holder-details-page-ribbon-title-container"]}>
                            <div className={styles["permit-holder-details-page-ribbon-title-label"]}>Charge Details
                            </div>
                        </div>
                        <div className={styles["permit-holder-details-page-ribbon-subtitle-container"]}>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>License No.
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedCharge.orgDetails.identifier}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Expense Type
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{ExpenseTypeMapping[selectedCharge.expenseType]}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Payment
                                    Method
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedCharge.paymentMethod}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Status
                                </div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{ChargeStatusMapping[selectedCharge.status]}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles["permit-holder-details-page-ribbon-container-col-2"]}`}>
                        {checkRenderPermissions(permissions.CAN_UPDATE_PAYMENT_METHOD, JSON.parse(userpolicies)) && !chargeStatusesPaymentMethodUpdateNotAllowed.includes(selectedCharge.status) ?
                            <div tabIndex={0}
                                 className={`${styles['license-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         setShowUpdateChargePaymentMethodDialog(true);
                                     }
                                 }}
                                 onClick={() => {
                                     setShowUpdateChargePaymentMethodDialog(true);
                                 }}>
                                <div
                                    className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                    Update Payment Method
                                </div>
                            </div> : null}
                        {checkPermissionForChargeStatusUpdate() && ChargeStatusUpdateButtonTextMapping[selectedCharge.status] ?
                            <div tabIndex={0}
                                 className={`${styles['view-expense-detail-button']}`}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (selectedCharge.status === 'READY_FOR_SWEEP') {
                                             setShowHoldChargeConfirmationDialog(true);
                                         } else if (selectedCharge.status === 'ON_HOLD') {
                                             setShowScheduleChargeConfirmationDialog(true);
                                         } else if (selectedCharge.status === 'SUBMITTED') {
                                             setShowSettleRejectChargeDialog(true);
                                         } else if (selectedCharge.status === 'REJECTED') {
                                             setShowSettleChargeConfirmationDialog(true);
                                         } else if (selectedCharge.status === 'SETTLED') {
                                             setShowRejectChargeConfirmationDialog(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (selectedCharge.status === 'READY_FOR_SWEEP') {
                                         setShowHoldChargeConfirmationDialog(true);
                                     } else if (selectedCharge.status === 'ON_HOLD') {
                                         setShowScheduleChargeConfirmationDialog(true);
                                     } else if (selectedCharge.status === 'SUBMITTED') {
                                         setShowSettleRejectChargeDialog(true);
                                     } else if (selectedCharge.status === 'REJECTED') {
                                         setShowSettleChargeConfirmationDialog(true);
                                     } else if (selectedCharge.status === 'SETTLED') {
                                         setShowRejectChargeConfirmationDialog(true);
                                     }
                                 }}>
                                <div
                                    className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']}`}>
                                    {ChargeStatusUpdateButtonTextMapping[selectedCharge.status]}
                                </div>
                            </div> : null}
                    </div>
                </div>

                <div className={`${styles["permit-holder-details-page-tabs-container"]}`}>
                    <Tabs className={`${styles["permit-holder-details-tabs"]}`} id="permit-holder-details-tabs">
                        <Tab eventKey="chargeDetails" title="Charge Details">
                            <div className={`${styles["row-1-container"]}`}>
                                <div className={`${styles["row-1-col-1-container"]}`}>
                                    <div className={`${styles["row-container-title"]}`}>Organization</div>
                                    <div className={`${styles['org-details-controls-container']}`}>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Org.
                                            Name
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.orgName}</div>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Street
                                            Address
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.streetAddress}</div>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.city}</div>

                                        <div
                                            className={`${styles['org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode
                                        </div>
                                        <div
                                            className={`${styles['org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.zip}</div>

                                    </div>
                                </div>
                                <div className={`${styles["row-1-col-2-container"]}`}>
                                    <div className={`${styles["row-container-title"]}`}>License</div>
                                    <div className={`${styles['license-details-controls-container']}`}>

                                        <div
                                            className={`${styles['license-details-controls-container-column-1']} ${styles['mslc-label']}`}>License
                                            No.
                                        </div>
                                        <div
                                            className={`${styles['license-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.orgDetails.identifier}</div>

                                    </div>
                                </div>
                            </div>
                            <div className={`${styles["row-2-container"]}`}>
                                <div className={`${styles["row-2-col-1-container"]}`}>
                                    <div className={`${styles["row-container-title"]}`}>Charge Information</div>
                                    <div className={`${styles['charge-details-controls-container']}`}>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-1']} ${styles['mslc-label']}`}>Charge
                                            Date
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-2']} ${styles['mslc-label']}`}>{moment(selectedCharge.date).utc().format('MM/DD/YYYY')}</div>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-3']} ${styles['mslc-label']}`}>Charge
                                            Status
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-4']} ${styles['mslc-label']}`}>{ChargeStatusMapping[selectedCharge.status]}</div>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-1']} ${styles['mslc-label']}`}>Expense
                                            Type
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-2']} ${styles['mslc-label']}`}>{ExpenseTypeMapping[selectedCharge.expenseType]}</div>

                                        <div
                                            className={`${styles['charge-details-controls-container-column-3']} ${styles['mslc-label']}`}>Check
                                            Received
                                        </div>
                                        {checkRenderPermissions(permissions.CAN_MARK_CHECK_RECEIVED, JSON.parse(userpolicies)) ?
                                            <div
                                                tabIndex={0}
                                                className={`${styles['charge-details-controls-container-toggle-switch']}`}
                                                onKeyDown={(ev: any) => {
                                                    if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                        if (selectedCharge.paymentMethod === 'CHECK') {
                                                            if (selectedCharge.checkReceived) {
                                                                rejectCharge(selectedCharge.chargeId);
                                                            } else {
                                                                settleCharge(selectedCharge.chargeId);
                                                            }
                                                        }
                                                    }
                                                }}>
                                                <ToggleSwitchComponent
                                                    id={`checkReceived`}
                                                    name={`checkReceived`}
                                                    isOn={selectedCharge.checkReceived}
                                                    disabled={selectedCharge.paymentMethod !== 'CHECK'}
                                                    handleToggle={() => {
                                                        if (selectedCharge.paymentMethod === 'CHECK') {
                                                            if (selectedCharge.checkReceived) {
                                                                rejectCharge(selectedCharge.chargeId);
                                                            } else {
                                                                settleCharge(selectedCharge.chargeId);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div> : null}
                                        <div
                                            className={`${styles['charge-details-controls-container-column-1']} ${styles['mslc-label']}`}>Payment
                                            Method
                                        </div>
                                        <div
                                            className={`${styles['charge-details-controls-container-column-2']} ${styles['mslc-label']}`}>{selectedCharge.paymentMethod}</div>


                                    </div>
                                </div>
                                <div className={`${styles["row-2-col-2-container"]}`}>
                                    <div className={`${styles["row-2-row-2-col-2-container"]}`}>
                                        <div className={`${styles["row-container-title"]}`}>Charge Amount</div>
                                        <div
                                            className={`${styles['charge-amount-summary-controls-container']} ${styles['padding-top-075']}`}>

                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Subtotal
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formatCentsToDollars(selectedCharge.baseAmountInCents)}</div>

                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-1']} ${styles['mslc-label']} ${styles['padding-top-1']}`}>Credit
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['padding-top-1']} ${styles['justify-right']}`}>{(selectedCharge.creditAmountInCents === 0) ? `n/a` : `-${formatCentsToDollars(selectedCharge.creditAmountInCents)}`}</div>

                                        </div>
                                        <hr className={styles['horizontal-line']}/>
                                        <div className={`${styles['charge-amount-summary-controls-container']}`}>

                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-1']} ${styles['mslc-label']}`}>Grand
                                                Total
                                            </div>
                                            <div
                                                className={`${styles['charge-amount-summary-controls-container-column-2']} ${styles['mslc-label']} ${styles['justify-right']}`}>{formatCentsToDollars(selectedCharge.totalAmountInCents)}</div>

                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles["row-2-col-3-container"]}`}>
                                    {(checkRenderPermissions(permissions.CAN_VIEW_EXPENSE_DETAILS, JSON.parse(userpolicies))) && (selectedCharge.expenseType === 'PURCHASE_ORDER') || (selectedCharge.expenseType === 'WEEKLY_BEANO') ?
                                        <div tabIndex={0}
                                             className={`${styles['view-expense-detail-button']} ${styles['']}`}
                                             role="button"
                                             onKeyDown={(ev: any) => {
                                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                     handleViewExpenseDetails();
                                                 }
                                             }}
                                             onClick={() => {
                                                 handleViewExpenseDetails();
                                             }}>
                                            <div
                                                className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                                {ExpenseDetailsButtonTextMapping[selectedCharge.expenseType]}
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                </div>

            </div>
            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                text={notificationBanner.text}
                type={notificationBanner.type}
                handleHide={hideNotificationBanner}
            /> : null}
            {showUpdateChargePaymentMethodDialog ? <UpdateChargePaymentMethodComponent
                updatePaymentMethod={updatePaymentMethod}
                setShowUpdateChargePaymentMethodDialog={setShowUpdateChargePaymentMethodDialog}
            /> : null}
            {showSettleRejectChargeDialog ? <SettleOrRejectChargeComponent
                settleOrRejectCharge={settleOrRejectCharge}
                chargeId={chargeId}
                setShowSettleRejectDialog={setShowSettleRejectChargeDialog}
            /> : null}
            {showSettleChargeConfirmationDialog ? <SettleChargeConfirmationComponent
                settleCharge={settleCharge}
                chargeId={chargeId}
                setShowSettleConfirmationDialog={setShowSettleChargeConfirmationDialog}
            /> : null}
            {showRejectChargeConfirmationDialog ? <RejectChargeConfirmationComponent
                rejectCharge={rejectCharge}
                chargeId={chargeId}
                setShowRejectConfirmationDialog={setShowRejectChargeConfirmationDialog}
            /> : null}
            {showHoldChargeConfirmationDialog ? <HoldChargeConfirmationComponent
                holdCharge={holdCharge}
                chargeId={chargeId}
                setShowHoldChargeConfirmationDialog={setShowHoldChargeConfirmationDialog}
            /> : null}
            {showScheduleChargeConfirmationDialog ? <ScheduleChargeConfirmationComponent
                scheduleCharge={scheduleChargeForEFTSweep}
                chargeId={chargeId}
                setShowScheduleChargeConfirmationDialog={setShowScheduleChargeConfirmationDialog}
            /> : null}
        </>

    );
}));