import React, {useEffect} from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const HoldChargesConfirmationComponent = ({YesHoldCharges, holdChargesConfirmationToggle, setHoldChargesConfirmationToggle}: any) => {

    useEffect(() => {
    });

    return (

        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["confirmation-dialog-main-container"]}>
                    <div id="confirmation-dialog-container"
                         className={stylesMessageBox["confirmation-dialog-container"]}>
                        <div className={stylesMessageBox["confirmation-dialog-text-container"]}>
                            <div className={stylesMessageBox["confirmation-dialog-title"]}>Hold Charges</div>
                            <div className={stylesMessageBox["confirmation-dialog-message"]}>
                                Update the status of the selected charges from “Scheduled” to “On Hold”. This action
                                will
                                remove the charges from the EFT Ledger screen.
                            </div>
                        </div>
                        <div className={stylesMessageBox["confirmation-dialog-button-container"]}>
                            <div tabIndex={0} className={stylesMessageBox["confirmation-dialog-yes-button"]}
                                 role="button"
                                 onKeyDown={(e: any) => {
                                     if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                         YesHoldCharges();
                                         setHoldChargesConfirmationToggle(false);
                                     }
                                 }}
                                 onClick={() => {
                                     YesHoldCharges();
                                     setHoldChargesConfirmationToggle(false);
                                 }}
                            >Update
                            </div>
                            <div
                                tabIndex={0}
                                className={stylesMessageBox["confirmation-dialog-no-button"]}
                                role="button"
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        setHoldChargesConfirmationToggle(!holdChargesConfirmationToggle);
                                    }
                                }}
                                onClick={() => {
                                    setHoldChargesConfirmationToggle(!holdChargesConfirmationToggle)
                                }}
                            >Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};