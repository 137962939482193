import React from 'react'
import { FaCheckCircle, FaRegQuestionCircle } from 'react-icons/fa'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import styles from './css/styles.module.css'

export const AccountStatusCmpt: React.FC<{
  status: string
  notes?: string
}> = ({ status, notes }) => {
  switch (status) {
    case 'Active':
      return <Active notes={notes} />
    case 'On Hold':
      return <OnHold notes={notes} />
    case 'Completed':
      return <Completed />
    default:
      return <Unassigned />
  }
}

// status components
export const Active: React.FC<{ notes?: string }> = ({ notes }) => {
  return (
    <div className={`${styles.status_container} d-flex align-items-center`}>
      <div className={`${styles.icon_container}`}>
        <div className={styles.icon}>
          <FaCheckCircle className="mr-2" color="#008000" />
        </div>
        {notes !== null && (
          <div
            className={`${styles.notes_container} rounded text-left ml-2 px-3 py-2`}
          >
            <div className="text-center font-weight-bold">Notes:</div>
            <p className="mt-1">{notes}</p>
          </div>
        )}
      </div>
      <div>Active</div>
    </div>
  )
}

export const OnHold: React.FC<{ notes?: string }> = ({ notes }) => {
  return (
    <div className={`${styles.status_container} d-flex align-items-center`}>
      <div className={`${styles.icon_container}`}>
        <FaRegQuestionCircle className="mr-2" color="#FFFFFF" />
        {notes !== null && (
          <div
            className={`${styles.notes_container} rounded text-left ml-2 px-3 py-2`}
          >
            <div className="text-center font-weight-bold">Notes:</div>
            <p className="mt-1">{notes}</p>
          </div>
        )}
      </div>
      <div>On Hold</div>
    </div>
  )
}

export const Completed: React.FC = () => {
  return <div className={styles.status_container}>Completed</div>
}

export const Unassigned: React.FC = () => {
  return (
    <div className={`${styles.status_container} d-flex align-items-center`}>
      <div className={`${styles.icon_container}`}>
        <HiOutlineExclamationCircle className="mr-2" color="#FFFFFF" />
        <div
          className={`${styles.notes_container} rounded text-left ml-2 px-3 py-2`}
        >
          <div className="font-weight-bold">What is this?</div>
          <p className="mt-1">
            This payee is currently not enrolled in a sub-account. Click Update
            Sub-Accounts above to assign them.
          </p>
        </div>
      </div>
      <div>Unassigned</div>
    </div>
  )
}
