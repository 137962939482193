import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import {onSubmit, setFieldValue, setVariables, validate, YesCancel, YesSave} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {
    SaveConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/SaveConfirmationComponent";
import {
    CancelConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/CancelConfirmationComponent";
import {
    UpdateBookKeeperComponent
} from "../../../../components/CharitableGamingComponents/License/Update/UpdateBookKeeperComponent";
import {PageMessageCommonComponent} from "../../../../components/CommonComponents/PageMessageCommonComponent";

export const UpdateBookkeeperDetailsPage = inject("charitableGamingStore")(observer(({licenseNumber, charitableGamingStore}: any) => {

        const history: any = useHistory();

        let {selectedLicense, errorNotificationBanner} = charitableGamingStore;

        const formik = useFormik({
            initialValues: selectedLicense,
            validate,
            onSubmit
        });

        const [saveToggle, setSaveToggle] = useState<boolean>(false);
        const [cancelToggle, setCancelToggle] = useState<boolean>(false);
        const ActivateSavebutton = (formik.isValid && formik.dirty);

        setVariables(licenseNumber, formik, history, charitableGamingStore);

        const hideErrorNotificationBanner = () => {
            charitableGamingStore.showErrorNotificationBanner('');
        }

        return (
            <>
                {(false) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['update-bookkeeper-details-page-container']} ${styles['']}`}
                     onClick={(e: any) => {
                         if (errorNotificationBanner.message !== "") {
                             hideErrorNotificationBanner();
                         }
                     }}
                >
                    <div className={`${styles["update-bookkeeper-details-ribbon-container"]}`}>
                        <div className={styles["update-bookkeeper-details-ribbon-title"]}>Update Bookkeeper</div>
                        <div className={`${styles['update-bookkeeper-details-ribbon-button-container']}`}>
                            <div tabIndex={0} className={styles["update-bookkeeper-details-ribbon-cancel-button"]}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         setCancelToggle(true);
                                     }
                                 }}
                                 onClick={() => {
                                     setCancelToggle(true);
                                 }}
                            >Cancel
                            </div>
                            <div tabIndex={0} className={styles["update-bookkeeper-details-ribbon-save-button"]}
                                 role="button"
                                 style={{
                                     opacity: ActivateSavebutton ? "1" : "0.4",
                                     cursor: ActivateSavebutton ? "pointer" : "not-allowed",
                                 }}
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         if (ActivateSavebutton) {
                                             setSaveToggle(true);
                                         }
                                     }
                                 }}
                                 onClick={() => {
                                     if (ActivateSavebutton) {
                                         setSaveToggle(true);
                                     }
                                 }}>Save
                            </div>
                        </div>
                    </div>
                    <div className={`${styles["update-bookkeeper-details-error-notification-container"]}`}>
                        {(errorNotificationBanner.message != "") ?
                            <PageMessageCommonComponent
                                message={errorNotificationBanner.message}
                                messageType={errorNotificationBanner.messageType}/>
                            : null
                        }
                    </div>
                    <div className={`${styles["update-bookkeeper-details-page-main-content-container"]}`}>
                        <UpdateBookKeeperComponent formik={formik} setFiledValue={setFieldValue}/>
                    </div>
                </div>
                {saveToggle ?
                    <SaveConfirmationComponent
                        YesSave={YesSave}
                        saveToggle={saveToggle}
                        setSaveToggle={setSaveToggle}>
                    </SaveConfirmationComponent> : null}
                {cancelToggle ?
                    <CancelConfirmationComponent
                        YesCancel={YesCancel}
                        cancelToggle={cancelToggle}
                        setCancelToggle={setCancelToggle}>
                    </CancelConfirmationComponent> : null}
            </>

        );
    })
);