import * as React from 'react'
import { FormikProps, getIn } from 'formik'
import {
  PrizeType,
  PrizeTypeDisplayByPrizeType,
  PromotionType,
} from '../../../../../../api/promotions-fetcher/constants'
import { CircleX } from '../../../../../../icons/circle-x-icon'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { CustomDropdownField } from '../../../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { NumberInput } from '../../../../../CommonComponents/number-input-component/number-input-ui'
import { SideBarTextInput } from '../../../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
} from '../helpers'
import {
  PromotionCampaignValidation,
  PromotionCampaignValidationPrizeTier,
} from '../validation'
import '../styles.scss'

type PromotionPrizeTierFormUIProps = {
  prizeTier: PromotionCampaignValidationPrizeTier
  index: number
  isLoading: boolean
  formik: FormikProps<PromotionCampaignValidation>
  removePrizeTier: () => Promise<void>
  isBonus?: boolean
  prizeTiersField: PromotionCampaignValidationPrizeTier[]
}

const prizeTypeOptions = [
  {
    key: '',
    value: '',
    display: '',
  },
  {
    key: PrizeType.CASH,
    value: PrizeType.CASH,
    display: PrizeTypeDisplayByPrizeType[PrizeType.CASH],
  },
  {
    key: PrizeType.EXPERIENCE,
    value: PrizeType.EXPERIENCE,
    display: PrizeTypeDisplayByPrizeType[PrizeType.EXPERIENCE],
  },
  {
    key: PrizeType.MERCHANDISE,
    value: PrizeType.MERCHANDISE,
    display: PrizeTypeDisplayByPrizeType[PrizeType.MERCHANDISE],
  },
  {
    key: PrizeType.ALTERNATE,
    value: PrizeType.ALTERNATE,
    display: PrizeTypeDisplayByPrizeType[PrizeType.ALTERNATE],
  },
]

const manualEntryPrizeTypeOptions = prizeTypeOptions.filter(
  (type) => type.key !== PrizeType.EXPERIENCE
)

export const PromotionPrizeTierFormUI = (
  props: PromotionPrizeTierFormUIProps
) => {
  const tierNumber = props.prizeTier.tierName || props.index + 1

  const prizeTiersFieldName = props.isBonus ? 'bonusPrizeTiers' : 'prizeTiers'

  const complexPrize = props.prizeTiersField[props.index]?.prizeType === PrizeType.EXPERIENCE || props.prizeTiersField[props.index]?.prizeType === PrizeType.MERCHANDISE
  const nonAlternatePrize = props.prizeTiersField[props.index]?.prizeType !== PrizeType.ALTERNATE

  return (
    <div>
      <div className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'header')}>
        {props.isBonus ? 'Bonus ' : ''}Prize Tier{' '}
        {props.prizeTier.id ? props.prizeTier.tierName : props.index + 1}
        {!props.prizeTier.id && (
          <button
            className={getBlockClass(
              PROMOTION_SIDEBAR_FORM_CLASS,
              'remove-button'
            )}
            onClick={props.removePrizeTier}
            aria-label={`Remove Prize Tier ${tierNumber}`}
            type="button"
          >
            <CircleX />
          </button>
        )}
      </div>
      <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
        <CustomDropdownField
          onChange={props.formik.handleChange}
          value={props.prizeTiersField[props.index]?.prizeType ?? ''}
          error={getIn(
            props.formik.errors,
            `${prizeTiersFieldName}[${props.index}].prizeType`
          )}
          touched={getIn(
            props.formik.touched,
            `${prizeTiersFieldName}[${props.index}].prizeType`
          )}
          onBlur={props.formik.handleBlur}
          id={`${
            props.isBonus ? 'bonus-' : ''
          }prize-tier-${tierNumber}-prize-type-dropdown`}
          name={`${prizeTiersFieldName}[${props.index}].prizeType`}
          options={
            props.formik.values.promotionType ===
            PromotionType.MANUAL_WINNER_ENTRY
              ? manualEntryPrizeTypeOptions
              : prizeTypeOptions
          }
          disabled={props.isLoading}
          label={'Prize Type'}
          greyOnDisabled
        />
      </div>
      {props.prizeTiersField[props.index]?.prizeType && (
        <div
          className={getBlockClass(
            PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
            'prize-tier'
          )}
        >
          {nonAlternatePrize && (
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <SideBarTextInput
                label={'Title'}
                name={`${prizeTiersFieldName}[${props.index}].description`}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.prizeTiersField[props.index].description ?? ''}
                error={getIn(
                  props.formik.errors,
                  `${prizeTiersFieldName}[${props.index}].description`
                )}
                touched={getIn(
                  props.formik.touched,
                  `${prizeTiersFieldName}[${props.index}].description`
                )}
                disabled={props.isLoading}
              />
            </div>
          )}
          <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
            <NumberInput
              label={'Quantity'}
              name={`${prizeTiersFieldName}[${props.index}].numWinners`}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.prizeTiersField[props.index].numWinners ?? ''}
              error={getIn(
                props.formik.errors,
                `${prizeTiersFieldName}[${props.index}].numWinners`
              )}
              touched={getIn(
                props.formik.touched,
                `${prizeTiersFieldName}[${props.index}].numWinners`
              )}
              disabled={props.isLoading}
              min={0}
              step={1}
              required
            />
          </div>
          {complexPrize && (
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <NumberInput
                label={props.prizeTiersField[props.index]?.prizeType === PrizeType.EXPERIENCE ? 'Event Allocation Amount' : 'Non-taxable Merchandise Value'}
                name={`${prizeTiersFieldName}[${props.index}].eventAllocationInDollars`}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={
                  props.prizeTiersField[props.index].eventAllocationInDollars ??
                  ''
                }
                error={getIn(
                  props.formik.errors,
                  `${prizeTiersFieldName}[${props.index}].eventAllocationInDollars`
                )}
                touched={getIn(
                  props.formik.touched,
                  `${prizeTiersFieldName}[${props.index}].eventAllocationInDollars`
                )}
                disabled={props.isLoading}
                min={0}
                step={0.01}
                required
              />
            </div>
          )}
          {complexPrize && (
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <NumberInput
                label={props.prizeTiersField[props.index]?.prizeType === PrizeType.EXPERIENCE ? 'Experience Prize Value' : 'Taxable Merchandise Value'}
                name={`${prizeTiersFieldName}[${props.index}].experienceValueInDollars`}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={
                  props.prizeTiersField[props.index].experienceValueInDollars ??
                  ''
                }
                error={getIn(
                  props.formik.errors,
                  `${prizeTiersFieldName}[${props.index}].experienceValueInDollars`
                )}
                touched={getIn(
                  props.formik.touched,
                  `${prizeTiersFieldName}[${props.index}].experienceValueInDollars`
                )}
                disabled={props.isLoading}
                min={0}
                step={0.01}
                required
              />
            </div>
          )}
          {nonAlternatePrize && (
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <NumberInput
                label={
                  props.prizeTiersField[props.index].prizeType ===
                  PrizeType.EXPERIENCE
                    ? 'Cash Prize Value'
                    : 'Prize Value'
                }
                name={`${prizeTiersFieldName}[${props.index}].prizeValueInDollars`}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={
                  props.prizeTiersField[props.index].prizeValueInDollars ?? ''
                }
                error={getIn(
                  props.formik.errors,
                  `${prizeTiersFieldName}[${props.index}].prizeValueInDollars`
                )}
                touched={getIn(
                  props.formik.touched,
                  `${prizeTiersFieldName}[${props.index}].prizeValueInDollars`
                )}
                disabled={props.isLoading}
                min={0}
                step={0.01}
                required
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
