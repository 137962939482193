import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {getOffsetAmount, getTotalOffsetAmountOwed, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {
    ErrorMessageFieldCommonComponent
} from "../../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import InputMask from 'react-input-mask';
import {formatSSN} from "../../../../util";

export const OffsetsInquiryServicePage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

    const history: any = useHistory();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            SSN: '',
        }, validate, onSubmit
    });

    const activateSearchButton = (formik.isValid && formik.dirty);

    const [playerOffsets, setPlayerOffsets] = useState<any>(null);
    const [requestCompleted, setRequestCompleted] = useState<boolean>(true);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const {notificationBanner} = charitableGamingStore;

    /*useEffect(() => {
    }, []);*/

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    setVariables(formik, charitableGamingStore, setShowErrorDialog, setRequestCompleted, setPlayerOffsets);

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['licensee-charges-page-container']}`}
            >
                <div tabIndex={0}
                     className={`${styles['licensee-charges-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['licensee-charges-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to Services
                    </div>
                </div>
                <div className={`${styles['licensee-charges-page-search-bar-container']} ${styles['']}`}>

                    <div
                        className={`${styles['licensee-charges-page-search-bar-filter-title']} ${styles['']}`}>Offsets
                        Player Inquiry Service
                    </div>

                    <div className={`${styles['licensee-charges-page-datetime-picker-container']}`}>
                        <div
                            className={`${styles['licensee-charges-page-label-datepicker-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>SSN/TIN &nbsp; Must be exact match
                            </div>
                            <InputMask
                                className={`${styles['mslc-datetime-picker']}`}
                                type='text'
                                id="SSN"
                                name="SSN"
                                value={formik.values.SSN}
                                onBlur={formik.handleBlur}
                                mask="999-99-9999"
                                maskChar=" "
                                onChange={(e: any) => {
                                    setFieldValue(e, formik);
                                }}
                            />
                            {(formik.touched.SSN && formik.errors.SSN) ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.SSN}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>

                    </div>

                </div>

                <div className={styles['table']}>

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div>Name</div>
                        <div>SSN/TIN</div>
                        <div>Back Taxes Owed</div>
                        <div>Child Support Owed</div>
                        <div>Comptroller Owed</div>
                        <div>Total Amount Owed</div>
                    </div>

                    {playerOffsets ? <div className={styles['table-data-body']}>
                        <div className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                {playerOffsets.offsetsResult.playerInfo.lastName}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatSSN(playerOffsets.offsetsResult.playerInfo.ssn)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {getOffsetAmount(playerOffsets.offsetsResult.offsetsOwed, 'TAXES')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {getOffsetAmount(playerOffsets.offsetsResult.offsetsOwed, 'CHILD_SUPPORT')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {getOffsetAmount(playerOffsets.offsetsResult.offsetsOwed, 'COMPTROLLER')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {getTotalOffsetAmountOwed(playerOffsets.offsetsResult.offsetsOwed)}
                            </div>
                        </div>
                    </div> : <div className={`${styles['licensee-charges-page-search-no-results-container']}`}>
                        <div className={`${styles['licensee-charges-page-search-no-results-container-text']}`}>
                            No Results
                        </div>
                    </div>}

                </div>
            </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                type={NotificationType.Success}
                text={notificationBanner.text}
                handleHide={hideNotificationBanner}
            /> : null}
        </>

    );
}));