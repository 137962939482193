import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ImageViewer } from '../../CommonComponents/ImageViewer/image-viewer-ui'
import styles from './css/styles.module.css'

export type DocumentImageViewerProps = {
  testIfImageIsAvailable: () => Promise<void>
  imageSrc?: string
  altText: string
}

export const DocumentImageViewer = (props: DocumentImageViewerProps) => {
  React.useEffect(() => {
    props.testIfImageIsAvailable()
    return () => {}
  }, [])

  return (
    <div className={styles['document-image-viewer']}>
      {!props.imageSrc ? (
        <div className={styles['document-image-viewer__loading']}>
          <CircularProgress />
        </div>
      ) : (
        <ImageViewer src={props.imageSrc} alt={props.altText} />
      )}
    </div>
  )
}
