import {makeHttpCall} from "../../../../services/MakeHttpCall";
import config, {taxformRoutePath} from "../../../../config";
import {AnnualBeanoSubmissionBody} from "@interaction-gaming/mslc-charitable-gaming-components";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";

let taxFormId: any;
let charitableGamingStore: any;
let history: any;
let setRequestCompleted: any;
let setShowErrorDialog: any;

export const setVariables = (taxFormIdObj: any, charitableGamingStoreObj: any, historyObj: any, setRequestCompletedObj: any, setShowErrorDialogObj: any) => {
    taxFormId = taxFormIdObj;
    charitableGamingStore = charitableGamingStoreObj;
    history = historyObj;
    setRequestCompleted = setRequestCompletedObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const getAnnualBeanoFormData = async (setAnnualBeanoFormData: any, setOrgData: any) => {

    const userpolicies: any = localStorage.getItem("userpolicies")
    if (!checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_TAX_TAB, JSON.parse(userpolicies))) {
        return;
    }

    setRequestCompleted(false);

    try {

        // Get the Weekly Beano Tax form Details
        const options1 = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/taxForm/annualBeano/${taxFormId}`,
        }
        const response1 = await makeHttpCall(options1);

        // Get the Org. details
        const options2 = {
            method: 'get',
            url: `${config.SERVER_BASE_URL}/api/v1/licensee/retrieve/${response1.taxForm.licenseNumber}`,
        }
        const response2 = await makeHttpCall(options2);

        setOrgData({
            organizationName: response2.licensee.orgDetails.orgName,
            idNumber: response2.licensee.licenseDetails.licenseNumber,
            address: response2.licensee.orgDetails.orgAddress.streetAddress,
            city: response2.licensee.orgDetails.orgAddress.city,
            state: 'MA',
            zipCode: response2.licensee.orgDetails.orgAddress.zip
        });
        setAnnualBeanoFormData(response1.taxForm);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}


export const submitAnnualBeanoForm = async (body: AnnualBeanoSubmissionBody | any, taxFormId: any) => {

    setRequestCompleted(false);

    delete body.year;
    delete body.licenseNumber;

    try {

        const options = {
            method: 'PUT',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/annualBeano/${taxFormId}`,
            data: body
        }

        const response = await makeHttpCall(options);

        setRequestCompleted(true);

        history.push({
            pathname: `${taxformRoutePath}/manage`, state: {tabName: 'annualBeano'} // This is so the 'Annual Beano' Tab is selected when you go back
        });

        charitableGamingStore.showNotificationBanner({
            show: true, type: NotificationType.Success, text: 'Annual Beano Form saved successfully'
        });

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}
