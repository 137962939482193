import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {PaginationCommonComponent} from "../../../CommonComponents/PaginationCommonComponent";
import moment from "moment";
import ExclamationIcon from "../../../../icons/icon_status_flagged.svg";
import {SearchResultExeceededInfoMessageComponent} from "../../Common/SearchResultExeceededInfoMessageComponent";
import {formatCentsToDollars} from "../../../../util";
import {keyPressedType} from "../../../../services/Commons";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import {taxformRoutePath} from "../../../../config";
import {getPageData} from "../../../../pages/CharitableGamingPages/TaxForm/TaxFormManagementPage/functions";

export const AnnualBeanoSearchResultComponent = ({annualBeanoTaxForms, currentPage, setCurrentPage,}: any) => {

    const history = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        setTimeout(function () {
            setShowSearchResultExceededInfoMessage(false);
        }, 3000);
    }, []);

    return (<>
            <div className={styles['table']}>

                <div className={styles['table-data-header']}>
                    <div className={styles['table-data-header-text']} aria-live="assertive">
                        {`Displaying ${annualBeanoTaxForms.forms.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(annualBeanoTaxForms.forms.length, currentPage)}
                            of ${annualBeanoTaxForms.forms.length}${annualBeanoTaxForms.additionalResults ? "+" : ""} results`}
                    </div>
                    {annualBeanoTaxForms.additionalResults ? <div
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                            setShowSearchResultExceededInfoMessage(true);
                        }}
                        onMouseLeave={() => {
                            setShowSearchResultExceededInfoMessage(false);
                        }}
                    >
                        <img
                            src={ExclamationIcon}
                            height={20}
                            width={20}
                            alt="exclamation icon"
                        />
                        {annualBeanoTaxForms.additionalResults && showSearchResultExceededInfoMessage ?
                            <SearchResultExeceededInfoMessageComponent/> : null}
                    </div> : null}
                </div>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>License ID</div>
                    <div>Org Name</div>
                    <div>Year</div>
                    <div>Submission Date</div>
                    <div>Gross Receipts</div>
                    <div>Prizes</div>
                    <div>Expenses</div>
                    <div>Taxes</div>
                    <div>Net Profit or Loss</div>
                </div>

                <div className={styles['table-data-body']}>
                    {annualBeanoTaxForms.forms.length > 0 && getPageData(annualBeanoTaxForms.forms, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                {e.licenseNumber}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.orgName}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.year}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.submissionDate).format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.grossReceiptsInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.prizesInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.expensesInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.taxesInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.netProfitOrLossInCents)}
                            </div>
                            <div className={styles['table-details-button-container']}>
                                {checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_FORM, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (keyPressedType(en) === 13 || keyPressedType(en) === 32) {
                                                 history.push(`${taxformRoutePath}/edit/annualbeano/${e.taxFormId}`);
                                             }
                                         }}
                                         onClick={() => {
                                             history.push(`${taxformRoutePath}/edit/annualbeano/${e.taxFormId}`);
                                         }}
                                         className={styles['table-details-button']}>
                                        View Form
                                    </div> : null}
                            </div>
                        </div>)}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((annualBeanoTaxForms.forms.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={annualBeanoTaxForms.forms}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>
        </>);
};
