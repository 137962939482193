import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import FocusTrap from "focus-trap-react";

export type SourceGuideEntry = {
    abbreviation: string,
    fullName: string
}

export type SourceGuideUIProps = {
    setPaymentSourceGuide: (value: boolean) => void
    entries: SourceGuideEntry[]
}

export const SourceGuideUI = (props: SourceGuideUIProps) => {
    return (

        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["cancel-confirmation-dialog-main-container"]}>
                    <div
                        id="cancel-confirmation-dialog-container"
                        className={stylesMessageBox["cancel-confirmation-dialog-container"]}
                    >
                        <div className={stylesMessageBox["cancel-confirmation-dialog-message"]}>
                            Source Guide
                        </div>
                        <div className={`${stylesMessageBox['check-details-controls-container']}`}>
                            {
                                props.entries.map((guideEntry) => {
                                    return <>
                                        <div key={`${guideEntry.abbreviation}-abbreviaton`}
                                            className={`${stylesMessageBox['check-details-controls-container-column-1']} ${stylesMessageBox['mslc-label']}`}>{guideEntry.abbreviation}
                                        </div>
                                        <div key={`${guideEntry.abbreviation}-fullName`}
                                            className={`${stylesMessageBox['check-details-controls-container-column-2']} ${stylesMessageBox['mslc-label']}`}>{guideEntry.fullName}
                                        </div>
                                    </>
                                })
                            }
                        </div>
                        <button
                            onClick={() => {
                                props.setPaymentSourceGuide(false);
                            }}
                            className={stylesMessageBox["cancel-confirmation-dialog-yes-button"]}
                        >
                            <div className={stylesMessageBox["cancel-confirmation-dialog-yes-button-text"]}>
                                Close
                            </div>
                        </button>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};