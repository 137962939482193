import React from "react"
import { useFetchReports } from "./use-fetch-reports"
import { ReconciliationPdfReportsPageUI } from "./reconciliation-pdf-reports-page-ui"
import { useFetchDownloadReconciliationReport } from "./use-fetch-download-report"

export const ReconciliationPdfReportsPageContainer = () => {
  const {
    isLoading: isGetReportsLoading,
    error: getReportsError,
    data: getReportsData,
    fetchReports
  } = useFetchReports()

  const {
    isLoading: isDownloadReportLoading,
    error: downloadError,
    preSignedUrlHandler
  } = useFetchDownloadReconciliationReport()

  React.useEffect(() => {
    fetchReports()
  }, [])

  return (
    <ReconciliationPdfReportsPageUI
      isLoading={isGetReportsLoading || isDownloadReportLoading}
      error={getReportsError || downloadError}
      reports={getReportsData?.reports ?? []}
      downloadReport={preSignedUrlHandler}
    />
  )
}