import moment from 'moment'
import { GenericTableActionMenu } from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-action-menu/generic-table-row-action-menu-ui'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
} from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import { GenericSidebarFormMode } from '../../../components/GenericSidebar/generic-sidebar-ui'
import { PlayslipsPromotionMessage } from '../../../api/playslips-fetcher/constants'

export enum PromotionMessageStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
}

const promotionStatusColors: Record<PromotionMessageStatus, string> = {
  [PromotionMessageStatus.PENDING]: '#FFEB00',
  [PromotionMessageStatus.ACTIVE]: '#0FCE00',
  [PromotionMessageStatus.EXPIRED]: '#676767',
}

export const PromotionMessageStatusOrder: Record<
  PromotionMessageStatus,
  number
> = {
  [PromotionMessageStatus.ACTIVE]: 1,
  [PromotionMessageStatus.PENDING]: 2,
  [PromotionMessageStatus.EXPIRED]: 3,
}

export type PromotionMessageTableRow = {
  title: string
  message: string
  game: string
  status: PromotionMessageStatus
  dates: string
  actionMenu: GenericTableActionMenu
}

export const promotionalMessageTableColumnMap: GenericTableColumnMap<PromotionMessageTableRow> =
  [
    {
      key: 'title',
      columnText: 'Title',
      columnType: GenericTableColumnType.BOLD,
    },
    {
      key: 'message',
      columnText: 'Message',
      columnType: GenericTableColumnType.SMALL,
      widthPx: 300,
    },
    {
      key: 'game',
      columnText: 'Game',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'status',
      columnText: 'Status',
      columnType: GenericTableColumnType.STATUS,
      statusMap: promotionStatusColors,
    },
    {
      key: 'dates',
      columnText: 'Dates',
      columnType: GenericTableColumnType.DEFAULT,
      widthPx: 210,
    },
  ]

export const mapPromotionMessageDataToRow = (
  promotion: PlayslipsPromotionMessage,
  actionsDisabled: boolean,
  setFormMode: (
    formMode: GenericSidebarFormMode,
    message?: PlayslipsPromotionMessage
  ) => void,
  openDeleteModal: () => void
): PromotionMessageTableRow => {
  const activeFromDate = promotion.activeFrom
    ? moment(promotion.activeFrom)
    : undefined
  const activeToDate = promotion.activeTo
    ? moment(promotion.activeTo)
    : undefined
  const today = moment()

  let status = PromotionMessageStatus.ACTIVE

  if (activeToDate && activeToDate.isBefore(today)) {
    status = PromotionMessageStatus.EXPIRED
  } else if (activeFromDate && activeFromDate.isAfter(today)) {
    status = PromotionMessageStatus.PENDING
  }

  const longDashCharacter = String.fromCharCode(8212)
  const formattedActiveFrom =
    activeFromDate?.format('MM/DD/YYYY hh:mm:ss A') ?? 'TBD'
  const formattedActiveTo =
    activeToDate?.format('MM/DD/YYYY  hh:mm:ss A') ?? 'TBD'

  return {
    title: promotion.title,
    message: promotion.message,
    game: promotion.gameName ?? longDashCharacter,
    status: status,
    dates:
      !activeFromDate && !activeToDate
        ? longDashCharacter
        : `${formattedActiveFrom} - ${formattedActiveTo}`,
    actionMenu: {
      ariaLabel: `Actions for ${promotion.title}`,
      menuItems: [
        {
          onClick: () => setFormMode(GenericSidebarFormMode.EDIT, promotion),
          label: 'Edit',
          disabled:
            actionsDisabled || status === PromotionMessageStatus.EXPIRED,
          ariaLabel: `Edit ${promotion.title}`,
          key: 'edit',
        },
        {
          onClick: openDeleteModal,
          label: 'Delete',
          disabled:
            actionsDisabled || status === PromotionMessageStatus.EXPIRED,
          ariaLabel: `Delete ${promotion.title}`,
          key: 'delete',
        },
      ],
    },
  }
}
