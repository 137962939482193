import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import moment from "moment";

let raffleId: string;
let charitableGamingStore: any;

export const setVariables = (raffleIdString: string, charitableGamingStoreObj: any) => {
    raffleId = raffleIdString;
    charitableGamingStore = charitableGamingStoreObj;
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.effectiveDate) {
        Object.assign(errors, {effectiveDate: InputValidationMessages.RequiredField});
    } else if (values.effectiveDate) {
        const currentDOI = moment(charitableGamingStore.selectedPermitHolder.effectiveDate).format('MM/DD/YYYY');
        const newDOI = moment(values.effectiveDate).format('MM/DD/YYYY');
        if (newDOI === currentDOI) {
            Object.assign(errors, {effectiveDate: InputValidationMessages.InvalidDOI});
        }
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updatePermitDateOfIssuance(values, raffleId);
}




