import React from "react"
import { GetPendingReissuePaymentsResponse } from "../../../api/pending-reissue-payments-fetcher/api-types"
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { ReissueQueueContainerUI } from "../../../components/PaymentComponents/ReissueQueueComponents/reissue-queue-container/reissue-queue-container-ui"

export type ReissueQueuePageUIProps = {
  pendingReissuesData : GetPendingReissuePaymentsResponse | undefined
  isLoading: boolean
  error: string | undefined
  fetchInitiateReissueRun: (futurePayments: string[]) => void
  initiateReissueRunError : string | undefined
}

export const ReissueQueuePageUI = (props: ReissueQueuePageUIProps) => {
  return (
    <AsyncContainerUI
      isLoading = {props.isLoading}
      error={props.error}
      errorTextSize={20}
      color="white"
    >
      {props.pendingReissuesData ? (
        <ReissueQueueContainerUI
          pendingReissuesData={props.pendingReissuesData}
          fetchInitiateReissueRun={props.fetchInitiateReissueRun}
          initiateReissueRunError={props.initiateReissueRunError}
        />
      ) : (
        <div>Something went wrong</div>
      )}
    </AsyncContainerUI>
  )
}