import * as React from 'react'
import { getBlockClass } from '../../../../../../utilities/helpers'
import {
  CustomRadioButtonOptions,
  RadioButtonInput,
} from '../../../../../radio-button-input-component/radio-button-input-ui'
import { PromotionCampaignTooltip } from '../../../promotion-campaign-tooltip/promotion-campaign-tooltip-ui'
import {
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  promotionIsNewOrPending,
} from '../helpers'
import { PromotionEntriesDistributionDetailsFormUI } from './entries-distribution-details-form-ui'
import {
  PromotionBuilderReward,
  RewardType,
} from '../../../../../../api/promotions-fetcher/constants'
import '../styles.scss'

type PromotionEntriesDistributionFormUIProps =
  PromotionCampaignBuilderSubFormProps

export const PromotionEntriesDistributionFormUI = (
  props: PromotionEntriesDistributionFormUIProps
) => {
  const [entriesDistributionActive, setEntriesDistributionActive] =
    React.useState<string>(
      props.formik.values.hasEntriesDistribution ? 'yes' : 'no'
    )

  const updateEntriesDistribution = async () => {
    await props.formik.setFieldValue(
      'hasEntriesDistribution',
      entriesDistributionActive === 'yes'
    )
    if (
      entriesDistributionActive === 'yes' &&
      props.formik.values.promotionRewardGroups[0].promotionRewards.length === 0
    ) {
      const defaultDistribution: PromotionBuilderReward = {
        id: undefined,
        rewardType: RewardType.DRAWING_ENTRY,
        quantity: 1,
        distributionPercentage: 100,
      }
      await props.formik.setFieldValue(
        'promotionRewardGroups[0].promotionRewards',
        [defaultDistribution]
      )
    } else if (
      entriesDistributionActive === 'no' &&
      props.formik.values.promotionRewardGroups[0].promotionRewards.length > 1
    ) {
      const singleRewardGroup: PromotionBuilderReward = {
        rewardType: RewardType.DRAWING_ENTRY,
        quantity: 1,
        distributionPercentage: 100,
      }
      await props.formik.setFieldValue(
        'promotionRewardGroups[0].promotionRewards',
        [singleRewardGroup]
      )
    }
  }

  React.useEffect(() => {
    updateEntriesDistribution()
  }, [entriesDistributionActive])

  const entriesDistributionOptions: CustomRadioButtonOptions[] = [
    {
      id: 'yes',
      value: 'yes',
      display: 'Yes',
      defaultChecked: props.formik.values.hasEntriesDistribution,
    },
    {
      id: 'no',
      value: 'no',
      display: 'No',
      defaultChecked: !props.formik.values.hasEntriesDistribution,
    },
  ]

  return (
    <>
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'entries-distribution'
        )}
      >
        <RadioButtonInput
          label={
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                'label'
              )}
            >
              Entries Distribution
              <PromotionCampaignTooltip
                tooltipText="Entries Distribution allows multiple entries per scan based on the distribution percentage. The percentage must equal 100%. The numbers must be whole numbers."
                altText="Entries Distribution Tooltip"
                widthPx={190}
              />
            </div>
          }
          name={'entriesDistributionActive'}
          options={entriesDistributionOptions}
          onChange={(e) => setEntriesDistributionActive(e.target.value)}
          onBlur={() => undefined}
          value={entriesDistributionActive}
          touched={undefined}
          error={undefined}
          disabled={
            props.isLoading ||
            !props.canEditPromotionCampaigns ||
            !promotionIsNewOrPending(props.promotionStatus) ||
            (props.formik.values.promotionRewardGroups[0].promotionRewards
              .length > 1 &&
              Boolean(
                props.formik.values.promotionRewardGroups[0].promotionRewards[1]
                  .id
              ))
          }
          greyOnDisabled
        />
      </div>
      {entriesDistributionActive === 'yes' && (
        <>
          <PromotionEntriesDistributionDetailsFormUI {...props} />
        </>
      )}
    </>
  )
}
