import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/styles.module.css";
import {PaginationCommonComponent} from "../../../../CommonComponents/PaginationCommonComponent";
import {getPageData} from "./functions";
import {PullTabGameInterface} from "../../../../../pages/CharitableGamingPages/PullTab/Games/PullTabGamesPage/interfaces";
import {keyPressedType} from "../../../../../services/Commons";
import {pulltabRoutePath} from "../../../../../config";
import {permissions} from "../../../../../constants";
import {checkRenderPermissions} from "../../../../../helpers";
import {formatDollarAmount} from "../../../../../util";
import {inject, observer} from "mobx-react";

export const PullTabGameSearchResultComponent = inject("charitableGamingStore")(observer(({currentPage, setCurrentPage, charitableGamingStore}: any) => {

        const history = useHistory();
        const userpolicies: any = localStorage.getItem("userpolicies");

        const {filteredPullTabGames} = charitableGamingStore;

        const normalizePage = (totalPages: number, currentPage: number) => {
            const number: number = totalPages - currentPage * 10;
            if (number > 10) return currentPage * 10 + 10;
            else return totalPages;
        };

        return (
            <>

                <div className={styles['table']}>
                    <div className={styles['table-data-header']}>
                        <div aria-live="assertive"
                             tabIndex={0}>{`Displaying ${filteredPullTabGames.length <= 0 ? 0 :
                            (currentPage * 10) + 1}-${normalizePage(filteredPullTabGames.length, currentPage)} 
                        of ${filteredPullTabGames.length} results`}</div>
                    </div>

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div tabIndex={0}>Stock No.</div>
                        <div tabIndex={0}>Game Name</div>
                        <div tabIndex={0}>Unit Cost</div>
                        <div tabIndex={0}>Unit Weight</div>
                        <div tabIndex={0}>Units Per Case</div>
                        <div tabIndex={0}>Case Cost</div>
                        <div tabIndex={0}>Case Weight</div>
                    </div>

                    <div className={styles['table-data-body']}>
                        {filteredPullTabGames.length > 0 &&
                        getPageData(filteredPullTabGames, currentPage).map((e: PullTabGameInterface, i: number) =>

                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {e.stockNumber}
                                </div>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {e.gameName}
                                </div>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {formatDollarAmount(Number(e.unitPriceInDollars))}
                                </div>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {`${e.unitWeight} lbs`}
                                </div>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {e.unitsPerCase}
                                </div>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {formatDollarAmount(Number(e.casePriceInDollars))}
                                </div>
                                <div tabIndex={0} className={styles['table-data-data-text']}>
                                    {`${e.caseWeight} lbs`}
                                </div>
                                <div className={styles['table-details-button-container']}>
                                    {checkRenderPermissions(permissions.CAN_UPDATE_PULL_TAB_GAMES, JSON.parse(userpolicies)) ?
                                        <div tabIndex={0} role='button' aria-pressed={"false"}
                                             onKeyDown={(en: any) => {
                                                 if (
                                                     keyPressedType(en) === 13 ||
                                                     keyPressedType(en) === 0 ||
                                                     keyPressedType(en) === 32
                                                 ) {
                                                     charitableGamingStore.getPullTabGameByStockNumber(e.stockNumber);
                                                     history.push(`${pulltabRoutePath}/update/${e.stockNumber}`);
                                                 }
                                             }}
                                             onClick={() => {
                                                 charitableGamingStore.getPullTabGameByStockNumber(e.stockNumber);
                                                 history.push(`${pulltabRoutePath}/update/${e.stockNumber}`);
                                             }}
                                             className={styles['table-details-button']}>
                                            <div aria-label={'Licensee Details Button.'}
                                                 className={styles['table-details-button-text']}>
                                                Update
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles['table-data-footer']}>

                        <div aria-live="assertive" tabIndex={0}
                             className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((filteredPullTabGames.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={filteredPullTabGames} currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>
                </div>


            </>

        );
    })
);
