import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import { FuturePaymentSource, FuturePaymentStatus } from '../../../../api/future-payments-fetcher/api-types'
import { permissions } from '../../../../constants'
import { futurePaymentsRoutePath } from '../../../../config.js'
import { checkRenderPermissions } from '../../../../helpers'
import { UpdateFuturePaymentStatusUI } from '../future-payment-details-update-status/future-payment-details-update-status-ui'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import { ConfirmationPopupUI } from '../../../CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui'

const ROOT_CLASS = 'future-payment-details-title-bar'

export type FuturePaymentDetailsTitleBarUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse
  updatePaymentStatus: (status: FuturePaymentStatus, notes: string) => void
  deletePayment: () => void
}

export const FuturePaymentDetailsTitleBarUI = (
  props: FuturePaymentDetailsTitleBarUIProps
) => {
  const [showStatusModal, setShowStatusModal] = React.useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)

  const history = useHistory()

  const userpolicies: any = localStorage.getItem('userpolicies')

  const optionallyRenderDeletePaymentCTA = (): React.ReactNode | undefined => {
    const paymentStatus = props.futurePaymentDetailsData.status
    const paymentSource = props.futurePaymentDetailsData.source

    return checkRenderPermissions(
      permissions.CAN_DELETE_FUTURE_PAYMENT,
      JSON.parse(userpolicies)
    ) && (paymentStatus === FuturePaymentStatus.PENDING || 
          paymentStatus === FuturePaymentStatus.ON_HOLD)
      && paymentSource !== FuturePaymentSource.ANNUITIES ? (
      <button
        className={`red button`}
        onClick={() => {
          console.log(`delete payment not wired up yet`)
          setShowDeleteModal(true)
        }}
      >
        <div className={`button-label`}>Delete Payment</div>
      </button>
    ) : null
  }

  const optionallyRenderUpdateHoldStatusCTA = ():
    | React.ReactNode
    | undefined => {
    return checkRenderPermissions(
      permissions.CAN_UPDATE_FUTURE_PAYMENT_STATUS,
      JSON.parse(userpolicies)
    ) ? (
      <button
        className={`green button`}
        onClick={() => {
          setShowStatusModal(true)
        }}
      >
        <div className={`button-label`}>Update Hold Status</div>
      </button>
    ) : null
  }

  const optionallyRenderUpdatePayeeDetailsCTA = () => {
    const paymentStatus = props.futurePaymentDetailsData.status
    const paymentSource = props.futurePaymentDetailsData.source

    const canUpdatePayeeDetails = checkRenderPermissions(
      permissions.CAN_UPDATE_FUTURE_PAYMENT_DETAILS,
      JSON.parse(userpolicies)
    ) && (paymentStatus === FuturePaymentStatus.ON_HOLD || paymentStatus === FuturePaymentStatus.PENDING)
    && paymentSource !== FuturePaymentSource.ANNUITIES

    return  canUpdatePayeeDetails && (
      <button
        className={'blue button'}
        onClick={() => {
          history.push(`${futurePaymentsRoutePath}/update/${props.futurePaymentDetailsData.id}`)
        }}
      >
        <div className={`button-label`}>Update Payee Info</div>
      </button>
    )
  }

  return (
    <div className={ROOT_CLASS}>
      <div className={`title`}>Payment Details</div>
      <div className={`buttons-container`}>
        {optionallyRenderDeletePaymentCTA()}
        {optionallyRenderUpdatePayeeDetailsCTA()}
        {optionallyRenderUpdateHoldStatusCTA()}
      </div>
      {showStatusModal && (
        <UpdateFuturePaymentStatusUI
          futurePayment={props.futurePaymentDetailsData}
          setShowStatusModal={setShowStatusModal}
          updatePaymentStatus={props.updatePaymentStatus}
        />
      )}
      {showDeleteModal && (
        <ConfirmationPopupUI 
          message={'Are you sure you want to delete this payment?'}
          subMessage={'This action cannot be undone.'}
          confirmText={'Delete'}
          goBackText={'Go Back'}
          confirmCallback={props.deletePayment}
          closePopup={() => {
            setShowDeleteModal(false)
          }}
        />
      )}
    </div>
  )
}
