import * as React from 'react'
import { ImagePreview, ImagePreviewUI } from './image-preview-component/image-preview-component-ui'
import { TextPreviewUI } from './text-preview-component/text-preview-component-ui'

export type FilePreview = ImagePreview

type FilePreviewProps = {
  filePreviews: FilePreview[]
  imagePreview?: boolean
  allowIndividualRemoval?: boolean
  removeFile: (filename: string) => void
  clearFiles: () => void
}

export const FilePreviewUI = (props: FilePreviewProps) => {
  return props.imagePreview ? (
    <ImagePreviewUI imagePreviews={props.filePreviews} {...props} />
  ) : (
    <TextPreviewUI {...props} />
  )
}
