import React from 'react'
import {
  CheckRunReportDisplayOptions,
  CheckRunReportListEntry,
  CheckRunReportStatus,
  CheckRunReportType,
  ListCheckRunReportsResponse,
  PreviewReportProps,
} from '../../../api/check-run-reporting-fetcher/api-types'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import {
  SearchResultsTableColumnMap,
  SearchResultsTableUI,
} from '../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { CheckRunReportingActionUI } from '../../../components/ReportingComponents/CheckRunReporting/check-run-reporting-action-ui/check-run-reporting-action-ui'
import { CheckRunReportingDownloadButton } from '../../../components/ReportingComponents/CheckRunReporting/check-run-reporting-download-button/check-run-reporting-download-button'
import { CheckRunReportingTitleBar } from '../../../components/ReportingComponents/CheckRunReporting/check-run-reporting-title-bar/check-run-reporting-title-bar'
import { toEasternTime } from '../../../utilities/date-helpers'
import './styles.scss'

const ROOT_CLASS = 'check-run-reporting-page'

export type CheckRunReportingPageUIProps = {
  isLoading: boolean
  error?: string
  checkRunReportingData?: ListCheckRunReportsResponse
  downloadHandler: (row: CheckRunReportDisplayRow) => Promise<void>
  generateHandler: (props: PreviewReportProps) => void
}

export type CheckRunReportDisplayRow = Omit<
  CheckRunReportListEntry,
  'reportType'
> & {
  reportType: CheckRunReportDisplayOptions
}

const formatReportName = (
  reportName: CheckRunReportType
): CheckRunReportDisplayOptions => {
  switch (reportName) {
    case CheckRunReportType.PREVIEW_ALL_PAYMENTS:
      return CheckRunReportDisplayOptions.ALL_PAYMENTS
    case CheckRunReportType.PREVIEW_ON_HOLD_PAYMENTS_ONLY:
      return CheckRunReportDisplayOptions.HOLD_PAYMENTS
    case CheckRunReportType.PREVIEW_PENDING_PAYMENTS_ONLY:
      return CheckRunReportDisplayOptions.NO_HOLD
    case CheckRunReportType.PREVIEW_RELEASED_PAYMENTS_ONLY:
      return CheckRunReportDisplayOptions.RELEASED_HOLDS
    case CheckRunReportType.CHECK_RUN_REPORT:
      return CheckRunReportDisplayOptions.CHECK_RUN_REPORT
    case CheckRunReportType.REISSUE_CHECK_RUN_REPORT:
      return CheckRunReportDisplayOptions.REISSUE_CHECK_RUN_REPORT
    default:
      return CheckRunReportDisplayOptions.UNKNOWN
  }
}

const mapCheckRunReportListToRow = (
  checkRunReportList: CheckRunReportListEntry
): CheckRunReportDisplayRow => {
  const dateTimeInEastern = toEasternTime(checkRunReportList.dateGenerated)
  const formattedDateTime = `${dateTimeInEastern.format(
    'MM/DD/YYYY'
  )} ${dateTimeInEastern.format('HH:MM')}`
  return {
    id: checkRunReportList.id,
    dateGenerated: formattedDateTime,
    generatedBy: checkRunReportList.generatedBy,
    status: checkRunReportList.status,
    reportType: formatReportName(checkRunReportList.reportType),
  }
}

const CHECK_RUN_REPORT_COLUMN_MAP: SearchResultsTableColumnMap<CheckRunReportDisplayRow> =
  [
    { dataKey: 'dateGenerated', label: 'Date & Time Generated', width: 15 },
    { dataKey: 'generatedBy', label: 'Generated By', width: 15 },
    { dataKey: 'reportType', label: 'Report Name', width: 25 },
    { dataKey: 'status', label: 'Status', width: 15 },
  ]

export const CheckRunReportingPageUI = (
  props: CheckRunReportingPageUIProps
) => {
  const downloadCheckRunReportCTA = (row: CheckRunReportDisplayRow) => {
    return (
      row.status === CheckRunReportStatus.SUCCESS && (
        <CheckRunReportingDownloadButton
          row={row}
          downloadHandler={props.downloadHandler}
        />
      )
    )
  }

  return (
    <div className={ROOT_CLASS}>
      <AsyncContainerUI
        isLoading={props.isLoading}
        error={props.error}
        errorTextSize={20}
        color="white"
      >
        <BackButtonUI tabIndex={0} message="Back" />
        <CheckRunReportingTitleBar />
        <CheckRunReportingActionUI {...props} />
        <SearchResultsTableUI
          rowData={
            props.checkRunReportingData?.checkRunReports.map(
              mapCheckRunReportListToRow
            ) ?? []
          }
          columnMap={CHECK_RUN_REPORT_COLUMN_MAP}
          generateRowCTA={downloadCheckRunReportCTA}
          additionalResults={false}
        />
      </AsyncContainerUI>
    </div>
  )
}
