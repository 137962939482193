import config from '../../../config'
import { useFetch } from "../../../hooks/use-fetch"
import { ListCheckRegisterReportsResponse } from '../../../api/check-register-reports-fetcher/api-types'

const getReportsUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/checkRegister/reports`

export const useFetchCheckRegisterReports = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<ListCheckRegisterReportsResponse>()
  const fetchReports = () => {
    fetchUrl(getReportsUrl, 'GET')
  }

  return {
    isLoading,
    error,
    data,
    fetchReports
  }
}