import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {keyPressedType} from "../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../icons/Icon_arrows_carrot_white-left.svg";
import {
    clearSearch,
    getOpenCalendarSweeps,
    getPageData,
    onSubmit,
    processW2GSweep,
    searchBySSN,
    setFieldValue,
    setVariables,
    validate
} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";
import {
    GenericErrorDialogComponent
} from "../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../components/HomeComponent/NotificationBannerComponent";
import filterCollapseIcon from "../../../icons/Icon_arrows_carrot_teal-up.svg";
import filterExpandIcon from "../../../icons/Icon_arrows_carrot_teal-down.svg";
import {ErrorMessageFieldCommonComponent} from "../../../components/CommonComponents/ErrorMessageFieldCommonComponent";
import {PaginationCommonComponent} from "../../../components/CommonComponents/PaginationCommonComponent";
import {formatCentsToDollars} from "../../../util";
import {paymentsRoutePath, w2gQueueRoutePath} from "../../../config";
import ExclamationIcon from "../../../icons/icon_status_flagged.svg";
import InputMask from 'react-input-mask';
import {
    PrintPaymentsConfirmationComponent
} from "../../../components/PaymentComponents/PrintPaymentsConfirmationComponent";

export const W2GQueuePage = () => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            year: '', sweepId: '', SSN: ''
        }, validate, onSubmit
    });

    const [W2GQueue, setW2GQueue] = useState<any>(null);
    const [openCalendarSweeps, setOpenCalendarSweeps] = useState<any>(null);

    const [requestCompleted, setRequestCompleted] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [printPaymentsConfirmation, setPrintPaymentsConfirmation] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const [notificationBanner, showNotificationBanner] = useState<any>({
        show: false, type: NotificationType.Success, text: ''
    });

    const [showFilters, setShowFilters] = useState<boolean>(true);

    const activateSearchButton = (formik.values.year && formik.values.SSN && formik.values.SSN.length === 11);
    const activateClearSearchButton = W2GQueue ? true : false;
    const activatePrintButton = (W2GQueue && W2GQueue.printable && W2GQueue.paymentsInSweep && W2GQueue.paymentsInSweep.length > 0);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        getOpenCalendarSweeps();
    }, []);

    const hideNotificationBanner = () => {
        showNotificationBanner({show: false, text: ''});
    }

    const showPrintWarningMessage = () => {
        if (W2GQueue) {
            if (W2GQueue.printable) {
                return false
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    setVariables(formik, setRequestCompleted, setOpenCalendarSweeps, setW2GQueue, setCurrentPage, showNotificationBanner, setShowErrorDialog);

    return (<>
        {(!requestCompleted) ? <div style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: "100vw",
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "rgba(0,0,0,0.3)",
            color: 'white'
        }}>
            <CircularProgress size="4em" color='inherit' thickness={2}/>
        </div> : null}

        <div className={`${styles['pulltab-inventory-page-container']} ${styles['']}`}
        >
            <div tabIndex={0}
                 className={`${styles['pulltab-inventory-page-back-button-container']} ${styles['']}`}
                 onKeyDown={(ev: any) => {
                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                         history.goBack();
                     }
                 }}
                 onClick={() => {
                     history.goBack();
                 }}
            >
                <img
                    style={{
                        cursor: "pointer"
                    }}
                    src={goBackIcon}
                    className={styles[""]}
                    alt="go back icon"
                />
                <div
                    className={`${styles['pulltab-inventory-page-back-button-container-message']} ${styles['']}`}
                    style={{
                        cursor: "pointer"
                    }}
                >Back to Payments
                </div>
            </div>
            <div className={`${styles['pulltab-inventory-page-ribbon-container']} ${styles['']}`}>
                <div className={`${styles['pulltab-inventory-page-ribbon-title']} ${styles['']}`}>W-2G Queue
                </div>
                <div className={`${styles['pulltab-inventory-page-ribbon-buttons-container']}`}>
                    {showPrintWarningMessage() ? <div className={`${styles['page-ribbon-message-container']}`}>
                        <img
                            src={ExclamationIcon}
                            height={20}
                            width={20}
                            alt="exclamation icon"
                        />
                        <div className={`${styles['page-ribbon-message-text']}`}>This queue cannot be printed until
                            January of next year
                        </div>
                    </div> : null}
                    {checkRenderPermissions(permissions.CAN_PRINT_W2G_QUEUE, JSON.parse(userpolicies)) ?
                        <div tabIndex={0}
                             className={`${styles['pulltab-inventory-page-ribbon-create-license-button']}`}
                             role="button"
                             style={{
                                 opacity: (activatePrintButton) ? "1" : "0.4",
                                 cursor: (activatePrintButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32 && activatePrintButton) {
                                     setPrintPaymentsConfirmation(true);
                                 }
                             }}
                             onClick={() => {
                                 if (activatePrintButton) {
                                     setPrintPaymentsConfirmation(true);
                                 }
                             }}>
                            <div
                                className={`${styles['pulltab-inventory-page-ribbon-create-license-button-label']} ${styles['']}`}>Print
                            </div>
                        </div> : null}
                    {checkRenderPermissions(permissions.CAN_SEE_W2G_PRINT_HISTORY, JSON.parse(userpolicies)) ?
                        <div tabIndex={0}
                             className={`${styles['pulltab-inventory-page-ribbon-renew-license-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                     history.push(`${w2gQueueRoutePath}/print-history/${formik.values.sweepId}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${w2gQueueRoutePath}/print-history/${formik.values.sweepId}`);
                             }}>
                            <div
                                className={`${styles['pulltab-inventory-page-ribbon-create-license-button-label']}`}
                            >
                                Print History
                            </div>
                        </div> : null}

                </div>

            </div>

            <div
                className={`${styles['pulltab-inventory-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                <div className={`${styles['pulltab-inventory-page-search-bar-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-inventory-page-search-bar-title']} ${styles['']}`}>Search
                    </div>
                    <div
                        className={`${styles['pulltab-inventory-page-search-bar-filter-container']} ${styles['']}`}>

                        <div
                            className={`${styles['pulltab-inventory-page-search-bar-filter-title-bar']} ${styles['']}`}>
                            <div
                                className={`${styles['pulltab-inventory-page-search-bar-filter-title']} ${styles['']}`}>Filter
                            </div>
                            <div tabIndex={0}
                                 className={`${styles['pulltab-inventory-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}
                                 onKeyDown={(e: any) => {
                                     if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                         setShowFilters(!showFilters);
                                     }
                                 }}
                                 onClick={() => {
                                     setShowFilters(!showFilters);
                                 }}
                            >
                                <img
                                    src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                    className={styles[""]}
                                    alt="filter expander icon"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {showFilters && openCalendarSweeps ? <div
                        className={`${styles['pulltab-inventory-page-search-bar-filter-expanded-container']}`}>
                        <div className={`${styles['pulltab-inventory-page-checkbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Select View</div>
                            <div className={`${styles.custom_select_div}`}>
                                <select
                                    id="year"
                                    name="year"
                                    onChange={async (e: any) => {
                                        await setFieldValue(e, formik);
                                        formik.submitForm();
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="Select"
                                    className={styles["custom_select"]}>
                                    <option value="" selected>Select...</option>
                                    {openCalendarSweeps.map((sweep: any) => <option
                                        selected={formik.values.year.toLowerCase() === sweep.displayName.toLowerCase()}
                                        value={JSON.stringify(sweep)}>{sweep.displayName}
                                    </option>)}
                                </select>
                                <span className={`${styles.custom_arrow}`}></span>
                            </div>
                        </div>

                    </div> : null}

                    <div
                        className={`${styles['pulltab-inventory-page-search-bar-input-button-container']} ${styles['']}`}>

                        <div
                            className={`${styles['pulltab-inventory-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div className={`${styles['mslc-label']} ${styles['']}`}>TIN&nbsp;&nbsp;&nbsp;Must be
                                exact match
                            </div>
                            <InputMask className={`${styles['mslc-textbox']}`}
                                       type="text"
                                       id="SSN"
                                       name="SSN"
                                       value={formik.values.SSN}
                                       onBlur={formik.handleBlur}
                                       mask="999-99-9999"
                                       maskChar=" "
                                       placeholder={'000-00-0000'}
                                       disabled={!formik.values.year}
                                       onChange={(e: any) => {
                                           setFieldValue(e, formik);
                                       }}/>
                            {formik.errors.SSN ? <ErrorMessageFieldCommonComponent
                                errorMessages={formik.errors.SSN}/> : null}
                        </div>

                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         searchBySSN(W2GQueue, formik.values.SSN);
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     searchBySSN(W2GQueue, formik.values.SSN);
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateClearSearchButton) ? "1" : "0.4",
                                 cursor: (activateClearSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32 && activateClearSearchButton) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 if (activateClearSearchButton) {
                                     clearSearch(formik);
                                 }
                             }}>Clear Search
                        </div>
                    </div>
                </div>


            </div>
            {!W2GQueue ? <div className={`${styles['pulltab-inventory-page-search-no-results-container']}`}>
                <div className={`${styles['pulltab-inventory-page-search-no-results-container-text']}`}>
                    Select a view to generate results
                </div>
            </div> : <div className={styles['table']}>
                <div className={styles['result-totals-container']}>
                    <div className={styles['result-totals-container-title-container']}>
                        <div className={styles['result-totals-container-title-1']}>Aggregate Totals</div>
                    </div>
                    <div className={`${styles['check-details-controls-container']}`}>
                        <div
                            className={`${styles['check-details-controls-container-column-1']} ${styles['mslc-label']}`}>Federal
                        </div>
                        <div
                            className={`${styles['check-details-controls-container-column-2']} ${styles['mslc-label']}`}>{formatCentsToDollars(W2GQueue.irsTotalInCents)}</div>

                        <div
                            className={`${styles['check-details-controls-container-column-3']} ${styles['mslc-label']}`}>State
                        </div>
                        <div
                            className={`${styles['check-details-controls-container-column-4']} ${styles['mslc-label']}`}>{formatCentsToDollars(W2GQueue.dorTotalInCents)}
                        </div>
                        <div
                            className={`${styles['check-details-controls-container-column-5']} ${styles['mslc-label']}`}>Reportable
                        </div>
                        <div
                            className={`${styles['check-details-controls-container-column-6']} ${styles['mslc-label']}`}>{formatCentsToDollars(W2GQueue.reportableTotalInCents)}
                        </div>
                        <div
                            className={`${styles['check-details-controls-container-column-7']} ${styles['mslc-label']}`}>Grand
                            Total
                        </div>
                        <div
                            className={`${styles['check-details-controls-container-column-8']} ${styles['mslc-label']}`}>{formatCentsToDollars(W2GQueue.grandTotal)}
                        </div>
                    </div>
                </div>
                <hr className={styles['horizontal-line']}/>
                <div className={styles['table-data-header']}>
                    <div className={styles['table-data-header-text']}
                         aria-live="assertive">{`Displaying ${W2GQueue.paymentsInSweep.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(W2GQueue.paymentsInSweep.length, currentPage)} 
                        of ${W2GQueue.paymentsInSweep.length} results`}</div>
                </div>
                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>PMT/TMPT Key</div>
                    <div>Payee Name</div>
                    <div>TIN</div>
                    <div>Date</div>
                    <div>Reportable Payment</div>
                    <div>Federal Tax</div>
                    <div>State Tax</div>
                    <div>Offsets</div>
                    <div>Net Payment</div>
                </div>
                <div className={styles['table-data-body']}>
                    {W2GQueue.paymentsInSweep.length > 0 && getPageData(W2GQueue.paymentsInSweep, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles['table-data-data-text']}>
                                {e.paymentKey}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.payeeName}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.payeeSsn}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.reportablePaymentDate}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.reportableWinningsInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.irsTaxInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.dorTaxInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.offsetsWithheldInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.netPaymentInCents)}
                            </div>
                            <div className={styles['table-details-button-container']}>
                                {(checkRenderPermissions(permissions.CAN_SEE_GLOBAL_PAYMENT_DETAILS, JSON.parse(userpolicies)) && e.paymentId) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {
                                                 history.push(`${paymentsRoutePath}/details/${e.paymentId}`);
                                             }
                                         }}
                                         onClick={() => {
                                             history.push(`${paymentsRoutePath}/details/${e.paymentId}`);
                                         }}
                                         className={styles['table-details-button']}>
                                        Details
                                    </div> : null}
                            </div>
                        </div>)}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((W2GQueue.paymentsInSweep.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={W2GQueue.paymentsInSweep} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>}
        </div>
        {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
        {notificationBanner.show ? <NotificationBannerComponent
            type={NotificationType.Success}
            text={notificationBanner.text}
            handleHide={hideNotificationBanner}
        /> : null}
        {printPaymentsConfirmation ? <PrintPaymentsConfirmationComponent
            processW2GSweep={processW2GSweep}
            sweepId={formik.values.sweepId}
            setPrintPaymentsConfirmation={setPrintPaymentsConfirmation}>
        </PrintPaymentsConfirmationComponent> : null}
    </>);
};