import * as React from 'react'
import { getBlockClass } from '../../../../../utilities/helpers'
import { GenericPopupModal } from '../../../../GenericPopupModal/generic-popup-modal-ui'
import {
  PromotionDrawingWinner,
  ContactChecklistResponse,
  ContactChecklistRequestBody,
} from '../../../../../api/promotions-fetcher/constants'
import { WinnersManagementContactChecklistSection } from './winners-management-contact-checklist-section'
import './styles.scss'

const ROOT_CLASS = 'contact-checklist'
const INFO_CLASS = `${ROOT_CLASS}-winner-info`
const CONTACT_CLASS = `${ROOT_CLASS}-contact-info`
const LABEL_CLASS = getBlockClass(INFO_CLASS, 'label')
const DATA_CLASS = getBlockClass(INFO_CLASS, 'data')

export type WinnersManagementContactChecklistUIProps = {
  isOpen: boolean
  handleCloseModal: (shouldRefresh: boolean) => Promise<void>
  winner: PromotionDrawingWinner
  updatedChecklist?: ContactChecklistResponse
  updateChecklistError?: string
  updateChecklistLoading: boolean
  submitUpdateContactChecklist: (
    updatedList: ContactChecklistRequestBody
  ) => Promise<void>
}

export const WinnersManagementContactChecklistUI = (
  props: WinnersManagementContactChecklistUIProps
) => {
  const [refreshRequired, setRefreshRequired] = React.useState<boolean>(false)

  return (
    <GenericPopupModal
      isOpen={props.isOpen}
      closeModal={() => props.handleCloseModal(refreshRequired)}
      roundedEdges
      hideCloseButton
    >
      <div className={ROOT_CLASS}>
        <div className={INFO_CLASS}>
          <header>Contact Information</header>
          <div>
            <div className={LABEL_CLASS}>Email</div>
            <div className={DATA_CLASS}>{props.winner.email}</div>{' '}
          </div>
          <div>
            <div className={LABEL_CLASS}>Phone Number</div>
            <div className={DATA_CLASS}>{props.winner.phone}</div>{' '}
          </div>
          <div>
            <div className={LABEL_CLASS}>Address</div>
            <div className={DATA_CLASS}>
              <span>{props.winner.address}</span>
              <span>
                {props.winner.city}, {props.winner.state} {props.winner.zip}
              </span>
            </div>
          </div>
        </div>
        <WinnersManagementContactChecklistSection
          winner={props.winner}
          checklistDisabled={props.updateChecklistLoading}
          setRefreshRequired={setRefreshRequired}
          submitUpdateContactChecklist={props.submitUpdateContactChecklist}
          updatedChecklist={props.updatedChecklist}
        />
        {props.updateChecklistError && (
          <div className={getBlockClass(CONTACT_CLASS, 'error')}>
            {props.updateChecklistError}
          </div>
        )}
        <button
          className={getBlockClass(ROOT_CLASS, 'back-button')}
          onClick={() => props.handleCloseModal(refreshRequired)}
          aria-label="Go Back"
        >
          Go Back
        </button>
      </div>
    </GenericPopupModal>
  )
}
