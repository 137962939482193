import React, { SetStateAction } from "react";
import { TimePaymentAccountPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { SubAccountTreeNode } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { PaymentScheduleEntry } from "../../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../../types/Annuities/responses/time-payment-account";
import { SubAccountComponent } from "../SubAccountComponent";

export const SubAccountTree: React.FunctionComponent<{
  treeRoot: SubAccountTreeNode;
  isPrimary: boolean;
  widthOffset: number;
  tpaPayeeList: TimePaymentAccountPayee[];
  tpaDetails: TimePaymentAccountResponse;
  tpaPaymentSchedule: PaymentScheduleEntry[];
  isPageInEditModeObj: {
    isPageInEditMode: boolean;
    setIsPageInEditMode: React.Dispatch<SetStateAction<boolean>>;
  };
  parentIdentifier: string;
  index: number;
}> = ({
  treeRoot,
  isPrimary,
  widthOffset,
  tpaPayeeList,
  tpaDetails,
  tpaPaymentSchedule,
  isPageInEditModeObj,
  parentIdentifier,
  index: treeIndex,
}) => {
  widthOffset += 1.2;
  parentIdentifier = isPrimary
    ? ""
    : parentIdentifier.length
    ? `${parentIdentifier}.${treeIndex}`
    : toBase26(treeIndex);
  return (
    <SubAccountComponent
      treeRoot={treeRoot}
      isPrimary={isPrimary}
      widthOffset={widthOffset}
      tpaPayeeList={tpaPayeeList}
      tpaDetails={tpaDetails}
      tpaPaymentSchedule={tpaPaymentSchedule}
      isPageInEditModeObj={isPageInEditModeObj}
      parentIdentifier={parentIdentifier}
    >
      <>
        {treeRoot.subAccountChildren
          .sort((a, b) => {
            if (a.account.createdOn > b.account.createdOn) {
              return 1;
            }
            if (b.account.createdOn > a.account.createdOn) {
              return -1;
            }

            return 0;
          })
          .map((child, index) => (
            <SubAccountTree
              treeRoot={child}
              key={index}
              isPrimary={false}
              widthOffset={widthOffset}
              tpaPayeeList={tpaPayeeList}
              tpaDetails={tpaDetails}
              tpaPaymentSchedule={tpaPaymentSchedule}
              isPageInEditModeObj={isPageInEditModeObj}
              parentIdentifier={parentIdentifier}
              index={index + 1}
            />
          ))}
      </>
    </SubAccountComponent>
  );
};

const toBase26 = (number: number): string => {
  let string = "";
  let _number = number;

  while (_number > 0) {
    string = toChar(_number % 26 || 26) + string;
    _number = Math.floor((_number - 1) / 26);
  }

  return string;
};

const toChar = (number: number) => {
  return String.fromCharCode("A".charCodeAt(0) - 1 + number);
};
