import React, { SetStateAction, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import InputMask from 'react-input-mask'
import {
  isFuturePaymentGame,
  isFuturePaymentSource,
  isFuturePaymentStatus,
} from '../../../../api/future-payments-fetcher/api-type-guards'
import {
  FuturePaymentGame,
  FuturePaymentSource,
  FuturePaymentStatus,
  SearchFuturePaymentsRequest,
} from '../../../../api/future-payments-fetcher/api-types'
import {
  GAMES,
  SOURCES,
  STATUSES,
} from './future-payment-search-and-filter-consts'
import './styles.scss'

const ROOT_CLASS = 'check-run-queue-future-payment-search-and-filter'

type FuturePaymentSearchAndFilterUIProps = {
  searchHandler: (searchCriteria?: SearchFuturePaymentsRequest) => void
}

function handleTypedSelect<T>(
  typeGuard: (value: unknown) => value is T,
  setState: React.Dispatch<SetStateAction<T | ''>>
) {
  return (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (typeGuard(event.target.value) || event.target.value === '') {
      setState(event.target.value)
      return event.target.value
    }
  }
}

export const FuturePaymentSearchAndFilterUI = (
  props: FuturePaymentSearchAndFilterUIProps
) => {
  const [tin, setTin] = useState<string>('')
  const [annuityAccountId, setAnnuityAccountId] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [freeFormName, setFreeformName] = useState<string>('')
  const [source, setSource] = useState<FuturePaymentSource | ''>('')
  const [status, setStatus] = useState<FuturePaymentStatus | ''>('')
  const [game, setGame] = useState<FuturePaymentGame | ''>('')
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const search = () => {
    const searchParams = Object.entries({
      tin,
      annuityAccountId,
      firstName,
      lastName,
      freeFormName,
      status,
      source,
      game,
    })
      .filter(([_key, value]) => value !== '')
      .reduce((prev, [key, value]) => {
        return { ...prev, [key]: value }
      }, {} as SearchFuturePaymentsRequest)
    props.searchHandler(searchParams)
  }

  const clear = () => {
    setTin('')
    setAnnuityAccountId('')
    setFirstName('')
    setLastName('')
    setFreeformName('')
    setSource('')
    setStatus('')
    setGame('')
    props.searchHandler()
  }

  const isClearDisabled = () => {
    // Return true if some state value is anything other than an empty string.
    return (
      [
        tin,
        status,
        source,
        game,
        lastName,
        firstName,
        freeFormName,
        annuityAccountId,
      ].filter((value) => value !== '').length === 0
    )
  }

  const validate = () => {
    // Disable search button if TIN isn't valid
    if (!(tin.length === 0 || tin.length === 9)) {
      return true
    }
  }

  return (
    <div className={ROOT_CLASS}>
      <section className="filter-section">
        <section className="filter-header">
          <header>
            Filter
            <button
              className="chevron"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              {isFilterOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </header>
        </section>
        <section
          className={`filter-control-section ${isFilterOpen ? 'open' : ''}`}
        >
          <div className="filter-control">
            <label htmlFor="source">Source</label>
            <div className="select-container">
              <select
                onChange={(e) => {
                  const updatedSource = handleTypedSelect(
                    isFuturePaymentSource,
                    setSource
                  )(e)
                  if (updatedSource !== FuturePaymentSource.ANNUITIES) {
                    setAnnuityAccountId('')
                  }
                }}
                name="source"
                tabIndex={isFilterOpen ? 0 : -1}
                value={source}
              >
                <option value="">All</option>
                {SOURCES.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <span className="select-arrow"></span>
            </div>
          </div>
          <div className="filter-control">
            <label htmlFor="game">Game</label>
            <div className="select-container">
              <select
                onChange={handleTypedSelect(isFuturePaymentGame, setGame)}
                name="game"
                tabIndex={isFilterOpen ? 0 : -1}
                value={game}
              >
                <option value="">All</option>
                {GAMES.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <span className="select-arrow"></span>
            </div>
          </div>
          <div className="filter-control">
            <label htmlFor="status">Status</label>
            <div className="select-container">
              <select
                onChange={handleTypedSelect(isFuturePaymentStatus, setStatus)}
                name="status"
                tabIndex={isFilterOpen ? 0 : -1}
                value={status}
              >
                <option value="">All</option>
                {STATUSES.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <span className="select-arrow"></span>
            </div>
          </div>
        </section>
      </section>
      <section className="search-section">
        <section className="search-control-section">
          <div className="search-control">
            <label htmlFor="first-name">First Name</label>
            <input
              className="text-box"
              name="first-name"
              aria-label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
          </div>
          <div className="search-control">
            <label htmlFor="last-name">Last Name</label>
            <input
              className="text-box"
              name="last-name"
              aria-label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            ></input>
          </div>
          <div className="search-control">
            <label htmlFor="freeform-name">Freeform Name</label>
            <input
              className="text-box"
              name="freeform-name"
              aria-label="Freeform Name"
              value={freeFormName}
              onChange={(e) => setFreeformName(e.target.value)}
            ></input>
          </div>
          <div className="search-control">
            <label>TIN</label>
            <InputMask
              className="text-box"
              name="tin"
              aria-label="TIN"
              mask="999999999"
              maskChar=""
              placeholder="000000000"
              value={tin}
              onChange={(e) => setTin(e.target.value)}
            ></InputMask>
          </div>
          <div className="search-control">
            <label htmlFor="annuity-id">Annuity ID</label>
            <input
              disabled={source !== FuturePaymentSource.ANNUITIES}
              className="text-box"
              name="annuity-id"
              aria-label="Annuity ID"
              type="text"
              value={annuityAccountId}
              onChange={(e) => setAnnuityAccountId(e.target.value)}
            ></input>
          </div>
        </section>
        <section className="search-action-section">
          <button
            className="search-button"
            onClick={search}
            disabled={validate()}
          >
            Search
          </button>
          <button
            className="clear-button"
            disabled={isClearDisabled()}
            onClick={clear}
          >
            Clear
          </button>
        </section>
      </section>
    </div>
  )
}
