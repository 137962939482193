import * as React from 'react'
import { useHistory } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import {
  AllPromotionsResponse,
  PromotionCampaignStatusOrder,
  PromotionResponse,
  PromotionStatus,
} from '../../../api/promotions-fetcher/constants'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { GenericTableUI } from '../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { GenericSidebarFormMode } from '../../../components/GenericSidebar/generic-sidebar-ui'
import { PromotionCampaignEndCampaignContainer } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-modals/end-campaign/promotion-campaign-end-campaign-container'
import { PromotionCampaignScheduleDrawingContainer } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-modals/schedule-drawing/promotion-campaign-schedule-drawing-container'
import { PromotionCampaignBuilderSidebarContainer } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-sidebar/promotion-campaign-sidebar-container'
import { winnersManagementRoutePath } from '../../../config.js'
import { getBlockClass } from '../../../utilities/helpers'
import {
  mapPromotionCampaignDataToRow,
  promotionCampaignTableColumnMap,
  PromotionCampaignTableRow,
} from './helpers'
import './styles.scss'
import { CollapsableSection } from '../../../components/CommonComponents/collapsable-section-component/collapsable-section-ui'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'
import capitalize from 'lodash/capitalize'
import {
  CustomDropdownField,
  CustomDropdownOption,
} from '../../../components/CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { SORT_OPTIONS, SORT_OPTION_KEYS, SORT_FUNCTIONS, STATUS_FILTERS } from './constants'

const ROOT_CLASS = 'promotion-campaign-builder-page'
const HEADER_CLASS = `${ROOT_CLASS}-header`
const FILTER_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'filter-controls')
const STATUS_FILTER_CLASS = getBlockClass(ROOT_CLASS, 'status-filter-controls')
const STATUS_FILTER_BUTTON_CLASS = getBlockClass(
  STATUS_FILTER_CLASS,
  'filter-button'
)

export type PromotionCampaignBuilderPageUIProps = {
  data?: AllPromotionsResponse
  isLoading: boolean
  error?: string
  fetchAllPromotions: () => Promise<void>
  disableAdminActions: boolean
  disableScheduleDrawingAction: boolean
}

export const PromotionCampaignBuilderPageUI = (
  props: PromotionCampaignBuilderPageUIProps
) => {
  const [sidebarFormMode, setSidebarFormMode] =
    React.useState<GenericSidebarFormMode>(GenericSidebarFormMode.CLOSED)
  const [openPromotionCampaign, setOpenPromotionCampaign] =
    React.useState<PromotionResponse>()
  const [openEndPromotionId, setOpenEndPromotionId] = React.useState<string>()
  const [openScheduleDrawingPromotion, setOpenScheduleDrawingPromotion] =
    React.useState<PromotionResponse>()
  const [selectedStatuses, setSelectedStatuses] = React.useState<Array<string>>(
    []
  )
  const [mappedData, setMappedData] = React.useState<
    Array<PromotionCampaignTableRow>
  >([])
  const [filteredData, setFilteredData] = React.useState<
    Array<PromotionCampaignTableRow>
  >([])
  const [selectedSort, setSelectedSort] = React.useState<CustomDropdownOption>(
    SORT_OPTIONS[0]
  )
  const history = useHistory()

  const setFormMode = (
    formMode: GenericSidebarFormMode,
    promotion?: PromotionResponse
  ) => {
    setSidebarFormMode(formMode)
    setOpenPromotionCampaign(promotion)
  }
  React.useEffect(() => {
    const sortedData = sortBy(props.data ?? [], [
      (item: PromotionResponse) => PromotionCampaignStatusOrder[item.status],
      (item: PromotionResponse) => moment(item.endDate),
    ])
    setMappedData(
      sortedData.map((promotion) =>
        mapPromotionCampaignDataToRow(
          promotion,
          props.disableAdminActions,
          props.disableScheduleDrawingAction,
          () => setFormMode(GenericSidebarFormMode.EDIT, promotion),
          () => setOpenEndPromotionId(promotion.id),
          () => history.push(`${winnersManagementRoutePath}/${promotion.id}`),
          () => setOpenScheduleDrawingPromotion(promotion)
        )
      )
    )
  }, [props.data])

  React.useEffect(() => {
    setFilteredData(mappedData)
  }, [mappedData])

  const handleStatusFilter = (status: PromotionStatus) => {
    let statuses = [...selectedStatuses]
    if (statuses.includes(status)) {
      statuses = statuses.filter((item) => item != status)
    } else {
      statuses.push(status)
    }
    setSelectedStatuses(statuses)
  }

  const handleSortChange = (value: string) => {
    const selectedOption = SORT_OPTIONS.find((option) => option.value === value)
    if (!selectedOption) {
      throw new Error('Value not found in original options')
    }
    setSelectedSort(selectedOption)
  }

  const filterMappedData = () =>
    mappedData.filter(
      (row) =>
        selectedStatuses.length === 0 || selectedStatuses.includes(row.status)
    )

  React.useEffect(() => {
    let updatedData = filterMappedData()
    const selectedSortOption = Object.values(SORT_OPTION_KEYS).find(
      (value) => value == selectedSort.value
    )
    if (selectedSortOption) {
      const sortingFunction = SORT_FUNCTIONS[selectedSortOption]
      updatedData.sort(sortingFunction)
    }
    setFilteredData(updatedData)
  }, [selectedStatuses, selectedSort])

  return (
    <>
      {sidebarFormMode !== GenericSidebarFormMode.CLOSED && (
        <PromotionCampaignBuilderSidebarContainer
          formMode={sidebarFormMode}
          closeSidebar={() =>
            setFormMode(GenericSidebarFormMode.CLOSED, undefined)
          }
          promotionId={openPromotionCampaign?.id}
          refreshPromotions={props.fetchAllPromotions}
          promotionStatus={openPromotionCampaign?.status}
        />
      )}
      {openEndPromotionId && (
        <PromotionCampaignEndCampaignContainer
          closeModal={() => setOpenEndPromotionId(undefined)}
          promotionId={openEndPromotionId}
          fetchAllPromotions={props.fetchAllPromotions}
        />
      )}
      {openScheduleDrawingPromotion && (
        <PromotionCampaignScheduleDrawingContainer
          closeModal={() => setOpenScheduleDrawingPromotion(undefined)}
          promotion={openScheduleDrawingPromotion}
          fetchAllPromotions={props.fetchAllPromotions}
        />
      )}
      <section className={ROOT_CLASS}>
        <BackButtonUI message="Back to Promotions" removeLeftMargin />
        <header className={HEADER_CLASS}>
          <div className={getBlockClass(HEADER_CLASS, 'title')}>
            Promotion Campaign Builder
          </div>
          <button
            className={getBlockClass(HEADER_CLASS, 'create-button')}
            onClick={() => setSidebarFormMode(GenericSidebarFormMode.NEW)}
            aria-label="Create Promotion Campaign"
            disabled={props.disableAdminActions}
          >
            <div>Create</div>
          </button>
        </header>
        <section className={FILTER_CONTAINER_CLASS}>
          <CollapsableSection title="Filter">
            <label className={getBlockClass(FILTER_CONTAINER_CLASS, 'label')}>
              Sort By
            </label>
            <CustomDropdownField
              options={SORT_OPTIONS}
              name="sort-dropdown"
              id="sort-dropdown-promotion-builder"
              value={selectedSort.value as string}
              onChange={(event) => handleSortChange(event.target.value)}
              disabled={false}
            />
          </CollapsableSection>
          <section className={STATUS_FILTER_CLASS}>
            {STATUS_FILTERS.map((status) => (
              <button
                aria-label={`Filter button for promotions in the ${status} status. This button is ${
                  selectedStatuses.includes(status) ? 'enabled.' : 'disabled.'
                }`}
                onClick={() => handleStatusFilter(status)}
                className={STATUS_FILTER_BUTTON_CLASS}
                key={status}
              >
                {selectedStatuses.includes(status) ? (
                  <FaRegCheckSquare />
                ) : (
                  <FaRegSquare />
                )}
                {capitalize(status.toLowerCase())} Promotions
              </button>
            ))}
          </section>
        </section>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <AsyncContainerUI
            isLoading={props.isLoading}
            error={props.error}
            errorTextSize={15}
            errorTextColor="white"
            color="white"
          >
            <GenericTableUI
              columnMap={promotionCampaignTableColumnMap}
              rowData={filteredData}
              useActionMenu
              usePages
              rowsPerPage={6}
              customRowCellStyling={{ padding: '30px 16px' }}
              customHeaderCellStyling={{ paddingLeft: '16px' }}
            />
          </AsyncContainerUI>
        </div>
      </section>
    </>
  )
}
