import {InputValidationMessages} from "../../../../../Constants/InputValidationMessages.js";
import config, {pulltabRoutePath} from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";
import moment from "moment";
import {NotificationType} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {convertDollarsToCents} from "../../../../../util";
import {BarcodeScanType} from "../../../../../Constants/CharitableConstant.js";
import axios from "axios";

let licenseNumber: string;
let formik: any;
let history: any;
let backButtonText: string;
let charitableGamingStore: any;

const BARCODE_LENGTH = 18;
const MAX_ORDER_WEIGHT = 99;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any, backButtonTextObj: string, charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    backButtonText = backButtonTextObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = async (e: any) => {

    if (e.target.name === 'paymentMethod') {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        if (e.target.value.trim().toLowerCase() === 'free') {
            formik.setFieldValue('totalPricePaidInCents', 0, true);
            formik.setFieldValue('shippingCost', '0.00', true);
        } /*else {
            if (formik.values.shippingCost && formik.values.shippingCost === '0.00') {
                formik.setFieldValue('shippingCost', '', true);
            }
        }*/
    } else if (e.target.name === 'shippingMethod') {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        if (e.target.value.trim().toLowerCase() === 'pick-up') {
            formik.setFieldValue('netWeight', 'n/a', true);
            formik.setFieldValue('shippingCost', '0.00', true);
        } /*else if (e.target.value.trim().toLowerCase() === 'ups') {
            formik.setFieldValue('netWeight', 'TBD', true);
            formik.setFieldValue('shippingCost', 'TBD', true);
        }*/

    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }
}

export const setBarcodeValue = async (e: any, i: number) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {

        const index = e.target.name.split('.')[1];

        formik.setFieldValue(e.target.name, e.target.value.trim(), true);

        formik.setFieldValue(`barcodeInformation.${index}.stockNumber`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.serialNumbers`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.quantity`, '', true);
        formik.setFieldValue(`barcodeInformation.${index}.unitPrice`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.totalPrice`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.currentWarehouse`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.error`, '', true);

        if (e.target.value.trim().length === 18) {
            await ScanBarcode(e.target.value.trim(), index);
        }
    }

}

export const validate = (values: any) => {

    const errors: any = {};

    for (let i = 0; i < values.barcodeInformation.length; i++) {

        if (!values.barcodeInformation[i].barcodeNumber) {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.RequiredField;
        } else if (values.barcodeInformation[i].barcodeNumber && values.barcodeInformation[i].barcodeNumber.length < BARCODE_LENGTH) {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.InvalidBarcode;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeInvalidWarehouse') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeInvalidWarehouse;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeIneligibleOrder') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeIneligibleOrder;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeNotfound') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeNotfound;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeWeightExceeded') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeWeightExceeded;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeScanGenericError') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeScanGenericError;
        }

    }

    if (!values.warehouse) {
        Object.assign(errors, {warehouse: InputValidationMessages.RequiredField});
    }

    if (!values.paymentMethod) {
        Object.assign(errors, {paymentMethod: InputValidationMessages.RequiredField});
    }

    if (!values.shippingMethod) {
        Object.assign(errors, {shippingMethod: InputValidationMessages.RequiredField});
    } else if (values.shippingMethod && values.shippingMethod.toLowerCase() === 'pick-up') {

        if (!values.receiver) {
            Object.assign(errors, {receiver: InputValidationMessages.RequiredField});
        }

        if (!values.authorizationSignature) {
            Object.assign(errors, {authorizationSignature: InputValidationMessages.RequiredField});
        }

    } else if (values.shippingMethod && values.shippingMethod.toLowerCase() === 'ups') {

        if (!values.netWeight) {
            Object.assign(errors, {netWeight: InputValidationMessages.RequiredField});
        } else if (values.netWeight && values.netWeight > MAX_ORDER_WEIGHT) {
            Object.assign(errors, {netWeight: InputValidationMessages.InvalidOrderNetWeight});
        }

        if (!values.shippingCost) {
            Object.assign(errors, {shippingCost: InputValidationMessages.RequiredField});
        }
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    CreateOrder(values);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {

    history.push({
        pathname: `${pulltabRoutePath}/orders/create/step-1`, state: {backButtonText: backButtonText}
    });

}

export const YesResetAllBarcodes = function () {

    let barcodeInformationTemp = [];

    for (let i = 0; i < formik.values.barcodeInformation.length; i++) {
        barcodeInformationTemp.push({
            serialNumber: barcodeInformationTemp.length + 1,
            barcodeNumber: '',
            stockNumber: '-',
            serialNumbers: '-',
            quantity: 0,
            unitPrice: '',
            totalPrice: '',
            currentWarehouse: '-',
            error: ''
        });
    }

    formik.resetForm({
        values: {
            warehouse: '',
            barcodeInformation: barcodeInformationTemp,
            shippingMethod: formik.values.shippingMethod,
            paymentMethod: formik.values.paymentMethod,
            netWeight: formik.values.netWeight,
            shippingCost: formik.values.shippingCost,
            receiver: '',
            authorizationSignature: '',
            quantity: 0,
            summedProductPriceInCents: 0,
            totalPricePaidInCents: 0
        }
    });

    //formik.setFieldValue("barcodeInformation", barcodeInformationTemp, true);

    // updateTotalOrderSummary();

}

export const YesDeleteBarcode = function (deleteIndex: number) {

    let barcodeInformationTemp = JSON.parse(JSON.stringify(formik.values.barcodeInformation));
    barcodeInformationTemp.splice(deleteIndex, 1);

    formik.setFieldValue("barcodeInformation", barcodeInformationTemp, true);

    updateTotalOrderSummary();

}

export const ScanBarcode = async function (barcodeNumber: string, index: number) {

    const body = {
        barcode: barcodeNumber,
        scanType: BarcodeScanType.PURCHASE
    };

    try {

        const options: any = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/pulltab/barcode/scan`, data: body,
            headers: {
                'Content-Type': 'application/json',
            }
        }

        let response: any = await axios(options);
        response = response.data;

        if (!response.hasOwnProperty('purchaseCostDetails')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeIneligibleOrder', true);
            return;
        } else {
            // If currently selected Warehouse is same as the current warehouse of the scanned barcode then reset the Warehouse dropdown list
            if (formik.values.warehouse !== '') {
                const currentSelectedWarehouse = formik.values.warehouse.split('-')[0].trim();
                if (currentSelectedWarehouse !== response.purchaseCostDetails.currentWarehouse.toString()) {
                    formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeInvalidWarehouse', true);
                    return;
                }
            }
        }

        formik.setFieldValue(`barcodeInformation.${index}.stockNumber`, response.stockNumber, true);
        formik.setFieldValue(`barcodeInformation.${index}.serialNumbers`, `${response.serialNumberStart} - ${response.serialNumberEnd}`, true);
        formik.setFieldValue(`barcodeInformation.${index}.quantity`, response.numUnits, true);
        formik.setFieldValue(`barcodeInformation.${index}.unitPrice`, response.purchaseCostDetails?.unitPriceInCents, true);
        formik.setFieldValue(`barcodeInformation.${index}.totalPrice`, response.purchaseCostDetails?.totalCostInCents, true);
        formik.setFieldValue(`barcodeInformation.${index}.currentWarehouse`, response.purchaseCostDetails?.currentWarehouse, true);

        updateTotalOrderSummary();

    } catch (e: any) {
        if (e.response && e.response.data) {
            let errorResponse: any = e.response;
            if (errorResponse.status === 404) {
                if (errorResponse.data && errorResponse.data.message && errorResponse.data.message.includes('weight')) { // MBO-20
                    formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeWeightExceeded', true);
                } else {
                    formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeNotfound', true);
                }
            } else if (errorResponse.status === 409) {
                formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeIneligibleOrder', true);
            } else {
                formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeScanGenericError', true);
            }
        } else {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeScanGenericError', true);
        }
    }

}

export const CreateOrder = async function (values: any) {

    // alert(JSON.stringify(values));

    let netWeight = values.netWeight;
    let shippingMethod = '';
    if (values.shippingMethod.toLowerCase() === 'pick-up') {
        shippingMethod = 'PICKUP';
        netWeight = '0';
    } else if (values.shippingMethod.toLowerCase() === 'ups') {
        shippingMethod = 'UPS';
    }

    const body: any = {
        warehouseId: values.warehouse.split('-')[0].trim(),
        licenseNumber: licenseNumber,
        date: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        shippingPriceInCents: 0,
        summedProductPriceInCents: 0,
        totalPricePaidInCents: 0,
        shipMethod: shippingMethod,
        paymentMethod: values.paymentMethod.toUpperCase(),
        totalWeightInLbs: netWeight,
        receiver: values.receiver,
        authSignature: values.authorizationSignature,
        productDetails: []
    };

    let summedProductPriceInCents = 0;

    for (let newOrder of values.barcodeInformation) {

        const serialNumberStart = newOrder.serialNumbers.split('-')[0].trim();
        const serialNumberEnd = newOrder.serialNumbers.split('-')[1].trim();

        summedProductPriceInCents = summedProductPriceInCents + newOrder.totalPrice;

        body.productDetails.push({
            stockNumber: newOrder.stockNumber,
            serialNumberStart: serialNumberStart,
            serialNumberEnd: serialNumberEnd,
            unitPriceInCents: newOrder.unitPrice,
            numUnits: newOrder.quantity,
            totalProductPriceInCents: newOrder.totalPrice
        });
    }

    body.summedProductPriceInCents = Number(summedProductPriceInCents);
    body.shippingPriceInCents = await convertDollarsToCents(values.shippingCost);
    body.totalPricePaidInCents = Number(summedProductPriceInCents) + body.shippingPriceInCents;

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/pulltab/purchase-order`, data: body
        }

        let response = await makeHttpCall(options);

        history.goBack();

        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: `Order ${response.purchaseOrderId} was successfully created.`
        });

    } catch (e) {
        console.log(e);
        charitableGamingStore.showErrorNotificationBanner('Something went wrong. Please try again');
    }

}

export const getLicenseeDetailsByLicenseNumber = async function (licenseNumber: string, setSelectedLicensee: any, setRequestCompleted: any) {

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/licensee/retrieve/${licenseNumber}`,
        }

        const response = await makeHttpCall(options);

        setSelectedLicensee(response.licensee);

        setRequestCompleted(true);

    } catch (e) {
        console.log(e);
        setRequestCompleted(true);
    }
}

export const updateTotalOrderSummary = async function () {

    let summedProductPriceInCents = 0;
    let totalPricePaidInCents = 0;
    let quantity = 0;

    for (let newOrder of formik.values.barcodeInformation) {
        quantity = quantity + Number(newOrder.quantity);
    }

    if (formik.values.paymentMethod.toLowerCase() !== 'free') {

        for (let newOrder of formik.values.barcodeInformation) {
            summedProductPriceInCents = summedProductPriceInCents + Number(newOrder.totalPrice);
            totalPricePaidInCents = totalPricePaidInCents + Number(newOrder.totalPrice);
        }

    }

    formik.setFieldValue(`summedProductPriceInCents`, summedProductPriceInCents, true);
    formik.setFieldValue(`totalPricePaidInCents`, totalPricePaidInCents, true);
    formik.setFieldValue(`quantity`, quantity, true);
}
