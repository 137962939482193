import React from "react"
import { GetPaymentDetailsResponse } from "../../../../api/payment-details-fetcher/api-types"
import './styles.scss'
import moment from "moment-timezone"
import { formatCentsToDollars } from "../../../../util"

export type PaymentTaxBasisUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
}

const ROOT_CLASS = 'payment-tax-basis'

export const PaymentDetailsTaxBasisUI = (
  props: PaymentTaxBasisUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className="tax-basis-container">
        <div className="row-container-title">Tax Basis</div>
        <div className={`date`}>
          Reportable Date: {
            moment(props.paymentDetailsData.payment.reportablePaymentDate).format('MM/DD/YYYY')
          }
        </div>
        <div className={`column-1`}>
          Taxable Merchandise
        </div>
        <div className={`column-2`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.taxableMerchandiseInCents
          )}
        </div>
        <div className={`column-1`}>
          Non-Taxable Merchandise
        </div>
        <div className={`column-2`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.nonTaxableMerchandiseInCents
          )}
        </div>
        <div className={`column-1`}>
          Non-Taxable (0.00&mdash;600.99)
        </div>
        <div className={`column-2`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.grossNonTaxableInCents
          )}
        </div>
        <div className={`column-1`}>
          State Taxable (601.00&mdash;5000.99)
        </div>
        <div className={`column-2`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.grossDorTaxableOnlyInCents
          )}
        </div>
        <div className={`column-1`}>
          Federal &amp; State Taxable (5001.00 and above)
        </div>
        <div className={`column-2`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.grossDorAndIrsTaxableInCents
          )}
        </div>
      </div>
    </div>
  )
}