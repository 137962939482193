import { makeHttpCall } from './../../../services/MakeHttpCall';
import config from './../../../config.js';
import { AnyCnameRecord } from 'dns';



function swap(items:any, leftIndex:any, rightIndex:any){
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
}

function partition(items:any, left:any, right:any) {
    
    var pivot   = items[Math.floor((right + left) / 2)], //middle element
        i       = left, //left pointer
        j       = right; //right pointer
        
    while (i <= j) {
       
        while (items[i].createdAt > pivot.createdAt) {
            i++;
        }
        
        while (items[j].createdAt < pivot.createdAt) {
             
            j--;
        }
        if (i <= j) {
            swap(items, i, j); //swap two elements
            i++;
            j--;
        }
    }
    return i;
}

function quickSort(items:any, left:any, right:any) {
   
    var index;
    if (items.length > 1) {
        index = partition(items, left, right); //index returned from partition
        if (left < index - 1) { //more elements on the left side of the pivot
            quickSort(items, left, index - 1);
        }
        if (index < right) { //more elements on the right side of the pivot
            quickSort(items, index, right);
        }
    }
    return items;
}




export const getKYCPlayerDocumentQueue = async (setKYCPlayerData:any,setIsData:any,setPageMessage:any,pageMessage:any,setNetworkError:any) =>{
    try {

        if(pageMessage.messageType === "ERROR")
            setPageMessage({message:"", messageType:""}); 
        const options =
        {
            method: 'get',
            url: config.SERVER_BASE_URL + '/v1/document/review-document-type-queue'
        }

        const response:any = await makeHttpCall(options);
        response.message.data = quickSort(response.message.data, 0, response.message.data.length - 1);
        setIsData(true);
        setKYCPlayerData(response.message.data);
        setNetworkError(false);
        
        if(pageMessage.messageType === "ERROR")
           setPageMessage({message:"", messageType:""} ); 
    } catch (e) {
        setIsData(true);  
        const er:any = e;
        if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
            if(value === "Network")
                return value;
        }) !== undefined)
        {
            setNetworkError(true);
            setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        }else
        {
            setNetworkError(false);
            setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
        }       
    }

}

