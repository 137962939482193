import * as React from 'react'
import { Caret } from '../../../icons/caret'
import { SmallCaret } from '../../../icons/small-caret'
import { getModifierClass } from '../../../utilities/helpers'
import './styles.scss'

export enum CaretColor {
  primaryTeal = 'primary-teal',
}

export enum CaretDirection {
  down = 0,
  right = 90,
  up = 180,
  left = 270,
}

export type Props = {
  height?: number
  width?: number
  direction?: CaretDirection
  color?: CaretColor
  smallCaret?: boolean
}

const ROOT_CLASS = 'icon-caret'

export const IconCaret = (props: Props) => {
  const colorClassName = getModifierClass(
    ROOT_CLASS,
    props.color ?? CaretColor.primaryTeal,
    true
  )
  return (
    <div
      className={`${ROOT_CLASS} ${colorClassName}`}
      style={{
        transform: `rotate(-${props.direction ?? CaretDirection.down}deg)`,
      }}
    >
      {props.smallCaret ? (
        <SmallCaret height={props.height} width={props.width} />
      ) : (
        <Caret height={props.height} width={props.width} />
      )}
    </div>
  )
}
