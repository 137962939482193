import React from "react"
import { SelectInputUI, SelectOption, TextFieldType, TextInputUI } from "../../CommonComponents/FormikInputComponent/input-ui"
import { Countries } from "../../../Constants/Countries"
import { PayeeType } from "../../../api/payment-details-fetcher/api-types"
import { FuturePaymentDetailsUpdateFormFields } from "../../../pages/PaymentsPages/FuturePaymentDetailsUpdatePage/future-payment-details-update-page-container"

export type FuturePaymentDetailsUpdateFormUIProps = {
  payment: FuturePaymentDetailsUpdateFormFields
}

const countryOptions: SelectOption[] = Countries.map((country: string) => {
  return {
    label: country,
    value: country
  }
})

export const FuturePaymentDetailsUpdateFormUI = (props: FuturePaymentDetailsUpdateFormUIProps) => {
  const { payment } = props

  const ROOT_CLASS = 'future-payment-details-update-form'

  return (
    <div className={`${ROOT_CLASS}-container`}>
      <div className={ROOT_CLASS}>
        {/* Name Fields */}
        {payment.payeeType === PayeeType.ENTITY ?
          <TextInputUI
            type={ TextFieldType.TEXT }
            label={ 'Freeform Name *' }
            name={ 'payeeFreeformName' }
          /> : <>
            <TextInputUI
              type={ TextFieldType.TEXT }
              label={ 'First Name *' }
              name={ 'payeeFirstName' }
            />
            <TextInputUI
              type={ TextFieldType.TEXT } 
              label={ 'Middle Initial' }
              name={ 'payeeMiddleInitial' }
            />
            <TextInputUI
              type={ TextFieldType.TEXT } 
              label={ 'Last Name *' }
              name={ 'payeeLastName' }
            />
          </>
        }

        {/* Remaining Payee Details */}
        <TextInputUI
          type={ TextFieldType.TEXT } 
          label={ 'TIN *' } 
          name={ 'tin' }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Mailing Address 1 *' } 
          name={ 'address1' }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Mailing Address 2' } 
          name={ 'address2' }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'City *' } 
          name={ 'city' }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'State/Region *' }
          name={ 'state' }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Zip/Postal Code *' }
          name={ 'zip' }
        />
        <SelectInputUI
          label={ 'Country' }
          name={ 'country' }
          options={ countryOptions }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Supplemental Address - Line 1' }
          name={ 'supplementalAddress1' }
        />
        <TextInputUI 
          type={ TextFieldType.TEXT } 
          label={ 'Supplemental Address - Line 2' }
          name={ 'supplementalAddress2' }
        />
        <TextInputUI 
          type={ TextFieldType.TEL } 
          label={ 'Phone Number' }
          name={ 'phone' }
        />
        <TextInputUI 
          type={ TextFieldType.EMAIL } 
          label={ 'Email' } 
          name={ 'email' }
        />
      </div>
    </div>
        
  )
}
