import { CircularProgress } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { PaymentDetailsComponent } from '../../../../components/AnnuitiesComponents/Common/PaymentDetailsCmpt'
import { annuitiesRoutePath } from '../../../../config.js'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { PaymentDetails } from '../../../../types/Annuities/PaymentDetails'
import { TimePaymentAccountResponse } from '../../../../types/Annuities/responses/time-payment-account'
import { PaymentStatusOptions } from '../../../../types/Annuities/type-options/payment-status'
import styles from './css/styles.module.css'
import { getPaymentDetails, getTpaDetails } from './functions'

export const PaymentDetailsPage = () => {
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const tpaId = searchParams.get('id') ?? ''
  const paymentId: string = searchParams.get('paymentId') ?? ''
  const paymentNumber: number =
    searchParams.get('num') === null ? 0 : +searchParams.get('num')!
  const bannerContext = useContext(BannerContext)
  const idemToken = uuidv4()

  interface adjustedPaymentDetails extends PaymentDetails {
    paymentNumber: number
    accountTotalPayments: number
    paymentFrequency: string
    taxDesignation: number
    paymentStatus: string
    paymentDate: string | Date
  }
  const initTimePaymentAccount: TimePaymentAccountResponse = {
    id: 'Loading...',
    validFrom: new Date(),
    validTo: new Date(),
    createdOn: new Date(),
    gameName: 'Powerball',
    gameType: 'Draw',
    drawDate: new Date(),
    claimDate: new Date(),
    remainingPayments: 30,
    paymentsMade: 1,
    lifetimePayment: true,
    documentId: 'ABC0123456789',
    investAcctNum: 'string',
    payFrequency: 'Yearly',
    tpaStatus: 'Active',
    grossAmount: 100000,
    irsAmount: 10000,
    dorsAmount: 5000,
    offsetsAmount: 0,
    recurringPaymentAmount: 5000000,
    firstPaymentSrcId: 'Loading...',
    notes: 'Notes :)',
    firstPaymentDate: new Date(),
    prevPaymentDate: new Date(),
    nextPaymentDate: new Date(),
    finalPaymentDate: new Date(),
    winAmount: 0,
    graduationRate: 0,
  }
  const initialPaymentDetails: adjustedPaymentDetails = {
    paymentNumber: 1,
    accountTotalPayments: 1,
    paymentFrequency: '',
    taxDesignation: 1,
    paymentStatus: '',
    paymentDate: new Date(),
    payment: {
      id: '',
      validFrom: '',
      createdOn: '',
      notes: '',
      subAccountId: '',
      paymentRuleId: '',
      paymentDate: new Date(),
      irsWithholding: 100000,
      dorsWithholding: 100000,
      paymentStatus: PaymentStatusOptions.Paid,
      offsetsWithholding: 100000,
      paymentAmount: 100000,
      taxLiability: 100000,
    },
    payee: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      dateOfBirth: '',
      dateOfDeath: '',
      tin: '',
      tinType: '',
      firstName: '',
      lastName: '',
      middleInitial: '',
      payeeType: 'Individual',
      phone: '',
      email: '',
      state: '',
      zip: '',
      id: '',
      validFrom: '',
      validTo: '',
      createdOn: '',
      notes: '',
      irsName: '',
    },
    paymentRule: {
      subAccountId: '',
      startDate: new Date(),
      endDate: new Date(),
      amount: 1000000,
      id: '',
      validFrom: new Date(),
      validTo: '',
      createdOn: new Date(),
      notes: '',
    },
    subAccount: {
      timePaymentAccountPayeeId: '',
      irsTaxEnabled: true,
      dorTaxEnabled: true,
      acctStatus: '',
      parentTaxResponsibility: true,
      parentAccountId: '',
      supportingInformation1: '',
      supportingInformation2: '',
      id: '',
      validFrom: new Date(),
      validTo: '',
      createdOn: new Date(),
      notes: '',
    },
    timePaymentAccount: initTimePaymentAccount,
  }

  const [paymentDetailsData, setPaymentDetailsData] =
    useState<adjustedPaymentDetails>(initialPaymentDetails)
  const [tpaDetails, setTpaDetails] = useState<TimePaymentAccountResponse>(
    initTimePaymentAccount
  )
  const [requestCompleted, setRequestCompleted] = useState<boolean>(false)

  useEffect(() => {
    const fetchPaymentList = async (tpaId: string) => {
      if (tpaId === '') {
        setRequestCompleted(true)
        bannerContext.setBannerInfo({
          message: 'Payment ID is required to populate Payment Details',
          error: true,
        })
      } else {
        try {
          const tempTpaDetails = await getTpaDetails(tpaId)
          const paymentDetailsCollection: adjustedPaymentDetails[] =
            await getPaymentDetails(tpaId)
          const foundPaymentDetails = paymentDetailsCollection
            .filter(
              (x: adjustedPaymentDetails) =>
                x.payment.paymentRuleId === paymentId
            )
            .find(
              (x: adjustedPaymentDetails) => x.paymentNumber === paymentNumber
            )
          setTpaDetails(tempTpaDetails)
          if (foundPaymentDetails === undefined) {
            throw new Error('Payment Not Found')
          } else {
            setPaymentDetailsData(foundPaymentDetails)
            setRequestCompleted(true)
          }
        } catch (error) {
          setRequestCompleted(true)
          bannerContext.setBannerInfo({ message: `${error}`, error: true })
        }
      }
    }

    fetchPaymentList(tpaId)
  }, [])

  return (
    <>
      {!requestCompleted && (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}
      <div className={styles['page-container']}>
        {/* Back Btn tsx */}
        <div className={styles['back-btn-container']}>
          <button
            type="button"
            onKeyPress={(event) => {
              if (
                keyPressedType(event) === KEY_CODES.ENTER ||
                keyPressedType(event) === KEY_CODES.SPACE
              ) {
                history.push(
                  `${annuitiesRoutePath}/annuity-full-payment-schedule/${tpaId}`
                )
              }
            }}
            onClick={() => {
              history.push(
                `${annuitiesRoutePath}/annuity-full-payment-schedule/${tpaId}`
              )
            }}
            className={styles['back-btn-inner-container']}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles['back-btn-custom']}
            />
            <div className={styles['back-btn-text']}>
              Back to Payment Schedule
            </div>
          </button>
        </div>
        {/* Header */}
        <div className={styles['cmpnt-container']}>
          <div className={`${styles.title_header}`}>
            <div
              className={`${styles.title_text}`}
            >{`Payment ${paymentDetailsData.paymentNumber} of ${paymentDetailsData.accountTotalPayments} `}</div>
            <div className={styles['flex-left']}>
              <div className={styles['document-id-label']}>Document ID</div>
              <div
                className={styles['document-id-value']}
              >{`${tpaDetails.documentId}`}</div>
            </div>
          </div>
          {/* Main Component */}
          <div className={styles['cmpnt-container']}>
            <PaymentDetailsComponent
              data={{
                payment: paymentDetailsData.payment,
                paymentRule: paymentDetailsData.paymentRule,
                payee: paymentDetailsData.payee,
                subAccount: paymentDetailsData.subAccount,
                timePaymentAccount: tpaDetails,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
