import { ReportStatus } from '../finserv-general-reports/api-types'

export type Agent1099ReportMetadataResponse = {
  reports: Agent1099ReportListEntry[]
}

export type Agent1099ReportListEntry = {
  id: string
  dateGenerated: Date
  generatedBy: string
  paymentYear: string
  status: ReportStatus
  reportType: Agent1099ReportType
}

export type Agent1099Year = {
  year: string
  printed: boolean
}

export type Agent1099YearsResponse = {
  years: Agent1099Year[]
}

export enum Agent1099ReportType {
  PRELIMINARY_AGENT_SUMMARY_REPORT = 'PRELIMINARY_AGENT_SUMMARY_REPORT',
  PRELIMINARY_AGENT_EXCEPTION_REPORT = 'PRELIMINARY_AGENT_EXCEPTION_REPORT',
  YEAR_END_REPORTS = 'YEAR_END_REPORTS',
  GROUPED_AGENT_SUMMARY_REPORT = 'GROUPED_AGENT_SUMMARY_REPORT',
}
