import * as React from 'react'
import { CreateCampaignDetails } from '../../../../api/promotions-fetcher/coupon-campaigns/constants'
import {
  useFetchArtworkRequirements,
  useFetchCreateCampaign,
  useFetchUploadCampaignArtwork,
} from '../../../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import { CampaignBuilderContext } from '../../../../pages/CampaignsPages/campaign-builder-context'
import { NewCampaignSideBarUI } from './new-campaign-side-bar-ui'

export type NewCampaignSidebarContainerProps = {
  closeSidebar: () => void
  showEditCampaign: (campaignIdentifier: string) => void
}

export const NewCampaignSidebarContainer = (
  props: NewCampaignSidebarContainerProps
) => {
  const campaignBuilderContext = React.useContext(CampaignBuilderContext)
  const {
    error: createCampaignError,
    isLoading: createCampaignLoading,
    submitCreateCampaignPayload,
    submittedCampaignIdentifier,
  } = useFetchCreateCampaign()

  const {
    error: submitArtworkError,
    isLoading: submitArtworkLoading,
    requestSuccessful: submitArtworkSuccessful,
    submitCampaignArtwork,
  } = useFetchUploadCampaignArtwork()

  const { fetchArtworkRequirements } = useFetchArtworkRequirements()

  const submitCampaign = async (
    createCampaignDetails: CreateCampaignDetails,
    imageFile?: File
  ) => {
    await submitCreateCampaignPayload(createCampaignDetails)
    await submitCampaignArtwork(
      createCampaignDetails.campaignIdentifier,
      imageFile
    )
  }

  React.useEffect(() => {
    if (
      submittedCampaignIdentifier &&
      !createCampaignLoading &&
      !submitArtworkLoading &&
      submitArtworkSuccessful
    ) {
      props.showEditCampaign(submittedCampaignIdentifier)
      campaignBuilderContext.fetchAllCampaigns()
    }
  }, [
    submittedCampaignIdentifier,
    createCampaignLoading,
    submitArtworkLoading,
    submitArtworkSuccessful,
  ])

  return (
    <NewCampaignSideBarUI
      error={createCampaignError ?? submitArtworkError}
      defaultLegalCopy={campaignBuilderContext.defaultLegalCopy}
      closeSidebar={props.closeSidebar}
      submitCampaign={submitCampaign}
      legalCopyLoading={campaignBuilderContext.isDefaultLegalCopyLoading}
      submissionLoading={createCampaignLoading || submitArtworkLoading}
      fetchArtworkRequirements={fetchArtworkRequirements}
    />
  )
}
