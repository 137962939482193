export const getPageData = (pullTabGames: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(pullTabGames[i] === undefined)) {
            pullTabGames[i].index = index;
            index++;
            array.push(pullTabGames[i]);
        }
    }
    return array;
}
