import * as React from 'react'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../../../utilities/helpers'
import {
  CollapsableSection,
  useForceOpen,
} from '../../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import { ErrorMessageFieldCommonComponent } from '../../../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { GenericSidebarFormMode } from '../../../../../GenericSidebar/generic-sidebar-ui'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  promotionIsNewOrPending,
} from '../helpers'
import { PromotionCampaignValidationPrizeTier } from '../validation'
import { PromotionPrizeTiersViewModeUI } from './prize-tier-view-mode-ui'
import { PromotionPrizeTierFormUI } from './single-prize-tier-form-ui'
import '../styles.scss'

type PromotionPrizeTiersFormUIProps = PromotionCampaignBuilderSubFormProps & {
  isBonus?: boolean
  prizeTiersField: PromotionCampaignValidationPrizeTier[]
}

const BUTTON_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_CLASS,
  'add-prize-tier-button'
)

export const PromotionPrizeTiersFormUI = (
  props: PromotionPrizeTiersFormUIProps
) => {
  const [editMode, setEditMode] = React.useState<boolean>(
    props.formMode === GenericSidebarFormMode.NEW
  )
  const { isForcedOpen, forceOpen } = useForceOpen()

  const openEditMode = () => {
    forceOpen()
    setEditMode(true)
  }

  const { removeElement, addElement } =
    useFormArray<PromotionCampaignValidationPrizeTier>({
      defaultFieldValue: {},
      fieldArray: props.prizeTiersField,
      setFieldArray: async (
        prizeTiers: PromotionCampaignValidationPrizeTier[]
      ) => {
        await props.formik.setFieldValue(
          props.isBonus ? 'bonusPrizeTiers' : 'prizeTiers',
          prizeTiers
        )
      },
    })

  const errorFieldName = props.isBonus ? 'hasBonusPrizeTiers' : 'hasPrizeTiers'

  const removePrizeTier = async (index: number) => {
    await removeElement(index)
    props.formik.setFieldTouched(errorFieldName)
  }

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'promotion-prize-tiers-container'
      )}
    >
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'promotion-prize-tiers'
        )}
      >
        <CollapsableSection
          title={`${props.isBonus ? 'Bonus ' : ''}Prize Tiers`}
          isForcedOpen={isForcedOpen}
          backgroundColor="light_blue"
          textSize="small"
          connectedBottom
          smallHeader
          defaultOpen
        >
          {editMode ? (
            <>
              {props.prizeTiersField.length > 0 && (
                <div
                  className={getBlockClass(
                    PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                    'promotion-prize-tiers-content'
                  )}
                >
                  {props.prizeTiersField
                    .sort((a, b) => Number(a.tierName) - Number(b.tierName))
                    .map((prizeTier, index) => (
                      <PromotionPrizeTierFormUI
                        key={`${
                          props.isBonus ? 'bonus-' : ''
                        }prize-tier-${index}`}
                        prizeTier={prizeTier}
                        index={index}
                        isLoading={props.isLoading}
                        formik={props.formik}
                        removePrizeTier={() => removePrizeTier(index)}
                        isBonus={props.isBonus}
                        prizeTiersField={props.prizeTiersField}
                      />
                    ))}
                </div>
              )}
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_CLASS,
                  'add-prize-tier-button-container'
                )}
              >
                <button
                  type="button"
                  className={joinClasses([
                    BUTTON_CLASS,
                    getModifierClass(
                      BUTTON_CLASS,
                      'bonus',
                      Boolean(props.isBonus)
                    ),
                  ])}
                  aria-label="Add prize tier"
                  disabled={props.isLoading}
                  onClick={addElement}
                >
                  Add {props.isBonus ? 'Bonus' : 'New'} Prize
                </button>
              </div>
            </>
          ) : (
            <PromotionPrizeTiersViewModeUI
              openEditMode={openEditMode}
              promotionStatus={props.promotionStatus}
              canEditPromotionCampaigns={props.canEditPromotionCampaigns}
              prizeTiers={props.prizeTiersField}
              isBonus={props.isBonus}
            />
          )}
        </CollapsableSection>
        <div
          className={getBlockClass(PROMOTION_SIDEBAR_FORM_ROOT_CLASS, 'footer')}
        >
          {!editMode &&
            promotionIsNewOrPending(props.promotionStatus) &&
            props.canEditPromotionCampaigns && (
              <button onClick={openEditMode}>Edit</button>
            )}
        </div>
      </div>
      {props.formik.errors[errorFieldName] &&
        props.formik.touched[errorFieldName] && (
          <ErrorMessageFieldCommonComponent
            errorMessage={props.formik.errors[errorFieldName]}
          />
        )}
    </div>
  )
}
