import * as React from 'react'
import { OpenQuestionCircleIcon } from '../../../../icons/open-question-circle'
import { getBlockClass } from '../../../../utilities/helpers'
import { GenericToolTipWrapper } from '../../../GenericToolTip/generic-tool-tip-wrapper'
import './styles.scss'

type PromotionCampaignTooltipProps = {
  tooltipText: string
  altText: string
  widthPx?: number
}

const ROOT_CLASS = 'promotion-campaign-tool-tip'

export const PromotionCampaignTooltip = (
  props: PromotionCampaignTooltipProps
) => {
  const circleIcon = (
    <div className={getBlockClass(ROOT_CLASS, 'icon')}>
      <OpenQuestionCircleIcon altText={props.altText} />
    </div>
  )
  return (
    <GenericToolTipWrapper
      position="top"
      noBorder
      shadow
      showArrow
      triggerElement={circleIcon}
      hiddenElement={
        <div
          className={getBlockClass(ROOT_CLASS, 'text')}
          style={{ width: `${props.widthPx ?? 234}px` }}
        >
          {props.tooltipText}
        </div>
      }
    />
  )
}
