import React, { useState } from 'react'
import { MdOutlinePayments } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { AllPlayslipsStepDownPermissions } from '../../../../api/playslips-fetcher/permissions'
import { PromotionTabPermissions } from '../../../../api/promotions-fetcher/permissions'
import { clearPageMessageDefault } from '../../../../components/CommonComponents/PageMessageCommonComponent'
import {
  actionsRoutePath,
  annuitiesRoutePath,
  charitableRoutePath,
  operationsBasePath,
  paymentsRoutePath,
  playerSupportRoutePath,
  promotionsBasePath,
  reportingRoutePath,
  servicesRoutePath,
  ticketSearchRoutePath,
} from '../../../../config.js'
import { permissions } from '../../../../constants.js'
import { checkRenderPermissions } from '../../../../helpers.js'
import actions from '../../../../icons/actions-icon.svg'
import operations from '../../../../icons/chasing-arrows-icon.svg'
import cogs from '../../../../icons/cogs.svg'
import CGInactive from '../../../../icons/dark-inactive-CG.svg'
import annuities from '../../../../icons/Icon_annuities_navy.svg'
import promotions from '../../../../icons/megaphone.svg'
import CGActive from '../../../../icons/navy-active-CG.svg'
import people from '../../../../icons/people-icon.svg'
import reporting from '../../../../icons/reporting-icon.svg'
import tickets from '../../../../icons/ticket-icon.svg'
import { keyPressedType } from '../../../../services/Commons'
import { MenuItemComponent } from '../MenuItemComponent/MenuItemComponent'
import styles from './css/styles.module.css'

const defaultButtonStates: any = {
  'hamburger-menu': 'false',
  players: false,
  tickets: false,
  payments: false,
  reporting: false,
  actions: false,
  annuities: false,
  charitablegames: false,
  promotions: false,
}
const MenuHomeComponent = ({ props, storesObject }: any) => {
  const history = useHistory()
  const userpolicy: string | any = localStorage.getItem('userpolicies')

  const [buttonStates, setButtonStates] = useState<any>(defaultButtonStates)
  const updateButtonStates = (e: any) => {
    Object.keys(defaultButtonStates).forEach((key: any) => {
      if (key === e) {
        buttonStates[key] = true
      } else {
        buttonStates[key] = false
        //alert(`key: ${key}, buttonStates[key]: ${buttonStates[key]}`)
      }
    })

    //alert(JSON.stringify(defaultButtonStates))

    //setButtonStates({...buttonStates});
  }
  return (
    <>
      <div role="nav" className={styles['menu-home-component-container']}>
        {checkRenderPermissions(
          permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="players"
            tabIndex={0}
            aria-pressed={buttonStates['players']}
            title={'Player Support'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                localStorage.setItem('activeTab', 'playerDetails')
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${playerSupportRoutePath}/search-new`)
                updateButtonStates('players')
              }
            }}
            onClick={(e: any) => {
              localStorage.setItem('activeTab', 'playerDetails')
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${playerSupportRoutePath}/search-new`)
              updateButtonStates('players')
            }}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={people}
              className={styles['menu-home-icon']}
              alt="people icon"
            />
            <div className={styles['menu-home-icon-text']}>Players</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_TICKETS_NEW,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="tickets"
            tabIndex={0}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${ticketSearchRoutePath}/usingbarcodeandserial`)
                updateButtonStates('tickets')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${ticketSearchRoutePath}/usingbarcodeandserial`)
              updateButtonStates('tickets')
            }}
            aria-pressed={buttonStates['tickets']}
            title={'Tickets'}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={tickets}
              className={styles['menu-home-icon']}
              alt="tickets icon"
            />
            <div className={styles['menu-home-icon-text']}>Tickets</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_GLOBAL_PAYMENTS_MODULE,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="payments"
            tabIndex={0}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${paymentsRoutePath}/`)
                updateButtonStates('payments')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${paymentsRoutePath}/`)
              updateButtonStates('payments')
            }}
            aria-pressed={buttonStates['payments']}
            title={'Payments'}
            className={styles['menu-home-icons-contanier']}
          >
            <MdOutlinePayments title="left arrow icon" />
            <div className={styles['menu-home-icon-text']}>Payments</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_ACCESS_FINANCE_PAYMENTS_MODULE,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="reporting"
            tabIndex={0}
            aria-pressed={buttonStates['reporting']}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${reportingRoutePath}/finance`)
                updateButtonStates('reporting')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${reportingRoutePath}/finance`)
              updateButtonStates('reporting')
            }}
            title={'Reporting'}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={reporting}
              className={styles['menu-home-icon']}
              alt="reporting icon"
            />
            <div className={styles['menu-home-icon-text']}>Reporting</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_ACTIONS,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="actions"
            tabIndex={0}
            aria-pressed={buttonStates['actions']}
            title={'Actions'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${actionsRoutePath}/home`)
                updateButtonStates('actions')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${actionsRoutePath}/home`)
              updateButtonStates('actions')
            }}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={actions}
              className={styles['menu-home-icon']}
              alt={'actions icon'}
            />
            <div className={styles['menu-home-icon-text']}>Actions</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_SYSTEM_SUPPORT,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="actions"
            tabIndex={0}
            aria-pressed={buttonStates['actions']}
            title={'System Support'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${servicesRoutePath}`)
                updateButtonStates('actions')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${servicesRoutePath}`)
              updateButtonStates('actions')
            }}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={cogs}
              className={styles['menu-home-icon']}
              alt="cogs icon"
            />
            <div className={styles['menu-home-icon-text']}>Services</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_ANNUITIES,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="annuities"
            tabIndex={0}
            aria-pressed={buttonStates['annuities']}
            title={'Annuities'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${annuitiesRoutePath}/`)
                updateButtonStates('annuities')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${annuitiesRoutePath}/`)
              updateButtonStates('annuities')
            }}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={annuities}
              className={styles['menu-home-icon']}
              alt="annuities icon"
            />
            <div className={styles['menu-home-icon-text']}>Annuities</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_CHARITABLE_GAMING,
          JSON.parse(userpolicy)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id="charitable"
            tabIndex={0}
            aria-pressed={buttonStates['annuities']}
            title={'Charitable Gaming'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                clearPageMessageDefault()
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${charitableRoutePath}/`)
                updateButtonStates('charitablegames')
              }
            }}
            onClick={(e: any) => {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${charitableRoutePath}/`)
              updateButtonStates('charitablegames')
            }}
            className={styles['menu-home-icons-contanier']}
          >
            <img
              src={
                defaultButtonStates['charitablegames'] ? CGActive : CGInactive
              }
              className={styles['menu-home-icon']}
              alt="CG active icon"
            />
            <div className={styles['menu-home-icon-text']}>CG</div>
          </div>
        ) : null}
        <MenuItemComponent
          anyOfPermissionsRequired={PromotionTabPermissions}
          userPolicy={userpolicy}
          id="promotions"
          title="Promotions"
          routePath={promotionsBasePath}
          buttonState="promotions"
          altText="promotions icon"
          imgSource={promotions}
          buttonStates={buttonStates}
          updateButtonStates={updateButtonStates}
          storesObject={storesObject}
          history={history}
        />
        <MenuItemComponent
          anyOfPermissionsRequired={AllPlayslipsStepDownPermissions}
          userPolicy={userpolicy}
          id="operations"
          title="Operations"
          routePath={operationsBasePath}
          buttonState="operations"
          altText="operations icon"
          imgSource={operations}
          buttonStates={buttonStates}
          updateButtonStates={updateButtonStates}
          storesObject={storesObject}
          history={history}
        />
      </div>
    </>
  )
}

export default MenuHomeComponent
