import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../../CommonComponents/ErrorMessageFieldCommonComponent'
import './styles.scss'

export type SideBarTextAreaProps = {
  label: string | JSX.Element
  name: string
  onChange: (e: string | any) => void
  onBlur: (e: any) => void
  value: string
  error: string | undefined
  touched: boolean | undefined
  disabled: boolean
  greyOnDisabled?: boolean
  maxLength?: number
}

const ROOT_CLASS = 'side-bar-text-area'
const TEXT_CLASS = getBlockClass(ROOT_CLASS, 'text')

export const SideBarTextArea = (props: SideBarTextAreaProps) => {
  return (
    <div className={ROOT_CLASS}>
      <label
        className={getBlockClass(ROOT_CLASS, 'label')}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <textarea
        id={props.name}
        className={joinClasses([
          TEXT_CLASS,
          getModifierClass(
            TEXT_CLASS,
            'grey',
            props.disabled && Boolean(props.greyOnDisabled)
          ),
        ])}
        name={props.name}
        rows={6}
        required
        value={props.value}
        onChange={(e) => props.onChange(e)}
        onBlur={(e) => props.onBlur(e)}
        disabled={props.disabled}
        maxLength={props.maxLength}
      />
      {props.error && props.touched && (
        <ErrorMessageFieldCommonComponent errorMessage={props.error} />
      )}
    </div>
  )
}
