export const READY_FOR_PAYMENT: any = {

    
    CREATED: "Validated - Payment Initialized",
    SUBMITTED: "Validated - Payment Pending",
    SETTLED: "Validated - Payment Complete",
    REJECTED: "Validated - Payment Rejected",

}

export const ClaimStatus: any = {


    ERROR_HOST_REJECTED:"Validation Failed - Ineligible Ticket",
    ATTEMPTING_TO_VALIDATE: "In Progress - Claim Attempting to validate", 
    ERROR_ALREADY_VALIDATED: "Validation Failed - Already Paid",
    ERROR_DOWNTIME: "Validation Failed - Downtime",
    ERROR_FREQUENT_CASHER: "Blocked - Frequent Casher",
    ERROR_INVALID_RESPONSE: "Validation Failed - Ineligible Ticket",
    ERROR_OFFSET: "Blocked - Player Offsets",
    ERROR_PLAYER_SUSPENDED: "Blocked - Player Suspended",
    ERROR_RTC_TICKET_HOLD:"Blocked- Ticket on Hold",
    ERROR_UNABLE_TO_VALIDATE: "Validation Failed - API Error",
    INITIATED:"In progress - Claim Started",
    PLAYER_ERROR: "Blocked - Player ID",
    READY_FOR_PAYMENT:READY_FOR_PAYMENT,
    RECEIVED: "In progress - Claim Received",
    VALIDATED:"Validated - Transmitting Info",       

}


function swap(items:any, leftIndex:any, rightIndex:any){
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
}

function partition(items:any, left:any, right:any) {
    
    var pivot   = items[Math.floor((right + left) / 2)], //middle element
        i       = left, //left pointer
        j       = right; //right pointer
        
    while (i <= j) {
       
        while (items[i].activeFrom > pivot.activeFrom) {
            i++;
        }
        
        while (items[j].activeFrom < pivot.activeFrom) {
             
            j--;
        }
        if (i <= j) {
            swap(items, i, j); //swap two elements
            i++;
            j--;
        }
    }
    return i;
}
function partitionTransaction(items:any, left:any, right:any) {
    
    var pivot   = items[Math.floor((right + left) / 2)], //middle element
        i       = left, //left pointer
        j       = right; //right pointer
         
        
    while (i <= j) {
       
        
        while (items[i].details.createdAt > pivot.details.createdAt) {
            i++;            
        }

        while (items[j].details.createdAt < pivot.details.createdAt ) {
            j--;
        }

        if (i <= j) {
            swap(items, i, j); //swap two elements
            i++;
            j--;
        }
    }
    return i;
}
export function quickSortTransaction(items:any, left:any, right:any) {
   
    var index;
  
    if (items.length > 1) {
        index = partitionTransaction(items, left, right); //index returned from partition
        if (left < index - 1) { //more elements on the left side of the pivot
            quickSortTransaction(items, left, index - 1);
        }
        if (index < right) { //more elements on the right side of the pivot
            quickSortTransaction(items, index, right);
        }
    }
    return items;
}
export function quickSort(items:any, left:any, right:any) {
   
    var index;
    if (items.length > 1) {
        index = partition(items, left, right); //index returned from partition
        if (left < index - 1) { //more elements on the left side of the pivot
            quickSort(items, left, index - 1);
        }
        if (index < right) { //more elements on the right side of the pivot
            quickSort(items, index, right);
        }
    }
    return items;
}
 
export const getTransactionStatusType = (value: string) => {
    
    const type: any = Object.keys(READY_FOR_PAYMENT).find((key: any) => key === value);
    return type === undefined ? "" : READY_FOR_PAYMENT[value];

}


export const getHistoryStatusType = (value: string) => {

    const type: any = Object.keys(ClaimStatus).find((key: any) => key === value)   
    return type === undefined ? "" : ClaimStatus[type];
}

export let numberOfRejectedRequest:number = 0; 
export const buildClaimsArrayByDate = (claimsHistory:any, claimsTransactions:any) =>{
    const claimsArray:any[] =[];
    let claimsHistoryIndex:number = 0;
    numberOfRejectedRequest = 0;
    
    for(let i = 0 ; i < claimsTransactions.length; i++ )
    {
         if(claimsHistory[claimsHistoryIndex] === undefined)
         {
            claimsArray.push({type:"T",data:claimsTransactions[i]});
            if( claimsArray[ claimsArray.length - 1 ].data.status === "REJECTED" )
            {
                ++numberOfRejectedRequest;
            }
         }
        else if( claimsHistory[claimsHistoryIndex].activeFrom > claimsTransactions[i].details.createdAt )
        {
            claimsArray.push({type:"H",data:claimsHistory[claimsHistoryIndex]});
            claimsHistoryIndex++;
        }else{
            claimsArray.push({type:"T",data:claimsTransactions[i]});
            if( claimsArray[ claimsArray.length - 1 ].data.status === "REJECTED" )
            {
                ++numberOfRejectedRequest;
            }
        }
    }
    for(let y = claimsHistoryIndex ; y < claimsHistory.length; y++ )
    {
         claimsArray.push({type:"H",data:claimsHistory[y]});
    }
    return claimsArray
}


export const getPageData = (KYCPlayerData: any[], page: number = 0) => {
    const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(KYCPlayerData[i] === undefined))
            array.push(KYCPlayerData[i]);
    }
    return array;
}

export const normilizeDate = (date: string) => {
    let dateArray = date.split('Z');
    dateArray = dateArray[0].split('T');
    date = dateArray[0].split("-").join('/');
    let time = dateArray[dateArray.length - 1].split('.');

    return `${date} at ${time[0]}`;


}
const getMonth = (val:any) => {
    switch (val) {
        case '01': return 'Jan'
        case '02': return 'Feb'
        case '03': return 'Mar'
        case '04': return 'Apr'
        case '05': return 'May'
        case '06': return 'Jun'
        case '07': return 'Jul'
        case '08': return 'Aug'
        case '09': return "Sep"
        case '10': return 'Oct'
        case '11': return 'Nov'
        case '12': return 'Dec'
        default: return "undefined"
    }
}

export const setDateFormat = (value:any) => {
    
    let date:any = value.split('T')[0];
    date = date.split('-');
    let year:any = date[0];
    let time:any = value.split('T')[1];
    time = time.split(".");
    time = time[0].split(":");
    let hr:any =parseInt(time[0]); 
    let period = hr >= 12 ? "pm" : "am";
    if(hr > 12)
    {
        hr = hr - 12;
    }

    return `${date[1]}/${date[2]}/${year}&${hr}:${time[1]}:${time[2]} ${period}`;

}