import React from "react";
import styles from './css/styles.module.css';

export const UpdatePermitInformationComponent = ({formik, setFieldValue}: any) => {

    const OrgStatusMapping: any = {
        true: 'Active',
        false: 'Expired'
    }

    const OrgStatus = [
        'Active',
        'Expired'
    ];

    return (
        <>
            <form className={styles['update-permit-details-component-container']}>
                <div tabIndex={0} className={styles['update-permit-details-component-title']}>Permit</div>
                <div className={styles["update-permit-details-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-permit-details-component-label"]}>Org. Status*
                    </div>
                    {/*<input
                        id="orgStatus"
                        name="orgStatus"
                        className={styles["update-permit-details-component-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.orgStatus}
                        placeholder={'Ex. 00'}
                        maxLength={2}
                    />*/}
                    <div className={`${styles.custom_select_div}`}>
                        <select className={styles["custom_select"]}
                                id="orgActive"
                                name="orgActive"
                                onChange={(e: any) => {
                                    setFieldValue(e);
                                }}
                                onBlur={formik.handleBlur}
                                placeholder="Select"
                        >
                            <option value="" selected disabled>Select...</option>
                            {
                                OrgStatus.map((expenseType: string) =>
                                    <option
                                        selected={OrgStatusMapping[formik.values.orgActive] === expenseType.toLowerCase()}
                                        value={expenseType}>{expenseType}
                                    </option>)
                            }
                        </select>
                        <span className={`${styles.custom_arrow}`}></span>
                    </div>
                    {/*{(formik.touched.orgStatus && formik.errors.orgStatus) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.orgStatus}/> : null}*/}
                </div>

            </form>
        </>

    );
}