import * as React from 'react'
import { FuturePaymentDetailsPageUI } from './future-payment-details-page-ui'
import {
  useFetchFutureReissuePaymentDetails
} from './use-fetch-future-reissue-payment-details'
import { FutureReissuePaymentDetailsPageUI } from './future-reissue-payment-details-page-ui'

export type FuturePaymentDetailsPageContainerProps = {
  futureReissuePaymentId: string
}

export const FutureReissuePaymentDetailsPageContainer = (
  props: FuturePaymentDetailsPageContainerProps
) => {
  const {
    isLoading: isFetchDetailsLoading,
    error: fetchDetailsError,
    data
  } = useFetchFutureReissuePaymentDetails(props.futureReissuePaymentId)
  
  return (
    <FutureReissuePaymentDetailsPageUI
      isLoading={isFetchDetailsLoading}
      error={fetchDetailsError}
      futureReissuePaymentDetailsData={data}
    />
  )
}
