import React from 'react'
import { formatCentsToDollars } from '../../../../util'
import './styles.scss'

const ROOT_CLASS = 'reissue-queue-aggregate-totals'

export type ReissueQueueAggregateTotalsUIProps = {
  annuitiesTotalInCents: number
  promotionsTotalInCents: number
  seasonTicketsTotalInCents: number
  otherTotalInCents: number
  totalNetPaymentInCents: number
}

export const ReissueQueueAggregateTotalsUI = (
  props: ReissueQueueAggregateTotalsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className='d-flex'>
        <h2 className={`outer-container-title`}>Aggregate Totals</h2>
      </div>
      <div className={`d-flex justify-content-between`}>
        <div className={'d-flex'}>
          <div className={`label-value-container`}>
            <div className={`label`}>AN</div>
            <div className={`value`}>
              {formatCentsToDollars(props.annuitiesTotalInCents)}
            </div>
          </div>
          <div className={`label-value-container`}>
            <div className={`label`}>Promotions</div>
            <div className={`value`}>
              {formatCentsToDollars(props.promotionsTotalInCents)}
            </div>
          </div>
          <div className={`label-value-container`}>
            <div className={`label`}>ST</div>
            <div className={`value`}>
              {formatCentsToDollars(props.seasonTicketsTotalInCents)}
            </div>
          </div>
          <div className={`label-value-container`}>
            <div className={`label`}>Other</div>
            <div className={`value`}>
              {formatCentsToDollars(props.otherTotalInCents)}
            </div>
          </div>
        </div>
        <div className={'d-flex'}>
          <div className={`label-value-container right-align`}>
            <div className={`label`}>Net Payment</div>
            <div className={`value`}>
              {formatCentsToDollars(props.totalNetPaymentInCents)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}