import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { clearPageMessageDefault } from "../../CommonComponents/PageMessageCommonComponent";
import { lineItemsInterface, mobileClaimDetailsPageInterface } from "../../../pages/ClaimsPages/MobileClaimDetailsPage/interfaces";
import { normilizeAmount } from "./functions";

export const WinningInformationComponent = ( { mobileClaimDetailsPage }:mobileClaimDetailsPageInterface|any) => {
    const prize:any = mobileClaimDetailsPage.claimReferenceNumber.claim.lineItems.find((e:lineItemsInterface) => e.type === "PRIZE"  );
    const federalTax:any = mobileClaimDetailsPage.claimReferenceNumber.claim.lineItems.find((e:lineItemsInterface) => e.type === "TAX_FEDERAL" );
    const stateTax:any = mobileClaimDetailsPage.claimReferenceNumber.claim.lineItems.find((e:lineItemsInterface) => e.type === "TAX_STATE" );
    const comptrollerOffset: any = mobileClaimDetailsPage.claimReferenceNumber.claim.lineItems.find((e: lineItemsInterface) => e.type === "COMPTROLLER");
    const childSupportOffset: any = mobileClaimDetailsPage.claimReferenceNumber.claim.lineItems.find((e: lineItemsInterface) => e.type === "CHILD_SUPPORT");
    const DORTaxOffset: any = mobileClaimDetailsPage.claimReferenceNumber.claim.lineItems.find((e: lineItemsInterface) => e.type === "OFFSET_TAXES");

    const comptrollerOffsetAmount: any = (comptrollerOffset === undefined) ? 0 : comptrollerOffset.amountInCents;
    const childSupportOffsetAmount: any = (childSupportOffset === undefined) ? 0 : childSupportOffset.amountInCents;
    const DORTaxOffsetAmount: any = (DORTaxOffset === undefined) ? 0 : DORTaxOffset.amountInCents;

    const totalOffsetsAmount: any = comptrollerOffsetAmount + childSupportOffsetAmount + DORTaxOffsetAmount;

    return (
        <>
            <div onFocus={()=> clearPageMessageDefault() } className={styles['page-container']}>
                <div className={styles['winning-content-title-container']}>
                    <div tabIndex={0} className={styles['winning-content-title']} >Winning Information</div>
                </div>
                <div className={styles['winning-content-container']}>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name"]}`}>Gross Payment </div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name-bold"]}`}>{`${  prize === undefined ? "": normilizeAmount(prize.amountInCents) }`}</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name"]}`}>State Tax Withholding</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name-bold"]}`}>{`${ stateTax === undefined ? "" : normilizeAmount(stateTax.amountInCents)}`}</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name"]}`}>Federal Tax Withholding</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name-bold"]}`}>{`${ federalTax === undefined ? "" : normilizeAmount(federalTax.amountInCents)} `}</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name"]}`}>Offsets Withheld</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name-bold"]}`}>{`${normilizeAmount(totalOffsetsAmount)}`}</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name"]}`}>Net Payment</div>
                    <div tabIndex={0} className={`${styles["winning-content-labels-name-bold"]}`}>{`${normilizeAmount(mobileClaimDetailsPage.claimReferenceNumber.claim.netPaymentAmountInCents)}`}</div>                    
                </div>
            </div>
            
        </>

    );
}