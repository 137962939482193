import moment from 'moment'
/**
 * Formats phone# into (999) 999-9999 format
 * @param phoneNumber
 */
import axios from 'axios'
import { ExportToCsv } from 'export-to-csv'
import { ReportStatus } from '../api/finserv-general-reports/api-types'
import config from '../config'
import { makeHttpCall } from '../services/MakeHttpCall'

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    const formattedPhoneNumber = `(${phoneNumber.substring(
      0,
      3
    )}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`
    return formattedPhoneNumber
  }
  return phoneNumber
}

export const formatSSN = (SSN: string) => {
  if (SSN) {
    const formattedSSN = `${SSN.substring(0, 3)}-${SSN.substring(
      3,
      5
    )}-${SSN.substring(5, 9)}`
    return formattedSSN
  }
  return SSN
}

export const formatCentsToDollars = (
  amountInCents: number,
  truncate: boolean = false
) => {
  let amountInDollars: number =
    amountInCents && typeof amountInCents === 'number' ? amountInCents / 100 : 0

  return formatDollarAmount(amountInDollars, true, truncate)
}

export const formatCentsToDollarsNoSign = (amountInCents: number) => {
  let amountInDollars: number =
    amountInCents && typeof amountInCents === 'number' ? amountInCents / 100 : 0
  return amountInDollars
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    .slice(1) // remove the '$'
}

export const formatDollarAmount = (
  amountInDollars: number,
  includeDollarSign: boolean = true,
  truncateCents: boolean = false
) => {
  let result = amountInDollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  if (!includeDollarSign) {
    result = result.replace('$', '')
  }
  if (truncateCents) {
    result = result.substring(0, result.length - 3)
  }
  return result
}

export const convertDollarsToCents = (amountInDollar: string) => {
  const amountInCents: number = amountInDollar
    ? Math.round(parseFloat(amountInDollar) * 100)
    : 0
  return amountInCents
}

export const parseFloatWithCommas = (s: string) => {
  return parseFloat(s.replace(/,/g, ''))
}

export const logAction = async (
  actionCategory: string,
  action: string,
  target = '',
  comments = ''
) => {
  const logline = {
    category: actionCategory,
    action: action,
    target: target,
    comments: comments,
  }

  try {
    const options = {
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/v1/log/action`,
      data: logline,
    }

    await makeHttpCall(options)
  } catch (e) {
    console.log(e)
  }
}

export const downloadMonthlyUserCSV = async function () {
  try {
    const options: any = {
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/v1/user/getactiveusers`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json text/csv',
      },
    }
    const response = await axios(options)
    const fileOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: 'RTC_BackOffice_Users_' + moment().format('YYYYMMDD'),
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'ID',
        'UserName',
        'FirstName',
        'MiddleName',
        'LastName',
        'RoleName',
        'IsActive',
        'CreateDate',
        'LastLogin',
        'LastLogout',
        'IsLocked',
        'FailedLoginAttempts',
      ],
    }
    const csvExporter = new ExportToCsv(fileOptions)
    await csvExporter.generateCsv(response.data)
  } catch (e) {
    console.log(e)
  }
}

export const emptyStringToUndefined = (value: string) => {
  if (value === '') {
    return undefined
  }
  return value
}

export const validateTIN = (value: string) => {
  const TINCheck = /^\d{9}$/
  return TINCheck.test(value)
}

export const getPageData = (
  pageData: any[],
  page: number = 0,
  itemsPerPage: number = 10
) => {
  //debugger;
  const array: any = []
  for (
    let i = page * itemsPerPage;
    i < page * itemsPerPage + itemsPerPage;
    i++
  ) {
    if (!(pageData[i] === undefined)) {
      array.push(pageData[i])
    }
  }
  return array
}

export const validateStringLength = (value: string) => {
  if (!value) {
    return true
  }
  // Should return true if the string is all white space.
  return !Boolean(value.trim().length)
}

export const validateFloatString = (value: string) =>
  isNaN(Number.parseFloat(value))

export const validateNonZeroCurrency = (value: string) => {
  const parsedValue = parseInt(value.replace(/\D/g, ''), 10)
  return isNaN(parsedValue) || parsedValue === 0
}

export const snakeCaseToTitleCase = (value: string) =>
  value
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())

export const mapFinservReportStatusToString = (
  status: ReportStatus
): string => {
  switch (status) {
    case ReportStatus.SUCCESS:
      return 'Success'
    case ReportStatus.PENDING:
      return 'Pending'
    case ReportStatus.EXPIRED:
      return 'Expired'
    case ReportStatus.FAILED:
    default:
      return 'Failed'
  }
}

export const saveFromUrl = (url: string) => {
  const fileLink = document.createElement('a')
  fileLink.href = url
  fileLink.click()
  fileLink.remove()
}

export const minutesToMilliseconds = (minutes: number) => minutes * 60000
