import React from "react";
import styles from './css/styles.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const PlayerSearchInfoMessageComponent = (errorMessages: any) => {


    return (
        <>
            <div tabIndex={0} className={styles['error-message-container']}>
                <div className={styles['error-message-carret']}>
                    <FontAwesomeIcon icon={faCaretUp as IconProp}/>
                </div>
                <div role="alert" tabIndex={0} aria-live={"assertive"}
                     className={styles['error-message-text-container']}
                >
                    {
                        Object.keys(errorMessages).map((e: any) =>
                            <>
                                {errorMessages[e]}
                            </>)
                    }
                </div>

            </div>

        </>

    );
}