import React from "react";
import './iconStyle.css';

const MassLogoIcon = (props) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="210px" height="70px" viewBox="0 0 686.000000 223.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,223.000000) scale(0.100000,-0.100000)"
fill="#173E67" stroke="none">
<path d="M5519 2211 c-74 -14 -146 -60 -266 -173 l-52 -48 -2548 -1 c-1947 0
-2556 -3 -2583 -12 -24 -8 -41 -23 -52 -47 -17 -32 -18 -84 -18 -648 0 -672
-1 -666 59 -696 27 -14 269 -16 2408 -16 1308 0 2383 -4 2390 -8 6 -4 40 -45
75 -92 123 -164 217 -285 252 -325 l35 -40 94 0 c111 0 108 -2 200 119 84 110
95 120 113 105 8 -6 38 -46 68 -88 29 -42 70 -92 91 -110 l38 -33 158 4 c180
3 191 8 244 93 17 26 44 63 60 83 28 30 38 35 97 41 79 8 94 20 150 114 53 88
83 112 149 121 63 8 116 31 129 56 17 32 42 242 37 319 -5 88 -25 213 -40 255
-9 25 -94 145 -193 274 -15 19 -40 42 -58 53 -88 54 -226 -15 -226 -112 0 -67
15 -93 170 -287 72 -91 69 -115 -46 -288 -95 -145 -220 -238 -356 -266 -102
-21 -208 14 -264 88 -38 49 -44 91 -27 186 28 157 9 208 -152 427 -116 158
-122 178 -86 250 22 43 64 82 149 139 132 90 158 151 150 347 -7 141 -21 176
-79 199 -51 20 -200 29 -270 17z m229 -124 c26 -27 27 -31 26 -130 -1 -56 -6
-114 -12 -129 -6 -15 -40 -49 -74 -75 -99 -75 -212 -189 -236 -237 -43 -85
-22 -150 98 -306 157 -205 173 -243 147 -359 -23 -102 -21 -143 9 -203 31 -61
115 -147 170 -173 55 -26 165 -31 241 -11 103 26 228 94 305 165 80 74 190
233 219 317 31 88 17 125 -98 262 -29 34 -69 87 -89 117 -32 48 -36 59 -26 80
15 32 55 42 87 21 14 -9 46 -49 72 -89 25 -40 65 -98 90 -130 46 -62 54 -88
73 -243 12 -99 2 -215 -24 -278 -15 -35 -18 -37 -116 -52 -53 -8 -94 -47 -152
-143 -40 -65 -64 -81 -124 -81 -19 0 -47 -6 -62 -14 -31 -16 -117 -102 -157
-155 -14 -19 -31 -38 -37 -43 -20 -12 -194 -9 -223 4 -27 12 -108 114 -164
205 -28 46 -37 53 -64 53 -43 0 -46 -3 -114 -95 -139 -187 -187 -216 -256
-156 -34 30 -152 179 -265 334 -29 39 -63 85 -75 100 l-22 28 -2363 -1 c-1787
0 -2368 3 -2385 12 -47 25 -46 12 -47 600 l0 556 31 26 31 26 2540 0 2540 0
66 63 c87 81 165 145 194 157 13 5 67 9 121 7 91 -3 99 -5 125 -30z"/>
<path d="M210 1620 l0 -90 70 0 70 0 2 -312 3 -313 103 -3 102 -3 0 316 0 315
50 0 50 0 2 -312 3 -313 102 -3 103 -3 2 163 3 163 85 0 85 0 3 -163 2 -163
98 3 97 3 3 403 2 402 -100 0 -100 0 0 -145 0 -145 -90 0 -90 0 0 145 0 145
-330 0 -330 0 0 -90z"/>
<path d="M1880 1305 l0 -405 200 0 200 0 0 66 0 66 49 -36 c88 -65 134 -80
241 -80 131 0 192 24 281 113 89 89 113 150 114 281 0 83 -4 103 -28 154 -15
32 -27 60 -27 62 0 2 27 4 60 4 l60 0 0 -315 0 -315 100 0 100 0 0 315 0 315
85 0 85 0 0 -315 0 -316 98 3 97 3 3 313 2 312 65 0 65 0 0 90 0 90 -420 0
-420 0 0 -75 c0 -41 -2 -75 -4 -75 -2 0 -21 16 -42 36 -22 20 -68 51 -104 68
-59 28 -73 31 -165 31 -92 0 -106 -3 -167 -32 -136 -64 -218 -187 -226 -338
-4 -76 5 -122 43 -207 6 -16 0 -18 -69 -18 l-76 0 0 305 0 305 -100 0 -100 0
0 -405z m799 167 c64 -39 94 -98 89 -178 -7 -109 -73 -175 -182 -182 -80 -5
-139 25 -180 91 -59 95 -26 225 69 275 72 37 134 35 204 -6z"/>
<path d="M3760 1310 l0 -400 220 0 221 0 -3 93 -3 92 -117 3 -118 3 0 64 0 65
120 0 120 0 0 95 0 95 -120 0 -120 0 0 55 0 55 120 0 120 0 0 90 0 90 -220 0
-220 0 0 -400z"/>
<path d="M4232 1308 l3 -403 103 -3 102 -3 0 116 c0 63 3 115 8 115 4 -1 41
-51 82 -113 l75 -112 123 -3 c67 -1 122 0 122 3 0 3 -40 62 -90 131 -49 69
-90 129 -90 132 1 4 24 21 53 37 66 38 117 128 117 206 0 28 4 48 9 45 4 -3
30 -54 57 -113 l49 -108 5 -165 5 -165 108 -3 107 -3 0 161 0 161 106 232 c58
128 108 238 111 245 4 9 -19 12 -93 12 l-99 0 -65 -125 c-36 -69 -67 -125 -70
-125 -3 0 -32 56 -64 125 l-59 125 -104 0 c-115 0 -109 4 -85 -59 3 -9 -9 -4
-29 11 -53 41 -99 48 -307 48 l-192 0 2 -402z m322 212 c33 -12 66 -66 66
-106 0 -61 -60 -104 -147 -104 l-33 0 0 110 0 110 44 0 c25 0 56 -4 70 -10z"/>
<path d="M1292 1303 l3 -398 220 0 220 0 3 93 3 92 -121 0 -120 0 0 65 0 65
120 0 120 0 0 95 0 94 -122 3 -123 3 0 55 0 55 123 3 122 3 0 84 0 85 -225 0
-225 0 2 -397z"/>
</g>
</svg>
    )
}

export default MassLogoIcon;
