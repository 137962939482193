import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';

import {applyFilters, getPlayerClaims, resetFilters, setEndDate, setStartDate} from "./functions";

import {CircularProgress} from "@material-ui/core";
import {MobileClaimsActivityTableComponent} from './../../../components/PlayersComponents/MobileClaimsActivityTableComponent';
import {MobileClaimsActivityButtonsComponent} from './../../../components/PlayersComponents/MobileClaimsActivityButtonsComponent';

import {useHistory} from "react-router-dom";
import {playerSupportRoutePath} from './../../../config.js';

import {keyPressedType} from "../../../services/Commons";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    PageMessageCommonComponent,
    pageMessageDefault
} from '../../../components/CommonComponents/PageMessageCommonComponent';

import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

let eventEmitterInitialize: any = undefined;
const timerArray: any = [];
export const MobileClaimsActivityPage = ({id, storesObject}: any) => {

    const claimStore = storesObject.claimStore;

    const [isData, setIsData] = useState(false);
    const [UnfilteredClaims, setUnfilteredClaims] = useState<any[]>(storesObject.claimStore.claims);
    const [FilteredClaims, setFilteredClaims] = useState<any[]>(storesObject.claimStore.claims);

    const [MCAStartDate, setMCAStartDate] = useState<any>((storesObject.claimStore.claims.length > 0 && storesObject.claimStore.claims[0].claim.referenceNumber !== id) ? storesObject.claimStore.claims[storesObject.claimStore.claims.length - 1].claim.activeFrom.split("T")[0] : `${new Date().getUTCFullYear()}-01-01`);
    const [MCAEndDate, setMCAEndDate] = useState<any>(((storesObject.claimStore.claims.length > 0 && storesObject.claimStore.claims[0].claim.referenceNumber !== id) ? storesObject.claimStore.claims[0].claim.activeFrom.split("T")[0] : new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]));

    const [MCAInitiated, setMCAInitiated] = useState<boolean>(false);
    const [MCALoaded, setMCALoaded] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [networkError, setNetworkError] = useState<boolean>(false);
    const history: any = useHistory();

    localStorage.setItem('callingPage', history.location.pathname);
    const sleep = (ms: any) => {

        const timer = setTimeout((timerArray: any) => {
            timerArray.map((e: any) => clearTimeout(e));
            setMCALoaded(false);

        }, ms, timerArray)
        timerArray.push(timer)

    }

    useEffect(() => {

        if (FilteredClaims.length <= 0 && MCALoaded == false) {
            setIsData(false);
            getPlayerClaims(id, storesObject, setPageMessage, setFilteredClaims, setUnfilteredClaims, id, setIsData, setMCAStartDate, setMCAEndDate, id, setNetworkError);
            setMCALoaded(true);
            document.getElementById("page-container")?.focus();

        }

        if (networkError && isData) {
            setIsData(false);
            setNetworkError(false);
            sleep(5000);
        }
    });


    return (
        <>

            {(((FilteredClaims.length <= 0 && !isData) && pageMessage.message === "") || (networkError && !isData)) &&
            <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="8em" color='inherit' thickness={2}/>
            </div>}
            <div
                onClick={(e: any) => {
                    pageMessage.message !== "" &&
                    setPageMessage({message: "", messageType: ""});
                }}
                id="page-container"
                className={styles['page-container']}>
                <div className={styles['back-to-player-hub-container']}>
                    <div tabIndex={0} role="button" aria-pressed={"false"}
                         onKeyPress={(e: any) => {
                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                 localStorage.setItem('activeTab', 'activity');
                                 history.push(`${playerSupportRoutePath}/player-hub-new/${id}`);

                             }
                         }
                         }
                         onClick={() => {
                             localStorage.setItem('activeTab', 'activity');
                             history.push(`${playerSupportRoutePath}/player-hub-new/${window.location.href.split('/')[window.location.href.split('/').length - 1]}`);
                         }} className={styles['back-to-player-hub-inner-container']}>
                        <i title='left arrow icon' className={styles['back-to-player-hub-icon']}>
                            <FontAwesomeIcon size="2x" arial-label='left arrow icon.' icon={faAngleLeft as IconProp}/>
                        </i>
                        <div className={styles['back-to-player-hub-inner-container-text']}>Back
                            to {localStorage.getItem("PlayersName")}</div>
                    </div>
                </div>
                {pageMessage.message != "" && (
                    <PageMessageCommonComponent
                        message={pageMessage.message}
                        messageType={pageMessage.messageType}
                    />
                )}
                <div className={styles['top-ribbon']}>
                    <div className={styles['top-ribbon-text']} tabIndex={0}>Mobile Claim Activity</div>

                    <div className={styles['filter-container']}>
                        <MobileClaimsActivityButtonsComponent
                            UnfilteredClaims={UnfilteredClaims}
                            MCAStartDate={MCAStartDate}
                            setMCAStartDate={setMCAStartDate}
                            MCAEndDate={MCAEndDate}
                            setMCAEndDate={setMCAEndDate}
                            MCAInitiated={MCAInitiated}
                            setMCAInitiated={setMCAInitiated}
                            setFilteredClaims={setFilteredClaims}
                            setCurrentPage={setCurrentPage}
                            FilteredClaims={FilteredClaims}
                            id={id}
                            applyFilter={applyFilters}
                            resetFilters={resetFilters}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                    <MobileClaimsActivityTableComponent setIsData={setIsData} setNetworkError={setNetworkError}
                                                        setPageMessage={setPageMessage}
                                                        setFilteredClaims={setFilteredClaims}
                                                        storesObject={storesObject} id={id}
                                                        FilteredClaims={FilteredClaims}
                                                        currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                </div>
            </div>
        </>

    );
}