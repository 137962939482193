export interface LicenseeInterface {
    orgDetails: {
        orgFid: string,
        orgName: string,
        mailAddress: {
            streetAddress: string,
            city: string,
            zip: string
        }
    },
    licenseDetails: {
        licenseNumber: string,
        expirationDate: string,
        markedForRenewal: boolean,
        occasionAddress: {
            streetAddress: string,
            city: string,
            zip: string
        },
        occasionDay: string,
        occasionTime: string,
        memberInChargePhone: string,
        active: boolean
    }
}

export const occasionTime: any = {
    AFTERNOON: "1:00 P.M. to 6:00 P.M.",
    EVENING: "6:00 P.M. to 12:00 MID."
}