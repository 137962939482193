import { useFetch } from "../../../hooks/use-fetch"
import { SplitPaymentRequest } from "./types"
import config from "../../../config"
import { useState } from "react"

export type UseFetchSplitPaymentProps = {
}

const SPLIT_PAYMENT_BASE_URL = `${config.SERVER_BASE_URL}/api/v1/payments/split`
export const useFetchSplitPayment = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch()

  const fetchSplitPayment = async (request: SplitPaymentRequest) => {
    fetchUrl(SPLIT_PAYMENT_BASE_URL, 'POST', {}, JSON.stringify(request))
  }

  return {
    isLoading,
    error,
    requestSuccessful,
    fetchSplitPayment
  }
}