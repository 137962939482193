export type CampaignValidation = {
  campaignName: string
  campaignIdentifier: string
  startDate: string
  endDate: string
  expirationDate: string
  legalText: string
  campaignImage: File | undefined
}

const ERROR_TEXT = 'This field is required'

export const validateCreateCampaignForm = (values: CampaignValidation) => {
  let errors = {}

  if (values.campaignName === '') {
    Object.assign(errors, { campaignName: ERROR_TEXT })
  }

  if (values.campaignIdentifier === '') {
    Object.assign(errors, { campaignIdentifier: ERROR_TEXT })
  }

  if (values.campaignIdentifier.length !== 5) {
    Object.assign(errors, { campaignIdentifier: 'Must be exactly 5 digits' })
  }

  if (!/^\d+$/.test(values.campaignIdentifier)) {
    Object.assign(errors, { campaignIdentifier: 'Must only contain numbers' })
  }

  if (values.endDate === '') {
    Object.assign(errors, { endDate: ERROR_TEXT })
  }

  if (values.startDate === '') {
    Object.assign(errors, { startDate: ERROR_TEXT })
  }

  if (values.expirationDate === '') {
    Object.assign(errors, { expirationDate: ERROR_TEXT })
  }

  if (values.legalText === '') {
    Object.assign(errors, { legalText: ERROR_TEXT })
  }

  if (!values.campaignImage) {
    Object.assign(errors, { campaignImage: ERROR_TEXT })
  }

  if (new Date(values.startDate) > new Date(values.endDate)) {
    Object.assign(errors, {
      endDate: 'End date must be same or after the start date',
    })
  }

  if (new Date(values.endDate) > new Date(values.expirationDate)) {
    Object.assign(errors, {
      expirationDate: 'Expiration date must be same or after the end date',
    })
  }

  return errors
}

export const playerFileNameValidator = (file: File, campaign?: string) => {
  const campaignIdentifier = file.name.match(
    /^player_barcodes_([A-Za-z0-9]+)(_\w*)*.csv$/im
  )
  if (
    !campaignIdentifier ||
    campaignIdentifier.length < 2 ||
    campaignIdentifier[1] !== campaign
  ) {
    return {
      code: 'invalid-name',
      message: 'Invalid file name',
    }
  }
  return null
}
