import {InputValidationMessages} from "../../../../Constants/InputValidationMessages";
import config from "../../../../config";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import {formatCentsToDollars} from "../../../../util";

let formik: any;
let charitableGamingStore: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setPlayerOffsets: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, setShowErrorDialogObj: any, setRequestCompletedObj: any, setPlayerOffsetsObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setPlayerOffsets = setPlayerOffsetsObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.SSN) {
        Object.assign(errors, {SSN: InputValidationMessages.RequiredField});
    } else if (values.SSN && values.SSN.trim().length !== 11) {
        Object.assign(errors, {SSN: InputValidationMessages.InvalidSSNValue});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    getPlayerOffsets(values);
}

export const getOffsetAmount = (JSON: any, offsetType: any) => {

    let amountInCents = 0;

    JSON.map((item: any) => {
        if (item.offsetType === offsetType) {
            amountInCents = item.amountInCents;
        }
    });

    return formatCentsToDollars(amountInCents);

}

export const getTotalOffsetAmountOwed = (JSON: any) => {

    let totalAmountOwedInCents = 0;

    JSON.map((item: any) => {
        totalAmountOwedInCents += item.amountInCents;
    });

    return formatCentsToDollars(totalAmountOwedInCents);

}

export const getPlayerOffsets = async (values: any) => {

    // alert(JSON.stringify(values));

    setRequestCompleted(false); // Display Circular Progress

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/offsets`, headers: {
                'X-SSN': values.SSN
            }
        }

        let response = await makeHttpCall(options);

        if (response.hasOwnProperty('offsetsResult')) {
            setPlayerOffsets(response);
        } else {
            setPlayerOffsets(null);
        }

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
        console.log(e);
    }

}
