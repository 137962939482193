import * as React from 'react'
import { ExitButtonIcon } from '../../../../icons/exit-button-icon'
import './styles.scss'

export type ImagePreview = {
  name: string
  preview: string
}

export type ImagePreviewProps = {
  imagePreviews: ImagePreview[]
  previewOnly?: boolean
  allowIndividualRemoval?: boolean
  removeFile?: (filename: string) => void
  clearFiles?: () => void
}

const ROOT_CLASS = 'image-preview'
const CONTAINER_CLASS = `${ROOT_CLASS}-image-container`

export const ImagePreviewUI = (props: ImagePreviewProps) => {
  if (!props.imagePreviews.length) {
    return null
  }
  return (
    <div className={ROOT_CLASS}>
      {props.imagePreviews.map((imagePreview) => {
        return (
          <div key={imagePreview.name} className={`${CONTAINER_CLASS}`}>
            <img
              className={`${CONTAINER_CLASS}__image`}
              src={imagePreview?.preview}
              title={imagePreview.name}
              alt={imagePreview.name}
              onLoad={() => {
                if (imagePreview) {
                  URL.revokeObjectURL(imagePreview.preview)
                }
              }}
            />
            {props.allowIndividualRemoval && !props.previewOnly && (
              <button
                type="button"
                className={`${CONTAINER_CLASS}__remove-button`}
                aria-label="Remove file"
                onClick={() => props.removeFile?.(imagePreview.name)}
              >
                <ExitButtonIcon />
              </button>
            )}
          </div>
        )
      })}
      {!props.allowIndividualRemoval && !props.previewOnly && (
        <button
          type="button"
          className={`${ROOT_CLASS}__remove-button`}
          aria-label="Remove all files"
          onClick={props.clearFiles}
        >
          <ExitButtonIcon />
        </button>
      )}
    </div>
  )
}
