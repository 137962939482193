import React, { useCallback, useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import stylesPopUp from './css/styles_popup_box.module.css'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeHttpCall, getData, getFormattedCreationDate } from './functions'
import {
  faCaretDown,
  faCaretUp,
  faPlusCircle,
  faMinusCircle,
  faReply,
} from '@fortawesome/free-solid-svg-icons'
import { keyPressedType } from './../../../services/Commons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PageMessagePopUpCommonComponent } from './../../CommonComponents/PageMessageCommonComponent'
import { DocumentTableHeaderNoData } from './document-table-no-data'
import { DocumentTableHeaderWithData } from './document-table-header'
import { DocumentImageViewer } from './document-image-viewer'
import config from './../../../config'

const valuesOfDocumentType = []
@inject('playerStore', 'claimStore')
@observer
class DisplayDocumentNewComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      documentData: [],
      documentOpenByIndex: [],
      zoom: [],
      rotation: [],
      index: 0,
      playerId: props.playerId,
      documentTypeId: [],
      isData: false,
      setDocumentType: undefined,
      popupRadioButtonResult: undefined,
      count: 0,
      eventListenerAdded: undefined,
      componentName: props.name,
      playersCurrentImage: [],
      refreshDataForImages: 0,
      showToast: false,
      showToastResponse: '',
      refreshStatus: false,
      rotationPopUp: 0,
      zoomPopUp: 100,
      imageActions: { rotatePressed: false, zoomIn: false, zoomOut: false },
      actionShowReviewButton: [],
      setFocusOnPopup: true,
    }
  }

  setImageActionsState(e) {
    Object.keys(this.state.imageActions).forEach((key) => {
      if (key === e) {
        this.state.imageActions[key] = true
      } else {
        this.state.imageActions[key] = false
      }
    })
    return this.state.imageActions
  }

  componentDidMount() {
    this.populateFields()
  }
  componentDidUpdate() {
    if (this.state.isData === false) {
      this.state.count = 0
      this.populateFields()
    }
    if (
      this.state.setFocusOnPopup &&
      this.state.playersCurrentImage[this.state.index] !== undefined &&
      window.document.getElementById('document-image-pop-up-container') !==
        null &&
      !this.state.documentOpenByIndex[this.state.index] &&
      valuesOfDocumentType.find(
        (e) => e === this.state.documentData[this.state.index].documentType
      ) === undefined
    ) {
      this.state.setFocusOnPopup = false
      window.document.getElementById('document-image-pop-up-container').focus()
    }
  }

  triggerSetState = (data) => {
    this.setState(data)
  }
  testIfImageIsAvailable = async () => {
    if (this.props.requestPerformed !== false) {
      return
    }

    if (
      this.state.playersCurrentImage[this.state.index] === '' &&
      this.state.documentData[this.state.index].documentType === undefined
    ) {
      return
    }

    const options = {
      method: 'put',
      url: config.SERVER_BASE_URL + '/v1/player/testplayerimageavailable',
      data: {
        imageUrl: this.state.documentData[this.state.index].url,
        documentType:
          this.state.documentData[this.state.index].documentType === undefined
            ? 'No Document type assigned'
            : this.state.documentData[this.state.index].documentType,
      },
    }

    try {
      const response = await makeHttpCall(options)
      if (this.props.pageMessage.messageType === 'ERROR') {
        this.props.setPageMessage({ message: '', messageType: '' })
      }
      this.state.playersCurrentImage[this.state.index] = ''

      if (response.statusCode === 200) {
        this.state.playersCurrentImage[this.state.index] = options.data.imageUrl
        this.setState({
          playersCurrentImage: this.state.playersCurrentImage,
          refreshDataForImages: 0,
          refreshStatus: false,
        })
        return
      } else if (response.statusCode === 403) {
        if (
          this.state.refreshDataForImages < 5 &&
          response.refreshStatus === true
        ) {
          this.state.playersCurrentImage[this.state.index] = undefined
          this.state.refreshDataForImages++
          this.state.refreshStatus = true
          await this.populateFields()

          return
        }
      }

      this.state.showToastResponse = `${response.statusCode} ${response.statusMessage}`
      this.setState({
        playersCurrentImage: this.state.playersCurrentImage,
        showToast: true,
        refreshDataForImages: 0,
        showToastResponse: this.state.showToastResponse,
        refreshStatus: false,
      })
    } catch (e) {
      this.props.setRequestPerformed(false)
      this.setState({ playersCurrentImage: this.state.playersCurrentImage })
      this.props.setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
    }
  }
  processRequest = (data) => {
    this.state.documentData = []
    this.state.documentOpenByIndex = []
    this.state.documentTypeId = []
    this.state.playersCurrentImage = []
    this.state.zoom = []
    this.state.rotation = []
    this.state.actionShowReviewButton = []
    if (data !== undefined && data !== null) {
      data.forEach((element, i) => {
        this.state.documentData.push(element)
        this.state.documentOpenByIndex.push(true)
        this.state.playersCurrentImage.push(undefined)
        this.state.zoom.push(100)
        this.state.rotation.push(0)
        this.state.actionShowReviewButton.push(false)
      })
      this.state.documentTypeId.push('Select')
      this.state.documentTypeId.push('Govt. Issued Id (Front)')
      valuesOfDocumentType.push('GOV_ID_FRONT')
      this.state.documentTypeId.push('Govt. Issued Id (Back)')
      valuesOfDocumentType.push('GOV_ID_BACK')
      this.state.documentTypeId.push('Social Security Card')
      valuesOfDocumentType.push('SSN_CARD')
      this.state.documentTypeId.push('Other')
      valuesOfDocumentType.push('OTHER')
      this.setState({
        documentData: this.state.documentData,
        documentTypeId: this.state.documentTypeId,
        isData: true,
        playersCurrentImage: this.state.playersCurrentImage,
        refreshStatus: this.state.refreshStatus,
        count: 0,
      })
    }
  }

  populateFields = () => {
    return new Promise((resolve, reject) => {
      if (this.state.count === 0) {
        this.state.count++

        if (
          this.props.playerId !== '' &&
          this.props.requestPerformed === false
        ) {
          getData(this.props.playerId, this.props.setPageMessage).then(
            (data) => {
              try {
                if (data.length <= 0) {
                  this.props.playerStore.playerDocImages.splice(
                    0,
                    this.props.playerStore.playerDocImages.length
                  )
                  this.props.playerStore.playerDocImages.push({
                    url: this.props.playerId,
                  })
                } else {
                  this.props.playerStore.playerDocImages = data
                }

                this.processRequest(data)

                resolve(true)
              } catch (e) {
                resolve(true)
              }
            }
          )
        } else if (this.props.playerStore.playerDocImages.length > 0) {
          for (
            let i = 0;
            i < this.props.playerStore.playerDocImages.length;
            i++
          ) {
            try {
              const element = this.props.playerStore.playerDocImages[i].url
              let found = false
              if (element !== undefined) {
                if (element === this.props.playerId) {
                  this.processRequest([])
                  return
                }
                const response = element
                  .split('?')[0]
                  .split('/')
                  .find((e) => {
                    if (e.localeCompare(this.props.playerId) === 0) {
                      found = true
                    }
                  })

                if (found) {
                  this.processRequest(this.props.playerStore.playerDocImages)
                  return
                }
              }
            } catch (e) {}
          }
          this.props.playerId !== '' &&
            this.props.requestPerformed === false &&
            getData(this.props.playerId, this.props.setPageMessage).then(
              (data) => {
                try {
                  if (data.length <= 0) {
                    this.props.playerStore.playerDocImages.splice(
                      0,
                      this.props.playerStore.playerDocImages.length
                    )
                    this.props.playerStore.playerDocImages.push({
                      url: this.props.playerId,
                    })
                  } else {
                    this.props.playerStore.playerDocImages = data
                  }

                  this.processRequest(data)
                  resolve(true)
                } catch (e) {}
              }
            )
        }
      }
    })
  }

  deleteImage = async (uploadId) => {
    if (uploadId === '') {
      this.state.showToastResponse = `Upload id is empty`
      this.setState({
        showToast: true,
        showToastResponse: this.state.showToastResponse,
      })
      return
    }
    const options = {
      method: 'delete',
      url: config.SERVER_BASE_URL + '/v1/player/deletedocument/' + uploadId,
      data: {
        imageUrl: this.state.documentData[this.state.index].url,
        documentType:
          this.state.documentData[this.state.index].documentType === undefined
            ? 'No Document type assigned'
            : this.state.documentData[this.state.index].documentType,
      },
    }

    try {
      const response = await makeHttpCall(options)
      if (response.statusCode === 200) {
        this.state.playersCurrentImage = []
        this.state.documentData = this.state.documentData.filter((element) => {
          if (element.uploadId !== uploadId) {
            this.state.playersCurrentImage.push(element.url)
            return element
          }
        })
        const data = {
          documentData:
            this.state.documentData === undefined
              ? []
              : this.state.documentData,
          index: --this.state.index <= 0 ? 0 : this.state.index,
          imageType: [],
          playersCurrentImage: this.state.playersCurrentImage,
        }
        this.setState(data)
      }
      this.props.playerStore.playerDocImages = this.state.documentData
      this.props.setPageMessage({
        message: response.message,
        messageType: 'SUCCESS',
      })
      document.scrollingElement.scrollTo(0, 0)
    } catch (e) {
      document.scrollingElement.scrollTo(0, 0)
      this.props.setRequestPerformed(false)
      this.props.setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
    }
  }
  getDocumentType = async (documentType, uploadId) => {
    const ev = documentType
    const options = {
      method: 'put',
      url: config.SERVER_BASE_URL + '/v1/player/putdocument/' + uploadId,
      data: {
        type: ev,
      },
    }

    try {
      const response = await makeHttpCall(options)

      if (response.statusCode === 200) {
        const data = {
          documentData: this.state.documentData.map((element) => {
            if (element.uploadId === uploadId) {
              element.documentType = ev
            }
            return element
          }),
        }
        this.props.playerStore.playerDocImages = data.documentData
        this.setState(data)
      }
      this.props.setPageMessage({
        message: response.message,
        messageType: 'SUCCESS',
      })
      document.scrollingElement.scrollTo(0, 0)
    } catch (e) {
      document.scrollingElement.scrollTo(0, 0)
      this.props.setRequestPerformed(false)
      this.props.setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
    }
  }

  getpopUpCheckButton(event) {
    let value = false

    if (event.target.value === 'document_type_approved') {
      value = true
    }

    this.setState({ popupRadioButtonResult: value })
  }

  setDocumentTypeFromPopUp(event) {
    this.setState({ setDocumentType: event.target.value })
  }

  popupActionCancel(i) {
    this.setState({
      setDocumentType: undefined,
      popupRadioButtonResult: undefined,
      toggle: this.setToggle(i),
    })
  }

  popupActionSave(i) {
    if (this.state.popupRadioButtonResult === undefined) {
      this.props.setPageMessage({
        message: 'Please Select an option',
        messageType: 'ERROR',
      })
      return
    }
    if (
      valuesOfDocumentType.find((e) => e === this.state.setDocumentType) ===
        undefined &&
      this.state.popupRadioButtonResult === true
    ) {
      this.props.setPageMessage({
        message: 'Please Select a Document Type',
        messageType: 'ERROR',
      })
      return
    }

    if (this.state.popupRadioButtonResult === true) {
      this.getDocumentType(
        this.state.setDocumentType,
        this.state.documentData[this.state.index].uploadId
      )
    } else {
      this.deleteImage(this.state.documentData[this.state.index].uploadId)
    }

    this.setState({
      toggle: this.setToggle(i),
      documentData: this.state.documentData.slice(),
    })
  }

  getDocumentTypeId(i) {
    try {
      let index = valuesOfDocumentType.indexOf(
        this.state.documentData[i].documentType
      )
      return index === -1 ? '' : this.state.documentTypeId[index + 1]
    } catch (e) {
      return ''
    }
  }

  setToggle(i) {
    this.state.documentOpenByIndex[i] = !this.state.documentOpenByIndex[i]
    return this.state.documentOpenByIndex
  }
  setRotation(i) {
    this.state.rotation[i] = (this.state.rotation[i] + 90) % 360
    return this.state.rotation
  }
  setZoomPlus(i) {
    this.state.zoom[i] = this.state.zoom[i] + 10
    return this.state.zoom
  }
  setZoomMinus(i) {
    this.state.zoom[i] = this.state.zoom[i] - 10
    return this.state.zoom
  }
  resetImageButtonStatus(e) {
    setTimeout(() => {
      this.setState({ imageActions: this.setImageActionsState('undefined') })
    }, 1000)
    return this.state.imageActions[e]
  }
  setShowButtonStatus(i) {
    this.state.actionShowReviewButton[i] = true
    return this.state.actionShowReviewButton
  }
  resetShowButtonStatus(i) {
    setTimeout(() => {
      this.state.actionShowReviewButton[i] = false
      this.setState({
        actionShowReviewButton: this.state.actionShowReviewButton,
      })
    }, 1000)
    return this.state.actionShowReviewButton[i]
  }
  seFocusOnPopUp() {
    document.getElementById('document-image-pop-up-outer-container').focus()
  }
  renderMountedPageWithData() {
    return (
      <>
        {this.state.showToast && (
          <div
            style={{
              zIndex: 1000,
              position: 'fixed',
              width: '41vmax',
              height: '6vmax',
              top: '5vmax',
              left: '41vmax',
            }}
          >
            {this.setState({ showToast: false })}
          </div>
        )}
        <div
          id="container"
          style={{
            minHeight: '5.52vmax',

            marginBottom: this.state.documentOpenByIndex ? '0vmax' : '2vmax',
          }}
          className={styles['document-image-outer-container']}
        >
          <DocumentTableHeaderWithData />

          <div
            className={
              styles[
                'player-document-info-players-document-details-content-images-container'
              ]
            }
          >
            {this.state.documentData.map((e, i) => (
              <React.Fragment key={e.url}>
                <div
                  style={{
                    minHeight: `${
                      valuesOfDocumentType.find(
                        (e) => e === this.state.documentData[i].documentType
                      ) !== undefined
                        ? this.state.documentOpenByIndex[i]
                          ? '4.125em'
                          : '25.437vmax'
                        : '4.125em'
                    }`,
                    height: 'auto',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    className={
                      styles[
                        'player-document-info-players-document-details-content-container'
                      ]
                    }
                  >
                    <div
                      className={`${styles['player-document-info-players-document-details-content-text']} 
                                            ${styles['player-document-info-players-document-details-content-text-upload-date']}`}
                      tabIndex="0"
                      aria-label={getFormattedCreationDate(
                        this.state.documentData[i].createdAt
                      )}
                    >
                      {getFormattedCreationDate(
                        this.state.documentData[i].createdAt
                      )}
                    </div>
                    <div
                      className={`${styles['player-document-info-players-document-details-content-text']} ${styles['player-document-info-players-document-details-content-text-margin-right']}`}
                      tabIndex="0"
                    >
                      {'JPG'}
                    </div>

                    <div
                      tabIndex="0"
                      style={{ minHeight: '100%', marginRight: '0em' }}
                      className={
                        styles[
                          'player-document-info-players-document-details-content-select'
                        ]
                      }
                    >
                      {this.getDocumentTypeId(i)}
                    </div>
                    <div
                      className={
                        styles[
                          'player-document-info-players-document-details-content-button-hide-show-review-container'
                        ]
                      }
                    >
                      <div
                        aria-live={'assertive'}
                        role="button"
                        aria-pressed={`${
                          this.state.actionShowReviewButton[i]
                            ? this.resetShowButtonStatus(i)
                            : false
                        }`}
                        tabIndex="0"
                        onKeyPress={(e) =>
                          keyPressedType(e) === 13 || keyPressedType(e) === 0
                            ? this.setState({
                                toggle: this.setToggle(i),
                                refreshDataForImages: 0,
                                index: i,
                                actionShowReviewButton:
                                  this.setShowButtonStatus(i),
                              })
                            : ''
                        }
                        onClick={(e) => {
                          this.setState({
                            toggle: this.setToggle(i),
                            refreshDataForImages: 0,
                            index: i,
                            actionShowReviewButton: this.setShowButtonStatus(i),
                            setFocusOnPopup: true,
                          })
                        }}
                        className={`
                                            ${
                                              valuesOfDocumentType.find(
                                                (e) =>
                                                  e ===
                                                  this.state.documentData[i]
                                                    .documentType
                                              ) === undefined
                                                ? styles[
                                                    'player-document-info-players-document-details-content-button-review'
                                                  ]
                                                : styles[
                                                    'player-document-info-players-document-details-content-button-hide-show'
                                                  ]
                                            }`}
                      >
                        <div
                          className={`
                                            ${
                                              valuesOfDocumentType.find(
                                                (e) =>
                                                  e ===
                                                  this.state.documentData[i]
                                                    .documentType
                                              ) === undefined
                                                ? styles[
                                                    'player-document-info-players-document-details-content-button-review-text'
                                                  ]
                                                : styles[
                                                    'player-document-info-players-document-details-content-button-hide-show-text'
                                                  ]
                                            }`}
                        >
                          {valuesOfDocumentType.find(
                            (e) => e === this.state.documentData[i].documentType
                          ) === undefined
                            ? 'Review'
                            : this.state.documentOpenByIndex[i]
                            ? 'Show'
                            : 'Hide'}
                        </div>
                        {valuesOfDocumentType.find(
                          (e) => e === this.state.documentData[i].documentType
                        ) !== undefined ? (
                          this.state.documentOpenByIndex[i] ? (
                            <FontAwesomeIcon icon={faCaretDown} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretUp} />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  {!this.state.documentOpenByIndex[i] &&
                    valuesOfDocumentType.find(
                      (e) => e === this.state.documentData[i].documentType
                    ) !== undefined && (
                      <>
                        <div
                          id="document_image"
                          className={styles['document-image-container']}
                        >
                          <DocumentImageViewer
                            testIfImageIsAvailable={this.testIfImageIsAvailable}
                            altText={this.state.documentData[i].documentType}
                            imageSrc={
                              this.state.playersCurrentImage[i] ??
                              this.state.documentData[i].url
                              // this.state.documentData[i].url seems to be a more reliable reference;
                              // however, initial implementation used this.state.playersCurrentImage[i],
                              // so that is use as the first attempted value in the interest of avoiding regressions
                            }
                          />
                        </div>
                      </>
                    )}

                  {this.state.documentData.length - 1 !== i && (
                    <div style={{ width: '100%', height: '.7em' }}>
                      <hr
                        style={{ height: '.01em', opacity: 0.4 }}
                        className={styles['document-image-outer-container-hr']}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>

        {!this.state.documentOpenByIndex[this.state.index] &&
          valuesOfDocumentType.find(
            (e) => e === this.state.documentData[this.state.index].documentType
          ) === undefined && (
            <>
              <div
                id="document-image-pop-up-outer-container"
                tabIndex={0}
                className={stylesPopUp['document-image-pop-up-outer-container']}
              >
                <div
                  tabIndex={0}
                  id={'document-image-pop-up-container'}
                  className={stylesPopUp['document-image-pop-up-container']}
                >
                  {this.props.pageMessage.message !== '' && (
                    <PageMessagePopUpCommonComponent
                      message={this.props.pageMessage.message}
                      messageType={this.props.pageMessage.messageType}
                    />
                  )}
                  <div
                    className={stylesPopUp['document-image-pop-up-title']}
                    tabIndex="0"
                  >
                    Review Document
                  </div>
                  <div className={stylesPopUp['document-image-container']}>
                    <DocumentImageViewer
                      testIfImageIsAvailable={this.testIfImageIsAvailable}
                      altText={
                        this.state.documentData[this.state.index].documentType
                      }
                      imageSrc={
                        this.state.playersCurrentImage[this.state.index] ===
                          undefined &&
                        !this.state.documentOpenByIndex[this.state.index]
                          ? this.testIfImageIsAvailable() && ''
                          : this.state.playersCurrentImage[this.state.index]
                      }
                    />
                  </div>
                  <div
                    tabIndex={0}
                    className={
                      stylesPopUp['document-image-pop-up-created-at-text']
                    }
                  >
                    {getFormattedCreationDate(
                      this.state.documentData[this.state.index].createdAt
                    )}
                  </div>
                  <div className={stylesPopUp['document-image-pop-up-actions']}>
                    <div
                      className={
                        stylesPopUp[
                          'document-image-pop-up-actions-radio-buttons-container'
                        ]
                      }
                    >
                      <div
                        className={
                          stylesPopUp[
                            'document-image-pop-up-actions-radio-select-container'
                          ]
                        }
                      >
                        <input
                          tabIndex="0"
                          type="radio"
                          value="document_type_approved"
                          name="document_type"
                          onChange={(e) => this.getpopUpCheckButton(e)}
                        />
                        <div tabIndex="0">Approved and</div>

                        <select
                          tabIndex="0"
                          style={{ height: '1.875vmax' }}
                          className={
                            stylesPopUp[
                              'player-document-info-players-document-details-content-select'
                            ]
                          }
                          onChange={(e) => this.setDocumentTypeFromPopUp(e)}
                        >
                          {this.state.documentTypeId.map((element, i) => {
                            return (
                              <option
                                key={element}
                                value={
                                  i === 0
                                    ? undefined
                                    : valuesOfDocumentType[i - 1]
                                }
                              >
                                {element}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                      <div
                        className={
                          stylesPopUp[
                            'document-image-pop-up-actions-radio-container'
                          ]
                        }
                      >
                        <input
                          tabIndex="0"
                          type="radio"
                          value="document_type_deny"
                          name="document_type"
                          onChange={(e) => this.getpopUpCheckButton(e)}
                        />
                        <div tabIndex={0}>Deny and delete file</div>
                        <br />
                        <div style={{ width: '7.5vmax' }}></div>
                      </div>

                      <div
                        role="button"
                        aria-pressed="false"
                        tabIndex={0}
                        style={{ cursor: 'pointer' }}
                        onKeyPress={(e) =>
                          keyPressedType(e) === 13 ||
                          keyPressedType(e) === 0 ||
                          keyPressedType(e) === 32
                            ? this.popupActionSave(this.state.index)
                            : ''
                        }
                        onClick={(e) => this.popupActionSave(this.state.index)}
                        className={
                          stylesPopUp[
                            'player-document-info-players-document-details-content-button-save'
                          ]
                        }
                      >
                        <div
                          className={
                            stylesPopUp[
                              'player-document-info-players-document-details-content-button-save-text'
                            ]
                          }
                        >
                          {' '}
                          Save
                        </div>
                      </div>
                      <div
                        className={
                          stylesPopUp[
                            'player-document-info-players-document-details-content-button-cancel'
                          ]
                        }
                        role="button"
                        tabIndex={0}
                        aria-pressed="false"
                        style={{ cursor: 'pointer' }}
                        onKeyPress={(e) =>
                          keyPressedType(e) === 13 ||
                          keyPressedType(e) === 0 ||
                          keyPressedType(e) === 32
                            ? this.popupActionCancel(this.state.index)
                            : ''
                        }
                        onClick={(e) =>
                          this.popupActionCancel(this.state.index)
                        }
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    )
  }

  render() {
    return (
      <>
        {!this.state.isData && this.props.pageMessage.message === '' && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.3)',
              color: 'white',
            }}
          >
            <CircularProgress size="8em" color="inherit" thickness={2} />
          </div>
        )}

        {!this.state.isData ? null : this.state.documentData.length > 0 ? (
          this.renderMountedPageWithData()
        ) : (
          <DocumentTableHeaderNoData />
        )}
      </>
    )
  }
}

export { DisplayDocumentNewComponent }
