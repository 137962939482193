import {InputValidationMessages} from "../../../../../Constants/InputValidationMessages.js";
import config from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";
import moment from "moment";
import {NotificationType} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {BarcodeScanType} from "../../../../../Constants/CharitableConstant.js";

let formik: any;
let history: any;
let charitableGamingStore: any;

const BARCODE_LENGTH = 18;

export const setVariables = (formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setWarehouse = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const setFieldValue = async (e: any, i: number) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {

        const index = e.target.name.split('.')[1];

        formik.setFieldValue(e.target.name, e.target.value.trim(), true);

        formik.setFieldValue(`barcodeInformation.${index}.stockNumber`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.serialNumbers`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.quantity`, '', true);
        formik.setFieldValue(`barcodeInformation.${index}.unitPrice`, '', true);
        formik.setFieldValue(`barcodeInformation.${index}.totalPrice`, '', true);
        formik.setFieldValue(`barcodeInformation.${index}.orgName`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.licenseNumber`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.purchaseOrderId`, '', true);
        formik.setFieldValue(`barcodeInformation.${index}.error`, '', true);

        if (e.target.value.trim().length === 18) {
            await ScanBarcode(e.target.value.trim(), index);
        }
    }

}

export const validate = (values: any) => {

    const errors: any = {};

    for (let i = 0; i < values.barcodeInformation.length; i++) {
        if (!values.barcodeInformation[i].barcodeNumber) {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.RequiredField;
        } else if (values.barcodeInformation[i].barcodeNumber && values.barcodeInformation[i].barcodeNumber.length < BARCODE_LENGTH) {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.InvalidBarcode;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeIneligibleReturn') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeIneligibleReturn;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeNotfound') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeNotfound;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeScanGenericError') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeScanGenericError;
        }
    }

    if (!values.warehouse) {
        Object.assign(errors, {warehouse: InputValidationMessages.RequiredField});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    ReturnOrder(values);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {
    history.goBack();
}

export const YesResetAllBarcodes = function () {

    let barcodeInformationTemp = [];

    for (let i = 0; i < formik.values.barcodeInformation.length; i++) {
        barcodeInformationTemp.push({
            serialNumber: barcodeInformationTemp.length + 1,
            barcodeNumber: '',
            stockNumber: '-',
            serialNumbers: '-',
            quantity: '0',
            unitPrice: '-',
            totalPrice: '-',
            orgName: '-',
            licenseNumber: '-',
            purchaseOrderId: '',
            error: ''
        });
    }

    formik.resetForm({
        values: {
            warehouse: formik.values.warehouse,
            barcodeInformation: barcodeInformationTemp
        }
    });

}

export const YesDeleteBarcode = function (deleteIndex: number) {

    let barcodeInformationTemp = JSON.parse(JSON.stringify(formik.values.barcodeInformation));
    barcodeInformationTemp.splice(deleteIndex, 1);

    formik.setFieldValue("barcodeInformation", barcodeInformationTemp, true);

}

export const ScanBarcode = async function (barcodeNumber: string, index: number) {

    const body = {
        barcode: barcodeNumber,
        scanType: BarcodeScanType.TRANSFER
    };

    try {

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/barcode/scan`,
            data: body
        }

        let response = await makeHttpCall(options);

        if (!response.hasOwnProperty('returnCostDetails')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeIneligibleReturn', true);
            return;
        }

        formik.setFieldValue(`barcodeInformation.${index}.stockNumber`, response.stockNumber, true);
        formik.setFieldValue(`barcodeInformation.${index}.serialNumbers`, `${response.serialNumberStart} - ${response.serialNumberEnd}`, true);
        formik.setFieldValue(`barcodeInformation.${index}.quantity`, response.numUnits.toString(), true);
        formik.setFieldValue(`barcodeInformation.${index}.unitPrice`, response.returnCostDetails?.unitPriceInCents, true);
        formik.setFieldValue(`barcodeInformation.${index}.totalPrice`, response.returnCostDetails?.totalCostInCents, true);
        formik.setFieldValue(`barcodeInformation.${index}.orgName`, response.orgName, true);
        formik.setFieldValue(`barcodeInformation.${index}.licenseNumber`, response.returnCostDetails?.licenseNumber, true);
        formik.setFieldValue(`barcodeInformation.${index}.purchaseOrderId`, response.returnCostDetails?.purchaseOrderId, true);

    } catch (e: any) {
        console.log(e);
        if (e.toString().includes('404')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeNotfound', true);
        } else if (e.toString().includes('409')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeIneligibleReturn', true);
        } else {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeScanGenericError', true);
        }
    }

}

export const ReturnOrder = async function (values: any) {

    // alert(JSON.stringify(values));

    const body: any = {
        details: []
    };

    const warehouseId = values.warehouse.split('-')[0].trim();
    const returnDate = moment().format('YYYY-MM-DD') + 'T00:00:00.000Z';

    for (let returnOrder of values.barcodeInformation) {

        const serialNumberStart = returnOrder.serialNumbers.split('-')[0].trim();
        const serialNumberEnd = returnOrder.serialNumbers.split('-')[1].trim();

        body.details.push({
            licenseNumber: returnOrder.licenseNumber,
            warehouseId: warehouseId,
            returnDate: returnDate,
            priceReturnedInCents: returnOrder.totalPrice,
            stockNumber: returnOrder.stockNumber,
            serialNumberStart: serialNumberStart,
            serialNumberEnd: serialNumberEnd,
            unitPriceInCents: returnOrder.unitPrice,
            numUnits: Number(returnOrder.quantity),
            purchaseOrderId: returnOrder.purchaseOrderId
        });
    }

    // alert(JSON.stringify(body));

    try {

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/returns`,
            data: body
        }

        let response = await makeHttpCall(options);

        history.goBack();

        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The order was successfully returned.'
        });

    } catch (e) {
        console.log(e);
        charitableGamingStore.showErrorNotificationBanner('Something went wrong. Please try again');
    }

}




