import { CircularProgress } from '@material-ui/core'
import React from 'react'

export const LoadingSpinner = ({ showSpinner }: { showSpinner: boolean }) => {
  return (
    <>
      {showSpinner && (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}
    </>
  )
}
