import * as React from 'react'
import sortBy from 'lodash/sortBy'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { getBlockClass } from '../../../utilities/helpers'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import {
  PlayslipsStepDownStatusOrder,
  PlayslipsStepDownTableRow,
  mapPlayslipsStepDownDataToRow,
  playslipsStepDownTableColumnMap,
} from './helpers'
import { GenericTableUI } from '../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { PlayslipsStepDownPageContext } from './playslips-step-down-context'
import { PlayslipsStepDownSidebarContainer } from '../../../components/OperationsComponents/playslips-step-down-sidebar/playslips-step-down-sidebar-container'
import { PlayslipsStepDownDeleteStepDownContainer } from '../../../components/OperationsComponents/playslips-step-down-modals/delete-step-down/playslips-step-down-delete-step-down-container'
import { PlayslipsAllStepDownsResponse } from '../../../api/playslips-fetcher/constants'
import './styles.scss'

const ROOT_CLASS = 'playslips-step-down-page'
const HEADER_CLASS = `${ROOT_CLASS}-header`

export type PlayslipsStepDownPageUIProps = {
  isLoading: boolean
  error?: string
  data?: PlayslipsAllStepDownsResponse
  actionsDisabled: boolean
  fetchAllPlayslipsStepDowns: () => Promise<void>
}

export const PlayslipsStepDownPageUI = (
  props: PlayslipsStepDownPageUIProps
) => {
  const [sidebarIsOpen, setSidebarIsOpen] = React.useState<boolean>(false)
  const [openDeleteStepDownIdentifier, setOpenDeleteStepDownIdentifier] =
    React.useState<string>()

  const mappedData = sortBy(
    props.data?.map((stepDown) =>
      mapPlayslipsStepDownDataToRow(stepDown, props.actionsDisabled, () =>
        setOpenDeleteStepDownIdentifier(stepDown.identifier)
      )
    ) ?? [],
    (item: PlayslipsStepDownTableRow) =>
      PlayslipsStepDownStatusOrder[item.status]
  )

  return (
    <>
      <PlayslipsStepDownPageContext.Provider
        value={{
          fetchAllPlayslipsStepDowns: props.fetchAllPlayslipsStepDowns,
        }}
      >
        {sidebarIsOpen && (
          <PlayslipsStepDownSidebarContainer
            isOpen={sidebarIsOpen}
            closeSidebar={() => setSidebarIsOpen(false)}
          />
        )}
      </PlayslipsStepDownPageContext.Provider>
      {openDeleteStepDownIdentifier && (
        <PlayslipsStepDownDeleteStepDownContainer
          closeModal={() => setOpenDeleteStepDownIdentifier(undefined)}
          gameIdentifier={openDeleteStepDownIdentifier}
          fetchAllPlayslipsStepDowns={props.fetchAllPlayslipsStepDowns}
        />
      )}
      <section className={ROOT_CLASS}>
        <BackButtonUI
          message="Back to Operations"
          tabIndex={1}
          removeLeftMargin
        />
        <header className={HEADER_CLASS}>
          <div className={getBlockClass(HEADER_CLASS, 'title')}>
            Playslips Step Down
          </div>
          <button
            className={getBlockClass(HEADER_CLASS, 'create-button')}
            onClick={() => setSidebarIsOpen(true)}
            aria-label="Create Playslips Step Down"
            disabled={props.actionsDisabled}
          >
            <div>Create</div>
          </button>
        </header>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <AsyncContainerUI
            isLoading={props.isLoading}
            error={props.error}
            errorTextSize={15}
            errorTextColor="white"
            color="white"
          >
            <GenericTableUI
              columnMap={playslipsStepDownTableColumnMap}
              rowData={mappedData}
              useColumnWidths
              useActionMenu
              usePages
              rowsPerPage={6}
              customRowCellStyling={{ padding: '24px 20px 24px 16px' }}
              customHeaderCellStyling={{ paddingLeft: '16px' }}
            />
          </AsyncContainerUI>
        </div>
      </section>
    </>
  )
}
