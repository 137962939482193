import { AuthorizationHistoryData } from "../../../../types/Annuities/TimePaymentAuthorization/AuthorizationHistory";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import config from "../../../../config";

// pagination
const normalizePage = (totalPages: number, currentPage: number) => {
  const number: number = totalPages - currentPage * 10;
  if (number > 10) {return currentPage * 10 + 10;}
  else {return totalPages;}
};

const getPageData = (KYCPlayerData: any[], page: number = 0) => {
  const array: any = [];
  for (let i = page * 10; i < page * 10 + 10; i++) {
    if (!(KYCPlayerData[i] === undefined)) {array.push(KYCPlayerData[i]);}
  }
  return array;
};

const getAuthorizationHistory = async (): Promise<AuthorizationHistoryData[]> => {
  const options = {
    method: "get",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/summary`,
  };

  let authorizationHistory;

  try {
    const response: any = await makeHttpCall(options);
    // update response to use Dates for sorting comparison
    const convertedAuthorizationHistory = response.map((obj: any) => {
      return { ...obj, createdOn: new Date(obj.createdOn) };
    });

    //sort array
    convertedAuthorizationHistory.sort((objA: any, objB: any) => Number(objB.createdOn) - Number(objA.createdOn));

    authorizationHistory = convertedAuthorizationHistory;
  } catch (error) {
    throw new Error("Something went wrong capturing Authorization History");
  }

  return authorizationHistory;
};

export { normalizePage, getPageData, getAuthorizationHistory };
