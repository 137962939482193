import * as React from 'react'
import { GetPaymentDetailsResponse } from '../../../api/payment-details-fetcher/api-types'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { PaymentDetailsContainerUI } from '../../../components/PaymentComponents/PaymentDetailsComponent/payment-details-container/payment-details-container'

export type PaymentDetailsPageUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse | undefined
  voidPayment: (internalPaymentId: string) => void
  isLoading: boolean
  voidPaymentError: string | undefined
  paymentDetailsError: string | undefined
}

export const PaymentDetailsPageUI = (props: PaymentDetailsPageUIProps) => {
  return (
    <AsyncContainerUI
      isLoading={props.isLoading}
      error={props.paymentDetailsError}
      errorTextSize={20}
      color="white"
    >
      {props.paymentDetailsData ? (
        <PaymentDetailsContainerUI
          paymentDetailsData={props.paymentDetailsData}
          voidPayment={props.voidPayment}
          voidPaymentError={props.voidPaymentError}
        />
      ) : (
        <div>Something went wrong</div>
      )}
    </AsyncContainerUI>
  )
}
