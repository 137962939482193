import React from "react"
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { ReissueHistoryContainerUI } from "../../../components/PaymentComponents/ReissueHistoryComponents/reissue-history-container/reissue-history-container-ui"
import { ReissueRunHistoryResponse } from "../../../api/reissue-run-history-fetcher/api-types"

export type ReissueHistoryPageUIProps = {
  historicalReissueRunsData: ReissueRunHistoryResponse | undefined
  isLoading: boolean
  error: string | undefined
}

export const ReissueHistoryPageUI = (props: ReissueHistoryPageUIProps) => {
  return (
    <AsyncContainerUI
      isLoading = {props.isLoading}
      error={props.error}
      errorTextSize={20}
      color="white"
    >
      {props.historicalReissueRunsData ? (
        <ReissueHistoryContainerUI historicalReissueRunsData={props.historicalReissueRunsData} />
      ) : (
        <div>Something went wrong</div>
      )}
    </AsyncContainerUI>
  )
}

