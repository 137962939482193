import config from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";
import moment from "moment";

let formik: any;
let charitableGamingStore: any;
let unfilteredStockInventory: any;
let setUnfilteredStockInventory: any;
let filteredStockInventory: any;
let setFilteredStockInventory: any;
let setCurrentPage: any;
let setRequestCompleted: any;
let setShowErrorDialog: any;
let showNotificationBanner: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, unfilteredLicenseesObj: any, setUnfilteredLicenseesObj: any,
                             filteredLicenseesObj: any, setFilteredLicenseesObj: any, setCurrentPageObj: any,
                             setRequestCompletedObj: any, setShowErrorDialogObj: any, showNotificationBannerObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;
    unfilteredStockInventory = unfilteredLicenseesObj;
    setUnfilteredStockInventory = setUnfilteredLicenseesObj;
    filteredStockInventory = filteredLicenseesObj;
    setFilteredStockInventory = setFilteredLicenseesObj;
    setCurrentPage = setCurrentPageObj;
    setRequestCompleted = setRequestCompletedObj;
    setShowErrorDialog = setShowErrorDialogObj;
    showNotificationBanner = showNotificationBannerObj;
}

export const setFieldValue = (e: any, formik: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === "serialNumber") {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }
}

export const handleCheckboxChange = (checkboxname: string, formik: any) => {

    setCurrentPage(0);

    if (checkboxname === 'showSoldOnly') {
        formik.setFieldValue(checkboxname, !formik.values.showSoldOnly, true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    getStockInventoryDetails(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            warehouse: '',
            stockNumber: formik.values.stockNumber,
            startDateRecorded: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endDateRecorded: moment().format('YYYY-MM-DD'),
            serialNumber: '',
            showSoldOnly: false
        }
    });

    // resetFilteredLicensees();
    formik.submitForm();

}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

export const getStockInventoryDetails = async (values: any) => {

    // alert(JSON.stringify(values));

    try {

        const body = {
            stockNumber: '',
            dateRange: {
                startDate: '',
                endDate: ''
            },
            serialNumber: ''
        }

        body.dateRange.startDate = values.startDateRecorded + 'T00:00:00.000Z';
        body.dateRange.endDate = values.endDateRecorded + 'T23:59:59.999Z';
        body.stockNumber = values.stockNumber;
        body.serialNumber = values.serialNumber;

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/inventory/search`,
            data: body
        }

        const response = await makeHttpCall(options);

        if (response && response.hasOwnProperty('packets') && response.packets.length > 0) {

            // Filter by Warehouse Id
            if (values.warehouse && values.warehouse.toLowerCase() !== 'all') {
                const warehouse = values.warehouse.split('-')[0].trim();
                response.packets = response.packets.filter((stockInventory: any) => stockInventory.warehouseId === warehouse);
            }

            // Filter by 'Sold Only'
            if (values.showSoldOnly) {
                response.packets = response.packets.filter((stockInventory: any) => (!stockInventory.warehouseId && stockInventory.latestPurchaseDate));
            } else {
                response.packets = response.packets.filter((stockInventory: any) => (stockInventory.warehouseId));
            }

        }

        // Deep copy
        setUnfilteredStockInventory(response);
        setFilteredStockInventory(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}
