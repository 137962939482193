import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  PaymentDetailsContainer,
  PaymentDetailsPageProps,
} from '../../../../components/AnnuitiesComponents/payment-details-container'
import { LoadingSpinner } from '../../../../components/loading-spinner'
import config from '../../../../config'
import { BannerContext } from '../../../../context/BannerContext'
import { makeHttpCall } from '../../../../services/MakeHttpCall'

type RouteParams = {
  paymentId: string
}

export const AuthorizedPaymentDetails = () => {
  const { state } = useLocation<PaymentDetailsPageProps>()
  const [props, setProps] = useState<PaymentDetailsPageProps>()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const params = useParams<RouteParams>()
  const bannerContext = useContext(BannerContext)

  useEffect(() => {
    const fetchPaymentDetails = async (id: string) => {
      const url = `${config.SERVER_BASE_URL}/v1/annuities/payment/${id}/details`
      setShowSpinner(true)
      try {
        const paymentDetails = await makeHttpCall({
          method: 'get',
          url,
        }).finally(() => setShowSpinner(false))
        setProps({
          ...state,
          paymentDetails,
        })
      } catch (err) {
        bannerContext.setBannerInfo({ message: `${err}`, error: true })
      }
    }
    fetchPaymentDetails(params.paymentId)
  }, [])

  const history = useHistory()

  return (
    <>
      <LoadingSpinner showSpinner={showSpinner} />
      {props ? <PaymentDetailsContainer {...props} /> : <></>}
    </>
  )
}
