import React, {useState} from "react";
import styles from './css/styles.module.css';
import {convertAmount, downloadClicked, getPageData} from "./functions";

import {claimsSupportRoutePath} from './../../../config.js';
import {useHistory} from "react-router-dom";

import {keyPressedType} from "../../../services/Commons";
import {checkRenderPermissions} from "./../../../helpers";
import {permissions} from "./../../../constants";

import {ExportToCsv} from 'export-to-csv';
import {claimHistory, claimReferenceNumber, claimTransaction, w2g} from "../../../services/StoresObject/Claims";

import {CircularProgress} from "@material-ui/core";

import moment from "moment-timezone";

const array: any = [];
for (let i = 0; i < 105; i++) {
  array.push({
    createdAt: "2021-08-25T17:01:09.706Z",
    firstName: "PlayerNameCharacterCountTestAccount",
    lastName: "PlayerNameCharacterCountTest Account",
        playerId: "2474b81b-3086-4987-9084-866655f53501"
    })
}

export const MobileClaimsActivityTableComponent = ({setIsData, setNetworkError, setFilteredClaims, storesObject, id, FilteredClaims, currentPage, setCurrentPage, setPageMessage}: any) => {

  const [CSVDownloadComplete, setCSVDownloadComplete] = useState<boolean>(true);

    const userpolicies: any = localStorage.getItem('userpolicies');
  const history = useHistory();
    localStorage.setItem('callingPage', history.location.pathname);

  const normilizePage = (totalPages: number, currentPage: number) => {
    const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;
    }

  let CSVoptions = {};

  try {
    CSVoptions = {
      showLabels: true,
      useTextFile: false,
      useBom: true,
      filename: `${FilteredClaims[0].claim.playerFirstName}_${FilteredClaims[0].claim.playerLastName}_Claims_Activity_Export`,
      showTitle: true,
      title: `${FilteredClaims[0].claim.playerFirstName}_${FilteredClaims[0].claim.playerLastName} <${id}> Claims Activity`,
      //useKeysAsHeaders: true,
            headers: ['Date', 'Claim ID', 'Mobile Claim Status', 'Net Payment', 'Ticket Barcode']
    };
  } catch (error) {
    CSVoptions = {
      showLabels: true,
      useTextFile: false,
      useBom: true,
      filename: `Unknown_Player_Claims_Activity_Export`,
      showTitle: true,
      title: `Unknown Player Claims Activity`,
      //useKeysAsHeaders: true,
            headers: ['Date', 'Claim ID', 'Mobile Claim Status', 'Net Payment', 'Ticket Barcode']
    };
  }

  let csvExporter: ExportToCsv = new ExportToCsv(CSVoptions);

    const createPages = (MCAPlayerData: any[], currentPage: any, setCurrentPage: any) => {
    const array: any = [];
    const pagesTotal = Math.ceil(MCAPlayerData.length / 10);

    if (currentPage > 2 && pagesTotal > 5) {

      array.push(
        <>
                    <div aria-live="assertive"
                         tabIndex={0} role='button'
            aria-pressed={currentPage === 0 ? "true" : "false"}
                         style={{backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : ""}}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(0);
              }
                         }
                         }
                         onClick={() => setCurrentPage(0)} className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {1}
          </div>
                    </div>
                    <div>{'...'}</div>
        </>
      );
    }
    if (currentPage <= 2 || pagesTotal <= 5) {

            for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
        array.push(
                    <div aria-live="assertive" tabIndex={0} role='button'
            aria-pressed={currentPage === i ? "true" : "false"}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(i);
              }
                         }
                         }
            onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
          </div>
        );
      }
        } else if (currentPage > 2 && currentPage <= (pagesTotal - 1) - 2) {

            for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
        array.push(
          <div
            aria-live="assertive"
            tabIndex={0}
                        role='button'
            aria-pressed={currentPage === i ? "true" : "false"}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(i);
              }
                        }
                        }
            onClick={() => setCurrentPage(i)}
                        style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                        className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
          </div>
        );
      }
        } else if (currentPage > (pagesTotal - 1) - 2) {
            for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
        array.push(
                    <div aria-live="assertive" tabIndex={0} role='button'
            aria-pressed={currentPage === i ? "true" : "false"}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(i);
              }
                         }
                         }
            onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
                            {i + 1}
                        </div>
          </div>
        );
      }

    }
        if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
      array.push(
        <>
                    <div>{'...'}</div>
                    <div aria-live="assertive" tabIndex={0} role='button'
            aria-pressed={currentPage === pagesTotal ? "true" : "false"}
                         style={{backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : ""}}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(pagesTotal - 1);
              }
                         }
                         }
                         onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['MCA-table-page-box']}>
                        <div className={styles['MCA-table-page-box-text']}>
              {pagesTotal}
            </div>
          </div>
        </>
      );
    }
    if (pagesTotal <= currentPage) {
      setCurrentPage(0);
    }

    return array;

    }


  return (
    <>
            {!CSVDownloadComplete && <div style={{
                position: 'absolute',
            zIndex: 50,
            top: 0,
            left: 0,
            width: "100%",
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="8em" color='inherit' thickness={2}/>
            </div>}
            <div className={styles['MCA-table']}>
                <div className={styles['MCA-table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber === id ? 0 : (currentPage * 10) + 1}-${normilizePage(FilteredClaims.length, currentPage)} of ${FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber === id ? 0 : FilteredClaims.length} results`}</div>
                    <div className={styles['MCA-table-export-button-container']}>
                        <button style={{
                            opacity: (FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber !== id) ? "1" : "0.4",
                            cursor: (FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber !== id) ? "pointer" : "not-allowed"
          }}
                                className={styles['MCA-table-export-button']}
                                onClick={() => (FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber !== id) && downloadClicked(FilteredClaims, setFilteredClaims, storesObject, csvExporter, setCSVDownloadComplete)}>Download
                            Table as CSV
            </button>
          </div>
        </div>

                <div className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-label']}`}>
          <div tabIndex={0}>Date</div>
          <div tabIndex={0}>Claim ID</div>
          <div tabIndex={0}>Mobile Claim Status</div>
          <div tabIndex={0}>Net Payment</div>
          <div tabIndex={0}>Ticket Barcode</div>
        </div>
                <div className={styles['MCA-table-data-body']}>

                    {(FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber !== id) && getPageData(setIsData, setNetworkError, FilteredClaims, storesObject, setFilteredClaims, setPageMessage, currentPage).map((e: any, i: number) =>

                        <div style={{
                  position: "relative",
                            backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)')
                        }} className={`${styles['MCA-table-data-grid']} ${styles['MCA-table-data-data']}`}>
                            {e.claim.status === 'READY_FOR_PAYMENT' && <div style={{
                                position: 'absolute',
                      zIndex: 50,
                      top: 0,
                      left: 0,
                      width: "100%",
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                      backgroundColor: "rgba(0,0,0,0.3)",
                                color: 'white'
                            }}>
                                <CircularProgress size="1em" color='inherit' thickness={2}/>
                            </div>}
                            <div tabIndex={0}
                                 className={styles['MCA-table-data-data-text']}>{moment.tz(e.claim.activeFrom, "America/New_York").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;{moment.tz(e.claim.activeFrom, "America/New_York").format('h:mm:ss a')}</div>
                            <div className={styles['MCA-table-data-data-text']}>
                                {(checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies)) == false) ? e.claim.referenceNumber :
                                    <a tabIndex={0}
                      onKeyPress={(en: any) => {

                                           if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {

                                               storesObject.claimStore.claimTransaction = JSON.parse(JSON.stringify(claimTransaction));
                                               storesObject.claimStore.claimReferenceNumber = JSON.parse(JSON.stringify(claimReferenceNumber));
                                               storesObject.claimStore.claimHistory = JSON.parse(JSON.stringify(claimHistory));
                                               storesObject.claimStore.claimW2G = JSON.parse(JSON.stringify(w2g));
                                               history.push(`${claimsSupportRoutePath}/mobile-claims-details/${window.location.href.split('/')[window.location.href.split('/').length - 1]}/${e.claim.referenceNumber}`)

                        }
                                       }
                                       }
                      onClick={() => {


                                           storesObject.claimStore.claimTransaction = JSON.parse(JSON.stringify(claimTransaction));
                                           storesObject.claimStore.claimReferenceNumber = JSON.parse(JSON.stringify(claimReferenceNumber));
                                           storesObject.claimStore.claimHistory = JSON.parse(JSON.stringify(claimHistory));
                                           storesObject.claimStore.claimW2G = JSON.parse(JSON.stringify(w2g));


                                           history.push(`${claimsSupportRoutePath}/mobile-claims-details/${window.location.href.split('/')[window.location.href.split('/').length - 1]}/${e.claim.referenceNumber}`)

                                       }
                                       } className={styles['mca-claim-table-link']}>{e.claim.referenceNumber}</a>}
                </div>
                            <div tabIndex={0} className={styles['MCA-table-data-data-text']}>{e.claim.status}</div>
                            <div tabIndex={0}
                                 className={styles['MCA-table-data-data-text']}>{convertAmount(e.netPaymentAmountInCents)}</div>
                            <div tabIndex={0} className={styles['MCA-table-data-data-text']}>{e.claim.barcode}</div>
              </div>
                    )}
        </div>
                <div className={styles['MCA-table-data-footer']}>

                    <div aria-live="assertive" tabIndex={0}
                         className={styles['MCA-table-page-label']}>{`Page ${currentPage + 1} of ${FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber === id ? 0 : Math.ceil((FilteredClaims.length / 10)).toString()}`}</div>
                    {(FilteredClaims.length > 0 && FilteredClaims[0].claim.referenceNumber !== id) && createPages(FilteredClaims, currentPage, setCurrentPage)}

        </div>
      </div>


    </>

  );
}
