import moment from 'moment';

export const testDate = async (name:any, setErrorEndDate:any,setErrorStartDate:any,comparingValue:any , valueToCompare:any ) => {   
   
    const startDateForDiff = new Date(comparingValue);
    const endDateForDiff = new Date(valueToCompare);
    const timeDifference = Math.abs(endDateForDiff.getTime() - startDateForDiff.getTime())
            
    if(comparingValue > new Date( new Date().toLocaleDateString()).toISOString().split("T")[0]) 
    {
        name === "SAStartDate" && setErrorStartDate(`The end Date is out of range current date  is ${new Date( new Date().toLocaleDateString() ).toISOString().split("T")[0]}`);
        name === "SAEndDate" && setErrorEndDate(`The end Date is out of range current date  is ${new Date( new Date().toLocaleDateString() ).toISOString().split("T")[0]}`);
        return;
    }
    if(comparingValue === "")
    {
        name === "SAStartDate" && setErrorStartDate(`The end Date is out of range and or is not a valid date `);
        name === "SAEndDate" &&  setErrorEndDate(`The end Date is out of range and or is not a valid date `);
        return;
    }
    if( comparingValue > valueToCompare && name === "SAStartDate")
    {
        setErrorStartDate(`The Start Date has to be less or equal to the End Date!`);
        return;
    }
    if( comparingValue < valueToCompare && name === "SAEndDate")
    {
        setErrorEndDate(`The Start Date has to be less or equal to the End Date!`);
        return;
    }
    setErrorEndDate(undefined);
    setErrorStartDate(undefined);
}



