import React, { useState } from "react"
import { FormPayeeDetails } from "../../../../pages/PaymentsPages/Create5754SplitPage/types"
import { FormikProps } from "formik"
import './styles.scss'
import { DeleteSplitProps } from "../create-5754-split-container/create-5754-split-container-ui"
import { PayeeTypeModalUI } from "../../PaymentDetailsComponent/payment-details-payee-type-ui/payment-details-payee-type-ui"
import { Create5754SplitChildPaymentFormHeaderUI } from "./create-5754-split-child-payment-fields/header/create-5754-split-child-payment-form-header-ui"
import { AddSplitButtonUI } from "./create-5754-split-child-payment-fields/buttons/add-split-button-ui"
import { getDefaultPayeeDetails } from "../../../../pages/PaymentsPages/Create5754SplitPage/use-fetch-5754-split-data"
import { PayeeType } from "../../../../api/payment-details-fetcher/api-types"
import { Create5754SplitChildPaymentFieldUI } from './create-5754-split-child-payment-fields/create-5754-split-child-payment-fields-ui'
import { DeleteSplitConfirmationUI, defaultDeleteSplitProps } from "../create-5754-split-dialogs/delete-split-confirmation/delete-split-confirmation-ui"

const MAX_CHILD_PAYMENTS = 99

function payeeTypeToString(payeeType: PayeeType): string {
  switch(payeeType) {
    case PayeeType.ENTITY: 
      return 'Entity'
    case PayeeType.INDIVIDUAL: 
      return 'Individual'
    default: 
      return 'None'
  }
}

export type ExecuteDeleteSplit = (i: number) => void

export type Create5754SplitChildPaymentsFormUIProps = {
  formikProps: FormikProps<any>
  parentPaymentId: string
  originalGrossInDollars: string
  setSubmitButtonEnabled: (s: boolean) => void
}

const ROOT_CLASS = 'create-5754-split-payee-forms'
export const Create5754SplitChildPaymentsFormUI = (props: Create5754SplitChildPaymentsFormUIProps) => {
  const { 
    parentPaymentId, 
    originalGrossInDollars, 
    formikProps,
    setSubmitButtonEnabled
  } = props 

  // State
  const [ showAddSplitPopup, setShowAddSplitPopup ] = useState<boolean>(false)
  const [ deleteSplitProps, setDeleteSplitProps ] = useState<DeleteSplitProps>(defaultDeleteSplitProps())
  const [ grossPaymentErrorIndex, setGrossPaymentErrorIndex ] = useState<number>(-1)

  // Callbacks
  const executeAddSplit = (newPayeeDetails: FormPayeeDetails) => {
    const newValues = formikProps.values.splits.concat(newPayeeDetails)
    formikProps.setFieldValue('splits', newValues)
  }
  const executeDeleteSplit = (payeeIndex: number) => {
    const beginning = formikProps.values.splits.slice(0, payeeIndex)
    const end = formikProps.values.splits.slice(payeeIndex + 1)
    formikProps.setValues({ splits: [...beginning, ...end] })
  }
  const setShowPayeeTypeModal = (show: boolean) => {
    setShowAddSplitPopup(show)
  }

  // Render
  return (
    <div className={ ROOT_CLASS }>
      {
        formikProps.values.splits.map((child: FormPayeeDetails, i: number) => {
          const title = `Payee #${i + 1} (${payeeTypeToString(child.payeeType)})`
          const key = `split-${i}`

          return (
            <div key={ key }>
              <Create5754SplitChildPaymentFormHeaderUI
                title={ title }
                showDeleteButton={ i > 0 }
                onClickDelete={() => { 
                  setDeleteSplitProps({
                    showPopup: true,
                    childIndex: i
                  })
                }}
              />
              <Create5754SplitChildPaymentFieldUI
                childPayment={ child }
                childIndex={ i }
                totalInDollars={ originalGrossInDollars }
                formikProps={ formikProps }
                showGrossPaymentError={ i === grossPaymentErrorIndex }
                setGrossPaymentIndex={ setGrossPaymentErrorIndex }
                onChange={ formikProps.handleChange}
                setSubmitButtonEnabled={ setSubmitButtonEnabled }
              />
            </div>
          )
        })
      }

      <AddSplitButtonUI
        setShowAddSplitPopup={ setShowAddSplitPopup }
        addSplitButtonEnabled={ formikProps.values.splits.length < MAX_CHILD_PAYMENTS }
      />

      {showAddSplitPopup ? 
        <PayeeTypeModalUI 
          internalPaymentId={ parentPaymentId } 
          setShowPayeeTypeModal={ setShowPayeeTypeModal } 
          addSplit={(payeeType: string, internalPaymentId: string) => {
            const newDetails = getDefaultPayeeDetails(payeeType as PayeeType)
            executeAddSplit(newDetails)
            setShowAddSplitPopup(false)
          }}        
        /> : null
      }

      {deleteSplitProps.showPopup ? 
        <DeleteSplitConfirmationUI 
          dsiplayIndex={ deleteSplitProps.childIndex! + 1 } 
          deleteSplit={() => {
            executeDeleteSplit(deleteSplitProps.childIndex!)
            setDeleteSplitProps((prevState) => {
              return {
                childIndex: prevState.childIndex,
                showPopup: false
              }
            })
          }} 
          setDeleteSplitProps={ setDeleteSplitProps }        
        /> : null
      }
    </div>
  )
}