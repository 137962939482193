import { TaxAdjustmentStatus } from '../../../api/check-run-payments-fetcher/api-types'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { permissions } from '../../../constants'

const taxAdjustmentUrlPrefix = `${config.SERVER_BASE_URL}/api/v1/payments/taxAdjustment`
const taxAdjustmentOnHoldUrlSuffix = `onHold`
const taxAdjustmentPendingUrlSuffix = `pending`

export const useFetchSetTaxAdjustmentStatus = () => {
  const { isLoading, error, fetchUrl } = useFetch()

  const setStatus = (taxAdjustmentId: string, newStatus: TaxAdjustmentStatus) => {
    let url: string
    switch(newStatus) {
      case TaxAdjustmentStatus.ON_HOLD:
        url = `${taxAdjustmentUrlPrefix}/${taxAdjustmentId}/${taxAdjustmentOnHoldUrlSuffix}`
        break
      case TaxAdjustmentStatus.PENDING:
        url = `${taxAdjustmentUrlPrefix}/${taxAdjustmentId}/${taxAdjustmentPendingUrlSuffix}`
        break
      default:
        throw new Error(`cannot update tax adjustment to status ${newStatus}`)
    }
    fetchUrl(url, 'PATCH', {}, undefined, [permissions.CAN_UPDATE_TAX_ADJUSTMENT_STATUS])
  }

  return { isLoading, error, setStatus }
}