import React from "react";
import styles from './css/styles.module.css';
import MenuHomeComponent from "../MenuHomeComponent";
import MenuAdministrationComponent from "../MenuAdministrationComponent";

const MenuContainerComponent = ({props,storesObject}:any) => {
    
    return (
        <>
            <div className={styles['menu-page-container']}>
                <MenuHomeComponent props={props} storesObject={storesObject}/>
                <MenuAdministrationComponent props={props} storesObject={storesObject}/>
            </div>
        </>
    )
};

export default MenuContainerComponent;