import React, {useEffect, useState} from "react";
import styles from "./css/styles.module.css";

import {useFormik} from "formik";
import {
    doPlayerSearchWithIdOrEmail,
    doPlayerSearchWithName,
    doPlayerSearchWithSSN,
    onClearSearch,
    onSubmit,
    setFiledValue,
    setPhoneValue,
    setVariables,
    validate,
} from "./functions";
import {useHistory} from "react-router-dom";
import {
    PageMessageCommonComponent,
    pageMessageDefault,
} from "../../../components/CommonComponents/PageMessageCommonComponent";

import {PlayerSearchResult} from "./interfaces";
import {PlayerSearchTableComponent} from "../../../components/PlayersComponents/PlayerSearchTableComponent";
import {CircularProgress} from "@material-ui/core";
import {PlayerSearchComponent} from "../../../components/PlayersComponents/PlayerSearchComponent";

export const PlayerSearchGenericNewComponentPage = ({id, storesObject}: any) => {
    const playerStore: any = storesObject.playerStore;
    const history = useHistory();
    localStorage.setItem('activeTab', "playerDetails");
    const initialSearchValues = playerStore.getPlayerSearchValues()

    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [PlayerSearchAPIResponse, setPlayerSearchAPIResponse] = useState<PlayerSearchResult[]>(initialSearchValues.searchResults);
    const [buttonClicked, setButtonClicked] = useState<boolean>(false);
    const [phoneNumberError, setPhoneNumberError] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(initialSearchValues.searchPageActive);

    const updateCurrentPage = (pageNumber: number) => {
        setCurrentPage(pageNumber)
        playerStore.setPlayerSearchPage(pageNumber)
    }
     
    const formik = useFormik({
        initialValues: {
            email: initialSearchValues.searchEmailAddress,
            playerId: initialSearchValues.searchPlayerId,
            firstName: initialSearchValues.searchFirstName,
            lastName: initialSearchValues.searchLastName,
            phone: initialSearchValues.searchPhoneNumber,
            socialSecurityNumber: initialSearchValues.searchSSN
        },
        onSubmit,
        validate,
    });

    setVariables(formik, playerStore, history, pageMessageDefault, setPageMessage, setButtonClicked);

    return (
        <>
            {(buttonClicked && pageMessage.message === "") && <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="8em" color='inherit' thickness={2}/>
            </div>}
            <div
                onClick={(e: any) => {
                    pageMessage.messageType !== "" &&
                    setPageMessage({message: "", messageType: ""});
                }}
                className={styles["page-container"]}
            >
                <div className={styles["top-ribbon"]}>
                    <PlayerSearchComponent
                        onClearSearch={onClearSearch}
                        setButtonClicked={setButtonClicked}
                        setPlayerSearchAPIResponse={setPlayerSearchAPIResponse}
                        doPlayerSearchWithName={doPlayerSearchWithName}
                        doPlayerSearchWithIdOrEmail={doPlayerSearchWithIdOrEmail}
                        doPlayerSearchWithSSN={doPlayerSearchWithSSN}
                        formik={formik}
                        setFieldValue={setFiledValue}
                        setPhoneValue={setPhoneValue}
                        phoneError={phoneNumberError}
                        setPhoneError={setPhoneNumberError}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                {pageMessage.message !== "" &&
                    <PageMessageCommonComponent message={pageMessage.message} messageType={pageMessage.messageType}/>}
                <div className={styles["page-container-body-container"]}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-center"]}>
                            {PlayerSearchAPIResponse.length > 0 &&
                                <PlayerSearchTableComponent KYCPlayerData={PlayerSearchAPIResponse}
                                                            currentPage={currentPage}
                                                            setCurrentPage={updateCurrentPage}/>}
                            {PlayerSearchAPIResponse.length <= 0 &&
                                <div className={styles["page-container-body-center-text"]}>
                                    <div><span tabIndex={0}>Enter search criteria above to see players</span></div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
