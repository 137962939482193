import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle,
  faReply,
} from '@fortawesome/free-solid-svg-icons'
import {
  useControls,
} from 'react-zoom-pan-pinch'
import './styles.scss'

export type ImageViewerControlsProps = {
  handleRotateClick: () => void
}

export const ROOT_CLASS = 'image-viewer-controls'

export const ImageViewerControls = (props: ImageViewerControlsProps) => {
  const { zoomIn, zoomOut } = useControls()
  return (
    <div className={ROOT_CLASS}>
      <button
        aria-label={'Rotate 90 degrees.'}
        onClick={props.handleRotateClick}
      >
        <FontAwesomeIcon
          role="img"
          aria-label="faReply icon"
          icon={faReply}
        />
      </button>
      <div className={`${ROOT_CLASS}__zoom-container`}>
        <button
          onClick={()=> zoomIn()}
          style={{ marginRight: '.2em' }}
          aria-label={'Zoom in'}
        >
          <FontAwesomeIcon role="img" icon={faPlusCircle} />
        </button>
        <button
          onClick={()=> zoomOut()}
          aria-label={'Zoom out'}
        >
          <FontAwesomeIcon role="img" icon={faMinusCircle} />
        </button>
      </div>
    </div>
  )}