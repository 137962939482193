import React, { useContext, useState, useRef, useEffect } from 'react'
import { BannerContext } from "../../../context/BannerContext"
import { useFetchGetFuturePaymentPayeeDetails, useFetchUpdateFuturePaymentPayeeDetails } from './use-fetch-future-payment-payee-details'
import { useHistory } from 'react-router-dom'
import { GetFuturePaymentPayeeDetailsReponse, UpdateFuturePaymentPayeeDetailsRequest } from '../../../api/future-payment-payee-details-fetcher/api-types'
import { PayeeType } from '../../../api/payment-details-fetcher/api-types'
import { getMatchingKnownCountry } from "../../../Constants/Countries"
import { omit } from 'lodash'
import { FuturePaymentDetailsUpdatePageUI } from './future-payment-details-update-page-ui'

export type FuturePaymentDetailsUpdatePageContainerProps = {
  futurePaymentId: string
}

type FuturePaymentDetailsUpdateFormFieldsIndividual = Omit<UpdateFuturePaymentPayeeDetailsRequest, 'payeeFreeformName'> & {
  payeeType: PayeeType.INDIVIDUAL
}
type FuturePaymentDetailsUpdateFormFieldsEntity = Omit<UpdateFuturePaymentPayeeDetailsRequest, 'payeeFirstName' | 'payeeMiddleInitial' | 'payeeLastName'> & {
  payeeType: PayeeType.ENTITY
}

export type FuturePaymentDetailsUpdateFormFields = FuturePaymentDetailsUpdateFormFieldsIndividual | FuturePaymentDetailsUpdateFormFieldsEntity

const mapPayeeDetailsToForm = (payeeDetails?: GetFuturePaymentPayeeDetailsReponse, ): FuturePaymentDetailsUpdateFormFields => {
  if (!payeeDetails) {
    return {
      payeeFreeformName: '',
      tin: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      payeeType: PayeeType.ENTITY
    }
  }

  const formDetailsBase = {
    tin: payeeDetails.tin ? payeeDetails.tin : undefined,
    address1: payeeDetails.address1,
    address2: payeeDetails.address2 ? payeeDetails.address2 : undefined,
    city: payeeDetails.city,
    state: payeeDetails.state,
    zip: payeeDetails.zip,
    country: getMatchingKnownCountry(payeeDetails.country),
    supplementalAddress1: payeeDetails.supplementalAddress1 ? payeeDetails.supplementalAddress1 : undefined,
    supplementalAddress2: payeeDetails.supplementalAddress2 ? payeeDetails.supplementalAddress2 : undefined,
    phone: payeeDetails.phone ? payeeDetails.phone : undefined,
    email: payeeDetails.email ? payeeDetails.email : undefined
  }

  if (payeeDetails.payeeIsEntity) {
    return {
      ...formDetailsBase,
      payeeType: PayeeType.ENTITY,
      payeeFreeformName: payeeDetails.payeeFreeformName ? payeeDetails.payeeFreeformName : ''
    }
  }

  return {
    ...formDetailsBase,
    payeeType: PayeeType.INDIVIDUAL,
    payeeFirstName: payeeDetails.payeeFirstName ? payeeDetails.payeeFirstName : '',
    payeeMiddleInitial: payeeDetails.payeeMiddleInitial ? payeeDetails.payeeMiddleInitial : undefined,
    payeeLastName: payeeDetails.payeeLastName ? payeeDetails.payeeLastName : '',
  }
}

export const FuturePaymentDetailsUpdatePageContainer = (
  props: FuturePaymentDetailsUpdatePageContainerProps
) => {
  const { futurePaymentId } = props

  const {
    isLoading: getLoading,
    error: getError,
    data: existingPayeeDetails
  } = useFetchGetFuturePaymentPayeeDetails(futurePaymentId)

  const {
    isLoading: updateLoading,
    error: updateError,
    requestSuccessful: updateSuccessful,
    updateFuturePaymentPayeeDetails
  } = useFetchUpdateFuturePaymentPayeeDetails(futurePaymentId)

  const history = useHistory()
  const bannerContext = useContext(BannerContext)

  const [showSubmitPopup, setShowSubmitPopup] = useState<boolean>(false)
  const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false)
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false)

  const submittedPayeeDetails = useRef<FuturePaymentDetailsUpdateFormFields | undefined>()

  const submitUpdate = async (formFields: FuturePaymentDetailsUpdateFormFields) => {
    // convert empty optional fields to undefined
    const requestFields = {
      ...formFields,
      tin: formFields.tin ? formFields.tin : undefined,
      address2: formFields.address2 ? formFields.address2 : undefined,
      supplementalAddress1: formFields.supplementalAddress1 ? formFields.supplementalAddress1 : undefined,
      supplementalAddress2: formFields.supplementalAddress2 ? formFields.supplementalAddress2 : undefined,
      phone: formFields.phone ? formFields.phone : undefined,
      email: formFields.email ? formFields.email : undefined
    }

    submittedPayeeDetails.current = { ...requestFields }
    await updateFuturePaymentPayeeDetails(omit(requestFields, ['payeeType']))
  }

  const cancelUpdate = () => {
    history.goBack()
  }

  useEffect(() => {
    if (updateSuccessful) {
      bannerContext.setBannerInfo({ message: 'The future payment details have been updated.', error: false })
      history.goBack()
    } else if (!updateLoading && updateError) {
      setShowErrorPopup(true) // TODO might want to have a specific error message if it's an SSN issue with PENDING payment
    }
  }, [updateLoading])

  return (
    <FuturePaymentDetailsUpdatePageUI
      payment={submittedPayeeDetails.current ?? mapPayeeDetailsToForm(existingPayeeDetails)}
      getLoading={getLoading}
      updateLoading={updateLoading}
      getError={getError}
      showSubmitPopup={showSubmitPopup}
      setShowSubmitPopup={setShowSubmitPopup}
      showCancelPopup={showCancelPopup}
      setShowCancelPopup={setShowCancelPopup}
      showErrorPopup={showErrorPopup}
      setShowErrorPopup={setShowErrorPopup}
      submitFuturePaymentUpdate={submitUpdate}
      cancelFuturePaymentUpdate={cancelUpdate}
    />
  )
}
