import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { Agent1099SweepPaymentsResponse } from './api-types'

export const getFetchAgent1099SweepPayments = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<Agent1099SweepPaymentsResponse>()
  const fetchPaymentsForSweep = (sweepId: string) => {
    fetchUrl(`${config.SERVER_BASE_URL}/api/v1/agent1099Queue/sweep/${sweepId}`)
  }

  return {
    isLoading,
    error,
    data,
    fetchPaymentsForSweep
  }
}
