import {
  FuturePaymentGame,
  FuturePaymentSource,
  FuturePaymentStatus,
} from './api-types'

function isValueInEnum<T extends { [key: string]: unknown }>(collection: T) {
  return (value: unknown): value is T[keyof T] =>
    Object.values(collection).includes(value as T[keyof T])
}

export const isFuturePaymentStatus = isValueInEnum(FuturePaymentStatus)

export const isFuturePaymentSource = isValueInEnum(FuturePaymentSource)

export const isFuturePaymentGame = isValueInEnum(FuturePaymentGame)
