import { makeHttpCall } from './../../../../services/MakeHttpCall';
import config from './../../../../config.js';
import moment from "moment-timezone";

const userpolicies: any = localStorage.getItem('userpolicies');

function swap(items: any, leftIndex: any, rightIndex: any) {
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
}

function partition(items: any, left: any, right: any) {

    var pivot = items[Math.floor((right + left) / 2)], //middle element
        i = left, //left pointer
        j = right; //right pointer

    while (i <= j) {

        while (items[i].createdAt > pivot.createdAt) {
            i++;
        }

        while (items[j].createdAt < pivot.createdAt) {

            j--;
        }
        if (i <= j) {
            swap(items, i, j); //swap two elements
            i++;
            j--;
        }
    }
    return i;
}

function quickSort(items: any, left: any, right: any) {

    var index;
    if (items.length > 1) {
        index = partition(items, left, right); //index returned from partition
        if (left < index - 1) { //more elements on the left side of the pivot
            quickSort(items, left, index - 1);
        }
        if (index < right) { //more elements on the right side of the pivot
            quickSort(items, index, right);
        }
    }
    return items;
}


const getPlayerSessionActivityArray: any = [];
export const getPlayerSessionActivity = async (setPlayerSessionActivityData: any, setIsDataPerformedSession: any, setPageMessage: any, pageMessage: any, storesObject: any, id: any) => {


    const pointer = setTimeout(async () => {

        try {

            let identifier = '1698d2cc-162a-4a31-a59e-3d4241f3de05';
            identifier = id;

            const startDate = moment().subtract(31, 'days');
            const momentStartDate = moment(startDate).format('YYYY-MM-DD');

            // today
            const endDate = new Date();
            const momentEndDate = moment(endDate).format('YYYY-MM-DD')+':23:59:59';

            let queryParams = '?';
            queryParams += `queryDateRangeStart=${momentStartDate}`
            if (queryParams.endsWith("?"))
                queryParams += `queryDateRangeEnd=${momentEndDate}`
            else
                queryParams += `&queryDateRangeEnd=${momentEndDate}`

            const options = {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/player/${identifier}/player-events${queryParams}`
            }

            const response: any = await makeHttpCall(options);

            if (localStorage.getItem("PlayersName") !== `${storesObject.playerStore.selectedPlayerDetails.identity.firstName} ${storesObject.playerStore.selectedPlayerDetails.identity.lastName}`)
                localStorage.setItem("PlayersName", `${storesObject.playerStore.selectedPlayerDetails.identity.firstName} ${storesObject.playerStore.selectedPlayerDetails.identity.lastName}`);

            setIsDataPerformedSession({ value: true });

            const defaultData: any = {
                id: identifier,
            }

            const data: any = response.data;

            if (response.data.length <= 0) {
                storesObject.playerStore.playerEvents = [];
                storesObject.playerStore.playerEvents.push(defaultData);
            }
            else
                storesObject.playerStore.playerEvents = response.data;

            setPlayerSessionActivityData(data.slice(0, 5));

            if (pageMessage.messageType === "ERROR")
                setPageMessage({ message: "", messageType: "" });

            getPlayerSessionActivityArray.map((e: any) => {
                clearTimeout(e);
            });

        } catch (e) {

            setIsDataPerformedSession({ value: false });
            const er:any = e;
            if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
                if(value === "Network")
                  return value
            }) !== undefined)
            {
               setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
            }else
              setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
          
            getPlayerSessionActivityArray.map((e: any) => {
                clearTimeout(e);
            });

        }
    }, 800);
    getPlayerSessionActivityArray.push(pointer);

}

const getPlayerMobileClaimsActivityArray: any = [];
export const getPlayerMobileClaimsActivity = async (setPlayerMobileClaimsActivityData: any, setIsDataPerformedMobile: any, setPageMessage: any, pageMessage: any, storesObject: any, id: any) => {

    let identifier = id;

    const pointer = setTimeout(async () => {
        try {

            const options = {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/player/${identifier}/claims`
            }
             
            
            const response: any = await makeHttpCall(options);

            setIsDataPerformedMobile({ value: true });

            const data: any = response.claims;

            const defaultData: any = {
                activeFrom: "",
                referenceNumber: identifier,

            }       

            if (response.claims.length <= 0) {
                storesObject.claimStore.claims = [];
                storesObject.claimStore.claims.push({ claim: defaultData });
            }
            else
                storesObject.claimStore.claims = response.claims;

            setPlayerMobileClaimsActivityData(data.slice(0, 5));

            if (pageMessage.messageType === "ERROR")
                setPageMessage({ message: "", messageType: "" });


            getPlayerMobileClaimsActivityArray.map((e: any) => {
                clearTimeout(e);
            });
        } catch (e) {
            setIsDataPerformedMobile({ value: false });
            const er:any = e;
            if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
                if(value === "Network")
                  return value
            }) !== undefined)
            {
               setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
            }else
              setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
          
            getPlayerMobileClaimsActivityArray.map((e: any) => {
                clearTimeout(e);
            });

        }
    }, 800);
    getPlayerMobileClaimsActivityArray.push(pointer);
}

const getPlayerTicketScanningActivityDataArray: any = [];
export const getPlayerTicketScanningActivityData = async (setPlayerTicketScanningActivityData: any, setIsDataPerformedTicket: any, setPageMessage: any, pageMessage: any, storesObject: any, id: any) => {


    const pointer = setTimeout(async () => {
        try {

            let identifier = '1698d2cc-162a-4a31-a59e-3d4241f3de05';
            identifier = id;

            // 31 days ago
            const startDate = moment().subtract(31, 'days');
            const momentStartDate = moment(startDate).format('YYYY-MM-DD');

            // today
            const endDate = new Date();
            const momentEndDate = moment(endDate).format('YYYY-MM-DD')+':23:59:59';

            let queryParams = '?';
            queryParams += `queryDateRangeStart=${momentStartDate}`
            if (queryParams.endsWith("?"))
                queryParams += `queryDateRangeEnd=${momentEndDate}`
            else
                queryParams += `&queryDateRangeEnd=${momentEndDate}`

            const options = {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/player/${identifier}/ticket-scanner-events/${queryParams}`
            }

            const response: any = await makeHttpCall(options);
            setIsDataPerformedTicket({ value: true });
            const data: any = response.data.slice(0, 10);
            setPlayerTicketScanningActivityData(data);



            const defaultData: any = {
                gameId: identifier,
            }

            if (response.data.length <= 0) {
                storesObject.playerStore.ticketScannerEvents = [];
                storesObject.playerStore.ticketScannerEvents.push(defaultData);
            }
            else
                storesObject.playerStore.ticketScannerEvents = response.data;



            getPlayerTicketScanningActivityDataArray.map((e: any) => {
                clearTimeout(e);
            });
            if (pageMessage.messageType === "ERROR")
                setPageMessage({ message: "", messageType: "" });
        } catch (e) {
            setIsDataPerformedTicket({ value: false });
            const er:any = e;
            if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
                if(value === "Network")
                  return value
            }) !== undefined)
            {
               setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
            }else
              setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
          
            getPlayerTicketScanningActivityDataArray.map((e: any) => {
                clearTimeout(e);
            });
        }
        getPlayerTicketScanningActivityDataArray.push(pointer);
    }, 800);

}