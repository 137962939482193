export const InputValidationMessages = {
    RequiredField: 'This field is required',
    InvalidEmail: 'Invalid Email',
    InvalidTelephone: 'Invalid Telephone',
    InvalidZip: 'Invalid Zipcode',
    InvalidRoutingNumber: 'Invalid Routing Number',
    InvalidCityCode: 'Invalid City Code',
    InvalidOrgType: 'Invalid Org. Type',
    InvalidOrgStatus: 'Invalid Org. Status',
    InvalidInvestigatorID: 'Invalid Investigator ID',
    InvalidCountyID: 'Invalid County ID',
    InvalidCityID: 'Invalid City/Town ID',
    InvalidTypeID: 'Invalid Type ID',
    InvalidDOI: 'Date must be different from current set date of issuance.',
    InvalidUnitWeight: 'Invalid Unit Weight',
    InvalidCaseWeight: 'Invalid Case Weight',
    InvalidDateRange: 'Invalid Date Range, Max 365 Days.',
    NegativeDateRange: 'Invalid Date Range.',
    InvalidBarcode: 'Invalid Barcode',
    BarcodeNotfound: 'Barcode cannot be found. Please scan or enter a correct barcode number.',
    BarcodeInvalidOrder: 'Barcode must be from the selected order.',
    BarcodeInvalidWarehouse: 'Barcode must be from the selected warehouse.',
    BarcodeIneligibleOrder: 'Barcode ineligible for pulltab order',
    BarcodeIneligibleReturn: 'Barcode ineligible for pulltab return',
    BarcodeIneligibleTransfer: 'Barcode ineligible for pulltab transfer',
    BarcodeAlreadyInSystem: 'Packet already in the system',
    InvalidLicenseNumberLength: 'License Number must be at least 3 digits long',
    InvalidRaffleIdLength: 'Raffle Id must be at least 3 digits long',
    InvalidYearValue: 'Invalid Year',
    InvalidSSNValue: 'Invalid SSN',
    InvalidOrderNetWeight: 'This order exceeds the max net weight. Remove barcodes until under 99 lbs.',
    BarcodeInvalidSerialNumbersRange: 'Serial numbers in range do not match',
    BarcodeWeightExceeded: 'Scanned barcode exceeds the weight limit',
    BarcodeScanGenericError: 'Server error',
    PaymentSearchWithAnnuityID: 'Source must be Annuities'
}