import * as React from 'react'
import { GenericPopupModal } from '../../../../GenericPopupModal/generic-popup-modal-ui'
import { LoadingDots } from '../../../../CommonComponents/loading-dots/loading-dots-ui'
import './styles.scss'

const ROOT_CLASS = 'end-campaign-popup'
const TEXT_CLASS = `${ROOT_CLASS}__text`
const POPUP_BUTTON_CLASS = `${ROOT_CLASS}__buttons`

export type CampaignsEndCampaignPopupProps = {
  endCampaignIsOpen: boolean
  error?: string
  submitEndCampaignLoading: boolean
  closeModal: () => void
  submitEndCampaign: () => Promise<void>
}

export const CampaignsEndCampaignPopup = (
  props: CampaignsEndCampaignPopupProps
) => (
  <GenericPopupModal
    isOpen={props.endCampaignIsOpen}
    closeModal={props.closeModal}
    roundedEdges={true}
  >
    <div className={ROOT_CLASS}>
      <header>Are You Sure?</header>
      <div className={TEXT_CLASS}>
        This is permanent. You cannot undo this once it is done.
      </div>
      {props.error && (
        <div className={`${TEXT_CLASS}__error-text`}>
          Error ending campaign: "{props.error}"
        </div>
      )}
      <div className={`${POPUP_BUTTON_CLASS}`}>
        {props.submitEndCampaignLoading ? (
          <div className={`${POPUP_BUTTON_CLASS}__loading`}>
            <LoadingDots id="loading" />
          </div>
        ) : (
          <>
            <button
              type="button"
              className={`${POPUP_BUTTON_CLASS}__cancel-button`}
              onClick={props.closeModal}
              aria-label="Cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`${POPUP_BUTTON_CLASS}__submit-button`}
              onClick={props.submitEndCampaign}
              aria-label="Submit"
            >
              Yes I'm Sure
            </button>
          </>
        )}
      </div>
    </div>
  </GenericPopupModal>
)
