import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';


import {
  getKYCPlayerDocumentQueue,
} from "./functions";
import stylesMessageBox from './css/stylesMessageBox.module.css';
import { CircularProgress } from "@material-ui/core";
import { KYCQueueTableComponent } from './../../../components/ActionsComponent/KYCQueueTableComponent';
import { KYCDocumentTypeQueueInterface } from "./interface";
import { useHistory } from "react-router-dom";
import { actionsRoutePath } from './../../../config.js';
import eventEmitter from './../../../services/EventEmitter';
import { keyPressedType } from "../../../services/Commons";
import { PageMessageCommonComponent, pageMessageDefault } from "../../../components/CommonComponents/PageMessageCommonComponent";

let eventEmitterInitialize: any = undefined;
const timerArray: any = [];
export const KYCQUEUEPage = ({ storesObject }: any) => {

  const playersStore = storesObject.playerStore;
  const defaultData: KYCDocumentTypeQueueInterface[] = [];

  const [isData, setIsData] = useState(false);
  const [KYCPlayerData, setKYCPlayerData] = useState<KYCDocumentTypeQueueInterface[]>(defaultData);
  const [requestPerformed, setRequestPerformed] = useState<any>(false);
  const [manuallyApprovedKYC, setManuallyApprovedKYC] = useState<boolean>(false);
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const history: any = useHistory();


  localStorage.setItem('callingPage', history.location.pathname);

  const sleep = (ms: any) => {

    const timer = setTimeout((timerArray: any) => {
      timerArray.map((e: any) => clearTimeout(e));
      setRequestPerformed(false);

    }, ms, timerArray)
    timerArray.push(timer)

  }

  useEffect(() => {

    if (localStorage.getItem('ManualKYCApproved') === 'true') {
      setManuallyApprovedKYC(true);
      localStorage.setItem('ManualKYCApproved', '');
    }
    document.getElementById('manually-approve-kyc-container')?.focus();
    if (KYCPlayerData.length <= 0 && !isData && requestPerformed === false) {
      setNetworkError(false);
      setIsData(false);
      getKYCPlayerDocumentQueue(setKYCPlayerData, setIsData, setPageMessage, pageMessage, setNetworkError);
      document.getElementById("page-container")?.focus();

    }
   
    if (networkError && isData) {
      setRequestPerformed(true);
      setIsData(false);
      setNetworkError(false);
      sleep(5000);

    }
  });


  return (
    <>

      {((!isData && pageMessage.message === "") || (networkError && !isData && pageMessage.message === "")) && <div style={{ position: 'fixed', top: 0, left: 0, width: "100vw", height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
        <CircularProgress size="8em" color='inherit' thickness={2} />
      </div>}
      <div onClick={(e: any) => { pageMessage.message !== "" && setPageMessage({ message: "", messageType: "" }) }} id="page-container" className={styles['page-container']}>
        <div className={styles['top-ribbon']}>
          <div className={styles['top-ribbon-text']} tabIndex={0}>Player Document Queue</div>

        </div>
        {pageMessage.message !== "" && <PageMessageCommonComponent message={pageMessage.message} messageType={pageMessage.messageType} />}
        <div className={styles['page-container-body-container']}>
          <div className={styles['page-container-body']}>
            <KYCQueueTableComponent KYCPlayerData={KYCPlayerData} storesObject={storesObject} />
          </div>
        </div>
      </div>
      {manuallyApprovedKYC &&

        <div className={stylesMessageBox['window-container']}>
          <div id='manually-approve-kyc-container' className={stylesMessageBox['manually-approve-kyc-container']}>

            <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-text']}>KYC Approved!</div>
            <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-message']}>The player now is able to use their Mass Lottery Player Account, and will receive an
              email confirmation that their information was updated
            </div>
            <br />
            <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-message']}>
              If the email address was updated, the player will receive a 6-digit verification code to the new email address immediately.
            </div>
            <div role='button'
              tabIndex={0}
              onKeyPress={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                  setManuallyApprovedKYC(false)
                }
              }
              }
              onClick={() => setManuallyApprovedKYC(false)}
              className={stylesMessageBox['manually-approve-kyc-button-yes']} >
              <div className={stylesMessageBox['manually-approve-kyc-yes-text']}>Close</div>
            </div>

          </div>
        </div>}
    </>

  );
}

