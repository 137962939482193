import config from "../../../../../config.js";
import { makeHttpCall } from "../../../../../services/MakeHttpCall";
import { Payment } from "../../../../../types/Annuities/entities/payment";
import { SubAccount } from "../../../../../types/Annuities/entities/sub-account";
import { TpaPayeePaymentInfo } from "./types";

// Takes in the number of cents and returns a currency string
export const getCurrencyString = (currencyInteger: number) => {
  // Format currency strings
  const currencyFormatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  });

  const currency = currencyFormatter.format(currencyInteger / 100);

  return currency;
};

export const defaultTpaPayeePaymentInfo: TpaPayeePaymentInfo = {
  paymentsMade: 0,
  paymentsPending: 0,
  grossAmountPaid: "Loading...",
  netAmountPaid: "Loading...",
  irsWithheld: "Loading...",
  dorWithheld: "Loading...",
  offsetsWithheld: "Loading...",
  remainingBalance: "Loading...",
};

// GET PlaceHolder TPA Payee Payment Info
export const getTpaPlaceholderPayeePaymentInfo = (): TpaPayeePaymentInfo => {
  const placeholderTempSubAccountProps = {
    ...defaultTpaPayeePaymentInfo,
  };

  return placeholderTempSubAccountProps;
};

export const getTpaPayeePaymentInfo = (): TpaPayeePaymentInfo => {
  let tpaPayeePaymentInfo: TpaPayeePaymentInfo;

  tpaPayeePaymentInfo = defaultTpaPayeePaymentInfo;

  return tpaPayeePaymentInfo;
};

// GET Sub Accounts For Payee
export const getTpaPayeeSubAccounts = async (
  tpaId: string,
  payeeId: string
): Promise<SubAccount[]> => {
  let subAccountList: SubAccount[] = [];

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaId}/payee/${payeeId}/sub-account`,
  };

  try {
    subAccountList = await makeHttpCall(options);
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong searching for Sub-Accounts");
  }

  return subAccountList;
};

// GET Payments For Sub Account
export const getTpaPayeeSubAccountsPayments = async (
  subAccountId: string
): Promise<Payment[]> => {
  let subAccountPaymentList: Payment[] = [];

  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/sub-account/${subAccountId}/payment`,
  };

  try {
    subAccountPaymentList = await makeHttpCall(options);
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong searching for sub-account payments");
  }

  return subAccountPaymentList;
};
