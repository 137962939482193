import * as React from 'react'
import { getPageData } from './functions'
import { playerSupportRoutePath } from './../../../config.js'
import { useHistory } from 'react-router-dom'
import { keyPressedType } from '../../../services/Commons'
import { PaginationCommonComponent } from '../../CommonComponents/PaginationCommonComponent'
import infoIcon from '../../../icons/exclamation-circle.svg'
import ErrorInfoIcon from '../../../icons/exclamation-circle-error.svg'
import KYCDeniedIcon from '../../../icons/icon_denied.svg'
import styles from './css/styles.module.css'

import { PlayerSearchInfoMessageComponent } from '../../CommonComponents/PlayerSearchInfoMessageComponent'
import { getPlayersDetailsInterface } from '../../../pages/PlayersPages/UpdateProfilePage/interfaces'

export const PlayerSearchTableComponent = ({
  KYCPlayerData,
  currentPage,
  setCurrentPage,
}: any) => {
  const history = useHistory()
  const [showError, setShowError] = React.useState({
    show: false,
    rowNumber: 0,
  })
  const normilizePage = (totalPages: number, currentPage: number) => {
    const number: number = totalPages - currentPage * 10
    if (number > 10) return currentPage * 10 + 10
    else return totalPages
  }

  const onPress = (e: getPlayersDetailsInterface | any) =>
    history.push(
      `${playerSupportRoutePath}/player-hub-new/${
        e.identity?.playerId === '' ? e.account.id : e.identity.playerId
      }`
    )

  return (
    <>
      <div className={styles['KYCQUEUE-table']}>
        <div className={styles['KYCQUEUE-table-data-header']}>
          <div aria-live="assertive" tabIndex={0}>{`Displaying ${
            KYCPlayerData.length <= 0 ? 0 : currentPage * 10 + 1
          }-${normilizePage(KYCPlayerData.length, currentPage)} of ${
            KYCPlayerData.length
          } results`}</div>
        </div>

        <div
          className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}
        >
          <div tabIndex={0}></div>
          <div tabIndex={0}>Email</div>
          <div tabIndex={0}>First Name</div>
          <div tabIndex={0}>Last Name</div>
          <div tabIndex={0}>Player ID</div>
        </div>
        <div className={styles['KYCQUEUE-table-data-body']}>
          {KYCPlayerData.length > 0 &&
            getPageData(KYCPlayerData, currentPage).map(
              (e: getPlayersDetailsInterface | any, i: number) => (
                <div
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)',
                  }}
                  className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}
                >
                  {(e.identity.identityCheckResult === '' &&
                    e.identity.status === '') || // when the identity key has a value of null in the API response, valid Condition For Account Not Created
                  e.identity.identityCheckResult === 'FAIL' || // Condition For KYC Denied
                  ((e.identity.status === 'NO_RECORD' ||
                    e.identity.status === 'NO_IDENTITY') && // Condition For Account Not Created
                    (e.identity.identityCheckResult === '' ||
                      e.identity.identityCheckResult === null)) ? (
                    <div
                      tabIndex={0}
                      className={styles['KYCQUEUE-table-data-data-text']}
                      onMouseEnter={() => {
                        setShowError({ show: true, rowNumber: i })
                      }}
                      onMouseLeave={() => {
                        setShowError({ show: false, rowNumber: i })
                      }}
                    >
                      <img
                        src={infoIcon}
                        className={styles['menu-exaclamation-icon']}
                        alt={'infoIcon'}
                      />

                      {showError.show && showError.rowNumber === Number(i) ? (
                        <PlayerSearchInfoMessageComponent
                          KYCDenied={
                            e.identity.identityCheckResult === 'FAIL' ? (
                              <div
                                style={{ marginTop: '.7em' }}
                                className={styles['pop-up-status-message']}
                              >
                                <div className={styles['error-header-row']}>
                                  <img
                                    src={KYCDeniedIcon}
                                    className={
                                      styles['menu-administration-icon']
                                    }
                                    alt={'infoIcon'}
                                  />
                                  <div className={styles['error-header']}>
                                    KYC Denied
                                  </div>
                                </div>
                                <div className={styles['error-description']}>
                                  This player cannot sign in until the identity
                                  has been manually approved.
                                </div>
                              </div>
                            ) : null
                          }
                          AccountNotCreated={
                            (e.identity.identityCheckResult === '' &&
                              e.identity.status === '') ||
                            ((e.identity.status === 'NO_RECORD' ||
                              e.identity.status === 'NO_IDENTITY') &&
                              (e.identity.identityCheckResult === '' ||
                                e.identity.identityCheckResult === null)) ? (
                              <div
                                style={{ marginBottom: '.7em' }}
                                className={styles['pop-up-status-message']}
                              >
                                <div className={styles['error-header-row']}>
                                  <img
                                    src={ErrorInfoIcon}
                                    className={
                                      styles['menu-administration-icon']
                                    }
                                    alt={'infoIcon'}
                                  />
                                  <div className={styles['error-header']}>
                                    Account Not Created
                                  </div>
                                </div>
                                <div className={styles['error-description']}>
                                  An account does not exist for this player
                                </div>
                              </div>
                            ) : null
                          }
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div />
                  )}
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-email-text']}
                    onKeyPress={(en: any) => {
                      if (
                        keyPressedType(en) === 13 ||
                        keyPressedType(en) === 0 ||
                        keyPressedType(en) === 32
                      ) {
                        onPress(e)
                      }
                    }}
                    onClick={() => {
                      onPress(e)
                    }}
                  >
                    {e.account.email}
                  </div>
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-text']}
                  >
                    {e.identity.firstName}
                  </div>
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-text']}
                  >
                    {e.identity.lastName}
                  </div>
                  <div
                    tabIndex={0}
                    className={styles['KYCQUEUE-table-data-data-text']}
                  >
                    {e.identity.playerId}
                  </div>
                </div>
              )
            )}
        </div>
        <div className={styles['KYCQUEUE-table-data-footer']}>
          <div
            aria-live="assertive"
            tabIndex={0}
            className={styles['KYCQUEUE-table-page-label']}
          >{`Page ${currentPage + 1} of ${Math.ceil(
            KYCPlayerData.length / 10
          ).toString()}`}</div>
          {
            <PaginationCommonComponent
              playerData={KYCPlayerData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        </div>
      </div>
    </>
  )
}
