import React from 'react'
import {
  CheckRunReportListEntry,
  ListCheckRunReportsResponse,
  PreSignedUrlResponse,
  PreviewReportProps,
} from '../../../api/check-run-reporting-fetcher/api-types'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { CheckRunReportDisplayRow } from './check-run-reporting-page-ui'

const getCheckRunBaseUrl = `${config.SERVER_BASE_URL}/api/v1/checkRun`

export const useFetchPreviewReport = () => {
  const fetchReport = useFetch<CheckRunReportListEntry>()

  const fetchPreviewReport = async (props: PreviewReportProps) => {
    const url = `${getCheckRunBaseUrl}/previewReport`
    const reqBody = {
      reportType: props.reportName,
      generatedBy: props.generatedBy,
    }
    await fetchReport.fetchUrl(url, 'POST', {}, JSON.stringify(reqBody))
  }

  return {
    fetchReportIsLoading: fetchReport.isLoading,
    fetchReportError: fetchReport.error,
    fetchReportData: fetchReport.data,
    fetchPreviewReport,
  }
}
export const useFetchCheckRunReports = () => {
  const fetchCheckRunReports = useFetch<ListCheckRunReportsResponse>()

  const fetchCheckRun = () => {
    const url = `${getCheckRunBaseUrl}/reports`
    fetchCheckRunReports.fetchUrl(url, 'GET', {})
  }
  React.useEffect(() => {
    fetchCheckRun()
  }, [])

  const fetchPreSignedUrl = useFetch<PreSignedUrlResponse>()
  const preSignedUrlHandler = async (row: CheckRunReportDisplayRow) => {
    const urlWithParams = `${getCheckRunBaseUrl}/reportUrl?id=${row.id}`
    const preSignedUrl = await fetchPreSignedUrl.fetchUrl(urlWithParams, 'GET')
    if (!preSignedUrl) {
      return
    }
    saveCheckRunReport(preSignedUrl.url)
  }

  const saveCheckRunReport = (url: string) => {
    const fileLink = document.createElement('a')
    fileLink.href = url
    fileLink.click()
    fileLink.remove()
  }

  return {
    fetchCheckRunReportsIsLoading:
      fetchCheckRunReports.isLoading || fetchPreSignedUrl.isLoading,
    fetchCheckRunReportsError:
      fetchCheckRunReports.error ?? fetchPreSignedUrl.error,
    fetchCheckRunReportsData: fetchCheckRunReports.data,
    fetchCheckRun,
    preSignedUrlHandler,
  }
}
