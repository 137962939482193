export type CheckRunHistoryResponse = {
  historicalCheckRuns: HistoricalCheckRun[]
}

export type HistoricalCheckRun = {
  id: string
  initiatedDate: string
  status: CheckRunStatus
  totalGrossPaymentInCents: number
  reportablePaymentDate: string
}

export enum CheckRunStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR_PLAYER_CSV = 'ERROR_PLAYER_CSV',
  ERROR_TAXABLE_PLAYER_CSV = 'ERROR_TAXABLE_PLAYER_CSV',
  ERROR_NON_TAXABLE_PLAYER_CSV = 'ERROR_NON_TAXABLE_PLAYER_CSV',
  ERROR_TAX_ADJUSTMENTS = 'ERROR_TAX_ADJUSTMENTS',
  ERROR_UPLOAD = 'ERROR_UPLOAD',
  ZIP_GENERATED = 'ZIP_GENERATED',
}
