import {makeHttpCall} from './../../../services/MakeHttpCall';
import config from './../../../config.js';
import moment from 'moment';

export const ClaimStatus: any = {

    ERROR_HOST_REJECTED: "Validation Failed - Ineligible Ticket",
    ATTEMPTING_TO_VALIDATE: "In Progress - Claim Attempting to validate",
    ERROR_ALREADY_VALIDATED: "Validation Failed - Already Paid",
    ERROR_DOWNTIME: "Validation Failed - Downtime",
    ERROR_FREQUENT_CASHER: "Blocked - Frequent Casher",
    ERROR_INVALID_RESPONSE: "Validation Failed - Ineligible Ticket",
    ERROR_OFFSET: "Blocked - Player Offsets",
    ERROR_PLAYER_SUSPENDED: "Blocked - Player Suspended",
    ERROR_RTC_TICKET_HOLD: "Blocked- Ticket on Hold",
    ERROR_UNABLE_TO_VALIDATE: "Validation Failed - API Error",
    READY_FOR_PAYMENT: 'READY_FOR_PAYMENT',
    INITIATED: "In progress - Claim Started",
    PLAYER_ERROR: "Blocked - Player ID",
    RECEIVED: "In progress - Claim Received",
    VALIDATED: "Validated - Transmitting Info",

}

export const getHistoryStatusType = (value: string) => {
    const type: any = Object.keys(ClaimStatus).find((key: any) => key === value)
    return type === undefined ? "" : ClaimStatus[type];
}

export const setStartDate = async (startDate: any, setMCAStartDate: any) => {
    setMCAStartDate(startDate.target.value);
}

export const setEndDate = async (endDate: any, setMCAEndDate: any) => {
    setMCAEndDate(endDate.target.value);
}

export const resetFilters = async (setFilteredClaims: any, UnfilteredClaims: any, setMCAEndDate: any, setMCAStartDate: any, setMCAInitiated: any, setCurrentPage: any, id: any) => {

    setMCAEndDate((UnfilteredClaims.length > 0 && UnfilteredClaims[0].claim.referenceNumber !== id) ? UnfilteredClaims[0].claim.activeFrom.split("T")[0] : new Date(new Date().toLocaleDateString()).toISOString().split("T")[0]);
    setMCAStartDate((UnfilteredClaims.length > 0 && UnfilteredClaims[0].claim.referenceNumber !== id) ? UnfilteredClaims[UnfilteredClaims.length - 1].claim.activeFrom.split("T")[0] : `${new Date().getUTCFullYear()}-01-01`);
    setMCAInitiated(false);
    setFilteredClaims(UnfilteredClaims);
    setCurrentPage(0);

}

/**
 * Applies filters to UnfilteredClaims
 */
export const applyFilters = async (setFilteredClaims: any, UnfilteredClaims: any[], startDate: string, endDate: string, initiated: boolean, setCurrentPage: any, id: any) => {

    setCurrentPage(0);

    let FilteredClaims = UnfilteredClaims.filter(row => {
        if (initiated == true) {
            return moment(row.claim.activeFrom).isSameOrAfter(startDate, 'day') && moment(row.claim.activeFrom).isSameOrBefore(endDate, 'day') && row.claim.status.toLowerCase().includes('initiated') === false;
        } else {
            return moment(row.claim.activeFrom).isSameOrAfter(startDate, 'day') && moment(row.claim.activeFrom).isSameOrBefore(endDate, 'day');
        }
    });

    if (FilteredClaims.length <= 0) {
        const defaultData: any = {
            activeFrom: "",
            referenceNumber: id,
        }
        FilteredClaims.push({claim: defaultData});
    }

    setFilteredClaims(FilteredClaims);
}

/**
 * Fetch Claims associated with a specific player - called in useEffect()
 */
export const getPlayerClaims = async (identifier: any, storesObject: any, setPageMessage: any, setFilteredClaims: any, setUnfilteredClaims: any, playerId: any, setIsData: any, setMCAStartDate: any, setMCAEndDate: any, id: any, setNetworkError: any) => {

    try {

        const options =
            {
                method: 'get',
                url: config.SERVER_BASE_URL + `/v1/player/${playerId}/claims`
            }

        const response: any = await makeHttpCall(options);

        response.claims.map((row: any) => {
            row.claim.status = getHistoryStatusType(row.claim.status);
        });

        if (response.claims.length <= 0) {
            const defaultData: any = {
                activeFrom: "",
                referenceNumber: identifier,
            }
            response.claims.push(defaultData);
        }

        storesObject.claimStore.claims = response.claims
        setIsData(true);
        setMCAStartDate(response.claims.length > 0 ? response.claims[response.claims.length - 1].claim.activeFrom.split("T")[0] : `${new Date().getUTCFullYear()}-01-01`)
        setMCAEndDate(response.claims.length > 0 ? response.claims[0].claim.activeFrom.split("T")[0] : new Date(new Date().toLocaleDateString()).toISOString().split("T")[0])
        setNetworkError(false);
        setFilteredClaims(response.claims);
        setUnfilteredClaims(response.claims);
        setPageMessage({message: "", messageType: ""});

    } catch (e) {

        setIsData(true);
        const er: any = e;
        if (er.toString().split(" ").find((value: any, i: any) => {
            if (value === "Network")
                return value;
        }) !== undefined) {
            setNetworkError(true);
            setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        } else {
            setNetworkError(false);
            setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
        }

    }

}

