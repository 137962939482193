import axios from 'axios';

export const makeHttpCall = async (options:any) => {
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers
    };
    Object.assign(options,{headers:headers});
    try {
        
        let response = await axios(options);
        return  response.data;
    }
    catch (e) {
        throw new Error("makeHttpCall" + e );
    }
}