import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../../icons/Icon_arrows_carrot_white-left.svg";
import {clearSearch, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {pulltabRoutePath} from "../../../../../config.js";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent, NotificationType
} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import {FaPlus} from 'react-icons/fa';
import {BsArrowCounterclockwise} from 'react-icons/bs';
import moment from "moment";
import {
    PullTabOrderOverviewHistorySearchBarComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Orders/PullTabOrderOverviewHistorySearchBarComponent";
import {
    PullTabOrderOverviewHistorySearchResultComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Orders/PullTabOrderOverviewHistorySearchResultComponent";

export const PullTabOrderOverviewHistoryPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            warehouse: '',
            startOrderDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endOrderDate: moment().format('YYYY-MM-DD'),
            purchaseOrderId: '',
            orgName: '',
            licenseNumber: ''
        }, validate, onSubmit
    });

    const {pullTabOrderOverviewHistory} = charitableGamingStore;

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const {notificationBanner} = charitableGamingStore;

    const backButtonText = localStorage.getItem("PullTabOrderOverviewHistoryPageBackButtonText");

    useEffect(() => {
        if (location.state) {
            localStorage.setItem("PullTabOrderOverviewHistoryPageBackButtonText", location.state.backButtonText);
        }
        if (requestCompleted === false) {
            formik.submitForm();
        }
    }, []);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    setVariables(formik, charitableGamingStore, setCurrentPage, setShowErrorDialog, setRequestCompleted);

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['pulltab-stock-inventory-page-container']}`}
            >
                <div tabIndex={0}
                     className={`${styles['pulltab-stock-inventory-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             // history.push(`${pulltabRoutePath}/orders`);
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         // history.push(`${pulltabRoutePath}/orders`);
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['pulltab-stock-inventory-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >{backButtonText}
                    </div>
                </div>
                <div className={`${styles['pulltab-stock-inventory-page-ribbon-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-stock-inventory-page-ribbon-title']} ${styles['']}`}>Order
                        Overview History
                    </div>
                    <div className={`${styles['pulltab-stock-inventory-page-ribbon-buttons-container']}`}>
                        {checkRenderPermissions(permissions.CAN_CREATE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <div tabIndex={0}
                                 className={`${styles['pulltab-stock-inventory-page-ribbon-create-license-button']} ${styles['']}`}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         history.push({
                                             pathname: `${pulltabRoutePath}/orders/create/step-1`,
                                             state: {
                                                 backButtonText: 'Back to Order Overview History',
                                                 backURL: `${pulltabRoutePath}/orders/overview-history`
                                             },
                                         });
                                     }
                                 }}
                                 onClick={() => {
                                     history.push({
                                         pathname: `${pulltabRoutePath}/orders/create/step-1`,
                                         state: {
                                             backButtonText: 'Back to Order Overview History',
                                             backURL: `${pulltabRoutePath}/orders/overview-history`
                                         },
                                     });
                                 }}>
                                <FaPlus style={{cursor: "pointer"}}/>
                                <div
                                    className={`${styles['pulltab-stock-inventory-page-ribbon-create-license-button-label']} ${styles['']}`}>Create
                                    Order
                                </div>
                            </div> : null}
                        {checkRenderPermissions(permissions.CAN_RETURN_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                            <div tabIndex={0}
                                 className={`${styles['pulltab-stock-inventory-page-ribbon-renew-license-button']} ${styles['']}`}
                                 role="button"
                                 onKeyDown={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                         history.push(`${pulltabRoutePath}/orders/return-global`);
                                     }
                                 }}
                                 onClick={() => {
                                     history.push(`${pulltabRoutePath}/orders/return-global`);
                                 }}>
                                <BsArrowCounterclockwise style={{cursor: "pointer"}}/>
                                <div
                                    className={`${styles['pulltab-stock-inventory-page-ribbon-create-license-button-label']}`}
                                >
                                    Return Order
                                </div>
                            </div> : null}


                    </div>

                </div>

                <div
                    className={`${styles['pulltab-stock-inventory-page-search-bar-and-notification-bar-container']}`}>
                    <PullTabOrderOverviewHistorySearchBarComponent formik={formik} setFieldValue={setFieldValue}
                                                                   clearSearch={clearSearch}/>
                </div>
                {pullTabOrderOverviewHistory.records.length <= 0 ?
                    <div className={`${styles['pulltab-stock-inventory-page-search-no-results-container']}`}>
                        <div className={`${styles['pulltab-stock-inventory-page-search-no-results-container-text']}`}>
                            No Results. Please try a different search.
                        </div>
                    </div> : <PullTabOrderOverviewHistorySearchResultComponent
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />}
            </div>

            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                type={NotificationType.Success}
                text={notificationBanner.text}
                handleHide={hideNotificationBanner}
            /> : null}
        </>

    );
}));