import * as React from 'react'

type PlayslipsStepDownContext = {
  fetchAllPlayslipsStepDowns: () => Promise<void>
}
export const defaultPlayslipsStepDownContext: PlayslipsStepDownContext = {
  fetchAllPlayslipsStepDowns: async () => {
    throw new Error('fetchAllPlayslipsStepDowns Not Implemented')
  },
}

export const PlayslipsStepDownPageContext =
  React.createContext<PlayslipsStepDownContext>(defaultPlayslipsStepDownContext)
