import * as React from 'react'
import { GenericPopupModal } from '../../GenericPopupModal/generic-popup-modal-ui'
import './styles.scss'

export type CampaignsImagePreviewProps = {
  clearImageUrl: () => void
  imageUrl: string | undefined
}

const ROOT_CLASS = 'campaigns-image-preview'

export const CampaignsImagePreview = (props: CampaignsImagePreviewProps) => {
  return (
    <GenericPopupModal
      isOpen={Boolean(props.imageUrl)}
      closeModal={props.clearImageUrl}
    >
      <img
        className={ROOT_CLASS}
        src={props.imageUrl}
        alt="Campaign Image Preview"
      />
    </GenericPopupModal>
  )
}
