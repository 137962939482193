import { Workbook } from "exceljs"
import moment from "moment"
import { TimePaymentAuthSchedule } from "../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization"
import { formatCentsToDollars } from "../../../../util"
import {
  autoFitColumns,
  createSheetWithHeaders,
  saveXLSX,
} from "../../../../util/excel-export-util"
import { formatDate } from "./functions"

type ReportSummary = {
  numberOfPayments: number;
  grossPayment: number;
  netPayment: number;
  irsWithholding: number;
  dorWithholding: number;
};

type PaymentAuthExportRow = {
  documentId: string;
  name: string;
  tin: string;
  cadence: string;
  paymentDate: string;
  grossPayment: string;
  irsWithholding: string;
  dorWithholding: string;
  netPayment: string;
  lifetimePayment: string;
  reLine1?: string;
  reLine2?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  status: string;
  anniversaryDate: string;
};

const exportAsXLSX = async (
  authorizationData: TimePaymentAuthSchedule[],
  throughDate: string,
  fromDate?: string
) => {
  const reportType = fromDate ? "Reporting" : "Authorization";
  const reportDates = `${
    fromDate ? `${formatDate(fromDate)}-` : ""
  }${formatDate(throughDate)}`;
  const fileName = `Time_Payment_${reportType}_${reportDates}`;
  const upcomingPayments = authorizationData.filter(
    (entry) => entry.payment.paymentStatus === "Upcoming" || entry.payment.paymentStatus === "Pending"
  );
  const heldPayments = authorizationData.filter(
    (entry) => entry.payment.paymentStatus === "On Hold"
  );

  const workbook = new Workbook();

  const upcomingPaymentSummary = getPaymentSummary(upcomingPayments);
  const productSummary = getProductSummary(upcomingPayments);

  createSummarySheet(workbook, upcomingPaymentSummary, "Upcoming");
  createProductSummarySheet(workbook, productSummary, "Upcoming");
  if (fromDate) {
    // Export is a forecast, include held payments
    const heldPaymentSummary = getPaymentSummary(heldPayments);
    const heldProductSummary = getProductSummary(heldPayments);

    createSummarySheet(workbook, heldPaymentSummary, "Held");
    createProductSummarySheet(workbook, heldProductSummary, "Held");
  }
  createPaymentSheet(
    workbook,
    mapDataToExportRows(authorizationData),
    Boolean(fromDate)
  );

  saveXLSX(await workbook.xlsx.writeBuffer(), fileName);
};

const mapDataToExportRows = (
  data: TimePaymentAuthSchedule[]
): PaymentAuthExportRow[] => {
  return data.map((record) => {
    const name =
      record.payee.payeeType === "Individual"
        ? `${record.payee.firstName} ${record.payee.lastName}`
        : record.payee.freeformName;

    const newData: PaymentAuthExportRow = {
      documentId: record.timePaymentAccount.documentId,
      name: name,
      tin: record.payee.tin,
      cadence: record.timePaymentAccount.payFrequency,
      paymentDate: moment(record.payment.paymentDate).format("YYYY-MM-DD"),
      anniversaryDate: moment(
        record.timePaymentAccount.drawDate ??
          record.timePaymentAccount.claimDate ??
          record.timePaymentAccount.nextPaymentDate
      ).format("YYYY-MM-DD"),
      grossPayment: formatCentsToDollars(record.payment.paymentAmount),
      irsWithholding: formatCentsToDollars(record.payment.irsWithholding),
      dorWithholding: formatCentsToDollars(record.payment.dorsWithholding),
      netPayment: formatCentsToDollars(calculateNetPayment(record)),
      lifetimePayment: record.timePaymentAccount.lifetimePayment ? "Yes" : "No",
      reLine1: record.subAccount.supportingInformation1,
      reLine2: record.subAccount.supportingInformation2,
      address1: record.payee.address1,
      address2: record.payee.address2,
      city: record.payee.city,
      state: record.payee.state,
      country: record.payee.country,
      zip: record.payee.zip,
      status: record.payment.paymentStatus,
    };

    return newData;
  });
};

const createSummarySheet = (
  workbook: Workbook,
  summary: ReportSummary,
  summaryType: "Upcoming" | "Held"
) => {
  let summarySheet = workbook.addWorksheet(`${summaryType} Payment Summary`);
  summarySheet.addRows([
    [`${summaryType} Payment Summary`],
    [`Number of ${summaryType} Payments`, summary.numberOfPayments],
    ["Gross Payment", formatCentsToDollars(summary.grossPayment)],
    ["Est. Federal Tax", formatCentsToDollars(summary.irsWithholding)],
    ["Est. State Tax", formatCentsToDollars(summary.dorWithholding)],
    ["Est. Net Payment", formatCentsToDollars(summary.netPayment)],
  ]);
  summarySheet.getRow(1).font = { bold: true };
  summarySheet = autoFitColumns(summarySheet);
};

const createProductSummarySheet = (
  workbook: Workbook,
  productSummary: Map<string, ReportSummary>,
  summaryType: "Upcoming" | "Held"
) => {
  const columnNames = [
    "Product Name",
    `Number of ${summaryType} Payments`,
    "Gross Payment",
    "Est. Federal Tax",
    "Est. State Tax",
    "Est. Net Payment",
  ];
  const productSummarySheet = createSheetWithHeaders(
    workbook,
    columnNames,
    `${summaryType} Payments By Product`
  );

  productSummary.forEach((summary, productName) => {
    productSummarySheet.addRow([
      productName,
      summary.numberOfPayments,
      formatCentsToDollars(summary.grossPayment),
      formatCentsToDollars(summary.irsWithholding),
      formatCentsToDollars(summary.dorWithholding),
      formatCentsToDollars(summary.netPayment),
    ]);
  });

  autoFitColumns(productSummarySheet);
};

const createPaymentSheet = (
  workbook: Workbook,
  rows: PaymentAuthExportRow[],
  forecastExport = false
) => {
  const reportingColumns = ["Status"];
  const defaultColumns = [
    "Document ID",
    "Name",
    "TIN",
    "Cadence",
    "Payment Date",
    "Anniversary Date",
    "Gross Payment",
    "Est. Federal Tax",
    "Est. State Tax",
    "Est. Net Payment",
    "Lifetime Payment",
    "RE: Line 1",
    "RE: Line 2",
    "Address Line 1",
    "Address Line 2",
    "City",
    "State",
    "Country",
    "ZIP",
  ];
  const detailSheet = createSheetWithHeaders(
    workbook,
    forecastExport ? [...defaultColumns, ...reportingColumns] : defaultColumns,
    "List of Payments"
  );
  for (const row of rows) {
    const data = Object.entries(row)
      .filter((entry) => entry[0] !== "status" || forecastExport)
      .map((entry) => entry[1]);
    detailSheet.addRow(data);
  }

  autoFitColumns(detailSheet);
};

const getProductSummary = (
  paymentData: TimePaymentAuthSchedule[]
): Map<string, ReportSummary> => {
  const summaryByProduct = new Map<string, ReportSummary>();
  const initValues = {
    numberOfPayments: 0,
    grossPayment: 0,
    irsWithholding: 0,
    dorWithholding: 0,
    netPayment: 0,
  };

  for (const record of paymentData) {
    const productType =
      record.timePaymentAccount.gameType === "Draw"
        ? record.timePaymentAccount.gameName
        : record.timePaymentAccount.gameType;
    const previousValues: ReportSummary =
      summaryByProduct.get(productType) ?? initValues;
    summaryByProduct.set(productType, {
      numberOfPayments: previousValues.numberOfPayments + 1,
      grossPayment: previousValues.grossPayment + record.payment.paymentAmount,
      netPayment: previousValues.netPayment + calculateNetPayment(record),
      irsWithholding:
        previousValues.irsWithholding + record.payment.irsWithholding,
      dorWithholding:
        previousValues.dorWithholding + record.payment.dorsWithholding,
    });
  }

  return summaryByProduct;
};

const getPaymentSummary = (
  paymentData: TimePaymentAuthSchedule[]
): ReportSummary => {
  const initValues = {
    numberOfPayments: 0,
    grossPayment: 0,
    irsWithholding: 0,
    dorWithholding: 0,
    netPayment: 0,
  };

  return paymentData.reduce(
    (prev, curr) => {
      prev.numberOfPayments++;
      prev.grossPayment += curr.payment.paymentAmount;
      prev.dorWithholding += curr.payment.dorsWithholding;
      prev.irsWithholding += curr.payment.irsWithholding;
      prev.netPayment += calculateNetPayment(curr);
      return prev;
    },
    { ...initValues }
  );
};

const calculateNetPayment = (authorization: TimePaymentAuthSchedule) => {
  return (
    authorization.payment.paymentAmount -
    authorization.payment.irsWithholding -
    authorization.payment.dorsWithholding
  );
};

export { exportAsXLSX };
