import React, {useState} from "react";
import styles from './css/styles.module.css';
import {getPageData} from "./functions";
import {useHistory} from "react-router-dom";
import {PaginationCommonComponent} from './../../../components/CommonComponents/PaginationCommonComponent'
import {RejectedPaymentsQueueResponseInterface} from "../../../pages/ActionsPages/RejectedPaymentsQueuePage/interface";
import moment from "moment";
import {keyPressedType} from "../../../services/Commons";
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";
import {claimsSupportRoutePath} from "../../../config";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";

export const RejectedPaymentsQueueTableComponent = ({RejectedPaymentsQueueData}: any) => {

    const userpolicies: any = localStorage.getItem('userpolicies');

    const history = useHistory();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const normilizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;
    }

    return (
        <>
            <div className={styles['KYCQUEUE-table']}>
                <div className={styles['KYCQUEUE-table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${RejectedPaymentsQueueData.length <= 0 ? 0 : (currentPage * 10) + 1}-${normilizePage(RejectedPaymentsQueueData.length, currentPage)} of ${RejectedPaymentsQueueData.length} results`}</div>
                </div>
                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date</div>
                    <div tabIndex={0}>Time</div>
                    <div tabIndex={0}>Player</div>
                    <div tabIndex={0}>Claim Ref#</div>
                    <div tabIndex={0}>Amount</div>
                    <div tabIndex={0}></div>
                </div>
                <div className={styles['KYCQUEUE-table-data-body']}>
                    {RejectedPaymentsQueueData.length > 0 && getPageData(RejectedPaymentsQueueData, currentPage).map((e: RejectedPaymentsQueueResponseInterface, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment(e.transactions[0].details.createdAt).format('MM-DD-YYYY')}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment(e.transactions[0].details.createdAt).format('HH:mm')}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{`${e.claim.playerFirstName} ${e.claim.playerLastName}`}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.claim.referenceNumber}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{normilizeAmount(e.transactions[0].details.amountInCents)}</div>
                            <div>
                                {checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies)) ?
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyPress={(ev: any) => {
                                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                                 history.push(`${claimsSupportRoutePath}/mobile-claims-details/${e.claim.playerId}/${e.claim.referenceNumber}`);
                                             }
                                         }
                                         }
                                         onClick={() => {
                                             history.push(`${claimsSupportRoutePath}/mobile-claims-details/${e.claim.playerId}/${e.claim.referenceNumber}`);
                                         }
                                         } className={styles['KYCQUEUE-table-review-kyc-button']}>
                                        <div aria-label={'Review KYC button.'}
                                             className={styles['KYCQUEUE-table-review-kyc-button-text']}>
                                            Show details
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles['KYCQUEUE-table-data-footer']}>
                    <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((RejectedPaymentsQueueData.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={RejectedPaymentsQueueData} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}
                </div>
            </div>
        </>
    );
}
