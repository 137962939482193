import * as React from 'react'
import { Agent1099Aggregate, Agent1099AggregatesSearchResponse, Agent1099DataYear } from '../../../api/agent-1099-fetcher/api-types'
import { Agent1099Payment, Agent1099QueuePaymentsTableRow, Agent1099QueuePaymentsTableUI } from './agent-1099-queue-payments-table-ui'
import './styles.scss'
import { Agent1099QueueSearchAndFilterBarUI } from './agent-1099-queue-search-and-filter-bar-ui'
import { LoadingSpinner } from '../../loading-spinner'

export type Agent1099QueueAllPaymentsTabUIProps = {
  isLoading: boolean
  error?: string
  yearOptions?: Agent1099DataYear[]
  searchData?: Agent1099AggregatesSearchResponse
  searchedYear?: string
  searchedTin?: string
  setSearchedYear: (year: string) => void
  setSearchedTin: (tin?: string) => void
  reprint: (tin: string, year: string) => void
}

const convertAggregateToPayment = (aggregate: Agent1099Aggregate): Agent1099Payment => {
  return {
    year: aggregate.year,
    fid: aggregate.tin,
    taxName: aggregate.taxName,
    address: aggregate.address,
    city: aggregate.city,
    reportableInCents: aggregate.reportableAmountInCents,
    digitalConsent: aggregate.digitalConsent,
    queued: aggregate.queued
  }
}

export const Agent1099QueueAllPaymentsTabUI = (
  props: Agent1099QueueAllPaymentsTabUIProps
) => {
  return (
    <>
      <LoadingSpinner showSpinner={props.isLoading} />
      <Agent1099QueueSearchAndFilterBarUI 
        selectedId={props.searchedYear}
        searchedTin={props.searchedTin}
        options={props.yearOptions?.map(option => {
          return { id: option.year, displayText: option.year }
        })}
        selectLabel={'Select Year'}
        setOption={props.setSearchedYear}
        search={(tin?: string) => props.setSearchedTin(tin)}
        clearSearch={() => props.setSearchedTin(undefined)}
      />

      <Agent1099QueuePaymentsTableUI 
        payments={props.searchData?.aggregates.map(convertAggregateToPayment)}
        additionalResults={props.searchData?.additionalResults}
        showRowCTAs={true}
        shouldDisableRowCTA={(payment: Agent1099QueuePaymentsTableRow) => payment.queued}
        ctaText={'Reprint 1099'}
        ctaCallback={props.reprint}
        noSelectionMessage={'Select a year to generate results.'}
      />
    </>
  )
}
