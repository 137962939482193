import config from '../../../../config'
import { makeHttpCall } from '../../../../services/MakeHttpCall'
import { TimePaymentAccountResponse } from '../../../../types/Annuities/responses/time-payment-account'

const getPaymentDetails = async (tpaId: string): Promise<any[]> => {
  const options = {
    method: 'get',
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaId}/payment?forecastPayments=true`,
  }

  try {
    const response = await makeHttpCall(options)
    return response
  } catch (error) {
    throw new Error('Something went wrong Fetching Payment Details')
  }
}

const getTpaDetails = async (
  tpaId: string
): Promise<TimePaymentAccountResponse> => {
  const options = {
    method: 'get',
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${tpaId}`,
  }

  try {
    const response = await makeHttpCall(options)
    return response
  } catch (error) {
    throw new Error('Something went wrong Fetching TPA Details')
  }
}

enum TaxDesignation {
  IRSDOR = 1,
  DOR,
  IRS,
  NON,
}

const getTaxDeclaration = (
  irsTaxEnabled: boolean,
  dorTaxEnabled: boolean
): TaxDesignation => {
  if (irsTaxEnabled && dorTaxEnabled) {
    return TaxDesignation.IRSDOR
  }
  if (irsTaxEnabled) {
    return TaxDesignation.IRS
  }
  if (dorTaxEnabled) {
    return TaxDesignation.DOR
  }

  return TaxDesignation.NON
}

const convertCurrency = (amountInCents: number): string => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(amountInCents)
}

export { getPaymentDetails, getTpaDetails }
