import * as React from 'react'
import { PromotionBuilderReward } from '../../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PROMOTION_SIDEBAR_FORM_ROOT_CLASS } from '../helpers'
import '../styles.scss'

type PromotionEntriesDistributionViewModeUIProps = {
  entryDistributions: PromotionBuilderReward[]
}

export const PromotionEntriesDistributionViewModeUI = (
  props: PromotionEntriesDistributionViewModeUIProps
) => {
  return (
    <>
      {props.entryDistributions.map((reward, index) => {
        return (
          <div key={`reward-distribution-${index}`}>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Entry Quantity
              <span>{reward.quantity}</span>
            </div>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Distribution Percentage
              <span>{reward.distributionPercentage}</span>
            </div>
            {index !== props.entryDistributions.length - 1 && (
              <hr
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'divider'
                )}
              />
            )}
          </div>
        )
      })}
    </>
  )
}
