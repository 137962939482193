import React from "react";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../services/Commons";
import { permissions, KEY_CODES } from "../../../constants.js";

export const ShortPaginationCommonComponent = ({playerData, currentPage, setCurrentPage}: any) => {

    const createPages = (KYCPlayerData: any[], currentPage: any, setCurrentPage: any) => {
        const array: any = [];
        const pagesTotal = Math.ceil(KYCPlayerData.length / 5);

        if (currentPage > 2 && pagesTotal > 10) {

            array.push(
                <>
                    <div
                        tabIndex={0}
                        role='button'
                        aria-pressed={currentPage === 0 ? "true" : "false"}
                        style={{backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : ""}}
                        onKeyPress={(event) => {
                            if (
                                keyPressedType(event) === KEY_CODES.ENTER || 
                                keyPressedType(event) === KEY_CODES.SPACE || 
                                keyPressedType(event) === KEY_CODES.UNKNOWN_KEY
                                ) {
                                setCurrentPage(0);
                            }
                        }
                        }
                        onClick={() => setCurrentPage(0)} className={styles['pagination-table-page-box']}>
                        <div className={styles['pagintation-table-page-box-text']}>
                            {1}
                        </div>
                    </div>
                    <div>{'...'}</div>
                </>
            );
        }
        if (currentPage <= 2 || pagesTotal <= 5) {

            for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
                array.push(
                    <div key={`${i}a`} tabIndex={0} role='button' aria-pressed={currentPage === i ? "true" : "false"}
                         onKeyPress={(event) => {
                             if (
                                keyPressedType(event) === KEY_CODES.ENTER || 
                                keyPressedType(event) === KEY_CODES.SPACE || 
                                keyPressedType(event) === KEY_CODES.UNKNOWN_KEY
                                ) {
                                 setCurrentPage(i);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(i)}
                         style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                         className={styles['pagination-table-page-box']}>
                        <div className={styles['pagination-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }
        } else if (currentPage > 2 && currentPage <= (pagesTotal - 1) - 2) {

            for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
                array.push(
                    <div
                        key={`${i}b`}
                        tabIndex={0}
                        role='button'
                        aria-pressed={currentPage === i ? "true" : "false"}
                        onKeyPress={(event) => {
                            if (
                                keyPressedType(event) === KEY_CODES.ENTER || 
                                keyPressedType(event) === KEY_CODES.SPACE || 
                                keyPressedType(event) === KEY_CODES.UNKNOWN_KEY
                                ) {
                                setCurrentPage(i);
                            }
                        }
                        }
                        onClick={() => setCurrentPage(i)}
                        style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                        className={styles['pagination-table-page-box']}>
                        <div className={styles['pagination-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }
        } else if (currentPage > (pagesTotal - 1) - 2) {
            for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
                array.push(
                    <div
                        key={`${i}c`}
                        tabIndex={0} role='button' aria-pressed={currentPage === i ? "true" : "false"}
                        onKeyPress={(event) => {
                            if (
                                keyPressedType(event) === KEY_CODES.ENTER || 
                                keyPressedType(event) === KEY_CODES.SPACE || 
                                keyPressedType(event) === KEY_CODES.UNKNOWN_KEY
                                ) {
                                setCurrentPage(i);
                            }
                        }
                        }
                        onClick={() => setCurrentPage(i)}
                        style={{backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : ""}}
                        className={styles['pagination-table-page-box']}>
                        <div className={styles['pagination-table-page-box-text']}>
                            {i + 1}
                        </div>
                    </div>
                );
            }

        }
        if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
            array.push(
                <>
                    <div>{'...'}</div>
                    <div tabIndex={0} role='button' aria-pressed={currentPage === pagesTotal ? "true" : "false"}
                         style={{backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : ""}}
                         onKeyPress={(event) => {
                             if (
                                keyPressedType(event) === KEY_CODES.ENTER || 
                                keyPressedType(event) === KEY_CODES.SPACE || 
                                keyPressedType(event) === KEY_CODES.UNKNOWN_KEY
                                ) {
                                 setCurrentPage(pagesTotal - 1);
                             }
                         }
                         }
                         onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['pagination-table-page-box']}>
                        <div className={styles['pagination-table-page-box-text']}>
                            {pagesTotal}
                        </div>
                    </div>
                </>
            );
        }
        return array;


    }


    return (
        <>
            {playerData.length > 0 && createPages(playerData, currentPage, setCurrentPage)}
        </>
    );
}


