import config from "../../../config";
import {makeHttpCall} from "../../../services/MakeHttpCall";

let setRequestCompleted: any;
let setReportDownloadCompleted: any;
let setW2GQueue: any;
let setCurrentPage: any;
let setShowErrorDialog: any;

export const setVariables = (setRequestCompletedObj: any, setReportDownloadCompletedObj: any, setW2GQueueObj: any, setCurrentPageObj: any, setShowErrorDialogObj: any) => {

    setRequestCompleted = setRequestCompletedObj;
    setReportDownloadCompleted = setReportDownloadCompletedObj;
    setW2GQueue = setW2GQueueObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    //alert(JSON.stringify(values));
    getW2GQueuePrintHistory(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            view: '', TIN: ''
        }
    });

}

export const getPageData = (printHistory: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(printHistory[i] === undefined)) {
            printHistory[i].index = index;
            index++;
            array.push(printHistory[i]);
        }
    }
    return array;
}


const getW2GQueuePrintHistory = async (values: any) => {

    setCurrentPage(0);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/w2gQueue/prints`
        }

        const response = await makeHttpCall(options);

        setW2GQueue(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const downloadW2GPrintReport = async (sweepId: string) => {
    setReportDownloadCompleted(false)

    try {
        const presignedUrlResponse = await makeHttpCall({
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/w2gQueue/${sweepId}/printReportUrl`
        })

        const fileLink = document.createElement('a')
        fileLink.href = presignedUrlResponse.url
        fileLink.click()
        fileLink.remove()

        setReportDownloadCompleted(true)

    } catch (e) {
        setReportDownloadCompleted(true)
        setShowErrorDialog(true)
    }
}

enum W2GSweepPrintStatus {
    PENDING = 'PENDING',
    ERROR_CSV_GENERATION = 'ERROR_CSV_GENERATION',
    ERROR_CSV_UPLOAD = 'ERROR_CSV_UPLOAD',
    ERROR_PRINT_REPORT_GENERATION = 'ERROR_PRINT_REPORT_GENERATION',
    ERROR_PRINT_REPORT_UPLOAD = 'ERROR_PRINT_REPORT_UPLOAD',
    ERROR_STUCK_PENDING = 'ERROR_STUCK_PENDING',
    SUCCESS = 'SUCCESS',
    ERROR_DATABASE = 'ERROR_DATABASE'
}

export const mapRawSweepPrintStatus = (status: W2GSweepPrintStatus): string => {
    switch (status) {
        case W2GSweepPrintStatus.PENDING:
        case W2GSweepPrintStatus.SUCCESS:
            return status
        case W2GSweepPrintStatus.ERROR_CSV_GENERATION:
        case W2GSweepPrintStatus.ERROR_CSV_UPLOAD:
        case W2GSweepPrintStatus.ERROR_PRINT_REPORT_GENERATION:
        case W2GSweepPrintStatus.ERROR_PRINT_REPORT_UPLOAD:
        case W2GSweepPrintStatus.ERROR_DATABASE:
        case W2GSweepPrintStatus.ERROR_STUCK_PENDING:
            return 'ERROR'
        default:
            return 'UNKNOWN'
    }
}
