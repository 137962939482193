import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { PLAYERS_PAGES }  from "../../../../application-data-structure/players-page.data-structure";
import { PlayerStatusComponent } from '../../../../components/PlayersComponents/PlayerStatusComponent';
import {  PlayerProfileComponent } from '../../../../components/PlayersComponents/PlayerProfileComponent';
import { PlayerEmailAndPhoneComponent } from "../../../../components/PlayersComponents/PlayersEmailAndPhoneComponent";
import { DisplayDocumentNewComponent } from './../../../../components/PlayersComponents/DisplayDocumentNewComponent'


export const PlayerDetailsPage = ({id, storesObject, playerStore = undefined,setPlayerStore,setPageMessage,pageMessage,requestPerformed,setRequestPerformed}: any) => {
 
    
   const  callingPage = PLAYERS_PAGES
   ['PLAYERS_SUPPORT_HUB_PAGE']
   ['TabsPlayersSupportHubPage'];
                                    
    return (
        <>
            <div className={styles['page-container']}>
                <div className={styles['players-details-left-container']} >
                    <div className={styles['player-status-component-container']}>
                      <PlayerStatusComponent  playerStore={playerStore}  setPlayerStore={setPlayerStore} callingPage={callingPage}/>
                    </div>
                    <div className={styles['player-profile-component-container']}>
                        <PlayerProfileComponent playerStore={playerStore} setPlayerStore={setPlayerStore}/> 
                    </div>
                    <div className={styles['player-email-and-phone-component-container']}>
                        <PlayerEmailAndPhoneComponent playerStore={playerStore} setPlayerStore={setPlayerStore} setPageMessage={setPageMessage} pageMessage={pageMessage}/>
                    </div>                   
                </div>   
                <div className={styles['players-details-right-container']} >
                     <DisplayDocumentNewComponent setRequestPerformed={setRequestPerformed}  storesObject={storesObject} requestPerformed={requestPerformed} playerId={id}  setPageMessage={setPageMessage} pageMessage={pageMessage}/>
                </div>              
            </div>

        </>

    );
}