import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {PaginationCommonComponent} from "../../../CommonComponents/PaginationCommonComponent";
import moment from "moment";
import ExclamationIcon from "../../../../icons/icon_status_flagged.svg";
import {SearchResultExeceededInfoMessageComponent} from "../../Common/SearchResultExeceededInfoMessageComponent";
import {getPageData} from "../../../../pages/CharitableGamingPages/TaxForm/TaxFormManagementPage/functions";

export const DelinquencyReportsSearchResultComponent = ({delinquencyReports, currentPage, setCurrentPage,}: any) => {

    const history = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        setTimeout(function () {
            setShowSearchResultExceededInfoMessage(false);
        }, 3000);
    }, []);

    const FormTypeMapping: any = {
        ANNUAL_BEANO: 'Annual', WEEKLY_BEANO: 'Weekly Beano'
    }

    return (<>
            <div className={styles['table']}>

                <div className={styles['table-data-header']}>
                    <div className={styles['table-data-header-text']} aria-live="assertive">
                        {`Displaying ${delinquencyReports.forms.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(delinquencyReports.forms.length, currentPage)}
                            of ${delinquencyReports.forms.length}${delinquencyReports.additionalResults ? "+" : ""} results`}
                    </div>
                    {delinquencyReports.additionalResults ? <div
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                            setShowSearchResultExceededInfoMessage(true);
                        }}
                        onMouseLeave={() => {
                            setShowSearchResultExceededInfoMessage(false);
                        }}
                    >
                        <img
                            src={ExclamationIcon}
                            height={20}
                            width={20}
                            alt="exclamation icon"
                        />
                        {delinquencyReports.additionalResults && showSearchResultExceededInfoMessage ?
                            <SearchResultExeceededInfoMessageComponent/> : null}
                    </div> : null}
                </div>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>Organization ID</div>
                    <div>Group Name</div>
                    <div>Occasion Date</div>
                    <div>Days Delinquent</div>
                    <div>Form Type</div>
                </div>

                <div className={styles['table-data-body']}>
                    {delinquencyReports.forms.length > 0 && getPageData(delinquencyReports.forms, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                {e.licenseNumber}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.orgName}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.occasionDate).format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.daysDelinquent}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {FormTypeMapping[e.formType]}
                            </div>
                        </div>)}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((delinquencyReports.forms.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={delinquencyReports.forms}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>
        </>);
};
