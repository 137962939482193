import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import { FuturePaymentStatusUI } from '../../CheckRunQueueComponents/future-payment-status/future-payment-status-ui'
import { FuturePaymentCheckDetailsUI } from '../future-payment-check-details/future-payment-check-details-ui'
import { FuturePaymentDemographicInfoUI } from '../future-payment-demographic-info/future-payment-demographic-info-ui'
import { FuturePaymentRecipientDetailsUI } from '../future-payment-recipient-details/future-payment-recipient-details-ui'
import './styles.scss'

const ROOT_CLASS = 'future-payment-details'

export type FuturePaymentDetailsUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse
}

export const FuturePaymentDetailsUI = (props: FuturePaymentDetailsUIProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={`check-details-row`}>
        <FuturePaymentCheckDetailsUI {...props} />
        <div className={`info-message-container`}>
          <FuturePaymentStatusUI
            status={props.futurePaymentDetailsData.status}
            notes={props.futurePaymentDetailsData.onHoldNotes}
            position="right"
          />
        </div>
      </div>
      <div className={`recipient-details-row`}>
        <FuturePaymentRecipientDetailsUI {...props} />
      </div>
      <div className={`demographic-info-row`}>
        <FuturePaymentDemographicInfoUI {...props} />
      </div>
    </div>
  )
}
