import { inject, observer } from 'mobx-react'
import React from 'react'
import { formatCentsToDollars } from '../../../../util'
import { PaginationCommonComponent } from '../../../CommonComponents/PaginationCommonComponent'
import { getPageData } from '../../../PlayersComponents/PlayerSearchTableComponent/functions'
import styles from './css/style.module.css'

export const GraduatedPaymentSchedule = inject('annuitiesStore')(
  observer(
    ({
      GraduatedPaymentSchedule,
      showDisclaimer,
      currentPage,
      setCurrentPage,
    }: any) => {
      return (
        <>
          <div className={styles['table']}>
            <div className={styles['table-data-header']}>
              <div
                className={styles['table-data-header-title']}
              >{`Graduated Payment Schedule`}</div>
              <p>{`Review the payment schedule and ensure the information is correct.`}</p>
            </div>

            <div
              className={`${styles['table-data-grid-header']} ${styles['table-data-label']}`}
            >
              <div></div>
              <div>Year</div>
              <div>Gross Payment</div>
            </div>

            <div className={styles['table-data-body']}>
              {GraduatedPaymentSchedule.length > 0 &&
                getPageData(GraduatedPaymentSchedule, currentPage).map(
                  (
                    payment: number,
                    i: number //TODO: make data structure for graduated payment list
                  ) => (
                    <div
                      key={i}
                      className={`${styles['table-data-grid-body']}`}
                    >
                      <div></div>
                      <div className={styles['table-data-text']}>
                        {!showDisclaimer || i > 0 || currentPage > 0 ? (
                          i + currentPage * 10 + 1
                        ) : (
                          <div className="d-flex align-items-center">
                            <span className="mr-2">1</span>
                            <span className={styles['disclaimer']}>
                              Note: the first payment has already been made
                              outside the annuity system
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={styles['table-data-text']}>
                        {formatCentsToDollars(payment)}
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className={styles['table-data-footer']}>
              <div
                aria-live="assertive"
                className={styles['table-page-label']}
              >{`Page ${currentPage + 1} of ${Math.ceil(
                GraduatedPaymentSchedule.length / 10
              ).toString()}`}</div>
              {
                <PaginationCommonComponent
                  playerData={GraduatedPaymentSchedule}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              }
            </div>
          </div>
        </>
      )
    }
  )
)
