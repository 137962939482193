import React from "react";
import moment from "moment-timezone";
import {timeOfDayFormat} from "../../../constants";
import styles from './css/styles.module.css';
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";

export const PaperCheckFileDetailsHeader = ({paperCheckFileDetailsSearchResponse}: any) => {

    return (
        <>
            <div
                className={`${styles["page-container-body-left-label-and-input-box-container"]}`}>
                <table style={{width:"76%"}}>
                    <thead>
                    <tr>
                        <td>File Creation Date</td>
                        <td>File Creation Time</td>
                        <td>Date Transmitted</td>
                        <td>Time Transmitted</td>
                        <td>Status</td>
                        <td>Amount</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td >{paperCheckFileDetailsSearchResponse[0]?.filecreationdatetime === undefined ? "": moment.tz(paperCheckFileDetailsSearchResponse[0].filecreationdatetime, "America/New_York").format('MM-DD-YYYY')}</td>
                        <td >{paperCheckFileDetailsSearchResponse[0]?.filecreationdatetime === undefined ? "": moment.tz(paperCheckFileDetailsSearchResponse[0].filecreationdatetime, "America/New_York").format(timeOfDayFormat)}</td>
                        <td >{paperCheckFileDetailsSearchResponse[0]?.filesubmissiondatetime === undefined ? "" : moment.tz(paperCheckFileDetailsSearchResponse[0].filesubmissiondatetime, "America/New_York").format('MM-DD-YYYY')}</td>
                        <td >{paperCheckFileDetailsSearchResponse[0]?.filesubmissiondatetime === undefined ? "": moment.tz(paperCheckFileDetailsSearchResponse[0].filesubmissiondatetime, "America/New_York").format(timeOfDayFormat)}</td>
                        <td >{paperCheckFileDetailsSearchResponse[0]?.status === undefined ? "" : paperCheckFileDetailsSearchResponse[0].status}</td>
                        <td >{paperCheckFileDetailsSearchResponse[0]?.netamount === undefined ? "" : normilizeAmount(paperCheckFileDetailsSearchResponse[0].netamount)}</td>
                    </tr>
                    </tbody>

                </table>
            </div>
        </>
    );
}