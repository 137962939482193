import moment from "moment";
import config from "../../../../config";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { PaymentResponse } from "../../../../types/Annuities/TimePaymentAuthorization/PaymentResponse";
import { CheckRun } from "../../../../types/Annuities/entities/check-run";
import { formatCentsToDollars } from "../../../../util";

const getPaymentList = async (
  authorizationId: string
): Promise<PaymentResponse[]> => {
  let paymentList: PaymentResponse[];

  const options = {
    method: "get",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/${authorizationId}/payment`,
  };

  try {
    const response = await makeHttpCall(options);
    paymentList = response;
  } catch (error) {
    paymentList = [];
    throw new Error("Something went wrong Fetching Payment List");
  }

  return paymentList;
};

const getCheckRunData = async (checkRunId: string): Promise<CheckRun> => {
  let checkRun: CheckRun;

  const options = {
    method: "get",
    url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/${checkRunId}`,
  };

  try {
    const response = await makeHttpCall(options);
    checkRun = response;
  } catch (error) {
    throw new Error("Something went wrong Fetching Authorization");
  }

  return checkRun;
};

// Csv
const exportToCsv = (paymentListData: PaymentResponse[], csvExport: any) => {
  // create new object per Authorization to produce csv
  const rows: any[] = Object.values(paymentListData).map(
    (payment: PaymentResponse) => {
      const newData = {
        documentId: payment.documentId,
        name: payment.name,
        tin: payment.tin,
        cadence: payment.payFrequency,
        paymentDate: moment(payment.paymentDate).format("MM/DD/YYYY"),
        grossPayment: formatCentsToDollars(payment.paymentAmount),
        irsWithholding: formatCentsToDollars(payment.irsWithholding),
        dorWithholding: formatCentsToDollars(payment.dorsWithholding),
        netPayment: formatCentsToDollars(
          payment.paymentAmount -
            payment.irsWithholding -
            payment.dorsWithholding
        ),
      };

      return newData;
    }
  );

  csvExport.generateCsv(rows);
};

export { exportToCsv, getCheckRunData, getPaymentList };
