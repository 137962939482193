import React from "react";
import {useHistory} from "react-router-dom";
import styles from './css/styles.module.css';
import {permitRoutePath} from "../../../../../config";
import {permissions} from "../../../../../constants";
import {checkRenderPermissions} from "../../../../../helpers";
import {keyPressedType} from "../../../../../services/Commons";

export const ViewPermitOrganizationDetailsComponent = ({permitHolderDetails}:any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    return (
        <>

            <div className={`${styles['view-permit-org-information-container']}`}>

                <div className={`${styles['view-permit-org-information-container-title-container']}`}>
                    <div className={`${styles['view-permit-org-information-container-title']}`}>Organization</div>
                    {checkRenderPermissions(permissions.CAN_UPDATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                        <div tabIndex={0} className={`${styles['view-permit-org-information-container-update-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     history.push(`${permitRoutePath}/update/org/${permitHolderDetails.raffleId}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${permitRoutePath}/update/org/${permitHolderDetails.raffleId}`);
                             }}>Update
                        </div> : null}
                </div>

                <div className={`${styles['view-permit-org-information-controls-container']}`}>

                    <div className={`${styles['view-permit-org-information-controls-container-column-1']} ${styles['mslc-label']}`}>Org. Name</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-2']} ${styles['mslc-label']}`}>{permitHolderDetails.orgName}</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-3']} ${styles['mslc-label']}`}>Org. Telephone</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-4']} ${styles['mslc-label']}`}>{permitHolderDetails.phone}</div>

                    <div className={`${styles['view-permit-org-information-controls-container-column-1']} ${styles['mslc-label']}`}>Street Address</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-2']} ${styles['mslc-label']}`}>{permitHolderDetails.address.streetAddress}</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-3']} ${styles['mslc-label']}`}>Email</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-4']} ${styles['mslc-label']}`}>{permitHolderDetails.email}</div>

                    <div className={`${styles['view-permit-org-information-controls-container-column-1']} ${styles['mslc-label']}`}>City/Town</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-2']} ${styles['mslc-label']}`}>{permitHolderDetails.address.city}</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-3']} ${styles['mslc-label']}`}>City Code</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-4']} ${styles['mslc-label']}`}>{permitHolderDetails.cityCode}</div>

                    <div className={`${styles['view-permit-org-information-controls-container-column-1']} ${styles['mslc-label']}`}>Zipcode</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-2']} ${styles['mslc-label']}`}>{permitHolderDetails.address.zip}</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-3']} ${styles['mslc-label']}`}>Org. Type</div>
                    <div className={`${styles['view-permit-org-information-controls-container-column-4']} ${styles['mslc-label']}`}>{permitHolderDetails.orgType}</div>

                </div>

            </div>


        </>

    );
};