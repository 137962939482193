import * as React from 'react'
import { useFetchEndCampaign } from '../../../../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import { CampaignBuilderContext } from '../../../../../pages/CampaignsPages/campaign-builder-context'
import { CampaignsEndCampaignPopup } from './campaigns-end-campaign-popup-ui'

export type CampaignsEndCampaignPopupContainerProps = {
  endCampaignIsOpen: boolean
  campaignIdentifier: string
  closeModal: () => void
}

export const CampaignsEndCampaignPopupContainer = (
  props: CampaignsEndCampaignPopupContainerProps
) => {
  const campaignBuilderContext = React.useContext(CampaignBuilderContext)
  const {
    error: endCampaignError,
    isLoading: endCampaignLoading,
    hasEndedCampaign,
    submitEndCampaign,
  } = useFetchEndCampaign()

  React.useEffect(() => {
    if (hasEndedCampaign && !endCampaignLoading) {
      campaignBuilderContext.fetchAllCampaigns()
    }
  }, [hasEndedCampaign, endCampaignLoading])

  const handleSubmitEndCampaign = async () =>
    await submitEndCampaign(props.campaignIdentifier)

  return (
    <CampaignsEndCampaignPopup
      endCampaignIsOpen={props.endCampaignIsOpen}
      error={endCampaignError}
      closeModal={props.closeModal}
      submitEndCampaign={handleSubmitEndCampaign}
      submitEndCampaignLoading={endCampaignLoading}
    />
  )
}
