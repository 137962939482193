import React, {useEffect, useState} from "react";
import styles from "./css/styles.module.css";
import {reportingRoutePath} from "../../../config";

import {useFormik} from "formik";
import {getACHRejectFileDetails, onSubmit, setVariables, validate,} from "./functions";
import {useHistory} from "react-router-dom";
import {
    PageMessageCommonComponent,
    pageMessageDefault,
} from "../../../components/CommonComponents/PageMessageCommonComponent";

import {RejectFileDetailsSearchResult} from "./interfaces";
import {CircularProgress} from "@material-ui/core";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {ACHRejectFileTableComponent} from "../../../components/FinanceComponents/ACHRejectFileTableComponent";
import {ACHRejectFileHeaderComponent} from "../../../components/FinanceComponents/ACHRejectFileHeaderComponent";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const defaultData: any[] = [];

export const ACHRejectFilePage = ({id}: any) => {
    let rejectFileReferenceNumber: any = id;
    const history = useHistory();
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
    const [rejectFileDetailsSearchResponse, setRejectFileDetailsSearchResponse] = useState<RejectFileDetailsSearchResult[]>(defaultData);
    const [rejectFileHeaderTitle, setRejectFileHeaderTitle] = useState<string>();
    const [buttonClicked, setButtonClicked] = useState<boolean>(false);
    const formik = useFormik({
        initialValues: {
            rejectFileReferenceNumber: ''
        },
        onSubmit,
        validate,
    });

    useEffect(() => {
        if (rejectFileDetailsSearchResponse.length <= 0 && !buttonClicked) {
            getACHRejectFileDetails(rejectFileReferenceNumber);
        }
    });

    setVariables(formik, history, pageMessageDefault, setPageMessage, setButtonClicked, setRejectFileDetailsSearchResponse, setRejectFileHeaderTitle);

    return (
        <>
            {
                ((rejectFileDetailsSearchResponse.length <= 0 && !buttonClicked) && pageMessage.message === "") &&
                <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="8em" color='inherit' thickness={2}/>
                </div>
            }
            <div
                onClick={() => {
                    pageMessage.messageType !== "" &&
                    setPageMessage({message: "", messageType: ""});
                }}
                className={styles["page-container"]}
            >
                <div className={styles['back-to-player-search-container']}>
                    <div tabIndex={0} role="button" aria-pressed={"false"} onClick={() => {
                        pageMessageDefault.message = "";
                        pageMessageDefault.messageType = "";
                        history.push(`${reportingRoutePath}/finance/claims/transactions`);
                    }} className={styles['back-to-player-search-inner-container']}>
                        <i title='left arrow icon' className={styles['back-to-player-search-icon']}>
                            <FontAwesomeIcon size="2x" arial-label='left arrow icon.' icon={faAngleLeft as IconProp}/>
                        </i>
                    </div>
                </div>
                <div
                    className={styles['page-container-body-left-title']}>{rejectFileHeaderTitle} {rejectFileReferenceNumber}</div>
                <div className={styles["top-ribbon"]}>

                    <ACHRejectFileHeaderComponent batchDetailSearchResponse={rejectFileDetailsSearchResponse}
                    />

                </div>
                {pageMessage.message !== "" &&
                <PageMessageCommonComponent message={pageMessage.message} messageType={pageMessage.messageType}/>}
                <div className={styles["page-container-body-container"]}>
                    <div className={styles["page-container-body"]}>
                        <div className={styles["page-container-body-center"]}>
                            <ACHRejectFileTableComponent KYCPlayerData={rejectFileDetailsSearchResponse}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
