export const getPageData = (KYCPlayerData:any[], page:number = 0) =>{
    const array:any= []; 
   for(let i = page * 10; i < ((page * 10) + 10); i++)
   {
         if(!(KYCPlayerData[i] === undefined))
              array.push(KYCPlayerData[i]);  
   }
   return array; 
}

export const normilizeDate = (date:string)=>{
 let dateArray = date.split('Z');
 dateArray = dateArray[0].split('T');
 date = dateArray[0].split("-").join('/');
 let time = dateArray[dateArray.length -1].split('.');

 return `${date} at ${time[0]}`;


}