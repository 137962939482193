import React from 'react'

export type GrossPaymentErrorUIProps = {
  
}

const ROOT_CLASS = 'gross-payment-error-container'
export const GrossPaymentErrorUI = (props: GrossPaymentErrorUIProps) => {
  return (
    <div className={ ROOT_CLASS }>
      Payment Amount Exceeded
    </div>
  )
}
