import React from 'react'
import {
  SearchFuturePaymentsRequest,
  SearchFuturePaymentsResponse,
} from '../../../api/future-payments-fetcher/api-types'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'

const futurePaymentSearchUrl = `${config.SERVER_BASE_URL}/api/v1/payments/future/search`

export const useFetchFuturePaymentsForSearch = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<SearchFuturePaymentsResponse>()
  React.useEffect(() => {
    searchHandler()
    return () => {}
  }, [])

  const searchHandler = (searchCriteria?: SearchFuturePaymentsRequest) => {
    const encodedRequestBody = JSON.stringify(searchCriteria ?? {})
    fetchUrl(futurePaymentSearchUrl, 'POST', {}, encodedRequestBody)
  }

  return {
    isLoading,
    error,
    data,
    searchHandler,
  }
}
