import moment from "moment-timezone";

export const getPageData = (KYCPlayerData: any[], page: number = 0) => {
  const array: any = [];
  for (let i = page * 10; i < page * 10 + 10; i++) {
    if (!(KYCPlayerData[i] === undefined)) array.push(KYCPlayerData[i]);
  }
  return array;
};

export const claimEligibleDefaultToNo = (dataValue: boolean) => {
  let retValue = "";
  if (dataValue) {
    return `Yes`;
  } else {
    retValue = "No";
  }
  return retValue;
};

export const convertAmount = (amount: number) => {
  if (amount === 0 || amount === null) {
    return `$ 0.00`;
  }
  return `$ ${[amount.toString().slice(0, amount.toString().length - 2), ".", amount.toString().slice(amount.toString().length - 2)].join("")}`;
};

export const downloadClicked = (data: any[], csvExport: any) => {
  const rows: any[] = Object.values(data).map(d => {
    let tempDate: string = moment.tz(d.requestTimestamp, "America/New_York").format("MM/DD/YYYY h:mm:ss a");

    let barcode: string = d.barcode;
    let winStatus: string = d.winStatus;
    let claimStatus: string = d.status;
    let tempAmount: string = convertAmount(d.prizeAmount);
    let claimOffered: string = claimEligibleDefaultToNo(d.claimOffered);
    d = {
      date: tempDate,
      claim: barcode,
      status: winStatus,
      barcode: claimStatus,
      amount: tempAmount,
      claimOffered: claimOffered,
    };
    return d;
  });
  csvExport.generateCsv(rows);
  return true;
};

export const normalizeDate = (date: string) => {
  let dateArray = date.split("Z");
  dateArray = dateArray[0].split("T");
  date = dateArray[0].split("-").join("/");
  let time = dateArray[dateArray.length - 1].split(".");
  return `${date} ${time[0]}`;
};
