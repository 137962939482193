import * as React from 'react'
import { GetPendingPaymentsResponse } from '../../../api/pending-payments-fetcher/api-types'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { CheckRunQueueContainerUI } from '../../../components/PaymentComponents/CheckRunQueueComponents/check-run-queue-container/check-run-queue-container-ui'

const ROOT_CLASS = 'check-run-queue-page'

export type CheckRunQueuePageUIProps = {
  pendingPaymentsData: GetPendingPaymentsResponse | undefined
  isLoading: boolean
  error: string | undefined
  fetchInitiateCheckRun: (futurePaymentIds: string[]) => void
  taxAdjustmentStatusLoading: boolean
  initiateCheckRunError: string | undefined
}

export const CheckRunQueuePageUI = (props: CheckRunQueuePageUIProps) => {
  return (
    <AsyncContainerUI
      isLoading={props.isLoading}
      error={props.error}
      errorTextSize={20}
      color="white"
    >
      {props.pendingPaymentsData ? (
        <CheckRunQueueContainerUI
          fetchInitiateCheckRun={props.fetchInitiateCheckRun}
          pendingPaymentsData={props.pendingPaymentsData}
          initiateCheckRunError={props.initiateCheckRunError}
          taxAdjustmentStatusLoading={props.taxAdjustmentStatusLoading}
        />
      ) : (
        <div>Something went wrong</div>
      )}
    </AsyncContainerUI>
  )
}
