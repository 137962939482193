import React, {useState} from "react";
import styles from './css/styles.module.css';
import {getPageData,} from "./functions";
import {PaginationCommonComponent} from '../../CommonComponents/PaginationCommonComponent'
import moment from "moment-timezone";
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";
import stylesMessageBox from "../../../pages/ClaimsPages/MobileClaimDetailsPage/css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../services/Commons";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";
import {BatchDetailSearchResult} from "../../../pages/FinancePages/ACHActivityDetailPage/interfaces";

export const ACHBatchDetailTable = ({KYCPlayerData}: any) => {

    // const kycPlayerObject = JSON.parse(KYCPlayerData);

    // const kycPlayerTotalRows = kycPlayerObject.length;
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showPopup, setShowPop] = useState<boolean>(false);
    const [accountNumPop, setAccountNumPop] = useState<string>('');
    const [accountTypePop, setAccountTypePop] = useState<string>('');
    const [routingPop, setRoutingPop] = useState<string>('');
    const [tracePop, setTracePop] = useState<string>('');

    const userPolicies: any = localStorage.getItem('userpolicies');
    // console.log(JSON.stringify(userPolicies))

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;
    }

    return (
        <>
            <div className={styles['KYCQUEUE-table']}>
                <div className={styles['KYCQUEUE-table-data-header']}>
                    {/*
                       <div aria-live="assertive"
                        tabIndex={0}>{`Displaying ${kycPlayerTotalRows <= 0 ? 0 : (currentPage * 10) + 1}-
                         ${normalizePage(kycPlayerTotalRows, currentPage)} of ${kycPlayerTotalRows} results`}</div>
                         */}
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${KYCPlayerData.length <= 0 ? 0 : (currentPage * 10) + 1}-
                         ${normalizePage(KYCPlayerData.length, currentPage)} of ${KYCPlayerData.length} results`}</div>

                </div>
                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date Submitted</div>
                    <div tabIndex={0}>Time</div>
                    <div tabIndex={0}>Claim ID</div>
                    <div tabIndex={0}>Transaction ID</div>
                    <div tabIndex={0}>Net Amount</div>
                    <div tabIndex={0}>Status</div>
                    <div tabIndex={0}>Trace Number</div>
                    <div tabIndex={0}>&nbsp;</div>
                </div>

                <div className={styles['KYCQUEUE-table-data-body']}>

                    {KYCPlayerData.length > 0 && getPageData(KYCPlayerData, currentPage).map((e: BatchDetailSearchResult, i: number) =>
                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)')}}
                             className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e?.batchnumber ? moment.tz(e.timestamp, "America/New_York").format('MM-DD-YYYY') : ''}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.timestamp, "America/New_York").format('HH:mm')}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e?.claimid}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e?.transactionid}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{normilizeAmount(e?.netamount)}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e?.achpaymenttransactionstatus}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e?.tracenumber}</div>

                            {showPopup &&
                            <div className={stylesMessageBox['window-container']}>
                                <div tabIndex={0} id='manually-approve-kyc-container'
                                     className={stylesMessageBox['manually-approve-kyc-container']}>
                                    <div tabIndex={0}
                                         className={stylesMessageBox['manually-approve-kyc-text']}>Payment
                                        Info
                                    </div>
                                    <div
                                        className={stylesMessageBox['manually-approve-kyc-account-info-container']}>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-label']}>Account
                                            Number
                                        </div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{(e.batchnumber) && accountNumPop}</div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-label']}>Account
                                            Type
                                        </div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{(accountTypePop) && accountTypePop}</div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-label']}>Routing
                                            Number
                                        </div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{(e.batchnumber) && routingPop}</div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-label']}>TraceID
                                        </div>
                                        <div tabIndex={0}
                                             className={stylesMessageBox['manually-approve-kyc-account-info-data']}>{(e.batchnumber) && tracePop}</div>
                                    </div>

                                    <div role='button'
                                         tabIndex={0}
                                         onKeyPress={(e: any) => {
                                             if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                                 setShowPop(false)
                                             }
                                         }
                                         }
                                         onClick={() => {
                                             setShowPop(false)
                                         }}
                                         className={stylesMessageBox['manually-approve-kyc-button-yes']}>
                                        <div className={stylesMessageBox['manually-approve-kyc-yes-text']}>Close
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {checkRenderPermissions(permissions.CAN_SEE_REJECT_MASKED_DETAIL,
                                JSON.parse(userPolicies)) ?
                                <div tabIndex={0} role='button' aria-pressed={"false"}
                                onKeyPress={() => {
                                    setShowPop(true);
                                    setAccountNumPop(e.accountnumber);
                                    setAccountTypePop(e.accounttype);
                                    setRoutingPop(e.routingnumber);
                                    setTracePop(e.tracenumber);
                                 } }
                                     onClick={() => {
                                         setShowPop(true);
                                         setAccountNumPop(e.accountnumber);
                                         setAccountTypePop(e.accounttype);
                                         setRoutingPop(e.routingnumber);
                                         setTracePop(e.tracenumber);
                                     }
                                     } className={styles['KYCQUEUE-table-review-kyc-button']}>
                                    <div aria-label={'Review KYC button.'}
                                         className={styles['KYCQUEUE-table-review-kyc-button-text']}>
                                        View Payment Info
                                    </div>
                                </div> : null}
                        </div>
                    )}
                </div>
                <div className={styles['KYCQUEUE-table-data-footer']}>
                    <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((KYCPlayerData.length / 10)).toString()}`}</div>

                    {/* <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((kycPlayerTotalRows / 10)).toString()}`}</div>

                     */}
                    {/* {<PaginationCommonComponent playerData={kycPlayerObject} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>} */}
                    {<PaginationCommonComponent playerData={KYCPlayerData} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}
                </div>

            </div>


        </>

    );
}
