import { isNil } from 'lodash'
import {
  PaymentDetails,
  PaymentType,
  PlayerPaymentStatus,
  SplitIndicator,
} from '../../../api/payment-details-fetcher/api-types'

export function isChild(payment: PaymentDetails): boolean {
  return (!isNil(payment.parentPaymentId) || payment.source === 'SPLIT')
}

export function isParent(payment: PaymentDetails): boolean {
  const { splitIndicator } = payment
  return (
    splitIndicator !== SplitIndicator.SPLIT_NONE &&
    Object.keys(SplitIndicator).includes(splitIndicator)
  )
}

export function parentIsKnown(payment: PaymentDetails): boolean {
  return !isNil(payment.parentPaymentId)
}

export const isPaymentStatusPaid = (payment: PaymentDetails) => {
  console.log(payment.paymentStatus)
  return payment.paymentStatus === PlayerPaymentStatus.PAID
}

export const isPaymentTypeCheck = (payment: PaymentDetails) => {
  return payment.paymentType === PaymentType.CHECK
}
