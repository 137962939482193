import config from "../../../../config";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import { FirstPaymentListData } from "../../../../types/Annuities/FirstPayment/FirstPayment";

export const getFirstPaymentList: () => Promise<FirstPaymentListData[]> = async () => {
  let firstPaymentListData: FirstPaymentListData[];
  const options = {
    method: "GET",
    url: `${config.SERVER_BASE_URL}/v1/annuities/first-payment/list`,
  };

  try {
    const response = await makeHttpCall(options);
    firstPaymentListData = response;
  } catch (error) {
    throw new Error("Failed to pull back First Payment List");
  }

  return firstPaymentListData;
};
