import * as React from 'react'
import FocusLock from 'react-focus-lock'
import { ActionMenuIcon } from '../../../../../icons/action-menu-icon'
import { getBlockClass } from '../../../../../utilities/helpers'
import './styles.scss'

type ActionMenuLink = {
  onClick: () => void
  label: string
  disabled?: boolean
  ariaLabel: string
  key: string
  hideMenuItem?: boolean
}

export type GenericTableActionMenu = {
  ariaLabel: string
  menuItems: ActionMenuLink[]
  hideMenu?: boolean
}

type GenericTableRowActionMenuProps = {
  actionMenu: GenericTableActionMenu
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ROOT_CLASS = `generic-table-row-action-menu`
const POPUP_CLASS = getBlockClass(ROOT_CLASS, 'popup')
const MENU_ITEM_CLASS = getBlockClass(POPUP_CLASS, 'menu-item')

const getVisibilityClass = (isOpen: boolean) =>
  isOpen ? `${POPUP_CLASS}--open` : `${POPUP_CLASS}--closed`

const getMenuItemEnabledClass = (enabled: boolean) =>
  enabled ? `${MENU_ITEM_CLASS}--enabled` : `${MENU_ITEM_CLASS}--disabled`

export const GenericTableRowActionMenuUI = (
  props: GenericTableRowActionMenuProps
) => {
  return (
    <div className={ROOT_CLASS}>
      {!props.actionMenu.hideMenu && (
        <button
          className={getBlockClass(ROOT_CLASS, 'icon')}
          onClick={() => props.setIsOpen(true)}
          aria-label={props.actionMenu.ariaLabel}
        >
          <ActionMenuIcon />
        </button>
      )}
      {props.isOpen && (
        <div
          className={getBlockClass(ROOT_CLASS, 'overlay')}
          onClick={() => props.setIsOpen(false)}
        />
      )}
      <FocusLock disabled={!props.isOpen} autoFocus={false}>
        <div className={`${POPUP_CLASS} ${getVisibilityClass(props.isOpen)}`}>
          {props.actionMenu?.menuItems
            .filter((menuItem) => !menuItem.hideMenuItem)
            .map((menuItem) => (
              <button
                className={`${MENU_ITEM_CLASS} ${getMenuItemEnabledClass(
                  !menuItem.disabled
                )}`}
                onClick={() => {
                  menuItem.onClick()
                  props.setIsOpen(false)
                }}
                disabled={menuItem.disabled}
                aria-label={menuItem.ariaLabel}
                key={menuItem.key}
              >
                {menuItem.label}
              </button>
            ))}
        </div>
      </FocusLock>
    </div>
  )
}
