import { useEffect } from "react"
import { useFetch } from "../../../hooks/use-fetch"
import config from "../../../config"
import { useHistory } from "react-router-dom"
import { PayeeDetailsResponse } from "../../../api/payee-details-fetcher/api-types"

const PAYEE_DETAILS_BASE_URL = `${config.SERVER_BASE_URL}/api/v1/payments/payeeDetails`
export const useFetchPayeeDetails = (internalPaymentId: string) => {
  const history = useHistory()
  const { isLoading, error, data, fetchUrl } = useFetch<PayeeDetailsResponse>()

  useEffect(() => {
    fetchUrl(`${PAYEE_DETAILS_BASE_URL}/${internalPaymentId}`)
  }, [])

  return {
    isLoading,
    error,
    data
  }
}