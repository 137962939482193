import * as React from 'react'
import { FuturePaymentStatus } from '../../../api/future-payments-fetcher/api-types'
import { FuturePaymentDetailsPageUI } from './future-payment-details-page-ui'
import {
  useFetchFuturePaymentDetails,
  useFetchUpdatePaymentStatus,
} from './use-fetch-future-payment-details'

export type FuturePaymentDetailsPageContainerProps = {
  futurePaymentId: string
}

export const FuturePaymentDetailsPageContainer = (
  props: FuturePaymentDetailsPageContainerProps
) => {
  const {
    isLoading: isFetchDetailsLoading,
    error: fetchDetailsError,
    data,
    fetchPaymentDetails,
  } = useFetchFuturePaymentDetails(props.futurePaymentId)
  const {
    isLoading: isUpdatePaymentStatusLoading,
    error: fetchUpdatePaymentStatusError,
    updatePaymentStatus,
    deletePayment
  } = useFetchUpdatePaymentStatus(props.futurePaymentId)

  const updateFetch = (status: FuturePaymentStatus, notes: string) => {
    updatePaymentStatus(status, notes).then(() => fetchPaymentDetails())
  }

  const deleteFetch = () => {
    deletePayment().then(() => fetchPaymentDetails())
  }

  return (
    <FuturePaymentDetailsPageUI
      isLoading={isFetchDetailsLoading || isUpdatePaymentStatusLoading}
      error={fetchDetailsError ?? fetchUpdatePaymentStatusError}
      futurePaymentDetailsData={data}
      updatePaymentStatus={updateFetch}
      deletePayment={deleteFetch}
    />
  )
}
