import React from "react"
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { Create5754SplitContainerUI } from "../../../components/PaymentComponents/Create5754SplitComponents/create-5754-split-container/create-5754-split-container-ui"
import { FormPayeeDetails, SplitPaymentRequest } from "./types"

export type Create5754SplitPageUIProps = {
  childPayments: FormPayeeDetails[]
  parentPaymentId: string
  originalGrossInDollars: number
  isLoading: boolean
  error: string | undefined
  splitPaymentError: string | undefined
  splitLoading: boolean
  fetchSplitPayment: (request: SplitPaymentRequest) => Promise<void>
}

export const Create5754SplitPageUI = (props: Create5754SplitPageUIProps) => {
  const { 
    isLoading, 
    error, 
    splitPaymentError,
    childPayments, 
    parentPaymentId, 
    originalGrossInDollars,
    splitLoading,
    fetchSplitPayment
  } = props

  return (
    <AsyncContainerUI
      isLoading={ isLoading }
      error={ error }
      color={ 'white' }
    >
      <Create5754SplitContainerUI
        splitPaymentError={ splitPaymentError }
        parentPaymentId={ parentPaymentId }
        childPayments={ childPayments }
        originalGrossInDollars={ originalGrossInDollars }
        splitLoading={ splitLoading }
        fetchSplitPayment={ fetchSplitPayment }
      />
    </AsyncContainerUI>
  )
}