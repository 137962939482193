import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import './styles.scss'

const ROOT_CLASS = 'future-payment-demographic-info'

export type FuturePaymentDemographicInfoUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse
}

export const FuturePaymentDemographicInfoUI = (
  props: FuturePaymentDemographicInfoUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={`row-container-title`}>
        Demographic &amp; Contact Info
      </div>
      <div className={`demographic-info-controls-container`}>
        <div className={`column-1`}>Mailing Address 1</div>
        <div className={`column-2`}>
          {props.futurePaymentDetailsData.address1}
        </div>
        <div className={`column-3`}>Mailing Address 2</div>
        <div className={`column-4`}>
          {props.futurePaymentDetailsData.address2}
        </div>
        <div className={`column-1`}>City</div>
        <div className={`column-2`}>{props.futurePaymentDetailsData.city}</div>
        <div className={`column-3`}>State/Region</div>
        <div className={`column-4`}>{props.futurePaymentDetailsData.state}</div>
        <div className={`column-1`}>Zip/Postal Code</div>
        <div className={`column-2`}>{props.futurePaymentDetailsData.zip}</div>
        <div className={`column-3`}>Country</div>
        <div className={`column-4`}>
          {props.futurePaymentDetailsData.country}
        </div>
        <div className={`column-1`}>Supplemental Line 1</div>
        <div className={`column-2`}>
          {props.futurePaymentDetailsData.supplementalAddress1 ?? (
            <span>--</span>
          )}
        </div>
        <div className={`column-3`}>Supplemental Line 2</div>
        <div className={`column-4`}>
          {props.futurePaymentDetailsData.supplementalAddress2 ?? (
            <span>--</span>
          )}
        </div>
        <div className={`column-1`}>Phone</div>
        <div className={`column-2`}>{props.futurePaymentDetailsData.phone}</div>
        <div className={`column-3`}>Email</div>
        <div className={`column-4`}>{props.futurePaymentDetailsData.email}</div>
      </div>
    </div>
  )
}
