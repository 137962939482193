import * as React from 'react'
import { GenericSidebar } from '../../GenericSidebar/generic-sidebar-ui'
import { AsyncContainerUI } from '../../CommonComponents/async-container-component/async-container-ui'
import { getBlockClass } from '../../../utilities/helpers'
import { PlayslipsStepDownSidebarFormContainer } from './playslips-step-down-sidebar-form/playslips-step-down-sidebar-form-container'
import { PlayslipsAllActivePlayslipsGamesResponse } from '../../../api/playslips-fetcher/constants'
import './styles.scss'

const HEADER_CLASS = 'playslips-step-down-sidebar-header'

export type PlayslipsStepDownSidebarUIProps = {
  closeSidebar: () => void
  isOpen: boolean
  activeGamesLoading: boolean
  activeGamesError?: string
  activeGames?: PlayslipsAllActivePlayslipsGamesResponse
}

export const PlayslipsStepDownSidebarUI = (
  props: PlayslipsStepDownSidebarUIProps
) => {
  return (
    <GenericSidebar isOpen={props.isOpen} closeSideBar={props.closeSidebar}>
      <header className={HEADER_CLASS}>
        <div className={getBlockClass(HEADER_CLASS, 'title')}>
          New Step Down
        </div>
        <button
          className={getBlockClass(HEADER_CLASS, 'close-button')}
          onClick={props.closeSidebar}
          aria-label="Close"
        >
          <div>Close</div>
        </button>
      </header>
      <AsyncContainerUI
        isLoading={props.activeGamesLoading}
        error={props.activeGamesError}
        color="blue"
      >
        {props.activeGames && props.isOpen && (
          <PlayslipsStepDownSidebarFormContainer
            closeSidebar={props.closeSidebar}
            activeGames={props.activeGames}
          />
        )}
      </AsyncContainerUI>
    </GenericSidebar>
  )
}
