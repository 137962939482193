import { PaymentSource } from '../../../api/payment-details-fetcher/api-types'
import { W2gReissueFormFields } from './review-w2g-reissue-page-container'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import React from 'react'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { Formik } from 'formik'
import './styles.scss'
import { ReviewW2gReissueTitleBar } from '../../../components/PaymentComponents/W2gReissueReviewComponents/review-w2g-reissue-title-bar/review-w2g-reissue-title-bar-ui'
import { ReviewW2gReissueFormUI } from '../../../components/PaymentComponents/W2gReissueReviewComponents/review-w2g-reissue-form-ui/review-w2g-reissue-form-ui'

export type ReviewW2gReissuePageUiProps = {
  paymentDetails: W2gReissueFormFields
  paymentSource: PaymentSource
  paymentLoading: boolean
  payeeLoading: boolean
  payeeError?: string
  addToQueueLoading: boolean
  addToQueueError?: string
  reprintImmediatelyLoading: boolean
  reprintImmediatelyError?: string
} & W2gReissueSubmitProps

export type W2gReissueSubmitProps = {
  submitAddToQueue: (request: W2gReissueFormFields) => Promise<void>
  submitReprintImmediately: (request: W2gReissueFormFields) => Promise<void>
}

const ROOT_CLASS = 'review-W2g-reissue-page'

export const ReviewW2gReissuePageUI = (props: ReviewW2gReissuePageUiProps) => {
  const {
    paymentDetails,
    paymentSource,
    paymentLoading,
    payeeLoading,
    addToQueueLoading,
    addToQueueError,
    submitAddToQueue,
    reprintImmediatelyLoading,
    reprintImmediatelyError,
    submitReprintImmediately,
    payeeError,
  } = props

  const onClick = () => {
    console.log('Submitted successfully')
  }

  return (
    <>
      <BackButtonUI tabIndex={0} message={'Back to Payment Details'} />
      <AsyncContainerUI
        isLoading={
          payeeLoading ||
          paymentLoading ||
          addToQueueLoading ||
          reprintImmediatelyLoading
        }
        error={payeeError || addToQueueError || reprintImmediatelyError}
        color={'white'}
      >
        <Formik initialValues={paymentDetails} onSubmit={onClick}>
          {(props) => {
            const paymentDetailsRequest = {...props.values, ssn: props.values.tin}
            return (
              <div className={ROOT_CLASS}>
                <ReviewW2gReissueTitleBar
                  submitButtonEnabled={props.isValid}
                  submitAddToQueue={submitAddToQueue}
                  submitReprintImmediately={submitReprintImmediately}
                  paymentDetails={paymentDetailsRequest}
                />
                <form onSubmit={props.handleSubmit}>
                  <ReviewW2gReissueFormUI
                    paymentDetails={paymentDetailsRequest}
                    paymentSource={paymentSource}
                  />
                </form>
              </div>
            )
          }}
        </Formik>
      </AsyncContainerUI>
    </>
  )
}
