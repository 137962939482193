import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {keyPressedType} from "../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../icons/Icon_arrows_carrot_white-left.svg";
import {downloadW2GPrintReport, mapRawSweepPrintStatus, onSubmit, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";
import {
    GenericErrorDialogComponent
} from "../../../components/CommonComponents/GenericErrorDialogComponent";
import {getPageData} from "../../CharitableGamingPages/PullTab/Inventory/PullTabInventoryPage/functions";
import {formatCentsToDollars} from "../../../util";
import moment from "moment";
import {w2gQueueRoutePath} from "../../../config";

export const W2GPrintHistoryPage = () => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            sweepId: ''
        }, validate, onSubmit
    });

    const [W2GQueue, setW2GQueue] = useState<any>(null);

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [reportDownloadCompleted, setReportDownloadCompleted] = useState<boolean>(true)
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        if (requestCompleted === false) {
            formik.submitForm();
        }
    }, []);

    setVariables(setRequestCompleted, setReportDownloadCompleted, setW2GQueue, setCurrentPage, setShowErrorDialog);

    return (<>
            {(!requestCompleted || !reportDownloadCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['pulltab-inventory-page-container']} ${styles['']}`}
                 onClick={() => {

                 }}
            >
                <div tabIndex={0}
                     className={`${styles['pulltab-inventory-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             history.goBack();
                         }
                     }}
                     onClick={() => {
                         history.goBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['pulltab-inventory-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >Back to W-2G Queue
                    </div>
                </div>
                <div className={`${styles['pulltab-inventory-page-ribbon-container']} ${styles['']}`}>
                    <div className={`${styles['pulltab-inventory-page-ribbon-title']} ${styles['']}`}>W-2G Print History
                    </div>
                </div>

                {!W2GQueue ? <div className={`${styles['pulltab-inventory-page-search-no-results-container']}`}>
                    <div className={`${styles['pulltab-inventory-page-search-no-results-container-text']}`}>

                    </div>
                </div> : <div className={styles['table']}>
                    {/*<div className={styles['table-data-header']}>
                        <div className={styles['table-data-header-text']}
                            aria-live="assertive">{`Displaying ${W2GQueue.prints.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(W2GQueue.prints.length, currentPage)} 
                        of ${W2GQueue.prints.length} results`}</div>
                    </div>*/}

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div>Date & Time Generated</div>
                        <div>Reportable Payment</div>
                        <div>Net Payment</div>
                        <div>Federal Tax</div>
                        <div>State Tax</div>
                        <div>Status</div>
                    </div>

                    <div className={styles['table-data-body']}>
                        {W2GQueue.prints.length > 0 && getPageData(W2GQueue.prints, currentPage).map((e: any, i: number) =>

                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div className={styles['table-data-data-text']}>
                                    {moment(e.initiatedDate).format('MM/DD/YYYY hh:mm A')}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {formatCentsToDollars(e.reportableAmountInCents)}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {formatCentsToDollars(e.netPaymentInCents)}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {formatCentsToDollars(e.federalTaxInCents)}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {formatCentsToDollars(e.stateTaxInCents)}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {mapRawSweepPrintStatus(e.status)}
                                </div>
                                <div className={styles['table-details-button-container']}>
                                    {checkRenderPermissions(permissions.CAN_DOWNLOAD_W2G_PRINT_REPORT, JSON.parse(userpolicies)) ?
                                            <button
                                                style={{
                                                    opacity: (e.status.toLowerCase() !== 'pending') ? "1" : "0.4",
                                                    cursor: (e.status.toLowerCase() !== 'pending') ? "pointer" : "not-allowed",
                                                }}
                                                onClick={() => {
                                                    downloadW2GPrintReport(e.sweepId);
                                                }}
                                                className={styles['download-report-button']}>
                                                <div aria-label={'Download Report Button'}
                                                    className={styles['table-details-button-text']}>
                                                    Download Report
                                                </div>
                                            </button> : null}
                                    {checkRenderPermissions(permissions.CAN_SEE_W2G_PRINT_DETAILS, JSON.parse(userpolicies)) ?
                                        <div tabIndex={0} role='button' aria-pressed={"false"}
                                             style={{
                                                 opacity: (e.status.toLowerCase() !== 'pending') ? "1" : "0.4",
                                                 cursor: (e.status.toLowerCase() !== 'pending') ? "pointer" : "not-allowed",
                                             }}
                                             onKeyDown={(en: any) => {
                                                 if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {
                                                     history.push(`${w2gQueueRoutePath}/print-details/${e.sweepId}`);
                                                 }
                                             }}
                                             onClick={() => {
                                                 history.push(`${w2gQueueRoutePath}/print-details/${e.sweepId}`);
                                             }}
                                             className={styles['view-print-details-button']}>
                                            <div aria-label={'Licensee Details Button.'}
                                                 className={styles['table-details-button-text']}>
                                                View Print Details
                                            </div>
                                        </div> : null}
                                </div>
                            </div>)}
                    </div>
                    {/*<div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((W2GQueue.prints.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={W2GQueue.prints} currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>*/}
                </div>}
            </div>
            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
        </>);
};