import * as React from 'react'

export const CircleX = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <title>{'X Button'}</title>
    <path
      d="M8.80469 0.328125C4.38641 0.328125 0.804688 3.90985 0.804688 8.32812C0.804688 12.7464 4.38641 16.3281 8.80469 16.3281C13.223 16.3281 16.8047 12.7464 16.8047 8.32812C16.8047 3.90985 13.223 0.328125 8.80469 0.328125Z"
      fill="white"
    />
    <path
      d="M12.572 4.56077C12.8822 4.87096 12.8822 5.38128 12.572 5.69148L9.93539 8.32812L12.572 10.9648C12.8822 11.275 12.8822 11.7853 12.572 12.0955C12.2618 12.4057 11.7515 12.4057 11.4413 12.0955L8.80469 9.45883L6.16804 12.0955C5.85785 12.4057 5.34753 12.4057 5.03733 12.0955C4.72714 11.7853 4.72714 11.275 5.03733 10.9648L7.67398 8.32812L5.03733 5.69148C4.72714 5.38128 4.72714 4.87096 5.03733 4.56077C5.34753 4.25058 5.85785 4.25058 6.16804 4.56077L8.80469 7.19742L11.4413 4.56077C11.7515 4.25058 12.2618 4.25058 12.572 4.56077Z"
      fill="#121921"
    />
  </svg>
)
