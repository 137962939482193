import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {CGReportingRoutePath} from "../../../../config.js";
import {charitableRoutePath} from "../../../../config";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import CustomCard from "../../../../dashboard/customCard";

export const ReportingHomePage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        return (
            <>

                <div className={`${styles['license-profile-mgmt-page-container']} ${styles['']}`}>
                    <div tabIndex={0}
                         className={`${styles['license-profile-mgmt-page-back-button-container']} ${styles['']}`}>
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.goBack();
                                }
                            }}
                            onClick={() => {
                                history.goBack();
                            }}
                        />
                        <div
                            className={`${styles['license-profile-mgmt-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${charitableRoutePath}/`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${charitableRoutePath}/`);
                            }}
                        >Back
                            to Charitable Gaming
                        </div>
                    </div>
                    <div className={`${styles['license-profile-mgmt-page-ribbon-container']} ${styles['']}`}>
                        <div tabIndex={0}
                             className={`${styles['license-profile-mgmt-page-ribbon-title']} ${styles['']}`}>Reporting
                        </div>
                    </div>

                    <div className={styles["page-content"]}>
                        <div className={styles["btn-align"]}>
                            {checkRenderPermissions(permissions.CAN_SEE_WEEKLY_BEANO_REPORTS, JSON.parse(userpolicies)) ?
                                <CustomCard
                                    title={'Weekly Beano Reporting'}
                                    iconPath={null}
                                    handleCallback={() => {
                                        history.push(`${CGReportingRoutePath}/weekly-beano`);
                                    }}
                                /> : null}
                            {checkRenderPermissions(permissions.CAN_SEE_ANNUAL_BEANO_REPORTS, JSON.parse(userpolicies)) ?
                                <CustomCard
                                    title={'Annual Beano Reporting'}
                                    iconPath={null}
                                    handleCallback={() => {
                                        history.push(`${CGReportingRoutePath}/annual-beano`);
                                    }}
                                /> : null}
                            {checkRenderPermissions(permissions.CAN_SEE_RAFFLE_CASINO_REPORTS, JSON.parse(userpolicies)) ?
                                <CustomCard
                                    title={'Raffle/Casino Night Reporting'}
                                    iconPath={null}
                                    handleCallback={() => {
                                        history.push(`${CGReportingRoutePath}/raffle-casino`);
                                    }}
                                /> : null}
                        </div>
                    </div>

                </div>

            </>

        );
    })
);