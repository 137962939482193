import * as React from 'react'
import { useHistory } from 'react-router-dom'
import {
  AllPromotionsResponse,
  WeeklyCheckRunResponse,
} from '../../../../api/promotions-fetcher/constants'
import { playerSupportRoutePath } from '../../../../config.js'
import { formatCentsToDollars } from '../../../../util'
import { getBlockClass } from '../../../../utilities/helpers'
import { AsyncContainerUI } from '../../../CommonComponents/async-container-component/async-container-ui'
import { GenericTableUI } from '../../../CommonComponents/generic-table-components/generic-table-ui'
import {
  getNextTuesday2AM,
  mapWeeklyCheckRunWinnerRow,
  weeklyCheckRunColumnMap,
} from './helpers'
import {
  PromotionSelection,
  WeeklyRunPromotionForm,
} from './promotions-form/weekly-run-promotion-form'
import './styles.scss'

export type WinnersManagementWeeklyRunProps = {
  promotionsLoading: boolean
  promotions?: AllPromotionsResponse
  promotionsError?: string
  checkRunLoading: boolean
  checkRunReport?: WeeklyCheckRunResponse
  checkRunError?: string
  generateCheckRunReport: (promotionId?: string) => Promise<void>
  downloadCheckRunCSV: (promotionId?: string) => Promise<void>
}

const ROOT_CLASS = 'winners-management-weekly-run'
const CONTENT_CLASS = `${ROOT_CLASS}-content`

export const WinnersManagementWeeklyRunUI = (
  props: WinnersManagementWeeklyRunProps
) => {
  const [selectedPromotion, setSelectedPromotion] = React.useState<string>()
  const [promotionsList, setPromotionsList] =
    React.useState<PromotionSelection[]>()

  const history = useHistory()

  React.useEffect(() => {
    const promotions = props.promotions?.map((promotion) => ({
      promotionId: promotion.id,
      promotionName: promotion.name,
    }))

    setPromotionsList(promotions)
  }, [props.promotions])

  const checkRunTotalPayments = props.checkRunReport?.length ?? '--'
  const checkRunTotalPrizeAmount = props.checkRunReport
    ? formatCentsToDollars(
        props.checkRunReport.reduce(
          (a, b): number => a + b.prizeValueInCents,
          0
        )
      )
    : '--'

  const headerText = `Total Payments: ${
    props.checkRunLoading || props.promotionsLoading
      ? '--'
      : checkRunTotalPayments
  } • Total Gross Prize Amount: ${
    props.checkRunLoading || props.promotionsLoading
      ? '--'
      : checkRunTotalPrizeAmount
  }`

  const mappedData =
    props.checkRunReport
      ?.sort((a, b) => Number(a.claimNumber) - Number(b.claimNumber))
      .map((winner) =>
        mapWeeklyCheckRunWinnerRow(winner, () =>
          history.push(
            `${playerSupportRoutePath}/player-hub-new/${winner.playerId}`
          )
        )
      ) ?? []

  return (
    <div className={ROOT_CLASS}>
      <WeeklyRunPromotionForm
        loading={props.promotionsLoading || props.checkRunLoading}
        promotionsList={promotionsList}
        promotionsError={props.promotionsError}
        setSelectedPromotion={setSelectedPromotion}
        generateCheckRunReport={props.generateCheckRunReport}
      />
      <section className={getBlockClass(ROOT_CLASS, 'header')}>
        {headerText}
      </section>
      <AsyncContainerUI isLoading={props.checkRunLoading} color="white">
        {props.checkRunReport && (
          <section className={CONTENT_CLASS}>
            <div className={getBlockClass(CONTENT_CLASS, 'header')}>
              <div className={getBlockClass(CONTENT_CLASS, 'title')}>
                Weekly Run • {getNextTuesday2AM().format('MM/DD/YY')}
                <span className={getBlockClass(CONTENT_CLASS, 'disclaimer')}>
                  Winners are sent to the check writer Tuesdays at 2:00 AM
                </span>
              </div>
              <button
                key={'download-csv-button'}
                className={getBlockClass(CONTENT_CLASS, 'download-csv-button')}
                aria-label="Download Table as .CSV"
                disabled={props.checkRunLoading}
                onClick={async () =>
                  await props.downloadCheckRunCSV(selectedPromotion)
                }
              >
                Download Table as .CSV
              </button>
            </div>
            <GenericTableUI
              columnMap={weeklyCheckRunColumnMap}
              rowData={mappedData}
              usePages
              rowsPerPage={25}
              customRowCellStyling={{ padding: '14px 16px' }}
              customHeaderCellStyling={{ paddingLeft: '16px' }}
            />
          </section>
        )}
      </AsyncContainerUI>
    </div>
  )
}
