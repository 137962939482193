import { AccountInterface, getPlayersDetailsInterface, IdentityInterface } from "../../pages/PlayersPages/UpdateProfilePage/interfaces"
import { playersIdentityValues } from './../Commons';
import { getPlayersDetailsData } from "./Player";


const storesObject:any ={};


const testPlayerStoreObjectData = (response:any )=>{
 
    let defaultData:any = JSON.parse(JSON.stringify(getPlayersDetailsData));
    let dataKeys:any = Object.keys(defaultData);
    let dummyResponse:any = { identity:{}, account:{}, identityDocument:{}}
    if(response === undefined || response === null || response === "")
          return defaultData;
    for(let i = 0 ; i < dataKeys.length; i++)
    {
      
        if(response[dataKeys[i]] === undefined || response[dataKeys[i]] === null || response[dataKeys[i]] === "")
        {
                       
            response[dataKeys[i]] = defaultData[dataKeys[i]];
            dummyResponse[dataKeys[i]].fieldStatus = "";
        }
        else{
            Object.keys(defaultData[dataKeys[i]]).map((key:string)=>{
                
                if(response[dataKeys[i]][key] === undefined ||response[dataKeys[i]][key] === null || response[dataKeys[i]][key] === "")
                   response[dataKeys[i]][key] = defaultData[dataKeys[i]][key];
            });
        }     
    }
    if(dummyResponse.identity.fieldStatus === "")
       playersIdentityValues.identityFieldIsUndefined = true;
    else
       playersIdentityValues.identityFieldIsUndefined = false;

    if(dummyResponse.account.fieldStatus === "")
       playersIdentityValues.accountFieldIsUndefined = true;
    else
       playersIdentityValues.accountFieldIsUndefined = false;
    return response; 
} 



export { storesObject , getPlayersDetailsData , testPlayerStoreObjectData }