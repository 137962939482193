import * as React from 'react'
import { GetPaymentDetailsResponse } from '../../../../api/payment-details-fetcher/api-types'
import { checkRenderPermissions, checkAllRenderPermissions } from '../../../../helpers'
import { permissions } from '../../../../constants'
import { paymentsRoutePath } from '../../../../config'
import './styles.scss'
import { VoidOptionsUI } from '../payment-details-void-options/payment-details-void-options-ui'
import { useHistory } from 'react-router-dom'
import { PayeeTypeModalUI } from '../payment-details-payee-type-ui/payment-details-payee-type-ui'
import {
  isParent,
  isChild,
  parentIsKnown,
  isPaymentStatusPaid,
  isPaymentTypeCheck,
} from '../../../../pages/PaymentsPages/PaymentDetailsPage/functions'
import { PaymentMessageUI } from '../payment-details-payment-message/payment-details-payment-message'
import { ConfirmationPopupUI } from '../../../CommonComponents/ConfirmationPopupComponent/confirmation-popup-ui'

const ROOT_CLASS = 'payment-details-title-bar'

export type PaymentDetailsTitleBarUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
  voidPayment: (internalPaymentId: string) => void
}

export const PaymentDetailsTitleBar = (
  props: PaymentDetailsTitleBarUIProps
) => {
  const history: any = useHistory()
  const [showVoidOptionsModal, setShowVoidOptionsModal] =
    React.useState<boolean>(false)
  const [showPayeeTypeModal, setShowPayeeTypeModal] =
    React.useState<boolean>(false)
  const [showVoidConfirmDialog, setShowVoidConfirmDialog] =
    React.useState<boolean>(false)

  const userpolicies: any = localStorage.getItem('userpolicies')

  const paymentIsParent = isParent(props.paymentDetailsData.payment)
  const paymentIsChild = isChild(props.paymentDetailsData.payment)
  const isParentKnown = parentIsKnown(props.paymentDetailsData.payment)
  const paymentStatusIsPaid = isPaymentStatusPaid(
    props.paymentDetailsData.payment
  )
  const paymentTypeIsCheck = isPaymentTypeCheck(
    props.paymentDetailsData.payment
  )

  const addSplit = function (payeeType: string) {
    const queryParams = new URLSearchParams()
    queryParams.append('initial-payee-type', payeeType)
    const { internalPaymentId } = props.paymentDetailsData.payment
    history.push({
      pathname: `${paymentsRoutePath}/create-5754-split/${internalPaymentId}`,
      search: queryParams.toString(),
      // state: {
      //   initialPayeeType: payeeType,
      //   internalPaymentId: props.paymentDetailsData.payment.internalPaymentId,
      // },
    })
  }

  const optionallyRenderVoidOptionsCTA = (): React.ReactNode | undefined => {
    if (paymentIsChild || !paymentStatusIsPaid || !paymentTypeIsCheck) {
      return null
    }

    const canVoidReissue = checkAllRenderPermissions([permissions.CAN_VOID_AND_REISSUE_PAYMENT, permissions.CAN_SEE_SSN], JSON.parse(userpolicies))
    const canVoid = checkRenderPermissions(permissions.CAN_VOID_PAYMENTS, JSON.parse(userpolicies))

    return (canVoid || canVoidReissue) &&
      <button
        className="void-options button"
        onClick={() => {
          setShowVoidOptionsModal(true)
        }}
      >
        <div className="button-label">Void Options</div>
      </button>
  }

  const optionallyRenderW2GReIssueCTA = (): React.ReactNode | undefined => {
    if (paymentIsParent || !paymentStatusIsPaid) {
      return null
    }
    return checkRenderPermissions(
      permissions.CAN_REISSUE_W2G,
      JSON.parse(userpolicies)
    ) ? (
      <button
        className="w2g-reissue button"
        onClick={() => {
          history.push(
            `${paymentsRoutePath}/review-w2g-reissue/${props.paymentDetailsData.payment.internalPaymentId}`
          )
        }}
      >
        <div className="button-label">W-2G Re-Issue</div>
      </button>
    ) : null
  }

  const optionallyRender5754SplitCTA = (): React.ReactNode | undefined => {
    console.log('RENDERING 5754 SPLIT BUTTON')
    if (paymentIsParent || !paymentStatusIsPaid) {
      console.log('NOT RENDERING 5754 SPLIT BUTTON')
      console.log(`paymentIsParent: ${paymentIsParent}`)
      console.log(`paymentStatusIsPaid: ${paymentStatusIsPaid}`)
      return null
    }
    return checkRenderPermissions(
      permissions.CAN_CREATE_5754_SPLIT,
      JSON.parse(userpolicies)
    ) ? (
      <button
        className="split button"
        onClick={() => {
          setShowPayeeTypeModal(true)
        }}
      >
        <div className="button-label">5754 Split</div>
      </button>
    ) : null
  }

  return (
    <div className={ROOT_CLASS}>
      <div className="title">Payment Details</div>
      <div className="buttons-container">
        <PaymentMessageUI
          paymentDetailsData={props.paymentDetailsData.payment}
          isParent={paymentIsParent}
          isChild={paymentIsChild}
          isParentKnown={isParentKnown}
        />
        {optionallyRenderVoidOptionsCTA()}
        {optionallyRenderW2GReIssueCTA()}
        {optionallyRender5754SplitCTA()}
      </div>
      {showVoidOptionsModal && (
        <VoidOptionsUI
          paymentDetailsData={props.paymentDetailsData}
          showConfirmVoidDialog={() => setShowVoidConfirmDialog(true)}
          setShowVoidOptionsModal={setShowVoidOptionsModal}
        />
      )}
      {showVoidConfirmDialog &&
        <ConfirmationPopupUI 
          message='Are you sure you want to void this payment?'
          confirmCallback={() => props.voidPayment(props.paymentDetailsData.payment.internalPaymentId)}
          closePopup={() => setShowVoidConfirmDialog(false)}
        />
      }
      {showPayeeTypeModal && (
        <PayeeTypeModalUI
          addSplit={addSplit}
          internalPaymentId={props.paymentDetailsData.payment.internalPaymentId}
          setShowPayeeTypeModal={setShowPayeeTypeModal}
        ></PayeeTypeModalUI>
      )}
    </div>
  )
}
