import * as React from 'react'
import './styles.scss'

export type CampaignsRedemptionPieChartProps = {
  percentRedeemed?: number
}

const ROOT_CLASS = 'redemption-pie-chart'

export const CampaignsRedemptionPieChart = (props: CampaignsRedemptionPieChartProps) => (
  <>{props.percentRedeemed && <div className={ROOT_CLASS} style={{ '--value': props.percentRedeemed ?? 0 }} />}</>
)
