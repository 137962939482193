import * as React from 'react'
import {
  AllPromotionsResponse,
  PromotionsHourlyReportProps,
  PromotionsHourlyReportResponse,
} from '../../../api/promotions-fetcher/constants'
import { PromotionsHourlyReportFilterUI } from '../promotions-hourly-report-filter/promotions-hourly-report-filter-ui'
import { PromotionsHourlyReportSummaryUI } from '../promotions-hourly-report-summary/promotions-hourly-report-summary-ui'
import './styles.scss'

export type PromotionsHourlyReportUIProps = {
  promotions?: AllPromotionsResponse
  reportLoading: boolean
  reportError?: string
  reportData?: PromotionsHourlyReportResponse
  generateReport: (props: PromotionsHourlyReportProps) => void
  resetData: () => void
}

const ROOT_CLASS = 'promotions-hourly-report-ui'

export const PromotionsHourlyReportUI = (
  props: PromotionsHourlyReportUIProps
) => {
  const showSummary =
    props.reportLoading || props.reportData || props.reportError
  return (
    <section className={ROOT_CLASS}>
      <PromotionsHourlyReportFilterUI {...props} />
      {showSummary && <PromotionsHourlyReportSummaryUI {...props} />}
    </section>
  )
}
