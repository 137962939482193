import React, {useState} from "react";
import styles from './css/styles.module.css';
import {CheckboxComponent} from "../../../CommonComponents/CheckboxComponent";
import {keyPressedType} from "../../../../services/Commons";
import {ErrorMessageFieldCommonComponent} from "../../../CommonComponents/ErrorMessageFieldCommonComponent";
import {useHistory} from "react-router-dom";
import filterExpandIcon from "../../../../icons/Icon_arrows_carrot_teal-down.svg";
import filterCollapseIcon from "../../../../icons/Icon_arrows_carrot_teal-up.svg";
import {clearSearch} from "../../../../pages/CharitableGamingPages/Permit/PermitProfileManagementPage/functions";

export const PermitSearchBarComponent = ({formik, setFieldValue, handleCheckboxChange}: any) => {

    const history: any = useHistory();

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const activateSearchButton = ((formik.values.streetAddress !== '' ||
        formik.values.orgName !== '' ||
        formik.values.city !== '' ||
        formik.values.raffleId !== '')
        && Object.keys(formik.errors).length === 0);

    return (
        <>

            <div className={`${styles['license-profile-mgmt-page-search-bar-container']} ${styles['']}`}>
                <div tabIndex={0} className={`${styles['license-profile-mgmt-page-search-bar-title']} ${styles['']}`}>Search</div>
                <div className={`${styles['license-profile-mgmt-page-search-bar-filter-container']} ${styles['']}`}>
                    <div className={`${styles['license-profile-mgmt-page-search-bar-filter-title-bar']} ${styles['']}`}>
                        <div tabIndex={0} className={`${styles['license-profile-mgmt-page-search-bar-filter-title']} ${styles['']}`}>Filter
                        </div>
                        <div
                            className={`${styles['license-profile-mgmt-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}>
                            <img tabIndex={0}
                                src={showFilters ? filterCollapseIcon : filterExpandIcon}
                                className={styles[""]}
                                alt="filter expander icon"
                                style={{
                                    cursor: "pointer"
                                }}
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                        setShowFilters(!showFilters);
                                    }
                                }}
                                onClick={() => {
                                    setShowFilters(!showFilters);
                                }}
                            />
                        </div>
                    </div>
                </div>

                {showFilters ?
                        <div
                            className={`${styles['license-profile-mgmt-page-search-bar-filter-expanded-container']} ${styles['']}`}>
                            <div
                                className={`${styles['license-profile-mgmt-page-checkbox-container']} ${styles['']}`}>
                                <div tabIndex={0} className={`${styles['license-profile-mgmt-page-checkbox']} ${styles['']}`}
                                     style={{
                                         cursor: "pointer"
                                     }}
                                     onKeyDown={(e: any) => {
                                         if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                             handleCheckboxChange('includeActivePermitHolders', formik);
                                             formik.submitForm();
                                         }
                                     }}
                                     onClick={(e: any) => {
                                         handleCheckboxChange('includeActivePermitHolders', formik);
                                         formik.submitForm();
                                     }}
                                >
                                    <CheckboxComponent isChecked={formik.values.includeActivePermitHolders}/>
                                </div>
                                <div
                                    className={`${styles['license-profile-mgmt-page-search-bar-filter-active']} ${styles['']}`}>Active
                                </div>
                            </div>
                            <div
                                className={`${styles['license-profile-mgmt-page-checkbox-container']} ${styles['']}`}>
                                <div tabIndex={0} className={`${styles['license-profile-mgmt-page-checkbox']} ${styles['']}`}
                                     style={{
                                         cursor: "pointer"
                                     }}
                                     onKeyDown={(e: any) => {
                                         if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                             handleCheckboxChange('includeInactivePermitHolders', formik);
                                             formik.submitForm();
                                         }
                                     }}
                                     onClick={() => {
                                         handleCheckboxChange('includeInactivePermitHolders', formik);
                                         formik.submitForm();
                                     }}
                                >
                                    <CheckboxComponent isChecked={formik.values.includeInactivePermitHolders}/>
                                </div>
                                <div
                                    className={`${styles['license-profile-mgmt-page-search-bar-filter-inactive']} ${styles['']}`}>Expired
                                </div>
                            </div>
                        </div> : null}

                <div
                    className={`${styles['license-profile-mgmt-page-search-bar-input-button-container']} ${styles['']}`}>

                    <div className={`${styles['license-profile-mgmt-page-search-bar-input-button-container-row-1']} ${styles['']}`}>
                        <div
                            className={`${styles['license-profile-mgmt-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div tabIndex={0} className={`${styles['mslc-label']} ${styles['']}`}>Name of Organization</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="orgName"
                                   name="orgName"
                                   value={formik.values.orgName}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.orgName ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.orgName}/> : null}
                        </div>
                        <div
                            className={`${styles['license-profile-mgmt-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div tabIndex={0} className={`${styles['mslc-label']} ${styles['']}`}>Address</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="streetAddress"
                                   name="streetAddress"
                                   value={formik.values.streetAddress}
                                   maxLength={5}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.streetAddress ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.streetAddress}/> : null}
                        </div>

                        <div
                            className={`${styles['license-profile-mgmt-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div tabIndex={0} className={`${styles['mslc-label']} ${styles['']}`}>City/Town</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="city"
                                   name="city"
                                   value={formik.values.city}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.city ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.city}/> : null}
                        </div>
                        <div tabIndex={0} className={`${styles['mslc-search-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateSearchButton) ? "1" : "0.4",
                                 cursor: (activateSearchButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     if (activateSearchButton) {
                                         formik.submitForm();
                                     }
                                 }
                             }}
                             onClick={() => {
                                 if (activateSearchButton) {
                                     formik.submitForm();
                                 }
                             }}>Search

                        </div>
                        <div tabIndex={0} className={`${styles['mslc-default-button']} ${styles['']}`}
                             role="button"
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     clearSearch(formik);
                                 }
                             }}
                             onClick={() => {
                                 clearSearch(formik);
                             }}>Clear Search
                        </div>
                    </div>
                    <div className={`${styles['license-profile-mgmt-page-search-bar-input-button-container-row-2']} ${styles['']}`}>
                        <div
                            className={`${styles['license-profile-mgmt-page-search-bar-label-textbox-container']} ${styles['']}`}>
                            <div tabIndex={0} className={`${styles['mslc-label']} ${styles['']}`}>Raffle ID</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="raffleId"
                                   name="raffleId"
                                   value={formik.values.raffleId}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e, formik);
                                   }}/>
                            {formik.errors.raffleId ?
                                <ErrorMessageFieldCommonComponent errorMessages={formik.errors.raffleId}/> : null}
                        </div>
                    </div>


                </div>
            </div>

        </>
    );
}