import React from 'react'
import './styles.scss'

const ROOT_CLASS = 'check-run-title-bar'

export const CheckRunReportingTitleBar = () => {
  return (
    <div className={ROOT_CLASS}>
      <div className={'check-run-title'}>General Check Run Reporting</div>
    </div>
  )
}
