import config from "../../../config";
import {makeHttpCall} from "../../../services/MakeHttpCall";
import {ExportToCsv} from "export-to-csv";

let setRequestCompleted: any;
let setW2GQueue: any;
let setCurrentPage: any;
let setPageMessage: any;
let setShowErrorDialog: any;

export const setVariables = (setRequestCompletedObj: any, setW2GQueueObj: any, setCurrentPageObj: any, setPageMessageObj: any, setShowErrorDialogObj: any) => {

    setRequestCompleted = setRequestCompletedObj;
    setW2GQueue = setW2GQueueObj;
    setCurrentPage = setCurrentPageObj;
    setPageMessage = setPageMessageObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    //alert(JSON.stringify(values));
    getW2GQueuePrintDetails(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            sweepId: ''
        }
    });


}

export const getPageData = (licensees: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(licensees[i] === undefined)) {
            licensees[i].index = index;
            index++;
            array.push(licensees[i]);
        }
    }
    return array;
}

const getW2GQueuePrintDetails = async (values: any) => {

    setCurrentPage(0);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/w2gQueue/${values.sweepId}`
        }

        const response = await makeHttpCall(options);

        setW2GQueue(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

export const exportW2GPrintDetails = async (W2GQueue: any, sweepId: string) => {

    try {

        let input = [];

        for (let payment of W2GQueue.paymentsInSweep) {
            input.push({
                paymentKey: payment.paymentKey,
                payeeName: payment.payeeName,
                payeeSsn: payment.payeeSsn,
                reportablePaymentDate: payment.reportablePaymentDate,
                reportableWinningsInCents: payment.reportableWinningsInCents,
                irsTaxInCents: payment.irsTaxInCents,
                dorTaxInCents: payment.dorTaxInCents,
                offsetsWithheldInCents: payment.offsetsWithheldInCents,
                netPaymentInCents: payment.netPaymentInCents
            });
        }

        const fileOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: `W2G-Print-Details-SweepId-${sweepId}`,
            title: '',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: ["PMT/TMPT Key", "Payee Name", "TIN", "Date", "Reportable Payment", "Federal Tax", "State Tax", "Offsets", "Net Payment"]
        };

        const csvExporter = new ExportToCsv(fileOptions);
        await csvExporter.generateCsv(input);

    } catch (e) {
        console.log(e);
    }

}