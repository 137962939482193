import config from "../../../../config.js"
import { TimePaymentAuthSchedule } from "../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization";
import { makeHttpCall } from "../../../../services/MakeHttpCall";
import moment from "moment";

const normalizePage = (totalPages: number, currentPage: number) => {
    const number: number = totalPages - currentPage * 10;
    if (number > 10) {return currentPage * 10 + 10;}
    else {return totalPages;}
  };
  
  const getPageData = (forecastedPayments: any[], page: number = 0) => {
    const array: any = [];
    for (let i = page * 10; i < page * 10 + 10; i++) {
      if (!(forecastedPayments[i] === undefined)) {array.push(forecastedPayments[i]);}
    }
    return array;
  };

  const getPaymentData = async (fromDate: string, throughDate: string): Promise<TimePaymentAuthSchedule[]> => {
    const options = {
      method: "get",
      url: `${config.SERVER_BASE_URL}/v1/annuities/check-run/reporting?fromDate=${fromDate}&throughDate=${throughDate}`,
    };
  
    let authorizationData;
  
    try {
      const response: any = await makeHttpCall(options);
  
      // convert createdOn from string to Date object to run sorting method
      const convertedAuthorizationData = response.map((authorization: any) => {
        return { ...authorization, payment: { ...authorization.payment, paymentDate: new Date(authorization.payment.paymentDate) } };
      });
  
      // sort array
      convertedAuthorizationData.sort((objA: any, objB: any) => Number(objA.payment.paymentDate) - Number(objB.payment.paymentDate));
  
      authorizationData = convertedAuthorizationData;
    } catch (error) {
      throw new Error("Something went wrong capturing Authorization Data");
    }
  
    return authorizationData;
  };

  // Csv
const exportToCsv = (authorizationData: TimePaymentAuthSchedule[], csvExport: any) => {
  // Format currency strings
  const currencyFormatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  });

  // create new object per Authorization Data to produce csv
  const rows: any[] = Object.values(authorizationData).map(individualAuthorization => {
    let name;

    if (individualAuthorization.payee.payeeType === "Individual") {
      name = `${individualAuthorization.payee.firstName} ${individualAuthorization.payee.lastName}`;
    } else {
      name = `${individualAuthorization.payee.freeformName}`;
    }

    const newData = {
      documentId: individualAuthorization.timePaymentAccount.documentId,
      name: name,
      tin: individualAuthorization.payee.tin,
      cadence: individualAuthorization.timePaymentAccount.payFrequency,
      paymentDate: moment(individualAuthorization.payment.paymentDate).tz("America/New_York").format("MM/DD/YYYY"),
      grossPayment: currencyFormatter.format(individualAuthorization.payment.paymentAmount / 100),
      irsWithholding: currencyFormatter.format(individualAuthorization.payment.irsWithholding / 100),
      dorWithholding: currencyFormatter.format(individualAuthorization.payment.dorsWithholding / 100),
      netPayment: currencyFormatter.format((individualAuthorization.payment.paymentAmount - individualAuthorization.payment.irsWithholding - individualAuthorization.payment.dorsWithholding) / 100),
      lifetimePayment: individualAuthorization.timePaymentAccount.lifetimePayment ? "Yes" : "No",
      status: individualAuthorization.payment.paymentStatus
    };

    return newData;
  });

  csvExport.generateCsv(rows);
};

  export {normalizePage, getPageData, getPaymentData, exportToCsv}