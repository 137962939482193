import * as React from 'react'
import { useFetch } from '../../hooks/use-fetch'
import {
  downloadFile,
  getContentDispositionFilename,
} from '../../utilities/helpers'
import {
  ContactChecklistRequestBody,
  ContactChecklistResponse,
  ManualWinner,
  ProcessClaimResponse,
  PromoteAlternateResponse,
  PromotionDrawingTier,
  WeeklyCheckRunResponse,
} from './constants'
import {
  AllWinnersManagementPermissions,
  WinnersManagementPermissions,
} from './permissions'
import { promotionsBaseUrl } from './use-fetch-base-promotions'

const getPromotionWinnersUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) =>
  `${promotionsBaseUrl}/${promotionId}/drawing-groups/${drawingGroupId}/drawings/${drawingId}/winners/${winnerId}`

const putUpdateContactChecklistUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) =>
  `${getPromotionWinnersUrl(
    promotionId,
    drawingGroupId,
    drawingId,
    winnerId
  )}/contact-checklist`

const putProcessClaimUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) =>
  `${getPromotionWinnersUrl(
    promotionId,
    drawingGroupId,
    drawingId,
    winnerId
  )}/process-claim`

const putPromoteAlternateUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) =>
  `${getPromotionWinnersUrl(
    promotionId,
    drawingGroupId,
    drawingId,
    winnerId
  )}/promote-alternate`

const postPromotionCampaignWinnersUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  prizeTier: string
) =>
  `${promotionsBaseUrl}/${promotionId}/drawing-groups/${drawingGroupId}/drawings/${drawingId}/prize-tier/${prizeTier}`

const getDownloadExperientialWinnersCSVUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string
) =>
  `${promotionsBaseUrl}/${promotionId}/drawing-groups/${drawingGroupId}/drawings/${drawingId}/fulfillment-csv`

const getDownloadTaxablePrizeWinnersCSVUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string
) =>
  `${promotionsBaseUrl}/${promotionId}/drawing-groups/${drawingGroupId}/drawings/${drawingId}/taxable-prizes-csv`

const getCheckLoadPreviewReportUrl = (promotionId?: string) =>
  `${promotionsBaseUrl}/report/check-load-preview-report${
    promotionId ? `?promotionId=${promotionId}` : ''
  }`

const getCheckLoadPreviewReportCSVUrl = (promotionId?: string) =>
  `${promotionsBaseUrl}/report/check-load-preview-report-csv${
    promotionId ? `?promotionId=${promotionId}` : ''
  }`

export const useFetchUpdateContactChecklist = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<ContactChecklistResponse>()

  const updateChecklist = async (checklist: ContactChecklistRequestBody) =>
    await fetchUrl(
      putUpdateContactChecklistUrl(
        promotionId,
        drawingGroupId,
        drawingId,
        winnerId
      ),
      'PUT',
      {},
      JSON.stringify(checklist),
      [WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT],
      undefined,
      undefined,
      true
    )

  return {
    updateChecklist,
    isLoading,
    error,
    data,
  }
}

export const useFetchProcessClaim = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) => {
  const { isLoading, error, requestSuccessful, fetchUrl } =
    useFetch<ProcessClaimResponse>()

  const processClaim = async () =>
    await fetchUrl(
      putProcessClaimUrl(promotionId, drawingGroupId, drawingId, winnerId),
      'PUT',
      {},
      undefined,
      [WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT],
      undefined,
      undefined,
      true
    )

  return {
    processClaim,
    isLoading,
    error,
    requestSuccessful,
  }
}

export const useFetchPromoteAlternate = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  winnerId: string
) => {
  const { isLoading, error, requestSuccessful, fetchUrl } =
    useFetch<PromoteAlternateResponse>()

  const promoteAlternate = async () =>
    await fetchUrl(
      putPromoteAlternateUrl(promotionId, drawingGroupId, drawingId, winnerId),
      'PUT',
      {},
      undefined,
      [WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT],
      undefined,
      undefined,
      true
    )

  return {
    promoteAlternate,
    isLoading,
    error,
    requestSuccessful,
  }
}

export const useFetchPostPromotionCampaignWinners = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  prizeTier: string
) => {
  const { isLoading, error, fetchUrl, requestSuccessful } =
    useFetch<PromotionDrawingTier>()
  const url = postPromotionCampaignWinnersUrl(
    promotionId,
    drawingGroupId,
    drawingId,
    prizeTier
  )

  const saveWinners = async (winners: ManualWinner[]) => {
    await fetchUrl(
      url,
      'POST',
      {},
      JSON.stringify(winners),
      [WinnersManagementPermissions.CAN_ADD_PROMOTION_CAMPAIGN_WINNERS],
      undefined,
      undefined,
      true
    )
  }
  return {
    isLoading,
    error,
    requestSuccessful,
    saveWinners,
  }
}

export const useFetchDownloadExperientialWinnersCSV = () => {
  const { data, response, fetchBlob } = useFetch<Blob>()

  React.useEffect(() => {
    if (data && response) {
      const downloadFileName = getContentDispositionFilename(
        response,
        'Experiential-Winners.csv'
      )

      downloadFile(downloadFileName, data)
    }
  }, [data, response])

  const fetchDownloadExperientialWinnersCSV = async (
    promotionId: string,
    drawingGroupId: string,
    drawingId: string
  ) => {
    await fetchBlob(
      getDownloadExperientialWinnersCSVUrl(
        promotionId,
        drawingGroupId,
        drawingId
      ),
      {},
      [
        WinnersManagementPermissions.CAN_READ_WINNERS_MANAGEMENT,
        WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT,
      ],
      false
    )
  }

  return {
    fetchDownloadExperientialWinnersCSV,
  }
}

export const useFetchDownloadTaxablePrizeWinnersCSV = () => {
  const { data, response, fetchBlob } = useFetch<Blob>()

  React.useEffect(() => {
    if (data && response) {
      const downloadFileName = getContentDispositionFilename(
        response,
        'Taxable-Prize-Winners.csv'
      )

      downloadFile(downloadFileName, data)
    }
  }, [data, response])

  const fetchDownloadTaxablePrizeWinnersCSV = async (
    promotionId: string,
    drawingGroupId: string,
    drawingId: string
  ) => {
    await fetchBlob(
      getDownloadTaxablePrizeWinnersCSVUrl(
        promotionId,
        drawingGroupId,
        drawingId
      ),
      {},
      [
        WinnersManagementPermissions.CAN_READ_WINNERS_MANAGEMENT,
        WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT,
      ],
      false
    )
  }

  return {
    fetchDownloadTaxablePrizeWinnersCSV,
  }
}

export const useFetchCheckLoadPreviewReport = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<WeeklyCheckRunResponse>()

  const generateReport = async (promotionId?: string) => {
    await fetchUrl(
      getCheckLoadPreviewReportUrl(promotionId),
      'GET',
      {},
      undefined,
      AllWinnersManagementPermissions
    )
  }

  React.useEffect(() => {
    generateReport()
  }, [])

  return {
    isLoading,
    error,
    data,
    generateReport,
  }
}

export const useFetchDownloadCheckLoadPreviewReportCSV = () => {
  const { data, response, fetchBlob } = useFetch<Blob>()

  React.useEffect(() => {
    if (data && response) {
      const downloadFileName = getContentDispositionFilename(
        response,
        'Check-Load-Preview.csv'
      )

      downloadFile(downloadFileName, data)
    }
  }, [data, response])

  const fetchDownloadCheckLoadPreviewReportCSV = async (
    promotionId?: string
  ) => {
    await fetchBlob(
      getCheckLoadPreviewReportCSVUrl(promotionId),
      {},
      AllWinnersManagementPermissions,
      false
    )
  }

  return {
    fetchDownloadCheckLoadPreviewReportCSV,
  }
}
