import * as React from 'react'
import { GetPaymentDetailsResponse } from '../../../../api/payment-details-fetcher/api-types'
import { formatCentsToDollars } from '../../../../util'
import './styles.scss'
import moment from 'moment-timezone'

export type PaymentCheckDetailsUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
}

const ROOT_CLASS = 'payment-check-details'

export const PaymentDetailsCheckDetailsUI = (
  props: PaymentCheckDetailsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className="check-details-container">
        <div className="row-container-title">Check Details</div>
        <div className="date">Payment Date: {
          moment(props.paymentDetailsData.payment.paymentDate).format('MM/DD/YYYY')
        }</div>
        <div className={`column-1`}>Check No.</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.documentNumber}
        </div>
        <div className={`column-3`}>Reportable Payment</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.reportableWinningsInCents
          )}
        </div>
        
        <div className={`column-1`}>Check Status</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.paymentStatus}
        </div>
        <div className={`column-3`}>Gross Payment</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.grossPaymentInCents
          )}
        </div>
        <div className={`column-1`}>Source</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.source}
        </div>
        <div className={`column-3`}>Federal Taxes Withheld</div>
        <div className={`column-4`}>
          {formatCentsToDollars(props.paymentDetailsData.payment.irsTaxInCents)}
        </div>
        <div className={`column-1`}>Annuity ID</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.annuityId}
        </div>
        <div className={`column-3`}>State Taxes Withheld</div>
        <div className={`column-4`}>
          {formatCentsToDollars(props.paymentDetailsData.payment.dorTaxInCents)}
        </div>
        <div className={`column-1`}>Payment Type</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.paymentType}
        </div>
        <div className={`column-3`}>Offsets Collected</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.offsetsWithheldInCents
          )}
        </div>
        <div className={`column-1`}>Game Name</div>
        <div className={`column-2`}>
          {props.paymentDetailsData.payment.game}
        </div>
        <hr className="horizontal-line"></hr>
        <div className={`column-3`}>Net Payment</div>
        <div className={`column-4`}>
          {formatCentsToDollars(
            props.paymentDetailsData.payment.netPaymentInCents
          )}
        </div>
      </div>
    </div>
  )
}
