export enum PlayslipsGameType {
  STANDARD = 'STANDARD',
  KENO = 'KENO',
  ROULETTE = 'ROULETTE',
  ORDERED = 'ORDERED',
}

export enum StepDownStatus {
  COMPLETE = 'Complete',
  PENDING = 'Pending',
}

export type PlayslipsGame = {
  identifier: string
  name: string
  type: PlayslipsGameType
  activeFrom?: string
  activeTo?: string
}

export type PlayslipsPromotionMessage = {
  id: number
  title: string
  message: string
  gameIdentifier?: string
  activeFrom?: string
  activeTo?: string
  gameName?: string
}

export type PlayslipsStepDown = {
  identifier: string
  name: string
  stepDownDate: string
  status: StepDownStatus
}

export type CreatePromotionMessageRequest = Omit<
  PlayslipsPromotionMessage,
  'id'
>

export type UpdatePromotionMessageRequest = PlayslipsPromotionMessage
export type PromotionMessageRequestBody =
  | CreatePromotionMessageRequest
  | UpdatePromotionMessageRequest

export type CreatePromotionMessageResponse = {
  id: number
}

export type CreatePlayslipsStepDownRequest = {
  gameIdentifier: string
  stepDownTimeISO: string
}

export type PlayslipsAllActivePlayslipsGamesResponse = PlayslipsGame[]

export type PlayslipsAllPromotionMessagesResponse = PlayslipsPromotionMessage[]

export type PlayslipsAllStepDownsResponse = PlayslipsStepDown[]
