import moment from 'moment'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { annuitiesRoutePath } from '../../../../config.js'
import { TimePaymentAuthSchedule } from '../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization.js'
import { formatDollarAmount } from '../../../../util/'
import styles from './css/styles.module.css'

export const InitiateAuthorizationListRowCmpt: React.FC<{
  authorization: TimePaymentAuthSchedule
  paymentNumber: number
  paymentDataLength: number
}> = ({ authorization, paymentNumber, paymentDataLength }) => {
  const history: any = useHistory()
  const { timePaymentAccount, payee, payment } = authorization

  return (
    <div className={`${styles.table_body}`}>
      <div className={`${styles.table_body_text}`}>
        {timePaymentAccount.documentId}
      </div>
      <div className={`${styles.table_body_text}`}>
        {payee.payeeType === 'Individual'
          ? `${payee.firstName} ${payee.lastName}`
          : `${payee.freeformName}`}
      </div>
      <div className={`${styles.table_body_text}`}>{payee.tin}</div>
      <div className={`${styles.table_body_text}`}>
        {timePaymentAccount.payFrequency}
      </div>
      <div className={`${styles.table_body_text}`}>
        {moment(payment.paymentDate).format('MM/DD/YYYY')}
      </div>
      <div className={`${styles.table_body_text}`}>
        {formatDollarAmount(payment.paymentAmount / 100)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {formatDollarAmount(payment.irsWithholding / 100)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {formatDollarAmount(payment.dorsWithholding / 100)}
      </div>
      <div className={`${styles.table_body_text}`}>
        {formatDollarAmount(
          (payment.paymentAmount -
            payment.irsWithholding -
            payment.dorsWithholding) /
            100
        )}
      </div>
      <div className={`${styles.table_body_text}`}>
        {timePaymentAccount.lifetimePayment ? 'Yes' : 'No'}
      </div>
      <Link
        className={styles.details_button}
        role="button"
        to={{
          pathname: `${annuitiesRoutePath}/authorization-payment-details`,
          state: {
            paymentDetails: authorization,
            from: useLocation().pathname,
            paymentNumber: paymentNumber,
            paymentDataLength: paymentDataLength,
          },
        }}
      >
        Details
      </Link>
    </div>
  )
}
