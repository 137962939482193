import {makeHttpCall} from '../../../services/MakeHttpCall';
import config from './../../../config.js';
import {BatchDetailSearchCriteria} from './interfaces';


let formik: any;
let setPageMessageType: any;
let setButtonClicked: any;
let history: any;
let setBatchDetailSearchResponse: any
let setBatchHeaderTitle: any
export const setVariables = (formikObj: any, historyObject: any,
                             pageMessageDefaultObj: any, setPageMessageObj: any, setButtonClickedObj: any,
                             setBatchDetailSearchResponseObj: any,
                             setBatchHeaderTitleObj: any) => {
    formik = formikObj;
    history = historyObject;
    setPageMessageType = setPageMessageObj;
    setButtonClicked = setButtonClickedObj;
    setBatchDetailSearchResponse = setBatchDetailSearchResponseObj
    setBatchHeaderTitle = setBatchHeaderTitleObj

}

export const refreshComponent = (response: any) => {
    console.log('UpdateProfile', response);
    if (!(response.error === undefined)) {
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}

export const onSubmit = async (values: BatchDetailSearchCriteria) => {
}

export const doACHActivitySearch = async (batchNumber: string) => {
    try {
        const options =
            {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/ach/batch/${batchNumber}`,
            }
        const response: any = await makeHttpCall(options);
        let responseData: any = response.data;
        // console.log(JSON.stringify(response))
        if (response.data.length === 0) {
            setBatchDetailSearchResponse([]);
            setBatchHeaderTitle("")
            setPageMessageType({message: "No Results Found.", messageType: "SUCCESS"});
        } else {
            const type = responseData[0].type
            if (type === 'NEW') {
                setBatchHeaderTitle("Player Payment by ACH: Batch ")
            } else if (type === 'TAX') {
                setBatchHeaderTitle("Taxes to DOR by ACH ")
            } else if (type === 'RESUBMIT') {
                setBatchHeaderTitle("Payment Retries by ACH: Batch ")
            } else if (type === 'PAPER CHECK') {
                setBatchHeaderTitle("Paper Check ")
            } else {
                setBatchHeaderTitle("Reject")
            }
            setButtonClicked(true);
            setBatchDetailSearchResponse(response.data);
        }
    } catch (e) {
        setButtonClicked(true);
        setBatchDetailSearchResponse([]);
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    }

}


export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: BatchDetailSearchCriteria) => {
    const errors = {};
    if (values.batchNumber) {
        if (values.batchNumber.length > 0 && values.batchNumber.length !== 7) {
            Object.assign(errors, {batchNumber: 'Invalid Batch Number'});
        }
    }
    return errors;
}


