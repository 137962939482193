import * as React from 'react'
import './styles.scss'
import { FutureReissuePaymentDetailsCheckDetailsUI } from '../future-reissue-payment-details-check-details/future-reissue-payment-details-check-details'
import { GetFutureReissuePaymentDetailsResponse } from '../../../../api/future-reissue-payment-details-fetcher/api-types'
import { FutureReissuePaymentRecipientDetailsUI } from '../future-reissue-payment-details-recipient-details/future-reissue-payment-details-recipient-details'

const ROOT_CLASS = 'future-reissue-payment-details'

export type PaymentDetailsUIProps = {
  futureReissuePaymentDetailsData: GetFutureReissuePaymentDetailsResponse
}

export const FutureReissuePaymentDetailsUI = (props: PaymentDetailsUIProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={`check-details-row`}>
        <FutureReissuePaymentDetailsCheckDetailsUI {...props} />
      </div>
      <div className={`recipient-details-row`}>
        <FutureReissuePaymentRecipientDetailsUI {...props} />
      </div>
    </div>
  )
}
