import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FaAngleLeft, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { BannerContext } from "../../../../context/BannerContext";
import { keyPressedType } from "../../../../services/Commons";
import { annuitiesRoutePath } from "../../../../config";
import { CircularProgress } from "@material-ui/core";
import {getPayeeList, applyFilters, exportToCsv, downloadCtiReportCSV} from "./functions";
import { Payee, PayeeFilter, dummyFilter } from "../../../../types/Annuities/PayeeManagement/Payee";
import { KEY_CODES } from "../../../../constants.js";
import { ExportToCsv } from "export-to-csv";
import styles from "./css/styles.module.css";
import { PayeeManagementListComponent } from "../../../../components/AnnuitiesComponents/PayeeManagement/PayeeManagementListCmpt";

export const AnnuitiesPayeeManagementPage = () => {
  const history = useHistory();
  const bannerContext = useContext(BannerContext);
  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [payeeList, setPayeeList] = useState<Payee[]>([]);
  const [unfilteredPayeeList, setUnfilteredPayeeList] = useState<Payee[]>([]);
  const [payeeFilterData, setPayeeFilterData] = useState<PayeeFilter>(dummyFilter);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  // CSV Options
  const csvOptions = {
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: `Payees`,
    showTitle: false,
    headers: ["Payee Name", "TIN", "Payee Type"],
  };

  const csvExporter: ExportToCsv = new ExportToCsv(csvOptions);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payeeList: Payee[] = await getPayeeList();
        setRequestCompleted(true);
        setPayeeList(payeeList);
        setUnfilteredPayeeList(payeeList);
      } catch (error) {
        setRequestCompleted(true);
        bannerContext.setBannerInfo({ message: `${error}`, error: true });
      }
    };

    fetchData();
  }, []);

  // setCurrentPage back to 0 when Payee List changes
  useEffect(() => {
    setCurrentPage(0)
  }, [payeeList])

  return (
    <>
      <div className={styles["page-container"]}>
        {/* Circular Progress initial Load screen */}
        {!requestCompleted && (
          <div
            style={{
              position: "absolute",
              zIndex: 50,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.3)",
              color: "white",
            }}
          >
            <CircularProgress
              size="8em"
              color="inherit"
              thickness={2}
            />
          </div>
        )}
        {/* Back Btn tsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                history.push(`${annuitiesRoutePath}/`);
              }
            }}
            onClick={() => {
              history.push(`${annuitiesRoutePath}/`);
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft
              title="left arrow icon"
              className={styles["back-btn-custom"]}
            />
            <div className={styles["back-btn-text"]}>Back to Annuities Landing Page</div>
          </button>
        </div>
        {/* Header */}
        <div className={styles["cmpnt-container"]}>
          <header className={`${styles.header} font-weight-bold w-100 d-flex align-items-center justify-content-between rounded pl-3`}>
            Payee Management
            <div className="d-flex">
              <button
                  type="button"
                  className={`${styles.export_btn}`}
                  disabled={payeeList.length <= 0}
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      downloadCtiReportCSV().then();
                    }
                  }}
                  onClick={() => {
                    downloadCtiReportCSV().then();
                  }}
              >
                Export PBI File
              </button>

              <button
                  type="button"
                  className={`${styles.export_btn}`}
                  disabled={payeeList.length <= 0}
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      exportToCsv(payeeList, csvExporter);
                    }
                  }}
                  onClick={() => {
                    exportToCsv(payeeList, csvExporter);
                  }}
              >
                Export Search
              </button>

            </div>
          </header>

          {/* Main Component */}
          <div className={`${styles.table_container} my-3 w-100 rounded d-flex flex-column`}>
            <div 
              className={`font-weight-bold w-100 d-flex align-items-center justify-content-left pl-3`}
              style={{fontSize: "2em", height: "2em"}}
              >Search</div>
            <div>
              <div 
                className={`${styles.search_text} font-weight-bold w-100 d-flex align-items-center justify-content-left pl-3`}
                style={{background: "rgb(20, 52, 85)", fontSize: "2em", height: "2em"}}
                >
                Filter
                <button
                  className="ml-2"
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      setIsFilterOpen(!isFilterOpen);
                    }
                  }}
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                >
                  {isFilterOpen ? <FaChevronUp style={{ color: "rgb(127, 203, 196)" }} /> : <FaChevronDown style={{ color: "rgb(127, 203, 196)" }} />}
                </button>
              </div>
              <div className={isFilterOpen ? `${styles.payee_container_open}` : `${styles.payee_container_closed}`}>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    className={`${styles.custom_input_payee_filter}`}
                    onChange={(e: any) => {
                      const updatedData: PayeeFilter = {
                        firstName: e.target.value,
                        lastName: payeeFilterData.lastName,
                        tin: payeeFilterData.tin,
                      };
                      setPayeeFilterData(updatedData);
                    }}
                    value={payeeFilterData.firstName}
                    name=""
                    id=""
                    defaultValue=""
                    tabIndex={isFilterOpen ? 0 : -1}
                  ></input>
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    className={`${styles.custom_input_payee_filter}`}
                    onChange={(e: any) => {
                      const updatedData: PayeeFilter = {
                        firstName: payeeFilterData.firstName,
                        lastName: e.target.value,
                        tin: payeeFilterData.tin,
                      };
                      setPayeeFilterData(updatedData);
                    }}
                    value={payeeFilterData.lastName}
                    name=""
                    id=""
                    defaultValue=""
                    tabIndex={isFilterOpen ? 0 : -1}
                  ></input>
                </div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="tin">
                    TIN <span className={`${styles.sub_text}`}>Must be exact match</span>
                  </label>
                  <input
                    className={`${styles.custom_input_payee_filter}`}
                    onChange={(e: any) => {
                      const updatedData: PayeeFilter = {
                        firstName: payeeFilterData.firstName,
                        lastName: payeeFilterData.lastName,
                        tin: e.target.value,
                      };
                      setPayeeFilterData(updatedData);
                    }}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={9}
                    value={payeeFilterData.tin}
                    tabIndex={isFilterOpen ? 0 : -1}
                  ></input>
                </div>
                <button
                  type="button"
                  className={payeeFilterData.firstName !== "" || payeeFilterData.lastName !== "" || payeeFilterData.tin !== ""  ? `${styles.light_btn}` : `${styles.light_btn_disabled}`}
                  onKeyPress={() => applyFilters(setPayeeList, unfilteredPayeeList, payeeFilterData)}
                  onClick={() => applyFilters(setPayeeList, unfilteredPayeeList, payeeFilterData)}
                >
                  Search
                </button>
                <button
                  type="button"
                  className={`${styles.dark_btn}`}
                  onKeyPress={event => {
                    if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                      setPayeeFilterData(dummyFilter);
                      applyFilters(setPayeeList, unfilteredPayeeList, dummyFilter);
                    }
                  }}
                  onClick={() => {
                    setPayeeFilterData(dummyFilter);
                    applyFilters(setPayeeList, unfilteredPayeeList, dummyFilter);
                  }}
                >
                  Clear Search
                </button>
              </div>
            </div>
            <PayeeManagementListComponent
              payeeList={payeeList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};
