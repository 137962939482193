import React from "react"
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { BackButtonUI } from "../../../components/CommonComponents/BackButtonComponent/back-button-ui"
import moment from "moment-timezone"
import { mapFinservReportStatusToString } from "../../../util"
import { SearchResultsTableColumnMap, SearchResultsTableUI } from "../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui"
import './styles.scss' 
import {checkAllRenderPermissions} from '../../../helpers.js';
import {permissions} from './../../../constants.js';
import { ReportStatus } from "../../../api/finserv-general-reports/api-types"
import { CheckRegisterReport, CheckRegisterReportType } from "../../../api/check-register-reports-fetcher/api-types"

export type CheckRegisterReportDisplayRow = {
  id: string
  dateGenerated: string
  status: string
  reportType: CheckRegisterReportType
}

export type CheckRegisterReportPageUIProps = {
  reports: CheckRegisterReport[]
  isLoading: boolean
  error: string | undefined
  downloadReport: (row: CheckRegisterReportDisplayRow) => Promise<void>
}

const mapReportToDisplayRow = (report: CheckRegisterReport) : CheckRegisterReportDisplayRow => {
  return {
    id: report.id,
    dateGenerated: moment(report.dateGenerated).tz('America/New_York').format('MM/DD/YYYY HH:mm'),
    status: mapFinservReportStatusToString(report.status),
    reportType: report.reportType
  }
}

const CHECK_REGISTER_REPORTS_COLUMN_MAP: SearchResultsTableColumnMap<CheckRegisterReportDisplayRow> = [
  { dataKey: 'dateGenerated', label: 'Date & Time Generated', width: 24 },
  { dataKey: 'status', label: 'Status', width: 18 },
  { dataKey: 'reportType', label: 'Report Type', width: 18}
]

const ROOT_CLASS = 'check-register-report-page'

export const CheckRegisterReportPageUI = (props: CheckRegisterReportPageUIProps) => {
  const { reports, isLoading, error, downloadReport } = props

  const generateDownloadCTA = (report: CheckRegisterReportDisplayRow) => {
    const noButtonStatuses = [ReportStatus.FAILED, ReportStatus.EXPIRED].map(mapFinservReportStatusToString)
    const userpolicies: any = localStorage.getItem('userpolicies')
    return (
      !noButtonStatuses.includes(report.status)
        && checkAllRenderPermissions([permissions.CAN_SEE_CHECK_REGISTER_REPORT, permissions.CAN_SEE_SSN], JSON.parse(userpolicies)) 
        && (
        <button
          disabled={report.status === 'Pending'}
          onClick={() => downloadReport(report)}
          className={`dark-btn`}
        >
          Download
        </button>
      )
    )
  }

  return (
    <div className={ROOT_CLASS}>
    <BackButtonUI tabIndex={0} message="Back" />
    <header>Check Register Reports</header>
    <AsyncContainerUI
      isLoading = {isLoading}
      error = {error}
      errorTextSize={20}
      color="white"
    >
      <SearchResultsTableUI
        rowData={reports.map(mapReportToDisplayRow)}
        additionalResults={false}
        columnMap={CHECK_REGISTER_REPORTS_COLUMN_MAP}
        generateRowCTA={generateDownloadCTA}
        noDataMessage="No reports have been generated"
      /> 
    </AsyncContainerUI>
    </div>
  )
}