import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import { PayTaxIndicatorMapping } from '../../../../pages/PaymentsPages/Constants'
import { MaskedSsnUI } from '../../../CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import './styles.scss'

const ROOT_CLASS = 'future-payment-recipient-details'

export type FuturePaymentRecipientDetailsUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse
}

export const FuturePaymentRecipientDetailsUI = (
  props: FuturePaymentRecipientDetailsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={`row-container-title`}>Recipient Details</div>
      <div className={`recipient-details-controls-container`}>
        <div className={`column-1`}>Payee</div>
        <div className={`column-2`}>{props.futurePaymentDetailsData.name}</div>
        <div className={`column-3`}>Tax ID No.</div>
        <div className={`column-4`}>
          {
            PayTaxIndicatorMapping[
              props.futurePaymentDetailsData.payTaxIndicator
            ]
          }
        </div>
        <div className={`column-1`}>TIN</div>
        <div className={`column-2`}>
          {props.futurePaymentDetailsData.tin ? (
            <MaskedSsnUI ssn={props.futurePaymentDetailsData.tin} />
          ) : (
            <span>--</span>
          )}
        </div>
      </div>
    </div>
  )
}
