import { makeHttpCall } from './../../../services/MakeHttpCall';
import config from './../../../config.js';
import { start } from 'repl';
import moment from 'moment';
import { listenerCount } from 'cluster';



function swap(items:any, leftIndex:any, rightIndex:any){
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
}

function partition(items:any, left:any, right:any) {
    
    var pivot   = items[Math.floor((right + left) / 2)], //middle element
        i       = left, //left pointer
        j       = right; //right pointer
        
    while (i <= j) {
       
        while (items[i].createdAt > pivot.createdAt) {
            i++;
        }
        
        while (items[j].createdAt < pivot.createdAt) {
             
            j--;
        }
        if (i <= j) {
            swap(items, i, j); //swap two elements
            i++;
            j--;
        }
    }
    return i;
}

function quickSort(items:any, left:any, right:any) {
   
    var index;
    if (items.length > 1) {
        index = partition(items, left, right); //index returned from partition
        if (left < index - 1) { //more elements on the left side of the pivot
            quickSort(items, left, index - 1);
        }
        if (index < right) { //more elements on the right side of the pivot
            quickSort(items, index, right);
        }
    }
    return items;
}


export const setStartDate = async (startDate:any, setSAStartDate:any) => {   
    setSAStartDate(startDate.target.value);
}

export const setEndDate = async (endDate:any, setSAEndDate:any) => {   
    setSAEndDate(endDate.target.value);
}

export const resetFilters = async ( pageMessage:any,setNetworkError:any, setCurrentPage:any,setPageMessage:any, playerId:any, setIsData:any, setSABasePlayerData:any, setRequestPerformed:any , setSALoaded:any, setErrorStartDate:any , setErrorEndDate:any, setSAPlayerData:any, SABasePlayerData:any, setSAEndDate:any, setSAStartDate:any, setSASignUp:any, setSASignIn:any) => {
   
    setSAEndDate( moment().format('YYYY-MM-DD') );
    const temp = new Date();
    temp.setDate(temp.getDate() - 31);
    setSAStartDate(`${temp.toISOString().split("T")[0]}`);
    setSASignUp(true);
    setSASignIn(true);
    setErrorStartDate(undefined);
    setErrorEndDate(undefined);
    const result:any = SABasePlayerData.filter((row:any)=>  moment(row.eventTimestamp).isSameOrAfter(temp.toISOString().split("T")[0], 'day') && moment(row.eventTimestamp).isSameOrBefore(moment().format('YYYY-MM-DD'), 'day'));
        
    if( result.length > 0 &&  result[0].id !== playerId )
       setSAPlayerData(result);
    else 
    {
        setRequestPerformed(true);
       getSAPlayerData(pageMessage, setNetworkError, setSALoaded, setRequestPerformed, setSAPlayerData, setSABasePlayerData, playerId, setIsData,temp.toISOString().split("T")[0], moment().format('YYYY-MM-DD'),setPageMessage,SABasePlayerData ,setCurrentPage,true, true)
    }
}
let oldStartDate:any = undefined;
let oldEndtDate:any = undefined;
export const applyFilters = async ( setRequestPerformed:any, setSAPlayerData:any, SABasePlayerData:any[], startDate:string, endDate:string, signIn:boolean, signUp:boolean,setCurrentPage:any, id:any) => {
    setCurrentPage(0);
    if(SABasePlayerData.length > 0 && SABasePlayerData[0] === id)
        return [];
    
    let result = SABasePlayerData.filter(row => {
        
       
        if ( signIn === true && signUp === true ){
            return moment(row.eventTimestamp).isSameOrAfter(startDate, 'day') && moment(row.eventTimestamp).isSameOrBefore(endDate, 'day');
        } else if(signIn === true && signUp === false) {           
            return moment(row.eventTimestamp).isSameOrAfter(startDate, 'day') && moment(row.eventTimestamp).isSameOrBefore(endDate, 'day') && row.authEvent.toLowerCase().includes('sign_up') === false;
        } else if(signIn === false && signUp === true) {
            return moment(row.eventTimestamp).isSameOrAfter(startDate, 'day') && moment(row.eventTimestamp).isSameOrBefore(endDate, 'day') && row.authEvent.toLowerCase().includes('sign_in') === false;
        } else {
            return moment(row.eventTimestamp).isSameOrAfter(startDate, 'day') && moment(row.eventTimestamp).isSameOrBefore(endDate, 'day') && row.authEvent.toLowerCase().includes('sign_in') === false && row.authEvent.toLowerCase().includes('sign_up') === false;
        }
    });
   
    if( oldStartDate === undefined )
    {
        oldStartDate = startDate;
        oldEndtDate = endDate; 
        
        return [];
    }
    

    if(result.length > 0 &&  ( oldStartDate > startDate  ||  oldEndtDate < endDate ) )
    {          
            oldStartDate = startDate;
            oldEndtDate = endDate
        
            return [];
    }
    return result;
}


export const getSAPlayerData = async (pageMessage:any,setNetworkError:any, setSALoaded:any, setRequestPerformed:any, setSAPlayerData:any, setSABasePlayerData:any, playerId:any, setIsData:any, SAStartDate:string, SAEndDate:string,setPageMessage:any,SABasePlayerData:any ,setCurrentPage:any, signIn:any, signUp:any) => {
    setCurrentPage(0);
    
    const result:any = await applyFilters(setRequestPerformed,setSAPlayerData,SABasePlayerData,SAStartDate,SAEndDate, signIn, signUp, setCurrentPage, playerId )
    
    if(result.length >  0 && result[0].id !== playerId )
    {
        setRequestPerformed(false);
        setSAPlayerData(result);
        return;
    }
  
   
    try {
        const options =
        {
            method: 'get',
            url: config.SERVER_BASE_URL + `/v1/player/${playerId}/player-events?queryDateRangeStart=${SAStartDate}&queryDateRangeEnd=${SAEndDate}:23:59:59`
        }

        const response:any = await makeHttpCall(options);
       
      
        if( response.data.length <=  0)
        {
            const defaultData:any = {
                id: playerId,           
            }

            response.data.push(defaultData);
        }       
        if(pageMessage.messageType === "ERROR")
            setPageMessage({message:"", messageType:""});
        setNetworkError(false);
        setRequestPerformed(false);
        setSAPlayerData(await applyFilters(setRequestPerformed,setSAPlayerData,response.data,SAStartDate,SAEndDate, signIn, signUp, setCurrentPage, playerId ));       
        setSABasePlayerData(response.data);
        setSALoaded(true); 
       
    } catch (e) {
        setSALoaded(true);
        setRequestPerformed(false);
        const er:any = e;
        if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
            if(value === "Network")
                return value;
        }) !== undefined)
        {
           setNetworkError(true);
           setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        }else
        {
          setNetworkError(false);
          setPageMessage({message:'Failed to retrieve Session Claims Activity for selected player.', messageType: "ERROR"});
        }
        

    }

}
export const getSAPlayerRefreshData = async (pageMessage:any, setSALoaded:any, setRequestPerformed:any, setSAPlayerData:any, setSABasePlayerData:any, playerId:any, setIsData:any, SAStartDate:string, SAEndDate:string,setPageMessage:any,SABasePlayerData:any ,setCurrentPage:any, signIn:any, signUp:any,setNetworkError:any) => {
    setCurrentPage(0);
    if(pageMessage.messageType === "ERROR")
        setPageMessage({message:"", messageType:""});
    const result:any = await applyFilters(setRequestPerformed,setSAPlayerData,SABasePlayerData,SAStartDate,SAEndDate, signIn, signUp, setCurrentPage, playerId )
    
    if(result.length >  0 && result[0].id !== playerId )
    {
        setRequestPerformed(false);
        setSAPlayerData(result);
        return;
    }
  
   
    try {
        const options =
        {
            method: 'get',
            url: config.SERVER_BASE_URL + `/v1/player/${playerId}/player-events?queryDateRangeStart=${SAStartDate}&queryDateRangeEnd=${SAEndDate}:23:59:59`
        }

        const response:any = await makeHttpCall(options);
      
        if( response.data.length <=  0)
        {
            const defaultData:any = {
                id: playerId,           
            }

            response.data.push(defaultData);
        }
        
        // const nameOptions =
        // {
        //     method: 'get',
        //     url: config.SERVER_BASE_URL + `/v1/player/${playerId}`
        // }

        // const nameResponse:any = await makeHttpCall(nameOptions);
        if(pageMessage.messageType === "ERROR")
            setPageMessage({message:"", messageType:""});
        setRequestPerformed(false);
        setSAPlayerData(response.data); 
        setSABasePlayerData(response.data);  
        setSALoaded(true); 
        setNetworkError(false);
        setIsData(true);       

    } catch (e) {

        setIsData(true);
        setSALoaded(true);
        setRequestPerformed(false);
        const er:any = e;
        if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
            if(value === "Network")
                return value;
        }) !== undefined)
        {
           setNetworkError(true);
           setPageMessage({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
        }else
        {
          setNetworkError(false);
          setPageMessage({message: "Something went wrong. Please try again.", messageType: "ERROR"});
        }
      
    }

}
