import * as React from 'react'
import { GetFutureReissuePaymentDetailsResponse } from '../../../api/future-reissue-payment-details-fetcher/api-types'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { FutureReissuePaymentDetailsContainerUI } from '../../../components/PaymentComponents/FutureReissuePaymentDetailsComponent/future-reissue-payment-details-container/future-reissue-payment-details-container-ui'

export type FutureReissuePaymentDetailsPageUIProps = {
  futureReissuePaymentDetailsData: GetFutureReissuePaymentDetailsResponse | undefined
  isLoading: boolean
  error: string | undefined
}

export const FutureReissuePaymentDetailsPageUI = (
  props: FutureReissuePaymentDetailsPageUIProps
) => {
  return (
    <AsyncContainerUI
      isLoading={props.isLoading}
      error={props.error}
      errorTextSize={20}
      color="white"
    >
      {props.futureReissuePaymentDetailsData ? (
        <FutureReissuePaymentDetailsContainerUI 
          futureReissuePaymentDetailsData={props.futureReissuePaymentDetailsData}
        />
      ) : (
        <div>Something went wrong</div>
      )}
    </AsyncContainerUI>
  )
}
