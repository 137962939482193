import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const FrequentCasherAddConfirmationComponent = ({formik, setShowAddConfirmation}: any) => {

    return (<>
        <FocusTrap focusTrapOptions={{initialFocus: false}}>
            <div className={stylesMessageBox["confirmation-dialog-main-container"]}>
                <div
                    id="confirmation-dialog-container"
                    className={stylesMessageBox["confirmation-dialog-container"]}
                >
                    <div
                        className={stylesMessageBox["confirmation-dialog-message"]}
                    >
                        Are you sure you wish to add this Player to the Frequent Casher List?
                    </div>
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                formik.submitForm();
                                setShowAddConfirmation(false);
                            }
                        }}
                        onClick={() => {
                            formik.submitForm();
                            setShowAddConfirmation(false);
                        }}
                        className={stylesMessageBox["confirmation-dialog-yes-button"]}
                    >
                        <div
                            className={stylesMessageBox["confirmation-dialog-yes-button-text"]}
                        >
                            Yes, Add
                        </div>
                    </div>
                    <div
                        tabIndex={0}
                        onKeyDown={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                setShowAddConfirmation(false);
                            }
                        }}
                        role="button"
                        onClick={() => {
                            setShowAddConfirmation(false);
                        }}
                        className={stylesMessageBox["confirmation-dialog-no-button"]}
                    >
                        Go Back
                    </div>
                </div>
            </div>
        </FocusTrap>
    </>);
};