import React from 'react'
import { BackButtonUI } from '../../../CommonComponents/BackButtonComponent/back-button-ui'
import { ReissueHistoryTitleBarUI } from '../reissue-history-title-bar/reissue-history-title-bar-ui'
import { ReissueRunHistoryResponse } from '../../../../api/reissue-run-history-fetcher/api-types'
import { ReissueRunHistoryTableUI } from '../reissue-run-history-table/reissue-run-history-table-ui'
import '../styles.scss'

export type ReissueHistoryContainerUIProps = {
  historicalReissueRunsData: ReissueRunHistoryResponse
}

const ROOT_CLASS = 'reissue-history-container-ui'

export const ReissueHistoryContainerUI = (props: ReissueHistoryContainerUIProps) => {
  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={0} message="Back to Re-Issue Queue" />
      <ReissueHistoryTitleBarUI/>
      <ReissueRunHistoryTableUI 
        data={props.historicalReissueRunsData}
      />
    </div>
  )
}