import React, {useEffect} from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const CancelConfirmationComponent = ({YesCancel, cancelToggle, setCancelToggle}: any) => {

    useEffect(() => {
    });

    return (

        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["cancel-confirmation-dialog-main-container"]}>
                    <div
                        id="cancel-confirmation-dialog-container"
                        className={stylesMessageBox["cancel-confirmation-dialog-container"]}
                    >
                        <div className={stylesMessageBox["cancel-confirmation-dialog-message"]}>
                            Are you sure you would like to cancel without saving?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    /*pageMessage.messageType !== "" &&
                                    setPageMessage({ message: "", messageType: "" });*/
                                    YesCancel();
                                }
                            }}
                            onClick={() => {
                                /*pageMessage.messageType !== "" &&
                                setPageMessage({ message: "", messageType: "" });*/
                                YesCancel();
                            }}
                            className={stylesMessageBox["cancel-confirmation-dialog-yes-button"]}
                        >
                            <div className={stylesMessageBox["cancel-confirmation-dialog-yes-button-text"]}>
                                Yes, Cancel
                            </div>
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    setCancelToggle(!cancelToggle);
                                }
                            }}
                            onClick={() => {
                                setCancelToggle(!cancelToggle)
                            }}
                            className={stylesMessageBox["cancel-confirmation-dialog-no-button"]}>
                            Go Back
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};