import React from 'react'
import { SearchResultsTableColumnMap, SearchResultsTableUI } from '../../../CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import moment from 'moment'
import { formatCentsToDollars } from '../../../../util'
import { useHistory } from 'react-router-dom'
import { checkRunQueueRoutePath } from '../../../../config.js'
import '../styles.scss'
import { HistoricalReissueRun, ReissueRunHistoryResponse } from '../../../../api/reissue-run-history-fetcher/api-types'
import { mapCheckRunStatusToString } from '../../CheckRunHistoryComponents/check-run-history-table/check-run-history-table-ui'

export type ReissueRunHistoryTableProps = {
  data: ReissueRunHistoryResponse
}

type ReissueRunDisplayRow = {
  id: string
  dateSubmitted: string
  timeSubmitted: string
  netPayment: string
  payDate: string
  status: 'Success' | 'Pending' | 'Failed'
}

const REISSUE_RUN_HISTORY_COLUMN_MAP: SearchResultsTableColumnMap<ReissueRunDisplayRow> =
  [
    { dataKey: 'dateSubmitted', label: 'Date Submitted', width: 14 },
    { dataKey: 'timeSubmitted', label: 'Time', width: 10 },
    { dataKey: 'netPayment', label: 'Net Payment', width: 14 },
    { dataKey: 'payDate', label: 'Pay Date', width: 18 },
    { dataKey: 'status', label: 'Status', width: 14 },
  ]

const mapReissueRunToRow = (checkRun: HistoricalReissueRun): ReissueRunDisplayRow => {
  return {
    id: checkRun.id,
    dateSubmitted: moment(checkRun.initiatedDate).format('MM/DD/YYYY'),
    timeSubmitted: moment(checkRun.initiatedDate).format('HH:mm'),
    netPayment: formatCentsToDollars(checkRun.totalNetPaymentInCents),
    payDate: checkRun.payDate,
    status: mapCheckRunStatusToString(checkRun.status),
  }
}

const ROOT_CLASS = 'reissue-run-history-table'

export const ReissueRunHistoryTableUI = (props: ReissueRunHistoryTableProps) => {
  const history = useHistory();

  const reissueRunCTAHandler = (id: string) => {
    history.push(`${checkRunQueueRoutePath}/${id}/payments`)
  }

  const generateReissueRunCTA = (checkRun: ReissueRunDisplayRow) => {
    return (
      checkRun.status !== 'Failed' && (
        <button
          disabled={checkRun.status === 'Pending'}
          onClick={() => reissueRunCTAHandler(checkRun.id)}
          className={`${ROOT_CLASS}_primary_button`}
        >
          Payment List
        </button>
      )
    )
  }

  return (<SearchResultsTableUI
    rowData={props.data.historicalCheckRuns.map(mapReissueRunToRow)}
    columnMap={REISSUE_RUN_HISTORY_COLUMN_MAP}
    generateRowCTA={generateReissueRunCTA}
    additionalResults={false}
    noDataMessage="No Reissue Runs have been initiated"
  ></SearchResultsTableUI>)
}