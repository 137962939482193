import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import styles from "./css/styles.module.css";
import {useHistory} from "react-router-dom";
import {playerSupportRoutePath} from './../../../config.js';
import {keyPressedType} from "../../../services/Commons";
import {pageMessageDefault} from "../../CommonComponents/PageMessageCommonComponent";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const BackToMobileClaimActivity = ({id}: any) => {
    const history = useHistory();
    const callingPage: any = localStorage.getItem('callingPage');

    return (
        <>
            <div
                tabIndex={0}
                role="button"
                aria-pressed={"false"}
                onKeyPress={(ev: any) => {
                    if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                        pageMessageDefault.message = "";
                        pageMessageDefault.messageType = "";
                        localStorage.setItem('activeTab', 'activity');
                        history.push(`${callingPage}`);
                    }
                }
                }
                onClick={() => {

                    pageMessageDefault.message = "";
                    pageMessageDefault.messageType = "";
                    localStorage.setItem('activeTab', 'activity');
                    history.push(`${callingPage}`);
                }}
                className={styles["back-to-inner-container"]}
            >
                <i title="left arrow icon" className={styles["back-to-icon"]}>
                    <FontAwesomeIcon
                        size="2x"
                        arial-label="left arrow icon."
                        icon={faAngleLeft as IconProp}
                    />
                </i>
                <div className={styles["back-to-inner-container-text"]}>
                    Back
                    to&nbsp;&nbsp;{callingPage === `${playerSupportRoutePath}/mobile-claims-activity/${id}` ? 'Mobile Claim Activity' : localStorage.getItem("PlayersName")}
                </div>
            </div>
        </>
    );
};
