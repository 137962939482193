import React from 'react'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'

import { GetFuturePaymentDetailsResponse } from '../../../api/future-payment-details-fetcher/api-types'
import { FuturePaymentStatus } from '../../../api/future-payments-fetcher/api-types'

const FUTURE_PAYMENT_URL_BASE = `${config.SERVER_BASE_URL}/api/v1/payments/future`

export const useFetchFuturePaymentDetails = (futurePaymentId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<GetFuturePaymentDetailsResponse>()
  const fetchPaymentDetails = () => {
    const urlWithId = `${FUTURE_PAYMENT_URL_BASE}/${futurePaymentId}`
    return fetchUrl(urlWithId, 'GET', {})
  }
  React.useEffect(() => {
    fetchPaymentDetails()
    return () => {}
  }, [])

  return {
    isLoading,
    error,
    data,
    fetchPaymentDetails,
  }
}

export const useFetchUpdatePaymentStatus = (futurePaymentId: string) => {
  const pendingURL = `${FUTURE_PAYMENT_URL_BASE}/${futurePaymentId}/pending`
  const onHoldURL = `${FUTURE_PAYMENT_URL_BASE}/${futurePaymentId}/onHold`
  const deletePaymentUrl = `${FUTURE_PAYMENT_URL_BASE}/${futurePaymentId}/deleted`

  const { isLoading, error, fetchUrl } = useFetch()

  const updatePaymentStatus = (status: FuturePaymentStatus, notes: string) => {
    const url = status === FuturePaymentStatus.ON_HOLD ? onHoldURL : pendingURL
    const body =
      url === onHoldURL ? JSON.stringify({ onHoldNotes: notes }) : undefined
    return fetchUrl(url, 'PATCH', null, body)
  }

  const deletePayment = () => {
    return fetchUrl(deletePaymentUrl, 'PATCH')
  }

  return { updatePaymentStatus, deletePayment, isLoading, error }
}
