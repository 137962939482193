import React from "react";
import styles from "./css/styles.module.css";
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";

export const UpdateEFTOrganizationInfoComponent = ({formik, setFieldValue}: any) => {

    return (
        <>

            <div className={`${styles['org-info-container']}`}>
                <div className={`${styles['org-info-container-title']}`}>Organization's Information</div>
                <div className={`${styles['org-info-controls-container']}`}>
                    <div className={`${styles['org-info-controls-container-col-1']}`}>
                        <div className={`${styles['org-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Authorizing Party (Head, MIC, or
                                Bookkeeper)*
                            </div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="authorizingParty"
                                   name="authorizingParty"
                                   value={formik.values.authorizingParty}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}>
                            </input>
                            {(formik.touched.authorizingParty && formik.errors.authorizingParty) ?
                                <ErrorMessageFieldCommonComponent
                                    authorizingParty={formik.errors.authorizingParty}/> : null}

                        </div>
                        <div className={`${styles['org-info-label-textbox-container']}`}>
                            <div className={`${styles['mslc-label']}`}>Email*</div>
                            <input className={`${styles['mslc-textbox']}`}
                                   type="text"
                                   id="emailAddress"
                                   name="emailAddress"
                                   value={formik.values.emailAddress}
                                   onBlur={formik.handleBlur}
                                   onChange={(e: any) => {
                                       setFieldValue(e);
                                   }}>
                            </input>
                            {(formik.touched.emailAddress && formik.errors.emailAddress) ?
                                <ErrorMessageFieldCommonComponent
                                    emailAddress={formik.errors.emailAddress}/> : null}

                        </div>
                    </div>
                    <div className={`${styles['org-info-controls-container-col-2']}`}>

                    </div>
                </div>
            </div>

        </>
    );
};


