import * as React from 'react'
import { PromotionCampaignPermissions } from '../../../api/promotions-fetcher/permissions'
import { useFetchAllPromotions } from '../../../api/promotions-fetcher/use-fetch-base-promotions'
import { useRecurringAction } from '../../../hooks/use-recurring-action'
import { minutesToMilliseconds } from '../../../util'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { PromotionCampaignBuilderPageUI } from './promotion-campaign-builder-page-ui'

export const PromotionCampaignBuilderPageContainer = () => {
  const { data, isLoading, isFirstLoad, error, fetchAllPromotions } =
    useFetchAllPromotions()

  useRecurringAction(fetchAllPromotions, minutesToMilliseconds(5))
  return (
    <PromotionCampaignBuilderPageUI
      data={data}
      isLoading={isFirstLoad && isLoading}
      error={error}
      fetchAllPromotions={fetchAllPromotions}
      disableAdminActions={
        !userHasAnyOfPermissions([
          PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS,
        ])
      }
      disableScheduleDrawingAction={
        !userHasAnyOfPermissions([
          PromotionCampaignPermissions.CAN_SCHEDULE_PROMOTION_CAMPAIGN_DRAWING,
          PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS,
        ])
      }
    />
  )
}
