import { inject, observer } from 'mobx-react'
import React from 'react'
import { permissions } from '../constants'
import CustomCard from '../dashboard/customCard'
import {
  checkRenderPermissions,
  checkAnyRenderPermissions,
  checkAllRenderPermissions,
} from '../helpers'
import { reportingRoutePath } from './../config.js'
import { AllPromotionReportingPermissions } from '../api/promotions-fetcher/permissions'

@inject('claimStore')
@observer
class Finance extends React.Component {
  navigateToClaimTransactions = () => {
    this.props.history.push(`${reportingRoutePath}/finance/claims/transactions`)
  }

  navigateToReconciliationMenu = () => {
    this.props.history.push(`${reportingRoutePath}/finance/reconciliation-menu`)
  }

  navigateToCheckRunReportingMenu = () => {
    this.props.history.push(
      `${reportingRoutePath}/finance/check-run-reporting-menu`
    )
  }

  navigateToOffsetsReporting = () => {
    this.props.history.push(`${reportingRoutePath}/finance/offsets/reporting`)
  }

  navigateToYearEndReports = () => {
    this.props.history.push(`${reportingRoutePath}/finance/reporting/year-end`)
  }

  navigateTo5754SplitReports = () => {
    this.props.history.push(
      `${reportingRoutePath}/finance/reporting/split-5754`
    )
  }

  navigateToQuarterlyTaxTotals = () => {
    this.props.history.push(
      `${reportingRoutePath}/finance/reporting/quarterly-tax-totals`
    )
  }

  navigateToPromotionsReporting = () => {
    this.props.history.push(`${reportingRoutePath}/promotions`)
  }

  navigateToCheckRegisterReports = () => {
    this.props.history.push(
      `${reportingRoutePath}/finance/reporting/check-register`
    )
  }

  naviagateToAgent1099Report = () => {
    this.props.history.push(`${reportingRoutePath}/agent-1099-report`)
  }

  render() {
    if (
      checkRenderPermissions(
        permissions.CAN_ACCESS_FINANCE_PAYMENTS_MODULE,
        JSON.parse(localStorage.getItem('userpolicies'))
      )
    ) {
      return (
        <div
          style={{
            paddingTop: '8em',
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: 'rgb(22, 58, 95)',
          }}
        >
          {checkRenderPermissions(
            permissions.CAN_SEE_ACH_ACTIVITY,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="ACH Activity"
              handleCallback={() => this.navigateToClaimTransactions()}
            />
          ) : null}
          {checkRenderPermissions(
            permissions.CAN_SEE_DAILY_RECONCILIATION,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Daily Reconciliation Report"
              handleCallback={() => this.navigateToReconciliationMenu()}
            />
          ) : null}
          {checkRenderPermissions(
            permissions.CAN_SEE_OFFSETS_REPORTING,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Offsets Reporting"
              handleCallback={() => this.navigateToOffsetsReporting()}
            />
          ) : null}
          {checkAnyRenderPermissions(
            [
              permissions.CAN_SEE_YEAR_END_REPORTS,
              permissions.CAN_SEE_1099_REPORTS,
            ],
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Year End Reporting"
              handleCallback={() => this.navigateToYearEndReports()}
            />
          ) : null}
          {checkRenderPermissions(
            permissions.CAN_SEE_QUARTERLY_TAX_TOTAL_REPORTS,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Quarterly Tax Totals"
              handleCallback={() => this.navigateToQuarterlyTaxTotals()}
            />
          ) : null}
          {checkAnyRenderPermissions(
            [
              permissions.CAN_SEE_CHECK_LOAD_REPORT,
              permissions.CAN_VIEW_CHECK_RUN_REPORTS,
            ],
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Check Run Reporting"
              handleCallback={() => this.navigateToCheckRunReportingMenu()}
            />
          ) : null}

          {checkRenderPermissions(
            permissions.CAN_SEE_5754_SPLIT_REPORTS,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="5754 Split Report"
              handleCallback={() => this.navigateTo5754SplitReports()}
            />
          ) : null}
          {checkAnyRenderPermissions(
            AllPromotionReportingPermissions,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Promotions Reporting"
              handleCallback={this.navigateToPromotionsReporting}
            />
          ) : null}
          {checkAllRenderPermissions(
            [
              permissions.CAN_SEE_CHECK_REGISTER_REPORT,
              permissions.CAN_SEE_SSN,
            ],
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="Check Register Reports"
              handleCallback={() => this.navigateToCheckRegisterReports()}
            />
          ) : null}
          {checkRenderPermissions(
            permissions.CAN_SEE_1099_REPORTS,
            JSON.parse(localStorage.getItem('userpolicies'))
          ) ? (
            <CustomCard
              title="1099 Agent Reports"
              handleCallback={() => this.naviagateToAgent1099Report()}
            />
          ) : null}
        </div>
      )
    } else {
      return (
        <h3 className="unauthorized-header">
          You do not have permission to view this page! Please contact your
          System Administrator!
        </h3>
      )
    }
  }
}
export default Finance
