import React from 'react'
import './styles.scss'
import { paymentsRoutePath } from '../../../../config'
import {
  PaymentDetails,
  PlayerPaymentStatus,
} from '../../../../api/payment-details-fetcher/api-types'
import { WarningImage } from '../payment-details-warning-image/payment-details-warning-image'
import { isPaymentTypeCheck } from '../../../../pages/PaymentsPages/PaymentDetailsPage/functions'
const ROOT_CLASS = 'payment_details_payment_message'

type PaymentMessageUIProps = {
  paymentDetailsData: PaymentDetails
  isParent: boolean
  isChild: boolean
  isParentKnown: boolean
}

export const PaymentMessageUI = (props: PaymentMessageUIProps) => {
  if (props.isParent) {
    if (props.isChild) {
      if (props.isParentKnown) {
        return (
          <div className={ROOT_CLASS}>
            <WarningImage />
            This payment is a child of a&nbsp;
            <i>
              <a
                href={`#${paymentsRoutePath}/details/${props.paymentDetailsData.parentPaymentId}`}
              >
                parent payment
              </a>
            </i>
            , and has been split again
          </div>
        )
      } else {
        return (
          <div className={ROOT_CLASS}>
            <WarningImage />
            This payment has been split from an unknown parent, and has been
            split again
          </div>
        )
      }
    } else {
      return (
        <div className={ROOT_CLASS}>
          <WarningImage />
          This payment has been split
        </div>
      )
    }
  } else {
    if (props.isChild) {
      if (props.isParentKnown) {
        return (
          <div className={ROOT_CLASS}>
            <WarningImage />
            This payment has been split from the&nbsp;
            <i>
              <a
                href={`#${paymentsRoutePath}/details/${props.paymentDetailsData.parentPaymentId}`}
              >
                parent
              </a>
            </i>
          </div>
        )
      } else {
        return (
          <div className={ROOT_CLASS}>
            <WarningImage />
            This payment has been split from an unknown parent
          </div>
        )
      }
    } else {
      if (props.paymentDetailsData.paymentStatus === PlayerPaymentStatus.VOID) {
        return (
          <div className={ROOT_CLASS}>
            <WarningImage />
            This payment has been voided.
          </div>
        )
      }
      if (!isPaymentTypeCheck(props.paymentDetailsData)) {
        return (
          <div className={ROOT_CLASS}>
            Payments cannot be voided unless the payment type is
            check.
          </div>
        )
      }
      return null
    }
  }
}
