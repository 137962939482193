import { makeHttpCall } from './../../../services/MakeHttpCall'
import config from './../../../config.js'
import {
  getPlayersDetailsData,
  testPlayerStoreObjectData,
} from './../../../services/StoresObject'
import { checkRenderPermissions } from '../../../helpers'
import { permissions } from '../../../constants'

export const getPlayerData = async (
  playerId: string,
  setPlayerStore: any,
  storesObject: any,
  setPageMessage: any,
  pageMessage: any,
  setIsData: any
) => {
  const userpolicies: any = localStorage.getItem('userpolicies')
  const maskSsn = !checkRenderPermissions(
    permissions.CAN_SEE_SSN,
    JSON.parse(userpolicies)
  )
  let queryParam = `?maskSsn=${maskSsn}`

  try {
    const options = {
      method: 'get',
      url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
    }

    let response: any = await makeHttpCall(options)
    response = testPlayerStoreObjectData(response)

    storesObject.playerStore.selectedPlayerDetails = response

    localStorage.setItem(
      'PlayersName',
      response.identity.firstName === ''
        ? `Player Search`
        : `${response.identity.firstName} ${response.identity.lastName}`
    )

    if (pageMessage.messageType === 'ERROR')
      setPageMessage({ message: '', messageType: '' })
    setPlayerStore(response)
    setIsData(true)
  } catch (e) {
    setIsData(true)
    storesObject.playerStore.selectedPlayerDetails = getPlayersDetailsData
    const er: any = e
    if (
      er
        .toString()
        .split(' ')
        .find((value: any, i: any) => {
          if (value === 'Network') if (value[i + 1] === 'Error') return value
        }) !== undefined
    ) {
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    } else
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
  }
}
