import { observable, action } from 'mobx';

export class PaymentSearchStore {
  //#region search parameters
  @observable startDate = '';
  @observable endDate = '';
  @observable firstName = '';
  @observable lastName = '';
  @observable ssn = '';
  @observable freeformName = '';
  @observable checkNumber = '';
  @observable annuityId = '';
  @observable paymentSource = '';
  //#endregion

  //#region payments (i.e., search results)/pagination
  @observable payments = null;
  @observable searchPageActive = 0;
  //#endregion

  @action clear() {
    this.startDate = '';
    this.endDate = '';
    this.firstName = '';
    this.lastName = '';
    this.ssn = '';
    this.freeformName = '';
    this.checkNumber = '';
    this.annuityId = '';
    this.paymentSource = '';

    this.payments = null
    this.searchPageActive = 0
  }

  @action setSearchParams(searchParams) {
    this.startDate = searchParams.startDate ?? ''
    this.endDate = searchParams.endDate ?? ''
    this.firstName = searchParams.firstName ?? ''
    this.lastName = searchParams.lastName ?? ''
    this.ssn = searchParams.ssn ?? ''
    this.freeformName = searchParams.freeformName ?? ''
    this.checkNumber = searchParams.checkNumber ?? ''
    this.annuityId = searchParams.annuityId ?? ''
    this.paymentSource = searchParams.paymentSource ?? ''
  }

  @action setPayments(payments) {
    this.payments = payments
  }

  @action setSearchPageActive(searchPageActive) {
    this.searchPageActive = searchPageActive
  }

  isNonempty() {
    return (this.startDate || this.endDate || this.firstName || this.lastName || this.ssn
    || this.freeformName || this.checkNumber || this.annuityId || this.paymentSource)
  }
}