import * as React from 'react'
import './styles.scss'

type LoadingDotsProps = {
  id: string
  color?: string
}
const DEFAULT_COLOR = '#1e4b75'
export const LoadingDots = (props: LoadingDotsProps) => (
  <div className="loading-dots-container" id={props.id}>
    <div
      className={`loading-dots`}
      style={{ color: props.color ?? DEFAULT_COLOR }}
    />
  </div>
)
