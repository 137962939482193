import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {PaginationCommonComponent} from "../../../CommonComponents/PaginationCommonComponent";
import moment from "moment";
import ExclamationIcon from "../../../../icons/icon_status_flagged.svg";
import {SearchResultExeceededInfoMessageComponent} from "../../Common/SearchResultExeceededInfoMessageComponent";
import {formatCentsToDollars} from "../../../../util";
import InfoIcon from "../../../../icons/info-white.svg";
import {keyPressedType} from "../../../../services/Commons";
import {checkRenderPermissions} from "../../../../helpers";
import {taxformRoutePath} from "../../../../config";
import {
    approveOutOfBalanceTaxForm,
    getPageData
} from "../../../../pages/CharitableGamingPages/TaxForm/TaxFormManagementPage/functions";
import {TaxFormAvgIndicatorInfoMessageComponent} from "../../Common/TaxFormAvgIndicatorInfoMessageComponent";
import {permissions} from "../../../../constants";
import {
    UnbalancedTaxFormApprovalConfirmationComponent
} from "../../Common/UnbalancedTaxFormApprovalConfirmationComponent";

export const WeeklyBeanoSearchResultComponent = ({weeklyBeanoTaxForms, currentPage, setCurrentPage,}: any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const [showSearchResultExceededInfoMessage, setShowSearchResultExceededInfoMessage] = useState<boolean>(true);
    const [showTaxFormAvgIndicatorInfoMessage, setShowTaxFormAvgIndicatorInfoMessage] = useState<boolean>(false);
    const [showUnbalancedTaxFormApprovalConfirmationDialog, setShowUnbalancedTaxFormApprovalConfirmationDialog] = useState<{ show: boolean, taxFormId: string }>({
        show: false,
        taxFormId: ''
    });

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        setTimeout(function () {
            setShowSearchResultExceededInfoMessage(false);
        }, 3000);
    }, []);

    return (<>
            <div className={styles['table']}>

                <div className={styles['table-data-header']}>
                    <div className={styles['table-data-header-text']} aria-live="assertive">
                        {`Displaying ${weeklyBeanoTaxForms.forms.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(weeklyBeanoTaxForms.forms.length, currentPage)}
                            of ${weeklyBeanoTaxForms.forms.length}${weeklyBeanoTaxForms.additionalResults ? "+" : ""} results`}
                    </div>
                    {weeklyBeanoTaxForms.additionalResults ? <div
                        style={{
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                            setShowSearchResultExceededInfoMessage(true);
                        }}
                        onMouseLeave={() => {
                            setShowSearchResultExceededInfoMessage(false);
                        }}
                    >
                        <img
                            src={ExclamationIcon}
                            height={20}
                            width={20}
                            alt="exclamation icon"
                        />
                        {weeklyBeanoTaxForms.additionalResults && showSearchResultExceededInfoMessage ?
                            <SearchResultExeceededInfoMessageComponent/> : null}
                    </div> : null}
                </div>

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>License ID</div>
                    <div>Org Name</div>
                    <div>Occasion Date</div>
                    <div>Submission Date</div>
                    <div>Gross Receipts</div>
                    <div>Prizes</div>
                    <div>Expenses</div>
                    <div>Taxes</div>
                    <div>Net Profit or Loss</div>
                    <div className={styles['table-header-icon-container']}>
                        <div>Avg Indicator</div>
                        <div
                            style={{
                                cursor: "pointer"
                            }}
                            onMouseEnter={() => {
                                setShowTaxFormAvgIndicatorInfoMessage(true);
                            }}
                            onMouseLeave={() => {
                                setShowTaxFormAvgIndicatorInfoMessage(false);
                            }}
                        >
                            <img src={InfoIcon} width="20" height="20" alt="Info Icon"/>
                            {showTaxFormAvgIndicatorInfoMessage ? <TaxFormAvgIndicatorInfoMessageComponent/> : null}
                        </div>
                    </div>

                </div>

                <div className={styles['table-data-body']}>
                    {weeklyBeanoTaxForms.forms.length > 0 && getPageData(weeklyBeanoTaxForms.forms, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles["table-data-data-text"]}>
                                {e.licenseNumber}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.orgName}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.occasionDate).format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {moment(e.submissionDate).format('MM/DD/YYYY')}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.grossReceiptsInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.prizesInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.expensesInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.taxesInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {formatCentsToDollars(e.netProfitOrLossInCents)}
                            </div>
                            <div className={styles["table-data-data-text"]}>
                                {e.unbalanced ? <div className={styles['avg-indicator-container']}><img
                                    src={ExclamationIcon}
                                    height={20}
                                    width={20}
                                    alt="exclamation icon"
                                />
                                    <div
                                        tabIndex={0}
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            fontFamily: '\'Barlow-Regular\', \'Barlow\'',
                                        }}
                                        onKeyDown={(ev: any) => {
                                            if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                                setShowUnbalancedTaxFormApprovalConfirmationDialog({ show: true, taxFormId: e.taxFormId });
                                            }
                                        }}
                                        onClick={() => {
                                            setShowUnbalancedTaxFormApprovalConfirmationDialog({ show: true, taxFormId: e.taxFormId });
                                        }}
                                    >Approve
                                    </div>
                                </div> : null}
                            </div>
                            {e.gamePlayed && checkRenderPermissions(permissions.CAN_SEE_WEEKLY_BEANO_FORM, JSON.parse(userpolicies)) ?
                                <div className={styles['table-details-button-container']}>
                                    <div tabIndex={0} role='button' aria-pressed={"false"}
                                         onKeyDown={(en: any) => {
                                             if (keyPressedType(en) === 13 || keyPressedType(en) === 32) {
                                                 history.push({
                                                     pathname: `${taxformRoutePath}/edit/weeklybeano/${e.taxFormId}`,
                                                     state: {
                                                         backButtonText: 'Back to Tax Form Management'
                                                     },
                                                 });
                                             }
                                         }}
                                         onClick={() => {
                                             history.push({
                                                 pathname: `${taxformRoutePath}/edit/weeklybeano/${e.taxFormId}`,
                                                 state: {
                                                     backButtonText: 'Back to Tax Form Management'
                                                 },
                                             });
                                         }}
                                         className={styles['table-details-button']}>
                                        View Form
                                    </div>
                                </div> :
                                <div className={`${styles["table-data-data-text-center"]} ${styles['text-italics']}`}>
                                    {'No Game'}
                                </div>}
                        </div>)}
                </div>
                <div className={styles['table-data-footer']}>

                    <div aria-live="assertive"
                         className={styles['table-page-label']}>{`Page ${currentPage + 1}
                            of ${Math.ceil((weeklyBeanoTaxForms.forms.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={weeklyBeanoTaxForms.forms}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>
            {showUnbalancedTaxFormApprovalConfirmationDialog.show ? <UnbalancedTaxFormApprovalConfirmationComponent
                approveOutOfBalanceTaxForm={approveOutOfBalanceTaxForm}
                taxFormId={showUnbalancedTaxFormApprovalConfirmationDialog.taxFormId}
                setShowUnbalancedTaxFormApprovalConfirmationDialog={setShowUnbalancedTaxFormApprovalConfirmationDialog}
            /> : null}
        </>);
};
