import config from "../../../config";
import {makeHttpCall} from "../../../services/MakeHttpCall";
import {ExportToCsv} from "export-to-csv";
import {formatCentsToDollars} from "../../../util";
import { savePDF } from "../../../util/excel-export-util";

let setQuarterlyTaxTotalsReport: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setDisplayedYear: any;

export const setVariables = (setQuarterlyTaxTotalsReportObj: any, setShowErrorDialogObj: any,
                             setRequestCompletedObj: any, setDisplayedYearObj: any) => {

    setQuarterlyTaxTotalsReport = setQuarterlyTaxTotalsReportObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setDisplayedYear = setDisplayedYearObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const clearSearch = (formik: any) => {
    formik.resetForm({
        values: {
            year: ''
        }
    });

    setQuarterlyTaxTotalsReport(null);
}

export const onSubmit = async (values: any) => {
    getQuarterlyTaxTotalsReport(values);
}

export const getPageData = (pullTabGames: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(pullTabGames[i] === undefined)) {
            pullTabGames[i].index = index;
            index++;
            array.push(pullTabGames[i]);
        }
    }
    return array;
}

export const getQuarterlyTaxTotalsReport = async (values: any) => {

    setRequestCompleted(false);

    try {

        const options = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/payments/quarterlyTotals?year=${values.year}`,
        }

        const response = await makeHttpCall(options);

        setQuarterlyTaxTotalsReport(response);

        setRequestCompleted(true);
        setDisplayedYear(values.year);

    } catch (e) {
        setRequestCompleted(true);
        //setShowErrorDialog(true);
    }
}


export const exportCsvReport = async (quarterlyTaxTotalsReport: any, formik: any) => {

    try {

        const response = quarterlyTaxTotalsReport;

        let input = [];

        for (let i = 0; i < response.quarterlyTotals.length; i++) {

            const quarterlyTotal = response.quarterlyTotals[i];

            for (let j = 0; j < quarterlyTotal.dailyTotals.length; j++) {
                const some: any = quarterlyTotal.dailyTotals[j];
                input.push({
                    Date: some.date,
                    IRSAmount: formatCentsToDollars(some.irsAmountInCents),
                    DORAmount: formatCentsToDollars(some.dorAmountInCents)
                });
            }

            let suffix = '';
            if (quarterlyTotal.quarterNumber === 1) {
                suffix = 'st';
            } else if (quarterlyTotal.quarterNumber === 2) {
                suffix = 'nd';
            } else if (quarterlyTotal.quarterNumber === 3) {
                suffix = 'rd';
            } else if (quarterlyTotal.quarterNumber === 4) {
                suffix = 'th';
            }

            input.push({
                Date: `${quarterlyTotal.quarterNumber}${suffix} QTR TOTALS`,
                IRSAmount: formatCentsToDollars(quarterlyTotal.irsTotalInCents),
                DORAmount: formatCentsToDollars(quarterlyTotal.dorTotalInCents)
            });
            input.push({
                Date: `GRAND TOTALS`,
                IRSAmount: formatCentsToDollars(quarterlyTotal.irsGrandTotalInCents),
                DORAmount: formatCentsToDollars(quarterlyTotal.dorGrandTotalInCents)
            });
        }

        const fileOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: `YQTAXTP_${formik.values.year}`,
            title: '',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: ["DATE", "IRS AMOUNT", "DOR AMOUNT"]
        };
        const csvExporter = new ExportToCsv(fileOptions);
        await csvExporter.generateCsv(input);
    } catch (e) {
        console.log(e);
    }

}

export const exportPdfReport = async (year: string, base64Data: string | undefined, setBannerInfo: any) => {
    try {
        if(!base64Data) {
            throw new Error('no data')
        }
        const pdfBuffer = Buffer.from(base64Data, 'base64')
        const filename = `quarterly-tax-totals-${year}`
        savePDF(pdfBuffer, filename)
    } catch(err) {
        setBannerInfo({message: 'Error saving quarterly tax total report PDF', error: true})
    }
}