import React, {useState} from "react";
import styles from "../../License/CreateProfilePage/css/styles.module.css";
import {PermitHolderDefaultData} from "./interfaces";
import {useHistory} from "react-router-dom";
import {
  PageMessageCommonComponent,
    pageMessageDefault
} from "../../../../components/CommonComponents/PageMessageCommonComponent";
import {useFormik} from "formik";
import {onSubmit, setFiledValue, setVariables, validate} from "./functions";
import {keyPressedType} from "../../../../services/Commons";
import {
    CreateOrganizationDetailsComponent
} from "../../../../components/CharitableGamingComponents/Permit/Create/CreateOrganizationDetailsComponent";
import {
    CreatePermitInformationComponent
} from "../../../../components/CharitableGamingComponents/Permit/Create/CreatePermitInformationComponent";
import stylesMessageBox from "../../../PlayersPages/UpdateProfilePage/css/stylesMessageBox.module.css";
import {permitRoutePath} from "../../../../config";

export const PermitProfileCreationPage = () => {

  const history = useHistory();
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);
  const [saveToggle, setSaveToggle] = useState<any>(false);
  const [cancelToggle, setCancelToggle] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: PermitHolderDefaultData,
    onSubmit,
        validate
  });

    const ActivateSavebutton = (formik.isValid && formik.dirty);

  setVariables(formik, history, pageMessageDefault, setPageMessage);

  return (
    <>
            <div className={styles["page-container"]}
            >
        <div className={styles["top-ribbon"]}>
          <div className={styles["top-ribbon-text"]} tabIndex={0}>
            Permit Profile Creation
          </div>
          <div className={styles["top-ribbon-button-container"]}>
            <div
              role="button"
              tabIndex={0}
              aria-pressed="false"
              onClick={() => setCancelToggle(!cancelToggle)}
              onKeyDown={(e: any) =>
                keyPressedType(e) === 13 || keyPressedType(e) === 32
                  ? setCancelToggle(!cancelToggle)
                  : ""
              }
              className={styles["top-ribbon-cancel-button"]}
            >
              Cancel
            </div>
            <div
              style={{
                opacity: ActivateSavebutton ? "1" : "0.4",
                cursor: ActivateSavebutton ? "pointer" : "not-allowed",
              }}
              role="button"
              tabIndex={0}
              aria-pressed="false"
              onKeyDown={(e: any) => {
                                if (
                                    (keyPressedType(e) === 13 || keyPressedType(e) === 32)
                                ) {
                  if (ActivateSavebutton) {
                    setSaveToggle(true);
                  }
                }
              }}
              onClick={() => {
                if (ActivateSavebutton) {
                  setSaveToggle(true);
                }
              }}
              className={styles["top-ribbon-save-button"]}
            >
              Submit
            </div>
          </div>
        </div>
        {pageMessage.message !== "" && (
          <PageMessageCommonComponent
            message={pageMessage.message}
            messageType={pageMessage.messageType}
          />
        )}
        <div className={styles["page-container-body-container"]}>
          <div className={styles["page-container-body"]}>
            <div className={styles["page-container-body-left"]}>
                            <CreateOrganizationDetailsComponent formik={formik} setFiledValue={setFiledValue}/>
            </div>
            <div className={styles["page-container-body-left1"]}>
              <div>
                                <CreatePermitInformationComponent formik={formik} setFiledValue={setFiledValue}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {saveToggle && (
        <div className={stylesMessageBox["window-container"]}>
          <div
            tabIndex={0}
            id="update-profile-save-box-container"
            className={stylesMessageBox["update-profile-save-box-container"]}
          >
            <div
              tabIndex={0}
              className={stylesMessageBox["update-profile-save-box-message"]}
            >
              Are you sure you would like to create this permit profile?
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                  formik.submitForm();
                                    setSaveToggle(false)
                }
              }}
              onClick={() => {
                formik.submitForm();
                                setSaveToggle(false)
              }}
              className={stylesMessageBox["update-profile-save-box-button-yes"]}
            >
              <div
                className={
                  stylesMessageBox["update-profile-save-button-yes-text"]
                }
              >
                Yes, Create
              </div>
            </div>
            <div
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                  setSaveToggle(false);
                }
              }}
              role="button"
              onClick={() => setSaveToggle(false)}
              className={
                stylesMessageBox["player-email-and-phone-message-box-button-no"]
              }
            >
              Go Back
            </div>
          </div>
        </div>
      )}
      {cancelToggle && (
        <div className={stylesMessageBox["window-container"]}>
          <div
            tabIndex={0}
            id="update-profile-save-box-container"
            className={stylesMessageBox["update-profile-save-box-container"]}
          >
            <div
              tabIndex={0}
              className={stylesMessageBox["update-profile-save-box-message"]}
            >
                            Are you sure you would like to cancel creating this permit profile?
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                  pageMessage.messageType !== "" &&
                                    setPageMessage({message: "", messageType: ""});
                  setCancelToggle(!cancelToggle);
                  history.push(`${permitRoutePath}/manage`);
                }
              }}
              onClick={() => {
                pageMessage.messageType !== "" &&
                                setPageMessage({message: "", messageType: ""});
                setCancelToggle(!cancelToggle);
                history.push(`${permitRoutePath}/manage`);
              }}
              className={stylesMessageBox["update-profile-save-box-button-yes"]}
            >
              <div
                className={
                  stylesMessageBox["update-profile-save-button-yes-text"]
                }
              >
                Yes, Cancel
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                  setCancelToggle(!cancelToggle);
                }
              }}
              onClick={() => setCancelToggle(!cancelToggle)}
              className={
                stylesMessageBox["player-email-and-phone-message-box-button-no"]
              }
            >
              Go Back
            </div>
          </div>
        </div>
      )}
    </>
  );
};