import React, { useEffect, useRef } from 'react'
import { Agent1099QueuePrintQueueTabUI } from './agent-1099-queue-print-queue-tab-ui'
import { useFetchAgent1099OpenSweeps } from '../../../api/agent-1099-fetcher/use-fetch-agent-1099-open-sweeps'
import { getFetchAgent1099SweepPayments } from '../../../api/agent-1099-fetcher/get-fetch-agent-1099-sweep-payments'
import { getFetchAgent1099RemoveFromSweep } from '../../../api/agent-1099-fetcher/get-fetch-agent-1099-remove-from-sweep'

export type Agent1099QueuePrintQueueTabContainerProps = {
  setPrintEnabled: (value: boolean) => void
  sweepId?: string
  setSweepId: (value: string) => void
}

export const Agent1099QueuePrintQueueTabContainer = (props: Agent1099QueuePrintQueueTabContainerProps) => {
  const {
    isLoading: openSweepsLoading,
    error: openSweepsError,
    data: openSweepsData
  } = useFetchAgent1099OpenSweeps()
  const {
    isLoading: sweepPaymentsLoading,
    error: sweepPaymentsError,
    data: sweepPaymentsData,
    fetchPaymentsForSweep
  } = getFetchAgent1099SweepPayments()
  const {
    isLoading: removeFromSweepLoading,
    error: removeFromSweepError,
    fetchAgent1099RemoveFromSweep
  } = getFetchAgent1099RemoveFromSweep()

  useEffect(() => {
    if (props.sweepId) {
      fetchPaymentsForSweep(props.sweepId)
    }
  }, [props.sweepId])

  useEffect(() => {
    if (sweepPaymentsData?.printable !== undefined) {
      props.setPrintEnabled(sweepPaymentsData?.printable)
    }
  }, [sweepPaymentsData])

  const prevIsRemoveLoading = useRef<boolean>()

  useEffect(() => {
    if(!removeFromSweepLoading) {
      if(!removeFromSweepError && props.sweepId && prevIsRemoveLoading.current) {
        fetchPaymentsForSweep(props.sweepId)
      }
    }
    prevIsRemoveLoading.current = removeFromSweepLoading
  }, [removeFromSweepLoading])

  return (
    <Agent1099QueuePrintQueueTabUI 
      isLoading={openSweepsLoading || sweepPaymentsLoading || removeFromSweepLoading}
      error={openSweepsError ?? sweepPaymentsError ?? removeFromSweepError}
      sweeps={openSweepsData?.sweeps}
      payments={sweepPaymentsData?.payments}
      sweepId={props.sweepId}
      setSweepId={props.setSweepId}
      removeFromQueue={(tin: string, year: string, sweepId: string) => fetchAgent1099RemoveFromSweep({ tin, year, sweepId })}
    />
  )
}
