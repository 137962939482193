import { FormikValues } from "formik";

export const validate = (values: FormikValues) => {
  const errors = {};

  if (values.tin.length > 0 && values.tin.length < 9) {
    Object.assign(errors, { tin: "Field must be 9 characters in length" });
  }

  return errors;
};
