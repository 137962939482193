import { FormikProps } from 'formik'
import * as React from 'react'
import { ManualWinnersForm } from '../../../../api/promotions-fetcher/constants'
import { WinnersManagementWinnerFormUIRow } from '../winners-management-winner-form-row/winners-management-winner-form-row-ui'

type WinnersManagementWinnerFormUIProps = {
  formik: FormikProps<ManualWinnersForm>
  tierId: string
  removeWinner: (index: number) => Promise<void>
}

export const WinnersManagementWinnerFormUI = (
  props: WinnersManagementWinnerFormUIProps
) => {
  return (
    <>
      {props.formik.values.winners.map((winner, index) => {
        return (
          <WinnersManagementWinnerFormUIRow
            key={`new-winner-${index}`}
            winner={winner}
            index={index}
            formik={props.formik}
            removeEntry={props.removeWinner}
          />
        )
      })}
    </>
  )
}
