import { useFetch } from "../../../hooks/use-fetch"
import config from '../../../config'

const initiateReissueRunUrl = `${config.SERVER_BASE_URL}/api/v1/checkRun/`

export const getFetchInitiateReissueRun = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch<void>()
  const fetchInitiateReissueRun = (futurePaymentIds: string[]) => {
    const jsonRequestBody = JSON.stringify({
      type: 'REISSUE',
      futurePaymentIds
    })
    fetchUrl(initiateReissueRunUrl, 'POST', {}, jsonRequestBody)
  }
  return { isLoading, error, requestSuccessful, fetchInitiateReissueRun }
}