import React from 'react'
import { useHistory } from 'react-router-dom'
import { futurePaymentsRoutePath } from '../../../../config.js'

const ROOT_CLASS = 'future-payments-list-row-cta'

export type FuturePaymentsListRowCTAUIProps = {
  futurePaymentId: string
}

export const FuturePaymentsListRowCTAUI = (
  props: FuturePaymentsListRowCTAUIProps
) => {
  const history = useHistory()

  return (
    <button
      className={`${ROOT_CLASS} dark-btn`}
      onClick={() => {
        history.push(
          `${futurePaymentsRoutePath}/details/${props.futurePaymentId}`
        )
      }}
    >
      Details
    </button>
  )
}
