import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../../icons/Icon_arrows_carrot_white-left.svg";
import {getAllPullTabInventory, onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {FaPlus} from 'react-icons/fa';
import {CircularProgress} from "@material-ui/core";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import RenewalIcon from "./../../../../../icons/renewal-white.svg";

import {
    PullTabInventorySearchBarComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Inventory/PullTabInventorySearchBarComponent";
import {
    PullTabInventorySearchResultComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Inventory/PullTabInventorySearchResultComponent";
import {
    GenericErrorDialogComponent
} from "../../../../../components/CommonComponents/GenericErrorDialogComponent";
import {pageMessageDefault} from "../../../../../components/CommonComponents/PageMessageCommonComponent";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {pulltabRoutePath} from "../../../../../config.js";

export const PullTabInventoryPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const formik = useFormik({
            initialValues: {
                warehouse: '',
                stockNumber: ''
            },
            validate,
            onSubmit
        });

        const [unfilteredPullTabInventory, setUnfilteredPullTabInventory] = useState<any>([]);
        const [filteredPullTabInventory, setFilteredPullTabInventory] = useState<any>([]);

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(0);
        const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);

        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

        const {notificationBanner} = charitableGamingStore;

        useEffect(() => {
            if (unfilteredPullTabInventory.length === 0 && requestCompleted === false) {
                getAllPullTabInventory(setRequestCompleted);
            }
        });

        const hideNotificationBanner = () => {
            charitableGamingStore.showNotificationBanner({show: false, text: ''});
        }

        setVariables(charitableGamingStore, unfilteredPullTabInventory, setUnfilteredPullTabInventory, filteredPullTabInventory,
            setFilteredPullTabInventory, setCurrentPage, setPageMessage, setShowErrorDialog);


        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['pulltab-inventory-page-container']} ${styles['']}`}
                     onClick={() => {

                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['pulltab-inventory-page-back-button-container']} ${styles['']}`}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 history.push(`${pulltabRoutePath}/manage`);
                             }
                         }}
                         onClick={() => {
                             history.push(`${pulltabRoutePath}/manage`);
                         }}
                    >
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['pulltab-inventory-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                        >Back
                            to Pull Tab Management
                        </div>
                    </div>
                    <div className={`${styles['pulltab-inventory-page-ribbon-container']} ${styles['']}`}>
                        <div className={`${styles['pulltab-inventory-page-ribbon-title']} ${styles['']}`}>Pull Tab Inventory
                        </div>
                        <div className={`${styles['pulltab-inventory-page-ribbon-buttons-container']}`}>
                            {checkRenderPermissions(permissions.CAN_ADD_NEW_PULL_TAB_INVENTORY, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['pulltab-inventory-page-ribbon-create-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             history.push(`${pulltabRoutePath}/inventory/add`);
                                         }
                                     }}
                                     onClick={() => {
                                         history.push(`${pulltabRoutePath}/inventory/add`);
                                     }}>
                                    <FaPlus style={{cursor: "pointer"}}/>
                                    <div
                                        className={`${styles['pulltab-inventory-page-ribbon-create-license-button-label']} ${styles['']}`}>Add
                                        New Inventory
                                    </div>
                                </div> : null}
                            {checkRenderPermissions(permissions.CAN_TRANSFER_PULL_TAB_INVENTORY, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['pulltab-inventory-page-ribbon-renew-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             history.push(`${pulltabRoutePath}/inventory/transfer`);
                                         }
                                     }}
                                     onClick={() => {
                                         history.push(`${pulltabRoutePath}/inventory/transfer`);
                                     }}>
                                    <img src={RenewalIcon} width="16" height="16" alt="Renew Licenses Icon"/>
                                    <div
                                        className={`${styles['pulltab-inventory-page-ribbon-create-license-button-label']}`}
                                    >
                                        Transfer Inventory
                                    </div>
                                </div> : null}

                        </div>

                    </div>

                    <div
                        className={`${styles['pulltab-inventory-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                        <PullTabInventorySearchBarComponent formik={formik} setFieldValue={setFieldValue}
                        />

                    </div>
                    {filteredPullTabInventory.length <= 0 ?
                        <div className={`${styles['pulltab-inventory-page-search-no-results-container']}`}>
                            <div className={`${styles['pulltab-inventory-page-search-no-results-container-text']}`}>
                                No Results. Please try a different search.
                            </div>
                        </div>
                        :
                        <PullTabInventorySearchResultComponent
                            LicenseeList={filteredPullTabInventory}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        type={NotificationType.Success}
                        text={notificationBanner.text}
                        handleHide={hideNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);