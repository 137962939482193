import { FormikValues } from 'formik'
import moment from 'moment-timezone'
import { SetStateAction } from 'react'
import { ExpectedAccountStatus } from '../../../Constants/AnnuityFlowConstants/AccountStatus.js'
import {
  GameType,
  GameTypeOptions,
  Games,
} from '../../../Constants/AnnuityFlowConstants/Games'
import { ClaimCenterLocations } from '../../../Constants/AnnuityFlowConstants/claim-center-locations'
import { getLifetimeRecurringAmt } from '../../../components/AnnuitiesComponents/CreationExceptionFlow/CreationFlowStep1Cmpt/functions'
import config from '../../../config'
import { makeHttpCall } from '../../../services/MakeHttpCall'
import { FirstPaymentClaimant } from '../../../types/Annuities/FirstPayment/FirstPayment'
import { PostFirstPayment } from '../../../types/Annuities/TPAManagement/SubAccountManagement/PostFirstPayment'
import { PayeeTypeOptions } from '../../../types/Annuities/type-options/payee-type'
import {
  emptyStringToUndefined,
  validateFloatString,
  validateNonZeroCurrency,
  validateStringLength,
  validateTIN,
} from '../../../util'

export const getFirstPayment = async (id: string) => {
  let annuityInfoObject: any

  if (id === '' || id === null) {
    return
  } else {
    const options = {
      method: 'get',
      url: `${config.SERVER_BASE_URL}/v1/annuities/first-payment/${id}`,
    }

    try {
      const response: any = await makeHttpCall(options)

      const endDate: string = generateEndDate(
        response.paymentFrequency,
        response.paymentDate,
        response.totalPayments - 1
      )

      annuityInfoObject = {
        gameType: ['Instant', 'Promotion'].includes(response.game.gameName)
          ? response.game.gameName
          : 'Draw',
        gameName: ['Instant', 'Promotion'].includes(response.game.gameName)
          ? ''
          : response.game.gameName,
        payFrequency: response.paymentFrequency,
        accountStatus: 'Active',
        remainingPayments: response.totalPayments - 1,
        lifetimePayment: response.lifetimePayment ? 'Yes' : 'No',
        firstPaymentDate: moment(response.paymentDate).format('YYYY-MM-DD'),
        recurringPaymentAmount:
          response.lifetimePayment === 0
            ? 0
            : getLifetimeRecurringAmt(
                response.winAmount,
                response.firstCheckGross,
                response.totalPayments - 1
              ),
        tpaDocumentId: response.checkNumber,
        firstPaymentAmount: response.firstCheckGross,
        finalPaymentDate: response.lifetimePayment
          ? 'Lifetime Payment'
          : endDate,
        winAmount: (response.winAmount / 100).toFixed(2),
      }
    } catch (error) {
      throw new Error('Error Fetching First Payment Details for Creation Flow')
    }
  }
  return annuityInfoObject
}

export const onSubmit = () => {}

export const generateEndDate = (
  frequency: string,
  paymentDate: string,
  payments: number
) => {
  let endDate = moment().format('MM/DD/YYYY')
  if (frequency === 'Yearly') {
    endDate = moment(paymentDate).add(payments, 'years').format('MM/DD/YYYY')
  } else if (frequency === 'Quarterly') {
    endDate = moment(paymentDate).add(payments, 'quarters').format('MM/DD/YYYY')
  } else if (frequency === 'Monthly') {
    endDate = moment(paymentDate).add(payments, 'months').format('MM/DD/YYYY')
  } else if (frequency === 'Weekly') {
    endDate = moment(paymentDate).add(payments, 'weeks').format('MM/DD/YYYY')
  }
  return endDate
}

export const validateTPA =
  (issueFirstPayment: boolean) => (values: FormikValues) => {
    const errors = {}

    if (validateStringLength(values.gameType)) {
      Object.assign(errors, { gameType: 'This field is Required' })
    }

    if (validateStringLength(values.gameName)) {
      Object.assign(errors, { gameName: 'This field is Required' })
    }

    if (validateStringLength(values.payFrequency)) {
      Object.assign(errors, { payFrequency: 'This field is Required' })
    }
    if (values.gameType !== 'Instant' && values.drawDate === '') {
      Object.assign(errors, { drawDate: 'This field is Required' })
    }

    if (!moment(values.claimDate).isValid()) {
      Object.assign(errors, { claimDate: 'This field is Required' })
    }

    if (validateStringLength(values.tpaDocumentId)) {
      Object.assign(errors, { tpaDocumentId: 'This field is Required' })
    }

    if (values.remainingPayments === '' || values.remainingPayments === 0) {
      Object.assign(errors, { remainingPayments: 'This field is Required' })
    }

    if (values.remainingPayments < 0) {
      Object.assign(errors, {
        remainingPayments: 'This field cannot be Negative',
      })
    }

    if (values.lifetimePayment === '') {
      Object.assign(errors, { lifetimePayment: 'This field is Required' })
    }
    const validStatuses = ['Active', 'Hold']
    if (!validStatuses.includes(values.accountStatus)) {
      Object.assign(errors, { accountStatus: 'This field is Required' })
    }

    if (!issueFirstPayment && !moment(values.firstPaymentDate).isValid()) {
      Object.assign(errors, { firstPaymentDate: 'This field is Required' })
    }

    if (!moment(values.nextPaymentDate).isValid()) {
      Object.assign(errors, { nextPaymentDate: 'This field is Required' })
    }

    if (!issueFirstPayment) {
      const firstPaymentDate = moment(values.firstPaymentDate)
      const nextPaymentDate = moment(values.nextPaymentDate)
      if (
        firstPaymentDate.isValid() &&
        nextPaymentDate.isValid() &&
        nextPaymentDate.isSameOrBefore(firstPaymentDate)
      ) {
        Object.assign(errors, {
          nextPaymentDate:
            'Next payment date should be after first payment date',
        })
      }
    }

    if (validateStringLength(values.winAmount)) {
      Object.assign(errors, { winAmount: 'This field is Required' })
    } else if (validateNonZeroCurrency(values.winAmount)) {
      Object.assign(errors, {
        winAmount: 'This field cannot be zero',
      })
    }

    if (Games.find((game) => game.name === values.gameName)?.graduated) {
      if (validateFloatString(values.graduationRate)) {
        Object.assign(errors, { graduationRate: 'This field is Required' })
      }
      if (parseFloat(values.graduationRate) === 0) {
        Object.assign(errors, { graduationRate: 'This field cannot be zero' })
      }
    } else {
      if (validateStringLength(values.recurringPaymentAmount)) {
        Object.assign(errors, {
          recurringPaymentAmount: 'This field is Required',
        })
      }

      if (
        !validateStringLength(values.recurringPaymentAmount) &&
        validateNonZeroCurrency(values.recurringPaymentAmount)
      ) {
        Object.assign(errors, {
          recurringPaymentAmount: 'This field cannot be zero',
        })
      }
    }

    return errors
  }

export const validateFirstPayment =
  (gameType: GameType) => (values: FormikValues) => {
    const errors = {}
    if (validateNetAmount(values)) {
      Object.assign(errors, {
        firstCheckNet: 'Amount is not equal to gross minus withholdings',
      })
    }
    if (validateStringLength(values.payFileKey)) {
      Object.assign(errors, { payFileKey: 'This field is Required' })
    }
    if (validateStringLength(values.checkNumber)) {
      Object.assign(errors, { checkNumber: 'This field is Required' })
    }
    if (!ClaimCenterLocations.includes(values.claimCenter)) {
      Object.assign(errors, { claimCenter: 'This field is Required' })
    }
    if (validateStringLength(values.firstCheckGross)) {
      Object.assign(errors, { firstCheckGross: 'This field is Required' })
    } else if (validateNonZeroCurrency(values.firstCheckGross)) {
      Object.assign(errors, {
        firstCheckGross: 'This field cannot be zero',
      })
    }
    if (validateStringLength(values.firstCheckIrs)) {
      Object.assign(errors, { firstCheckIrs: 'This field is Required' })
    }
    if (validateStringLength(values.firstCheckDor)) {
      Object.assign(errors, { firstCheckDor: 'This field is Required' })
    }
    if (validateStringLength(values.firstCheckDbd)) {
      Object.assign(errors, { firstCheckDbd: 'This field is Required' })
    }
    if (validateStringLength(values.firstCheckNet)) {
      Object.assign(errors, { firstCheckNet: 'This field is Required' })
    }
    if (gameType === GameTypeOptions.Instant) {
      if (validateStringLength(values.bookNumber)) {
        Object.assign(errors, { bookNumber: 'This field is Required' })
      }
      if (validateStringLength(values.ticketNumber)) {
        Object.assign(errors, { ticketNumber: 'This field is Required' })
      }
    }
    return errors
  }

const validateNetAmount = (values: FormikValues) => {
  const {
    firstCheckGross,
    firstCheckNet,
    firstCheckDbd,
    firstCheckIrs,
    firstCheckDor,
  } = values
  const checkValues = [
    firstCheckGross,
    firstCheckNet,
    firstCheckDbd,
    firstCheckIrs,
    firstCheckDor,
  ]
  const deductions = [firstCheckDbd, firstCheckDor, firstCheckIrs]
  if (checkValues.map(parseFloat).find((value) => isNaN(value))) {
    // Some field hasn't been set, treat as valid. Caught in later validation.
    return false
  }
  const netValue =
    parseFloat(firstCheckGross) -
    deductions.map(parseFloat).reduce((prev, curr) => prev + curr)
  return netValue !== parseFloat(firstCheckNet)
}

export const validatePayee = () => (values: FormikValues) => {
  const errors = {}
  if (values.payeeType === PayeeTypeOptions.Individual) {
    // Individual-only fields
    if (validateStringLength(values.firstName)) {
      Object.assign(errors, { firstName: 'This field is Required' })
    }
    if (validateStringLength(values.lastName)) {
      Object.assign(errors, { lastName: 'This field is Required' })
    }
  }

  if (values.payeeType === PayeeTypeOptions.Entity) {
    if (validateStringLength(values.freeformName)) {
      Object.assign(errors, { freeformName: 'This field is Required' })
    }
  }

  if (validateStringLength(values.addr_1)) {
    Object.assign(errors, { addr_1: 'This field is Required' })
  }

  if (validateStringLength(values.city)) {
    Object.assign(errors, { city: 'This field is Required' })
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    Object.assign(errors, { email: 'Invalid email address' })
  }

  if (validateStringLength(values.state_region)) {
    Object.assign(errors, { state_region: 'This field is Required' })
  }

  if (validateStringLength(values.tin)) {
    Object.assign(errors, { tin: 'This field is Required' })
  }

  if (!validateStringLength(values.tin) && !validateTIN(values.tin)) {
    Object.assign(errors, { tin: 'This value is not a valid TIN' })
  }

  if (values.zip_code.toString().length < 5) {
    Object.assign(errors, {
      zip_code: 'This field must be at least 5 digits long',
    })
  }

  if (values.phone) {
    const trimmedPhone = values.phone.replace(/\-/g, '')
    if (trimmedPhone.length < 10) {
      Object.assign(errors, { phone: 'Invalid Phone Number' })
    }
  }

  if (validateStringLength(values.country)) {
    Object.assign(errors, { country: 'This field is Required' })
  }

  if (values.irsName) {
    if (values.irsName.length < 2) {
      Object.assign(errors, {
        irsName: 'Must be between 2 and 4 characters',
      })
    }
  }

  if (!values.irsName) {
    Object.assign(errors, { irsName: 'This field is Required' })
  }
  return errors
}

const createFirstPayment = async (
  timePaymentAccountForm: FormikValues,
  firstPaymentForm: FormikValues,
  idemToken: string
) => {
  const firstPaymentData: PostFirstPayment = {
    paymentDate: moment(
      timePaymentAccountForm.values.firstPaymentDate
    ).toISOString(),
    paymentFrequency: timePaymentAccountForm.values.payFrequency,
    lifetimePayment: timePaymentAccountForm.values.lifetimePayment === 'Yes',
    winAmount: +timePaymentAccountForm.values.winAmount.replace(/\D/g, ''),
    bookNumber: ['Instant', 'Promotion'].includes(
      timePaymentAccountForm.values.gameType
    )
      ? firstPaymentForm.values.bookNumber
      : undefined,
    ticketNumber: ['Instant', 'Promotion'].includes(
      firstPaymentForm.values.gameType
    )
      ? firstPaymentForm.values.ticketNumber
      : undefined,
    checkNumber: firstPaymentForm.values.checkNumber,
    firstCheckNet: +firstPaymentForm.values.firstCheckNet.replace(/\D/g, ''),
    firstCheckGross: +firstPaymentForm.values.firstCheckGross.replace(
      /\D/g,
      ''
    ),
    firstCheckIrs: +firstPaymentForm.values.firstCheckIrs.replace(/\D/g, ''),
    firstCheckDor: +firstPaymentForm.values.firstCheckDor.replace(/\D/g, ''),
    firstCheckDbd: +firstPaymentForm.values.firstCheckDbd.replace(/\D/g, ''),
    gameName: timePaymentAccountForm.values.gameName,
    gameType: timePaymentAccountForm.values.gameType,
    claimCenter: firstPaymentForm.values.claimCenter,
    tpaDocumentId: timePaymentAccountForm.values.tpaDocumentId,
    payFileKey: firstPaymentForm.values.payFileKey,
    remainingPayments: timePaymentAccountForm.values.remainingPayments,
  }
  const options = {
    method: 'POST',
    url: `${config.SERVER_BASE_URL}/v1/annuities/first-payment`,
    data: firstPaymentData,
    headers: {
      'Content-Type': `application/json`,
      'x-idempotency-token': idemToken,
    },
  }

  try {
    const response: any = await makeHttpCall(options)
    return response.id
  } catch (error) {
    throw new Error('Something went wrong Creating First Payment')
  }
}

const generateStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return ExpectedAccountStatus.active
    case 'Hold':
      return ExpectedAccountStatus.hold
    default:
      return ExpectedAccountStatus.hold
  }
}

const createPayee = async (
  payeeForm: FormikValues,
  setSearchedPayeeID: React.Dispatch<SetStateAction<string>>,
  idemToken: string
) => {
  // If there is no payeeID from payee search, must create new payee with formik values
  let payeeSpecificData: any
  const fullPayeeData = {
    address1: payeeForm.values.addr_1,
    address2: payeeForm.values.addr_2,
    city: payeeForm.values.city,
    country: payeeForm.values.country,
    phone: payeeForm.values.phone === '' ? undefined : payeeForm.values.phone,
    tin: payeeForm.values.tin,
    state: payeeForm.values.state_region,
    irsName: payeeForm.values.irsName,
    zip: `${payeeForm.values.zip_code}`,
    email: payeeForm.values.email === '' ? undefined : payeeForm.values.email,
  }
  if (payeeForm.values.payeeType === 'Entity') {
    payeeSpecificData = {
      dateOfBirth: undefined,
      dateOfDeath: undefined,
      firstName: undefined,
      freeformName: payeeForm.values.freeformName,
      lastName: undefined,
      middleInitial: undefined,
      payeeType: 'Entity',
    }
  } else {
    payeeSpecificData = {
      dateOfBirth:
        payeeForm.values.birthDate !== ''
          ? payeeForm.values.birthDate
          : undefined,
      dateOfDeath: undefined,
      firstName: payeeForm.values.firstName,
      freeformName: undefined,
      lastName: payeeForm.values.lastName,
      middleInitial: payeeForm.values.middleInitial,
      payeeType: 'Individual',
    }
  }
  const rawData = {
    ...payeeSpecificData,
    ...fullPayeeData,
  }

  const payeeData = JSON.stringify(rawData)
  const options = {
    method: 'POST',
    url: `${config.SERVER_BASE_URL}/v1/annuities/payee`,
    data: payeeData,
    headers: {
      'Content-Type': `application/json`,
      'x-idempotency-token': idemToken,
    },
  }

  let payeeId
  try {
    const response: any = await makeHttpCall(options)
    payeeId = response.id
    setSearchedPayeeID(payeeId)
  } catch (error) {
    throw new Error('Something went wrong Creating Payee')
  }

  return payeeId
}

export const getFirstPaymentClaimant: (
  firstPaymentId: string
) => Promise<FirstPaymentClaimant> = async (firstPaymentId) => {
  let firstPaymentClaimant: FirstPaymentClaimant
  const options = {
    method: 'GET',
    url: `${config.SERVER_BASE_URL}/v1/annuities/first-payment/${firstPaymentId}/claimant`,
  }

  try {
    const response = await makeHttpCall(options)
    firstPaymentClaimant = response
  } catch (error) {
    throw new Error('Failed to retrieve First Payment Claimant response')
  }

  return firstPaymentClaimant
}

export const mapClaimantForFormik = (
  claimant: FirstPaymentClaimant | undefined
) => {
  if (!claimant) {
    return
  }
  const mappedPayee = {
    firstName: claimant.firstName,
    middleInitial: claimant.middleInitial,
    lastName: claimant.lastName,
    tin: claimant.ssnFidValue,
    freeformName: claimant.freeformName,
    addr_1: claimant.address1,
    addr_2: claimant.address2,
    city: claimant.city,
    state_region: claimant.state,
    zip_code: claimant.zip5,
    country: ['US', 'USA', 'U.S', 'U.S.A'].includes(
      claimant.country.toUpperCase()
    )
      ? 'UNITED STATES OF AMERICA'
      : claimant.country.toUpperCase(),
    phone: claimant.phone,
    email: claimant.email,
    birthDate: '',
    irsName: '',
  }

  return mappedPayee
}

const createTPA = async (
  timePaymentAccountForm: FormikValues,
  payeeId: string,
  idemToken: string,
  paymentID?: string
) => {
  let accountData = {
    timePaymentAccount: {
      gameType: timePaymentAccountForm.values.gameType,
      gameName: timePaymentAccountForm.values.gameName,
      payFrequency: timePaymentAccountForm.values.payFrequency,
      drawDate:
        timePaymentAccountForm.values.drawDate.length === 0
          ? undefined
          : moment(timePaymentAccountForm.values.drawDate).toISOString(),
      claimDate: moment(timePaymentAccountForm.values.claimDate).toISOString(),
      // If first payment has occurred outside of the digital system,
      // use provided first payment date. Otherwise, use next payment date.
      firstPaymentDate: moment(
        paymentID
          ? timePaymentAccountForm.values.firstPaymentDate
          : timePaymentAccountForm.values.nextPaymentDate
      ).toISOString(),
      nextPaymentDate: moment(
        timePaymentAccountForm.values.nextPaymentDate
      ).toISOString(),
      remainingPayments: parseInt(
        timePaymentAccountForm.values.remainingPayments,
        10
      ),
      lifetimePayment: timePaymentAccountForm.values.lifetimePayment === 'Yes',
      investAcctNum:
        timePaymentAccountForm.values.investmentAccountNumber === ''
          ? undefined
          : timePaymentAccountForm.values.investmentAccountNumber,
      tpaStatus: generateStatus(timePaymentAccountForm.values.accountStatus),
      recurringPaymentAmount: Games.find(
        (game: any) => game.name === timePaymentAccountForm.values.gameName
      )?.graduated
        ? undefined
        : +timePaymentAccountForm.values.recurringPaymentAmount.replace(
            /\D/g,
            ''
          ),
      firstPaymentSrcId: paymentID,
      graduationRate: Games.find(
        (game: any) => game.name === timePaymentAccountForm.values.gameName
      )?.graduated
        ? Number(
            parseFloat(timePaymentAccountForm.values.graduationRate).toFixed(2)
          )
        : undefined,
      winAmount:
        typeof timePaymentAccountForm.values.winAmount === 'string' ||
        timePaymentAccountForm.values.winAmount instanceof String
          ? +timePaymentAccountForm.values.winAmount.replace(/\D/g, '')
          : timePaymentAccountForm.values.winAmount,
      documentId: timePaymentAccountForm.values.tpaDocumentId,
    },
    paymentAccount: {
      payeeId: payeeId,
      irsTaxEnabled: parseInt(timePaymentAccountForm.values.federalTax, 10) > 0,
      dorTaxEnabled: parseInt(timePaymentAccountForm.values.stateTax, 10) > 0,
      supportingInformation1: emptyStringToUndefined(
        timePaymentAccountForm.values.supportingInformation1
      ),
      supportingInformation2: emptyStringToUndefined(
        timePaymentAccountForm.values.supportingInformation2
      ),
    },
  }

  const sendData = JSON.stringify(accountData)

  const options = {
    method: 'POST',
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account`,
    data: sendData,
    headers: {
      'Content-Type': `application/json`,
      'x-idempotency-token': idemToken,
    },
  }
  try {
    await makeHttpCall(options)
  } catch (error) {
    throw new Error('Something went wrong Creating Time Payment Account')
  }
}

export const submitAnnuity = async (
  timePaymentAccountForm: FormikValues,
  payeeForm: FormikValues,
  firstPaymentForm: FormikValues,
  paymentID: string,
  issueFirstPayment: boolean,
  searchedPayeeID: string,
  idemToken: string,
  setSearchedPayeeID: React.Dispatch<SetStateAction<string>>
) => {
  // create first payment account if/when going through exception flow
  if ((paymentID === '' || paymentID === null) && !issueFirstPayment) {
    try {
      paymentID = await createFirstPayment(
        timePaymentAccountForm,
        firstPaymentForm,
        idemToken
      )
    } catch (error) {
      throw new Error(`${error}`)
    }
  }
  let payeeId = searchedPayeeID
  if (searchedPayeeID === '') {
    try {
      payeeId = await createPayee(payeeForm, setSearchedPayeeID, idemToken)
    } catch (error) {
      throw new Error(`${error}`)
    }
  }
  try {
    await createTPA(
      timePaymentAccountForm,
      payeeId,
      idemToken,
      emptyStringToUndefined(paymentID)
    )
  } catch (error) {
    throw new Error(`${error}`)
  }
}
