import { FormikValues, useFormik } from 'formik'
import moment from 'moment'
import React, { SetStateAction, useContext } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { BannerContext } from '../../../../../context/BannerContext'
import { PaymentRule } from '../../../../../types/Annuities/entities/payment-rule'
import { PaymentScheduleEntry } from '../../../../../types/Annuities/responses/payment-schedule'
import { ErrorMessageFieldCommonComponent } from '../../../../CommonComponents/ErrorMessageFieldCommonComponent'
import styles from './css/styles.module.css'

export const PaymentRuleComponent: React.FC<{
  paymentRule: PaymentRule
  allExistingPaymentRules: PaymentRule[]
  handleFieldValueChange: any
  index: number
  setExistingRulesPassValidation: React.Dispatch<SetStateAction<boolean>>
  tpaPaymentSchedule: PaymentScheduleEntry[]
  isEditable: boolean
}> = ({
  paymentRule,
  allExistingPaymentRules,
  handleFieldValueChange,
  index,
  setExistingRulesPassValidation,
  tpaPaymentSchedule,
  isEditable,
}) => {
  const bannerContext = useContext(BannerContext)

  const validateSiblingRules = (paymentRules: PaymentRule[]): PaymentRule[] => {
    const validationPass: PaymentRule[] = paymentRules.filter(
      (siblingPaymentrule) =>
        siblingPaymentrule.id !== paymentRule.id &&
        (siblingPaymentrule.amount === 0 ||
          siblingPaymentrule.amount === undefined ||
          isNaN(siblingPaymentrule.amount) ||
          siblingPaymentrule.startDate === '' ||
          siblingPaymentrule.startDate === 'Invalid Date' ||
          siblingPaymentrule.endDate === '' ||
          siblingPaymentrule.endDate === 'Invalid Date' ||
          moment(siblingPaymentrule.endDate) <
            moment(siblingPaymentrule.startDate))
    )

    return validationPass
  }

  const validate = (values: FormikValues) => {
    const errors = {}

    if (
      values.amount === '' ||
      values.amount === '0.00' ||
      values.amount === 0 ||
      values.amount === undefined ||
      isNaN(values.amount)
    ) {
      Object.assign(errors, { amount: 'This field is Required' })
    }

    if (values.endDate < values.startDate) {
      Object.assign(errors, { endDate: 'Cannot end before the start date' })
    }

    if (values.startDate === '' || values.startDate === 'Invalid Date') {
      Object.assign(errors, { startDate: 'This field is required' })
    }

    if (values.endDate === '' || values.endDate === 'Invalid Date') {
      Object.assign(errors, { endDate: 'This field is required' })
    }

    const allExistingRulesValidation: PaymentRule[] = validateSiblingRules(
      allExistingPaymentRules
    )

    if (
      Object.keys(errors).length !== 0 ||
      allExistingRulesValidation.length > 0
    ) {
      setExistingRulesPassValidation(false)
    } else if (
      Object.keys(errors).length === 0 &&
      allExistingRulesValidation.length < 1
    ) {
      setExistingRulesPassValidation(true)
    }

    return errors
  }

  // initialize formik
  const formik = useFormik({
    initialValues: {
      subAccountId: paymentRule.subAccountId,
      startDate: paymentRule.startDate,
      endDate: paymentRule.endDate,
      amount: paymentRule.amount || 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => {},
    validate,
  })

  // Need to revisit this
  const paymentCount = tpaPaymentSchedule.filter(
    (entry) => entry.paymentRule.id === paymentRule.id
  ).length

  return (
    <div className={`${styles.payment_info_container}`}>
      <div className={`${styles.flex_sp_bw}`}>
        <div className={`${styles.flex}`}>
          <div className={`${styles.header_icon_container}`}>{index}</div>
          <h4>Payment Information</h4>
        </div>
      </div>
      <div>
        <div className={`${styles.flex}`}>
          <label htmlFor="amount" className={`${styles.payment_rule_label}`}>
            Deposit
          </label>
          <div className={`${styles.flex_col}`}>
            <div className={`${styles.info_detail}`}>
              <CurrencyInput
                className={`${styles.custom_input}`}
                name="amount"
                id="amount"
                prefix="$"
                defaultValue={formik.values.amount / 100}
                onBlur={() => formik.setFieldTouched('amount', true)}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name!, value)
                  handleFieldValueChange(name, value, paymentRule.id)
                }}
                decimalsLimit={2}
                decimalScale={2}
                disableAbbreviations={true}
                allowNegativeValue={false}
                disabled={isEditable === true ? false : true}
              />
            </div>

            {formik.errors.amount && (
              <div className={`${styles.info_detail}`}>
                <ErrorMessageFieldCommonComponent
                  errorMessage={formik.errors.amount}
                />
              </div>
            )}
          </div>

          <label htmlFor="startDate" className={`${styles.payment_rule_label}`}>
            From
          </label>
          <div className={`${styles.flex_col}`}>
            <div className={`${styles.info_detail}`}>
              <input
                type="date"
                max={moment(formik.values.endDate ?? '9999-12-31').format(
                  'YYYY-MM-DD'
                )}
                name="startDate"
                id="startDate"
                className={`${styles.custom_input}`}
                value={
                  formik.values.startDate === ''
                    ? ''
                    : moment(formik.values.startDate).format('YYYY-MM-DD')
                }
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(event.target.name, event.target.value)
                  handleFieldValueChange(
                    event.target.name,
                    event.target.value,
                    paymentRule.id
                  )
                }}
                disabled={isEditable === true ? false : true}
              />
            </div>

            {formik.errors.startDate && (
              <div className={`${styles.info_detail}`}>
                <ErrorMessageFieldCommonComponent
                  errorMessage={formik.errors.startDate}
                />
              </div>
            )}
          </div>

          <label htmlFor="endDate" className={`${styles.payment_rule_label}`}>
            To
          </label>
          <div className={`${styles.flex_col}`}>
            <div className={`${styles.info_detail}`}>
              <input
                type="date"
                min={moment(formik.values.startDate).format('YYYY-MM-DD')}
                max="9999-12-31"
                name="endDate"
                id="endDate"
                className={`${styles.custom_input}`}
                value={
                  formik.values.endDate === ''
                    ? ''
                    : moment(formik.values.endDate).format('YYYY-MM-DD')
                }
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(event.target.name, event.target.value)
                  handleFieldValueChange(
                    event.target.name,
                    event.target.value,
                    paymentRule.id
                  )
                }}
                disabled={isEditable === true ? false : true}
              />
            </div>

            {formik.errors.endDate && (
              <div className={`${styles.info_detail}`}>
                <ErrorMessageFieldCommonComponent
                  errorMessage={formik.errors.endDate}
                />
              </div>
            )}
          </div>
        </div>
        {/* Need to revisit this */}
        <div>(Selected date range includes {paymentCount} payment(s).)</div>
      </div>
    </div>
  )
}
