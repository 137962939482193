import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/styles.module.css";
import {PaginationCommonComponent} from "../../../../CommonComponents/PaginationCommonComponent";
import {getPageData} from "./functions";
import {keyPressedType} from "../../../../../services/Commons";
import {pulltabRoutePath} from "../../../../../config";
import {permissions} from "../../../../../constants";
import {checkRenderPermissions} from "../../../../../helpers";
import {inject, observer} from "mobx-react";
import {LicenseeInterface} from "../../../../../pages/CharitableGamingPages/License/LicenseProfileManagementPage/interfaces";

export const PullTabOrderCreationStepOneSearchResultComponent = inject("charitableGamingStore")(observer(({licenseeList, currentPage, setCurrentPage, backButtonText, charitableGamingStore}: any) => {

        const history: any = useHistory();
        const userpolicies: any = localStorage.getItem("userpolicies");

        const normalizePage = (totalPages: number, currentPage: number) => {
            const number: number = totalPages - currentPage * 10;
            if (number > 10) return currentPage * 10 + 10;
            else return totalPages;
        };

        return (
            <>

                <div className={styles['table']}>
                    <div className={styles['table-data-header']}>
                        <div aria-live="assertive"
                        >{`Displaying ${licenseeList.length <= 0 ? 0 :
                            (currentPage * 10) + 1}-${normalizePage(licenseeList.length, currentPage)} 
                        of ${licenseeList.length} results`}</div>
                    </div>

                    <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                        <div>License No.</div>
                        <div>Organization Name</div>
                        <div>Mailing & Shipping Address</div>
                        <div>City/Town</div>
                        <div>Zipcode</div>
                    </div>

                    <div className={styles['table-data-body']}>
                        {licenseeList.length > 0 &&
                        getPageData(licenseeList, currentPage).map((e: LicenseeInterface, i: number) =>

                            <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                 className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                <div className={styles['table-data-data-text']}>
                                    {e.licenseDetails.licenseNumber}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {e.orgDetails.orgName}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {e.orgDetails.mailAddress.streetAddress}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {e.orgDetails.mailAddress.city}
                                </div>
                                <div className={styles['table-data-data-text']}>
                                    {e.orgDetails.mailAddress.zip}
                                </div>
                                <div className={styles['table-details-button-container']}>
                                    {checkRenderPermissions(permissions.CAN_CREATE_PULL_TAB_ORDERS, JSON.parse(userpolicies)) ?
                                        <div tabIndex={0} role='button' aria-pressed={"false"}
                                             onKeyDown={(en: any) => {
                                                 if (
                                                     keyPressedType(en) === 13 ||
                                                     keyPressedType(en) === 0 ||
                                                     keyPressedType(en) === 32
                                                 ) {
                                                     history.push({
                                                         pathname: `${pulltabRoutePath}/orders/create/step-2/${e.licenseDetails.licenseNumber}`,
                                                         state: {backButtonText: ''}
                                                     });
                                                 }
                                             }}
                                             onClick={() => {
                                                 history.push({
                                                     pathname: `${pulltabRoutePath}/orders/create/step-2/${e.licenseDetails.licenseNumber}`,
                                                     state: {backButtonText: ''}
                                                 });
                                             }}
                                             className={styles['table-details-button']}>
                                            <div aria-label={'Licensee Details Button.'}
                                                 className={styles['table-details-button-text']}>
                                                Create Order
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((licenseeList.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={licenseeList} currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>
                </div>


            </>

        );
    })
);
