import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { getPayee, getPlaceholderPayee } from "./functions";
import { keyPressedType } from "../../../../services/Commons";
import { annuitiesRoutePath } from "../../../../config.js";
import styles from "./css/styles.module.css";
import { PayeeDetailsReadOnly } from "../../../../components/AnnuitiesComponents/PayeeManagement/PayeeDetails/PayeeDetailsReadOnlyCmpt";
import { PayeeDetailsEdit } from "../../../../components/AnnuitiesComponents/PayeeManagement/PayeeDetails/PayeeDetailsEditCmpt";
import { PayeeDetailsPaymentComponent } from "../../../../components/AnnuitiesComponents/PayeeManagement/PayeeDetails/PayeeDetailsPaymentCmpt";
import { FormPayee } from "../../../../types/Annuities/PayeeManagement/Payee";
import { KEY_CODES } from "../../../../constants.js";
import { BannerContext } from "../../../../context/BannerContext";

export const AnnuitiesPayeeDetailsPage = () => {
  // routing config
  const history = useHistory();
  const location = useLocation();
  const bannerContext = useContext(BannerContext);
  const searchParams = new URLSearchParams(location.search);

  // ID from URL
  const payeeId: string = searchParams.get("payeeId")!;
  const tpaId: string | null = searchParams.get("tpaId");
  const fromPaymentDetails: string | null = searchParams.get("fromPaymentDetails");
  const [payee, setPayee] = useState<FormPayee>(getPlaceholderPayee());
  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payeeResponse: FormPayee = await getPayee(payeeId);
        setPayee(payeeResponse);
      } catch (error) {
        bannerContext.setBannerInfo({ message: `${error}`, error: true });
      }
    };

    fetchData();
  }, [payeeId]);
  return (
    <>
      <div className={styles["page-container"]}>
        {/* Back Btn tsx */}
        <div className={styles["back-btn-container"]}>
          <button
            type="button"
            onKeyPress={event => {
              if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                history.go(-1);
              }
            }}
            onClick={() => {
              history.go(-1);
            }}
            className={styles["back-btn-inner-container"]}
          >
            <FaAngleLeft title="left arrow icon" className={styles["back-btn-custom"]} />
            {fromPaymentDetails === "true" ? <div className={styles["back-btn-text"]}>Back to Payment Details</div> : tpaId === null ? <div className={styles["back-btn-text"]}>Back to Payee Management</div> : <div className={styles["back-btn-text"]}>Back to Time Payment Account Details</div>}
          </button>
        </div>
        {/* Header */}
        <div className={styles["cmpnt-container"]}>
          <div className={styles["top-ribbon-text"]}>
            <div>{payee.payeeType === "Individual" ? <h1>{payee.firstName}'s Payee Details</h1> : <h1>{payee.freeformName}'s Payee Details</h1>}</div>
            <div>
              <p>
                Player ID <strong>{payee.id}</strong>
              </p>
            </div>
          </div>
          {/* Main Components */}
          <div className={styles["margin-bottom"]}></div>
          <div className={styles["table-container"]}>
            {editable ? (
              <PayeeDetailsEdit payee={payee} setPayee={setPayee} toggleEditable={() => setEditable(!editable)} />
            ) : (
              <PayeeDetailsReadOnly payee={payee} toggleEditable={() => setEditable(!editable)} />
            )}
          </div>
          {tpaId !== null && (
            <div className={styles["table-container"]}>
              <PayeeDetailsPaymentComponent payeeId={payeeId} tpaId={tpaId} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
