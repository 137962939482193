import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";
import CurrencyInput from "react-currency-input-field";

export const UpdatePullTabGameComponent = ({formik, setFieldValue}: any) => {

    return (
        <>
            <form className={styles['update-pulltab-game-component-container']}>
                <div tabIndex={0} className={styles['update-pulltab-game-component-title']}>Pull Tab Details</div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Stock Number*
                    </div>
                    <input
                        id="stockNumber"
                        name="stockNumber"
                        className={styles["update-pulltab-game-component-input-box"]}
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.stockNumber}
                    />
                    {(formik.touched.stockNumber && formik.errors.stockNumber) ?
                        <ErrorMessageFieldCommonComponent
                            orgName={formik.errors.stockNumber}/> : null}
                </div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Units Per Case*</div>
                    <input
                        id="unitsPerCase"
                        name="unitsPerCase"
                        value={formik.values.unitsPerCase}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFieldValue(e)}
                        className={`${styles["update-pulltab-game-component-input-box"]}`}
                        type="text"
                    />
                    {(formik.touched.unitsPerCase && formik.errors.unitsPerCase) ?
                        <ErrorMessageFieldCommonComponent
                            city={formik.errors.unitsPerCase}/> : null}
                </div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Game Name*</div>
                    <input
                        autoComplete={"text"}
                        id="gameName"
                        name="gameName"
                        className={styles["update-pulltab-game-component-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.gameName}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.gameName && formik.errors.gameName) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.gameName}/> : null}
                </div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Case Cost*
                    </div>
                    <CurrencyInput
                        className={`${styles["update-pulltab-game-component-input-box"]}`}
                        id="casePriceInDollars"
                        name="casePriceInDollars"
                        prefix="$"
                        defaultValue={formik.values.casePriceInDollars}
                        onBlur={formik.handleBlur}
                        decimalsLimit={2}
                        disableAbbreviations={true}
                        onValueChange={(value, name) => formik.setFieldValue(name, value)}
                    />
                    {(formik.touched.casePriceInDollars && formik.errors.casePriceInDollars) ?
                        <ErrorMessageFieldCommonComponent
                            orgDateChartered={formik.errors.casePriceInDollars}/> : null}
                </div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Unit Cost*</div>
                    <CurrencyInput
                        className={styles["update-pulltab-game-component-input-box"]}
                        id="unitPriceInDollars"
                        name="unitPriceInDollars"
                        prefix="$"
                        defaultValue={formik.values.unitPriceInDollars}
                        onBlur={formik.handleBlur}
                        decimalsLimit={2}
                        disableAbbreviations={true}
                        onValueChange={(value, name) => formik.setFieldValue(name, value)}
                    />
                    {(formik.touched.unitPriceInDollars && formik.errors.unitPriceInDollars) ?
                        <ErrorMessageFieldCommonComponent
                            orgPhone={formik.errors.unitPriceInDollars}/> : null}
                </div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Case Weight*
                    </div>
                    <CurrencyInput
                        className={styles["update-pulltab-game-component-input-box"]}
                        id="caseWeight"
                        name="caseWeight"
                        defaultValue={formik.values.caseWeight}
                        onBlur={formik.handleBlur}
                        suffix={' lbs'}
                        decimalsLimit={2}
                        disableGroupSeparators={true}
                        disableAbbreviations={true}
                        placeholder={'Ex. 0.00 lbs'}
                        onValueChange={(value, name) => formik.setFieldValue('caseWeight', value)}
                    />
                    {(formik.touched.caseWeight && formik.errors.caseWeight) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.caseWeight}/> : null}
                </div>
                <div className={styles["update-pulltab-game-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["update-pulltab-game-component-label"]}>Unit Weight*
                    </div>
                    <CurrencyInput
                        className={styles["update-pulltab-game-component-input-box"]}
                        id="unitWeight"
                        name="unitWeight"
                        defaultValue={formik.values.unitWeight}
                        onBlur={formik.handleBlur}
                        suffix={' lbs'}
                        decimalsLimit={2}
                        disableGroupSeparators={true}
                        disableAbbreviations={true}
                        placeholder={'Ex. 0.00 lbs'}
                        onValueChange={(value, name) => formik.setFieldValue('unitWeight', value)}
                    />
                    {(formik.touched.unitWeight && formik.errors.unitWeight) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.unitWeight}/> : null}
                </div>
            </form>
        </>

    );
}