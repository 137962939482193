import * as React from 'react'
import { PromotionsReportingPageUI } from './promotions-reporting-page-ui'
import { TriggerEvent } from '../../../api/promotions-fetcher/constants'
import { useFetchAllPromotions } from '../../../api/promotions-fetcher/use-fetch-base-promotions'

export const PromotionsReportingPageContainer = () => {
  const {
    data: promotions,
    isLoading: promotionsLoading,
    error: promotionsError,
  } = useFetchAllPromotions()
  const scanPromotions = promotions?.filter(
    (promotion) => promotion.triggerEventType === TriggerEvent.SCAN
  )
  return (
    <PromotionsReportingPageUI
      promotions={scanPromotions}
      promotionsLoading={promotionsLoading}
      promotionsError={promotionsError}
    />
  )
}
