import { create } from 'mobx-persist'
import { PlayerStore } from './PlayerStore'
import { AuthStore } from './AuthStore'
import { SettingsStore } from './SettingsStore'
import { ClaimStore } from './ClaimStore'
import { AnnuitiesStore } from './AnnuitiesStore'
import { MiscellaneousStore } from './MiscellaneousStore'
import { CharitableGamingStore } from './CharitableGamingStore'
import { PaymentSearchStore } from './PaymentSearchStore'

const hydrate = create({
  storage: localStorage,
})

class RootStore {
  playerStore = null
  authStore = null
  settingsStore = null
  claimStore = null
  miscellaneousStore = null
  annuitiesStore = null
  charitableGamingStore = null
  paymentSearchStore = null

  constructor() {
    this.playerStore = new PlayerStore(this)
    this.authStore = new AuthStore(this)
    this.settingsStore = new SettingsStore(this)
    this.claimStore = new ClaimStore(this)
    this.miscellaneousStore = new MiscellaneousStore(this)
    this.annuitiesStore = new AnnuitiesStore(this)
    this.charitableGamingStore = new CharitableGamingStore(this)
    this.paymentSearchStore = new PaymentSearchStore()

    Promise.all([
      hydrate('auth', this.authStore),
      hydrate('player', this.playerStore),
      hydrate('settings', this.settingsStore),
      hydrate('claim', this.claimStore),
      hydrate('miscellaneous', this.miscellaneousStore),
      hydrate('annuities', this.annuitiesStore),
      hydrate('charitableGaming', this.charitableGamingStore)
      // don't bother hydrating paymentSearchStore, we're never serializing it
    ]).then(() => console.log('All Stores Hydrated.'))
  }
}

export const rootStore = new RootStore()
