import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import {
    getPageData,
    setDateFormat,
} from "./functions";

import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import {PlayerSessionActivityInterface} from "./interface";
import { keyPressedType } from "../../../services/Commons";
import { playerSupportRoutePath } from './../../../config.js'
import {checkRenderPermissions} from './../../../helpers.js';
import {permissions} from './../../../constants.js';
import { CircularProgress } from "@material-ui/core";


export const PlayerSessionActivityTableComponent = ({ isDataPerformedSession, pageMessage, PlayerSessionActivityData, id }: any) => {

    const userpolicies: any = localStorage.getItem('userpolicies');
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState<number>(0);

    return (
        <>

            <div className={styles['KYCQUEUE-table']}>
            {(PlayerSessionActivityData.length <= 0 && !isDataPerformedSession.value && pageMessage.message === "") && <div style={{ position: 'absolute', zIndex:50, top: 0, left: 0, width: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
                 <CircularProgress size="8em" color='inherit' thickness={2} />
            </div>}
                <div className={styles['KYCQUEUE-table-data-header']}>
                    <div className={styles['KYCQUEUE-table-data-header-inner-container']}>
                        <div tabIndex={0} className={styles['KYCQUEUE-table-data-header-title']} >Session Activity</div>
                        <div tabIndex={0} className={styles['KYCQUEUE-table-data-header-text']}>5 most recent</div>
                    </div>
                   { checkRenderPermissions(permissions.CAN_SEE_SESSION_ACTIVITY, JSON.parse(userpolicies)) && 
                   <div tabIndex={0} 
                         onKeyPress={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                history.push(`${playerSupportRoutePath}/session-activity/${id}`);
                                
                            }
                        }
                        }
                       onClick={() => { history.push(`${playerSupportRoutePath}/session-activity/${id}`)}} role="button" className={styles['table-profile-content-button']}>
                        <div className={styles['table-profile-content-button-text']}>View All</div>
                    </div> }
                </div>

                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date</div>
                    <div tabIndex={0}>Activity</div>
                    <div tabIndex={0}>Device</div>
                </div>
                <div className={styles['KYCQUEUE-table-data-body']} >
                    {PlayerSessionActivityData.length > 0 && getPageData(PlayerSessionActivityData, currentPage).map((e: PlayerSessionActivityInterface, i: number) =>

                        <div key={i} style={{ backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)') }} className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.eventTimestamp, "America/New_York").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;{moment.tz(e.eventTimestamp, "America/New_York").format('h:mm:ss a')} </div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.authEvent}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.clientOs}</div>
                        </div>
                    )}
                </div>
            </div>


        </>

    );
}


