import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {keyPressedType} from "../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../icons/Icon_arrows_carrot_white-left.svg";
import {exportW2GPrintDetails, onSubmit, setVariables, validate} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../components/CommonComponents/GenericErrorDialogComponent";
import {pageMessageDefault} from "../../../components/CommonComponents/PageMessageCommonComponent";
import {getPageData} from "../../CharitableGamingPages/PullTab/Inventory/PullTabInventoryPage/functions";
import {formatCentsToDollars} from "../../../util";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";

export const W2GPrintDetailsPage = ({sweepId}: any) => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const formik = useFormik({
        initialValues: {
            sweepId: sweepId
        }, validate, onSubmit
    });

    const [W2GQueue, setW2GQueue] = useState<any>(null);

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);

    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const activateExportButton = (W2GQueue && W2GQueue.paymentsInSweep.length > 0);

    const normalizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10) return currentPage * 10 + 10; else return totalPages;
    };

    useEffect(() => {
        if (requestCompleted === false) {
            formik.submitForm();
        }
    }, []);

    setVariables(setRequestCompleted, setW2GQueue, setCurrentPage, setPageMessage, setShowErrorDialog);

    return (<>
        {(!requestCompleted) ? <div style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: "100vw",
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "rgba(0,0,0,0.3)",
            color: 'white'
        }}>
            <CircularProgress size="4em" color='inherit' thickness={2}/>
        </div> : null}

        <div className={`${styles['pulltab-inventory-page-container']} ${styles['']}`}>
            <div tabIndex={0}
                 className={`${styles['pulltab-inventory-page-back-button-container']} ${styles['']}`}
                 onKeyDown={(ev: any) => {
                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                         history.goBack();
                     }
                 }}
                 onClick={() => {
                     history.goBack();
                 }}
            >
                <img
                    style={{
                        cursor: "pointer"
                    }}
                    src={goBackIcon}
                    className={styles[""]}
                    alt="go back icon"
                />
                <div
                    className={`${styles['pulltab-inventory-page-back-button-container-message']} ${styles['']}`}
                    style={{
                        cursor: "pointer"
                    }}
                >Back to W-2G Print History
                </div>
            </div>
            <div className={`${styles['pulltab-inventory-page-ribbon-container']} ${styles['']}`}>
                <div className={`${styles['pulltab-inventory-page-ribbon-title']} ${styles['']}`}>Print Details
                </div>
                <div className={`${styles['pulltab-inventory-page-ribbon-buttons-container']}`}>
                    {checkRenderPermissions(permissions.CAN_EXPORT_W2G_PRINT_DETAILS, JSON.parse(userpolicies)) ?
                        <div tabIndex={0}
                             className={`${styles['pulltab-inventory-page-ribbon-renew-license-button']} ${styles['']}`}
                             role="button"
                             style={{
                                 opacity: (activateExportButton) ? "1" : "0.4",
                                 cursor: (activateExportButton) ? "pointer" : "not-allowed",
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32 && activateExportButton) {
                                     exportW2GPrintDetails(W2GQueue, sweepId);
                                 }
                             }}
                             onClick={() => {
                                 if (activateExportButton) {
                                     exportW2GPrintDetails(W2GQueue, sweepId);
                                 }
                             }}>
                            <div
                                className={`${styles['pulltab-inventory-page-ribbon-create-license-button-label']}`}
                            >
                                Export
                            </div>
                        </div> : null}

                </div>

            </div>

            {!W2GQueue ? <div className={`${styles['pulltab-inventory-page-search-no-results-container']}`}>
                <div className={`${styles['pulltab-inventory-page-search-no-results-container-text']}`}>
                    {/*Select a view to generate results*/}
                </div>
            </div> : <div className={styles['table']}>
                {/*                    <div className={styles['table-data-header']}>
                        <div className={styles['table-data-header-text']}
                             aria-live="assertive">{`Displaying ${W2GQueue.paymentsInSweep.length <= 0 ? 0 : (currentPage * 10) + 1}-${normalizePage(W2GQueue.paymentsInSweep.length, currentPage)} 
                        of ${W2GQueue.paymentsInSweep.length} results`}</div>
                    </div>*/}

                <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                    <div>PMT/TMPT Key</div>
                    <div>Payee Name</div>
                    <div>TIN</div>
                    <div>Date</div>
                    <div>Reportable Payment</div>
                    <div>Federal Tax</div>
                    <div>State Tax</div>
                    <div>Offsets</div>
                    <div>Net Payment</div>
                </div>

                <div className={styles['table-data-body']}>
                    {W2GQueue.paymentsInSweep.length > 0 && getPageData(W2GQueue.paymentsInSweep, currentPage).map((e: any, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                             className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                            <div className={styles['table-data-data-text']}>
                                {e.paymentKey}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.payeeName}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.payeeSsn}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {e.reportablePaymentDate}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.reportableWinningsInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.irsTaxInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.dorTaxInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.offsetsWithheldInCents)}
                            </div>
                            <div className={styles['table-data-data-text']}>
                                {formatCentsToDollars(e.netPaymentInCents)}
                            </div>
                        </div>)}
                </div>
                {/*<div className={styles['table-data-footer']}>

                        <div aria-live="assertive"
                             className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((W2GQueue.prints.length / 10)).toString()}`}</div>
                        {<PaginationCommonComponent playerData={W2GQueue.prints} currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}/>}

                    </div>*/}
            </div>}
        </div>
        {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
    </>);
};