import { permissions } from '../../constants.js'

export const PlayslipsStepDownPermissions = {
  CAN_READ_PLAYSLIPS_STEP_DOWN: permissions.CAN_READ_PLAYSLIPS_STEP_DOWN,
  CAN_ADMIN_PLAYSLIPS_STEP_DOWN: permissions.CAN_ADMIN_PLAYSLIPS_STEP_DOWN,
}

export const AllPlayslipsStepDownPermissions = Object.values(
  PlayslipsStepDownPermissions
)

export const PromotionalMessagesPermissions = {
  CAN_ADMIN_PROMOTIONAL_MESSAGES: permissions.CAN_ADMIN_PROMOTIONAL_MESSAGES,
  CAN_READ_PROMOTIONAL_MESSAGES: permissions.CAN_READ_PROMOTIONAL_MESSAGES,
}

export const AllPromotionalMessagesPermissions = Object.values(
  PromotionalMessagesPermissions
)
