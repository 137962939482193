import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {getWeeklyBeanoFormData, setVariables, submitWeeklyBeanoForm} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {NotificationBannerComponent} from "../../../../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {WeeklyBeanoForm, WeeklyBeanoFormBodySubmission} from "@interaction-gaming/mslc-charitable-gaming-components";

export const WeeklyBeanoTaxFormPage = inject("charitableGamingStore")(observer(({
                                                                                    taxFormId, charitableGamingStore
                                                                                }: any) => {

    const history: any = useHistory();
    const location = useLocation();

    const userpolicies: any = localStorage.getItem("userpolicies");

    const [weeklyBeanoFormData, setWeeklyBeanoFormData] = useState<any>();
    const [orgData, setOrgData] = useState<any>();
    const [occasionTime, setOccasionTime] = useState<any>();

    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const {notificationBanner} = charitableGamingStore;

    const [showUnbalancedTaxFormApprovalConfirmationDialog, setShowUnbalancedTaxFormApprovalConfirmationDialog] = useState<boolean>(false);

    const backButtonText = localStorage.getItem("WeeklyBeanoTaxFormPageBackButtonText");

    useEffect(() => {
        if (location.state) {
            localStorage.setItem("WeeklyBeanoTaxFormPageBackButtonText", location.state.backButtonText);
        }
        getWeeklyBeanoFormData(setWeeklyBeanoFormData, setOrgData, setOccasionTime);
    }, []);

    const hideNotificationBanner = () => {
        charitableGamingStore.showNotificationBanner({show: false, text: ''});
    }

    setVariables(taxFormId, charitableGamingStore, history, setRequestCompleted, setShowErrorDialog);

    const handleGoBack = () => {
        localStorage.removeItem("WeeklyBeanoTaxFormPageBackButtonText");
        history.goBack();
    }

    return (<>
            {(!requestCompleted) ? <div style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                left: 0,
                width: "100vw",
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "rgba(0,0,0,0.3)",
                color: 'white'
            }}>
                <CircularProgress size="4em" color='inherit' thickness={2}/>
            </div> : null}

            <div className={`${styles['pulltab-stock-inventory-page-container']} ${styles['']}`}
                 onClick={() => {

                 }}
            >
                <div tabIndex={0}
                     className={`${styles['pulltab-stock-inventory-page-back-button-container']} ${styles['']}`}
                     onKeyDown={(ev: any) => {
                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                             handleGoBack();
                         }
                     }}
                     onClick={() => {
                         handleGoBack();
                     }}
                >
                    <img
                        style={{
                            cursor: "pointer"
                        }}
                        src={goBackIcon}
                        className={styles[""]}
                        alt="go back icon"
                    />
                    <div
                        className={`${styles['pulltab-stock-inventory-page-back-button-container-message']} ${styles['']}`}
                        style={{
                            cursor: "pointer"
                        }}
                    >{backButtonText}
                    </div>
                </div>

                <div className={`${styles["license-details-page-tabs-container"]}`}>
                    {weeklyBeanoFormData ? <WeeklyBeanoForm
                        initialValues={weeklyBeanoFormData}
                        onSubmit={(formData: WeeklyBeanoFormBodySubmission) => {
                            submitWeeklyBeanoForm(formData, taxFormId);
                        }}
                        submitDisabled={isLoading}
                        dateString={moment.utc(weeklyBeanoFormData.occasionDate).format('MM/DD/YYYY')}
                        organizationDetails={orgData}
                        occasionTime={occasionTime}
                        backOffice
                    /> : null}
                </div>
            </div>
            {showErrorDialog ? <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/> : null}
            {notificationBanner.show ? <NotificationBannerComponent
                text={notificationBanner.text}
                type={notificationBanner.type}
                handleHide={hideNotificationBanner}
            /> : null}
        </>

    );
}));