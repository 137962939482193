import {InputValidationMessages} from "../../../../../Constants/InputValidationMessages";
import config from "../../../../../config";
import {makeHttpCall} from "../../../../../services/MakeHttpCall";
import {NotificationType} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {BarcodeScanType} from "../../../../../Constants/CharitableConstant.js";

let licenseNumber: string;
let formik: any;
let history: any;
let warehouses: any;
let setValidWarehouses: any;
let charitableGamingStore: any;

const BARCODE_LENGTH = 18;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any, warehousesObj: any, setValidWarehousesObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    warehouses = warehousesObj;
    setValidWarehouses = setValidWarehousesObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setWarehouse = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const setFieldValue = async (e: any, i: number) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {

        const index = e.target.name.split('.')[1];

        formik.setFieldValue(e.target.name, e.target.value.trim(), true);

        formik.setFieldValue(`barcodeInformation.${index}.stockNumber`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.serialNumbers`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.quantity`, '', true);
        formik.setFieldValue(`barcodeInformation.${index}.currentWarehouse`, '-', true);
        formik.setFieldValue(`barcodeInformation.${index}.error`, '', true);

        if (e.target.value.trim().length === 18) {
            await ScanBarcode(e.target.value.trim(), index);
        }
    }

}

export const validate = (values: any) => {

    const errors: any = {};

    for (let i = 0; i < values.barcodeInformation.length; i++) {
        if (!values.barcodeInformation[i].barcodeNumber) {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.RequiredField;
        } else if (values.barcodeInformation[i].barcodeNumber && values.barcodeInformation[i].barcodeNumber.length < BARCODE_LENGTH) {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.InvalidBarcode;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeIneligibleTransfer') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeIneligibleTransfer;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeNotfound') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeNotfound;
        } else if (values.barcodeInformation[i].error && values.barcodeInformation[i].error === 'BarcodeScanGenericError') {
            errors[`barcodeInformation-${i}-barcodeNumber`] = InputValidationMessages.BarcodeScanGenericError;
        }
    }

    if (!values.warehouse) {
        Object.assign(errors, {warehouse: InputValidationMessages.RequiredField});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    TransferInventory(values);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {
    history.goBack();
}

export const YesDeleteBarcode = function (deleteIndex: number) {

    let barcodeInformationTemp = JSON.parse(JSON.stringify(formik.values.barcodeInformation));
    barcodeInformationTemp.splice(deleteIndex, 1);

    formik.setFieldValue("barcodeInformation", barcodeInformationTemp, true);

}

export const YesResetAllBarcodes = () => {

    let barcodeInformationTemp = [];

    for (let i = 0; i < formik.values.barcodeInformation.length; i++) {
        barcodeInformationTemp.push({
            serialNumber: barcodeInformationTemp.length + 1,
            barcodeNumber: '',
            stockNumber: '-',
            serialNumbers: '-',
            quantity: '0',
            currentWarehouse: '-',
            error: ''
        });
    }

    formik.resetForm({
        values: {
            warehouse: formik.values.warehouse,
            barcodeInformation: barcodeInformationTemp
        }
    });

    setValidWarehouses(JSON.parse(JSON.stringify(warehouses)));

}

export const ScanBarcode = async function (barcodeNumber: string, index: number) {

    const body = {
        barcode: barcodeNumber,
        scanType: BarcodeScanType.TRANSFER
    };

    try {

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/barcode/scan`,
            data: body
        }

        let response = await makeHttpCall(options);

        if (!response.hasOwnProperty('purchaseCostDetails')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeIneligibleTransfer', true);
            return;
        }

        formik.setFieldValue(`barcodeInformation.${index}.stockNumber`, response.stockNumber, true);
        formik.setFieldValue(`barcodeInformation.${index}.serialNumbers`, `${response.serialNumberStart} - ${response.serialNumberEnd}`, true);
        formik.setFieldValue(`barcodeInformation.${index}.quantity`, response.numUnits.toString(), true);
        formik.setFieldValue(`barcodeInformation.${index}.currentWarehouse`, response.purchaseCostDetails.currentWarehouse.toString(), true);

        // If currently selected Warehouse is same as the current warehouse of the scanned barcode then reset the Warehouse dropdown list
        if (formik.values.warehouse !== '') {
            const currentSelectedWarehouse = formik.values.warehouse.split('-')[0].trim();
            if (currentSelectedWarehouse === response.purchaseCostDetails.currentWarehouse.toString()) {
                formik.setFieldValue('warehouse', '', true);
            }
        }

        await filterWarehouses();

    } catch (e: any) {
        console.log(e);
        if (e.toString().includes('404')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeNotfound', true);
        } else if (e.toString().includes('409')) {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeIneligibleTransfer', true);
        } else {
            formik.setFieldValue(`barcodeInformation.${index}.error`, 'BarcodeScanGenericError', true);
        }
    }

}

export const TransferInventory = async function (values: any) {

    //alert(JSON.stringify(values));

    const body: any = {
        destinationWarehouse: values.warehouse.split('-')[0].trim(),
        barcodes: []
    };

    try {

        for (let barcodeInfo of values.barcodeInformation) {
            body.barcodes.push(barcodeInfo.barcodeNumber);
        }

        // alert(JSON.stringify(body));

        const options = {
            method: 'POST',
            url: `${config.SERVER_BASE_URL}/api/v1/pulltab/inventory/transfer`,
            data: body
        }

        let response = await makeHttpCall(options);

        history.goBack();

        charitableGamingStore.showNotificationBanner({
            show: true,
            type: NotificationType.Success,
            text: 'The inventory was successfully transferred.'
        });

    } catch (e) {
        console.log(e);
        charitableGamingStore.showErrorNotificationBanner('Something went wrong. Please try again');
    }

}

/*
 Updates the Warehouse dropdown with only valid Warehouses
 An Invalid Warehouse would be the current warehouse of the packet to be transferred.
 */
const filterWarehouses = async function () {

    let invalidWarehouses = new Set();
    for (let i = 0; i < formik.values.barcodeInformation.length; i++) {
        if (formik.values.barcodeInformation[i].currentWarehouse) {
            invalidWarehouses.add(formik.values.barcodeInformation[i].currentWarehouse);
        }
    }

    let validWarehouses = [];
    for (let warehouse of warehouses) {
        const warehouseId = warehouse.split('-')[0].trim();
        if (!invalidWarehouses.has(warehouseId)) {
            validWarehouses.push(warehouse);
        }
    }

    setValidWarehouses(validWarehouses);

}




