import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { useHistory } from "react-router-dom";
import { playerSupportRoutePath } from './../../../config.js'
import { keyPressedType } from "../../../services/Commons";
import { setDateFormat } from './functions';
import { playersIdentityValues } from './../../../services/Commons';
import { clearPageMessageDefault } from "../../CommonComponents/PageMessageCommonComponent";
import {checkRenderPermissions} from "../../../helpers.js";
import {permissions} from "../../../constants.js";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {logAction} from "../../../util";
import {UserAction, UserActionCategory} from "../../../UserActionCategory";

export const PlayerProfileComponent = ({ playerStore }: any) => {

    let history = useHistory();

    const userpolicies: any = localStorage.getItem('userpolicies');
    const canSeeSSN = checkRenderPermissions(permissions.CAN_SEE_SSN, JSON.parse(userpolicies));
    const [unmaskSSN, setUnmaskSSN] = useState<boolean>(false);
   
    return (
        <>
            <div onFocus={()=> clearPageMessageDefault() } className={styles['page-container']}>
                <div className={styles['player-profile-content-title-container']}>
                    <div tabIndex={0} className={styles['player-profile-content-title']} >Profile</div>
                    <div tabIndex={0} style={{ opacity: (playersIdentityValues.identityFieldIsUndefined === true) ? 0.4 : 1, 
                                               cursor: playersIdentityValues.identityFieldIsUndefined === true ? "not-allowed" : "pointer",    
                }}
                        aria-hidden={`${playersIdentityValues.identityFieldIsUndefined}`}
                        onKeyPress={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                clearPageMessageDefault(); playersIdentityValues.identityFieldIsUndefined === false && history.push(`${playerSupportRoutePath}/update-profile/${playerStore.account.id}`)
                            }
                        }
                        }
                        onClick={() => { clearPageMessageDefault(); playersIdentityValues.identityFieldIsUndefined === false && history.push(`${playerSupportRoutePath}/update-profile/${playerStore.account.id}`) }} role="button" className={styles['player-profile-content-button']}>
                        <div className={styles['player-profile-content-button-text']}>Update</div>
                    </div>
                </div>
                <div className={styles['player-profile-content-container']}>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>First name</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.firstName}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>Mailing Address 1</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.address1}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>Last name</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.lastName}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>Mailing Address 2</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.address2}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>Birthday</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.dateOfBirth}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>City</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.city}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>SSN</div>
                    <div tabIndex={0} onKeyPress={(e) => {
                        if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                            if (canSeeSSN) {
                                setUnmaskSSN(!unmaskSSN);
                            }
                        }
                    }}
                         className={`${styles["player-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>
                        {canSeeSSN ?
                            (unmaskSSN ?
                                playerStore.identity.ssn : `***-**-${playerStore.identity?.ssn?.slice(playerStore.identity.ssn.length - 4)}`)
                            : playerStore.identity.ssn}&nbsp;&nbsp;&nbsp;&nbsp;
                        {canSeeSSN ?
                            (unmaskSSN ?
                                <FaEyeSlash style={{cursor: "pointer"}} onClick={() => {
                                    setUnmaskSSN(!unmaskSSN);
                                    logAction(UserActionCategory.BUTTON_CLICK, UserAction.TOGGLE_SSN_VISIBILITY, `Player Id: ${playerStore.account.id}, Player Name: ${playerStore.identity.firstName} ${playerStore.identity.lastName}`, `Masked SSN ***-**-${playerStore.identity?.ssn?.slice(playerStore.identity.ssn.length - 4)}`);
                                    //alert('Hiding');
                                }}/> :
                                <FaEye style={{cursor: "pointer"}} onClick={() => {
                                    setUnmaskSSN(!unmaskSSN);
                                    logAction(UserActionCategory.BUTTON_CLICK, UserAction.TOGGLE_SSN_VISIBILITY, `Player Id: ${playerStore.account.id}, Player Name: ${playerStore.identity.firstName} ${playerStore.identity.lastName}`, `Unmasked SSN ***-**-${playerStore.identity?.ssn?.slice(playerStore.identity.ssn.length - 4)}`);
                                    //alert('Showing');
                                }}/>) : null
                        }
                    </div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>State</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.state}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>Gender</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.gender}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>Zipcode</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.identity.zip5}</div>

                    <div tabIndex={0} className={`${styles["player-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>{`T&C's Date`}</div>
                    <div tabIndex={0} className={`${styles["player-profile-content-container-column2-end"]} ${styles["player-profile-content-labels-name"]}`}>{playerStore.account.creationDate !== "" && setDateFormat(playerStore.account.creationDate)}</div>

                </div>
            </div>
        </>

    );
}

