import React from 'react'
import ExclamationIcon from '../../../../icons/icon_status_flagged.svg'
import './styles.scss'

export const WarningImage = () => {
  return (
    <img
      className="warning-image"
      src={ExclamationIcon}
      height={20}
      width={20}
      alt="exclamation icon"
    />
  )
}
