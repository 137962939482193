import {makeHttpCall} from "../../../../services/MakeHttpCall";
import config, {taxformRoutePath} from "../../../../config";
import {RaffleFormSubmissionBody} from "@interaction-gaming/mslc-charitable-gaming-components";
import {NotificationType} from "../../../../components/HomeComponent/NotificationBannerComponent";
import moment from "moment";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";

let taxFormId: any;
let charitableGamingStore: any;
let history: any;
let setRequestCompleted: any;
let setShowErrorDialog: any;

export const setVariables = (taxFormIdObj: any, charitableGamingStoreObj: any, historyObj: any, setRequestCompletedObj: any, setShowErrorDialogObj: any) => {
    taxFormId = taxFormIdObj;
    charitableGamingStore = charitableGamingStoreObj;
    history = historyObj;
    setRequestCompleted = setRequestCompletedObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const getRaffleFormData = async (setRaffleFormData: any, setOrgData: any) => {

    const userpolicies: any = localStorage.getItem("userpolicies")
    if (!checkRenderPermissions(permissions.CAN_SEE_RAFFLE_TAX_FORM_TAB, JSON.parse(userpolicies))) {
        return;
    }

    setRequestCompleted(false);

    try {

        // Get the Raffle Bazaar Tax form Details
        const options1 = {
            method: 'GET', url: `${config.SERVER_BASE_URL}/api/v1/taxForm/raffleBazaar/${taxFormId}`,
        }
        const response1 = await makeHttpCall(options1);

        // Get the Org. details
        const options2 = {
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/api/v1/permitholder/${response1.taxForm.raffleId}`,
        }

        const response2 = await makeHttpCall(options2);

        setOrgData({
            organizationName: response2.permitHolder.orgName,
            idNumber: response2.permitHolder.raffleId,
            address: response2.permitHolder.address.streetAddress,
            city: response2.permitHolder.address.city,
            state: 'MA',
            zipCode: response2.permitHolder.address.zip
        });
        setRaffleFormData(response1.taxForm);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }
}


export const submitRaffleForm = async (body: RaffleFormSubmissionBody | any, taxFormId: any) => {

    setRequestCompleted(false);

    delete body.raffleId;
    body.occasionDate = moment(body.occasionDate).utc().format('YYYY-MM-DD') + 'T00:00:00.000Z';

    try {

        const options = {
            method: 'PUT',
            url: `${config.SERVER_BASE_URL}/api/v1/taxForm/raffleBazaar/${taxFormId}`,
            data: body
        }

        const response = await makeHttpCall(options);

        setRequestCompleted(true);

        const backButtonText = localStorage.getItem("RaffleTaxFormPageBackButtonText");

        if (backButtonText?.toLowerCase().includes('tax')) {
            localStorage.removeItem("RaffleTaxFormPageBackButtonText");
            history.push({
                pathname: `${taxformRoutePath}/manage`, state: {tabName: 'raffle'} // This is so the 'Raffle' Tab is selected when you go back
            });
        } else if (backButtonText?.toLowerCase().includes('charge')) {
            localStorage.removeItem("RaffleTaxFormPageBackButtonText");
            history.goBack();
        }

        charitableGamingStore.showNotificationBanner({
            show: true, type: NotificationType.Success, text: 'Raffle Bazaar Form saved successfully'
        });

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}
