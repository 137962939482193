import { ListSplit5754ReportsResponse } from '../../../api/split-5754-reporting-fetcher/api-types'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { permissions } from '../../../constants'

const getSplitReportsUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/5754Split/reports`

export const getFetchSplit5754Reports = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<ListSplit5754ReportsResponse>()
  const fetchSplit5754Reports = (year: string) => {
    fetchUrl(`${getSplitReportsUrl}?year=${year}`, 'GET', undefined, undefined, [permissions.CAN_SEE_5754_SPLIT_REPORTS])
  }
  return { isLoading, error, data, fetchSplit5754Reports }
}
