import moment from 'moment'
import { WeeklyCheckRunWinner } from '../../../../api/promotions-fetcher/constants'
import { formatCentsToDollars } from '../../../../util'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
  GenericTableLink,
} from '../../../CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'

export const getNextTuesday2AM = () => {
  const tuesday = 2
  const today = moment().tz('America/New_York')
  const tuesdayAtTwoAM = moment()
    .tz('America/New_York')
    .weekday(tuesday)
    .set('hour', 2)
    .set('minute', 0)

  if (today < tuesdayAtTwoAM) {
    return moment().tz('America/New_York').weekday(tuesday)
  } else {
    return moment().tz('America/New_York').add(1, 'weeks').weekday(tuesday)
  }
}

export type WeeklyCheckRunTableRow = {
  prize: string
  name: GenericTableLink
  address: string
  city: string
  state: string
  zip: string
  claimNumber: string
  offsets: string
  claimAcceptedDate: string
}

export const weeklyCheckRunColumnMap: GenericTableColumnMap<WeeklyCheckRunTableRow> =
  [
    {
      key: 'prize',
      columnText: 'Prize',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'name',
      columnText: 'First and Last Name',
      columnType: GenericTableColumnType.LINK,
    },
    {
      key: 'address',
      columnText: 'Address',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'city',
      columnText: 'City/Town',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'state',
      columnText: 'State',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'zip',
      columnText: 'Zip',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'claimNumber',
      columnText: 'Claim #',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'offsets',
      columnText: 'Offsets',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'claimAcceptedDate',
      columnText: 'Claim Accepted Date',
      columnType: GenericTableColumnType.DEFAULT,
    },
  ]

export const mapWeeklyCheckRunWinnerRow = (
  winner: WeeklyCheckRunWinner,
  navigateToPlayerProfile: () => void
): WeeklyCheckRunTableRow => ({
  prize: formatCentsToDollars(winner.prizeValueInCents, true),
  name: {
    value: `${winner.firstName ?? ''} ${winner.lastName ?? ''}`,
    onClick: winner.playerId ? navigateToPlayerProfile : () => {},
    ariaLabel: `Navigate to player profile for player ${winner.playerId}`,
    disabled: !Boolean(winner.playerId),
  },
  address: `${winner.address1 ?? ''} ${winner.address2 ?? ''}`,
  city: winner.city,
  state: winner.state,
  zip: winner.zip,
  claimNumber: winner.claimNumber.toString().padStart(5, '0'),
  offsets: winner.hasOffsets ? 'Yes' : 'No',
  claimAcceptedDate: moment(winner.claimAcceptedDate).format('MM/DD/YYYY'),
})
