import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { DisplayDocument } from './../../components/DisplayDocument';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { fa-fw, fa-eye } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { useFormik } from 'formik';
import { GenderCodes } from "../../Constants/GenderCodes";

import { checkRenderPermissions } from "./../../../src/helpers";
import { permissions } from "./../../../src/constants";


export const PlayerIDAndNameComponent = ({ playerId = undefined, playerName = undefined }: any) => {

   
   
   
    return (
        <>
            <div  className={styles['page-container']}>
                <div className={styles['players-data-container']} >
                    <div tabIndex={0} className={styles['players-data-name']}>{ playerName.trim() <= 0 ? "--" : playerName}</div>
                    <div tabIndex={0} className={styles['player-data-id-container']}><div className={styles['player-data-id-label']}>PlayerID</div><div className={styles['player-data-id']}>{playerId}</div></div>
                </div>
                
            </div>

        </>

    );
}