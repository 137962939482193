import * as React from 'react'
import { PromotionBuilderDrawing } from '../../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../../utilities/helpers'
import {
  CustomRadioButtonOptions,
  RadioButtonInput,
} from '../../../../../radio-button-input-component/radio-button-input-ui'
import { PromotionCampaignTooltip } from '../../../promotion-campaign-tooltip/promotion-campaign-tooltip-ui'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  getDrawFilePrefix,
  promotionIsNewOrPending,
} from '../helpers'
import { PromotionCampaignValidationPrizeTier } from '../validation'
import { PromotionDrawingsFormUI } from './promotion-drawings-form-ui'
import { PromotionPrizeTiersFormUI } from './promotion-prize-tiers-form-ui'
import '../styles.scss'
import { SideBarTextInput } from '../../../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'

type PromotionBonusDrawingsFormUIProps =
  PromotionCampaignBuilderSubFormProps & {
    numberOfDrawingsField: number
    drawingsField: PromotionBuilderDrawing[]
    prizeTiersField: PromotionCampaignValidationPrizeTier[]
  }

export const PromotionBonusDrawingsFormUI = (
  props: PromotionBonusDrawingsFormUIProps
) => {
  const [bonusDrawingActive, setBonusDrawingActive] = React.useState<string>(
    props.formik.values.hasBonusDraws ? 'yes' : 'no'
  )

  const updateBonusDrawings = async () => {
    await props.formik.setFieldValue(
      'hasBonusDraws',
      bonusDrawingActive === 'yes'
    )
  }

  React.useEffect(() => {
    updateBonusDrawings()
  }, [bonusDrawingActive])

  const {
    numberOfDrawingsField,
    drawingsField,
    prizeTiersField,
    ...subFormProps
  } = props

  const bonusDrawingOptions: CustomRadioButtonOptions[] = [
    {
      id: 'yes',
      value: 'yes',
      display: 'Yes',
      defaultChecked: props.formik.values.hasBonusDraws,
    },
    {
      id: 'no',
      value: 'no',
      display: 'No',
      defaultChecked: !props.formik.values.hasBonusDraws,
    },
  ]

  return (
    <>
      <hr
        className={getBlockClass(PROMOTION_SIDEBAR_FORM_CLASS, 'separator')}
      />
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'bonus-drawings'
        )}
      >
        <RadioButtonInput
          label={
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                'label'
              )}
            >
              Bonus Drawing
              <PromotionCampaignTooltip
                tooltipText="The bonus drawing can be initiated manually throughout the promotion."
                altText="Bonus Drawings Tooltip"
                widthPx={215}
              />
            </div>
          }
          name={'bonusDrawingActive'}
          options={bonusDrawingOptions}
          onChange={(e) => setBonusDrawingActive(e.target.value)}
          onBlur={() => undefined}
          value={bonusDrawingActive}
          touched={undefined}
          error={undefined}
          disabled={
            props.isLoading ||
            !props.canEditPromotionCampaigns ||
            !promotionIsNewOrPending(props.promotionStatus)
          }
          greyOnDisabled
        />
        {bonusDrawingActive === 'yes' && (
          <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
            <SideBarTextInput
              label={
                <div
                  className={getBlockClass(
                    PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                    'label'
                  )}
                >
                  Promotion File Prefix
                  <PromotionCampaignTooltip
                    tooltipText="To accurately fill this section, please use all caps, do not add spaces and/or underscores."
                    altText="Promotion File Prefix Tooltip"
                    widthPx={185}
                  />
                </div>
              }
              name={`bonusDrawFilePrefix`}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.bonusDrawFilePrefix}
              error={props.formik.errors.bonusDrawFilePrefix}
              touched={props.formik.touched.bonusDrawFilePrefix}
              disabled={
                props.isLoading ||
                !props.canEditPromotionCampaigns ||
                !promotionIsNewOrPending(props.promotionStatus)
              }
              placeholderText={getDrawFilePrefix(
                props.formik.values.promotionType
              )}
              greyOnDisabled
            />
          </div>
        )}
      </div>
      {bonusDrawingActive === 'yes' && (
        <>
          <PromotionDrawingsFormUI
            {...subFormProps}
            isBonus
            numberOfDrawingsField={numberOfDrawingsField}
            drawingsField={drawingsField}
          />
          <PromotionPrizeTiersFormUI
            {...props}
            isBonus
            prizeTiersField={prizeTiersField}
          />
        </>
      )}
    </>
  )
}
