export interface ChargeDetailsInterface {
    chargeId: string,
    expenseType: string,
    associatedEntityId: string,
    date: string,
    status: string,
    baseAmountInCents: number,
    creditAmountInCents: number,
    totalAmountInCents: number,
    paymentMethod: string,
    checkReceived: boolean,
    orgDetails: {
        chargeType: string,
        identifier: string,
        orgName: string,
        streetAddress: string,
        city: string,
        zip: string
    }
}

export const ChargeDetailsDefaultData = {
    chargeId: '',
    expenseType: '',
    associatedEntityId: '',
    date: '',
    status: '',
    baseAmountInCents: 0,
    creditAmountInCents: 0,
    totalAmountInCents: 0,
    paymentMethod: '',
    checkReceived: false,
    orgDetails: {
        chargeType: '',
        identifier: '',
        orgName: '',
        streetAddress: '',
        city: '',
        zip: ''
    }
}
