import React, { useState } from 'react'
import './styles.scss'
import { PayeeType } from '../../../../api/payment-details-fetcher/api-types'
import { ModalOverlayContainer } from '../../../CommonComponents/modal-container/modal-container-component'

const ROOT_CLASS = 'payment-details-payee-type-modal'

type PayeeTypeModalUIProps = {
  internalPaymentId: string
  setShowPayeeTypeModal: (show: boolean) => void
  addSplit: (payeeType: string, internalPaymentId: string) => void
}

export const PayeeTypeModalUI = (props: PayeeTypeModalUIProps) => {
  const [payeeType, setPayeeType] = useState<string>('')
  const addSplitHandler = () => {
    props.addSplit(
      payeeType,
      props.internalPaymentId
    )
  }

  return (
    <ModalOverlayContainer>
      <div className={ROOT_CLASS}>
        <header>Select Payee Type</header>
        <section className="controls">
          <div className="control-wrapper">
            <input
              type="radio"
              name="Individual"
              id="Individual-Id"
              onChange={(e) => setPayeeType(e.target.value as PayeeType)}
              value={PayeeType.INDIVIDUAL}
              checked={payeeType === PayeeType.INDIVIDUAL}
            />
            <label htmlFor="Individual-Id">Individual</label>
          </div>
          <div className="control-wrapper">
            <input
              type="radio"
              name="Entity"
              id="Entity-Id"
              onChange={(e) => setPayeeType(e.target.value as PayeeType)}
              value={PayeeType.ENTITY}
              checked={payeeType === PayeeType.ENTITY}
            />
            <label htmlFor="Entity-Id">Entity</label>
          </div>
        </section>
        <button className="primary-button" disabled={ !payeeType } onClick={ addSplitHandler }>
          Select
        </button>
        <a role="button" onClick={() => props.setShowPayeeTypeModal(false)}>
          Cancel
        </a>
      </div>
    </ModalOverlayContainer>
  )
}
