import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import {
    getPageData,
    setDateFormat,
} from "./functions";
import { useHistory } from "react-router-dom";
import { PlayerMobileClaimsActivityInterface } from "./interfaces";
import { keyPressedType } from "../../../services/Commons";
import { playerSupportRoutePath, claimsSupportRoutePath } from './../../../config.js';
import { checkRenderPermissions } from './../../../helpers.js';
import { permissions } from './../../../constants.js';
import { CircularProgress } from "@material-ui/core";
import { claimHistory, claimReferenceNumber, claimTransaction, w2g } from "../../../services/StoresObject/Claims";

import moment from "moment-timezone";

export const PlayerMobileClaimsActivityTableComponent = ({ setPlayerMobileClaimsActivityData, isDataPerformedMobile, pageMessage, PlayerMobileClaimsActivityData, id, storesObject }: any) => {

    let i: number = 0;
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const userpolicies: any = localStorage.getItem('userpolicies');
    const increaseCount = () => {
        i++;
    }

   
    return (
        <>

            <div className={styles['KYCQUEUE-table']}>
                
                {(PlayerMobileClaimsActivityData.length <= 0 && !isDataPerformedMobile.value && pageMessage.message === "") && <div style={{ position: 'absolute', zIndex: 50, top: 0, left: 0, width: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
                    <CircularProgress size="8em" color='inherit' thickness={2} />
                </div>}
                <div className={styles['KYCQUEUE-table-data-header']}>
                    <div className={styles['KYCQUEUE-table-data-header-inner-container']}>
                        <div tabIndex={0} className={styles['KYCQUEUE-table-data-header-title']} >Mobile Claims Activity</div>
                        <div tabIndex={0} className={styles['KYCQUEUE-table-data-header-text']}>5 most recent</div>
                    </div>
                    {checkRenderPermissions(permissions.CAN_SEE_CLAIM_ACTIVITY, JSON.parse(userpolicies)) &&
                        <div tabIndex={0}
                            onKeyPress={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                    history.push(`${playerSupportRoutePath}/mobile-claims-activity/${id}`);
                                }
                            }
                            }
                            onClick={() => history.push(`${playerSupportRoutePath}/mobile-claims-activity/${id}`)} role="button" className={styles['table-profile-content-button']}>
                            <div className={styles['table-profile-content-button-text']}>View All</div>
                        </div>
                    }
                </div>

                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date</div>
                    <div tabIndex={0}>Claim ID</div>
                    <div tabIndex={0}>Mobile Claim Status</div>
                </div>
                <div className={styles['KYCQUEUE-table-data-body']} >
                  
                    { PlayerMobileClaimsActivityData.length > 0 && getPageData(storesObject,isDataPerformedMobile, setPlayerMobileClaimsActivityData,PlayerMobileClaimsActivityData, currentPage).map((e: PlayerMobileClaimsActivityInterface, i: number) =>
                        <>
                                   
                            {
                                <div key={i} style={{ backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)'), position: "relative" }} className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                                    {e.claim.status === 'READY_FOR_PAYMENT' && <div style={{ position: 'absolute', zIndex: 50, top: 0, left: 0, width: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
                                        <CircularProgress size="1em" color='inherit' thickness={2} />
                                    </div>}
                                    <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(e.claim.activeFrom, "America/New_York").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;{moment.tz(e.claim.activeFrom, "America/New_York").format('h:mm:ss a')}</div>
                                    <div className={styles['KYCQUEUE-table-data-data-text']}>
                                        <u
                                            style={{
                                                opacity: (checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies))) ? "1" : "0.4",
                                                cursor: (checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies))) ? "pointer" : "not-allowed"
                                            }
                                            }
                                            onKeyPress={(en: any) => {

                                                if (keyPressedType(en) === 13 || keyPressedType(en) === 0 || keyPressedType(en) === 32) {
                                                    if (checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies))) {
                                                        localStorage.setItem('callingPage', history.location.pathname);
                                                        storesObject.claimStore.claimTransaction = JSON.parse(JSON.stringify(claimTransaction));
                                                        storesObject.claimStore.claimReferenceNumber = JSON.parse(JSON.stringify(claimReferenceNumber));
                                                        storesObject.claimStore.claimHistory = JSON.parse(JSON.stringify(claimHistory));
                                                        storesObject.claimStore.claimW2G = JSON.parse(JSON.stringify(w2g));
                                                        history.push(`${claimsSupportRoutePath}/mobile-claims-details/${window.location.href.split('/')[window.location.href.split('/').length - 1]}/${e.claim.referenceNumber}`)

                                                    }
                                                }
                                            }
                                            }


                                            onClick={() => {

                                                if (checkRenderPermissions(permissions.CAN_SEE_CLAIM_DETAILS, JSON.parse(userpolicies))) {
                                                    localStorage.setItem('callingPage', history.location.pathname);
                                                    storesObject.claimStore.claimTransaction = JSON.parse(JSON.stringify(claimTransaction));
                                                    storesObject.claimStore.claimReferenceNumber = JSON.parse(JSON.stringify(claimReferenceNumber));
                                                    storesObject.claimStore.claimHistory = JSON.parse(JSON.stringify(claimHistory));
                                                    storesObject.claimStore.claimW2G = JSON.parse(JSON.stringify(w2g));
                                                    history.push(`${claimsSupportRoutePath}/mobile-claims-details/${window.location.href.split('/')[window.location.href.split('/').length - 1]}/${e.claim.referenceNumber}`)

                                                }
                                            }
                                            }

                                            tabIndex={0}


                                        >{e.claim.referenceNumber}</u>
                                    </div>
                                    <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.claim.status}</div>
                                </div>
                            }

                        </>
                    )}
                </div>
            </div>


        </>

    );
}


