import * as React from 'react'
import { GetFutureReissuePaymentDetailsResponse } from '../../../../api/future-reissue-payment-details-fetcher/api-types'
import { useHistory } from 'react-router-dom'
import { paymentsRoutePath } from '../../../../config.js'
import './styles.scss'

const ROOT_CLASS = 'future-reissue-payment-details-title-bar'

export type FutureReissuePaymentDetailsTitleBarProps = {
  futureReissuePaymentDetailsData: GetFutureReissuePaymentDetailsResponse
}

export const FutureReissuePaymentDetailsTitleBar = (
  props: FutureReissuePaymentDetailsTitleBarProps
) => {
  const history: any = useHistory()

  const renderOriginalPaymentButton = (): React.ReactNode => {
    return (
      <button
        onClick={() => {
          const { voidedPaymentId } = props.futureReissuePaymentDetailsData
          history.push(`${paymentsRoutePath}/details/${voidedPaymentId}`)
        }}>
          <div className="button-label">Original Payment</div>
      </button>
    )
  }

  return (
    <div className={ROOT_CLASS}>
      <div className="title">Re-Issue Payment Details</div>
      <div className="buttons-container">
        {renderOriginalPaymentButton()}
      </div>
    </div>
  )
}