import * as React from 'react'
import sortBy from 'lodash/sortBy'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { getBlockClass } from '../../../utilities/helpers'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { GenericTableUI } from '../../../components/CommonComponents/generic-table-components/generic-table-ui'
import {
  PromotionMessageStatusOrder,
  PromotionMessageTableRow,
  mapPromotionMessageDataToRow,
  promotionalMessageTableColumnMap,
} from './helpers'
import { PromotionalMessagesSidebarContainer } from '../../../components/PromotionsComponents/promotional-messages-sidebar/promotional-messages-sidebar-container'
import { GenericSidebarFormMode } from '../../../components/GenericSidebar/generic-sidebar-ui'
import { PromotionalMessagesPageContext } from './promotional-messages-context'
import { PromotionalMessagesDeleteMessageContainer } from '../../../components/PromotionsComponents/promotional-messages-modals/delete-message/promotional-messages-delete-message-container'
import {
  PlayslipsAllPromotionMessagesResponse,
  PlayslipsPromotionMessage,
} from '../../../api/playslips-fetcher/constants'
import './styles.scss'

const ROOT_CLASS = 'promotional-messages-page'
const HEADER_CLASS = `${ROOT_CLASS}-header`

export type PromotionalMessagesPageUIProps = {
  isLoading: boolean
  error?: string
  data?: PlayslipsAllPromotionMessagesResponse
  actionsDisabled: boolean
  fetchAllPromotionMessages: () => Promise<void>
}

export const PromotionalMessagesPageUI = (
  props: PromotionalMessagesPageUIProps
) => {
  const [sidebarFormMode, setSidebarFormMode] =
    React.useState<GenericSidebarFormMode>(GenericSidebarFormMode.CLOSED)
  const [openEditMessage, setOpenEditMessage] =
    React.useState<PlayslipsPromotionMessage>()
  const [openDeleteMessageId, setOpenDeleteMessageId] = React.useState<number>()

  const setFormMode = (
    formMode: GenericSidebarFormMode,
    message?: PlayslipsPromotionMessage
  ) => {
    setSidebarFormMode(formMode)
    setOpenEditMessage(message)
  }

  const mappedData = sortBy(
    props.data?.map((promotion) =>
      mapPromotionMessageDataToRow(
        promotion,
        props.actionsDisabled,
        setFormMode,
        () => setOpenDeleteMessageId(promotion.id)
      )
    ) ?? [],
    (item: PromotionMessageTableRow) => PromotionMessageStatusOrder[item.status]
  )

  return (
    <>
      <PromotionalMessagesPageContext.Provider
        value={{
          message: openEditMessage,
          setFormMode: setFormMode,
          fetchAllPromotionMessages: props.fetchAllPromotionMessages,
        }}
      >
        {sidebarFormMode != GenericSidebarFormMode.CLOSED && (
          <PromotionalMessagesSidebarContainer
            formMode={sidebarFormMode}
            closeSidebar={() => setFormMode(GenericSidebarFormMode.CLOSED)}
          />
        )}
      </PromotionalMessagesPageContext.Provider>
      {openDeleteMessageId && (
        <PromotionalMessagesDeleteMessageContainer
          closeModal={() => setOpenDeleteMessageId(undefined)}
          messageId={openDeleteMessageId}
          fetchAllPromotionMessages={props.fetchAllPromotionMessages}
        />
      )}
      <section className={ROOT_CLASS}>
        <BackButtonUI
          message="Back to Promotions"
          tabIndex={1}
          removeLeftMargin
        />
        <header className={HEADER_CLASS}>
          <div className={getBlockClass(HEADER_CLASS, 'title')}>
            Playslip Promotion Messaging
          </div>
          <button
            className={getBlockClass(HEADER_CLASS, 'create-button')}
            onClick={() => setFormMode(GenericSidebarFormMode.NEW)}
            aria-label="Create Promotion Message"
            disabled={props.actionsDisabled}
          >
            <div>Create</div>
          </button>
        </header>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <AsyncContainerUI
            isLoading={props.isLoading}
            error={props.error}
            errorTextSize={15}
            errorTextColor="white"
            color="white"
          >
            <GenericTableUI
              columnMap={promotionalMessageTableColumnMap}
              rowData={mappedData}
              useColumnWidths
              useActionMenu
              usePages
              rowsPerPage={6}
              customRowCellStyling={{ padding: '24px 20px 24px 16px' }}
              customHeaderCellStyling={{ paddingLeft: '16px' }}
            />
          </AsyncContainerUI>
        </div>
      </section>
    </>
  )
}
