import React from 'react'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { downloadFile } from '../../../utilities/helpers'
import {
  AllCouponCampaignPermissions,
  CouponCampaignPermissions,
} from '../permissions'
import {
  CampaignData,
  CampaignDetails,
  CampaignFile,
  CampaignFileStatus,
  CreateCampaignDetails,
} from './constants'

export enum ArtworkTemplateExtension {
  AI = 'ai',
  PSD = 'psd',
}

const ArtworkTemplateFile = {
  [ArtworkTemplateExtension.AI]: 'Illustrator Artwork Requirements.ai',
  [ArtworkTemplateExtension.PSD]: 'Photoshop Artwork Requirements.psd',
}

export type DefaultResponse = {
  data: string
}

export type CampaignDataResponse = {
  data: {
    campaigns: CampaignData[]
  }
}

export type DefaultLegalCopyResponse = {
  data: {
    defaultLegalCopy: string
  }
}

export type CampaignDetailsResponse = {
  data: CampaignDetails
}

export type PutCampaignArtworkResponse = {
  data: {
    imageUrl: string
  }
}

export type CampaignFileStatusResponse = {
  data: {
    filesByCampaign: CampaignFileStatus[]
  }
}

export type CampaignFileStatusMap = Map<string, CampaignFile[]>

const couponsBaseUrl = `${config.SERVER_BASE_URL}/coupons/api/v1`
const ALL_COUPON_CAMPAIGNS_URL = `${couponsBaseUrl}/campaign/all`
const ARTWORK_REQUIREMENTS_URL = `${couponsBaseUrl}/campaign/artwork-templates`
const DEFAULT_LEGAL_COPY_URL = `${couponsBaseUrl}/campaign/default-legal-copy`
const CAMPAIGN_DETAILS_URL = `${couponsBaseUrl}/campaign/details`
const CREATE_CAMPAIGN_URL = `${couponsBaseUrl}/campaign/create`
const PUT_CAMPAIGN_IMAGE_URL = `${couponsBaseUrl}/campaign/image`
const PLAYER_ASSIGNMENT_URL = `${couponsBaseUrl}/campaign/player-assignment`
const CAMPAIGN_FILE_STATUS_URL = `${couponsBaseUrl}/campaign/file-status`
const PUT_EXPIRE_CAMPAIGN_URL = `${couponsBaseUrl}/campaign/expire`

export const useFetchAllCampaigns = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<CampaignDataResponse>()

  const fetchAllCampaigns = async () =>
    await fetchUrl(
      ALL_COUPON_CAMPAIGNS_URL,
      'GET',
      {},
      undefined,
      AllCouponCampaignPermissions
    )

  React.useEffect(() => {
    fetchAllCampaigns()
    return () => {}
  }, [])

  return {
    fetchAllCampaigns,
    isLoading,
    error,
    data,
  }
}

export const useFetchDefaultLegalCopy = () => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<DefaultLegalCopyResponse>()
  React.useEffect(() => {
    fetchUrl(
      DEFAULT_LEGAL_COPY_URL,
      'GET',
      {},
      undefined,
      AllCouponCampaignPermissions
    )
    return () => {}
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}

export const useFetchCampaignDetails = (campaignIdentifier?: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<CampaignDetailsResponse>()
  React.useEffect(() => {
    if (campaignIdentifier) {
      fetchUrl(
        `${CAMPAIGN_DETAILS_URL}?campaignIdentifier=${campaignIdentifier}`,
        'GET',
        {},
        undefined,
        AllCouponCampaignPermissions
      )
    }
    return () => {}
  }, [campaignIdentifier])

  return {
    isLoading,
    error,
    data,
  }
}

export const useFetchArtworkRequirements = () => {
  const [fileType, setFileType] = React.useState<ArtworkTemplateExtension>()
  const { data, fetchBlob } = useFetch<Blob>()

  React.useEffect(() => {
    if (data && fileType) {
      downloadFile(ArtworkTemplateFile[fileType], data)
    }
  }, [data, fileType])

  const fetchArtworkRequirements = async (
    fileType: ArtworkTemplateExtension
  ) => {
    setFileType(fileType)
    await fetchBlob(
      `${ARTWORK_REQUIREMENTS_URL}?type=${fileType}`,
      {},
      undefined,
      false
    )
  }

  return {
    fetchArtworkRequirements,
  }
}

export const useFetchCreateCampaign = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } =
    useFetch<DefaultResponse>()
  const [submittedCampaignIdentifier, setSubmittedCampaignIdentifier] =
    React.useState<string>()
  const submitCreateCampaignPayload = async (
    campaignPayload: CreateCampaignDetails
  ) => {
    setSubmittedCampaignIdentifier(undefined)
    await fetchUrl(
      CREATE_CAMPAIGN_URL,
      'POST',
      {},
      JSON.stringify(campaignPayload),
      [CouponCampaignPermissions.CAN_WRITE_COUPON_CAMPAIGNS]
    )
    setSubmittedCampaignIdentifier(campaignPayload.campaignIdentifier)
  }

  return {
    isLoading,
    error,
    requestSuccessful,
    submittedCampaignIdentifier: requestSuccessful
      ? submittedCampaignIdentifier
      : undefined,
    submitCreateCampaignPayload,
  }
}

export const useFetchUploadCampaignArtwork = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } =
    useFetch<PutCampaignArtworkResponse>()
  const [fileError, setFileError] = React.useState<string | undefined>(
    undefined
  )

  const submitCampaignArtwork = async (
    campaignIdentifier: string,
    imageFile?: File
  ) => {
    if (!imageFile) {
      setFileError('No image file present')
      return
    }
    const formData = new FormData()
    formData.append('campaignIdentifier', campaignIdentifier)
    formData.append('campaignImage', imageFile)
    await fetchUrl(PUT_CAMPAIGN_IMAGE_URL, 'PUT', {}, formData, [
      CouponCampaignPermissions.CAN_WRITE_COUPON_CAMPAIGNS,
    ])
  }

  return {
    isLoading,
    error: fileError ?? error,
    requestSuccessful,
    submitCampaignArtwork,
  }
}

export const useSubmitPlayerCouponFiles = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } =
    useFetch<PutCampaignArtworkResponse>()
  const [fileError, setFileError] = React.useState<string | undefined>(
    undefined
  )

  const submitPlayerFiles = async (
    campaignIdentifier: string,
    playerFiles: File[]
  ) => {
    if (!playerFiles || playerFiles.length === 0) {
      setFileError('No player file present')
      return
    }
    const formData = new FormData()
    formData.append('campaignIdentifier', campaignIdentifier)
    for (const file of playerFiles) {
      formData.append('playerAssignmentFiles', file)
    }

    await fetchUrl(PLAYER_ASSIGNMENT_URL, 'POST', {}, formData, [
      CouponCampaignPermissions.CAN_ASSIGN_PLAYER_COUPONS,
    ])
  }

  return {
    isLoading,
    error: fileError ?? error,
    requestSuccessful,
    submitPlayerFiles,
  }
}

export const useFetchCampaignPlayerFileStatuses = () => {
  const { isLoading, error, data, requestSuccessful, fetchUrl } =
    useFetch<CampaignFileStatusResponse>()

  const [fileStatusMap, setFileStatusMap] = React.useState<
    CampaignFileStatusMap | undefined
  >(undefined)

  const fetchPlayerFileStatuses = async () => {
    await fetchUrl(
      `${CAMPAIGN_FILE_STATUS_URL}?type=player`,
      'GET',
      {},
      undefined,
      AllCouponCampaignPermissions
    )
  }

  React.useEffect(() => {
    fetchPlayerFileStatuses()
    return () => {}
  }, [])

  React.useEffect(() => {
    const dataMap = data
      ? new Map(
          data?.data.filesByCampaign.map((campaign) => [
            campaign.campaignIdentifier,
            campaign.files,
          ])
        )
      : undefined
    setFileStatusMap(dataMap)
  }, [data])

  return {
    isLoading,
    error,
    requestSuccessful,
    data: fileStatusMap,
    fetchPlayerFileStatuses,
  }
}

export const useFetchEndCampaign = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } =
    useFetch<DefaultResponse>()
  const submitEndCampaign = async (campaignIdentifier: string) => {
    await fetchUrl(
      PUT_EXPIRE_CAMPAIGN_URL,
      'PUT',
      {},
      JSON.stringify({ campaignIdentifier: campaignIdentifier }),
      [CouponCampaignPermissions.CAN_END_COUPON_CAMPAIGNS]
    )
  }

  return {
    isLoading,
    error,
    hasEndedCampaign: requestSuccessful,
    submitEndCampaign,
  }
}
