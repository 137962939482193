import React from 'react'
import styles from './css/styles.module.css'
import { keyPressedType } from '../../../services/Commons'

const Ellipsis_Object = (key: string) => (
  <div className={styles['pagination-table-ellipsis']} key={key}>
    {'...'}
  </div>
)

export const PaginationCommonComponent = ({ playerData, currentPage, setCurrentPage, rowsPerPage = 10 }: any) => {
  const createPages = (data: any[], currentPage: any, setCurrentPage: any, rowsPerPage: number) => {
    const array: any = []
    const pagesTotal = Math.ceil(data.length / rowsPerPage)
    if (currentPage > 2 && pagesTotal > 10) {
      array.push(
        <div
          key="first"
          tabIndex={0}
          role="button"
          aria-pressed={currentPage === 0 ? 'true' : 'false'}
          style={{ backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : '' }}
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
              setCurrentPage(0)
            }
          }}
          onClick={() => setCurrentPage(0)}
          className={styles['pagination-table-page-box']}
        >
          <div className={styles['pagintation-table-page-box-text']}>{1}</div>
        </div>
      )
      array.push(Ellipsis_Object('ellipsis1'))
    }
    if (currentPage <= 2 || pagesTotal <= 5) {
      for (let i = 0; i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1; i++) {
        array.push(
          <div
            key={`${i}a`}
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === i ? 'true' : 'false'}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(i)
              }
            }}
            onClick={() => setCurrentPage(i)}
            style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : '' }}
            className={styles['pagination-table-page-box']}
          >
            <div className={styles['pagination-table-page-box-text']}>{i + 1}</div>
          </div>
        )
      }
    } else if (currentPage > 2 && currentPage <= pagesTotal - 1 - 2) {
      for (let i = currentPage - 2; i < currentPage + 3 && currentPage <= pagesTotal - 1; i++) {
        array.push(
          <div
            key={`${i}b`}
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === i ? 'true' : 'false'}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(i)
              }
            }}
            onClick={() => setCurrentPage(i)}
            style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : '' }}
            className={styles['pagination-table-page-box']}
          >
            <div className={styles['pagination-table-page-box-text']}>{i + 1}</div>
          </div>
        )
      }
    } else if (currentPage > pagesTotal - 1 - 2) {
      for (let i = pagesTotal - 1 - 4; i < pagesTotal; i++) {
        array.push(
          <div
            key={`${i}c`}
            tabIndex={0}
            role="button"
            aria-pressed={currentPage === i ? 'true' : 'false'}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                setCurrentPage(i)
              }
            }}
            onClick={() => setCurrentPage(i)}
            style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : '' }}
            className={styles['pagination-table-page-box']}
          >
            <div className={styles['pagination-table-page-box-text']}>{i + 1}</div>
          </div>
        )
      }
    }
    if (pagesTotal > 5 && currentPage < pagesTotal - 1 - 2) {
      array.push(Ellipsis_Object('ellipsis2'))
      array.push(
        <div
          key={`e`}
          tabIndex={0}
          role="button"
          aria-pressed={currentPage === pagesTotal ? 'true' : 'false'}
          style={{ backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : '' }}
          onKeyPress={(e: any) => {
            if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
              setCurrentPage(pagesTotal - 1)
            }
          }}
          onClick={() => setCurrentPage(pagesTotal - 1)}
          className={styles['pagination-table-page-box']}
        >
          <div className={styles['pagination-table-page-box-text']}>{pagesTotal}</div>
        </div>
      )
    }
    return array
  }

  return <>{playerData.length > 0 && createPages(playerData, currentPage, setCurrentPage, rowsPerPage)}</>
}
