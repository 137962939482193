import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {pageMessageDefault} from "../../../../components/CommonComponents/PageMessageCommonComponent";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {
    getLicensees,
    handleCheckboxChange,
    onSubmit,
    RenewLicenses,
    setFieldValue,
    setVariables,
    validate
} from "./functions";
import {
    LicenseeSearchResultComponent
} from "../../../../components/CharitableGamingComponents/License/LicenseeSearchResultComponent";
import {licenseeRoutePath} from "../../../../config.js";
import {
    LicenseeSearchBarComponent
} from "../../../../components/CharitableGamingComponents/License/LicenseeSearchBarComponent";
import {FaPlus} from 'react-icons/fa';
import {CircularProgress} from "@material-ui/core";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import {
    RenewLicensesInfoMessageComponent
} from "../../../../components/CharitableGamingComponents/Common/RenewLicensesInfoMessageComponent";
import {
    RenewLicensesConfirmationComponent
} from "../../../../components/CharitableGamingComponents/Common/RenewLicensesConfirmationComponent";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import moment from "moment";
import InfoIcon from "./../../../../icons/info-white.svg";
import RenewalIcon from "./../../../../icons/renewal-white.svg";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../../components/HomeComponent/NotificationBannerComponent";

export const LicenseProfileManagementPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const formik = useFormik({
            initialValues: {
                licenseNumber: '',
                orgName: '',
                city: '',
                includeActiveLicensees: true,
                includeInactiveLicensees: false,
                renewing: true,
                notRenewing: true
            },
            validate,
            onSubmit
        });

        const [unfilteredLicensees, setUnfilteredLicensees] = useState<any>([]);
        const [filteredLicensees, setFilteredLicensees] = useState<any>([]);

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(0);
        const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault);

        const [showRenewLicensesInfoMessage, setShowRenewLicensesInfoMessage] = useState<boolean>(false);
        const [showRenewLicensesConfirmation, setShowRenewLicensesConfirmation] = useState<boolean>(false);
        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
        const [notificationBanner, showNotificationBanner] = useState<any>({show: false, text: ''});
        const [newLicenseCreated, setNewLicenseCreated] = useState<boolean>(false);

        // The [Renew Licenses] is active only in the month of October
        const OCTOBER = 9; // 0 = January, 11 = December
        const ActivateRenewLicensesButton = (moment().month() === OCTOBER);

        useEffect(() => {
            if (unfilteredLicensees.length === 0 && requestCompleted === false) {
                getLicensees(setRequestCompleted);
                charitableGamingStore.reset();
                if (location.state !== undefined && !newLicenseCreated) {
                    setNewLicenseCreated(true)
                    showNotificationBanner({show: true, text: location.state.detail});
                }
            }
        });

        setVariables(charitableGamingStore, unfilteredLicensees, setUnfilteredLicensees, filteredLicensees,
            setFilteredLicensees, setCurrentPage, setPageMessage, setShowErrorDialog, showNotificationBanner);


        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['license-profile-mgmt-page-container']} ${styles['']}`}
                     onClick={() => {
                         // if (pageMessage.messageType !== "") setPageMessage({message: "", messageType: ""});
                         if (notificationBanner.text !== "") {
                             showNotificationBanner({show: false, text: ""});
                         }
                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['license-profile-mgmt-page-back-button-container']} ${styles['']}`}>
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${licenseeRoutePath}/`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${licenseeRoutePath}/`);
                            }}
                        />
                        <div
                            className={`${styles['license-profile-mgmt-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${licenseeRoutePath}/`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${licenseeRoutePath}/`);
                            }}
                        >Back
                            to Charitable Gaming
                        </div>
                    </div>
                    <div className={`${styles['license-profile-mgmt-page-ribbon-container']} ${styles['']}`}>
                        <div tabIndex={0}
                             className={`${styles['license-profile-mgmt-page-ribbon-title']} ${styles['']}`}>License Profile
                            Management
                        </div>
                        <div className={`${styles['license-profile-mgmt-page-ribbon-buttons-container']}`}>
                            {checkRenderPermissions(permissions.CAN_CREATE_LICENSE_PROFILES, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['license-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             history.push(`${licenseeRoutePath}/create`);
                                         }
                                     }}
                                     onClick={() => {
                                         history.push(`${licenseeRoutePath}/create`);
                                     }}>
                                    <FaPlus style={{cursor: "pointer"}}/>
                                    <div
                                        className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>Create
                                        License
                                    </div>
                                </div> : null}
                            {checkRenderPermissions(permissions.CAN_RENEW_LICENSES, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['license-profile-mgmt-page-ribbon-renew-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             if (ActivateRenewLicensesButton) {
                                                 setShowRenewLicensesConfirmation(true);
                                             }
                                         }
                                     }}
                                     onClick={() => {
                                         if (ActivateRenewLicensesButton) {
                                             setShowRenewLicensesConfirmation(true);
                                         }
                                     }}
                                     onMouseEnter={() => {
                                         if (!ActivateRenewLicensesButton) {
                                             setShowRenewLicensesInfoMessage(true);
                                         }
                                     }}
                                     onMouseLeave={() => {
                                         if (!ActivateRenewLicensesButton) {
                                             setShowRenewLicensesInfoMessage(false);
                                         }
                                     }}
                                >
                                    {ActivateRenewLicensesButton ?
                                        <img src={RenewalIcon} width="16" height="16" alt="Renew Licenses Icon"/> :
                                        <img src={InfoIcon} width="16" height="16" alt="Info Icon"/>
                                    }
                                    <div
                                        className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']}`}
                                        style={{
                                            opacity: ActivateRenewLicensesButton ? "1" : "0.4",
                                            cursor: ActivateRenewLicensesButton ? "pointer" : "not-allowed"
                                        }}>
                                        Renew Licenses
                                    </div>
                                </div> : null}
                            {showRenewLicensesInfoMessage ?
                                <RenewLicensesInfoMessageComponent/> : null
                            }

                        </div>

                    </div>

                    <div
                        className={`${styles['license-profile-mgmt-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                        <LicenseeSearchBarComponent formik={formik} setFieldValue={setFieldValue}
                                                    handleCheckboxChange={handleCheckboxChange}/>
                        {/*                        {pageMessage.message != "" ?
                            <PageMessageCommonComponent message={pageMessage.message} messageType={pageMessage.messageType}/>
                            : null
                        }*/}
                    </div>
                    {filteredLicensees.length <= 0 ?
                        <div className={`${styles['license-profile-mgmt-page-search-no-results-container']}`}>
                            <div className={`${styles['license-profile-mgmt-page-search-no-results-container-text']}`}>
                                No Results. Please try a different search.
                            </div>
                        </div>
                        :
                        <LicenseeSearchResultComponent
                            LicenseeList={filteredLicensees}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
                {showRenewLicensesConfirmation ?
                    <RenewLicensesConfirmationComponent
                        RenewLicenses={RenewLicenses}
                        setShowRenewLicensesConfirmation={setShowRenewLicensesConfirmation}/>
                    : null}
                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        type={NotificationType.Success}
                        text={notificationBanner.text}
                        handleHide={showNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);