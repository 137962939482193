import * as React from 'react'
import { AsyncContainerUI } from '../../../../../components/CommonComponents/async-container-component/async-container-ui'
import { GenericTableUI } from '../../../../../components/CommonComponents/generic-table-components/generic-table-ui'
import {
  ScanToEnterProgressTableRow,
  filterScanToEnterProgressData,
  mapScanToEnterProgressRow,
  scanToEnterProgressTableColumnMap,
} from './helpers'
import { ConsolidatedScanToEnterPromotion } from '../../../../../api/promotions-fetcher/constants'
import {
  PlayerPromotionFilterValues,
  ScanToEnterProgressFilterForm,
  initialPlayerPromotionFilterValues,
} from './ScanToEnterProgressFilterForm/ScanToEnterProgressFilterForm'
import { ScanToEnterProgressDetailsModalUI } from './ScanToEnterProgressDetailsModal/ScanToEnterProgressDetailsModalUI'
import './styles.scss'

const ROOT_CLASS = 'scan-to-enter-progress'

export type ScanToEnterProgressTabUIProps = {
  data: ConsolidatedScanToEnterPromotion[]
  isLoading: boolean
  error?: string
}

export const ScanToEnterProgressTabUI = (
  props: ScanToEnterProgressTabUIProps
) => {
  const [filterValues, setFilterValues] =
    React.useState<PlayerPromotionFilterValues>(
      initialPlayerPromotionFilterValues
    )
  const [mappedData, setMappedData] = React.useState<
    ScanToEnterProgressTableRow[]
  >([])

  // For use when pop up details modal is added
  const [openScanToEnterPromotion, setOpenScanToEnterPromotion] =
    React.useState<string>()

  React.useEffect(() => {
    const filteredData = filterScanToEnterProgressData(props.data, filterValues)

    setMappedData(
      filteredData.map((promotion) =>
        mapScanToEnterProgressRow(promotion, setOpenScanToEnterPromotion)
      )
    )
  }, [props.data, filterValues])

  return (
    <section className={ROOT_CLASS}>
      {openScanToEnterPromotion && (
        <ScanToEnterProgressDetailsModalUI
          data={
            props.data.find(
              (promotion) => promotion.promotionId === openScanToEnterPromotion
            )!
          }
          closeModal={() => setOpenScanToEnterPromotion(undefined)}
        />
      )}
      <ScanToEnterProgressFilterForm
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        clearFilterValues={() =>
          setFilterValues(initialPlayerPromotionFilterValues)
        }
        disabled={Boolean(props.error) || props.isLoading}
      />
      <div>
        <AsyncContainerUI
          isLoading={props.isLoading}
          error={props.error}
          errorTextSize={15}
          errorTextColor="white"
          color="white"
        >
          {mappedData.length === 0 ? (
            <div>No promotions found.</div>
          ) : (
            <GenericTableUI
              columnMap={scanToEnterProgressTableColumnMap}
              rowData={mappedData}
              usePages
              rowsPerPage={10}
              customRowCellStyling={{ padding: '12px 8px' }}
            />
          )}
        </AsyncContainerUI>
      </div>
    </section>
  )
}
