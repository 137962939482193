import React from "react";
import moment from "moment-timezone";
import {timeOfDayFormat} from "../../../constants";
import styles from './css/styles.module.css';
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";

export const ACHRejectFileHeaderComponent = ({batchDetailSearchResponse}: any) => {

    return (
        <>
            <div
                className={`${styles["page-container-body-left-label-and-input-box-container"]}`}>
                <table style={{width: "64%"}}>
                    <thead>
                    <tr>
                        <td>File Creation Date</td>
                        <td>File Creation Time</td>
                        <td>File Received Date</td>
                        <td>File Received Time</td>
                        <td>Number of Records</td>
                        <td>Amount</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{batchDetailSearchResponse[0]?.processtimestamp === undefined ? "" : moment.tz(batchDetailSearchResponse[0].processtimestamp, "America/New_York").format('MM-DD-YYYY')}</td>
                        <td>{batchDetailSearchResponse[0]?.processtimestamp === undefined ? "" : moment.tz(batchDetailSearchResponse[0].processtimestamp, "America/New_York").format(timeOfDayFormat)}</td>
                        <td>{batchDetailSearchResponse[0]?.recievetimestamp === undefined ? "" : moment.tz(batchDetailSearchResponse[0].recievetimestamp, "America/New_York").format('MM-DD-YYYY')}</td>
                        <td>{batchDetailSearchResponse[0]?.recievetimestamp === undefined ? "" : moment.tz(batchDetailSearchResponse[0].recievetimestamp, "America/New_York").format(timeOfDayFormat)}</td>
                        <td>{batchDetailSearchResponse[0]?.totalnumberofrecords === undefined ? "" : batchDetailSearchResponse[0].totalnumberofrecords}</td>
                        <td>{batchDetailSearchResponse[0]?.amount === undefined ? "" : normilizeAmount(batchDetailSearchResponse[0].amount)}</td>
                    </tr>
                    </tbody>

                </table>
            </div>
        </>
    );
}