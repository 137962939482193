export type PlayslipsStepDownValidation = {
  gameIdentifier: string
  stepDownDate: string
}

const ERROR_TEXT = 'This field is required'

export const validateCreatePlayslipsStepDownForm = (
  values: PlayslipsStepDownValidation
) => {
  let errors = {}

  if (!values.gameIdentifier) {
    Object.assign(errors, { gameIdentifier: ERROR_TEXT })
  }

  if (!values.stepDownDate) {
    Object.assign(errors, { stepDownDate: ERROR_TEXT })
  }

  return errors
}
