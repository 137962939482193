import React from "react";
import styles from './css/styles.module.css';
import InputMask from 'react-input-mask';
import {ErrorMessageFieldCommonComponent} from "../../../../CommonComponents/ErrorMessageFieldCommonComponent";


export const CreateBookKeeperComponent = ({
                                              formik,
                                              setFiledValue,
                                          }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>Bookkeeper Details
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Name of Bookkeeper*
                    </div>
                    <input
                        autoComplete={"name"}
                        id="licenseDetails.bookkeeper.fullName"
                        name="licenseDetails.bookkeeper.fullName"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.bookkeeper.fullName}
                        onBlur={formik.handleBlur}
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.fullName && formik.errors.fullName6) ?
                        <ErrorMessageFieldCommonComponent
                            fullName6={formik.errors.fullName6}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Home Phone*
                    </div>
                    <InputMask
                        id="licenseDetails.bookkeeper.homePhone"
                        name="licenseDetails.bookkeeper.homePhone"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.bookkeeper.homePhone}
                        onBlur={formik.handleBlur}
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                        autoComplete={"tel-national"}
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.homePhone && formik.errors.homePhone6) ?
                        <ErrorMessageFieldCommonComponent
                            homePhone6={formik.errors.homePhone6}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Mailing Address*</div>
                    <input
                        id="licenseDetails.bookkeeper.address.streetAddress"
                        name="licenseDetails.bookkeeper.address.streetAddress"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.bookkeeper.address.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                        autoComplete={"street-address"}
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.address?.streetAddress && formik.errors.streetAddress6) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress6={formik.errors.streetAddress6}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Business Phone*
                    </div>
                    <InputMask
                        id="licenseDetails.bookkeeper.businessPhone"
                        name="licenseDetails.bookkeeper.businessPhone"
                        onChange={(e: any) => setFiledValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.licenseDetails.bookkeeper.businessPhone}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                        autoComplete={"tel-national"}
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.businessPhone && formik.errors.businessPhone6) ?
                        <ErrorMessageFieldCommonComponent
                            businessPhone6={formik.errors.businessPhone6}/> : null}
                </div>
                <div className={styles['city-zip-container-body']}>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            id="licenseDetails.bookkeeper.address.city"
                            name="licenseDetails.bookkeeper.address.city"
                            value={formik.values.licenseDetails.bookkeeper.address.city}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            autoComplete={"address-level2"}
                        />
                        {(formik.touched.licenseDetails?.bookkeeper?.address?.city && formik.errors.city6) ?
                            <ErrorMessageFieldCommonComponent
                                city6={formik.errors.city6}/> : null}
                    </div>

                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Zipcode*</div>
                        <InputMask
                            id="licenseDetails.bookkeeper.address.zip"
                            name="licenseDetails.bookkeeper.address.zip"
                            value={formik.values.licenseDetails.bookkeeper.address.zip}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder="Ex. 12345"
                            mask="99999"
                            maskChar=" "
                            autoComplete={"postal-code"}
                        />
                        {(formik.touched.licenseDetails?.bookkeeper?.address?.zip && formik.errors.zip6) ?
                            <ErrorMessageFieldCommonComponent
                                zip6={formik.errors.zip6}/> : null}
                    </div>
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Email Address*</div>
                    <input
                        id="licenseDetails.bookkeeper.email"
                        name="licenseDetails.bookkeeper.email"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.bookkeeper.email}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                        autoComplete={"email"}
                    />
                    {(formik.touched.licenseDetails?.bookkeeper?.email && formik.errors.email6) ?
                        <ErrorMessageFieldCommonComponent
                            email6={formik.errors.email6}/> : null}
                </div>
            </form>
        </>
    );
}