import * as React from 'react'
import {
  PromotionalMessagesSidebarUI,
  PromotionalMessagesSidebarUIProps,
} from './promotional-messages-sidebar-ui'
import { useFetchAllActivePlayslipsGames } from '../../../api/playslips-fetcher/use-fetch-playslips'

export type PromotionalMessagesSidebarContainerProps = Pick<
  PromotionalMessagesSidebarUIProps,
  'formMode' | 'closeSidebar'
>

export const PromotionalMessagesSidebarContainer = (
  props: PromotionalMessagesSidebarContainerProps
) => {
  const { data, isLoading, error } = useFetchAllActivePlayslipsGames()
  return (
    <PromotionalMessagesSidebarUI
      {...props}
      activeGamesLoading={isLoading}
      activeGamesError={error}
      activeGames={data}
    />
  )
}
