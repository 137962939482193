import config from "../../../../../config.js";
import { makeHttpCall } from "../../../../../services/MakeHttpCall";
import { PostPaymentRules } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/PostPaymentRules";
import { SubAccountTreeNode } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { TempPaymentRules } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/TempPaymentRules";
import { PaymentRule } from "../../../../../types/Annuities/entities/payment-rule";
import { SubAccount } from "../../../../../types/Annuities/entities/sub-account";
import { convertPaymentRules } from "../functions";

// submit- update sub account
const updateSubAccount: (
  existingPaymentRules: PaymentRule[],
  previousPaymentRules: TempPaymentRules[],
  subAccountDetails: SubAccount,
  context: any,
  history: any,
  idemToken: string
) => void = async (
  existingPaymentRules,
  previousPaymentRules,
  subAccountDetails,
  context,
  history,
  idemToken
) => {
  try {
    await updateSubAccountDetails(subAccountDetails);
    await updateExistingPaymentRules(existingPaymentRules);
    if (previousPaymentRules.length > 0) {
      await addPaymentRules(
        previousPaymentRules,
        subAccountDetails.id,
        idemToken
      );
    }
    context.setBannerInfo({ message: "Update Successful", error: false });
    history.go(0);
  } catch (error) {
    context.setBannerInfo({ message: `${error}`, error: true });
  }
};

// update existing payment rules
const updateExistingPaymentRules = async (
  existingPaymentRules: PaymentRule[]
) => {
  const options = {
    method: "PUT",
    url: `${config.SERVER_BASE_URL}/v1/annuities/payment-rule/`,
    data: existingPaymentRules,
  };

  try {
    await makeHttpCall(options);
  } catch {
    throw new Error("Something went wrong Updating Existing Payment Rules");
  }
};

// POST - new Payment Rules
const addPaymentRules = async (
  previousPaymentRules: TempPaymentRules[],
  subAccountId: string,
  idemToken: string
) => {
  const newPaymentRules: PostPaymentRules[] = convertPaymentRules(
    previousPaymentRules,
    subAccountId
  );

  const options = {
    method: "POST",
    url: `${config.SERVER_BASE_URL}/v1/annuities/payment-rule/`,
    data: newPaymentRules,
    headers: {
      "Content-Type": `application/json`,
      "x-idempotency-token": idemToken,
    },
  };

  try {
    await makeHttpCall(options);
  } catch {
    throw new Error("Something went wrong Adding Payment Rules");
  }
};

// PUT - update subAccount
const updateSubAccountDetails = async (subAccountDetails: SubAccount) => {
  const options = {
    method: "PUT",
    url: `${config.SERVER_BASE_URL}/v1/annuities/sub-account/${subAccountDetails.id}`,
    data: subAccountDetails,
  };

  try {
    await makeHttpCall(options);
  } catch {
    throw new Error("Something went wrong updating Sub Account Details");
  }
};

const getParentNode = (
  rootNode: SubAccountTreeNode,
  subAccountId: string
): SubAccountTreeNode | undefined => {
  const desiredSubAccount = rootNode.subAccountChildren.find(
    (childNode) => childNode.account.id === subAccountId
  )
  if (desiredSubAccount) {
    return rootNode
  }
  if (rootNode.subAccountChildren) {
    for (let i = 0; i < rootNode.subAccountChildren.length; i++) {
      const result = getParentNode(rootNode.subAccountChildren[i], subAccountId)
      if (result !== undefined) {
        return result
      }
    }
  }

  return undefined
};

export { getParentNode, updateSubAccount };
