import React, {useEffect, useState} from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from './../../CommonComponents/ErrorMessageFieldCommonComponent';
import {keyPressedType} from "../../../services/Commons";


export const ACHActivitySearchComponent = ({onClearSearch, setACHActivitySearchAPIResponse: setACHActivitySearchAPIResponse, doACHActivitySearch, formik, setFieldValue, setButtonClicked, setCurrentPage}: any) => {

    const userpolicy: string | any = localStorage.getItem('userpolicies');

    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>ACH Activity</div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-1"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Start Date</div>
                    <input
                        autoComplete={"off"}
                        id="startDate"
                        name="startDate"
                        onChange={(e: any) => setFieldValue(e)}
                        className={styles["page-container-body-left-input-box"]}
                        value={formik.values.startDate}
                        aria-label={`${formik.values.startDate}.`}
                        type="date"
                    />
                    {formik.errors.startDate && <ErrorMessageFieldCommonComponent startDate={formik.errors.startDate}/>}
                </div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-2"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>End Date</div>
                    <input
                        autoComplete={"text"}
                        id="endDate"
                        name="endDate"
                        className={styles["page-container-body-left-input-box"]}
                        type="date"
                        onChange={(e: any) => {
                            setFieldValue(e);
                        }}
                        value={formik.values.endDate}
                        aria-label={`${formik.values.endDate}.`}
                        disabled={!formik.values.startDate}
                    />
                    {formik.errors.endDate && <ErrorMessageFieldCommonComponent endDate={formik.errors.endDate}/>}
                </div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-1"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Batch #</div>
                    <input
                        autoComplete={"off"}
                        id="batchNumber"
                        name="batchNumber"
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.batchNumber}
                        className={styles["page-container-body-left-input-box"]}
                        aria-label={`${formik.values.batchNumber}.`}
                        type="text"
                    />
                    {formik.errors.batchNumber &&
                    <ErrorMessageFieldCommonComponent batchNumber={formik.errors.batchNumber}/>}
                </div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-2"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Reject File Ref #</div>
                    <input
                        autoComplete={"text"}
                        id="rejectFileRefNumber"
                        name="rejectFileRefNumber"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.rejectFileRefNumber}
                        aria-label={`${formik.values.rejectFileRefNumber}.`}
                        type="text"
                    />
                    {formik.errors.rejectFileRefNumber &&
                    <ErrorMessageFieldCommonComponent rejectFileRefNumber={formik.errors.rejectFileRefNumber}/>}
                </div>
                <div
                    className={`${styles["page-container-body-left-label-and-input-box-container"]} ${styles["form-column-3"]}`}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Paper Check File #
                    </div>
                    <input
                        autoComplete={"text"}
                        id="papercheckFileNumber"
                        name="papercheckFileNumber"
                        className={styles["page-container-body-left-input-box"]}
                        onChange={(e: any) => setFieldValue(e)}
                        value={formik.values.papercheckFileNumber}
                        aria-label={`${formik.values.papercheckFileNumber}.`}
                        type="text"
                    />
                    {formik.errors.papercheckFileNumber &&
                    <ErrorMessageFieldCommonComponent papercheckFileNumber={formik.errors.papercheckFileNumber}/>}
                </div>
                <div className={`${styles["top-ribbon-button-container"]} ${styles["form-column-3"]}`}>
                    <div
                        role="button"
                        tabIndex={0}
                        aria-pressed="false"
                        style={{
                            opacity: ((formik.values.startDate !== '' && formik.values.endDate !== '' ||
                                formik.values.batchNumber !== '' || formik.values.rejectFileRefNumber !== '' ||
                                formik.values.papercheckFileNumber !== '') &&
                                Object.keys(formik.errors).length === 0) ? "1" : "0.4",
                            cursor: ((formik.values.startDate !== '' && formik.values.endDate !== '' ||
                                formik.values.batchNumber !== '' || formik.values.rejectFileRefNumber !== '' ||
                                formik.values.papercheckFileNumber !== '') &&
                                Object.keys(formik.errors).length === 0) ? "pointer" : "not-allowed",
                        }}
                        onKeyPress={(e: any) => {
                            if (
                                (keyPressedType(e) === 13 || keyPressedType(e) === 0) &&
                                Object.keys(formik.errors).length === 0
                            ) {
                                if (formik.values.startDate !== '' && formik.values.endDate !== '' ||
                                    formik.values.batchNumber !== '' || formik.values.rejectFileRefNumber !== '' ||
                                    formik.values.papercheckFileNumber !== '' &&
                                    Object.keys(formik.errors).length === 0) {
                                    setButtonClicked(true);
                                    doACHActivitySearch(formik.values.startDate, formik.values.endDate, formik.values.batchNumber, formik.values.rejectFileRefNumber, formik.values.papercheckFileNumber, setACHActivitySearchAPIResponse, setCurrentPage);
                                    formik.submitForm();
                                }
                            }
                        }}

                        onClick={() => {

                            if (formik.values.startDate !== '' && formik.values.endDate !== '' ||
                                formik.values.batchNumber !== '' || formik.values.rejectFileRefNumber !== '' ||
                                formik.values.papercheckFileNumber !== '' &&
                                Object.keys(formik.errors).length === 0) {
                                setButtonClicked(true);
                                doACHActivitySearch(formik.values.startDate, formik.values.endDate, formik.values.batchNumber, formik.values.rejectFileRefNumber, formik.values.papercheckFileNumber, setACHActivitySearchAPIResponse, setCurrentPage);
                                formik.submitForm();
                            }

                        }}

                        className={styles["top-ribbon-search-button"]}
                    >
                        Search
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        aria-pressed="false"
                        style={{
                            opacity: Object.keys(formik.errors).length > 0 ? "0.4" : "1",
                        }}
                        onKeyPress={(e: any) => {
                            if (
                                (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) &&
                                Object.keys(formik.errors).length === 0
                            ) {

                                formik.resetForm({
                                    values: {
                                        startDate: '',
                                        endDate: '',
                                        batchNumber: '',
                                        rejectFileRefNumber: '',
                                        papercheckFileNumber: ''
                                    }
                                });
                                onClearSearch(setACHActivitySearchAPIResponse);

                            }
                        }}
                        onClick={() => {
                            formik.resetForm({
                                values: {
                                    startDate: '',
                                    endDate: '',
                                    batchNumber: '',
                                    rejectFileRefNumber: '',
                                    papercheckFileNumber: ''
                                }
                            });
                            onClearSearch(setACHActivitySearchAPIResponse);
                        }}
                        className={styles["top-ribbon-clear-search-button"]}
                    >
                        Clear Search
                    </div>
                </div>
            </form>

        </>

    );
}