import * as React from 'react'
import { PromotionCampaignPermissions } from '../../../../api/promotions-fetcher/permissions'
import { useFetchGetPromotionCampaignBuilder } from '../../../../api/promotions-fetcher/use-fetch-base-promotions'
import { userHasAnyOfPermissions } from '../../../../util/access-helpers'
import {
  PromotionCampaignBuilderSidebarUI,
  PromotionCampaignBuilderSidebarUIProps,
} from './promotion-campaign-sidebar-ui'

export type PromotionCampaignBuilderSidebarContainerProps = Omit<
  PromotionCampaignBuilderSidebarUIProps,
  'promotion' | 'isLoading' | 'error' | 'canEditPromotionCampaigns'
> & {
  promotionId?: string
}

export const PromotionCampaignBuilderSidebarContainer = (
  props: PromotionCampaignBuilderSidebarContainerProps
) => {
  const { promotionId, ...uiProps } = props
  const { data, isLoading, error } =
    useFetchGetPromotionCampaignBuilder(promotionId)
  return (
    <PromotionCampaignBuilderSidebarUI
      {...uiProps}
      promotion={data}
      error={error}
      isLoading={isLoading}
      canEditPromotionCampaigns={userHasAnyOfPermissions([
        PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS,
      ])}
    />
  )
}
