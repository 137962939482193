import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';

import success from './../../../icons/icon_approved.svg';
import denied from './../../../icons/icon_denied.svg';
import { useHistory } from "react-router-dom";
import { playerSupportRoutePath } from './../../../config.js';
import { keyPressedType } from "../../../services/Commons";
import { playersIdentityValues } from './../../../services/Commons';
import { clearPageMessageDefault } from './../../CommonComponents/PageMessageCommonComponent'
import { mobileClaimDetailsPageInterface } from "../../../pages/ClaimsPages/MobileClaimDetailsPage/interfaces";

export const TicketInformationComponent = ( { mobileClaimDetailsPage }:mobileClaimDetailsPageInterface|any ) => {
        
    return (
        <>
            <div onFocus={() => clearPageMessageDefault()} className={styles['page-container']}>
                <div className={styles['claim-data-container']}>
                    <div className={styles['players-status-title-container']} >
                        <div tabIndex={0} className={styles['players-status-label']}>Ticket Information</div>
                    </div>
                    <div className={styles['player-status-data-container']}>
                        <div tabIndex={0} className={`${styles['player-status-kyc']} ${styles['opacity']}`}><div className={styles['players-status-label-text']}>Ticket<br />Barcode</div></div>
                        <div tabIndex={0} className={styles['player-status-data-container-column2']}>
                            <div className={styles['KYC-Status']}>{`${mobileClaimDetailsPage.claimReferenceNumber.claim.currState.barcode}`}</div>
                        </div>
                        <div tabIndex={0} className={`${styles['player-status-account']} ${styles['opacity']}`}>Game</div>
                        <div tabIndex={0} className={`${styles['player-status-data-container-column2']} ${styles['player-status-account']}`}>
                            <div className={styles['KYC-Status']}>{`${mobileClaimDetailsPage.claimReferenceNumber.claim.currState.gameName}`}</div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}