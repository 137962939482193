import moment from 'moment'
import { getIn, setIn } from 'formik'
import {
  PrizeType,
  PromotionBuilderDrawing,
  PromotionBuilderDrawingGroup,
  PromotionBuilderRequestBody,
  PromotionBuilderReward,
  PromotionBuilderRewardGroup,
  PromotionBuilderRule,
  PromotionBuilderRulePredicate,
  PromotionType,
  RewardType,
  TriggerEvent,
} from '../../../../../api/promotions-fetcher/constants'
import {
  FORMIK_DATE_FORMAT,
  getDrawFilePrefix,
  getMinimumDateForDrawing,
  mapDrawingGroup,
  mapInitialDrawing,
  mapInitialPrizeTier,
  mapRules,
  TAXABLE_PRIZE_AMOUNT,
  VALID_GAME_ID_LENGTH,
} from './helpers'

export type PromotionCampaignValidationDrawingGroup = {
  id?: string
  name?: string
}

export type PromotionCampaignValidationRewardGroup = {
  id?: string
  name?: string
  promotionRewards: PromotionBuilderReward[]
  rules: PromotionCampaignValidationRule[]
}

export type PromotionCampaignValidationRule = {
  id?: string
  predicates: PromotionBuilderRulePredicate[]
  quantity?: number
  gameIds: string[]
}

export type PromotionCampaignValidationPrizeTier = {
  id?: string
  tierName?: string
  prizeType?: PrizeType
  prizeValueInDollars?: number
  numWinners?: number
  description?: string
  eventAllocationInDollars?: number
  experienceValueInDollars?: number
}

export type PromotionCampaignValidation = {
  name: string
  drawFilePrefix: string
  description: string
  promotionType: string
  startDate: string
  endDate: string
  numberOfDrawings: number
  drawings: PromotionBuilderDrawing[]
  drawingGroup?: PromotionCampaignValidationDrawingGroup
  prizeTiers: PromotionCampaignValidationPrizeTier[]
  hasPrizeTiers: boolean
  gameIds: string[]
  hasBonusDraws: boolean
  bonusDrawingGroup?: PromotionCampaignValidationDrawingGroup
  bonusDrawFilePrefix: string
  numberOfBonusDrawings: number
  bonusDrawings: PromotionBuilderDrawing[]
  hasBonusPrizeTiers: boolean
  bonusPrizeTiers: PromotionCampaignValidationPrizeTier[]
  hasEntriesDistribution: boolean
  promotionRewardGroups: PromotionCampaignValidationRewardGroup[]
  vendor: string
}

export const getInitialValues = (
  promotion?: PromotionBuilderRequestBody
): PromotionCampaignValidation => {
  if (promotion) {
    const drawingGroup = promotion.drawingGroups.find(
      (x) => !x.name.toLowerCase().includes('bonus')
    )
    const bonusDrawingGroup = promotion.drawingGroups.find((x) =>
      x.name.toLowerCase().includes('bonus')
    )

    const drawFilePrefix = drawingGroup?.drawFilePrefix ?? ''
    const drawings = drawingGroup?.drawings ?? []
    const prizeTiers = drawingGroup?.prizeTiers ?? []

    const bonusDrawFilePrefix = bonusDrawingGroup?.drawFilePrefix ?? ''
    const bonusDrawings = bonusDrawingGroup?.drawings ?? []
    const bonusPrizeTiers = bonusDrawingGroup?.prizeTiers ?? []

    const rewardGroup: PromotionCampaignValidationRewardGroup[] =
      promotion.promotionRewardGroups.map((val) => {
        return {
          promotionRewards: val.promotionRewards,
          rules: val.rules ? val.rules : [],
          id: val.id,
          name: val.name,
        }
      })

    return {
      name: promotion.name,
      drawFilePrefix: drawFilePrefix,
      description: promotion.description || '',
      promotionType:
        PromotionType[promotion.type as keyof typeof PromotionType],
      startDate: moment(promotion.startDate).format(FORMIK_DATE_FORMAT),
      endDate: promotion.endDate
        ? moment(promotion.endDate).format(FORMIK_DATE_FORMAT)
        : '',
      numberOfDrawings: drawings.length,
      drawingGroup: {
        id: drawingGroup?.id,
        name: drawingGroup?.name,
      },
      drawings: drawings.map(mapInitialDrawing),
      prizeTiers: prizeTiers.map(mapInitialPrizeTier),
      hasPrizeTiers: prizeTiers.length > 0,
      gameIds: promotion.gameIds,
      bonusDrawFilePrefix: bonusDrawFilePrefix,
      hasBonusDraws: Boolean(bonusDrawingGroup),
      bonusDrawingGroup: {
        id: bonusDrawingGroup?.id,
        name: bonusDrawingGroup?.name,
      },
      numberOfBonusDrawings: bonusDrawings.length,
      bonusDrawings: bonusDrawings.map(mapInitialDrawing),
      hasBonusPrizeTiers: bonusPrizeTiers.length > 0,
      bonusPrizeTiers: bonusPrizeTiers.map(mapInitialPrizeTier),
      promotionRewardGroups: rewardGroup,
      hasEntriesDistribution:
        promotion.promotionRewardGroups[0].promotionRewards.length > 1,
      vendor: promotion.vendor ?? ''
    }
  }
  return {
    name: '',
    drawFilePrefix: '',
    description: '',
    promotionType: '',
    startDate: '',
    endDate: '',
    numberOfDrawings: 0,
    drawings: [],
    prizeTiers: [{}],
    hasPrizeTiers: true,
    gameIds: [''],
    bonusDrawFilePrefix: '',
    hasBonusDraws: false,
    numberOfBonusDrawings: 0,
    bonusDrawings: [],
    hasBonusPrizeTiers: false,
    bonusPrizeTiers: [{}],
    promotionRewardGroups: [
      {
        name: '',
        promotionRewards: [],
        rules: [{ predicates: [], gameIds: [''] }],
      },
    ],
    hasEntriesDistribution: false,
    vendor: ''
  }
}

const getPromotionTriggerEvent = (promotionTypeString: string) => {
  switch (promotionTypeString) {
    case PromotionType.MANUAL_WINNER_ENTRY:
      return TriggerEvent.MANUAL
    case PromotionType.REGISTRATION:
      return TriggerEvent.REGISTRATION
    case PromotionType.ZIP_TRIP:
      return TriggerEvent.CLICK
    case PromotionType.SECOND_CHANCE:
    case PromotionType.SCAN_TO_ENTER:
      return TriggerEvent.SCAN
    default:
      throw new Error('Invalid Promotion Type')
  }
}

export const mapSubmissionBody = (
  values: PromotionCampaignValidation,
  originalPromotion?: PromotionBuilderRequestBody | undefined
): PromotionBuilderRequestBody => {
  const promotionType =
    Object.keys(PromotionType)[
      Object.values(PromotionType).indexOf(
        values.promotionType as PromotionType
      )
    ]

  let bonusDrawingGroup: PromotionBuilderDrawingGroup[] = []

  if (values.hasBonusDraws) {
    bonusDrawingGroup = [
      mapDrawingGroup(
        true,
        values.bonusDrawFilePrefix,
        values.promotionType,
        values.bonusDrawings,
        values.bonusPrizeTiers,
        values.bonusDrawingGroup
      ),
    ]
  }

  let rules: PromotionBuilderRule[] | undefined = undefined

  if (values.promotionType === PromotionType.SECOND_CHANCE) {
    rules = [
      {
        gameIds: values.gameIds,
        predicates: [],
        quantity: 1,
        id: originalPromotion?.promotionRewardGroups[0].rules?.[0].id,
      },
    ]
  } else {
    rules = mapRules(values.promotionRewardGroups[0].rules)
  }

  const rewardGroup: PromotionBuilderRewardGroup[] = [
    {
      id: values.promotionRewardGroups[0].id,
      name:
        values.promotionRewardGroups[0].name ??
        (values.hasEntriesDistribution
          ? 'Entries Distribution Reward Group'
          : 'Auto-generated Reward Group'),
      promotionRewards:
        values.promotionRewardGroups[0].promotionRewards.length > 0
          ? values.promotionRewardGroups[0].promotionRewards
          : [
              {
                rewardType: RewardType.DRAWING_ENTRY,
                quantity: 1,
                distributionPercentage: 100,
              },
            ],
      rules: rules,
    },
  ]

  return {
    id: originalPromotion?.id,
    name: values.name,
    description: values.description,
    startDate: values.startDate,
    endDate: values.endDate || undefined,
    triggerEventType: getPromotionTriggerEvent(values.promotionType),
    type: promotionType,
    gameIds:
      values.promotionType === PromotionType.SECOND_CHANCE
        ? values.gameIds
        : [],
    promotionRewardGroups: rewardGroup,
    drawingGroups: [
      mapDrawingGroup(
        false,
        values.drawFilePrefix,
        values.promotionType,
        values.drawings,
        values.prizeTiers,
        values.drawingGroup
      ),
      ...bonusDrawingGroup,
    ],
    vendor: values.vendor || undefined
  }
}

const REQUIRED_ERROR_TEXT = 'This field is required'
const DESCRIPTION_LENGTH_FOR_SCAN_TO_ENTER =
  'Description cannot exceed 100 characters for Scan to Enter promotion'
const END_DATE_AFTER_START_DATE_ERROR_TEXT =
  'End date must be after the start date'
const AT_LEAST_ONE_DRAWING_ERROR_TEXT =
  'Promotion campaign must contain at least 1 drawing'

const getEndDateAfterPromotionStartDateError = (fieldName: string) =>
  `${fieldName} must be later than promotion start date`

const getEndDateInFutureAndAfterOtherEndDatesError = (fieldName: string) =>
  `${fieldName} must be in the future and later than all preceding ${fieldName.toLowerCase()}s`
const SCHEDULED_DRAW_TIME_AFTER_COLLECTION_END_TIME =
  'Scheduled draw time must be later than scheduled draw collection end time'
const AT_LEAST_ONE_PRIZE_TIER_ERROR_TEXT =
  'Promotion campaign must contain at least 1 prize tier'
const QUANTITY_GREATER_THAN_ZERO_ERROR_TEXT = 'Quantity must be greater than 0'
const GAME_ID_VALID_LENGTH_TEXT = `Game ID must have exactly ${VALID_GAME_ID_LENGTH} digits`
const GAME_ID_MUST_BE_NUMBER = 'Game ID must only contain digits 0-9'
const PRIZE_AMOUNT_MUST_BE_NON_TAXABLE_FOR_MANUAL_ENTRY = `Prize amount must be less than $${TAXABLE_PRIZE_AMOUNT} for Manual Winner Entry`
const NO_EXPERIENTIAL_PRIZE_FOR_MANUAL_ENTRY = `Experiential prizes are not allowed for Manual Winner Entry`
const PRIZE_AMOUNT_MUST_BE_GREATER_THAN_ZERO =
  'Prize amount must be greater than $0'
const INVALID_STRING_PATTERN =
  'Field must be in all caps and cannot contain spaces or underscores'
const INVALID_PROMOTION_FILE_PREFIX = 'Invalid promotion file prefix'
const DISTRIBUTION_MUST_TOTAL_ONE_HUNDRED =
  'The distribution percentages must total to 100%'
const DISTRIBUTION_QUANTITY_MUST_BE_AT_LEAST_ONE =
  'Distribution quantity must be at least 1'
const DISTRIBUTION_PERCENTAGE_MUST_BE_WHOLE_NUMBER =
  'Distribution percentage must be a whole number'
const AT_LEAST_ONE_RULE =
  'At least one rule is required for Scan to Enter Promotions'
const RULE_QUANTITY_WHOLE_NUMBER = 'Quantity should be a whole number'

type PromotionCampaignErrorType = {
  name?: string
  drawFilePrefix?: string
  description?: string
  promotionType?: string
  startDate?: string
  endDate?: string
  gameIds?: string[]
  numberOfDrawings?: string
  drawings?: { drawingDate?: string }[]
  hasPrizeTiers?: string
  prizeTiers?: PromotionCampaignValidationPrizeTier[]
  bonusDrawFilePrefix?: string
  numberOfBonusDrawings?: string
  hasBonusDraws?: string
  bonusDrawings?: { drawingDate?: string }[]
  hasBonusPrizeTiers?: string
  bonusPrizeTiers?: PromotionCampaignValidationPrizeTier[]
  hasEntriesDistribution?: string
  promotionRewards?: string[]
  rules?: PromotionCampaignValidationRule[],
  vendor?: string
}

const validateEntriesDistribution = (
  errors: PromotionCampaignErrorType,
  hasEntriesDistribution: boolean,
  rewards: PromotionBuilderReward[]
) => {
  if (!hasEntriesDistribution) {
    return errors
  }

  errors = setIn(errors, 'promotionRewards', [
    ...Array(rewards.length).fill(''),
  ])

  if (
    rewards.reduce(
      (prev, curr): number => prev + curr.distributionPercentage,
      0
    ) !== 100
  ) {
    errors.hasEntriesDistribution = DISTRIBUTION_MUST_TOTAL_ONE_HUNDRED
  }
  rewards.forEach((reward, index) => {
    if (reward.quantity < 1) {
      errors = setIn(
        errors,
        `promotionRewards[${index}]`,
        DISTRIBUTION_QUANTITY_MUST_BE_AT_LEAST_ONE
      )
    } else if (reward.distributionPercentage % 1 !== 0) {
      errors = setIn(
        errors,
        `promotionRewards[${index}]`,
        DISTRIBUTION_PERCENTAGE_MUST_BE_WHOLE_NUMBER
      )
    }
  })

  if (errors.promotionRewards?.every((value) => !value)) {
    delete errors['promotionRewards']
  }

  return errors
}

const validateDrawings = (
  errors: PromotionCampaignErrorType,
  drawings: PromotionBuilderDrawing[],
  numberOfDrawings: number,
  numberOfField: 'numberOfDrawings' | 'numberOfBonusDrawings',
  drawingsField: 'drawings' | 'bonusDrawings',
  promotionStartDate: string
) => {
  if (numberOfDrawings < 1) {
    errors = setIn(errors, numberOfField, AT_LEAST_ONE_DRAWING_ERROR_TEXT)
  }

  errors = setIn(errors, drawingsField, [...Array(drawings.length).fill({})])

  drawings.forEach((drawing, index) => {
    if (
      drawing.scheduledDrawTime &&
      promotionStartDate &&
      moment(drawing.scheduledDrawTime).isBefore(moment(promotionStartDate))
    ) {
      errors = setIn(
        errors,
        `${drawingsField}[${index}].scheduledDrawTime`,
        getEndDateAfterPromotionStartDateError('Scheduled draw time')
      )
    }
    const minDate = getMinimumDateForDrawing('', drawings, index, true)
    if (
      index > 0 &&
      drawing.scheduledDrawTime &&
      moment(drawing.scheduledDrawTime).isBefore(minDate)
    ) {
      errors = setIn(
        errors,
        `${drawingsField}[${index}].scheduledDrawTime`,
        getEndDateInFutureAndAfterOtherEndDatesError('Scheduled draw time')
      )
    }

    if (
      drawing.scheduledDrawCollectionEndTime &&
      promotionStartDate &&
      moment(drawing.scheduledDrawCollectionEndTime).isBefore(
        moment(promotionStartDate)
      )
    ) {
      errors = setIn(
        errors,
        `${drawingsField}[${index}].scheduledDrawCollectionEndTime`,
        getEndDateAfterPromotionStartDateError(
          'Scheduled draw collection end time'
        )
      )
    }
    const minCollectionDate = getMinimumDateForDrawing(
      '',
      drawings,
      index,
      false
    )
    if (
      index > 0 &&
      drawing.scheduledDrawCollectionEndTime &&
      moment(drawing.scheduledDrawCollectionEndTime).isBefore(minCollectionDate)
    ) {
      errors = setIn(
        errors,
        `${drawingsField}[${index}].scheduledDrawCollectionEndTime`,
        getEndDateInFutureAndAfterOtherEndDatesError(
          'Scheduled draw collection end time'
        )
      )
    }

    if (
      drawing.scheduledDrawCollectionEndTime &&
      drawing.scheduledDrawTime &&
      moment(drawing.scheduledDrawTime).isBefore(
        moment(drawing.scheduledDrawCollectionEndTime)
      )
    ) {
      errors = setIn(
        errors,
        `${drawingsField}[${index}].scheduledDrawTime`,
        SCHEDULED_DRAW_TIME_AFTER_COLLECTION_END_TIME
      )
    }
  })

  if (
    getIn(errors, drawingsField)?.every((value: { drawingDate?: string }) =>
      value ? Object.keys(value).length === 0 : true
    )
  ) {
    delete errors[drawingsField]
  }
  return errors
}

const validateRules = (
  errors: PromotionCampaignErrorType,
  rules: PromotionCampaignValidationRule[]
) => {
  if (rules.length < 1) {
    errors = setIn(errors, `rules`, AT_LEAST_ONE_RULE)
  }

  rules.forEach((rule, ruleIndex) => {
    //Validate predicates in future

    if (!rule.quantity) {
      errors = setIn(
        errors,
        `promotionRewardGroups[0].rules[${ruleIndex}].quantity`,
        REQUIRED_ERROR_TEXT
      )
    } else if (rule.quantity < 1) {
      errors = setIn(
        errors,
        `promotionRewardGroups[0].rules[${ruleIndex}].quantity`,
        QUANTITY_GREATER_THAN_ZERO_ERROR_TEXT
      )
    } else if (rule.quantity % 1 !== 0) {
      errors = setIn(
        errors,
        `promotionRewardGroups[0].rules[${ruleIndex}].quantity`,
        RULE_QUANTITY_WHOLE_NUMBER
      )
    }

    rule.gameIds.forEach((gameId, gameIndex) => {
      if (gameId.length !== VALID_GAME_ID_LENGTH) {
        errors = setIn(
          errors,
          `promotionRewardGroups[0].rules[${ruleIndex}].gameIds[${gameIndex}]`,
          GAME_ID_VALID_LENGTH_TEXT
        )
      } else if (!/^\d+$/.test(gameId)) {
        errors = setIn(
          errors,
          `promotionRewardGroups[0].rules[${ruleIndex}].gameIds[${gameIndex}]`,
          GAME_ID_MUST_BE_NUMBER
        )
      }
    })
  })

  return errors
}

const validatePrizeTiers = (
  errors: PromotionCampaignErrorType,
  prizeTiers: PromotionCampaignValidationPrizeTier[],
  prizeTiersField: 'prizeTiers' | 'bonusPrizeTiers',
  errorField: 'hasPrizeTiers' | 'hasBonusPrizeTiers',
  promotionType: string
) => {
  if (prizeTiers.length < 1) {
    errors = setIn(errors, errorField, AT_LEAST_ONE_PRIZE_TIER_ERROR_TEXT)
  }

  errors = setIn(errors, prizeTiersField, [
    ...Array(prizeTiers.length).fill({}),
  ])

  prizeTiers.forEach((prizeTier, index) => {
    if (!prizeTier.prizeType) {
      errors = setIn(
        errors,
        `${prizeTiersField}[${index}].prizeType`,
        REQUIRED_ERROR_TEXT
      )
    }

    if (prizeTier.numWinners === undefined) {
      errors = setIn(
        errors,
        `${prizeTiersField}[${index}].numWinners`,
        REQUIRED_ERROR_TEXT
      )
    } else if (prizeTier.numWinners < 1) {
      errors = setIn(
        errors,
        `${prizeTiersField}[${index}].numWinners`,
        QUANTITY_GREATER_THAN_ZERO_ERROR_TEXT
      )
    }

    if (
      prizeTier.prizeType &&
      (prizeTier.prizeType === PrizeType.CASH ||
        prizeTier.prizeType === PrizeType.EXPERIENCE)
    ) {
      if (!prizeTier.description) {
        errors = setIn(
          errors,
          `${prizeTiersField}[${index}].description`,
          REQUIRED_ERROR_TEXT
        )
      }
      if (prizeTier.prizeValueInDollars === undefined) {
        errors = setIn(
          errors,
          `${prizeTiersField}[${index}].prizeValueInDollars`,
          REQUIRED_ERROR_TEXT
        )
      }

      if (prizeTier.prizeType === PrizeType.CASH) {
        if (
          prizeTier.prizeValueInDollars !== undefined &&
          prizeTier.prizeValueInDollars <= 0
        ) {
          errors = setIn(
            errors,
            `${prizeTiersField}[${index}].prizeValueInDollars`,
            PRIZE_AMOUNT_MUST_BE_GREATER_THAN_ZERO
          )
        }
      }

      if (prizeTier.prizeType === PrizeType.EXPERIENCE) {
        if (promotionType === PromotionType.MANUAL_WINNER_ENTRY) {
          errors = setIn(
            errors,
            `${prizeTiersField}[${index}].prizeType`,
            NO_EXPERIENTIAL_PRIZE_FOR_MANUAL_ENTRY
          )
        }
        if (prizeTier.eventAllocationInDollars === undefined) {
          errors = setIn(
            errors,
            `${prizeTiersField}[${index}].eventAllocationInDollars`,
            REQUIRED_ERROR_TEXT
          )
        }
        if (prizeTier.experienceValueInDollars === undefined) {
          errors = setIn(
            errors,
            `${prizeTiersField}[${index}].experienceValueInDollars`,
            REQUIRED_ERROR_TEXT
          )
        }
      }

      if (
        prizeTier.prizeType === PrizeType.CASH &&
        promotionType === PromotionType.MANUAL_WINNER_ENTRY &&
        (prizeTier.prizeValueInDollars ?? 0) >= TAXABLE_PRIZE_AMOUNT
      ) {
        errors = setIn(
          errors,
          `${prizeTiersField}[${index}].prizeValueInDollars`,
          PRIZE_AMOUNT_MUST_BE_NON_TAXABLE_FOR_MANUAL_ENTRY
        )
      }
    }
  })
  if (
    getIn(errors, prizeTiersField)?.every(
      (value: PromotionCampaignValidationPrizeTier) =>
        value ? Object.keys(value).length === 0 : true
    )
  ) {
    delete errors[prizeTiersField]
  }
  return errors
}

const validateFilePrefix = function (
  errors: PromotionCampaignErrorType,
  promotionType: PromotionType,
  prefix?: string
) {
  if (!prefix) {
    errors.drawFilePrefix = REQUIRED_ERROR_TEXT
  } else if (!/^[A-Z0-9\-]+$/.test(prefix)) {
    errors.drawFilePrefix = INVALID_STRING_PATTERN
  } else if (!prefix.startsWith(getDrawFilePrefix(promotionType))) {
    errors.drawFilePrefix = INVALID_PROMOTION_FILE_PREFIX
  }

  return errors
}

const validateVendor = function (
  errors: PromotionCampaignErrorType,
  promotionType: string,
  prizeTiers: PromotionCampaignValidationPrizeTier[],
  vendor?: string 
) {
  const hasExperientialPrize = prizeTiers.some(tier => tier.prizeType === PrizeType.EXPERIENCE)

  if (promotionType === PromotionType.SECOND_CHANCE && hasExperientialPrize && !vendor) {
    errors.vendor = REQUIRED_ERROR_TEXT
  }

  return errors
}

export const validatePromotionCampaignForm = (
  values: PromotionCampaignValidation
) => {
  let errors: PromotionCampaignErrorType = {}
  if (!values.name) {
    errors.name = REQUIRED_ERROR_TEXT
  }

  if (!values.description) {
    errors.description = REQUIRED_ERROR_TEXT
  }

  if (!values.promotionType) {
    errors.promotionType = REQUIRED_ERROR_TEXT
  }

  if (
    values.promotionType === PromotionType.SCAN_TO_ENTER &&
    values.description.length > 100
  ) {
    errors.description = DESCRIPTION_LENGTH_FOR_SCAN_TO_ENTER
  }

  if (!values.startDate) {
    errors.startDate = REQUIRED_ERROR_TEXT
  }

  if (
    values.startDate &&
    values.endDate &&
    moment(values.endDate).isSameOrBefore(moment(values.startDate))
  ) {
    errors.endDate = END_DATE_AFTER_START_DATE_ERROR_TEXT
  }

  if (
    values.promotionType !== PromotionType.SECOND_CHANCE &&
    values.promotionType !== PromotionType.SCAN_TO_ENTER &&
    !values.endDate
  ) {
    errors.endDate = REQUIRED_ERROR_TEXT
  }

  if (values.promotionType === PromotionType.SECOND_CHANCE) {
    errors.gameIds = [...Array(values.gameIds.length).fill('')]

    values.gameIds.forEach((gameId, index) => {
      if (gameId.length !== VALID_GAME_ID_LENGTH) {
        errors.gameIds![index] = GAME_ID_VALID_LENGTH_TEXT
      } else if (!/^\d+$/.test(gameId)) {
        errors.gameIds![index] = GAME_ID_MUST_BE_NUMBER
      }
    })

    errors = validateFilePrefix(
      errors,
      values.promotionType,
      values.drawFilePrefix
    )
  } else if (values.promotionType === PromotionType.SCAN_TO_ENTER) {
    errors = validateRules(errors, values.promotionRewardGroups[0].rules)

    errors = validateFilePrefix(
      errors,
      values.promotionType,
      values.drawFilePrefix
    )
  }

  errors = validateVendor(errors, values.promotionType, values.prizeTiers, values.vendor)

  errors = validateEntriesDistribution(
    errors,
    values.hasEntriesDistribution,
    values.promotionRewardGroups[0].promotionRewards
  )
  errors = validateDrawings(
    errors,
    values.drawings,
    values.numberOfDrawings,
    'numberOfDrawings',
    'drawings',
    values.startDate
  )
  errors = validatePrizeTiers(
    errors,
    values.prizeTiers,
    'prizeTiers',
    'hasPrizeTiers',
    values.promotionType
  )

  if (values.hasBonusDraws) {
    if (values.promotionType === PromotionType.SECOND_CHANCE) {
      if (!values.bonusDrawFilePrefix) {
        errors.bonusDrawFilePrefix = REQUIRED_ERROR_TEXT
      } else if (!/^[A-Z0-9\.\-]+$/.test(values.bonusDrawFilePrefix)) {
        errors.bonusDrawFilePrefix = INVALID_STRING_PATTERN
      } else if (
        !values.bonusDrawFilePrefix.startsWith(
          getDrawFilePrefix(values.promotionType)
        )
      ) {
        errors.bonusDrawFilePrefix = INVALID_PROMOTION_FILE_PREFIX
      }
    }

    errors = validateDrawings(
      errors,
      values.bonusDrawings,
      values.numberOfBonusDrawings,
      'numberOfBonusDrawings',
      'bonusDrawings',
      values.startDate
    )
    errors = validatePrizeTiers(
      errors,
      values.bonusPrizeTiers,
      'bonusPrizeTiers',
      'hasBonusPrizeTiers',
      values.promotionType
    )
  }
  if (errors.gameIds?.every((value) => !value)) {
    delete errors['gameIds']
  }
  return errors
}
