import config from "../../../../config";
import { makeHttpCall } from "../../../../services/MakeHttpCall";

export const getLifetimeRecurringAmt = (winAmount: number, firstPaymentAmount: number, remainingPayments: number) => {
  // Amounts passed in are number of cents and should be adjusted to a currency before setting the recurring amount field value. 
  const recurringAmount = Math.round(((winAmount - firstPaymentAmount) / remainingPayments)) / 100;

  return recurringAmount.toFixed(2);
};

export const generateDocumentId = async (): Promise<string> => {
  const options = {
    method: "get",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/document-id`,
  };

  let docId;

  try {
    const response: any = await makeHttpCall(options);
    docId = response.documentId;
  } catch (error) {
    throw new Error("Something went wrong generating Document ID");
  }

  return docId;
};

export const getGraduatedPaymentSchedule = async (numberOfPayments: string, graduationRate: string, winAmount: string, setPaymentSchedule: any) => {
  let cleanedWinAmount = winAmount.replace('.', '');
  const options = {
    method: "get",
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/graduated-payment-schedule?numberOfPayments=${numberOfPayments}&graduationRate=${graduationRate}&winAmount=${cleanedWinAmount}`,
  };

  try {
    const response: any = await makeHttpCall(options);
    if (response.length > 0) {
      setPaymentSchedule(response);
    } else {
      setPaymentSchedule([]);
    }
  } catch (error) {
    throw new Error("Something went wrong getting Graduated Payment Schedule");
  }
};
