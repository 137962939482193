import axios, { AxiosRequestConfig } from "axios";
import config from "../../../../config";
import {makeHttpCall} from "../../../../services/MakeHttpCall";
import { savePDF } from "../../../../util/excel-export-util";

let formik: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;
let setBatchFileDetails: any;
let setWithholdings: any;

export const setVariables = (formikObj: any, setCurrentPageObj: any, setShowErrorDialogObj: any,
                             setRequestCompletedObj: any, setBatchFileDetailsObj: any, setWithholdingsObj: any) => {

    formik = formikObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
    setBatchFileDetails = setBatchFileDetailsObj;
    setWithholdings = setWithholdingsObj;
}

export const setFieldValue = (e: any, formik: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    getBatchFileDetails(values);
    getWithholdingDetails(values);
}

export const getPageData = (weeklyBeanoReports: any[], page: number = 0) => {
    const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(weeklyBeanoReports[i] === undefined)) {
            array.push(weeklyBeanoReports[i]);
        }
    }
    return array;
}

export const getBatchFileDetails = async (values: any) => {

    // alert(JSON.stringify(values));

    setCurrentPage(0);

    setRequestCompleted(false); // Display Circular Progress

    try {

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/offsets/achbatch/${values.batchNumber}`
        }

        let response = await makeHttpCall(options);

        setBatchFileDetails(response.data);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}


export const getWithholdingDetails = async (values: any) => {

    // alert(JSON.stringify(values));

    setCurrentPage(0);

    setRequestCompleted(false); // Display Circular Progress

    try {

        const options = {
            method: 'POST', url: `${config.SERVER_BASE_URL}/api/v1/offsets/batchInformation/${values.batchNumber}`
        }

        // alert(options.url);

        let response = await makeHttpCall(options);

        setWithholdings(response);

        setRequestCompleted(true);

    } catch (e) {
        setRequestCompleted(true);
        setShowErrorDialog(true);
    }

}

// based on annuities generateCheckRunPDF function
export const downloadOffsetsPdfReport = async (batchNumber: string, setBannerInfo: any) => {
    try {
        const url =`${config.SERVER_BASE_URL}/api/v1/offsets/pdfReport`

        const requestBody = {
            batchNumber
        }
    
        const axiosConfig : AxiosRequestConfig = {
            responseType: "arraybuffer"
        }
    
        const axiosResponse = await axios.post(url, requestBody, axiosConfig)
        if(axiosResponse.status !== 200) {
            throw new Error(`Error response from PDF endpoint`)
        }

        const contentDisposition = axiosResponse.headers['content-disposition']
    
        const filenameRegEx = /filename="(.*?)\.pdf"/ // PDF extension gets re-added in savePDF, so exclude it from the regex match
        const fileName = contentDisposition.match(filenameRegEx)[1]
    
        savePDF(axiosResponse.data, fileName)
    } catch(err) {
        setBannerInfo({message: 'Error downloading offsets report PDF', error: true})
    }
    
}