import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./css/style.module.css";
import {licenseeRoutePath} from "../../../../config.js";
import {keyPressedType} from "../../../../services/Commons";
import {PaginationCommonComponent} from "../../../CommonComponents/PaginationCommonComponent";
import {getPageData} from "../../../../pages/CharitableGamingPages/License/LicenseProfileManagementPage/functions";
import licenseActiveIcon from "../../../../icons/Icon_status_approved.svg";
import licenseInactiveIcon from "../../../../icons/icon_denied.svg";
import {
    LicenseeInterface,
    occasionTime
} from "../../../../pages/CharitableGamingPages/License/LicenseProfileManagementPage/interfaces";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";

export const LicenseeSearchResultComponent = ({LicenseeList, currentPage, setCurrentPage,}: any) => {

            const history:any = useHistory();
            const userpolicies: any = localStorage.getItem("userpolicies");

            const normalizePage = (totalPages: number, currentPage: number) => {
                const number: number = totalPages - currentPage * 10;
                if (number > 10) return currentPage * 10 + 10;
                else return totalPages;
            };

            return (
                <>

                    <div className={styles['table']}>
                        <div className={styles['table-data-header']}>
                            <div aria-live="assertive"
                                 tabIndex={0}>{`Displaying ${LicenseeList.length <= 0 ? 0 :
                                (currentPage * 10) + 1}-${normalizePage(LicenseeList.length, currentPage)} 
                        of ${LicenseeList.length} results`}</div>
                        </div>

                        <div className={`${styles['table-data-grid']} ${styles['table-data-label']}`}>
                            <div tabIndex={0}>License Number</div>
                            <div tabIndex={0}>Organization Name</div>
                            <div tabIndex={0}>Occasion Address</div>
                            <div tabIndex={0}>City/Town</div>
                            <div tabIndex={0}>Day</div>
                            <div tabIndex={0}>Hours of Operation</div>
                            <div tabIndex={0}>MIC Phone</div>
                            <div tabIndex={0}>Status</div>
                            <div tabIndex={0}>Renew</div>
                        </div>

                        <div className={styles['table-data-body']}>
                            {LicenseeList.length > 0 &&
                            getPageData(LicenseeList, currentPage).map((e: LicenseeInterface, i: number) =>

                                <div key={i} style={{backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)')}}
                                     className={`${styles['table-data-grid']} ${styles['table-data-data']}`}>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.licenseDetails.licenseNumber}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.orgDetails.orgName}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.licenseDetails.occasionAddress.streetAddress}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.licenseDetails.occasionAddress.city}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.licenseDetails.occasionDay.toUpperCase()}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {occasionTime[e.licenseDetails.occasionTime]}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.licenseDetails.memberInChargePhone}
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {(e.licenseDetails.active) ? "Active" : "Expired"}
                                        <img
                                            src={(e.licenseDetails.active) ? licenseActiveIcon : licenseInactiveIcon}
                                            alt="status icon"
                                        />
                                    </div>
                                    <div tabIndex={0} className={styles['table-data-data-text']}>
                                        {e.licenseDetails.markedForRenewal ? "Yes" : "No"}
                                    </div>
                                    <div className={styles['table-details-button-container']}>
                                        {checkRenderPermissions(permissions.CAN_VIEW_LICENSE_DETAILS, JSON.parse(userpolicies)) ?
                                            <div tabIndex={0} role='button' aria-pressed={"false"}
                                                 onKeyDown={(en: any) => {
                                                     if (
                                                         keyPressedType(en) === 13 ||
                                                         keyPressedType(en) === 32
                                                     ) {
                                                         history.push({
                                                             pathname: `${licenseeRoutePath}/view/${e.licenseDetails.licenseNumber}`,
                                                             state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
                                                         });
                                                     }
                                                 }}
                                                 onClick={() => {
                                                     history.push({
                                                         pathname: `${licenseeRoutePath}/view/${e.licenseDetails.licenseNumber}`,
                                                         state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
                                                     });
                                                 }}
                                                 className={styles['table-details-button']}>
                                                <div aria-label={'Licensee Details Button.'}
                                                     className={styles['table-details-button-text']}>
                                                    Details
                                                </div>
                                            </div> : null}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles['table-data-footer']}>

                            <div aria-live="assertive" tabIndex={0}
                                 className={styles['table-page-label']}>{`Page ${currentPage + 1} 
                         of ${Math.ceil((LicenseeList.length / 10)).toString()}`}</div>
                            {<PaginationCommonComponent playerData={LicenseeList} currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}/>}

                        </div>
                    </div>


                </>
            );
};
