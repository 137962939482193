import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { keyPressedType } from "../../../../services/Commons";
import { KEY_CODES } from "../../../../constants.js";
import { annuitiesRoutePath } from "../../../../config.js";
import { formatDollarAmount } from "../../../../util";
import styles from "./css/styles.module.css";
import { AuthorizationHistoryData } from "../../../../types/Annuities/TimePaymentAuthorization/AuthorizationHistory";

export const AuthorizationHistoryListRowCmpt: React.FC<{ authorizationHistory: AuthorizationHistoryData }> = ({ authorizationHistory }) => {
  const history = useHistory();

  return (
    <div className={`${styles.table_body}`}>
      <div className={`${styles.table_body_text}`}>{moment.tz(authorizationHistory.createdOn, "America/New_York").format("MM/DD/YYYY")}</div>
      <div className={`${styles.table_body_text}`}>{moment(authorizationHistory.createdOn).format("HH:mm")}</div>
      <div className={`${styles.table_body_text}`}>{formatDollarAmount(authorizationHistory.grossAmount / 100)}</div>
      <div className={`${styles.table_body_text}`}>{formatDollarAmount(authorizationHistory.netAmount / 100)}</div>
      <div className={`${styles.table_body_text}`}>{formatDollarAmount(authorizationHistory.irsAmount / 100)}</div>
      <div className={`${styles.table_body_text}`}>{formatDollarAmount(authorizationHistory.dorAmount / 100)}</div>
      <button
        className={`${styles.light_btn}`}
        onKeyPress={event => {
          if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
            history.push(`${annuitiesRoutePath}/time-payment-authorization-list?id=${authorizationHistory.id}`);
          }
        }}
        onClick={() => {
          history.push(`${annuitiesRoutePath}/time-payment-authorization-list?id=${authorizationHistory.id}`);
        }}
      >
        Payment List
      </button>
    </div>
  );
};
