import * as React from "react"
import { BackButtonUI } from "../../../CommonComponents/BackButtonComponent/back-button-ui"
import { PaymentDetailsTitleBar } from "../payment-details-title-bar/payment-details-title-bar"
import { GetPaymentDetailsResponse } from "../../../../api/payment-details-fetcher/api-types"
import "./styles.scss"
import { PaymentDetailsUI } from "../payment-details/payment-details-ui"
import { GenericErrorDialogComponent } from "../../../CommonComponents/GenericErrorDialogComponent"

const ROOT_CLASS = 'payment-details-page'

export type PaymentDetailsContainerUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
  voidPayment: (internalPaymentId: string) => void
  voidPaymentError: string | undefined
}

export const PaymentDetailsContainerUI = (
  props: PaymentDetailsContainerUIProps
) => {
  const [showErrorDialog, setShowErrorDialog] = React.useState<Boolean>(
    props.voidPaymentError ? true : false
  )

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={0} message="Back" />
      <PaymentDetailsTitleBar {...props} />
      <PaymentDetailsUI {...props} />
      {showErrorDialog && (
        <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog} message='Error Voiding Payment' />
      )}
    </div>
  )
}
