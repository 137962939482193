import * as React from 'react'
import {
  PrizeType,
  PrizeTypeDisplayByPrizeType,
  PromotionStatus,
} from '../../../../../../api/promotions-fetcher/constants'
import { formatDollarAmount } from '../../../../../../util'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PROMOTION_SIDEBAR_FORM_ROOT_CLASS } from '../helpers'
import { PromotionCampaignValidationPrizeTier } from '../validation'
import '../styles.scss'

type PromotionPrizeTierViewModeUIProps = {
  promotionStatus?: PromotionStatus
  canEditPromotionCampaigns: boolean
  openEditMode: () => void
  prizeTiers: PromotionCampaignValidationPrizeTier[]
  isBonus?: boolean
}

export const PromotionPrizeTiersViewModeUI = (
  props: PromotionPrizeTierViewModeUIProps
) => (
  <>
    {props.prizeTiers
      .sort((a, b) => Number(a.tierName) - Number(b.tierName))
      .map((prizeTier, index) => {
        return (
          <div key={`prize-tier-${index}`}>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-header'
              )}
            >
              {props.isBonus ? 'Bonus ' : ''}Prize Tier {prizeTier.tierName}
            </div>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Prize Type
              <span>
                {prizeTier.prizeType
                  ? PrizeTypeDisplayByPrizeType[prizeTier.prizeType]
                  : ''}
              </span>
            </div>
            {prizeTier.description &&
              prizeTier.prizeType !== PrizeType.ALTERNATE && (
                <div
                  className={getBlockClass(
                    PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                    'display-field'
                  )}
                >
                  Title
                  <span>{prizeTier.description}</span>
                </div>
              )}
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Quantity
              <span>{prizeTier.numWinners}</span>
            </div>
            {prizeTier.eventAllocationInDollars && (
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'display-field'
                )}
              >
                Event Allocation Amount
                <span>
                  {formatDollarAmount(prizeTier.eventAllocationInDollars)}
                </span>
              </div>
            )}
            {prizeTier.experienceValueInDollars && (
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'display-field'
                )}
              >
                Experience Prize Value
                <span>
                  {formatDollarAmount(prizeTier.experienceValueInDollars)}
                </span>
              </div>
            )}
            {Boolean(prizeTier.prizeValueInDollars) && (
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'display-field'
                )}
              >
                {prizeTier.prizeType === PrizeType.EXPERIENCE
                  ? 'Cash Prize Value'
                  : 'Prize Value'}
                <span>
                  {formatDollarAmount(prizeTier.prizeValueInDollars!)}
                </span>
              </div>
            )}
          </div>
        )
      })}
  </>
)
