import * as React from 'react'
import { formatCentsToDollars } from '../../../util'
import { OffsetsSystemAggregate } from '../../../pages/FinancePages/Offsets/OffsetsByACHPage/interfaces'
import './styles.scss'

const ROOT_CLASS = 'offsets-system-breakdown-table-row'

export type OffsetsSystemBreakdownUIProps = OffsetsSystemAggregate & {
  dateSubmitted: string // YYYY-MM-DD
  systemName: string
}

export const OffsetsSystemBreakdownTableRowUI = (props: OffsetsSystemBreakdownUIProps) => {
  return (
    <div className={`${ROOT_CLASS} offsets-system-breakdown-table-grid`}>
      <div>{ props.systemName } </div>
      <div>{ props.dateSubmitted }</div>
      <div>{ formatCentsToDollars(props.grandTotalInCents) } </div>
      <div>{ formatCentsToDollars(props.childServicesTotalInCents) }</div>
      <div>{ formatCentsToDollars(props.dorTotalInCents) }</div>
      <div>{ formatCentsToDollars(props.comptrollerTotalInCents) }</div>
    </div>
  )
}