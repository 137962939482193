import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { reportingRoutePath } from '../../../../config'
import '../styles.scss'

const ROOT_CLASS = 'reissue-history-title-bar'

export const ReissueHistoryTitleBarUI = (_props: {}) => {
  const history = useHistory()

  return (
    <div className={ROOT_CLASS}>
      <div className={`title`}>
        Re-Issue History
      </div>
      <button className='button blue' onClick={() => history.push(`${reportingRoutePath}/finance/reporting/check-run`)}>
        Check Run Reports
      </button>
    </div>
  )
}