import moment from "moment";
import {InputValidationMessages} from "../../../../../Constants/InputValidationMessages.js";

let formik: any;
let charitableGamingStore: any;
let setCurrentPage: any;
let setShowErrorDialog: any;
let setRequestCompleted: any;

export const setVariables = (formikObj: any, charitableGamingStoreObj: any, setCurrentPageObj: any,
                             setShowErrorDialogObj: any, setRequestCompletedObj: any) => {

    formik = formikObj;
    charitableGamingStore = charitableGamingStoreObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
    setRequestCompleted = setRequestCompletedObj;
}

export const setFieldValue = (e: any, formik: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === "warehouse") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    } else if (e.target.name === "stockNumber") {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else if (e.target.name === "serialNumber") {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else if (e.target.name === "orgName") {
        formik.setFieldValue(e.target.name, e.target.value, true);
    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    if (values.startOrderDate && values.endOrderDate) {
        const a = moment(values.startOrderDate);
        const b = moment(values.endOrderDate);
        if (b.diff(a, 'days') > 365) {
            Object.assign(errors, {startOrderDate: InputValidationMessages.InvalidDateRange});
            // Object.assign(errors, {endOrderDate: InputValidationMessages.InvalidDateRange});
        }
    }

    if (values.licenseNumber && values.licenseNumber.trim().length < 3) {
        Object.assign(errors, {licenseNumber: InputValidationMessages.InvalidLicenseNumberLength});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    // alert(JSON.stringify(values));
    setCurrentPage(0);
    charitableGamingStore.searchPullTabOrderItemHistory(values, setRequestCompleted);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            warehouse: '',
            startOrderDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            endOrderDate: moment().format('YYYY-MM-DD'),
            stockNumber: '',
            serialNumber: '',
            orgName: '',
            licenseNumber: ''
        }
    });

    formik.submitForm();

}
