import React from "react";
import {Switch, withRouter} from "react-router-dom";
import {NotFound404Page} from "../../CommonsPage/NotFound404Page";
import {ManualKYCPage} from "../ManualKYCPage";
import {PlayersSupportHubPage} from "../PlayersSupportHubPage";

import {UpdatePlayerEmailAndPhonePage} from "../UpdatePlayerEmailAndPhonePage";
import {MobileClaimsActivityPage} from "../MobileClaimsActivityPage";
import {SessionActivityPage} from "../SessionActivityPage";
import {UpdateProfilePage} from "../UpdateProfilePage";

import {PrivateRoute} from './../../../routes';
import {playerSupportRoutePath} from './../../../config.js';

import {checkRenderPermissions} from './../../../helpers.js';
import {permissions} from './../../../constants.js';
import TicketScanningActivityPage from "../TicketScanningActivityPage";
import {PlayerSearchGenericNewComponentPage} from "../PlayerSearchGenericNewComponentPage";


const PlayersSupportRoutes = ({props, storesObject}: any) => {

    const userpolicies: any = localStorage.getItem('userpolicies');

    return (
        <>

            <Switch>
                {checkRenderPermissions(permissions.CAN_SEE_PLAYER_SUPPORT_NEW, JSON.parse(userpolicies)) &&
                <PrivateRoute path={`${playerSupportRoutePath}/search-new`}
                              component={(props: any) => <PlayerSearchGenericNewComponentPage
                                  storesObject={storesObject}
                                  id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>}/>
                }

                {checkRenderPermissions(permissions.UPDATE_PLAYER, JSON.parse(userpolicies)) && <PrivateRoute
                    path={`${playerSupportRoutePath}/update-profile/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) => <UpdateProfilePage storesObject={storesObject}
                                                                  id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>}/>
                }
                <PrivateRoute
                    path={`${playerSupportRoutePath}/player-hub-new/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) => <PlayersSupportHubPage storesObject={storesObject}
                                                                      id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>}/>
                {checkRenderPermissions(permissions.CAN_SEE_CLAIM_ACTIVITY, JSON.parse(userpolicies)) && <PrivateRoute
                    path={`${playerSupportRoutePath}/mobile-claims-activity/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) =>
                        <MobileClaimsActivityPage storesObject={storesObject}
                                                  id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>}/>
                }
                {checkRenderPermissions(permissions.CAN_SEE_SESSION_ACTIVITY, JSON.parse(userpolicies)) && <PrivateRoute
                    path={`${playerSupportRoutePath}/session-activity/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) =>
                        <SessionActivityPage storesObject={storesObject}
                                             id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                    }/>
                }
                {checkRenderPermissions(permissions.SEE_PLAYER_VERIFICATION, JSON.parse(userpolicies)) && <PrivateRoute
                    path={`${playerSupportRoutePath}/manual-kyc/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) =>
                        <ManualKYCPage storesObject={storesObject}
                                       id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>
                    }/>
                }
                <PrivateRoute
                    path={`${playerSupportRoutePath}/update-email-and-phone/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) => <UpdatePlayerEmailAndPhonePage storesObject={storesObject}
                                                                              id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>}/>

                {checkRenderPermissions(permissions.CAN_SEE_TICKET_SCANNING_ACTIVITY, JSON.parse(userpolicies)) &&
                <PrivateRoute
                    path={`${playerSupportRoutePath}/ticket-scanning/${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`}
                    component={(props: any) => <TicketScanningActivityPage storesObject={storesObject}
                                                                           id={props.location.pathname.split('/')
                                                                               [props.location.pathname.split('/').length - 1]}/>}/>
                }
                <PrivateRoute path={`/`} component={(props: any) => <NotFound404Page/>}/>
            </Switch>
        </>


    );
}
export default withRouter(PlayersSupportRoutes)