import { useEffect } from 'react'
import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { Agent1099OpenSweepsResponse } from './api-types'

export const useFetchAgent1099OpenSweeps = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<Agent1099OpenSweepsResponse>()
  useEffect(() => {
    fetchUrl(`${config.SERVER_BASE_URL}/api/v1/agent1099Queue/sweeps/open`)
  }, [])

  return { isLoading, error, data }
}
