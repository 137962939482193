import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import {
  getPageData,
  normilizeDate,
} from "./functions";
import { playerSupportRoutePath } from './../../../config.js';
import { useHistory } from "react-router-dom";
import { KYCDocumentTypeQueueInterface } from "../../../pages/ActionsPages/KYCQUEUEPage/interface";
import { keyPressedType } from "../../../services/Commons";
import { PaginationCommonComponent } from './../../../components/CommonComponents/PaginationCommonComponent'
import { getPlayersDetailsData } from "../../../services/StoresObject/Player";


const array:any = [];
for(let i = 0; i < 105; i++)
{
  array .push({  createdAt: "2021-08-25T17:01:09.706Z",
  firstName: "PlayerNameCharacterCountTestAccount",
  lastName: "PlayerNameCharacterCountTest Account",
  playerId: "2474b81b-3086-4987-9084-866655f53501"})
}



export const KYCQueueTableComponent = ({ KYCPlayerData, storesObject }: any) => {

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const normilizePage = (totalPages:number, currentPage:number) =>{
    const number:number = totalPages - currentPage * 10;
    if(number > 10)
        return currentPage * 10 + 10;
    else
      return totalPages;

  } 
  // // KYCPlayerData = array;
  // const createPages = (KYCPlayerData: any[], currentPage: any, setCurrentPage: any) => {
  //   const array: any = [];
  //   const pagesTotal = Math.ceil(KYCPlayerData.length / 10);
     
  //   if (currentPage > 2 && pagesTotal > 5) {

  //     array.push(
  //       <>
  //         <div  
  //         tabIndex={0}
  //         role='button'  
  //         aria-pressed={ currentPage === 0 ? "true" : "false" } 
  //         style={{ backgroundColor: currentPage === 0 ? 'rgb(49, 109, 156)' : "" }}
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(0);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(0)} className={styles['KYCQUEUE-table-page-box']}>
  //           <div className={styles['KYCQUEUE-table-page-box-text']}>
  //             {1}
  //           </div>
  //         </div>
  //         <div>{'...'}</div>
  //       </>
  //     );
  //   }
  //   if (currentPage <= 2 || pagesTotal <= 5) {

  //     for (let i = 0; (i < (i < 5 ? pagesTotal : 5) && currentPage <= pagesTotal - 1); i++) {
  //       array.push(
  //         <div tabIndex={0} role='button' aria-pressed={ currentPage === i ? "true" : "false" }
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(i);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(i)} style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} 
  //         className={styles['KYCQUEUE-table-page-box']}>
  //           <div className={styles['KYCQUEUE-table-page-box-text']}>
  //             {i + 1}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   else if (currentPage > 2  && currentPage <= (pagesTotal - 1) - 2) {

  //     for (let i = currentPage - 2; (i < currentPage + 3 && currentPage <= pagesTotal - 1); i++) {
  //       array.push(
  //         <div 
  //         tabIndex={0} 
  //         role='button' 
  //         aria-pressed={ currentPage === i ? "true" : "false" } 
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(i);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(i)} style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} className={styles['KYCQUEUE-table-page-box']}>
  //           <div   className={styles['KYCQUEUE-table-page-box-text']}>
  //             {i + 1}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   else if ( currentPage > (pagesTotal - 1) - 2) {
  //     for (let i = (pagesTotal - 1) - 4; (i < pagesTotal); i++) {
  //       array.push(
  //         <div tabIndex={0} role='button' aria-pressed={ currentPage === i ? "true" : "false" } 
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(i);
  //           }
  //         }
  //         }   
  //         onClick={() => setCurrentPage(i)} 
  //         style={{ backgroundColor: currentPage === i ? 'rgb(49, 109, 156)' : "" }} 
  //         className={styles['KYCQUEUE-table-page-box']}>
  //           <div className={styles['KYCQUEUE-table-page-box-text']}>
  //             {i + 1}
  //           </div>
  //         </div>
  //       );
  //     }

  //   }
  //   if (pagesTotal > 5 && currentPage < (pagesTotal - 1) - 2) {
  //     array.push(
  //       <>
  //         <div>{'...'}</div>
  //         <div  tabIndex={0} role='button' aria-pressed={ currentPage === pagesTotal ? "true" : "false" } 
  //         style={{ backgroundColor: currentPage === pagesTotal ? 'rgb(49, 109, 156)' : "" }}
  //         onKeyPress={(e: any) => {
  //           if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
  //             setCurrentPage(pagesTotal - 1);
  //           }
  //         }
  //         }    
  //         onClick={() => setCurrentPage(pagesTotal - 1)} className={styles['KYCQUEUE-table-page-box']}>
  //           <div  className={styles['KYCQUEUE-table-page-box-text']}>
  //             {pagesTotal}
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }
  //   return array;


  // }



  return (
    <>
   
      <div className={styles['KYCQUEUE-table']}>
        <div className={styles['KYCQUEUE-table-data-header']}>
          <div aria-live="assertive" tabIndex={0} >{`Displaying ${ KYCPlayerData.length <= 0 ? 0 : (currentPage * 10) + 1}-${normilizePage(KYCPlayerData.length, currentPage)} of ${KYCPlayerData.length} results`}</div>

        </div>

        <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
          <div tabIndex={0}>Document TimeStamp</div>
          <div tabIndex={0}>First Name</div>
          <div tabIndex={0}>Last Name</div>
        </div>
        <div className={styles['KYCQUEUE-table-data-body']} >
          {KYCPlayerData.length > 0 && getPageData(KYCPlayerData, currentPage).map((e: KYCDocumentTypeQueueInterface, i: number) =>

            <div key={i} style={{ backgroundColor: (i % 2 === 0 ? '' : 'rgb(30, 75, 117)') }} className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{normilizeDate(e.createdAt)}</div>
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.firstName}</div>
              <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.lastName}</div>
              <div>
                <div tabIndex={0} role='button' aria-pressed={"false"}
                   onKeyPress={(ev: any) => {
                    if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                      storesObject.playerStore.selectedPlayerDetails = getPlayersDetailsData;
                      history.push(`${playerSupportRoutePath}/manual-kyc/${e.playerId}`)  
                    }
                  }
                  }    
                onClick={() => { 
                       storesObject.playerStore.selectedPlayerDetails = getPlayersDetailsData;
                       if(storesObject.playerStore.playerDocImages.length > 0)
                       {
                          if(storesObject.playerStore.playerDocImages[0].url === e.playerId)
                              storesObject.playerStore.playerDocImages = [];
                       }     
                       history.push(`${playerSupportRoutePath}/manual-kyc/${e.playerId}`)}
                    } className={styles['KYCQUEUE-table-review-kyc-button']}>
                  <div aria-label={'Review KYC button.'} className={styles['KYCQUEUE-table-review-kyc-button-text']}>
                    Review KYC
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles['KYCQUEUE-table-data-footer']}>

          <div aria-live="assertive" tabIndex={0} className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((KYCPlayerData.length / 10)).toString()}`}</div>
          { <PaginationCommonComponent playerData={KYCPlayerData} currentPage={currentPage} setCurrentPage={setCurrentPage} />}

        </div>
      </div>


    </>

  );
}

function updateButtonStates(arg0: string) {
  throw new Error("Function not implemented.");
}
