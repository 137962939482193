import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {getAnnualBeanoFormData, setVariables, submitAnnualBeanoForm} from "./functions";
import {CircularProgress} from "@material-ui/core";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {AnnualBeanoForm, AnnualBeanoSubmissionBody} from "@interaction-gaming/mslc-charitable-gaming-components";

import {taxformRoutePath} from "../../../../config.js";

export const AnnualBeanoTaxFormPage = inject("charitableGamingStore")(observer(({taxFormId, charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const [annualBeanoFormData, setAnnualBeanoFormData] = useState<any>();
        const [orgData, setOrgData] = useState<any>();

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
        const [notificationBanner, showNotificationBanner] = useState<any>({show: false, text: ''});

        useEffect(() => {
            getAnnualBeanoFormData(setAnnualBeanoFormData, setOrgData);
        }, []);

        setVariables(taxFormId, charitableGamingStore, history, setRequestCompleted, setShowErrorDialog);

        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['pulltab-stock-inventory-page-container']} ${styles['']}`}
                     onClick={() => {
                         // if (pageMessage.messageType !== "") setPageMessage({message: "", messageType: ""});
                         if (notificationBanner.text !== "") {
                             showNotificationBanner({show: false, text: ""});
                         }
                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['pulltab-stock-inventory-page-back-button-container']} ${styles['']}`}
                         onKeyDown={(ev: any) => {
                             if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                 history.push({
                                     pathname: `${taxformRoutePath}/manage`,
                                     state: {tabName: 'annualBeano'} // This is so the 'Annual Beano' Tab is selected when you go back
                                 });
                             }
                         }}
                         onClick={() => {
                             history.push({
                                 pathname: `${taxformRoutePath}/manage`,
                                 state: {tabName: 'annualBeano'} // This is so the 'Annual Beano' Tab is selected when you go back
                             });
                         }}
                    >
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['pulltab-stock-inventory-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                        >Back to Charitable Gaming
                        </div>
                    </div>

                    <div className={`${styles["license-details-page-tabs-container"]}`}>
                        {annualBeanoFormData ?
                            <AnnualBeanoForm
                                initialValues={annualBeanoFormData}
                                onSubmit={(formData: AnnualBeanoSubmissionBody) => {
                                    submitAnnualBeanoForm(formData, taxFormId);
                                }}
                                submitDisabled={isLoading}
                                year={annualBeanoFormData.year}
                                organizationDetails={orgData}
                                backOffice
                            /> : null}
                    </div>
                </div>
                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        type={NotificationType.Success}
                        text={notificationBanner.text}
                        handleHide={showNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);