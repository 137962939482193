export enum AccessibleKey {
  enter = 'Enter',
  space = ' ',
  upArrow = 'ArrowUp',
  leftArrow = 'ArrowLeft',
  downArrow = 'ArrowDown',
  rightArrow = 'ArrowRight',
  escape = 'Escape',
  tab = 'Tab',
}

export const isSelectionKey = (key: AccessibleKey) =>
  key === AccessibleKey.enter || key === AccessibleKey.space;
