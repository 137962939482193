import * as React from 'react'
import dropdown from '../../../icons/icon_drop_down_arrow.svg'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../ErrorMessageFieldCommonComponent'
import './styles.scss'

export type CustomDropdownOption = {
  key: string
  value: string | number | readonly string[] | undefined
  display: string
  disabled?: boolean
}

type CustomDropdownProps = {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: React.FocusEvent) => void
  options: CustomDropdownOption[]
  name: string
  id: string
  disabled?: boolean
  error?: string
  touched?: boolean
  value?: string | number
  greyOnDisabled?: boolean
  label?: string
}

const ROOT_CLASS = 'custom-dropdown'
const DROPDOWN_CLASS = getBlockClass(ROOT_CLASS, 'dropdown')

export const CustomDropdownField = (props: CustomDropdownProps) => (
  <div className={ROOT_CLASS}>
    {props.label && (
      <label className={getBlockClass(ROOT_CLASS, 'label')} htmlFor={props.id}>
        {props.label}
      </label>
    )}
    <select
      onChange={props.onChange}
      onBlur={props.onBlur}
      className={joinClasses([
        DROPDOWN_CLASS,
        getModifierClass(DROPDOWN_CLASS, 'disabled', props.disabled),
        getModifierClass(
          DROPDOWN_CLASS,
          'grey',
          props.disabled && Boolean(props.greyOnDisabled)
        ),
      ])}
      name={props.name}
      id={props.id}
      disabled={props.disabled}
      value={props.value}
      style={{
        backgroundImage: `url(${dropdown})`,
      }}
    >
      {props.options.map((option) => (
        <option
          key={option.key}
          value={option.value}
          disabled={option.disabled}
        >
          {option.display}
        </option>
      ))}
    </select>
    {props.error && props.touched && (
      <ErrorMessageFieldCommonComponent errorMessage={props.error} />
    )}
  </div>
)
