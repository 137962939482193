let charitableGamingStore: any;
let setCurrentPage: any;
let setShowErrorDialog: any;

export const setVariables = (charitableGamingStoreObj: any, setCurrentPageObj: any,
                             setShowErrorDialogObj: any) => {

    charitableGamingStore = charitableGamingStoreObj;
    setCurrentPage = setCurrentPageObj;
    setShowErrorDialog = setShowErrorDialogObj;
}

export const setFieldValue = (e: any, formik: any) => {
    if (e.target.name === 'stockNumber') {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        formik.setFieldValue('gameName', '', true);
    } else if (e.target.name === 'gameName') {
        formik.setFieldValue(e.target.name, e.target.value, true);
        formik.setFieldValue('stockNumber', '', true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    return errors;
}

export const onSubmit = async (values: any) => {
    setCurrentPage(0);
    charitableGamingStore.searchPullTabGames(values);
}

export const clearSearch = (formik: any) => {

    setCurrentPage(0);

    formik.resetForm({
        values: {
            stockNumber: '',
            gameName: ''
        }
    });

    resetFilteredPullTabGames();

}

export const getPageData = (pullTabGames: any[], page: number = 0) => {
    const array: any = [];
    let index = 0;
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(pullTabGames[i] === undefined)) {
            pullTabGames[i].index = index;
            index++;
            array.push(pullTabGames[i]);
        }
    }
    return array;
}

const resetFilteredPullTabGames = () => {
    charitableGamingStore.resetFilteredPullTabGames();
}

