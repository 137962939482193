import React from "react";
import {Switch, Route, useRouteMatch, Router, withRouter, useHistory} from "react-router-dom";

import {PrivateRoute, reportingRoutePath} from './../../../routes';
import {actionsRoutePath} from './../../../config.js';
import {KYCQUEUEPage} from "../KYCQUEUEPage";
import {NotFound404Page} from "../../CommonsPage/NotFound404Page";
import ActionsComponent from "../HomePage";
import {RejectedPaymentsQueuePage} from "../RejectedPaymentsQueuePage";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants";

const ActionsRoutes = ({props, storesObject}: any) => {

    const userpolicies: any = localStorage.getItem('userpolicies');


    return (
        <>

            <Switch>
                {checkRenderPermissions(permissions.CAN_SEE_PLAYER_DOCUMENT_QUEUE, JSON.parse(userpolicies)) ?
                    <PrivateRoute path={`${actionsRoutePath}/kyc-queue`}
                                  component={(props: any) =>
                                      <KYCQUEUEPage storesObject={storesObject}
                                                                           id={props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}/>}/> : null}
                {checkRenderPermissions(permissions.CAN_SEE_REJECT_CLAIMS, JSON.parse(userpolicies)) ?
                    <PrivateRoute path={`${actionsRoutePath}/rejected-payments-queue`}
                                  component={(props: any) => <RejectedPaymentsQueuePage
                                      storesObject={storesObject}/>}/> : null}
                <PrivateRoute path={`${actionsRoutePath}/home`}
                              component={(props: any) => <ActionsComponent title="Actions" {...props}
                                                                           showBackButton={true}/>}/>
                <PrivateRoute path={`/`} component={(props: any) => <NotFound404Page/>}/>

            </Switch>


        </>

    );
}
export default withRouter(ActionsRoutes)