import {InputValidationMessages} from "../../../../../Constants/InputValidationMessages.js";

let licenseNumber: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === 'stockNumber' || e.target.name === 'unitsPerCase') {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else if (e.target.name === 'gameName') {
        formik.setFieldValue(e.target.name, e.target.value, true);
    } else {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    }

}

export const validate = (values: any) => {

    const errors = {};

    if (!values.stockNumber) {
        Object.assign(errors, {stockNumber: InputValidationMessages.RequiredField});
    }

    if (!values.gameName) {
        Object.assign(errors, {gameName: InputValidationMessages.RequiredField});
    }

    if (!values.unitPriceInDollars) {
        Object.assign(errors, {unitPriceInDollars: InputValidationMessages.RequiredField});
    }

    if (!values.unitsPerCase) {
        Object.assign(errors, {unitsPerCase: InputValidationMessages.RequiredField});
    }

    if (!values.casePriceInDollars) {
        Object.assign(errors, {casePriceInDollars: InputValidationMessages.RequiredField});
    }

    if (!values.unitWeight) {
        Object.assign(errors, {unitWeight: InputValidationMessages.RequiredField});
    }

    if (!values.caseWeight) {
        Object.assign(errors, {caseWeight: InputValidationMessages.RequiredField});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updatePullTabGame(values, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {
    history.goBack();
}



