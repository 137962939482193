import React from "react";
import { Switch , withRouter } from "react-router-dom";
import { PrivateRoute } from './../../../routes';
import RoleManagementComponent from './../../../roles';
import UserManagementComponent from "./../../../users";
import AdministrationButtons from './../AdministrationButtons';
import { administrationRoutePath } from './../../../config.js';
import ActionAuditComponent from "./../../..//users/ActionAudit";
import SettingsHubComponent from "./../../../Admin/Settings/Hub";
import {FrequentCasherModulePage} from "../FrequentCasherModulePage";
import {Forbidden409Page} from "../../CommonsPage/Forbidden409Page";
import {permissions} from "../../../constants";
import {checkRenderPermissions} from "../../../helpers";

 const AdministrationsRoutesPage = ({ props, storesObject }: any) => {

       const playerId:string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 2]}`;
       const claimsId:string = `${props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]}`;
       const urlVariables:string =  `${playerId}/${claimsId}`;
       const userPolicies: any = localStorage.getItem("userpolicies");

     return (
        <>            
            <Switch>

                <PrivateRoute path={`${administrationRoutePath}/admin/settings`} component={(props:any) => <SettingsHubComponent title="Settings" {...props} showBackButton={true} />} />
                <PrivateRoute path={`${administrationRoutePath}/user/audit/:id`} component={(props:any) => <ActionAuditComponent {...props} showBackButton={true} />} />
                <PrivateRoute path={`${administrationRoutePath}/rolemanagement`} component={(props:any) => <RoleManagementComponent title="Role Management" {...props} showBackButton={true} />} />
                <PrivateRoute path={`${administrationRoutePath}/usermanagement`} component={(props:any) => <UserManagementComponent title="User Management" {...props} showBackButton={true} />  } />
                <PrivateRoute path={`${administrationRoutePath}/frequent-casher`} component={(props: any) =>
                    checkRenderPermissions(permissions.CAN_ACCESS_FREQUENT_CASHER_MODULE, JSON.parse(userPolicies)) ?
                        <FrequentCasherModulePage/> : <Forbidden409Page/>
                }/>
                <PrivateRoute path={`${administrationRoutePath}`} component={(props:any) => <AdministrationButtons title="Administration" {...props} showBackButton={true} />} />
                    
            </Switch>
       </>

    );
}


export  default withRouter( AdministrationsRoutesPage ) 