import React from 'react'
import { PreviewReportProps } from '../../../api/check-run-reporting-fetcher/api-types'
import { CheckRunReportingPageUI } from './check-run-reporting-page-ui'
import {
  useFetchCheckRunReports,
  useFetchPreviewReport,
} from './get-fetch-check-run-reports'

export const CheckRunReportingPageContainer = () => {
  const {
    fetchCheckRunReportsIsLoading,
    fetchCheckRunReportsError,
    fetchCheckRunReportsData,
    fetchCheckRun,
    preSignedUrlHandler,
  } = useFetchCheckRunReports()

  const { fetchReportIsLoading, fetchReportError, fetchPreviewReport } =
    useFetchPreviewReport()

  const generateAndRefreshReports = (props: PreviewReportProps) => {
    fetchPreviewReport(props).then(() => fetchCheckRun())
  }
  return (
    <div>
      <CheckRunReportingPageUI
        isLoading={fetchCheckRunReportsIsLoading || fetchReportIsLoading}
        error={fetchCheckRunReportsError ?? fetchReportError}
        checkRunReportingData={fetchCheckRunReportsData}
        generateHandler={generateAndRefreshReports}
        downloadHandler={preSignedUrlHandler}
      />
    </div>
  )
}
