import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import styles from "./css/styles.module.css";
import { keyPressedType } from "../../../../services/Commons";
import { annuitiesRoutePath } from "../../../../config";
import { FirstPaymentDetails } from "../../../../components/AnnuitiesComponents/FirstPayment/FirstPaymentDetailsCmpt";
import { KEY_CODES } from "../../../../constants.js";

export const FirstPaymentDetailPage = inject("annuitiesStore")(
  observer(({ annuitiesStore }: any) => {
    const history = useHistory();
    const [annuityBtnConfirm, setAnnuityBtnConfirm] = useState<Boolean>(false);

    // package required to focus modal accessibility
    const FocusTrap = require("focus-trap-react");
    const firstPaymentId = window.location.href.split("/")[window.location.href.split("/").length - 1];

    return (
      <>
        <div className={styles["page-container"]}>
          {/* Pop Up Modal for Annuity Creation */}
          {annuityBtnConfirm && (
            <FocusTrap focusTrapOptions={{ initialFocus: false }}>
              <div className={styles["overlay-container"]}>
                <div className={styles["main-modal"]}>
                  <div>
                    <div className={styles["modal-title"]}>Attention!</div>
                    <div className={styles["modal-desc"]}>
                      During the annuity set up, you will not be able to save and exit. Please be sure to have all of the relevant information and documents ready to go before you begin.
                    </div>
                  </div>
                  <button
                    type="button"
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        annuitiesStore.setFirstPaymentID(firstPaymentId);
                        history.push(`${annuitiesRoutePath}/annuity-creation-flow`);
                      }
                    }}
                    onClick={() => {
                      annuitiesStore.setFirstPaymentID(firstPaymentId);
                      history.push(`${annuitiesRoutePath}/annuity-creation-flow`);
                    }}
                    className={styles["modal-confirm-btn"]}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        annuitiesStore.setFirstPaymentID("");
                        setAnnuityBtnConfirm(!annuityBtnConfirm);
                      }
                    }}
                    onClick={() => {
                      annuitiesStore.setFirstPaymentID("");
                      setAnnuityBtnConfirm(!annuityBtnConfirm);
                    }}
                    className={styles["modal-cancel-btn"]}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </FocusTrap>
          )}
          {/* back btn jsx */}
          <div className={styles["back-btn-container"]}>
            <button
              onKeyPress={event => {
                if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                  history.go(-1);
                }
              }}
              onClick={() => {
                history.go(-1);
              }}
              className={styles["back-btn-inner-container"]}
            >
              <FaAngleLeft title="left arrow icon" className={styles["back-btn-custom"]} />
              <div className={styles["back-btn-text"]}>Back to First Payment List</div>
            </button>
          </div>
          {/* Header component jsx */}
          <div className={styles["cmpnt-container"]}>
            <div className={styles["title-header"]}>
              <div className={styles["flex-space-bw"]}>
                <div className={styles["title-text"]}>First Payment Details</div>
                <div>
                  <button
                    type="button"
                    className={styles["annuity-btn"]}
                    onKeyPress={event => {
                      if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                        setAnnuityBtnConfirm(!annuityBtnConfirm);
                      }
                    }}
                    onClick={() => {
                      setAnnuityBtnConfirm(!annuityBtnConfirm);
                    }}
                  >
                    Begin Annuity Creation
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Main component */}
          <div className={styles["addtnl-cmpt-container"]}>
            <FirstPaymentDetails />
          </div>
        </div>
      </>
    );
  })
);
