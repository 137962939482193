import config from "./../../../config";

import axios from 'axios'
const data = require("./__test__/test_data.json");

export const makeHttpCall = async (options) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Content-Type": "application/json",
  };
  Object.assign(options, { headers: headers });

  try {
    let response = await axios(options);
    return response.data;
  } catch (e) {
    throw new Error("makeHttpCall____________" + e.message);
  }
};
