export const ClaimCenterLocations = [
  'Braintree Regional Office',
  'Boston',
  'Dorchester',
  'Fairhaven',
  'West Springfield Retail Center',
  'Worcester Regional Office',
  'Woburn',
  'Raynham',
  'Finance',
  'NAT',
] as const
