import React, {useState} from "react";
import styles from './css/styles.module.css';
import {getPageData,} from "./functions";
import {useHistory} from "react-router-dom";
import {PaginationCommonComponent} from '../../CommonComponents/PaginationCommonComponent'
import {ACHActivitySearchResultInterface} from "./interface";
import moment from "moment-timezone";
import {keyPressedType} from "../../../services/Commons";
import {reportingRoutePath} from "../../../config";
import {checkRenderPermissions} from './../../../helpers.js';
import {permissions} from './../../../constants.js';
import {normilizeAmount} from "../../PlayersComponents/PlayerTicketScanningActivityTableComponent/functions";


export const ACHActivitySearchTableComponent = ({ACHActivitySearchResult, currentPage, setCurrentPage}: any) => {

    const userpolicies: any = localStorage.getItem('userpolicies');

    const history: any = useHistory();
    const [showError, setShowError] = useState({show: false, rowNumber: 0});
    const normilizePage = (totalPages: number, currentPage: number) => {
        const number: number = totalPages - currentPage * 10;
        if (number > 10)
            return currentPage * 10 + 10;
        else
            return totalPages;

    }

    const parseType = (type: any) => {
        if (type === 'NEW') {
            return 'Player Payment by ACH';
        } else if (type === 'TAX') {
            return 'Taxes to DOR by ACH';
        } else if (type === 'RESUBMIT') {
            return 'Payment Retries by ACH'
        } else if (type === 'PAPER CHECK') {
            return 'Paper Check'
        } else if (type === 'OFFSETS') {
            return 'Offsets'
        } else {
            return 'Reject'
        }
    }

    return (
        <>

            <div className={styles['KYCQUEUE-table']}>
                <div className={styles['KYCQUEUE-table-data-header']}>
                    <div aria-live="assertive"
                         tabIndex={0}>{`Displaying ${ACHActivitySearchResult.length <= 0 ? 0 : (currentPage * 10) + 1}-${normilizePage(ACHActivitySearchResult.length, currentPage)} of ${ACHActivitySearchResult.length} results`}</div>

                </div>

                <div className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-label']}`}>
                    <div tabIndex={0}>Date Submitted</div>
                    <div tabIndex={0}>Date Received</div>
                    <div tabIndex={0}>Time</div>
                    <div tabIndex={0}>Batch #</div>
                    <div tabIndex={0}>Reject Ref #</div>
                    <div tabIndex={0}>Paper Check File #</div>
                    <div tabIndex={0}>Number of Records</div>
                    <div tabIndex={0}>Amount</div>
                    <div tabIndex={0}>Status</div>
                    <div tabIndex={0}>Type</div>
                    <div tabIndex={0}></div>
                </div>
                <div className={styles['KYCQUEUE-table-data-body']}>
                    {
                        // console.log('KYCPlayerData', KYCPlayerData)
                    }
                    {ACHActivitySearchResult.length > 0 && getPageData(ACHActivitySearchResult, currentPage).map((e: ACHActivitySearchResultInterface, i: number) =>

                        <div key={i} style={{backgroundColor: (i % 2 === 0 ? 'rgb(20, 52, 85)' : 'rgb(30, 75, 117)')}}
                             className={`${styles['KYCQUEUE-table-data-grid']} ${styles['KYCQUEUE-table-data-data']}`}>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{(e.batchnumber || e.papercheckfilenumber) ? moment.tz(e.filesubmissiondatetime, "America/New_York").format('MM-DD-YYYY') : ''}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.rejectreferencenumber ? moment.tz(e.recievetimestamp, "America/New_York").format('MM-DD-YYYY') : ''}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{moment.tz(((e.batchnumber || e.papercheckfilenumber) ? e.filesubmissiondatetime : e.recievetimestamp), "America/New_York").format('HH:mm')}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.batchnumber}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.rejectreferencenumber}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.papercheckfilenumber}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{e.totalnumberofrecords}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{normilizeAmount(e.amount)}</div>
                            <div tabIndex={0} className={styles['KYCQUEUE-table-data-data-text']}>{e.status}</div>
                            <div tabIndex={0}
                                 className={styles['KYCQUEUE-table-data-data-text']}>{parseType(e.type)}</div>

                            {checkRenderPermissions(permissions.CAN_SEE_BATCH_REJECT_FILE_DETAILS, JSON.parse(userpolicies)) &&
                            <div tabIndex={0} role='button' aria-pressed={"false"}
                                 onKeyPress={(ev: any) => {
                                     if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                         
                                         if (e.batchnumber && e.type !== "TAX" && e.type !== "OFFSETS") {
                                             history.push(`${reportingRoutePath}/finance/claims/batch/details/${e.batchnumber}`);
                                         } else if (e.batchnumber && e.type === "OFFSETS") {
                                             history.push({
                                                 pathname: `${reportingRoutePath}/finance/offsets/reporting/ach/${e.batchnumber}`,
                                                 state: {
                                                     backButtonText: 'Back to ACH Activity'
                                                 },
                                             });
                                         } else if (e.rejectreferencenumber) {
                                             history.push(`${reportingRoutePath}/finance/claims/reject-file/details/${e.rejectreferencenumber}`);
                                         } else if (e.papercheckfilenumber) {
                                             history.push(`${reportingRoutePath}/finance/claims/papercheck/details/${e.papercheckfilenumber}`);
                                         }else{
                                            history.push(`${reportingRoutePath}/finance/claims/tax-file/details/${e.batchnumber}`);
                                         } 
                                     }
                                 }
                                 }
                                 onClick={() => {
                                     if (e.batchnumber && e.type !== "TAX" && e.type !== "OFFSETS") {
                                         history.push(`${reportingRoutePath}/finance/claims/batch/details/${e.batchnumber}`)
                                     } else if (e.batchnumber && e.type === "OFFSETS") {
                                         history.push({
                                             pathname: `${reportingRoutePath}/finance/offsets/reporting/ach/${e.batchnumber}`,
                                             state: {
                                                 backButtonText: 'Back to ACH Activity'
                                             },
                                         });
                                     }  else if (e.rejectreferencenumber) {
                                         history.push(`${reportingRoutePath}/finance/claims/reject-file/details/${e.rejectreferencenumber}`);
                                     } else if (e.papercheckfilenumber) {
                                         history.push(`${reportingRoutePath}/finance/claims/papercheck/details/${e.papercheckfilenumber}`);
                                     }else{
                                          history.push(`${reportingRoutePath}/finance/claims/tax-file/details/${e.batchnumber}`);
                                     }
                                 }
                                 } className={styles['KYCQUEUE-table-review-kyc-button']}>
                                <div aria-label={'Review KYC button.'}
                                     className={styles['KYCQUEUE-table-review-kyc-button-text']}>
                                    Show Details
                                </div>
                            </div>
                            }
                        </div>
                    )}
                </div>
                <div className={styles['KYCQUEUE-table-data-footer']}>

                    <div aria-live="assertive" tabIndex={0}
                         className={styles['KYCQUEUE-table-page-label']}>{`Page ${currentPage + 1} of ${Math.ceil((ACHActivitySearchResult.length / 10)).toString()}`}</div>
                    {<PaginationCommonComponent playerData={ACHActivitySearchResult} currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}/>}

                </div>
            </div>


        </>

    );
}
