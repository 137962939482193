import React from "react";
import styles from "./css/styles.module.css";
import { keyPressedType } from "../../../../services/Commons";
import { KEY_CODES } from "../../../../constants.js";
import { Payee } from "../../../../types/Annuities/PayeeManagement/Payee";

export const SinglePayeeResult: React.FC<{
  payee: Payee;
  setSelectedPayee: (id: string, payeeType: "Individual" | "Entity" | "") => void;
  cancelPayeeSelectionFlow: () => void;
  createPayeeSelected: (isFromPayeeResults: boolean) => void;
}> = ({ payee, setSelectedPayee, cancelPayeeSelectionFlow, createPayeeSelected }) => {
  return (
    <div className={styles["overlay-container"]}>
      <div className={styles["main-modal"]}>
        <div>
          <div className={styles["modal-title"]}>Existing Payee Results</div>
          <div className={styles["payee_result"]}>
            <h5>Primary Info</h5>
            <div className={styles["payee_result_primary_info"]}>
              <div className={styles["grid_col_2"]}>
                {payee.payeeType === "Individual" ? (
                  <>
                    <div className={styles["flex_row"]}>
                      <div>First Name</div>
                      <div>{payee.firstName}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Middle Initial</div>
                      <div>{payee.middleInitial}</div>
                    </div>
                    <div className={styles["flex_row"]}>
                      <div>Last Name</div>
                      <div>{payee.lastName}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles["flex_row"]}>
                      <div>Freeform Name</div>
                      <div>{payee.freeformName}</div>
                    </div>
                  </>
                )}
                <div className={styles["flex_row"]}>
                  <div>TIN</div>
                  <div>{payee.tin}</div>
                </div>
              </div>
            </div>
            <h5>Demographic &amp; Contact Info</h5>
            <div className={styles["payee_result_demo_info"]}>
              <div className={styles["grid_col_2"]}>
                <div className={styles["flex_row"]}>
                  <div>Mailing Address 1</div>
                  <div>{payee.address1}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>Mailing Address 2</div>
                  <div>{payee.address2}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>City</div>
                  <div>{payee.city}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>State/Region</div>
                  <div>{payee.state}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>Zip/Postal Code</div>
                  <div>{payee.zip}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>Country</div>
                  <div>{payee.country}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>Phone</div>
                  <div>{payee.phone}</div>
                </div>
                <div className={styles["flex_row"]}>
                  <div>Email</div>
                  <div>{payee.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          onKeyPress={event => {
            if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
              setSelectedPayee(payee.id, payee.payeeType);
            }
          }}
          onClick={() => {
            setSelectedPayee(payee.id, payee.payeeType);
          }}
          className={styles["modal-confirm-btn"]}
        >
          Confirm
        </button>
        <button
          onKeyPress={async event => {
            if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
              await createPayeeSelected(true);
            }
          }}
          onClick={async () => {
            await createPayeeSelected(true);
          }}
          className={styles["modal-confirm-btn"]}
        >
          Create New Payee
        </button>
        <button
          type="button"
          onKeyPress={event => {
            if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
              cancelPayeeSelectionFlow();
            }
          }}
          onClick={() => {
            cancelPayeeSelectionFlow();
          }}
          className={styles["modal-cancel-btn"]}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
