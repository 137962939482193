import * as React from 'react'

type OpenQuestionCircleProps = {
  altText?: string
}

export const OpenQuestionCircleIcon = (props: OpenQuestionCircleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <title>{props.altText ?? 'Open Question Circle Icon'}</title>
    <path
      d="M6.96265 1.01078C10.0089 1.2757 12.2591 3.96294 11.9908 7.01078C11.7225 10.0587 9.03719 12.3166 5.99086 12.0517C4.59232 11.9301 3.32031 11.2914 2.39216 10.2763C2.18508 10.0498 2.20192 9.69931 2.42977 9.49347C2.65763 9.28763 3.01022 9.30436 3.2173 9.53085C3.9588 10.3418 4.97196 10.8506 6.08804 10.9476C8.51893 11.159 10.6653 9.35428 10.8801 6.91418C11.0948 4.47415 9.29631 2.32627 6.86547 2.11488C4.4346 1.90348 2.28811 3.70831 2.07335 6.14832C2.04055 6.52098 2.05427 6.89121 2.11299 7.25317C2.162 7.55532 1.95532 7.83976 1.65135 7.88848C1.34738 7.9372 1.06123 7.73176 1.01221 7.42961C0.93888 6.97758 0.921761 6.51564 0.962594 6.05172C1.23085 3.0039 3.91634 0.745864 6.96265 1.01078Z"
      fill="#0F1821"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0085 7.13819C6.95792 7.26386 6.93264 7.43649 6.93264 7.65608H5.77082C5.77348 7.28568 5.82871 7.0145 5.93651 6.84253C6.04431 6.67056 6.25924 6.46155 6.5813 6.21549C6.77028 6.06204 6.92133 5.89669 7.03445 5.71942C7.14757 5.54216 7.20413 5.34373 7.20413 5.12414C7.20413 4.88073 7.13958 4.69024 7.01049 4.55267C6.8814 4.41509 6.69708 4.3463 6.45753 4.3463C6.26057 4.3463 6.09355 4.40451 5.95647 4.52092C5.8194 4.63733 5.74953 4.80798 5.74686 5.03286H4.61299L4.60501 5.00905C4.59702 4.50108 4.76537 4.11216 5.11006 3.84229C5.45475 3.57243 5.9039 3.4375 6.45753 3.4375C7.05375 3.4375 7.52153 3.58764 7.8609 3.88793C8.20026 4.18822 8.36994 4.59632 8.36994 5.11223C8.36994 5.44824 8.27079 5.75911 8.0725 6.04485C7.87421 6.33058 7.626 6.57002 7.3279 6.76316C7.16554 6.88751 7.05907 7.01251 7.0085 7.13819ZM6.93869 8.30469V9.29683H5.76489V8.30469H6.93869Z"
      fill="#0F1821"
    />
  </svg>
)
