import React from 'react';
import styles from './css/styles.module.css';
import stylesPopUp from './css/styles_popup_box.module.css';
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {makeHttpCall} from './functions'
import { faTrash, faCaretDown, faCaretUp, faPlusCircle, faMinusCircle, faReply, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { keyPressedType } from './../../../services/Commons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkRenderPermissions } from './../../../helpers.js'
import { permissions } from './../../../constants.js';
import config from './../../../config';
import { type } from 'os';
import * as _ from 'lodash'

let pass = false;
const valuesOfDocumentType = [];
let  pdfButtonPressed = 0;
@inject('playerStore', 'claimStore')
@observer
class MobileClaimDocumentsComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            documentData: [],
            toggle: [],
            zoom: [],
            rotation: [],
            index: 0,
            playerId: props.playerId,
            documentTypeId: [],
            isData: false,
            setDocumentType: undefined,
            popupRadioButtonResult: undefined,
            count: 0,
            eventListenerAdded: undefined,
            componentName: props.name,
            playersCurrentImage: [],
            refreshDataForImages: 0,
            showToast: false,
            showToastResponse: "",
            refreshStatus: false,
            rotationPopUp: 0,
            zoomPopUp: 100,
            imageActions: { rotatePressed: false, zoomIn: false, zoomOut: false },
            actionShowReviewButton: [],
            setFocusOnPopup: true,
            userpolicies:localStorage.getItem('userpolicies'),
           
        }
        pass = false;       
       
    }

    setImageActionsState(e) {
        Object.keys(this.state.imageActions)
            .forEach((key) => {
                if (key === e)
                    {this.state.imageActions[key] = true;}
                else
                    {this.state.imageActions[key] = false;}

            })
        return this.state.imageActions;

    }

    componentDidMount() {

    }

    componentDidUpdate() {
        
        
        if( this.props.mobileClaimDetailsPage.claimReferenceNumber.claim.eSignatureUrl !== "" && pass === false)
        {
            this.populateFields();
            pass = true;
        }
        
        if (this.state.setFocusOnPopup && this.state.playersCurrentImage[this.state.index] !== undefined && window.document.getElementById("document-image-pop-up-container") !== null && !this.state.toggle[this.state.index] && valuesOfDocumentType.find(e => e === this.state.documentData[this.state.index].documentType) === undefined) {
            this.state.setFocusOnPopup = false;
            window.document.getElementById("document-image-pop-up-container").focus();
        }
    }

    triggerSetState = (data) => {
        this.setState(data);        
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    testIfImageIsAvailable = async () => {
        if (this.props.requestPerformed !== false) {return;}

        if (this.state.playersCurrentImage[this.state.index] === "" && this.state.documentData[this.state.index].documentType === undefined)
            {return;}

        const options =
        {
            method: 'put',
            url: config.SERVER_BASE_URL + '/v1/player/testplayerimageavailable',
            data: {
                imageUrl: this.state.documentData[this.state.index].url,
                documentType: this.state.documentData[this.state.index].documentType === undefined ? "No Document type assigned" : this.state.documentData[this.state.index].documentType,
            }
        }

        
        try {
            const response = await makeHttpCall(options);
            if (this.props.pageMessage.messageType === "ERROR")
                {this.props.setPageMessage({ message: "", messageType: "" });}
            this.state.playersCurrentImage[this.state.index] = "";

            if (response.statusCode === 200) {
                this.state.playersCurrentImage[this.state.index] = options.data.imageUrl;
                this.setState({ playersCurrentImage: this.state.playersCurrentImage, refreshDataForImages: 0, refreshStatus: false });
                return;
            }
            else if (response.statusCode === 403) {


                if (this.state.refreshDataForImages < 5 && response.refreshStatus === true) {

                    this.state.playersCurrentImage[this.state.index] = undefined;
                    this.state.refreshDataForImages++;
                    this.state.refreshStatus = true;
                    await this.populateFields();
                    return;
                }
            }

            this.state.showToastResponse = `${response.statusCode} ${response.statusMessage}`;
            this.setState({ playersCurrentImage: this.state.playersCurrentImage, showToast: true, refreshDataForImages: 0, showToastResponse: this.state.showToastResponse, refreshStatus: false });

        } catch (e) {
            this.props.setRequestPerformed(false);
            this.setState({ playersCurrentImage: this.state.playersCurrentImage });
            this.props.setPageMessage({ message: "Something went wrong. Please try again.", messageType: "ERROR" });

        }

    }


    populateFields = () => {

       
        return new Promise((resolve, reject) => {
            console.log('this.props.mobileClaimDetailsPage......',this.props.mobileClaimDetailsPage.claimReferenceNumber.claim)
            let data = [];
            data.push(
                {
                    url: `${this.props.mobileClaimDetailsPage.claimReferenceNumber.claim.eSignatureUrl}`,
                    uploadId: "e99bb64b-618c-4d7e-a905-b4ad38154396",
                    documentType: "E-Signature",
                    fileType: "PNG"
                },
                {
                    url: `${this.props.mobileClaimDetailsPage.w2g}`,
                    uploadId: "e99bb64b-618c-4d7e-a905-b4ad38154396",
                    documentType: "W-2G",
                    fileType: "PDF"
                }
            )
            
            this.state.documentData = [];
            this.state.toggle = [];
            this.state.documentTypeId = [];
            this.state.playersCurrentImage = [];
            this.state.zoom = [];
            this.state.rotation = [];
            this.state.actionShowReviewButton = [];
            if (data !== undefined && data !== null) {
                data.forEach((element, i) => {
                    this.state.documentData.push(element);
                    this.state.toggle.push(true);
                    this.state.playersCurrentImage.push(undefined);
                    this.state.zoom.push(100);
                    this.state.rotation.push(0);
                    this.state.actionShowReviewButton.push(false);
                });
                // this.state.playersCurrentImage[this.state.index] = playersCurrentImage;
                this.state.documentTypeId.push('W-2G');
                valuesOfDocumentType.push('W-2G');
                this.state.documentTypeId.push('E-Signature');
                valuesOfDocumentType.push('E-Signature');
                this.setState(
                    {

                        documentData: this.state.documentData,
                        documentTypeId: this.state.documentTypeId,
                        isData: true,
                        playersCurrentImage: this.state.playersCurrentImage,
                        refreshStatus: this.state.refreshStatus,
                        count: 0
                    }
                );

            }

            resolve(true);
        });

    }
    deleteImage = async (uploadId) => {

       
        if (uploadId === "") {
            this.state.showToastResponse = `Upload id is empty`;
            this.setState({ showToast: true, showToastResponse: this.state.showToastResponse });
            return;
        }
        const options =
        {
            method: 'delete',
            url: config.SERVER_BASE_URL + '/v1/player/deletedocument/' + uploadId,
            data: {
                imageUrl: this.state.documentData[this.state.index].url,
                documentType: this.state.documentData[this.state.index].documentType === undefined ? "No Document type assigned" : this.state.documentData[this.state.index].documentType,
            }
        }

        try {
            const response = await makeHttpCall(options);
            if (response.statusCode === 200) {

                this.state.playersCurrentImage = [];
                this.state.documentData = this.state.documentData.filter(element => {
                    if (element.uploadId !== uploadId) {
                        this.state.playersCurrentImage.push(element.url);
                        return element;
                    }
                })
                const data = {
                    documentData: this.state.documentData === undefined ? [] : this.state.documentData,
                    index: --this.state.index <= 0 ? 0 : this.state.index,
                    imageType: [],
                    playersCurrentImage: this.state.playersCurrentImage
                }
                this.setState(data);
            }

            this.props.setPageMessage({ message: response.message, messageType: "SUCCESS" });
            document.scrollingElement.scrollTo(0, 0);

        } catch (e) {
            document.scrollingElement.scrollTo(0, 0);
            this.props.setRequestPerformed(false);
            this.props.setPageMessage({ message: "Something went wrong. Please try again.", messageType: "ERROR" });
        }

    }
    getDocumentType = async (documentType, uploadId) => {
       
        const ev = documentType;
        const options =
        {
            method: 'put',
            url: config.SERVER_BASE_URL + '/v1/player/putdocument/' + uploadId,
            data: {
                type: ev
            }
        }

        try {
            const response = await makeHttpCall(options);

            if (response.statusCode === 200) {
                const data = {
                    documentData: this.state.documentData.map(element => {
                        if (element.uploadId === uploadId)
                            {element.documentType = ev;}
                        return element;
                    })
                }
                this.setState(data);
            }
            this.props.setPageMessage({ message: response.message, messageType: "SUCCESS" });
            document.scrollingElement.scrollTo(0, 0);

        }
        catch (e) {
            document.scrollingElement.scrollTo(0, 0);
            this.props.setRequestPerformed(false);
            this.props.setPageMessage({ message: "Something went wrong. Please try again.", messageType: "ERROR" });
        }

    }

    getpopUpCheckButton(event) {
        let value = false;

        if (event.target.value === 'document_type_approved')
            {value = true;}

        this.setState({ popupRadioButtonResult: value });

    }

    setDocumentTypeFromPopUp(event) {
        this.setState({ setDocumentType: event.target.value });
    }

    getMonth(val) {
        switch (val) {
            case '01': return 'Jan'
            case '02': return 'Feb'
            case '03': return 'Mar'
            case '04': return 'Apr'
            case '05': return 'May'
            case '06': return 'Jun'
            case '07': return 'Jul'
            case '08': return 'Aug'
            case '09': return "Sep"
            case '10': return 'Oct'
            case '11': return 'Nov'
            case '12': return 'Dec'
            default: return "undefined"
        }
    }

    setDateFormat(value) {
        let date = value.split('T')[0];
        date = date.split('-');
        let year = date[0]
        return `${this.getMonth(date[1])} ${date[2]}, ${year}`;

    }
    popupActionCancel(i) {
        this.setState({
            setDocumentType: undefined,
            popupRadioButtonResult: undefined,
            toggle: this.setToggle(i)

        });
    }
    popupActionSave(i) {
        if (this.state.popupRadioButtonResult === undefined) {
            this.props.setPageMessage({ message: "Please Select an option", messageType: "ERROR" });
            return;
        }
        if (valuesOfDocumentType.find(e => e === this.state.setDocumentType) === undefined && this.state.popupRadioButtonResult === true) {
            this.props.setPageMessage({ message: "Please Select a Document Type", messageType: "ERROR" });
            return;
        }

        if (this.state.popupRadioButtonResult === true)
            {this.getDocumentType(this.state.setDocumentType, this.state.documentData[this.state.index].uploadId);}
        else
            {this.deleteImage(this.state.documentData[this.state.index].uploadId);}


        this.setState({ toggle: this.setToggle(i), documentData: this.state.documentData.slice() })
    }
    getDocumentTypeId(i) {
        try {
           
            let index = valuesOfDocumentType.indexOf(this.state.documentData[i].documentType);
            return index === -1 ? "" : this.state.documentTypeId[index];
        } catch (e) {
            return "";
        }
    }
    ScrollElement() {
        document.getElementById('container').scrollIntoView(false);
    }
    setToggle(i) {

        this.state.toggle[i] = !this.state.toggle[i];
        return this.state.toggle

    }
    setRotation(i) {
        this.state.rotation[i] = ((this.state.rotation[i] + 90) % 360);
        return this.state.rotation
    }
    setZoomPlus(i) {
        this.state.zoom[i] = this.state.zoom[i] + 10;
        return this.state.zoom;

    }
    setZoomMinus(i) {
        this.state.zoom[i] = this.state.zoom[i] - 10;
        return this.state.zoom;

    }
    resetImageButtonStatus(e) {
        let myVar = setTimeout(() => {
            this.setState({ imageActions: this.setImageActionsState("undefined") });
        }, 1000);
        return this.state.imageActions[e];
    }

    setShowButtonStatus(i) {
        this.state.actionShowReviewButton[i] = true;
        return this.state.actionShowReviewButton;
    }

    resetShowButtonStatus(i) {
        let myVar = setTimeout(() => {
            this.state.actionShowReviewButton[i] = false;
            this.setState({ actionShowReviewButton: this.state.actionShowReviewButton });
        }, 1000);
        return this.state.actionShowReviewButton[i];
    }

    seFocusOnPopUp() {
        document.getElementById("document-image-pop-up-outer-container").focus();
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
    openToNewWindow(pdf){
        
        if(pdfButtonPressed === 0)
        {
            
            
            var a = document.createElement('a');
            a.target = '_blank';
            const data  =  _.escape(`data:application/pdf;base64,${pdf}`);
            // const link = document.createElement("a");

            // link.href = data;
            // link.download = "file.pdf";
            // link.click();
           
            const w = window.open("file","_blank")
            w.document.write(`<embed width='100%' height="${_.escape(window.innerHeight) }px" src="${data}" ></embed>`)
            pdfButtonPressed++;
            this.setState({ toggle: this.setToggle(this.state.index)});                                                   
            
        }
    }

    renderMountedPageWithData() {

        return (
            <>

                <div id='container' ref='container' style={{
                    minHeight: '5.52vmax',

                    marginBottom: (this.state.toggle ? '0vmax' : '2vmax')
                }}
                    className={styles['document-image-outer-container']}>
                    <div className={styles['player-document-info-players-document-label-container']}>
                        <div className={styles['player-document-info-players-document-text']}
                            aria-label="Player's Documents"
                            tabIndex='0'
                        >Mobile Claim Documents </div>
                    </div>
                    <div className={`${styles['player-document-info-players-document-details-labels-container']}`}>
                        <div className={styles['player-document-info-players-document-details-labels-text']} aria-label='File Type' tabIndex='0'>File Type</div>
                        <div className={styles['player-document-info-players-document-details-labels-text']} aria-label='Document Type' tabIndex='0'>Document Type</div>
                    </div>
                    <div style={{ width: '100%', height: '.7em', }}>
                        <hr className={styles['document-image-outer-container-hr']} />
                    </div>

                    <div className={styles['player-document-info-players-document-details-content-images-container']} >
                        {this.state.documentData.map((e, i) =>
                            <>
                                <div style={{ minHeight: valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) !== "W-2G" ? (this.state.toggle[i] ? '4.125em' : '25.437vmax') :'4.125em' , height: 'auto', width: '100%', display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                                    <div className={styles['player-document-info-players-document-details-content-container']}>
                                        <div className={`${styles['player-document-info-players-document-details-content-text']} ${styles['player-document-info-players-document-details-content-text-margin-right']}`} tabIndex='0'>{this.state.documentData[i].fileType}</div>


                                        <div tabIndex='0' style={{ minHeight: '100%', marginRight: '0em' }}
                                            className={styles['player-document-info-players-document-details-content-select']}
                                        >
                                            {this.getDocumentTypeId(i)}
                                        </div>

                                        { this.state.documentData[i].documentType === "W-2G" && checkRenderPermissions(permissions.CAN_SEE_CLAIM_W2G, JSON.parse(this.state.userpolicies)) && 
                                            
                                         <div
                                            className={styles['player-document-info-players-document-details-content-button-hide-show-review-container']}>
                                            <div aria-live={"assertive"} role='button' aria-pressed={`${this.state.actionShowReviewButton[i] === true ? this.resetShowButtonStatus(i) : false}`} tabIndex='0'
                                                onKeyPress={(e) =>{
                                                    pdfButtonPressed = 0; 
                                                    if(keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32)
                                                       {this.setState({ toggle: this.setToggle(i), refreshDataForImages: 0, index: i, actionShowReviewButton: this.setShowButtonStatus(i)})}
                                                   
                                                }}
                                                onClick={(e) => { pdfButtonPressed = 0 ;this.setState({ toggle: this.setToggle(i), refreshDataForImages: 0, index: i, actionShowReviewButton: this.setShowButtonStatus(i), setFocusOnPopup: true}); }}
                                                className={styles['player-document-info-players-document-details-content-button-review']}>
                                                <div className={styles['player-document-info-players-document-details-content-button-review-text']}
                                                >{valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) === "W-2G" ? 'Download' : this.state.toggle[i] ? 'Show' : 'Hide'}</div>
                                                {/* {this.state.toggle ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />} */}
                                                {valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) !== "W-2G" ? (this.state.toggle[i] ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />) : ""}
                                            </div>
                                        </div>
                                        }
                                        { this.state.documentData[i].documentType !== "W-2G" && checkRenderPermissions(permissions.CAN_SEE_E_SIGNATURE, JSON.parse(this.state.userpolicies)) &&
                                        <div
                                            className={styles['player-document-info-players-document-details-content-button-hide-show-review-container']}>
                                            <div aria-live={"assertive"} role='button' aria-pressed={`${this.state.actionShowReviewButton[i] === true ? this.resetShowButtonStatus(i) : false}`} tabIndex='0'
                                                onKeyPress={(e) => keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32
                                                    ?
                                                    this.setState({ toggle: this.setToggle(i), refreshDataForImages: 0, index: i, actionShowReviewButton: this.setShowButtonStatus(i) })
                                                    :
                                                    ""
                                                }
                                                onClick={(e) => { this.setState({ toggle: this.setToggle(i), refreshDataForImages: 0, index: i, actionShowReviewButton: this.setShowButtonStatus(i), setFocusOnPopup: true }); }}
                                                className={styles['player-document-info-players-document-details-content-button-review']}>
                                                <div className={styles['player-document-info-players-document-details-content-button-review-text']}
                                                >{valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) === "W-2G" ? 'Download' : this.state.toggle[i] ? 'Show' : 'Hide'}</div>
                                                {/* {this.state.toggle ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />} */}
                                                {valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) !== "W-2G" ? (this.state.toggle[i] ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />) : ""}
                                            </div>
                                        </div>
                                      }
                                    </div>



                                    
                                    {(!this.state.toggle[i] && valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) === "W-2G")  &&  this.openToNewWindow(this.state.documentData[i].url)/*this.openToNewWindow(new Blob ([this.state.documentData[i].url],{ type: 'application/pdf' }))*/ } 
                                    {(!this.state.toggle[i] && valuesOfDocumentType.find(e => e === this.state.documentData[i].documentType) !== "W-2G")
                                        &&
                                        <>
                                            <div id='document_image' className={styles['document-image-container']}>

                                                <div

                                                    style={
                                                        {
                                                            backgroundSize: ` ${this.state.zoom[i] === 100 ? 'cover' : `${this.state.zoom[i]}%`}`,
                                                            backgroundImage: `url(${(this.state.playersCurrentImage[i] === undefined && !this.state.toggle[i]) ? this.testIfImageIsAvailable() : this.state.playersCurrentImage[i]})`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                            textAlign: 'center',
                                                        }
                                                    }
                                                    className={`${styles['document-image-src-container']}                         
                                                        ${this.state.rotation[i] === 90 ? styles['rotateimg90'] :
                                                            this.state.rotation[i] === 180 ? styles['rotateimg180'] :
                                                                this.state.rotation[i] === 270 ? styles['rotateimg270'] :
                                                                    styles['rotateimg0']
                                                        }`} alt={'image_name'} >

                                                    {
                                                        this.state.playersCurrentImage[i] === "" &&
                                                        <h2>Image Not available</h2>

                                                    }
                                                    {
                                                        this.state.playersCurrentImage[i] === undefined &&
                                                        <h2>{this.state.refreshStatus === true ? "Fetching Image" : ""}</h2>

                                                    }
                                                </div>
                                                {
                                                    this.state.playersCurrentImage[i] === undefined &&
                                                    <div style={{ position: 'absolute', top: '37%', left: '45%' }}>
                                                        <CircularProgress />
                                                    </div>
                                                }
                                                <div onFocus={(e) => { this.setImageActionsState("undefined") }} className={styles['document-image-src-controll-container']}>
                                                    <div role='button' aria-pressed="false"
                                                        tabIndex='0'
                                                        aria-live={"assertive"}
                                                        aria-pressed={this.state.imageActions.rotatePressed === true ? this.resetImageButtonStatus("rotatePressed") : false}
                                                        aria-label={"rotate 90 degrees."}
                                                        onKeyPress={(e)=> keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32
                                                            ? 
                                                            this.setState({ rotation: this.setRotation(i), imageActions: this.setImageActionsState("rotatePressed") })          
                                                            :
                                                            ""
                                                        } 
                                                        onClick={(e) => this.setState({ rotation: this.setRotation(i), imageActions: this.setImageActionsState("rotatePressed") })}
                                                    >
                                                        <FontAwesomeIcon role='img' aria-label='faReply icon' icon={faReply} />

                                                    </div>
                                                    <div style={{ display: 'inline' }} >
                                                        <span onClick={(e) => this.setState((state, props) => ({ zoom: this.setZoomPlus(i), imageActions: this.setImageActionsState("zoomIn") }))}
                                                             onKeyPress={(e)=> keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32
                                                                ? 
                                                                this.setState((state, props) => ({ zoom: this.setZoomPlus(i), imageActions: this.setImageActionsState("zoomIn") }))           
                                                                :
                                                                ""
                                                            } 
                                                            role='button'
                                                            tabIndex='0'
                                                            aria-live={"assertive"}
                                                            aria-pressed={this.state.imageActions.zoomIn ? this.resetImageButtonStatus("zoomIn") : false}
                                                            style={{ marginRight: '.2em' }}
                                                            aria-label={"Zoom in."}
                                                        >

                                                            <FontAwesomeIcon role='img' icon={faPlusCircle} />

                                                        </span>
                                                        <span role='button'
                                                            tabIndex='0'
                                                            aria-live={"assertive"}
                                                            aria-pressed={this.state.imageActions.zoomOut ? this.resetImageButtonStatus("zoomOut") : false}
                                                            onClick={(e) => this.setState((state, props) => ({ zoom: this.setZoomMinus(i), imageActions: this.setImageActionsState("zoomOut") }))}
                                                            onKeyPress={(e)=> keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32
                                                                ? 
                                                                this.setState((state, props) => ({ zoom: this.setZoomMinus(i), imageActions: this.setImageActionsState("zoomOut") }))
                                                                :
                                                                ""
                                                            } 
                                                            aria-label={"Zoom out."}
                                                        >
                                                            <FontAwesomeIcon role='img' icon={faMinusCircle} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {(this.state.documentData.length - 1) !== i &&
                                        <div style={{ width: '100%', height: '.7em' }}>
                                            <hr style={{ height: '.01em', opacity: 0.4 }} className={styles['document-image-outer-container-hr']} />
                                        </div>}
                                       

                                </div>
                            </>
                        )}
                    </div>

                </div>

            </>

        );

    }


    render() {
        return (<>{(this.state.isData && this.state.documentData.length > 0) && this.renderMountedPageWithData()}</>);
    }
}

export { MobileClaimDocumentsComponent }

