import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const HoldChargeConfirmationComponent = ({holdCharge, chargeId, setShowHoldChargeConfirmationDialog}: any) => {

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["confirmation-dialog-main-container"]}>
                    <div id="confirmation-dialog-container"
                         className={stylesMessageBox["confirmation-dialog-container"]}>
                        <div className={stylesMessageBox["confirmation-dialog-text-container"]}>
                            <div className={stylesMessageBox["confirmation-dialog-title"]}>Hold Charge</div>
                            <div className={stylesMessageBox["confirmation-dialog-message"]}>
                                Update the status of the charge from “Scheduled” to “On Hold”.
                                This action will remove the charge from the EFT Ledger screen.
                            </div>
                        </div>
                        <div className={stylesMessageBox["confirmation-dialog-button-container"]}>
                            <div tabIndex={0} className={stylesMessageBox["confirmation-dialog-yes-button"]}
                                 role="button"
                                 onKeyDown={(e: any) => {
                                     if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                         holdCharge(chargeId, false);
                                         setShowHoldChargeConfirmationDialog(false);
                                     }
                                 }}
                                 onClick={() => {
                                     holdCharge(chargeId, false);
                                     setShowHoldChargeConfirmationDialog(false);
                                 }}
                            >Update
                            </div>
                            <div
                                tabIndex={0}
                                className={stylesMessageBox["confirmation-dialog-no-button"]}
                                role="button"
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        setShowHoldChargeConfirmationDialog(false);
                                    }
                                }}
                                onClick={() => {
                                    setShowHoldChargeConfirmationDialog(false)
                                }}
                            >Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};