import * as React from 'react'
import {
  PromotionDrawingWinner,
  WinnerStatus,
} from '../../../../../api/promotions-fetcher/constants'
import { WinnersManagementPermissions } from '../../../../../api/promotions-fetcher/permissions'
import { userHasAnyOfPermissions } from '../../../../../util/access-helpers'
import { WinnersManagementTierContext } from '../../winners-management-list-context'
import { WinnersManagementListRowActionMenuUI } from './winners-management-list-row-action-menu-ui'

export type WinnersManagementListRowActionMenuContainerProps = {
  winner: PromotionDrawingWinner
  prizeAmountInCents: number
}

export const WinnersManagementListRowActionMenuContainer = (
  props: WinnersManagementListRowActionMenuContainerProps
) => {
  const winnersManagementTierContext = React.useContext(
    WinnersManagementTierContext
  )

  return (
    <WinnersManagementListRowActionMenuUI
      {...winnersManagementTierContext}
      winner={props.winner}
      prizeAmountInCents={props.prizeAmountInCents}
      menuItemsDisabled={
        !userHasAnyOfPermissions([
          WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT,
        ]) || props.winner.status === WinnerStatus.ALTERNATE
      }
    />
  )
}
