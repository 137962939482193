import * as React from 'react'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { getBlockClass } from '../../../utilities/helpers'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { SearchResultsTableColumnMap, SearchResultsTableUI } from '../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import './styles.scss'
import { ReportStatus } from '../../../api/finserv-general-reports/api-types'
import { permissions } from '../../../constants.js'
import { Agent1099ReportListEntry, Agent1099ReportType } from '../../../api/agent-1099-reports/api-types'
import { generateReportDateString } from '../helper'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { mapFinservReportStatusToString } from '../../../util'

export type Agent1099ReportPageUIProps = {
  isLoading: boolean
  error?: string
  reports?: Agent1099ReportListEntry[],
  presignedUrlHandler: (id: string) => Promise<void>
}

const ROOT_CLASS = 'agent-1099-report-page-ui'

export type Agent1099ReportDisplayRow = Omit<Agent1099ReportListEntry, 'dateGenerated' | 'status'> & {
  dateGenerated: string
  status: string
  reportName: string
}

function mapReportTypeToDisplayName(type: Agent1099ReportType): string {
  switch (type) {
    case Agent1099ReportType.PRELIMINARY_AGENT_SUMMARY_REPORT:
      return 'Agent Summary'
    case Agent1099ReportType.PRELIMINARY_AGENT_EXCEPTION_REPORT:
      return 'Agent Exception'
    case Agent1099ReportType.GROUPED_AGENT_SUMMARY_REPORT:
      return 'Grouped Agent Summary'
    default:
      return type
  }
}

function mapToDisplayRow(row: Agent1099ReportListEntry): Agent1099ReportDisplayRow {
  return {
    ...row,
    reportName: mapReportTypeToDisplayName(row.reportType),
    dateGenerated: generateReportDateString(row.dateGenerated),
    status: mapFinservReportStatusToString(row.status)
  }
}

const columnMap: SearchResultsTableColumnMap<Agent1099ReportDisplayRow> = [
  {
    dataKey: 'dateGenerated',
    label: 'Date & Time Generated',
    width: 24
  },
  {
    dataKey: 'status',
    label: 'Report Status',
    width: 18
  },
  {
    dataKey: 'reportName',
    label: 'Report Name',
    width: 30
  },
]

export const Agent1099ReportPageUI = (props: Agent1099ReportPageUIProps) => {

  
  const diplayRow  = props.reports ? props.reports.filter(report => report.reportType !== Agent1099ReportType.YEAR_END_REPORTS).map(mapToDisplayRow) : []

  const generateDownloadCTA = (report: Agent1099ReportDisplayRow) => {
    const noButtonStatuses = [ReportStatus.FAILED, ReportStatus.EXPIRED].map(mapFinservReportStatusToString)
    return (
      !noButtonStatuses.includes(report.status)
        &&  userHasAnyOfPermissions([permissions.CAN_SEE_1099_REPORTS, permissions.CAN_PRINT_1099_QUEUE])       
        && (
        <button
          disabled={report.status === 'Pending'}
          onClick={() => props.presignedUrlHandler(report.id)}
          className={`dark-btn`}
        >
          Download
        </button>
      )
    )
  }

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI message="Back"/>
      <header className = {getBlockClass(ROOT_CLASS, 'header')}>
        1099 Agent Reports
      </header>
      <AsyncContainerUI
        isLoading={props.isLoading}
        error={props.error}
        color="white"
      >

        <SearchResultsTableUI
          columnMap={columnMap}
          rowData={diplayRow}
          additionalResults={false}
          generateRowCTA={generateDownloadCTA}
          noDataMessage="No reports have been generated"
        />
      </AsyncContainerUI>
    </div>
  )
  
  
}