import FuzzySearch from 'fuzzy-search'
import { SetStateAction } from 'react'
import { AnnuityAccountInfo } from './interfaces'

let filteredTimePaymentAccounts: AnnuityAccountInfo[]
let timePaymentAccountList: AnnuityAccountInfo[]
let setFilteredAccountList: React.Dispatch<SetStateAction<AnnuityAccountInfo[]>>
let formik: any

export const setVariables = (
  formikObj: any,
  annuityAccountListObj: any,
  filteredAccountListObj: any,
  setFilteredAccountListObj: any
) => {
  formik = formikObj
  filteredTimePaymentAccounts = filteredAccountListObj
  timePaymentAccountList = annuityAccountListObj
  setFilteredAccountList = setFilteredAccountListObj
}

export const clearSearch = (formik: any) => {
  formik.resetForm({
    values: {
      statusCode: '',
      payeeName: '',
      tin: '',
      documentId: '',
    },
  })
  setFilteredAccountList(timePaymentAccountList)
}

export const setFieldValue = (e: any, formik: any) => {
  formik.setFieldValue(e.target.name, e.target.value, true)
}

export const onSubmit = async (values: any) => {
  filterTimePaymentAccounts(values)
}

export const validate = (values: any) => {
  const errors = {}
  if (values.tinNumber && !/^\d{3}-?\d{2}-?\d{4}$/.test(values.tinNumber)) {
    Object.assign(errors, {
      tinNumber: 'Invalid Tin number, This should be 9 digit.',
    })
  }
  return errors
}

type TimePaymentAccountSearchParams = {
  tin: string
  documentId: string
  statusCode: string
  payeeName: string
}

const filterTimePaymentAccounts = (values: TimePaymentAccountSearchParams) => {
  let filteredTimePaymentAccountList = [...timePaymentAccountList]
  if (values.statusCode && values.statusCode !== 'All') {
    const status =
      values.statusCode === 'Completed (Inactive)'
        ? 'Completed'
        : values.statusCode
    filteredTimePaymentAccountList = filteredTimePaymentAccountList.filter(
      (entry) => entry.status === status
    )
  }
  // Search
  if (values.payeeName && values.payeeName !== '') {
    const searcher = new FuzzySearch(filteredTimePaymentAccountList, ['name'], {
      caseSensitive: false,
      sort: true,
    })
    filteredTimePaymentAccountList = searcher.search(values.payeeName)
  }
  if (values.tin && values.tin !== '') {
    filteredTimePaymentAccountList = filteredTimePaymentAccountList.filter(
      (timePaymentAccount) => timePaymentAccount.tin === values.tin
    )
  }
  if (values.documentId && values.documentId !== '') {
    filteredTimePaymentAccountList = filteredTimePaymentAccountList.filter(
      (item) => item.documentId === values.documentId
    )
  }
  setFilteredAccountList(filteredTimePaymentAccountList)
}
