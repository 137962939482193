import React from 'react'
import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { GetPendingPaymentsResponse } from '../../../api/pending-payments-fetcher/api-types'

const getPendingPaymentsUrl = `${config.SERVER_BASE_URL}/api/v1/payments/future/pending`

export const getFetchPendingPayments = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<GetPendingPaymentsResponse>()
  const fetchPendingPayments = () => {
    fetchUrl(getPendingPaymentsUrl, 'GET')
  }

  return {
    isLoading,
    error,
    data,
    fetchPendingPayments
  }
}
