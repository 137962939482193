import { CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { TimePaymentAuthSchedule } from '../../../../types/Annuities/TimePaymentAuthorization/TimePaymentAuthorization'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { PaginationCommonComponent } from '../../../CommonComponents/PaginationCommonComponent'
import { ExportDropdown } from '../../../CommonComponents/export-dropdown/export-dropdown-ui'
import { AggregateTotalsCmpt } from '../Common/AuthorizationAggregateTotalsCmpt'
import { generateCheckRunPDF } from '../Common/functions'
import { exportAsXLSX } from '../Common/time-payment-authorization-excel-export'
import { InitiateAuthorizationListRowCmpt } from '../InitiateAuthorizationListRowCmpt'
import styles from './css/styles.module.css'
import {
  fetchLastThroughDate,
  getAuthorizationData,
  getPageData,
  normalizePage,
  startAuthorization,
  validate,
} from './functions'

export const InitiateAuthorizationCmpt: React.FC = () => {
  const bannerContext = useContext(BannerContext)

  const idemToken: string = uuidv4()
  const exportFileName = 'Time_Payment_Authorization'

  // state variables
  const [authorizationPaymentDetails, setAuthorizationPaymentDetails] =
    useState<TimePaymentAuthSchedule[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [lastThroughDate, setLastThroughDate] = useState<string>('Loading...')
  const [confirmStartAuthorization, setConfirmStartAuthorization] =
    useState<boolean>(false)
  const [tableBodyMessage, setTableBodyMessage] = useState<string>('')

  // package required to focus modal accessibility
  const FocusTrap = require('focus-trap-react')

  // formik
  const formik = useFormik({
    initialValues: { throughDate: '' },
    enableReinitialize: true,
    onSubmit: (values) => {},
    validate,
  })

  const createAuthorization = async () => {
    setShowSpinner(true)
    setConfirmStartAuthorization(false)
    try {
      await startAuthorization(formik.values.throughDate, idemToken)
      setAuthorizationPaymentDetails([])
      formik.resetForm()
      setLastThroughDate('Loading...')
      bannerContext.setBannerInfo({
        message: `The Authorization process through ${`${moment(
          formik.values.throughDate
        ).format('dddd, MMMM Do, YYYY')}`} has been initiated.`,
        error: false,
      })
    } catch (error) {
      bannerContext.setBannerInfo({ message: `${error}`, error: true })
    }
    setShowSpinner(false)
  }

  const startAuthorizationSearch = async () => {
    try {
      setShowSpinner(true)
      const response = await getAuthorizationData(formik.values.throughDate)

      if (response.length === 0) {
        setTableBodyMessage('There are no checks to run through this date.')
      }
      setAuthorizationPaymentDetails(response)
    } catch (error) {
      bannerContext.setBannerInfo({ message: `${error}`, error: true })
    }
    setShowSpinner(false)
  }

  // useEffect to grab last through date
  useEffect(() => {
    const getLastThroughDate = async () => {
      try {
        const lastThroughDate = await fetchLastThroughDate()
        setLastThroughDate(lastThroughDate)
      } catch (error) {
        setLastThroughDate('Error')
        bannerContext.setBannerInfo({ message: `${error}`, error: true })
      }
    }
    getLastThroughDate()
  }, [lastThroughDate])

  // useEffect to update Pagination currentPage number when data is updated
  useEffect(() => {
    setCurrentPage(0)
  }, [authorizationPaymentDetails])

  // useEffect to change table body text
  useEffect(() => {
    if (formik.values.throughDate === '') {
      setTableBodyMessage('Please select a through date.')
    }
  }, [formik.values.throughDate])

  const exportXLSX = () =>
    exportAsXLSX(authorizationPaymentDetails, formik.values.throughDate)
  const exportPDF = async () => {
    setShowSpinner(true)
    try {
      await generateCheckRunPDF(formik.values.throughDate)
    } catch (err) {
      bannerContext.setBannerInfo({
        message: 'Error exporting PDF',
        error: true,
      })
    }
    setShowSpinner(false)
  }

  return (
    <>
      {showSpinner && (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      )}
      {/* Pop Up Modal to begin annuity */}
      {confirmStartAuthorization && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles['overlay-container']}>
            <div className={styles['main-modal']}>
              <div>
                <div className={styles['modal-title']}>Attention!</div>
                <div className={styles['modal-desc']}>
                  This action will initiate the Authorization process and is not
                  reversible. Are you sure you want to continue?
                </div>
              </div>
              {/* confirm */}
              <button
                type="button"
                className={styles['modal-confirm-btn']}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    createAuthorization()
                  }
                }}
                onClick={() => {
                  createAuthorization()
                }}
              >
                Confirm
              </button>
              {/* Cancel */}
              <button
                type="button"
                className={styles['modal-cancel-btn']}
                onClick={() => {
                  setConfirmStartAuthorization(false)
                }}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setConfirmStartAuthorization(false)
                  }
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
      <main className={`${styles.initiate_auth_cmpt} w-100 px-2 rounded mb-3`}>
        {/* Authorization Search */}
        <div className={`${styles.auth_search}`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex pt-2">
              <div className={`${styles.info_detail}`}>
                <label htmlFor="throughDate">Select Through Date</label>
                <input
                  type="date"
                  min={moment(lastThroughDate).format('YYYY-MM-DD')}
                  max={moment(lastThroughDate).add(1, 'y').format('YYYY-MM-DD')}
                  className={`${styles.custom_input}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="throughDate"
                  id="throughDate"
                  value={formik.values.throughDate}
                />
                {formik.errors.throughDate && formik.touched.throughDate && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={formik.errors.throughDate}
                  />
                )}
              </div>
              {/* Get Authorization Button */}
              <button
                type="button"
                className={
                  formik.values.throughDate === ''
                    ? `${styles.search_btn_disabled}`
                    : `${styles.search_btn}`
                }
                disabled={formik.values.throughDate === '' ? true : false}
                onKeyPress={async (event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    await startAuthorizationSearch()
                  }
                }}
                onClick={async () => {
                  await startAuthorizationSearch()
                }}
              >
                Search
              </button>
              {/* Post Authorization */}
              <button
                type="button"
                className={
                  authorizationPaymentDetails.length > 0
                    ? `${styles.light_btn}`
                    : `${styles.light_btn_disabled}`
                }
                disabled={authorizationPaymentDetails.length > 0 ? false : true}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setConfirmStartAuthorization(true)
                  }
                }}
                onClick={() => {
                  setConfirmStartAuthorization(true)
                }}
              >
                Authorize
              </button>
            </div>
            {/* Export CSV */}
            <div className={`${styles.through_date_container} pt-2`}>
              <label>Last Through Date Run: {lastThroughDate}</label>
              <ExportDropdown
                disabled={!authorizationPaymentDetails.length}
                exportPDF={exportPDF}
                exportXLSX={exportXLSX}
              ></ExportDropdown>
            </div>
          </div>
        </div>
        {/* Auth Aggregates Container */}
        <div className={`w-100 mb-2 pt-2 border-bottom border-white`}>
          {
            <AggregateTotalsCmpt
              paymentResponse={authorizationPaymentDetails}
              showOnHoldDisclaimer={true}
            />
          }
        </div>
        {/* Authorization Table */}
        <div className={`${styles.table}`}>
          <div
            className={`${styles.table_results_display} font-italic font-weight-bold pt-2`}
          >
            <div aria-live="assertive">{`Displaying ${
              authorizationPaymentDetails.length === 0
                ? 0
                : currentPage * 10 + 1
            }-${normalizePage(
              authorizationPaymentDetails.length,
              currentPage
            )} of ${authorizationPaymentDetails.length} results`}</div>
          </div>
          <div
            className={`rounded-top font-weight-bold ${styles.table_header}`}
          >
            <div>Doc ID</div>
            <div>Name</div>
            <div>TIN</div>
            <div>Cadence</div>
            <div>Payment Date</div>
            <div>Gross Payment</div>
            <div>Est. Federal Tax</div>
            <div>Est. State Tax</div>
            <div>Est. Net Payment</div>
            <div>Lifetime Payment</div>
            <div></div>
          </div>

          <div className={styles.table_body}>
            {authorizationPaymentDetails.length > 0 ? (
              getPageData(authorizationPaymentDetails, currentPage).map(
                (authorization: TimePaymentAuthSchedule, index: number) => (
                  <InitiateAuthorizationListRowCmpt
                    authorization={authorization}
                    paymentNumber={index + 1}
                    paymentDataLength={authorizationPaymentDetails.length}
                    key={index++}
                  />
                )
              )
            ) : (
              <div className={`${styles.table_body_text} text-center pt-2`}>
                <em>{tableBodyMessage}</em>
              </div>
            )}
          </div>
          <div
            className={`${styles.table_footer} p-2 rounded-bottom w-100 d-flex align-items-center justify-content-end mb-3`}
          >
            <div
              aria-live="assertive"
              className={`${styles.table_page_label} mr-2`}
            >{`Page ${
              authorizationPaymentDetails.length === 0 ? 0 : currentPage + 1
            } of ${Math.ceil(
              authorizationPaymentDetails.length / 10
            ).toString()}`}</div>
            {
              <PaginationCommonComponent
                playerData={authorizationPaymentDetails}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            }
          </div>
        </div>
      </main>
    </>
  )
}
