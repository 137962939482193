import { FormikValues, useFormik } from "formik";
import moment from "moment";
import React, { SetStateAction, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { annuitiesRoutePath } from "../../../../../config";
import { KEY_CODES } from "../../../../../constants.js";
import { BannerContext } from "../../../../../context/BannerContext";
import { keyPressedType } from "../../../../../services/Commons";
import { TimePaymentAccountPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { SubAccountTreeNode } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { TempPaymentRules } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/TempPaymentRules";
import { PaymentScheduleEntry } from "../../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../../types/Annuities/responses/time-payment-account";
import { CheckboxComponent } from "../../../../CommonComponents/CheckboxComponent";
import { AccountStatusCmpt } from "../../../Common/AccountStatusCmpt";
import { AddPaymentRuleComponent } from "../AddPaymentRuleComponent";
import { ValidationNotificationComponent } from "../ValidationNotificationComponent";
import { validatePaymentRule } from "../functions";
import styles from "./css/styles.module.css";
import { submitNewSubAccount } from "./functions";

export const AddSubAccountComponent: React.FC<{
  parentNode: SubAccountTreeNode;
  payee: TimePaymentAccountPayee | undefined;
  setShowAddSubAccount: React.Dispatch<React.SetStateAction<boolean>>;
  tpaDetails: TimePaymentAccountResponse;
  tpaPaymentSchedule: PaymentScheduleEntry[];
  predeterminedWidth: number;
  setIsPageInEditMode: React.Dispatch<SetStateAction<boolean>>;
}> = ({
  parentNode,
  payee,
  setShowAddSubAccount,
  tpaDetails,
  tpaPaymentSchedule,
  setIsPageInEditMode,
  predeterminedWidth,
}) => {
  // routing
  const history = useHistory();

  // Context
  const bannerContext = React.useContext(BannerContext);
  const idemToken = uuidv4();

  // Component sizing to make added sub account the same size as a child account
  const modifiedWidth = predeterminedWidth - 1.2;

  // initial federalTax and StateTax - eventually coming from global scope
  const initialFederalTax = "24";
  const initialStateTax = "5";

  // Package required to focus modal accessibility
  const FocusTrap = require("focus-trap-react");

  const defaultPaymentRule = {
    subAccountId: parentNode.account.id,
    startDate: moment().add(1, "d").toDate(),
    endDate: moment().add(2, "d").toDate(),
    amount: 0,
  };

  const [annuityBtnSave, setAnnuityBtnSave] = useState<boolean>(false);
  const [paymentRules, setPaymentRules] = useState<TempPaymentRules[]>([
    { temporaryId: uuidv4(), ...defaultPaymentRule },
  ]);
  const [newRulesPassValidation, setNewRulesPassValidation] =
    useState<boolean>(true);
  const [validationErrorVisible, setValidationErrorVisible] =
    useState<boolean>(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");

  // function to update STATE at the PARENT level when a field has changed from Child Payment Rule Level
  const handleFieldValueChange = (
    fieldName: string,
    fieldValue: string,
    temporaryId: string
  ) => {
    //find index for where this specific object is inside formik
    const paymentRuleIndex = paymentRules.findIndex(
      (paymentRule: any) => paymentRule.temporaryId === temporaryId
    );

    // make index change
    switch (fieldName) {
      case "amount":
        setPaymentRules((currentState) => {
          currentState[paymentRuleIndex].amount =
            fieldValue === "" ? 0 : Math.round(parseFloat(fieldValue) * 100);
          return currentState;
        });
        break;
      case "startDate":
        setPaymentRules((currentState) => {
          currentState[paymentRuleIndex].startDate =
            fieldValue === ""
              ? ""
              : moment(`${fieldValue} 00:00`, "YYYY-MM-DD HH:mm")
                  .utc()
                  .toDate();
          return currentState;
        });
        break;
      case "endDate":
        setPaymentRules((currentState) => {
          currentState[paymentRuleIndex].endDate =
            fieldValue === ""
              ? ""
              : moment(`${fieldValue} 00:00`, "YYYY-MM-DD HH:mm")
                  .utc()
                  .toDate();
          return currentState;
        });
        break;
      default:
        console.log("No case found...");
    }
  };

  /**FORMIK AREA */
  const onSubmit = (values: FormikValues) => {
    console.log("Submitting...");
  };

  // must validate form field here to have access to other account details
  const validate = (values: FormikValues) => {
    const errors = {};

    return errors;
  };

  // initialize Formik for Tax Area
  const formik = useFormik({
    initialValues: {
      timePaymentAccountPayeeId: payee!.timePaymentAccountPayeeId,
      irsTaxEnabled: parentNode.account.parentTaxResponsibility
        ? parentNode.account.irsTaxEnabled
        : true,
      dorTaxEnabled: parentNode.account.parentTaxResponsibility
        ? parentNode.account.dorTaxEnabled
        : true,
      acctStatus: parentNode.account.acctStatus,
      parentTaxResponsibility: parentNode.account.parentTaxResponsibility,
      parentAccountId: parentNode.account.id,
    },
    enableReinitialize: true,
    onSubmit,
    validate,
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  const submitSubAccount = async () => {
    try {
      await submitNewSubAccount(formik.values, paymentRules, idemToken);
      setAnnuityBtnSave(false);
      bannerContext.setBannerInfo({
        message: `Successfully Added Sub Account`,
        error: false,
      });
    } catch (error) {
      bannerContext.setBannerInfo({ error: true, message: `${error}` });
    }
  };

  const validatePaymentRules = () => {
    try {
      validatePaymentRule(parentNode, tpaDetails, paymentRules, []);
      setAnnuityBtnSave(true);
    } catch (error) {
      if (error instanceof Error) {
        setValidationErrorVisible(true);
        setValidationErrorMsg(error.message);
      } else {
        bannerContext.setBannerInfo({
          error: true,
          message: "Something went wrong presenting Validation Error Component",
        });
      }
    }
  };

  return (
    <>
      <div className={styles["sub-page-container"]}>
        {/*** MODAL CONTROL DESIGNS ***/}
        {/** Sub-Account Save Modal Controls **/}
        {annuityBtnSave && (
          <FocusTrap focusTrapOptions={{ initialFocus: false }}>
            <div className={styles["overlay-container"]}>
              <div className={styles["main-modal"]}>
                <div>
                  <div className={styles["modal-title"]}>Warning!</div>
                  <div className={styles["modal-desc"]}>
                    You are about to alter at least one account that will
                    dictate future payments in this Time Payment Account. Are
                    you sure you want to proceed?
                  </div>
                </div>
                <button
                  type="button"
                  className={`${styles.modal_btn} ${styles.modal_confirm_btn}`}
                  onKeyPress={async (event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      await submitSubAccount().then(() =>
                        history.push(
                          `${annuitiesRoutePath}/annuity-full-payment-schedule/${tpaDetails.id}`
                        )
                      );
                    }
                  }}
                  onClick={async () => {
                    await submitSubAccount().then(() =>
                      history.push(
                        `${annuitiesRoutePath}/annuity-full-payment-schedule/${tpaDetails.id}`
                      )
                    );
                  }}
                >
                  Submit &amp; View Payment Schedule
                </button>
                <button
                  type="button"
                  className={`${styles.modal_btn} ${styles.modal_confirm_btn_two}`}
                  onKeyPress={async (event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      await submitSubAccount().then(() =>
                        history.push(
                          `${annuitiesRoutePath}/annuity-management-details/${tpaDetails.id}`
                        )
                      );
                    }
                  }}
                  onClick={async () => {
                    await submitSubAccount().then(() =>
                      history.push(
                        `${annuitiesRoutePath}/annuity-management-details/${tpaDetails.id}`
                      )
                    );
                  }}
                >
                  Submit &amp; Return to Account Details
                </button>
                <button
                  type="button"
                  onKeyPress={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      setAnnuityBtnSave(!annuityBtnSave);
                    }
                  }}
                  onClick={() => {
                    setAnnuityBtnSave(!annuityBtnSave);
                  }}
                  role="button"
                  className={styles["modal-cancel-btn"]}
                >
                  Cancel
                </button>
              </div>
            </div>
          </FocusTrap>
        )}
        {validationErrorVisible && (
          <ValidationNotificationComponent
            setValidationErrorVisible={setValidationErrorVisible}
            validationMessage={validationErrorMsg}
          />
        )}
        {/*** MAIN COMPONENT DESIGN ***/}
        {/** Add Sub Account **/}
        <div
          className={`${styles.page_container}`}
          style={{ float: "right", width: `${modifiedWidth}%` }}
        >
          <div className={`${styles.sub_account_header}`}>
            <div className={`${styles.flex_left_new}`}>
              <div className={`${styles.sub_account_text}`}>Sub-Account</div>
              <div className={`${styles.sub_account_value}`}>
                {payee?.payeeType === "Individual"
                  ? `${payee?.firstName} ${payee?.lastName}`
                  : `${payee?.freeformName}`}
              </div>
            </div>
            <div className={`${styles.flex} align-items-center`}>
              <AccountStatusCmpt
                status={parentNode.account.acctStatus}
                notes={""}
              />
              <button
                type="button"
                className={`${styles.remove_btn}`}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setShowAddSubAccount(false);
                    setIsPageInEditMode(false);
                  }
                }}
                onClick={() => {
                  setShowAddSubAccount(false);
                  setIsPageInEditMode(false);
                }}
              >
                Remove
              </button>
              <button
                type="button"
                className={
                  paymentRules.length === 0 ||
                  (paymentRules.length > 0 && !newRulesPassValidation) ||
                  Object.keys(formik.errors).length !== 0
                    ? `${styles.light_btn_disabled}`
                    : `${styles.light_btn}`
                }
                disabled={
                  paymentRules.length === 0 ||
                  (paymentRules.length > 0 && !newRulesPassValidation) ||
                  Object.keys(formik.errors).length !== 0
                }
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    validatePaymentRules();
                  }
                }}
                onClick={() => {
                  validatePaymentRules();
                }}
              >
                Save
              </button>
            </div>
          </div>
          <div className={`${styles.sub_account_body}`}>
            <div className={`${styles.grid_col_2}`}>
              {/* Column 1 */}
              <div>
                {paymentRules.map((paymentRule, index) => (
                  <AddPaymentRuleComponent
                    tpaPaymentSchedule={tpaPaymentSchedule}
                    handleFieldValueChange={handleFieldValueChange}
                    individualPaymentRule={paymentRule}
                    allNewPaymentRules={paymentRules}
                    setAllPaymentRules={setPaymentRules}
                    setNewRulesPassValidation={setNewRulesPassValidation}
                    ruleNumber={index + 1}
                    key={index}
                  />
                ))}
                <div
                  className={`${styles.add_rule_container}`}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      setPaymentRules((prevState) => {
                        const newPaymentRulesArray = prevState.concat([
                          { temporaryId: uuidv4(), ...defaultPaymentRule },
                        ]);
                        return newPaymentRulesArray;
                      });
                    }
                  }}
                  onClick={() => {
                    setPaymentRules((prevState) => {
                      const newPaymentRulesArray = prevState.concat([
                        { temporaryId: uuidv4(), ...defaultPaymentRule },
                      ]);
                      return newPaymentRulesArray;
                    });
                  }}
                >
                  <div>Add Another Rule</div>
                  <FaPlus />
                </div>
              </div>

              {/* Column 2 */}
              <div className={`${styles.tax_info_container}`}>
                <h4>Tax Designation</h4>
                <p>
                  Setting these tax designations relates only to this annuity
                  account.
                </p>
                <div className={`${styles.flex}`}>
                  {/* Checkboxes */}
                  <div>
                    <div
                      role="button"
                      tabIndex={0}
                      className={
                        parentNode.account.parentTaxResponsibility === true
                          ? `${styles.checkbox_disabled}`
                          : `${styles.checkbox}`
                      }
                      onKeyDown={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          formik.setFieldValue(
                            "irsTaxEnabled",
                            !formik.values.irsTaxEnabled
                          );
                        }
                      }}
                      onClick={() => {
                        formik.setFieldValue(
                          "irsTaxEnabled",
                          !formik.values.irsTaxEnabled
                        );
                      }}
                    >
                      <div className={`${styles.flex}`}>
                        <p>Federal {initialFederalTax}% </p>
                        <CheckboxComponent
                          isChecked={formik.values.irsTaxEnabled}
                        />
                      </div>
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      className={
                        parentNode.account.parentTaxResponsibility === true
                          ? `${styles.checkbox_disabled}`
                          : `${styles.checkbox}`
                      }
                      onKeyDown={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          formik.setFieldValue(
                            "dorTaxEnabled",
                            !formik.values.dorTaxEnabled
                          );
                        }
                      }}
                      onClick={() => {
                        formik.setFieldValue(
                          "dorTaxEnabled",
                          !formik.values.dorTaxEnabled
                        );
                      }}
                    >
                      <div className={`${styles.flex}`}>
                        <p>State {initialStateTax}% </p>
                        <CheckboxComponent
                          isChecked={formik.values.dorTaxEnabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`d-flex align-items-center`}>
                  <div
                    className={
                      parentNode.account.parentTaxResponsibility
                        ? `${styles.parent_tax_checkbox_disabled}`
                        : `${styles.parent_tax_checkbox}`
                    }
                    role="button"
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        formik.setFieldValue(
                          "parentTaxResponsibility",
                          !formik.values.parentTaxResponsibility
                        );
                      }
                    }}
                    onClick={() => {
                      formik.setFieldValue(
                        "parentTaxResponsibility",
                        !formik.values.parentTaxResponsibility
                      );
                    }}
                  >
                    {parentNode.account.parentTaxResponsibility ? (
                      <CheckboxComponent
                        isChecked={parentNode.account.parentTaxResponsibility}
                        disabled={true}
                      />
                    ) : (
                      <CheckboxComponent
                        isChecked={formik.values.parentTaxResponsibility}
                      />
                    )}
                  </div>
                  <div
                    className={
                      parentNode.account.parentTaxResponsibility
                        ? `${styles.parent_tax_label_disabled}`
                        : `${styles.parent_tax_label}`
                    }
                  >
                    <em className={`${styles.parent_tax_disclaimer}`}>
                      The parent account is assuming all tax withholdings on
                      these payments
                    </em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
