import * as React from 'react'
import { GetFutureReissuePaymentDetailsResponse } from '../../../../api/future-reissue-payment-details-fetcher/api-types'
import { BackButtonUI } from '../../../CommonComponents/BackButtonComponent/back-button-ui'
import { FutureReissuePaymentDetailsTitleBar } from '../future-reissue-payment-details-title-bar/future-reissue-payment-details-title-bar'
import './styles.scss'
import { FutureReissuePaymentDetailsUI } from '../future-reissue-payment-details-ui/future-reissue-payment-details-ui'

const ROOT_CLASS = 'future-reissue-payment-details-page'

export type FutureReissuePaymentDetailsContainerUIProps = {
  futureReissuePaymentDetailsData: GetFutureReissuePaymentDetailsResponse
}

export const FutureReissuePaymentDetailsContainerUI = (
  props: FutureReissuePaymentDetailsContainerUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={0} message={`Back to Re-Issue Queue`} />
      <FutureReissuePaymentDetailsTitleBar {...props} />
      <FutureReissuePaymentDetailsUI {...props} />
    </div>
  )
}