let setTicketOnHold: any;
let setTicketOnHoldText: any;

export const TicketHoldText = {
    TicketOnHold: 'Ticket on hold',
    TicketNotOnHold: 'Ticket on hold'
}

export const setVariables = (setTicketOnHoldObj: any, setTicketOnHoldTextObj: any) => {
    setTicketOnHold = setTicketOnHoldObj;
    setTicketOnHoldText = setTicketOnHoldTextObj;
}

export const setTicketHoldText = (ticketOnHold: boolean) => {
    if (ticketOnHold) {
        setTicketOnHoldText(TicketHoldText.TicketOnHold)
        setTicketOnHold(ticketOnHold)
    } else {
        setTicketOnHoldText(TicketHoldText.TicketNotOnHold)
        setTicketOnHold(ticketOnHold)
    }
}

export const getPageData = (KYCPlayerData: any[], page: number = 0) => {
    const array: any = [];
    for (let i = page * 10; i < ((page * 10) + 10); i++) {
        if (!(KYCPlayerData[i] === undefined))
            array.push(KYCPlayerData[i]);
    }
    return array;
}
