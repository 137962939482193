import React from "react";
import styles from './css/styles.module.css';
//import { fa-fw, fa-eye } from '@fortawesome/free-solid-svg-icons';

export const CheckboxComponent = ({isChecked, text}: any) => {
    return (
        <>
            {/*<div  className={styles['checkbox-container']}>*/}
            <img className={styles['checkbox-img']}
                 src={isChecked ? '/icons/icon_checkbox_filled_white.svg' : '/icons/icon_checkbox_empty_white.svg'}/>
            {' ' + text}
            {/*</div>*/}
        </>
    );
}