import { useFormik } from "formik";
import moment from "moment";
import React, { SetStateAction, useContext, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaPencilAlt, FaPlus, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { KEY_CODES } from "../../../../../constants.js";
import { BannerContext } from "../../../../../context/BannerContext";
import { SubAccountContext } from "../../../../../context/SubAccountContext";
import { keyPressedType } from "../../../../../services/Commons";
import { TimePaymentAccountPayee } from "../../../../../types/Annuities/PayeeManagement/Payee";
import { TaxPopupType } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/StateTypes";
import { SubAccountTreeNode } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/SubAccountTreeNode";
import { TempPaymentRules } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/TempPaymentRules";
import { PaymentRule } from "../../../../../types/Annuities/entities/payment-rule";
import { PaymentScheduleEntry } from "../../../../../types/Annuities/responses/payment-schedule";
import { TimePaymentAccountResponse } from "../../../../../types/Annuities/responses/time-payment-account";
import { CheckboxComponent } from "../../../../CommonComponents/CheckboxComponent";
import { AccountStatusCmpt } from "../../../Common/AccountStatusCmpt";
import { AddPaymentRuleComponent } from "../AddPaymentRuleComponent";
import { AddSubAccountComponent } from "../AddSubAccountComponent";
import { PaymentRuleComponent } from "../PaymentRuleComponent";
import { SelectPayeeComponent } from "../SelectPayeeComponent";
import { UpdateSubAccountStatusComponent } from "../UpdateSubAccountStatusComponent";
import { UpdateSupportingInformationComponent } from "../UpdateSupportingInformationComponent";
import { ValidationNotificationComponent } from "../ValidationNotificationComponent";
import { validatePaymentRule } from "../functions";
import styles from "./css/styles.module.css";
import { getParentNode, updateSubAccount } from "./functions";

export const AnnuityChildrenSubAccountComponent: React.FC<{
  treeRoot: SubAccountTreeNode;
  widthOffset: number;
  tpaPayeeList: TimePaymentAccountPayee[];
  tpaDetails: TimePaymentAccountResponse;
  tpaPaymentSchedule: PaymentScheduleEntry[];
  isPageInEditModeObj: {
    isPageInEditMode: boolean;
    setIsPageInEditMode: React.Dispatch<SetStateAction<boolean>>;
  };
  parentIdentifier: string;
}> = ({
  treeRoot,
  widthOffset,
  tpaPayeeList,
  tpaDetails,
  tpaPaymentSchedule,
  isPageInEditModeObj,
  parentIdentifier,
  children,
}) => {
  const { account, paymentRules, paymentMade } = treeRoot;
  const { isPageInEditMode, setIsPageInEditMode } = isPageInEditModeObj;
  const {
    irsTaxEnabled,
    dorTaxEnabled,
    supportingInformation1,
    supportingInformation2,
  } = account;

  const bannerContext = useContext(BannerContext);
  const subAccountContext = useContext(SubAccountContext);
  const history = useHistory();
  const idemToken = uuidv4();

  const defaultPopupType: TaxPopupType = {
    display: false,
    data: "",
  };

  const [showSelectPayeeModal, setShowSelectPayeeModal] =
    useState<boolean>(false);
  const [showAddSubAccount, setShowAddSubAccount] = useState<boolean>(false);
  const [showUpdateAccountStatusModal, setShowUpdateAccountStatusModal] =
    useState<boolean>(false);
  const [showAddPaymentRule, setShowAddPaymentRule] = useState<boolean>(false);
  const [showOnHoldAttentionModal, setShowOnHoldAttentionModal] =
    useState<boolean>(false);
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);
  const [payeeObjectToAdd, setPayeeObjectToAdd] =
    useState<TimePaymentAccountPayee>();
  const [isLocalEditable, setIsLocalEditable] = useState<boolean>(false);
  const [existingPaymentRules, setExistingPaymentRules] =
    useState<PaymentRule[]>(paymentRules);
  const [newPaymentRules, setNewPaymentRules] = useState<TempPaymentRules[]>(
    []
  );
  const [existingRulesPassValidation, setExistingRulesPassValidation] =
    useState<boolean>(true);
  const [newRulesPassValidation, setNewRulesPassValidation] =
    useState<boolean>(true);
  const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");
  const [showTaxPopupModal, setShowTaxPopupModal] =
    useState<TaxPopupType>(defaultPopupType);
  const [showSupportingInfoPopupModal, setShowSupportingInfoPopupModal] =
    useState<boolean>(false);

  // initial federalTax and StateTax - coming from global scope
  const initialFederalTax = "24";
  const initialStateTax = "5";

  // package required to focus modal accessibility
  const FocusTrap = require("focus-trap-react");

  // Number indicating level location of sub account
  let subAccountNumberLabel = parentIdentifier?.length ? parentIdentifier : "A";

  // variable for offsetting sub account component
  const baseWidth = 101.2;
  const calculatedWidthOffset = baseWidth - widthOffset;

  // get specific Payee Name for this component
  const getPayeeSpecificName = (): string => {
    const payeeSpecific = tpaPayeeList.find(
      (payee) =>
        account.timePaymentAccountPayeeId === payee.timePaymentAccountPayeeId
    );

    if (payeeSpecific === undefined) {
      return "Name Not Found";
    } else {
      if (payeeSpecific.payeeType === "Individual") {
        return `${payeeSpecific.firstName} ${payeeSpecific.lastName}`;
      } else {
        return `${payeeSpecific.freeformName}`;
      }
    }
  };

  const handleClick = () => {
    return (event: React.MouseEvent) => {
      setShowSupportingInfoPopupModal(true);
    };
  };

  const parentNodeFound = getParentNode(
    subAccountContext.parentNode,
    account.id
  );
  const validatePaymentRules = () => {
    if (parentNodeFound) {
      try {
        validatePaymentRule(
          parentNodeFound,
          tpaDetails,
          newPaymentRules,
          existingPaymentRules
        );
        updateSubAccount(
          existingPaymentRules,
          newPaymentRules,
          formik.values,
          bannerContext,
          history,
          idemToken
        );
      } catch (error) {
        if (error instanceof Error) {
          setShowValidationError(true);
          setValidationErrorMsg(error.message);
        } else {
          bannerContext.setBannerInfo({
            error: true,
            message: "Something went wrong presenting Validation Error",
          });
        }
      }
    }
  };

  const defaultPaymentRule = {
    subAccountId: account.id,
    startDate: moment().add(1, "d").toDate(),
    endDate: moment().add(2, "d").toDate(),
    amount: 0,
  };

  /**FORMIK AREA */
  const formik = useFormik({
    initialValues: { ...account },
    enableReinitialize: true,
    onSubmit: (values) => {},
    validate: (values) => {},
  });

  // function to update STATE at the PARENT level when a field has changed from Child Payment Rule
  const handleExistingFieldValueChange = (
    fieldName: string,
    fieldValue: string,
    paymentRuleId: string
  ) => {
    //find index for where this specific object is inside formik
    const paymentRuleIndex = existingPaymentRules.findIndex(
      (paymentRule: PaymentRule) => paymentRule.id === paymentRuleId
    );

    // make index change
    switch (fieldName) {
      case "amount":
        setExistingPaymentRules((currentState) => {
          currentState[paymentRuleIndex].amount = Math.round(
            parseFloat(fieldValue) * 100
          );
          return currentState;
        });
        break;
      case "startDate":
        setExistingPaymentRules((currentState) => {
          currentState[paymentRuleIndex].startDate =
            fieldValue === ""
              ? ""
              : moment(`${fieldValue} 00:00`, "YYYY-MM-DD HH:mm")
                  .utc()
                  .toDate();
          return currentState;
        });
        break;
      case "endDate":
        setExistingPaymentRules((currentState) => {
          currentState[paymentRuleIndex].endDate =
            fieldValue === ""
              ? ""
              : moment(`${fieldValue} 00:00`, "YYYY-MM-DD HH:mm")
                  .utc()
                  .toDate();
          return currentState;
        });
        break;
      default:
        console.log("No case found...");
    }
  };

  // function to update STATE at the PARENT level when a field has changed from Child Payment Rule
  const handleNewFieldValueChange = (
    fieldName: string,
    fieldValue: string,
    temporaryId: string
  ) => {
    //find index for where this specific object is inside formik
    const paymentRuleIndex = newPaymentRules.findIndex(
      (paymentRule: TempPaymentRules) => paymentRule.temporaryId === temporaryId
    );

    // make index change
    switch (fieldName) {
      case "amount":
        setNewPaymentRules((currentState) => {
          currentState[paymentRuleIndex].amount =
            fieldValue === "" ? 0 : Math.round(parseFloat(fieldValue) * 100);
          return currentState;
        });
        break;
      case "startDate":
        setNewPaymentRules((currentState) => {
          currentState[paymentRuleIndex].startDate =
            fieldValue === ""
              ? ""
              : moment(`${fieldValue} 00:00`, "YYYY-MM-DD HH:mm")
                  .utc()
                  .toDate();
          return currentState;
        });
        break;
      case "endDate":
        setNewPaymentRules((currentState) => {
          currentState[paymentRuleIndex].endDate =
            fieldValue === ""
              ? ""
              : moment(`${fieldValue} 00:00`, "YYYY-MM-DD HH:mm")
                  .utc()
                  .toDate();
          return currentState;
        });
        break;
      default:
        console.log("No case found...");
    }
  };

  return (
    <>
      {/* Pop Up Modal for on hold attention */}
      {showOnHoldAttentionModal && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles["overlay-container"]}>
            <div className={styles["main-modal"]}>
              <div>
                <div className={styles["modal-title"]}>Attention!</div>
                <div className={styles["modal-desc"]}>
                  The primary account is currently on hold. Any sub-accounts
                  created will inherit the on hold status until the primary
                  account is updated to active. Do you want to proceed?
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setShowOnHoldAttentionModal(false);
                  setShowSelectPayeeModal(true);
                  setIsPageInEditMode(true);
                }}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setShowOnHoldAttentionModal(false);
                    setShowSelectPayeeModal(true);
                    setIsPageInEditMode(true);
                  }
                }}
                className={styles["modal-confirm-btn"]}
              >
                Add Sub-Account
              </button>
              <button
                type="button"
                onClick={() => setShowOnHoldAttentionModal(false)}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setShowOnHoldAttentionModal(false);
                  }
                }}
                className={styles["modal-cancel-btn"]}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
      {showTaxPopupModal.display && (
        <FocusTrap focusTrapOptions={{ initialFocus: false }}>
          <div className={styles["overlay-container"]}>
            <div className={styles["main-modal"]}>
              <div>
                <div className={styles["modal-title"]}>Attention!</div>
                <div className={styles["modal-desc"]}>
                  You are about to alter the Tax Designation. This will dictate
                  future payments in this Time Payment Account. Are you sure you
                  want to proceed?
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  if (showTaxPopupModal.data === "irsTaxEnabled") {
                    formik.setFieldValue(
                      "irsTaxEnabled",
                      !formik.values.irsTaxEnabled
                    );
                  } else if (showTaxPopupModal.data === "dorTaxEnabled") {
                    formik.setFieldValue(
                      "dorTaxEnabled",
                      !formik.values.dorTaxEnabled
                    );
                  } else if (
                    showTaxPopupModal.data === "parentTaxResponsibility"
                  ) {
                    formik.setFieldValue(
                      "parentTaxResponsibility",
                      !formik.values.parentTaxResponsibility
                    );
                  }
                  setShowTaxPopupModal(defaultPopupType);
                }}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    if (showTaxPopupModal.data === "irsTaxEnabled") {
                      formik.setFieldValue(
                        "irsTaxEnabled",
                        !formik.values.irsTaxEnabled
                      );
                    } else if (showTaxPopupModal.data === "dorTaxEnabled") {
                      formik.setFieldValue(
                        "dorTaxEnabled",
                        !formik.values.dorTaxEnabled
                      );
                    } else if (
                      showTaxPopupModal.data === "parentTaxResponsibility"
                    ) {
                      formik.setFieldValue(
                        "parentTaxResponsibility",
                        !formik.values.parentTaxResponsibility
                      );
                    }
                    setShowTaxPopupModal(defaultPopupType);
                  }
                }}
                className={styles["modal-confirm-btn-red"]}
              >
                Confirm
              </button>
              <button
                type="button"
                onClick={() => setShowTaxPopupModal(defaultPopupType)}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setShowTaxPopupModal(defaultPopupType);
                  }
                }}
                className={styles["modal-cancel-btn"]}
              >
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      )}
      {showSelectPayeeModal && (
        <SelectPayeeComponent
          setShowSelectPayee={setShowSelectPayeeModal}
          setShowAddSubAccount={setShowAddSubAccount}
          setPayeeObjectToAdd={setPayeeObjectToAdd}
          tpaPayeeList={tpaPayeeList}
          setIsPageInEditMode={setIsPageInEditMode}
        />
      )}
      {showValidationError && (
        <ValidationNotificationComponent
          setValidationErrorVisible={setShowValidationError}
          validationMessage={validationErrorMsg}
        />
      )}
      {showUpdateAccountStatusModal && (
        <UpdateSubAccountStatusComponent
          setShowUpdateAccountStatusModal={setShowUpdateAccountStatusModal}
          subAccountDetails={account}
        />
      )}
      <div
        className={`${styles.page_container}`}
        style={{ float: "right", width: `${calculatedWidthOffset}%` }}
      >
        <div
          className={`${styles.sub_account_header} rounded d-flex justify-content-between align-items-center mt-3`}
        >
          <div className="d-flex align-items-center ml-3">
            <div
              className={`${styles.primary_account_text} font-weight-bold mr-3`}
            >
              Sub-Account {subAccountNumberLabel}
            </div>
            <div className="d-flex align-items-center">
              <div className={`${styles.primary_account_text} mr-2`}>
                {getPayeeSpecificName()}
              </div>
              {isLocalEditable ? (
                <div className={`${styles.pencil_icon}`}>
                  <FaPencilAlt onClick={handleClick()} />
                </div>
              ) : (
                <div className={`${styles.icon_container}`}>
                  <AiOutlineQuestionCircle size={25} />
                  <div
                    className={`${styles.notes_container} rounded text-left ml-2 px-3 py-3`}
                  >
                    <div className="font-weight-bold">
                      Supporting Information
                    </div>
                    {!account.supportingInformation1 &&
                    !account.supportingInformation2 ? (
                      <>
                        <p className="my-2">
                          No supporting information has been added.
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="my-2">{account.supportingInformation1}</p>
                        <p>{account.supportingInformation2}</p>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`d-flex align-items-center`}>
            <div className="mr-2">
              <AccountStatusCmpt
                status={account.acctStatus}
                notes={account.notes}
              />
            </div>
            {/* Update Status button */}
            <button
              className={
                isPageInEditMode || account.acctStatus === "Completed"
                  ? `${styles.update_status_btn_disabled}`
                  : `${styles.update_btn}`
              }
              disabled={isPageInEditMode || account.acctStatus === "Completed"}
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  setShowUpdateAccountStatusModal(true);
                }
              }}
              onClick={() => setShowUpdateAccountStatusModal(true)}
            >
              Update Status
            </button>
            {/* Edit button */}
            {!isLocalEditable && (
              <button
                type="button"
                className={
                  isPageInEditMode && !isLocalEditable
                    ? `${styles.dark_btn_disabled}`
                    : `${styles.dark_btn}`
                }
                disabled={isPageInEditMode && !isLocalEditable}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setIsLocalEditable(true);
                    setIsPageInEditMode(true);
                  }
                }}
                onClick={() => {
                  setIsLocalEditable(true);
                  setIsPageInEditMode(true);
                }}
              >
                Edit
              </button>
            )}
            {/* Save button */}
            {isLocalEditable && (
              <>
                <button
                  type="button"
                  className={
                    !existingRulesPassValidation ||
                    (newPaymentRules.length > 0 && !newRulesPassValidation) ||
                    Object.keys(formik.errors).length !== 0
                      ? `${styles.save_btn_disabled}`
                      : `${styles.save_btn}`
                  }
                  disabled={
                    !existingRulesPassValidation ||
                    (newPaymentRules.length > 0 && !newRulesPassValidation) ||
                    Object.keys(formik.errors).length !== 0
                  }
                  onKeyPress={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      validatePaymentRules();
                    }
                  }}
                  onClick={() => {
                    validatePaymentRules();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className={`${styles.cancel_btn}`}
                  onKeyPress={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      setIsLocalEditable(false);
                      setIsPageInEditMode(false);
                      history.go(0);
                    }
                  }}
                  onClick={() => {
                    setIsLocalEditable(false);
                    setIsPageInEditMode(false);
                    history.go(0);
                  }}
                >
                  <FaTimes />
                </button>
              </>
            )}
            {/* Add Sub Account button */}
            <button
              type="button"
              disabled={isPageInEditMode}
              className={`${styles.light_btn}`}
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  if (account.acctStatus === "On Hold") {
                    setShowOnHoldAttentionModal(true);
                  } else {
                    setShowSelectPayeeModal(true);
                    setIsPageInEditMode(true);
                  }
                }
              }}
              onClick={() => {
                if (account.acctStatus === "On Hold") {
                  setShowOnHoldAttentionModal(true);
                } else {
                  setShowSelectPayeeModal(true);
                  setIsPageInEditMode(true);
                }
              }}
            >
              Add Sub-Account <FaPlus />
            </button>
          </div>
        </div>
        <div className={`${styles.sub_account_body}`}>
          <div className={`${styles.grid_col_2}`}>
            {/* Column 1 */}
            <div>
              {existingPaymentRules.map((paymentRule, index) => (
                <PaymentRuleComponent
                  paymentRule={paymentRule}
                  allExistingPaymentRules={existingPaymentRules}
                  handleFieldValueChange={handleExistingFieldValueChange}
                  setExistingRulesPassValidation={
                    setExistingRulesPassValidation
                  }
                  index={index + 1}
                  tpaPaymentSchedule={tpaPaymentSchedule}
                  isEditable={isLocalEditable && !paymentMade}
                  key={index}
                />
              ))}
              {showAddPaymentRule &&
                newPaymentRules.map((paymentRule, index) => (
                  <AddPaymentRuleComponent
                    tpaPaymentSchedule={tpaPaymentSchedule}
                    handleFieldValueChange={handleNewFieldValueChange}
                    individualPaymentRule={paymentRule}
                    allNewPaymentRules={newPaymentRules}
                    setAllPaymentRules={setNewPaymentRules}
                    setNewRulesPassValidation={setNewRulesPassValidation}
                    ruleNumber={existingPaymentRules.length + index + 1}
                    key={index}
                  />
                ))}

              {isLocalEditable && !paymentMade && (
                <div
                  className={`${styles.add_rule_container}`}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      setShowAddPaymentRule(true);
                      setNewPaymentRules((prevState) => {
                        const newPaymentRulesArray = prevState.concat([
                          { temporaryId: uuidv4(), ...defaultPaymentRule },
                        ]);
                        return newPaymentRulesArray;
                      });
                    }
                  }}
                  onClick={() => {
                    setShowAddPaymentRule(true);
                    setNewPaymentRules((prevState) => {
                      const newPaymentRulesArray = prevState.concat([
                        { temporaryId: uuidv4(), ...defaultPaymentRule },
                      ]);
                      return newPaymentRulesArray;
                    });
                  }}
                >
                  <div>Add Another Rule</div>
                  <FaPlus />
                </div>
              )}
            </div>
            <div className={`${styles.tax_info_container}`}>
              <h4>Tax Designation</h4>
              <p>
                Setting these tax designations relates only to this annuity
                account.
              </p>
              <div className={`d-flex`}>
                {/* Checkboxes */}
                <div>
                  <button
                    className={
                      parentNodeFound?.account.parentTaxResponsibility
                        ? `${styles.checkbox_disabled} mr-2`
                        : isLocalEditable
                        ? `${styles.checkbox} mr-2`
                        : `${styles.checkbox_disabled} mr-2`
                    }
                    disabled={
                      parentNodeFound?.account.parentTaxResponsibility
                        ? true
                        : isLocalEditable && !paymentMade
                        ? false
                        : true
                    }
                    onKeyDown={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        const updatedTaxPopupData: TaxPopupType = {
                          display: true,
                          data: "irsTaxEnabled",
                        };
                        setShowTaxPopupModal(updatedTaxPopupData);
                      }
                    }}
                    onClick={() => {
                      const updatedTaxPopupData: TaxPopupType = {
                        display: true,
                        data: "irsTaxEnabled",
                      };
                      setShowTaxPopupModal(updatedTaxPopupData);
                    }}
                  >
                    <div className="d-flex">
                      <p>Federal {initialFederalTax}% </p>
                      {parentNodeFound?.account.parentTaxResponsibility ? (
                        <CheckboxComponent isChecked={false} disabled={true} />
                      ) : isLocalEditable ? (
                        <CheckboxComponent
                          isChecked={formik.values.irsTaxEnabled}
                        />
                      ) : (
                        <CheckboxComponent
                          isChecked={irsTaxEnabled}
                          disabled={true}
                        />
                      )}
                    </div>
                  </button>
                  <button
                    className={
                      parentNodeFound?.account.parentTaxResponsibility
                        ? `${styles.checkbox_disabled} mr-2`
                        : isLocalEditable === true
                        ? `${styles.checkbox} mr-2`
                        : `${styles.checkbox_disabled} mr-2`
                    }
                    disabled={
                      parentNodeFound?.account.parentTaxResponsibility
                        ? true
                        : isLocalEditable && !paymentMade
                        ? false
                        : true
                    }
                    onKeyDown={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        const updatedTaxPopupData: TaxPopupType = {
                          display: true,
                          data: "dorTaxEnabled",
                        };
                        setShowTaxPopupModal(updatedTaxPopupData);
                      }
                    }}
                    onClick={() => {
                      const updatedTaxPopupData: TaxPopupType = {
                        display: true,
                        data: "dorTaxEnabled",
                      };
                      setShowTaxPopupModal(updatedTaxPopupData);
                    }}
                  >
                    <div className="d-flex">
                      <p>State {initialStateTax}% </p>
                      {parentNodeFound?.account.parentTaxResponsibility ? (
                        <CheckboxComponent isChecked={false} disabled={true} />
                      ) : isLocalEditable ? (
                        <CheckboxComponent
                          isChecked={formik.values.dorTaxEnabled}
                        />
                      ) : (
                        <CheckboxComponent
                          isChecked={dorTaxEnabled}
                          disabled={true}
                        />
                      )}
                    </div>
                  </button>
                </div>
              </div>
              {/* Parent Tax Responsibility Section */}
              <div className={`d-flex align-items-center`}>
                <button
                  className={
                    parentNodeFound?.account.parentTaxResponsibility
                      ? `${styles.parent_tax_checkbox_disabled}`
                      : isLocalEditable
                      ? `${styles.parent_tax_checkbox}`
                      : `${styles.parent_tax_checkbox_disabled}`
                  }
                  disabled={
                    parentNodeFound?.account.parentTaxResponsibility
                      ? true
                      : isLocalEditable && !paymentMade
                      ? false
                      : true
                  }
                  onKeyDown={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      const updatedTaxPopupData: TaxPopupType = {
                        display: true,
                        data: "parentTaxResponsibility",
                      };
                      setShowTaxPopupModal(updatedTaxPopupData);
                    }
                  }}
                  onClick={() => {
                    const updatedTaxPopupData: TaxPopupType = {
                      display: true,
                      data: "parentTaxResponsibility",
                    };
                    setShowTaxPopupModal(updatedTaxPopupData);
                  }}
                >
                  {parentNodeFound?.account.parentTaxResponsibility ? (
                    <CheckboxComponent
                      isChecked={
                        parentNodeFound?.account.parentTaxResponsibility
                      }
                      disabled={true}
                    />
                  ) : (
                    <CheckboxComponent
                      isChecked={formik.values.parentTaxResponsibility}
                    />
                  )}
                </button>
                <div
                  className={
                    parentNodeFound?.account.parentTaxResponsibility
                      ? `${styles.parent_tax_label_disabled}`
                      : isLocalEditable
                      ? `${styles.parent_tax_label}`
                      : `${styles.parent_tax_label_disabled}`
                  }
                >
                  <em className={`${styles.parent_tax_disclaimer}`}>
                    The parent account is assuming all tax withholdings on these
                    payments
                  </em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddSubAccount && (
        <AddSubAccountComponent
          parentNode={treeRoot}
          payee={payeeObjectToAdd}
          setShowAddSubAccount={setShowAddSubAccount}
          tpaDetails={tpaDetails}
          tpaPaymentSchedule={tpaPaymentSchedule}
          predeterminedWidth={calculatedWidthOffset}
          setIsPageInEditMode={setIsPageInEditMode}
        />
      )}
      {showSupportingInfoPopupModal && (
        <UpdateSupportingInformationComponent
          formik={formik}
          setShowSupportingInfoPopupModal={setShowSupportingInfoPopupModal}
        />
      )}
      {children}
    </>
  );
};
