import * as React from 'react'
import {
  AllPromotionsResponse,
  PromotionsDateRangeReportProps,
} from '../../../api/promotions-fetcher/constants'
import { PromotionsDateRangeReportUI } from './promotions-date-range-report-ui'
import { useFetchPromotionDateRangeReport } from '../../../api/promotions-fetcher/use-fetch-promotions-reporting'
import { BannerContext } from '../../../context/BannerContext'
type PromotionsDateRangeReportContainerProps = {
  promotions?: AllPromotionsResponse
}
export const PromotionsDateRangeReportContainer = (
  props: PromotionsDateRangeReportContainerProps
) => {
  const bannerContext = React.useContext(BannerContext)
  const { isLoading, error, generateReport, response } =
    useFetchPromotionDateRangeReport()
  const handleReportGeneration = (props: PromotionsDateRangeReportProps) =>
    generateReport(props)
  React.useEffect(() => {
    if (response && response.status && response.status < 299) {
      bannerContext.setBannerInfo({
        message:
          'The promotion report you have requested has been emailed to you.',
        error: false,
      })
    }
  }, [response])
  React.useEffect(() => {
    if (error) {
      bannerContext.setBannerInfo({
        message: error,
        error: true,
      })
    }
  }, [error])
  return (
    <PromotionsDateRangeReportUI
      promotions={props.promotions}
      reportLoading={isLoading}
      reportError={error}
      requestReportGeneration={handleReportGeneration}
    />
  )
}
