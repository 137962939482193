// pagination
const normalizePage = (totalPages: number, currentPage: number) => {
  const number: number = totalPages - currentPage * 10;
  if (number > 10) {return currentPage * 10 + 10;}
  else {return totalPages;}
};

const getPageData = (KYCPlayerData: any[], page: number = 0) => {
  const array: any = [];
  for (let i = page * 10; i < page * 10 + 10; i++) {
    if (!(KYCPlayerData[i] === undefined)) {array.push(KYCPlayerData[i]);}
  }
  return array;
};

export { normalizePage, getPageData };
