import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import { useEffect } from 'react'
import { ReissueRunHistoryResponse } from './api-types'

const getHistoricalReissueRunsUrl = `${config.SERVER_BASE_URL}/api/v1/checkRun/reissue/history`

export const useFetchHistoricalReissueRuns = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<ReissueRunHistoryResponse>()
  
  useEffect(() => {
    fetchUrl(getHistoricalReissueRunsUrl, 'GET')
  }, [])

  return {
    isLoading,
    error,
    data
  }
}