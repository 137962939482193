import * as React from 'react'
import { AutoFocusInside } from 'react-focus-lock'
import moment from 'moment'
import { useFormik } from 'formik'
import { CircularProgress } from '@material-ui/core'
import { CreateCampaignDetails } from '../../../../api/promotions-fetcher/coupon-campaigns/constants'
import { ArtworkTemplateExtension } from '../../../../api/promotions-fetcher/coupon-campaigns/use-fetch-campaigns'
import {
  DEFAULT_EXPRESS_MAX_PAYLOAD_SIZE_BYTES,
  FormFileUpload,
} from '../../../FormFileUploadComponent/form-file-upload-component-ui'
import { SideBarDateAndTimeInput } from '../../../SidebarFormComponents/side-bar-date-and-time-input-component/side-bar-date-and-time-ui'
import { SideBarDateInput } from '../../../SidebarFormComponents/side-bar-date-input-component/side-bar-date-input-ui'
import { SideBarTextArea } from '../../../SidebarFormComponents/side-bar-text-area-component/side-bar-text-area-ui'
import { SideBarTextInput } from '../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'
import { CampaignsArtworkRequirements } from '../../campaigns-artwork-requirements/campaigns-artwork-requirements-ui'
import { CampaignSideBarPlayersContainer } from '../campaign-side-bar-barcode-details/campaign-side-bar-players-container'
import { CampaignsSideBarSubmitSection } from '../campaigns-side-bar-submit-section/campaigns-side-bar-submit-ui'
import {
  CampaignValidation,
  validateCreateCampaignForm,
} from '../side-bar-validation-functions'
import './styles.scss'

const ROOT_CLASS = 'new-campaign-sidebar'
const HEADER_CLASS = `${ROOT_CLASS}__header`
const FORM_CLASS = `${ROOT_CLASS}__form`
const FORM_START_AND_END_DATE_CLASS = `${FORM_CLASS}__start-and-end-date`
const ROOT_CLASS_FORM_ARTWORK = `${FORM_CLASS}__artwork`

export type NewCampaignSideBarProps = {
  defaultLegalCopy: string
  error?: string
  legalCopyLoading: boolean
  submissionLoading: boolean
  closeSidebar: () => void
  submitCampaign: (
    createCampaignDetails: CreateCampaignDetails,
    imageFile?: File
  ) => Promise<void>
  fetchArtworkRequirements: (
    fileType: ArtworkTemplateExtension
  ) => Promise<void>
}

const getDateEpoch = (formValue: string) => new Date(formValue).getTime() / 1000

function formatDateForDateAndTimeInput(date: string) {
  return new Date(date).toISOString().substring(0, 16)
}

export const NewCampaignSideBarUI = (props: NewCampaignSideBarProps) => {
  const [designRequirementsIsOpen, setDesignRequirementsIsOpen] =
    React.useState<boolean>(false)

  const initialValues: CampaignValidation = {
    campaignIdentifier: '',
    campaignName: '',
    startDate: '',
    endDate: '',
    expirationDate: '',
    legalText: props.defaultLegalCopy,
    campaignImage: undefined,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const submissionPayload: CreateCampaignDetails = {
        campaignIdentifier: values.campaignIdentifier,
        campaignName: values.campaignName,
        startDateEpochSeconds: getDateEpoch(values.startDate),
        endDateEpochSeconds: getDateEpoch(values.endDate),
        expirationDateEpochSeconds: getDateEpoch(values.expirationDate),
        legalText:
          values.legalText !== props.defaultLegalCopy
            ? values.legalText
            : undefined,
      }
      await props.submitCampaign(submissionPayload, values.campaignImage)
    },
    validate: validateCreateCampaignForm,
  })

  return (
    <>
      <CampaignsArtworkRequirements
        isOpen={designRequirementsIsOpen}
        closeModal={() => setDesignRequirementsIsOpen(false)}
        fetchArtworkRequirements={props.fetchArtworkRequirements}
      />
      <div className={ROOT_CLASS}>
        <header className={HEADER_CLASS}>
          <div className={`${HEADER_CLASS}__title`}>New Campaign</div>
          <button
            className={`${HEADER_CLASS}__dismiss-button`}
            onClick={props.closeSidebar}
            aria-label="Close"
          >
            <div>Close</div>
          </button>
        </header>
        {props.legalCopyLoading ? (
          <div className={`${ROOT_CLASS}__loading`}>
            <CircularProgress
              id="progress"
              style={{ color: '#1e4b75' }}
              size={`14em`}
              thickness={2}
            />
          </div>
        ) : (
          <form
            className={FORM_CLASS}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <AutoFocusInside>
              <SideBarTextInput
                label={'Campaign Name'}
                name={'campaignName'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.campaignName}
                error={formik.errors.campaignName}
                touched={formik.touched.campaignName}
                disabled={props.submissionLoading}
              />
            </AutoFocusInside>
            <div className={FORM_START_AND_END_DATE_CLASS}>
              <SideBarDateInput
                label={'Campaign Start & End Dates'}
                name={'startDate'}
                dependsOnOtherDate={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.startDate}
                error={formik.errors.startDate}
                touched={formik.touched.startDate}
                minDate={moment().format('YYYY-MM-DD')}
                disabled={props.submissionLoading}
              />
              <div className={`${FORM_START_AND_END_DATE_CLASS}__dash`}>-</div>
              <div className={`${FORM_START_AND_END_DATE_CLASS}__end`}>
                <SideBarDateInput
                  label={''}
                  name={'endDate'}
                  dependsOnOtherDate={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                  error={formik.errors.endDate}
                  touched={formik.touched.endDate}
                  minDate={formik.values.startDate}
                  disabled={
                    formik.values.startDate === '' || props.submissionLoading
                  }
                />
              </div>
            </div>
            <SideBarDateAndTimeInput
              label={'Coupon Expiration Date'}
              name={'expirationDate'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.expirationDate}
              error={formik.errors.expirationDate}
              touched={formik.touched.expirationDate}
              minDate={
                formik.values.endDate
                  ? formatDateForDateAndTimeInput(formik.values.endDate)
                  : ''
              }
              disabled={formik.values.endDate === '' || props.submissionLoading}
            />
            <SideBarTextArea
              label={'Legal Text'}
              name={'legalText'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.legalText}
              error={formik.errors.legalText}
              touched={formik.touched.legalText}
              disabled={props.submissionLoading}
            />
            <div className={ROOT_CLASS_FORM_ARTWORK}>
              <FormFileUpload
                label={'Artwork'}
                name={'campaignImage'}
                onChange={(file: File | File[]) => {
                  formik.setFieldValue('campaignImage', file)
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.campaignImage}
                onError={(errorMessage?: string) =>
                  formik.setFieldError('campaignImage', errorMessage)
                }
                acceptedFileTypes={{ 'image/png': ['.png'] }}
                dropzoneAriaLabel="Campaign artwork dropzone"
                imagePreview={true}
                maxFiles={1}
                maxPayloadSizeBytes={DEFAULT_EXPRESS_MAX_PAYLOAD_SIZE_BYTES}
                disabled={props.submissionLoading}
                touched={Boolean(
                  formik.touched.campaignImage ||
                    (formik.errors.campaignImage &&
                      formik.errors.campaignImage !== 'This field is required')
                )}
              />
              <button
                type="button"
                className={`${ROOT_CLASS_FORM_ARTWORK}__link`}
                onClick={() => setDesignRequirementsIsOpen(true)}
                aria-label="Artwork design requirements"
              >
                Artwork design requirements
              </button>
            </div>
            <SideBarTextInput
              label={'Campaign Promotion ID'}
              name={'campaignIdentifier'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.campaignIdentifier}
              error={formik.errors.campaignIdentifier}
              touched={formik.touched.campaignIdentifier}
              disabled={props.submissionLoading}
              maxLength={5}
            />
            {props.error && (
              <span className={`${FORM_CLASS}__error-text`}>
                Error submitting campaign: "{props.error}"
              </span>
            )}
            <CampaignsSideBarSubmitSection
              loading={props.submissionLoading}
              closeSideBar={props.closeSidebar}
            />
          </form>
        )}
        <CampaignSideBarPlayersContainer
          closeSideBar={props.closeSidebar}
          disabled
        />
      </div>
    </>
  )
}
