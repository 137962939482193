import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../../icons/Icon_arrows_carrot_white-left.svg";
import {onSubmit, setFieldValue, setVariables, validate} from "./functions";
import {pulltabRoutePath} from "../../../../../config.js";
import {FaPlus} from 'react-icons/fa';
import {CircularProgress} from "@material-ui/core";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";
import {
    GenericErrorDialogComponent
} from "../../../../../components/CommonComponents/GenericErrorDialogComponent";
import {NotificationBannerComponent} from "../../../../../components/HomeComponent/NotificationBannerComponent";
import {
    PullTabGameSearchBarComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Games/PullTabGameSearchBarComponent";
import {
    PullTabGameSearchResultComponent
} from "../../../../../components/CharitableGamingComponents/PullTab/Games/PullTabGameSearchResultComponent";

export const PullTabGamesPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const formik = useFormik({
            initialValues: {
                stockNumber: '',
                gameName: ''
            },
            validate,
            onSubmit
        });

        const {filteredPullTabGames, notificationBanner} = charitableGamingStore;

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(0);

        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

        useEffect(() => {
            if (requestCompleted === false) {
                setCurrentPage(0);
                charitableGamingStore.getAllPullTabGames(setRequestCompleted);
                //charitableGamingStore.reset();
            }
        }, []);


        const hideNotificationBanner = () => {
            charitableGamingStore.showNotificationBanner({show: false, text: ''});
        }

        setVariables(charitableGamingStore, setCurrentPage, setShowErrorDialog);

        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['pulltab-games-page-container']} ${styles['']}`}
                     onClick={() => {

                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['pulltab-games-page-back-button-container']} ${styles['']}`}>
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${pulltabRoutePath}/manage`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${pulltabRoutePath}/manage`);
                            }}
                        />
                        <div
                            className={`${styles['pulltab-games-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${pulltabRoutePath}/manage`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${pulltabRoutePath}/manage`);
                            }}
                        >Back
                            to Pull Tab Management
                        </div>
                    </div>
                    <div className={`${styles['pulltab-games-page-ribbon-container']} ${styles['']}`}>
                        <div tabIndex={0}
                             className={`${styles['pulltab-games-page-ribbon-title']} ${styles['']}`}>Pull Tab Games
                        </div>
                        <div className={`${styles['pulltab-games-page-ribbon-buttons-container']}`}>
                            {checkRenderPermissions(permissions.CAN_CREATE_PULL_TAB_GAMES, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['pulltab-games-page-ribbon-create-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             history.push(`${pulltabRoutePath}/create`);
                                         }
                                     }}
                                     onClick={() => {
                                         history.push(`${pulltabRoutePath}/create`);
                                     }}>
                                    <FaPlus style={{cursor: "pointer"}}/>
                                    <div
                                        className={`${styles['pulltab-games-page-ribbon-create-license-button-label']} ${styles['']}`}>Create
                                        Pull Tab Game
                                    </div>
                                </div> : null}

                        </div>

                    </div>

                    <div
                        className={`${styles['pulltab-games-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                        <PullTabGameSearchBarComponent formik={formik} setFieldValue={setFieldValue}/>
                    </div>
                    {filteredPullTabGames.length <= 0 ?
                        <div className={`${styles['pulltab-games-page-search-no-results-container']}`}>
                            <div className={`${styles['pulltab-games-page-search-no-results-container-text']}`}>
                                No Results. Please try a different search.
                            </div>
                        </div>
                        :
                        <PullTabGameSearchResultComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>

                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        text={notificationBanner.text}
                        type={notificationBanner.type}
                        handleHide={hideNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);