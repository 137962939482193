import * as React from 'react'
import FocusLock from 'react-focus-lock'
import {
  PromotionDrawingWinner,
  WinnerStatus,
} from '../../../../../api/promotions-fetcher/constants'
import { ActionMenuIcon } from '../../../../../icons/action-menu-icon'
import { WinnersManagementContactChecklistContainer } from '../../winners-management-modals/contact-checklist/winners-management-contact-checklist-container'
import { WinnersManagementProcessClaimContainer } from '../../winners-management-modals/process-claim/winners-management-process-claim-container'
import { WinnersManagementPromoteAlternateContainer } from '../../winners-management-modals/promote-alternate/winners-management-promote-alternate-container'
import './styles.scss'

export type WinnersManagementListRowActionMenuUIProps = {
  refreshTiers: () => Promise<void>
  winner: PromotionDrawingWinner
  menuItemsDisabled: boolean
  prizeAmountInCents: number
}

const ROOT_CLASS = 'winners-management-list-row-action-menu'
const POPUP_CLASS = `${ROOT_CLASS}__popup`
const MENU_ITEM_CLASS = `${POPUP_CLASS}__menu-item`

const getVisibilityClass = (isOpen: boolean) =>
  isOpen ? `${POPUP_CLASS}--open` : `${POPUP_CLASS}--closed`

const getMenuItemEnabledClass = (enabled: boolean) =>
  enabled ? `${MENU_ITEM_CLASS}--enabled` : `${MENU_ITEM_CLASS}--disabled`

export const WinnersManagementListRowActionMenuUI = (
  props: WinnersManagementListRowActionMenuUIProps
) => {
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState<boolean>(false)

  const processingDisabled =
    props.menuItemsDisabled || props.winner.status !== WinnerStatus.PENDING

  const processingUnavailable = props.prizeAmountInCents <= 60000

  const [contactChecklistIsOpen, setContactChecklistIsOpen] =
    React.useState<boolean>(false)
  const [processClaimIsOpen, setProcessClaimIsOpen] =
    React.useState<boolean>(false)
  const [promoteAlternateIsOpen, setPromoteAlternateIsOpen] =
    React.useState<boolean>(false)

  const showContactChecklistModal = () => {
    setActionMenuIsOpen(false)
    setContactChecklistIsOpen(true)
  }

  const showProcessClaimModal = () => {
    setActionMenuIsOpen(false)
    setProcessClaimIsOpen(true)
  }

  const showPromoteAlternateModal = () => {
    setActionMenuIsOpen(false)
    setPromoteAlternateIsOpen(true)
  }

  return (
    <>
      {contactChecklistIsOpen && (
        <WinnersManagementContactChecklistContainer
          isOpen={contactChecklistIsOpen}
          handleCloseModal={async (changed: boolean) => {
            setContactChecklistIsOpen(false)
            if (changed) {
              await props.refreshTiers()
            }
          }}
          winner={props.winner}
        />
      )}
      {processClaimIsOpen && (
        <WinnersManagementProcessClaimContainer
          isOpen={processClaimIsOpen}
          closeModal={() => setProcessClaimIsOpen(false)}
          winnerId={props.winner.id}
        />
      )}
      {promoteAlternateIsOpen && (
        <WinnersManagementPromoteAlternateContainer
          isOpen={promoteAlternateIsOpen}
          closeModal={() => setPromoteAlternateIsOpen(false)}
          winnerId={props.winner.id}
        />
      )}
      <div className={ROOT_CLASS}>
        <button
          className={`${ROOT_CLASS}__icon`}
          onClick={() => setActionMenuIsOpen(true)}
          aria-label={`Winners Management Action Menu Icon for ${props.winner.id}`}
        >
          <ActionMenuIcon />
        </button>
        {actionMenuIsOpen && (
          <div
            className={`${ROOT_CLASS}__overlay`}
            onClick={() => setActionMenuIsOpen(false)}
          />
        )}
        <FocusLock disabled={!actionMenuIsOpen} autoFocus={false}>
          <div
            className={`${POPUP_CLASS} ${getVisibilityClass(actionMenuIsOpen)}`}
          >
            <button
              className={`${MENU_ITEM_CLASS} ${getMenuItemEnabledClass(
                !props.menuItemsDisabled
              )}`}
              onClick={showContactChecklistModal}
              disabled={props.menuItemsDisabled}
              aria-label={`Open contact checklist for winner id ${props.winner.id}`}
            >
              Contact Checklist
            </button>
            {!processingUnavailable && (
              <>
                <button
                  className={`${MENU_ITEM_CLASS} ${getMenuItemEnabledClass(
                    !processingDisabled
                  )}`}
                  onClick={showProcessClaimModal}
                  disabled={processingDisabled}
                  aria-label={`Process claim for winner id ${props.winner.id}`}
                >
                  Process Claim
                </button>
                <button
                  className={`${MENU_ITEM_CLASS} ${getMenuItemEnabledClass(
                    !processingDisabled
                  )}`}
                  onClick={showPromoteAlternateModal}
                  disabled={processingDisabled}
                  aria-label={`Promote alternate for winner id ${props.winner.id}`}
                >
                  Promote Alternate
                </button>
              </>
            )}
          </div>
        </FocusLock>
      </div>
    </>
  )
}
