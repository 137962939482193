import * as React from 'react'
import { AllPromotionsResponse } from '../../../../api/promotions-fetcher/constants'
import {
  useFetchCheckLoadPreviewReport,
  useFetchDownloadCheckLoadPreviewReportCSV,
} from '../../../../api/promotions-fetcher/use-fetch-winners-management'
import { WinnersManagementWeeklyRunUI } from './winners-management-weekly-run-ui'

type WinnersManagementWeeklyRunContainerProps = {
  promotionsLoading: boolean
  promotions?: AllPromotionsResponse
  promotionsError?: string
}

export const WinnersManagementWeeklyRunContainer = (
  props: WinnersManagementWeeklyRunContainerProps
) => {
  const {
    isLoading: checkRunLoading,
    error: checkRunError,
    data: checkRunReport,
    generateReport,
  } = useFetchCheckLoadPreviewReport()

  const { fetchDownloadCheckLoadPreviewReportCSV } =
    useFetchDownloadCheckLoadPreviewReportCSV()

  return (
    <WinnersManagementWeeklyRunUI
      {...props}
      checkRunLoading={checkRunLoading}
      checkRunReport={checkRunReport}
      checkRunError={checkRunError}
      generateCheckRunReport={generateReport}
      downloadCheckRunCSV={fetchDownloadCheckLoadPreviewReportCSV}
    />
  )
}
