import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import {
    HoursOfOperationCodes,
    HoursOfOperationMapping,
    OccasionDayCodes,
    StaffTrained,
    StaffTrainedMapping
} from "../../../../../Constants/CharitableConstant.js";
import InputMask from 'react-input-mask';

export const UpdateBeanoGameInformationComponent = ({formik, setFiledValue,}: any) => {

    return (
        <>
            <form className={styles['update-beano-game-info-component-container']}>
                <div tabIndex={0} className={styles['update-beano-game-info-component-container-title']}>Organization</div>
                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Occasion Address*</div>
                    <input
                        autoComplete={"text"}
                        id="licenseDetails.occasionAddress.streetAddress"
                        name="licenseDetails.occasionAddress.streetAddress"
                        className={styles["mslc-input-box"]}
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.occasionAddress.streetAddress}
                        onBlur={formik.handleBlur}
                        type="text"
                    />
                    {(formik.touched.licenseDetails?.occasionAddress?.streetAddress && formik.errors.licenseDetails?.occasionAddress?.streetAddress) ?
                        <ErrorMessageFieldCommonComponent
                            streetAddress={formik.errors.licenseDetails?.occasionAddress?.streetAddress}/> : null}
                </div>
                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Occasion Day*</div>
                    <select
                        id="licenseDetails.occasionDay"
                        name="licenseDetails.occasionDay"
                        onChange={(e: any) => setFiledValue(e)}
                        onBlur={formik.handleBlur}
                        placeholder="Select"
                        className={styles["mslc-input-box"]}>
                        <option value="" selected disabled>Select...</option>
                        {
                            OccasionDayCodes.map((dayCode: string) =>
                                <option
                                    selected={formik.values.licenseDetails.occasionDay.toLowerCase() === dayCode.toLowerCase()}
                                    value={dayCode}>{dayCode}
                                </option>)
                        }

                    </select>
                    {(formik.touched.licenseDetails?.occasionDay && formik.errors.licenseDetails?.occasionDay) ?
                        <ErrorMessageFieldCommonComponent
                            occasionDay={formik.errors.licenseDetails?.occasionDay}/> : null}
                </div>
                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>City/Town*</div>
                    <input
                        autoComplete={"text"}
                        id="licenseDetails.occasionAddress.city"
                        name="licenseDetails.occasionAddress.city"
                        value={formik.values.licenseDetails.occasionAddress.city}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["mslc-input-box"]}`}
                        type="text"
                    />
                    {(formik.touched.licenseDetails?.occasionAddress?.city && formik.errors.licenseDetails?.occasionAddress?.city) ?
                        <ErrorMessageFieldCommonComponent
                            city={formik.errors.licenseDetails?.occasionAddress?.city}/> : null}
                </div>

                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Hours of Operation*
                    </div>
                    <select
                        id="licenseDetails.occasionTime"
                        name="licenseDetails.occasionTime"
                        onChange={(e: any) => setFiledValue(e)}
                        onBlur={formik.handleBlur}
                        placeholder="Select"
                        className={styles["mslc-input-box"]}>
                        <option value="" selected disabled>Select...</option>
                        {
                            HoursOfOperationCodes.map((operationCodes: string) =>
                                <option
                                    selected={HoursOfOperationMapping[formik.values.licenseDetails.occasionTime] === operationCodes}
                                    value={operationCodes}>{operationCodes}
                                </option>)
                        }
                    </select>

                    {(formik.touched.licenseDetails?.occasionTime && formik.errors.licenseDetails?.occasionTime) ?
                        <ErrorMessageFieldCommonComponent
                            occasionTime={formik.errors.licenseDetails?.occasionTime}/> : null}
                </div>

                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Zipcode*</div>
                    <input
                        autoComplete={"text"}
                        id="licenseDetails.occasionAddress.zip"
                        name="licenseDetails.occasionAddress.zip"
                        value={formik.values.licenseDetails.occasionAddress.zip}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["mslc-input-box"]}`}
                        type="text"
                        placeholder="Ex. 12345"
                        maxLength={5}
                    />
                    {(formik.touched.licenseDetails?.occasionAddress?.zip && formik.errors.licenseDetails?.occasionAddress?.zip) ?
                        <ErrorMessageFieldCommonComponent
                            zip={formik.errors.licenseDetails?.occasionAddress?.zip}/> : null}
                </div>


                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Start Date*</div>
                    <input
                        autoComplete={"off"}
                        id="licenseDetails.startDate"
                        name="licenseDetails.startDate"
                        value={formik.values.licenseDetails.startDate}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => setFiledValue(e)}
                        className={`${styles["mslc-input-box"]}`}
                        type="date"/>
                    {(formik.errors.licenseDetails?.startDate) ?
                        <ErrorMessageFieldCommonComponent
                            startDate={formik.errors.licenseDetails?.startDate}/> : null}
                </div>


                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Beano Hall Phone*</div>
                    <InputMask
                        autoComplete={"off"}
                        id="licenseDetails.beanoHallPhone"
                        name="licenseDetails.beanoHallPhone"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.beanoHallPhone}
                        className={styles["mslc-input-box"]}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder="(000) 000-0000"
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    {(formik.touched.licenseDetails?.beanoHallPhone && formik.errors.licenseDetails?.beanoHallPhone) ?
                        <ErrorMessageFieldCommonComponent
                            beanoHallPhone={formik.errors.licenseDetails?.beanoHallPhone}/> : null}
                </div>
                <div className={styles["update-beano-game-info-component-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["mslc-label"]}>Staff Trained*</div>
                    <select
                        id="licenseDetails.staffTrained"
                        name="licenseDetails.staffTrained"
                        onChange={(e: any) => setFiledValue(e)}
                        onBlur={formik.handleBlur}
                        placeholder="Select"
                        className={styles["mslc-input-box"]}>
                        <option value="" selected disabled>Select...</option>
                        {
                            StaffTrained.map((value: string) =>
                                <option
                                    selected={StaffTrainedMapping[formik.values.licenseDetails.staffTrained] === value}
                                    value={value}>{value}
                                </option>)
                        }

                    </select>
                    {(formik.touched.licenseDetails?.staffTrained && formik.errors.licenseDetails?.staffTrained) ?
                        <ErrorMessageFieldCommonComponent
                            staffTrained={formik.errors.licenseDetails?.staffTrained}/> : null}
                </div>

            </form>
        </>

    );
}