import React from 'react'
import { ReissueHistoryPageUI } from './reissue-history-page-ui'
import { useFetchHistoricalReissueRuns } from '../../../api/reissue-run-history-fetcher/use-fetch-historical-reissue-runs'

export const ReissueHistoryPageContainer = () => {
  const { isLoading, data, error } = useFetchHistoricalReissueRuns()
  return (
    <ReissueHistoryPageUI isLoading={isLoading} error={error} historicalReissueRunsData={data} />
  )
}
