import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";

let raffleId: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (raffleIdObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    raffleId = raffleIdObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === 'address.zip' || e.target.name === 'cityCode' || e.target.name === 'orgType') {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value, true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.orgName) {
        Object.assign(errors, {orgName: InputValidationMessages.RequiredField});
    }

    if (!values.phone) {
        Object.assign(errors, {phone: InputValidationMessages.RequiredField});
    } else if (values.phone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.phone)) {
        Object.assign(errors, {phone: InputValidationMessages.InvalidTelephone});
    }

    if (!values.address.streetAddress) {
        Object.assign(errors, {address: {streetAddress: InputValidationMessages.RequiredField}});
    }

    if (!values.email) {
        Object.assign(errors, {email: InputValidationMessages.RequiredField});
    } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        Object.assign(errors, {email: InputValidationMessages.InvalidEmail});
    }

    if (!values.address.city) {
        Object.assign(errors, {address: {city: InputValidationMessages.RequiredField}});
    }

    if (!values.address.zip) {
        Object.assign(errors, {address: {zip: InputValidationMessages.RequiredField}});
    } else if (values.address.zip && values.address.zip.length !== 5) {
        Object.assign(errors, {address: {zip: InputValidationMessages.InvalidZip}});
    }

    if (!values.cityCode) {
        Object.assign(errors, {cityCode: InputValidationMessages.RequiredField});
    } else if (values.cityCode && values.cityCode.length !== 3) {
        Object.assign(errors, {cityCode: InputValidationMessages.InvalidCityCode});
    }

    if (!values.orgType) {
        Object.assign(errors, {orgType: InputValidationMessages.RequiredField});
    } else if (values.orgType && values.orgType.length !== 3) {
        Object.assign(errors, {orgType: InputValidationMessages.InvalidOrgType});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updatePermitHolder(values, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {
    history.goBack();
}



