import moment from 'moment-timezone'
import config from '../../../../config'
import { makeHttpCall } from '../../../../services/MakeHttpCall'
import { AnnuityPaymentScheduleInfo } from './interfaces'

export const getPaymentSchedule = async (id: string) => {
  let schedule: AnnuityPaymentScheduleInfo[] = []

  const options = {
    method: 'get',
    url: `${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${id}/payment?forecastPayments=true`,
  }

  try {
    let response: any[] = await makeHttpCall(options)
    schedule = response.map((item) => {
      return {
        id: item.payment.id,
        tin: item.payee.tin,
        paymentNumber: item.paymentNumber,
        lastPaymentNumber: item.accountTotalPayments,
        paymentDate: item.payment.paymentDate,
        paymentRuleId: item.payment.paymentRuleId,
        status: item.payment.paymentStatus,
        payCycle: item.paymentFrequency,
        paymentAmount: item.calculatedPaymentAmount
          ? (item.calculatedPaymentAmount / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          : (item.payment.paymentAmount / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        finalPaymentDate: item.paymentRule.endDate,
        taxDesignation: item.taxDesignation,
        payeeName:
          item.payee.payeeType === 'Individual'
            ? `${item.payee.firstName} ${item.payee.lastName}`
            : `${item.payee.freeformName}`,
        subAccount: item.subAccount,
      }
    })
  } catch (e) {
    schedule = []
    throw new Error('Failed to Retrieve Payment Schedule')
  }
  return schedule
}
