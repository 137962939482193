import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { CircularProgress } from '@material-ui/core'
import { PromotionEntriesPermissions } from '../../../api/promotions-fetcher/permissions'
import {
  PageMessageCommonComponent,
  pageMessageDefault,
} from '../../../components/CommonComponents/PageMessageCommonComponent'
import { PlayerIDAndNameComponent } from '../../../components/PlayersComponents/PlayerIDAndNameComponent'
import { playerSupportRoutePath } from '../../../config.js'
import alert_caution_2x from '../../../icons/icon_caution_2x.svg'
import alert_denied_2x from '../../../icons/icon_denied_2x.svg'
import { keyPressedType } from '../../../services/Commons'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { PlayerActivityDetailsPage } from '../TabsPlayersSupportHubPage/PlayerActivityDetailsPage'
import { PlayerDetailsPage } from '../TabsPlayersSupportHubPage/PlayerDetailsPage'
import PlayerPermissionPage from '../TabsPlayersSupportHubPage/PlayerPermissionPage'
import { PlayerPromotionsPage } from '../TabsPlayersSupportHubPage/PromotionsPage/PlayerPromotionsPage'
import { getPlayersDetailsInterface } from '../UpdateProfilePage/interfaces'
import styles from './css/styles.module.css'
import stylesMessageBox from './css/stylesMessageBox.module.css'
import stylesTabStatuses from './css/stylesTabStatutes.module.css'
import { getPlayerData } from './functions'

const timerArray: any = []
export const PlayersSupportHubPage = ({ id, storesObject }: any) => {
  const history = useHistory()
  localStorage.setItem('callingPage', history.location.pathname)
  localStorage.setItem(
    'PlayersName',
    storesObject.playerStore.selectedPlayerDetails.identity.firstName === ''
      ? `Player Search`
      : `${storesObject.playerStore.selectedPlayerDetails.identity.firstName} ${storesObject.playerStore.selectedPlayerDetails.identity.lastName}`
  )
  const [playerStore, setPlayerStore] = useState<
    getPlayersDetailsInterface | any
  >(storesObject.playerStore.selectedPlayerDetails)
  const [manuallyApprovedKYC, setManuallyApprovedKYC] = useState<boolean>(false)
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault)
  const [requestPerformed, setRequestPerformed] = useState<any>(false)
  const [isData, setIsData] = useState<any>(
    playerStore.account.email === '' ? false : true
  )

  const getTabStatuses = () => {
    // status
    const array: any = []
    if (
      playerStore.identity.identityCheckResult === '' ||
      playerStore.identity.identityCheckResult === null
    ) {
      array.push(
        <div
          tabIndex={0}
          className={stylesTabStatuses['message-status-container']}
        >
          <div
            tabIndex={0}
            className={stylesTabStatuses['message-status-inner-container']}
          >
            <img
              title="Caution"
              alt={'caution icon'}
              src={alert_caution_2x}
              className={stylesTabStatuses['message-status-icon']}
            />{' '}
            &nbsp;&nbsp;
            <div
              tabIndex={0}
              className={stylesTabStatuses['message-status-label']}
            >
              KYC Not Submitted
            </div>
            &nbsp;&nbsp;
            <div
              tabIndex={0}
              className={stylesTabStatuses['message-status-text']}
            >
              This player has not performed an identity check
            </div>
          </div>
        </div>
      )
    }

    if (
      playerStore.identity.status === 'NO_RECORD' ||
      playerStore.identity.status === 'NO_IDENTITY' ||
      playerStore.identity.status === '' ||
      playerStore.identity.status === null
    ) {
      array.push(
        <div
          tabIndex={0}
          className={stylesTabStatuses['message-status-container']}
        >
          <div
            tabIndex={0}
            className={stylesTabStatuses['message-status-inner-container']}
          >
            <img
              title="Caution"
              alt={'caution icon'}
              src={alert_caution_2x}
              className={stylesTabStatuses['message-status-icon']}
            />{' '}
            &nbsp;&nbsp;
            <div
              tabIndex={0}
              className={stylesTabStatuses['message-status-label']}
            >
              Account Not Created
            </div>
            &nbsp;&nbsp;
            <div
              tabIndex={0}
              className={stylesTabStatuses['message-status-text']}
            >
              An account does not exist for this player
            </div>
          </div>
        </div>
      )
    }

    if (playerStore.identity.identityCheckResult === 'FAIL') {
      array.push(
        <div className={stylesTabStatuses['message-status-container']}>
          <div
            tabIndex={0}
            className={stylesTabStatuses['message-status-inner-container']}
          >
            <img
              title="Denied"
              alt={'Denied icon'}
              src={alert_denied_2x}
              className={stylesTabStatuses['message-status-icon']}
            />{' '}
            &nbsp;&nbsp;
            <div className={stylesTabStatuses['message-status-label']}>
              KYC Denied
            </div>
            &nbsp;&nbsp;
            <div className={stylesTabStatuses['message-status-text']}>
              This player cannot sign in until the identity has been manually
              approved
            </div>
          </div>
          <u
            tabIndex={0}
            aria-pressed={'false'}
            onClick={(e: any) =>
              history.push(
                `${playerSupportRoutePath}/manual-kyc/${playerStore.account.id}`
              )
            }
            className={stylesTabStatuses['message-status-link']}
          >
            Manually Approve
          </u>
        </div>
      )
    }
    if (playerStore.identity.status === 'INVALID_IDENTITY') {
      array.push(
        <div className={stylesTabStatuses['message-status-container']}>
          <div
            tabIndex={0}
            className={stylesTabStatuses['message-status-inner-container']}
          >
            <img
              title="Denied"
              alt={'Denied icon'}
              src={alert_denied_2x}
              className={stylesTabStatuses['message-status-icon']}
            />{' '}
            &nbsp;&nbsp;
            <div className={stylesTabStatuses['message-status-label']}>
              Account Denied
            </div>
            &nbsp;&nbsp;
            <div className={stylesTabStatuses['message-status-text']}>
              Account failed automatic KYC check or is a duplicate account
            </div>
          </div>
        </div>
      )
    }

    return array.length > 0 ? array.slice(0, 3) : array
  }
  const sleep = (ms: any) => {
    const timer = setTimeout(
      (timerArray: any) => {
        timerArray.map((e: any) => clearTimeout(e))

        setRequestPerformed(false)
      },
      ms,
      timerArray
    )
    timerArray.push(timer)
  }

  useEffect(() => {
    if (localStorage.getItem('ManualKYCApproved') === 'true') {
      setManuallyApprovedKYC(true)
      localStorage.setItem('ManualKYCApproved', '')
    }

    if (
      (playerStore.account.email === '' || id !== playerStore.account.id) &&
      requestPerformed === false
    ) {
      document.getElementById('page-container')?.focus()
      getPlayerData(
        id,
        setPlayerStore,
        storesObject,
        setPageMessage,
        pageMessage,
        setIsData
      )
    }
    if (playerStore.account.email === '' && isData === true) {
      setIsData(false)
      setRequestPerformed(true)
      sleep(5000)
    }
  }, [])
  const setActiveTab = () => {
    let activeTab: any = localStorage.getItem('activeTab')
    return activeTab
  }

  return (
    <>
      {(playerStore.account.email === undefined ||
        playerStore.account.email === '' ||
        id !== playerStore.account.id) &&
        pageMessage.message === '' &&
        !isData &&
        storesObject.playerStore.playerDocImages.find(
          (e: any) => e.url.split('?')[0].split('/') !== playerStore.account.id
        ) !== undefined && (
          <div
            style={{
              zIndex: 1000,
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.3)',
              color: 'white',
            }}
          >
            <CircularProgress size="8em" color="inherit" thickness={2} />
          </div>
        )}
      <div
        onClick={(e: any) => {
          pageMessage.messageType !== '' &&
            setPageMessage({ message: '', messageType: '' })
        }}
        className={styles['page-container']}
      >
        <div className={styles['back-to-player-search-container']}>
          <div
            tabIndex={0}
            role="button"
            aria-pressed={'false'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                localStorage.setItem('activeTab', 'playerDetails')
                pageMessageDefault.message = ''
                pageMessageDefault.messageType = ''
                storesObject.claimStore.reset()
                history.push(`${playerSupportRoutePath}/search-new`)
              }
            }}
            onClick={() => {
              localStorage.setItem('activeTab', 'playerDetails')
              pageMessageDefault.message = ''
              pageMessageDefault.messageType = ''
              storesObject.claimStore.reset()
              history.push(`${playerSupportRoutePath}/search-new`)
            }}
            className={styles['back-to-player-search-inner-container']}
          >
            <i
              title="left arrow icon"
              className={styles['back-to-player-search-icon']}
            >
              <FontAwesomeIcon
                size="2x"
                arial-label="left arrow icon."
                icon={faAngleLeft as IconProp}
              />
            </i>
            <div
              className={styles['back-to-player-search-inner-container-text']}
            >
              Back to Player Search
            </div>
          </div>
        </div>
        {pageMessage.message != '' && (
          <div style={{ width: '97.813%' }}>
            <PageMessageCommonComponent
              message={pageMessage.message}
              messageType={pageMessage.messageType}
            />
          </div>
        )}
        {pageMessage.message != '' && document.scrollingElement?.scrollTo(0, 0)}
        <div className={styles['player-details-container']}>
          <div className={styles['playerIDAndName-container']}>
            <PlayerIDAndNameComponent
              setPageMessage={setPageMessage}
              playerId={id}
              playerName={`${playerStore.identity.firstName} ${playerStore.identity.lastName}`}
            />
          </div>
          <div className={styles['players-tab-statuses']}>
            {playerStore.account.email !== '' && getTabStatuses()}
          </div>
        </div>
        <div className={`${styles['players-support-tab-container']}`}>
          <Tabs
            onClick={() => localStorage.setItem('activeTab', 'playerDetails')}
            defaultActiveKey={setActiveTab()}
            id="players-support-tab"
          >
            <Tab eventKey="playerDetails" title="Player Details">
              <PlayerDetailsPage
                id={id}
                setRequestPerformed={setRequestPerformed}
                requestPerformed={requestPerformed}
                storesObject={storesObject}
                playerStore={playerStore}
                setPlayerStore={setPlayerStore}
                setPageMessage={setPageMessage}
                pageMessage={pageMessage}
              />
            </Tab>
            <Tab eventKey="activity" title="Activity">
              <PlayerActivityDetailsPage
                id={id}
                setRequestPerformed={setRequestPerformed}
                requestPerformed={requestPerformed}
                storesObject={storesObject}
                playerStore={playerStore}
                setPlayerStore={setPlayerStore}
                setPageMessage={setPageMessage}
                pageMessage={pageMessage}
              />
            </Tab>
            <Tab eventKey="permissions" title="Permissions">
              <PlayerPermissionPage
                id={id}
                setRequestPerformed={setRequestPerformed}
                requestPerformed={requestPerformed}
                storesObject={storesObject}
                playerStore={playerStore}
                setPlayerStore={setPlayerStore}
                setPageMessage={setPageMessage}
                pageMessage={pageMessage}
              />{' '}
            </Tab>
            <Tab
              eventKey="promotions"
              title="Promotions"
              disabled={
                !userHasAnyOfPermissions([
                  PromotionEntriesPermissions.CAN_READ_PROMOTION_ENTRIES,
                ])
              }
            >
              <PlayerPromotionsPage playerId={id} />
            </Tab>
          </Tabs>
        </div>
      </div>
      {manuallyApprovedKYC && (
        <div className={stylesMessageBox['window-container']}>
          <div className={stylesMessageBox['manually-approve-kyc-container']}>
            <div
              tabIndex={0}
              className={stylesMessageBox['manually-approve-kyc-text']}
            >
              KYC Approved!
            </div>
            <div
              tabIndex={0}
              className={stylesMessageBox['manually-approve-kyc-message']}
            >
              The player now is able to use their Mass Lottery Player Account,
              and will receive an email confirmation that their information was
              updated
            </div>
            <br />
            <div
              tabIndex={0}
              className={stylesMessageBox['manually-approve-kyc-message']}
            >
              If the email address was updated, the player will receive a
              6-digit verification code to the new email address immediately.
            </div>
            <div
              role="button"
              onClick={() => setManuallyApprovedKYC(false)}
              className={stylesMessageBox['manually-approve-kyc-button-yes']}
            >
              <div
                className={stylesMessageBox['manually-approve-kyc-yes-text']}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
