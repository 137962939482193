import React from "react";
import {inject, observer} from "mobx-react";
import CustomCard from "../../../dashboard/customCard";
import {checkRenderPermissions} from "../../../helpers";
import {permissions} from "../../../constants.js";
import {actionsRoutePath} from './../../../config'

@inject('claimStore')
@observer
class Actions extends React.Component {

    navigateToKYCQueue = () => {
        this.props.history.push(`${actionsRoutePath}/kyc-queue`);
    }

    navigateToRejectedPaymentsQueue = () => {
        this.props.history.push(`${actionsRoutePath}/rejected-payments-queue`);
    }

    render() {
        if(checkRenderPermissions(permissions.CAN_SEE_ACTIONS, JSON.parse(localStorage.getItem('userpolicies')))) {
            return (
                <div  style={{paddingTop:"8em", width:'100%', height:'100vh', display:'flex', flexDirection:"column", alignItems:"center", justifyContent:"flex-start", backgroundColor:"rgb(22, 58, 95)"}}>
            {checkRenderPermissions(permissions.CAN_SEE_PLAYER_DOCUMENT_QUEUE, JSON.parse(localStorage.getItem('userpolicies'))) ?
            <CustomCard
                title='Player Document Queue'
                handleCallback={() => this.navigateToKYCQueue()}
                /> : null}
                {checkRenderPermissions(permissions.CAN_SEE_REJECT_CLAIMS, JSON.parse(localStorage.getItem('userpolicies'))) ?
                <CustomCard
                    title='Rejected Payments Queue'
                    handleCallback={() => this.navigateToRejectedPaymentsQueue()}
                    /> : null}
                    </div>
                )
        } else {
            return <h3 className='unauthorized-header'>You do not have permission to view this page! Please contact your System
            Administrator!</h3>
        }

    }
}
export default Actions;
