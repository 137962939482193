import * as React from 'react'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  promotionIsNewOrPending,
} from '../helpers'
import { GenericSidebarFormMode } from '../../../../../GenericSidebar/generic-sidebar-ui'
import {
  CollapsableSection,
  useForceOpen,
} from '../../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import { PromotionCampaignValidationRule } from '../validation'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PromotionRuleFormUI } from './single-rule-form-ui'
import { PromotionRulesViewModeUI } from './rule-view-mode-ui'
import '../styles.scss'

export type PromotionRulesUIProps = PromotionCampaignBuilderSubFormProps & {
  rules: PromotionCampaignValidationRule[]
}

const BUTTON_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_CLASS,
  'add-rule-button'
)

export const PromotionRulesFormUI = (props: PromotionRulesUIProps) => {
  const [editMode, setEditMode] = React.useState<boolean>(
    props.formMode === GenericSidebarFormMode.NEW
  )
  const { isForcedOpen, forceOpen } = useForceOpen()

  const openEditMode = () => {
    forceOpen()
    setEditMode(true)
  }

  const { removeElement, addElement } =
    useFormArray<PromotionCampaignValidationRule>({
      defaultFieldValue: { predicates: [], gameIds: [''] },
      fieldArray: props.rules,
      setFieldArray: async (rules: PromotionCampaignValidationRule[]) => {
        await props.formik.setFieldValue(
          'promotionRewardGroups[0].rules',
          rules
        )
      },
    })

  const removeRule = async (index: number) => {
    await removeElement(index)
    props.formik.setFieldTouched('promotionRewardGroups[0].rules')
  }

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'promotion-rules-container'
      )}
    >
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'promotion-rules'
        )}
      >
        <CollapsableSection
          title="Rules"
          isForcedOpen={isForcedOpen}
          backgroundColor="light_blue"
          textSize="small"
          connectedBottom
          smallHeader
          defaultOpen
        >
          {editMode ? (
            <>
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'promotions-rules-content'
                )}
              >
                {props.rules.map((rule, index) => {
                  return (
                    <>
                      <PromotionRuleFormUI
                        index={index}
                        formik={props.formik}
                        isLoading={props.isLoading}
                        removeRule={() => removeRule(index)}
                        rule={rule}
                        key={`rule-${index}`}
                        promotionStatus={props.promotionStatus}
                      />
                      {index !== props.rules.length - 1 ? (
                        <p
                          className={getBlockClass(
                            PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                            'and-separator'
                          )}
                        >
                          AND
                        </p>
                      ) : undefined}
                    </>
                  )
                })}
              </div>
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                  'promotion-rule-button-container'
                )}
              >
                <button
                  type="button"
                  className={BUTTON_CLASS}
                  aria-label="Add rule"
                  disabled={props.isLoading}
                  onClick={addElement}
                >
                  Add New Rule
                </button>
              </div>
            </>
          ) : (
            <>
              <PromotionRulesViewModeUI rules={props.rules} />
            </>
          )}
        </CollapsableSection>
        <div
          className={getBlockClass(PROMOTION_SIDEBAR_FORM_ROOT_CLASS, 'footer')}
        >
          {!editMode &&
            promotionIsNewOrPending(props.promotionStatus) &&
            props.canEditPromotionCampaigns && (
              <button onClick={openEditMode}>Edit</button>
            )}
        </div>
      </div>
    </div>
  )
}
