import React from "react";
import styles from './css/styles.module.css';
import {ErrorMessageFieldCommonComponent} from '../../../../CommonComponents/ErrorMessageFieldCommonComponent';
import {clearField} from "../../../../PlayersComponents/UpdateProfileComponent/functions";


export const CreateLicenseInformationComponent = ({
                                                      formik,
                                                      setFiledValue,
                                                  }: any) => {
    return (
        <>
            <form className={styles['page-container-body']}>
                <div tabIndex={0} className={styles['page-container-body-left-title']}>License Information - For MSLC
                    Use Only
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Investigator ID*
                    </div>
                    <input
                        id="licenseDetails.investCode"
                        name="licenseDetails.investCode"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.investCode}
                        onBlur={formik.handleBlur}
                        placeholder={'Ex. 00'}
                    />
                    {(formik.touched.licenseDetails?.investCode && formik.errors.investCode) ?
                        <ErrorMessageFieldCommonComponent
                            investCode={formik.errors.investCode}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Type ID*
                    </div>
                    <input
                        id="licenseDetails.type"
                        name="licenseDetails.type"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.type}
                        onBlur={formik.handleBlur}
                        placeholder={'Ex. 000'}
                    />
                    {(formik.touched.licenseDetails?.type && formik.errors.type) ?
                        <ErrorMessageFieldCommonComponent
                            type={formik.errors.type}/> : null}
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>County*
                    </div>
                    <input
                        id="licenseDetails.county"
                        name="licenseDetails.county"
                        className={styles["page-container-body-left-input-box"]}
                        type="text"
                        onChange={(e: any) => {
                            setFiledValue(e);
                        }}
                        value={formik.values.licenseDetails.county}
                        onBlur={formik.handleBlur}
                        placeholder={'Ex. 00'}
                    />
                    {(formik.touched.licenseDetails?.county && formik.errors.county) ?
                        <ErrorMessageFieldCommonComponent
                            county={formik.errors.county}/> : null}
                </div>

                <div>
                    <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                        <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>City/Town*</div>
                        <input
                            id="licenseDetails.city"
                            name="licenseDetails.city"
                            value={formik.values.licenseDetails.city}
                            onBlur={formik.handleBlur}
                            onClick={(e) => clearField(e, formik)}
                            onChange={(e: any) => setFiledValue(e)}
                            className={`${styles["page-container-body-left-input-box"]}`}
                            type="text"
                            placeholder={'Ex. 000'}
                        />
                        {(formik.touched.licenseDetails?.city && formik.errors.city3) ?
                            <ErrorMessageFieldCommonComponent
                                city3={formik.errors.city3}/> : null}
                    </div>
                </div>
                <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                    <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Effective date*</div>
                    <input
                        id="licenseDetails.dateOfFirstLicense"
                        name="licenseDetails.dateOfFirstLicense"
                        onChange={(e: any) => setFiledValue(e)}
                        value={formik.values.licenseDetails.dateOfFirstLicense}
                        className={styles["page-container-body-left-input-box"]}
                        onBlur={formik.handleBlur}
                        type="date"
                    />
                    {(formik.touched.licenseDetails?.dateOfFirstLicense && formik.errors.dateOfFirstLicense) ?
                        <ErrorMessageFieldCommonComponent
                            dateOfFirstLicense={formik.errors.dateOfFirstLicense}/> : null}
                </div>
            </form>
        </>

    );
}