import {makeHttpCall} from '../../../services/MakeHttpCall';
import config from './../../../config.js';

let formik: any;
let setPageMessageType: any;
let setButtonClicked: any;
let history: any;
let setACHTaxFileDetailsSearchResponse: any

export const setVariables = (formikObj: any, historyObject: any,
                             pageMessageDefaultObj: any, setPageMessageObj: any, setButtonClickedObj: any,
                             setACHTaxFileDetailsSearchResponseObj: any) => {
    formik = formikObj;
    history = historyObject;
    setPageMessageType = setPageMessageObj;
    setButtonClicked = setButtonClickedObj;
    setACHTaxFileDetailsSearchResponse = setACHTaxFileDetailsSearchResponseObj

}

export const refreshComponent = (response: any) => {
    console.log('UpdateProfile', response);
    if (!(response.error === undefined)) {
        setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
    } else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }
}

export const onSubmit = async (values: any) => {
}

export const getACHTaxFileDetails = async (batchNumber: string) => {

    try {

        const options =
            {
                method: 'get',
                url: `${config.SERVER_BASE_URL}/v1/ach/tax-batch/${batchNumber}`,
            }

        const response: any = await makeHttpCall(options);

        if (response.data.length === 0) {
            setACHTaxFileDetailsSearchResponse([]);
            setPageMessageType({message: "No Results Found.", messageType: "SUCCESS"});
        } else {
            setButtonClicked(true);
            setACHTaxFileDetailsSearchResponse(response.data);
        }

    } catch (e) {
        setButtonClicked(true);
        setACHTaxFileDetailsSearchResponse([]);
        setPageMessageType({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
    }

}

export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true);
}

export const validate = (values: any) => {
    const errors = {};
    if (values.batchNumber) {
        if (values.batchNumber.length > 0 && values.batchNumber.length !== 7) {
            Object.assign(errors, {batchNumber: 'Invalid Batch Number'});
        }
    }
    return errors;
}


