import React from 'react'
import styles from './css/styles.module.css'
import { ErrorMessageFieldCommonComponent } from './../../CommonComponents/ErrorMessageFieldCommonComponent'
import { keyPressedType } from '../../../services/Commons'
import InputMask from 'react-input-mask'

export const PlayerSearchComponent = ({
  onClearSearch,
  setPlayerSearchAPIResponse,
  doPlayerSearchWithName,
  doPlayerSearchWithIdOrEmail,
  doPlayerSearchWithSSN,
  formik,
  setFieldValue,
  setPhoneValue,
  phoneError,
  setPhoneError,
  setButtonClicked,
  setCurrentPage,
}: any) => {
  const anyFieldPopulated = (): boolean =>
    formik.values.playerId ||
    formik.values.email ||
    formik.values.firstName ||
    formik.values.lastName ||
    formik.values.phone ||
    formik.values.socialSecurityNumber

  return (
    <>
      <form className={styles['page-container-body']}>
        <div tabIndex={0} className={styles['page-container-body-left-title']}>
          Player Search
        </div>
        <div
          className={`${styles['page-container-body-left-label-and-input-box-container']} ${styles['form-column-1']}`}
        >
          <div
            tabIndex={0}
            className={styles['page-container-body-left-labels-name']}
          >
            Full Email Address must be exact match
          </div>
          <input
            autoComplete={'off'}
            id="email"
            name="email"
            onChange={(e: any) => setFieldValue(e)}
            className={styles['page-container-body-left-input-box']}
            value={formik.values.email}
            aria-label={`${formik.values.email}.`}
            type="text"
          />
          {formik.errors.email && (
            <ErrorMessageFieldCommonComponent email={formik.errors.email} />
          )}
        </div>
        <div
          className={`${styles['page-container-body-left-label-and-input-box-container']} ${styles['form-column-2']}`}
        >
          <div
            tabIndex={0}
            className={styles['page-container-body-left-labels-name']}
          >
            Full Player ID must be exact match
          </div>
          <input
            autoComplete={'text'}
            id="playerId"
            name="playerId"
            className={styles['page-container-body-left-input-box']}
            type="text"
            onChange={(e: any) => {
              setFieldValue(e)
            }}
            value={formik.values.playerId}
            aria-label={`${formik.values.playerId}.`}
          />
          {formik.errors.playerId && (
            <ErrorMessageFieldCommonComponent
              playerId={formik.errors.playerId}
            />
          )}
        </div>
        <div
          className={`${styles['page-container-body-left-label-and-input-box-container']} ${styles['form-column-1']}`}
        >
          <div
            tabIndex={0}
            className={styles['page-container-body-left-labels-name']}
          >
            First Name
          </div>
          <input
            autoComplete={'off'}
            id="firstName"
            name="firstName"
            onChange={(e: any) => setFieldValue(e)}
            value={formik.values.firstName}
            className={styles['page-container-body-left-input-box']}
            aria-label={`${formik.values.firstName}.`}
            type="text"
          />
          {formik.errors.firstName && (
            <ErrorMessageFieldCommonComponent
              lastName={formik.errors.firstName}
            />
          )}
        </div>
        <div
          className={`${styles['page-container-body-left-label-and-input-box-container']} ${styles['form-column-2']}`}
        >
          <div
            tabIndex={0}
            className={styles['page-container-body-left-labels-name']}
          >
            Last Name
          </div>
          <input
            autoComplete={'text'}
            id="lastName"
            name="lastName"
            className={styles['page-container-body-left-input-box']}
            onChange={(e: any) => setFieldValue(e)}
            value={formik.values.lastName}
            aria-label={`${formik.values.lastName}.`}
            type="text"
          />
          {formik.errors.lastName && (
            <ErrorMessageFieldCommonComponent
              address2={formik.errors.lastName}
            />
          )}
        </div>
        <div
          className={`${styles['top-ribbon-button-container']} ${styles['form-column-3']}`}
        >
          <div
            role="button"
            tabIndex={0}
            aria-pressed="false"
            style={{
              opacity:
                anyFieldPopulated() && Object.keys(formik.errors).length === 0
                  ? '1'
                  : '0.4',
              cursor:
                anyFieldPopulated() && Object.keys(formik.errors).length === 0
                  ? 'pointer'
                  : 'not-allowed',
            }}
            onKeyPress={(e: any) => {
              if (
                (keyPressedType(e) === 13 || keyPressedType(e) === 0) &&
                Object.keys(formik.errors).length === 0
              ) {
                if (
                  anyFieldPopulated() &&
                  Object.keys(formik.errors).length === 0
                ) {
                  setButtonClicked(true)
                  if (formik.values.playerId) {
                    doPlayerSearchWithIdOrEmail(
                      formik.values.playerId,
                      setPlayerSearchAPIResponse,
                      setCurrentPage,
                      'id'
                    )
                  } else if (formik.values.email) {
                    doPlayerSearchWithIdOrEmail(
                      formik.values.email,
                      setPlayerSearchAPIResponse,
                      setCurrentPage,
                      'email'
                    )
                  } else if (formik.values.socialSecurityNumber) {
                    doPlayerSearchWithSSN(
                      formik.values.socialSecurityNumber,
                      setPlayerSearchAPIResponse,
                      setCurrentPage
                    )
                  } else {
                    doPlayerSearchWithName(
                      formik.values.firstName,
                      formik.values.lastName,
                      formik.values.phone,
                      setPlayerSearchAPIResponse,
                      setCurrentPage
                    )
                  }
                  formik.submitForm()
                }
              }
            }}
            onClick={() => {
              if (
                anyFieldPopulated() &&
                Object.keys(formik.errors).length === 0
              ) {
                if (formik.values.phone && formik.values.phone.length != 10) {
                  setPhoneError('Length of Phone Number must be 10')
                } else {
                  setPhoneError('')
                  setButtonClicked(true)
                  if (formik.values.playerId) {
                    doPlayerSearchWithIdOrEmail(
                      formik.values.playerId,
                      setPlayerSearchAPIResponse,
                      setCurrentPage,
                      'id'
                    )
                  } else if (formik.values.email) {
                    doPlayerSearchWithIdOrEmail(
                      formik.values.email,
                      setPlayerSearchAPIResponse,
                      setCurrentPage,
                      'email'
                    )
                  } else if (formik.values.socialSecurityNumber) {
                    doPlayerSearchWithSSN(
                      formik.values.socialSecurityNumber,
                      setPlayerSearchAPIResponse,
                      setCurrentPage
                    )
                  } else {
                    doPlayerSearchWithName(
                      formik.values.firstName,
                      formik.values.lastName,
                      formik.values.phone,
                      setPlayerSearchAPIResponse,
                      setCurrentPage
                    )
                  }
                  formik.submitForm()
                }
              }
            }}
            className={styles['top-ribbon-search-button']}
          >
            Search
          </div>
          <div
            role="button"
            tabIndex={0}
            aria-pressed="false"
            style={{
              opacity: Object.keys(formik.errors).length > 0 ? '0.4' : '1',
            }}
            onKeyPress={(e: any) => {
              if (
                (keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32) &&
                Object.keys(formik.errors).length === 0
              ) {
                formik.resetForm({
                  values: {
                    email: '',
                    playerId: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    socialSecurityNumber: '',
                  },
                })
                onClearSearch(setPlayerSearchAPIResponse)
              }
            }}
            onClick={() => {
              formik.resetForm({
                values: {
                  email: '',
                  playerId: '',
                  firstName: '',
                  lastName: '',
                  phone: '',
                  socialSecurityNumber: '',
                },
              })
              onClearSearch(setPlayerSearchAPIResponse)
            }}
            className={styles['top-ribbon-clear-search-button']}
          >
            Clear Search
          </div>
        </div>
        <div
          className={`${styles['page-container-body-left-label-and-input-box-container']} ${styles['form-column-3']}`}
        >
          <div
            tabIndex={0}
            className={styles['page-container-body-left-labels-name']}
          >
            Phone Number must be exact match
          </div>
          <input
            autoComplete={'text'}
            id="phone"
            name="phone"
            className={styles['page-container-body-left-input-box']}
            onChange={(e: any) => setPhoneValue(e)}
            value={formik.values.phone}
            aria-label={`${formik.values.phone}.`}
            type="text"
          />
          {phoneError.length > 0 && (
            <ErrorMessageFieldCommonComponent address2={phoneError} />
          )}
        </div>
        <div
          className={`${styles['page-container-body-left-label-and-input-box-container']} ${styles['form-column-4']}`}
        >
          <div
            tabIndex={0}
            className={styles['page-container-body-left-labels-name']}
          >
            Social Security Number must be exact match
          </div>
          <InputMask
            mask="999-99-9999"
            maskChar=" "
            id="socialSecurityNumber"
            name="socialSecurityNumber"
            className={styles['page-container-body-left-input-box']}
            onChange={(e: any) => setFieldValue(e)}
            value={formik.values.socialSecurityNumber}
            aria-label={`${formik.values.socialSecurityNumber}.`}
            placeholder={'000-00-0000'}
          ></InputMask>
          {formik.errors.socialSecurityNumber && (
            <ErrorMessageFieldCommonComponent
              socialSecurityNumber={formik.errors.socialSecurityNumber}
            />
          )}
        </div>
      </form>
    </>
  )
}
