import * as React from 'react'
import { AccessibleKey } from '../../components/common'

export type UseKeyDownProps = {
  key: AccessibleKey
  dependencies: any[]
  action: () => any
}

export const useKeyDown = (props: UseKeyDownProps) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === props.key) {
      props.action()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [...props.dependencies])
}
