import moment from 'moment'
import React from 'react'
import { CheckRunHistoryResponse } from '../../../../api/check-run-history-fetcher/api-types'
import { LoadingDots } from '../../../CommonComponents/loading-dots/loading-dots-ui'
import './styles.scss'

type LatestCheckRunUIProps = {
  isLoading: boolean
  error?: string
  data?: CheckRunHistoryResponse
}

const ROOT_CLASS = 'latest-check-run-ui'
export const LatestCheckRunUI = (props: LatestCheckRunUIProps) => {
  const getText = () => {
    if (props.data) {
      const historicalCheckRuns = props.data.historicalCheckRuns
      if (historicalCheckRuns.length > 0) {
        return moment(props.data.historicalCheckRuns[0].initiatedDate).format(
          'MM/DD/YY'
        )
      } else {
        return `N/A`
      }
    }
    if (props.error) {
      return 'Unavailable'
    }
    return 'N/A'
  }
  return (
    <span className={ROOT_CLASS}>
      <span className="label">Last Check Run Date:</span>
      <span>
        {props.isLoading ? (
          <LoadingDots
            id="latest-check-run-loading-dots"
            color="white"
          ></LoadingDots>
        ) : (
          getText()
        )}
      </span>
    </span>
  )
}
