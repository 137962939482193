import { makeHttpCall } from './../../../services/MakeHttpCall';
import config, { playerSupportRoutePath } from './../../../config.js';
import {
    IdentityInterface,
    AccountInterface,
    getPlayersDetailsInterface
} from './interfaces';


let formik: any;
let playerStore: any;
let ssnIsPresent: boolean = false;
let history: any;
let pageMessageDefault: any;
let setPageMessageType: any;
let oldPlayerSelectDetailsDataIdentity:any;
let setIsDate:any;
export const setVariables = (formikObj: any, playerStoreObject: any, historyObject: any, pageMessageDefaultObj: any, setPageMessageObj: any, setIsDateObj:any) => {
    formik = formikObj;
    playerStore = playerStoreObject;
    history = historyObject;
    pageMessageDefault = pageMessageDefaultObj;
    setPageMessageType = setPageMessageObj;
    oldPlayerSelectDetailsDataIdentity =  JSON.parse(JSON.stringify(playerStoreObject.selectedPlayerDetails.identity));
    setIsDate = setIsDateObj;
}

export const refreshComponent = (response: any) => {
  
    if (!(response.error === undefined)) {
        setPageMessageType({ message: "Something went wrong. Please try again.", messageType: "ERROR" });
    }
    else {
        formik.initialValues = response;
        formik.setValues(response, false);
    }

}

export const ssnNumberIsBeenUsed = (values: any) => {
    ssnIsPresent = values;
}

export const onSubmit = async (values: getPlayersDetailsInterface) => {

    formik.setValues(values, true);

    if (Object.keys(formik.errors).length <= 0) {
        console.log(">>>>>>>>>>>>>>> onSubmit");
        const req: any = {
            newData:{
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip5: '',
                phone: '',
                gender: '',
                dateOfBirth: '',
            },
            oldData:oldPlayerSelectDetailsDataIdentity
        }

        const identity: any = values.identity;
        Object.keys(req.newData).forEach((key: string) => { req.newData[key] = (typeof identity[key] === 'string' ? identity[key] : "") });
        if ( ssnIsPresent && identity.ssn !== playerStore.selectedPlayerDetails.identity.ssn) {
            const ssnValues = identity.ssn.split('-');
            let isnan = false;
            for (let i = 0; i < ssnValues.length; i++) {
                if (isNaN(parseInt(ssnValues[i]))) {
                    isnan = true;
                    setPageMessageType({ message: 'must be all numbers no asterics', messageType: "ERROR" });
                    return;
                }
            }
            req.newData.ssn = identity.ssn;
        }
        
        try {
            const options =
            {
                method: 'post',
                url: config.SERVER_BASE_URL + '/v1/player/' + playerStore.selectedPlayerDetails.account.id,
                data: req
            }

            const response = await makeHttpCall(options);
            pageMessageDefault.message = response.message;
            pageMessageDefault.messageType = "SUCCESS";

            setPageMessageType({ ...pageMessageDefault });
            Object.keys(req.newData).forEach((key: string) => {  oldPlayerSelectDetailsDataIdentity[key] = req.newData[key]  });
            
            if ( ssnIsPresent && identity.ssn !== playerStore.selectedPlayerDetails.identity.ssn )
            {
                oldPlayerSelectDetailsDataIdentity['ssn'] = identity.ssn.replace(/(\d{3})-(\d{2})-(\d{4})/, "***-**-$3");
            }
            setIsDate(true);
            playerStore.selectedPlayerDetails.identity = JSON.parse( JSON.stringify( oldPlayerSelectDetailsDataIdentity ) );
            
            history.push(`${playerSupportRoutePath}/player-hub-new/${formik.values.account.id}`);

        } catch (e) {
            setIsDate(true);
            const er:any = e;
            if(er.toString().split( " " ).find( (value:any, i:any) =>{ 
                if(value === "Network")
                  return value
            }) !== undefined)
            {
               setPageMessageType({message: "Something went wrong. Network Error. Please try again.", messageType: "ERROR"});
            }else
              setPageMessageType({message: "Something went wrong. Please try again.", messageType: "ERROR"});
          
        }

    }
}

export const setFiledValue = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value, true);
}
export const validate = (values: getPlayersDetailsInterface) => {
    const errors = {};

    if (!values.identity.firstName) {
        Object.assign(errors, { firstName: ' First Name is Required and not be empty' });
    }

    if (!values.identity.lastName) {
        Object.assign(errors, { lastName: 'Last Name is Required and not be empty' });

    }

    if (!values.identity.address1) {
        Object.assign(errors, { address1: 'address is Required and not be empty' });

    }

    if (!values.identity.zip5) {
        Object.assign(errors, { zip5: 'Zip is Required' });

    }
    else if (!(values.identity.zip5.trim().length === 5)) {
        Object.assign(errors, { zip5: 'Zip Must be 5 digits' });

    }
    else if (!values.identity.zip5.match(/^[0-9\b]+$/)) {
        Object.assign(errors, { zip5: 'Zip Must be digits only' });

    }

    if (!values.identity.city && values.identity.city.trim().length <= 0) {
        Object.assign(errors, { city: 'City is Required' });
    }

    
    if (!values.identity.ssn) {
        Object.assign(errors, { ssn: 'SSN is required' });
    }
    else if (!(values.identity.ssn.trim().length == 11) && !values.identity.ssn.match(/^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/)) {
        Object.assign(errors, { ssn: 'SSN must be of format xxx-xx-xxxx' });
    }




    const year = parseInt(values.identity.dateOfBirth.split("-")[0]);
    const month = parseInt(values.identity.dateOfBirth.split("-")[1]);
    const day = parseInt(values.identity.dateOfBirth.split("-")[2]);
    const currentDate = new Date().toISOString().split("T")[0];
    const currentYear = parseInt(currentDate.split("-")[0]);
    const currentMonth = parseInt(currentDate.split("-")[1]);
    const currentDay = parseInt(currentDate.split("-")[2]);



    if ( (currentYear - year) === 18 ) {
        if ((month - currentMonth) > 0) {
            Object.assign(errors, { dateOfBirth: 'The birthdate is below 18 years old' });
        }
        if ((currentMonth - month) === 0) {
            if (day > currentDay) {
                Object.assign(errors, { dateOfBirth: 'The birthdate is below 18 years old' });
            }
        }

    }
    else if ( (currentYear - year)   < 18 ) {
        Object.assign(errors, { dateOfBirth: 'The birthdate is below 18 years old' });
    }
    return errors;
}


