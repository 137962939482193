import { makeHttpCall } from './../../../services/MakeHttpCall'
import config, { playerSupportRoutePath } from './../../../config.js'
import { getPlayersDetailsInterface } from '../UpdateProfilePage/interfaces'
import { checkRenderPermissions } from '../../../helpers'
import { permissions } from '../../../constants'

const identityDocument: any = {
  identification1: '',
  identification2: '',
  comment1: '',
  comment2: '',
  ssn: '',
  reenterssn: '',
}

let formik: any
let playerStore: any
let ssnIsPresent: boolean = false
let history: any
let playerId: string
let firstPass: boolean = false
let pageMessage: any
let setPageMessageType: any

export const setVariables = (
  id: string,
  formikObj: any,
  playerStoreObject: any,
  historyObject: any,
  pageMessageObj: any,
  setPageMessageObj: any
) => {
  formik = formikObj
  playerStore = playerStoreObject
  history = historyObject
  playerId = id
  pageMessage = pageMessageObj
  setPageMessageType = setPageMessageObj
}

export const refreshComponent = (response: any) => {
  if (!(response.error === undefined)) {
    setPageMessageType({
      message: 'An error has accoured',
      messageType: 'ERROR',
    })
  } else {
    response.identityDocument = identityDocument
    formik.initialValues = response
    formik.setValues(response, false)
  }
}
export const getKYCStatus = async (
  playerId: string,
  setPlayerStore: any,
  setPageMessage: any,
  pageMessage: any,
  setIsData: any
) => {
  try {
    const userpolicies: any = localStorage.getItem('userpolicies')
    const maskSsn = !checkRenderPermissions(
      permissions.CAN_SEE_SSN,
      JSON.parse(userpolicies)
    )
    let queryParam = `?maskSsn=${maskSsn}`

    const options = {
      method: 'get',
      url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
    }
    const response = await makeHttpCall(options)
    response.identityDocument = identityDocument
    playerStore.selectedPlayerDetails = response
    setPlayerStore(response)

    formik.setValues(response, false)

    if (pageMessage.messageType === 'ERROR') {
      setPageMessage({ message: '', messageType: '' })
    }

    setIsData(true)
  } catch (e) {
    setIsData(true)
    const er: any = e
    if (
      er
        .toString()
        .split(' ')
        .find((value: any, i: any) => {
          if (value === 'Network') {
            return value
          }
        }) !== undefined
    ) {
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    } else {
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
    }
  }
}

export const getPlayersDeatils = async (
  playerId: string,
  setPlayerStore: any,
  setPageMessage: any,
  pageMessage: any
) => {
  try {
    const userpolicies: any = localStorage.getItem('userpolicies')
    const maskSsn = !checkRenderPermissions(
      permissions.CAN_SEE_SSN,
      JSON.parse(userpolicies)
    )
    let queryParam = `?maskSsn=${maskSsn}`
    const options = {
      method: 'get',
      url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
    }
    const response = await makeHttpCall(options)
    response.identityDocument = identityDocument
    playerStore.selectedPlayerDetails = response
    setPlayerStore(response)
    formik.setValues(response, false)
    if (pageMessage.messageType === 'ERROR') {
      setPageMessage({ message: '', messageType: '' })
    }
  } catch (e) {
    const er: any = e
    if (
      er
        .toString()
        .split(' ')
        .find((value: any, i: any) => {
          if (value === 'Network') {
            return value
          }
        }) !== undefined
    ) {
      setPageMessage({
        message: 'Something went wrong. Network Error. Please try again.',
        messageType: 'ERROR',
      })
    } else {
      setPageMessage({
        message: 'Something went wrong. Please try again.',
        messageType: 'ERROR',
      })
    }
  }
}
export const ssnNumberIsBeenUsed = (values: any) => {
  ssnIsPresent = values
}
export const onSubmit = async (values: getPlayersDetailsInterface | any) => {
  formik.setValues(values, true)

  if (Object.keys(formik.errors).length <= 0) {
    const existingPlayerData = {
      ...playerStore.selectedPlayerDetails.identity,
      email: playerStore.selectedPlayerDetails.account.email,
    }
    let playerInfoChanged = false
    const req: any = {
      newData: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip5: '',
        phone: '',
        gender: '',
        dateOfBirth: '',
        ssn: values.identityDocument.ssn,
        email: values.account.email,
      },
      oldData: existingPlayerData,
    }
    const identity: any = values.identity
    Object.keys(req.newData).forEach((key: string) => {
      // Updating the SSN field is handled in "identityDocument" in this formik instance.
      // We want to avoid overwriting that with the source data from the identity object.
      // This is gross, and I hate it, but changing it would require overhauling the page completely.
      if (identity[key] && key !== 'ssn') {
        req.newData[key] = identity[key]
      }
      if (req.newData[key] !== existingPlayerData[key]) {
        playerInfoChanged = true
      }
    })

    try {
      if (playerInfoChanged) {
        // If a player's information has been updated, send request to update the account/identity.
        const updateOptions = {
          method: 'post',
          url: config.SERVER_BASE_URL + '/v1/player/' + playerId,
          data: req,
        }
        await makeHttpCall(updateOptions)
      }

      const verificationNotes: any = {
        identification1: '',
        identification2: '',
        comment1: '',
        comment2: '',
        email: '',
      }
      Object.keys(verificationNotes).forEach((key: string) => {
        verificationNotes[key] = values.identityDocument[key]
      })
      verificationNotes.firstName = identity.firstName
      verificationNotes.lastName = identity.lastName
      verificationNotes.email = values.account.email
      const verifyOptions = {
        method: 'post',
        url: config.SERVER_BASE_URL + '/v1/player/' + playerId + '/verify',
        data: verificationNotes,
      }
      const verifyResponse = await makeHttpCall(verifyOptions)
      formik.setValues(values, false)

      //playerStore.selectedPlayerDetails.account.email = "";

      const callingPage = localStorage.getItem('callingPage')
      localStorage.setItem('ManualKYCApproved', 'true')

      const userpolicies: any = localStorage.getItem('userpolicies')
      const maskSsn = !checkRenderPermissions(
        permissions.CAN_SEE_SSN,
        JSON.parse(userpolicies)
      )
      let queryParam = `?maskSsn=${maskSsn}`

      let options1 = {
        method: 'get',
        url: config.SERVER_BASE_URL + '/v1/player/' + playerId + queryParam,
      }
      const playerDetailsResponse = await makeHttpCall(options1)
      playerStore.selectedPlayerDetails = playerDetailsResponse

      history.push(`${callingPage}`)
    } catch (e) {
      const er: any = e
      if (
        er
          .toString()
          .split(' ')
          .find((value: any, i: any) => {
            if (value === 'Network') {
              return value
            }
          }) !== undefined
      ) {
        setPageMessageType({
          message: 'Something went wrong. Network Error. Please try again.',
          messageType: 'ERROR',
        })
      } else {
        setPageMessageType({
          message: 'Something went wrong. Please try again.',
          messageType: 'ERROR',
        })
      }
    }
  }
}
export const setFirstPass = (value: any) => {
  firstPass = value
}
export const displayErrors = (formik: any, setApprovedToggle: any) => {
  if (!firstPass && Object.keys(formik.errors).length > 0) {
    alert(JSON.stringify(formik.errors))
    formik.setErrors({})
    firstPass = true
    setApprovedToggle({ value: false })
    return false
  }
}
export const setFiledValue = (e: any) => {
  formik.setFieldValue(e.target.name, e.target.value, true)
}

export const validate = (values: getPlayersDetailsInterface | any) => {
  const errors = {}

  if (!values.account.email.trim()) {
    Object.assign(errors, { email: 'Email is Required and cannot be empty' })
  } else if (
    !values.account.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
  ) {
    Object.assign(errors, { email: 'Invalid Email' })
  }

  if (!values.identity.firstName.trim()) {
    Object.assign(errors, {
      firstName: ' First Name is Required and not be empty',
    })
  }

  if (!values.identity.lastName.trim()) {
    Object.assign(errors, {
      lastName: 'Last Name is Required and not be empty',
    })
  }

  if (!values.identity.address1.trim()) {
    Object.assign(errors, { address1: 'address is Required and not be empty' })
  }

  if (!values.identity.zip5) {
    Object.assign(errors, { zip5: 'Zip is Required' })
  } else if (!(values.identity.zip5.trim().length === 5)) {
    Object.assign(errors, { zip5: 'Zip Must be 5 digits' })
  } else if (!values.identity.zip5.match(/^[0-9\b]+$/)) {
    Object.assign(errors, { zip5: 'Zip Must be digits only' })
  }

  if (!values.identity.city && values.identity.city.trim().length <= 0) {
    Object.assign(errors, { city: 'City is Required' })
  }

  if (!values.identityDocument.ssn) {
    Object.assign(errors, { ssn: 'SSN is required' })
  } else if (
    !(values.identityDocument.ssn.trim().length == 11) &&
    !values.identityDocument.ssn.match(
      /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
    )
  ) {
    Object.assign(errors, { ssn: 'SSN must be of format xxx-xx-xxxx' })
  } else if (values.identityDocument.ssn) {
    const ssnValues = values.identityDocument.ssn.split('-')
    let isnan = false
    for (let i = 0; i < ssnValues.length; i++) {
      if (isNaN(parseInt(ssnValues[i]))) {
        isnan = true
        Object.assign(errors, { ssn: 'must be all digits no asterics' })
        break
      }
    }
  }
  if (!values.identityDocument.identification1) {
    Object.assign(errors, { identification1: 'Please select a document type' })
  } else if (values.identityDocument.identification1 === 'Select...') {
    Object.assign(errors, { identification1: 'Please select a document type' })
  }

  if (!values.identityDocument.identification2) {
    Object.assign(errors, { identification2: 'Please select a document type' })
  } else if (values.identityDocument.identification2 === 'Select...') {
    Object.assign(errors, { identification2: 'Please select a document type' })
  }

  if (!values.identityDocument.reenterssn) {
    Object.assign(errors, { reenterssn: 'Reenter SSN is required' })
  } else if (
    !(values.identityDocument.reenterssn.trim().length == 11) &&
    !values.identityDocument.reenterssn.match(
      /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
    )
  ) {
    Object.assign(errors, {
      reenterssn: 'Reenter SSN must be of format xxx-xx-xxxx',
    })
  } else if (values.identityDocument.reenterssn) {
    const ssnValues = values.identityDocument.reenterssn.split('-')

    for (let i = 0; i < ssnValues.length; i++) {
      if (isNaN(parseInt(ssnValues[i]))) {
        Object.assign(errors, { reenterssn: 'must be all digits no asterics' })
        break
      }
    }
  }

  if (values.identityDocument.reenterssn !== values.identityDocument.ssn) {
    Object.assign(errors, { ssnTest: `SSN don't match` })
  }

  const year = parseInt(values.identity.dateOfBirth.split('-')[0])
  const month = parseInt(values.identity.dateOfBirth.split('-')[1])
  const day = parseInt(values.identity.dateOfBirth.split('-')[2])
  const currentDate = new Date().toISOString().split('T')[0]
  const currentYear = parseInt(currentDate.split('-')[0])
  const currentMonth = parseInt(currentDate.split('-')[1])
  const currentDay = parseInt(currentDate.split('-')[2])

  if (currentYear - year === 18) {
    if (month - currentMonth > 0) {
      Object.assign(errors, {
        dateOfBirth: 'The birthdate is below 18 years old',
      })
    }
    if (currentMonth - month === 0) {
      if (day > currentDay) {
        Object.assign(errors, {
          dateOfBirth: 'The birthdate is below 18 years old',
        })
      }
    }
  } else if (currentYear - year < 18) {
    Object.assign(errors, {
      dateOfBirth: 'The birthdate is below 18 years old',
    })
  }
  return errors
}
