import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {inject, observer} from "mobx-react";
import styles from './css/styles.module.css';
import {onSubmit, setVariables, validate} from "./functions";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {keyPressedType} from "../../../../services/Commons";
import {permitRoutePath} from "../../../../config.js";
import {Tab, Tabs} from 'react-bootstrap';
import licenseActiveIcon from "../../../../icons/Icon_status_approved.svg";
import licenseInactiveIcon from "../../../../icons/icon_denied.svg";
import {NotificationBannerComponent} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {
    ViewPermitOrganizationDetailsComponent
} from "../../../../components/CharitableGamingComponents/Permit/View/ViewPermitOrganizationDetailsComponent";
import {
    ViewPermitInformationComponent
} from "../../../../components/CharitableGamingComponents/Permit/View/ViewPermitInformationComponent";
import moment from "moment";
import {
    UpdatePermitDateOfIssuanceComponent
} from "../../../../components/CharitableGamingComponents/Permit/Update/UpdatePermitDateOfIssuanceComponent";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import {useFormik} from "formik";

export const PermitProfilePage = inject("charitableGamingStore")(observer(({raffleId, charitableGamingStore}: any) => {

        const history: any = useHistory();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const {selectedPermitHolder, notificationBanner} = charitableGamingStore;

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [updateDialogToggle, setUpdateDialogToggle] = useState<boolean>(false);

        useEffect(() => {
            if (requestCompleted === false) {
                charitableGamingStore.getPermitHolderDetailsByRaffleID(raffleId, setRequestCompleted);
            }
        }, []);

        const hideNotificationBanner = () => {
            charitableGamingStore.showNotificationBanner({show: false, text: ''});
        }

        setVariables(raffleId, charitableGamingStore);

        const formik = useFormik({
            initialValues: {
                effectiveDate: ''
            },
            validate,
            onSubmit
        });

        return (
            <>
                <div className={`${styles['permit-holder-details-page-container']}`}
                     onClick={() => {

                     }}
                >
                    <div className={`${styles['permit-holder-details-page-back-button-container']} ${styles['']}`}>
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                        />
                        <div
                            className={`${styles['permit-holder-details-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${permitRoutePath}/manage`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${permitRoutePath}/manage`);
                            }}
                        >Back to Permit Profile Management
                        </div>
                    </div>

                    <div className={`${styles["permit-holder-details-page-ribbon-container"]}`}>
                        <div className={`${styles["permit-holder-details-page-ribbon-container-col-1"]}`}>
                            <div className={styles["permit-holder-details-page-ribbon-title-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-title-label"]}>Raffle ID</div>
                                <div
                                    className={styles["permit-holder-details-page-ribbon-title-value"]}>{`${selectedPermitHolder.raffleId}`}</div>
                            </div>
                            <div className={styles["permit-holder-details-page-ribbon-subtitle-container"]}>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                    <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Organization</div>
                                    <div className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{selectedPermitHolder.orgName}</div>
                                </div>
                                <div className={styles["permit-holder-details-page-ribbon-subtitle-label-value-container"]}>
                                    <div className={styles["permit-holder-details-page-ribbon-subtitle-label"]}>Date of Issuance</div>
                                    <div className={styles["permit-holder-details-page-ribbon-subtitle-value"]}>{moment(selectedPermitHolder.effectiveDate).utc().format('MM/DD/YYYY')}</div>
                                </div>
                                <div className={styles["table-data-data-text-icon-container"]}>
                                    <div
                                        className={styles["table-data-data-text"]}>{(selectedPermitHolder.orgActive) ? "Active" : "Expired"}</div>
                                    <img
                                        src={(selectedPermitHolder.orgActive) ? licenseActiveIcon : licenseInactiveIcon}
                                        alt="status icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`${styles["permit-holder-details-page-ribbon-container-col-2"]}`}>
                            {checkRenderPermissions(permissions.CAN_UPDATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['license-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             formik.resetForm({
                                                 values: {
                                                     effectiveDate: ''
                                                 }
                                             });
                                             setUpdateDialogToggle(true);
                                         }
                                     }}
                                     onClick={() => {
                                         formik.resetForm({
                                             values: {
                                                 effectiveDate: ''
                                             }
                                         });
                                         setUpdateDialogToggle(true);
                                     }}>
                                    <div
                                        className={`${styles['license-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>
                                        Update Date of Issuance
                                    </div>
                                </div> : null}
                        </div>
                    </div>

                    <div className={`${styles["permit-holder-details-page-tabs-container"]}`}>
                        <Tabs className={`${styles["permit-holder-details-tabs"]}`} id="permit-holder-details-tabs">
                            <Tab eventKey="licenseDetails" title="Permit Details">
                                <div className={`${styles["permit-holder-details-tab-main-container"]}`}>
                                    <div className={`${styles["permit-holder-details-tab-main-container-row-3"]}`}>
                                        <div
                                            className={`${styles["permit-holder-details-tab-main-container-row-3-col-1"]}`}>
                                            <ViewPermitOrganizationDetailsComponent
                                                permitHolderDetails={selectedPermitHolder}/>
                                        </div>
                                        <div
                                            className={`${styles["permit-holder-details-tab-main-container-row-3-col-2"]}`}>
                                            <ViewPermitInformationComponent permitHolderDetails={selectedPermitHolder}/>
                                        </div>
                                    </div>

                                </div>
                            </Tab>
                        </Tabs>

                    </div>

                </div>
                {updateDialogToggle ?
                    <UpdatePermitDateOfIssuanceComponent
                        formik={formik}
                        setUpdateDialogToggle={setUpdateDialogToggle}
                    /> : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        text={notificationBanner.text}
                        type={notificationBanner.type}
                        handleHide={hideNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);