import {ElectronicsFundTransferFormInterface} from "./interfaces";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {licenseeRoutePath} from "../../../../config.js";

let formik: any;
let history: any;
let setPageMessageType: any;
let licenseNumber: any;
let charitableGamingStore: any;

export const setVariables = (formikObj: any, historyObj: any, setPageMessageTypeObj: any,
                             licenseNumberObj: any, charitableGamingStoreObj: any) => {
    formik = formikObj;
    history = historyObj;
    setPageMessageType = setPageMessageTypeObj;
    licenseNumber = licenseNumberObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === "routingNumber" || e.target.name === "accountNumber") {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else if (e.target.name === "emailAddress") {
        formik.setFieldValue(e.target.name, e.target.value.trim(), true);
    } else {
        formik.setFieldValue(e.target.name, e.target.value, true);
    }
}

export const validate = async (values: ElectronicsFundTransferFormInterface) => {

    const errors = {};

    if (!values.bankName) {
        Object.assign(errors, {bankName: InputValidationMessages.RequiredField});
    }

    if (!values.accountNumber) {
        Object.assign(errors, {accountNumber: InputValidationMessages.RequiredField});
    }

    if (!values.routingNumber) {
        Object.assign(errors, {routingNumber: InputValidationMessages.RequiredField});
    } else if (values.routingNumber && values.routingNumber.length !== 9) {
        Object.assign(errors, {routingNumber: InputValidationMessages.InvalidRoutingNumber});
    } else if (values.routingNumber && values.routingNumber.length === 9) {
        await fetch(`https://www.routingnumbers.info/api/name.json?rn=${values.routingNumber}`,
            {
                method: 'GET',
            })
            .then(response => response.json())
            .then(res => {
                if (res.code !== 200) {
                    Object.assign(errors, {routingNumber: InputValidationMessages.InvalidRoutingNumber});
                }
            })
            .catch((error) => {
                console.log(error.toString());
            });
    }

    /*if (!values.accountType) {
        Object.assign(errors, {accountType: InputValidationMessages.RequiredField});
    }*/

    if (!values.nameOnAccount) {
        Object.assign(errors, {nameOnAccount: InputValidationMessages.RequiredField});
    }

    if (!values.authorizingParty) {
        Object.assign(errors, {authorizingParty: InputValidationMessages.RequiredField});
    }

    if (!values.emailAddress) {
        Object.assign(errors, {emailAddress: InputValidationMessages.RequiredField});
    } else if (values.emailAddress && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
        Object.assign(errors, {emailAddress: InputValidationMessages.InvalidEmail});
    }

    return errors;
}

export const onSubmit = async (values: ElectronicsFundTransferFormInterface) => {
    charitableGamingStore.updateEFTInformation(licenseNumber, values, history);
}

export const YesCancel = function () {
    // Go back to previous page
    history.push({
        pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
        state: {tabName: 'EFTInformation'} // This is so the 'EFT Details' Tab is selected when you go back
    });
}

export const YesSave = function () {
    formik.submitForm();
}
