import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const DeleteBarcodeConfirmationComponent = ({YesDeleteBarcode, deleteBarcodeToggle, setDeleteBarcodeToggle}: any) => {

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["save-confirmation-dialog-main-container"]}>
                    <div
                        id="save-confirmation-dialog-container"
                        className={stylesMessageBox["save-confirmation-dialog-container"]}
                    >
                        <div
                            className={stylesMessageBox["save-confirmation-dialog-message"]}
                        >
                            Are you sure you would like to delete this barcode?
                        </div>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 32) {
                                    YesDeleteBarcode(deleteBarcodeToggle.deleteIndex);
                                    setDeleteBarcodeToggle({show: false, deleteIndex: -1});
                                }
                            }}
                            onClick={() => {
                                YesDeleteBarcode(deleteBarcodeToggle.deleteIndex);
                                setDeleteBarcodeToggle({show: false, deleteIndex: -1});
                            }}
                            className={stylesMessageBox["save-confirmation-dialog-yes-button"]}
                        >
                            <div
                                className={
                                    stylesMessageBox["save-confirmation-dialog-yes-button-text"]
                                }
                            >
                                Yes, Delete
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            onKeyDown={(e: any) => {
                                if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                    setDeleteBarcodeToggle({show: false, deleteIndex: -1});
                                }
                            }}
                            role="button"
                            onClick={() => {
                                setDeleteBarcodeToggle({show: false, deleteIndex: -1});
                            }}
                            className={
                                stylesMessageBox["save-confirmation-dialog-no-button"]
                            }
                        >
                            Go Back
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};