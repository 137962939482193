import React from "react"
import "./styles.scss"
import { FaRegQuestionCircle } from "react-icons/fa"

export type GrossPaymentTooltipUIProps = {
  displayTotal: string
}

const ROOT_CLASS = 'gross-payment-tooltip'
export const GrossPaymentTooltipUI = (props: GrossPaymentTooltipUIProps) => {
  const { displayTotal } = props 

  return (
    <div className={ ROOT_CLASS }>
      <div className={ 'container' }>
          <FaRegQuestionCircle />
          <div className={ 'tooltip-message-container' }>
            <div>
              The split amounts combined must be equal to the Gross Payment: ${ displayTotal }
            </div>
          </div>
      </div>
    </div>
  )
}