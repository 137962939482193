import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./css/styles.module.css";
import { CallingPage, onSubmit, setFiledValue, setVariables, validate } from "./functions";
import { useFormik } from 'formik';
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import { getPaymentDataInterface, PaymentMethodInterface } from "./interfaces";

import { PageMessageCommonComponent } from "../../../components/CommonComponents/PageMessageCommonComponent";
import { CircularProgress } from "@material-ui/core";
import { claimsSupportRoutePath } from './../../../config.js';
import { keyPressedType } from "../../../services/Commons";
import { PaymentMethodComponent } from "../../../components/ClaimsComponents/PaymentMethodComponent";
let eventEmitterInitialize: any = undefined;
const ReSubmitPaymentPage = ({ id, claimId, storesObject }: any) => {

    const history = useHistory();
    const claimStore: any = storesObject.claimStore;
    
    const identityValues: PaymentMethodInterface = {
        accountType: "",
        routingNumber: 0,
        reEnterRoutingNumber: 0,
        accountNumber: 0,
        reEnterAccountNumber: 0
    }

    const getPaymentData: getPaymentDataInterface = {
        identityValues
    }

    const formikPayment = useFormik({
        initialValues: getPaymentData,
        onSubmit,
        validate,
    });



    const setToggleValue: any = { value: false }
    const [pageMessage, setPageMessage] = useState<any>({ message: "", messageType: "" });
    const [saveToggle, setSaveToggle] = useState<any>(setToggleValue);
    const [cancelToggle, setCancelToggle] = useState<boolean>(false);
    const [ bankInformation, setBankInformation ] = useState<any>({value:undefined})
    
    setVariables( storesObject,formikPayment, history, setPageMessage, claimId, id,bankInformation );

    useEffect(() => {
        document.getElementById("update-profile-save-box-container")?.focus();
        document.getElementById("update-profile-save-box-container")?.focus();

    })
    const goToBackScreen = () => {
        history.push(`${claimsSupportRoutePath}/mobile-claims-details/${id}/${claimId}`)
    }

    return (<>
        {(storesObject.claimStore === null && pageMessage.message === "") && <div style={{ position: 'fixed', top: 0, left: 0, width: "100vw", height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0,0.3)", color: 'white' }}>
            <CircularProgress size="8em" color='inherit' thickness={2} />
        </div>}
        <div onClick={(e: any) => {
            pageMessage.message !== "" &&
                setPageMessage({ message: "", messageType: "" })
        }}
            className={styles['page-container']}>
            <div className={styles['top-ribbon']}>
                <div className={styles['top-ribbon-text']} tabIndex={0}>Re-Submit Payment</div>
                <div className={styles['top-ribbon-button-container']}>
                    <div role="button" tabIndex={0} aria-pressed="false"
                        onKeyPress={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                setCancelToggle(!cancelToggle);

                            }
                        }
                        }
                        onClick={() => setCancelToggle(!cancelToggle)}
                        className={styles['top-ribbon-cancel-button']}>Cancel
                    </div>
                    <div
                        style={{ opacity: Object.keys(formikPayment.errors).length > 0 ? "0.4" : "1", cursor: Object.keys(formikPayment.errors).length === 0 ? "pointer" : "not-allowed" }}
                        role="button"
                        tabIndex={0}
                        aria-pressed="false"
                        onKeyPress={(e: any) => {
                            if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                if (Object.keys(formikPayment.errors).length === 0) {
                                    setSaveToggle({ value: true });
                                    saveToggle.value = true;
                                }

                            }
                        }
                        }
                        onClick={() => {
                            if (Object.keys(formikPayment.errors).length === 0) {
                                setSaveToggle({ value: true });
                                saveToggle.value = true;
                            }
                        }} className={styles['top-ribbon-save-button']}>Re-Submit
                    </div>
                </div>
            </div>
            {pageMessage.message !== "" &&
                <PageMessageCommonComponent message={pageMessage.message}
                    messageType={pageMessage.messageType} />}
            <div className={styles['page-container-body-container']}>
                <div className={styles['page-container-body']}>
                    <div className={styles['page-container-body-left']}>
                        {<PaymentMethodComponent formikPayment={formikPayment}
                            setFiledValue={setFiledValue}
                            callingPage={CallingPage.PaymentMethod} 
                            bankInformation={bankInformation}/>}
                    </div>
                </div>
            </div>
        </div>

        {saveToggle.value && <div className={stylesMessageBox['window-container']}>
            <div id={stylesMessageBox['update-profile-save-box-container']} className={stylesMessageBox['update-profile-save-box-container']}>
                <div tabIndex={0} className={stylesMessageBox['update-profile-save-box-message']}>Are you sure you
                    would like to save?
                </div>
                <div role='button'
                    onKeyPress={(e: any) => {
                        if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                            localStorage.setItem('activeTab', "activity");
                            formikPayment.submitForm().then();
                            (saveToggle.value = false)

                        }
                    }
                    }
                    onClick={() => {
                        localStorage.setItem('activeTab', "activity");
                        formikPayment.submitForm().then();
                        (saveToggle.value = false)
                    }}
                    className={stylesMessageBox['update-profile-save-box-button-yes']}>
                    <div className={stylesMessageBox['update-profile-save-button-yes-text']}>Yes, Save</div>
                </div>
                <div role='button'
                    onClick={() => setSaveToggle(!saveToggle)}
                    className={stylesMessageBox['player-email-and-phone-message-box-button-no']}>Go Back

                </div>
            </div>
        </div>}
        {cancelToggle && <div className={stylesMessageBox['window-container']}>
            <div id={stylesMessageBox['update-profile-save-box-container']} className={stylesMessageBox['update-profile-save-box-container']}>
                <div tabIndex={0} className={stylesMessageBox['update-profile-save-box-message']}>Are you sure you
                    would like to cancel without saving?
                </div>
                <div role='button'
                    onKeyPress={(e: any) => {
                        if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                            goToBackScreen();

                        }
                    }
                    }
                    onClick={() => goToBackScreen()}
                    className={stylesMessageBox['update-profile-save-box-button-yes']}>
                    <div className={stylesMessageBox['update-profile-save-button-yes-text']}>Yes, Cancel</div>
                </div>
                <div role='button'
                    onKeyPress={(e: any) => {
                        if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                            goToBackScreen();

                        }
                    }
                    }
                    onClick={() => setCancelToggle(!cancelToggle)}
                    className={stylesMessageBox['player-email-and-phone-message-box-button-no']}>Go Back
                </div>
            </div>
        </div>}
    </>
    );
}

export default ReSubmitPaymentPage