import * as React from 'react'
import { PromotionalMessagesPermissions } from '../../../api/playslips-fetcher/permissions'
import { useFetchAllPromotionMessages } from '../../../api/playslips-fetcher/use-fetch-playslips'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { PromotionalMessagesPageUI } from './promotional-messages-page-ui'

export const PromotionalMessagesPageContainer = () => {
  const { data, isLoading, error, fetchAllPromotionMessages } =
    useFetchAllPromotionMessages()

  return (
    <PromotionalMessagesPageUI
      data={data}
      isLoading={isLoading}
      error={error}
      fetchAllPromotionMessages={fetchAllPromotionMessages}
      actionsDisabled={
        !userHasAnyOfPermissions([
          PromotionalMessagesPermissions.CAN_ADMIN_PROMOTIONAL_MESSAGES,
        ])
      }
    />
  )
}
