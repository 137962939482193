import * as React from 'react'
import { GetFutureReissuePaymentDetailsResponse } from '../../../../api/future-reissue-payment-details-fetcher/api-types'
import { MaskedSsnUI } from '../../../CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import { PayTaxIndicatorMapping } from '../../../../pages/PaymentsPages/Constants'
import './styles.scss'

const ROOT_CLASS = 'future-reissue-payment-recipient-details'

export type PaymentRecipientDetailsUIProps = {
  futureReissuePaymentDetailsData: GetFutureReissuePaymentDetailsResponse
}

export const FutureReissuePaymentRecipientDetailsUI = (
  props: PaymentRecipientDetailsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <section className={`recipient-details-container`}>
        <div className={`row-container-title`}>Recipient Details</div>
        <div className={`column-1`}>Payee</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.payeeName}
        </div>
        <div className={`column-3`}>Tax ID No.</div>
        <div className={`column-4`}>
          {
            PayTaxIndicatorMapping[
              props.futureReissuePaymentDetailsData.payTaxIndicator
            ]
          }
        </div>
        <div className={`column-1`}>TIN</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.tin ? (
            <MaskedSsnUI ssn={props.futureReissuePaymentDetailsData.tin} />
          ) : (
            <span>--</span>
          )}
        </div>
        <div className={`column-3`}>TIN Type</div>
        <div className={`column-4`}>
          <span>--</span>
        </div>
      </section>
      <section className="demographic-details-container">
        <div className={`row-container-title`}>
          Demographic &amp; Contact Info
        </div>
        <div className={`column-1`}>Mailing Address 1</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.payeeAddress1}
        </div>
        <div className={`column-3`}>Mailing Address 2</div>
        <div className={`column-4`}>
          {props.futureReissuePaymentDetailsData.payeeAddress2}
        </div>
        <div className={`column-1`}>City</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.payeeCity}
        </div>
        <div className={`column-3`}>State/Region</div>
        <div className={`column-4`}>
          {props.futureReissuePaymentDetailsData.payeeState}
        </div>
        <div className={`column-1`}>Zip/Postal Code</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.payeeZip}
        </div>
        <div className={`column-3`}>Country</div>
        <div className={`column-4`}>
          {props.futureReissuePaymentDetailsData.payeeCountry}
        </div>
        <div className={`column-1`}>Phone</div>
        <div className={`column-2`}>
          {props.futureReissuePaymentDetailsData.payeePhone}
        </div>
        <div className={`column-3`}>Email</div>
        <div className={`column-4`}>
          {props.futureReissuePaymentDetailsData.payeeEmail}
        </div>
      </section>
    </div>
  )
}
