import * as React from 'react'
import { PromotionsHourlyReportResponse } from '../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../utilities/helpers'
import { AsyncContainerUI } from '../../CommonComponents/async-container-component/async-container-ui'
import { getHourDisplayString } from '../../../utilities/date-helpers'
import './styles.scss'

type PromotionsHourlyReportSummaryProps = {
  reportLoading: boolean
  reportError?: string
  reportData?: PromotionsHourlyReportResponse
}

const ROOT_CLASS = 'promotions-hourly-report-summary-ui'

const SummaryItem = (props: { leftText: string; rightText: string }) => (
  <div className={getBlockClass(ROOT_CLASS, 'summary-item-text')}>
    <p>{props.leftText}</p>
    <p>{props.rightText}</p>
  </div>
)

export const PromotionsHourlyReportSummaryUI = (
  props: PromotionsHourlyReportSummaryProps
) => {
  return (
    <section
      aria-label="Promotions hourly report summary"
      className={ROOT_CLASS}
    >
      <AsyncContainerUI
        isLoading={props.reportLoading}
        color="white"
        error={props.reportError}
        fitted={true}
        circularProgressSizeEm={6}
      >
        <header className={getBlockClass(ROOT_CLASS, 'header')}>Summary</header>
        <p className={getBlockClass(ROOT_CLASS, 'total-tickets-header')}>
          Total Number of Tickets
          <span className={getBlockClass(ROOT_CLASS, 'total-tickets-text')}>
            {props?.reportData?.total}
          </span>
        </p>
        <div className={getBlockClass(ROOT_CLASS, 'summary-item-header')}>
          <p>Hour</p>
          <p>Count</p>
        </div>
        {props?.reportData?.hours.map((hourScanCount, hour) => (
          <SummaryItem
            key={hour}
            leftText={getHourDisplayString(hour)}
            rightText={hourScanCount.toString()}
          />
        ))}
      </AsyncContainerUI>
    </section>
  )
}
