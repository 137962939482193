import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {keyPressedType} from "../../../../services/Commons";
import {useFormik} from "formik";
import styles from "./css/styles.module.css";
import goBackIcon from "../../../../icons/Icon_arrows_carrot_white-left.svg";
import {
    getAllCurrentPermitHolders,
    handleCheckboxChange,
    onSubmit,
    setFieldValue,
    setVariables,
    validate
} from "./functions";
import {FaPlus} from 'react-icons/fa';
import {CircularProgress} from "@material-ui/core";
import {checkRenderPermissions} from "../../../../helpers";
import {permissions} from "../../../../constants";
import {
    GenericErrorDialogComponent
} from "../../../../components/CommonComponents/GenericErrorDialogComponent";
import {
    NotificationBannerComponent,
    NotificationType
} from "../../../../components/HomeComponent/NotificationBannerComponent";
import {
    PermitSearchResultComponent
} from "../../../../components/CharitableGamingComponents/Permit/PermitSearchResultComponent";
import {
    PermitSearchBarComponent
} from "../../../../components/CharitableGamingComponents/Permit/PermitSearchBarComponent";
import {permitRoutePath} from "../../../../config";

export const PermitProfileManagementPage = inject("charitableGamingStore")(observer(({charitableGamingStore}: any) => {

        const history: any = useHistory();
        const location = useLocation();

        const userpolicies: any = localStorage.getItem("userpolicies");

        const formik = useFormik({
            initialValues: {
                streetAddress: '',
                orgName: '',
                city: '',
                raffleId: '',
                includeActivePermitHolders: true,
                includeInactivePermitHolders: false
            },
            validate,
            onSubmit
        });

        const [unfilteredPermitHolders, setUnfilteredPermitHolders] = useState<any>([]);
        const [filteredPermitHolders, setFilteredPermitHolders] = useState<any>([]);

        const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(0);

        const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
        const [notificationBanner, showNotificationBanner] = useState<any>({show: false, text: ''});
        const [newPermitCreated, setNewPermitCreated] = useState<boolean>(false);

        useEffect(() => {
            if (unfilteredPermitHolders.length === 0 && requestCompleted === false) {
                getAllCurrentPermitHolders();
            }
            if (location.state !== undefined && !newPermitCreated) {
                setNewPermitCreated(true)
                showNotificationBanner({show: true, text: location.state.detail});
            }
        });

        setVariables(charitableGamingStore, unfilteredPermitHolders, setUnfilteredPermitHolders, filteredPermitHolders,
            setFilteredPermitHolders, setRequestCompleted, setCurrentPage, setShowErrorDialog, showNotificationBanner);


        return (
            <>
                {(!requestCompleted) ? <div style={{
                    zIndex: 1000,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(0,0,0,0.3)",
                    color: 'white'
                }}>
                    <CircularProgress size="4em" color='inherit' thickness={2}/>
                </div> : null}

                <div className={`${styles['permit-profile-mgmt-page-container']} ${styles['']}`}
                     onClick={() => {
                         if (notificationBanner.text !== "") {
                             showNotificationBanner({show: false, text: ""});
                         }
                     }}
                >
                    <div tabIndex={0}
                         className={`${styles['permit-profile-mgmt-page-back-button-container']} ${styles['']}`}>
                        <img
                            style={{
                                cursor: "pointer"
                            }}
                            src={goBackIcon}
                            className={styles[""]}
                            alt="go back icon"
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${permitRoutePath}/`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${permitRoutePath}/`);
                            }}
                        />
                        <div
                            className={`${styles['permit-profile-mgmt-page-back-button-container-message']} ${styles['']}`}
                            style={{
                                cursor: "pointer"
                            }}
                            onKeyDown={(ev: any) => {
                                if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                    history.push(`${permitRoutePath}/`);
                                }
                            }}
                            onClick={() => {
                                history.push(`${permitRoutePath}/`);
                            }}
                        >Back
                            to Charitable Gaming
                        </div>
                    </div>
                    <div className={`${styles['permit-profile-mgmt-page-ribbon-container']} ${styles['']}`}>
                        <div tabIndex={0}
                             className={`${styles['permit-profile-mgmt-page-ribbon-title']} ${styles['']}`}>Permit Profile
                            Management
                        </div>
                        <div className={`${styles['permit-profile-mgmt-page-ribbon-buttons-container']}`}>
                            {checkRenderPermissions(permissions.CAN_CREATE_PERMIT_PROFILES, JSON.parse(userpolicies)) ?
                                <div tabIndex={0}
                                     className={`${styles['permit-profile-mgmt-page-ribbon-create-license-button']} ${styles['']}`}
                                     role="button"
                                     onKeyDown={(ev: any) => {
                                         if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                                             history.push(`${permitRoutePath}/create`);
                                         }
                                     }}
                                     onClick={() => {
                                         history.push(`${permitRoutePath}/create`);
                                     }}>
                                    <FaPlus style={{cursor: "pointer"}}/>
                                    <div
                                        className={`${styles['permit-profile-mgmt-page-ribbon-create-license-button-label']} ${styles['']}`}>Create
                                        Permit Profile
                                    </div>
                                </div> : null}
                        </div>

                    </div>

                    <div
                        className={`${styles['permit-profile-mgmt-page-search-bar-and-notification-bar-container']} ${styles['']}`}>
                        <PermitSearchBarComponent formik={formik} setFieldValue={setFieldValue}
                                                  handleCheckboxChange={handleCheckboxChange}/>
                    </div>
                    {filteredPermitHolders.length <= 0 ?
                        <div className={`${styles['permit-profile-mgmt-page-search-no-results-container']}`}>
                            <div className={`${styles['permit-profile-mgmt-page-search-no-results-container-text']}`}>
                                No Results. Please try a different search.
                            </div>
                        </div>
                        :
                        <PermitSearchResultComponent
                            permitHoldersList={filteredPermitHolders}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
                {showErrorDialog ?
                    <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog}/>
                    : null
                }
                {notificationBanner.show ?
                    <NotificationBannerComponent
                        type={NotificationType.Success}
                        text={notificationBanner.text}
                        handleHide={showNotificationBanner}
                    /> : null
                }
            </>

        );
    })
);