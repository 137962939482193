import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import filterExpandIcon from '../../../../icons/Icon_arrows_carrot_teal-down.svg'
import filterCollapseIcon from '../../../../icons/Icon_arrows_carrot_teal-up.svg'
import { clearSearch } from '../../../../pages/AnnuitiesPages/TPAManagement/AnnuityManagementPage/functions'
import { keyPressedType } from '../../../../services/Commons'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import styles from './css/styles.module.css'
export const AnnuityManagementFilterSortComponent = ({
  formik,
  setFieldValue,
}: any) => {
  const [statusFilter] = useState<string[]>([
    'Active',
    'On Hold',
    'Completed (Inactive)',
  ])
  const [showFilters, setShowFilters] = useState<boolean>(false)

  const activateSearchButton =
    (formik.values.payeeName !== '' &&
      Object.keys(formik.errors).length === 0) ||
    (formik.values.tinNumber !== '' &&
      Object.keys(formik.errors).length === 0) ||
    (formik.values.documentId !== '' && Object.keys(formik.errors).length === 0)

  return (
    <>
      <div
        className={`${styles['annuity-page-search-bar-container']} ${styles['']}`}
      >
        <div
          className={`${styles['annuity-page-search-bar-title']} ${styles['']}`}
        >
          Search
        </div>
        <div
          className={`${styles['annuity-page-search-bar-filter-container']} ${styles['']}`}
        >
          <div
            className={`${styles['annuity-page-search-bar-filter-title-bar']} ${styles['']}`}
          >
            <div
              className={`${styles['annuity-page-search-bar-filter-title']} ${styles['']}`}
            >
              Filter
            </div>
            <div
              tabIndex={0}
              className={`${styles['annuity-page-search-bar-filter-title-icon-wrapper']} ${styles['']}`}
              onKeyDown={(e: any) => {
                if (
                  keyPressedType(e) === 13 ||
                  keyPressedType(e) === 0 ||
                  keyPressedType(e) === 32
                ) {
                  setShowFilters(!showFilters)
                }
              }}
              onClick={() => {
                setShowFilters(!showFilters)
              }}
            >
              <img
                src={showFilters ? filterCollapseIcon : filterExpandIcon}
                className={styles['']}
                alt="filter expander icon"
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>

        {showFilters ? (
          <div
            className={`${styles['annuity-page-search-bar-filter-expanded-container']}`}
          >
            <div className={`${styles['annuity-page-checkbox-container']}`}>
              <div className={`${styles['mslc-label']}`}>Filter</div>
              <div className={`${styles.custom_select_div}`}>
                <select
                  id="statusCode"
                  name="statusCode"
                  onChange={(e: any) => {
                    setFieldValue(e, formik)
                    formik.submitForm()
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select"
                  className={styles['custom_select']}
                >
                  <option value="All" selected>
                    All
                  </option>
                  {statusFilter.map((filterValue: string) => (
                    <option
                      selected={
                        formik.values.statusCode.toLowerCase() ===
                        filterValue.toLowerCase()
                      }
                      value={filterValue}
                    >
                      {filterValue}
                    </option>
                  ))}
                </select>
                <span className={`${styles.custom_arrow}`}></span>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`${styles['annuity-page-search-bar-input-button-container']} ${styles['']}`}
        >
          <div
            className={`${styles['annuity-page-search-bar-label-textbox-container']} ${styles['']}`}
          >
            <div className={`${styles['mslc-label']} ${styles['']}`}>TIN</div>
            <InputMask
              className={`${styles['mslc-textbox']}`}
              mask="999999999"
              maskChar=""
              id="tin"
              name="tin"
              onChange={(e: any) => setFieldValue(e, formik)}
              value={formik.values.tin}
              aria-label={`${formik.values.tin}.`}
              placeholder={'000000000'}
            ></InputMask>
            {formik.errors.tin ? (
              <ErrorMessageFieldCommonComponent
                errorMessages={formik.errors.tin}
              />
            ) : null}
          </div>
          <div
            className={`${styles['annuity-page-search-bar-label-textbox-container']} ${styles['']}`}
          >
            <div className={`${styles['mslc-label']} ${styles['']}`}>
              Document ID{' '}
              <span
                className={`${styles['annuity_mustbeexactmatch_text']} ${styles['']}`}
              >
                {' '}
                Must be exact match{' '}
              </span>
            </div>
            <input
              className={`${styles['mslc-textbox']}`}
              type="text"
              id="documentId"
              name="documentId"
              onChange={(e: any) => setFieldValue(e, formik)}
              onBlur={formik.handleBlur}
              value={formik.values.documentId}
              aria-label={`${formik.values.documentId}.`}
            ></input>
            {formik.errors.documentId ? (
              <ErrorMessageFieldCommonComponent
                errorMessages={formik.errors.documentId}
              />
            ) : null}
          </div>
          <div
            className={`${styles['annuity-page-search-bar-label-textbox-container']} ${styles['']}`}
          >
            <div className={`${styles['mslc-label']} ${styles['']}`}>
              Payee Name
            </div>
            <input
              className={`${styles['mslc-textbox']}`}
              type="text"
              id="payeeName"
              name="payeeName"
              value={formik.values.payeeName}
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                setFieldValue(e, formik)
              }}
            />
            {formik.errors.payeeName ? (
              <ErrorMessageFieldCommonComponent
                errorMessages={formik.errors.payeeName}
              />
            ) : null}
          </div>
          <div
            tabIndex={0}
            className={`${styles['mslc-search-button']} ${styles['']}`}
            role="button"
            style={{
              opacity: activateSearchButton ? '1' : '0.4',
              cursor: activateSearchButton ? 'pointer' : 'not-allowed',
            }}
            onKeyDown={(ev: any) => {
              if (
                keyPressedType(ev) === 13 ||
                keyPressedType(ev) === 0 ||
                keyPressedType(ev) === 32
              ) {
                if (activateSearchButton) {
                  formik.submitForm()
                }
              }
            }}
            onClick={() => {
              if (activateSearchButton) {
                formik.submitForm()
              }
            }}
          >
            Search
          </div>
          <div
            tabIndex={0}
            className={`${styles['mslc-default-button']} ${styles['']}`}
            role="button"
            onKeyDown={(ev: any) => {
              if (
                keyPressedType(ev) === 13 ||
                keyPressedType(ev) === 0 ||
                keyPressedType(ev) === 32
              ) {
                clearSearch(formik)
              }
            }}
            onClick={() => {
              clearSearch(formik)
            }}
          >
            Clear Search
          </div>
        </div>
      </div>
    </>
  )
}
