import { CircularProgress } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { PayeeSearchCmpt } from '../../../../components/AnnuitiesComponents/Common/PayeeSearchCmpt'
import { AnnuityManagementDetailsComponent } from '../../../../components/AnnuitiesComponents/TPAManagement/TpaDetailsCmpt'
import { annuitiesRoutePath } from '../../../../config'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { TimePaymentAccountResponse } from '../../../../types/Annuities/responses/time-payment-account'
import { getPlaceholderTpa } from '../AnnuitySubAccountsPage/functions'
import styles from './css/styles.module.css'

export const AnnuityManagementDetailsPage = inject('annuitiesStore')(
  observer(({ annuitiesStore }: any) => {
    const initialAnnuityAccountDetails: TimePaymentAccountResponse =
      getPlaceholderTpa()

    const history = useHistory()
    const bannerContext = React.useContext(BannerContext)
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false)
    const [beginPayeeSearch, setBeginPayeeSearchFlow] = useState<boolean>(false)
    const [timePaymentAccount, setTimePaymentAccount] =
      useState<TimePaymentAccountResponse>(initialAnnuityAccountDetails)

    //variable to grab ID coming from TPA list
    const tpaID =
      window.location.href.split('/')[
        window.location.href.split('/').length - 1
      ]

    useEffect(() => {
      const fetchAnnuityAccountDetails = async () => {
        try {
          const response = await annuitiesStore.populateAnnuityAccountDetails(
            tpaID
          )
          setTimePaymentAccount(response)
          setRequestCompleted(true)
        } catch (error) {
          setRequestCompleted(true)
          bannerContext.setBannerInfo({
            message: `${error}`,
            error: true,
          })
        }
      }

      fetchAnnuityAccountDetails()
    }, [])

    return (
      <>
        <div className={styles['page-container']}>
          {/* Circular Progress initial Load screen */}
          {!requestCompleted && (
            <div
              style={{
                position: 'absolute',
                zIndex: 50,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)',
                color: 'white',
              }}
            >
              <CircularProgress size="8em" color="inherit" thickness={2} />
            </div>
          )}
          {/* Payee Search goes here */}
          {beginPayeeSearch && (
            <PayeeSearchCmpt
              isFromCreationFlow={false}
              setBeginPayeeSearchFlow={setBeginPayeeSearchFlow}
              accountId={timePaymentAccount?.id}
              annuitiesStore={annuitiesStore}
            />
          )}
          {/* back btn jsx */}
          <div className={styles['back-btn-container']}>
            <button
              type="button"
              onKeyPress={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  history.push(`${annuitiesRoutePath}/annuity-management`)
                }
              }}
              onClick={() => {
                history.push(`${annuitiesRoutePath}/annuity-management`)
              }}
              className={styles['back-btn-inner-container']}
            >
              <FaAngleLeft
                title="left arrow icon"
                className={styles['back-btn-custom']}
              />
              <div className={styles['back-btn-text']}>
                Back to Time Payment Account Management
              </div>
            </button>
          </div>
          {/* Header component jsx */}
          <div className={styles['cmpnt-container']}>
            <div className={styles['title-header']}>
              <div className={styles['flex-space-bw']}>
                <div>
                  <div className={styles['title-text']}>
                    Time Payment Account Details
                  </div>
                  <div className={styles['flex-left']}>
                    <div className={styles['document-id-label']}>
                      Annuity ID
                    </div>
                    <div className={styles['document-id-value']}>
                      {timePaymentAccount.documentId}
                    </div>
                  </div>
                </div>
                <div className={styles['flex-right']}>
                  <div>
                    <button
                      type="button"
                      className={styles['full-payment-details-btn']}
                      onKeyPress={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          history.push(
                            `${annuitiesRoutePath}/annuity-full-payment-schedule/${tpaID}`
                          )
                        }
                      }}
                      onClick={() => {
                        history.push(
                          `${annuitiesRoutePath}/annuity-full-payment-schedule/${tpaID}`
                        )
                      }}
                    >
                      Full Payment Schedule
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className={styles['sub-account-btn']}
                      onKeyPress={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          history.push(
                            `${annuitiesRoutePath}/annuity-update-sub-accounts?tpaId=${tpaID}`
                          )
                        }
                      }}
                      onClick={() => {
                        history.push(
                          `${annuitiesRoutePath}/annuity-update-sub-accounts?tpaId=${tpaID}`
                        )
                      }}
                    >
                      Update Sub-Accounts
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Main component */}
          <div className={styles['addtnl-cmpt-container']}>
            {timePaymentAccount ? (
              <AnnuityManagementDetailsComponent
                timePaymentAccount={timePaymentAccount}
                setBeginPayeeSearchFlow={setBeginPayeeSearchFlow}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    )
  })
)
