import * as React from 'react'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { getBlockClass, getModifierClass } from '../../../utilities/helpers'
import { AllPromotionsResponse } from '../../../api/promotions-fetcher/constants'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { PromotionsDateRangeReportContainer } from '../../../components/PromotionsComponents/promotions-date-range-report/promotions-date-range-report-container'
import { PromotionsHourlyReportContainer } from '../../../components/PromotionsComponents/promotions-hourly-report/promotions-hourly-report-container'
import './styles.scss'

type PromotionsReportingPageUIProps = {
  promotions?: AllPromotionsResponse
  promotionsLoading: boolean
  promotionsError?: string
}

const ROOT_CLASS = 'promotions-reporting-page-ui'
enum PROMOTIONS_REPORTING_TABS {
  DATE_RANGE = 1,
  HOURLY,
}

export const PromotionsReportingPageUI = (
  props: PromotionsReportingPageUIProps
) => {
  const [selectedTab, setSelectedTab] =
    React.useState<PROMOTIONS_REPORTING_TABS>(
      PROMOTIONS_REPORTING_TABS.DATE_RANGE
    )
  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI message="Back to Reporting" tabIndex={1} />
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Promotions Reporting
      </header>
      <AsyncContainerUI
        isLoading={props.promotionsLoading}
        error={props.promotionsError}
        color="white"
      >
        <div className={getBlockClass(ROOT_CLASS, 'tab-container')}>
          <button
            className={`${getBlockClass(ROOT_CLASS, 'tab')} ${getModifierClass(
              ROOT_CLASS,
              'tab-selected',
              selectedTab === PROMOTIONS_REPORTING_TABS.DATE_RANGE
            )}`}
            onClick={() => {
              setSelectedTab(PROMOTIONS_REPORTING_TABS.DATE_RANGE)
            }}
          >
            Date Range Report
          </button>
          <button
            className={`${getBlockClass(ROOT_CLASS, 'tab')} ${getModifierClass(
              ROOT_CLASS,
              'tab-selected',
              selectedTab === PROMOTIONS_REPORTING_TABS.HOURLY
            )}`}
            onClick={() => {
              setSelectedTab(PROMOTIONS_REPORTING_TABS.HOURLY)
            }}
          >
            Hourly Report
          </button>
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          {selectedTab === PROMOTIONS_REPORTING_TABS.DATE_RANGE ? (
            <PromotionsDateRangeReportContainer promotions={props.promotions} />
          ) : (
            <PromotionsHourlyReportContainer promotions={props.promotions} />
          )}
        </div>
      </AsyncContainerUI>
    </div>
  )
}
