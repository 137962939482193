import { useFormik } from 'formik'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { GameType } from '../../../Constants/AnnuityFlowConstants/Games'
import { PayeeSearchCmpt } from '../../../components/AnnuitiesComponents/Common/PayeeSearchCmpt'
import { CreationFlowStep1Cmpt } from '../../../components/AnnuitiesComponents/CreationExceptionFlow/CreationFlowStep1Cmpt'
import { CreationFlowStep2Cmpt } from '../../../components/AnnuitiesComponents/CreationExceptionFlow/CreationFlowStep2Cmpt'
import { annuitiesRoutePath } from '../../../config'
import { KEY_CODES } from '../../../constants.js'
import { BannerContext } from '../../../context/BannerContext'
import { keyPressedType } from '../../../services/Commons'
import styles from './css/styles.module.css'
import {
  getFirstPayment,
  getFirstPaymentClaimant,
  mapClaimantForFormik,
  onSubmit,
  submitAnnuity,
  validateFirstPayment,
  validatePayee,
  validateTPA,
} from './functions'

export const AnnuitiesCreationFlow = inject('annuitiesStore')(
  observer(({ annuitiesStore }: any) => {
    const history = useHistory()
    const FocusTrap = require('focus-trap-react')
    const bannerContext = React.useContext(BannerContext)
    const idemToken = uuidv4()
    let firstPaymentId = annuitiesStore.firstPaymentID
    // placeholder for federal/state tax
    const federalTax = '24'
    const stateTax = '5'

    const initialTimePaymentAccount = {
      gameType: '',
      gameName: '',
      payFrequency: '',
      accountStatus: '',
      drawDate: '',
      claimDate: '',
      remainingPayments: '',
      lifetimePayment: '',
      investmentAccountNumber: '',
      firstPaymentDate: '',
      nextPaymentDate: '',
      recurringPaymentAmount: '0.00',
      tpaDocumentId: '',
      finalPaymentDate: '',
      winAmount: '0.00',
      graduationRate: 0,
      federalTax: federalTax,
      stateTax: stateTax,
      supportingInformation1: '',
      supportingInformation2: '',
    }

    const initialFirstPayment = {
      firstCheckGross: '0.00',
      firstCheckIrs: '0.00',
      firstCheckDor: '0.00',
      firstCheckDbd: '0.00',
      firstCheckNet: '0.00',
      bookNumber: '',
      ticketNumber: '',
      payFileKey: '',
      checkNumber: '',
      claimCenter: '',
    }

    //set state for page flow & Payee Type to know how to populate certain fields
    const [beginPayeeSearchFlow, setBeginPayeeSearchFlow] =
      useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchedPayeeID, setSearchedPayeeID] = useState<string>('')
    const [payeeType, setPayeeType] = useState<'Individual' | 'Entity' | ''>('') //need at page level to know how to populate step 2 either for Entity or Individual
    const [confirmSubmission, setConfirmSubmission] = useState<boolean>(false)
    const [initialPaymentInfo, setInitialPaymentInfo] = useState<any>()
    const [initialClaimantInfo, setInitialClaimantInfo] = useState<any>()
    const [issueFirstPayment, setIssueFirstPayment] = useState<boolean>(false)

    // state for step 2
    const [isFederalChecked, setIsFederalChecked] = useState<boolean>(true)
    const [isStateChecked, setIsStateChecked] = useState<boolean>(true)

    // step 2 field names
    const initialPayee = {
      firstName: '',
      middleInitial: '',
      lastName: '',
      tin: '',
      freeformName: '',
      addr_1: '',
      addr_2: '',
      city: '',
      state_region: '',
      zip_code: '',
      country: '',
      phone: '',
      email: '',
      birthDate: '',
      irsName: '',
    }

    const submitNewAnnuity = async () => {
      try {
        await submitAnnuity(
          timePaymentAccountForm,
          payeeForm,
          firstPaymentForm,
          firstPaymentId,
          issueFirstPayment,
          searchedPayeeID,
          idemToken,
          setSearchedPayeeID
        )
        setConfirmSubmission(false)
        history.push(`${annuitiesRoutePath}`)
        bannerContext.setBannerInfo({
          message: 'Successfully Created Time Payment Account',
          error: false,
        })
      } catch (error) {
        setConfirmSubmission(false)
        bannerContext.setBannerInfo({ message: `${error}`, error: true })
      }
    }

    const getTouchedValuesFromObject = (
      value: Record<string, unknown> | undefined
    ) => {
      // Use this to set initialTouched when working with seeded data
      if (!value) {
        return
      }
      const touchedValues = Object.entries(value)
        .filter(([key, value]) => value !== '')
        .reduce(
          (prev, [key, value]) => Object.assign(prev, { [key]: true }),
          {}
        )
      return touchedValues
    }
    const timePaymentAccountForm = useFormik({
      initialValues: { ...initialTimePaymentAccount, ...initialPaymentInfo },
      initialTouched: getTouchedValuesFromObject(initialPaymentInfo),
      onSubmit,
      enableReinitialize: true,
      validate: validateTPA(issueFirstPayment),
    })
    const firstPaymentForm = useFormik({
      initialValues: {
        ...initialFirstPayment,
      },
      initialTouched: {
        firstCheckIrs: true,
        firstCheckDor: true,
        firstCheckDbd: true,
      },
      enableReinitialize: true,
      onSubmit,
      validate: validateFirstPayment(
        // TODO: Define a form type that sets this type explicitly
        timePaymentAccountForm.values.gameType as GameType
      ),
    })
    const payeeForm = useFormik({
      initialValues: { ...initialPayee, ...initialClaimantInfo },
      onSubmit,
      validate: validatePayee(),
      enableReinitialize: true,
    })

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (firstPaymentId) {
            const data = await getFirstPayment(firstPaymentId)
            const claimant = await getFirstPaymentClaimant(firstPaymentId)
            setInitialPaymentInfo(data)
            setInitialClaimantInfo(mapClaimantForFormik(claimant))
          }
        } catch (error) {
          bannerContext.setBannerInfo({ message: `${error}`, error: true })
        }
      }

      fetchData()

      //ComponentUnmount callback to reset First Payment ID to null so we can use the createException flow
      return () => {
        annuitiesStore.setFirstPaymentID('')
      }
    }, [])

    // triggers validation on ALL formik fields when any field has changed
    useEffect(() => {
      firstPaymentForm.validateForm()
      timePaymentAccountForm.validateForm()
      payeeForm.validateForm()
    }, [
      firstPaymentForm.values,
      timePaymentAccountForm.values,
      payeeForm.values,
      issueFirstPayment,
    ])
    // Formik doesn't provide a type for the object created by useFormik.
    const isFormValid = (form: any) => {
      return Object.values(form.errors).length === 0
    }

    const firstPageValid = () => {
      let formsValid = isFormValid(timePaymentAccountForm)
      if (!firstPaymentId && !issueFirstPayment) {
        formsValid = formsValid && isFormValid(firstPaymentForm)
      }
      return formsValid
    }

    return (
      <>
        <div className={styles['page-container']}>
          {/* Payee Search Pop Up Flow */}
          {beginPayeeSearchFlow && (
            <PayeeSearchCmpt
              isFromCreationFlow={true}
              formik={payeeForm}
              initialPayeeSearchTIN={initialClaimantInfo?.tin ?? ''}
              setBeginPayeeSearchFlow={setBeginPayeeSearchFlow}
              setCurrentPage={setCurrentPage}
              payeeType={payeeType}
              setPayeeType={setPayeeType}
              setSearchedPayeeID={setSearchedPayeeID}
            />
          )}
          {/* Header component jsx */}
          <div className={styles['cmpnt-container']}>
            <div className={styles['title-header']}>
              <div className={styles['flex-space-bw']}>
                <div className={styles['title-text']}>
                  Annuity Creation (Step {currentPage} of 2)
                </div>
                <div>
                  {currentPage === 1 ? (
                    <button
                      type="button"
                      className={
                        firstPageValid()
                          ? `${styles.light_btn}`
                          : `${styles.light_btn_disabled}`
                      }
                      disabled={!firstPageValid()}
                      onKeyPress={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          setBeginPayeeSearchFlow(true)
                        }
                      }}
                      onClick={() => setBeginPayeeSearchFlow(true)}
                    >
                      Payee Info
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className={`${styles.dark_btn}`}
                        onKeyPress={(event) => {
                          if (
                            keyPressedType(event) === KEY_CODES.ENTER ||
                            keyPressedType(event) === KEY_CODES.SPACE
                          ) {
                            setCurrentPage(1)
                          }
                        }}
                        onClick={() => {
                          setCurrentPage(1)
                        }}
                      >
                        Back to Annuity Info
                      </button>
                      <button
                        type="button"
                        className={
                          Object.entries(payeeForm.errors).length > 0
                            ? `${styles.light_btn_disabled}`
                            : `${styles.light_btn}`
                        }
                        disabled={Boolean(
                          Object.entries(payeeForm.errors).length
                        )}
                        onKeyPress={(event) => {
                          if (
                            keyPressedType(event) === KEY_CODES.ENTER ||
                            keyPressedType(event) === KEY_CODES.SPACE
                          ) {
                            setConfirmSubmission(!confirmSubmission)
                          }
                        }}
                        onClick={() => {
                          setConfirmSubmission(!confirmSubmission)
                        }}
                      >
                        Review &amp; Submit
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Main components */}
          {currentPage === 1 ? (
            <div className={styles['addtnl-cmpt-container']}>
              <CreationFlowStep1Cmpt
                timePaymentAccountForm={timePaymentAccountForm}
                firstPaymentForm={firstPaymentForm}
                issueFirstPayment={issueFirstPayment}
                setIssueFirstPayment={setIssueFirstPayment}
              />
            </div>
          ) : (
            <div className={styles['addtnl-cmpt-container']}>
              <CreationFlowStep2Cmpt
                payeeForm={payeeForm}
                timePaymentAccountForm={timePaymentAccountForm}
                payeeType={payeeType}
                isFederalChecked={isFederalChecked}
                isStateChecked={isStateChecked}
                searchedPayeeID={searchedPayeeID}
                setIsFederalChecked={setIsFederalChecked}
                setIsStateChecked={setIsStateChecked}
              />
            </div>
          )}
          {/* Confirm Submission Pop Up */}
          {confirmSubmission && (
            <FocusTrap focusTrapOptions={{ initialFocus: false }}>
              <div className={styles['overlay-container']}>
                <div className={styles['review_submit_modal']}>
                  <div className={styles['review_submit_title']}>
                    Review &amp; Submit New Annuity
                  </div>
                  <div className={styles['grid_col_2']}>
                    <div className={styles['review_submit_div']}>
                      <div className={styles['review_submit_div_title']}>
                        Annuity Info
                      </div>
                      <div
                        className={`${styles.grid_col_2} ${styles.review_submit_div_bold}`}
                      >
                        <div>Game Type</div>
                        <div className="text-uppercase">
                          {timePaymentAccountForm.values.gameType}
                        </div>
                        <div>Game Name</div>
                        <div className="text-uppercase">
                          {timePaymentAccountForm.values.gameName}
                        </div>
                        <div>Pay Frequency</div>
                        <div className="text-uppercase">
                          {timePaymentAccountForm.values.payFrequency}
                        </div>
                        <div>Draw Date</div>
                        <div>
                          {['Instant'].includes(
                            timePaymentAccountForm.values.gameType
                          )
                            ? 'N/A'
                            : moment(
                                timePaymentAccountForm.values.drawDate
                              ).format('MM/DD/YYYY')}
                        </div>
                        <div>Claim Date</div>
                        <div>
                          {moment(
                            timePaymentAccountForm.values.claimDate
                          ).format('MM/DD/YYYY')}
                        </div>
                        <div>Remaining Payments</div>
                        <div className="text-uppercase">
                          {timePaymentAccountForm.values.remainingPayments}
                        </div>
                        <div>Lifetime Payment</div>
                        <div className="text-uppercase">
                          {timePaymentAccountForm.values.lifetimePayment}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles['review_submit_div_title']}>
                        Tax Designation
                      </div>
                      <div
                        className={`${styles.grid_col_2} ${styles.review_submit_div_bold}`}
                      >
                        <div>Federal</div>
                        <div>{timePaymentAccountForm.values.federalTax}%</div>
                        <div>State</div>
                        <div>{timePaymentAccountForm.values.stateTax}%</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles['review_submit_div']}>
                    <div className={styles['review_submit_div_title']}>
                      Payee Info
                    </div>
                    <div className={` ${styles.grid_col_2}`}>
                      <div
                        className={`${styles.grid_col_2} ${styles.review_submit_div_bold}`}
                      >
                        {payeeType === 'Individual' ? (
                          <>
                            <div>First Name</div>
                            <div className="text-uppercase">
                              {payeeForm.values.firstName}
                            </div>
                            <div>Middle Initial</div>
                            <div className="text-uppercase">
                              {payeeForm.values.middleInitial}
                            </div>
                            <div>Last Name</div>
                            <div className="text-uppercase">
                              {payeeForm.values.lastName}
                            </div>
                          </>
                        ) : (
                          <>
                            <div>Freeform Name</div>
                            <div className="text-uppercase">
                              {payeeForm.values.freeformName}
                            </div>
                          </>
                        )}
                        <div>IRS Name</div>
                        <div className="text-uppercase">{`${payeeForm.values.irsName}`}</div>
                        <div>Email</div>
                        <div className="text-uppercase">
                          {payeeForm.values.email}
                        </div>
                        <div>Phone</div>
                        <div>{payeeForm.values.phone}</div>
                        <div>TIN</div>
                        <div>{payeeForm.values.tin}</div>
                      </div>
                      <div
                        className={`${styles.grid_col_2} ${styles.review_submit_div_bold} ${styles.review_submit_2_col_margin}`}
                      >
                        <div>Mailing Address 1</div>
                        <div className="text-uppercase">
                          {payeeForm.values.addr_1}
                        </div>
                        <div>Mailing Address 2</div>
                        <div className="text-uppercase">
                          {payeeForm.values.addr_2}
                        </div>
                        <div>City</div>
                        <div className="text-uppercase">
                          {payeeForm.values.city}
                        </div>
                        <div>State/Region</div>
                        <div className="text-uppercase">
                          {payeeForm.values.state_region}
                        </div>
                        <div>Zip/Postal Code</div>
                        <div>{payeeForm.values.zip_code}</div>
                        <div>Country</div>
                        <div className="text-uppercase">
                          {payeeForm.values.country}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onKeyPress={async (event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        await submitNewAnnuity()
                      }
                    }}
                    onClick={async () => {
                      await submitNewAnnuity()
                    }}
                    className={`${styles.modal_confirm_btn} ${styles.review_submit_confirm_btn}`}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onKeyPress={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        setConfirmSubmission(!confirmSubmission)
                      }
                    }}
                    onClick={() => {
                      setConfirmSubmission(!confirmSubmission)
                    }}
                    className={`${styles.modal_cancel_btn} ${styles.review_submit_cancel_btn}`}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </FocusTrap>
          )}
        </div>
      </>
    )
  })
)
