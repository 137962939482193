import React, { ChangeEvent } from "react"
import { FormPayeeDetails } from "../../../../../pages/PaymentsPages/Create5754SplitPage/types"
import './styles.scss'
import { Countries } from "../../../../../Constants/Countries"
import { Create5754SplitGrossPaymentUI } from "./gross-payment/gross-payment-ui"
import { FormikProps } from "formik"
import { PayeeType } from "../../../../../api/payment-details-fetcher/api-types"
import { SelectInputUI, SelectOption, TextFieldType, TextInputUI } from "../../../../CommonComponents/FormikInputComponent/input-ui"

export type Create5754SplitChildPaymentFormUIProps = {
  childPayment: FormPayeeDetails
  childIndex: number
  totalInDollars: string
  formikProps: FormikProps<any>
  showGrossPaymentError: boolean 
  setGrossPaymentIndex: (s: number) => void
  setSubmitButtonEnabled: (s: boolean) => void
  onChange: (e: ChangeEvent) => void
}

const ROOT_CLASS = 'create-5754-split-payee-form'
export const Create5754SplitChildPaymentFieldUI = (props: Create5754SplitChildPaymentFormUIProps) => {
  const { 
    childPayment, 
    childIndex, 
    totalInDollars, 
    formikProps, 
    showGrossPaymentError,
    setSubmitButtonEnabled,
    setGrossPaymentIndex,
    onChange 
  } = props
  const { payeeType } = childPayment

  const countryOptions: SelectOption[] = Countries.map((country: string) => {
    return {
      label: country,
      value: country
    }
  })

  return (
    <div className={ ROOT_CLASS }>
      {/* Name Fields */}
      {payeeType === PayeeType.ENTITY ?
        <TextInputUI
          type={ TextFieldType.TEXT }
          label={ 'Freeform Name *' }
          name={ `splits.${childIndex}.payeeFreeformName` }
        /> : <>
          <TextInputUI
            type={ TextFieldType.TEXT }
            label={ 'First Name *' }
            name={ `splits.${childIndex}.payeeFirstName` }
          />
          <TextInputUI
            type={ TextFieldType.TEXT } 
            label={ 'Middle Initial' }
            name={ `splits.${childIndex}.payeeMiddleInitial` }
          />
          <TextInputUI
            type={ TextFieldType.TEXT } 
            label={ 'Last Name *' }
            name={ `splits.${childIndex}.payeeLastName` }
          />
        </>
      }

      {/* Remaining Payee Details */}
      <TextInputUI
        type={ TextFieldType.TEXT } 
        label={ 'TIN *' } 
        name={ `splits.${childIndex}.tin` }
      />
      <TextInputUI 
        type={ TextFieldType.TEXT } 
        label={ 'Mailing Address 1 *' } 
        name={ `splits.${childIndex}.address1` }
      />
      <TextInputUI 
        type={ TextFieldType.TEXT } 
        label={ 'Mailing Address 2' } 
        name={ `splits.${childIndex}.address2` }
      />
      <TextInputUI 
        type={ TextFieldType.TEXT } 
        label={ 'City *' } 
        name={ `splits.${childIndex}.city` }
      />
      <TextInputUI 
        type={ TextFieldType.TEXT } 
        label={ 'State/Region *' }
        name={ `splits.${childIndex}.state` }
      />
      <TextInputUI 
        type={ TextFieldType.TEXT } 
        label={ 'Zip/Postal Code *' }
        name={ `splits.${childIndex}.zip` }
      />
      <SelectInputUI
        label={ 'Country' }
        name={ `splits.${childIndex}.country` }
        options={ countryOptions }
      />
      <TextInputUI 
        type={ TextFieldType.TEL } 
        label={ 'Phone Number' }
        name={ `splits.${childIndex}.phone` }
      />
      <TextInputUI 
        type={ TextFieldType.EMAIL } 
        label={ 'Email' } 
        name={ `splits.${childIndex}.email` }
      />

      {/* Gross Payment Field */}
      <div
        tabIndex={ 0 }
        className={ 'title-2' }
      >
        Payment
      </div>
      <Create5754SplitGrossPaymentUI  
        label={ 'Amount *' } 
        name={ `splits.${childIndex}.grossPaymentInDollars` }
        totalInDollars={ totalInDollars }
        formikProps={ formikProps }
        childIndex={ childIndex }
        showGrossPaymentError={ showGrossPaymentError }
        setGrossPaymentIndex={ setGrossPaymentIndex }
        setSubmitButtonEnabled={ setSubmitButtonEnabled }
        onChange={ onChange }
      />
    </div>
  )
}