import {UpdateLicenseSuccessMessage} from "../Common/UpdateLicenseSuccessMessage";
import {InputValidationMessages} from "../../../../Constants/InputValidationMessages.js";
import {licenseeRoutePath} from "../../../../config.js";

let licenseNumber: string;
let formik: any;
let history: any;
let charitableGamingStore: any;

export const setVariables = (licenseNumberObj: string, formikObj: any, historyObj: any,
                             charitableGamingStoreObj: any) => {
    licenseNumber = licenseNumberObj;
    formik = formikObj;
    history = historyObj;
    charitableGamingStore = charitableGamingStoreObj;
}

export const setFieldValue = (e: any) => {

    const onlyNumbers = /^[0-9\b]+$/;

    if (e.target.name === 'orgDetails.headOfOrg.address.zip') {
        if (e.target.value.trim() === "" || onlyNumbers.test(e.target.value.trim())) {
            formik.setFieldValue(e.target.name, e.target.value.trim(), true);
        }
    } else {
        formik.setFieldValue(e.target.name, e.target.value, true);
    }
}

export const validate = (values: any) => {

    const errors = {};

    if (!values.orgDetails.headOfOrg.fullName) {
        Object.assign(errors, {orgDetails: {headOfOrg: {fullName: InputValidationMessages.RequiredField}}});
    }

    if (!values.orgDetails.headOfOrg.homePhone) {
        Object.assign(errors, {orgDetails: {headOfOrg: {homePhone: InputValidationMessages.RequiredField}}});
    } else if (values.orgDetails.headOfOrg.homePhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.orgDetails.headOfOrg.homePhone)) {
        Object.assign(errors, {orgDetails: {headOfOrg: {homePhone: InputValidationMessages.InvalidTelephone}}});
    }

    if (!values.orgDetails.headOfOrg.address.streetAddress) {
        Object.assign(errors, {orgDetails: {headOfOrg: {address: {streetAddress: InputValidationMessages.RequiredField}}}});
    }

    if (!values.orgDetails.headOfOrg.businessPhone) {
        Object.assign(errors, {orgDetails: {headOfOrg: {businessPhone: InputValidationMessages.RequiredField}}});
    } else if (values.orgDetails.headOfOrg.businessPhone && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.orgDetails.headOfOrg.businessPhone)) {
        Object.assign(errors, {orgDetails: {headOfOrg: {businessPhone: InputValidationMessages.InvalidTelephone}}});
    }

    if (!values.orgDetails.headOfOrg.address.city) {
        Object.assign(errors, {orgDetails: {headOfOrg: {address: {city: InputValidationMessages.RequiredField}}}});
    }

    if (!values.orgDetails.headOfOrg.address.zip) {
        Object.assign(errors, {orgDetails: {headOfOrg: {address: {zip: InputValidationMessages.RequiredField}}}});
    } else if (values.orgDetails.headOfOrg.address.zip && values.orgDetails.headOfOrg.address.zip.length !== 5) {
        Object.assign(errors, {orgDetails: {headOfOrg: {address: {zip: InputValidationMessages.InvalidZip}}}});
    }

    if (!values.orgDetails.headOfOrg.email) {
        Object.assign(errors, {orgDetails: {headOfOrg: {email: InputValidationMessages.RequiredField}}});
    } else if (values.orgDetails.headOfOrg.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.orgDetails.headOfOrg.email)) {
        Object.assign(errors, {orgDetails: {headOfOrg: {email: InputValidationMessages.InvalidEmail}}});
    }

    return errors;
}

export const onSubmit = async (values: any) => {
    charitableGamingStore.updateLicense(values, UpdateLicenseSuccessMessage.HEAD_OF_ORG, history);
}

export const YesSave = function () {
    formik.submitForm();
}

export const YesCancel = function () {

    // history.goBack();

    history.push({
        pathname: `${licenseeRoutePath}/view/${licenseNumber}`,
        state: {tabName: 'licenseDetails'} // This is so the 'License Details' Tab is selected when you go back
    });
}



