import * as React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  PromotionResponse,
  PromotionStatusColors,
  PromotionType,
} from '../../../api/promotions-fetcher/constants'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
} from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import { PromotionDrawingGroupTooltipUI } from '../../../components/PromotionsComponents/promotion-drawing-tooltip/promotion-drawing-tooltip-ui'
import { winnersManagementRoutePath } from '../../../config.js'

export type WinnersManagementTableRow = {
  promotionName: JSX.Element
  promotionType: string
  promotionId: string
  promotionStatus: string
  promotionDrawings: JSX.Element
  promotionStartDate: string
}
export const winnersManagementTableColumnMapping: GenericTableColumnMap<WinnersManagementTableRow> =
  [
    {
      key: 'promotionName',
      columnText: 'Name',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'promotionType',
      columnText: 'Type',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'promotionId',
      columnText: 'Promotion ID',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'promotionStatus',
      columnText: 'Status',
      columnType: GenericTableColumnType.STATUS,
      statusMap: PromotionStatusColors,
    },
    {
      key: 'promotionDrawings',
      columnText: 'Drawings',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'promotionStartDate',
      columnText: 'Promotion Start Date',
      columnType: GenericTableColumnType.DEFAULT,
      textAlign: 'right',
    },
  ]

export const mapPromotionToRow = (
  promotion: PromotionResponse
): WinnersManagementTableRow => {
  return {
    promotionName: (
      <Link to={`${winnersManagementRoutePath}/${promotion.id}`}>
        {promotion.name}
      </Link>
    ),
    promotionType: PromotionType[promotion.type],
    promotionId: promotion.id,
    promotionStatus: promotion.status,
    promotionDrawings: (
      <PromotionDrawingGroupTooltipUI
        drawingGroup={
          promotion.drawingGroups.length > 0
            ? promotion.drawingGroups[0]
            : undefined
        }
        bonusDrawingGroup={
          promotion.drawingGroups.length > 1
            ? promotion.drawingGroups[1]
            : undefined
        }
        promotionStatus={promotion.status}
      />
    ),
    promotionStartDate: moment(promotion.startDate).format('MM/DD/YYYY'),
  }
}
