import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { CircularProgress } from "@material-ui/core";
import { keyPressedType } from "../../../../services/Commons";
import { KEY_CODES } from "../../../../constants.js";
import { ExportToCsv } from "export-to-csv";
import { fetchLastThroughDate, getPaymentList, exportToCsv, validate } from "./functions";
import styles from "./css/styles.module.css";
import { BannerContext } from "../../../../context/BannerContext";
import { PaymentTableCmpt } from "../Common/PaymentTableCmpt";
import { PaymentResponse } from "../../../../types/Annuities/TimePaymentAuthorization/PaymentResponse";
import { ErrorMessageFieldCommonComponent } from "../../../CommonComponents/ErrorMessageFieldCommonComponent";

export const PaymentSearchCmpt: React.FC = () => {
  const bannerContext = useContext(BannerContext);

  // state variables
  const [paymentData, setPaymentData] = useState<PaymentResponse[]>([]);
  const [requestCompleted, setRequestCompleted] = useState<boolean>(true);
  const [lastThroughDate, setLastThroughDate] = useState<string>("Loading...");

  const startPaymentSearch = async () => {
    try {
      setRequestCompleted(false);
      const response = await getPaymentList(formik.values.fromDate, formik.values.throughDate);
      setPaymentData(response);
      setRequestCompleted(true);
    } catch (error) {
      setRequestCompleted(true);
      bannerContext.setBannerInfo({ message: `${error}`, error: true });
    }
  };

  // CSV Options
  const csvOptions = {
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: `TimePaymentAuthorizationExport`,
    showTitle: false,
    headers: ["Document ID", "Name", "TIN", "Cadence", "Anniversary Date", "Payment Date", "Gross Payment", "Federal Tax", "State Tax", "Net Payment"],
  };

  const csvExporter: ExportToCsv = new ExportToCsv(csvOptions);

  // formik
  const formik = useFormik({
    initialValues: { fromDate: "", throughDate: "" },
    enableReinitialize: true,
    onSubmit: values => {},
    validate,
  });

  // useEffect to grab last through date
  useEffect(() => {
    const getLastThroughDate = async () => {
      try {
        const lastThroughDate = await fetchLastThroughDate();
        setLastThroughDate(lastThroughDate);
      } catch (error) {
        setLastThroughDate("Error");
        bannerContext.setBannerInfo({ message: `${error}`, error: true });
      }
    };
    getLastThroughDate();
  }, []);

  return (
    <>
      {!requestCompleted && (
        <div
          style={{
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.3)",
            color: "white",
          }}
        >
          <CircularProgress
            size="4em"
            color="inherit"
            thickness={2}
          />
        </div>
      )}
      <main className={`${styles.payment_search_cmpt} w-100 pr-2 rounded`}>
        {/* Search Input Fields */}
        <div className={`${styles.payment_search} pt-2 pl-2`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="fromDate">Select From Date</label>
                  <input
                    type="date"
                    max="9999-12-31"
                    className={`${styles.custom_input}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="fromDate"
                    id="fromDate"
                    value={formik.values.fromDate}
                  />
                </div>
                {formik.errors.fromDate && (
                  <div className={`${styles.info_detail}`}>
                    <ErrorMessageFieldCommonComponent errorMessage={formik.errors.fromDate} />
                  </div>
                )}
              </div>
              <div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="throughDate">Select Through Date</label>
                  <input
                    type="date"
                    max="9999-12-31"
                    className={`${styles.custom_input}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="throughDate"
                    id="throughDate"
                    value={formik.values.throughDate}
                  />
                </div>
                {formik.errors.throughDate && (
                  <div className={`${styles.info_detail}`}>
                    <ErrorMessageFieldCommonComponent errorMessage={formik.errors.throughDate} />
                  </div>
                )}
              </div>
              {/* Payment Search Button */}
              <button
                type="button"
                className={formik.values.fromDate === "" || formik.errors.throughDate ? `${styles.search_btn_disabled}` : `${styles.search_btn}`}
                disabled={Object.keys(formik.errors).length > 0}
                onKeyPress={async event => {
                  if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                    await startPaymentSearch();
                  }
                }}
                onClick={async () => {
                  await startPaymentSearch();
                }}
              >
                Search
              </button>
              {/* Clear Search Button */}
              <button
                type="button"
                className={`${styles.dark_btn}`}
                onKeyPress={event => {
                  if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                    formik.resetForm();
                    setPaymentData([]);
                  }
                }}
                onClick={() => {
                  formik.resetForm();
                  setPaymentData([]);
                }}
              >
                Clear Search
              </button>
              {/* Export CSV */}
              <button
                type="button"
                className={paymentData.length > 0 ? `${styles.dark_btn}` : `${styles.dark_btn_disabled}`}
                disabled={paymentData.length > 0 ? false : true}
                onKeyPress={event => {
                  if (keyPressedType(event) === KEY_CODES.ENTER || keyPressedType(event) === KEY_CODES.SPACE) {
                    exportToCsv(paymentData, csvExporter);
                  }
                }}
                onClick={() => {
                  exportToCsv(paymentData, csvExporter);
                }}
              >
                Export
              </button>
            </div>
            <div className={`${styles.throughDate_text}`}>
              <p>
                <em>Last Through Date Run: {lastThroughDate}</em>
              </p>
            </div>
          </div>
        </div>
        {/* Payment Table */}
        <div>
          <PaymentTableCmpt paymentListData={paymentData} fromPage={"PaymentSearch"}/>
        </div>
      </main>
    </>
  );
};
