import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { FaRegFileExcel } from 'react-icons/fa'
import { GrDocumentPdf } from 'react-icons/gr'
import { GrDocumentCsv } from 'react-icons/gr'

import styles from './styles.module.css'

type ExportDropdownProps = {
  disabled: boolean
  exportPDF?: () => Promise<void>
  exportXLSX?: () => Promise<void>
  exportCSV?: () => Promise<void>
}

const getIcon = (key: string) => {
  switch (key) {
    case 'exportPDF':
      return <GrDocumentPdf className="mr-2" />
    case 'exportXLSX':
      return <FaRegFileExcel className="mr-2" />
    case 'exportCSV':
      return <GrDocumentCsv className="mr-2" />
  }
}

const getExtension = (key: string) => {
  switch (key) {
    case 'exportPDF':
      return 'PDF'
    case 'exportXLSX':
      return 'XLSX'
    case 'exportCSV':
      return 'CSV'
  }
}

export const ExportDropdown = (props: ExportDropdownProps) => {
  const { disabled, ...handlers } = props
  return (
    <Dropdown>
      <Dropdown.Toggle className={styles.dropdown_toggle} disabled={disabled}>
        Export As
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        {Object.entries(handlers).map(([key, method]) => {
          return (
            <Dropdown.Item
              onClick={method}
              className={styles.dropdown_item}
              key={key}
            >
              <div className="d-flex align-items-center">
                {getIcon(key)}
                {getExtension(key)}
              </div>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
