import moment from 'moment'
import React, { useEffect } from 'react'
import InputMask from 'react-input-mask'
import { Countries } from '../../../../Constants/Countries'
import { KEY_CODES } from '../../../../constants.js'
import { keyPressedType } from '../../../../services/Commons'
import { CheckboxComponent } from '../../../CommonComponents/CheckboxComponent'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import styles from './css/styles.module.css'

export const CreationFlowStep2Cmpt: React.FC<{
  payeeForm: any
  timePaymentAccountForm: any
  payeeType?: 'Individual' | 'Entity' | ''
  isFederalChecked: boolean
  isStateChecked: boolean
  searchedPayeeID: string
  setIsFederalChecked: React.Dispatch<React.SetStateAction<boolean>>
  setIsStateChecked: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  payeeForm,
  timePaymentAccountForm,
  payeeType,
  isFederalChecked,
  isStateChecked,
  searchedPayeeID,
  setIsFederalChecked,
  setIsStateChecked,
}) => {
  // initial federalTax and StateTax - should eventually come from global scope
  const initialFederalTax = '24'
  const initialStateTax = '5'

  useEffect(() => {
    payeeForm.setFieldValue('payeeType', payeeType)
  }, [payeeType])

  useEffect(() => {
    if (payeeForm.values.country === '') {
      // Auto select United States of America if creating a new Payee.
      payeeForm.setFieldValue('country', 'UNITED STATES OF AMERICA')
    }
  }, [])

  return (
    <>
      <div className={`${styles.main_page_container}`}>
        <div className={`${styles.grid_col_2}`}>
          {/* Column 1 */}
          <div className={`${styles.cmpnt_container} py-3 px-3 rounded`}>
            <h4>Payee Info</h4>
            <p>Review and enter the information below</p>
            <div className={`${styles.grid_col_2}`}>
              {payeeType === 'Individual' ? (
                <>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      className={`${styles.custom_input} text-uppercase`}
                      onChange={payeeForm.handleChange}
                      onBlur={payeeForm.handleBlur}
                      name="firstName"
                      id="firstName"
                      value={payeeForm.values.firstName}
                      disabled={
                        searchedPayeeID !== null && searchedPayeeID !== ''
                      }
                    />
                    {payeeForm.errors.firstName &&
                      payeeForm.touched.firstName && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={payeeForm.errors.firstName}
                        />
                      )}
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="middleInitial">Middle Initial</label>
                    <InputMask
                      mask="a"
                      maskChar=""
                      className={`${styles.custom_input} text-uppercase`}
                      onChange={payeeForm.handleChange}
                      onBlur={payeeForm.handleBlur}
                      name="middleInitial"
                      id="middleInitial"
                      value={payeeForm.values.middleInitial}
                      disabled={
                        searchedPayeeID !== null && searchedPayeeID !== ''
                      }
                    ></InputMask>
                  </div>
                  <div className={`${styles.info_detail}`}>
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      type="text"
                      className={`${styles.custom_input} text-uppercase`}
                      onChange={payeeForm.handleChange}
                      onBlur={payeeForm.handleBlur}
                      name="lastName"
                      id="lastName"
                      value={payeeForm.values.lastName}
                      disabled={
                        searchedPayeeID !== null && searchedPayeeID !== ''
                      }
                    />
                    {payeeForm.errors.lastName &&
                      payeeForm.touched.lastName && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={payeeForm.errors.lastName}
                        />
                      )}
                  </div>
                </>
              ) : (
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="freeformName">Freeform Name*</label>
                  <input
                    type="text"
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={payeeForm.handleChange}
                    onBlur={payeeForm.handleBlur}
                    name="freeformName"
                    id="freeformName"
                    value={payeeForm.values.freeformName}
                    disabled={
                      searchedPayeeID !== null && searchedPayeeID !== ''
                    }
                  />
                  {payeeForm.errors.freeformName &&
                    payeeForm.touched.freeformName && (
                      <ErrorMessageFieldCommonComponent
                        errorMessage={payeeForm.errors.freeformName}
                      />
                    )}
                </div>
              )}
              <div className={`${styles.info_detail}`}>
                <label htmlFor="addr_1">Mailing Address 1*</label>
                <input
                  type="text"
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="addr_1"
                  id="addr_1"
                  value={payeeForm.values.addr_1}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                />
                {payeeForm.errors.addr_1 && payeeForm.touched.addr_1 && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.addr_1}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="tin">TIN*</label>
                <InputMask
                  mask="999999999"
                  maskChar=""
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  id="tin"
                  name="tin"
                  placeholder={'123456789'}
                  value={payeeForm.values.tin}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                ></InputMask>
                {payeeForm.errors.tin && payeeForm.touched.tin && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.tin}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="addr_2">Mailing Address 2</label>
                <input
                  type="text"
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="addr_2"
                  id="addr_2"
                  value={payeeForm.values.addr_2}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                />
                {payeeForm.errors.addr_2 && payeeForm.touched.addr_2 && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.addr_2}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="irsName">IRS Name*</label>
                <InputMask
                  mask="aaaa"
                  maskChar=""
                  className={`${styles.custom_input} mr-2 text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="irsName"
                  id="irsName"
                  value={payeeForm.values.irsName}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                ></InputMask>
                {payeeForm.errors.irsName && payeeForm.touched.irsName && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.irsName}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="email"
                  id="email"
                  value={payeeForm.values.email}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                />
                {payeeForm.errors.email && payeeForm.touched.email && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.email}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="city"
                  id="city"
                  value={payeeForm.values.city}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                />
                {payeeForm.errors.city && payeeForm.touched.city && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.city}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="phone">Phone</label>
                <InputMask
                  mask="999-999-9999"
                  maskChar=""
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  id="phone"
                  name="phone"
                  placeholder={'888-555-6789'}
                  value={payeeForm.values.phone}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                ></InputMask>
                {payeeForm.errors.phone && payeeForm.touched.phone && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.phone}
                  />
                )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="state_region">State/Region*</label>
                <InputMask
                  mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
                  maskChar=""
                  className={`${styles.custom_input} text-uppercase`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="state_region"
                  id="state_region"
                  value={payeeForm.values.state_region}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                ></InputMask>
                {payeeForm.errors.state_region &&
                  payeeForm.touched.state_region && (
                    <ErrorMessageFieldCommonComponent
                      errorMessage={payeeForm.errors.state_region}
                    />
                  )}
              </div>
              <div className={`${styles.info_detail}`}>
                <label htmlFor="country">Country*</label>
                <div className={`${styles.custom_select_div}`}>
                  <select
                    onChange={payeeForm.handleChange}
                    onBlur={payeeForm.handleBlur}
                    className={`${styles.custom_select}`}
                    name="country"
                    id="country"
                    disabled={
                      searchedPayeeID !== null && searchedPayeeID !== ''
                    }
                  >
                    <option value="default" disabled>
                      Select Country...
                    </option>
                    {Countries.map((country: string, index: number) => (
                      <option
                        value={country}
                        key={index}
                        selected={
                          country === payeeForm.values.country ? true : false
                        }
                      >{`${country}`}</option>
                    ))}
                  </select>
                  <span className={`${styles.custom_arrow}`}></span>
                </div>
                {payeeForm.errors.country && payeeForm.touched.country && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.country}
                  />
                )}
              </div>
              {payeeType === 'Individual' && (
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="birthDate">Birth Date</label>
                  <input
                    type="date"
                    max={moment().format('YYYY-MM-DD')}
                    className={`${styles.custom_input} text-uppercase`}
                    onChange={payeeForm.handleChange}
                    onBlur={payeeForm.handleBlur}
                    name="birthDate"
                    id="birthDate"
                    value={payeeForm.values.birthDate}
                    disabled={
                      searchedPayeeID !== null && searchedPayeeID !== ''
                    }
                  />
                  {payeeForm.errors.birthDate &&
                    payeeForm.touched.birthDate && (
                      <ErrorMessageFieldCommonComponent
                        errorMessage={payeeForm.errors.birthDate}
                      />
                    )}
                </div>
              )}
              <div className={`${styles.info_detail}`}>
                <label htmlFor="zip_code">Zip/Postal Code*</label>
                <InputMask
                  mask="99999"
                  maskChar=""
                  className={`${styles.custom_input} ${styles.custom_zip_code}`}
                  onChange={payeeForm.handleChange}
                  onBlur={payeeForm.handleBlur}
                  name="zip_code"
                  id="zip_code"
                  value={payeeForm.values.zip_code}
                  disabled={searchedPayeeID !== null && searchedPayeeID !== ''}
                ></InputMask>
                {payeeForm.errors.zip_code && payeeForm.touched.zip_code && (
                  <ErrorMessageFieldCommonComponent
                    errorMessage={payeeForm.errors.zip_code}
                  />
                )}
              </div>
            </div>
          </div>
          {/* Column 2 */}
          <form>
            <div
              className={`${styles.tax_info_container} ml-2 py-3 px-3 rounded`}
            >
              <h4>Tax Designation</h4>
              <p>
                Setting these tax designations relates only to this annuity
                account.
              </p>
              <div className={`${styles.flex_row}`}>
                {/* Checkboxes */}
                <div>
                  <div
                    className={`${styles.checkbox} mr-3 pl-3 pt-2 pb-2`}
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        if (!isFederalChecked) {
                          setIsFederalChecked(true)
                          timePaymentAccountForm.values.federalTax =
                            initialFederalTax
                        } else {
                          setIsFederalChecked(false)
                          timePaymentAccountForm.values.federalTax = '0'
                        }
                      }
                    }}
                    onClick={() => {
                      if (!isFederalChecked) {
                        setIsFederalChecked(true)
                        timePaymentAccountForm.values.federalTax =
                          initialFederalTax
                      } else {
                        setIsFederalChecked(false)
                        timePaymentAccountForm.values.federalTax = '0'
                      }
                    }}
                  >
                    <div className={`${styles.flex_row}`}>
                      <p>Federal {initialFederalTax}% </p>
                      <CheckboxComponent isChecked={isFederalChecked} />
                    </div>
                  </div>
                  <div
                    className={`${styles.checkbox} pl-3 pt-2 pb-2`}
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        if (!isStateChecked) {
                          setIsStateChecked(true)
                          timePaymentAccountForm.values.stateTax =
                            initialStateTax
                        } else {
                          setIsStateChecked(false)
                          timePaymentAccountForm.values.stateTax = '0'
                        }
                      }
                    }}
                    onClick={() => {
                      if (!isStateChecked) {
                        setIsStateChecked(true)
                        timePaymentAccountForm.values.stateTax = initialStateTax
                      } else {
                        setIsStateChecked(false)
                        timePaymentAccountForm.values.stateTax = '0'
                      }
                    }}
                  >
                    <div className={`${styles.flex_row}`}>
                      <p>State {initialStateTax}% </p>
                      <CheckboxComponent isChecked={isStateChecked} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.supporting_doc_container} mt-3 ml-2 py-3 px-2 rounded`}
            >
              <h4>Supporting Address Information</h4>
              <p>
                This is a continuation of the payee address, please add any
                relevant address line information. i.e, 'Re: FBO Johnson', or
                'C/O Bank of New York Mellon’
              </p>
              <div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="supportingInformation1">Line 1</label>
                  <input
                    type="text"
                    className={`${styles.supporting_information} text-uppercase`}
                    onChange={timePaymentAccountForm.handleChange}
                    onBlur={timePaymentAccountForm.handleBlur}
                    name="supportingInformation1"
                    id="supportingInformation1"
                    value={timePaymentAccountForm.values.supportingInformation1}
                  />
                  {timePaymentAccountForm.errors.supportingInformation1 &&
                    timePaymentAccountForm.touched.supportingInformation1 && (
                      <ErrorMessageFieldCommonComponent
                        errorMessage={
                          timePaymentAccountForm.errors.supportingInformation1
                        }
                      />
                    )}
                </div>
                <div></div>
                <div className={`${styles.info_detail}`}>
                  <label htmlFor="supportingInformation2">Line 2</label>
                  <input
                    type="text"
                    className={`${styles.supporting_information} text-uppercase`}
                    onChange={timePaymentAccountForm.handleChange}
                    onBlur={timePaymentAccountForm.handleBlur}
                    name="supportingInformation2"
                    id="supportingInformation2"
                    value={timePaymentAccountForm.values.supportingInformation2}
                  />
                  {timePaymentAccountForm.errors.supportingInformation2 &&
                    timePaymentAccountForm.touched.supportingInformation2 && (
                      <ErrorMessageFieldCommonComponent
                        errorMessage={
                          timePaymentAccountForm.errors.supportingInformation2
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
