import React from 'react'
import { useFetch } from '../../../hooks/use-fetch'
import config from '../../../config'
import { GetPaymentDetailsResponse } from '../../../api/payment-details-fetcher/api-types'
const PAYMENT_DETAILS_BASE_URL = `${config.SERVER_BASE_URL}/api/v1/payments`

export const useFetchPaymentDetails = (internalPaymentId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<GetPaymentDetailsResponse>()

  const fetchPaymentDetails = () => {
    const urlWithId = `${PAYMENT_DETAILS_BASE_URL}/${internalPaymentId}`
    return fetchUrl(urlWithId, 'GET', {})
  }

  React.useEffect(() => {
    fetchPaymentDetails()
    return () => {}
  }, [])

  return {
    isLoading,
    error,
    data,
    fetchPaymentDetails,
  }
}

export const useFetchVoidPayment = () => {
  const { isLoading, error, fetchUrl, requestSuccessful } = useFetch()

  const fetchVoidPayment = async (internalPaymentId: string) => {
    const url = `${PAYMENT_DETAILS_BASE_URL}/void`
    const reqBody = {
      paymentId: internalPaymentId,
    }
    return fetchUrl(url, 'POST', {}, JSON.stringify(reqBody))
  }

  return {
    fetchVoidPayment,
    requestSuccessful,
    isLoading,
    error,
  }
}
