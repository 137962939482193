import config from '../../../config'
import { useFetch } from '../../../hooks/use-fetch'
import { permissions } from '../../../constants'

const generateSplitReportUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/5754Split/generate`

export const getFetchGenerate5754SplitReport = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<void>()
  const generate5754SplitReport = async (year: string, generatedBy: string) => {
    await fetchUrl(`${generateSplitReportUrl}`, 'POST', {}, JSON.stringify({ year, generatedBy }), [permissions.CAN_SEE_5754_SPLIT_REPORTS])
  }
  return { isLoading, error, data, generate5754SplitReport }
}
