import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { setSSNValue ,clearSSNField, resetSnnCount, resetReSnnCount, setReSSNValue } from './functions';
import { ErrorMessageFieldCommonComponent } from './../../CommonComponents/ErrorMessageFieldCommonComponent';




export const IdentityDocumentsDocument1:string[] =['Select...','Drivers License','Passport','Document','Other'];
export const IdentityDocumentsDocument2:string[] =['Select...','Social Security'];



export const IdentityDocumentsComponent = ({ formik , setFiledValue,ssnNumberIsBeenUsed }: any) => {
     let numberOfClick:number = 0;
     const [ssnErrorMessage, setSsnErrorMessage ] = useState(undefined);
     const [reSsnErrorMessage, setReSsnErrorMessage ] = useState(undefined);
    
    return (
        <>
           <form className={styles['page-container-body']}>
              <div tabIndex={0} className={styles['page-container-body-left-title']} >Identity Documents</div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Document 1 *</div>
                <select 
                id="identityDocument.identification1" 
                name="identityDocument.identification1"  
                onChange={(e:any) =>  setFiledValue(e) } 
                aria-label={`${formik.values.identityDocument.identification1}.`}
                className={styles["page-container-body-left-input-box"]}  >
                  {IdentityDocumentsDocument1.map((g: string) => <option  value={g}>{g}</option>)}
                </select> 
                {formik.errors.identification1 &&  <ErrorMessageFieldCommonComponent identification1={formik.errors.identification1} />}  
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Document 2 *</div>
                <select 
                id="identityDocument.identification2" 
                name="identityDocument.identification2"  
                onChange={(e:any) =>  setFiledValue(e) }
                aria-label={`${formik.values.identityDocument.identification2}.`}                                                                           
                className={styles["page-container-body-left-input-box"]}  >
                  {IdentityDocumentsDocument2.map((g: string) => <option value={g}>{g}</option>)}
               </select>
               {formik.errors.identification2 &&  <ErrorMessageFieldCommonComponent identification2={formik.errors.identification2}  />}
               
              </div>  
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Documents 1 Notes</div>
                <textarea
                className={styles["page-container-body-left-textarea"]} 
                id="identityDocument.comment1"
                name="identityDocument.comment1" 
                value={formik.values.identityDocument.comment1} 
                onChange={(e:any) =>  setFiledValue(e) }
                aria-label={`${formik.values.identityDocument.comment1}.`}
                ></textarea>
                 
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Documents 2 Notes</div>
                <textarea
                  id="identityDocument.comment2"
                  name="identityDocument.comment2"
                  onChange={(e:any) =>  setFiledValue(e) }
                  value={formik.values.identityDocument.comment2}
                  className={styles["page-container-body-left-textarea"]}
                  aria-label={`${formik.values.identityDocument.comment2}.`}
                />
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>SSN *</div>
                <input
                  autoComplete={"off"}
                  id="identityDocument.ssn"
                  name="identityDocument.ssn"
                  className={styles["page-container-body-left-input-box"]}
                  onFocus={() => resetSnnCount()}
                  onChange={(e:any) =>  setSSNValue(e,formik,setSsnErrorMessage) }
                  value={formik.values.identityDocument.ssn}
                  onClick = { (e) =>  clearSSNField(e,formik)}
                  aria-label={`${formik.values.identityDocument.ssn}.`}
                  type="text"
                  />
                   {formik.errors.ssn &&  <ErrorMessageFieldCommonComponent ssn={formik.errors.ssn} ssnErrorMessage={ssnErrorMessage}  />}
              </div>
              <div className={styles["page-container-body-left-label-and-input-box-container"]}>
                <div tabIndex={0} className={styles["page-container-body-left-labels-name"]}>Re-Enter SSN *</div>
                <input
                  autoComplete={"off"}  
                  id="identityDocument.reenterssn"
                  name="identityDocument.reenterssn"
                  className={styles["page-container-body-left-input-box"]}
                  onFocus={() => resetReSnnCount()}
                  onChange={(e:any) =>  setReSSNValue(e,formik,setReSsnErrorMessage) }
                  onClick = { (e) =>  clearSSNField(e,formik)}
                  value={formik.values.identityDocument.reenterssn}
                  aria-label={`${formik.values.identityDocument.reenterssn}.`}
                  type="text"
                  />
                  {(formik.errors.reenterssn|| formik.errors.ssnTest) &&  <ErrorMessageFieldCommonComponent reenterssn={formik.errors.reenterssn} reSsnErrorMessage={reSsnErrorMessage} ssnTest={formik.errors.ssnTest} />}
                
              </div>            
            </form>
        </>

    );
}