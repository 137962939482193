import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GetPendingPaymentsResponse } from '../../../../api/pending-payments-fetcher/api-types'
import goBackIcon from '../../../../icons/Icon_arrows_carrot_white-left.svg'
import { keyPressedType } from '../../../../services/Commons'
import { GenericErrorDialogComponent } from '../../../CommonComponents/GenericErrorDialogComponent'
import { AllFuturePaymentsContainer } from '../all-future-payments/all-future-payments-container'
import { CheckRunQueueTitleBarUI } from '../check-run-queue-title-bar/check-run-queue-title-bar-ui'
import { PendingCheckQueueUI } from '../pending-check-queue/pending-check-queue-ui'
import './styles.scss'

const ROOT_CLASS = 'check-run-queue-page'

export type CheckRunQueueContainerUIProps = {
  pendingPaymentsData: GetPendingPaymentsResponse
  fetchInitiateCheckRun: (futurePaymentIds: string[]) => void
  initiateCheckRunError: string | undefined
  taxAdjustmentStatusLoading: boolean
}

export const CheckRunQueueContainerUI = (
  props: CheckRunQueueContainerUIProps
) => {
  const [showErrorDialog, setShowErrorDialog] = useState(
    props.initiateCheckRunError !== undefined
  ) // this is a bit of a hack imo -- it really relies on the fact that this component goes away & gets re-rendered every time we hit "initiate check run" to make sure the message gets displayed...
  const history: any = useHistory()

  const [pendingTabSelected, setPendingTabSelected] = useState<boolean>(true)

  return (
    <div className={ROOT_CLASS}>
      <div
        tabIndex={0}
        className={`back-button-container`}
        onClick={() => {
          history.goBack()
        }}
        onKeyDown={(ev: any) => {
          if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
            history.goBack()
          }
        }}
      >
        <img
          style={{ cursor: 'pointer' }}
          src={goBackIcon}
          alt="go back icon"
        />
        <div className={`back-message`} style={{ cursor: 'pointer' }}>
          Back to Payments
        </div>
      </div>

      <CheckRunQueueTitleBarUI
        {...props}
        showInititateCTA={pendingTabSelected}
        activateInitiateButton={
          props.pendingPaymentsData.futurePayments.length > 0
        }
        fetchInitiateCheckRun={props.fetchInitiateCheckRun}
      />

      <div className="w-100 d-flex mt-3 border-bottom border-white">
        <button
          className={`tab-button ml-2 pt-2 text-center rounded-top`}
          style={
            pendingTabSelected
              ? { background: '#fff', color: 'rgb(22, 58, 95)' }
              : { background: 'rgb(22, 58, 95)' }
          }
          onClick={() => {
            setPendingTabSelected(true)
          }}
        >
          Pending Check Queue
        </button>
        <button
          className={`tab-button ml-2 pt-2 text-center rounded-top`}
          style={
            pendingTabSelected
              ? { background: 'rgb(22, 58, 95)' }
              : { background: '#fff', color: 'rgb(22, 58, 95)' }
          }
          onClick={() => {
            setPendingTabSelected(false)
          }}
        >
          All Payments
        </button>
      </div>
      {pendingTabSelected ? (
        <PendingCheckQueueUI {...props} />
      ) : (
        <AllFuturePaymentsContainer
          taxAdjustmentStatusLoading={props.taxAdjustmentStatusLoading}
         />
      )}
      {showErrorDialog ? (
        <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog} />
      ) : null}
    </div>
  )
}
