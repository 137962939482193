export interface PermitHolderDetailsInterface {
    raffleId: string,
    cityCode: string,
    orgType: string,
    orgName: string,
    address: {
        streetAddress: string,
        city: string,
        zip: string
    },
    phone: string,
    dateOfFirstPermit: string,
    effectiveDate: string,
    expirationDate: string,
    email: string,
    orgActive: boolean
}

export const PermitHolderDetailsDefaultData = {
    raffleId: '',
    cityCode: '',
    orgType: '',
    orgName: '',
    address: {
        streetAddress: '',
        city: '',
        zip: ''
    },
    phone: '',
    dateOfFirstPermit: '',
    effectiveDate: '',
    expirationDate: '',
    email: '',
    orgActive: false
}
