import React from "react";
import {useHistory} from "react-router-dom";
import styles from './css/styles.module.css';
import {keyPressedType} from "../../../../../services/Commons";
import {licenseeRoutePath} from "../../../../../config.js";
import {checkRenderPermissions} from "../../../../../helpers";
import {permissions} from "../../../../../constants";

export const ViewEFTInformationComponent = ({licenseNumber, EFTInformation}: any) => {

    const history: any = useHistory();
    const userpolicies: any = localStorage.getItem("userpolicies");

    const defaultFieldValue = 'n/a';

    return (
        <>

            <div className={`${styles['view-org-details-container']}`}>

                <div className={`${styles['view-org-details-container-title-1-container']}`}>
                    <div className={`${styles['view-org-details-container-title']}`}>Bank Information</div>
                    {checkRenderPermissions(permissions.CAN_UPDATE_EFT_DETAILS, JSON.parse(userpolicies)) ?
                        <div tabIndex={0} className={`${styles['view-org-details-container-update-button']}`}
                             role="button"
                             style={{
                                 cursor: "pointer"
                             }}
                             onKeyDown={(ev: any) => {
                                 if (keyPressedType(ev) === 13 || keyPressedType(ev) === 0 || keyPressedType(ev) === 32) {
                                     history.push(`${licenseeRoutePath}/update/eft-account/${licenseNumber}`);
                                 }
                             }}
                             onClick={() => {
                                 history.push(`${licenseeRoutePath}/update/eft-account/${licenseNumber}`);
                             }}>Update
                        </div> : null}
                </div>

                <div className={`${styles['view-org-details-controls-container']}`}>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Bank Name</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{(EFTInformation.bankName === '') ? defaultFieldValue : EFTInformation.bankName}</div>
                    <div className={`${styles['view-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Name as Shown on Account</div>
                    <div className={`${styles['view-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{(EFTInformation.nameOnAccount === '') ? defaultFieldValue : EFTInformation.nameOnAccount}</div>
                    <div className={`${styles['view-org-details-controls-container-column-5']} ${styles['mslc-label']}`}>Account Type</div>
                    <div className={`${styles['view-org-details-controls-container-column-6']} ${styles['mslc-label']}`}>{(EFTInformation.licenseNumber === '') ? defaultFieldValue : EFTInformation.accountType}</div>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Bank Transit Number</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{(EFTInformation.routingNumber === '') ? defaultFieldValue : EFTInformation.routingNumber}</div>
                    <div className={`${styles['view-org-details-controls-container-column-3']} ${styles['mslc-label']}`}>Account Number</div>
                    <div className={`${styles['view-org-details-controls-container-column-4']} ${styles['mslc-label']}`}>{(EFTInformation.accountNumber === '') ? defaultFieldValue : EFTInformation.accountNumber}</div>

                </div>

                <div className={`${styles['view-org-details-container-title-2-container']}`}>
                    <div className={`${styles['view-org-details-container-title']}`}>Organization's Information</div>
                </div>
                <div className={`${styles['view-org-details-controls-container']}`}>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Authorizing Party</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{(EFTInformation.authorizingParty === '') ? defaultFieldValue : EFTInformation.authorizingParty}</div>

                    <div className={`${styles['view-org-details-controls-container-column-1']} ${styles['mslc-label']}`}>Email</div>
                    <div className={`${styles['view-org-details-controls-container-column-2']} ${styles['mslc-label']}`}>{(EFTInformation.emailAddress === '') ? defaultFieldValue : EFTInformation.emailAddress}</div>

                </div>


            </div>


        </>

    );
};