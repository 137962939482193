import React from "react"
import moment from 'moment-timezone'
import { AsyncContainerUI } from "../../../components/CommonComponents/async-container-component/async-container-ui"
import { Split5754ReportListEntry } from "../../../api/split-5754-reporting-fetcher/api-types"
import { SearchResultsTableColumnMap, SearchResultsTableUI } from "../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui"
import { Split5754ReportingFilterBarUI } from "../../../components/PaymentComponents/Split5754ReportingComponents/split-5754-reporting-filter-bar/split-5754-reporting-filter-bar-ui"
import { BackButtonUI } from "../../../components/CommonComponents/BackButtonComponent/back-button-ui"
import {checkRenderPermissions} from '../../../helpers.js';
import {permissions} from './../../../constants.js';
import './styles.scss' 
import { mapFinservReportStatusToString } from "../../../util"

export type Split5754ReportingPageProps = {
  reports : Split5754ReportListEntry[]
  isLoading: boolean
  error: string | undefined
  year: string | undefined
  setYear: (year: string) => void
  generateReport: (year: string, generatedBy: string) => Promise<void>
  downloadReport: (row: Split5754ReportingDisplayRow) => Promise<void>
}

export type Split5754ReportingDisplayRow = {
  id: string
  dateGenerated: string
  generatedBy: string
  year: string
  status: string
}

const SPLIT_5754_REPORTING_COLUMN_MAP: SearchResultsTableColumnMap<Split5754ReportingDisplayRow> =
  [
    { dataKey: 'dateGenerated', label: 'Date & Time Generated', width: 24 },
    { dataKey: 'generatedBy', label: 'Generated by', width: 14 },
    { dataKey: 'year', label: 'Year', width: 14 },
    { dataKey: 'status', label: 'Status', width: 18 },
  ]

const mapReportToDisplayRow = (
  report: Split5754ReportListEntry
): Split5754ReportingDisplayRow => {
  return {
    id: report.id,
    dateGenerated: moment(report.dateGenerated).tz('America/New_York').format('MM/DD/YYYY HH:mm'),
    generatedBy: report.generatedBy,
    year: report.year,
    status: mapFinservReportStatusToString(report.status),
  }
}

const ROOT_CLASS = 'split-5754-reporting-page'

export const Split5754ReportingPageUI = (props: Split5754ReportingPageProps) => {
  const { reports, isLoading, error, year, setYear, generateReport, downloadReport } = props

  const generateReportingDownloadCTA = (report: Split5754ReportingDisplayRow) => {
    const userpolicies: any = localStorage.getItem('userpolicies')
    return (
      report.status !== 'Failed' && checkRenderPermissions(permissions.CAN_DOWNLOAD_5754_SPLIT_REPORTS, JSON.parse(userpolicies)) && (
        <button
          disabled={report.status === 'Pending'}
          onClick={() => downloadReport(report)}
          className={`${ROOT_CLASS}-dark-btn`}
        >
          Download
        </button>
      )
    )
  }

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={0} message="Back to Reporting" />
      <header>5754 Split Report</header>
      <Split5754ReportingFilterBarUI 
        year={year || ''}
        setYear={setYear}
        generateEnabled={!!year}
        generateReport={generateReport}
      />
      <AsyncContainerUI
        isLoading = {isLoading}
        error={error}
        errorTextSize={20}
        color="white"
      >
        { year ? 
          <SearchResultsTableUI
            rowData={reports?.map(mapReportToDisplayRow) ?? []}
            additionalResults={false}
            columnMap={SPLIT_5754_REPORTING_COLUMN_MAP}
            generateRowCTA={generateReportingDownloadCTA}
            noDataMessage="No reports have been generated for the given year"
          /> :
          <div className={'no-results-container'}>
            <div className={'no-results-text'}>Select a year to generate reports</div>
          </div>
        }
        
      </AsyncContainerUI>
    </div>
  )
}
