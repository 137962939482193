import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { CaretDirection, IconCaret } from '../icon-caret/icon-caret'
import './styles.scss'

type CollapsableSectionProps = {
  children?: React.ReactNode
  title?: string
  subtitle?: string | JSX.Element
  isForcedOpen?: boolean
  connectedBottom?: boolean
  textSize?: 'large' | 'small'
  backgroundColor?: 'default' | 'dark_blue' | 'light_blue'
  smallHeader?: boolean
  defaultOpen?: boolean
}

const ROOT_CLASS = 'collapsable-section'
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')
const BUTTON_CLASS = getBlockClass(HEADER_CLASS, 'button')
const TITLE_CLASS = getBlockClass(HEADER_CLASS, 'title')
const BODY_CLASS = getBlockClass(ROOT_CLASS, 'body')

export const useForceOpen = () => {
  const [forceSectionOpen, setForceSectionOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    setForceSectionOpen(false)
  }, [forceSectionOpen])

  return {
    isForcedOpen: forceSectionOpen,
    forceOpen: () => setForceSectionOpen(true),
  }
}

export const CollapsableSection = (props: CollapsableSectionProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(
    Boolean(props.defaultOpen) ? true : false
  )

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  React.useEffect(() => {
    if (props.isForcedOpen) {
      setIsOpen(true)
    }
  }, [props.isForcedOpen])

  return (
    <section
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(
          ROOT_CLASS,
          props.backgroundColor ?? '',
          Boolean(props.backgroundColor)
        ),
        getModifierClass(ROOT_CLASS, 'flat', Boolean(props.connectedBottom)),
        getModifierClass(ROOT_CLASS, 'small', Boolean(props.smallHeader)),
      ])}
    >
      <div className={`${HEADER_CLASS} ${isOpen ? 'open' : ''}`}>
        <button
          onClick={toggleOpen}
          aria-label={`expand section for ${props.title}`}
          className={joinClasses([
            BUTTON_CLASS,
            getModifierClass(
              BUTTON_CLASS,
              'space-between',
              Boolean(props.smallHeader)
            ),
          ])}
          type="button"
        >
          <span
            className={joinClasses([
              TITLE_CLASS,
              getModifierClass(
                TITLE_CLASS,
                props.textSize ?? '',
                Boolean(props.textSize)
              ),
            ])}
          >
            {props.title}
          </span>
          <IconCaret
            direction={isOpen ? CaretDirection.up : CaretDirection.down}
            smallCaret={Boolean(props.smallHeader)}
          />
        </button>
        {props.subtitle && (
          <span className={getBlockClass(HEADER_CLASS, 'subtitle')}>
            {props.subtitle}
          </span>
        )}
      </div>
      <div
        className={joinClasses([
          BODY_CLASS,
          getModifierClass(BODY_CLASS, 'open', isOpen),
        ])}
      >
        {isOpen && props.children}
      </div>
    </section>
  )
}
