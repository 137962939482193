import React, { useEffect, useState } from "react";
import styles from './css/styles.module.css';
import { GenderCodes } from "../../Constants/GenderCodes";
import { AddressStateCodes } from "../../Constants/AddressStateCodes";
import { checkRenderPermissions } from "./../../../src/helpers";
import { permissions } from "./../../../src/constants";
import { getPlayersDetailsInterface } from "../../../Player/UpdateProfile/interfaces";
import { useHistory } from "react-router-dom";
import { playerSupportRoutePath } from './../../../config.js'
import { keyPressedType } from "../../../services/Commons";
import { normilizePhoneStyle } from './functions';
import { playersIdentityValues } from './../../../services/Commons';
import { clearPageMessageDefault } from "../../CommonComponents/PageMessageCommonComponent";
import { mobileClaimDetailsPageInterface } from "../../../pages/ClaimsPages/MobileClaimDetailsPage/interfaces";


export const ClaimOwnerInformationComponent = ({ mobileClaimDetailsPage }:any | mobileClaimDetailsPageInterface) => {
   
    
   
    return (
        <>
            <div onFocus={()=> clearPageMessageDefault() } className={styles['page-container']}>
                   <div className={styles['claim-data-container']}>
                    <div className={styles['claim-profile-content-title-container']}>
                        <div tabIndex={0} className={styles['claim-profile-content-title']} >Claim Owner Information</div>
                    </div>
                    <div className={styles['claim-profile-content-container']}>
                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>Claim Owner</div>
                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>{
                        `${mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerFirstName} ${mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerLastName}`}</div>

                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>Email</div>
                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>
                            {`${ mobileClaimDetailsPage.player.account.email }`}
                            </div>

                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column1"]} ${styles["player-profile-content-labels-name"]}`}>Player ID</div>
                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column2"]} ${styles["player-profile-content-labels-name"]}`}>
                            {`${ mobileClaimDetailsPage.claimReferenceNumber.claim.currState.playerId }`}
                        </div>

                        <div tabIndex={0} className={`${styles["claim-profile-content-container-column3"]} ${styles["player-profile-content-labels-name"]}`}>Phone</div>
                        <div tabIndex={0}  className={`${styles["claim-profile-content-container-column4"]} ${styles["player-profile-content-labels-name"]}`}>
                            {`${ normilizePhoneStyle(mobileClaimDetailsPage.player.identity.phone) }`}
                        </div>                    
                    </div>
                    </div>
            </div>
        </>

    );
}

