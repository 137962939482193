import * as React from 'react'
import {
  PromotionDrawingGroup,
  PromotionStatus,
} from '../../../api/promotions-fetcher/constants'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { GenericToolTipWrapper } from '../../GenericToolTip/generic-tool-tip-wrapper'
import {
  generateDrawingGroupText,
  generateDrawingGroupTooltip,
} from './helpers'
import './styles.scss'

const ROOT_CLASS = 'promotion-drawing-tooltip-ui'
const DRAWING_CLASS = getBlockClass(ROOT_CLASS, 'drawing')
const DRAWING_TEXT_CLASS = `${DRAWING_CLASS}-text`

export type PromotionDrawingTooltipProps = {
  promotionStatus: PromotionStatus
  drawingGroup?: PromotionDrawingGroup
  bonusDrawingGroup?: PromotionDrawingGroup
  showHeader?: boolean
}

export const PromotionDrawingGroupTooltipUI = (
  props: PromotionDrawingTooltipProps
) => {
  const { drawingGroup, bonusDrawingGroup, showHeader, promotionStatus } = props
  let drawingText = generateDrawingGroupText(promotionStatus, drawingGroup)
  let toolTip = generateDrawingGroupTooltip(drawingGroup)

  let bonusDrawingText = bonusDrawingGroup
    ? generateDrawingGroupText(promotionStatus, drawingGroup, true)
    : undefined

  return (
    <div className={DRAWING_CLASS}>
      <GenericToolTipWrapper
        triggerElement={
          <div className={DRAWING_CLASS}>
            {showHeader && (
              <span className={DRAWING_TEXT_CLASS}>{drawingGroup?.name}</span>
            )}
            <span
              className={joinClasses([
                DRAWING_TEXT_CLASS,
                getModifierClass(
                  DRAWING_TEXT_CLASS,
                  'italic',
                  Boolean(showHeader)
                ),
              ])}
            >
              {drawingText}
            </span>
          </div>
        }
        hiddenElement={toolTip}
        position="top"
        noBorder
        showArrow
      />
      {bonusDrawingGroup && (
        <span
          className={joinClasses([
            DRAWING_TEXT_CLASS,
            getModifierClass(DRAWING_TEXT_CLASS, 'italic', true),
          ])}
        >
          {bonusDrawingText}
        </span>
      )}
    </div>
  )
}
