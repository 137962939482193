export const GenderCodes = [
    'Male',
    'Female',
    'Non-Binary',
    'Prefer not to Designate'
]
export const OrganizationTypeCodes = [
    '001 - Fraternal Organization',
    '002 - Church or Religious Organization',
    '003 - Veterans Organization',
    '004 - Volunteer Non-Profit Fire Company or Volunteer Non-Profit Ambulance Service',
    '005 - Boston Fireman\'s Relief Fund',
    '006 - Non-Profit Organization for the Interest of Differently Abled Citizens',
    '007 - Non-Profit Athletic Organization',
    '008 - Non-Profit Senior Citizens Organization',
    '009 - Non-Profit Social Welfare Organization',
    '010 - Other',
]

export const OccasionDayCodes = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]
export const TaxIDNoTypes = [
    '1 - Federal Only',
    '2 - State Only',
    '3 - Both',
    '4 - Neither'
]

export const TINTypes = [
    'TIN',
    'ATIN',
    'SSN',
    'EIN'
]

export const StaffTrained = [
    'Yes',
    'No'
]

export const StaffTrainedMapping = {
    true: 'Yes',
    false: 'No'
}

export const HoursOfOperationMapping = {
    EVENING: '6:00 P.M. to 12:00 MID.',
    AFTERNOON: '1:00 P.M to 6:00 P.M'
}

export const SortByOccasionDate = [
    'Newest First',
    'Oldest First'
]

export const ShippingMethods = [
    'Pick-up',
    'UPS'
]

export const PaymentMethods = [
    'Check',
    'EFT',
    'Free'
]

export const ExpenseTypeMapping = {
    WEEKLY_BEANO: 'Weekly Taxes',
    RAFFLE_BAZAAR: 'Raffle Taxes',
    CASINO_FUNCTION: 'Casino Night Taxes',
    LICENSE_RENEWAL: 'License Renewal',
    PURCHASE_ORDER: 'Pull Tab Order'
}

export const ChargeStatusMapping = {
    AWAITING_APPROVAL: 'Out of Balance',
    READY_FOR_SWEEP: 'Scheduled',
    SWEEP_PENDING: 'Pending',
    SUBMITTED: 'Submitted',
    SETTLED: 'Paid',
    REJECTED: 'Rejected',
    AWAITING_CHECK: 'Awaiting Check',
    REJECTED_AWAITING_CHECK: 'Awaiting Check',
    ON_HOLD: 'On Hold'
}

export const PayTaxIndicatorMapping = {
    NOT_TIME_PAYMENT: '0 - N/A',
    IRS_DOR: '1 - IRS and DOR',
    IRS_ONLY: '2 - IRS Only',
    DOR_ONLY: '3 - DOR Only',
    NON: '4 - No Taxes'
}
