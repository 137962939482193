import config from "../../../../../config.js";
import { makeHttpCall } from "../../../../../services/MakeHttpCall";
import { PostPaymentRules } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/PostPaymentRules.js";
import { PostSubAccount } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/PostSubAccount.js";
import { TempPaymentRules } from "../../../../../types/Annuities/TPAManagement/SubAccountManagement/TempPaymentRules.js";
import { convertPaymentRules } from "../functions";
// submit New sub account
export const submitNewSubAccount = async (
  subAccountdata: PostSubAccount,
  newPaymentRules: TempPaymentRules[],
  idemToken: string
) => {
  try {
    const subAccountId = await createNewSubAccount(subAccountdata, idemToken);
    await addPaymentRules(newPaymentRules, subAccountId, idemToken);
  } catch (error) {
    throw error;
  }
};

// POST - create new sub Account
const createNewSubAccount = async (
  subAccountdata: PostSubAccount,
  idemToken: string
) => {
  const options = {
    method: "POST",
    url: `${config.SERVER_BASE_URL}/v1/annuities/sub-account/`,
    data: subAccountdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "application/json",
      "x-idempotency-token": idemToken,
    },
  };

  try {
    const response = await makeHttpCall(options);
    return response.id;
  } catch {
    throw new Error("Something went wrong Adding New Sub Account");
  }
};

// POST - create Payment Rules to Sub Account
const addPaymentRules = async (
  previousPaymentRules: TempPaymentRules[],
  subAccountId: string,
  idemToken: string
) => {
  const newPaymentRules: PostPaymentRules[] = convertPaymentRules(
    previousPaymentRules,
    subAccountId
  );

  const options = {
    method: "POST",
    url: `${config.SERVER_BASE_URL}/v1/annuities/payment-rule/`,
    data: newPaymentRules,
    headers: {
      "Content-Type": `application/json`,
      "x-idempotency-token": idemToken,
    },
  };

  try {
    const response = await makeHttpCall(options);
    return response;
  } catch {
    throw new Error("Something went wrong Adding Payment Rules");
  }
};
