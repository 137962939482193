import * as React from 'react'
import { AllPromotionsResponse } from '../../../api/promotions-fetcher/constants'
import { PromotionsHourlyReportUI } from './promotions-hourly-report-ui'
import { useFetchPromotionHourlyReport } from '../../../api/promotions-fetcher/use-fetch-promotions-reporting'

export type PromotionsHourlyReportContainerProps = {
  promotions?: AllPromotionsResponse
}

export const PromotionsHourlyReportContainer = (
  props: PromotionsHourlyReportContainerProps
) => {
  const { isLoading, error, data, resetData, generateReport } =
    useFetchPromotionHourlyReport()
  return (
    <PromotionsHourlyReportUI
      promotions={props.promotions}
      reportLoading={isLoading}
      reportError={error}
      reportData={data}
      generateReport={generateReport}
      resetData={resetData}
    />
  )
}
