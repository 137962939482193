import config from '../../../config'
import { useFetch } from "../../../hooks/use-fetch"
import { ListCheckLoadReportsResponse } from '../../../api/check-load-reports-fetcher/api-types'

const getReportsUrl = `${config.SERVER_BASE_URL}/api/v1/reporting/checkLoad/reports`

export const useFetchCheckLoadReports = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<ListCheckLoadReportsResponse>()
  const fetchReports = () => {
    fetchUrl(getReportsUrl, 'GET')
  }

  return {
    isLoading,
    error,
    data,
    fetchReports
  }
}