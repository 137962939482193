import * as React from 'react'
import './styles.scss'
import { formatCentsToDollars } from '../../../util'
import { SearchResultsTableColumnMap, SearchResultsTableUI } from '../../CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { GenericToolTipWrapper } from '../../GenericToolTip/generic-tool-tip-wrapper'
import { getBlockClass } from '../../../utilities/helpers'

const ROOT_CLASS = 'agent-1099-queue-payments-table'

export type Agent1099QueuePaymentsTableRow = {
  year: string
  fid: string
  taxName: string
  address: string
  city: string
  reportable: string
  digitalConsent: string
  queued: boolean
}

export const agent1099QueuePaymentsTableColumnMap: SearchResultsTableColumnMap<Agent1099QueuePaymentsTableRow> =
  [
    {
      dataKey: 'year',
      label: 'Year',
      width: 6
    },
    {
      dataKey: 'fid',
      label: 'FID',
      width: 8
    },
    {
      dataKey: 'taxName',
      label: 'Corp Name',
      width: 20
    },
    {
      dataKey: 'address',
      label: 'Address',
      width: 20,
    },
    {
      dataKey: 'city',
      label: 'City',
      width: 12,
    },
    {
      dataKey: 'reportable',
      label: 'Total',
      width: 12,
    },
    {
      dataKey: 'digitalConsent',
      label: 'Digital Consent',
      width: 12
    }
  ]

export type Agent1099Payment = {
  year: string
  fid: string
  taxName: string
  address: string
  city: string
  reportableInCents: number
  digitalConsent: boolean
  queued: boolean
}

export type Agent1099QueuePaymentsTableUIProps = {
  payments?: Agent1099Payment[]
  sweepId?: string
  additionalResults?: boolean
  showRowCTAs?: boolean
  shouldDisableRowCTA: (payment: Agent1099QueuePaymentsTableRow) => boolean
  ctaText: string
  ctaCallback: (tin: string, year: string) => void
  noSelectionMessage: string
}

export const Agent1099QueuePaymentsTableUI = (
  props: Agent1099QueuePaymentsTableUIProps
) => {
  const mappedPayments: Agent1099QueuePaymentsTableRow[] =
    props.payments?.map(payment => {
      return {
        year: payment.year,
        fid: payment.fid,
        taxName: payment.taxName,
        address: payment.address,
        city: payment.city,
        reportable: formatCentsToDollars(payment.reportableInCents),
        digitalConsent: payment.digitalConsent ? 'Y' : 'N',
        queued: payment.queued
      }
    }) ?? []

  const generateCTA = (row: Agent1099QueuePaymentsTableRow) => {
    const disabled = props.shouldDisableRowCTA(row)

    const generateButton = () => {
      return (
        <button
          onClick={() => props.ctaCallback(row.fid, row.year)}
          className={`dark-btn`}
          disabled={disabled}
        >
          {props.ctaText}
        </button>
      )
    }
    return (
      disabled ?
      <GenericToolTipWrapper
        position="top"
        noBorder
        shadow
        showArrow
        triggerElement={generateButton()}
        hiddenElement={
          <div
            className={getBlockClass(ROOT_CLASS, 'tooltip')}
          >
            This 1099 is already in the queue to be printed.
          </div>
        }
      /> : generateButton()
    )
  }
  return (
    <SearchResultsTableUI
      rowData={mappedPayments}
      columnMap={agent1099QueuePaymentsTableColumnMap}
      generateRowCTA={props.showRowCTAs === true ? generateCTA : undefined}
      additionalResults={props.additionalResults ?? false}
      noDataMessage={props.payments ? 'There are no results to display.' : props.noSelectionMessage}
    />
  )
}
