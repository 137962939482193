import * as React from 'react'
import { WinnersManagementTierContext } from '../../winners-management-list-context'
import { ConfirmPopupModal } from '../../../../CommonComponents/ConfirmPopupModal/confirm-popup-modal'
import { useFetchProcessClaim } from '../../../../../api/promotions-fetcher/use-fetch-winners-management'

export type WinnersManagementProcessClaimContainerProps = {
  isOpen: boolean
  closeModal: () => void
  winnerId: string
}

export const WinnersManagementProcessClaimContainer = (
  props: WinnersManagementProcessClaimContainerProps
) => {
  const winnersManagementTierContext = React.useContext(
    WinnersManagementTierContext
  )

  const { isLoading, error, requestSuccessful, processClaim } =
    useFetchProcessClaim(
      winnersManagementTierContext.promotionId,
      winnersManagementTierContext.drawingGroupId,
      winnersManagementTierContext.drawingId,
      props.winnerId
    )

  return (
    <ConfirmPopupModal
      headerText="Are you sure you would like to process this claim?"
      buttonText="Continue"
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      error={error}
      loading={isLoading}
      actionSuccessful={requestSuccessful}
      onSuccess={winnersManagementTierContext.refreshTiers}
      submitAction={processClaim}
    />
  )
}
