import { useFormik } from 'formik'
import moment from 'moment-timezone'
import React, { SetStateAction, useContext, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { BsPencilSquare } from 'react-icons/bs'
import { FaPlus, FaRegSave } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { KEY_CODES } from '../../../../constants.js'
import { BannerContext } from '../../../../context/BannerContext'
import { keyPressedType } from '../../../../services/Commons'
import { TimePaymentAccountResponse } from '../../../../types/Annuities/responses/time-payment-account'
import { formatCentsToDollars } from '../../../../util'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { AccountStatusCmpt } from '../../Common/AccountStatusCmpt'
import { PaymentInfoComponent } from '../PaymentInfoComponent'
import { AnnuityManagementPayeeListComponent } from '../TpaPayeeListCmpt'
import styles from './css/styles.module.css'
import { updateTpaAccount, validate } from './functions'
export const AnnuityManagementDetailsComponent: React.FC<{
  timePaymentAccount: TimePaymentAccountResponse
  setBeginPayeeSearchFlow: React.Dispatch<SetStateAction<boolean>>
}> = ({ timePaymentAccount, setBeginPayeeSearchFlow }) => {
  const FocusTrap = require('focus-trap-react')
  const bannerContext = useContext(BannerContext)
  const history = useHistory()
  const tpaID =
    window.location.href.split('/')[window.location.href.split('/').length - 1]

  // state variables
  const [annuityBtnChangeStatus, setAnnuityBtnChangeStatus] =
    useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [editInvestmentActNum, setEditInvestmentActNum] =
    useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      investAcctNum: timePaymentAccount.investAcctNum,
      status: timePaymentAccount.tpaStatus,
      notes: timePaymentAccount.notes,
    },
    enableReinitialize: true,
    validate,
    onSubmit: () => {},
  })

  const handleCheckboxUpdate = (fieldName: string, fieldValue: string) => {
    formik.setFieldValue(fieldName, fieldValue)
  }

  const handleNotesChange = (fieldName: string, fieldValue: string) => {
    formik.setFieldValue(fieldName, fieldValue)
  }

  return (
    <>
      {/* Update Status Modal */}
      <div className={styles['page-container']}>
        <div
          className={`${styles['flex-space-bw']} ${styles['inner-container']}`}
        >
          <div className={`${styles['general-info-container']}`}>
            <div className={`${styles['general-info-header']}`}>
              <div className={`${styles.flex_left_new}`}>General Info</div>
              <div className="d-flex align-items-center">
                {annuityBtnChangeStatus && (
                  <FocusTrap focusTrapOptions={{ initialFocus: false }}>
                    <div className={styles['overlay-container']}>
                      <div className={styles['main-modal']}>
                        <div>
                          <div className={styles['modal-title']}>
                            Update Status
                          </div>
                          <div className={styles['modal-desc']}>
                            REMINDER: Changing the status from On Hold to Active
                            will automatically release any pending hold
                            payments.
                          </div>
                          <div className={styles['radio_container']}>
                            <div>
                              <input
                                type="radio"
                                className={styles['radio_input']}
                                name="status"
                                value="Active"
                                id="active"
                                checked={formik.values.status === 'Active'}
                                onChange={(event) =>
                                  handleCheckboxUpdate(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                              />
                              <label
                                htmlFor="active"
                                className={styles['radio_label']}
                              >
                                Active
                              </label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                className={styles['radio_input']}
                                name="status"
                                value="On Hold"
                                id="onHold"
                                checked={formik.values.status === 'On Hold'}
                                onChange={(event) =>
                                  handleCheckboxUpdate(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                              />
                              <label
                                htmlFor="onHold"
                                className={styles['radio_label']}
                              >
                                On Hold
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <textarea
                            className={`${styles['text-area-input']}`}
                            id="notes"
                            name="notes"
                            value={formik.values.notes}
                            placeholder="Include any additional context here..."
                            onChange={(event) =>
                              handleNotesChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          ></textarea>
                        </div>
                        <button
                          onKeyPress={async (event) => {
                            if (
                              keyPressedType(event) === KEY_CODES.ENTER ||
                              keyPressedType(event) === KEY_CODES.SPACE
                            ) {
                              try {
                                await updateTpaAccount(
                                  timePaymentAccount,
                                  formik.values
                                )
                                setAnnuityBtnChangeStatus(false)
                                bannerContext.setBannerInfo({
                                  message:
                                    'Successfully Updated Time Payment Account',
                                  error: false,
                                })
                                setTimeout(() => {
                                  history.go(0)
                                }, 2000)
                              } catch (error) {
                                setAnnuityBtnChangeStatus(false)
                                bannerContext.setBannerInfo({
                                  message: `${error}`,
                                  error: true,
                                })
                                setTimeout(() => {
                                  history.go(0)
                                }, 2000)
                              }
                            }
                          }}
                          onClick={async () => {
                            try {
                              await updateTpaAccount(
                                timePaymentAccount,
                                formik.values
                              )
                              setAnnuityBtnChangeStatus(false)
                              bannerContext.setBannerInfo({
                                message:
                                  'Successfully Updated Time Payment Account',
                                error: false,
                              })
                              setTimeout(() => {
                                history.go(0)
                              }, 2000)
                            } catch (error) {
                              setAnnuityBtnChangeStatus(false)
                              bannerContext.setBannerInfo({
                                message: `${error}`,
                                error: true,
                              })
                              setTimeout(() => {
                                history.go(0)
                              }, 2000)
                            }
                          }}
                          className={styles['modal-confirm-btn']}
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          onKeyPress={(event) => {
                            if (
                              keyPressedType(event) === KEY_CODES.ENTER ||
                              keyPressedType(event) === KEY_CODES.SPACE
                            ) {
                              setAnnuityBtnChangeStatus(false)
                            }
                          }}
                          onClick={() => {
                            setAnnuityBtnChangeStatus(false)
                          }}
                          className={styles['modal-cancel-btn']}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </FocusTrap>
                )}
                <AccountStatusCmpt
                  status={timePaymentAccount.tpaStatus}
                  notes={timePaymentAccount.notes ?? ''}
                />
                <button
                  type="button"
                  className={
                    timePaymentAccount.tpaStatus === 'Completed'
                      ? `${styles['update-status-btn-disabled']}`
                      : `${styles['update-status-btn']}`
                  }
                  onKeyPress={(event) => {
                    if (
                      keyPressedType(event) === KEY_CODES.ENTER ||
                      keyPressedType(event) === KEY_CODES.SPACE
                    ) {
                      setAnnuityBtnChangeStatus(!annuityBtnChangeStatus)
                    }
                  }}
                  onClick={() => {
                    setAnnuityBtnChangeStatus(!annuityBtnChangeStatus)
                  }}
                >
                  Update Status
                </button>
              </div>
            </div>
            <div className={`${styles['general-info-content']}`}>
              <div className={`${styles['general-info-detail']}`}>
                <div>Game Type</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.gameType}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Game Name</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.gameName}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Cadence</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.payFrequency}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Total Payments</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.remainingPayments +
                    timePaymentAccount.paymentsMade}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Draw Date</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.gameType === 'Instant' &&
                  !timePaymentAccount.drawDate
                    ? 'N/A'
                    : moment(timePaymentAccount.drawDate).format('MM/DD/YYYY')}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Claim Date</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.claimDate
                    ? moment(timePaymentAccount.claimDate).format('MM/DD/YYYY')
                    : '--'}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>First Pay Date</div>
                <div className={`${styles['flex-right']}`}>
                  {moment(timePaymentAccount.firstPaymentDate).format(
                    'MM/DD/YYYY'
                  )}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Final Pay Date</div>
                <div className={`${styles['flex-right']}`}>
                  {moment(timePaymentAccount.finalPaymentDate).format(
                    'MM/DD/YYYY'
                  )}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Lifetime Payment</div>
                <div className={`${styles['flex-right']}`}>
                  {timePaymentAccount.lifetimePayment ? 'Yes' : 'No'}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Win Amount</div>
                <div className={`${styles['flex-right']}`}>
                  {formatCentsToDollars(timePaymentAccount.winAmount)}
                </div>
              </div>
              <div className={`${styles['general-info-detail']}`}>
                <div>Investment Account No</div>
                {!editInvestmentActNum ? (
                  <div className={`${styles['flex-right']}`}>
                    {formik.values.investAcctNum ?? '--'}
                  </div>
                ) : (
                  <div className={`${styles.info_detail}`}>
                    <input
                      type="text"
                      className={`${styles.custom_input}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="investAcctNum"
                      id="investAcctNum"
                      value={formik.values.investAcctNum ?? '--'}
                    />
                    {formik.errors.investAcctNum &&
                      formik.touched.investAcctNum && (
                        <ErrorMessageFieldCommonComponent
                          errorMessage={formik.errors.investAcctNum}
                        />
                      )}
                  </div>
                )}
              </div>
              <div className={`${styles['general-info-detail']}`}>
                {!editInvestmentActNum ? (
                  <BsPencilSquare
                    onKeyPress={(event) => {
                      if (
                        keyPressedType(event) === KEY_CODES.ENTER ||
                        keyPressedType(event) === KEY_CODES.SPACE
                      ) {
                        setEditInvestmentActNum(true)
                      }
                    }}
                    onClick={() => setEditInvestmentActNum(true)}
                    className={`${styles.general_info_edit_icon}`}
                  />
                ) : (
                  <div className={styles['investment-account-number-actions']}>
                    <FaRegSave
                      className={
                        formik.values.investAcctNum === ''
                          ? `${styles.general_info_save_icon_disabled}`
                          : `${styles.general_info_save_icon}`
                      }
                      onKeyPress={async (event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          try {
                            if (formik.dirty) {
                              await updateTpaAccount(
                                timePaymentAccount,
                                formik.values
                              )
                              setEditInvestmentActNum(false)
                              bannerContext.setBannerInfo({
                                message:
                                  'Successfully Updated Time Payment Account',
                                error: false,
                              })
                            } else {
                              setEditInvestmentActNum(false)
                              bannerContext.setBannerInfo({
                                message:
                                  'Successfully Updated Time Payment Account',
                                error: false,
                              })
                            }
                          } catch (error) {
                            setEditInvestmentActNum(false)
                            formik.setFieldValue(
                              'investAcctNum',
                              timePaymentAccount.investAcctNum
                            )
                            bannerContext.setBannerInfo({
                              message: `${error}`,
                              error: true,
                            })
                          }
                        }
                      }}
                      onClick={async () => {
                        try {
                          if (formik.dirty) {
                            await updateTpaAccount(
                              timePaymentAccount,
                              formik.values
                            )
                            setEditInvestmentActNum(false)
                            bannerContext.setBannerInfo({
                              message:
                                'Successfully Updated Time Payment Account',
                              error: false,
                            })
                          } else {
                            setEditInvestmentActNum(false)
                            bannerContext.setBannerInfo({
                              message:
                                'Successfully Updated Time Payment Account',
                              error: false,
                            })
                          }
                        } catch (error) {
                          setEditInvestmentActNum(false)
                          formik.setFieldValue(
                            'investAcctNum',
                            timePaymentAccount.investAcctNum
                          )
                          bannerContext.setBannerInfo({
                            message: `${error}`,
                            error: true,
                          })
                        }
                      }}
                    />
                    <AiOutlineClose
                      onKeyPress={(event) => {
                        if (
                          keyPressedType(event) === KEY_CODES.ENTER ||
                          keyPressedType(event) === KEY_CODES.SPACE
                        ) {
                          setEditInvestmentActNum(false)
                          formik.setFieldValue(
                            'investAcctNum',
                            timePaymentAccount.investAcctNum
                          )
                        }
                      }}
                      onClick={() => {
                        setEditInvestmentActNum(false)
                        formik.setFieldValue(
                          'investAcctNum',
                          timePaymentAccount.investAcctNum
                        )
                      }}
                      className={`${styles.general_info_exit_icon}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles['payee-container']}`}>
            <div className="d-flex justify-content-between">
              <div className={`${styles.flex_left_new}`}>Payees</div>
              <button
                type="button"
                className={`${styles['add-payee-btn']}`}
                onKeyPress={(event) => {
                  if (
                    keyPressedType(event) === KEY_CODES.ENTER ||
                    keyPressedType(event) === KEY_CODES.SPACE
                  ) {
                    setBeginPayeeSearchFlow(true)
                  }
                }}
                onClick={() => {
                  setBeginPayeeSearchFlow(true)
                }}
              >
                Add Payee &nbsp; <FaPlus size={18} />
              </button>
            </div>
            <div className={`${styles['table-container']}`}>
              {/*Payee List Table*/}
              <AnnuityManagementPayeeListComponent
                tpaID={tpaID}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
          <PaymentInfoComponent timePaymentAccount={timePaymentAccount} />
        </div>
      </div>
    </>
  )
}
