import React, { useEffect, useState } from 'react'
import styles from './css/styles.module.css'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  onReset,
  onSubmit,
  onSubmitRefresh,
  setFiledValue,
  setVariables,
  validate,
} from './functions'
import { useFormik } from 'formik'
import {
  PageMessageCommonComponent,
  pageMessageDefault,
} from '../../../components/CommonComponents/PageMessageCommonComponent'
import { TicketScanningActivityButtonsAndFieldsComponent } from '../../../components/PlayersComponents/TicketScanningActivityButtonsAndFieldsComponent'
import {
  TicketScanningActivityDataInterface,
  TicketScanningActivityDateInterface,
} from './interface'
import { TicketScanningActivityTableComponent } from '../../../components/PlayersComponents/TicketScanningActivityTableComponent'
import { playerSupportRoutePath } from '../../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { keyPressedType } from '../../../services/Commons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PromotionEntryDetailsModalContainer } from '../../../components/CommonComponents/promotion-entry-details-modal/promotion-entry-details-modal-container'

let eventEmitterInitialize: any = undefined
const timerArray: any = []
let resetData: any = []
const TicketScanningActivityPage = ({ id, storesObject }: any) => {
  const defaultTicketScanningActivityData: TicketScanningActivityDataInterface[] =
    []
  const currentDate: any = new Date(new Date().toLocaleDateString())
    .toISOString()
    .split('T')[0]
  const temp = new Date(
    new Date(new Date().toLocaleDateString()).toLocaleDateString()
  )
  temp.setDate(temp.getDate() - 31)
  const currentDateMinusSeven: any = temp.toISOString().split('T')[0]
  const defaultTicketScanningDateFilterData:
    | TicketScanningActivityDateInterface
    | any = {
    startDate: currentDateMinusSeven,
    endDate: currentDate,
  }
  resetData = storesObject.playerStore.ticketScannerEvents.slice()
  const playerStore = storesObject.playerStore
  const playerId = playerStore.selectedPlayerDetails.identity.playerId
  const playerName = localStorage.getItem('PlayersName')
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [MCAPlayerData, setMCAPlayerData] = useState<any[]>(
    storesObject.playerStore.ticketScannerEvents.slice()
  )
  const [MCALoaded, setMCALoaded] = useState<boolean>(
    storesObject.playerStore.ticketScannerEvents.length <= 0
      ? false
      : MCAPlayerData[0]?.gameId === undefined
      ? false
      : true
  )
  const [requestPerformed, setRequestPerformed] = useState<any>(false)
  const [pageMessage, setPageMessage] = useState<any>(pageMessageDefault)
  const [networkError, setNetworkError] = useState<boolean>(false)
  const [isData, setIsData] = useState(false)

  const [openBarcode, setOpenBarcode] = React.useState<string | undefined>(
    undefined
  )

  const history: any = useHistory()
  const formik = useFormik({
    initialValues: defaultTicketScanningDateFilterData,
    onSubmit,
    onReset,
    validate,
  })

  setVariables(
    setIsData,
    id,
    resetData,
    formik,
    playerStore,
    history,
    pageMessageDefault,
    setPageMessage,
    setRequestPerformed,
    setMCAPlayerData,
    setCurrentPage,
    pageMessage,
    setNetworkError
  )
  const sleep = (ms: any) => {
    const timer = setTimeout(
      (timerArray: any) => {
        timerArray.map((e: any) => clearTimeout(e))
        setMCALoaded(false)
      },
      ms,
      timerArray
    )
    timerArray.push(timer)
  }
  // if (eventEmitterInitialize === undefined)
  //     eventEmitter.addListener("refresh-data-ready", refreshComponent);

  useEffect(() => {
    if (MCAPlayerData[0]?.gameId === undefined && MCALoaded === false) {
      setNetworkError(false)
      setIsData(false)
      onSubmitRefresh(
        defaultTicketScanningDateFilterData,
        id,
        setMCALoaded,
        setIsData,
        pageMessage,
        setNetworkError
      )
      document.getElementById('page-container')?.focus()
    }

    if (networkError && isData) {
      setIsData(false)
      setNetworkError(false)
      sleep(5000)
    }
  })

  return (
    <>
      {(((MCALoaded === false || requestPerformed) &&
        pageMessage.message === '') ||
        (networkError && !isData && pageMessage.message === '')) && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
            zIndex: 1000,
          }}
        >
          <CircularProgress size="8em" color="inherit" thickness={2} />
        </div>
      )}

      <div
        onClick={(e: any) => {
          pageMessage.messageType !== '' &&
            setPageMessage({ message: '', messageType: '' })
        }}
        id="page-container"
        className={styles['page-container']}
      >
        <div className={styles['back-to-player-search-container']}>
          <div
            tabIndex={0}
            role="button"
            aria-pressed={'false'}
            onKeyPress={(e: any) => {
              if (
                keyPressedType(e) === 13 ||
                keyPressedType(e) === 0 ||
                keyPressedType(e) === 32
              ) {
                localStorage.setItem('activeTab', 'activity')
                pageMessageDefault.message = ''
                pageMessageDefault.messageType = ''
                history.push(`${playerSupportRoutePath}/player-hub-new/${id}`)
              }
            }}
            onClick={() => {
              localStorage.setItem('activeTab', 'activity')
              pageMessageDefault.message = ''
              pageMessageDefault.messageType = ''
              history.push(`${playerSupportRoutePath}/player-hub-new/${id}`)
            }}
            className={styles['back-to-player-search-inner-container']}
          >
            <i
              title="left arrow icon"
              className={styles['back-to-player-search-icon']}
            >
              <FontAwesomeIcon
                size="2x"
                arial-label="left arrow icon."
                icon={faAngleLeft as IconProp}
              />
            </i>
            <div
              className={styles['back-to-player-search-inner-container-text']}
            >
              Back to {playerName}
            </div>
          </div>
        </div>
        {pageMessage.message !== '' && (
          <PageMessageCommonComponent
            message={pageMessage.message}
            messageType={pageMessage.messageType}
          />
        )}
        {openBarcode && (
          <PromotionEntryDetailsModalContainer
            playerId={playerId}
            barcode={openBarcode}
            clearBarcode={() => setOpenBarcode(undefined)}
          />
        )}
        <div className={styles['top-ribbon']}>
          <div className={styles['top-ribbon-text']} tabIndex={0}>
            Ticket Scanning Activity
          </div>
          <TicketScanningActivityButtonsAndFieldsComponent
            formik={formik}
            setFiledValue={setFiledValue}
            setRequestPerformed={setRequestPerformed}
          />
          <TicketScanningActivityTableComponent
            id={id}
            MCAPlayerData={MCAPlayerData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setOpenBarcode={setOpenBarcode}
          />
        </div>
      </div>
    </>
  )
}
export default TicketScanningActivityPage
