import * as React from 'react'
import { userHasAnyOfPermissions } from '../../../../util/access-helpers'
import { clearPageMessageDefault } from './../../../../components/CommonComponents/PageMessageCommonComponent'
import { isSelectionKey } from '../../../common'
import './styles.scss'

type MenuItemComponentProps = {
  anyOfPermissionsRequired: string[]
  userPolicy: string
  id: string
  title: string
  routePath: string
  buttonState: string
  altText: string
  imgSource?: string
  buttonStates: any
  updateButtonStates: (e: any) => void
  storesObject: any
  history: any
}

function handleClick(props: MenuItemComponentProps): void {
  clearPageMessageDefault()
  props.storesObject.playerStore.reset()
  props.storesObject.claimStore.reset()
  props.history.push(`${props.routePath}/`)
  props.updateButtonStates(props.id)
}

export const MenuItemComponent = (props: MenuItemComponentProps) => {
  if (!userHasAnyOfPermissions(props.anyOfPermissionsRequired)) {
    return null
  }
  return (
    <button
      aria-live={'assertive'}
      id={props.id}
      tabIndex={0}
      aria-pressed={props.buttonStates[props.buttonState]}
      title={props.title}
      onKeyPress={(e: any) => {
        if (isSelectionKey(e.key)) {
          handleClick(props)
        }
      }}
      onClick={(e: any) => {
        handleClick(props)
      }}
      className={'menu-home-icons-container'}
    >
      <img
        src={props.imgSource}
        className={'menu-home-icon'}
        alt={props.altText}
      />
      <div className={'menu-home-icon-text'}>{props.title}</div>
    </button>
  )
}
