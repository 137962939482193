import React from 'react'
import { PreviewReportProps } from '../../../../api/check-run-reporting-fetcher/api-types'
import './styles.scss'

type PreviewReportGenerateProps = {
  generateRequestProps: PreviewReportProps
  generateEnabled: boolean
  generateHandler: (generateRequestProps: PreviewReportProps) => void
}

export const PreviewReportGenerateButton = (
  props: PreviewReportGenerateProps
) => {
  const handleClick = () => {
    props.generateHandler(props.generateRequestProps)
  }
  return (
    <button
      onClick={handleClick}
      disabled={!props.generateEnabled}
      className={`generate-btn`}
    >
      Generate
    </button>
  )
}
