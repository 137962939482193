import * as React from 'react'
import { PlayerPromotionDrawingDetailsResponse } from '../../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { LoadingDots } from '../../../../../components/CommonComponents/loading-dots/loading-dots-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../../utilities/helpers'
import './drawing-details-table-styles.scss'
import { PromotionType } from '../../../../../api/promotions-fetcher/constants'

const TABLE_CLASS = 'promotion-drawing-details-table'

const SecondChanceTableHeaders = [
  { columnText: 'Ticket Number' },
  { columnText: 'Ticket Entry Method' },
  { columnText: 'Entries' },
  { columnText: 'Entry Date' },
]

const ScanToEnterTableHeaders = [
  { columnText: 'Ticket Number' },
  { columnText: 'Ticket Entry Method' },
  { columnText: 'Scan Date' },
]

export type DrawingDetailsTableProps = Pick<
  PlayerPromotionDrawingDetailsResponse,
  'scanEvents' | 'summary'
> & {
  onLoadMore: () => void
  isLoadingMore: boolean
  hideLoadingMore: boolean
}

export const DrawingDetailsTable = (props: DrawingDetailsTableProps) => (
  <div className={getBlockClass(TABLE_CLASS, 'table-container')}>
    {PromotionType[
      props.summary.promotionType as keyof typeof PromotionType
    ] === PromotionType.SCAN_TO_ENTER ? (
      <ScanToEnterTable {...props} />
    ) : (
      <SecondChanceTable {...props} />
    )}
    {!props.hideLoadingMore && (
      <button
        className={getBlockClass(TABLE_CLASS, `load-more`)}
        onClick={props.onLoadMore}
        disabled={props.isLoadingMore}
      >
        {props.isLoadingMore ? (
          <LoadingDots id="promotion-drawing-details-loading" color="#FFFFFF" />
        ) : (
          'Load More'
        )}
      </button>
    )}
  </div>
)

const SecondChanceTable = (props: DrawingDetailsTableProps) => (
  <>
    <table
      className={joinClasses([
        TABLE_CLASS,
        getModifierClass(TABLE_CLASS, 'no-load-more', props.hideLoadingMore),
      ])}
    >
      <thead>
        <tr>
          {SecondChanceTableHeaders.map((header) => (
            <th key={header.columnText}>{header.columnText}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.scanEvents.map((row) => (
          <tr key={row.ticketNumber}>
            <td>{row.ticketNumber}</td>
            <td>{row.scanMethod}</td>
            <td>{row.entryCount}</td>
            <td>{row.scanDate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)

const ScanToEnterTable = (props: DrawingDetailsTableProps) => (
  <>
    <table
      className={joinClasses([
        TABLE_CLASS,
        getModifierClass(TABLE_CLASS, 'no-load-more', props.hideLoadingMore),
      ])}
    >
      <thead>
        <tr>
          {ScanToEnterTableHeaders.map((header) => (
            <th key={header.columnText}>{header.columnText}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.scanEvents.map((row) => (
          <tr key={row.ticketNumber}>
            <td>{row.ticketNumber}</td>
            <td>{row.scanMethod}</td>
            <td>{row.scanDate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)
