import { Agent1099ReportListEntry } from '../../../api/agent-1099-reports/api-types'
import { SearchResultsTableColumnMap } from '../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui'

export type MappedAgent1099ReportListEntry = Omit<
  Agent1099ReportListEntry,
  'dateGenerated' | 'status'
> & {
  dateGenerated: string
  status: string
}

export const COLUMN_MAP: SearchResultsTableColumnMap<MappedAgent1099ReportListEntry> =
  [
    {
      dataKey: 'dateGenerated',
      label: 'Date & Time Generated',
      width: 24,
    },
    {
      dataKey: 'generatedBy',
      label: 'Generated By',
      width: 18,
    },
    {
      dataKey: 'paymentYear',
      label: 'Year',
      width: 10,
    },
    {
      dataKey: 'status',
      label: 'Status',
      width: 10,
    },
  ]
