import * as React from 'react'
import './styles.scss'
import { GetPaymentDetailsResponse } from '../../../../api/payment-details-fetcher/api-types'
import { PaymentDetailsCheckDetailsUI } from '../payment-details-check-details/payment-details-check-details'
import { PaymentRecipientDetailsUI } from '../payment-details-recipient-details/payment-details-recipient-details'
import { PaymentDetailsVoidReissueDetails } from '../payment-details-void-reissue-details/payment-details-void-reissue-details'
import { isNull } from 'lodash'
import { PaymentDetailsTaxBasisUI } from '../payment-details-tax-basis/payment-details-tax-basis'

const ROOT_CLASS = 'payment-details'

export type PaymentDetailsUIProps = {
  paymentDetailsData: GetPaymentDetailsResponse
}

const renderVoidReissueDetails = (props: PaymentDetailsUIProps) => {
  const { voidDetails, reissueDetails } = props.paymentDetailsData.payment
  if (!isNull(voidDetails) || !isNull(reissueDetails)) {
    return (
      <PaymentDetailsVoidReissueDetails 
        voidDetails={props.paymentDetailsData.payment.voidDetails} 
        reissueDetails={props.paymentDetailsData.payment.reissueDetails}
      />
    )
  }
  return null
}

export const PaymentDetailsUI = (props: PaymentDetailsUIProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className='check-details-tax-basis'>
        <div className={`check-details-row`}>
          <PaymentDetailsCheckDetailsUI {...props} />
          {renderVoidReissueDetails(props)}
        </div>
        <div className={`tax-basis-row`}>
          <PaymentDetailsTaxBasisUI {...props} />
        </div>
      </div>

      <div className={`recipient-details-row`}>
        <PaymentRecipientDetailsUI {...props} />
      </div>
    </div>
  )
}
