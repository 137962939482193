import * as React from 'react'
import FocusLock from 'react-focus-lock'
import { AccessibleKey } from '../common'
import { useKeyDown } from '../../hooks/use-keydown/use-keydown'
import { getBlockClass } from '../../utilities/helpers'
import { ConfirmPopupModal } from '../CommonComponents/ConfirmPopupModal/confirm-popup-modal'
import './styles.scss'

export enum GenericSidebarFormMode {
  NEW = 'new',
  EDIT = 'edit',
  CLOSED = 'closed',
}

export type GenericSidebarProps = {
  isOpen: boolean
  closeSideBar: () => void
  headerText?: string
  useConfirm?: boolean
  confirmHeaderText?: string
  confirmSubheaderText?: string
}

const ROOT_CLASS = 'generic-sidebar'
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')

const DEFAULT_CONFIRM_HEADER =
  'Are you sure you want to close out of the sidebar?'
const DEFAULT_CONFIRM_SUBHEADER = 'Nothing will be saved if you close now.'

const getVisibilityClass = (isOpen: boolean) =>
  isOpen ? `${ROOT_CLASS}--open` : `${ROOT_CLASS}--closed`

export const GenericSidebar = (
  props: React.PropsWithChildren<GenericSidebarProps>
) => {
  const [openCloseConfirmation, setOpenCloseConfirmation] =
    React.useState<boolean>(false)

  const getCloseSidebarAction = props.useConfirm
    ? () => setOpenCloseConfirmation(true)
    : props.closeSideBar

  const handleClose = () => {
    if (props.isOpen) {
      getCloseSidebarAction()
    }
  }

  const handleConfirmSubmit = () => {
    props.closeSideBar()
    setOpenCloseConfirmation(false)
  }

  useKeyDown({
    key: AccessibleKey.escape,
    action: handleClose,
    dependencies: [props.isOpen],
  })

  return (
    <>
      {props.useConfirm && (
        <ConfirmPopupModal
          isOpen={openCloseConfirmation}
          headerText={props.confirmHeaderText ?? DEFAULT_CONFIRM_HEADER}
          subheaderText={
            props.confirmSubheaderText ?? DEFAULT_CONFIRM_SUBHEADER
          }
          buttonText="Confirm"
          closeModal={() => setOpenCloseConfirmation(false)}
          loading={false}
          actionSuccessful={false}
          submitAction={handleConfirmSubmit}
        />
      )}
      {props.isOpen && <div className={`${ROOT_CLASS}__overlay`} />}
      <FocusLock disabled={!props.isOpen} autoFocus={false}>
        <aside className={`${ROOT_CLASS} ${getVisibilityClass(props.isOpen)}`}>
          {props.headerText && (
            <header className={HEADER_CLASS}>
              <div className={getBlockClass(HEADER_CLASS, 'title')}>
                {props.headerText}
              </div>
              <button
                className={getBlockClass(HEADER_CLASS, 'close-button')}
                onClick={handleClose}
                aria-label="Close"
              >
                <div>Close</div>
              </button>
            </header>
          )}
          {props.children}
        </aside>
      </FocusLock>
    </>
  )
}
