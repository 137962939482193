import React from "react";
import stylesMessageBox from "./css/stylesMessageBox.module.css";
import {keyPressedType} from "../../../../services/Commons";
import FocusTrap from "focus-trap-react";

export const SettleChargeConfirmationComponent = ({settleCharge, chargeId, setShowSettleConfirmationDialog}: any) => {

    return (
        <>
            <FocusTrap focusTrapOptions={{initialFocus: false}}>
                <div className={stylesMessageBox["permit-update-DOI-dialog-main-container"]}>
                    <div className={stylesMessageBox["permit-update-DOI-dialog-container"]}>
                        <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-1"]}>
                            <div className={stylesMessageBox["permit-update-DOI-dialog-title"]}>
                                Settle Charge
                            </div>
                            <div className={stylesMessageBox["permit-update-DOI-dialog-message"]}>
                                Update the status of the charge from “Rejected” to “Paid”.
                            </div>
                        </div>
                        <div className={stylesMessageBox["permit-update-DOI-dialog-inner-container-2"]}>
                            <div
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        settleCharge(chargeId);
                                        setShowSettleConfirmationDialog(false);
                                    }
                                }}
                                onClick={() => {
                                    settleCharge(chargeId);
                                    setShowSettleConfirmationDialog(false);
                                }}
                                className={stylesMessageBox["permit-update-DOI-dialog-yes-button"]}
                            >
                                <div
                                    className={
                                        stylesMessageBox["permit-update-DOI-dialog-yes-button-text"]
                                    }
                                >
                                    Update
                                </div>
                            </div>
                            <div
                                tabIndex={0}
                                onKeyDown={(e: any) => {
                                    if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                                        setShowSettleConfirmationDialog(false);
                                    }
                                }}
                                role="button"
                                onClick={() => {
                                    setShowSettleConfirmationDialog(false);
                                }}
                                className={
                                    stylesMessageBox["permit-update-DOI-dialog-no-button"]
                                }
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};