import React from 'react'
import { CircleInfo } from '../../icons/circle-info'
import {
  GenericToolTipWrapper,
  GenericToolTipWrapperProps,
} from './generic-tool-tip-wrapper'
import './styles.scss'

type GenericToolTipProps = Omit<
  GenericToolTipWrapperProps,
  'triggerElement'
> & {
  altText: string
}

export const GenericToolTip = (props: GenericToolTipProps) => {
  const circleIcon = (
    <div className={`generic-tool-tip__icon`}>
      <CircleInfo altText={props.altText} />
    </div>
  )
  return (
    <GenericToolTipWrapper
      position={props.position}
      sizePx={props.sizePx}
      leftPx={props.leftPx}
      noBorder={props.noBorder}
      triggerElement={circleIcon}
      hiddenElement={props.hiddenElement}
    />
  )
}
